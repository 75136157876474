import styled from 'styled-components';
import { media } from 'gantri-components';
import Grid from '../../../../../common/grid';

export const StyledGrid = styled(Grid)`
  border-top: 1px solid var(--colors-light-grey);
  ${media.greaterThan('md')`
    display: none;
  `}
`;
