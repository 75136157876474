import styled from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledDownloadContainer = styled.div`
  cursor: pointer;
  min-height: 24px;
  display: flex;
  align-items: center;
  user-select: none;
`;

export const StyledDownloadIconContainer = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const StyledFileName = styled(Typography)`
  max-width: 320px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
