import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { startFinishStageModalAtoms } from '../../start-finishing-stage-job-modal.atoms';
import { ConfirmStartFooter } from '../step-confirm-start/confirm-start-footer';
import { PutInVlmFooter } from '../step-put-in-vlm/put-in-vlm-footer';
import { FinishStageJobFooterProps } from './finish-stage-job-footer.types';

export const FinishStageJobFooter = (props: FinishStageJobFooterProps) => {
  const { handleCloseModal, job } = props;
  const { id: jobId } = job;

  const step = useRecoilValue(startFinishStageModalAtoms.step);

  switch (step) {
    case 'CONFIRM_START':
      return (
        <ConfirmStartFooter handleCloseModal={handleCloseModal} jobId={jobId} />
      );

    case 'PUT_IN_VLM':
      return (
        <PutInVlmFooter handleCloseModal={handleCloseModal} jobId={jobId} />
      );

    case 'VLM_IS_FULL':
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={async () => {
            await handleCloseModal();
          }}
        />
      );

    default:
      return null;
  }
};
