import { Content } from 'pdfmake/interfaces';
import {
  formatDate,
  formatPhoneNumber,
} from '../../../../../../../../../../helpers/formatter';
import { GetPackingSlipDocProps } from '../../get-packing-slip-doc.types';
import { getStocksNotYetShipped } from '../get-stocks-not-yet-shipped';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getSummaryTable = (props: GetPackingSlipDocProps): Content[] => {
  const { currentOrderData, estimateDate, stocks } = props;

  const moreThanOneItemInShipment = stocks.length > 1;
  const stocksNotYetShipped = getStocksNotYetShipped(props);
  const willHaveAdditionalShipments = stocksNotYetShipped.length > 0;

  const notes: Content[] = [];

  if (moreThanOneItemInShipment) {
    notes.push({
      style: ['tableCellVerticalMargins', 'lightGray', 'fontSize8'],
      text: 'Shipments with multiple items are delivered in multiple packages.',
    });
  }

  if (willHaveAdditionalShipments) {
    notes.push({
      style: ['tableCellVerticalMargins', 'lightGray', 'fontSize8'],
      text: 'Your order will be completed in additional shipments.',
    });
  }

  const hasNotes = !!notes.length;

  const firstName = currentOrderData?.address?.firstName || '';
  const lastName = currentOrderData?.address?.lastName || '';
  const name = currentOrderData?.address?.name || `${firstName} ${lastName}`;
  const street = currentOrderData?.address?.street;
  const streetAddress = currentOrderData?.address?.streetAddress || street;
  const unit = currentOrderData?.address?.unit;
  const city = currentOrderData?.address?.city;
  const state = currentOrderData?.address?.state;
  const zip = currentOrderData?.address?.zip;
  const phone = currentOrderData?.address?.phone;

  const shipToData: Content[] = [
    name && [{ text: name }],
    streetAddress && [{ text: streetAddress }],
    unit && [{ text: unit }],
    (city || state || zip) && [
      {
        text: `${city}, ${state} ${zip}`,
      },
    ],
    phone && [{ text: formatPhoneNumber(phone) }],
  ].filter((data) => {
    return !!data;
  });

  return [
    {
      layout: 'noBorders',
      style: ['tableMargins'],
      table: {
        body: [
          [
            {
              layout: 'noBorders',
              table: {
                body: [
                  [
                    {
                      style: ['tableCellVerticalMargins', 'bold'],
                      text: 'SHIP TO:',
                    },
                  ],
                  ...shipToData,
                ],
                headerRows: 1,
              },
            },
            {
              layout: 'noBorders',
              table: {
                body: [
                  [
                    {
                      style: ['tableCellVerticalMargins', 'bold'],
                      text: 'ORDER NUMBER:',
                    },
                  ],
                  [
                    {
                      style: ['tableCellVerticalMargins'],
                      text: `#${currentOrderData?.id}`,
                    },
                  ],
                  [
                    {
                      style: ['summaryHeaderTopMargin', 'bold'],
                      text: 'ORDER DATE:',
                    },
                  ],
                  [
                    {
                      style: ['tableCellVerticalMargins'],
                      text: formatDate(currentOrderData?.createdAtSec),
                    },
                  ],
                ],
                headerRows: 1,
              },
            },
            {
              layout: 'noBorders',
              table: {
                body: [
                  [
                    {
                      style: ['tableCellVerticalMargins', 'bold'],
                      text: 'SHIPMENT DATE:',
                    },
                  ],
                  [
                    {
                      style: ['tableCellVerticalMargins'],
                      text: formatDate(estimateDate?.shippedOnSec),
                    },
                  ],
                  ...(hasNotes
                    ? [
                        [
                          {
                            style: ['summaryHeaderTopMargin', 'bold'],
                            text: 'NOTES:',
                          },
                        ],
                        [notes],
                      ]
                    : []),
                ],
                headerRows: 1,
              },
            },
          ],
        ],
        widths: ['35%', '*', '30%'],
      },
    },
  ];
};
