import { ProductColorCode } from 'gantri-components';
import { ShortProductSummaryInfo } from '../../../../components/common/short-product-summary/short-product-summary.types';
import { SortingType } from '../../../../components/common/table/table.props';

export interface FetchPaginatedProductReviewsArgs {
  colors: ProductColorCode[];
  count: number;
  page: number;
  productId: number;
  search: string;
  sortingField: ProductReviewsSortingField;
  sortingType: SortingType;
  statuses: ProductReviewStatus[];
}

export interface FetchPaginatedProductReviewsResponse {
  maxPages: number;
  reviews: Review[];
  success: boolean;
  totalReviews: number;
}

export interface Review {
  color: string;
  id: number;
  lastDate: number;
  orderId: number;
  product: ShortProductSummaryInfo;
  rating: number;
  status: string;
  stockId: number;
  userId: number;
  userName: string;
  version: string;
}

export type ProductReviewsSortingField =
  | 'user'
  | 'rating'
  | 'lastDate'
  | 'product'
  | 'version'
  | 'stockId'
  | 'orderId';

export type ProductReviewStatus =
  | 'Published'
  | 'Rejected'
  | 'Pending'
  | 'Incomplete';

export const ProductReviewStatuses = Object.freeze({
  COMPLETED: 'Completed',
  INCOMPLETE: 'Incomplete',
  PENDING: 'Pending',
  PUBLISHED: 'Published',
  REJECTED: 'Rejected',
});
