import { Icon, Tooltip, Typography } from 'gantri-components';
import { TableHeaderWithInfoToolTipProps } from './header.props';
import { HeaderPresets } from './header.presets';

export const TableHeaderWithInfoToolTip = (
  props: TableHeaderWithInfoToolTipProps,
) => {
  const { headerText, headerTextStyle, position, tooltipText } = props;

  return (
    <Typography
      color="t1"
      icon={
        <Tooltip
          Component={
            <Typography
              color="t2"
              horizontalPadding="1.4rem"
              text={tooltipText}
              verticalPadding=".6rem"
            />
          }
          position={position}
        >
          <Icon color="t1" name="alert:i_circle" />
        </Tooltip>
      }
      iconPosition="right"
      text={headerText}
      textStyle={headerTextStyle}
    />
  );
};

TableHeaderWithInfoToolTip.defaultProps = HeaderPresets;
