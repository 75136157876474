import { MachineDataCell } from '../../../../components';
import { JobMachineCellProps } from './job-machine-cell.types';

export const JobMachineCell = ({ getValue, row }: JobMachineCellProps) => {
  const { assignedInfo, machineName } = row.original;

  return (
    <MachineDataCell
      assignedInfo={assignedInfo}
      machineId={getValue()}
      machineName={machineName}
    />
  );
};
