import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../components/report-table/report-table.types';

export const getSortingOptionsFromColumns = <
  TableData extends Record<string, any>,
>(
  columns: DownloadableTableColumn<TableData>[],
): SortOptionFromTable<TableData>[] => {
  return columns.map(({ accessorKey, header, label }) => {
    return {
      bidirectional: true,
      label: label ?? header.toString(),
      sortingField: accessorKey as keyof TableData,
    };
  });
};
