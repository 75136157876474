import { DashboardReportToHide } from '../../../../../../../types/store';

/** Listed in order to render. */
export const availableReports: DashboardReportToHide[] = [
  'Orders',
  'Stocks',
  'Print Jobs',
  'Finishing Jobs',
  'Assembly Jobs',
  'Tech',
];
