import { InventoryRequest } from '../../api/jobs/jobs.types';

export const filterInvalidInventoryRequests = (
  inventoryRequests: InventoryRequest[],
) => {
  return (
    inventoryRequests?.filter(
      // specifically filtering by name to avoid temp IDs
      (request) => {
        return request.inventory?.name && (request.need || request.discarded);
      },
    ) || []
  );
};
