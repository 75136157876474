import { FC, PropsWithChildren } from 'react';
import { Icon, Overlay } from 'gantri-components';
import { DetailsPanelProps } from './details-panel.props';
import { DetailsPanelPresets } from './details-panel.presets';
import {
  StyledDetailsPanelStack,
  StyledDetailsPanelContent,
  StyledDetailsPanelTitle,
  StyledDetailsPanelTitleOptions,
  detailsPanelZIndex,
} from './details-panel.styles';
import Menu from '../menu';

export const DetailsPanel: FC<PropsWithChildren<DetailsPanelProps>> = (
  props,
) => {
  const { children, dataAttrs, footer, header, menu, onClose, onOpen, open } =
    props;

  return (
    <Overlay
      content={
        <StyledDetailsPanelStack
          {...dataAttrs}
          gap="unset"
          rows="max-content 1fr max-content"
        >
          <div>
            <StyledDetailsPanelTitleOptions
              style={{
                gridTemplateColumns: `repeat(${menu ? 2 : 1}, max-content)`,
              }}
            >
              {!!menu && <Menu data={null} options={menu} />}
              <Icon
                color="t2"
                cursor="pointer"
                name="ui-control:x"
                size="1.3rem"
                top="-3px"
                onClick={onClose}
              />
            </StyledDetailsPanelTitleOptions>
            <StyledDetailsPanelTitle>{header}</StyledDetailsPanelTitle>
          </div>
          <StyledDetailsPanelContent>{children}</StyledDetailsPanelContent>
          {footer}
        </StyledDetailsPanelStack>
      }
      open={open}
      triggerEvent="manual"
      zIndex={detailsPanelZIndex}
      onClose={onClose}
      onOpen={onOpen}
    />
  );
};

DetailsPanel.defaultProps = DetailsPanelPresets;
