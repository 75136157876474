import {
  HandleCloseModal,
  UseHandleCloseModalProps,
} from './use-handle-close-modal.types';

export const useHandleCloseModal = (props: UseHandleCloseModalProps) => {
  const { onClose, onUpdate, updateAfterClose, updateOnClose } = props;

  const handleCloseModal: HandleCloseModal = async (options) => {
    const shouldCallOnUpdate = updateOnClose || options?.updateOnClose;

    if (!updateAfterClose && shouldCallOnUpdate) {
      await onUpdate();
    }

    onClose();

    if (updateAfterClose && shouldCallOnUpdate) {
      // timeout added to ensure sufficient time to unmount as this is likely used to invalidate react-query cache
      setTimeout(async () => {
        await onUpdate();
      }, 100);
    }
  };

  return handleCloseModal;
};
