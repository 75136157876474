import { Button } from 'gantri-components';
import { jobsApi } from '../../../../../../api';
import { jobStatuses } from '../../../../../../constants/options';
import { useNotification } from '../../../../../../hooks/useNotification';
import { StartJobFooterProps } from './start-job-footer.types';
import { startJobFooterDefaultProps } from './start-job-footer.presets';

export const StartJobFooter = (props: StartJobFooterProps<string>) => {
  const { job, nextStep, onClose, setStep, setUpdateOnClose, size } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handleStartJob = async () => {
    try {
      if (job.status === jobStatuses.ready) {
        showLoading();
        await jobsApi.startJob({ jobId: job.id });
        setUpdateOnClose(true);
      }

      setStep(nextStep);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to start job.' });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button size={size} text="Cancel" variant="secondary" onClick={onClose} />
      <Button size={size} text="Yes" onClick={handleStartJob} />
    </>
  );
};

StartJobFooter.defaultProps = startJobFooterDefaultProps;
