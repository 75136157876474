import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import MetaData from '../../components/meta-data';
import { dashboardAndReportAtoms } from '../dashboard/dashboard.atoms';
import { useValidateEndDate } from '../dashboard/hooks/use-validate-end-date';
import { ReportJobs } from './components/report-jobs';
import { ReportMachines } from './components/report-machines';
import { ReportOrders } from './components/report-orders';
import { ReportProfit } from './components/report-profit';
import { ReportSales } from './components/report-sales';
import { ReportStocks } from './components/report-stocks';

export const DashboardReport = () => {
  const { dash } = useParams<{ dash: string }>();
  const capitalizedDash = dash.charAt(0).toUpperCase() + dash.slice(1);

  const endDate = useRecoilValue(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const isValidEndDate = useValidateEndDate({ endDate, rangeType });
  const rootTitle = `${capitalizedDash} Report`;

  return (
    <>
      <MetaData title={rootTitle} />
      {isValidEndDate && <Report dash={dash} rootTitle={rootTitle} />}
    </>
  );
};

const Report = ({ dash, rootTitle }: { dash: string; rootTitle: string }) => {
  switch (dash) {
    case 'jobs':
      return <ReportJobs rootTitle={rootTitle} />;
    case 'machines':
      return <ReportMachines rootTitle={rootTitle} />;
    case 'orders':
      return <ReportOrders rootTitle={rootTitle} />;
    case 'profit':
      return <ReportProfit rootTitle={rootTitle} />;
    case 'sales':
      return <ReportSales rootTitle={rootTitle} />;
    case 'stocks':
      return <ReportStocks rootTitle={rootTitle} />;
    default:
      return null;
  }
};
