import styled, { css } from 'styled-components';
import { transitions, zIndexes } from '../../../constants/styles';

export const StyledMoreMenuOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: ${zIndexes.dropdown};
`;

export const StyledMoreMenuOption = styled.div<{ enabled: boolean }>`
  ${({ enabled, theme }) => {
    return css`
      padding: 0 20px;
      line-height: 35px;
      outline: none;
      width: 100%;
      text-align: left;
      ${transitions.base};
      color: ${enabled
        ? theme.colors.typography.t1
        : theme.colors.typography.t2};
      cursor: ${enabled ? 'pointer' : 'not-allowed'};
      opacity: ${enabled ? 1 : 0.5};

      :hover {
        background-color: ${enabled &&
        theme.colors.surfaces.monochrome.overlayLevel2};
      }
    `;
  }}
`;
