import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { MachineOfflineFooterProps } from './machine-offline-footer.types';

export const MachineOfflineFooter = (props: MachineOfflineFooterProps) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={async () => {
        await handleCloseModal();
      }}
    />
  );
};
