import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedInventoryPurchasesArgs,
  GetPaginatedInventoryPurchasesResponse,
} from './get-paginated-inventory-purchases.types';

export const getPaginatedInventoryPurchases = ({
  completedDateRange,
  createdDateRange,
  count = 100,
  country = '',
  hasDelayedShipping = false,
  hasQuantityIssues = false,
  page = 1,
  search = '',
  sortingField = '',
  sortingType = '',
  statuses = [],
  vendor,
  hasDiscardedShipment = false,
}: GetPaginatedInventoryPurchasesArgs) => {
  return axios.post<GetPaginatedInventoryPurchasesResponse>(
    `${coreApiUrl}/inventory-purchases/pagination`,
    {
      completedEndDate: completedDateRange?.to,
      completedStartDate: completedDateRange?.from,
      count,
      country,
      endDate: createdDateRange?.to,
      hasDelayedShipping,
      hasDiscardedShipment,
      hasQuantityIssues,
      page,
      search,
      sortingField,
      sortingType,
      startDate: createdDateRange?.from,
      statuses,
      vendor,
    },
  );
};
