import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchBatchProductsAndPartsArgs,
  FetchBatchProductsAndPartsResponse,
} from './fetch-batch-products-and-parts.types';
import { fetchBatchProductsAndParts } from './fetch-batch-products-and-parts';

const fetchBatchProductsAndPartsQueriesObj = getGenericQueryObj({
  groupName: 'product-part-template-list',
  query: fetchBatchProductsAndParts,
});

export const useFetchBatchProductsAndParts = <
  TransformedData = FetchBatchProductsAndPartsResponse,
>(
  props?: GenericFetchProps<
    FetchBatchProductsAndPartsArgs,
    FetchBatchProductsAndPartsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch batch products and parts.',
    queryObj: fetchBatchProductsAndPartsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchBatchProductsAndPartsCache = () => {
  const invalidateFetchBatchProductsAndPartsCache = useGetInvalidateQueryCache(
    fetchBatchProductsAndPartsQueriesObj,
  );

  return { invalidateFetchBatchProductsAndPartsCache };
};
