import { useSetRecoilState } from 'recoil';
import { PrintPrepareQueue } from '../../../../../../../api/queue/print-prepare/print-prepare.types';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-prepare-queue-modal.constants';
import { UseNavigateToNextAvailableSetProps } from './use-navigate-to-next-available-set.types';

export const useNavigateToNextAvailableSet = (
  props: UseNavigateToNextAvailableSetProps,
) => {
  const { handleCloseModal } = props;

  const setStep = useSetRecoilState(printPrepareQueueModalAtoms.step);
  const setActiveSetIndex = useSetRecoilState(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  const navigateToNextAvailableSet = async (queueData: PrintPrepareQueue) => {
    const nextActiveSetIndex = queueData?.sets?.findIndex?.(
      ({ isComplete }) => {
        return !isComplete;
      },
    );

    if (nextActiveSetIndex >= 0) {
      setActiveSetIndex(nextActiveSetIndex);
      setStep(printPrepareQueueModalSteps.jobSetStart);
    } else {
      await handleCloseModal({ updateOnClose: true });
    }
  };

  return { navigateToNextAvailableSet };
};
