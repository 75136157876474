import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMemo } from 'react';
import { getPartChecklist } from './helpers/get-part-checklist';
import { completeAssemblyModalAtoms } from '../../../../../complete-assembly-modal.atoms';
import { useCompleteJob } from '../../../../../../../../../../../api/jobs/routes/complete-job';
import { useGetStock } from '../../../../../../../../../../../api/stocks/routes';
import { UsePassOrFailJobProps } from './use-pass-or-fail-job.types';
import { maxExceededCycleTime } from '../../../../../complete-assembly-modal.constants';
import { getFailingReasonsFromChecklist } from '../../../../../../../helpers/get-failing-reasons-from-checklist';

export const usePassOrFailJob = (props: UsePassOrFailJobProps) => {
  const { handleCloseModal } = props;

  const job = useRecoilValue(completeAssemblyModalAtoms.job);
  const jobChecklist = useRecoilValue(completeAssemblyModalAtoms.jobChecklist);
  const setExceededCycleTime = useSetRecoilState(
    completeAssemblyModalAtoms.exceededCycleTime,
  );
  const setPartChecklist = useSetRecoilState(
    completeAssemblyModalAtoms.partChecklist,
  );
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeAssemblyModalAtoms.updateOnClose,
  );
  const setOldParts = useSetRecoilState(completeAssemblyModalAtoms.oldParts);

  const isFailure: boolean = useMemo(() => {
    return !!getFailingReasonsFromChecklist(jobChecklist).length;
  }, [jobChecklist]);

  const { data, isLoading: isLoadingFail } = useGetStock({
    enabled: isFailure,
    fetchArgs: {
      stockId: job.stockId,
    },
  });

  const { isLoading: isLoadingPass, onCompleteJob } = useCompleteJob({
    onSuccess: async ({ exceededCycleTime }) => {
      setExceededCycleTime(exceededCycleTime);

      if (exceededCycleTime > maxExceededCycleTime) {
        setStep('JOB_PASSED_EXCEEDED_CYCLE_TIME');
        setUpdateOnClose(true);
      } else {
        await handleCloseModal({ updateOnClose: true });
      }
    },
  });

  const onComplete = async () => {
    await onCompleteJob({
      jobId: job.id,
    });
  };

  const onFail = async () => {
    const { stock } = data;

    setOldParts(stock.parts);

    const partChecklist = getPartChecklist(stock);

    setPartChecklist(partChecklist);
    setStep('PART_CHECKLIST');
  };

  return {
    onComplete,
    onFail,
    processing: isLoadingFail || isLoadingPass,
    unableToLoadFailRequirements: isFailure && !data?.stock,
  };
};
