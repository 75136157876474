import {
  Button,
  Flex,
  Stack,
  Typography,
  useConfirmationModal,
} from 'gantri-components';
import React from 'react';
import { ResetJobCountProps } from './reset-job-count.types';
import { useMachineQueriesCache } from '../../../queries-cache';
import { machinesApi } from '../../../../../api';
import { useNotification } from '../../../../../hooks/useNotification';

export const ResetJobCount = ({ currentMachine }: ResetJobCountProps) => {
  const { startJobCount, type: machineType } = currentMachine;
  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();
  const invalidateCache = useMachineQueriesCache();

  const resetTapeBedCount = async () => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await machinesApi.resetJobCountdown(currentMachine.id);

        if (data.success) {
          notify(data.notice);
          await invalidateCache(currentMachine.id);
        }

        hideResetTapeBedCountModal();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to reset tape bed count.',
        });
      }
    });
  };

  const [showResetTapeBedCountModal, hideResetTapeBedCountModal] =
    useConfirmationModal({
      content: (
        <Stack alignContent="center" height={{ lg: '12rem', md: 'unset' }}>
          <Typography
            align="center"
            text="Are you sure you want to reset the counter?"
          />
        </Stack>
      ),
      headerText: 'Prints on Build Plate',
      onConfirm: resetTapeBedCount,
      width: { lg: '40rem', md: 'auto' },
    });

  const disabled = startJobCount === 0;

  return (
    <Flex alignItems="center" gap="x">
      <Typography color="t1" text={startJobCount} />
      <Button
        disabled={disabled}
        size="small"
        text="Reset"
        variant="ghost"
        onClick={showResetTapeBedCountModal}
      />
    </Flex>
  );
};
