import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { bulkStartPrintPrepareQueueSetJobs } from './bulk-start-print-prepare-queue-set-jobs';
import {
  BulkStartPrintPrepareQueueSetJobsArgs,
  BulkStartPrintPrepareQueueSetJobsResponse,
} from './bulk-start-print-prepare-queue-set-jobs.types';

export const useBulkStartPrintPrepareQueueSetJobs = (args: {
  onDeleted: () => void;
  showLoading?: boolean;
}) => {
  const { onDeleted, showLoading } = args;

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    BulkStartPrintPrepareQueueSetJobsArgs,
    BulkStartPrintPrepareQueueSetJobsResponse
  >({
    config: {
      onError: (error) => {
        // @ts-expect-error
        if (error.response.status === 404) {
          onDeleted();
        } else {
          notifyAxiosError({
            error,
            fallbackMessage:
              'Unable to bulk start jobs in print prepare queue.',
          });
        }
      },
      onSuccess: async (data) => {
        notify(data.notice);
      },
    },
    mutationFn: bulkStartPrintPrepareQueueSetJobs,
    showLoading,
  });

  return { isLoading, onBulkStartPrintPrepareQueueSetJobs: onMutate };
};
