import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { FailPrintJobArgs, FailPrintJobResponse } from './fail-print-job.types';
import { failPrintJob } from './fail-print-job';

export const useFailPrintJob = <TransformedData = FailPrintJobResponse>(
  props?: GenericMutateQueryProps<
    FailPrintJobArgs,
    FailPrintJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onFailPrintJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to fail print job.',
    mutationFn: failPrintJob,
    ...props,
  });

  return { ...rest, onFailPrintJob };
};
