import { Stack, Typography } from 'gantri-components';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';

export const VlmIsFullContent = () => {
  return (
    <Stack gap="4x">
      <ModalContentHeading color="alert" titleText="Part could not be stored" />
      <Stack gap="x">
        <Typography text="Make room in VLM" variant="h5" />
        <Typography text="There are no bins available for a new Stock VLM assignment. Please un-assign a bin to make room for this stock." />
      </Stack>
    </Stack>
  );
};
