import { LocationSortingField } from '../../api/locations/routes/paginated-location/paginated-location.types';
import { getSearchFilterAtomFamily } from '../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { LocationType, LocationWorkshop } from './locations.types';

export const locationsFiltersDefaults: {
  filters: {
    sortBy: LocationSortingField;
    type: Lowercase<LocationType>[];
    workshop: LocationWorkshop;
  };
} = {
  filters: {
    sortBy: 'default',
    type: [],
    workshop: null,
  },
};

const atomKeyPrefix = 'LOCATIONS_FILTERS';

export const pageName: AtomPageName = 'locations';

export const locationsPageAtoms = {
  defaults: locationsFiltersDefaults,
  filters: {
    type: getGenericAtomFamily({
      defaultValue: locationsFiltersDefaults.filters.type,
      key: `${atomKeyPrefix}-type`,
    })(pageName),
    workshop: getGenericAtomFamily({
      defaultValue: locationsFiltersDefaults.filters.workshop,
      key: `${atomKeyPrefix}-workshop`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getGenericAtomFamily({
    defaultValue: locationsFiltersDefaults.filters.sortBy,
    key: `${atomKeyPrefix}-sort-by`,
  })(pageName),
};
