import { Typography, useConfirmationModal } from 'gantri-components';
import { useRef } from 'react';
import { downloadsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

type Props = {
  onComplete: CallableFunction;
};

export const useDeleteDownload = (props: Props) => {
  const { onComplete } = props;
  const idRef = useRef<number>();
  const { notify, notifyAxiosError, onInterceptProcessingRequest } =
    useNotification();

  const onConfirmRemoval = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await downloadsApi.deleteDownload(idRef.current);

        notify(data.notice || 'Item deletion successful.');

        if (data?.success) {
          onComplete();
          hideModal();
        }
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to delete item.' });
      }
    });
  };

  const [showModal, hideModal] = useConfirmationModal(
    {
      confirmButtonText: 'Confirm',
      confirmButtonVariant: 'primary',
      content: (
        <Typography
          style={{ textAlign: 'center' }}
          text="Are you sure you want to remove the download?"
        />
      ),
      headerText: 'Delete download',
      onClose: () => {
        hideModal();
      },
      onConfirm: onConfirmRemoval,
      width: { lg: '42rem', md: '100%' },
    },
    [],
  );

  const onShowConfirm = (id: number) => {
    idRef.current = id;
    showModal();
  };

  return { onShowConfirm };
};
