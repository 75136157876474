import { useRecoilValue } from 'recoil';
import { startFinishStageModalAtoms } from '../../start-finishing-stage-job-modal.atoms';
import { PutInVlmContent } from '../step-put-in-vlm/put-in-vlm-content';
import { ConfirmStartContent } from '../step-confirm-start/confirm-start-content';
import { FinishStageJobContentProps } from './finish-stage-job-content.types';
import { VlmIsFullContent } from '../step-vlm-is-full/vlm-is-full-content';

export const FinishStageJobContent = (props: FinishStageJobContentProps) => {
  const { job } = props;

  const step = useRecoilValue(startFinishStageModalAtoms.step);

  switch (step) {
    case 'CONFIRM_START':
      return <ConfirmStartContent />;

    case 'PUT_IN_VLM':
      return <PutInVlmContent job={job} />;

    case 'VLM_IS_FULL':
      return <VlmIsFullContent />;

    default:
      return null;
  }
};
