import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportProfitQueries } from '../../report-profit.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportProfitQueries>();
  const { groupBy, productId, version } = queries;

  if (!/^productId$/i.test(groupBy)) {
    return 'Order Type';
  }

  if (version) {
    return 'SKU';
  }

  if (productId) {
    return 'Version';
  }

  return 'Product Name';
};
