import { CellContext, ColumnDef } from '@tanstack/react-table';
import { FailReasonsCell } from '../common/fail-reasons-cell';
import { FailedReason } from '../common/fail-reasons-cell/fail-reasons-cell.types';
import { TechTableData } from './dashboard-tech-summary.types';

const IssuesCell = (props: CellContext<TechTableData, any>) => {
  const { getValue, row } = props;
  const { topIssues = [] } = row.original;

  const failReasons: FailedReason[] = topIssues.map(({ count, type }) => {
    const failReason: FailedReason = {
      count,
      reason: type,
    };

    return failReason;
  });

  return (
    <FailReasonsCell
      failReasons={failReasons}
      title="Top issues"
      value={getValue()}
    />
  );
};

export const printJobsTableColumns: ColumnDef<TechTableData>[] = [
  {
    accessorKey: 'type',
    header: 'Machine type',
    size: 80,
  },
  {
    accessorKey: 'total',
    cell: IssuesCell,
    header: 'Total',
    size: 80,
  },
  {
    accessorKey: 'online',
    cell: IssuesCell,
    header: 'Online',
    size: 80,
  },
  {
    accessorKey: 'issues',
    cell: IssuesCell,
    header: 'Issues',
    size: 80,
  },
  {
    accessorKey: 'repairs',
    cell: IssuesCell,
    header: 'Repairs',
    size: 80,
  },
];
