import styled from 'styled-components';
import { media } from 'gantri-components';

export const StyledLocationContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 12rem;
  width: fit-content;

  ${media.lessThan('md')`
    width: calc(100vw - 6rem);
  `}

  ${media.lessThan('sm')`
    width: calc(100vw - 2.4rem);
  `}
  
  & > p,
  svg {
    margin-right: 5px;
  }
`;

export const StyledModalSubtitle = styled.div`
  margin-top: 1rem;
`;

export const StyledModalInputContainer = styled.div`
  min-width: 30rem;
  margin-bottom: 2rem;
`;

export const StyledLocationHistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1rem;
  max-height: 12rem;
  overflow-y: auto;
`;
