import styled, { css } from 'styled-components';
import { PrimarySurfaceSwatchProps } from './primary-surface-swatch.types';

export const StyledPrimarySurfaceSwatch = styled.div<PrimarySurfaceSwatchProps>`
  ${({ material, size = '2.6rem', theme }) => {
    return css`
      width: ${size};
      height: ${size};
      background: ${material === 'Opaque GPP' ? '#6FED6F' : '#4ebffc'};
      box-shadow: ${theme.colors.shadow.low};
      border-radius: 2px;
    `;
  }}
`;
