import {
  FC,
  forwardRef,
  MouseEvent,
  PropsWithChildren,
  Ref,
  useMemo,
} from 'react';
import { Icon, Typography } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  StyledToggleWrapper,
  StyledMenuLink,
  StyledIconWrapper,
  StyledMenuLinkWrapper,
} from './menu-link.styles';
import { dataAttrIsActive } from './menu-link.constants';
import { isActivePath } from '../../../../../../../helpers/path-utils';
import { MenuLinkProps } from './menu-link.types';
import { uiAtoms } from '../../../../../../../global-atoms/ui';
import { userAtoms } from '../../../../../../../global-atoms/users';

export const MenuLink: FC<PropsWithChildren<MenuLinkProps>> = forwardRef(
  (props, ref: Ref<HTMLDivElement>) => {
    const { expandedMenuItems, isSubPath, onLinkClicked, route } = props;

    const isSidebarOpen = useRecoilValue(uiAtoms.isSidebarOpen);
    const setExpandedMenuItems = useSetRecoilState(uiAtoms.expandedMenuItems);

    const isActive = isActivePath(route.path);
    const isExpanded = expandedMenuItems?.[route.name];

    const theme = useRecoilValue(userAtoms.theme);

    const toggleExpanded = (route: string, event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      setExpandedMenuItems({
        ...expandedMenuItems,
        [route]: !expandedMenuItems[route],
      });
    };

    const iconColor = useMemo(() => {
      if (theme === 'dark') {
        return isActive ? 't1' : 't2';
      }

      return isActive ? 't2' : 't1';
    }, [theme, isActive]);

    return (
      <StyledMenuLinkWrapper ref={ref}>
        <StyledMenuLink
          $isSidebarOpen={isSidebarOpen}
          $isSubPath={isSubPath}
          to={route.path}
          onClick={onLinkClicked}
          {...{
            [dataAttrIsActive]: isActive ? '' : undefined,
          }}
        >
          {route.icon && (
            <StyledIconWrapper isSidebarOpen={isSidebarOpen}>
              <Icon color={iconColor} name={route.icon} />
            </StyledIconWrapper>
          )}
          {isSidebarOpen && (
            <>
              <Typography
                color={isActive ? 't1' : 't2'}
                text={route.name}
                textStyle={isActive ? 'bold' : 'regular'}
                variant="p2"
              />

              {!!route.subPaths?.length && (
                <StyledToggleWrapper
                  onClick={(event) => {
                    toggleExpanded(route.name, event);
                  }}
                >
                  <Icon
                    color={iconColor}
                    name={
                      isExpanded
                        ? 'arrows:arrow_chevron_up'
                        : 'arrows:arrow_chevron_down'
                    }
                  />
                </StyledToggleWrapper>
              )}
            </>
          )}
        </StyledMenuLink>
      </StyledMenuLinkWrapper>
    );
  },
);
