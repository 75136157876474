import {
  Button,
  Cell,
  Conditional,
  Grid,
  Icon,
  Tooltip,
  useModal,
} from 'gantri-components';
import { shipmentStatuses, shipmentText } from '../../../../shipment.constants';
import { ConfirmDeliveryModal } from './components/confirm-delivery-modal';
import { DeleteShipmentModal } from './components/delete-shipment-modal';
import { NotEditingShipmentButtonsProps } from './not-editing-shipment-buttons.types';
import { requiredKeysForConfirmDelivery } from './not-editing-shipment-buttons.constants';

export const NotEditingShipmentButtons = (
  props: NotEditingShipmentButtonsProps,
) => {
  const {
    editedInventoryPurchase,
    editedShipment,
    isEditingDisabled,
    onUpdate,
    setEditedShipment,
    setIsEditing,
    setIsEditingDisabled,
    shipmentNumber,
    totalShipments,
  } = props;

  const enableEdit = () => {
    setIsEditing(true);
    setIsEditingDisabled(true);
  };

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return (
      <DeleteShipmentModal
        shipmentId={editedShipment.id}
        shipmentNumber={shipmentNumber}
        onClose={hideDeleteModal}
        onUpdate={onUpdate}
      />
    );
  }, [shipmentNumber]);

  const [showConfirmDeliveryModal, hideConfirmDeliveryModal] = useModal(() => {
    return (
      <ConfirmDeliveryModal
        editedInventoryPurchase={editedInventoryPurchase}
        editedShipment={editedShipment}
        setEditedShipment={setEditedShipment}
        shipmentNumber={shipmentNumber}
        totalShipments={totalShipments}
        onClose={() => {
          hideConfirmDeliveryModal();
          setIsEditingDisabled(false);
        }}
        onUpdate={onUpdate}
      />
    );
  }, [editedShipment, editedInventoryPurchase]);

  const isShipmentDelivered =
    editedShipment.status === shipmentStatuses.delivered;
  const isConfirmDeliveryDisabled = requiredKeysForConfirmDelivery.some(
    (keyName) => {
      return !editedShipment[keyName];
    },
  );
  const canDelete = !isShipmentDelivered && shipmentNumber > 1;

  const ConfirmDeliveryButton = () => {
    return (
      <Button
        disabled={isEditingDisabled || isConfirmDeliveryDisabled}
        text="Confirm Delivery"
        variant="secondary"
        onClick={() => {
          showConfirmDeliveryModal();
          setIsEditingDisabled(true);
        }}
      />
    );
  };

  return (
    <Conditional condition={!isShipmentDelivered}>
      <Cell width={{ lg: 1, sm: 2 }}>
        <Grid
          alignItems="center"
          columns={`repeat(${canDelete ? 3 : 2}, max-content)`}
          gap="0.8rem"
          justifyContent={{ lg: 'flex-end', sm: 'flex-start' }}
        >
          <Button
            disabled={isEditingDisabled}
            text="Edit"
            variant="secondary"
            onClick={enableEdit}
          />
          <Conditional
            condition={isConfirmDeliveryDisabled}
            Fallback={<ConfirmDeliveryButton />}
          >
            <Tooltip
              description={`The following fields are required: ${requiredKeysForConfirmDelivery
                .map((keyName) => {
                  const isValueProvided = !!editedShipment[keyName];
                  const matchingLabel = shipmentText[keyName]?.label;

                  return !isValueProvided && matchingLabel;
                })
                .filter((i) => {
                  return i;
                })
                .join(', ')}.`}
              position="top-end"
            >
              <ConfirmDeliveryButton />
            </Tooltip>
          </Conditional>
          <Conditional condition={canDelete}>
            <Button
              disabled={isEditingDisabled}
              icon={
                <Icon
                  color={isEditingDisabled ? 't3' : 't2'}
                  name="ui-control:x"
                />
              }
              variant="ghost"
              onClick={showDeleteModal}
            />
          </Conditional>
        </Grid>
      </Cell>
    </Conditional>
  );
};
