import styled, { css } from 'styled-components';
import { Icon, Typography } from 'gantri-components';
import { mediaQueries } from '../../constants/styles';

export const StyledFieldWrapper = styled.div`
  margin-top: 30px;
  flex: 1;
  min-width: 350px;

  & > p {
    padding-bottom: 10px;
  }

  &:not(:last-child) {
    margin-right: 30px;
  }

  ${mediaQueries.mobile(css`
    &:not(:last-child) {
      margin-right: 0;
    }
  `)}
`;

export const StyledFieldLabel = styled(Typography)`
  padding-bottom: 10px;
`;

export const StyledURLBase = styled(Typography)`
  margin-right: 10px;
`;

export const StyledThumbnailPlusIcon = styled(Icon)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

export const StyledColorInputContainer = styled.div<{ color: string }>`
  ${({ color, theme }) => {
    return css`
      position: relative;
      padding-left: 3rem;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 2rem;
        height: 2rem;
        background-color: ${color};
        border: 1px solid ${theme.colors.dividers.t1};
        border-radius: 50%;
      }
    `;
  }}
`;

export const StyledColorPopup = styled.div`
  position: absolute;
  left: 3rem;
  top: 3.5rem;
  z-index: 2;
`;

export const StyledFeatureSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`;

export const StyledFeatureContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 11rem 1fr 1fr;
  gap: 1rem;
  column-gap: 1.5rem;
  margin-bottom: 2rem;

  .feature-name {
    margin-top: 3rem !important;
    ${mediaQueries.mobile(
      css`
        margin-top: 0 !important;
      `,
    )}
  }

  > .feature-remove {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    z-index: 1;

    ${mediaQueries.mobile(
      css`
        display: block;
      `,
    )}
  }

  .feature-remove-column {
    float: right;
    ${mediaQueries.mobile(
      css`
        display: none;
      `,
    )}
  }

  ${mediaQueries.mobile(
    css`
      grid-template-columns: 1fr;
    `,
  )}
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  > p {
    margin-right: 1rem;
  }

  ${mediaQueries.mobile(
    css`
      margin-top: 2rem;
    `,
  )}
`;

export const StyleRemoveContainer = styled.div`
  position: relative;
`;
