import { Conditional, Stack, Typography } from 'gantri-components';
import { LONG_FORMAT } from '../../../../../../../../../constants/dates';
import { formatDate } from '../../../../../../../../../helpers/formatter';
import { IssueDifficulty } from '../../../../../../issue-difficulty';
import { StyledNotes } from './issue-details.styles';
import { IssueDetailsProps } from './issue-details.types';
import { StyledInlineContainer } from '../../../../../../../../../assets/styles/common';
import routePaths from '../../../../../../../../../config/route-paths';
import { useRouter } from '../../../../../../../../../hooks';

export const IssueDetails = ({ issue, onClose }: IssueDetailsProps) => {
  const { navigate } = useRouter();

  const onGoToUserDetail = (event) => {
    event.preventDefault();
    event.stopPropagation();

    onClose?.();

    navigate({
      pathname: `${routePaths.users}/${issue?.user?.id}`,
      search: '?tab=account',
    });
  };

  return (
    <Stack gap="0" marginTop="-0.2rem">
      <Typography marginBottom="0.4rem" text={issue.type} />
      <StyledInlineContainer>
        <Typography
          color="t2"
          text={formatDate(issue.createdAt, LONG_FORMAT)}
          variant="p2"
        />
        <Conditional condition={!!issue?.user}>
          <Typography color="t2" text=" by " />

          <Typography
            color="link"
            decoration="underline"
            display="inline"
            style={{ cursor: 'pointer' }}
            text={`${issue?.user?.firstName} ${issue?.user?.lastName}`}
            onClick={onGoToUserDetail}
          />
        </Conditional>
      </StyledInlineContainer>

      <IssueDifficulty issue={issue} />
      <Conditional condition={!!issue.notes}>
        <StyledNotes color="t2" text={issue.notes} />
      </Conditional>
    </Stack>
  );
};
