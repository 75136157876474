import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useAsync } from 'react-use';
import { useState } from 'react';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { StartAssembleStageJobModalProps } from './start-assemble-stage-job-modal.types';
import { AssembleStageJobContent } from './components/assemble-stage-job-content';
import { AssembleStageJobFooter } from './components/assemble-stage-job-footer';
import { JobDetailsPanelLarge } from '../../../common/job-details-panel-large';
import { startAssembleStageModalAtoms } from './start-assemble-stage-job-modal.atoms';
import { useResetRecoilAtomsOnMount } from '../../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { useNotification } from '../../../../../../../hooks/useNotification';
import { stocksApi } from '../../../../../../../api';
import { Stock } from '../../../../../../../api/stocks/stocks.types';
import { useHandleCloseModal } from '../../../hooks/use-handle-close-modal';

export const StartAssembleStageJobModal = (
  props: StartAssembleStageJobModalProps,
) => {
  const { job, onClose, onJobUpdated } = props;
  const [stock, setStock] = useState<Stock>(null);
  const step = useRecoilValue(startAssembleStageModalAtoms.step);
  const { notifyAxiosError } = useNotification();

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    startAssembleStageModalAtoms,
  );

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose: step !== 'CONFIRM_START',
  });

  useAsync(async () => {
    try {
      const data = await stocksApi.getStock({ stockId: job.stockId });

      setStock(data.stock);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch stock parts.',
      });
    }
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            sectionsToShow={[
              'stock',
              'part',
              'product',
              'instructions',
              'product photo',
            ]}
            stock={stock}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <AssembleStageJobFooter
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <AssembleStageJobContent job={job} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
