import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { maxExceededCycleTime } from '../../../complete-assembly-modal.constants';
import { AssemblyCompletedFailedFooterProps } from './assembly-completed-failed-footer.types';

export const AssemblyCompletedFailedFooter = (
  props: AssemblyCompletedFailedFooterProps,
) => {
  const { handleCloseModal } = props;

  const exceededCycleTime = useRecoilValue(
    completeAssemblyModalAtoms.exceededCycleTime,
  );
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const jobChecklist = useRecoilValue(completeAssemblyModalAtoms.jobChecklist);

  const isFailed = checkAgainstChecklistItems(jobChecklist, ({ status }) => {
    return status !== 'Pass';
  });

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={async () => {
        if (exceededCycleTime > maxExceededCycleTime) {
          setStep(
            isFailed
              ? 'JOB_FAILED_EXCEEDED_CYCLE_TIME'
              : 'JOB_PASSED_EXCEEDED_CYCLE_TIME',
          );
        } else {
          await handleCloseModal({ updateOnClose: true });
        }
      }}
    />
  );
};
