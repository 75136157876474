import { FC } from 'react';
import { Button, Conditional, Flex, Typography } from 'gantri-components';
import { EditableTextRowProps } from './editable-text-row.types';
import { StyledEditableTextRow } from './editable-text-row.styles';
import { EditableTextRowPresets } from './editable-text-row.presets';

export const EditableTextRow: FC<EditableTextRowProps> = (props) => {
  const {
    editButtonText,
    editing,
    marginBottom,
    marginTop,
    onCancel,
    onEdit,
    onSave,
    text,
  } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      marginBottom={marginBottom}
      marginTop={marginTop}
      width="100%"
      wrap="nowrap"
    >
      <Typography text={text} variant="h4" />

      <StyledEditableTextRow>
        <Conditional
          condition={!editing}
          Fallback={
            <>
              <Button
                text="Cancel"
                variant="primaryContrast"
                onClick={onCancel}
              />
              <Button
                text="Save"
                type="submit"
                variant="ghost"
                onClick={onSave}
              />
            </>
          }
        >
          <Button text={editButtonText} variant="ghost" onClick={onEdit} />
        </Conditional>
      </StyledEditableTextRow>
    </Flex>
  );
};

EditableTextRow.defaultProps = EditableTextRowPresets;
