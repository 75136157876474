import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'gantri-components';

const AutocompleteStreet = (props) => {
  const {
    classnames,
    errorMessage,
    labelText,
    name,
    onBlur,
    onChange,
    onFocus,
    onSelectAddress,
    placeholder = '',
    required,
    value = '',
  } = props;
  const placeInputRef = useRef(null);

  function pickAddress(place, type) {
    const emptyResult = {
      long_name: '',
      short_name: '',
    };
    const result = [];

    if (!place.address_components || !place.address_components.length) {
      return [emptyResult];
    }

    for (let i = 0; i < place.address_components.length; i++) {
      if (place.address_components[i].types.indexOf(type) > -1) {
        result.push(place.address_components[i]);
      }
    }

    if (!result.length) {
      return [emptyResult];
    }

    return result;
  }

  function switchAddress(autocomplete) {
    const place = autocomplete.getPlace();
    const address = {};

    address.city = pickAddress(place, 'locality')[0].long_name;
    address.state = pickAddress(
      place,
      'administrative_area_level_1',
    )[0].short_name;
    address.country = pickAddress(place, 'country')[0].long_name;
    address.zip = pickAddress(place, 'postal_code')[0].long_name;
    address.street = `${pickAddress(place, 'street_number')[0].long_name} ${
      pickAddress(place, 'route')[0].long_name
    }`;
    onSelectAddress(address);
  }

  const initPlaceAPI = () => {
    const inputRef = placeInputRef.current;

    // eslint-disable-next-line no-undef
    const autocomplete = new google.maps.places.Autocomplete(inputRef, {
      types: ['geocode'],
    });

    autocomplete.addListener('place_changed', () => {
      return switchAddress(autocomplete);
    });
  };

  useEffect(() => {
    initPlaceAPI();
  }, []);

  return (
    <TextField
      className={classnames}
      errorMessage={errorMessage}
      innerRef={placeInputRef}
      labelText={labelText}
      name={name}
      placeholder={placeholder}
      required={required}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
    />
  );
};

AutocompleteStreet.propTypes = {
  classnames: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  labelText: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSelectAddress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
};

export default AutocompleteStreet;
