import styled, { css } from 'styled-components';
import { Flex } from 'gantri-components';

export const StyledGroupHeader = styled(Flex)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      border-right: 1px solid ${theme.colors.dividers.t1};
      border-left: 1px solid ${theme.colors.dividers.t1};
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;
