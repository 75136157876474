import { Button, Modal, Typography } from 'gantri-components';
import { useBulkUpdateMachines } from '../../../../api/machines/routes';
import { ResetBuildPlateModalProps } from './reset-build-plate-modal.types';

export const ResetBuildPlateModal = (props: ResetBuildPlateModalProps) => {
  const { machineIds, onClose, onSuccess } = props;

  const { onBulkUpdateMachines } = useBulkUpdateMachines({
    onSuccess,
  });

  const onConfirm = async () => {
    await onBulkUpdateMachines({
      action: 'Reset build plate',
      ids: machineIds,
    });
    onClose();
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button size="large" text="Apply" onClick={onConfirm} />
        </>
      }
      header="Reset Build Plate(s)"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        text="Are you sure you want to reset the build plate(s) for the selected machine(s)?"
      />
    </Modal>
  );
};
