import { useQueryMutation } from '../../../../hooks/use-mutation';
import { deleteInventoryTransaction } from './delete-inventory-transaction';
import { useInvalidateGetInventoryTransactionsCache } from '../get-inventory';
import { useNotification } from '../../../../hooks/useNotification';

export const useDeleteInventoryTransactionMutation = () => {
  const invalidateCache = useInvalidateGetInventoryTransactionsCache();
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<{
    inventoryId: number;
    transactionId: number;
  }>({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred on delete inventory transaction.',
        });
      },
      onSuccess: async (data, variables) => {
        notify(data.notice);
        await invalidateCache({ inventoryId: variables.inventoryId });
      },
    },
    mutationFn: (request) => {
      return deleteInventoryTransaction(request.transactionId);
    },
    showLoading: true,
  });

  return { isLoading, onDeleteTransaction: onMutate };
};
