import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportSalesQueries } from '../../report-sales.types';

export const getGroupByIsVisible = (): boolean => {
  const queries = getActiveQueries<ReportSalesQueries>();
  const { color, country, creatorId, customerType, groupBy, productId } =
    queries;

  if (
    (/^productId$/i.test(groupBy) && productId) ||
    (/^color$/i.test(groupBy) && color) ||
    (/^creatorId$/i.test(groupBy) && creatorId) ||
    (/^customerType$/i.test(groupBy) && customerType) ||
    (/^country$/i.test(groupBy) && country)
  ) {
    return false;
  }

  return true;
};
