import { useEffect, useState } from 'react';
import { useRowSelection } from 'gantri-components';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../../../components/common/table/hooks';
import {
  pageName,
  stockSummaryFiltersDefaults,
  stockSummaryPageAtoms,
} from '../../stock-summary.atoms';
import { StockSummaryFilter } from '../../stock-summary-filter';
import {
  FetchStockSummaryArgs,
  FetchStockSummaryResponse,
  StockSummaryRecord,
  StockSummaryTotal,
} from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.types';
import {
  stockSummaryModalFiltersDetails,
  stockSummarySortOptions,
} from '../../stock-summary.constants';
import { useFetchStockSummaryQuery } from '../../../../api/stocks/routes/fetch-stock-summary/fetch-stock-summary.query';
import { useSorting } from '../../../../hooks/use-sorting';
import { getSortMapping } from '../../../../components/common/table/components/table-actions/helpers/get-sort-mapping';

export const useStockSummaryTableData = () => {
  const [filters, setFilters] = useState<FetchStockSummaryArgs>();
  const [footerData, setFooterData] = useState<StockSummaryTotal>();

  const handleFetchStockSummary = (
    args: FiltersFetchRequestArgs<
      FetchStockSummaryArgs,
      keyof StockSummaryRecord
    >,
  ) => {
    const {
      count: _count,
      page: _page,
      search: _search,
      sortingField,
      sortingType,
      ...filters
    } = args;

    setSortingField(sortingField);
    setSortingType(sortingType);
    setFilters(filters);
  };

  const pageSize = 100;

  const {
    currentPage,
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    StockSummaryRecord,
    FetchStockSummaryArgs,
    keyof StockSummaryRecord
  >({
    fetchRequest: handleFetchStockSummary,
    filtersContent: <StockSummaryFilter />,
    modalFiltersDetails: stockSummaryModalFiltersDetails,
    pageName,
    pageSize,
    sortByFilterDetails: {
      atom: stockSummaryPageAtoms.sortBy,
      defaultValue: stockSummaryFiltersDefaults.sortBy,
    },
    sortOptions: stockSummarySortOptions,
  });

  const { clearSelectedRows } = useRowSelection();

  type StockSummaryRecordWithId = StockSummaryRecord & {
    id: string;
  };

  const { data } = useFetchStockSummaryQuery<
    Omit<FetchStockSummaryResponse, 'stocks'> & {
      stocks: StockSummaryRecordWithId[];
    }
  >({
    enabled: !!filters,
    fetchArgs: filters,
    onSuccess: async (transformedData) => {
      clearSelectedRows();

      handSortAndPagination(transformedData.stocks);
    },
    showLoading: true,
    transform: (data) => {
      const updatedStocks =
        data?.stocks?.map((stock) => {
          return {
            ...stock,
            // add sku as ID for selectable rows logic
            id: stock.sku,
          };
        }) || [];

      return { ...data, stocks: updatedStocks };
    },
  });

  const handSortAndPagination = (stocks: StockSummaryRecordWithId[]) => {
    const sortedData = sortData(stocks);

    const endIndex = currentPage * pageSize;
    const startIndex = endIndex - pageSize;
    const pagedRecords = sortedData.slice(startIndex, endIndex);

    setRecords(pagedRecords);
  };

  const { setSortingField, setSortingType, sortData } = useSorting({
    data: [
      // Intentionally empty. Sorted data is being handled via sortData due to re-render issues.
    ],
    fieldsMapping: getSortMapping(stockSummarySortOptions),
    initialSortingField: stockSummaryFiltersDefaults.sortBy.sortingField,
    initialSortingType: stockSummaryFiltersDefaults.sortBy.sortingType,
  });

  useEffect(() => {
    setFooterData(data?.total);
    setTotalCount(data?.stocks?.length || 0);
  }, [data?.total, data?.stocks?.length]);

  useEffect(() => {
    if (records.length) {
      handSortAndPagination(data?.stocks || []);
    }
  }, [currentPage]);

  return {
    filtersProps,
    footerData,
    pagingProps,
    records,
    sortProps,
  };
};
