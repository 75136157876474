import { Button } from 'gantri-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { setMachinesOfflineModalAtoms } from '../../../set-machines-offline-modal.atoms';
import { useBulkUpdateMachines } from '../../../../../../../api/machines/routes';
import { ConfirmSetMachinesOfflineFooterProps } from './confirm-set-machines-offline-footer.types';
import { BulkUpdateResponse } from '../../../../../../../api/machines/routes/bulk-update/bulk-update.types';
import { setMachinesOfflineModalSteps } from '../../../set-machines-offline-modal.constants';

export const ConfirmSetMachinesOfflineFooter = (
  props: ConfirmSetMachinesOfflineFooterProps,
) => {
  const { handleCloseModal } = props;

  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    setMachinesOfflineModalAtoms.detailsPanelMachines,
  );
  const setUpdateOnClose = useSetRecoilState(
    setMachinesOfflineModalAtoms.updateOnClose,
  );
  const setMachinesOfflined = useSetRecoilState(
    setMachinesOfflineModalAtoms.machinesOfflined,
  );
  const setStep = useSetRecoilState(setMachinesOfflineModalAtoms.step);

  const onSuccess = async ({ offlined }: BulkUpdateResponse) => {
    setMachinesOfflined(offlined);

    const updatedDetailsPanelMachines = detailsPanelMachines.map(
      (oldMachine) => {
        const updatedMachine = offlined.find(({ id }) => {
          return id === oldMachine.id;
        });

        return updatedMachine || oldMachine;
      },
    );

    setDetailsPanelMachines(updatedDetailsPanelMachines);
    setUpdateOnClose(true);
    setStep(setMachinesOfflineModalSteps.reviewOfflinedMachines);
  };

  const { isLoading, onBulkUpdateMachines } = useBulkUpdateMachines({
    onSuccess,
  });

  const onConfirm = async () => {
    const machineIds = detailsPanelMachines.map(({ id }) => {
      return id;
    });

    const { offlined } = await onBulkUpdateMachines({
      action: 'Set offline',
      ids: machineIds,
    });

    setMachinesOfflined(offlined);

    const updatedDetailsPanelMachines = detailsPanelMachines.map(
      (oldMachine) => {
        const updatedMachine = offlined.find(({ id }) => {
          return id === oldMachine.id;
        });

        return updatedMachine || oldMachine;
      },
    );

    setDetailsPanelMachines(updatedDetailsPanelMachines);
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Confirm"
        onClick={onConfirm}
      />
    </>
  );
};
