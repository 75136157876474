import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SubmitRecommendedNextStepButton } from '../../../../../common/submit-recommended-next-step-button';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { RecommendedNextStepFooterProps } from './recommended-next-step-footer.types';

export const RecommendedNextStepFooter = (
  props: RecommendedNextStepFooterProps,
) => {
  const { job } = props;

  const setNewParts = useSetRecoilState(completeSandJobModalAtoms.newParts);
  const setUpdateOnClose = useSetRecoilState(
    completeSandJobModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);
  const nextStepDetails = useRecoilValue(
    completeSandJobModalAtoms.nextStepDetails,
  );
  const nextStep = useRecoilValue(completeSandJobModalAtoms.nextStep);
  const checklist = useRecoilValue(completeSandJobModalAtoms.checklist);

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('COMPLETE_CHECKLIST');
        }}
      />
      <SubmitRecommendedNextStepButton
        checklist={checklist}
        jobId={job.id}
        nextStepDetails={nextStepDetails}
        partId={job.part.id}
        selectedNextStep={nextStep}
        setDuplicatedJobs={() => {}}
        setNewParts={setNewParts}
        setStep={setStep}
        setUpdateOnClose={setUpdateOnClose}
        size={fullScreenModalFooterButtonSize}
      />
    </>
  );
};
