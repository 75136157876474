import { checkAgainstFailingChecklistItems } from '../../../../../../../../../helpers/check-against-failing-checklist-items';
import { FinishingQaFailedNextStep } from '../../../../../../../complete-finishing-qa-modal.types';
import { GetRecommendedStepArgs } from './get-recommended-step.types';

export const getRecommendedStep = (
  args: GetRecommendedStepArgs,
): FinishingQaFailedNextStep => {
  const { checklist, isAtMaxFinishAttempts } = args;

  const shouldRestartPart =
    isAtMaxFinishAttempts ||
    checkAgainstFailingChecklistItems(
      checklist,
      // any restart recommendation overrides refinish
      (item) => {
        return item.failStatusRecommendation === 'Restart';
      },
    );

  if (shouldRestartPart) {
    return 'JOB_FAILED_RESTART';
  }

  const isCompleteRework = checkAgainstFailingChecklistItems(
    checklist,
    (checklistItem) => {
      return checklistItem.failStatusRecommendation === 'Finish_Complete';
    },
  );

  if (isCompleteRework) {
    return 'JOB_FAILED_REFINISH_COMPLETE_REWORK';
  }

  const isTargetedRework = checkAgainstFailingChecklistItems(
    checklist,
    (checklistItem) => {
      return checklistItem.failStatusRecommendation === 'Finish_Targeted';
    },
  );

  if (isTargetedRework) {
    return 'JOB_FAILED_REFINISH_TARGETED_REWORK';
  }

  const isPaintOnly = checkAgainstFailingChecklistItems(
    checklist,
    (checklistItem) => {
      return checklistItem.failStatusRecommendation === 'Finish_Paint';
    },
  );

  if (isPaintOnly) {
    return 'JOB_FAILED_REFINISH_PAINT_ONLY';
  }

  return 'JOB_FAILED_RESTART';
};
