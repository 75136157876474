import React, { FC, useMemo } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Icon,
  Typography,
  useModal,
} from 'gantri-components';
import pick from 'lodash/pick';
import { EditShippingModal } from '..';
import { orderStatusesForShipChange } from './shipping.constants';
import { ShippingProps } from './shipping.types';
import { useNotification } from '../../../../hooks/useNotification';
import {
  canadianShippingMethods,
  defaultCanadianShippingMethod,
  defaultUSShippingMethod,
  ShippingMethod,
  usShippingMethods,
} from '../../../../constants/options';
import { transactionsApi } from '../../../../api';
import { CurrentOrderData } from '../../../../types/store';
import { UpdateShippingMethodArgs } from '../../../../api/transactions/routes/update-shipping-method/update-shipping-method.types';
import { getFilenameFromURL } from '../../../../helpers/path-utils';

export const Shipping: FC<ShippingProps> = ({
  currentOrderData,
  setCurrentOrderData,
}) => {
  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { shipments = [], status: orderStatus } = currentOrderData || {};
  const [firstShipment] = shipments ?? [];
  const country = currentOrderData?.address?.country;
  const shippingOptions = firstShipment?.shippingOptions;
  const shippingType = shippingOptions?.type;
  const instructionLocation = shippingOptions?.locationInstruction;

  const instructionLocationFileName = useMemo(() => {
    if (!instructionLocation) return '';

    return getFilenameFromURL(instructionLocation);
  }, [instructionLocation]);

  const option = useMemo(() => {
    if (country === 'United States' || country === 'US') {
      return usShippingMethods.find((item) => {
        return item.shippingType === firstShipment?.shippingOptions?.type;
      });
    }

    if (country === 'Canada' || country === 'CA') {
      return canadianShippingMethods.find((item) => {
        return item.shippingType === firstShipment?.shippingOptions?.type;
      });
    }

    return null;
  }, [country, firstShipment?.shippingOptions?.type]);

  const defaultShippingOption =
    country === 'United States' || country === 'US'
      ? defaultUSShippingMethod
      : defaultCanadianShippingMethod;

  const onConfirm = async (option: ShippingMethod) => {
    await onInterceptProcessingRequest(async () => {
      try {
        let order: CurrentOrderData;

        // replace with Promise.settledAll once the backend supports the concurrency
        // replace with react-query once the backend supports the set of all shipments in one request
        for (const { id: shipmentId } of shipments ?? []) {
          const response = await transactionsApi.updateShippingMethod({
            shipmentId,
            ...pick(option, ['shippingType', 'provider']),
          } as UpdateShippingMethodArgs);

          const { order: updatedOrder, success } = response.data;

          order = updatedOrder;
        }

        setCurrentOrderData(order);
        notify('Shipping method has been updated');
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred Updating Shipping.',
        });
      } finally {
        hideModal();
      }
    });
  };

  const [showModal, hideModal] = useModal(() => {
    return (
      <EditShippingModal
        address={currentOrderData.address}
        handleConfirm={onConfirm}
        processing={processing}
        value={option ?? defaultShippingOption}
        onClose={hideModal}
      />
    );
  }, [processing, currentOrderData.address]);

  const showEditButton = useMemo(() => {
    return (
      shippingType !== 'other' &&
      orderStatusesForShipChange.some((status) => {
        return status === orderStatus;
      })
    );
  }, [orderStatus, shippingType]);

  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <Conditional
        condition={shippingType !== 'other'}
        Fallback={
          <Flex direction="column">
            <Typography text="Shipping Information" />
            <Typography
              color="link"
              decoration="underline"
              icon={<Icon color="link" name="arrows:arrow_download" />}
              iconPosition="left"
              text={instructionLocationFileName}
              onClick={() => {
                window.open(instructionLocation, '_blank');
              }}
            />
          </Flex>
        }
      >
        <Typography text={option?.label} />
      </Conditional>

      <Conditional condition={showEditButton}>
        <Button text="Change" variant="ghost" onClick={showModal} />
      </Conditional>
    </Flex>
  );
};
