import {
  InventoryForJobs,
  useFetchAllInventoriesForJobsQuery,
} from '../../../api/inventories/routes/fetch-all-for-jobs';

export const useInventoriesForJobs = (limitToTypes?: string[]) => {
  const { inventories, isLoading } = useFetchAllInventoriesForJobsQuery<
    InventoryForJobs[]
  >({
    transform: (response) => {
      return (
        response.inventories?.filter(({ status, type }) => {
          return (
            status === 'Active' &&
            (!limitToTypes || limitToTypes.includes(type))
          );
        }) ?? []
      );
    },
    transformDependencies: limitToTypes,
  });

  return {
    inventories: inventories ?? [],
    isLoading,
  };
};
