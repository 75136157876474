import styled, { css, keyframes } from 'styled-components';
import { getThemeColor } from 'gantri-components';

const spinny = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledSpinnerWrapper = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      width: ${({ size }) => {
        return size;
      }};
      height: ${({ size }) => {
        return size;
      }};
      border-radius: 50%;
      border: ${({ lineSize }) => {
          return lineSize;
        }}
        solid
        ${({ lineBG }) => {
          return getThemeColor(lineBG, theme);
        }};

      &::after {
        position: absolute;
        top: -${({ lineSize }) => {
            return lineSize;
          }};
        left: -${({ lineSize }) => {
            return lineSize;
          }};
        right: -${({ lineSize }) => {
            return lineSize;
          }};
        bottom: -${({ lineSize }) => {
            return lineSize;
          }};
        content: '';
        border: ${({ lineSize }) => {
            return lineSize;
          }}
          solid
          ${({ spinnerColor }) => {
            return getThemeColor(spinnerColor, theme);
          }};
        border-color: ${({ spinnerColor }) => {
            return getThemeColor(spinnerColor, theme);
          }}
          transparent transparent transparent;
        border-radius: 50%;
        animation: ${({ timing }) => {
            return timing;
          }}
          ${spinny} linear infinite;
      }
    `;
  }}
`;
