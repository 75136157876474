export const exceededCycleTimeOptionLabels = [
  'Fixed bad finishing',
  'Fixed loose part or bad fit',
  'Fixed error that occurred during assembly',
  'Encountered staging issue (wrong part or lost part, etc.)',
  'Encountered instructions issue',
  'Work break occurred during job',
  'Other',
] as const;

export const exceededCycleTimeOptions = exceededCycleTimeOptionLabels.map(
  (label) => {
    return { label, value: label === 'Other' ? '' : label };
  },
);

export const exceededCycleTimeText = {
  placeholderNotes: 'Add additional details...',
  placeholderOther: 'Describe a reason not listed above...',
  textJobTimeExceeded:
    'The job time has exceeded the expected time. Please select the highest contributing reason below:',
  textNotes: 'Add additional details for reason selected above:',
  title: 'Exceeded Cycle Time',
} as const;
