import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { StyledThumbnailCell } from '../../assets/styles/common';
import Thumbnail from '../../components/common/thumbnail/thumbnail';
import { formatDate } from '../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../constants/dates';
import { Download } from './downloads.types';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { MoreMenu } from '../../components/dropdowns';
import { useDownloadModal } from './download-modal/use-download-modal';
import { useDeleteDownload } from './hooks/use-delete-download';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';
import { useDownloadWithSmallToast } from '../../hooks/use-download-with-small-toast';
import { downloadToastText } from '../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';

export const useGetDownloadsColumns = ({
  fetchPaginatedDownloads,
}: {
  fetchPaginatedDownloads: () => Promise<void>;
}) => {
  const showEditDownloadModal = useDownloadModal({
    onComplete: () => {
      return fetchPaginatedDownloads();
    },
  });

  const { onShowConfirm } = useDeleteDownload({
    onComplete: () => {
      return fetchPaginatedDownloads();
    },
  });

  const { DownloadStatusToast, downloadSingleFile } = useDownloadWithSmallToast(
    {
      ...downloadToastText.file,
    },
  );

  const columns: ColumnDef<Download>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        cell: ({ getValue, row }: CellContext<Download, Download['name']>) => {
          return (
            <StyledThumbnailCell>
              <Thumbnail size="6rem" src={row.original.thumbnail} />
              {getValue()}
            </StyledThumbnailCell>
          );
        },
        header: 'Name',
        minSize: 200,
      },
      {
        accessorKey: 'description',
        header: 'Description',
        minSize: 300,
      },
      {
        accessorKey: 'type',
        cell: ({ getValue }: CellContext<Download, Download['type']>) => {
          return <Typography text={capitalize(getValue())} />;
        },
        header: 'Type',
      },
      {
        accessorKey: 'createdAt',
        cell: ({ getValue }: CellContext<Download, Download['createdAt']>) => {
          return formatDate(getValue(), MEDIUM_FORMAT);
        },
        header: 'Create Date',
        minSize: 84,
      },
      {
        accessorKey: 'fileName',
        cell: ({ row }: CellContext<Download, Download['fileName']>) => {
          const { fileName } = row.original;

          return <StyledAnchor text={fileName} />;
        },
        header: 'Download',
        meta: {
          getIsClickable: getIsClickableIfValue,
          getOnClick: ({
            getValue,
            row,
          }: Cell<Download, Download['fileName']>) => {
            const fileName = getValue();
            const { link } = row.original;
            const isClickable = !!link;

            return isClickable
              ? () => {
                  return downloadSingleFile({
                    fileName,
                    url: link,
                  });
                }
              : undefined;
          },
        },
      },
      {
        cell: ({ row }: CellContext<Download, never>) => {
          return (
            <MoreMenu
              data={row.original}
              options={[
                {
                  enabled: true,
                  name: 'Edit',
                  onOptionClick: (record: Download) => {
                    showEditDownloadModal(record);
                  },
                },
                {
                  enabled: true,
                  name: 'Delete',
                  onOptionClick: (record: Download) => {
                    return onShowConfirm(record.id);
                  },
                },
                {
                  name: 'Copy download URL',
                  onOptionClick: async (record: Download) => {
                    await navigator.clipboard.writeText(record.link);
                  },
                },
              ]}
            />
          );
        },
        id: 'menu',
        size: 30,
      },
    ];
  }, [fetchPaginatedDownloads]);

  return { DownloadStatusToast, columns };
};
