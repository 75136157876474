import { OrderFilterProps } from './order-filter.types';
import { OrdersSearchInput } from '../../../components/orders-search-input';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { FilterRow } from '../../../../common/filter-grid';

export const OrderFilter = (props: OrderFilterProps) => {
  const { atom, default: defaultValue } = props;

  return (
    <FilterRow>
      <FilterResetLabel atom={atom} default={defaultValue} text="Order #" />
      <OrdersSearchInput atom={atom} />
    </FilterRow>
  );
};
