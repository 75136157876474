import {
  Button,
  Cell,
  Dropdown,
  FormikInput,
  Icon,
  Modal,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { IconType } from 'gantri-components/dist/components/icon/icon.type';
import { Field, Form, Formik } from 'formik';
import { machineIssueOptions } from '../../../../constants/options';
import { EditIssueProps } from './edit-machine-issue-modal.types';
import { useEditMachineIssue } from '../../../../api/machines/routes';
import { Issue } from '../../../../api/machines/routes/get-issues/get-issues.types';

export const EditMachineIssueModal = (props: EditIssueProps) => {
  const { issueSelected, machineId, onClose, onSuccess } = props;

  const { onEditIssue } = useEditMachineIssue({
    onSuccess: async () => {
      await onSuccess();

      onClose();
    },
  });

  const onSubmit = async (values: Issue) => {
    await onEditIssue({ ...values, machineId });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...issueSelected }}
      onSubmit={onSubmit}
    >
      {({ setFieldValue }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button size="large" text="Confirm" type="submit" />
                </>
              }
              header={
                <Typography
                  align="center"
                  text="Edit Machine Issue"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width="44rem"
              onClose={onClose}
            >
              <Stack>
                <Field name="type">
                  {({ field }) => {
                    const selectedIssueType = machineIssueOptions.find(
                      (item) => {
                        return item.type === field.value;
                      },
                    );

                    return (
                      <Cell>
                        <Dropdown
                          disabled={!selectedIssueType}
                          idProperty="type"
                          items={machineIssueOptions}
                          labelProperty="type"
                          maxHeight="38rem"
                          placeholder={field.value || 'Select type'}
                          onSelect={(type) => {
                            setFieldValue('difficulty', type.difficulty);
                          }}
                          {...field}
                        />

                        {selectedIssueType && selectedIssueType.difficulty && (
                          <Typography
                            icon={
                              <Icon
                                color="link"
                                name={
                                  `machine:difficulty_${selectedIssueType.difficulty?.toLowerCase()}` as IconType
                                }
                              />
                            }
                            marginTop="10px"
                            text={selectedIssueType.difficulty}
                          />
                        )}
                      </Cell>
                    );
                  }}
                </Field>

                <FormikInput
                  Field={<TextArea ariaLabel="notes" minRows={4} />}
                  labelText="Notes"
                  name="notes"
                />
              </Stack>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
