import { FC, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useClickAway, useToggle } from 'react-use';
import { TextField, Typography, Cell } from 'gantri-components';
import {
  StyledColorInputContainer,
  StyledColorPopup,
} from '../../../../../../designer-styles';
import { ColorInputCellProps } from './color-input-cell.types';

export const ColorInputCell: FC<ColorInputCellProps> = ({
  color,
  label,
  setColor,
  ...cellProps
}) => {
  const [colorPickerIsVisible, toggleColorPickerIsVisible] = useToggle(false);
  const popupRef = useRef();

  useClickAway(popupRef, toggleColorPickerIsVisible);

  return (
    <Cell {...cellProps}>
      <Typography
        marginBottom="1rem"
        text={label}
        textStyle="bold"
        variant="p2"
      />
      <StyledColorInputContainer color={color}>
        <TextField
          value={color}
          onFocus={toggleColorPickerIsVisible}
          onTextChange={setColor}
        />

        {!!colorPickerIsVisible && (
          <StyledColorPopup ref={popupRef}>
            <ChromePicker
              color={color}
              disableAlpha
              onChangeComplete={(values) => {
                return setColor(values.hex);
              }}
            />
          </StyledColorPopup>
        )}
      </StyledColorInputContainer>
    </Cell>
  );
};
