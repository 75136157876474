import { LocationProps } from './location.types';

export const Location = ({ designerInfo }: LocationProps) => {
  const { city, country, state } = designerInfo;

  return (
    <p>
      <span>{city}</span>, <span>{country === 'US' ? state : country}</span>
    </p>
  );
};
