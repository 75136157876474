import {
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { formatDate } from '../../../../helpers/formatter';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { useRouter } from '../../../../hooks';
import { Divider } from '../../../../components/divider';
import { Label } from '../../../../components/label';

export const Overview = ({ design }: { design: Design }) => {
  const conceptActionDate =
    design.concept.approvedAt || design.concept.completedAt;
  const conceptStatus = `${design.concept.status} ${
    conceptActionDate ? formatDate(conceptActionDate) : ''
  }`;

  const { navigate } = useRouter();

  const modelActionDate =
    design.model.status === 'Approved'
      ? design.model.approvedAt
      : design.model.completedAt;

  const modelStatus = `${design.model.status} ${
    modelActionDate ? formatDate(modelActionDate) : ''
  }`;

  const engineeringStatus = `${design.engineering.status} ${
    design.engineering.completedAt
      ? formatDate(design.engineering.completedAt)
      : ''
  }`;

  const failedStatus = `Failed ${
    design.failedAt ? formatDate(design.failedAt) : ''
  }`;

  const engineeringButtonText =
    design.engineering.status === 'Completed'
      ? 'View'
      : design.engineering.stepProgress
      ? 'Proceed'
      : 'Start';

  const contentStatus = `${design.content.status} ${
    design.content.completedAt ? formatDate(design.content.completedAt) : ''
  }`;

  const contentButtonText =
    design.content.status === 'Completed'
      ? 'View'
      : design.content.stepProgress
      ? 'Proceed'
      : 'Start';

  return (
    <Stack gap="3x">
      <Typography text="Overview" textStyle="bold" variant="h4" />

      <Divider />

      <Grid alignItems="center" columns="2fr 1fr">
        <Stack gap="x">
          <Label text="Step 1: Concept" />
          <StepStatus
            statusText={conceptStatus}
            stepStatus={design.concept.status}
          />
        </Stack>

        <Flex alignItems="center" justifyContent="flex-end">
          <Button
            text={design.concept.status === 'Submitted' ? 'Review' : 'View'}
            variant={
              design.concept.status === 'Approved' ? 'secondary' : 'primary'
            }
            onClick={() => {
              return navigate(`/designs/${design.id}/concept`);
            }}
          />
        </Flex>
      </Grid>

      <Divider />

      <Grid columns="2fr 1fr">
        <Stack gap="x">
          <Label text="Step 2: Model" />
          <StepStatus
            statusText={modelStatus}
            stepStatus={design.model.status}
          />
        </Stack>
        {design.concept.status === 'Approved' &&
          design.model.status !== 'Waiting' && (
            <Flex alignItems="center" justifyContent="flex-end">
              <Button
                text={design.model.status === 'Submitted' ? 'Review' : 'View'}
                variant={
                  design.model.status === 'Approved' ? 'secondary' : 'primary'
                }
                onClick={() => {
                  return navigate(`/designs/${design.id}/model`);
                }}
              />
            </Flex>
          )}
      </Grid>

      <Divider />

      <Grid columns="2fr 1fr">
        <Stack gap="x">
          <Label text="Step 3: Engineering" />
          <StepStatus
            statusText={design.failedAt ? failedStatus : engineeringStatus}
            stepStatus={design.engineering.status}
          />
        </Stack>

        {design.model.status === 'Approved' && !design.failedAt && (
          <Flex alignItems="center" justifyContent="flex-end">
            <Button
              text={engineeringButtonText}
              variant={
                design.engineering.status === 'Completed'
                  ? 'secondary'
                  : 'primary'
              }
              onClick={() => {
                return navigate(`/designs/${design.id}/engineering`);
              }}
            />
          </Flex>
        )}
      </Grid>

      <Divider />

      <Grid columns="2fr 1fr">
        <Stack gap="x">
          <Label text="Step 4: Content" />
          <StepStatus
            statusText={contentStatus}
            stepStatus={design.content.status}
          />
        </Stack>

        {design.engineering.status === 'Completed' && (
          <Flex alignItems="center" justifyContent="flex-end">
            <Button
              text={contentButtonText}
              variant={
                design.content.status === 'Completed' ? 'secondary' : 'primary'
              }
              onClick={() => {
                return navigate(`/designs/${design.id}/content`);
              }}
            />
          </Flex>
        )}
      </Grid>

      <Divider />
    </Stack>
  );
};

export const StepStatus = ({
  statusText,
  stepStatus,
}: {
  statusText: string;
  stepStatus: string;
}) => {
  const isApproved = /(completed)|(approved)/i.test(stepStatus);
  const isWaiting = /waiting/i.test(stepStatus);

  return (
    <Conditional
      condition={isWaiting}
      Fallback={
        <Conditional
          condition={isApproved}
          Fallback={
            <Flex alignItems="center" gap="x">
              <Icon
                color="alert"
                name="ui-control:exclamation_circle_filled"
                size="2.4rem"
              />
              <Typography color="alert" text={statusText} />
            </Flex>
          }
        >
          <Flex alignItems="center" gap="x">
            <Icon
              color="success"
              name="ui-control:check_mark_circle_filled_24"
              size="2.4rem"
            />
            <Typography color="link" text={statusText} />
          </Flex>
        </Conditional>
      }
    >
      <Typography color="t1" text={statusText} />
    </Conditional>
  );
};
