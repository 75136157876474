import { useRecoilValue } from 'recoil';
import { FinishingQaContentProps } from './finishing-qa-content.types';
import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { ChecklistContent } from '../step-checklist/checklist-content';
import { JobFailedRecommendedNextStepContent } from '../step-job-failed-recommended-next-step/job-failed-recommended-next-step-content';
import { JobFailedRefinishPartContent } from '../step-job-failed-refinish-part/job-failed-refinish-part-content';
import { finishingQcModalAtoms } from '../../complete-finishing-qa-modal.atoms';
import { JobFailedRestartPartContent } from '../step-job-failed-restart-part/step-job-failed-restart-part-content';
import { JobFailedUpdateBatchContent } from '../step-job-failed-update-batch/job-failed-update-batch-content';

export const FinishingQaContent = (props: FinishingQaContentProps) => {
  const { job } = props;
  const { part } = job;
  const { batchId, material } = part;

  const step = useRecoilValue(finishingQcModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return <ConfirmBeginContent />;

    case 'CHECKLIST':
      return <ChecklistContent material={material} />;

    case 'JOB_FAILED_NEXT_STEP':
      return <JobFailedRecommendedNextStepContent />;

    case 'JOB_FAILED_UPDATE_BATCH':
      return <JobFailedUpdateBatchContent batchId={batchId} />;

    case 'JOB_FAILED_REFINISH_PAINT_ONLY':
    case 'JOB_FAILED_REFINISH_TARGETED_REWORK':
    case 'JOB_FAILED_REFINISH_COMPLETE_REWORK':
      return <JobFailedRefinishPartContent />;

    case 'JOB_FAILED_RESTART':
      return <JobFailedRestartPartContent part={part} />;

    default:
      return null;
  }
};
