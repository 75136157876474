import { getTypeColumnHeading } from '../get-type-column-heading';
import {
  ReportJobsQueries,
  ReportJobsFailuresTableData,
  ReportJobsTableData,
} from '../../report-jobs.types';
import {
  getJobsBaseTableColumns,
  getJobsBaseSortingOptions,
  reportJobFailReasonsTableColumns,
  reportJobFailReasonsSortingOptions,
  defaultJobsGroupBy,
} from '../../report-jobs.constants';
import { GroupByItem, ReportDetails } from './get-report-details.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { getGroupByIsVisible } from '../get-group-by-is-visible';
import { ReportJobsGroupBy } from '../../../../../../api/dashboard/routes/get-report-jobs/get-report-jobs.types';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const { groupBy = defaultJobsGroupBy, jobType } =
    getActiveQueries<ReportJobsQueries>();
  const heading = getTypeColumnHeading();

  const isIssuesPage = !!groupBy && !!jobType;

  const restTableColumns = isIssuesPage
    ? reportJobFailReasonsTableColumns
    : getJobsBaseTableColumns({ groupBy });

  const tableColumns: (
    | DownloadableTableColumn<ReportJobsTableData>
    | DownloadableTableColumn<ReportJobsFailuresTableData>
  )[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...restTableColumns,
  ];

  const restSortingOptions = isIssuesPage
    ? reportJobFailReasonsSortingOptions
    : getJobsBaseSortingOptions({ groupBy });

  const sortingOptions: (
    | SortOptionFromTable<ReportJobsTableData>
    | SortOptionFromTable<ReportJobsFailuresTableData>
  )[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...restSortingOptions,
  ];

  const groupByItems: GroupByItem[] = [
    {
      label: 'Product Name',
      value: 'productName',
    },
    {
      label: 'Job Type',
      value: 'jobType',
    },
    {
      label: 'Worker Name',
      value: 'workerName',
    },
    {
      label: 'Machine Type',
      value: 'machineType',
    },
  ];

  const groupByOnChange = (groupBy: ReportJobsGroupBy): void => {
    const windowLocation = window.location.origin + window.location.pathname;
    const queriesString = convertQueriesToString({ groupBy });

    window.location.href = windowLocation + queriesString;
  };

  const groupByIsVisible = getGroupByIsVisible();

  return {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  };
};
