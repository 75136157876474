export const actionTypes = {
  completeRefund: 'Complete Refund',
  doNotShip: 'Do not ship',
  resendConfirmationEmail: 'resendConfirmationEmail',
  ship: 'Ship order',
};

export const modalMessages = {
  completeRefund:
    "You're about to refund a product: Are you sure you want to continue?",
  doNotShip: 'Please confirm shipment delivery',
  resendConfirmationEmail: 'Are you sure you want to resend this email?',
  ship: 'Ship order',
  shipWithGift: 'Ship gift order',
};

export const modalDescriptions = {
  ship: 'Confirm shipment transaction and shipping option.',
  shipWithGift: 'Confirm the following and shipping option.',
};
