import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdatePriorityArgs,
  UpdatePriorityResponse,
} from './update-priority.types';

export const updatePriority = ({ priority, stockId }: UpdatePriorityArgs) => {
  return axios.put<UpdatePriorityResponse>(
    `${coreApiUrl}/stocks/${stockId}/update-priority`,
    { priority },
  );
};
