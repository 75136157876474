import { CSSProperties, FC, PropsWithChildren } from 'react';

export interface SelectProps {
  classnames?: string;
  id?: any;
  onChange: (value: any) => void;
  style?: CSSProperties;
  value: any;
}

/**
 * @deprecated replace with Dropdown from gantri-components (https://components.gantri.com/?path=/story/core-dropdown--primary)
 */
const Select: FC<PropsWithChildren<SelectProps>> = ({
  children,
  classnames,
  id,
  onChange,
  style,
  value,
}) => {
  const handleChange = (e) => {
    e.stopPropagation();

    return !!onChange && onChange(e.target.value);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <select
      className={classnames}
      id={id}
      style={{
        ...style,
        minWidth: style?.minWidth ?? 'fit-content',
      }}
      value={value || ''}
      onChange={handleChange}
      onClick={handleClick}
    >
      {children}
    </select>
  );
};

export default Select;
