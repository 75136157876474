import * as yup from 'yup';
import {
  required,
  validateRequiredNumber,
} from '../../../../helpers/validators';

export const InventoryCheckoutSchema = yup.object().shape({
  inventories: yup
    .array()
    .min(1)
    .of(
      yup.object().shape({
        inventory: required(),
        quantity: validateRequiredNumber().test(
          'exists',
          'Invalid number',
          (value, { path, schema }) => {
            if (schema.spec.nullable && value === null) {
              return true;
            }

            return +value > 0;
          },
        ),
      }),
    ),

  type: required(),
});
