import { v4 as uuidv4 } from 'uuid';
import { Button, Icon, Stack } from 'gantri-components';
import { InventoryFormProps } from './inventory-form.types';
import { InventoryFormItem } from './components/inventory-form-item';
import { useInventoriesForJobs } from './use-inventories-for-jobs';

export const InventoryForm = (props: InventoryFormProps) => {
  const {
    hasError,
    hideExceedsStockIcon,
    inventoryRequests,
    large,
    limitToTypes,
    onEdit,
  } = props;

  const { inventories, isLoading } = useInventoriesForJobs(limitToTypes);

  const addNewInventory = () => {
    const tempInventoryId = uuidv4();

    onEdit([
      ...inventoryRequests,
      {
        id: uuidv4(),
        inventory: {
          id: tempInventoryId,
          name: null,
          status: 'Active',
          type: null,
          unit: null,
        },
        inventoryId: tempInventoryId,
        need: 1,
      },
    ]);
  };

  return (
    <Stack gap="0.4rem" height="unset" paddingTop={large ? '1rem' : undefined}>
      {inventoryRequests.map(
        ({ id: inventoryRequestId, inventory, need }, index) => {
          return (
            <InventoryFormItem
              key={inventoryRequestId || index}
              allInventories={inventories}
              hideExceedsStockIcon={hideExceedsStockIcon}
              inventory={inventory}
              inventoryRequests={inventoryRequests}
              isFetching={isLoading}
              large={large}
              need={need}
              onEdit={onEdit}
            />
          );
        },
      )}
      <div>
        <Button
          icon={
            <Icon color={hasError ? 'white' : 'link'} name="ui-control:plus" />
          }
          processing={isLoading}
          text="Add Inventory"
          variant={hasError ? 'primaryAlert' : 'ghost'}
          onClick={addNewInventory}
        />
      </div>
    </Stack>
  );
};
