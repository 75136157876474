import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateMachineArgs,
  CreateMachineResponse,
} from './create-machine.types';

export const createMachine = (args: CreateMachineArgs) => {
  const { active, location, name, status, type } = args;

  return axios
    .post<CreateMachineResponse>(`${coreApiUrl}/machines`, {
      active,
      location,
      name,
      status,
      type,
    })
    .then(({ data }) => {
      return data;
    });
};
