import { productLightCategories } from '../../../api/products/products.constants';
import { designStatuses, designSteps } from '../../../constants/options';

export const designsFilterCategoriesOptions = Object.values(
  productLightCategories,
);

export const designsFilterStepsOptions = Object.values(designSteps);

export const designsFilterStatusesOptions = Object.values(designStatuses);
