import { Button, Modal, Typography } from 'gantri-components';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { DeleteJobTemplateModalProps } from './delete-job-template-modal.types';

export const DeleteJobTemplateModal = (props: DeleteJobTemplateModalProps) => {
  const {
    cachedJobTemplates,
    jobTemplate,
    onClose,
    onSaveOverride,
    refetchProduct,
  } = props;

  const { notifyError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { id: jobTemplateId, step, type } = jobTemplate;

  const handleDeleteJob = async () => {
    await onInterceptProcessingRequest(async () => {
      const fallbackErrorMessage = 'Unable to delete job template.';

      try {
        const updatedJobTemplates = cachedJobTemplates.filter(({ id }) => {
          return id !== jobTemplateId;
        });

        onSaveOverride(updatedJobTemplates);

        await refetchProduct?.();

        onClose();
      } catch {
        notifyError(fallbackErrorMessage);
      }
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="No"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={processing}
            size="large"
            text="Yes"
            variant="primaryAlert"
            onClick={handleDeleteJob}
          />
        </>
      }
      header="Delete"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        marginBottom="2rem"
        text={`Are you sure you want to delete this ${step} ${type} job?`}
      />
    </Modal>
  );
};
