import { Checkbox, Conditional, Stack, Typography } from 'gantri-components';
import { v4 as uuidv4 } from 'uuid';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { InventoryRequest } from '../../../../../../../../../../api/jobs/jobs.types';
import { JobDetails } from '../../../../../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { useGetInventoryInfoQuery } from '../../../../../../../../../../api/inventories/routes/get-inventory';
import { RadioButton } from '../../../../../../../../../../components/common/radio-button';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startPrintPrintJobModalAtoms } from '../../../start-print-job-modal.atoms';
import { SpoolChanged } from '../../../start-print-job-modal.types';

export const getTransactionText = (inventoryRequests: InventoryRequest[]) => {
  const [firstTransaction] = inventoryRequests;

  if (!firstTransaction) {
    return '';
  }

  const { inventory, need } = firstTransaction;
  const { id, name, unit } = inventory;

  return `A transaction of ${need} ${unit.toLowerCase()} of Inventory #${id}: ${name} will be recorded.`;
};

export const materialInventoryIds = {
  opaque: 10179,
  translucent: 10178,
} as const;

export const MachineTasksContent = (props: { job: JobDetails }) => {
  const { job } = props;
  const { material } = job.part;
  const isOpaqueMaterial = material === 'Opaque GPP';

  const buildPlateNeedsChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );
  const [buildPlateChanged, setBuildPlateChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.buildPlateChanged,
  );
  const [spoolChanged, setSpoolChanged] = useRecoilState(
    startPrintPrintJobModalAtoms.spoolChanged,
  );
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const isNewSpool = spoolChanged === 'YES_NEW';
  const spoolChangeOptions: {
    groupValue: SpoolChanged;
    labelHtmlText: string;
  }[] = [
    {
      groupValue: 'NO_CHANGE',
      labelHtmlText: 'No spool change',
    },
    {
      groupValue: 'YES_USED',
      labelHtmlText: 'Yes, <strong>used</strong> spool added',
    },
    {
      groupValue: 'YES_NEW',
      labelHtmlText: 'Yes, <strong>new</strong> spool added',
    },
  ];

  const { inventory, isLoading } = useGetInventoryInfoQuery({
    enabled: isNewSpool,
    id: isOpaqueMaterial
      ? materialInventoryIds.opaque
      : materialInventoryIds.translucent,
  });

  useEffect(() => {
    if (isNewSpool && inventory?.inventory) {
      const inventoryRequests: InventoryRequest[] = [
        {
          discarded: 0,
          id: uuidv4(),
          inventory: {
            ...inventory?.inventory,
            stock: Number(inventory?.inventory?.stock || null),
          },
          inventoryId: inventory?.inventory?.id,
          need: isOpaqueMaterial ? 2.5 : 5,
        },
      ];

      setInventoryRequests(inventoryRequests);
    } else {
      setInventoryRequests([]);
    }
  }, [spoolChanged, inventory?.inventory]);

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Machine tasks" />
      <Stack gap="5x">
        <Conditional condition={buildPlateNeedsChanged}>
          <Stack gap="x">
            <Typography text="Change build plate" variant="h5" />
            <Typography text="This machine has completed 20 prints. Please change build plate to ensure print adhesion." />
            <Checkbox
              aria-label="Build plate has been changed."
              checked={buildPlateChanged}
              labelText="Build plate has been changed."
              onSelected={setBuildPlateChanged}
            />
          </Stack>
        </Conditional>
        <Stack gap="x">
          <Typography text="Spool Change" variant="h5" />
          <Typography text="Did you perform a spool change to start print on this machine?" />
          {spoolChangeOptions.map(({ groupValue, labelHtmlText }) => {
            return (
              <RadioButton
                key={groupValue}
                checked={groupValue === spoolChanged}
                groupValue={groupValue}
                labelHtmlText={labelHtmlText}
                name={groupValue}
                value={spoolChanged}
                onSelected={(value) => {
                  setSpoolChanged(value);
                }}
              />
            );
          })}
        </Stack>
        <Conditional condition={isNewSpool && !isLoading}>
          <Stack gap=".5x">
            <Typography text="New spool inventory" variant="h6" />
            <Typography text={getTransactionText(inventoryRequests)} />
          </Stack>
        </Conditional>
      </Stack>
    </Stack>
  );
};
