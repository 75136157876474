import styled from 'styled-components';
import { palette, Typography } from 'gantri-components';

export const StyledPageButtonWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const StyledRowButtonWrapper = styled.div`
  &:not(:last-child) {
    margin-right: 5px;
    padding-right: 5px;
    border-right: 1px solid ${palette.monochrome_200};
  }
`;

export const StyledDownloadSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledLink = styled(Typography)`
  text-decoration: none !important;
`;

export const StyledLinkContainer = styled.span`
  text-decoration: none !important;

  a {
    text-decoration: none !important;
  }
`;
