// value based on listTimeZones from timezone-support, a sub-dependency of date-fns-timezone
export const PACIFIC_TIMEZONE = 'America/Los_Angeles';

export const HOUR_FORMAT = "hh:mmaaaaa'm'";

export const MEDIUM_FORMAT = 'MM/dd/yy';

export const API_DATE_FORMAT = 'MM/dd/yyyy';

export const LONG_FORMAT = "MM/dd/yyyy 'at' hh:mmaaaaa'm'";

export const UNIX_MILISECONDS = 'T';

export const DOWNLOAD_MEDIUM_FORMAT = 'MM-dd-yyyy';

export const DOWNLOAD_LONG_FORMAT = 'yyyy-MM-dd HH:mm:ss';
