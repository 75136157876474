import { useRecoilValue } from 'recoil';
import { StartPrintJobContentProps } from './start-print-job-modal-content.types';
import { MachineTasksContent } from '../step-machine-tasks/machine-tasks-content';
import { startPrintPrintJobModalAtoms } from '../../start-print-job-modal.atoms';
import { AssignMachineContent } from '../step-assign-machine/assign-machine-content';

export const StartPrintJobContent = (props: StartPrintJobContentProps) => {
  const { job } = props;

  const step = useRecoilValue(startPrintPrintJobModalAtoms.step);

  switch (step) {
    case 'ASSIGN_MACHINE':
      return <AssignMachineContent job={job} />;

    case 'MACHINE_TASKS':
      return <MachineTasksContent job={job} />;

    default:
      return null;
  }
};
