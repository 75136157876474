import { Conditional, TextField, Typography } from 'gantri-components';
import { JobTemplateEditableInputCellProps } from './job-template-editable-input-cell.types';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks/use-validate-fields';

export const JobTemplateEditableInputCell = (
  props: JobTemplateEditableInputCellProps,
) => {
  const {
    disableEditing,
    getValue,
    keyName,
    onEdit,
    placeholder,
    row,
    textFieldProps,
    transformTextValue,
  } = props;

  const { getHasError } = useValidateFields();
  const hasError = getHasError(keyName);

  const value = getValue();

  const isEditingRow = useGetIsEditingRow(row);

  return (
    <Conditional
      condition={isEditingRow && !disableEditing}
      Fallback={<Typography text={transformTextValue?.(value) || value} />}
    >
      <TextField
        {...textFieldProps}
        errorMessage={hasError ? 'Required' : undefined}
        placeholder={placeholder}
        value={value ? String(value) : undefined}
        onBlurTextChange={(value) => {
          onEdit({
            ...row.original,
            [keyName]: value,
          });
        }}
      />
    </Conditional>
  );
};
