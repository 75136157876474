import { Button } from 'gantri-components';
import { jobsApi } from '../../../../../../../../api';
import { jobStatuses } from '../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { CompletePrintPrepareFooterProps } from './complete-print-prepare-footer.types';

export const CompletePrintPrepareFooter = (
  props: CompletePrintPrepareFooterProps,
) => {
  const { job, onClose, onJobUpdated } = props;

  const { notify, notifyAxiosError, processing, setProcessing } =
    useNotification();

  const handleCompleteJob = async () => {
    try {
      setProcessing(true);

      if (job.status === jobStatuses.ready) {
        await jobsApi.startJob({ jobId: job.id });
      }

      await jobsApi.completeJob({ jobId: job.id });

      notify('Print prepare job completed successfully.');

      await onJobUpdated();

      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to prepare print job.',
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Button
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Complete job"
      onClick={handleCompleteJob}
    />
  );
};
