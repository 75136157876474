import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateDesignCategoriesArgs,
  UpdateDesignCategoriesResponse,
} from './update-design-categories.types';

export const updateDesignCategories = ({
  id,
  ...body
}: UpdateDesignCategoriesArgs) => {
  return axios
    .put<UpdateDesignCategoriesResponse>(
      `${getCoreApiUrl('designs')}/${id}/categories`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
