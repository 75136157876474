import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { useInvalidateGetPrintPrepareQueuesCache } from '../get-print-prepare-queues';
import { createPrintPrepareQueue } from './create-print-prepare-queue';
import {
  CreatePrintPrepareQueueArgs,
  CreatePrintPrepareQueueResponse,
} from './create-print-prepare-queue.types';

export const useCreatePrintPrepareQueue = (options?: {
  showLoading?: boolean;
}) => {
  const { invalidateGetPrintPrepareQueuesCache } =
    useInvalidateGetPrintPrepareQueuesCache();

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    CreatePrintPrepareQueueArgs,
    CreatePrintPrepareQueueResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to add new print prepare queue.',
        });
      },
      onSuccess: async (data) => {
        notify(data.notice);
        await invalidateGetPrintPrepareQueuesCache();
      },
    },
    mutationFn: createPrintPrepareQueue,
    showLoading: options?.showLoading,
  });

  return { isLoading, onCreatePrintPrepareQueue: onMutate };
};
