import { PaginatedInventory } from '../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { useUpdateInventoryMutation } from '../../api/inventories/routes/update-inventory';

interface UseToggleStatusProps {
  onSuccess: (updatedInventory: PaginatedInventory) => void;
}

export const useToggleStatus = (props?: UseToggleStatusProps) => {
  const { onUpdateInventory } = useUpdateInventoryMutation();

  const onToggleActivate = async ({
    locations = [],
    ...record
  }: PaginatedInventory) => {
    const inventoryRecord = {
      ...record,
      id: record.id,
      locations: locations.map(({ id }) => {
        return id;
      }),
      source: 'inventories-table',
      status: record.status === 'Active' ? 'Inactive' : 'Active',
    };

    await onUpdateInventory(inventoryRecord, {
      onSuccess: (response) => {
        props?.onSuccess?.(response.inventory);
      },
    });
  };

  return { onToggleActivate };
};
