import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Property } from 'csstype';
import { CSSProperties } from 'react';
import {
  SortableWrapper,
  UseSortableItemProps,
} from './use-sortable-item.types';

/**
 * ```
 * const { SortableItemWrapper } = useSortableItem({ id });
 *
 * return (
 *   <SortableItemWrapper>
 *     {YOUR_SORTABLE_ELEMENT}
 *   </SortableItemWrapper>
 * );
 * ```
 */

export const useSortableItem = (props: UseSortableItemProps) => {
  const { id, useDragHandle } = props;

  const {
    attributes,
    isDragging,
    listeners,
    setActivatorNodeRef,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const cursor: Property.Cursor = isDragging ? 'grabbing' : 'grab';

  const baseStyles: CSSProperties = {
    touchAction: 'none',
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 2 : 1,
  };

  const wrapperStyles = useDragHandle
    ? { ...baseStyles }
    : { ...baseStyles, cursor };

  const wrapperListeners = useDragHandle ? {} : { ...listeners };

  const SortableItemWrapper: SortableWrapper = (props) => {
    return (
      <span
        {...props}
        ref={setNodeRef}
        style={wrapperStyles}
        {...attributes}
        {...wrapperListeners}
      />
    );
  };

  const SortableDragHandleWrapper: SortableWrapper = (props) => {
    return (
      <span
        {...props}
        ref={setActivatorNodeRef}
        {...listeners}
        style={{ cursor, display: 'contents' }}
      />
    );
  };

  return {
    /**
     * Only used if a specific element in the draggable item should activate the drag and drop. Still requires the entire sortable item is wrapped in `SortableItemWrapper`.
     *
     * https://docs.dndkit.com/presets/sortable/usesortable#activator
     */
    SortableDragHandleWrapper,
    SortableItemWrapper,
  };
};
