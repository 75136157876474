import { Table } from 'gantri-components';
import { useEffect, useState } from 'react';
import {
  GetPaginatedUsersArgs,
  User,
  UsersSortingField,
} from '../../api/users/routes/get-paginated-users/get-paginated-users.types';
import {
  pageName,
  usersFiltersDefaults,
  usersPageAtoms,
} from '../../components/dropdowns/users-filter/users-filter.atoms';
import { useTableFilters } from '../../components/common/table/hooks';
import { UsersFilter } from '../../components/dropdowns';
import { PageWithTable } from '../../components/layout';
import { columns, sortOptions } from './users.constant';
import { useFetchPaginatedUsersQuery } from '../../api/users/routes';
import { useTableColumnsSync } from '../../hooks';

export const Users = () => {
  const [filters, setFilters] = useState<GetPaginatedUsersArgs>(null);
  const { data, isLoading } = useFetchPaginatedUsersQuery({ filters });
  const columnsSyncProps = useTableColumnsSync('users');

  useEffect(() => {
    if (isLoading) return;

    setRecords(data?.users ?? []);
    setTotalCount(data?.resultsCount ?? 0);
  }, [isLoading, data]);

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<User, GetPaginatedUsersArgs, UsersSortingField>({
    fetchRequest: setFilters,
    filtersContent: <UsersFilter />,
    modalFiltersDetails: [
      {
        atom: usersPageAtoms.filters.types,
        defaultValue: usersFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
      {
        atom: usersPageAtoms.filters.active,
        defaultValue: usersFiltersDefaults.filters.active,
        payloadKey: 'active',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: usersPageAtoms.search,
    },
    sortByFilterDetails: {
      atom: usersPageAtoms.sortBy,
      defaultValue: usersFiltersDefaults.sortBy,
    },
    sortOptions,
  });

  return (
    <PageWithTable pageTitle="Users">
      <PageWithTable.Header title="All Users" />

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={({ original }) => {
            return {
              'data-user-id': original.id,
            };
          }}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          {...columnsSyncProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
