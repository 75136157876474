import { Box, Button, Modal, Typography } from 'gantri-components';
import { ExitWarningModalProps } from './exit-warning-modal.types';

export const ExitWarningModal = (props: ExitWarningModalProps) => {
  const { children, header, message, onClose, onConfirm } = props;

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Go Back"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            size="large"
            text="Exit"
            variant="primaryAlert"
            onClick={() => {
              onConfirm();
              onClose();
            }}
          />
        </>
      }
      header={header}
      width={{ lg: '42rem', md: '100%' }}
      onClose={onClose}
    >
      <Box>{children || <Typography align="center" text={message} />}</Box>
    </Modal>
  );
};
