import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import { nextBtnText } from './checklist-footer.constants';
import { ChecklistFooterProps } from './checklist-footer.types';
import { usePassOrFailJob } from './hooks/use-pass-or-fail-job';

export const ChecklistFooter = (props: ChecklistFooterProps) => {
  const { handleCloseModal, job } = props;

  const checklist = useRecoilValue(finishingQcModalAtoms.checklist);

  const isChecklistReviewed = useRecoilValue(
    finishingQcModalAtoms.isChecklistReviewed,
  );

  const { onFail, onPass, processing } = usePassOrFailJob({
    handleCloseModal,
    job,
  });

  const shouldFailPart = checkAgainstFailingChecklistItems(
    checklist,
    (item) => {
      return item.failStatusShouldFailPart;
    },
  );

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <CompleteChecklistButtonWrapper checklist={checklist}>
        <Button
          disabled={!isChecklistReviewed}
          processing={processing}
          size={fullScreenModalFooterButtonSize}
          text={shouldFailPart ? nextBtnText.failJob : nextBtnText.passJob}
          variant={shouldFailPart ? 'primaryAlert' : 'primary'}
          onClick={shouldFailPart ? onFail : onPass}
        />
      </CompleteChecklistButtonWrapper>
    </>
  );
};
