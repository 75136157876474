import { Typography } from 'gantri-components';
import { formatAsCurrency } from '../../../../../helpers/formatter';
import { CurrencyCellProps } from './currency-cell.types';

export const CurrencyCell = (props: CurrencyCellProps) => {
  const { isCents = true, value, ...options } = props;

  return (
    <Typography text={formatAsCurrency(value || 0, { isCents, ...options })} />
  );
};
