import { FC, Suspense, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Conditional,
  Flex,
  Stack,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { useParams } from 'react-router-dom';
import OrderTimeline from '../../components/order-timeline';
import {
  orderActionModalTypes,
  orderStatusesMap,
  transactionTypesMap,
} from '../../constants/options';
import OrderSummary from '../../components/summaries/order-summary';
import Modal from '../../components/modals';
import {
  StyledLeftSection,
  StyledOrderActionWrapper,
  StyledOrderPageWrapper,
  StyledRightSection,
} from './order.styles';
import {
  PaddedStyledPageSubsection,
  StyledPageSubsection,
} from '../../components/layout/page-layout-styles';
import Protected from '../../components/common/protected/protected';
import { Items, OptionActionsType } from './order.type';
import { CurrentOrderData, TransactionEmailLog } from '../../types/store';
import MetaData from '../../components/meta-data';
import { PageHeading } from '../../components/layout/page-heading';
import {
  actionTypes,
  modalDescriptions,
  modalMessages,
} from './order.constants';
import {
  Address,
  ManualShippingModal,
  Notes,
  OrderActionMenu,
  OrderActionModal,
  OrderGiftCard,
  OrderPaymentInfo,
  OrderPaymentSummary,
  OrderShipment,
  RestockModal,
} from './components';
import { useRouter, useSpinner } from '../../hooks';
import routePaths from '../../config/route-paths';
import { transactionsApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import LoadingSpinner from '../../components/common/spinner';

export const Order: FC = () => {
  const { notify, notifyAxiosError } = useNotification();
  const { navigate } = useRouter();
  const { id } = useParams<{ id: string }>();
  const currentOrderId = Number(id);

  const { onInterceptRequest } = useSpinner();

  const [confirmationId, setConfirmationId] = useState(null);
  const [confirmType, setConfirmType] = useState('');
  const [actionModalType, setActionModalType] = useState('');
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [cancelShipmentId, setCancelShipmentId] = useState(null);
  const [emailInfo, setEmailInfo] =
    useState<Pick<TransactionEmailLog, 'emailType' | 'ownerId'>>();
  const [modalText, setModalText] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [confirmOptionsVisible, setConfirmOptionsVisible] = useState(false);
  const [hasPlainPackaging, setHasPlainPackaging] = useState(false);
  const [giftMessageIncluded, setGiftMessageIncluded] = useState(false);
  const [shippingErrorDialogVisible, setShippingErrorDialogVisible] =
    useState(false);
  const [manualShipDialogVisible, setManualShipDialogVisible] = useState(false);
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);

  const pageTitle = currentOrderId
    ? `Transaction #${currentOrderId}`
    : 'Orders';

  const [currentOrderData, setCurrentOrderData] =
    useState<CurrentOrderData>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [viewTimeline, setViewTimeline] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [overallStatus, setOverallStatus] = useState('');
  const { status, type: orderType } = currentOrderData || {};

  const showRefundButton =
    [transactionTypesMap.refund, transactionTypesMap.thirdPartyRefund].some(
      (type) => {
        return type === orderType;
      },
    ) &&
    ![orderStatusesMap.refunded, orderStatusesMap.cancelled].some(
      (cancelledOrRefunded) => {
        return cancelledOrRefunded === status;
      },
    );

  const optionActions: OptionActionsType = {
    cancelItems: () => {
      setActionModalType(orderActionModalTypes.cancel);
      setShowSelectionModal(true);
    },
    changeItems: () => {
      setActionModalType(orderActionModalTypes.edit);
      setShowSelectionModal(true);
    },
    createRefund: () => {
      return navigate(`${routePaths.orders}/r/${currentOrderId}/refund`);
    },
    createReplacement: () => {
      return navigate(`${routePaths.orders}/r/${currentOrderId}/replacement`);
    },
    createReturn: () => {
      return navigate(`${routePaths.orders}/r/${currentOrderId}/return`);
    },
    reassignStock: () => {
      return navigate(
        `${routePaths.orders}/r/${currentOrderId}/reassign-stock`,
      );
    },
  };

  const modalConfirm = async () => {
    setConfirmationLoading(true);

    if (confirmType === actionTypes.resendConfirmationEmail) {
      try {
        setViewTimeline(false);

        const { data } = await transactionsApi.resendConfirmationEmail({
          currentOrderId,
          shipmentId: emailInfo.ownerId,
          type: emailInfo.emailType,
        });

        notify(data.notice);

        setViewTimeline(true);
        await fetchOrder();
        setShowConfirmationModal(false);
        setConfirmationLoading(false);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to re-send confirmation email.',
        });
      }
    } else if (confirmType === actionTypes.doNotShip) {
      try {
        setViewTimeline(false);

        const { data } = await transactionsApi.deliverDoNotShipShipment({
          shipmentId: confirmationId,
        });

        notify(data.notice);

        if (data && data.success) {
          setCurrentOrderData(data.order);
          setViewTimeline(true);
        }

        setShowConfirmationModal(false);
        setConfirmationLoading(false);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage:
            'Unable to deliver shipment marked as "Do Not Ship".',
        });
      }
    } else {
      setShippingErrorDialogVisible(false);
      setViewTimeline(false);

      try {
        const { data } = await transactionsApi.shipTransaction({
          id: confirmationId,
        });

        notify(data.notice);

        if (data && data.success) {
          setCurrentOrderData(data.order);
          setViewTimeline(true);
        }

        setShowConfirmationModal(false);
        setConfirmationLoading(false);
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to ship order.' });

        setShowConfirmationModal(false);
        setConfirmationLoading(false);
        setShippingErrorDialogVisible(true);
      }
    }
  };

  const modalCancel = () => {
    setConfirmationId('');
    setConfirmType('');
    setModalText('');
    setModalDescription('');
    setConfirmOptionsVisible(false);
    setShowConfirmationModal(false);
  };

  const onResendClick = (
    info: Pick<TransactionEmailLog, 'emailType' | 'ownerId'>,
  ) => {
    setEmailInfo(info);
    setConfirmType(actionTypes.resendConfirmationEmail);
    setModalText(modalMessages.resendConfirmationEmail);
    setModalDescription('');
    setShowConfirmationModal(true);
  };

  const toggleCancelShipmentModal = (shipmentId: any) => {
    setCancelShipmentId(shipmentId);
    setCancelModalVisible(!cancelModalVisible);
  };

  const cancelShipment = (shipmentId) => {
    return () => {
      toggleCancelShipmentModal(shipmentId);
    };
  };

  const onShipmentCancelConfirm = async () => {
    try {
      setCancelLoading(true);
      setViewTimeline(false);

      const { data } = await transactionsApi.cancelShipment(cancelShipmentId);

      notify(data.notice);

      if (data && data.success) {
        setCurrentOrderData(data.order);
        setViewTimeline(true);
      }

      setCancelLoading(false);
      setCancelModalVisible(!cancelModalVisible);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to cancel shipment.',
      });
    }
  };

  const completeRefundClick = () => {
    setConfirmationId(currentOrderId);
    showRestockModal();
  };

  const onRestockModalConfirm = async (items: Items[]) => {
    try {
      const { data } = await transactionsApi.restockItems(
        confirmationId,
        items,
      );

      notify(data.notice);

      if (data && data.success) {
        setCurrentOrderData(data.order);
      }

      hideRestockModal();
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to restock items.' });
    }
  };

  const [showRestockModal, hideRestockModal] = useModal(() => {
    return (
      <RestockModal
        stocks={currentOrderData?.stocks}
        onClose={hideRestockModal}
        onConfirm={onRestockModalConfirm}
      />
    );
  }, [currentOrderData?.stocks, onRestockModalConfirm]);

  const hideManualShipDialog = () => {
    setManualShipDialogVisible(false);
  };

  const shipManually = async (trackingNumber: any, shippingCost: any) => {
    try {
      setViewTimeline(false);

      const { data } = await transactionsApi.shipManually({
        orderId: currentOrderId,
        rateCost: shippingCost,
        shipmentId: confirmationId,
        trackingNumber,
      });

      notify(data.notice);

      if (data && data.success) {
        setCurrentOrderData(data.order);
        setViewTimeline(true);
      }

      hideManualShipDialog();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to mark order as shipped.',
      });
    }
  };

  const openManualShippingDialog = () => {
    setShowConfirmationModal(false);
    setShippingErrorDialogVisible(false);
    setManualShipDialogVisible(true);
  };

  const handleShip = (shipmentId: string | number, doNotShip: boolean) => {
    const { shipments } = currentOrderData;

    hideManualShipDialog();
    setConfirmationId(shipmentId);

    const shipment = shipments.find((record) => {
      return record.id === shipmentId;
    });
    const showConfirmOptions = shipment.stocks.some((record) => {
      return record.isGift;
    });

    setConfirmOptionsVisible(showConfirmOptions);
    setConfirmType(doNotShip ? actionTypes.doNotShip : actionTypes.ship);

    const shipTitle = showConfirmOptions
      ? modalMessages.shipWithGift
      : modalMessages.ship;

    const shipDescription = showConfirmOptions
      ? modalDescriptions.shipWithGift
      : modalDescriptions.ship;

    setModalText(doNotShip ? modalMessages.doNotShip : shipTitle);
    setModalDescription(doNotShip ? '' : shipDescription);
    setShowConfirmationModal(true);
  };

  const fetchOrder = async () => {
    await onInterceptRequest(async () => {
      if (currentOrderId) {
        try {
          const { data } = await transactionsApi.getTransaction({
            id: currentOrderId,
          });

          if (data && data.success) {
            setCurrentOrderData(data.order);
            setViewTimeline(true);
          }
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to fetch order.',
          });
        }
      }
    });
  };

  const onUpdateOverallStatus = async (newOverallStatus: string) => {
    setOverallStatus(newOverallStatus);

    if (newOverallStatus !== currentOrderData?.status) {
      await fetchOrder();
    }
  };

  const { amount, gift, giftCards, payment, shipments, type } =
    currentOrderData || {};

  const hasInvoice = useMemo(() => {
    return ['Trade', 'Third Party', 'Wholesale'].includes(type);
  }, [type]);

  const disableCompleteRefund = useMemo(() => {
    return shipments?.some(({ rateCost, trackingNumber }) => {
      return !trackingNumber || !rateCost;
    });
  }, [shipments]);

  useEffect(() => {
    fetchOrder().then();
  }, [currentOrderId]);

  if (!currentOrderData) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <MetaData title={pageTitle} />
      <StyledOrderPageWrapper>
        <StyledLeftSection>
          <PageHeading title={`${orderType} #${currentOrderId}`}>
            <StyledOrderActionWrapper>
              <Protected allowedFor={['Admin']}>
                <Conditional condition={showRefundButton}>
                  <Tooltip
                    description={
                      disableCompleteRefund &&
                      'The order must have shipping details before the refund can be completed.'
                    }
                    position="bottom"
                  >
                    <Button
                      disabled={disableCompleteRefund}
                      text="Complete Refund"
                      onClick={completeRefundClick}
                    />
                  </Tooltip>
                </Conditional>
              </Protected>
              <OrderActionMenu
                optionActions={optionActions}
                order={currentOrderData}
              />
            </StyledOrderActionWrapper>
          </PageHeading>

          <Stack gap="8x" marginTop="1.6rem">
            <OrderSummary currentOrderData={currentOrderData} />

            <Stack gap="8x" width="100%">
              <Conditional
                condition={!!shipments}
                Fallback={
                  <Typography
                    color="t1"
                    text="There are no shipments associated with this order."
                  />
                }
              >
                {shipments?.map((shipment) => {
                  return (
                    <OrderShipment
                      key={shipment.id}
                      {...shipment}
                      cancelShipment={cancelShipment(shipment.id)}
                      currentOrderData={currentOrderData}
                      handleShip={handleShip}
                      setCurrentOrderData={setCurrentOrderData}
                    />
                  );
                })}
              </Conditional>

              {giftCards?.map((giftCard) => {
                return <OrderGiftCard key={giftCard.id} giftCard={giftCard} />;
              })}
            </Stack>
          </Stack>
        </StyledLeftSection>
        <StyledRightSection>
          <Conditional
            condition={!!currentOrderData.address}
            Fallback={
              <>
                <Typography marginBottom="1rem" text="Shipping" variant="h4" />
                <Typography
                  color="t1"
                  marginBottom="8x"
                  text="There is no shipping address info for this order."
                />
              </>
            }
          >
            <PaddedStyledPageSubsection data-address-section>
              <Address
                currentOrderData={currentOrderData}
                setCurrentOrderData={setCurrentOrderData}
              />
            </PaddedStyledPageSubsection>
          </Conditional>

          <Conditional condition={payment && !payment.thirdParty}>
            <PaddedStyledPageSubsection>
              <OrderPaymentInfo
                currentOrderData={currentOrderData}
                payment={payment}
              />
            </PaddedStyledPageSubsection>
          </Conditional>

          <PaddedStyledPageSubsection>
            <OrderPaymentSummary amount={amount} gift={gift} />

            <Conditional condition={hasInvoice}>
              <Typography marginTop="3x" text="Invoice #" variant="h6" />
              <Typography text={currentOrderData.invoice ?? 'N/A'} />
            </Conditional>
          </PaddedStyledPageSubsection>

          <StyledPageSubsection>
            <Notes
              currentOrderData={currentOrderData}
              onUpdate={(newNotes) => {
                setCurrentOrderData({ ...currentOrderData, notes: newNotes });
              }}
            />
          </StyledPageSubsection>
        </StyledRightSection>

        <Modal
          confirmButtonText={
            confirmType === actionTypes.ship ? 'Shippo' : 'Confirm'
          }
          confirmDisabled={
            confirmOptionsVisible &&
            (!hasPlainPackaging || !giftMessageIncluded)
          }
          description={modalDescription}
          footer={
            <Flex justifyContent="center" marginTop="20px">
              <Button
                size="large"
                text="Ship Manually"
                variant="ghost"
                onClick={openManualShippingDialog}
              />
            </Flex>
          }
          loading={confirmationLoading}
          text={modalText}
          type="confirmation"
          visible={showConfirmationModal}
          onCancel={modalCancel}
          onConfirm={modalConfirm}
        >
          {confirmOptionsVisible && (
            <Stack gap="10px" marginTop="30px">
              <Checkbox
                checked={hasPlainPackaging}
                labelText="This item has plain packaging"
                onSelected={setHasPlainPackaging}
              />
              <Checkbox
                checked={giftMessageIncluded}
                labelText="Gift message is included"
                onSelected={setGiftMessageIncluded}
              />
            </Stack>
          )}
        </Modal>
        <Modal
          cancelButtonArchetype="secondary"
          cancelButtonText="No"
          confirmButtonArchetype="red"
          confirmButtonText="Yes"
          description="Are you sure you want to cancel?"
          loading={cancelLoading}
          text="Cancel Shipment"
          type="confirmation"
          visible={cancelModalVisible}
          onCancel={toggleCancelShipmentModal}
          onConfirm={onShipmentCancelConfirm}
        />
        <OrderActionModal
          currentOrderData={currentOrderData}
          setCurrentOrderData={setCurrentOrderData}
          setStatus={onUpdateOverallStatus}
          type={actionModalType}
          visible={showSelectionModal}
          onClose={() => {
            return setShowSelectionModal(false);
          }}
        />
        <Modal
          cancelButtonArchetype="secondary"
          cancelButtonText="Cancel"
          confirmButtonText="Re-try"
          footer={
            <Flex justifyContent="center" marginTop="20px">
              <Button
                size="large"
                text="Ship Manually"
                variant="ghost"
                onClick={openManualShippingDialog}
              />
            </Flex>
          }
          type="selection"
          visible={shippingErrorDialogVisible}
          onCancel={() => {
            return setShippingErrorDialogVisible(false);
          }}
          onClose={() => {
            return setShippingErrorDialogVisible(false);
          }}
          onConfirm={modalConfirm}
        >
          <Stack gap="10px">
            <Typography
              align="center"
              text="Shipping Error"
              textStyle="bold"
              variant="p1"
            />
            <Typography
              align="center"
              color="alert"
              text="An error has occurred from Shippo."
              variant="p2"
            />
          </Stack>
        </Modal>

        <ManualShippingModal
          visible={manualShipDialogVisible}
          onCancel={hideManualShipDialog}
          onConfirm={shipManually}
          onShippoClick={() => {
            return handleShip(
              confirmationId,
              currentOrderData.address.doNotShip,
            );
          }}
        />
        {viewTimeline && (
          <Suspense fallback={<div>loading...</div>}>
            <OrderTimeline
              currentOrderData={currentOrderData}
              orderId={currentOrderId}
              onResend={onResendClick}
            />
          </Suspense>
        )}
      </StyledOrderPageWrapper>
    </>
  );
};
