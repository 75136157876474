import { Cell, TextField, Typography } from 'gantri-components';
import { FocusEvent, useEffect, useState } from 'react';
import { Label } from '../../../../../../../../components/label';
import { NumberInputCellProps } from './number-input-cell.types';

export const NumberInputCell = (props: NumberInputCellProps) => {
  const {
    editedShipment,
    isEditing,
    keyName,
    label,
    placeholder,
    prefillValue,
    required,
    setEditedShipment,
    ...rest
  } = props;

  const value = editedShipment[keyName];

  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    if (isEditing && !value && !!prefillValue) {
      setEditedShipment((oldProps) => {
        return {
          ...oldProps,
          [keyName]: prefillValue,
        };
      });
    }
  }, [isEditing]);

  return (
    <Cell {...rest}>
      <Label paddingBottom=".5x" text={label} />
      {isEditing ? (
        <TextField
          errorMessage={errorMessage}
          minValue={1}
          placeholder={placeholder}
          required={required}
          type="number"
          value={String(value || '')}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            const { value } = event.target;

            if (required && !value) {
              setErrorMessage('This field is required');
            } else {
              setErrorMessage('');
            }
          }}
          onChange={(event) => {
            setEditedShipment((oldProps) => {
              return {
                ...oldProps,
                [keyName]: Number(event.target.value),
              };
            });
          }}
        />
      ) : (
        <Typography text={value || 'TBD'} />
      )}
    </Cell>
  );
};
