import { useRecoilValue } from 'recoil';
import { completePrintModalAtoms } from '../../complete-print-modal.atoms';
import { EvaluatePartFooter } from '../step-evaluate-part/evaluate-part-footer';
import { CompleteChecklistFooter } from '../step-complete-checklist/complete-checklist-footer';
import { CompletePrintFooterProps } from './complete-print-footer.types';
import { JobFailedFooter } from '../step-job-failed/job-failed-footer';
import { MachineOfflineFooter } from '../step-machine-offline/machine-offline-footer';

export const CompletePrintFooter = (props: CompletePrintFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(completePrintModalAtoms.step);

  switch (step) {
    case 'EVALUATE_PART':
      return <EvaluatePartFooter handleCloseModal={handleCloseModal} />;

    case 'COMPLETE_CHECKLIST':
      return (
        <CompleteChecklistFooter
          handleCloseModal={handleCloseModal}
          job={job}
        />
      );

    case 'JOB_FAILED':
      return <JobFailedFooter handleCloseModal={handleCloseModal} />;

    case 'MACHINE_OFFLINE':
      return <MachineOfflineFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
