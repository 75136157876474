import { v4 as uuidv4 } from 'uuid';
import { RecoilState, atom } from 'recoil';
import { InventoryRequest } from '../../../../../../../api/jobs/jobs.types';
import {
  SpoolChanged,
  StartPrintJobModalStep,
} from './start-print-job-modal.types';

export const atomKeyPrefix = 'START_PRINT_PRINT_JOB_MODAL';

export const startPrintPrintJobModalAtoms = {
  assignedMachine: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-assigned-machine`,
  }),
  buildPlateChanged: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-build-plate-changed`,
  }),
  buildPlateNeedsChanged: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-build-plate-needs-changed`,
  }),
  inventoryRequests: atom<InventoryRequest[]>({
    default: [
      {
        id: uuidv4(),
        inventory: { name: '', status: null, unit: '' },
        isNew: true,
        need: 0,
      },
    ],
    key: `${atomKeyPrefix}-inventoryRequest`,
  }),
  machines: atom<unknown[]>({
    default: [],
    key: `${atomKeyPrefix}-machines`,
  }),
  spoolChanged: atom<SpoolChanged>({
    default: 'NO_CHANGE',
    key: `${atomKeyPrefix}-spool-changed`,
  }),
  step: atom<StartPrintJobModalStep>({
    default: 'ASSIGN_MACHINE',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
} satisfies Record<string, RecoilState<any>>;
