import { useMemo, useState } from 'react';
import orderBy from 'lodash/orderBy';
import { SortInfo, SortingType } from '../components/common/table/table.props';

export const useSorting = <
  TData extends Record<any, any>,
  SortingField extends string,
>({
  data = [],
  fieldsMapping,
  initialSortingField,
  initialSortingType,
}: {
  data: TData[];
  fieldsMapping: Record<string, SortInfo<SortingField>>;
  initialSortingField: SortingField;
  initialSortingType: SortingType;
}) => {
  const [currentSortingField, setCurrentSortingField] =
    useState<string>(initialSortingField);
  const [currentSortingType, setCurrentSortingType] =
    useState<SortingType>(initialSortingType);

  const lowercaseCurrentSortingType =
    currentSortingType?.toLowerCase() as Lowercase<typeof currentSortingType>;

  const sortData = (data: TData[]) => {
    const sortedData = orderBy(
      data.filter((row) => {
        return row.id !== 'total';
      }),
      (item) => {
        const value = item[currentSortingField];

        return typeof value === 'string' ? value.toLowerCase().trim() : value;
      },
      [lowercaseCurrentSortingType],
    );

    return sortedData;
  };

  const sortedData = useMemo(() => {
    return sortData(data);
  }, [data, currentSortingField, currentSortingType]);

  const selectedSorting = useMemo(() => {
    const index = Object.values(fieldsMapping).findIndex((value) => {
      return (
        value.sortingField === currentSortingField &&
        value.sortingType === currentSortingType
      );
    });

    if (index > -1) {
      return Object.keys(fieldsMapping)[index];
    }

    return '';
  }, [currentSortingType, currentSortingField]);

  const handleSort = (newSortingField: SortingField) => {
    const { sortingField, sortingType } = Object.values(fieldsMapping).find(
      ({ sortingField }) => {
        return sortingField === newSortingField;
      },
    );

    setCurrentSortingField(sortingField);
    setCurrentSortingType(sortingType);
  };

  return {
    selectedSortingValue: selectedSorting,
    setSortingField: setCurrentSortingField,
    setSortingType: setCurrentSortingType,
    sort: handleSort,
    sortData,
    sortedData,
    sortingField: currentSortingField,
    sortingType: currentSortingType,
  };
};
