import { MachineRecord } from '../../machines.types';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';

export const getSetOfflineDisabled = (
  machine: Pick<MachineRecord, 'status'>,
): boolean => {
  const disabledStatuses: MachineStatus[] = [
    machineStatuses.offline,
    machineStatuses.inProgress,
  ];

  const isDisabledStatus = disabledStatuses.some((disabledStatus) => {
    return disabledStatus === machine?.status;
  });

  return isDisabledStatus;
};
