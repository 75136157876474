import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { DateUserDataCellProps } from './last-action-data-cell.props';
import { StyledTooltipTypography } from '../custom-data-cells-styles';
import { formatDate } from '../../../../helpers/formatter';
import routePaths from '../../../../config/route-paths';
import { StyledTooltipContent } from './last-action-data-cell.styles';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../../constants/dates';

export const LastActionDataCell = (props: DateUserDataCellProps) => {
  const { data, date, jobId } = props;

  const TooltipContent = () => {
    const formattedStartDate: string = formatDate(date, LONG_FORMAT);

    return (
      <StyledTooltipContent>
        <StyledTooltipTypography
          text={`${data.job?.name} (${data.job?.status})`}
        />
        <Conditional condition={!!data?.user?.id}>
          <StyledTooltipTypography text=" by " variant="p2" />
          <Link to={`${routePaths.users}/${data?.user?.id}`}>
            <StyledTooltipTypography
              color="link"
              text={`${data?.user?.firstName} ${data?.user?.lastName}`}
            />
          </Link>
        </Conditional>
        <StyledTooltipTypography text={` on ${formattedStartDate}`} />
      </StyledTooltipContent>
    );
  };

  return (
    <Conditional condition={!!date}>
      <Flex alignItems="center" gap="0.8rem">
        <Typography text={formatDate(date, MEDIUM_FORMAT)} />
        <Conditional condition={!!jobId}>
          <Tooltip Component={<TooltipContent />} position="top-end">
            <Icon name="alert:i_circle" top=".2rem" />
          </Tooltip>
        </Conditional>
      </Flex>
    </Conditional>
  );
};
