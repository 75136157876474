import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { FooterProps } from './complete-assembly-modal-footer.types';
import { JobChecklistFooter } from '../step-job-checklist/job-checklist-footer';
import { PartChecklistFooter } from '../step-part-checklist/part-checklist-footer';
import { completeAssemblyModalAtoms } from '../../complete-assembly-modal.atoms';
import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { InventoryChecklistFooter } from '../step-inventory-checklist/inventory-checklist-footer';
import { ExceededCycleTimeFooter } from '../step-exceeded-cycle-time/exceeded-cycle-time-footer';
import { AssemblyCompletedFailedFooter } from '../step-assembly-completed-failed/assembly-completed-failed-footer';

export const CompleteAssemblyModalFooter: FC<FooterProps> = (props) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(completeAssemblyModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return <ConfirmBeginFooter handleCloseModal={handleCloseModal} />;

    case 'INVENTORY_CHECKLIST':
      return <InventoryChecklistFooter />;

    case 'JOB_CHECKLIST':
      return <JobChecklistFooter handleCloseModal={handleCloseModal} />;

    case 'PART_CHECKLIST':
      return <PartChecklistFooter />;

    case 'ASSEMBLY_COMPLETED_FAILED':
      return (
        <AssemblyCompletedFailedFooter handleCloseModal={handleCloseModal} />
      );

    case 'JOB_FAILED_EXCEEDED_CYCLE_TIME':
    case 'JOB_PASSED_EXCEEDED_CYCLE_TIME':
      return <ExceededCycleTimeFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
