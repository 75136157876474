import { Stack, Typography } from 'gantri-components';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { StyledFlexContainer } from '../../assemble-stage-job-content/assemble-stage-job-content.styles';
import { NoAssignedLocationContentProps } from './no-assigned-location-content.types';

export const NoAssignedLocationContent = (
  props: NoAssignedLocationContentProps,
) => {
  const { job } = props;
  const { stockId } = job;

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText="No assigned location for Stock"
      />

      <Typography text="Manually Locate Stock" variant="h5" />

      <StyledFlexContainer
        horizontalPadding="3x"
        justifyContent="center"
        verticalPadding="4x"
      >
        <Typography
          text={`There's no assigned location for Stock #${stockId}. Please locate it and confirm.`}
        />
      </StyledFlexContainer>
    </Stack>
  );
};
