import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchIdleMachinesArgs,
  FetchIdleMachinesResponse,
} from './fetch-idle-machines.types';

export const fetchIdleMachines = (args: FetchIdleMachinesArgs) => {
  const { type } = args;

  return axios.get<FetchIdleMachinesResponse>(`${coreApiUrl}/machines/idle`, {
    params: { type },
  });
};
