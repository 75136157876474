import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { RenameFileArgs, RenameFileResponse } from './rename-file.types';

/** Allows you to rename/relocate cloudinary files. */
export const renameFile = async ({
  fromPublicId,
  toPublicId,
}: RenameFileArgs) => {
  return axios.post<RenameFileResponse>(
    `${baseApiUrl}/cloudinary/rename-file`,
    {
      from_public_id: updatePublicId(fromPublicId),
      to_public_id: updatePublicId(toPublicId),
    },
  );
};

const updatePublicId = (publicId: string) => {
  const extensionPattern = /\.[^.]+$/;

  return publicId.replace(extensionPattern, '');
};
