import React, { FC, useState } from 'react';
import { Form, Formik } from 'formik';
import { Conditional, Modal, Typography } from 'gantri-components';
import { FormikProps } from 'formik/dist/types';
import {
  DownloadFormState,
  DownloadFormSteps,
  DownloadModalProps,
} from '../download-modal.types';
import { initialFormState } from '../download-modal.constants';
import { DownloadFooterFilesStep } from './download-footer-files-step';
import { DownloadFooterInfoStep } from './download-footer-info-step';
import { DownloadBodyInfoStep } from './download-body-info-step';
import { DownloadBodyFilesStep } from './download-body-files-step';
import { DownloadModalSchema } from './download-modal.schema';

export const DownloadModal: FC<DownloadModalProps> = (props) => {
  const { download, onClose, onSave, processing } = props;
  const [step, setStep] = useState<DownloadFormSteps>('info');

  const onNext = () => {
    return setStep('files');
  };

  const onBack = () => {
    return setStep('info');
  };

  return (
    <Formik
      initialValues={{
        ...initialFormState,
        ...((download || {}) as DownloadFormState),
      }}
      validateOnChange
      validateOnMount
      validationSchema={DownloadModalSchema}
      onSubmit={onSave}
    >
      {({ errors, isValid }: FormikProps<DownloadFormState>) => {
        return (
          <Form>
            <Modal
              footer={
                <Conditional
                  condition={step === 'info'}
                  Fallback={
                    <DownloadFooterFilesStep
                      isValid={isValid}
                      processing={processing}
                      onBack={onBack}
                    />
                  }
                >
                  <DownloadFooterInfoStep
                    isValid={
                      !errors.name && !errors.type && !errors.description
                    }
                    onCancel={onClose}
                    onNext={onNext}
                  />
                </Conditional>
              }
              header={
                <Typography
                  align="center"
                  text={download?.id ? 'Edit download' : 'Add download'}
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '42rem', md: '100%' }}
              onClose={onClose}
            >
              <Conditional
                condition={step === 'info'}
                Fallback={<DownloadBodyFilesStep />}
              >
                <DownloadBodyInfoStep />
              </Conditional>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
