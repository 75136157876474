import { PhotoConfig } from './imagery-section.types';

export const imageryPhotoConfig: PhotoConfig[] = [
  {
    fileType: 'process-imagery',
    keyName: 'processImages',
    max: 4,
    title: 'Process imagery',
  },
  {
    fileType: 'editorial-imagery',
    keyName: 'editorialImages',
    max: Infinity,
    title: 'Editorial imagery',
  },
  {
    fileType: 'quote-imagery',
    keyName: 'quoteImage',
    max: 1,
    title: 'Quote imagery',
  },
];
