import { useRecoilValue } from 'recoil';
import { locationsApi } from '../../../../../../api';
import { useInvalidateGetLocationsCache } from '../../../../../../api/locations/routes/get-locations/get-locations.query';
import { useNotification } from '../../../../../../hooks/useNotification';
import { locationModalAtoms } from '../../location-modal.atoms';
import { LocationModalSchema } from '../../location-modal.schema';
import { UseGetOnSubmitProps } from './use-get-on-submit.types';

export const useGetOnSubmit = (props: UseGetOnSubmitProps) => {
  const { handleCloseModal, handleFetchRequest, records, setRecords } = props;

  const isEditModal = useRecoilValue(locationModalAtoms.isEditModal);

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { invalidateGetLocationsCache } = useInvalidateGetLocationsCache();

  const handleCreateLocation = async (location: LocationModalSchema) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const response = await locationsApi.createLocation({
          location,
        });

        if (response.success) {
          notify(response.notice);

          await handleFetchRequest({ page: 1 });

          await invalidateGetLocationsCache();

          handleCloseModal();
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred creating a new location.',
        });
      }
    });
  };

  const handleEditLocation = async (location: LocationModalSchema) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const response = await locationsApi.editLocation({
          location,
        });

        if (response.success) {
          setRecords(
            records.map((location) => {
              return location.id === response.location.id
                ? { ...location, ...response.location }
                : location;
            }),
          );
          notify(response.notice);

          await invalidateGetLocationsCache();

          handleCloseModal();
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred editing this location.',
        });
      }
    });
  };

  const onSubmit = isEditModal ? handleEditLocation : handleCreateLocation;

  return { onSubmit, processing };
};
