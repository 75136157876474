import { useInvalidateGetInventoryTransactionsCache } from '../get-inventory';
import { useNotification } from '../../../../hooks/useNotification';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { CreateInventoryTransactionArgs } from './create-inventory-transaction.types';
import { createTransaction } from './create-inventory-transaction';

export const useCreateInventoryTransactionMutation = () => {
  const invalidateCache = useInvalidateGetInventoryTransactionsCache();
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } =
    useQueryMutation<CreateInventoryTransactionArgs>({
      config: {
        onError: (error) => {
          notifyAxiosError({
            error,
            fallbackMessage: 'An error occurred saving new transaction data.',
          });
        },
        onSuccess: async (data, variables) => {
          notify(data.notice);
          await invalidateCache({ inventoryId: variables.inventoryId });
        },
      },
      mutationFn: (request) => {
        return createTransaction(request);
      },
      showLoading: true,
    });

  return { isLoading, onCreateTransaction: onMutate };
};
