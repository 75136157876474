import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { CompleteJobImmediatelyFooterProps } from './complete-job-immediately-footer.types';

export const CompleteJobImmediatelyFooter = (
  props: CompleteJobImmediatelyFooterProps,
) => {
  const { handleCloseModal, job } = props;

  return <ConfirmBeginFooter handleCloseModal={handleCloseModal} job={job} />;
};
