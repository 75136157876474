import { useRecoilState } from 'recoil';
import DatePicker from '../../common/date-picker';
import { userActivityPageAtoms } from '../../../pages/user/activity/user-activity.atoms';
import { userActivityOptions } from '../../../pages/user/activity/user-activity.constants';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';

const UserActivityFilter = () => {
  const [dateRange, setDateRange] = useRecoilState(
    userActivityPageAtoms.filters.dateRange,
  );

  const onCreatedDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom,
      to: formattedTo,
    });
  };

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={userActivityPageAtoms.filters.dateRange}
          default={userActivityPageAtoms.defaults.filters.dateRange}
          text="Date"
        />
        <DatePicker
          initialValue={dateRange}
          range
          onDateChanged={onCreatedDateChanged}
        />
      </FilterRow>

      <FilterRow columns={1}>
        <FilterResetLabel
          atom={userActivityPageAtoms.filters.type}
          default={userActivityPageAtoms.defaults.filters.type}
          text="Type"
        />
        <AtomsCheckboxList
          atom={userActivityPageAtoms.filters.type}
          items={userActivityOptions}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default UserActivityFilter;
