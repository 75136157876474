import { Conditional, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { RestartPartTableData } from '../../complete-job/complete-assembly-modal/components/step-assembly-completed-failed/assembly-completed-failed-content/assembly-completed-failed-content.types';
import { PartStatusBadge } from '../../../../../../components/common/part-status-badge';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';

export const restartPartTableColumns: ColumnDef<RestartPartTableData>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
    }: CellContext<RestartPartTableData, RestartPartTableData['id']>) => {
      return <Typography text={`#${getValue()}`} />;
    },
    header: 'Part #',
  },
  {
    accessorKey: 'status',
    cell: ({
      getValue,
    }: CellContext<RestartPartTableData, RestartPartTableData['status']>) => {
      return <PartStatusBadge status={getValue()} />;
    },
    header: 'Status',
  },
  {
    accessorKey: 'version',
    cell: ({
      getValue,
    }: CellContext<RestartPartTableData, RestartPartTableData['version']>) => {
      const version = getValue();

      return (
        <Conditional condition={!!version} Fallback={<NotApplicableText />}>
          <Typography text={`v${version}`} />
        </Conditional>
      );
    },
    header: 'Version',
  },
  {
    accessorKey: 'createdAt',
    cell: ({
      getValue,
      row,
    }: CellContext<
      RestartPartTableData,
      RestartPartTableData['createdAt']
    >) => {
      const createdAt = getValue();

      return (
        <Conditional condition={!!createdAt} Fallback={<NotApplicableText />}>
          <DateWithUserDataCell
            data={{}}
            date={createdAt}
            jobId={row.original.id}
            type="Created"
          />
        </Conditional>
      );
    },
    header: 'Created',
  },
];
