import { useFormikContext } from 'formik';
import {
  Cell,
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ColorSwatchAndLabel } from '../../../../../../../../components/common/color-swatch-and-label';
import { productStatuses } from '../../../../../../../../constants/options';
import { ColorSwatchDropdown } from '../../../../../../../../components/common/color-swatch-dropdown';
import { batchPartCreationAtoms } from '../../../../../../batch-part-creation.atoms';
import { BatchPartCreationFormik } from '../../../../../../batch-part-creation.types';
import { ContentWithLabel } from '../../../common/content-with-label';
import { useGetBatchTemplateDetails } from '../../../select-template-page/components/create-part-from-product-form/hooks/use-get-batch-template-details';
import { PartThumbnail } from './components/part-thumbnail';

export const PartInformation = () => {
  const selectedProductDetails = useRecoilValue(
    batchPartCreationAtoms.selectedProductDetails,
  );

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  const { batchQty, colorCode, material, partName, productId } = formikValues;

  const { isTranslucentMaterial, partMaterialOptions, productColorOptions } =
    useGetBatchTemplateDetails();

  const partNameStructure = '[Prod/R&D]: [Part Name] [Recipient/Department]';

  const isLowQty = typeof batchQty === 'number' && batchQty <= 0;

  const isHighQty = batchQty > 100;

  return (
    <>
      <Typography paddingTop="1.2rem" text="Part Information" variant="h4" />
      <Grid columns={{ lg: 'max-content 1fr', sm: '1fr' }} gap="1.6rem">
        <Cell>
          <PartThumbnail />
        </Cell>
        <Grid alignItems="baseline" columns={{ lg: 4, sm: 1 }}>
          <Cell width={{ lg: 4, sm: 1 }}>
            <Stack gap="0.6rem">
              <Typography text="Part Name" textStyle="bold" />
              <Typography
                text={`Customize part name using the following format: ${partNameStructure}.`}
              />
              <FormikInput
                debounce={500}
                name="batchPartName"
                placeholder={partNameStructure}
                required
              />
            </Stack>
          </Cell>
          <Cell width={{ lg: 2, sm: 1 }}>
            <FormikInput
              debounce={500}
              labelText="Quantity"
              minValue={1}
              name="batchQty"
              placeholder="Enter number of parts"
              required={
                /* Hide required message to render low/high qty message. */
                !(isLowQty || isHighQty)
              }
              type="number"
            />
            <Conditional condition={isLowQty}>
              <Typography
                color="alert"
                paddingTop="0.4rem"
                text="Quantity must be more than 0."
                variant="p3"
              />
            </Conditional>
            <Conditional condition={isHighQty}>
              <Typography
                color="warning"
                paddingTop="0.4rem"
                text="Quantity is high, please  ensure you need that many parts."
                variant="p3"
              />
            </Conditional>
          </Cell>
          <Cell width={{ lg: 2, sm: 1 }}>
            <FormikInput
              debounce={500}
              Field={<TextArea placeholder="Enter a note" />}
              labelText="Notes"
              name="batchPartNotes"
            />
          </Cell>
          <Conditional
            condition={!!productId}
            Fallback={
              <>
                <Cell width={{ lg: 2, sm: 1 }}>
                  <FormikInput
                    Field={<Dropdown items={partMaterialOptions} required />}
                    labelText="Material"
                    name="material"
                    placeholder="Select material"
                    required
                  />
                </Cell>
                <Cell width={{ lg: 2, sm: 1 }}>
                  <FormikInput
                    Field={
                      <ColorSwatchDropdown
                        disabled={!material}
                        items={productColorOptions}
                      />
                    }
                    fieldVariant="standard"
                    labelText="Color"
                    name="colorCode"
                    placeholder="Select color"
                    required={!isTranslucentMaterial}
                  />
                </Cell>
              </>
            }
          >
            <Cell width={{ lg: 4, sm: 1 }}>
              <Grid columns={{ lg: 5, sm: 1 }}>
                <ContentWithLabel
                  Content={() => {
                    return (
                      <Cell>
                        <Typography text={selectedProductDetails?.fullName} />
                        <Conditional
                          condition={
                            selectedProductDetails?.status !==
                            productStatuses.active
                          }
                        >
                          <Typography
                            color="t2"
                            text={`(${selectedProductDetails?.status})`}
                          />
                        </Conditional>
                      </Cell>
                    );
                  }}
                  label="Product"
                />
                <ContentWithLabel
                  Content={() => {
                    return <ColorSwatchAndLabel colorCode={colorCode} />;
                  }}
                  label="Color"
                />
                <ContentWithLabel label="Part" text={partName} />
              </Grid>
            </Cell>
          </Conditional>
        </Grid>
      </Grid>
    </>
  );
};
