import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';
import { StartJobFooter } from '../../../../../common/start-job-footer';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal } = props;

  const job = useRecoilValue(completeAssemblyModalAtoms.job);
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeAssemblyModalAtoms.updateOnClose,
  );

  return (
    <StartJobFooter
      job={job}
      nextStep="INVENTORY_CHECKLIST"
      setStep={setStep}
      setUpdateOnClose={setUpdateOnClose}
      size={fullScreenModalFooterButtonSize}
      onClose={handleCloseModal}
    />
  );
};
