import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportProfitQueries } from '../../report-profit.types';

export const getGroupByIsVisible = (): boolean => {
  const queries = getActiveQueries<ReportProfitQueries>();
  const { groupBy, productId } = queries;

  if (/^productId$/i.test(groupBy) && productId) {
    return false;
  }

  return true;
};
