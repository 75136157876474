import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CreateLocationResponse } from './create-location.types';

export const createLocation = async ({ location }) => {
  const res = await axios.post<CreateLocationResponse>(
    `${coreApiUrl}/locations`,
    location,
  );

  return res.data;
};
