import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultJobsGroupBy } from '../../report-jobs.constants';
import { ReportJobsQueries } from '../../report-jobs.types';

export const getGroupByIsVisible = (): boolean => {
  const queries = getActiveQueries<ReportJobsQueries>();
  const {
    groupBy = defaultJobsGroupBy,
    productName,
    jobType,
    workerName,
    machineType,
  } = queries;

  if (
    (/^productName$/i.test(groupBy) && productName) ||
    (/^jobType$/i.test(groupBy) && jobType) ||
    (/^workerName$/i.test(groupBy) && workerName) ||
    (/^machineType$/i.test(groupBy) && machineType)
  ) {
    return false;
  }

  return true;
};
