import { Flex, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledPlaceholderStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
      border-radius: 3px;
      background-color: ${theme.colors.palette.monochrome_white};
    `;
  }}
`;

export const StyledImgWrapper = styled.div`
  img {
    max-height: 60rem;
    width: auto;
    object-fit: contain;
  }
`;

export const StyledSwatchFlexWrapper = styled(Flex)`
  column-gap: 3.2rem;
  row-gap: 0.8rem;
`;
