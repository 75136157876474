import { Button, Flex } from 'gantri-components';
import routePaths from '../../../../config/route-paths';
import { partsApi } from '../../../../api';
import { useRouter } from '../../../../hooks';
import Protected from '../../../../components/common/protected/protected';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { PartsHeaderContentProps } from './parts-header-content.types';
import { downloadColumns } from './parts-header-content.constants';

export const PartsHeaderContent = (props: PartsHeaderContentProps) => {
  const { filterArgs, totalResults } = props;

  const { navigate } = useRouter();

  const { Toast: DownloadCsvToast, downloadOption } = useGetDownloadMenuOption({
    columns: downloadColumns,
    fetchRequestWithFilters: async (overrides) => {
      return partsApi
        .getParts({ ...filterArgs, ...overrides })
        .then(({ data }) => {
          return data.parts;
        });
    },
    fileNamePrefix: 'Parts Admin',
    totalResults,
  });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: ['Admin', 'Lead'],
      },
    ],
  });

  return (
    <Flex alignItems="center" gap=".5x">
      <Protected allowedFor={['Admin']}>
        <Button
          text="New Part(s)"
          onClick={() => {
            navigate(routePaths.batchPartCreation);
          }}
        />
      </Protected>
      <Protected allowedFor={['Admin', 'Lead']}>
        <MoreMenu />
        <DownloadCsvToast />
      </Protected>
    </Flex>
  );
};
