import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  ResendConfirmationEmailArgs,
  ResendConfirmationEmailResponse,
} from './resend-confirmation-email.types';

export const resendConfirmationEmail = (args: ResendConfirmationEmailArgs) => {
  const { currentOrderId, shipmentId, type } = args;

  return axios.post<ResendConfirmationEmailResponse>(
    `${baseApiUrl}/transactions/resend-email`,
    {
      orderId: currentOrderId,
      shipmentId,
      type,
    },
  );
};
