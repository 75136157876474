import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  CreateFeatureArgs,
  CreateFeatureResponse,
} from './create-feature-settings.types';

export const createDesignerFeature = ({
  designerId,
  name,
}: CreateFeatureArgs) => {
  return axios.post<CreateFeatureResponse>(
    `${baseApiUrl}/designers/${designerId}/feature-page`,
    { name },
  );
};
