import { Conditional, Stack, Typography } from 'gantri-components';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { UpdateBatchContent } from '../../../../../../../../part/components/part-summary/components/update-part-batch-modal/components/step-update-batch/update-batch-content';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';

export const JobFailedUpdateBatchContent = ({
  batchId,
}: {
  batchId: number;
}) => {
  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.finish} ${jobTypeOptions.qc} job failed`}
      />
      <Stack gap="x">
        <Typography text="Update batch" variant="h5" />
        <Conditional condition={!!batchId}>
          <Typography
            htmlText={`The part will be removed from <strong>Batch #${batchId}</strong>.`}
          />
        </Conditional>
        <UpdateBatchContent
          atomActiveBatches={finishingQcModalAtoms.activeBatches}
          atomBatchUpdateMethod={finishingQcModalAtoms.batchUpdateMethod}
          atomNewBatchId={finishingQcModalAtoms.newBatchId}
          currentBatchId={batchId}
        />
      </Stack>
    </Stack>
  );
};
