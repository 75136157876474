import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';

export const SelectJobFooter = () => {
  const setStep = useSetRecoilState(printQcModalAtoms.step);
  const selectedPrintJob = useRecoilValue(printQcModalAtoms.selectedPrintJob);

  return (
    <Button
      disabled={!selectedPrintJob}
      size={fullScreenModalFooterButtonSize}
      text="Next"
      onClick={() => {
        setStep('CHECKLIST');
      }}
    />
  );
};
