import { Conditional, Stack, Typography } from 'gantri-components';
import { ColorSwatchAndLabel } from '../../../../../../../../../../../components/common/color-swatch-and-label';
import { StyledItemGrid } from '../../../../../../../../../../../components/common/inventory-form/inventory-form.styles';
import { LONG_FORMAT } from '../../../../../../../../../../../constants/dates';
import { formatDate } from '../../../../../../../../../../../helpers/formatter';
import { BatchRenderItemProps } from './batch-search-render-item.types';

export const BatchSearchRenderItem = (props: BatchRenderItemProps) => {
  const { batch } = props;
  const { colors, createdAt, currentSteps, disabled, id } = batch;

  const textColor = disabled ? 't3' : 't1';

  return (
    <StyledItemGrid
      $disabled={disabled}
      alignItems="baseline"
      columns="6rem 10.7rem 12.9rem 1fr"
      gap="2x"
      horizontalPadding="2x"
      verticalPadding="x"
    >
      <Typography color={textColor} text={`#${id}`} />
      <Stack gap=".5x">
        <Conditional
          condition={!!colors?.length}
          Fallback={<Typography text="-" />}
        >
          {colors.map((color) => {
            return (
              <ColorSwatchAndLabel
                key={color}
                colorCode={color}
                iconColor={disabled ? 't3' : undefined}
                labelColor={textColor}
              />
            );
          })}
        </Conditional>
      </Stack>
      <Stack gap=".5x">
        <Conditional
          condition={!!currentSteps?.length}
          Fallback={<Typography text="-" />}
        >
          {currentSteps.map((step) => {
            return <Typography key={step} color={textColor} text={step} />;
          })}
        </Conditional>
      </Stack>
      <Typography color={textColor} text={formatDate(createdAt, LONG_FORMAT)} />
    </StyledItemGrid>
  );
};
