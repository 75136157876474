import { useRecoilState, useResetRecoilState } from 'recoil';
import { jobTypeOptions } from '../../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { jobsTemplateTableAtoms } from '../../../../../../jobs-template-table.atoms';
import { JobTemplateWithId } from '../../../../../../jobs-template-table.types';

export const useValidateFields = () => {
  const { notifyError } = useNotification();

  const [fieldErrors, setFieldErrors] = useRecoilState(
    jobsTemplateTableAtoms.fieldErrors,
  );

  const getHasError = (field: keyof JobTemplateWithId): boolean => {
    return fieldErrors.some((fieldName) => {
      return fieldName === field;
    });
  };

  const resetValidateFields = useResetRecoilState(
    jobsTemplateTableAtoms.fieldErrors,
  );

  const validateFields = (jobTemplate: JobTemplateWithId): boolean => {
    const fieldErrors: (keyof JobTemplateWithId)[] = [];

    if (!jobTemplate.type) {
      fieldErrors.push('type');
    }

    if (!jobTemplate.description) {
      fieldErrors.push('description');
    }

    if (!jobTemplate.step) {
      fieldErrors.push('step');
    }

    if (!jobTemplate.duration) {
      fieldErrors.push('duration');
    }

    const isPrintPrepareJob = jobTemplate.type === jobTypeOptions.prepare;

    if (isPrintPrepareJob) {
      if (!jobTemplate.gcode?.url) {
        fieldErrors.push('gcode');
      }

      if (!jobTemplate.weight) {
        fieldErrors.push('weight');
      }

      if (!jobTemplate.machineType) {
        fieldErrors.push('machineType');
      }
    }

    const passesValidation = !fieldErrors.length;

    if (!passesValidation) {
      if (fieldErrors.includes('gcode')) {
        notifyError('Please add a g-code file.');
      } else if (fieldErrors.includes('inventoryRequests')) {
        notifyError('Please add a material inventory to your print job.');
      } else {
        notifyError('Please correct the highlighted fields.');
      }
    }

    setFieldErrors(fieldErrors);

    return passesValidation;
  };

  return { getHasError, resetValidateFields, validateFields };
};
