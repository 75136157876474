import { getFileUrl, Grid } from 'gantri-components';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import { cloudinaryUrl } from '../../../../../../helpers/images';
import { StatColumn } from './components/stat-column';
import { StatRow } from './components/stat-column/stat-column.types';
import {
  StyledScrollWrapper,
  StyledTopStatsWrapper,
} from './dashboard-top-stats.styles';
import { DashboardTopStatsProps } from './dashboard-top-stats.types';

export const DashboardTopStats = (props: DashboardTopStatsProps) => {
  const { topInfo } = props;
  const { categories = [], creators = [], products = [] } = topInfo || {};

  const categoriesData: StatRow[] = categories.map(({ amount, id }) => {
    const rowData: StatRow = {
      key: id,
      title: id,
      value: formatAsCurrency(amount, {
        isCents: true,
        maximumFractionDigits: 0,
      }),
    };

    return rowData;
  });

  const creatorsData: StatRow[] = creators.map(
    ({ amount, id, name, thumbnailUrl }) => {
      const thumbnailSrc = getFileUrl<'designers'>({
        directory: 'designers',
        fileName: thumbnailUrl,
        fileType: 'headshot',
        identifiers: {
          userId: Number(id),
        },
      });
      const rowData: StatRow = {
        key: id,
        thumbnailUrl: cloudinaryUrl(thumbnailSrc, {
          aspectRatio: 1,
          crop: 'fill',
          width: 40,
        }),
        title: name,
        value: formatAsCurrency(amount, {
          isCents: true,
          maximumFractionDigits: 0,
        }),
      };

      return rowData;
    },
  );

  const productsData: StatRow[] = products.map(
    ({ amount, id, name, sku, thumbnailUrl }) => {
      const thumbnailSrc = getFileUrl<'products'>({
        directory: 'products',
        fileName: thumbnailUrl,
        fileType: 'product-photos',
        identifiers: {
          productId: Number(id),
          sku,
        },
      });
      const rowData: StatRow = {
        key: id,
        thumbnailUrl: cloudinaryUrl(thumbnailSrc, {
          aspectRatio: 1,
          crop: 'fill',
          width: 40,
        }),
        title: name,
        value: formatAsCurrency(amount, {
          isCents: true,
          maximumFractionDigits: 0,
        }),
      };

      return rowData;
    },
  );

  const fillEmptyIndexes = ({
    array,
    showThumbnailPlaceholder,
  }: {
    array: StatRow[];
    showThumbnailPlaceholder?: boolean;
  }) => {
    const placeholderData: StatRow = {
      key: '',
      showThumbnailPlaceholder,
      title: 'N/A',
      value: '-',
    };
    const placeholderDataArray: StatRow[] = new Array<StatRow>(3)
      .fill(placeholderData)
      .map(({ key, ...data }, index) => {
        return { ...data, key: `${key}-${index}` };
      });

    return array.length === 3
      ? array
      : [...array, ...placeholderDataArray].slice(0, 3);
  };

  return (
    <StyledTopStatsWrapper>
      <StyledScrollWrapper>
        {topInfo && (
          <Grid columns={3}>
            <StatColumn
              data={fillEmptyIndexes({
                array: productsData,
                showThumbnailPlaceholder: true,
              })}
              title="Top Products"
            />
            <StatColumn
              data={fillEmptyIndexes({
                array: creatorsData,
                showThumbnailPlaceholder: true,
              })}
              title="Top Creators"
            />
            <StatColumn
              data={fillEmptyIndexes({ array: categoriesData })}
              title="Top Categories"
            />
          </Grid>
        )}
      </StyledScrollWrapper>
    </StyledTopStatsWrapper>
  );
};
