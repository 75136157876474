import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';

export const finalizationRecords = [
  {
    key: 'photos',
    label: 'Photos',
  },
  {
    key: 'copy',
    label: 'Copy',
  },
  {
    key: 'technicalSpecs',
    label: 'Technical specs',
  },
  {
    key: 'packagingAssets',
    label: 'Packaging assets',
  },
  {
    key: 'approveDesignerProfile',
    label: 'Approve designer profile',
  },
];

export const initialContent: Design['content'] = {
  completedAt: null,
  productFinalization: {
    approveDesignerProfile: {
      approved: false,
      completedAt: null,
    },
    completedAt: null,
    copy: {
      approved: false,
      completedAt: null,
    },
    packagingAssets: {
      approved: false,
      completedAt: null,
    },
    photos: {
      approved: false,
      completedAt: null,
    },
    technicalSpecs: {
      approved: false,
      completedAt: null,
    },
  },
  status: 'Waiting',
  stepProgress: 0,
};
