import { Modal, Stack } from 'gantri-components';
import { useResetRecoilAtomsOnMount } from '../../../../hooks/use-reset-recoil-atoms-on-mount';
import { CreateBatchModalContent } from './components/create-batch-modal-content';
import { CreateBatchModalFooter } from './components/create-batch-modal-footer';
import { CreateBatchModalHeader } from './components/create-batch-modal-header';
import { createBatchModalAtoms } from './create-batch-modal.atoms';

export const CreateBatchModal = ({ onClose }: { onClose: () => void }) => {
  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    createBatchModalAtoms,
  );

  return (
    <ResetAtomsWrapper>
      <Modal
        footer={<CreateBatchModalFooter onClose={onClose} />}
        header={<CreateBatchModalHeader />}
        maxWidth={{ lg: '46rem', md: '100%' }}
        onClose={onClose}
      >
        <Stack
          alignContent="center"
          alignItems="center"
          gap="0"
          height="17rem"
          justifyContent="center"
        >
          <CreateBatchModalContent />
        </Stack>
      </Modal>
    </ResetAtomsWrapper>
  );
};
