import { Cell, Flex, Icon, Tooltip, Typography } from 'gantri-components';
import { IssuesProps } from './open-issues.types';

export const OpenIssues = ({ issues }: IssuesProps) => {
  const { length } = issues;
  const multipleIssues = `${length} issues`;

  const openIssueList = issues.map((item, index) => {
    return (
      <Typography
        key={`${item.id}-${index}`}
        color="alert"
        text={item.type}
        variant="p2"
      />
    );
  });

  const tooltipList = issues.map((item, index) => {
    const difficultyIconName = (value) => {
      return `machine:difficulty_${value}`;
    };

    const iconName = difficultyIconName(item.difficulty);
    const iconDifficultyName = iconName as any;

    return (
      <Flex key={`${item.id}-${index}`} gap="1rem" paddingBottom="0.8rem">
        <Icon color="link" name={iconDifficultyName} />
        <Typography key={`${item.id}-${index}`} text={item.type} variant="p2" />
      </Flex>
    );
  });

  if (length > 3) {
    return (
      <Tooltip
        Component={
          <div style={{ padding: '0.8rem 1.6rem' }}>{tooltipList}</div>
        }
        position="right-start"
      >
        <Typography color="alert" text={multipleIssues} />
      </Tooltip>
    );
  }

  if (length === 0) {
    return <Typography color="alert" text="0" />;
  }

  return <Cell>{openIssueList}</Cell>;
};
