import { useSetRecoilState } from 'recoil';
import { AllDashboardDateRangeTypes } from '../../../../../dashboard.types';
import { dashboardAndReportAtoms } from '../../../../../dashboard.atoms';
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  formatDateWithoutTimezone,
  startOfMonth,
  startOfWeek,
} from '../../../../../../../helpers/formatter';

export const getYesterdayDates = () => {
  const now = addDays(new Date(), -1);

  return {
    from: formatDateWithoutTimezone(now),
    to: formatDateWithoutTimezone(now),
  };
};

/** Returns Monday and following Sunday dates. */
export const getPastWeekDates = () => {
  const now = addDays(new Date(), -7);

  return {
    from: formatDateWithoutTimezone(startOfWeek(now)),
    to: formatDateWithoutTimezone(endOfWeek(now)),
  };
};

/** Returns the 1st and the last day of the month(s). */
export const getPastMonthsDates = (numMonths: 1 | 3) => {
  const now = addMonths(new Date(), numMonths * -1);
  const pastMonth = addMonths(new Date(), -1);

  return {
    from: formatDateWithoutTimezone(startOfMonth(now)),
    to: formatDateWithoutTimezone(endOfMonth(pastMonth)),
  };
};

/**
 * When `onDateRangeTypeChange` is called, `from`, `to`, and `rangeType` are updated in the dashboard atoms.
 *
 * When the `value` is `'Custom'`, only `rangeType` is set in the atoms.  */
export const useOnDateRangeTypeChange = () => {
  const setStartDate = useSetRecoilState(dashboardAndReportAtoms.startDate);
  const setEndDate = useSetRecoilState(dashboardAndReportAtoms.endDate);
  const setRangeType = useSetRecoilState(dashboardAndReportAtoms.rangeType);

  const onDateRangeTypeChange = (value: AllDashboardDateRangeTypes) => {
    setRangeType(value);

    switch (value) {
      case 'Past week': {
        const { from, to } = getPastWeekDates();

        setStartDate(from);
        setEndDate(to);

        break;
      }

      case 'Past month': {
        const { from, to } = getPastMonthsDates(1);

        setStartDate(from);
        setEndDate(to);

        break;
      }

      case 'Past 3 months': {
        const { from, to } = getPastMonthsDates(3);

        setStartDate(from);
        setEndDate(to);

        break;
      }

      case 'Custom': {
        // DO NOTHING. Atoms update handled by DatePicker component
        break;
      }

      case 'Yesterday':

      default: {
        const { from, to } = getYesterdayDates();

        setStartDate(from);
        setEndDate(to);

        break;
      }
    }
  };

  return { onDateRangeTypeChange };
};
