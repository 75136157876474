import { ProductColorCode } from 'gantri-components';
import { BatchStatus } from '../../api/batch/batch.types';
import { DateRangeFilter } from '../../components/common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
  SortBy,
} from '../../components/common/table/hooks';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { BatchesSortingField } from './batches.types';

export const batchesFiltersDefaults: {
  filters: {
    colors: ProductColorCode[];
    createdDateRange: DateRangeFilter;
    status: BatchStatus[];
  };
  sortBy: SortBy<BatchesSortingField>;
} = {
  filters: {
    colors: [],
    createdDateRange: {
      from: null,
      to: null,
    },
    status: ['Active'],
  },
  sortBy: {
    sortingField: 'createdAt',
    sortingType: 'DESC',
  },
};

const atomKeyPrefix = 'BATCHES_FILTERS';

export const pageName: AtomPageName = 'batches';

export const batchesFilterAtoms = {
  colors: getGenericAtomFamily({
    defaultValue: batchesFiltersDefaults.filters.colors,
    key: `${atomKeyPrefix}-colors`,
  })(pageName),
  createdDateRange: getGenericAtomFamily({
    defaultValue: batchesFiltersDefaults.filters.createdDateRange,
    key: `${atomKeyPrefix}-created-date-range`,
  })(pageName),
  defaults: batchesFiltersDefaults,
  status: getGenericAtomFamily({
    defaultValue: batchesFiltersDefaults.filters.status,
    key: `${atomKeyPrefix}-status`,
  })(pageName),
};

export const batchesPageSearchAtom = getSearchFilterAtomFamily(pageName);

export const batchesPageSortByAtom = getSortByFilterAtomFamily<
  SortBy<BatchesSortingField>
>({
  defaultValue: batchesFiltersDefaults.sortBy,
})(pageName);
