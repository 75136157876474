import { GiftCardsSortingField } from '../../../api/gift-cards/routes/fetch-paginated-giftcards/fetch-paginated-giftcards.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { GiftCardsFilterStatus } from './gift-cards-filter.types';

export const giftCardsFiltersDefaults: {
  filters: {
    statuses: GiftCardsFilterStatus[];
  };
  sortBy: SortBy<GiftCardsSortingField>;
} = {
  filters: {
    statuses: [],
  },
  sortBy: {
    sortingField: 'createdAt',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'GIFT_CARDS_FILTERS';

export const pageName: AtomPageName = 'gift-cards';

export const giftCardsPageAtoms = {
  defaults: giftCardsFiltersDefaults,
  filters: {
    statuses: getGenericAtomFamily({
      defaultValue: giftCardsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<GiftCardsSortingField>>({
    defaultValue: giftCardsFiltersDefaults.sortBy,
  })(pageName),
};
