import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { batchApi } from '../../../../api';
import { BatchPart } from '../../../../api/batch/batch.types';
import { ColorSwatchAndLabel } from '../../../../components/common/color-swatch-and-label';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenu } from '../../../../components/dropdowns';
import routePaths from '../../../../config/route-paths';
import { jobStatuses } from '../../../../constants/options';
import { useNotification } from '../../../../hooks/useNotification';
import StatusDataCell from '../../../../components/common/custom-data-cells/status-data-cell';
import { useJobComplete, useJobStart } from '../../../jobs/hooks';
import { Thumbnail } from '../../../../components';
import { placeholderImageSrc } from '../../../../constants/images';
import { jobTypeToIconTypeMap } from '../../../../constants/jobs';
import { UseGetBatchPartsColumnsProps } from './use-get-batch-parts-columns.types';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { batchAtoms } from '../../batch.atoms';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';

export const useGetBatchPartsColumns = (
  props: UseGetBatchPartsColumnsProps,
) => {
  const { batchId, fetchBatchDetails, isReorderingActive, setBatch } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const isReorderActive = useRecoilValue(batchAtoms.isReorderActive);

  const startJob = useJobStart({
    onStartEnd: fetchBatchDetails,
  });

  const completeJob = useJobComplete({
    onCompleteEnd: fetchBatchDetails,
  });

  const columns: ColumnDef<BatchPart>[] = [
    {
      accessorKey: 'id',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['id']>) => {
        const id = getValue();

        return (
          <StyledAnchor text={`#${id}`} to={`${routePaths.parts}/${id}`} />
        );
      },
      header: 'Part ID',
      meta: {
        getIsClickable: (args) => {
          return !isReorderingActive && getIsClickableIfValue(args);
        },
        hideable: false,
        label: 'Part ID',
        reorderable: false,
      },
      size: 80,
    },
    {
      accessorKey: 'name',
      cell: ({ getValue, row }: CellContext<BatchPart, BatchPart['name']>) => {
        const name = getValue();
        const { thumbnail } = row.original;

        return (
          <Conditional condition={!!name} Fallback={<Typography text="-" />}>
            <Grid columns="4.8rem 1fr" gap="x">
              <Thumbnail
                alt={name}
                size={48}
                source="absolute"
                src={thumbnail || placeholderImageSrc}
              />
              <Typography text={name} />
            </Grid>
          </Conditional>
        );
      },
      header: 'Name',
      meta: {
        label: 'Name',
      },
      size: 250,
    },
    {
      accessorKey: 'product',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['product']>) => {
        return <ShortProductSummary {...getValue()} hideImage />;
      },
      header: 'Product',
      size: 200,
    },
    {
      accessorKey: 'color',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['color']>) => {
        const colorCode = getValue();

        return <ColorSwatchAndLabel key={colorCode} colorCode={colorCode} />;
      },
      header: 'Part Color',
      meta: {
        hideable: false,
        label: 'Part Color',
      },
      size: 100,
    },
    {
      accessorKey: 'currentJob',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['currentJob']>) => {
        const job = getValue();
        const iconName = jobTypeToIconTypeMap[job?.type];

        return (
          <Conditional condition={!!job} Fallback={<Typography text="-" />}>
            <Flex alignItems="center" gap=".5x">
              <Tooltip position="top" title={job?.type}>
                {iconName && (
                  <Icon color="t2" name={iconName} size="2.2rem" top="2px" />
                )}
              </Tooltip>
              <Typography text={job?.type} />
            </Flex>
          </Conditional>
        );
      },
      header: 'Current Job Type',
      id: 'job-type',
    },
    {
      accessorKey: 'currentJob',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['currentJob']>) => {
        const job = getValue();
        const jobName = job?.description;

        return (
          <Conditional condition={!!job} Fallback={<Typography text="-" />}>
            <Typography text={jobName?.replace('_', ' ')} />
          </Conditional>
        );
      },
      header: 'Current Job Name',
      id: 'job-name',
      meta: {
        hideable: false,
        label: 'Current Job Name',
      },
      size: 250,
    },
    {
      accessorKey: 'currentJob',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['currentJob']>) => {
        const job = getValue();

        return (
          <Conditional condition={!!job} Fallback={<Typography text="-" />}>
            <StatusDataCell {...job} />
          </Conditional>
        );
      },
      header: 'Job Status',
      id: 'job-status',
      size: 100,
    },
    {
      accessorKey: 'location',
      cell: ({ getValue }: CellContext<BatchPart, BatchPart['location']>) => {
        const location = getValue();

        return (
          <Conditional
            condition={!!location?.name}
            Fallback={<Typography text="-" />}
          >
            <Typography text={location?.name} />
          </Conditional>
        );
      },
      header: 'Location',
      size: 200,
    },
    {
      cell: ({ row }: CellContext<BatchPart, never>) => {
        const status = row.original.currentJob?.status;
        const isReady = status === jobStatuses.ready;
        const isInProgress = status === jobStatuses.inProgress;
        const options: MoreMenuOption<BatchPart>[] = [
          isReady && {
            enabled: !isReorderActive,
            name: 'Start job',
            onOptionClick: async ({ currentJob }) => {
              await startJob(currentJob?.id);
            },
          },
          isInProgress && {
            enabled: !isReorderActive,
            name: 'Complete job',
            onOptionClick: async ({ currentJob }) => {
              await completeJob(currentJob?.id);
            },
          },
          {
            enabled: !isReorderActive,
            name: 'Remove from batch',
            onOptionClick: async ({ id }) => {
              try {
                showLoading();

                const { data } = await batchApi.removePartFromBatch({
                  batchId,
                  partId: id,
                  returnBatch: true,
                });

                notify(data.notice);
                setBatch(data.batch);
              } catch (error: unknown) {
                notifyAxiosError({
                  error,
                  fallbackMessage: 'Unable to remove part from batch.',
                });
              } finally {
                hideLoading();
              }
            },
          },
        ];

        return (
          <Flex alignItems="center" gap="x" justifyContent="flex-end">
            <Conditional condition={isReady}>
              <Button
                disabled={isReorderActive}
                text="Start Job"
                variant="secondary"
                onClick={async () => {
                  await startJob(row.original.currentJob?.id);
                }}
              />
            </Conditional>
            <Conditional condition={isInProgress}>
              <Button
                disabled={isReorderActive}
                text="Complete Job"
                variant="secondary"
                onClick={async () => {
                  await completeJob(row.original.currentJob?.id);
                }}
              />
            </Conditional>
            <MoreMenu data={row.original} options={options} />
          </Flex>
        );
      },
      header: '',
      id: 'actions',
      maxSize: 20,
      meta: { hideable: false, label: 'Actions', reorderable: false },
    },
  ];

  return columns;
};
