import { useRecoilValue } from 'recoil';
import { PrintQaContentProps } from './print-qa-content.types';
import { SelectJobContent } from '../step-select-job/select-job-content';
import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { ChecklistContent } from '../step-checklist/checklist-content';
import { JobFailedContent } from '../step-job-failed/job-failed-content';
import { printQcModalAtoms } from '../../complete-print-qa-modal.atoms';
import { MachineOfflineContent } from '../step-machine-offline/machine-offline-content';

export const PrintQaContent = (props: PrintQaContentProps) => {
  const { job } = props;
  const { material } = job.part;

  const step = useRecoilValue(printQcModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return <ConfirmBeginContent />;

    case 'SELECT_JOB':
      return <SelectJobContent />;

    case 'CHECKLIST':
      return <ChecklistContent material={material} />;

    case 'JOB_FAILED':
      return <JobFailedContent />;

    case 'MACHINE_OFFLINE':
      return <MachineOfflineContent />;

    default:
      return null;
  }
};
