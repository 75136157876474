import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import { GetProductArgs, GetProductResponse } from './get-product.types';

export const fetchProduct = async ({
  id,
  sku,
  type,
  versionId,
}: GetProductArgs) => {
  const queries = convertQueriesToString({
    sku,
    versionId,
  });

  return axios
    .get<GetProductResponse>(
      `${getCoreApiUrl('products')}/${id}/${type}${queries}`,
    )
    .then(({ data }) => {
      return data;
    });
};
