import { GetFrontCardCss } from './get-front-card-css.types';

export const getFrontCardCss: GetFrontCardCss = ({
  frontBackgroundColor,
  frontTextColor,
}) => {
  return `
  .handout--front-bleed-border {
    background-color: ${frontBackgroundColor};
    color: ${frontTextColor};
  }

  .handout--front-top-section {
    height: 124px;
    padding-top: 5px;
    width: 100%;
  }

  .handout--front-top-section td {
    vertical-align: middle
  }

  .handout--front-logo-wrapper {
    padding: 8px 8px 5px;
  }

  .handout--front-name-wrapper {
    padding: 0 8px;
  }

  .handout--front-location-wrapper {
    padding: 0 8px;
  }

  .handout--back-created-by *,
  .handout--front-location-wrapper * {
    font-size: 11px;
    line-height: 18px;
  }

  .handout--front-designer-image-wrapper {
    padding: 5px 8px;
  }

  .handout--front-designer-image-wrapper img {
    margin: 0 auto;
    max-height: 170px;
  }

  .handout--front-bio-wrapper {
    padding: 5px 8px;
    text-align: center;
    height: 93px;
  }

  .handout--front-bottom-links-wrapper {
    vertical-align: middle;
    padding-top: 5px;
    height: 20px;
  }
  .handout--front-bottom-links-wrapper * {
    font-size: 9px;
    line-height: 11px;
  }

  .handout--store-path {
    padding-right: 5px;
    padding-left: 8px;
    float: left;
    text-align: left;
  }

  .handout--instagram {
    float: right;
    padding-right: 8px;
  }
  
  .handout--instagram td {
    width: unset !important;
    height: unset !important;
  }

  .handout--instagram svg {
    float: right;
    height: 11px;
    width: 11px;
  }

  .handout--instagram-username {
    white-space: nowrap;
    padding-left: 3px;
  }
`;
};
