import { ColumnConfig } from 'gantri-components';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { useRef } from 'react';
import { hashArray } from './use-table-columns-sync.helpers';
import {
  tableColumnsSyncState,
  tableOriginalColumnsSyncState,
} from './use-table-columns-sync.atoms';
import { tablesSyncApi } from '../../api';
import { TableSyncState } from './use-table-columns-sync.types';

export const useTableColumnsSync = (tableId: string) => {
  const syncWasStarted = useRef<boolean>(false);
  const state = useRecoilValue(tableColumnsSyncState(tableId));
  const originalState = useRecoilValue(tableOriginalColumnsSyncState(tableId));

  const onSaveConfiguration = async (config: TableSyncState) => {
    await tablesSyncApi.saveTableColumns(config);
  };

  const getColumnsConfig = useRecoilCallback(({ set, snapshot }) => {
    return async function* (columns: ColumnConfig[]) {
      const originalHash = await hashArray(columns);

      const currentConfig = snapshot
        .getLoadable(tableColumnsSyncState(tableId))
        .getValue();

      const currentOriginalConfig = snapshot
        .getLoadable(tableOriginalColumnsSyncState(tableId))
        .getValue();

      if (currentOriginalConfig?.hash !== originalHash) {
        const config = {
          columns,
          hash: originalHash,
          tableId,
        };

        /*
         * reset the state if exists discrepancies between the original configuration
         * and the saved original configuration
         * */
        set(tableOriginalColumnsSyncState(tableId), config);
        set(tableColumnsSyncState(tableId), config);

        // prevent the conflicts if exists previous saved configuration
        await onSaveConfiguration(config);

        yield columns;

        return;
      }

      if (currentConfig?.columns?.length) {
        yield currentConfig.columns;
      }

      if (syncWasStarted.current) {
        return;
      }

      try {
        syncWasStarted.current = true;

        const { data } = await tablesSyncApi.getTableColumns(tableId);

        if (data?.hash !== currentConfig?.hash) {
          yield data.columns;
        }
      } catch {
        syncWasStarted.current = false;
      }
    };
  }, []);

  const onColumnsConfigChange = useRecoilCallback(({ set, snapshot }) => {
    return async (columns: ColumnConfig[], original: ColumnConfig[]) => {
      const hash = await hashArray(columns);

      const currentConfig = snapshot
        .getLoadable(tableColumnsSyncState(tableId))
        .getValue();

      if (currentConfig?.hash !== hash) {
        const config = {
          columns,
          hash,
          tableId,
        };

        try {
          await onSaveConfiguration(config);
          set(tableColumnsSyncState(tableId), config);
        } catch {
          // do nothing
        }
      }
    };
  }, []);

  return {
    getColumnsConfig,
    onColumnsConfigChange,
    syncState: {
      reordered:
        !!originalState?.hash &&
        state?.hash &&
        originalState?.hash !== state?.hash,
    },
  };
};
