import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledAlertGrid = styled(Grid)`
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.iconography.success};
      background-color: ${theme.colors.surfaces.blue.t1};
      border-radius: 3px;
    `;
  }}
`;
