import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedInventoriesArgs,
  FetchPaginatedInventoriesResponse,
} from './fetch-paginated-inventories.types';

export const fetchPaginatedInventories = (
  args: FetchPaginatedInventoriesArgs,
) => {
  return axios.post<FetchPaginatedInventoriesResponse>(
    `${coreApiUrl}/inventory/paginatedInventory`,
    args,
  );
};
