import { productColorsMap } from 'gantri-components';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getInventoriesListFromRequests } from '../../hooks/use-get-job-columns/components/job-inventories-cell';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import routePaths from '../../../../config/route-paths';
import { formatDate } from '../../../../helpers/formatter';
import { DOWNLOAD_LONG_FORMAT } from '../../../../constants/dates';
import { newlineSeparator } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { DownloadJob } from '../../../../api/jobs/jobs.types';
import { prepareFailureReasons } from '../../../../helpers/fail-reason';
import { unpaintedColor } from '../../../../constants/colors';

export const downloadColumns: DownloadColumn<DownloadJob>[] = [
  {
    getValue: ({ description, id }) => {
      return formatAsLinkForCSV({
        text: description,
        url: `${getAdminUrl() + routePaths.jobs}/${id}`,
      });
    },
    header: 'Job Name',
  },
  {
    getValue: ({ attentions }) => {
      const numAlerts = attentions?.length;

      return !!numAlerts ? numAlerts : '';
    },
    header: 'Alert',
  },
  {
    getValue: ({ attempt }) => {
      return attempt;
    },
    header: 'Atmpt',
  },
  {
    getValue: ({ step }) => {
      return step;
    },
    header: 'Step',
  },
  {
    getValue: ({ type }) => {
      return type;
    },
    header: 'Type',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Status',
  },
  {
    getValue: ({ failedReason }) => {
      return prepareFailureReasons(failedReason)
        .map(({ details, label }) => {
          return [label, details].filter(Boolean).join(' - ');
        })
        .join(newlineSeparator);
    },
    header: 'Failure Reason',
  },
  {
    getValue: ({ product }) => {
      return product?.name;
    },
    header: 'Product Name',
  },
  {
    getValue: ({ product }) => {
      return product?.sku;
    },
    header: 'SKU',
  },
  {
    getValue: ({ product }) => {
      return product?.category;
    },
    header: 'Product Category',
  },
  {
    getValue: ({ product }) => {
      return (
        productColorsMap[product?.color]?.shortColorName || unpaintedColor.name
      );
    },
    header: 'Product Color',
  },
  {
    getValue: ({ assignedTo }) => {
      return assignedTo?.userId
        ? formatAsLinkForCSV({
            text: assignedTo?.userName,
            url: `${getAdminUrl() + routePaths.users}/${assignedTo.userId}`,
          })
        : '';
    },
    header: 'Assigned to',
  },
  {
    getValue: ({ versionInfo }) => {
      return versionInfo?.version ? `v${versionInfo?.version}` : '';
    },
    header: 'Version',
  },
  {
    getValue: ({ part }) => {
      return part?.id
        ? formatAsLinkForCSV({
            text: part?.name,
            url: `${getAdminUrl() + routePaths.parts}/${part?.id}`,
          })
        : '';
    },
    header: 'Part',
  },
  {
    getValue: ({ gcode }) => {
      return formatAsLinkForCSV({
        text: gcode?.fileName,
        url: gcode?.url,
      });
    },
    header: 'G-code',
  },
  {
    getValue: ({ weight }) => {
      return weight ? `${weight}g` : '';
    },
    header: 'Weight',
  },
  {
    getValue: ({ machineId, machineName }) => {
      return machineId
        ? formatAsLinkForCSV({
            text: machineName,
            url: `${getAdminUrl() + routePaths.machines}/${machineId}`,
          })
        : '';
    },
    header: 'Machine',
  },
  {
    getValue: ({ part }) => {
      return part?.id
        ? formatAsLinkForCSV({
            text: `#${part?.id}`,
            url: `${getAdminUrl() + routePaths.parts}/${part?.id}`,
          })
        : '';
    },
    header: 'Part #',
  },
  {
    getValue: ({ startDate }) => {
      return formatDate(startDate, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Start',
  },
  {
    getValue: ({ startedBy }) => {
      return startedBy?.id
        ? formatAsLinkForCSV({
            text: startedBy?.name,
            url: `${getAdminUrl() + routePaths.users}/${startedBy?.id}`,
          })
        : '';
    },
    header: 'Started By',
  },
  {
    getValue: ({ endDate }) => {
      return formatDate(endDate, DOWNLOAD_LONG_FORMAT);
    },
    header: 'End',
  },
  {
    getValue: ({ completedBy }) => {
      return completedBy?.id
        ? formatAsLinkForCSV({
            text: completedBy?.name,
            url: `${getAdminUrl() + routePaths.users}/${completedBy?.id}`,
          })
        : '';
    },
    header: 'Ended By',
  },
  {
    getValue: ({ endDate, startDate }) => {
      return startDate && endDate
        ? String(Math.round((Number(endDate) - Number(startDate)) / 1000 / 60))
        : '';
    },
    header: 'Actual Dur (m)',
  },
  {
    getValue: ({ stockId }) => {
      return stockId
        ? formatAsLinkForCSV({
            text: `#${stockId}`,
            url: `${getAdminUrl() + routePaths.stocks}/${stockId}`,
          })
        : '';
    },
    header: 'Stock #',
  },
  {
    getValue: ({ order }) => {
      return order?.id
        ? formatAsLinkForCSV({
            text: `${order.type} #${order.id}`,
            url: `${getAdminUrl() + routePaths.orders}/${order.id}`,
          })
        : '';
    },
    header: 'Order #',
  },
  {
    getValue: ({ instruction }) => {
      return instruction?.text;
    },
    header: 'Instructions (text)',
  },
  {
    getValue: ({ instruction }) => {
      if (instruction?.files) {
        return instruction.files.length === 1
          ? formatAsLinkForCSV({
              text: instruction.files[0].fileName,
              url: instruction.files[0].url,
            })
          : instruction.files
              .map(({ fileName }) => {
                return fileName;
              })
              .join(', ');
      }

      return '';
    },
    header: 'Instructions (pdf)',
  },
  {
    getValue: ({ inventoryRequests }) => {
      return getInventoriesListFromRequests(inventoryRequests).join(
        newlineSeparator,
      );
    },
    header: 'Inventories',
  },
  {
    getValue: ({ duration }) => {
      return duration;
    },
    header: 'Dur (m)',
  },
  {
    getValue: ({ machineType }) => {
      return machineType;
    },
    header: 'Machine Type',
  },
  {
    getValue: ({ machineIssues }) => {
      return machineIssues
        ?.map(({ type }) => {
          return type;
        })
        ?.join(newlineSeparator);
    },
    header: 'Machine Issues',
  },
  {
    getValue: ({ product }) => {
      return product?.handouts
        ?.map(({ link, name }) => {
          return product?.handouts?.length === 1
            ? formatAsLinkForCSV({
                text: name,
                url: link,
              })
            : name;
        })
        ?.join(newlineSeparator);
    },
    header: 'Handouts',
  },
  {
    getValue: ({ notes }) => {
      return notes;
    },
    header: 'Notes',
  },
  {
    getValue: ({ exceededCycleTimeReason }) => {
      return [exceededCycleTimeReason?.reason, exceededCycleTimeReason?.comment]
        .filter(Boolean)
        .join(' - ');
    },
    header: 'Assemble Assemble Exceeded Cycle Time Reason',
  },
];
