import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { GetDashboardReportSummaryQueryArgs } from '../../dashboard.types';
import { getDashboardOrdersSummary } from './get-dashboard-orders-summary';

export const useFetchDashboardOrdersSummary = (
  props: GetDashboardReportSummaryQueryArgs,
) => {
  const { args, configs, enabled } = props;

  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!args && enabled,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch dashboard orders summary.',
        });
      },
      queryFn: () => {
        return getDashboardOrdersSummary.query(args, configs);
      },
      queryKey: getDashboardOrdersSummary.key(args, configs),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
