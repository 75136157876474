import { memo } from 'react';
import { countries } from '../../../../../../../../constants/locales';
import { StyledFieldLabel } from '../../../../../../designer-styles';
import { Select } from '../../../../../../../../components/common/inputs';
import Cell from '../../../../../../../../components/common/cell';
import { CountrySelectProps } from './country-select.types';

const Section = (props: CountrySelectProps) => {
  const { country, handleSelectChange } = props;

  const options = countries.map(({ code, name: countryName }) => {
    return (
      <option key={countryName} value={code}>
        {countryName}
      </option>
    );
  });

  return (
    <Cell>
      <StyledFieldLabel text="Country" textStyle="bold" variant="p2" />
      <Select value={country} onChange={handleSelectChange('country')}>
        {options}
      </Select>
    </Cell>
  );
};

export const CountrySelect = memo(Section);
