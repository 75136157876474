import { Typography } from 'gantri-components';
import styled from 'styled-components';

export const StyledReasonLabel = styled(Typography)`
  > span {
    margin-left: 0.8rem;
  }
  svg {
    cursor: pointer;
  }
`;
