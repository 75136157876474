import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { activate } from './activate';
import {
  ActivateDesignerArgs,
  ActivateDesignerResponse,
} from './activate.types';

export const useUpdateDesignerActiveStatus = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onUpdateDesignerActiveStatus, ...rest } = useQueryMutation<
    ActivateDesignerArgs,
    ActivateDesignerResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update designer status.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(notice);
      },
    },
    mutationFn: async (args) => {
      return activate(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onUpdateDesignerActiveStatus };
};
