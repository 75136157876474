import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateRepairJobResponse,
  CreateRepairJobArgs,
} from './create-repair-job.types';

export const createRepairJob = (body: CreateRepairJobArgs) => {
  return axios.post<CreateRepairJobResponse>(`${coreApiUrl}/jobs`, body);
};
