import { css } from 'styled-components';

export const RealtimeRowUpdate = css`
  background-color: ${({ theme }) => {
    return `${theme.colors.palette.green_400}`;
  }} !important;
  transition: 3s ease-in;
`;

export const BaseTransition = css`
  transition: 0.15s ease-in-out;
`;

export const BorderTopDivider = css`
  border-top: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
`;
