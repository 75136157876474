import { useRef, ChangeEvent, memo } from 'react';
import { useClickAway, useToggle } from 'react-use';
import { ChromePicker } from 'react-color';
import { Typography, Grid, Cell, TextField } from 'gantri-components';
import {
  StyledColorInputContainer,
  StyledColorPopup,
} from '../../../../designer-styles';
import { ColorSectionProps } from './color-section.types';

const Section = (props: ColorSectionProps) => {
  const { theme, updateDesignerInfo } = props;

  const [backgroundPickerVisible, toggleBackgroundPickerVisible] =
    useToggle(false);
  const [highlightPickerVisible, toggleHighlightPickerVisible] =
    useToggle(false);

  const backgroundRef = useRef();

  useClickAway(backgroundRef, () => {
    toggleBackgroundPickerVisible();
  });

  const highlightRef = useRef();

  useClickAway(highlightRef, () => {
    toggleHighlightPickerVisible();
  });

  const updateTheme = (property: keyof typeof theme, value: string) => {
    updateDesignerInfo({
      key: 'theme',
      type: 'key',
      value: {
        ...theme,
        [property]: value,
      },
    });
  };

  const onBackgroundColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateTheme('background', event.currentTarget.value);
  };

  const onHighlightColorChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateTheme('highlight', event.currentTarget.value);
  };

  return (
    <>
      <Typography
        marginBottom="1rem"
        text="Color"
        textStyle="bold"
        variant="h4"
      />
      <Grid columns={{ lg: 3, sm: 1 }} rowGap="3rem">
        <Cell>
          <Typography
            marginBottom="5px"
            text="Background color"
            textStyle="bold"
          />
          <StyledColorInputContainer color={theme?.background}>
            <TextField
              value={theme?.background}
              onChange={onBackgroundColorChange}
              onFocus={toggleBackgroundPickerVisible}
            />
            {backgroundPickerVisible && (
              <StyledColorPopup ref={backgroundRef}>
                <ChromePicker
                  color={theme?.background}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('background', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
        <Cell>
          <Typography
            marginBottom="5px"
            text="Highlight color"
            textStyle="bold"
          />

          <StyledColorInputContainer color={theme?.highlight}>
            <TextField
              value={theme?.highlight}
              onChange={onHighlightColorChange}
              onFocus={toggleHighlightPickerVisible}
            />
            {highlightPickerVisible && (
              <StyledColorPopup ref={highlightRef}>
                <ChromePicker
                  color={theme?.highlight}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('highlight', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
      </Grid>
    </>
  );
};

export const ColorSection = memo(Section);
