import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { RenameFileResponse } from '../rename-file/rename-file.types';
import { DeleteFileArgs } from './delete-file.types';
import { deleteFile } from './delete-file';

export const useDeleteCloudinaryFile = <TransformedData = RenameFileResponse>(
  props?: GenericMutateQueryProps<
    DeleteFileArgs,
    RenameFileResponse,
    TransformedData
  >,
) => {
  const { onMutate: onDeleteFile, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to delete file.',
    mutationFn: deleteFile,
    ...props,
  });

  return { ...rest, onDeleteFile };
};
