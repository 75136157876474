import * as yup from 'yup';
import { machineStatuses, machineTypes } from '../../../../constants/machines';
import { AddOrEditMachineFormData } from './add-or-edit-machine-modal.types';

export const machineInitialValues: AddOrEditMachineFormData = {
  id: undefined,
  location: 'San Leandro',
  name: undefined,
  status: undefined,
  type: undefined,
};

const requiredText = 'Required';

export const addOrEditMachineSchema: yup.SchemaOf<AddOrEditMachineFormData> =
  yup.object().shape({
    id: yup.number(),
    location: yup.string().required(requiredText),
    name: yup.string().required(requiredText),
    status: yup
      .mixed()
      .oneOf(Object.values(machineStatuses))
      .required(requiredText),
    type: yup.mixed().oneOf(Object.values(machineTypes)).required(requiredText),
  });
