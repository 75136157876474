import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { JobStartDateCellProps } from './job-start-date-cell.types';

export const JobStartDateCell = ({ getValue, row }: JobStartDateCellProps) => {
  const { id, statusInfo } = row.original;

  const startDate = getValue();

  return !!startDate ? (
    <DateWithUserDataCell
      data={statusInfo}
      date={startDate}
      jobId={id}
      type="Start"
    />
  ) : null;
};
