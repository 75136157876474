import { FC, PropsWithChildren } from 'react';
import { Toggle, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  StyledAccountDropdown,
  StyledAccountDropdownDivider,
  StyledAccountDropdownItem,
  StyledToggleBox,
} from './user-settings-dropdown.styles';
import { usersApi } from '../../../../../api';
import { asyncLogOutAdmin } from '../../../../../actions/users';
import { environment } from '../../../../../environment';
import { UserSettingsDropdownProps } from './user-settings-dropdown.types';
import { userAtoms } from '../../../../../global-atoms/users';

export const UserSettingsDropdown: FC<
  PropsWithChildren<UserSettingsDropdownProps>
> = () => {
  const [theme, setTheme] = useRecoilState(userAtoms.theme);

  const saveTheme = async (darkModeActive: boolean) => {
    const theme = darkModeActive ? 'dark' : 'light';

    setTheme(theme);
    await usersApi.setTheme(theme);
  };

  return (
    <StyledAccountDropdown>
      <StyledAccountDropdownItem>
        <a href={`${environment.SHOP_URL}/account/settings`}>
          <Typography text="Settings" />
        </a>
      </StyledAccountDropdownItem>
      <StyledAccountDropdownItem>
        <Typography text="Dark Theme" />
        <StyledToggleBox>
          <Toggle value={theme === 'dark'} onSelected={saveTheme} />
        </StyledToggleBox>
      </StyledAccountDropdownItem>
      <StyledAccountDropdownDivider />
      <StyledAccountDropdownItem onClick={asyncLogOutAdmin}>
        <Typography color="alert" text="Logout" />
      </StyledAccountDropdownItem>
    </StyledAccountDropdown>
  );
};
