import { FC } from 'react';
import { createGlobalHandoutCss } from './helpers/create-global-handout-css';
import { Page } from './components/page';
import { BackCard } from './components/back-card';
import { getBackCardCss } from './components/back-card/helpers/get-back-card-css';
import { FrontCard } from './components/front-card';
import { getFrontCardCss } from './components/front-card/helpers/get-front-card-css';
import { HandoutProps } from './handout.types';

export const Handout: FC<HandoutProps> = ({ designerInfo }) => {
  const { handout } = designerInfo;
  const css =
    createGlobalHandoutCss() +
    getFrontCardCss(handout) +
    getBackCardCss(handout);

  return (
    <>
      <style>{css}</style>
      <Page>
        <FrontCard designerInfo={designerInfo} />
      </Page>
      <Page>
        <BackCard designerInfo={designerInfo} />
      </Page>
    </>
  );
};
