import { Grid, Typography } from 'gantri-components';
import { inventoryTypes } from '../../../../../../../../constants/options';
import { ConfirmUsedInventoryContentProps } from './confirm-used-inventory-content.types';
import { StyledTileInfo } from './confirm-used-inventory-content.styles';
import { EditableInventoriesTable } from '../../../../../../../../components/common/editable-inventories-table';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';

export const ConfirmUsedInventoryContent = (
  props: ConfirmUsedInventoryContentProps,
) => {
  const { inventoryRequests, setInventoryRequests } = props;

  return (
    <>
      <Grid gap="unset">
        <ModalContentHeading
          subTitleText="Enter the quantity of all inventory used during the repair:"
          titleText="Repair Inventory"
        />
        <StyledTileInfo>
          <li>
            <Typography htmlTag="strong" text="Used: " textStyle="bold" />
            <Typography text="used in stock or damaged during repair" />
          </li>
          <li>
            <Typography htmlTag="strong" text="Discarded: " textStyle="bold" />
            <Typography text="issue from manufacturer (low quality, doesn’t work)" />
          </li>
        </StyledTileInfo>
      </Grid>

      <EditableInventoriesTable
        inventoryRequests={inventoryRequests}
        limitToInventoryTypes={[inventoryTypes.machinePart]}
        trackDiscarded
        onEdit={setInventoryRequests}
      />
    </>
  );
};
