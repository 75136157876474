import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateLocationArgs,
  UpdateLocationResponse,
} from './update-location.types';

export const updateLocation = (args: UpdateLocationArgs) => {
  const { id, locationId, source } = args;

  return axios.put<UpdateLocationResponse>(
    `${coreApiUrl}/parts/${id}/location`,
    { locationId, source },
  );
};
