import { useEffect, useState } from 'react';
import ReplacementReason from './replacement-reason';
import RefundReason from './refund-reason';
import ReassignStock from './reassign-stock/reassign-stock';
import { useRouter } from '../../hooks';
import { useNotification } from '../../hooks/useNotification';
import { transactionsApi } from '../../api';

const OrderReason = () => {
  const { params } = useRouter();
  const { notifyAxiosError, onInterceptRequest } = useNotification();
  const { id, type } = params;
  const [currentOrderData, setCurrentOrderData] = useState(false);

  const fetchOrder = async () => {
    await onInterceptRequest(async () => {
      if (id) {
        try {
          const { data } = await transactionsApi.getTransaction({
            id,
          });

          if (data && data.success) {
            setCurrentOrderData(data.order);
          }
        } catch (error) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to fetch order.',
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchOrder();
  }, []);

  if (type === 'replacement' && currentOrderData)
    return <ReplacementReason currentOrderData={currentOrderData} id={id} />;
  if (type === 'refund' && currentOrderData)
    return <RefundReason currentOrderData={currentOrderData} id={id} />;
  if (type === 'return' && currentOrderData)
    return (
      <RefundReason currentOrderData={currentOrderData} id={id} isReturn />
    );
  if (type === 'reassign-stock' && currentOrderData)
    return <ReassignStock currentOrderData={currentOrderData} id={id} />;

  return null;
};

export default OrderReason;
