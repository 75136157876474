import {
  bleedSize,
  cardHeight,
  cardWidth,
} from '../../../../helpers/create-global-handout-css/create-global-handout-css';
import { GetBackCardCss } from './get-back-card-css.types';

/** Added to center content offset by line height */
const lineHeightOverride = 3;

export const getBackCardCss: GetBackCardCss = ({
  backBackgroundColor,
  backTextColor,
}) => {
  return `
  .handout--back-bleed-border {
    background-color: ${backBackgroundColor};
    color: ${backTextColor};
  }

  .handout--back-bottom-content {
    width: 100%;
    height: ${cardHeight - cardWidth - bleedSize - lineHeightOverride}px;
  }

  .handout--back-bottom-content td{
    vertical-align: middle;
  }

  .handout--back-image-wrapper {
    width: 100%;
    height: ${cardWidth}px;
    padding-bottom: ${bleedSize}px;
  }

  .handout--back-image-wrapper img {
    position: relative;
    top: -${bleedSize}px;
    left: -${bleedSize}px;
    height: ${cardWidth + bleedSize * 2}px;
    width: ${cardWidth + bleedSize * 2}px;
    max-width: unset;
  }

  .handout--back-logo-wrapper {
    padding: 5px 10px;
  }

  .handout--back-name-wrapper {
    padding: 0 10px;
  }

  .handout--back-location-wrapper {
    padding: 0 10px;
  }
  `;
};
