import {
  Cell,
  Flex,
  Grid,
  TextArea,
  TextField,
  Typography,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { getActiveEnv } from '../../../../../../helpers/get-active-env';
import { useCloudinaryFileUploader } from '../../../../../../hooks/use-cloudinary-file-uploader';
import { ValueProps } from '../../../../desginer-feature.types';
import { ValuePropProps } from './value-prop.types';

export const ValueProp = (props: ValuePropProps) => {
  const {
    allValueProps,
    id,
    index,
    updateFeatureInfo,
    userId,
    valueProp,
    valuePropErrors,
  } = props;
  const { ctaLink, ctaTitle, paragraph, photo, title } = valueProp;

  const getOnInputChange = (property: keyof ValueProps, index: number) => {
    return (value: string) => {
      updateFeatureInfo({
        key: 'valueProps',
        type: 'key',
        value: allValueProps.map((prop, i) => {
          return i === index
            ? {
                ...prop,
                [property]: value,
              }
            : prop;
        }),
      });
    };
  };

  const removeValueProp = (position: number) => {
    updateFeatureInfo({
      key: 'valueProps',
      type: 'key',
      value: allValueProps.filter((_prop, index: number) => {
        return index !== position;
      }),
    });
  };

  const getHandleOnFileSelected = (index: number) => {
    const handleUploadsComplete: HandleUploadsComplete = async ([
      { fileName },
    ]) => {
      updateFeatureInfo({
        key: 'valueProps',
        type: 'key',
        value: allValueProps.map((prop, i) => {
          return i === index ? { ...prop, photo: fileName } : prop;
        }),
      });
    };

    return handleUploadsComplete;
  };

  const getHandleOnFileDelete = (index: number) => {
    const onFileDelete: HandleDeleteFile = async () => {
      updateFeatureInfo({
        key: 'valueProps',
        type: 'key',
        value: allValueProps.map((prop, i) => {
          return i === index ? { ...prop, photo: '' } : prop;
        }),
      });
    };

    return onFileDelete;
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'designers'>({
    directory: 'designers',
    fileName: photo,
    fileType: 'collection-pages-value-props',
    handleUploadsComplete: getHandleOnFileSelected(index),
    identifiers: {
      collectionId: id,
      userId,
    },
    onFileDelete: getHandleOnFileDelete(index),
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace('image'));
    },
  });

  const { isLocalEnv } = getActiveEnv();

  return (
    <>
      <Flex>
        <Typography
          marginBottom="1.1rem"
          marginTop="1.1rem"
          text={`Value prop #${index + 1}`}
          textStyle="bold"
          variant="h5"
        />
        <Typography
          align="right"
          color="alert"
          decoration="underline"
          marginLeft="0.8rem"
          marginTop="0.95rem"
          style={{
            cursor: 'pointer',
          }}
          text="Remove"
          onClick={() => {
            return removeValueProp(index);
          }}
        />
      </Flex>
      <Grid alignItems="start" columns={{ lg: 'max-content 1fr' }}>
        <Cell>
          <Typography marginBottom="5px" text="Image" textStyle="bold" />
          <FileUploader
            {...fileUploaderProps}
            enableCopyUrl={isLocalEnv}
            variant="thumbnail"
          />
        </Cell>
        <Cell>
          <Grid columns={{ lg: 1 }}>
            <TextField
              debounce={500}
              errorMessage={
                valuePropErrors[index]?.title?.error ? 'Required' : null
              }
              labelPosition="top"
              labelText="Title"
              placeholder="Title..."
              required
              value={title}
              onBlurTextChange={getOnInputChange('title', index)}
            />
            <Flex gap="2.9rem">
              <TextField
                debounce={500}
                labelPosition="top"
                labelText="CTA Title"
                placeholder="CTA title..."
                value={ctaTitle}
                onBlurTextChange={getOnInputChange('ctaTitle', index)}
              />
              <TextField
                debounce={500}
                labelPosition="top"
                labelText="CTA Link"
                placeholder="CTA link..."
                value={ctaLink}
                onBlurTextChange={getOnInputChange('ctaLink', index)}
              />
            </Flex>
            <TextArea
              debounce={500}
              labelPosition="top"
              labelText="Paragraph"
              placeholder="Paragraph..."
              value={paragraph}
              onTextChange={getOnInputChange('paragraph', index)}
            />
          </Grid>
        </Cell>
      </Grid>
    </>
  );
};
