import {
  FileUploaderProps,
  HandleDeleteFile,
  HandleUploadFile,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { UseFirebaseFileUploaderArgs } from './use-firebase-file-uploader.types';
import { deleteBlob, useUploadBlob } from '../../helpers/firebase';
import { useNotification } from '../useNotification';
import {
  fileUploaderAtoms,
  isUploadingDisabledMessage,
} from '../use-cloudinary-file-uploader/use-cloudinary-file-uploader.atoms';

export const useFirebaseFileUploader = (props: UseFirebaseFileUploaderArgs) => {
  const {
    fileUrl,
    handleUploadsComplete,
    isGcode,
    onFileDelete: updateDbOnFileDelete,
    onFileSelected,
    path,
    showLoading = false,
    validateFile,
  } = props;

  const [isUploading, setIsUploading] = useRecoilState(
    fileUploaderAtoms.isUploading,
  );

  const {
    notifyAxiosError,
    notifyError,
    onInterceptProcessingRequest,
    processing,
  } = useNotification();

  const uploadBlob = useUploadBlob();

  const handleUploadFile: HandleUploadFile = async ({
    fileBlob,
    fileName,
    metadata,
  }) => {
    return onInterceptProcessingRequest(async () => {
      let uploadedFileUrl = '';

      setIsUploading(true);

      await uploadBlob({
        blob: fileBlob,
        displayLoader: showLoading,
        filename: fileName,
        metadata,
        onComplete: async (url, relativePath) => {
          uploadedFileUrl = url;

          await handleUploadsComplete([
            {
              fileName,
              fileSize: fileBlob.size,
              fileUrl: url,
              relativePath,
            },
          ]);
        },
        path,
        replacing: !!fileUrl,
        type: isGcode ? 'gcode' : undefined,
      });

      return { fileName, fileUrl: uploadedFileUrl };
    });
  };

  const handleDeleteFile: HandleDeleteFile = async (props) => {
    await onInterceptProcessingRequest(async () => {
      const { isReplacing } = props;

      if (!isReplacing) {
        await updateDbOnFileDelete?.(props);
      }

      return deleteBlob({ fileUrl, onError: notifyError });
    });
  };

  const handleDeleteFileOnly = async () => {
    return deleteBlob({ fileUrl, onError: notifyError });
  };

  const fileUploaderProps = {
    disabledDescription: isUploading ? isUploadingDisabledMessage : undefined,
    fileUrl,
    handleDeleteFile,
    handleFileSelected: onFileSelected as never,
    handleUploadFile: onFileSelected ? undefined : handleUploadFile,
    handleUploadsComplete: async () => {
      setIsUploading(false);
    },
    isDisabled: isUploading,
    maxFileSizeMB: 2,
    onError: (args) => {
      return notifyAxiosError(args, { keepOpen: true });
    },
    processing,
    purifyFileName: true,
    validateFile,
  } satisfies Partial<FileUploaderProps>;

  return {
    fileUploaderProps,
    /** Use this to delete the file, but not update the database. */
    handleDeleteFileOnly,
    /** Use this if the file is not immediately uploaded on selection. */
    handleUploadFile,
    processing,
  };
};
