import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';
import { TransactionFormState } from './new-inventory-transaction.types';

export const transactionInit: TransactionFormState = {
  cost: undefined,
  notes: '',
  stock: undefined,
  stockChange: '',
  transactionDate: '',
  type: '',
};

export const typeOptions = convertStringArrayToValueLabelArray([
  'Purchase',
  'R&D',
  'Sales',
  'Discarded',
]);

export const discardReasonOptions = convertStringArrayToValueLabelArray([
  'Shipper error',
  'Manufacturer error',
  'Specification error',
]);
