import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import {
  Checklist,
  ChecklistItem,
} from '../../common/job-checklist/job-checklist.types';

/** Checks against every `checklistItem` using `Array.some()`. */
export const checkAgainstChecklistItems = <KeyType extends FailedReason>(
  checklist: Partial<Checklist<KeyType>>,
  callback: (checklistItem: ChecklistItem) => boolean,
): boolean => {
  const reasons = Object.keys(checklist) as KeyType[];

  return reasons.some((reason) => {
    const checklistItem = checklist[reason];

    return callback(checklistItem);
  });
};
