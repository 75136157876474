import { FC, useEffect, useMemo, useRef } from 'react';
import { useToggle } from 'react-use';
import { Formik } from 'formik';
import pick from 'lodash/pick';
import { Conditional } from 'gantri-components';
import { FormikProps } from 'formik/dist/types';
import { AddressProps } from './address.types';
import { EditAddress, ViewAddress } from './mode';
import { EditableTextRow } from '../../../../components/common/editable-text-row';
import { StyledForm } from './mode/edit-address.styles';
import { UpdateTransactionAddressArgs } from '../../../../api/transactions/routes/update-transaction-address/update-transaction-address.types';
import { EditAddressSchema } from './edit-address.schema';
import { countries } from '../../../../constants/locales';
import { transactionsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';

export const Address: FC<AddressProps> = (props) => {
  const { currentOrderData, setCurrentOrderData } = props;
  const { address, id: orderId } = currentOrderData;
  const strippedPhone = address.phone?.toString()?.replace(/[\s-]+/g, '');
  // support legacy saved values
  const countryInstance = useMemo(() => {
    return countries.find(({ code, name }) => {
      return name === address.country || code === address.country;
    });
  }, [address.country]);

  const initialValues = {
    phone: strippedPhone,
    ...pick(address, [
      'city',
      'country',
      'doNotShip',
      'firstName',
      'lastName',
      'state',
      'street',
      'unit',
      'zip',
    ]),
    country: countryInstance?.code || address.country,
  };

  const { notify, notifyAxiosError } = useNotification();
  const [isEditModeActive, toggleEditMode] = useToggle(false);
  const formRef =
    useRef<FormikProps<UpdateTransactionAddressArgs['address']>>();

  useEffect(() => {
    if (!isEditModeActive) {
      formRef.current?.setValues(initialValues);
    }
  }, [isEditModeActive]);

  const onSave = async (formValues) => {
    try {
      if (!isEditModeActive) return;

      const { data } = await transactionsApi.updateTransactionAddress({
        address: formValues.doNotShip
          ? { doNotShip: true }
          : { ...formValues, phone: formValues.phone?.toString() },
        transactionId: orderId,
      });

      if (!data?.success) return;

      notify(data.notice);

      setCurrentOrderData(data.order);
      toggleEditMode();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update order address.',
      });
    }
  };

  const selectAddress = (address) => {
    formRef.current?.setValues({
      ...initialValues,
      city: address.city,
      country: address.country,
      state: address.state,
      street: address.street,
      zip: address.zip,
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      innerRef={formRef}
      validateOnChange
      validationSchema={EditAddressSchema}
      onSubmit={(values) => {
        return onSave(values);
      }}
    >
      {(formikProps) => {
        return (
          <StyledForm onSubmit={formikProps.handleSubmit}>
            <EditableTextRow
              editButtonText="Change"
              editing={isEditModeActive}
              marginBottom="2x"
              text="Shipping"
              onCancel={toggleEditMode}
              onEdit={toggleEditMode}
            />

            <Conditional
              condition={isEditModeActive}
              Fallback={<ViewAddress {...props} />}
            >
              <EditAddress selectAddress={selectAddress} />
            </Conditional>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
