import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getFailingReasonsFromChecklist } from '../../../../../helpers/get-failing-reasons-from-checklist';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { completeJobText } from './job-checklist-footer.constants';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { usePassOrFailJob } from './hooks/use-pass-or-fail-job';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';
import { JobChecklistFooterProps } from './job-checklist-footer.types';

export const JobChecklistFooter = (props: JobChecklistFooterProps) => {
  const { handleCloseModal } = props;

  const [isJobChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completeAssemblyModalAtoms.isJobChecklistReviewed,
  );
  const jobChecklist = useRecoilValue(completeAssemblyModalAtoms.jobChecklist);
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);

  const { onComplete, onFail, processing, unableToLoadFailRequirements } =
    usePassOrFailJob({
      handleCloseModal,
    });

  const failingChecklistKeys = getFailingReasonsFromChecklist(jobChecklist);
  const isFailure = !!failingChecklistKeys.length;

  const missingRequiredFailDetails = checkAgainstFailingChecklistItems(
    jobChecklist,
    ({ details, requireDetailsOnFail }) => {
      return !details && requireDetailsOnFail;
    },
  );

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setIsChecklistReviewed(false);
          setStep('INVENTORY_CHECKLIST');
        }}
      />

      <CompleteChecklistButtonWrapper checklist={jobChecklist}>
        <Button
          disabled={
            !isJobChecklistReviewed ||
            missingRequiredFailDetails ||
            unableToLoadFailRequirements
          }
          processing={processing}
          size={fullScreenModalFooterButtonSize}
          text={
            isFailure
              ? completeJobText.nextButtonJobFailed
              : completeJobText.nextButtonJobPassed
          }
          variant={isFailure ? 'primaryAlert' : 'primary'}
          onClick={isFailure ? onFail : onComplete}
        />
      </CompleteChecklistButtonWrapper>
    </>
  );
};
