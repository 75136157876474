import {
  Conditional,
  FormikInput,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import {
  productJobsTabAtoms,
  stockJobsSections,
} from '../../../../product-jobs.atoms';
import {
  defaultJobBlockDurations,
  detailsGridColumns,
} from '../../../parts-section/components/part-job-blocks/part-job-blocks.constants';
import { formatAsLocaleNumber } from '../../../../../../../../helpers/formatter';
import { productAtoms } from '../../../../../../product.atoms';
import {
  getStockJobsDurationInitialValues,
  StockJobsDurationFormData,
  stockJobsDurationFormikSchema,
} from './stock-jobs-durations.schema';
import { Label } from '../../../../../../../../components/label';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { StockJobsHeader } from '../stock-jobs-header';
import { useUpdateProductJobBlocks } from '../../../../../../../../api/products/routes/update-product-job-blocks';
import { jobBlockTypes } from '../../../../../../../../api/products/routes/update-product-job-blocks/update-product-job-blocks.types';
import { getIsStockJobsDurationValid } from '../../helpers/get-is-stock-jobs-block-valid';

export const StockJobsDurations = () => {
  const product = useRecoilValue(productAtoms.product);
  const isAccessory = useRecoilValue(productAtoms.isAccessory);
  const isEditingStockJobsSection = useRecoilValue(
    productJobsTabAtoms.isEditingStockJobsSection,
  );
  const resetIsEditingStockJobsSection = useResetRecoilState(
    productJobsTabAtoms.isEditingStockJobsSection,
  );

  const stockBlock = product?.stockBlock;

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const { onUpdateProductJobBlocks } = useUpdateProductJobBlocks({
    onSuccess: async () => {
      await invalidateFetchProductCache();
      resetIsEditingStockJobsSection();
    },
  });

  const onSubmit = async (values: StockJobsDurationFormData) => {
    await onUpdateProductJobBlocks({
      id: stockBlock.id,
      jobBlock: {
        stockBlock: {
          ...stockBlock,
          ...values,
        },
        type: jobBlockTypes.stock,
      },
      variantIds: product.variants.map(({ id }) => {
        return id;
      }),
    });
  };

  const initialValues = useMemo(() => {
    return getStockJobsDurationInitialValues({
      isAccessory,
      stockJobsBlock: stockBlock,
    });
  }, [stockBlock]);

  const sectionId = stockJobsSections.durations;
  const isEditing = isEditingStockJobsSection === sectionId;

  const isStockJobsDurationInvalid = !getIsStockJobsDurationValid({
    isAccessory,
    stockJobsBlock: product?.stockBlock,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={stockJobsDurationFormikSchema}
      onSubmit={onSubmit}
    >
      {({ values }) => {
        const { assembleDuration, packDuration, qcDuration, stageDuration } =
          values;

        const formattedStageDuration = stageDuration
          ? `${formatAsLocaleNumber(stageDuration)}m`
          : '-';
        const formattedAssembleDuration = assembleDuration
          ? `${formatAsLocaleNumber(assembleDuration)}m`
          : '-';
        const formattedQcDuration = qcDuration
          ? `${formatAsLocaleNumber(qcDuration)}m`
          : '-';
        const formattedPackDuration = packDuration
          ? `${formatAsLocaleNumber(packDuration)}m`
          : '-';

        return (
          <Form>
            <Stack gap="x">
              <StockJobsHeader
                sectionId={sectionId}
                showInvalidIcon={isStockJobsDurationInvalid}
                text="Stock Jobs Durations"
              />
              <Grid columns={detailsGridColumns} gap="x">
                <Conditional condition={!isAccessory}>
                  <Label text="Stage Duration" />
                  <Conditional
                    condition={isEditing}
                    Fallback={<Typography text={formattedStageDuration} />}
                  >
                    <FormikInput
                      Field={
                        <TextField
                          placeholder={`Suggested: ${defaultJobBlockDurations.assembleStage}`}
                          rightIcon={<Typography color="t2" text="m" />}
                          type="number"
                        />
                      }
                      fieldVariant="standard"
                      name="stageDuration"
                      required
                    />
                  </Conditional>

                  <Label text="Assemble Duration" />
                  <Conditional
                    condition={isEditing}
                    Fallback={<Typography text={formattedAssembleDuration} />}
                  >
                    <FormikInput
                      Field={
                        <TextField
                          placeholder="Input duration"
                          rightIcon={<Typography color="t2" text="m" />}
                          type="number"
                        />
                      }
                      fieldVariant="standard"
                      name="assembleDuration"
                      required
                    />
                  </Conditional>

                  <Label text="QC Duration" />
                  <Conditional
                    condition={isEditing}
                    Fallback={<Typography text={formattedQcDuration} />}
                  >
                    <FormikInput
                      Field={
                        <TextField
                          placeholder={`Suggested: ${defaultJobBlockDurations.qaQc}`}
                          rightIcon={<Typography color="t2" text="m" />}
                          type="number"
                        />
                      }
                      fieldVariant="standard"
                      name="qcDuration"
                      required
                    />
                  </Conditional>
                </Conditional>

                <Label text="Pack Duration" />
                <Conditional
                  condition={isEditing}
                  Fallback={<Typography text={formattedPackDuration} />}
                >
                  <FormikInput
                    Field={
                      <TextField
                        placeholder={`Suggested: ${defaultJobBlockDurations.packPack}`}
                        rightIcon={<Typography color="t2" text="m" />}
                        type="number"
                      />
                    }
                    fieldVariant="standard"
                    name="packDuration"
                    required
                  />
                </Conditional>
              </Grid>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
