import { memo } from 'react';
import {
  CheckboxList,
  OnCheckboxListValueChange,
  Typography,
} from 'gantri-components';
import { sortBy } from 'lodash';
import { awardOptions } from '../../profile.constants';
import { DesignerAward } from '../../../../../../types/store';
import { AwardsReceivedProps } from './awards-received.types';

const Section = (props: AwardsReceivedProps) => {
  const { awards, updateDesignerInfo } = props;

  const onValueChange: OnCheckboxListValueChange<DesignerAward> = (
    selectedValues,
  ) => {
    const formattedValues: DesignerAward[] = selectedValues.map((value) => {
      return {
        award: awardOptions.find((item) => {
          return item.award === value;
        }).award,
        code: value,
      };
    });

    updateDesignerInfo({
      key: 'awards',
      type: 'key',
      value: formattedValues,
    });
  };

  const sortedAwards = sortBy(awardOptions, 'award');

  return (
    <>
      <Typography
        marginBottom="1rem"
        marginTop="6rem"
        text="Awards received"
        textStyle="bold"
      />
      <CheckboxList
        gridProps={{ columns: { lg: 3, sm: 1 } }}
        idProperty="code"
        items={sortedAwards}
        labelProperty="award"
        values={awards.map(({ code }) => {
          return code;
        })}
        onValueChange={onValueChange}
      />
    </>
  );
};

export const AwardsReceived = memo(Section);
