import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQuery,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { getInventoryInfo } from './get-inventory-info';

interface UseGetInventoryQueryProps {
  enabled?: boolean;
  id: number;
}

export const useGetInventoryInfoQuery = (props: UseGetInventoryQueryProps) => {
  const { enabled = true, id } = props;
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      cacheTime: 0,
      enabled: enabled && !!id,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventory info.',
        });
      },
      queryFn: () => {
        return getInventoryInfo.query(id);
      },
      queryKey: getInventoryInfo.key(id),
    },
    showLoading: true,
  });

  return { inventory: data, isLoading };
};

export const useInvalidateGetInventoryCache = () => {
  const invalidate = useInvalidateQuery();

  const invalidateCache = async (id: number) => {
    await invalidate(getInventoryInfo.key(id));
  };

  return invalidateCache;
};
