import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  GetUploadSignatureArgs,
  GetUploadSignatureResponse,
} from './get-upload-signature.types';

export const getUploadSignature = (args: GetUploadSignatureArgs) => {
  const { folder, fromPublicId, publicId, toPublicId, uploadPreset } = args;

  return axios.post<GetUploadSignatureResponse>(
    `${baseApiUrl}/cloudinary/signature`,
    {
      params: {
        folder,
        from_public_id: fromPublicId,
        public_id: publicId,
        to_public_id: toPublicId,
        upload_preset: uploadPreset,
      },
    },
  );
};
