import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportJobsArgs,
  GetReportJobsResponse,
} from './get-report-jobs.types';

export const getReportJobs = (
  { rangeType, ...queries }: GetReportJobsArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportJobsResponse>(
    `${coreApiUrl}/reports/jobs${queriesString}`,
    configs,
  );
};
