import { useToggle } from 'react-use';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { GeneralSectionProps } from './general-section.types';
import {
  MachineStatus,
  OpenIssue,
} from '../../../../api/machines/routes/get-overview';
import { userAtoms } from '../../../../global-atoms/users';
import { userAccessDeniedMessage } from '../../../../constants/users';
import { useUserHasAccessLevel } from '../../../../hooks/use-user-has-access-level';

type Props = {
  hasLeadAccess: boolean;
  isOverviewTabActive: boolean;
  openIssues: OpenIssue[];
  status: MachineStatus;
};

const getTooltipMessage = ({ hasLeadAccess, openIssues, status }: Props) => {
  if (status === 'In progress')
    return 'Can not activate or deactivate a machine in progress.';
  if (!hasLeadAccess) return userAccessDeniedMessage;
  if (status === 'Offline' && openIssues?.length > 0)
    return 'Please complete all repairs and resolve all open machine issues.';
};

const canActivateEditStatus = ({
  hasLeadAccess,
  isOverviewTabActive,
  openIssues,
  status,
}: Props) => {
  if (!hasLeadAccess) return false;
  if (status === 'In progress') return false;
  if (status === 'Offline' && openIssues?.length) return false;
  if (!isOverviewTabActive) return false;

  return true;
};

export const useEditStatusState = (props: GeneralSectionProps) => {
  const { currentMachine, isEditModeActive, isOverviewTabActive } = props;
  const [isEditStatusActive, setIsEditStatusActive] = useToggle(false);
  const { status } = currentMachine;

  const { type } = useRecoilValue(userAtoms.user);
  const { hasLeadAccess } = useUserHasAccessLevel();

  const helperProps = {
    hasLeadAccess,
    isOverviewTabActive,
    openIssues: currentMachine.openIssues,
    status,
  };

  const canActivateEditStatusBtn = canActivateEditStatus(helperProps);

  const showEditTooltip =
    type !== 'Admin' ||
    status === 'In progress' ||
    (status === 'Offline' && currentMachine.openIssues?.length > 0);

  const editTooltipMessage = getTooltipMessage(helperProps);

  useEffect(() => {
    if (isEditModeActive) {
      setIsEditStatusActive(false);
    }
  }, [isEditModeActive]);

  return {
    canActivateEditStatusBtn,
    editTooltipMessage,
    isEditStatusActive,
    setIsEditStatusActive,
    showEditTooltip,
  };
};
