import { useRecoilState } from 'recoil';
import { Stack } from 'gantri-components';
import { useEffect } from 'react';
import { rejectJobModalAtoms } from '../../../reject-job-modal.atoms';
import { RecommendedStepOption } from '../../../../../../pages/jobs/components/modals/complete-job/complete-finishing-qa-modal/components/step-job-failed-recommended-next-step/job-failed-recommended-next-step-content/components/recommended-step-option';
import { ModalContentHeading } from '../../../../../../pages/jobs/components/modals/common/modal-content-heading';
import { NextStepContentProps } from './next-step-content.types';
import { getNextStepOptions } from './helpers/get-next-step-options';

export const NextStepContent = (props: NextStepContentProps) => {
  const { job } = props;

  const [selectedNextStep, setSelectedNextStep] = useRecoilState(
    rejectJobModalAtoms.nextStep,
  );

  const availableOptions = getNextStepOptions(job);
  const onlyOneOption = availableOptions.length === 1;

  useEffect(() => {
    if (onlyOneOption) {
      setSelectedNextStep(availableOptions[0].step);
    }
  }, [availableOptions]);

  return (
    <Stack gap="2x" verticalPadding="2x">
      <ModalContentHeading
        subTitleText={
          onlyOneOption
            ? 'Review the following next step and confirm'
            : "Please select how you'd like to proceed with the rejected job."
        }
        titleText="Next Steps"
      />
      {availableOptions.map(({ icon, label, step, subText }) => {
        return (
          <RecommendedStepOption
            key={step}
            hideRadio={onlyOneOption}
            icon={icon}
            label={label}
            selectedNextStep={selectedNextStep}
            setSelectedNextStep={setSelectedNextStep}
            step={step}
            subText={subText}
            systemRecommendedStep={null}
          />
        );
      })}
    </Stack>
  );
};
