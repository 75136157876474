import { CellContext, ColumnDef } from '@tanstack/react-table';
import { NumberCell } from '../common/number-cell';
import { StocksTableData } from './dashboard-stocks-summary.types';

export const ordersTableColumns: ColumnDef<StocksTableData>[] = [
  {
    accessorKey: 'type',
    header: 'Stock type',
    size: 80,
  },
  {
    accessorKey: 'completed',
    cell: ({
      getValue,
    }: CellContext<StocksTableData, StocksTableData['completed']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Completed',
    size: 80,
  },
  {
    accessorKey: 'waiting',
    cell: ({
      getValue,
    }: CellContext<StocksTableData, StocksTableData['waiting']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Waiting',
    size: 80,
  },
  {
    accessorKey: 'printing',
    cell: ({
      getValue,
    }: CellContext<StocksTableData, StocksTableData['printing']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Printing',
    size: 80,
  },
  {
    accessorKey: 'finishing',
    cell: ({
      getValue,
    }: CellContext<StocksTableData, StocksTableData['finishing']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Finishing',
    size: 80,
  },
  {
    accessorKey: 'assemble_qa_packing',
    cell: ({
      getValue,
    }: CellContext<
      StocksTableData,
      StocksTableData['assemble_qa_packing']
    >) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Assembly, QA, Packing',
  },
];
