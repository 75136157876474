import {
  ChangeEvent,
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Typography,
  TextField,
  Grid,
  Icon,
  Conditional,
} from 'gantri-components';
import { useAsync } from 'react-use';
import { Comment } from './components/comment';
import { Note } from './components/note';
import { getExtension, useUploadBlob } from '../../../../helpers/firebase';
import {
  Design,
  DesignMessage,
  DesignNote,
} from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { designsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import {
  StyledAttachmentTypography,
  StyledContent,
  StyledContentContainer,
  StyledFooter,
  StyledHeader,
  StyledInputContainer,
  StyledInstructions,
  StyledMessagesContainer,
  StyledMessagesIconLarge,
  StyledMessagesIconSmall,
  StyledNotesIconLarge,
  StyledNotesIconSmall,
  StyledSendButton,
} from './messages.styles';

interface MessagesProps {
  current: Design;
  designId: number;
  messages: DesignMessage[];
  notes: DesignNote[];
  updateMessages: Dispatch<SetStateAction<Design>>;
}

export const Messages = (props: MessagesProps) => {
  const { current, designId, messages, notes, updateMessages } = props;

  const [message, setMessage] = useState('');
  const [collapsed, setCollapsed] = useState(false);
  const [messagesTabActive, setMessagesTabActive] = useState(true);
  const anchorRef = useRef<HTMLDivElement>();
  const attachmentRef = useRef<HTMLInputElement>();

  const { notifyAxiosError } = useNotification();

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const showMessages = () => {
    setMessagesTabActive(true);

    if (collapsed) {
      setCollapsed(false);
    }
  };

  const showNotes = () => {
    setMessagesTabActive(false);

    if (collapsed) {
      setCollapsed(false);
    }
  };

  useEffect(() => {
    anchorRef.current.scrollIntoView();
    setMessage('');
  }, [messagesTabActive]);

  const sendMessage = async () => {
    if (message) {
      try {
        const { data } = await designsApi.sendMessage(designId, {
          content: message,
        });

        if (data.success) {
          updateMessages({ ...current, messages: data.messages });
        }

        anchorRef.current.scrollIntoView();
        setMessage('');
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to send message.' });
      }
    }
  };

  const sendFile = async (fileData: {
    fileExtension: string;
    fileName: string;
    fileSize: number;
    fileUrl: string;
  }) => {
    try {
      const { data } = await designsApi.sendMessage(designId, {
        file: fileData,
      });

      if (data.success) {
        updateMessages({ ...current, messages: data.messages });
      }

      anchorRef.current.scrollIntoView();
      setMessage('');
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to send file.' });
    }
  };

  const sendNote = async () => {
    if (message) {
      try {
        const { data } = await designsApi.sendNote(designId, message);

        if (data.success) {
          updateMessages({ ...current, notes: data.notes });
        }

        anchorRef.current.scrollIntoView();
        setMessage('');
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to send note.' });
      }
    }
  };

  const onNoteEdit = async (noteId: number, content: string) => {
    try {
      const { data } = await designsApi.editNote(noteId, content);

      if (data.success) {
        updateMessages({ ...current, notes: data.notes });
      }

      anchorRef.current.scrollIntoView();
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to edit note.' });
    }
  };

  const onNoteDelete = async (noteId: number) => {
    try {
      const { data } = await designsApi.deleteNote(noteId);

      if (data.success) {
        updateMessages({ ...current, notes: data.notes });
      }
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to delete note.' });
    }
  };

  const saveMessage = async () => {
    if (messagesTabActive) {
      await sendMessage();
    } else {
      await sendNote();
    }
  };

  const onMessageKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (/enter/i.test(event.code)) {
      await saveMessage();
      setMessage('');
    }
  };

  const attachFile = () => {
    attachmentRef.current.click();
  };

  const uploadBlob = useUploadBlob();

  const onAttachmentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];

    if (event.target.files.length > 0 && file) {
      const extension = file.type.split('/')[1];
      const filename = file.name;
      const contentType = `image/${extension}`;
      const metadata = {
        contentType,
        name: filename,
      };
      const blob = new Blob(event.target.files as unknown as BlobPart[], {
        type: contentType,
      });

      uploadBlob({
        blob,
        filename,
        metadata,
        onComplete: async (url) => {
          await sendFile({
            fileExtension: getExtension(filename),
            fileName: filename,
            fileSize: blob.size,
            fileUrl: url,
          });
        },
        path: `designs/${designId}/messages`,
      });

      attachmentRef.current.value = null;
    }
  };

  useAsync(async () => {
    const messagesIds = messages
      .filter((item) => {
        return !item.readAt && !item.isAdmin;
      })
      .map((item) => {
        return item.id;
      });

    if (messagesIds.length) {
      await designsApi.readMessages(designId, { messagesIds });
    }
  }, []);

  return (
    <StyledMessagesContainer collapsed={collapsed}>
      <StyledHeader>
        <Conditional
          condition={collapsed}
          Fallback={
            <>
              <Icon
                color="link"
                name="arrows:arrow_line_right_24"
                size="2.4rem"
                onClick={toggleCollapsed}
              />
              <Typography
                color={messagesTabActive ? 't1' : 't2'}
                text="Messages"
                textStyle="bold"
                onClick={showMessages}
              />
              <Typography
                color={messagesTabActive ? 't2' : 't1'}
                text="Notes"
                textStyle="bold"
                onClick={showNotes}
              />
            </>
          }
        >
          <Icon
            color="link"
            name="arrows:arrow_line_left_24"
            size="2.4rem"
            onClick={toggleCollapsed}
          />
          <StyledMessagesIconSmall onClick={showMessages} />
          <StyledNotesIconSmall onClick={showNotes} />
        </Conditional>
      </StyledHeader>
      <StyledContentContainer>
        {messagesTabActive && (!messages || !messages.length) && (
          <StyledInstructions>
            <StyledMessagesIconLarge />
            <Typography
              align="center"
              color="t2"
              text="Send a message to our design experts when you need help."
            />
          </StyledInstructions>
        )}

        {!messagesTabActive && (!notes || !notes.length) && (
          <StyledInstructions>
            <StyledNotesIconLarge />
            <div>
              <Typography
                align="center"
                color="t2"
                text="Add a private note."
              />
              <Typography
                align="center"
                color="t2"
                text="It's only visible to admins."
              />
            </div>
          </StyledInstructions>
        )}

        <StyledContent active={messagesTabActive}>
          {messages.map((item) => {
            return <Comment key={item.id} message={item} />;
          })}
        </StyledContent>

        <StyledContent active={!messagesTabActive}>
          {notes.map((item) => {
            return (
              <Note
                key={item.id}
                data={item}
                onDelete={onNoteDelete}
                onEdit={onNoteEdit}
              />
            );
          })}
        </StyledContent>

        <div ref={anchorRef} />
      </StyledContentContainer>
      <StyledFooter>
        <Grid columns={1} gap="10px">
          {messagesTabActive && (
            <StyledAttachmentTypography
              color="link"
              icon={<Icon color="link" name="arrows:arrow_upload" />}
              text="Attachment"
              onClick={attachFile}
            />
          )}

          <StyledInputContainer>
            <TextField
              placeholder="Aa..."
              value={message}
              onKeyDown={onMessageKeyPress}
              onTextChange={setMessage}
            />

            <StyledSendButton onClick={saveMessage}>
              <Icon color="alt" name="arrows:arrow_right" />
            </StyledSendButton>
          </StyledInputContainer>
        </Grid>
        <input
          ref={attachmentRef}
          style={{ display: 'none' }}
          type="file"
          onChange={onAttachmentChange}
        />
      </StyledFooter>
    </StyledMessagesContainer>
  );
};
