import { Version } from '../../../../../api/version/version.types';

export const getVersionDetails = (
  version: Pick<Version, 'description' | 'status' | 'type' | 'id'>,
) => {
  const isDraft = version?.status === 'Draft';

  const isFirstVersionDraft = version?.description === 'First version';

  return {
    id: version?.id,
    isArchived: version?.status === 'Archived',
    isDraft,
    isFirstVersionDraft,
    isMajorVersion: isDraft && version?.type === 'Major',
    isMinorVersion: isDraft && version?.type === 'Minor',
    isPublished: version?.status === 'Published',
  };
};
