import { InventoryRequest } from '../../../../../api/jobs/jobs.types';

export const getInventoryWarnings = (
  inventoryRequest: Pick<InventoryRequest, 'inventory' | 'need' | 'discarded'>,
) => {
  const { discarded, inventory, need } = inventoryRequest;

  const isStockExceeded =
    (Number(need) || 0) + (discarded || 0) > inventory.stock;

  const isInactive = inventory.status && inventory.status !== 'Active';

  const showWarningIcon = isInactive || isStockExceeded;

  return { isInactive, isStockExceeded, showWarningIcon };
};
