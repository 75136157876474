import { FC } from 'react';
import { Icon, Typography, Conditional } from 'gantri-components';
import { StyledLocationContainer } from './part-location-information-styles';
import { PartLocationInformationProps } from './part-location-information.types';
import { usePartLocations } from './use-part-locations';

export const PartLocationInformation: FC<PartLocationInformationProps> = (
  props,
) => {
  const { location } = usePartLocations(props);

  return (
    <StyledLocationContainer>
      <Conditional condition={!!location}>
        <Typography
          color="t1"
          icon={<Icon color="t2" name="location:location_pin" size="1.6rem" />}
          iconPosition="left"
          text={location?.name}
        />
      </Conditional>

      <Typography color="link" decoration="underline" text="Update" />
    </StyledLocationContainer>
  );
};
