import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateNonProductJobArgs,
  UpdateNonProductJobResponse,
} from './update-non-product-job.types';

export const updateNonProductJob = (
  jobId: number,
  body: UpdateNonProductJobArgs,
) => {
  return axios.put<UpdateNonProductJobResponse>(
    `${coreApiUrl}/jobs/non-product/${jobId}/edit`,
    body,
  );
};
