import { partStatuses } from '../../../constants/options';
import { Material } from '../../../constants/options/options.types';
import { materials } from '../../../constants/parts';
import { Status, Stock } from './parts-filter.types';

// STOCK
export const stockFilterOptions = [
  {
    label: 'Select stock type...',
    value: null,
  },
  {
    label: 'Stock only',
    value: 'stockOnly',
  },
  {
    label: 'Non stock only',
    value: 'nonStockOnly',
  },
] satisfies DropdownItem<Stock>[];

// STATUS

const statusOptions = Object.values(partStatuses);

export const statusFilterOptions = [
  {
    label: 'Select status...',
    value: null,
  },
  ...statusOptions.map((label) => {
    return { label, value: label };
  }),
] satisfies DropdownItem<Status>[];

// MATERIALS
export const materialFilterOptions = [
  {
    label: 'Select material...',
    value: null,
  },
  ...Object.values(materials).map((label) => {
    return { label, value: label };
  }),
] satisfies DropdownItem<Material>[];

interface DropdownItem<Value = string> {
  label: string;
  value: Value;
}
