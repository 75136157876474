import axios from 'axios';
import { coreApiUrl } from '../../helpers/auth';
import { fetchPaginatedInventories } from './routes/fetch-paginated-inventories';
import { createInventoryCheckout } from './routes/create-inventory-checkout';
import { fetchAllInventories } from './routes/fetch-all';
import { fetchAllInventoriesForJobs } from './routes/fetch-all-for-jobs';
import { fetchAllInventoriesForStock } from './routes/fetch-all-for-stock';
import { migrateInventory } from './routes/migrate-inventory';

const inventoriesApi = {
  create: ({ description, name, unit }) => {
    return axios({
      data: { description, name, unit },
      method: 'post',
      url: `${coreApiUrl}/inventory/create`,
    });
  },
  createInventoryCheckout,
  fetchAllInventoriesForJobs,
  fetchAllInventoriesForStock,
  fetchPaginatedInventories,
  fetchPaginatedInventoryTransactions: ({
    count,
    endDateSec,
    inventoryId,
    page,
    search,
    sortingField,
    sortingType,
    startDateSec,
    type,
  }) => {
    return axios({
      data: {
        count,
        endDateSec,
        page,
        search,
        sortingField,
        sortingType,
        startDateSec,
        type,
      },
      method: 'post',
      url: `${coreApiUrl}/inventory-transaction/${inventoryId}`,
    });
  },
  getAll: fetchAllInventories,
  migrateInventory,
  update: ({ id, ...data }) => {
    return axios({
      data,
      method: 'put',
      url: `${coreApiUrl}/inventory/${id}`,
    });
  },
};

export default inventoriesApi;
