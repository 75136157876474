import { ProductSkuLevelFileType } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { productSkuLevelAssetsFileTypes } from '../../../../bulk-upload-modal.constants';
import { useGetBulkUploaderPatterns } from '../use-get-bulk-uploader-patterns';

export const useGetFileType = () => {
  const { whitespacePatternString } = useGetBulkUploaderPatterns();

  /** Defaults to general background photo. */
  const getFileType = (props: {
    fileExtension: string;
    fileName: string;
  }): ProductSkuLevelFileType => {
    const { fileExtension, fileName } = props;

    const isUsdz = /usdz/i.test(fileExtension);

    if (isUsdz) {
      return productSkuLevelAssetsFileTypes.usdz;
    }

    const fileTypeScaleRegExpPattern = new RegExp(
      `${whitespacePatternString}scale`,
      'i',
    );

    if (fileTypeScaleRegExpPattern.test(fileName)) {
      return productSkuLevelAssetsFileTypes.scalePhoto;
    }

    const fileTypeDimmingDarkRegExpPattern = new RegExp(
      `${whitespacePatternString}(?:(?:dimming_dark)|(?:Dimming Dark))`,
      'i',
    );

    if (fileTypeDimmingDarkRegExpPattern.test(fileName)) {
      return productSkuLevelAssetsFileTypes.dimmingPhotoDark;
    }

    const fileTypeDimmingLightRegExpPattern = new RegExp(
      `${whitespacePatternString}(?:(?:dimming_light)|(?:Dimming Light))`,
      'i',
    );

    if (fileTypeDimmingLightRegExpPattern.test(fileName)) {
      return productSkuLevelAssetsFileTypes.dimmingPhotoLight;
    }

    return productSkuLevelAssetsFileTypes.whiteBackgroundPhotos;
  };

  return getFileType;
};
