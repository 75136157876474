import {
  Typography,
  TypographyProps,
  useThemeDefaults,
} from 'gantri-components';

export const Label = (props: TypographyProps) => {
  const themeDefaults = useThemeDefaults();

  return (
    <Typography variant={themeDefaults.textField.labelVariant} {...props} />
  );
};
