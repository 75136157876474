import { atom } from 'recoil';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { FinalQAFailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Checklist } from '../../common/job-checklist/job-checklist.types';
import { completeFinalQAModalChecklistDefaults } from './complete-final-qa-modal.constants';
import { CompleteFinalQAModalStep } from './complete-final-qa-modal.types';

const atomKeyPrefix = 'QA_QC_MODAL';

export const completeQaQcModalAtoms = {
  checklist: atom<Checklist<FinalQAFailedReason>>({
    default: completeFinalQAModalChecklistDefaults,
    key: `${atomKeyPrefix}-checklist`,
  }),
  duplicatedJobs: atom<Job[]>({
    default: [],
    key: `${atomKeyPrefix}-duplicated-jobs`,
  }),
  isChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-checklist-reviewed`,
  }),
  step: atom<CompleteFinalQAModalStep>({
    default: 'CONFIRM_START',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
