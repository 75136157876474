import { Icon } from 'gantri-components';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { JobHandoutsCellProps } from './job-handouts-cell.types';

export const JobHandoutsCell = (props: JobHandoutsCellProps) => {
  const { downloadHandout, getValue } = props;

  const handouts = getValue()?.handouts || [];
  const downloadViaColumnMeta = handouts.length === 1;

  return (
    <div>
      {handouts.map((handout) => {
        const onClick = downloadViaColumnMeta
          ? undefined
          : () => {
              return downloadHandout({
                fileName: handout.name,
                url: handout.link,
              });
            };

        return (
          <StyledAnchor
            key={handout.id}
            icon={<Icon color="link" name="arrows:arrow_external" />}
            iconPosition="right"
            text={handout.name}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
};
