import styled, { css } from 'styled-components';
import { media, Typography } from 'gantri-components';
import { mediaQueries } from '../../../../constants/styles';

export const StyledGiftCardContentContainer = styled.div`
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  border-top: unset;
  padding: 2.4rem;

  ${media.lessThan('sm')`
     padding: 1.6rem;
  `};
`;

export const StyledGiftCardRow = styled.div`
  display: grid;
  grid-template-columns: 6.6rem 1fr;
  gap: 20px;
  user-select: none;
  align-items: start;

  ${media.lessThan('sm')`
      grid-template-columns: 4.4rem 1fr;
      align-items: start;
  `};
`;

export const StyledSpecsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledRightAlignedBlock = styled.div`
  text-align: right;
  position: relative;

  ${mediaQueries.mobile(
    css`
      display: none;
    `,
  )}
`;

export const StyledRowContent = styled.div`
  display: grid;
  height: max-content;
  grid-template-columns: 1fr max-content;
  column-gap: 10px;
`;

export const StyledCard = styled.div<{
  photo: string;
}>`
  overflow: hidden;
  position: relative;
  background-image: ${({ photo }) => {
    return `url(${photo})`;
  }};
  background-position: center;
  background-size: cover;
  aspect-ratio: 3/2;
  @extend % base-shadow;
`;

export const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return css`
      text-align: left;

      span {
        color: ${theme.colors.typography.t2};
      }
    `;
  }}
`;

export const StyledGiftCardOrderImageContainer = styled.div`
  ${({ theme }) => {
    return css`
      width: unset;
      height: unset;
      background: ${theme.colors.palette.monochrome_050};
      padding: ${theme.dimensions.spacing.s1};
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-width: 0;
      min-height: 0;
      aspect-ratio: 1/1;

      ${media.lessThan('sm')`
        padding: ${theme.dimensions.spacing.x};
      `}
    `;
  }}
`;

export const StyledMessageTypography = styled(Typography)`
  white-space: pre-line;
  overflow-wrap: anywhere;
`;

export const StyledRecipientTypography = styled(StyledMessageTypography)`
  ${({ theme }) => {
    return css`
      span {
        color: ${theme.colors.typography.t2};
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;
        font-weight: inherit;
        transition: all ${theme.transitions.quick};
      }
    `;
  }}
`;
