import { ALL_USER_TYPES } from '../../product/components/info/info.constants';

export const getTypesFormValues = (value: boolean = false) => {
  return ALL_USER_TYPES.reduce((acc, type) => {
    return {
      ...acc,
      [type]: value,
    };
  }, {});
};

export const isAllTypeSelected = (types: Record<string, boolean>): boolean => {
  return ALL_USER_TYPES.every((type) => {
    return types[type];
  });
};
