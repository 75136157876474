import { Conditional, Stack, Typography } from 'gantri-components';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';
import { IssueDetails } from '../common/issue-details';
import { RepairSummaryContentProps } from './repair-summary-content.types';

export const RepairSummaryContent = (props: RepairSummaryContentProps) => {
  const { issues } = props;

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="success"
        titleText="Technician Repair job complete"
      />
      <Conditional condition={!!issues.length}>
        <Stack gap="x">
          <Typography text="Issues addressed" variant="h5" />
          {issues.map((issue) => {
            return <IssueDetails key={issue.id} issue={issue} />;
          })}
        </Stack>
      </Conditional>
    </Stack>
  );
};
