import { Stock } from '../../../../../../../../../../types/store';
import { TableItems } from './get-table-items-from-stocks.types';

export const getTableItemsFromStocks = (stocks: Stock[]): TableItems[] => {
  const reducedStocks = stocks.reduce<Record<string, TableItems>>(
    (accumulator, { product }) => {
      const reducedStock = accumulator[product.sku];

      if (!!reducedStock) {
        reducedStock.qty += 1;

        return accumulator;
      }

      return {
        ...accumulator,
        [product.sku]: {
          color: product.color.name,
          name: product.fullName,
          qty: 1,
          size: product.size.name,
          sku: product.sku,
        },
      };
    },
    {},
  );

  const skusInShipment = Object.keys(reducedStocks);

  const tableItems = skusInShipment.map((sku) => {
    return reducedStocks[sku];
  });

  return tableItems;
};
