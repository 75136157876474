import {
  Cell,
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
} from 'gantri-components';
import { StyledAlertGrid } from './job-instruction.styles';
import { JobInstructionProps } from './job-instruction.types';

export const JobInstruction = (props: JobInstructionProps) => {
  const { instruction } = props;

  return (
    <StyledAlertGrid columns="15rem 1fr" gap="1.6rem" marginBottom="1.2rem">
      <Cell paddingBottom="1rem" paddingLeft="1.2rem" paddingTop="1rem">
        <Typography
          icon={
            <Icon color="success" name="ui-control:exclamation_circle_filled" />
          }
          text="Instruction"
          textStyle="bold"
        />
      </Cell>
      <Stack
        gap="0.4rem"
        paddingBottom="1rem"
        paddingRight="1rem"
        paddingTop="1rem"
      >
        <Conditional condition={!!instruction?.text}>
          <Typography htmlText={instruction?.text?.replace(/\n/g, '<br />')} />
        </Conditional>

        {instruction?.files?.map(({ fileName, url }) => {
          return (
            <a key={url} download href={url}>
              <Flex alignItems="center" gap="1.6rem">
                <Typography
                  color="link"
                  decoration="underline"
                  style={{
                    wordBreak: 'break-word',
                  }}
                  text={fileName}
                />
                <Icon color="link" name="arrows:arrow_external" />
              </Flex>
            </a>
          );
        })}
      </Stack>
    </StyledAlertGrid>
  );
};
