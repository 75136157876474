import Cookies from 'universal-cookie';
import { usersApi } from '../../../api';
import { redirectToMantle } from '../../../helpers/auth';
import {
  ADMIN_ROLE_TYPE,
  AUTH_TOKEN,
  FIREBASE_TOKEN,
  STD_COOKIE_OPTIONS,
} from '../../../constants/environment';

export const asyncLogOutAdmin = async () => {
  const cookies = new Cookies();

  cookies.remove(AUTH_TOKEN, STD_COOKIE_OPTIONS);
  cookies.remove(FIREBASE_TOKEN, STD_COOKIE_OPTIONS);
  cookies.remove(ADMIN_ROLE_TYPE, STD_COOKIE_OPTIONS);
  cookies.set('CART_OUTDATED', 'true', STD_COOKIE_OPTIONS);

  await usersApi.logoutAdmin();

  redirectToMantle();
};
