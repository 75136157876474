import { isEqual } from 'lodash';
import * as yup from 'yup';
import { partPaintedStatuses } from '../../../../../../../../api/products/products.constants';
import {
  InstructionJobMap,
  JobBlockInstruction,
  JobBlockPrint,
  MappedJobBlockInstruction,
  PartTemplate,
} from '../../../../../../../../api/products/products.types';
import {
  InstructionFile,
  JobInstructions,
} from '../../../../../../../../api/products/routes/fetch-product-part-templates/fetch-product-part-templates.types';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../constants/options';
import { PartJobBlocksFormData } from './part-job-blocks.types';

export const getPartJobBlocksInitialValues = ({
  part,
}: {
  part: PartTemplate;
}): PartJobBlocksFormData => {
  const { hasBondo, id: partTemplateId, jobBlock, paintedStatus } = part;

  const emptyPrintBlock: JobBlockPrint = {
    gcode: {},
    instructions: [],
    instructionsJobMap: jobBlock?.printBlock?.[0]?.instructionsJobMap || [],
    name: '',
    printDuration: '' as unknown as number,
    weight: '' as unknown as number,
  };

  const printBlock = jobBlock?.printBlock?.map((printBlock) => {
    return {
      ...printBlock,
      instructions: mapInstructionsToLabels({
        instructions: printBlock.instructions,
        instructionsJobMap: printBlock.instructionsJobMap,
      }),
    };
  });
  const bondoBlock = jobBlock?.bondoBlock;
  const finishBlock = jobBlock?.finishBlock;

  const initialPrintBlocks: JobBlockPrint[] = printBlock || [emptyPrintBlock];

  return {
    bondoBlock: {
      instructions:
        mapInstructionsToLabels({
          instructions: bondoBlock?.instructions,
          instructionsJobMap: bondoBlock?.instructionsJobMap,
        }) || [],
      instructionsJobMap: bondoBlock?.instructionsJobMap || [],
      sandRawDuration: bondoBlock?.sandRawDuration || ('' as unknown as number),
    },
    finishBlock: {
      instructions:
        mapInstructionsToLabels({
          instructions: finishBlock?.instructions,
          instructionsJobMap: finishBlock?.instructionsJobMap,
        }) || [],
      instructionsJobMap: finishBlock?.instructionsJobMap || [],
      sandPrimedDuration:
        finishBlock?.sandPrimedDuration || ('' as unknown as number),
      sandRawDuration:
        finishBlock?.sandRawDuration || ('' as unknown as number),
    },
    hasBondo,
    id: jobBlock?.id,
    paintedStatus: paintedStatus || partPaintedStatuses.unpainted,
    partTemplateId,
    printBlock: initialPrintBlocks,
  };
};

export const mapInstructionsToLabels = (props: {
  instructions: JobBlockInstruction[];
  instructionsJobMap: InstructionJobMap[];
}): MappedJobBlockInstruction[] => {
  const { instructions = [], instructionsJobMap = [] } = props;

  const instructionsWithLabels = instructions.map(
    ({ data, ...instructionKeys }) => {
      const matchingInstructionJobMap = instructionsJobMap.find(
        ({ label: _label, ...jobMapKeys }) => {
          return isEqual(
            {
              isBrightColor: instructionKeys.isBrightColor,
              step: instructionKeys.step,
              type: instructionKeys.type,
            },
            {
              isBrightColor: jobMapKeys.isBrightColor,
              step: jobMapKeys.step,
              type: jobMapKeys.type,
            },
          );
        },
      );

      return { data, ...matchingInstructionJobMap };
    },
  );

  return instructionsWithLabels;
};

const getRequiredText = (label: string) => {
  return `${label} is required`;
};

export const singleInstructionSchema: yup.SchemaOf<JobInstructions> = yup
  .object()
  .shape({
    files: yup.array().of(
      yup.object().shape({
        fileName: yup.string().nullable(),
        originalFileName: yup.string().nullable(),
        path: yup.string().nullable(),
        url: yup.string().when('text', {
          is: (value: string) => {
            return !!value?.trim();
          },
          otherwise: yup.string().required(''),
          then: yup.string().nullable(),
        }),
      }),
    ),
    text: yup.string().when('files', {
      is: (files: InstructionFile[]) => {
        // always valid if files are provided
        return !!files.some(({ url }) => {
          return !!url;
        });
      },
      otherwise: yup.string().nullable().required(''),
      then: yup.string().nullable(),
    }),
  });

export const jobBlockInstructionsSchema: yup.SchemaOf<JobBlockInstruction[]> =
  yup.array().of(
    yup.object().shape(
      {
        data: singleInstructionSchema,
        isBrightColor: yup.boolean(),
        label: yup.string(),
        step: yup.mixed().oneOf(Object.values(jobSteps)).required(''),
        type: yup.mixed().oneOf(Object.values(jobTypeOptions)).required(''),
      },
      [['files', 'text']],
    ),
  );

export const instructionsJobMapSchema = yup.array().of(
  yup.object().shape({
    isBrightColor: yup.boolean(),
    label: yup.string().required(),
    step: yup.mixed().oneOf(Object.values(jobSteps)).required(),
    type: yup.mixed().oneOf(Object.values(jobTypeOptions)).required(),
  }),
);

export const partJobBlocksFormikSchema: yup.SchemaOf<PartJobBlocksFormData> =
  yup.object().shape({
    bondoBlock: yup.object().shape({
      instructions: jobBlockInstructionsSchema,
      instructionsJobMap: instructionsJobMapSchema,
      sandRawDuration: yup
        .number()
        .min(1, ({ min }) => {
          return `Number cannot be less than ${min}.`;
        })
        .test({
          exclusive: false,
          message: getRequiredText(`${jobTypeOptions.sandRaw} Duration`),
          name: 'conditionally-required',
          test: (sandRawDuration, context) => {
            // @ts-expect-error
            const hasBondoValue = context.from[1].value.hasBondo;
            const isValid = hasBondoValue ? !!sandRawDuration : true;

            return isValid;
          },
        }),
    }),
    finishBlock: yup.object().shape({
      instructions: jobBlockInstructionsSchema,
      instructionsJobMap: instructionsJobMapSchema,
      sandPrimedDuration: yup
        .number()
        .min(1, ({ min }) => {
          return `Number cannot be less than ${min}.`;
        })
        .test({
          exclusive: false,
          message: getRequiredText(`${jobTypeOptions.sandPrimed} Duration`),
          name: 'conditionally-required',
          test: (sandPrimedDuration, context) => {
            const isPainted =
              // @ts-expect-error
              context.from[1].value.paintedStatus ===
              partPaintedStatuses.painted;
            const isValid = isPainted ? !!sandPrimedDuration : true;

            return isValid;
          },
        }),
      sandRawDuration: yup
        .number()
        .min(1, ({ min }) => {
          return `Number cannot be less than ${min}.`;
        })
        .test({
          exclusive: false,
          message: getRequiredText(`${jobTypeOptions.sandRaw} Duration`),
          name: 'conditionally-required',
          test: (sandRawDuration, context) => {
            const isPainted =
              // @ts-expect-error
              context.from[1].value.paintedStatus ===
              partPaintedStatuses.painted;
            const isValid = isPainted ? !!sandRawDuration : true;

            return isValid;
          },
        }),
    }),
    hasBondo: yup.boolean(),
    id: yup.number().required(),
    paintedStatus: yup
      .mixed()
      .oneOf(Object.values(Object.values(partPaintedStatuses))),
    partTemplateId: yup.number().required(),
    printBlock: yup.array().of(
      yup.object().shape({
        gcode: yup.object().shape({
          fileName: yup.string().required(getRequiredText('G-code')),
          originalFileName: yup.string().required(getRequiredText('G-code')),
          url: yup.string().required(getRequiredText('G-code')),
        }),
        instructions: jobBlockInstructionsSchema,
        instructionsJobMap: instructionsJobMapSchema,
        name: yup.string().test({
          // Using `test` to check root level context. `when` only checks within the current `shape`.
          exclusive: false,
          message: getRequiredText('Name'),
          name: 'conditionally-required',
          test: (name, context) => {
            const printBlockValue: JobBlockPrint[] =
              // @ts-expect-error
              context.from[1].value.printBlock;

            const isValid = printBlockValue?.length > 1 ? !!name : true;

            return isValid;
          },
        }),
        printDuration: yup.number().required(getRequiredText('Print Duration')),
        weight: yup
          .number()
          .min(1, ({ min }) => {
            return `Number cannot be less than ${min}.`;
          })
          .required(getRequiredText('Weight')),
      }),
    ),
  });
