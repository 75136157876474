import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { DiscardDraftArgs, DiscardDraftResponse } from './discard-draft.types';

export const discardDraft = ({ versionId }: DiscardDraftArgs) => {
  return axios
    .delete<DiscardDraftResponse>(`${coreApiUrl}/versions/${versionId}`)
    .then(({ data }) => {
      return data;
    });
};
