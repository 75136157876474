import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  CompleteNonProductJobArgs,
  CompleteNonProductJobResponse,
} from './complete-non-product-job.types';
import { completeNonProductJob } from './complete-non-product-job';

export const useCompleteNonProductJob = <
  TransformedData = CompleteNonProductJobResponse,
>(
  props?: GenericMutateQueryProps<
    CompleteNonProductJobArgs,
    CompleteNonProductJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCompleteNonProductJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to complete non-product job.',
    mutationFn: completeNonProductJob,
    ...props,
  });

  return { ...rest, onCompleteNonProductJob };
};
