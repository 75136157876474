import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ExceededCycleTimeFooterProps } from './exceeded-cycle-time-footer.types';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { useAddExceededCycleTimeReasons } from '../../../../../../../../../api/jobs/routes/add-exceeded-cycle-time-reasons/add-exceeded-cycle-time-reasons.query';

export const ExceededCycleTimeFooter = (
  props: ExceededCycleTimeFooterProps,
) => {
  const { handleCloseModal } = props;

  const exceededCycleTimeReason = useRecoilValue(
    completeAssemblyModalAtoms.exceededCycleTimeReason,
  );
  const job = useRecoilValue(completeAssemblyModalAtoms.job);

  const { isLoading, onAddExceededCycleTimeReasons } =
    useAddExceededCycleTimeReasons({
      onSuccess: handleCloseModal,
    });

  const handleSubmitExceededCycleTimeReason = async () => {
    const isOtherOption = exceededCycleTimeReason.label === 'Other';

    await onAddExceededCycleTimeReasons({
      jobId: job.id,
      reasonsForExceeding: {
        additionalDetails: exceededCycleTimeReason.notes,
        reason: isOtherOption
          ? exceededCycleTimeReason.value
          : exceededCycleTimeReason.label,
      },
    });
  };

  return (
    <Button
      disabled={!exceededCycleTimeReason?.value}
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={handleSubmitExceededCycleTimeReason}
    />
  );
};
