import { Badge } from 'gantri-components';
import { useTheme } from 'styled-components';
import { FC } from 'react';
import { MachineStatusDataCellType } from './machine-status-data-cell.types';

export const MachineStatusesDataCell: FC<MachineStatusDataCellType> = ({
  status,
}: MachineStatusDataCellType) => {
  const theme = useTheme();

  switch (status) {
    case 'Ready': {
      return (
        <Badge
          borderColor={theme.colors.surfaces.green.t2}
          color={theme.colors.surfaces.green.t1}
          icon="actions:thumbs_up"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case 'Manual': {
      return (
        <Badge
          borderColor={theme.colors.surfaces.blue.t2}
          color={theme.colors.surfaces.blue.t1}
          icon="people:person"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case 'In progress': {
      return (
        <Badge
          borderColor={theme.colors.surfaces.warning.t2}
          color={theme.colors.surfaces.warning.t1}
          icon="time:clock"
          minWidth="10.5rem"
          text="In Progress"
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case 'Offline': {
      return (
        <Badge
          borderColor={theme.colors.surfaces.alert.t2}
          color={theme.colors.surfaces.alert.t1}
          icon="ui-control:x"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    default:
      return (
        <Badge
          borderColor={theme.colors.surfaces.alert.t2}
          color={theme.colors.surfaces.alert.t1}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
  }
};
