import { Icon, Typography, Tooltip, Flex } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { formatDate } from '../../../../helpers/formatter';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../../constants/dates';
import { UserActivity } from '../../../../api/users/routes/get-user-activities/get-user-activities.types';

export const DateDataCell = ({
  getValue,
}: CellContext<UserActivity, UserActivity['dateSec']>) => {
  const dateSec = getValue();

  return (
    <Flex alignItems="center" gap=".5x">
      <Typography color="t1" text={formatDate(dateSec, MEDIUM_FORMAT)} />
      <Tooltip description={formatDate(dateSec, LONG_FORMAT)} position="top">
        <Icon name="alert:i_circle" top="2px" />
      </Tooltip>
    </Flex>
  );
};
