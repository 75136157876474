import { useRecoilValue } from 'recoil';
import { RecommendedStepOptionGroup } from './use-recommended-next-steps.types';
import { finishingQcModalAtoms } from '../../../../../complete-finishing-qa-modal.atoms';
import { jobTypeOptions } from '../../../../../../../../../../../constants/options';

export const restartOptionGroup: RecommendedStepOptionGroup = {
  icon: 'ui-control:lines_three',
  items: [
    {
      label: 'Re-start',
      step: 'JOB_FAILED_RESTART',
      subText: 'Creates new part and part ID.',
    },
  ],
  label: 'Re-start Part',
};

export const refinishOptionGroup: RecommendedStepOptionGroup = {
  icon: 'work:sand_paper',
  items: [
    {
      label: 'Paint Only',
      step: 'JOB_FAILED_REFINISH_PAINT_ONLY',
      subText: `Duplicates ${jobTypeOptions.paint} and ${jobTypeOptions.qc} jobs.`,
    },
    {
      label: 'Targeted re-work',
      step: 'JOB_FAILED_REFINISH_TARGETED_REWORK',
      subText: `Duplicates ${jobTypeOptions.sandPrimed}, ${jobTypeOptions.paint}, and ${jobTypeOptions.qc} jobs.`,
    },
    {
      label: 'Complete re-work',
      step: 'JOB_FAILED_REFINISH_COMPLETE_REWORK',
      subText: `Duplicates ${jobTypeOptions.sandPrimed}, ${jobTypeOptions.paint}, and ${jobTypeOptions.qc} jobs.`,
    },
  ],
  label: 'Re-finish Part',
};

export const useRecommendedNextSteps = () => {
  const isAtMaxFinishAttempts = useRecoilValue(
    finishingQcModalAtoms.isAtMaxFinishAttempts,
  );

  const nextStepOptions: RecommendedStepOptionGroup[] = [
    !isAtMaxFinishAttempts && refinishOptionGroup,
    restartOptionGroup,
  ].filter(Boolean);

  return {
    nextStepOptions,
  };
};
