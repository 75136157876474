import { useRecoilValue } from 'recoil';
import { ModalContentHeading } from '../../../../common/modal-content-heading';
import { useGetNumJobsInSet } from '../../../hooks/get-num-jobs-in-set';
import { printPrepareQueueModalAtoms } from '../../../print-prepare-queue-modal.atoms';

export const JobSetCompletedContent = () => {
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  const numJobsInSet = useGetNumJobsInSet();

  const setNumber = activeSetIndex + 1;

  return (
    <ModalContentHeading
      color="success"
      subTitleText={`All jobs (${numJobsInSet}) in this set have been completed. Please move on to next set.`}
      titleText={`Set ${setNumber} completed`}
    />
  );
};
