import { Table } from 'gantri-components';
import { RestartPartTableData } from '../../complete-job/complete-assembly-modal/components/step-assembly-completed-failed/assembly-completed-failed-content/assembly-completed-failed-content.types';
import { partStatuses } from '../../../../../../constants/options';
import { restartPartTableColumns } from './restarted-part-table.constants';
import { RestartedPartTableProps } from './restarted-part-table.types';

export const RestartedPartTable = (props: RestartedPartTableProps) => {
  const { newParts, oldParts, partId } = props;

  const oldPart = oldParts.find((part) => {
    return part.id === partId;
  });
  const newPart = newParts.find((part) => {
    return part.name === oldPart.name;
  });
  const restartPartTableData: RestartPartTableData[] = [
    {
      createdAt: oldPart.createdAt,
      id: oldPart.id,
      name: null,
      status: partStatuses.failed,
      version: oldPart?.version,
    },
    {
      createdAt: newPart?.createdAt,
      id: newPart?.id,
      name: null,
      status: newPart?.status,
      version: newPart?.version,
    },
  ];

  return (
    <Table columns={restartPartTableColumns} data={restartPartTableData} />
  );
};
