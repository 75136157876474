import { formatDate } from '../../helpers/formatter';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { AllDashboardDateRangeTypes } from './dashboard.types';
import { DashboardReportToHide } from '../../types/store';

const initTo = new Date();
const initFrom = new Date();

initFrom.setDate(initTo.getDate() - 7);

const initialValue = {
  from: initFrom,
  to: initTo,
};

export const dashboardAndReportsAtomDefaults: {
  columns: DashboardReportToHide[];
  endDate: string;
  rangeType: AllDashboardDateRangeTypes;
  startDate: string;
} = {
  columns: [],
  endDate: formatDate(initialValue.to),
  rangeType: 'Past week',
  startDate: formatDate(initialValue.from),
};

const atomKeyPrefix = 'DASHBOARD';

const pageName: AtomPageName = 'dashboard';

export const dashboardAndReportAtoms = {
  columns: getGenericAtomFamily({
    defaultValue: dashboardAndReportsAtomDefaults.columns,
    key: `${atomKeyPrefix}-columns`,
  })(pageName),
  endDate: getGenericAtomFamily({
    defaultValue: dashboardAndReportsAtomDefaults.endDate,
    key: `${atomKeyPrefix}-end-date`,
  })(pageName),
  rangeType: getGenericAtomFamily({
    defaultValue: dashboardAndReportsAtomDefaults.rangeType,
    key: `${atomKeyPrefix}-range-type`,
  })(pageName),
  startDate: getGenericAtomFamily({
    defaultValue: dashboardAndReportsAtomDefaults.startDate,
    key: `${atomKeyPrefix}-start-date`,
  })(pageName),
};
