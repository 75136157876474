import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';

export const updateSignatureRequiredForDelivery = (
  shipmentId: number,
  isSignatureRequired: boolean,
) => {
  return axios.put<TransactionResponse>(
    `${baseApiUrl}/shipment/${shipmentId}/signature-required`,
    { isSignatureRequired },
  );
};
