import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { getStyleArgs } from './helpers/get-style-args';
import { GetPackingSlipDocProps } from './get-packing-slip-doc.types';
import { getFileName } from './helpers/get-file-name';
import { getFooter } from './helpers/get-footer';
import { getHeader } from './helpers/get-header';
import { getItemInPackage } from './helpers/get-item-in-package';
import { getItemsInShipment } from './helpers/get-items-in-shipment';
import { getItemsNotYetShipped } from './helpers/get-items-not-yet-shipped';
import { getSummaryTable } from './helpers/get-summary-table';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getPackingSlipDoc = async (
  props: GetPackingSlipDocProps,
): Promise<TDocumentDefinitions> => {
  const { currentOrderData } = props;
  const isThirdParty = currentOrderData?.type === 'Third Party';

  const docDefinition: TDocumentDefinitions = {
    content: [
      ...getSummaryTable(props),
      ...getItemsInShipment(props),
      ...getItemInPackage(props),
      ...getItemsNotYetShipped(props),
    ],
    footer: getFooter(props),
    header: await getHeader(props),
    info: {
      // Tab title used in "open" and "print" commands
      title: getFileName(props),
    },
    pageMargins: [46, 100, 46, isThirdParty ? 160 : 100],
    pageSize: 'LETTER',
    ...getStyleArgs(),
  };

  return docDefinition;
};
