import axios from 'axios';
import { stringify } from 'query-string';
import yotpoConfig from '../../config/yotpo';
import { environment } from '../../environment';
import { baseApiUrl, coreApiUrl, getCoreApiUrl } from '../../helpers/auth';
import { resetJobTemplateDuration } from './routes/reset-job-template-duration';
import {
  addProductAssets,
  getProductJobs,
  loadAllProducts,
  removeProductImage,
  replaceProductImage,
} from './routes';

const productsApi = {
  addProductAssets,
  copySpecsToSkus: (productId, data) => {
    return axios({
      data,
      method: 'PUT',
      url: `${coreApiUrl}/product/${productId}/copy-specs`,
    });
  },
  deleteHandoutOrInstructions: (data) => {
    return axios({
      data,
      method: 'DELETE',
      url: `${baseApiUrl}/product-assets/${data.id}`,
    });
  },
  fetchProductReviews: (sku) => {
    return axios({
      method: 'GET',
      url: `https://api.yotpo.com/v1/widget/${yotpoConfig.production.apiKey}/products/${sku}/reviews.json?page=1&per_page=100`,
    });
  },
  fetchProductStats: (params) => {
    return axios({
      method: 'GET',
      url: `${environment.API_URL}/products/stats?${stringify(params)}`,
    });
  },
  getPaginatedProducts: ({
    categories,
    count,
    inventory,
    page,
    search,
    sortingField,
    sortingType,
    statuses,
  }) => {
    return axios({
      data: {
        categories,
        count,
        inventory,
        page,
        search,
        sortingField,
        sortingType,
        statuses,
      },
      method: 'POST',
      url: `${coreApiUrl}/paginated-products`,
    });
  },
  getProductJobs,
  loadAllProducts,
  markPhotoAsSelected: (productId: number, data) => {
    return axios({
      data,
      method: 'PUT',
      url: `${getCoreApiUrl('products')}/${productId}/select-photo`,
    });
  },
  removeProductImage,
  replacePhoto: (productId, data) => {
    return axios({
      data,
      method: 'POST',
      url: `${baseApiUrl}/products/${productId}/replaceImg`,
    });
  },
  replaceProductImage,
  resetJobTemplateDuration,
  scheduleLaunch: (data) => {
    return axios({
      data,
      method: 'PUT',
      url: `${coreApiUrl}/products/launch`,
    });
  },
  sortPhotos: (productId, data) => {
    return axios({
      data,
      method: 'PUT',
      url: `${baseApiUrl}/products/${productId}/updateImages`,
    });
  },
  uploadHandoutOrInstructions: (data) => {
    return axios({
      data,
      method: 'POST',
      url: `${baseApiUrl}/product-assets`,
    });
  },
};

export default productsApi;
