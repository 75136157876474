import { Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { manageQueuesModalAtoms } from '../../../manage-queues-modal.atoms';

export const ConfirmDeleteContent = () => {
  const queueToDelete = useRecoilValue(manageQueuesModalAtoms.queueToDelete);

  const name = [queueToDelete.user.firstName, queueToDelete.user.lastName]
    .filter(Boolean)
    .join(' ');

  return (
    <Stack alignContent="center" justifyContent="center">
      <Typography
        align="center"
        maxWidth="27rem"
        text={`Are you sure you want to delete Queue #${queueToDelete.id}, created by ${name}?`}
      />
    </Stack>
  );
};
