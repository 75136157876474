import { memo, useEffect, useState } from 'react';
import { DashboardReportSummary } from '../../../../api/dashboard/dashboard.types';
import { useFetchDashboardStocksSummary } from '../../../../api/dashboard/routes/get-dashboard-stocks-summary/get-dashboard-stocks-summary.query';
import routePaths from '../../../../config/route-paths';
import { getChartLeftMargin } from '../common/dashboard-chart/helpers';
import { ReportSummary } from '../common/reports-summary';
import { ordersTableColumns } from './dashboard-stocks-summary.constants';

const DashboardStocksSummaryBase = () => {
  const [report, setReport] = useState<DashboardReportSummary>();
  const [activeChart, setActiveChart] = useState<string>();

  const activeChartDetails = report?.chartData?.[activeChart];
  const leftMargin = getChartLeftMargin({
    activeChartDetails,
  });

  useEffect(() => {
    if (!activeChart) {
      const chartDataKeys = Object.keys(report?.chartData || {});
      const firstCartDataKey = chartDataKeys[0];

      setActiveChart(firstCartDataKey);
    }
  }, [report?.chartData]);

  return (
    <ReportSummary
      activeChart={activeChart}
      activeChartDetails={activeChartDetails}
      chartData={report?.chartData}
      fullReportHref={`${routePaths.dashboard}/stocks`}
      leftMargin={leftMargin}
      minHeight="24rem"
      setActiveChart={setActiveChart}
      setReport={setReport}
      tableColumns={ordersTableColumns}
      tableData={report?.tableData}
      tableMinWidth="70rem"
      title="Stocks"
      useGetDashboardReportSummary={useFetchDashboardStocksSummary}
    />
  );
};

export const DashboardStocksSummary = memo(DashboardStocksSummaryBase);
