import {
  Cell,
  Conditional,
  Flex,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAsync } from 'react-use';
import { Batch as BatchType, BatchPart } from '../../api/batch/batch.types';
import { batchApi } from '../../api';
import { Divider } from '../../components/divider';
import { PageHeading } from '../../components/layout/page-heading';
import { MetaData } from '../../components/meta-data/metaData';
import { useNotification } from '../../hooks/useNotification';
import { AddPartToBatch } from './components/add-part-to-batch';
import { BatchSummary } from './components/batch-summary';
import { useGetBatchPartsColumns } from './hooks/use-get-batch-parts-columns';
import { StartOrCompleteBatchJobs } from './components/start-or-complete-batch-jobs';
import { UpdateBatchLocations } from './components/update-batch-locations';
import Timeline from '../../components/common/timeline';
import { BatchTimelineItem } from './components/batch-timeline-item';
import { useReordering } from './hooks/use-reordering';
import { useRealTimeWebHook } from '../../hooks/use-real-time-web-hook';
import { useTableColumnsSync } from '../../hooks';
import { realTimeChannels } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.constants';
import {
  RealTimeJobChangedData,
  GetUpdatedRecordData,
} from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.types';
import { jobStatuses } from '../../constants/options';

export const Batch = () => {
  const { id } = useParams<{ id: string }>();

  const batchId = Number(id);

  const pageTitle = `Batch #${batchId}`;

  const [batch, setBatch] = useState<BatchType>(null);
  const [batchParts, setBatchParts] = useState<BatchPart[]>([]);
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();
  const { reordering } = useReordering({ batch, setBatch });
  const columnsSyncProps = useTableColumnsSync('batch-parts');

  const fetchBatchDetails = async (options?: {
    hideLoadingIndicator: boolean;
  }) => {
    try {
      if (!options?.hideLoadingIndicator) {
        showLoading();
      }

      const { data } = await batchApi.getBatchDetails(batchId);

      setBatch(data.batch);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to find batch.' });
    } finally {
      if (!options?.hideLoadingIndicator) {
        hideLoading();
      }
    }
  };

  const batchPartsColumns = useGetBatchPartsColumns({
    batchId,
    fetchBatchDetails,
    isReorderingActive: reordering.isActive,
    setBatch,
  });

  const dataAttrJobId = 'data-job-id';

  const getUpdatedRecordData: GetUpdatedRecordData<
    BatchPart,
    RealTimeJobChangedData
  > = ({ changedData, record }) => {
    const matchingChangedData = changedData.find(({ id }) => {
      return id === record.currentJob.id;
    });

    if (matchingChangedData) {
      const currentJob = {
        ...record.currentJob,
        ...matchingChangedData,
      };

      return { currentJob };
    }

    return undefined;
  };

  useRealTimeWebHook<BatchPart, RealTimeJobChangedData>({
    activeStatusFilters: [jobStatuses.ready, jobStatuses.inProgress],
    channel: realTimeChannels.jobs,
    dataAttrForRow: dataAttrJobId,
    getUpdatedRecordData,
    handleFetchRequest: () => {
      return fetchBatchDetails({ hideLoadingIndicator: true });
    },
    records: batchParts,
    setRecords: setBatchParts,
  });

  useEffect(() => {
    if (batch?.parts) {
      setBatchParts(batch.parts);
    }
  }, [batch?.parts]);

  useAsync(async () => {
    await fetchBatchDetails();
  }, []);

  return (
    <>
      <MetaData title={pageTitle} />
      <PageHeading title={pageTitle}>
        <Flex gap="x" wrap="wrap">
          <StartOrCompleteBatchJobs batch={batch} setBatch={setBatch} />
          <UpdateBatchLocations batch={batch} setBatch={setBatch} />
        </Flex>
      </PageHeading>
      <Conditional condition={!!batch}>
        <Stack gap="5x" height="unset" paddingTop="4x">
          <BatchSummary batch={batch} />
          <Conditional condition={batch?.status === 'Active'}>
            <Stack gap="0">
              <Typography
                text={`Parts (${batch?.parts?.length || 0})`}
                variant="h5"
              />
              <Divider paddingTop="x" />
              <Table
                columns={batchPartsColumns}
                data={batchParts}
                getRowProps={({ original }) => {
                  return {
                    [dataAttrJobId]: original?.currentJob?.id,
                  };
                }}
                highlightHoveredRow
                reordering={reordering}
                stickyLastColumn
                {...columnsSyncProps}
              />
              <AddPartToBatch batch={batch} setBatch={setBatch} />
            </Stack>
          </Conditional>
          <Cell>
            <Typography text="Timeline" variant="h4" />
            <Divider paddingBottom="2x" paddingTop="2x" />
            <Timeline
              isTitleVisible={false}
              items={batch?.timelines || []}
              renderItem={BatchTimelineItem}
            />
          </Cell>
        </Stack>
      </Conditional>
    </>
  );
};
