import { useRecoilState } from 'recoil';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { UpdateJobChecklist } from './job-checklist-content.types';

export const JobChecklistContent = () => {
  const [jobChecklist, setJobChecklist] = useRecoilState(
    completeAssemblyModalAtoms.jobChecklist,
  );
  const [isJobChecklistReviewed, setIsJobChecklistReviewed] = useRecoilState(
    completeAssemblyModalAtoms.isJobChecklistReviewed,
  );

  const updateJobChecklist: UpdateJobChecklist = (reason, data) => {
    setJobChecklist((oldChecklist) => {
      return {
        ...oldChecklist,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      buttonToggleWidth="8rem"
      checklist={jobChecklist}
      isChecklistReviewed={isJobChecklistReviewed}
      setIsChecklistReviewed={setIsJobChecklistReviewed}
      showConfirmCheckbox
      statuses={['Pass', 'Fail']}
      onItemChange={updateJobChecklist}
    />
  );
};
