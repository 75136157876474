import { useState } from 'react';
import { useModal } from 'gantri-components';
import { ErrorBoundary } from 'react-error-boundary';
import { CompleteJobModal } from '../components';
import { useNotification } from '../../../hooks/useNotification';
import { jobsApi } from '../../../api';
import { JobDetails } from '../../../api/jobs/routes/get-job-details/get-job-details.types';
import { ErrorFallback, errorHandler } from '../../../components/error';

export interface UseJobCompleteParams {
  onCompleteEnd: (jobId: number) => Promise<void>;
}

export const useJobComplete = (params: UseJobCompleteParams) => {
  const { onCompleteEnd } = params;
  const [currentJob, setCurrentJob] = useState<JobDetails>();

  const { notifyError } = useNotification();

  const [showCompleteJobModal, hideCompleteJobModal] = useModal(() => {
    return (
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) => {
          notifyError(error.message);
          hideCompleteJobModal();

          return errorHandler(error, info);
        }}
      >
        <CompleteJobModal
          job={currentJob}
          onClose={hideCompleteJobModal}
          onJobUpdated={() => {
            return onCompleteEnd(currentJob?.id);
          }}
        />
      </ErrorBoundary>
    );
  }, [currentJob]);

  return async (jobId: number) => {
    const { job } = await jobsApi.getJobDetails(jobId);

    setCurrentJob(job);
    showCompleteJobModal();
  };
};
