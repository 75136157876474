import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateGiftCodeArgs,
  CreateGiftCodeResponse,
} from './create-giftcode.types';

export const createGiftCode = ({
  accountLimit,
  amount,
  code,
  details,
  totalLimit,
  type,
  userTypes,
}: CreateGiftCodeArgs) => {
  return axios.post<CreateGiftCodeResponse>(`${coreApiUrl}/gift`, {
    accountLimit,
    amount,
    code,
    details,
    totalLimit,
    type,
    userTypes,
  });
};
