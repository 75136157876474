import { Button, Conditional } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useToggle } from 'react-use';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { CompleteChecklistButtonWrapper } from '../../../../../common/job-checklist/components/complete-checklist-button-wrapper';
import { jobsApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal, job } = props;

  const [processing, toggleProcessing] = useToggle(false);

  const setNextJob = useSetRecoilState(completeSandJobModalAtoms.nextJob);
  const checklist = useRecoilValue(completeSandJobModalAtoms.checklist);
  const isChecklistReviewed = useRecoilValue(
    completeSandJobModalAtoms.isChecklistReviewed,
  );
  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);

  const { notify, notifyAxiosError } = useNotification();

  const isChecklistFailing = checkAgainstChecklistItems(
    checklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  const handleCompleteJob = async () => {
    try {
      toggleProcessing();

      const data = await jobsApi.completeJob({
        jobId: job.id,
      });

      notify(data.notice);
      setNextJob(data.nextJob);
      await handleCloseModal({ updateOnClose: true });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete the job.',
      });
    } finally {
      toggleProcessing();
    }
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <CompleteChecklistButtonWrapper checklist={checklist}>
        <Conditional
          condition={isChecklistFailing}
          Fallback={
            <Button
              disabled={!isChecklistReviewed}
              processing={processing}
              size={fullScreenModalFooterButtonSize}
              text={`Confirm ${job.type} Completion`}
              onClick={handleCompleteJob}
            />
          }
        >
          <Button
            disabled={!isChecklistReviewed}
            size={fullScreenModalFooterButtonSize}
            text="Confirm Fail"
            variant="primaryAlert"
            onClick={() => {
              setStep('RECOMMENDED_NEXT_STEP');
            }}
          />
        </Conditional>
      </CompleteChecklistButtonWrapper>
    </>
  );
};
