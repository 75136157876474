import { Button, Flex } from 'gantri-components';
import { locationsApi } from '../../../../api';
import { LocationState } from '../../locations.types';
import Protected from '../../../../components/common/protected/protected';
import { useGetDownloadMenuOption } from '../../../../hooks/use-get-download-menu-option';
import { useMoreMenu } from '../../../../hooks/use-more-menu';
import { downloadColumns } from './locations-header-content.constants';
import { LocationsHeaderContentProps } from './locations-header-content.types';

export const LocationsHeaderContent = (props: LocationsHeaderContentProps) => {
  const { filterArgs, handleAddLocation, totalResults } = props;

  const { Toast: DownloadCsvToast, downloadOption } =
    useGetDownloadMenuOption<LocationState>({
      columns: downloadColumns,
      fetchRequestWithFilters: async (overrides) => {
        return locationsApi
          .paginatedLocations({ ...filterArgs, ...overrides })
          .then(({ data }) => {
            return data.locations;
          });
      },
      fileNamePrefix: 'Locations Admin',
      totalResults,
    });

  const { MoreMenu } = useMoreMenu({
    options: [
      {
        ...downloadOption,
        allowedFor: ['Admin', 'Lead'],
      },
    ],
  });

  return (
    <Flex alignItems="center" gap=".5x">
      <Protected allowedFor={['Admin']}>
        <Button text="Add Location" onClick={handleAddLocation} />
      </Protected>
      <Protected allowedFor={['Admin', 'Lead']}>
        <MoreMenu />
        <DownloadCsvToast />
      </Protected>
    </Flex>
  );
};
