import axios from 'axios';
import { environment } from '../../../../environment';
import {
  ForgotPasswordArgs,
  ForgotPasswordResponse,
} from './forgot-password.types';

export const forgotPassword = ({ email }: ForgotPasswordArgs) => {
  return axios.post<ForgotPasswordResponse>(
    `${environment.API_URL}/auth/forgot`,
    {
      email,
    },
  );
};
