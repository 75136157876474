import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { JobEndDateCellProps } from './job-end-date-cell.types';

export const JobEndDateCell = ({ getValue, row }: JobEndDateCellProps) => {
  const job = row.original;
  const endDate = getValue();

  return !!endDate ? (
    <DateWithUserDataCell
      data={job.statusInfo}
      date={endDate}
      jobId={job.id}
      type="End"
    />
  ) : null;
};
