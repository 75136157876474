import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { deleteDesignerFeature } from './delete-feature-settings';
import {
  DeleteFeatureArgs,
  DeleteFeatureResponse,
} from './delete-feature-settings.types';

export const useDeleteDesignerFeature = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onDeleteDesignerFeature, ...rest } = useQueryMutation<
    DeleteFeatureArgs,
    DeleteFeatureResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to delete collection page.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(
          notice || "Designer's Feature Settings has been deleted successfully",
        );
      },
    },
    mutationFn: async (args) => {
      return deleteDesignerFeature(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onDeleteDesignerFeature };
};
