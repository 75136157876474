import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedGiftCardsArgs,
  FetchPaginatedGiftCardsResponse,
} from './fetch-paginated-giftcards.types';

const query = (args: FetchPaginatedGiftCardsArgs) => {
  return axios
    .get<FetchPaginatedGiftCardsResponse>(createUrl(args))
    .then((response) => {
      return response.data;
    });
};

const createUrl = (
  args: FetchPaginatedGiftCardsArgs = {} as FetchPaginatedGiftCardsArgs,
): string => {
  const { page, search, sortingField, sortingType, statuses } = args;
  const url = new URL(`${coreApiUrl}/paginated-gift-cards`);

  url.searchParams.append('search', search ?? '');
  url.searchParams.append('sortField', sortingField ?? '');
  url.searchParams.append('sortOrder', sortingType ?? '');
  url.searchParams.append('pages', String(page ?? ''));
  url.searchParams.append('status', statuses?.join(',') ?? '');

  return url.toString();
};

const groupKey = () => {
  return 'paginated-gift-cards';
};

const key = (args?: FetchPaginatedGiftCardsArgs) => {
  return [
    { groupKey: groupKey(), key: createUrl(args).replace(coreApiUrl, '') },
  ] as const;
};

export const fetchPaginatedGiftCards = {
  groupKey,
  key,
  query,
};
