import { Conditional, Flex, useModal } from 'gantri-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { MoreMenu } from '../../../../../../dropdowns/more-menu';
import { productJobsTabAtoms } from '../../../../../../../pages/product/components/product-jobs/product-jobs.atoms';
import { getVersionDetails } from '../../../../helpers/get-version-details';
import { JobTemplateMenuCellProps } from './job-template-menu-cell.types';
import { jobsTemplateTableAtoms } from '../../../../jobs-template-table.atoms';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { MoreMenuOption } from '../../../../../../dropdowns/more-menu/more-menu.types';
import { getTemplateEditDisabledReason } from '../../../../helpers/get-template-edit-disabled-reason';
import { DeleteJobTemplateModal } from './components/delete-job-template-modal';

export const JobTemplateMenuCell = (props: JobTemplateMenuCellProps) => {
  const { cachedJobTemplates, onSaveOverride, refetchProduct, row, version } =
    props;

  const isEditingRow = useGetIsEditingRow(row);
  const [isEditingJobs, setIsEditingJobs] = useRecoilState(
    productJobsTabAtoms.isEditingJobs,
  );
  const setEditingTemplateId = useSetRecoilState(
    jobsTemplateTableAtoms.editingTemplateId,
  );

  const { isDraft, isMinorVersion } = getVersionDetails(version);

  const { id: jobTemplateId, isGroupTitle } = row.original;

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return (
      <DeleteJobTemplateModal
        cachedJobTemplates={cachedJobTemplates}
        jobTemplate={row.original}
        refetchProduct={refetchProduct}
        onClose={hideDeleteModal}
        onSaveOverride={onSaveOverride}
      />
    );
  }, [row]);

  const options: MoreMenuOption[] = [
    {
      disabledTooltipProps: {
        description: getTemplateEditDisabledReason({
          isEditing: isEditingJobs,
        }),
      },
      enabled: !isEditingJobs,
      name: 'Edit',
      onOptionClick: () => {
        setIsEditingJobs(true);
        setEditingTemplateId(jobTemplateId);
      },
    },
    {
      disabledTooltipProps: {
        description: getTemplateEditDisabledReason({
          isEditing: isEditingJobs,
          isMinorVersion,
        }),
      },
      enabled: !isEditingJobs && !isMinorVersion,
      name: 'Delete',
      onOptionClick: showDeleteModal,
    },
  ];

  return (
    <Conditional condition={!isEditingRow && isDraft && !isGroupTitle}>
      <Flex justifyContent="end">
        <MoreMenu options={options} />
      </Flex>
    </Conditional>
  );
};
