import { Cell, FileUploader, HandleUploadsComplete } from 'gantri-components';
import { getActiveEnv } from '../../../../helpers/get-active-env';
import { useCloudinaryFileUploader } from '../../../../hooks/use-cloudinary-file-uploader';
import { useSortableItem } from '../../../../hooks/use-sortable-item';
import { RenderUploadedFileProps } from './render-uploaded-file.types';

export const RenderUploadedFile = (props: RenderUploadedFileProps) => {
  const {
    draggable,
    expectedExtensions,
    fileName,
    getFolderStructureArgs,
    id,
    index,
    newFileName,
    onClick,
    onImageRemove,
    onImageReplace,
    replaceable,
    selectedPhoto,
    uploaderWidthValue,
  } = props;

  const { SortableDragHandleWrapper, SortableItemWrapper } = useSortableItem({
    id,
    useDragHandle: true,
  });

  const onFileDelete = async () => {
    await onImageRemove(index);
  };

  const handleReplaceComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    await onImageReplace(fileName, index);
  };

  const { fileUploaderProps } = useCloudinaryFileUploader({
    ...getFolderStructureArgs,
    fileName,
    // This is the rendered file component, so you can only replace, never upload from nothing
    handleUploadsComplete: handleReplaceComplete,
    onFileDelete,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace(newFileName));
    },
  });

  const isActive = fileName === selectedPhoto;

  const { isLocalEnv } = getActiveEnv();

  return (
    <SortableItemWrapper title={fileName}>
      <Cell key={fileName}>
        <FileUploader
          {...fileUploaderProps}
          enableCopyUrl={isLocalEnv}
          expectedExtensions={expectedExtensions}
          isActive={isActive}
          isDraggable={draggable}
          isReplaceable={replaceable}
          SortableDragHandleWrapper={SortableDragHandleWrapper}
          thumbnailSize={uploaderWidthValue}
          variant="thumbnail"
          onClick={onClick}
        />
      </Cell>
    </SortableItemWrapper>
  );
};
