import { memo, Fragment, useState, useEffect } from 'react';
import {
  Typography,
  Flex,
  Grid,
  Dropdown,
  SearchField,
} from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { MeetCollectionProps, SkuTypes } from './meet-collection.types';
import {
  MeetCollection as ProductType,
  ProductNames,
} from '../../desginer-feature.types';

export const Section = (props: MeetCollectionProps) => {
  const { meetCollection, products, updateFeatureInfo } = props;
  const [skus, setSkus] = useState<SkuTypes[]>([]);

  const addNewProduct = () => {
    updateFeatureInfo({
      key: 'meetCollection',
      type: 'key',
      value: [...meetCollection, { id: '', skuSelected: '', skus: '' }],
    });
  };

  const onProductSelect = (position: number) => {
    return (selectedProduct: ProductNames) => {
      const skuArray =
        skus
          .find((sku) => {
            return sku.id === selectedProduct.id;
          })
          .skus?.map((sku, index) => {
            return {
              label: sku,
              name: sku,
              value: index,
            };
          }) || [];

      updateFeatureInfo({
        key: 'meetCollection',
        type: 'key',
        value: meetCollection.map((product, index) => {
          return index === position
            ? { id: selectedProduct.id, skuSelected: '', skus: skuArray }
            : product;
        }),
      });
    };
  };

  const onSkuSelected = (position: number) => {
    return (event) => {
      meetCollection[position].skuSelected = event.name;
      updateFeatureInfo({
        key: 'meetCollection',
        type: 'key',
        value: [...meetCollection],
      });
    };
  };

  const removeProduct = (position: number) => {
    updateFeatureInfo({
      key: 'meetCollection',
      type: 'key',
      value: meetCollection.filter((product, index: number) => {
        return index !== position;
      }),
    });
  };

  useEffect(() => {
    setSkus(
      products.map((product) => {
        return { id: product.id, skus: product.skus };
      }),
    );
  }, [products]);

  return (
    <StyledSectionContainer>
      <Flex>
        <Typography text="Meet the collection" textStyle="bold" variant="h4" />
        <Typography
          align="right"
          color="link"
          decoration="underline"
          marginLeft="0.8rem"
          marginTop="8px"
          style={{
            cursor: 'pointer',
          }}
          text="Add products"
          onClick={addNewProduct}
        />
      </Flex>
      <Grid
        columns={{ lg: '1fr 28.7rem 10rem', sm: '1fr' }}
        gap="1.1rem"
        justifyContent="space-between"
        marginTop="2rem"
      >
        {meetCollection?.map((product: ProductType, index: number) => {
          return (
            <Fragment key={index}>
              <SearchField
                idProperty="id"
                items={products || []}
                labelProperty="name"
                placeholder="Product name..."
                value={product.id}
                onSelect={onProductSelect(index)}
              />

              {!!product.skus && (
                <Dropdown
                  idProperty="name"
                  items={product.skus}
                  placeholder="Product SKU..."
                  value={product.skuSelected}
                  onSelect={onSkuSelected(index)}
                />
              )}

              <Typography
                align={!!product.skus ? 'right' : 'left'}
                color="alert"
                decoration="underline"
                marginLeft="1.5rem"
                style={{
                  cursor: 'pointer',
                }}
                text="Remove"
                onClick={() => {
                  return removeProduct(index);
                }}
              />
            </Fragment>
          );
        })}
      </Grid>
    </StyledSectionContainer>
  );
};

export const MeetCollection = memo(Section);
