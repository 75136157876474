import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateBatchSortOrderArgs,
  UpdateBatchSortOrderResponse,
} from './update-batch-sort-order.types';

export const updateBatchPartSortOrder = (args: UpdateBatchSortOrderArgs) => {
  const { batchId, parts } = args;

  return axios.put<UpdateBatchSortOrderResponse>(
    `${coreApiUrl}/batch/${batchId}/sort`,
    {
      parts,
    },
  );
};
