import { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Select from '../select';
import styles from '../styles.module.scss';

const SelectInput = ({
  classnames,
  hasError,
  labelProperty,
  onChange,
  options,
  placeholder,
  style,
  value,
  valueProperty,
}) => {
  const [innerValue, setInnerValue] = useState(value);
  const [isStringArray, setIsStringArray] = useState(false);

  useEffect(() => {
    if (options && options.length) {
      if (typeof options[0] === 'string') {
        setIsStringArray(true);
      }
    }
  }, [options]);

  useEffect(() => {
    if (value !== innerValue) {
      setInnerValue(value);
      onChange(value);
    }
  }, [value]);

  const onSelectChange = (newValue) => {
    setInnerValue(newValue);
    onChange && onChange(newValue);
  };

  return (
    <Select
      classnames={cx(classnames, { [`${styles.error}`]: hasError })}
      defaultValue={placeholder}
      style={style}
      value={innerValue}
      onChange={onSelectChange}
    >
      {placeholder && (
        <option className="option-disabled" disabled value="">
          {placeholder}
        </option>
      )}

      {options &&
        options.map((option, index) => {
          return (
            <option
              key={index}
              value={isStringArray ? option : option[valueProperty || 'value']}
            >
              {capitalize(
                (isStringArray ? option : option[labelProperty || 'label']) ||
                  '',
              )}
            </option>
          );
        })}
    </Select>
  );
};

SelectInput.defaultProps = {
  onChange: null,
  options: [],
  placeholder: 'Please select',
  value: '',
};

SelectInput.propTypes = {
  classnames: PropTypes.string,
  hasError: PropTypes.bool,
  labelProperty: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ]),
  ),
  placeholder: PropTypes.string,
  style: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueProperty: PropTypes.string,
};

export default SelectInput;
