import { CSSProperties, FC } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import { Property } from 'csstype';
import Tooltip from '../tooltip';
import Thumbnail from '../thumbnail/thumbnail';
import styles from './styles.module.scss';

interface ThumbnailLinkProps {
  alt?: string;
  bgSize?: Property.BackgroundSize;
  border?: Property.Border;
  cursor?: Property.Cursor;
  imageSize?: ResolutionAwareProp<CSSProperties['width']>;
  imageUrl?: string;
  link?: any;
  onClick?: () => void;
  tooltipText?: string;
}

const ThumbnailLink: FC<ThumbnailLinkProps> = ({
  alt,
  bgSize,
  border,
  cursor,
  imageSize = '4rem',
  imageUrl,
  link = {
    path: '',
    state: {},
  },
  onClick,
  tooltipText = null,
}) => {
  const content = () => {
    if (tooltipText && link.path) {
      return (
        <Tooltip text={tooltipText} tooltipClassnames={cx(styles.tooltip)}>
          <Link state={link.state} to={link.path}>
            <Thumbnail
              alt={alt}
              size={imageSize}
              src={imageUrl}
              style={{ border }}
              onClick={onClick}
            />
          </Link>
        </Tooltip>
      );
    }

    if (!tooltipText && link.path) {
      return (
        <Link state={link.state} to={link.path}>
          <Thumbnail
            alt={alt}
            size={imageSize}
            src={imageUrl}
            style={{ backgroundSize: bgSize, border, marginRight: '1rem' }}
          />
        </Link>
      );
    }

    return (
      <Thumbnail
        alt={alt}
        cursor={cursor}
        size={imageSize}
        src={imageUrl}
        style={{ backgroundSize: bgSize, border, marginRight: '1rem' }}
        onClick={onClick}
      />
    );
  };

  return <>{content()}</>;
};

export default ThumbnailLink;
