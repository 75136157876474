import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CreateVersionResponse, CreateVersionArgs } from '../../version.types';

export const createDraftVersion = (data: CreateVersionArgs) => {
  return axios
    .post<CreateVersionResponse>(`${coreApiUrl}/versions`, data)
    .then(({ data }) => {
      return data;
    });
};
