import { GetRowProps } from 'gantri-components';
import { DownloadCSVProps } from '../../components/common/download-csv/download-csv.types';
import { InventoriesDownloadData } from './helpers/get-download-data/get-download-data.types';
import { PaginatedInventory } from '../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { GetStatus } from '../../helpers/get-table-row-status-from-attentions/get-table-row-status-from-attentions.types';

export const downloadHeaders: DownloadCSVProps<InventoriesDownloadData>['headers'] =
  [
    {
      key: 'id',
      label: 'Inventory #',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'vendorName',
      label: 'Vendor',
    },
    {
      key: 'unit',
      label: 'Unit',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'usage',
      label: 'Usage',
    },
    {
      key: 'stock',
      label: 'Stock',
    },
    {
      key: 'alertLevel',
      label: 'Alert Level',
    },
    {
      key: 'unitCost',
      label: 'Unit Cost',
    },
  ];

const getStatus: GetStatus<PaginatedInventory> = (row) => {
  switch (row.original.stockStatus) {
    case 'red':
      return 'error';
    case 'yellow':
      return 'warning';
    default:
      return undefined;
  }
};

export const getRowProps: GetRowProps<PaginatedInventory> = (row) => {
  return {
    status: getStatus(row),
  };
};
