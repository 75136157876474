import { useRecoilState, useRecoilValue } from 'recoil';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { CompleteChecklistContentProps } from './complete-checklist-content.types';

export const CompleteChecklistContent = (
  props: CompleteChecklistContentProps,
) => {
  const { updateChecklist } = props;

  const checklist = useRecoilValue(completeQaQcModalAtoms.checklist);
  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completeQaQcModalAtoms.isChecklistReviewed,
  );

  return (
    <AdvancedChecklist
      buttonToggleWidth="8rem"
      checklist={checklist}
      isChecklistReviewed={isChecklistReviewed}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={['Pass', 'Fail']}
      onItemChange={updateChecklist}
    />
  );
};
