import { useToggle } from 'react-use';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../../api';
import { FailQaJobArgs } from '../../../../../../../../../../../api/jobs/routes/fail-qa-job/fail-qa-job.types';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { checkAgainstFailingChecklistItems } from '../../../../../../../helpers/check-against-failing-checklist-items';
import { getFailJobReasonArg } from '../../../../../../../helpers/get-fail-job-reason-arg';
import { printQcModalAtoms } from '../../../../../complete-print-qa-modal.atoms';
import { UsePassOrFailJobProps } from './use-pass-or-fail-job.types';

export const usePassOrFailJob = (props: UsePassOrFailJobProps) => {
  const { handleCloseModal, job } = props;

  const setStep = useSetRecoilState(printQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(printQcModalAtoms.updateOnClose);
  const setDuplicatedJobs = useSetRecoilState(printQcModalAtoms.duplicatedJobs);
  const setIsMachineInProgress = useSetRecoilState(
    printQcModalAtoms.isMachineInProgress,
  );
  const setNextJob = useSetRecoilState(printQcModalAtoms.nextJob);
  const checklist = useRecoilValue(printQcModalAtoms.checklist);
  const selectedPrintJob = useRecoilValue(printQcModalAtoms.selectedPrintJob);

  const [processing, toggleProcessing] = useToggle(false);
  const { notify, notifyAxiosError } = useNotification();

  const onFail = async () => {
    try {
      toggleProcessing();

      const shouldReprintPart = checkAgainstFailingChecklistItems(
        checklist,
        (item) => {
          return item.failStatusRecommendation === 'Reprint';
        },
      );
      const reason = getFailJobReasonArg(checklist, {
        material: job.part.material,
      });

      const body: FailQaJobArgs = {
        duplicateType: shouldReprintPart ? 'Reprint' : undefined,
        printJobId: selectedPrintJob.id,
        reason,
      };

      const { duplicatedJobs, machine, notice } = await jobsApi.failQaJob(
        job.id,
        body,
      );

      if (!!duplicatedJobs?.length) {
        setDuplicatedJobs(duplicatedJobs);
        setUpdateOnClose(true);
      }

      const isMachineInProgress = machine?.status === 'In progress';

      if (isMachineInProgress) {
        setIsMachineInProgress(isMachineInProgress);
      }

      notify(notice);
      setStep('JOB_FAILED');
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fail job.' });
    } finally {
      toggleProcessing();
    }
  };

  const onPass = async () => {
    try {
      toggleProcessing();

      const reason = getFailJobReasonArg(checklist, {
        material: job.part.material,
      });

      const data = await jobsApi.completeJob({
        jobId: job.id,
        printJobId: selectedPrintJob.id,
        reason,
      });
      const { nextJob, notice } = data;

      notify(notice);

      if (!!nextJob) {
        setNextJob(nextJob);
      }

      await handleCloseModal({ updateOnClose: true });
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to complete job.' });
    } finally {
      toggleProcessing();
    }
  };

  return { onFail, onPass, processing };
};
