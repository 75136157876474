import React, { FC } from 'react';
import { Button, Flex } from 'gantri-components';
import { useFormikContext } from 'formik';
import { InventoryFormData } from '../inventory.schema';

interface FormHeaderProps {
  isEditModeActive?: boolean;
  isSyncing?: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onSave?: () => void;
}

export const FormHeader: FC<FormHeaderProps> = (props) => {
  const { isEditModeActive, isSyncing, onCancel, onEdit, onSave } = props;
  const { isValid } = useFormikContext<InventoryFormData>();

  if (!isEditModeActive)
    return (
      <Button text="Edit Inventory" variant="secondary" onClick={onEdit} />
    );

  return (
    <Flex gap=".4rem">
      <Button
        disabled={!isValid}
        processing={isSyncing}
        text="Save"
        type="submit"
        onClick={onSave}
      />
      <Button
        disabled={isSyncing}
        text="Cancel"
        variant="secondary"
        onClick={onCancel}
      />
    </Flex>
  );
};
