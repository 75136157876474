import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GetPaginatedJobsArgs } from '../get-paginated-jobs/get-paginated-jobs.types';
import { GetPaginatedJobsCountResponse } from './get-paginated-jobs-count.types';

export const getPaginatedJobsCount = ({
  attentionOnly = false,
  colors = [],
  completedDateRange,
  count = 100,
  createdDateRange,
  locations = [],
  machineIssues = [],
  machineTypes = [],
  materials = [],
  page = 1,
  search = '',
  sortBy = '',
  statuses = [],
  steps = [],
  types = [],
  userIds = [],
  userStatuses = [],
  ...rest
}: GetPaginatedJobsArgs) => {
  return axios.post<GetPaginatedJobsCountResponse>(
    `${coreApiUrl}/jobs/paginatedJobsCount`,
    {
      ...rest,
      attentionOnly,
      colors,
      completedEndDate: completedDateRange?.to,
      completedStartDate: completedDateRange?.from,
      count,
      createdEndDate: createdDateRange?.to,
      createdStartDate: createdDateRange?.from,
      locations,
      machineIssues,
      machineTypes,
      materials,
      page,
      search,
      sortBy,
      statuses,
      steps,
      types,
      userIds,
      userStatuses,
    },
  );
};

const query = (args: GetPaginatedJobsArgs) => {
  return getPaginatedJobsCount(args).then(({ data }) => {
    return data;
  });
};

export const fetchPaginatedJobsCount = getGenericQueryObj({
  groupName: 'paginated-jobs-count',
  query,
});
