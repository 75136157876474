import { IconCylindricalTool } from './components/icon-cylindrical-tool';
import { IconOverlayTool } from './components/icon-overlay-tool';
import { IconWarpingGauge } from './components/icon-warping-gauge';
import { ToolIconProps } from './tool-icon.types';

export const ToolIcon = (props: ToolIconProps) => {
  const { tool } = props;

  switch (tool) {
    case 'Cylindrical Tool':
      return <IconCylindricalTool />;
    case 'Overlay Tool':
      return <IconOverlayTool />;
    case 'Warping Gauges':
      return <IconWarpingGauge />;
    default:
      return null;
  }
};
