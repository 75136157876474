import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportOrdersArgs,
  GetReportOrdersResponse,
} from './get-report-orders.types';

export const getReportOrders = (
  { rangeType, ...queries }: GetReportOrdersArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportOrdersResponse>(
    `${coreApiUrl}/reports/orders${queriesString}`,
    configs,
  );
};
