import styled from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledFileName = styled(Typography)`
  margin-right: 8px;
  max-width: 230px;
  word-break: break-word;
  text-decoration: underline;
  cursor: pointer;
`;
