import { useEffect, useMemo } from 'react';
import {
  Conditional,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { sortBy } from 'lodash';
import { InventoryForm } from '../../inventory-form';
import { InventoryDataCellProps } from './inventory-data-cell.types';

export const InventoryDataCell = (props: InventoryDataCellProps) => {
  const { editing, hasError, hideExceedsStockIcon, inventoryRequests, onEdit } =
    props;

  const sortedInventoryRequests = useMemo(() => {
    const sortedInventoryRequests = sortBy(
      inventoryRequests,
      (inventoryRequest) => {
        return (
          inventoryRequest?.name || inventoryRequest.inventory?.name
        )?.trim();
      },
    );

    return sortedInventoryRequests;
  }, [editing]);

  useEffect(() => {
    if (editing) {
      // sort once when edit becomes enabled and update the inventories
      onEdit(sortedInventoryRequests);
    }
  }, [editing]);

  return (
    <Conditional
      condition={editing}
      Fallback={
        <Stack gap="0">
          {sortedInventoryRequests?.map((inventoryRequest) => {
            if (inventoryRequest.inventory) {
              const needWithUnitText = `${inventoryRequest.need || 0} ${
                inventoryRequest.inventory.unit
                  ? inventoryRequest.inventory.unit.toLowerCase()
                  : ''
              } ${inventoryRequest.inventory.name}`;

              return (
                <Typography
                  key={inventoryRequest.inventory.id}
                  icon={
                    inventoryRequest.inventory.status === 'Inactive' && (
                      <Tooltip
                        maxWidth="20.5rem"
                        overlayContainerStyles={{ top: '1px' }}
                        position="bottom-start"
                        title="Inventory is inactive. Please remove or replace this inventory."
                      >
                        <Icon color="alert" name="alert:warning_triangle" />
                      </Tooltip>
                    )
                  }
                  iconPosition="right"
                  text={needWithUnitText}
                  variant="p2"
                />
              );
            }

            return null;
          })}
        </Stack>
      }
    >
      <InventoryForm
        hasError={hasError}
        hideExceedsStockIcon={hideExceedsStockIcon}
        inventoryRequests={inventoryRequests}
        onEdit={onEdit}
      />
    </Conditional>
  );
};
