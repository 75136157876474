import React, { FC, MouseEvent } from 'react';
import { Image } from 'gantri-components';
import { ThumbnailProps } from './thumbnail.types';
import { ThumbnailPresets } from './thumbnail.presets';
import { StyledThumbnail } from './thumbnail.styles';
import { placeholderImageSrc } from '../../constants/images';

export const Thumbnail: FC<ThumbnailProps> = (props) => {
  const { alt, borderColor, onClick, size, src, ...rest } = props;

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    onClick?.();
  };

  return (
    <StyledThumbnail
      $borderColor={borderColor}
      $hasAction={!!onClick}
      $size={size}
      onClick={handleClick}
    >
      <Image
        src={src || placeholderImageSrc}
        {...rest}
        alt={alt || ''}
        height={size}
        maxHeight={`${size}px`}
        maxWidth={`${size}px`}
        minHeight={`${size}px`}
        minWidth={`${size}px`}
        style={{ cursor: onClick ? 'pointer' : 'default' }}
        transformations={{
          mode: 'fit',
        }}
        width={size}
      />
    </StyledThumbnail>
  );
};

Thumbnail.defaultProps = ThumbnailPresets;
