import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateProductJobBlocksArgs,
  UpdateProductJobBlocksResponse,
} from './update-product-job-blocks.types';

export const updateProductJobBlocks = ({
  id,
  ...body
}: UpdateProductJobBlocksArgs) => {
  return axios
    .put<UpdateProductJobBlocksResponse>(
      `${getCoreApiUrl('product-job-blocks')}/${id}`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
