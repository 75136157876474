import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultStocksGroupBy } from '../../report-stocks.constants';
import { ReportStocksQueries } from '../../report-stocks.types';

export const getTitle = (rootTitle: string): string => {
  const queries = getActiveQueries<ReportStocksQueries>();
  const {
    groupBy = defaultStocksGroupBy,
    productId,
    productName,
    version,
  } = queries;

  if (/^productId$/i.test(groupBy) && productId) {
    if (version) {
      return `${version}`;
    }

    return productName;
  }

  return rootTitle;
};
