export const inventoryPurchaseStatuses = {
  arrived: 'Arrived',
  completed: 'Completed',
  delivered: 'Delivered',
  partial: 'Partial',
  readyToShip: 'Ready to ship',
  shipped: 'Shipped',
  waiting: 'Waiting',
} as const;

export const currencyOptions: Record<
  string,
  { country: string[]; symbol: string }
> = {
  CAD: {
    country: ['Canada'],
    symbol: '$',
  },
  CNY: {
    country: ['China'],
    symbol: '¥',
  },
  EUR: {
    country: [
      'Andorra',
      'Austria',
      'Belgium',
      'Cyprus',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Ireland',
      'Italy',
      'Latvia',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Monaco',
      'Serbia and Montenegro',
      'Netherlands',
      'Portugal',
      'San Marino',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Holy See (Vatican City State)',
    ],
    symbol: '€',
  },
  GBP: {
    country: ['United Kingdom', 'South Georgia and the South Sandwich Islands'],
    symbol: '£',
  },
  JPY: {
    country: ['Japan'],
    symbol: '¥',
  },
  USD: {
    country: ['United States'],
    symbol: '$',
  },
};

export const currenciesAsOptions = Object.keys(currencyOptions).map(
  (currency) => {
    return { label: currency, value: currency };
  },
);

export const factoryDestinations = [
  {
    label: 'Gantri Factory - Polvorosa',
    value: '2265 Polvorosa Ave., Suite 310, San Leandro, California, 94577',
  },
  {
    label: 'Gantri HQ - Capp',
    value: '170 Capp Street, Suite 3B, San Francisco, CA, 94110',
  },
];

export const inventoryPurchasePriority = ['Normal', 'Urgent'];

export const inventoryPurchaseFields = {
  arrviedAt: 'arrivedAt',
  cargoReady: 'cargoReadyAt',
  completedAt: 'completedAt',
  deliveredAt: 'deliveredAt',
  eta: 'eta',
  etd: 'etd',
  inventoryId: 'inventoryId',
  shippedAt: 'shippedAt',
};
