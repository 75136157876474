import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetUserActivitiesArgs,
  GetUserActivitiesResponse,
} from './get-user-activities.types';

export const getUserActivities = ({
  dateRange,
  userId,
  ...rest
}: GetUserActivitiesArgs) => {
  return axios.post<GetUserActivitiesResponse>(
    `${coreApiUrl}/get-user/${userId}/activities`,
    {
      ...rest,
      endDate: dateRange.to,
      startDate: dateRange.from,
    },
  );
};
