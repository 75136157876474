import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { fetchShortProductSummary } from './fetch-short-product-summary';
import {
  FetchShortProductSummaryArgs,
  FetchShortProductSummaryResponse,
} from './fetch-short-product-summary.types';

const fetchShortProductSummaryQueriesObj = getGenericQueryObj({
  groupName: 'short-product-summary',
  query: fetchShortProductSummary,
});

export const useFetchShortProductSummary = <
  TransformedData = FetchShortProductSummaryResponse,
>(
  props?: GenericFetchProps<
    FetchShortProductSummaryArgs,
    FetchShortProductSummaryResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch short product summary.',
    queryObj: fetchShortProductSummaryQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchShortProductSummaryCache = () => {
  const invalidateFetchShortProductSummaryCache = useGetInvalidateQueryCache(
    fetchShortProductSummaryQueriesObj,
  );

  return { invalidateFetchShortProductSummaryCache };
};
