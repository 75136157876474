import { Icon, Overlay } from 'gantri-components';
import { UserPreferencesModal } from './components/user-preferences-modal/user-preferences-modal';
import { CustomColumns } from '../../types/store';
import { StyledButton } from './user-preferences-toggle.styles';
import { UserPreferencesToggleProps } from './user-preferences-toggle.types';

/** Toggles a dialog where the user can save their preferences for the specified `page`. */
export const UserPreferencesToggle = <Page extends keyof CustomColumns>(
  props: UserPreferencesToggleProps<Page>,
) => {
  const {
    checklistOptions,
    isActive,
    modalHeading,
    onClose,
    onReset,
    page,
    setChecklistOptions,
    setIsActive,
  } = props;

  const handleOnClose = async () => {
    await onClose();
    setIsActive(false);
  };

  return (
    <Overlay
      content={
        <UserPreferencesModal
          checklistOptions={checklistOptions}
          setChecklistOptions={setChecklistOptions}
          title={modalHeading}
          userPreferencesPage={page}
          onClose={handleOnClose}
          onReset={onReset}
        />
      }
      contentStyles={{
        inset: 'unset !important',
      }}
      open={isActive}
      position="bottom-end"
      triggerEvent="click"
      onClose={handleOnClose}
      onOpen={() => {
        setIsActive(true);
      }}
    >
      <StyledButton
        data-is-active={isActive}
        icon={
          <Icon
            color={isActive ? 't2' : 'link'}
            name="actions:gear_24"
            size="1.6rem"
          />
        }
        variant="secondary"
      />
    </Overlay>
  );
};
