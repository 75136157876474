import { FC } from 'react';
import { Typography } from 'gantri-components';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { StyledPaymentInfoField, StyledPaymentTitle } from './summary.styles';
import { SummaryProps } from './summary.types';

export const OrderPaymentSummary: FC<SummaryProps> = ({ amount, gift }) => {
  const fSub = formatAsCurrency(amount.subtotal, { isCents: true });
  const fShip = formatAsCurrency(amount.shipping, { isCents: true });
  const fTax = formatAsCurrency(amount.tax, { isCents: true });
  const fCredits = formatAsCurrency(amount.credit, { isCents: true });
  const fDiscount = formatAsCurrency(amount.gift || amount.discount, {
    isCents: true,
  });
  const fTotal = formatAsCurrency(amount.total, { isCents: true });

  return (
    <>
      <StyledPaymentTitle>
        <Typography text="Summary" variant="h4" />
      </StyledPaymentTitle>
      <StyledPaymentInfoField>
        <Typography color="t1" text="Subtotal" />
        <Typography color="t1" text={fSub} />
      </StyledPaymentInfoField>
      <StyledPaymentInfoField>
        <Typography color="t1" text="Shipping" />
        <Typography color="t1" text={fShip} />
      </StyledPaymentInfoField>
      <StyledPaymentInfoField>
        <Typography color="t1" text="Tax" />
        <Typography color="t1" text={fTax} />
      </StyledPaymentInfoField>
      {!!amount.credit && (
        <StyledPaymentInfoField>
          <Typography color="t1" text="Credits" />
          <Typography color="t1" text={fCredits} />
        </StyledPaymentInfoField>
      )}
      {(!!amount.gift || !!amount.discount) && (
        <StyledPaymentInfoField>
          <Typography color="t1" text="Discount" />
          <Typography color="t1" text={fDiscount} />
        </StyledPaymentInfoField>
      )}
      {gift?.type === 'Gift' && (
        <StyledPaymentInfoField>
          <Typography color="t1" text="Gift Code" />
          <Typography color="t1" text={gift.code} />
        </StyledPaymentInfoField>
      )}
      <StyledPaymentInfoField>
        <Typography color="t1" text="Total" />
        <Typography color="t1" text={fTotal} />
      </StyledPaymentInfoField>
    </>
  );
};
