import styled, { css } from 'styled-components';

export const StyledDateRange = styled.div<{
  $autoWidth: boolean;
  $range: boolean;
}>`
  position: relative;
  font-size: 1.5rem;
  min-width: ${({ $range }) => {
    return $range ? '26rem' : '15rem';
  }};
  width: ${({ $autoWidth }) => {
    return $autoWidth && '100%';
  }};
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t2};
    `;
  }}
`;

export const StyledContent = styled.div<{ $hasValue: boolean }>`
  ${({ $hasValue, theme }) => {
    return css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding: 0.5rem 1rem;
      user-select: none;
      color: ${$hasValue
        ? theme.colors.typography.t1
        : theme.colors.typography.t2};
    `;
  }}
`;

export const StyledToggleDate = styled.div<{ $isFilledIn: boolean }>`
  ${({ $isFilledIn, theme }) => {
    const placeholderColor =
      theme.name === 'light'
        ? theme.colors.typography.t2
        : theme.colors.typography.t1;

    return css`
      color: ${$isFilledIn ? theme.colors.typography.t1 : placeholderColor};
      width: 10.5rem;
      cursor: pointer;
      padding-right: 1rem;
      white-space: nowrap;
    `;
  }}
`;
