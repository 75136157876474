import { FC, useState, useEffect } from 'react';
import { Box, Flex, Icon, Tooltip, Typography } from 'gantri-components';
import { DateUserDataCellProps } from './date-user-data-cell.types';
import { formatDate } from '../../../../helpers/formatter';
import routePaths from '../../../../config/route-paths';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../../constants/dates';
import { StyledAnchor } from '../../../../components/common/styled-anchor';

export const DateWithUserDataCell: FC<DateUserDataCellProps> = ({
  data,
  date,
}) => {
  const [tooltipData, setTooltipData] = useState<any>();

  const TooltipContent = () => {
    const formattedDate = formatDate(+date, LONG_FORMAT);

    if (tooltipData?.user && tooltipData?.userId) {
      return (
        <Box horizontalPadding="2x" verticalPadding="x">
          <Typography display="inline" text={`${formattedDate}`} variant="p2" />
          <Typography color="t2" display="inline" text=" by " variant="p2" />
          <StyledAnchor
            display="inline"
            text={`${tooltipData.user.firstName} ${tooltipData.user.lastName}`}
            to={`${routePaths.users}/${tooltipData.userId}`}
          />
        </Box>
      );
    }

    return (
      <Box horizontalPadding="2x" verticalPadding="x">
        <Typography color="t2" text={`${formattedDate}`} />
      </Box>
    );
  };

  useEffect(() => {
    setTooltipData(data);
  }, [data]);

  return data ? (
    <Flex alignItems="center" gap="x">
      <Typography text={formatDate(+date, MEDIUM_FORMAT)} />
      <Tooltip Component={<TooltipContent />} position="top">
        <Icon name="alert:i_circle" top="2px" />
      </Tooltip>
    </Flex>
  ) : null;
};
