import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CompleteNonProductJobArgs,
  CompleteNonProductJobResponse,
} from './complete-non-product-job.types';

export const completeNonProductJob = ({
  inventoryRequests,
  jobId,
  notes,
}: CompleteNonProductJobArgs) => {
  return axios
    .put<CompleteNonProductJobResponse>(
      `${coreApiUrl}/jobs/non-product/${jobId}/complete`,
      { inventoryRequests, notes },
    )
    .then(({ data }) => {
      return data;
    });
};
