import { productColorsMap } from 'gantri-components';
import { Row } from '@tanstack/react-table';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import {
  ReportSalesQueries,
  ReportSalesTableData,
} from '../../report-sales.types';

export const getRowHref = (row: Row<ReportSalesTableData>) => {
  const windowLocation = window.location.origin + window.location.pathname;
  const queries = getActiveQueries<ReportSalesQueries>();
  const { color, country, creatorId, customerType, groupBy, productId, state } =
    queries;
  const rowData = row?.original;

  if (/^total$/i.test(rowData?.type)) {
    return;
  }

  if (!groupBy) {
    const queryString = convertQueriesToString({
      groupBy: 'productId',
      productId: rowData?.info?.id,
      productName: rowData?.info?.name,
    });

    return windowLocation + queryString;
  }

  if (/^creatorId$/i.test(groupBy)) {
    if (!creatorId) {
      const queryString = convertQueriesToString({
        ...queries,
        creatorId: rowData?.info?.id,
        creatorName: rowData?.info?.name,
      });

      return windowLocation + queryString;
    }

    if (creatorId && !productId) {
      const queryString = convertQueriesToString({
        ...queries,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
      });

      return windowLocation + queryString;
    }
  }

  if (/^customerType$/i.test(groupBy)) {
    if (!customerType) {
      const queryString = convertQueriesToString({
        ...queries,
        customerType: rowData?.type,
      });

      return windowLocation + queryString;
    }

    if (customerType && !productId) {
      const queryString = convertQueriesToString({
        ...queries,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
      });

      return windowLocation + queryString;
    }
  }

  if (/^productId$/i.test(groupBy)) {
    if (!productId) {
      const queryString = convertQueriesToString({
        ...queries,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
      });

      return windowLocation + queryString;
    }
  }

  if (/^country$/i.test(groupBy)) {
    if (!country) {
      if (!/^(united states)|(US)$/i.test(rowData.type)) {
        const queryString = convertQueriesToString({
          ...queries,
          country: rowData.type,
          state: 'noStates',
        });

        return windowLocation + queryString;
      }

      const queryString = convertQueriesToString({
        ...queries,
        country: rowData.type,
      });

      return windowLocation + queryString;
    }

    if (country && /$noStates$/i.test(state)) {
      const queryString = convertQueriesToString({
        ...queries,
        state: rowData?.type,
      });

      return windowLocation + queryString;
    }

    if (country && !state) {
      const queryString = convertQueriesToString({
        ...queries,
        state: rowData?.type,
      });

      return windowLocation + queryString;
    }
  }

  if (/^color$/i.test(groupBy)) {
    if (!color) {
      const colorName = rowData?.type;
      const colorDetails = Object.values(productColorsMap).find(
        ({ shortColorName }) => {
          return colorName === shortColorName;
        },
      );
      const queryString = convertQueriesToString({
        ...queries,
        color: colorDetails?.code,
      });

      return windowLocation + queryString;
    }
  }
};
