import { useEffect, useState } from 'react';
import {
  Grid,
  Icon,
  Typography,
  Tooltip,
  SearchField,
  Conditional,
} from 'gantri-components';
import { debounce } from 'lodash';
import UnitInput from '../../../inputs/unit-input';
import {
  StyledItemGrid,
  StyledRemoveRowIconContainer,
  StyledUnitInputContainer,
} from './inventory-form-item.styles';
import {
  InventoryFormItemProps,
  InventoryOption,
} from './inventory-form-item.types';
import { InventoryRequest } from '../../../../../api/jobs/jobs.types';
import { getInventoryWarnings } from '../../../editable-inventories-table/helpers/get-inventory-warnings';

export const InventoryFormItem = (props: InventoryFormItemProps) => {
  const {
    allInventories,
    hideExceedsStockIcon,
    inventory,
    inventoryRequests,
    isFetching,
    need,
    onEdit,
  } = props;

  const [searchValue, setSearchValue] = useState<string>(inventory?.name);
  const [inventoryOptions, setInventoryOptions] = useState<InventoryOption[]>(
    [],
  );

  const { isInactive, isStockExceeded: isStockExceededBase } =
    getInventoryWarnings({ inventory, need });
  const isStockExceeded = hideExceedsStockIcon ? false : isStockExceededBase;
  const showWarningIcon = isInactive || isStockExceeded;

  const removeInventoryRequest = () => {
    const updatedInventoryRequests = inventoryRequests.filter(
      (inventoryRequest) => {
        return inventoryRequest.inventory.id !== inventory.id;
      },
    );

    onEdit(updatedInventoryRequests);
  };

  const handleEditInventory = (field: keyof InventoryRequest) => {
    return (value: InventoryRequest[keyof InventoryRequest]) => {
      const transformedValue = Number.isNaN(+value) ? value : +value;

      const inventoryId: string | number =
        typeof transformedValue === 'object' && field === 'inventory'
          ? Number(transformedValue.id)
          : inventory.id;

      const updatedInventoryRequests: InventoryRequest[] =
        inventoryRequests.map((inventoryRequest) => {
          return inventoryRequest.inventory.id === inventory.id
            ? {
                ...inventoryRequest,
                [field]: transformedValue,
                inventoryId,
              }
            : inventoryRequest;
        });

      onEdit(updatedInventoryRequests);
    };
  };

  const handleSelectInventoryItem = (value: number | string) => {
    const selectedInventory = allInventories.find((inventory) => {
      return inventory.id === Number(value);
    });

    if (selectedInventory) {
      const newInventory: InventoryRequest['inventory'] = {
        id: selectedInventory.id,
        name: selectedInventory.name,
        status: selectedInventory.status,
        stock: parseFloat(String(selectedInventory.stock)) || 0,
        unit: selectedInventory.unit || 'Each',
      };

      handleEditInventory('inventory')(newInventory);
    }
  };

  // render only current value until all options are available
  const inventoryItems =
    isFetching && inventory
      ? [{ label: inventory.name, value: inventory.id }]
      : inventoryOptions.length
      ? [
          { disabled: true, label: 'Inventory Name', value: 'ID' },
          ...inventoryOptions,
        ]
      : [];

  useEffect(() => {
    const inventoryOptions: InventoryOption[] = allInventories.map(
      ({ id, name }) => {
        const matchesExistingItem = inventoryRequests.some(({ inventory }) => {
          return inventory.id === id;
        });

        return {
          disabled: matchesExistingItem,
          label: name,
          value: id,
        };
      },
    );

    setInventoryOptions(inventoryOptions);
  }, [allInventories, searchValue, inventoryRequests]);

  return inventory ? (
    <Grid
      alignItems="center"
      columns={`1fr repeat(${showWarningIcon ? 3 : 2}, min-content)`}
      gap="0.8rem"
      width="100%"
    >
      <SearchField
        autoSelectFirst={false}
        disabled={isFetching}
        dropdownPopupWidth="auto"
        errorMessage={isInactive ? ' ' : undefined}
        items={inventoryItems}
        maxHeight="40rem"
        placeholder={isInactive ? inventory.name : 'Inventory'}
        renderItem={({ disabled, label, value }) => {
          return (
            <StyledItemGrid
              $disabled={disabled}
              columns="5rem 1fr"
              gap="0.8rem"
              horizontalPadding="1.6rem"
              maxWidth="30rem"
              verticalPadding="0.8rem"
            >
              <Typography color={disabled ? 't3' : 't1'} text={value} />
              <Typography color={disabled ? 't3' : 't1'} text={label} />
            </StyledItemGrid>
          );
        }}
        value={inventory.id}
        onBlur={() => {
          setSearchValue(inventory?.name);
        }}
        onSelect={(item) => {
          handleSelectInventoryItem(item?.value);
        }}
        onTextChange={debounce(setSearchValue, 300)}
      />
      <Conditional condition={showWarningIcon}>
        <Tooltip
          maxWidth="20.5rem"
          overlayContainerStyles={{ top: '1px' }}
          position="top-end"
          title="Inventory is inactive. Please remove or replace this inventory."
        >
          <Icon color="alert" name="alert:warning_triangle" />
        </Tooltip>
      </Conditional>
      <StyledUnitInputContainer>
        <UnitInput
          hasError={isStockExceeded}
          unit={inventory.unit?.toLowerCase() || ''}
          value={parseFloat(String(need))}
          onBlurTextChange={handleEditInventory('need')}
        />
      </StyledUnitInputContainer>
      <StyledRemoveRowIconContainer onClick={removeInventoryRequest}>
        <Icon color="alert" name="actions:trash_can" />
      </StyledRemoveRowIconContainer>
    </Grid>
  ) : null;
};
