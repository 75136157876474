import { jobSteps, jobTypeOptions } from './options';

export const machineTypes = {
  bambuOpaque: 'Bambu Opaque',
  bambuTranslucent: 'Bambu Translucent',
  crealityHighTemp: 'Creality High-Temp PLA',
  crealityTranslucent: 'Creality Translucent PLA',
  tumbler: 'Tumbler',
  ultimakerHighTemp: 'Ultimaker High-Temp PLA',
  ultimakerTranslucent: 'Ultimaker Translucent PLA',
} as const;

export const machineIssueSources = {
  manual: 'Manual',
  print: jobSteps.print,
  printQc: `${jobSteps.print} ${jobTypeOptions.qc}`,
} as const;

export type MachineType = typeof machineTypes[keyof typeof machineTypes];

export const printerTypes: MachineType[] = Object.values(machineTypes);

const removeInactiveMachineTypes = (machineTypesToUpdate: MachineType[]) => {
  const inactiveMachineTypes = [
    machineTypes.tumbler,
    machineTypes.ultimakerHighTemp,
    machineTypes.ultimakerTranslucent,
  ] satisfies MachineType[];

  return machineTypesToUpdate.filter((type) => {
    return !inactiveMachineTypes.some((inactiveType) => {
      return inactiveType === type;
    });
  });
};

export const activePrinterTypes = removeInactiveMachineTypes(printerTypes);

export const opaqueMaterialPrinterTypes = [
  machineTypes.bambuOpaque,
  machineTypes.crealityHighTemp,
  machineTypes.ultimakerHighTemp,
];

export const activeOpaqueMaterialPrinterTypes = removeInactiveMachineTypes(
  opaqueMaterialPrinterTypes,
);

export const translucentMaterialPrinterTypes = [
  machineTypes.bambuTranslucent,
  machineTypes.crealityTranslucent,
  machineTypes.ultimakerTranslucent,
];

export const activeTranslucentMaterialPrinterTypes = removeInactiveMachineTypes(
  translucentMaterialPrinterTypes,
);

export const machineStatuses = {
  inProgress: 'In progress',
  manual: 'Manual',
  offline: 'Offline',
  ready: 'Ready',
} as const;

export type MachineStatus =
  typeof machineStatuses[keyof typeof machineStatuses];

export const machineStatusOptions: MachineStatus[] = [
  machineStatuses.offline,
  machineStatuses.ready,
  machineStatuses.inProgress,
  machineStatuses.manual,
];
