import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedUsersArgs,
  GetPaginatedUsersResponse,
} from './get-paginated-users.types';

const query = (body: GetPaginatedUsersArgs) => {
  return axios.post<GetPaginatedUsersResponse>(`${coreApiUrl}/users`, body);
};

const key = (args?: GetPaginatedUsersArgs) => {
  const queries = [
    groupKey(),
    `active=${String(args?.active)}`,
    `count=${args?.count}`,
    `page=${args?.page}`,
    `search=${args?.search}`,
    `sortingField=${args?.sortingField}`,
    `sortingType=${args?.sortingType}`,
    `types=${args?.types?.join(',') ?? ''}`,
  ];

  return [
    {
      groupKey: groupKey(),
      key: queries.join('|'),
    },
  ] as const;
};

const groupKey = () => {
  return 'paginated-users';
};

export const getPaginatedUsers = {
  groupKey,
  key,
  query,
};
