import { Flex } from 'gantri-components';
import styled from 'styled-components';

export const StyledFlexContainer = styled(Flex)`
  background-color: ${({ theme }) => {
    return theme.name === 'dark'
      ? theme.colors.surfaces.monochrome.t3
      : theme.colors.surfaces.monochrome.t1Alt;
  }};
`;
