import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetInventoryResponse } from './get-inventory.types';

const query = (inventoryId: number) => {
  return axios
    .post<GetInventoryResponse>(
      `${coreApiUrl}/inventory/${inventoryId}/Info`,
      {},
    )
    .then((response) => {
      return response.data;
    });
};

const key = (id: number) => {
  return [`/inventory/${id}/info`];
};

export const getInventoryInfo = {
  key,
  query,
};
