/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FinalQAFailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { Checklist } from '../../common/job-checklist/job-checklist.types';

export const completeFinalQAModalChecklistDefaults: Checklist<FinalQAFailedReason> =
  {
    // keys sorted according to desired display order
    bad_finish: checklistDefaults.bad_finish,
    bad_fit: checklistDefaults.bad_fit,
    damaged_by_assembly: checklistDefaults.damaged_by_assembly,
    wrong_assembly: checklistDefaults.wrong_assembly,
    support_material_or_stringing:
      checklistDefaults.support_material_or_stringing,
    bulb_issue: checklistDefaults.bulb_issue,
    cord_or_switch_issue: checklistDefaults.cord_or_switch_issue,
    other_failure_modes: checklistDefaults.other_failure_modes,
  };
