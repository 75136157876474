import { getTypeColumnHeading } from '../get-type-column-heading';
import {
  ReportMachineIssuesTableData,
  ReportMachinesQueries,
  ReportMachinesTableData,
} from '../../report-machines.types';
import {
  machinesBaseTableColumns,
  machinesBaseSortingOptions,
  defaultMachinesGroupBy,
  reportMachineIssuesSortingOptions,
  reportMachineIssuesTableColumns,
} from '../../report-machines.constants';
import { ReportDetails } from './get-report-details.types';
import { getGroupByIsVisible } from '../get-group-by-is-visible';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { GroupByItem } from '../../../report-jobs/helpers/get-report-details/get-report-details.types';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const {
    groupBy = defaultMachinesGroupBy,
    machineType,
    machineId,
  } = getActiveQueries<ReportMachinesQueries>();
  const heading = getTypeColumnHeading();

  const isIssuesPage = !!groupBy && (!!machineType || !!machineId);

  const restTableColumns = isIssuesPage
    ? reportMachineIssuesTableColumns
    : machinesBaseTableColumns;

  const tableColumns: (
    | DownloadableTableColumn<ReportMachinesTableData>
    | DownloadableTableColumn<ReportMachineIssuesTableData>
  )[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...restTableColumns,
  ];

  const restSortingOptions = isIssuesPage
    ? reportMachineIssuesSortingOptions
    : machinesBaseSortingOptions;

  const sortingOptions: (
    | SortOptionFromTable<ReportMachinesTableData>
    | SortOptionFromTable<ReportMachineIssuesTableData>
  )[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...restSortingOptions,
  ];

  const groupByItems: GroupByItem[] = [
    {
      label: 'Machine Type',
      value: 'machineType',
    },
    {
      label: 'Machine Name',
      value: 'machineName',
    },
  ];

  const groupByOnChange: ReportDetails['groupByOnChange'] = (groupBy) => {
    const windowLocation = window.location.origin + window.location.pathname;
    const queriesString = convertQueriesToString({ groupBy });

    window.location.href = windowLocation + queriesString;
  };

  const groupByIsVisible = getGroupByIsVisible();

  return {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  };
};
