import { FC, PropsWithChildren, useRef, useState } from 'react';
import {
  StyledTooltip,
  StyledTooltipBase,
  StyledTooltipContainer,
} from './tooltip.styles';
import { TooltipProps } from './tooltip.types';

/**
 * @deprecated Use equivalent from gantri-components (https://components.gantri.com/?path=/story/core-tooltip--tooltip)
 * */
export const Tooltip: FC<PropsWithChildren<TooltipProps>> = (props) => {
  const {
    children,
    classnames,
    content,
    nowrap,
    position = 'left',
    text = '',
    tooltipClassnames,
    tooltipVerticalPosition,
    top = 'unset',
  } = props;

  const leftPositions = ['left', 'center'];
  const baseRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState({
    left: leftPositions.includes(position) ? '0' : undefined,
    right: position === 'right' ? '0' : undefined,
    top,
  });

  const setTooltipOverhang = () => {
    const { current } = baseRef;
    const rect = current.getBoundingClientRect();
    const documentRect = document.body.getBoundingClientRect();
    const tooltipRect = tooltipRef.current.getBoundingClientRect();
    const rightPosition = documentRect.width - rect.right;
    const leftPosition =
      rect.left - (position === 'center' ? tooltipRect.width / 2 : 0);
    const topPosition =
      !!top && top !== 'unset' ? top : `${rect.top - tooltipRect.height}px`;
    const bottomPosition = `${rect.top + tooltipRect.height / 2}px`;

    setTooltipPosition({
      left: leftPositions.includes(position) ? `${leftPosition}px` : undefined,
      right: position === 'right' ? `${rightPosition}px` : undefined,
      top: tooltipVerticalPosition === 'bottom' ? bottomPosition : topPosition,
    });
  };

  return (
    <StyledTooltipContainer className={classnames} data-testid="tooltip">
      <StyledTooltip
        ref={tooltipRef}
        className={tooltipClassnames}
        style={{
          ...tooltipPosition,
          maxWidth: nowrap ? 'unset' : '27.5rem',
        }}
      >
        {content || text}
      </StyledTooltip>
      <StyledTooltipBase ref={baseRef} onMouseEnter={setTooltipOverhang}>
        {children}
      </StyledTooltipBase>
    </StyledTooltipContainer>
  );
};
