import { CircularProgress } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { DashboardSummary } from './components/dashboard-summary';
import { DashboardTopStats } from './components/dashboard-top-stats';
import { dashboardSummaryDateRangeTypeOptions } from '../common/dashboard-heading/components/dashboard-date-toggles/dashboard-date-toggles.constants';
import { getIsValidRangeType } from '../common/dashboard-heading/components/dashboard-date-toggles/helpers/get-is-valid-range-type';
import { StyledSpinnerWrapper } from './dashboard-summary-and-top-stats.styles';
import { useValidateEndDate } from '../../hooks/use-validate-end-date';
import { dashboardAndReportAtoms } from '../../dashboard.atoms';
import { DashboardSummaryDateRangeType } from '../../dashboard.types';
import { useFetchDashboardSummary } from '../../../../api/dashboard/routes/get-dashboard-summary/get-dashboard-summary.query';

export const DashboardSummaryAndTopStats = () => {
  const startDate = useRecoilValue(dashboardAndReportAtoms.startDate);
  const endDate = useRecoilValue(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const isValidEndDate = useValidateEndDate({ endDate, rangeType });

  const isValidRangeType = getIsValidRangeType({
    rangeType,
    validRangeTypes: dashboardSummaryDateRangeTypeOptions,
  });

  const enableRequest =
    isValidRangeType && isValidEndDate && !!startDate && !!endDate;

  const { dashboardInfo, isLoading, topInfo } = useFetchDashboardSummary({
    args: {
      endDate,
      rangeType: rangeType as DashboardSummaryDateRangeType,
      startDate,
    },
    enabled: enableRequest,
  });

  return isLoading ? (
    <StyledSpinnerWrapper minHeight="36rem">
      <CircularProgress size="10rem" />
    </StyledSpinnerWrapper>
  ) : (
    <>
      <DashboardSummary dashboardInfo={dashboardInfo} />
      <DashboardTopStats topInfo={topInfo} />
    </>
  );
};
