import {
  Button,
  CheckboxList,
  OnCheckboxListValueChange,
  Stack,
  Typography,
} from 'gantri-components';
import {
  StyledClickableIcon,
  StyledContent,
  StyledFooter,
  StyledHeading,
  StyledPreferencesPopUp,
} from './user-preferences-modal.styles';
import { CustomColumnsPage } from '../../../../types/store';
import {
  CheckboxLabel,
  UserPreferencesModalProps,
} from './user-preferences-modal.types';

export const UserPreferencesModal = <
  Props extends { userPreferencesPage: CustomColumnsPage },
>(
  props: UserPreferencesModalProps<Props['userPreferencesPage']>,
) => {
  const { checklistOptions, onClose, onReset, setChecklistOptions, title } =
    props;

  const onValueChange: OnCheckboxListValueChange<{
    label: CheckboxLabel<Props['userPreferencesPage']>;
    value: CheckboxLabel<Props['userPreferencesPage']>;
  }> = (selectedValues) => {
    const updatedChecklistOptions = checklistOptions.map((option) => {
      return {
        ...option,
        checked: selectedValues.some((value) => {
          return option.label === value;
        }),
      };
    });

    setChecklistOptions(updatedChecklistOptions);
  };

  const selectedValues = checklistOptions
    .filter(({ checked }) => {
      return checked;
    })
    .map(({ label }) => {
      return label;
    });

  return (
    <StyledPreferencesPopUp>
      <StyledHeading
        alignItems="center"
        columns="1fr min-content"
        justifyContent="space-between"
      >
        <Typography text={title} textStyle="bold" variant="h5" />
        <StyledClickableIcon
          name="ui-control:x"
          size="1.2rem"
          onClick={onClose}
        />
      </StyledHeading>
      <StyledContent>
        <Stack alignItems="center" flow="row" gap="0.6rem">
          <CheckboxList
            items={checklistOptions.map(({ label }) => {
              return { label, value: label };
            })}
            values={selectedValues}
            onValueChange={onValueChange}
          />
          <Typography
            color="t2"
            marginTop="1rem"
            text="Preferences saved to your user account."
            variant="p3"
          />
        </Stack>
      </StyledContent>
      <StyledFooter>
        <Button
          text="Reset"
          variant="secondary"
          width="100%"
          onClick={onReset}
        />
      </StyledFooter>
    </StyledPreferencesPopUp>
  );
};
