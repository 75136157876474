import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { Cell, GetRowProps } from 'gantri-components';
import { productJobsTabAtoms } from '../../../pages/product/components/product-jobs/product-jobs.atoms';
import { dataAttrIsEditing, StyledTable } from './jobs-template-table.styles';
import {
  JobsTemplateTableProps,
  JobTemplateWithId,
} from './jobs-template-table.types';
import { getVersionDetails, getOnEdit } from './helpers';
import { jobsTemplateTableAtoms } from './jobs-template-table.atoms';
import { useGetJobsTemplateTableColumns, useHandleReorder } from './hooks';
import { AddJobTemplateButton } from './components';

export const JobsTemplateTable = (props: JobsTemplateTableProps) => {
  const {
    className,
    data,
    disableEditing,
    onSaveOverride,
    partId,
    partName,
    productId,
    productName,
    refetchProduct,
    restrictJobStepTo,
    selectedSKU,
    version,
  } = props;

  const isEditingPage = useRecoilValue(productJobsTabAtoms.isEditingJobs);
  const [editingTemplateId, setEditingTemplateId] = useRecoilState(
    jobsTemplateTableAtoms.editingTemplateId,
  );

  const [isReorderActive, setIsReorderActive] = useState<boolean>(false);
  const [isReorderDisabled, setIsReorderDisabled] = useState<boolean>(false);
  const [cachedJobTemplates, setCachedJobTemplates] = useState<
    JobTemplateWithId[]
  >([]);
  const [jobTemplates, setJobTemplates] = useState<JobTemplateWithId[]>([]);

  const { isDraft, isMinorVersion } = getVersionDetails(version);

  const onEdit = getOnEdit({ setJobTemplates });

  const { handleReorder } = useHandleReorder({
    onSaveOverride,
  });

  const { DownloadGcodeStatusToast, DownloadInstructionStatusToast, columns } =
    useGetJobsTemplateTableColumns({
      cachedJobTemplates,
      disableEditing,
      onEdit,
      onSaveOverride,
      partId,
      partName,
      productId,
      productName,
      refetchProduct,
      restrictJobStepTo,
      selectedSKU,
      setJobTemplates,
      version,
    });

  const getRowProps: GetRowProps<JobTemplateWithId> = ({ original }) => {
    return {
      'data-job-template-id': original.id,
      [dataAttrIsEditing]: original.id === editingTemplateId ? '' : undefined,
    };
  };

  const isEditingAllowed = isDraft && !disableEditing;

  useEffect(() => {
    const isReorderDisabled =
      disableEditing ||
      jobTemplates.length <= 1 ||
      (!isReorderActive && isEditingPage) ||
      isMinorVersion;

    setIsReorderDisabled(isReorderDisabled);
  }, [
    jobTemplates.length,
    isReorderActive,
    isEditingPage,
    isMinorVersion,
    disableEditing,
  ]);

  useEffect(() => {
    setCachedJobTemplates(data);
    setJobTemplates(data);
  }, [data]);

  return (
    <>
      <Cell className={className}>
        <DownloadGcodeStatusToast position="top-end" />
        <DownloadInstructionStatusToast maxWidth="25rem" position="top-end" />
        <StyledTable
          columns={columns}
          data={jobTemplates}
          getRowProps={getRowProps}
          highlightHoveredRow={!isReorderActive}
          reordering={
            disableEditing
              ? undefined
              : {
                  disabled: isReorderDisabled,
                  getRowNumber: (row) => {
                    return row.original.sortOrder;
                  },
                  isActive: isReorderActive,
                  isAvailable: isEditingAllowed,
                  onReorder: handleReorder,
                  setIsActive: setIsReorderActive,
                }
          }
          stickyLastColumn={isEditingAllowed}
          verticalAlign="top"
        />
      </Cell>
      <AddJobTemplateButton
        disableEditing={disableEditing}
        isReorderActive={isReorderActive}
        jobStep={restrictJobStepTo}
        jobTemplates={jobTemplates}
        productId={productId}
        selectedSKU={selectedSKU}
        setEditingTemplateId={setEditingTemplateId}
        setJobTemplates={setJobTemplates}
        version={version}
      />
    </>
  );
};
