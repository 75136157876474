import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { CreatePrintPrepareQueueModalProps } from './create-print-prepare-queue-modal.types';
import { useCreatePrintPrepareQueue } from '../../../../../api/queue/print-prepare';
import { getCreatePrintPrepareQueueSchema } from './create-print-prepare-queue-modal.schema';
import { CreatePrintPrepareQueueModalContent } from './components/create-print-prepare-queue-modal-content';
import { createPrintPrepareQueueModalAtoms } from './create-print-prepare-queue-modal.atoms';
import { CreatePrintPrepareQueueModalFooter } from './components/create-print-prepare-queue-modal-footer';
import { useOnSubmit } from './hooks/use-on-submit';
import { useHandleCloseModal } from '../hooks/use-handle-close-modal';

export const CreatePrintPrepareQueueModal = (
  props: CreatePrintPrepareQueueModalProps,
) => {
  const { onClose, onConfirm } = props;

  const availableJobs = useRecoilValue(
    createPrintPrepareQueueModalAtoms.availableJobs,
  );

  const preparePrintsModalSchema = getCreatePrintPrepareQueueSchema({
    availableJobs,
  });

  const { isLoading: processingConfirm, onCreatePrintPrepareQueue } =
    useCreatePrintPrepareQueue();

  const handleCloseModal = useHandleCloseModal({ onClose });

  const onSubmit = useOnSubmit({
    onClose,
    onConfirm,
    onCreatePrintPrepareQueue,
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{ amount: undefined }}
      validateOnChange
      validateOnMount
      validationSchema={preparePrintsModalSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal
              contentStackProps={{
                alignContent: { lg: 'center', md: 'flex-start' },
                minHeight: '16.8rem',
              }}
              footer={
                <CreatePrintPrepareQueueModalFooter
                  handleCloseModal={handleCloseModal}
                  processingConfirm={processingConfirm}
                />
              }
              header="Prepare Prints"
              width={{ lg: '42.8rem', md: '100%' }}
              onClose={onClose}
            >
              <CreatePrintPrepareQueueModalContent />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
