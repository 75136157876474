import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ColumnConfig } from 'gantri-components';
import { UserPreferencesToggle } from '../../../../../../../components/user-preferences-toggle';
import { getChecklistFromReports } from './helpers';
import { availableReports } from './settings-button.constants';
import { CheckboxOption } from '../../../../../../../components/user-preferences-toggle/components/user-preferences-modal/user-preferences-modal.types';
import { dashboardAndReportAtoms } from '../../../../../dashboard.atoms';
import { useSpinner } from '../../../../../../../hooks';
import { tablesSyncApi } from '../../../../../../../api';
import { hashArray } from '../../../../../../../hooks/use-table-columns-sync/use-table-columns-sync.helpers';
import { DashboardReportToHide } from '../../../../../../../types/store';

export const SettingsButton = () => {
  const [reportsToHide, setReportsToHide] = useRecoilState(
    dashboardAndReportAtoms.columns,
  );

  const { onInterceptRequest } = useSpinner();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [checklistOptions, setChecklistOptions] =
    useState<CheckboxOption<'dashboard'>[]>();

  useEffect(() => {
    setChecklistOptions(getChecklistFromReports(reportsToHide));
  }, [reportsToHide]);

  const submitHiddenReports = async (columns?: ColumnConfig[]) => {
    await onInterceptRequest(async () => {
      await tablesSyncApi.saveTableColumns({
        columns,
        hash: await hashArray(columns),
        tableId: 'dashboard',
      });

      setReportsToHide(
        columns
          .filter((column) => {
            return column.hidden;
          })
          .map((column) => {
            return column.id as DashboardReportToHide;
          }),
      );
    });
  };

  const onClose = async () => {
    await submitHiddenReports(
      checklistOptions.map((checkListItem) => {
        return {
          hidden: !checkListItem.checked,
          id: checkListItem.label,
        };
      }),
    );
  };

  const onReset = async () => {
    await submitHiddenReports(
      availableReports.map((column) => {
        return { hidden: true, id: column };
      }),
    );
  };

  return (
    <UserPreferencesToggle
      checklistOptions={checklistOptions}
      isActive={isActive}
      modalHeading="Show charts"
      page="dashboard"
      setChecklistOptions={setChecklistOptions}
      setIsActive={setIsActive}
      onClose={onClose}
      onReset={onReset}
    />
  );
};
