import { Conditional, Flex, Typography } from 'gantri-components';
import { Timeline } from '../../../../api/jobs/jobs.types';
import { formatDate } from '../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../constants/dates';
import TimelineUserInfo from '../../../../components/common/timeline/user-info';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';

export const BatchTimelineItem = (props: Timeline) => {
  const { action, createdAtSec, description, extra, userId, ...rest } = props;

  return (
    <>
      <Typography text={formatDate(createdAtSec, LONG_FORMAT)} />
      <Flex>
        <Conditional
          condition={!!extra?.partId}
          Fallback={<Typography text={description} />}
        >
          <StyledAnchor
            text={description}
            to={`${routePaths.parts}/${extra?.partId}`}
          />
          &nbsp;
          <Typography display="inline" text={action} />
        </Conditional>
        <Conditional
          condition={!!userId}
          Fallback={
            <Conditional condition={/archived/i.test(action)}>
              &nbsp;
              <Typography decoration="inline" text="by the system" />
            </Conditional>
          }
        >
          &nbsp;
          <Typography decoration="inline" text="by" />
          &nbsp;
          <TimelineUserInfo userId={userId} {...rest} />
        </Conditional>
      </Flex>
    </>
  );
};
