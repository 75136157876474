import { useRef, useState } from 'react';
import { GetRowProps } from 'gantri-components';
import { useOnWindowResize } from '../../../../../../../hooks/use-on-window-resize';
import {
  dataAttrActiveChart,
  dataAttrTotalRow,
  StyledReportTableWrapper,
  StyledTable,
} from './report-table-column.styles';
import { ReportTableColumnProps } from './report-table-column.types';
import { DashboardTableData } from '../../../../../../../api/dashboard/dashboard.types';

export const ReportTableColumn = (props: ReportTableColumnProps) => {
  const { activeChart, minWidth = '60rem', tableColumns, tableData } = props;

  const [tableDimensions, setTableDimensions] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const tableWrapperRef = useRef<HTMLDivElement>(null);

  useOnWindowResize(() => {
    const height = tableWrapperRef.current?.offsetHeight;
    const width = tableWrapperRef.current?.offsetWidth;

    setTableDimensions({ height, width });
  });

  const getActiveChartProps = (columnId: string) => {
    const isActiveColumn = columnId === activeChart;

    return {
      [dataAttrActiveChart]: isActiveColumn ? '' : undefined,
    };
  };

  const getRowProps: GetRowProps<DashboardTableData[number]> = ({
    original,
  }) => {
    const isTotalRow = /^total$/i.test(original.type);

    return { [dataAttrTotalRow]: isTotalRow ? '' : undefined };
  };

  return (
    <StyledReportTableWrapper ref={tableWrapperRef}>
      <StyledTable
        $minWidth={minWidth}
        $tableDimensions={tableDimensions}
        columns={tableColumns}
        data={tableData}
        getCellProps={(cell) => {
          return getActiveChartProps(cell.column.id);
        }}
        getHeaderCellProps={(column) => {
          return getActiveChartProps(column.id);
        }}
        getRowProps={getRowProps}
      />
    </StyledReportTableWrapper>
  );
};
