import { Stack } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { ModalContentHeading } from '../../../../../../jobs/components/modals/common/modal-content-heading';
import { IssueChecklist } from './components/issue-checklist';
import { newMachineIssuesModalAtoms } from '../../../new-machine-issues-modal.atoms';
import { IssuesChecklistContentProps } from './issues-checklist-content.types';

export const IssuesChecklistContent = (props: IssuesChecklistContentProps) => {
  const { showBulkOptionsOnly } = props;

  const [selectedIssues, setSelectedIssues] = useRecoilState(
    newMachineIssuesModalAtoms.selectedIssues,
  );

  return (
    <Stack gap="x">
      <ModalContentHeading titleText="Select all issues that apply" />
      <IssueChecklist
        selectedIssues={selectedIssues}
        setSelectedIssues={setSelectedIssues}
        showBulkOptionsOnly={showBulkOptionsOnly}
      />
    </Stack>
  );
};
