import { memo } from 'react';
import { Typography, TextField, Grid, Cell } from 'gantri-components';
import { StyledFieldLabel } from '../../../../designer-styles';
import { SocialLinkProps } from './social-links.types';

const Section = (props: SocialLinkProps) => {
  const { links, updateDesignerInfo } = props;

  const handleLinkChange = (
    property: 'website' | 'instagram' | 'twitter' | 'behance',
  ) => {
    return (value: string) => {
      updateDesignerInfo({
        key: 'links',
        type: 'key',
        value: {
          ...links,
          [property]: value,
        },
      });
    };
  };

  return (
    <>
      <Typography
        marginBottom="1rem"
        marginTop="6rem"
        text="Social"
        textStyle="bold"
        variant="h4"
      />
      <Grid
        columns={{ lg: 2, sm: 1 }}
        gap="2rem"
        marginBottom="9rem"
        width="100%"
      >
        <Cell>
          <StyledFieldLabel text="Website" textStyle="bold" variant="p2" />
          <TextField
            placeholder="url name"
            value={links.website}
            onTextChange={handleLinkChange('website')}
          />
        </Cell>
        <Cell>
          <StyledFieldLabel text="Instagram" textStyle="bold" variant="p2" />
          <TextField
            placeholder="url name"
            value={links.instagram}
            onTextChange={handleLinkChange('instagram')}
          />
        </Cell>

        <Cell>
          <StyledFieldLabel text="Twitter" textStyle="bold" variant="p2" />
          <TextField
            placeholder="url name"
            value={links?.twitter}
            onTextChange={handleLinkChange('twitter')}
          />
        </Cell>
        <Cell>
          <StyledFieldLabel text="Behance" textStyle="bold" variant="p2" />
          <TextField
            placeholder="url name"
            value={links?.behance}
            onTextChange={handleLinkChange('behance')}
          />
        </Cell>
      </Grid>
    </>
  );
};

export const SocialLink = memo(Section);
