import { atom } from 'recoil';

const atomKeyPrefix = 'PRODUCT_JOBS_TAB';

export const productJobsTabAtoms = {
  draftVersionId: atom<number>({
    default: undefined,
    key: `${atomKeyPrefix}-draft-version-id`,
  }),
  invalidPartIds: atom<Record<number, boolean>>({
    default: {},
    key: `${atomKeyPrefix}-invalid-part-ids`,
  }),
  isEditingJobs: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-editing-jobs`,
  }),
  isEditingPartId: atom<number>({
    default: undefined,
    key: `${atomKeyPrefix}-is-editing-part-id`,
  }),
  isEditingStockJobsSection: atom<StockJobsSection>({
    default: undefined,
    key: `${atomKeyPrefix}-is-editing-stock-jobs`,
  }),
  versionId: atom<number>({
    default: undefined,
    key: `${atomKeyPrefix}-version-id`,
  }),
};

export type StockJobsSection =
  typeof stockJobsSections[keyof typeof stockJobsSections];

export const stockJobsSections = {
  assembleInventory: 'assemble-inventory',
  durations: 'durations',
  instructions: 'instructions',
  packInventory: 'pack-inventory',
} as const;
