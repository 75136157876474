import { Stack } from 'gantri-components';
import styled from 'styled-components';

export const StyledStack = styled(Stack)`
  &&& {
    table {
      [data-cell-need] {
        width: 4rem;
      }

      [data-cell-unit] {
        width: 7rem;
      }

      tr {
        box-shadow: none;
      }

      td {
        padding: 0.5rem;
        border-bottom: none;
      }
    }
  }
`;

export const StyledRemoveRowIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;
