import React from 'react';
import { Grid, Typography } from 'gantri-components';

export const SelectorHeader = () => {
  return (
    <Grid columnGap="1rem" columns="5rem 1fr" padding=".8rem">
      <Typography color="t3" text="ID" />
      <Typography color="t3" text="Inventory Name" />
    </Grid>
  );
};
