import React, { FC } from 'react';
import { Stack } from 'gantri-components';
import { ConfirmCompleteRepairProps } from './confirm-complete-repair-content.types';
import { RepairDetails } from '../new-repair-content/components/repair-details';
import { OpenIssue } from '../../../../../../../../api/machines/machines.types';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';

export const ConfirmCompleteRepairContent: FC<ConfirmCompleteRepairProps> = (
  props,
) => {
  const {
    notes,
    onClose,
    openIssues,
    repairType,
    selectedIssues,
    setNotes,
    setSelectedIssues,
    setUpdateOnClose,
  } = props;

  const onChangeIssuesSelected = (items: OpenIssue[]) => {
    setUpdateOnClose(true);
    setSelectedIssues(items);
  };

  return (
    <Stack gap="4x">
      <ModalContentHeading titleText="Complete Repair" />
      <RepairDetails
        fromCompleteStep
        isDisabledIssue={(issue) => {
          return issue.type === 'Maintenance';
        }}
        notes={notes}
        openIssues={openIssues}
        repairType={repairType}
        selectedIssues={selectedIssues}
        setNotes={setNotes}
        setSelectedIssues={onChangeIssuesSelected}
        onClose={onClose}
      />
    </Stack>
  );
};
