import isEmpty from 'lodash/isEmpty';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchPaginatedTimelinesArgs } from './fetch-paginated-machine-timelines.types';
import { fetchPaginatedMachineTimelines } from './fetch-paginated-machine-timelines';

export const useFetchPaginatedMachineTimelinesQuery = (
  props: FetchPaginatedTimelinesArgs,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!props && !!props?.machineId,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred fetching machine timeline.',
        });
      },
      queryFn: () => {
        return fetchPaginatedMachineTimelines.query(props);
      },
      queryKey: fetchPaginatedMachineTimelines.key(props),
    },
    showLoading: true,
  });

  return { isLoading, response: data };
};

export const useInvalidateFetchPaginatedMachineTimelineCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateCache = async (
    argsToInvalidate?: FetchPaginatedTimelinesArgs,
  ) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      const { machineId, ...paginateParams } = argsToInvalidate ?? {};

      return !isEmpty(paginateParams)
        ? key === fetchPaginatedMachineTimelines.key(argsToInvalidate)[0].key
        : groupKey === fetchPaginatedMachineTimelines.groupKey(machineId);
    });
  };

  return invalidateCache;
};
