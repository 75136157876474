import { Icon, Conditional, Stack, Box } from 'gantri-components';
import { Fragment } from 'react';
import { useToggle } from 'react-use';
import { Divider } from '../../divider';
import {
  StyledBackgroundStack,
  StyledListToggleFlex,
} from './expandable-items-list.styles';
import { ExpandableItemsListProps } from './expandable-items-list.types';

export const ExpandableItemsList = <Data extends Record<any, any>>(
  props: ExpandableItemsListProps<Data>,
) => {
  const {
    Item,
    Label,
    backgroundColor,
    defaultIsExpanded = false,
    idProperty,
    list,
    listStackProps,
    wrapperStackProps,
  } = props;

  const numItems = list.length;

  const [isExpanded, toggleIsExpanded] = useToggle(defaultIsExpanded);

  return (
    <Conditional condition={!!numItems}>
      <StyledBackgroundStack
        $backgroundColor={backgroundColor}
        gap="0"
        width="100%"
        {...wrapperStackProps}
      >
        <StyledListToggleFlex
          alignItems="center"
          gap="x"
          padding="x"
          onClick={() => {
            toggleIsExpanded();
          }}
        >
          <Conditional
            condition={isExpanded}
            Fallback={<Icon color="t2" name="arrows:arrow_chevron_down" />}
          >
            <Icon color="t2" name="arrows:arrow_chevron_up" />
          </Conditional>

          <Label isExpanded={isExpanded} numItems={numItems} />
        </StyledListToggleFlex>

        <Conditional condition={isExpanded}>
          <Stack gap="0" paddingBottom="x" width="100%" {...listStackProps}>
            {list.map((data, index) => {
              return (
                <Fragment key={data[idProperty]}>
                  <Conditional condition={!!index}>
                    <Divider paddingLeft="x" paddingRight="x" />
                  </Conditional>
                  <Box padding="x">
                    <Item {...data} index={index} />
                  </Box>
                </Fragment>
              );
            })}
          </Stack>
        </Conditional>
      </StyledBackgroundStack>
    </Conditional>
  );
};
