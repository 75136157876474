import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetPrintJobsArgs } from './get-print-jobs.types';

export const getMachinePrintJobs = (args: GetPrintJobsArgs) => {
  const { machineId } = args;

  return axios
    .get<any>(`${coreApiUrl}/machines/${machineId}/print-jobs`)
    .then(({ data }) => {
      return data;
    });
};
