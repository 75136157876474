import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  Conditional,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { DateWithUserDataCell } from '../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';

export const duplicatedJobsColumns: ColumnDef<Job>[] = [
  {
    accessorKey: 'step',
    header: 'Job Step',
  },
  {
    accessorKey: 'type',
    cell: ({ getValue }: CellContext<Job, Job['type']>) => {
      const type = getValue();

      return (
        <Grid alignItems="center" columns="max-content 1fr" gap="x">
          <Tooltip
            overlayContainerStyles={{ display: 'flex' }}
            position="top"
            title={type}
          >
            <Icon color="t2" name={jobTypeToIconTypeMap[type]} top="2px" />
          </Tooltip>
          <Typography text={type} />
        </Grid>
      );
    },
    header: 'Job Type',
  },
  {
    accessorKey: 'description',
    header: 'Job Name',
  },
  {
    accessorKey: 'createdAt',
    cell: ({
      getValue,
      row: { original },
    }: CellContext<Job, Job['createdAt']>) => {
      const createdAt = getValue();

      return (
        <Conditional condition={!!createdAt}>
          <DateWithUserDataCell
            data={{}}
            date={createdAt}
            jobId={original.id}
            type="Created"
          />
        </Conditional>
      );
    },
    header: 'Created',
  },
];
