import React, { FC } from 'react';
import { Button } from 'gantri-components';

export const DownloadFooterInfoStep: FC<{
  isValid: boolean;
  onCancel: () => void;
  onNext: () => void;
}> = (props) => {
  const { isValid, onCancel, onNext } = props;

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onCancel}
      />

      <Button disabled={!isValid} size="large" text="Next" onClick={onNext} />
    </>
  );
};
