import { atom } from 'recoil';
import { PrintPrepareQueueModalStep } from './print-prepare-queue-modal.types';
import { printPrepareQueueModalSteps } from './print-prepare-queue-modal.constants';
import { PrintPrepareQueue } from '../../../../../api/queue/print-prepare/print-prepare.types';

const atomKeyPrefix = 'PRINT_PREPARE_QUEUE_MODAL';

export const printPrepareQueueModalAtoms = {
  activeSetIndex: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-active-set-index`,
  }),
  deleteQueueOnClose: atom<boolean>({
    default: true,
    key: `${atomKeyPrefix}-delete-queue-on-close`,
  }),
  isPacketPrepared: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-packet-prepared`,
  }),
  queueData: atom<PrintPrepareQueue>({
    default: {
      id: null,
      sets: [],
      size: 0,
    },
    key: `${atomKeyPrefix}-queue-data`,
  }),
  step: atom<PrintPrepareQueueModalStep>({
    default: printPrepareQueueModalSteps.jobSetStart,
    key: `${atomKeyPrefix}-step`,
  }),
};
