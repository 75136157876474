import { addMonths, setDate } from 'date-fns';
import {
  PayoutsFilterStatus,
  PayoutsSortingField,
} from '../../api/transactions/routes/fetch-paginated-payouts/fetch-paginated-payouts.types';
import { DateRangeFilter } from '../../components/common/table/components/table-filters/table-filters.types';
import { getSortByFilterAtomFamily } from '../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { SortBy } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { formatDate } from '../../helpers/formatter';
import { API_DATE_FORMAT } from '../../constants/dates';

const toDate = setDate(new Date(), 1);

export const payoutsFiltersDefaults: {
  filters: {
    dateRange: DateRangeFilter;
    statuses: PayoutsFilterStatus[];
  };
  sortBy: SortBy<PayoutsSortingField>;
} = {
  filters: {
    dateRange: {
      from: formatDate(addMonths(toDate, -4), API_DATE_FORMAT),
      to: formatDate(toDate, API_DATE_FORMAT),
    },
    statuses: [],
  },
  sortBy: {
    sortingField: 'status',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'PAYOUTS_FILTERS';

export const pageName: AtomPageName = 'payouts';

export const payoutsPageAtoms = {
  defaults: payoutsFiltersDefaults,
  filters: {
    dateRange: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: payoutsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  sortBy: getSortByFilterAtomFamily<SortBy<PayoutsSortingField>>({
    defaultValue: payoutsFiltersDefaults.sortBy,
  })(pageName),
};
