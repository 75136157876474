import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Typography,
  TypographyColor,
} from 'gantri-components';
import { ModalContentHeadingProps } from './modal-content-heading.types';

export const ModalContentHeading = (props: ModalContentHeadingProps) => {
  const { color, subTitleHtmlText, subTitleText, titleHtmlText, titleText } =
    props;

  const colorsWithIcon: TypographyColor[] = ['alert', 'warning', 'success'];
  const showIcon = colorsWithIcon.includes(color);

  return (
    <Stack gap="x">
      <Flex alignItems="center" gap="x">
        <Conditional condition={showIcon}>
          <Icon
            color={color}
            name={
              color === 'success'
                ? 'ui-control:check_mark_circle_filled_24'
                : 'alert:warning_triangle_24'
            }
            size="2.4rem"
          />
        </Conditional>
        <Typography
          color={color}
          htmlText={titleHtmlText}
          text={titleText}
          variant="h3"
        />
      </Flex>
      <Conditional condition={!!(subTitleText || subTitleHtmlText)}>
        <Typography htmlText={subTitleHtmlText} text={subTitleText} />
      </Conditional>
    </Stack>
  );
};
