import { useMemo } from 'react';
import { Icon, Table, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { formatAsCurrency } from '../../helpers/formatter';
import routePaths from '../../config/route-paths';
import { PageWithTable } from '../../components/layout';
import { PayoutFilters } from '../../components/dropdowns';
import { FiltersFetchRequestArgs } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { useNotification } from '../../hooks/useNotification';
import {
  FetchPaginatedPayoutsArgs,
  PaginatedPayout,
  PayoutsSortingField,
} from '../../api/transactions/routes/fetch-paginated-payouts/fetch-paginated-payouts.types';
import { transactionsApi } from '../../api';
import { useTableFilters } from '../../components/common/table/hooks/use-table-filters';
import {
  pageName,
  payoutsFiltersDefaults,
  payoutsPageAtoms,
} from './payouts.atoms';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';

export const Payouts = () => {
  const columns = useMemo(() => {
    const columns: ColumnDef<PaginatedPayout>[] = [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor
              href={`${routePaths.payouts}/${id}`}
              text={`#${id}`}
            />
          );
        },
        header: 'Payout #',
        maxSize: 100,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'period',
        header: 'Period',
      },
      {
        accessorKey: 'user',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['user']>) => {
          const user = getValue();

          return (
            <StyledAnchor
              text={user.designerInfo.designer}
              to={`${routePaths.designers}/${user.id}`}
            />
          );
        },
        header: 'Designer',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'method',
        header: 'Payment Method',
      },
      {
        accessorKey: 'amount',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['amount']>) => {
          const amount = getValue();

          return (
            <Typography
              align="right"
              text={
                amount
                  ? formatAsCurrency(amount.royalty, { isCents: true })
                  : ''
              }
            />
          );
        },
        header: () => {
          return <Typography align="right" text="Amount" />;
        },
        maxSize: 60,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedPayout, PaginatedPayout['status']>) => {
          const status = getValue();

          return status === 'Paid' ? (
            <Typography
              icon={
                <Icon
                  color="success"
                  name="ui-control:check_mark"
                  size="2rem"
                />
              }
              text={status}
            />
          ) : (
            status
          );
        },
        header: 'Status',
        maxSize: 60,
      },
    ];

    return columns;
  }, []);

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const fetchPaginatedPayouts = async ({
    count,
    ...params
  }: FiltersFetchRequestArgs<
    Omit<FetchPaginatedPayoutsArgs, 'itemsPerPage'>,
    PayoutsSortingField
  >) => {
    try {
      showLoading();

      const { data } = await transactionsApi.fetchPaginatedPayouts({
        itemsPerPage: count,
        ...params,
      });

      setRecords(data.payouts);
      setTotalCount(data.allPayouts);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fetch payouts.' });
    } finally {
      hideLoading();
    }
  };

  const {
    filtersProps,
    pagingProps,
    records,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedPayout,
    FetchPaginatedPayoutsArgs,
    PayoutsSortingField
  >({
    fetchRequest: fetchPaginatedPayouts,
    filtersContent: <PayoutFilters />,
    modalFiltersDetails: [
      {
        atom: payoutsPageAtoms.filters.dateRange,
        defaultValue: payoutsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: payoutsPageAtoms.filters.statuses,
        defaultValue: payoutsFiltersDefaults.filters.statuses,
        payloadKey: 'statuses',
      },
    ],
    pageName,
    pageSize: 80,
    sortByFilterDetails: {
      atom: payoutsPageAtoms.sortBy,
      defaultValue: payoutsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { bidirectional: true, label: 'Status', sortingField: 'status' },
    ],
  });

  return (
    <PageWithTable pageTitle="Payouts">
      <PageWithTable.Header title="Payouts" />
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          sorting={sortProps}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
