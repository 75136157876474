import {
  Badge,
  Box,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { useSetState } from 'react-use';
import { StyledFieldIconWrapper } from '../../../custom-data-cells-styles';
import { formatDate } from '../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { StatusInfo } from '../../../../../../api/jobs/jobs.types';
import { jobsApi } from '../../../../../../api';
import { RejectedStatusProps } from './rejected-status.types';

export const RejectedStatus = (props: RejectedStatusProps) => {
  const { hideTooltip, id, status } = props;

  const [statusInfo, setStatusInfo] = useSetState<StatusInfo>({});

  const theme = useTheme();

  return (
    <Flex alignItems="flex-start">
      <Badge
        borderColor={theme.colors.surfaces.alert.t2}
        color={theme.colors.surfaces.alert.t1}
        icon="work:no_entry"
        text={status}
        textColor={theme.colors.typography.t1}
      />
      <Conditional condition={!hideTooltip}>
        <Tooltip
          Component={
            <Conditional condition={!!statusInfo.rejected}>
              <Box horizontalPadding="2x" verticalPadding="x">
                <Typography
                  color="alert"
                  display="inline"
                  text={statusInfo?.rejected?.reason}
                />
                <Typography
                  display="inline"
                  text={` ${formatDate(
                    statusInfo?.rejected?.date,
                    LONG_FORMAT,
                  )}`}
                />
                <Conditional condition={!!statusInfo?.rejected?.user}>
                  <Typography display="inline" text=" by " />
                  <Link to={`/users/${statusInfo?.rejected?.user?.id}`}>
                    <Typography
                      color="link"
                      decoration="underline"
                      display="inline"
                      text={[
                        statusInfo?.rejected?.user?.firstName,
                        statusInfo?.rejected?.user?.lastName,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    />
                  </Link>
                </Conditional>
              </Box>
            </Conditional>
          }
          position="bottom"
        >
          <StyledFieldIconWrapper>
            <Icon
              name="alert:i_circle"
              top="3px"
              onMouseEnter={async () => {
                if (!statusInfo.rejected) {
                  const { data } = await jobsApi.getTooltipInfo(id);

                  setStatusInfo(data.statusInfo);
                }
              }}
            />
          </StyledFieldIconWrapper>
        </Tooltip>
      </Conditional>
    </Flex>
  );
};
