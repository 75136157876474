import { atom } from 'recoil';
import { SetMachinesOfflineModalStep } from './set-machines-offline-modal.types';
import { setMachinesOfflineModalSteps } from './set-machines-offline-modal.constants';
import { BulkUpdateMachineRecord } from '../../../../api/machines/routes/bulk-update/bulk-update.types';

const atomKeyPrefix = 'SET_MACHINES_OFFLINE_MODAL';

export const setMachinesOfflineModalAtoms = {
  detailsPanelMachines: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-details-panel-machines`,
  }),
  hasMachinesWithInvalidStatuses: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-has-ready-or-in-progress-machines`,
  }),
  machinesNotOfflined: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-not-offlined`,
  }),
  machinesOfflined: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-offlined`,
  }),
  step: atom<SetMachinesOfflineModalStep>({
    default: setMachinesOfflineModalSteps.confirmSetMachinesOffline,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
