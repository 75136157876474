import styled, { css } from 'styled-components';

export const StyledThumbnail = styled.div<{
  $borderColor: string;
  $hasAction: boolean;
  $size: number;
}>`
  ${({ $borderColor, $hasAction, $size, theme }) => {
    return css`
      display: inline-block;
      border: 1px solid ${$borderColor || theme.colors.dividers.t1};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
      border-radius: 2px;
      width: ${$size + 2}px;
      height: ${$size + 2}px;
      cursor: ${$hasAction ? 'pointer' : 'default'};
      user-select: none;

      img {
        position: relative;
      }
    `;
  }}
`;
