import styled from 'styled-components';
import { Color, getThemeColor, Typography } from 'gantri-components';

export const StyledTableIconContainer = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const StyledStatusTypography = styled(Typography)<{
  background: Color;
}>`
  min-width: max-content;
  padding: 4px 1rem;
  box-sizing: border-box;
  border-radius: 4rem;
  text-align: center;
  background-color: ${(props) => {
    return getThemeColor(props.background || 'warning');
  }};

  display: inline-flex;
  justify-content: center;
  text-transform: capitalize;
  postion: relative;
  padding-left: 1rem;

  svg {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0.6rem;
    left: 1rem;
  }

  span {
    margin-left: 2rem;
  }
`;

export const StyledTooltipTypography = styled(Typography)`
  display: inline;
`;

export const StyledFieldIconWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
`;

export const StyledTextAndIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledPartURLInformation = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;

  & > p {
    margin-right: 5px;
  }
`;

export const StyledRFIDIconWrapper = styled.div`
  width: 2.6rem;
  position: relative;
  left: -2px;
`;

export const StyledPrintLink = styled.a`
  position: relative;
  margin-left: 10px;

  &:before {
    content: '';
    width: 1px;
    height: 14px;
    position: absolute;
    background-color: ${(props) => {
      return props.theme.colors.palette.monochrome_600;
    }};
    left: -5px;
    top: 4px;
  }
`;
