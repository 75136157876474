import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetProductReviewDetailResponse } from './get-product-review-detail.types';

const query = async (
  reviewId: number,
): Promise<GetProductReviewDetailResponse> => {
  const response = await axios.get<GetProductReviewDetailResponse>(
    `${coreApiUrl}/product-reviews/${reviewId}`,
  );

  return response.data;
};

const groupKey = () => {
  return 'user-product-review-details';
};

const key = (reviewId: number) => {
  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}/${reviewId}`,
    },
  ] as const;
};

export const getProductReviewDetail = {
  groupKey,
  key,
  query,
};
