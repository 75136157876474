import { ModalContentHeading } from '../../../../common/modal-content-heading';

export const QueueDeletedContent = () => {
  return (
    <ModalContentHeading
      color="warning"
      subTitleText="Please follow up with your supervisor if you have any questions or concerns."
      titleText="Queue has been deleted"
    />
  );
};
