import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchGiftCodeResponse } from './fetch-giftcode.types';

export const fetchGiftCode = (id: number) => {
  return axios
    .get<FetchGiftCodeResponse>(`${coreApiUrl}/gift/${id}`)
    .then((response) => {
      return response.data;
    });
};
