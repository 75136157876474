import { FC, useState } from 'react';
import { Conditional, Table, Typography } from 'gantri-components';
import { MachineIssuesTableProps } from './machine-issues-table-type';
import { useGetMachineIssuesQuery } from '../../../../api/machines/routes/get-issues/get-issues.query';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';
import { useGetMachineIssuesColumns } from './hooks/use-get-machine-issues-columns';
import { useGetGetMachineIssuesCellProps } from './hooks/use-get-machine-issues-cell-props';

export const MachineIssuesTable: FC<MachineIssuesTableProps> = (props) => {
  const { machineId, onIssueDelete } = props;

  const [page, setPage] = useState<number>(1);

  const { response } = useGetMachineIssuesQuery({ machineId, page });
  const { issues = [], totalIssues = 0, maxPages = 0 } = response ?? {};

  const getCellProps = useGetGetMachineIssuesCellProps();
  const columns = useGetMachineIssuesColumns({ machineId, onIssueDelete });

  return (
    <Conditional
      condition={issues.length > 0}
      Fallback={<Typography text="There are no issues for this machine." />}
    >
      <JobsDetailsPanelWrapper
        idsMap={({ repair }) => {
          return repair?.id;
        }}
        records={issues}
      >
        <Table
          columns={columns}
          data={issues}
          getCellProps={getCellProps}
          highlightHoveredRow
          paging={{
            currentPage: page,
            onPageChange: setPage,
            pageCount: maxPages,
            total: totalIssues,
          }}
          stickyLastColumn
        />
      </JobsDetailsPanelWrapper>
    </Conditional>
  );
};
