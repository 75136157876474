import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { GetDesignerStatsResponse } from './get-designer-stats.types';

export const query = (id: number) => {
  return axios
    .get<GetDesignerStatsResponse>(`${baseApiUrl}/designers/${id}/stats`)
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'designer-stats';
};

const key = (id: number) => {
  const queries = [`id=${id}`];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getDesignerStats = {
  groupKey,
  key,
  query,
};
