import React from 'react';
import { Dropdown, FormikInput, Grid, TextArea } from 'gantri-components';
import { downloadTypeOptions } from '../download-modal.constants';

export const DownloadBodyInfoStep = (props) => {
  return (
    <Grid gap="1rem">
      <FormikInput
        labelText="Download Name"
        name="name"
        placeholder="Enter name"
        required
      />
      <FormikInput
        Field={
          <Dropdown
            items={downloadTypeOptions}
            placeholder="Select a type"
            required
          />
        }
        labelText="Type"
        name="type"
        required
      />
      <FormikInput
        Field={<TextArea minRows={3} placeholder="Enter description" />}
        labelText="Description"
        name="description"
        required
      />
    </Grid>
  );
};
