import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { SetMachinesOnlineModalProps } from './set-machines-online-modal.types';
import { setMachinesOnlineModalAtoms } from './set-machines-online-modal.atoms';
import { SetMachinesOnlineModalFooter } from './components/set-machines-online-modal-footer';
import { SetMachinesOnlineModalContent } from './components/set-machines-online-modal-content';
import { MachineStatus, machineStatuses } from '../../../../constants/machines';
import { useResetRecoilAtomsOnMount } from '../../../../hooks/use-reset-recoil-atoms-on-mount';
import { BulkUpdateMachinesModalDetailsPanel } from '../bulk-update-machines-modal-details-panel';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';

export const SetMachinesOnlineModal = (props: SetMachinesOnlineModalProps) => {
  const { machines, onClose, onSuccess } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    setMachinesOnlineModalAtoms,
  );
  const updateOnClose = useRecoilValue(
    setMachinesOnlineModalAtoms.updateOnClose,
  );
  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    setMachinesOnlineModalAtoms.detailsPanelMachines,
  );
  const setHasMachinesWithInvalidStatuses = useSetRecoilState(
    setMachinesOnlineModalAtoms.hasMachinesWithInvalidStatuses,
  );

  const handleCloseModal = async () => {
    if (updateOnClose) {
      await onSuccess();
    }

    onClose();
  };

  useEffect(() => {
    setDetailsPanelMachines(machines);

    const hasMachinesWithInvalidStatuses = machines.some(({ status }) => {
      const alertStatuses: MachineStatus[] = [
        machineStatuses.ready,
        machineStatuses.inProgress,
      ];

      return alertStatuses.some((alertStatus) => {
        return alertStatus === status;
      });
    });

    setHasMachinesWithInvalidStatuses(hasMachinesWithInvalidStatuses);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        detailsPanel={
          <BulkUpdateMachinesModalDetailsPanel
            machines={detailsPanelMachines}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <SimpleErrorBoundary>
            <SetMachinesOnlineModalFooter handleCloseModal={handleCloseModal} />
          </SimpleErrorBoundary>
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            text="Set Machines Online"
          />
        }
        isFullScreen
      >
        <SimpleErrorBoundary>
          <SetMachinesOnlineModalContent />
        </SimpleErrorBoundary>
      </Modal>
    </ResetAtomsWrapper>
  );
};
