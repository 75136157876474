import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { HandleCloseModal } from '../../../../hooks/use-handle-close-modal/use-handle-close-modal.types';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { useNavigateToNextAvailableSet } from '../../../hooks/use-navigate-to-next-available-set';
import { printPrepareQueueModalAtoms } from '../../../print-prepare-queue-modal.atoms';

export interface JobSetCompletedFooterProps {
  handleCloseModal: HandleCloseModal;
}

export const JobSetCompletedFooter = (props: JobSetCompletedFooterProps) => {
  const { handleCloseModal } = props;

  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);

  const { navigateToNextAvailableSet } = useNavigateToNextAvailableSet({
    handleCloseModal,
  });

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="View next available set"
      onClick={async () => {
        await navigateToNextAvailableSet(queueData);
      }}
    />
  );
};
