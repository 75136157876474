import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { DeliverDoNotShipShipmentArgs } from './deliver-do-not-ship-shipment.types';

export const deliverDoNotShipShipment = (
  args: DeliverDoNotShipShipmentArgs,
) => {
  const { shipmentId } = args;

  return axios.put<TransactionResponse>(
    `${baseApiUrl}/shipment/${shipmentId}/delivered`,
  );
};
