import React, { FC } from 'react';
import {
  Conditional,
  FormikInput,
  Grid,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { SectionProps } from './section.types';
import { TableHeaderWithInfoToolTip } from '../../../../../components/common/custom-data-cells/headers';
import { FormItem } from '../../../components/form-item';
import { InventoryLocations } from './components/inventory-location';

export const OverviewGeneralSection: FC<SectionProps> = ({
  inventory,
  isEditModeActive,
}) => {
  return (
    <Grid
      columns="1fr"
      gap="1.2rem"
      maxWidth={isEditModeActive ? '60rem' : 'unset'}
    >
      <FormItem
        isEditMode={isEditModeActive}
        label="Inventory Name"
        orientation="row"
        viewElement={inventory.name}
      >
        <FormikInput name="name" required />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Alert level (units)"
        orientation="row"
        viewElement={inventory.alertLevel}
      >
        <FormikInput
          name="alertLevel"
          placeholder="Enter alert level"
          type="number"
        />
      </FormItem>

      <FormItem
        label={
          <TableHeaderWithInfoToolTip
            headerText="% Discarded"
            position="bottom"
            tooltipText="Percent of last 100 inventory transactions with transactions type: Discarded."
          />
        }
        orientation="row"
        viewElement={`${inventory?.discarded ?? 0}%`}
      />

      <FormItem
        label={
          <TableHeaderWithInfoToolTip
            headerText="Usage"
            position="bottom"
            tooltipText="Number of parts  used per week, on a rolling 2 month average."
          />
        }
        orientation="row"
        viewElement={String(inventory?.usage)}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Location"
        orientation="row"
        viewElement={
          <Stack>
            {inventory.locations.map(({ id, name }) => {
              return <Typography key={id} text={name} />;
            })}
          </Stack>
        }
      >
        <FormikInput
          Field={
            <InventoryLocations
              assignedLocations={inventory.locations}
              isEditModeActive={isEditModeActive}
            />
          }
          fieldVariant="standard"
          name="locations"
          placeholder="Enter a note"
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Description"
        orientation="row"
        viewElement={inventory?.description}
      >
        <FormikInput
          name="description"
          placeholder="Enter a description"
          required
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Notes"
        orientation="row"
        viewElement={inventory?.notes}
      >
        <FormikInput
          Field={<TextArea minRows={3} />}
          name="notes"
          placeholder="Enter a note"
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Website"
        orientation="row"
        viewElement={
          <Conditional condition={!!inventory.website}>
            <a
              href={
                /^https?:\/\//i.test(inventory.website)
                  ? inventory.website
                  : `https://${inventory.website}`
              }
              rel="noreferrer"
              target="_blank"
            >
              <Typography
                color="link"
                style={{ lineBreak: 'anywhere' }}
                text={inventory.website}
              />
            </a>
          </Conditional>
        }
      >
        <FormikInput name="website" placeholder="Enter a website" />
      </FormItem>
    </Grid>
  );
};
