import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledGrid = styled(Grid)`
  padding: 1rem 2.3rem 1rem 0;
  ${({ theme }) => {
    return css`
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

export const StyledImgWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
    `;
  }}
`;
