import { Property } from 'csstype';
import styled, { css } from 'styled-components';
import { shadows, zIndexes } from '../../../constants/styles';

export const StyledDropdownWrapper = styled.div<{
  customDrop?: Property.Top;
  customWidth?: Property.Width;
}>`
  position: absolute;
  top: ${({ customDrop }) => {
    return customDrop || '48px';
  }};
  left: 0;
  z-index: 11;
  ${({ customWidth }) => {
    return (
      customWidth &&
      css`
        width: ${customWidth};
      `
    );
  }}
  user-select: none;
`;

export const StyledDropdownContainer = styled.div<{
  moreMenu?: boolean;
}>`
  ${({ moreMenu, theme }) => {
    return css`
      z-index: ${zIndexes.dropdown};
      background-color: ${theme.colors.surfaces.monochrome.overlayLevel1};
      border: 1px solid ${theme.colors.dividers.t1};
      min-width: 150px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      border-radius: 3px;
      ${shadows.overhang}
      ${moreMenu &&
      css`
        padding: 0;
      `};

      [class^='styles_gantri-daterange'] {
        background-color: ${theme.name === 'light'
          ? theme.colors.surfaces.monochrome.t2
          : theme.colors.surfaces.monochrome.t1};
      }
    `;
  }}
`;
