import { useRecoilValue } from 'recoil';
import { rejectJobModalAtoms } from '../../reject-job-modal.atoms';
import { RejectReasonContent } from '../step-reject-reason/reject-reason-content';
import { NextStepContent } from '../step-next-step/next-step-content';
import { RejectJobModalContentProps } from './reject-job-modal-content.types';

export const RejectJobModalContent = (props: RejectJobModalContentProps) => {
  const { job } = props;

  const step = useRecoilValue(rejectJobModalAtoms.step);

  switch (step) {
    case 'REJECT_REASON':
      return <RejectReasonContent />;

    case 'NEXT_STEP':
      return <NextStepContent job={job} />;

    default:
      return null;
  }
};
