import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateDesignCategoriesArgs,
  UpdateDesignCategoriesResponse,
} from './update-design-categories.types';
import { updateDesignCategories } from './update-design-categories';

export const useUpdateDesignCategories = <
  TransformedData = UpdateDesignCategoriesResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateDesignCategoriesArgs,
    UpdateDesignCategoriesResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateDesignCategories, ...rest } = useGenericMutateQuery(
    {
      fallbackErrorMessage: 'Unable to update design categories.',
      mutationFn: updateDesignCategories,
      ...props,
    },
  );

  return { ...rest, onUpdateDesignCategories };
};
