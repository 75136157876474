import { Conditional, Typography } from 'gantri-components';
import { JobStatus } from '../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { ButtonToggleGroup } from '../../../../../../../../components/common/button-toggle-group';
import { statusToggleDefaultProps } from './status-toggle.presets';
import { StatusToggleProps } from './status-toggle.types';

export const StatusToggle = (props: StatusToggleProps) => {
  const { buttonToggleWidth, onItemChange, option, reason, statuses } = props;

  const getActiveBackgroundColor = (status: JobStatus) => {
    return status === 'Pass' ? 'green_400' : 'red_400';
  };

  return (
    <>
      <Conditional condition={!!option.statusToggleDetails}>
        <Typography text={option.statusToggleDetails} />
      </Conditional>
      <ButtonToggleGroup
        data-cy-checklist-toggle=""
        defaultItem={option.status}
        getActiveBackgroundColor={getActiveBackgroundColor}
        items={option.statuses || statuses}
        itemsWidth={buttonToggleWidth}
        transformLabel={(value) => {
          return `${value} ${option.label}`;
        }}
        onChange={(value: JobStatus) => {
          onItemChange(reason, {
            ...option,
            details: value === 'Pass' ? '' : option.details,
            status: value,
          });
        }}
      />
    </>
  );
};

StatusToggle.defaultProps = statusToggleDefaultProps;
