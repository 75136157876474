import { Button, Modal, Stack, Typography } from 'gantri-components';
import {
  useInvalidateFetchDesignCache,
  useUpdateDesign,
} from '../../../../../../api/designs/routes';
import { useHandleCloseModal } from '../../../../../jobs/components/modals/hooks/use-handle-close-modal';
import { CompleteDesignModalProps } from './complete-design-modal.types';

export const CompleteDesignModal = (props: CompleteDesignModalProps) => {
  const { content, designId, designName, onClose } = props;

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: invalidateFetchDesignCache,
  });

  const { isLoading: isCompletingDesign, onUpdateDesign } = useUpdateDesign({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
    showLoading: true,
  });

  const updateDesign = async () => {
    await onUpdateDesign({
      content,
      id: designId,
      step: 'Content',
    });
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={async () => {
              await handleCloseModal();
            }}
          />
          <Button
            processing={isCompletingDesign}
            size="large"
            text="Confirm"
            onClick={updateDesign}
          />
        </>
      }
      header="Complete Design"
      maxWidth={{ lg: '46rem', md: '100%' }}
      onClose={async () => {
        await handleCloseModal();
      }}
    >
      <Stack alignContent="center" justifyContent="center" minHeight="12.2rem">
        <Typography
          align="center"
          maxWidth="33.7rem"
          text={`Are you sure you want to complete '${designName}' design?`}
        />
      </Stack>
    </Modal>
  );
};
