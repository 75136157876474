import { useMemo } from 'react';
import { ProductListRecord } from '../../../../../../../../../api/products/routes/get-all/get-all.types';
import { ProductOptionItem } from '../../product-search.types';

export const useGetProductsListItems = (products: ProductListRecord[]) => {
  const productOptions = useMemo(() => {
    const productOptions = (products || []).map<ProductOptionItem>(
      ({ category, fullProductName, id, name, status }) => {
        const productName =
          fullProductName || [name, category].filter(Boolean).join(' ');

        return {
          label: productName,
          status,
          value: id,
        };
      },
    );

    return productOptions;
  }, [products]);

  return productOptions;
};
