import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchPaginatedMachinesArgs,
  FetchPaginatedMachinesResponse,
} from './fetch-paginated-machines.types';
import { fetchPaginatedMachinesQueryObj } from './fetch-paginated-machines';

export const useFetchPaginatedMachines = <
  TransformedData = FetchPaginatedMachinesResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedMachinesArgs,
    FetchPaginatedMachinesResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated machines.',
    queryObj: fetchPaginatedMachinesQueryObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedMachinesCache = () => {
  const invalidateFetchPaginatedMachinesCache = useGetInvalidateQueryCache(
    fetchPaginatedMachinesQueryObj,
  );

  return { invalidateFetchPaginatedMachinesCache };
};
