import { useRecoilValue } from 'recoil';
import { completeQaQcModalAtoms } from '../../complete-final-qa-modal.atoms';
import { QaQcJobContentProps } from './qa-qc-job-content.types';
import { ConfirmStartContent } from '../step-confirm-start/confirm-start-content';
import { CompleteChecklistContent } from '../step-complete-checklist/complete-checklist-content';
import { JobFailedContent } from '../step-job-failed/job-failed-content';

export const QaQcJobContent = (props: QaQcJobContentProps) => {
  const { job, updateChecklist } = props;

  const step = useRecoilValue(completeQaQcModalAtoms.step);

  switch (step) {
    case 'CONFIRM_START':
      return <ConfirmStartContent job={job} />;

    case 'COMPLETE_CHECKLIST':
      return <CompleteChecklistContent updateChecklist={updateChecklist} />;

    case 'JOB_FAILED':
      return <JobFailedContent />;

    default:
      return null;
  }
};
