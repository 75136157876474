import { useFormikContext } from 'formik';
import {
  Conditional,
  Flex,
  FormikInput,
  Icon,
  Stack,
  Typography,
  FileUploader,
  FileUploaderCustomContent,
  HandleDeleteFile,
  FileUploaderProps,
  HandleUploadsComplete,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { userAtoms } from '../../../../global-atoms/users';
import { DownloadFormState } from '../download-modal.types';

export const DownloadBodyFilesStep = () => {
  const { id: userId } = useRecoilValue(userAtoms.user);

  const { setFieldValue, setValues, values } =
    useFormikContext<DownloadFormState>();

  const onFileDelete: HandleDeleteFile = async () => {
    await setFieldValue('link', undefined);
    await setFieldValue('fileName', undefined);
  };

  const onFileUpload: HandleUploadsComplete = async ([
    { fileName, fileUrl },
  ]) => {
    await setValues({ ...values, fileName, link: fileUrl });
  };

  const onThumbnailDelete: HandleDeleteFile = async ({ isReplacing }) => {
    if (!isReplacing) {
      await setFieldValue('thumbnail', undefined);
    }
  };

  const onThumbnailUpload: HandleUploadsComplete = async ([{ fileUrl }]) => {
    await setFieldValue('thumbnail', fileUrl);
  };

  const thumbnailFileUrl = values.thumbnail;
  const thumbnailFileName = thumbnailFileUrl?.replace(/.+\//g, '');

  const downloadFileUrl = values.link;
  const downloadFileName = values.fileName;

  const minSize = 200;

  const { fileUploaderProps: thumbnailFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: thumbnailFileUrl,
      handleUploadsComplete: onThumbnailUpload,
      onFileDelete: onThumbnailDelete,
      path: `users/${userId}/downloads/images`,
      showLoading: false,
    });

  const { fileUploaderProps: downloadFileUploaderProps } =
    useFirebaseFileUploader({
      fileUrl: downloadFileUrl,
      handleUploadsComplete: onFileUpload,
      onFileDelete,
      path: `users/${userId}/downloads/files`,
      showLoading: false,
    });

  const CustomContentThumbnail: FileUploaderCustomContent = ({
    handleOpenFileSelect,
    processing,
  }) => {
    return (
      <StyledThumbnailDetailsStack
        alignContent="center"
        gap=".5x"
        justifyItems="center"
        onClick={handleOpenFileSelect}
      >
        <Conditional
          condition={processing}
          Fallback={
            <>
              <Icon color="link" name="arrows:arrow_upload" />
              <Typography align="center" color="link" text="Upload thumbnail" />
              <Typography
                align="center"
                text={`Min. size ${minSize} x ${minSize}`}
              />
            </>
          }
        >
          <Icon color="link" name="animated:loader" size="3.2rem" />
        </Conditional>
      </StyledThumbnailDetailsStack>
    );
  };

  const customUploaderProps = (
    thumbnailFileUrl
      ? { variant: 'thumbnail' }
      : {
          CustomContent: CustomContentThumbnail,
          variant: 'custom',
        }
  ) satisfies Partial<FileUploaderProps>;

  return (
    <Flex
      alignItems="center"
      direction="column"
      gap="1rem"
      justifyContent="center"
    >
      <FormikInput
        Field={
          <FileUploader
            {...customUploaderProps}
            {...thumbnailFileUploaderProps}
            fileName={thumbnailFileName}
            inputName="thumbnail"
            minImageHeight={minSize}
            minImageWidth={minSize}
            thumbnailSize="20rem"
          />
        }
        fieldVariant="standard"
        name="thumbnail"
        required
        value={thumbnailFileUrl}
      />

      <FormikInput
        Field={
          <FileUploader
            {...downloadFileUploaderProps}
            disabledDescription="Please upload a thumbnail first."
            expectedExtensions={['step']}
            fileName={downloadFileName}
            inputName="link"
            variant="plain"
          />
        }
        fieldVariant="standard"
        name="link"
        required
        value={downloadFileUrl}
      />
    </Flex>
  );
};

export const StyledThumbnailDetailsStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      height: 20rem;
      width: 20rem;
      border: 1px dashed ${theme.colors.iconography.link};
      cursor: pointer;
    `;
  }}
`;
