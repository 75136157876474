import { FC } from 'react';
import { Conditional, Grid, Typography } from 'gantri-components';
import { DetailsPanel } from '../../../../../components/common/details-panel';
import { useReviewDetailsPanel } from '../../reviews-context';
import { ReviewDetails } from './review';
import { NPSReviewDetails } from './nps';
import {
  UserNPSReview,
  UserProductReview,
} from '../../../../../api/users/routes';

export const ReviewDetailsPanel: FC = () => {
  const {
    onHideReviewDetails,
    onStatusSelected,
    onUpdateReviewStatus,
    open,
    reviewSelected,
    source,
  } = useReviewDetailsPanel();

  if (!open) return null;

  return (
    <DetailsPanel
      header={
        <Grid columns="1fr repeat(2, max-content)" gap="1rem">
          <Typography
            style={{ fontSize: '2.2rem', lineHeight: '2.8rem' }}
            text={
              source === 'REVIEW'
                ? 'Product Review Details'
                : 'NPS Review Details'
            }
            textStyle="bold"
            variant="h4"
          />
        </Grid>
      }
      open
      onClose={onHideReviewDetails}
    >
      <Conditional
        condition={source === 'REVIEW'}
        Fallback={<NPSReviewDetails review={reviewSelected as UserNPSReview} />}
      >
        <ReviewDetails
          review={reviewSelected as UserProductReview}
          onStatusSelected={onStatusSelected}
          onUpdateReviewStatus={onUpdateReviewStatus}
        />
      </Conditional>
    </DetailsPanel>
  );
};
