import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultJobsGroupBy } from '../../report-jobs.constants';
import { ReportJobsQueries } from '../../report-jobs.types';

export const getTitle = (rootTitle: string): string => {
  const queries = getActiveQueries<ReportJobsQueries>();

  const {
    groupBy = defaultJobsGroupBy,
    partName,
    jobType,
    machineType,
    machineName,
    productName,
    workerName,
    version,
  } = queries;

  if (/^productName$/i.test(groupBy)) {
    if (productName) {
      if (jobType) {
        return jobType;
      }

      if (partName) {
        return partName;
      }

      if (version) {
        return `${version}`;
      }

      return productName;
    }
  }

  if (/^jobType$/i.test(groupBy) && jobType) {
    return jobType;
  }

  if (/^workerName$/i.test(groupBy)) {
    if (workerName) {
      if (jobType) {
        return jobType;
      }

      return workerName;
    }
  }

  if (/^machineType$/i.test(groupBy)) {
    if (machineType) {
      if (machineName) {
        if (jobType) {
          return jobType;
        }

        return machineName;
      }

      return machineType;
    }
  }

  return rootTitle;
};
