import { Button } from 'gantri-components';
import { useCompleteJob } from '../../../../../../../../../../api/jobs/routes';
import { jobTypeOptions } from '../../../../../../../../../../constants/options';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { PutInVlmFooterProps } from './put-in-vlm-footer.types';

export const PutInVlmFooter = (props: PutInVlmFooterProps) => {
  const { handleCloseModal, jobId } = props;

  const { isLoading, onCompleteJob } = useCompleteJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const handleConfirmPartStored = async () => {
    await onCompleteJob({ jobId });
  };

  return (
    <Button
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text={`Confirm ${jobTypeOptions.stage} Completion`}
      onClick={handleConfirmPartStored}
    />
  );
};
