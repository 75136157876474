import { Stack } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { InventoryChecklistStep } from '../../../../common/inventory-checklist-step';
import { ModalContentHeading } from '../../../../common/modal-content-heading';
import { packPackModalAtoms } from '../../complete-packing-modal.atoms';
import { ConfirmStartContent } from '../step-confirm-start/confirm-start-content';

export const PackPackJobContent = () => {
  const step = useRecoilValue(packPackModalAtoms.step);
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    packPackModalAtoms.inventoryRequests,
  );

  switch (step) {
    case 'CONFIRM_START':
      return <ConfirmStartContent />;

    case 'INVENTORY_CHECKLIST':
      return (
        <Stack gap="x">
          <ModalContentHeading titleText="Packing Inventory" />
          <InventoryChecklistStep
            inventoryRequests={inventoryRequests}
            jobStep="packing"
            setInventoryRequests={setInventoryRequests}
          />
        </Stack>
      );

    default:
      return null;
  }
};
