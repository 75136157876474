import { memo } from 'react';
import { Typography, Flex } from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { ValuePropTypes } from './value-props.types';
import { ValueProp } from './components/value-prop';

const Section = (props: ValuePropTypes) => {
  const { id, updateFeatureInfo, userId, valuePropErrors, valueProps } = props;

  const addNewValueProp = () => {
    updateFeatureInfo({
      key: 'valueProps',
      type: 'key',
      value: [
        ...valueProps,
        { ctaLink: '', ctaTitle: '', paragraph: '', photo: '', title: '' },
      ],
    });
  };

  return (
    <StyledSectionContainer>
      <Flex>
        <Typography text="Value props" textStyle="bold" variant="h4" />
        <Typography
          align="right"
          color="link"
          decoration="underline"
          marginLeft="0.8rem"
          marginTop="0.8rem"
          style={{
            cursor: 'pointer',
          }}
          text="Add value prop"
          onClick={addNewValueProp}
        />
      </Flex>
      {valueProps.map((valueProp, index) => {
        return (
          <ValueProp
            key={`${valueProp.title}-${index}`}
            allValueProps={valueProps}
            id={id}
            index={index}
            updateFeatureInfo={updateFeatureInfo}
            userId={userId}
            valueProp={valueProp}
            valuePropErrors={valuePropErrors}
          />
        );
      })}
    </StyledSectionContainer>
  );
};

export const ValueProps = memo(Section);
