import { Content } from 'pdfmake/interfaces';
import { GetPackingSlipDocProps } from '../../get-packing-slip-doc.types';
import { getTableItemsFromStocks } from '../get-table-items-from-stocks';
import { getStocksNotYetShipped } from '../get-stocks-not-yet-shipped';
import { getPalette } from '../get-style-args';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getItemsNotYetShipped = (
  props: GetPackingSlipDocProps,
): Content[] => {
  const stocksNotYetShipped = getStocksNotYetShipped(props);
  const numStocksToBeShipped = stocksNotYetShipped.length;
  const itemsNotYetShipped = getTableItemsFromStocks(stocksNotYetShipped);
  const { borderColor } = getPalette();

  const itemsNotYetShippedTable: Content[] = [
    {
      margin: [0, 20, 0, 0],
      style: ['fontSize11'],
      text: 'Items not yet shipped',
    },
    {
      columns: [
        {
          svg: `<svg fill="${borderColor}" xmlns="http://www.w3.org/2000/svg"><rect width="532" height="1" /></svg>`,
        },
      ],
      margin: [0, 4, 0, 12],
    },
    {
      margin: [0, 0, 0, 12],
      style: ['lightGray', 'fontSize8'],
      text: 'The following items in your order will be shipped when ready.',
    },
    {
      layout: 'noBorders',
      style: ['tableMargins'],
      table: {
        body: [
          [
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: `Items not yet shipped ${
                numStocksToBeShipped > 1
                  ? `(${numStocksToBeShipped} total)`
                  : ''
              }`,
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'SKU',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Color',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Size',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Quantity',
            },
          ],
          ...itemsNotYetShipped.map(({ color, name, qty, size, sku }) => {
            return [
              { style: ['tableCellAllMargins'], text: name },
              {
                style: ['tableCellAllMargins', 'lightGray', 'fontSize8'],
                text: sku,
              },
              { style: ['tableCellAllMargins'], text: color },
              { style: ['tableCellAllMargins'], text: size },
              { style: ['tableCellAllMargins'], text: qty },
            ];
          }),
        ],
        headerRows: 1,
        widths: ['35%', '20%', '15%', '15%', '15%'],
      },
    },
  ];

  return !!numStocksToBeShipped ? itemsNotYetShippedTable : [];
};
