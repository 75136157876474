import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  DeleteFeatureArgs,
  DeleteFeatureResponse,
} from './delete-feature-settings.types';

export const deleteDesignerFeature = ({
  designerId,
  featureId,
}: DeleteFeatureArgs) => {
  return axios.delete<DeleteFeatureResponse>(
    `${baseApiUrl}/designers/${designerId}/feature-page/${featureId}`,
  );
};
