import { Typography } from 'gantri-components';
import { formatAsLocaleNumber } from '../../../../../helpers/formatter';
import { PercentCellProps } from './percent-cell.types';

export const PercentCell = (props: PercentCellProps) => {
  const { formatNumberOptions, typographyProps, value } = props;
  const isNaN = Number.isNaN(Number(value === null ? '-' : value));
  const cellValue = isNaN
    ? '-'
    : formatAsLocaleNumber(value / 100, {
        ...formatNumberOptions,
        style: 'percent',
      });

  return <Typography {...typographyProps} text={cellValue} />;
};
