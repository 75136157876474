import { useFormikContext } from 'formik';
import { useCallback } from 'react';

export const useFormikHelper = () => {
  const { setFieldValue, setTouched } = useFormikContext();

  const resetInputsValue = useCallback(
    (
      names: string | string[],
      value: any = '',
      emitTouchEvent: boolean = true,
    ) => {
      const touch = {};
      const inputs = Array.isArray(names) ? names : [names];

      inputs.forEach((name) => {
        setFieldValue(name, value);
        touch[name] = emitTouchEvent;
      });

      setTouched(touch);
    },
    [],
  );

  return { resetInputsValue };
};
