import { useEffect, useState } from 'react';
import { Button, TextField, Typography } from 'gantri-components';
import { StyledPageSection } from '../../../../components/layout/page-layout-styles';
import { StyledFieldLabel } from '../../designer-styles';
import StackedContainer from '../../../../components/common/stacked-container';
import { IntegrationProps } from './integrations.types';
import { useUpdateDesigner } from '../../../../api/designers/routes/update-designer/update-designer.query';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { getDesigner } from '../../../../api/designers/routes';
import { DesignerData } from '../../designer.types';

export const DesignerIntegrations = (props: IntegrationProps) => {
  const { designerInfo, updateDesignerInfo } = props;

  const [shopifyStoreName, setShopifyStoreName] = useState<string>('');
  const [shopifyApiKey, setShopifyApiKey] = useState<string>('');
  const [shopifyPassword, setShopifyPassword] = useState<string>('');

  const { isLoading: processing, onUpdateDesigner } = useUpdateDesigner();

  const invalidateDesignerQueryCache = useGetInvalidateQueryCache(getDesigner);

  const handlePaypalUpdate = async () => {
    const updatedDesignerInfo: DesignerData = {
      ...designerInfo,
      shopifyApiKey,
      shopifyPassword,
      shopifyStoreName,
    };

    onUpdateDesigner(
      { designerInfo: updatedDesignerInfo },
      {
        onSuccess: async () => {
          updateDesignerInfo({ type: 'state', value: updatedDesignerInfo });

          await invalidateDesignerQueryCache();
        },
      },
    );
  };

  useEffect(() => {
    if (designerInfo) {
      setShopifyStoreName(designerInfo.shopifyStoreName || '');
      setShopifyApiKey(designerInfo.shopifyApiKey || '');
      setShopifyPassword(designerInfo.shopifyPassword || '');
    }
  }, [designerInfo]);

  return (
    <StyledPageSection>
      <StackedContainer gap="20px" marginBottom="60px" width="360px">
        <Typography text="Shopify" textStyle="bold" variant="h4" />

        <div>
          <StyledFieldLabel text="Store Name" textStyle="bold" variant="p2" />
          <TextField
            placeholder="Enter Store Name"
            value={shopifyStoreName}
            onTextChange={setShopifyStoreName}
          />
        </div>

        <div>
          <StyledFieldLabel text="API Key" textStyle="bold" variant="p2" />
          <TextField
            placeholder="Enter API Key"
            value={shopifyApiKey}
            onTextChange={setShopifyApiKey}
          />
        </div>

        <div>
          <StyledFieldLabel text="Password" textStyle="bold" variant="p2" />
          <TextField
            placeholder="Enter Password"
            type="password"
            value={shopifyPassword}
            onTextChange={setShopifyPassword}
          />
        </div>
      </StackedContainer>

      <Button
        processing={processing}
        text="Update"
        onClick={handlePaypalUpdate}
      />
    </StyledPageSection>
  );
};
