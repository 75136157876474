import { generateStylesForResolutionAwareProps } from 'gantri-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'gantri-components/dist/types/layout';
import styled, { css } from 'styled-components';
import { ThumbnailProps } from './thumbnail.props';

export const StyledThumbnail = styled.div<ThumbnailProps>`
  ${({ backgroundSize, cursor, height, onClick, size, src, width }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'width',
        resolutionAwareProp: width || size,
      },
      {
        cssProperty: 'height',
        resolutionAwareProp: height || size,
      },
    ];

    return css`
      ${generateStylesForResolutionAwareProps(entries)};
      background-image: url(${src});
      background-position: center;
      background-size: ${backgroundSize};
      background-repeat: no-repeat;
      background-color: var(--colors-white);
      cursor: ${onClick ? 'pointer' : cursor};
    `;
  }}
`;
