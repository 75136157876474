import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { ShipManuallyArgs } from './ship-manually.types';

export const shipManually = (args: ShipManuallyArgs) => {
  const { shipmentId, ...data } = args;

  return axios.put<TransactionResponse>(
    `${baseApiUrl}/shipment/${shipmentId}/manual-ship`,
    data,
  );
};
