import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { GetDashboardReportSummaryQueryArgs } from '../../dashboard.types';
import { getDashboardFinishJobsSummary } from './get-dashboard-finishing-jobs-summary';

export const useFetchDashboardFinishJobsSummary = (
  props: GetDashboardReportSummaryQueryArgs,
) => {
  const { args, configs, enabled } = props;

  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!args && enabled,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch dashboard finish jobs summary.',
        });
      },
      queryFn: () => {
        return getDashboardFinishJobsSummary.query(args, configs);
      },
      queryKey: getDashboardFinishJobsSummary.key(args, configs),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
