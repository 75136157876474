import {
  Conditional,
  Grid,
  Icon,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useState } from 'react';
import { InstructionDataCellProps } from './instruction-data-cell.types';
import { StyledAnchor } from '../../styled-anchor';
import { useDownload } from '../../../../hooks/use-download';

export const InstructionDataCell = (props: InstructionDataCellProps) => {
  const { editing, instruction, onTextEdit } = props;

  const [instructionText, setInstructionText] = useState<string>(
    instruction?.text,
  );

  const { downloadSingleFile } = useDownload();

  return (
    <Stack gap=".5x">
      <Conditional
        condition={editing}
        Fallback={
          <Conditional condition={!!instruction?.text}>
            <Typography
              htmlText={instruction?.text?.replace(/\n/g, '<br />')}
            />
          </Conditional>
        }
      >
        <TextArea
          placeholder="Enter text instruction..."
          style={{ height: '10rem' }}
          value={instruction?.text}
          onBlur={(event) => {
            event.preventDefault();
            event.stopPropagation();

            onTextEdit(instructionText);
          }}
          onTextChange={setInstructionText}
        />
      </Conditional>
      {instruction?.files?.map(({ fileName, url }) => {
        return (
          <Grid
            key={url}
            alignItems="center"
            columns="1fr max-content"
            gap="x"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              return downloadSingleFile({
                fileName,
                url,
              });
            }}
          >
            <StyledAnchor text={fileName} />
            <Icon color="link" name="arrows:arrow_external" />
          </Grid>
        );
      })}
    </Stack>
  );
};
