import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultStocksGroupBy } from '../../report-stocks.constants';
import { ReportStocksQueries } from '../../report-stocks.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Stocks';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportStocksQueries>();
  const {
    groupBy = defaultStocksGroupBy,
    productId,
    productName,
    version,
  } = queries;

  if (!/^productId$/i.test(groupBy) || !productId) return [];

  const breadcrumb: Breadcrumb[] = [
    {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'productId' }),
    },
  ];

  if (version) {
    breadcrumb.push({
      name: productName,
      path:
        location +
        convertQueriesToString({
          groupBy: 'productId',
          productId,
          productName,
        }),
    });
  }

  return breadcrumb;
};
