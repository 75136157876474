import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultMachinesGroupBy } from '../../report-machines.constants';
import { ReportMachinesQueries } from '../../report-machines.types';

export const getTitle = (rootTitle: string): string => {
  const queries = getActiveQueries<ReportMachinesQueries>();
  const {
    groupBy = defaultMachinesGroupBy,
    machineId,
    machineName,
    machineType,
  } = queries;

  if (/^machineName$/i.test(groupBy) && machineId) {
    return machineName;
  }

  if (/^machineType$/i.test(groupBy) && machineType) {
    return machineType;
  }

  return rootTitle;
};
