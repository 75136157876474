import { Cell, Toggle } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { usersPageAtoms } from './users-filter.atoms';
import { usersTypesOptions } from './users-filter.constants';

const UsersFilter = () => {
  const [active, setActive] = useRecoilState(usersPageAtoms.filters.active);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={usersPageAtoms.filters.types}
          default={usersPageAtoms.defaults.filters.types}
          marginBottom="1rem"
          text="Type"
        />
        <AtomsCheckboxList
          atom={usersPageAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={[...usersTypesOptions]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={usersPageAtoms.filters.active}
          default={usersPageAtoms.defaults.filters.active}
          marginBottom="1rem"
          text="Active Users Only"
        />
        <Cell justifyContent="start">
          <Toggle name="show-all" value={active} onSelected={setActive} />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};

export default UsersFilter;
