import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { Items } from '../../../../pages/order/order.type';
import { TransactionResponse } from '../../transactions.types';

export const restockItems = (transactionId: number, items: Items[]) => {
  return axios.post<TransactionResponse>(
    `${baseApiUrl}/transactions/${transactionId}/payout-refund`,
    { stockAssociationData: items },
  );
};
