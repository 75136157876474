import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { Vendor } from '../../vendors.types';

interface GetVendorResponse {
  notice: string;
  success: boolean;
  vendor: Vendor;
}

export const getVendor = (id: number) => {
  return axios.get<GetVendorResponse>(`${coreApiUrl}/vendors/${id}`);
};
