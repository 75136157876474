import { FC } from 'react';
import { Stack } from 'gantri-components';
import { OverviewSectionProps } from './overview-section.types';
import { RequiresAttentionSection } from './sections/requires-attention-section';
import { OverviewTimelineSection } from './sections/overview-timeline-section';
import { OverviewFormSection } from './sections/form-section';

export const OverviewSection: FC<OverviewSectionProps> = ({
  currentMachine,
  isEditModeActive,
}) => {
  return (
    <Stack rowGap="7x">
      <OverviewFormSection
        currentMachine={currentMachine}
        isEditModeActive={isEditModeActive}
      />
      <RequiresAttentionSection attentions={currentMachine.attentions} />
      <OverviewTimelineSection machineId={currentMachine.id} />
    </Stack>
  );
};
