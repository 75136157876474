import { Button, Conditional, Stack, Typography } from 'gantri-components';
import { useState } from 'react';
import { useDownloadWithSmallToast } from '../use-download-with-small-toast';
import { List } from '../../components/common/list';
import { pluralize } from '../../helpers/pluralize';
import {
  useDownloadButtonProps,
  DownloadButtonProps,
} from './use-download-button.types';
import { FileToDownload } from '../use-download/hooks/use-handle-download-request/use-handle-download-request.types';

export const useDownloadButton = (props: useDownloadButtonProps) => {
  const { download, onError, onStart, ...rest } = props;

  const [filesWithError, setFilesWithError] = useState<FileToDownload[]>([]);

  const numFilesWithError = filesWithError.length;

  const filesToDownload = Array.isArray(download) ? download : [download];

  const numSuccessfulDownloads = filesToDownload.length - filesWithError.length;

  const DownloadButton = (props: DownloadButtonProps) => {
    return (
      <>
        <Button
          processing={processing}
          size="extraLarge"
          variant="secondary"
          width="100%"
          onClick={async () => {
            await downloadMultipleFiles(filesToDownload);
          }}
          {...props}
        />
        <Conditional condition={!!numFilesWithError}>
          <Stack gap=".5x">
            <Conditional condition={!!numSuccessfulDownloads}>
              <Typography
                text={`${numSuccessfulDownloads} ${pluralize(
                  'file',
                  numSuccessfulDownloads,
                )}  downloaded successfully!`}
                textStyle="bold"
              />
            </Conditional>
            <Typography
              color="alert"
              text={`Unable to download ${pluralize(
                'file',
                numFilesWithError,
              )}:`}
              textStyle="bold"
            />
            <List color="alert">
              {filesWithError.map(({ url }) => {
                return (
                  <li>
                    <Typography
                      color="alert"
                      style={{ overflowWrap: 'anywhere' }}
                      text={url}
                    />
                  </li>
                );
              })}
            </List>
          </Stack>
        </Conditional>
      </>
    );
  };

  const { DownloadStatusToast, downloadMultipleFiles, processing } =
    useDownloadWithSmallToast({
      ...rest,
      onError: (file) => {
        setFilesWithError((errorFiles) => {
          return [...errorFiles, file];
        });
        onError?.(file);
      },
      onStart: () => {
        setFilesWithError([]);
        onStart?.();
      },
    });

  return {
    /** Renders a download button. If there are any errors with the download, the problem files are listed below. */
    DownloadButton,
    /** Renders a tooltip with the download start/complete/error statuses. */
    DownloadStatusToast,
  };
};
