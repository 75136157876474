import { Icon } from 'gantri-components';
import { MachineStatus } from '../../../../constants/machines';

export const StatusIcon = ({ status }: { status: MachineStatus }) => {
  switch (status) {
    case 'Ready':
      return (
        <Icon color="link" name="actions:thumbs_up" size="1.6rem" top=".2rem" />
      );
    case 'Manual':
      return (
        <Icon color="success" name="people:person" size="1.6rem" top=".2rem" />
      );
    case 'In progress':
      return (
        <Icon color="warning" name="time:clock" size="1.6rem" top=".2rem" />
      );
    case 'Offline':
    default:
      return (
        <Icon color="alert" name="ui-control:x" size="1.6rem" top=".2rem" />
      );
  }
};
