import { Grid, Stack } from 'gantri-components';
import { FilterGridProps, FilterRowProps } from './filter-grid.types';

export const FilterGrid = (props: FilterGridProps) => {
  const { isSmallFormat, ...rest } = props;

  const desktopWidth = isSmallFormat ? 'unset' : '60rem';

  return (
    <Stack
      gap="4x"
      minHeight="8rem"
      width={{ lg: desktopWidth, md: '100%' }}
      {...rest}
    />
  );
};

export const FilterRow = (props: FilterRowProps) => {
  return (
    <Grid
      columnGap="3x"
      columns={{ lg: '15rem 1fr', md: 1 }}
      rowGap=".5x"
      {...props}
    />
  );
};
