import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledRemoveRowIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

export const StyledUnitInputContainer = styled.div`
  width: 8rem;
`;

export const StyledItemGrid = styled(Grid)<{ $disabled?: boolean }>`
  ${({ $disabled }) => {
    return (
      $disabled &&
      css`
        cursor: not-allowed;
      `
    );
  }}
`;
