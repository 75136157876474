import { getThemeColor } from 'gantri-components';
import { CreateGlobalHandoutCss } from './create-global-handout-css.types';

const convertInchesToPx = (inches: number) => {
  /** pixels/in */
  const dpi = 72;

  return Math.round(inches * dpi);
};

/** In pixels. */
const pageWidth = convertInchesToPx(12);
/** In pixels. */
const pageHeight = convertInchesToPx(18);

/** In pixels. */
export const cardWidth = convertInchesToPx(4);

/** In pixels. */
export const cardHeight = convertInchesToPx(6);

/** In pixels. */
export const bleedSize = convertInchesToPx(0.0625);
/** In pixels. */
const baselineSpacing = convertInchesToPx(0.275);
/** In pixels. */
const largeSpacing = convertInchesToPx(0.75);

export const createGlobalHandoutCss: CreateGlobalHandoutCss = (isPreview) => {
  const gantriFontPath = 'https://res.cloudinary.com/gantri/raw/upload/fonts/';
  const fontFamilyNormal = 'SohneBuch';
  const fontFamilyBold = 'SohneDreiviertelfett';

  return `
    @font-face {
        font-family: "${fontFamilyNormal}";
        font-weight: normal;
        src: url("${gantriFontPath + fontFamilyNormal}.ttf") format("truetype");
        font-display: swap;
    }

    @font-face {
        font-family: "${fontFamilyBold}";
        font-weight: bold;
        src: url("${gantriFontPath + fontFamilyBold}.ttf") format("truetype");
        font-display: swap;
    }

    body {
        margin: 0;
    }

    .handout--page {
        position: relative;
        width: ${pageWidth}px;
        height: ${pageHeight}px;
        background-color: white;
        box-sizing: content-box;
        overflow: hidden;
    }

    .handout--page * {
        box-sizing: content-box;
        font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .handout--page p {
        margin: 0;
    }

    .handout--page table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    .handout--page-header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: ${convertInchesToPx(0.5)}px ${convertInchesToPx(1.2)}px 0;
    }

    .handout--header {
        width: 100%;
        color: black;
    }

    .handout--header tr {
        vertical-align: top;
    }

    .handout--table-grid {
        position: absolute;
        left: ${convertInchesToPx(1.85) - baselineSpacing}px;
        bottom: ${convertInchesToPx(0.5) - baselineSpacing}px;
        margin: 0 auto;
    }

    .handout--table-grid tr:first-child td,
    .handout--table-grid tr:last-child td {
        height: ${baselineSpacing - bleedSize}px;
    }
    
    .handout--table-grid tr:nth-child(3) td {
        height: ${largeSpacing - bleedSize * 2}px;
    }

    .handout--table-grid td:first-child,
    .handout--table-grid td:last-child {
        width: ${baselineSpacing - bleedSize}px;
    }
    
    .handout--table-grid td:nth-child(3) {
        width: ${baselineSpacing - bleedSize * 2}px;
    }
    
    .handout--table-grid td {
        position: relative;
        padding: 0;
    }

    .handout--table-grid .handout--grid-card-wrapper {
        width: ${cardWidth + bleedSize * 2}px;
        height: ${cardHeight + bleedSize * 2}px;
    }

    .handout--printer-mark {
        height: ${convertInchesToPx(0.2)}px;
        width: ${convertInchesToPx(0.8)}px;
        background-color: black;
        margin-left: auto;
    }

    .handout--logo-wrapper img {
        margin: 0 auto;
        max-height: 55px;
    }

    .handout--card-bleed-border {
        position: relative;
        z-index: 1;
        overflow: hidden;
        ${isPreview ? '' : `padding: ${bleedSize}px;`}
    }

    .handout--card {
        height: ${cardHeight}px;
        width: ${cardWidth}px;
        text-align: center;
        box-sizing: content-box;
    }

    .handout--page *,
    .handout--card * {
        font-family: "${fontFamilyNormal}", Helvetica, sans-serif;
        font-size: 10px;
        line-height: 13px;
        box-sizing: content-box;
        text-align: center;
    }

    .handout--card strong {
        font-family: "${fontFamilyBold}", Helvetica, sans-serif;
        font-size: 14px;
        line-height: 22px;
    }

    .handout--card img {
        display: block;
    }

    .handout--card p {
        color: inherit;
    }

    .handout--image-placeholder {
        padding-top: 100%;
        width: 100%;
        background: ${getThemeColor('monochrome_400')};
    }

    .handout--image-placeholder-logo {
        width: 60px;
        height: 60px;
        padding-top: 0;
        margin: 0 auto;
    }
    .printer-mark {
        position: absolute;
        z-index: 0;
        background-color: black;
    }
    .printer-mark-vertical {
        top: 0;
        bottom: 0;
        width: 1px;
        height: 100%;
    }
    .printer-mark-horizontal {
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
    }

    .handout--table-grid td {
        position: relative;
    }

    .handout--table-grid .printer-mark-vertical:nth-child(odd) {
        left: ${bleedSize}px;
    }

    .handout--table-grid .printer-mark-vertical:nth-child(even) {
        right: ${bleedSize}px;
    }

    .handout--table-grid .printer-mark-horizontal:nth-child(odd) {
        top: ${bleedSize}px;
    }

    .handout--table-grid .printer-mark-horizontal:nth-child(even) {
        bottom: ${bleedSize}px;
    }
`;
};
