import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledFlex = styled(Flex)<{ disabled: boolean }>`
  ${({ disabled }) => {
    return css`
      cursor: ${disabled && 'now-allowed'};
    `;
  }}
`;
