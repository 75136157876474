import {
  Cell,
  Stack,
  Typography,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { useState } from 'react';
import DownloadLink from '../../../../components/common/download-link';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { SectionHeading } from '../section-heading';
import { AttachmentsSectionProps } from './attachments-section.types';
import { InventoryPurchaseAttachment } from '../../../../api/inventory-purchases/inventory-purchases.types';
import { imageExtensions } from '../../../../helpers/images/cloudinary/generate-transformations-string/generate-transformations-string.types';

export const AttachmentsSection = (props: AttachmentsSectionProps) => {
  const {
    editedInventoryPurchase,
    handleSave,
    isEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  let asyncAttachments = editedInventoryPurchase.attachments.map(
    (attachment) => {
      return attachment.fileName;
    },
  );
  const uploadPath = `inventory-purchases/${editedInventoryPurchase.id}`;

  const onFileDelete: HandleDeleteFile = async ({ fileName, fileUrl }) => {
    asyncAttachments = asyncAttachments.filter((name) => {
      return name !== fileName;
    });

    updateEditedInventoryPurchaseWith({
      attachments: editedInventoryPurchase.attachments.filter((attachment) => {
        return attachment.url !== fileUrl && attachment.fileName !== fileName;
      }),
    });
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName, fileUrl },
  ]) => {
    const newAttachment: InventoryPurchaseAttachment &
      Record<'newAttachment', boolean> = {
      fileName,
      newAttachment: true,
      url: fileUrl,
    };

    const existingAttachment = asyncAttachments.includes(fileName);

    if (existingAttachment) {
      updateEditedInventoryPurchaseWith({
        attachments: editedInventoryPurchase.attachments.map((attachment) => {
          return attachment.fileName === fileName ? newAttachment : attachment;
        }),
      });
    } else {
      updateEditedInventoryPurchaseWith({
        attachments: [...editedInventoryPurchase.attachments, newAttachment],
      });
    }
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: null,
    handleUploadsComplete,
    onFileDelete,
    path: uploadPath,
  });

  const expectedExtensions = [...imageExtensions, 'pdf'] as const;

  const Attachment = (props: { attachment: InventoryPurchaseAttachment }) => {
    const { attachment } = props;

    const { fileUploaderProps } = useFirebaseFileUploader({
      fileUrl: attachment.url,
      handleUploadsComplete,
      onFileDelete,
      path: uploadPath,
    });

    return (
      <Cell justifyItems="start">
        <FileUploader
          {...fileUploaderProps}
          expectedExtensions={[...expectedExtensions]}
          fileName={attachment.fileName}
          variant="plain"
        />
      </Cell>
    );
  };

  return (
    <>
      <SectionHeading
        handleSave={handleSave}
        isEditing={isEditing}
        isEditingDisabled={isEditingDisabled}
        setIsEditing={setIsEditing}
        text="Attachments"
        toggleEditMode={toggleEditMode}
      />
      <Stack height="unset" justifyContent="start">
        {isEditing ? (
          <>
            {editedInventoryPurchase.attachments.map((attachment, index) => {
              return <Attachment key={index} attachment={attachment} />;
            })}
            <Cell>
              <FileUploader
                {...fileUploaderProps}
                expectedExtensions={[...expectedExtensions]}
                fileName={null}
                variant="button"
              />
            </Cell>
          </>
        ) : !!editedInventoryPurchase.attachments?.length ? (
          editedInventoryPurchase.attachments.map((attachment) => {
            return (
              <Cell key={attachment.url}>
                <DownloadLink {...attachment} />
              </Cell>
            );
          })
        ) : (
          <Typography
            color="t2"
            text="There are no attachments for this inventory purchase."
          />
        )}
      </Stack>
    </>
  );
};
