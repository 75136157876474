import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedReferralsArgs,
  FetchPaginatedReferralsResponse,
} from './fetch-paginated-referrals.types';

export const fetchPaginatedReferrals = (args: FetchPaginatedReferralsArgs) => {
  const { page, purchased, search, sortingField, sortingType } = args;

  return axios.post<FetchPaginatedReferralsResponse>(
    `${coreApiUrl}/paginatedReferrals`,
    {
      page,
      purchased,
      search,
      sortingField,
      sortingType,
    },
  );
};
