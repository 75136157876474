import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllInventoriesForStockResponse } from './fetch-all-for-stock.types';

const query = (
  ids: number[] = [],
): Promise<FetchAllInventoriesForStockResponse> => {
  return axios({
    method: 'get',
    params: { ids },
    url: `${coreApiUrl}/inventory/for-stock`,
  }).then((response) => {
    return response.data;
  });
};

const key = (ids: number[] = []) => {
  return [
    {
      groupKey: groupKey(),
      key: `/inventory/for-stock/${ids.sort().join('/')}`,
    },
  ] as const;
};

const groupKey = () => {
  return 'inventory-for-stock';
};

export const fetchAllInventoriesForStock = {
  groupKey,
  key,
  query,
};
