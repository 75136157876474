import styled, { css, CSSProperties } from 'styled-components';

export const StyledScrollParent = styled.div`
  overflow-x: auto;
`;

export const StyledScrollChild = styled.div<{
  $minWidth?: CSSProperties['minWidth'];
}>`
  overflow: visible;
  height: 100%;
  ${({ $minWidth = '50rem' }) => {
    return css`
      min-width: ${$minWidth};
    `;
  }}
`;
