import { FC, useCallback, useState } from 'react';
import {
  Box,
  Button,
  Cell,
  Checkbox,
  Conditional,
  Flex,
  Grid,
  Line,
  Stack,
  Typography,
} from 'gantri-components';
import SubsectionTitle from '../../layout/subsection-title';
import ReasonStock from '../reason-stock';
import routePaths from '../../../config/route-paths';
import { formatAsCurrency } from '../../../helpers/formatter';
import { shipmentStatuses, stockStatuses } from '../../../constants/options';
import { useNotification } from '../../../hooks/useNotification';
import { ManualShippingModal } from '../../../pages/order/components';
import { PageHeading } from '../../layout/page-heading';
import { useIsNotFirstRender, useRouter } from '../../../hooks';
import { transactionsApi } from '../../../api';
import MetaData from '../../meta-data';

const preShipmentStatuses = [
  shipmentStatuses.waiting,
  shipmentStatuses.inProgress,
  shipmentStatuses.readyToShip,
  shipmentStatuses.cancelled,
];

const RefundReason: FC<{
  currentOrderData: any;
  id: number;
  isReturn: boolean;
}> = ({ currentOrderData, id, isReturn }) => {
  const { navigate } = useRouter();

  const { notify, notifyAxiosError, onInterceptRequest } = useNotification();
  const {
    amount: orderPaymentAmounts,
    gift: giftInfo,
    shipments,
    stocks,
  } = currentOrderData;

  const [manualShipDialogVisible, setManualShipDialogVisible] = useState(false);

  const [refunds, setRefunds] = useState([]);
  const [statefulStocks] = useState(stocks);
  const [checkedStocks, setCheckedStocks] = useState([]);
  const [valid, setValid] = useState(false);
  const [subtotal, setSubtotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [credit, setCredit] = useState(0);
  const [gift, setGift] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  const [isActiveReturnShipping, setIsActiveReturnShipping] = useState(false);

  const onCancel = () => {
    navigate(`${routePaths.orders}/${id}`);
  };

  const onConfirm = async (
    trackingNumber?: unknown,
    shippingCost?: unknown,
  ) => {
    if (valid) {
      await onInterceptRequest(async () => {
        try {
          const manualShipping = trackingNumber
            ? {
                manualDataFromShippo: {
                  rateCost: shippingCost,
                  trackingNumber,
                },
                type: 'Manual refund',
              }
            : {};

          const { data } = await transactionsApi.cancelOrRefundItems({
            refundItems: refunds,
            refundShipping: isReturn ? isActiveReturnShipping : false,
            status: 'Refund',
            transactionId: id,
            ...manualShipping,
          });

          if (data.newTransaction && data.newTransaction.shipments.length) {
            notify(data.notice);
          }

          if (!!data.oldTransaction && !data.newTransaction) {
            notify(data.notice);
          }

          if (!data.isShippoError && data.success) {
            const orderId = data.newTransaction?.id || data.oldTransaction?.id;

            if (orderId) {
              navigate(`${routePaths.orders}/${orderId}`, {
                state: {
                  locationOrderId: orderId,
                },
              });
            }
          }
        } catch (error) {
          notifyAxiosError({
            error,
            fallbackMessage:
              'A shipping error occurred, please enter the shipping details manually.',
          });
          setManualShipDialogVisible(true);
        }
      });
    }
  };

  const validate = (checkedIds) => {
    if (isReturn && isActiveReturnShipping && checkedIds.length === 0) {
      setValid(true);

      return;
    }

    const hasInvalid = statefulStocks.find((stock) => {
      return (
        !stock.valid &&
        stock.valid !== undefined &&
        checkedIds.includes(stock.id)
      );
    });

    setValid(!hasInvalid && checkedIds.length > 0);
  };

  const determineAllShipmentsUnshipped = useCallback(() => {
    return shipments.every((shipment) => {
      return preShipmentStatuses.includes(shipment.status);
    });
  }, [shipments]);

  const determineAllStocksProcessed = useCallback(
    (selectedStocks) => {
      return shipments.every((shipment) => {
        return shipment.stocks.every((stock) => {
          return (
            [stockStatuses.refunded, stockStatuses.returnInProgress].includes(
              stock.status,
            ) || selectedStocks.includes(stock.id)
          );
        });
      });
    },

    [shipments],
  );

  const determineRefundCredit = (recentlyCheckedStocks) => {
    let canRefundCredit = true;

    if (!recentlyCheckedStocks.length) {
      return 0;
    }

    shipments.forEach((shipment) => {
      shipment.stocks.forEach((stock) => {
        if (stock.status === stockStatuses.refunded) {
          canRefundCredit = false;
        }
      });
    });

    return canRefundCredit ? orderPaymentAmounts.credit : 0;
  };

  const determineGiftAmount = (gift, subtotal) => {
    let amount = gift;
    let refundedCount = 0;
    let refundedAmount = 0;
    let itemsInOrder = 0;
    const total = currentOrderData.amount.subtotal;

    shipments.forEach((shipment) => {
      shipment.stocks.forEach((stock) => {
        itemsInOrder++;

        if (stock.status === stockStatuses.refunded) {
          refundedCount++;
          refundedAmount += stock.amount ? stock.amount.subtotal : 0;
        }
      });
    });

    if (giftInfo && giftInfo.type == 'Discount %') {
      const percentOff = giftInfo.amount / 100;

      amount = subtotal * percentOff;
    }

    if (giftInfo && giftInfo.type === 'Discount') {
      const allSubtotal = refundedAmount + subtotal;
      const remainingAmount = total - allSubtotal;

      if (total - allSubtotal > gift) {
        amount = 0;
      } else {
        amount -= remainingAmount;
      }
    }

    if (amount < 0) {
      amount = 0;
    }

    if (subtotal === 0) {
      return 0;
    }

    if (amount > subtotal) {
      return -(amount - (amount - subtotal));
    }

    return -amount || 0;
  };

  const adjustSummaryValues = (
    recentlyCheckedStocks,
    statefulStock,
    modifier,
  ) => {
    const shipmentForStock = shipments.find((shipment) => {
      return shipment.id === statefulStock.shipmentId;
    });

    const { amount: stockAmounts } = statefulStock;
    const { amount: orderAmounts } = currentOrderData;

    let canRefundShipping = false;

    if (
      determineAllShipmentsUnshipped() &&
      determineAllStocksProcessed(recentlyCheckedStocks) &&
      shipmentForStock &&
      preShipmentStatuses.includes(shipmentForStock.status)
    ) {
      canRefundShipping = true;
    }

    const refundCredit = determineRefundCredit(recentlyCheckedStocks);
    const shippingAmount =
      shipmentForStock.shippingOptions.type === 'fastest' ? 5000 : 0;

    const newSubtotal = subtotal + modifier * stockAmounts.subtotal || 0;
    const newShipping =
      newSubtotal && (canRefundShipping || (isReturn && isActiveReturnShipping))
        ? modifier * (orderAmounts.shipping || shippingAmount)
        : 0;

    const newTax = tax + modifier * stockAmounts.tax || 0;
    const newGift = determineGiftAmount(orderAmounts.gift, newSubtotal);

    const newCredit = -Math.min(
      refundCredit,
      newSubtotal + newShipping + newTax + newGift,
    );

    const newDiscount = orderAmounts.discount
      ? determineGiftAmount(orderAmounts.discount, newSubtotal)
      : 0;

    const newTotal =
      newSubtotal + newShipping + newTax + newCredit + newGift + newDiscount;

    setSubtotal(newSubtotal);
    setShipping(newShipping);
    setTax(newTax);
    setCredit(newCredit);
    setGift(newGift);
    setDiscount(newDiscount);
    setTotal(newTotal);
  };

  const handleCheck = (modified) => {
    return (checked) => {
      const statefulStockIndex = statefulStocks.findIndex((stock) => {
        return stock.id === modified.id;
      });
      const statefulStock = { ...statefulStocks[statefulStockIndex] };
      let modifier = 1;
      let filteredStocks = checkedStocks.slice();

      filteredStocks.push(modified.id);

      if (!checked) {
        modifier = -1;
        filteredStocks = filteredStocks.filter((id) => {
          return id !== modified.id;
        });
      }

      setCheckedStocks(filteredStocks);
      setRefunds(refunds);
      adjustSummaryValues(filteredStocks, statefulStock, modifier);
      validate(filteredStocks);
    };
  };

  const handleUpdate = (modified) => {
    const spliceIndex = refunds.findIndex((refund) => {
      return refund.id === modified.stockInfoId;
    });

    if (spliceIndex >= 0) {
      refunds.splice(spliceIndex, 1);
    }

    setRefunds([
      ...refunds,
      {
        id: modified.stockInfoId,
        refundReason: modified.refundReason,
      },
    ]);
    validate(checkedStocks);
  };

  const hideManualShipDialog = () => {
    setManualShipDialogVisible(false);
  };

  const onManualShipmentConfirm = (trackingNumber, shippingCost) => {
    onConfirm(trackingNumber, shippingCost);
  };

  useIsNotFirstRender(() => {
    if (!isReturn) return;

    if (isActiveReturnShipping) {
      setShipping(currentOrderData.amount.shipping);
      setTotal(total + currentOrderData.amount.shipping);

      if (checkedStocks.length === 0) {
        setValid(true);
      }

      return;
    }

    if (checkedStocks.length === 0) {
      setValid(false);
    }

    setShipping(0);
    setTotal(
      checkedStocks?.length ? total - currentOrderData.amount.shipping : 0,
    );
  }, [isActiveReturnShipping, isReturn]);

  return (
    <>
      <MetaData title={`Create ${isReturn ? 'Return' : 'Refund'}`} />

      <PageHeading
        subTitle={`Select items below to create a ${
          isReturn ? 'return' : 'refund'
        }`}
        title={`Create ${isReturn ? 'Return' : 'Refund'}`}
      />

      <Box>
        {statefulStocks.map((stock, index) => {
          return (
            <ReasonStock
              key={index}
              shipments={shipments}
              stock={stock}
              type={isReturn ? 'return' : 'refund'}
              onCheck={handleCheck(stock)}
              onUpdate={handleUpdate}
            />
          );
        })}
      </Box>

      <Conditional condition={isReturn && currentOrderData.amount.shipping > 0}>
        <Flex
          justifyContent="space-between"
          marginTop="4x"
          maxWidth={{ lg: '66%', sm: '100%' }}
        >
          <Checkbox
            checked={isActiveReturnShipping}
            disabled={currentOrderData?.hasRefundShipping}
            labelText="Shipping refund"
            onSelected={setIsActiveReturnShipping}
          />
          <Typography
            text={formatAsCurrency(currentOrderData.amount.shipping, {
              isCents: true,
            })}
          />
        </Flex>
      </Conditional>

      <Line verticalMargin="6rem" />

      <Grid maxWidth={{ lg: '66%', sm: '100%' }} rowGap="6rem">
        <Cell>
          <SubsectionTitle>
            <Typography text="Summary" textStyle="bold" variant="h4" />
          </SubsectionTitle>
          <Stack gap="0">
            <Flex justifyContent="space-between">
              <Typography color="t1" text="Subtotal" />
              <Typography
                color="t2"
                text={formatAsCurrency(subtotal, { isCents: true })}
              />
            </Flex>
            <Flex justifyContent="space-between">
              <Typography color="t1" text="Shipping" />
              <Typography
                color="t2"
                text={formatAsCurrency(shipping, { isCents: true })}
              />
            </Flex>
            <Flex justifyContent="space-between">
              <Typography color="t1" text="Tax" />
              <Typography
                color="t1"
                text={formatAsCurrency(tax, { isCents: true })}
              />
            </Flex>
            {!!credit && (
              <Flex justifyContent="space-between">
                <Typography color="t1" text="Credit" />
                <Typography
                  color="t2"
                  text={formatAsCurrency(credit, { isCents: true })}
                />
              </Flex>
            )}
            {!!gift && (
              <Flex justifyContent="space-between">
                <Typography color="t1" text="Discount" />
                <Typography
                  color="t2"
                  text={formatAsCurrency(gift, { isCents: true })}
                />
              </Flex>
            )}
            {!!discount && (
              <Flex justifyContent="space-between">
                <Typography color="t1" text="Discount" />
                <Typography
                  color="t2"
                  text={formatAsCurrency(discount, { isCents: true })}
                />
              </Flex>
            )}
            <Flex justifyContent="space-between">
              <Typography
                color="t1"
                text={`Total ${isReturn ? 'for Returns' : 'Refunds'}`}
              />
              <Typography
                color="t1"
                text={formatAsCurrency(total, { isCents: true })}
              />
            </Flex>
          </Stack>
        </Cell>
        <Grid columnGap="1rem" columns="repeat(2, 12rem)" paddingBottom="6rem">
          <Button text="Cancel" variant="secondary" onClick={onCancel} />

          <Button
            disabled={!valid}
            text="Confirm"
            variant="primary"
            onClick={() => {
              return onConfirm();
            }}
          />
        </Grid>
      </Grid>

      <ManualShippingModal
        shippoButtonVisible={false}
        visible={manualShipDialogVisible}
        onCancel={hideManualShipDialog}
        onConfirm={onManualShipmentConfirm}
      />
    </>
  );
};

export default RefundReason;
