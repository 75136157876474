import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { ConfirmBeginContentProps } from './confirm-begin-content.types';

export const ConfirmBeginContent = (props: ConfirmBeginContentProps) => {
  const { job } = props;

  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to start ${job.step} ${job.type}?`}
      titleText="Start job"
    />
  );
};
