import { Button, Modal, Stack, Typography } from 'gantri-components';
import { JobStatus, jobStatuses } from '../../../../../constants/options';
import { JobHasWrongStatusModalProps } from './job-has-wrong-status-modal.types';

export const JobHasWrongStatusModal = (props: JobHasWrongStatusModalProps) => {
  const { expectedStatus, onClose, onJobUpdated } = props;

  const handleCloseModal = async () => {
    await onJobUpdated();
    onClose();
  };

  return (
    <Modal
      closeable={false}
      footer={<Button text="Refresh page" onClick={handleCloseModal} />}
      header={getHeaderText(expectedStatus)}
      width={{ lg: '40rem', md: '100%' }}
    >
      <Stack alignContent="center" minHeight="10rem">
        <Typography
          align="center"
          text={`Job is not in "${expectedStatus}" status.`}
        />
      </Stack>
    </Modal>
  );
};

const getHeaderText = (expectedStatus: JobStatus) => {
  switch (expectedStatus) {
    case jobStatuses.ready:
      return 'Unable to start job';

    case jobStatuses.inProgress:
      return 'Unable to complete job';

    default:
      return null;
  }
};
