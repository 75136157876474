import React, { useEffect } from 'react';
import {
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  SearchField,
  Table,
  TextField,
  Tooltip,
  Typography,
} from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { EditableInventoriesProps } from './inventory.props';
import {
  StyledRemoveRowIconContainer,
  StyledStack,
} from './inventory-check-list-setp.styles';
import { getInventoryUnit } from './adapter';
import { useEditableInventoriesTable } from './use-editable-inventories-table';
import { InventoryRequest } from '../../../api/jobs/jobs.types';
import { getInventoryWarnings } from './helpers/get-inventory-warnings';

export const EditableInventoriesTable = (props: EditableInventoriesProps) => {
  const { inventoryRequests, limitToInventoryTypes, onEdit, trackDiscarded } =
    props;

  const {
    addNewInventory,
    inventoriesOptions,
    onChangeInventoryData,
    onSelectInventory,
    removeInventoryRequest,
  } = useEditableInventoriesTable({
    inventoryRequests,
    limitToInventoryTypes,
    onEdit,
  });

  const columns: ColumnDef<InventoryRequest>[] = [
    {
      cell: ({ row: { original } }: CellContext<InventoryRequest, never>) => {
        const { id, inventory, isNew } = original;

        const { isStockExceeded, showWarningIcon } =
          getInventoryWarnings(original);

        return isNew ? (
          <SearchField
            autoSelectFirst={false}
            dropdownPopupWidth="33rem"
            errorMessage={isStockExceeded ? ' ' : undefined}
            filterFn={(searchBy, option) => {
              return (
                String(option.value)
                  ?.toLowerCase()
                  ?.includes(searchBy.toLowerCase()) ||
                String(option.label)
                  ?.toLowerCase()
                  ?.includes(searchBy.toLowerCase())
              );
            }}
            items={inventoriesOptions}
            maxHeight="33rem"
            placeholder="Select inventory"
            renderItem={({ label, value }) => {
              return (
                <Grid
                  columns="5rem 1fr"
                  gap="0.8rem"
                  horizontalPadding="1.6rem"
                  maxWidth="30rem"
                  verticalPadding="0.8rem"
                >
                  <Typography color="t1" text={value} />
                  <Typography color="t1" text={label} />
                </Grid>
              );
            }}
            searchable
            value={inventory.id}
            onSelect={(item) => {
              onSelectInventory(id, item?.value);
            }}
          />
        ) : (
          <Typography
            color={showWarningIcon ? 'alert' : undefined}
            text={inventory.name}
          />
        );
      },
      header: 'Name',
      id: 'name',
      size: 190,
    },
    {
      accessorKey: 'need',
      cell: ({
        getValue,
        row: { index, original },
      }: CellContext<InventoryRequest, InventoryRequest['need']>) => {
        const { id } = original;

        const { isInactive, isStockExceeded, showWarningIcon } =
          getInventoryWarnings(original);

        return (
          <Flex alignItems="center" gap="x">
            <Conditional condition={showWarningIcon}>
              <Tooltip
                Component={
                  <Typography
                    color="alert"
                    horizontalPadding="1.6rem"
                    text={[
                      isInactive && 'Inventory is inactive.',
                      isStockExceeded && 'Quantity not available.',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                    verticalPadding="0.8rem"
                  />
                }
                position="top-end"
              >
                <Icon color="alert" name="alert:warning_triangle" top=".3rem" />
              </Tooltip>
            </Conditional>
            <TextField
              key={`${index}_need`}
              ariaLabel={`${index}_need`}
              type="number"
              value={String(parseFloat(String(getValue() || 0)))}
              onBlurTextChange={(value) => {
                return onChangeInventoryData(id, 'need', Number(value));
              }}
            />
          </Flex>
        );
      },
      header: 'Used',
      size: 100,
    },
    {
      cell: ({ row }: CellContext<InventoryRequest, never>) => {
        const inventoryRequest = row.original;
        const { discarded, id, inventory } = inventoryRequest;

        return (
          <Flex alignItems="center" gap="x">
            <Conditional condition={trackDiscarded}>
              <TextField
                key={`${id}_discarded`}
                ariaLabel={`${id}_discarded`}
                type="number"
                value={String(parseFloat(String(discarded || 0)))}
                onBlurTextChange={(value) => {
                  onChangeInventoryData(id, 'discarded', Number(value || 0));
                }}
              />
            </Conditional>
            <Flex alignItems="center" gap="x" justifyContent="space-between">
              <Typography
                color="t2"
                minWidth="3.5rem"
                text={getInventoryUnit(inventory?.unit?.toLowerCase() || '')}
              />
              <StyledRemoveRowIconContainer
                onClick={() => {
                  return removeInventoryRequest(id);
                }}
              >
                <Icon color="t2" name="ui-control:x" size="1.6rem" />
              </StyledRemoveRowIconContainer>
            </Flex>
          </Flex>
        );
      },
      header: trackDiscarded ? 'Discarded' : 'Unit',
      id: 'unit',
      size: trackDiscarded ? 150 : 50,
    },
  ];

  useEffect(() => {
    // Define discarded as zero to appease back-end expectations
    onEdit(
      inventoryRequests.map(({ discarded, ...rest }) => {
        return {
          ...rest,
          discarded: discarded || 0,
        };
      }),
    );
  }, []);

  return (
    <StyledStack gap="x">
      <Table columns={columns} data={inventoryRequests} />
      <Button
        text="Add Inventory"
        variant="secondary"
        width="fit-content"
        onClick={addNewInventory}
      />
    </StyledStack>
  );
};
