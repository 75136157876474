import { productColorsMap } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { productAtoms } from '../../../../../../../../../../product.atoms';

const whitespacePatternString = '(?:_|-|\\s)';

export const convertWhitespace = (string: string) => {
  return string.replace(/\s/g, whitespacePatternString);
};

export const useGetBulkUploaderPatterns = () => {
  const product = useRecoilValue(productAtoms.product);

  const productIdOrNameRegExp: RegExp = useMemo(() => {
    const productIdOrNamePattern = new RegExp(
      convertWhitespace(
        `^(?:${product.id})|(?:${product.name} ${product.category})`,
      ),
      'i',
    );

    return productIdOrNamePattern;
  }, [product?.id]);

  const paintedColorsRegExp: RegExp = useMemo(() => {
    const colorsArray = Object.values(productColorsMap);

    const matchByColorCodeOrNameArray = colorsArray.map(
      ({ code, shortColorName }) => {
        const matchByColorCodeOrName = `(?:${code})|(?:${shortColorName})`;

        return matchByColorCodeOrName;
      },
    );

    const matchAgainstAllColorCodesAndNames =
      matchByColorCodeOrNameArray.join('|');

    return new RegExp(
      convertWhitespace(
        `${whitespacePatternString}(${matchAgainstAllColorCodesAndNames})`,
      ),
      'i',
    );
  }, []);

  /** Contains RegExp pattern strings for every variant (allows identifying each individual variant) */
  const variantPatternStrings: string[] = useMemo(() => {
    const variantPatternStrings = product?.selectors?.map(
      (partialSelectors) => {
        const variantPatternString = `${whitespacePatternString}(?:${partialSelectors
          .map(({ options }) => {
            const variantPartialCodeOrName = `(${options
              .map(({ code, name }) => {
                return `(?:(?:${code})|(?:${name}))`;
              })
              .join('|')})`;

            return variantPartialCodeOrName;
          })
          .join('|')})+`;

        return convertWhitespace(variantPatternString);
      },
    );

    return variantPatternStrings;
  }, [product?.selectors]);

  return {
    paintedColorsRegExp,
    productIdOrNameRegExp,
    variantPatternStrings,
    whitespacePatternString,
  };
};
