import { Conditional, Icon, Modal, Stack } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { CompletePrintQaModalProps } from './complete-print-qa-modal.types';
import { PrintQaFooter } from './components/print-qa-footer';
import { PrintQaContent } from './components/print-qa-content';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { printQcModalAtoms } from './complete-print-qa-modal.atoms';
import { useInitializeInProgressJob } from './hooks/use-handle-in-progress-job';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { materials } from '../../../../../../constants/parts';
import {
  opaqueHighTempChecklistDefaults,
  translucentGplaChecklistDefaults,
} from './complete-print-qa-modal.constants';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

export const CompletePrintQaModal = (props: CompletePrintQaModalProps) => {
  const { job, onClose, onJobUpdated } = props;

  const { isFetchingAvailableJobs } = useInitializeInProgressJob({ job });

  const selectedPrintJob = useRecoilValue(printQcModalAtoms.selectedPrintJob);
  const updateOnClose = useRecoilValue(printQcModalAtoms.updateOnClose);
  const setChecklist = useSetRecoilState(printQcModalAtoms.checklist);

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(printQcModalAtoms);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  useEffect(() => {
    const checklist =
      job.part.material === materials.opaque
        ? opaqueHighTempChecklistDefaults
        : translucentGplaChecklistDefaults;

    setChecklist(checklist);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            machineName={selectedPrintJob?.machine?.name}
            sectionsToShow={[
              'part',
              'color & material',
              'product',
              'machine',
              'instructions',
              'part diagram',
              'part diagram swatches',
            ]}
          />
        }
        detailsPanelWidth="1fr"
        footer={<PrintQaFooter handleCloseModal={handleCloseModal} job={job} />}
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <Conditional
          condition={isFetchingAvailableJobs}
          Fallback={<PrintQaContent job={job} />}
        >
          <Stack alignContent="center" height="20rem" justifyContent="center">
            <Icon color="link" name="animated:loader" size="5rem" />
          </Stack>
        </Conditional>
      </Modal>
    </ResetAtomsWrapper>
  );
};
