import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { IssueDifficulty } from '../../../../../../../../machine/components/issue-difficulty';
import { machineIssueSources } from '../../../../../../../../../constants/machines';
import { MachineIssueChecklistItem } from '../../../../../../../../../constants/options';

export const useGetBulkUpdateIssuesColumns = () => {
  return useMemo(() => {
    const columns: ColumnDef<MachineIssueChecklistItem>[] = [
      {
        accessorKey: 'type',
        header: 'Type',
        size: 80,
      },
      {
        cell: () => {
          return <Typography text={machineIssueSources.manual} />;
        },
        header: 'Source',
        id: 'source',
        size: 80,
      },
      {
        accessorKey: 'status',
        cell: ({
          row,
        }: CellContext<
          MachineIssueChecklistItem,
          MachineIssueChecklistItem['difficulty']
        >) => {
          return <IssueDifficulty issue={row.original} />;
        },
        header: 'Difficulty',
        size: 80,
      },
      {
        accessorKey: 'notes',
        header: 'Notes',
        size: 80,
      },
    ];

    return columns;
  }, []);
};
