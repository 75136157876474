import { useRecoilState, useRecoilValue } from 'recoil';
import { useAsync } from 'react-use';
import { DashboardReportToHide } from '../../types/store';
import { DashboardHeading } from './components/common/dashboard-heading';
import { DashboardOrdersSummary } from './components/dashboard-orders-summary';
import { DashboardStocksSummary } from './components/dashboard-stocks-summary';
import { DashboardPrintJobsSummary } from './components/dashboard-print-jobs-summary';
import { DashboardFinishingJobsSummary } from './components/dashboard-finishing-jobs-summary';
import { DashboardAssemblyJobsSummary } from './components/dashboard-assembly-jobs-summary';
import { DashboardTechSummary } from './components/dashboard-tech-summary';
import { DashboardFeedDownloads } from './components/dashboard-feed-downloads';
import { dashboardSummaryDateRangeToggleItems } from './components/common/dashboard-heading/components/dashboard-date-toggles/dashboard-date-toggles.constants';
import MetaData from '../../components/meta-data';
import { useValidateEndDate } from './hooks/use-validate-end-date';
import { DashboardSummaryAndTopStats } from './components/dashboard-summary-and-top-stats/dashboard-summary-and-top-stats';
import { dashboardAndReportAtoms } from './dashboard.atoms';
import { tablesSyncApi } from '../../api';
import { useSpinner } from '../../hooks';

export const Dashboard = () => {
  const endDate = useRecoilValue(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);
  const isValidEndDate = useValidateEndDate({ endDate, rangeType });

  const [reportsToHide, setReportsToHide] = useRecoilState(
    dashboardAndReportAtoms.columns,
  );
  const { onInterceptRequest } = useSpinner();

  const shouldRenderReport = (reportName: DashboardReportToHide) => {
    return !reportsToHide.includes(reportName);
  };

  const renderReport = {
    assemblyJobs: shouldRenderReport('Assembly Jobs'),
    finishingJobs: shouldRenderReport('Finishing Jobs'),
    orders: shouldRenderReport('Orders'),
    printJobs: shouldRenderReport('Print Jobs'),
    stocks: shouldRenderReport('Stocks'),
    tech: shouldRenderReport('Tech'),
  };

  useAsync(async () => {
    await onInterceptRequest(async () => {
      const { data } = await tablesSyncApi.getTableColumns('dashboard');

      setReportsToHide(
        data.columns
          ?.filter((column) => {
            return column.hidden;
          })
          ?.map((column) => {
            return column.id as DashboardReportToHide;
          }) ?? [],
      );
    });
  }, []);

  return (
    <>
      <MetaData title="Dashboard" />
      {isValidEndDate && (
        <>
          <DashboardHeading
            dateToggleItems={dashboardSummaryDateRangeToggleItems}
            enableSettingsToggle
            title="Dashboard"
          />
          <DashboardSummaryAndTopStats />
          {renderReport.orders && <DashboardOrdersSummary />}
          {renderReport.stocks && <DashboardStocksSummary />}
          {renderReport.printJobs && <DashboardPrintJobsSummary />}
          {renderReport.finishingJobs && <DashboardFinishingJobsSummary />}
          {renderReport.assemblyJobs && <DashboardAssemblyJobsSummary />}
          {renderReport.tech && <DashboardTechSummary />}
          <DashboardFeedDownloads />
        </>
      )}
    </>
  );
};
