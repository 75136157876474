import {
  Badge,
  Box,
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { jobStatuses, locationStatuses } from '../../../../constants/options';
import { StyledFieldIconWrapper } from '../custom-data-cells-styles';
import { formatDate } from '../../../../helpers/formatter';
import { prepareFailureReasons } from '../../../../helpers/fail-reason';
import { LONG_FORMAT } from '../../../../constants/dates';
import { Job } from '../../../../api/jobs/jobs.types';
import { RejectedStatus } from './components/rejected-status';

interface StatusDataCellProps extends Partial<Job> {
  hideTooltip?: boolean;
}

const StatusDataCell = (props: StatusDataCellProps) => {
  const { failedReason, hideTooltip, id, status } = props;

  const theme = useTheme();

  switch (status) {
    case jobStatuses.waiting: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.blue.t2}
          color={theme.colors.surfaces.blue.t1}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    case jobStatuses.completed: {
      return (
        <Badge color="green_400" icon="ui-control:check_mark" text={status} />
      );
    }

    case jobStatuses.rejected: {
      return (
        <RejectedStatus hideTooltip={hideTooltip} id={id} status={status} />
      );
    }

    case jobStatuses.failed: {
      const failureReasons = failedReason
        ? prepareFailureReasons(failedReason)
        : [];

      const showTooltip =
        !!failureReasons.length ||
        !!failedReason?.failedAt ||
        !!failedReason?.userId;

      return (
        <Flex alignItems="flex-start">
          <Badge
            borderColor={theme.colors.surfaces.alert.t2}
            color={theme.colors.surfaces.alert.t1}
            icon="alert:warning_triangle"
            text={status}
            textColor={theme.colors.typography.t1}
          />

          <Conditional condition={showTooltip}>
            <Tooltip
              Component={
                <Box horizontalPadding="1.6rem" verticalPadding="1rem">
                  {failureReasons.map(({ code, label }) => {
                    return <Typography key={code} color="alert" text={label} />;
                  })}
                  <Typography
                    display="inline"
                    text={formatDate(failedReason?.failedAt, LONG_FORMAT)}
                  />
                  <Conditional condition={!!failedReason.userId}>
                    <Typography display="inline" text=" by " />
                    <Link to={`/users/${failedReason.userId}`}>
                      <Typography
                        color="link"
                        decoration="underline"
                        display="inline"
                        text={failedReason.userName}
                      />
                    </Link>
                  </Conditional>
                </Box>
              }
              position="right"
            >
              <StyledFieldIconWrapper>
                <Icon name="alert:i_circle" top="3px" />
              </StyledFieldIconWrapper>
            </Tooltip>
          </Conditional>
        </Flex>
      );
    }

    case jobStatuses.cancelled:
    case locationStatuses.inactive:
      return (
        <Badge
          borderColor={theme.colors.surfaces.monochrome.t3}
          color={theme.colors.surfaces.monochrome.overlayLevel2}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );

    case jobStatuses.inProgress:
      return (
        <Badge
          borderColor={theme.colors.surfaces.warning.t2}
          color={theme.colors.surfaces.warning.t1}
          icon="time:clock"
          minWidth="10.2rem"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    case jobStatuses.ready:
      return (
        <Badge
          borderColor={theme.colors.surfaces.green.t2}
          color={theme.colors.surfaces.green.t1}
          icon="actions:thumbs_up"
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );

    case locationStatuses.active: {
      return (
        <Badge
          borderColor={theme.colors.surfaces.green.t2}
          color={theme.colors.surfaces.green.t1}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
    }

    default:
      return (
        <Badge
          borderColor={theme.colors.surfaces.alert.t2}
          color={theme.colors.surfaces.alert.t1}
          text={status}
          textColor={theme.colors.typography.t1}
        />
      );
  }
};

export default StatusDataCell;
