import { FC } from 'react';
import { ImageProps } from './image.types';

export const Image: FC<ImageProps> = ({ altText, isLogo, src }) => {
  const logoClassName = isLogo ? ' handout--image-placeholder-logo' : '';

  return src ? (
    <img alt={altText} src={src} />
  ) : (
    <div className={`handout--image-placeholder${logoClassName}`} />
  );
};
