import { atom } from 'recoil';
import { Job, PrintJob } from '../../../../../../api/jobs/jobs.types';
import { NextJob } from '../../../../../../api/jobs/routes/complete-job/complete-job.types';
import {
  CompletePrintQaChecklist,
  CompletePrintQaModalStep,
} from './complete-print-qa-modal.types';

export const atomKeyPrefix = 'PRINT_QA_MODAL';

export const printQcModalAtoms = {
  availablePrintJobs: atom<PrintJob[]>({
    default: [],
    key: `${atomKeyPrefix}-available-print-jobs`,
  }),
  checklist: atom<CompletePrintQaChecklist>({
    default: null,
    key: `${atomKeyPrefix}-checklist`,
  }),
  duplicatedJobs: atom<Job[]>({
    default: [],
    key: `${atomKeyPrefix}-duplicated-jobs`,
  }),
  isChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-checklist-reviewed`,
  }),
  isMachineInProgress: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-machine-in-progress`,
  }),
  nextJob: atom<NextJob>({
    default: null,
    key: `${atomKeyPrefix}-next-job`,
  }),
  rfidHandled: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-rfid-handled`,
  }),
  selectedPrintJob: atom<PrintJob>({
    default: null,
    key: `${atomKeyPrefix}-selected-print-job`,
  }),
  step: atom<CompletePrintQaModalStep>({
    default: 'CONFIRM_BEGIN',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
