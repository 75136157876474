import { media, Icon } from 'gantri-components';
import styled from 'styled-components';

export const StyledToggleIcon = styled(Icon)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&& {
    top: 0.2rem;
    cursor: pointer;

    ${media.greaterThan('md')`
      display: none
    `};
  }
`;
