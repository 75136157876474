import { JobStatus } from '../../../../../../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Stock } from '../../../../../../../../../../../../../api/stocks/stocks.types';
import { placeholderImageSrc } from '../../../../../../../../../../../../../constants/images';
import { maxFinishAttemptsBeforeRestart } from '../../../../../../../../../../../../../constants/jobs';
import { ChecklistItem } from '../../../../../../../../../common/job-checklist/job-checklist.types';
import { CompleteAssemblyModalPartChecklist } from '../../../../../../../complete-assembly-modal.types';

export const getPartChecklist = ({
  parts,
}: Stock): CompleteAssemblyModalPartChecklist => {
  const partChecklist: CompleteAssemblyModalPartChecklist =
    parts.reduce<CompleteAssemblyModalPartChecklist>((accumulator, part) => {
      const defaultStatusOptions: JobStatus[] = [
        'Pass',
        'Re-finish',
        'Re-start',
      ];
      const partReachedMaxFinishAttempts =
        part.finishAttempts >= maxFinishAttemptsBeforeRestart;
      const availableStatuses = defaultStatusOptions.filter((status) => {
        return partReachedMaxFinishAttempts ? status !== 'Re-finish' : true;
      });

      const partChecklistItem: ChecklistItem = {
        description: part.name,
        details: '',
        exampleImgSrc: part.thumbnail || '',
        label: part.name,
        showThumbnail: true,
        status: 'Pass',
        statusToggleDetails: partReachedMaxFinishAttempts
          ? `This part has been finished the maximum ${maxFinishAttemptsBeforeRestart} times.`
          : '',
        statuses: availableStatuses,
        thumbnailSrc: part.thumbnail || placeholderImageSrc,
      };
      const updatedPartChecklist: CompleteAssemblyModalPartChecklist = {
        ...accumulator,
        [part.id]: partChecklistItem,
      };

      return updatedPartChecklist;
    }, {});

  return partChecklist;
};
