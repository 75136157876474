import { useEffect, useState } from 'react';
import {
  Button,
  Cell,
  Flex,
  Grid,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { debounce } from 'lodash';
import { useHandleFetchDesign } from '../../hooks/fetch-design';
import { DesignHeading } from '../design-heading';
import { Messages } from '../messages';
import {
  StyledActions,
  StyledFullImage,
  StyledPdf,
  StyledPdfAnchor,
  StyledReviewItem,
} from './design-concept.styles';
import { useNotification } from '../../../../hooks/useNotification';
import { designsApi } from '../../../../api';
import { useInvalidateFetchDesignCache } from '../../../../api/designs/routes';

const InfoSection = ({ label, value }: { label: string; value: string }) => {
  return (
    <div>
      <Typography marginBottom="1rem" text={label} />
      <Typography color="t1" text={value} variant="p2" />
    </div>
  );
};

export const DesignConcept = () => {
  const { notifyAxiosError, onInterceptRequest } = useNotification();
  const { current, setCurrent } = useHandleFetchDesign();
  const reviewSteps = current?.reviewSteps;
  const [reviews, setReviews] = useState({});
  const [reviewSubmitted, setReviewSubmitted] = useState(false);

  const { invalidateFetchDesignCache } = useInvalidateFetchDesignCache();

  const fourthStep = current?.concept?.subSteps?.['4'];
  const fifthStep = current?.concept?.subSteps?.['5'];
  const sixthStep = current?.concept?.subSteps?.['6'];
  const images = fifthStep?.interiorUrls
    ?.filter((item) => {
      return !!item.fileUrl;
    })
    ?.map((uploadedFile) => {
      return {
        extension: uploadedFile.fileExtension,
        fileName: uploadedFile.fileName,
        url: uploadedFile.fileUrl,
      };
    });
  const rooms = fifthStep?.rooms?.join(', ');

  const formatSketchData = (uploadedFile) => {
    return {
      extension: uploadedFile.fileExtension,
      fileName: uploadedFile.fileName,
      url: uploadedFile.fileUrl,
    };
  };

  const sketchesData = [
    fourthStep?.sketches?.top
      ? formatSketchData(fourthStep?.sketches?.top?.uploadedFile)
      : undefined,
    fourthStep?.sketches?.front
      ? formatSketchData(fourthStep?.sketches?.front?.uploadedFile)
      : undefined,
    fourthStep?.sketches?.side
      ? formatSketchData(fourthStep?.sketches?.side?.uploadedFile)
      : undefined,
    fourthStep?.sketches?.model
      ? formatSketchData(fourthStep?.sketches?.model?.uploadedFile)
      : undefined,
  ].filter((data) => {
    return data;
  });

  const getOnInspirationItemUpdated = (
    reviewKey,
    position,
    approved: boolean,
    message?: string,
  ) => {
    return async () => {
      await onInterceptRequest(async () => {
        try {
          const temporal = { ...reviews };

          temporal[reviewKey].sections = temporal[reviewKey].sections.map(
            (item, idx) => {
              return idx === position ? { ...item, approved, message } : item;
            },
          );

          setReviews(temporal);

          await designsApi.updateDesignReview(current.id, {
            review: reviews,
            step: 'Concept',
          });
        } catch (error: unknown) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to update design review.',
          });
        }
      });
    };
  };

  const completeReview = async () => {
    await onInterceptRequest(async () => {
      try {
        setReviewSubmitted(true);
        await designsApi.completeDesignReview(current?.id, {
          review: reviews,
          step: 'Concept',
        });
        setReviewSubmitted(false);
        await invalidateFetchDesignCache();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to complete review.',
        });
      }
    });
  };

  const onReasonTextChange = (reviewKey, position) => {
    return async (message: string) => {
      await getOnInspirationItemUpdated(reviewKey, position, false, message)();
    };
  };

  useEffect(() => {
    if (
      (current &&
        current?.concept &&
        current?.concept.review &&
        current?.concept.review[1]) ||
      (reviewSteps && reviewSteps.concept)
    ) {
      setReviews(
        current?.concept &&
          current?.concept.review &&
          current?.concept.review[1]
          ? current?.concept.review
          : reviewSteps.concept,
      );
    }
  }, [current?.concept?.review, reviewSteps]);

  return current?.concept ? (
    <Grid columns="2fr 1fr" gap="3rem" paddingTop="s1">
      <Stack gap="12rem">
        <DesignHeading design={current} />

        <Stack gap="3rem">
          <Typography text="Description" textStyle="bold" variant="h2" />

          <InfoSection
            label="Describe the target user of your design"
            value={fifthStep.targetUser}
          />

          <InfoSection
            label="What is the user need or pain point that you’re designing for?"
            value={fifthStep.userNeeds}
          />

          <Cell>
            <Typography
              marginBottom="2rem"
              text="What kind of interior will your design fit into?"
            />

            <Grid columns={2} gap="3rem">
              {images.map((image) => {
                if (image) {
                  const { extension, fileName, url } = image;

                  if (extension === '.pdf') {
                    return (
                      <StyledPdfAnchor key={url} href={url}>
                        <StyledPdf>
                          <div className="pdf" />
                          <Typography
                            color="t1"
                            text={`${fileName}.pdf`}
                            variant="p2"
                          />
                        </StyledPdf>
                      </StyledPdfAnchor>
                    );
                  }

                  return (
                    <StyledPdfAnchor key={url} href={url}>
                      <StyledFullImage
                        style={{ backgroundImage: `url(${url})` }}
                      />
                    </StyledPdfAnchor>
                  );
                }
              })}
            </Grid>
            {images.length <= 0 && (
              <Typography
                color="t1"
                text="There are no interior images associated with this design."
                variant="p2"
              />
            )}
          </Cell>

          <InfoSection
            label="Which room(s) does your design belong?"
            value={rooms}
          />

          <Cell>
            <Typography marginBottom="2rem" text="Sketches" />

            <Grid columns={2} gap="3rem">
              {sketchesData.map((sketch) => {
                if (sketch) {
                  const { extension, fileName, url } = sketch;

                  if (extension === '.pdf') {
                    return (
                      <StyledPdfAnchor key={url} href={url}>
                        <StyledPdf>
                          <div className="pdf" />
                          <Typography
                            color="t1"
                            text={`${fileName}.pdf`}
                            variant="p2"
                          />
                        </StyledPdf>
                      </StyledPdfAnchor>
                    );
                  }

                  return (
                    <StyledPdfAnchor key={url} href={url}>
                      <StyledFullImage
                        style={{ backgroundImage: `url(${url})` }}
                      />
                    </StyledPdfAnchor>
                  );
                }
              })}
            </Grid>
            {sketchesData.length <= 0 && (
              <Typography
                color="t1"
                text="There are no sketches associated with this design."
                variant="p2"
              />
            )}
          </Cell>

          <InfoSection
            label="Please describe your design."
            value={fourthStep.describe}
          />

          <InfoSection
            label="What’s the purpose of your design?"
            value={fourthStep.purpose}
          />

          <InfoSection
            label="What’s the inspiration behind your design?"
            value={fourthStep.inspiration}
          />

          <InfoSection
            label="Is there anything that connects you to the inspiration?"
            value={fourthStep.inspirationConnect}
          />

          <InfoSection
            label="What’s unique about your design?"
            value={fourthStep.uniqueDesign}
          />

          <InfoSection
            label="How is your design used?"
            value={sixthStep.howDesignUsed}
          />

          <InfoSection
            label="When is your design used?"
            value={sixthStep.whenDesignUsed}
          />

          <InfoSection
            label="How does the user change the bulb?"
            value={sixthStep.changeBulb}
          />
        </Stack>

        {Object.keys(reviews || {}).map((reviewKey) => {
          return (
            <Cell key={reviewKey}>
              <Flex alignItems="center" justifyContent="space-between">
                <Typography
                  text={reviews[reviewKey].header}
                  textStyle="bold"
                  variant="h2"
                />
                <Typography text="Approve" variant="p2" />
              </Flex>

              <Stack gap="0" marginTop="3rem">
                {reviews[reviewKey].sections.map((item, position) => {
                  return (
                    <StyledReviewItem key={item.title}>
                      <Flex alignItems="center" justifyContent="space-between">
                        <div>
                          <Typography text={item.title} variant="p2" />
                          <Typography
                            color="t1"
                            text={item.description}
                            variant="p2"
                          />
                        </div>

                        <Grid columns={2} gap="1rem">
                          <Button
                            text="Yes"
                            variant={item.approved ? 'primary' : 'secondary'}
                            onClick={getOnInspirationItemUpdated(
                              reviewKey,
                              position,
                              true,
                            )}
                          />
                          <Button
                            text="No"
                            variant={
                              item.approved ? 'secondary' : 'primaryAlert'
                            }
                            onClick={getOnInspirationItemUpdated(
                              reviewKey,
                              position,
                              false,
                            )}
                          />
                        </Grid>
                      </Flex>
                      {!item.approved && (
                        <div>
                          <TextField
                            placeholder="Give a reason"
                            value={item.message}
                            onTextChange={debounce(
                              onReasonTextChange(reviewKey, position),
                              300,
                            )}
                          />
                        </div>
                      )}
                    </StyledReviewItem>
                  );
                })}
              </Stack>
            </Cell>
          );
        })}

        {current?.concept.status === 'Submitted' && (
          <StyledActions>
            <Button
              processing={reviewSubmitted}
              text="Complete Review"
              onClick={completeReview}
            />
          </StyledActions>
        )}

        <Cell />
      </Stack>
      <Cell>
        <Messages
          current={current}
          designId={current?.id}
          messages={current?.messages || []}
          notes={current?.notes || []}
          updateMessages={setCurrent}
        />
      </Cell>
    </Grid>
  ) : null;
};
