import { FC } from 'react';
import { Conditional } from 'gantri-components';
import { JobOrderIdCellProps } from './job-order-cell.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';

export const JobOrderCell: FC<JobOrderIdCellProps> = ({ row }) => {
  const { order } = row.original;

  return (
    <Conditional condition={!!order}>
      <StyledAnchor
        href={`${routePaths.orders}/${order?.id}`}
        text={`${order?.type} #${order?.id}`}
      />
    </Conditional>
  );
};
