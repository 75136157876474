import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import {
  FetchStockSummaryArgs,
  FetchStockSummaryResponse,
} from './fetch-stock-summary.types';

const query = (body: FetchStockSummaryArgs) => {
  return axios
    .post<FetchStockSummaryResponse>(
      `${getCoreApiUrl('stocks')}/stock-summary`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchStockSummary = getGenericQueryObj({
  groupName: 'stock-summary',
  query,
});
