import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { useFetchDesign } from '../../../../api/designs/routes';

export const useHandleFetchDesign = () => {
  const params = useParams<{ designId: string }>();
  const designId = Number(params.designId);

  const [current, setCurrent] = useState<Design>();

  useFetchDesign({
    enabled: !!designId,
    fetchArgs: {
      id: designId,
    },
    onSuccess: async ({ data, options }) => {
      setCurrent({ ...data, reviewSteps: options.reviewSteps });
    },
  });

  return { current, setCurrent };
};
