import { ColorDetails, ColorPicker } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { FilterRow } from '../../../../common/filter-grid';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { ColorFilterProps } from './color-filter.types';

export const ColorFilter = (props: ColorFilterProps) => {
  const { atom, default: defaultValue } = props;

  const [selectedColorCodes, setSelectedColorCodes] = useRecoilState(atom);

  const handleColorSelect = (selectedColorsDetails: ColorDetails[]) => {
    const newSelectedColorCodes = selectedColorsDetails
      ? selectedColorsDetails.map(({ code }) => {
          return code;
        })
      : [];

    setSelectedColorCodes(newSelectedColorCodes);
  };

  return (
    <FilterRow>
      <FilterResetLabel atom={atom} default={defaultValue} text="Colors" />
      <ColorPicker
        value={selectedColorCodes}
        onValueChange={handleColorSelect}
      />
    </FilterRow>
  );
};
