import { useRecoilState, useSetRecoilState } from 'recoil';
import { InventoryChecklistFooter as BaseInventoryChecklistFooter } from '../../../../../common/inventory-checklist-footer';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';

export const InventoryChecklistFooter = () => {
  const [job, setJob] = useRecoilState(completeAssemblyModalAtoms.job);
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    completeAssemblyModalAtoms.inventoryRequests,
  );
  const setUpdateOnClose = useSetRecoilState(
    completeAssemblyModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);

  return (
    <BaseInventoryChecklistFooter
      afterInventoryStep="JOB_CHECKLIST"
      inventoryRequests={inventoryRequests}
      job={job}
      setInventoryRequests={setInventoryRequests}
      setJob={setJob}
      setStep={setStep}
      setUpdateOnClose={setUpdateOnClose}
      size={fullScreenModalFooterButtonSize}
    />
  );
};
