import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { BulkUpdateArgs, BulkUpdateResponse } from './bulk-update.types';

export const bulkUpdate = (request: BulkUpdateArgs) => {
  return axios
    .put<BulkUpdateResponse>(`${coreApiUrl}/machines/bulk-update`, request)
    .then(({ data }) => {
      return data;
    });
};
