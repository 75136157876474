import axios from 'axios';
import { environment } from '../../../../environment';
import { ReadMessagesResponse } from './read-messages.types';

export const readMessages = (
  designId: number,
  data: { messagesIds: number[] },
) => {
  return axios.put<ReadMessagesResponse>(
    `${environment.API_URL}/designs/${designId}/read`,
    data,
  );
};
