import { atom } from 'recoil';

const atomKeyPrefix = 'PRINT_PREPARE_QUEUE_MODAL';

export const createPrintPrepareQueueModalAtoms = {
  availableJobs: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-available-jobs`,
  }),
};
