import axios from 'axios';
import { userApiUrl } from '../../../../helpers/auth';
import { FetchDesignArgs, FetchDesignResponse } from './fetch-design.types';

export const fetchDesign = ({ id }: FetchDesignArgs) => {
  return axios
    .get<FetchDesignResponse>(`${userApiUrl}/designs/${id}/get`)
    .then(({ data }) => {
      return data;
    });
};
