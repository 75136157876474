import { useEffect, useState } from 'react';
import { Conditional, Flex, Table, Typography } from 'gantri-components';
import { useGetDesignerStatsColumns } from './hooks/use-get-designer-stats-columns';
import { StatDataResponse, StatProps } from './stats.types';
import { useGetDesignerStats } from '../../../../api/designers/routes/get-designer-stats/get-designer-stats.query';

export const Stats = (props: StatProps) => {
  const { currentDesigner } = props;

  const [data, setData] = useState<StatDataResponse>(null);

  const designerId = currentDesigner ? currentDesigner.id : null;

  const { isLoading, ...response } = useGetDesignerStats({
    id: designerId,
  });

  useEffect(() => {
    if (!isLoading) {
      setData(response);
    }
  }, [isLoading]);

  const statsColumns = useGetDesignerStatsColumns({
    lifetimeStats: data?.stats?.lifetime,
  });

  return (
    <Conditional condition={!!data}>
      <Table
        columns={statsColumns}
        customAction={{
          Component: () => {
            return (
              <Flex alignItems="center" gap="4x" paddingBottom="4x">
                <Typography text={`Visits: ${data?.countAllVisits}`} />
                <Typography
                  text={`Unique Visits: ${data?.countUniqueVisits}`}
                />
              </Flex>
            );
          },
          position: 'bottom',
        }}
        data={data?.stats?.dates}
      />
    </Conditional>
  );
};
