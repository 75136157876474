import React, { FC } from 'react';
import { FormikInput, Grid } from 'gantri-components';
import { FormItem } from '../../../../inventory/components/form-item';
import { OverviewMachine } from '../../../../../api/machines/routes/get-overview';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';
import { ResetJobCount } from '../reset-job-count';

export const OverviewFormSection: FC<{
  currentMachine: OverviewMachine;
  isEditModeActive?: boolean;
}> = ({ currentMachine, isEditModeActive }) => {
  return (
    <Grid
      columns="1fr"
      gap="1.2rem"
      maxWidth={isEditModeActive ? '60rem' : 'unset'}
    >
      <FormItem
        isEditMode={isEditModeActive}
        label="Machine Name"
        orientation="row"
        viewElement={currentMachine.name}
      >
        <FormikInput name="name" required />
      </FormItem>

      <FormItem
        label="Created"
        orientation="row"
        viewElement={formatDate(currentMachine.createdAt, LONG_FORMAT)}
      />

      <FormItem
        label="Current Jobs"
        orientation="row"
        viewElement={String(currentMachine.currentJobsCount)}
      />

      <FormItem
        label="Past Jobs"
        orientation="row"
        viewElement={String(currentMachine.pastJobsCount)}
      />

      <FormItem
        isEditMode={isEditModeActive}
        label="Location"
        orientation="row"
        viewElement={currentMachine.location}
      >
        <FormikInput name="location" />
      </FormItem>

      <FormItem
        label="Prints on Build Plate"
        orientation="row"
        viewElement={<ResetJobCount currentMachine={currentMachine} />}
      />

      <FormItem
        label="Last repair"
        orientation="row"
        viewElement={
          formatDate(currentMachine.lastRepairInfo?.date, LONG_FORMAT) || '-'
        }
      />
    </Grid>
  );
};
