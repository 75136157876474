import { Icon } from 'gantri-components';
import Protected from '../../../../components/common/protected/protected';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { ViewBatchAnchorProps } from './view-batch-anchor.types';

export const ViewBatchAnchor = (props: ViewBatchAnchorProps) => {
  const { batchId, ...rest } = props;

  return (
    <Protected allowedFor={['Admin', 'Lead']}>
      <StyledAnchor
        icon={<Icon color="link" name="arrows:arrow_external" />}
        iconPosition="right"
        target="_blank"
        text="View Batch"
        to={`${routePaths.batches}/${batchId}`}
        {...rest}
      />
    </Protected>
  );
};
