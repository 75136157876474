import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { GetDashboardReportSummaryQueryArgs } from '../../dashboard.types';
import { getDashboardStocksSummary } from './get-dashboard-stocks-summary';

export const useFetchDashboardStocksSummary = (
  props: GetDashboardReportSummaryQueryArgs,
) => {
  const { args, configs, enabled } = props;

  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!args && enabled,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch dashboard stocks summary.',
        });
      },
      queryFn: () => {
        return getDashboardStocksSummary.query(args, configs);
      },
      queryKey: getDashboardStocksSummary.key(args, configs),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
