import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchAllVendorNamesResponse } from './fetch-all-vendor-names.types';
import { fetchAllVendorNames } from './fetch-all-vendor-names';

interface Props<TData>
  extends UseQueryProps<TData, FetchAllVendorNamesResponse> {
  query?: string;
}

export const useFetchAllVendorNamesQuery = (
  props?: Props<FetchAllVendorNamesResponse['vendors']>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<FetchAllVendorNamesResponse>({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch vendors.',
        });

        props?.onError?.(error);
      },
      queryFn: () => {
        return fetchAllVendorNames.query(props?.query);
      },
      queryKey: fetchAllVendorNames.key(props.query),
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    isLoading,
    vendors: data?.vendors ?? [],
  };
};

export const useInvalidateFetchAllVendorNamesCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateFetchAllVendorNamesCache = (search?: string) => {
    return invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!search
        ? key === fetchAllVendorNames.key(search)[0].key
        : groupKey === fetchAllVendorNames.groupKey();
    });
  };

  return { invalidateFetchAllVendorNamesCache };
};
