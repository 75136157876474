import { CellContext, ColumnDef } from '@tanstack/react-table';
import { CurrencyCell } from '../common/currency-cell';
import { NumberCell } from '../common/number-cell';
import { OrdersTableData } from './dashboard-orders-summary.types';

export const ordersTableColumns: ColumnDef<OrdersTableData>[] = [
  {
    accessorKey: 'type',
    header: 'Order type',
    size: 80,
  },
  {
    accessorKey: 'sales',
    cell: ({
      getValue,
    }: CellContext<OrdersTableData, OrdersTableData['sales']>) => {
      return <CurrencyCell isCents value={getValue()} />;
    },
    header: 'Sales $',
    size: 80,
  },
  {
    accessorKey: 'orders',
    cell: ({
      getValue,
    }: CellContext<OrdersTableData, OrdersTableData['orders']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Orders #',
    size: 80,
  },
  {
    accessorKey: 'refunds',
    cell: ({
      getValue,
    }: CellContext<OrdersTableData, OrdersTableData['refunds']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Refunds #',
    size: 80,
  },
  {
    accessorKey: 'lateOrders',
    cell: ({
      getValue,
    }: CellContext<OrdersTableData, OrdersTableData['lateOrders']>) => {
      return <NumberCell value={getValue()} />;
    },
    header: 'Late Orders #',
    size: 80,
  },
];
