import { MachineRecord } from '../../machines.types';
import { MachineType, machineTypes } from '../../../../constants/machines';

export const getIsCrealityMachine = ({ type }: Pick<MachineRecord, 'type'>) => {
  const crealityTypes: MachineType[] = [
    machineTypes.crealityHighTemp,
    machineTypes.crealityTranslucent,
  ];

  const isCrealityMachine = crealityTypes.some((crealityType) => {
    return crealityType === type;
  });

  return isCrealityMachine;
};
