import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';

export const optimizationRecords = [
  {
    key: 'assemblyAndMatingFeatures',
    label: 'Assembly and mating features',
  },
  {
    key: 'partitioningAndPrintOrientation',
    label: 'Partitioning and print orientation',
  },
  {
    key: 'lightTransmission',
    label: 'Light transmission',
  },
  {
    key: 'functionalComponentAccommodation',
    label: 'Functional component accommodation',
  },
  {
    key: 'overhangMitigation',
    label: 'Overhang mitigation',
  },
  {
    key: 'detailWork',
    label: 'Detail work',
  },
  {
    key: 'usability',
    label: 'Usability',
  },
  {
    key: 'gCodeSlicingSetting',
    label: 'GCode slicing settings',
  },
];

export const qualityRecords = [
  {
    key: 'assembly',
    label: 'Assembly (full assembly)',
  },
  {
    key: 'interaction',
    label: 'Interaction (evaluator accessing the bulb)',
  },
  {
    key: 'strength',
    label: 'Strength (product knocked over)',
  },
  {
    key: 'heat',
    label: 'Heat (product in heat chamber)',
  },
  {
    key: 'finishing',
    label: 'Finishing (first painted prototype)',
  },
  {
    key: 'light',
    label: 'Light (product illuminated)',
  },
  {
    key: 'shipping',
    label: 'Shipping (product packaged)',
  },
];

export const finalizationRecords = [
  {
    key: 'gatheringProductSpecs',
    label: 'Gathering product specs',
  },
  {
    key: 'finishedProductsInAllColorways',
    label: 'Finished products in all colorways',
  },
  {
    key: 'gCode',
    label: 'G-code',
  },
  {
    key: 'assemblyInstructions',
    label: 'Assembly instructions',
  },
];

export const initialEngineering: Design['engineering'] = {
  completedAt: null,
  finalization: {
    assemblyInstructions: false,
    completedAt: null,
    finishedProductsInAllColorways: false,
    finishedPrototypes: [],
    gCode: false,
    gatheringProductSpecs: false,
    stepProgress: 0,
  },
  modelingOptimization: {
    assemblyAndMatingFeatures: false,
    completedAt: null,
    detailWork: false,
    functionalComponentAccommodation: false,
    gCodeSlicingSetting: false,
    lightTransmission: false,
    overhangMitigation: false,
    partitioningAndPrintOrientation: false,
    stepProgress: 0,
    usability: false,
  },
  prototyping: {
    completedAt: null,
    images: [],
  },
  qualityTesting: {
    assembly: false,
    completedAt: null,
    finishing: false,
    heat: false,
    interaction: false,
    light: false,
    shipping: false,
    stepProgress: 0,
    strength: false,
  },
  status: 'In progress',
  stepProgress: 0,
};
