import { CloudinaryTransformations } from './generate-transformations-string.types';

export const generateTransformationsString = (
  transformations: CloudinaryTransformations,
): string => {
  const {
    aspectRatio,
    border,
    crop,
    dpr,
    flags,
    format,
    gravity,
    height,
    quality,
    round,
    width,
    x,
    y,
    zoom,
  } = transformations || {};
  const transformationsArray: string[] = [];

  if (aspectRatio) {
    let ratioValue = aspectRatio;

    const roundRatio = (aspectRatio: number) => {
      return Math.round(aspectRatio * 100) / 100;
    };

    if (typeof aspectRatio === 'string') {
      const [widthString, heightString] = aspectRatio.split(':');
      const width = Number(widthString);
      const height = Number(heightString);

      // convert ratios with decimal values to numbers.
      if (width !== Math.round(width) || height !== Math.round(height)) {
        ratioValue = roundRatio(width / height);
      }
    }

    if (typeof aspectRatio === 'number') {
      ratioValue = roundRatio(aspectRatio);
    }

    transformationsArray.push(`ar_${ratioValue}`);
  }

  if (border) {
    transformationsArray.push(`bo_${border}`);
  }

  if (crop) {
    transformationsArray.push(`c_${crop}`);
  }

  if (dpr) {
    transformationsArray.push(`dpr_${dpr}`);
  }

  if (flags) {
    const flag = flags.join('.');

    transformationsArray.push(`fl_${flag}`);
  }

  if (format) {
    transformationsArray.push(`f_${format}`);
  } else {
    transformationsArray.push('f_auto');
  }

  if (gravity) {
    transformationsArray.push(`g_${gravity}`);
  }

  if (height) {
    transformationsArray.push(`h_${height}`);
  }

  if (quality) {
    if (typeof quality === 'string') {
      transformationsArray.push(`q_auto:${quality}`);
    } else {
      transformationsArray.push(`q_${quality}`);
    }
  } else {
    transformationsArray.push('q_auto:best');
  }

  if (round) {
    transformationsArray.push(`r_${round}`);
  }

  if (width) {
    transformationsArray.push(`w_${width}`);
  }

  if (x) {
    transformationsArray.push(`x_${x}`);
  }

  if (y) {
    transformationsArray.push(`y_${y}`);
  }

  if (zoom) {
    transformationsArray.push(`z_${zoom}`);
  }

  return `${transformationsArray.join()}/`;
};
