import styled, { css } from 'styled-components';
import { modelIconSrc } from '../../../../constants/images';
import { cloudinaryUrl } from '../../../../helpers/images';

export const StyledAssemblyThumbnail = styled.span`
  ${({ theme }) => {
    return css`
      height: 6rem;
      width: 6rem;
      border: 1px solid ${theme.colors.dividers.t1};
      background-image: url(${cloudinaryUrl(modelIconSrc, {
        crop: 'limit',
        height: 30,
        width: 30,
      })});
      background-position: center;
      background-size: 3rem 3rem;
      background-repeat: no-repeat;
    `;
  }}
`;

export const StyledReviewItem = styled.div`
  ${({ theme }) => {
    return css`
      padding: 1rem 0;
      border-top: 1px solid ${theme.colors.dividers.t1};

      button,
      button:hover,
      button:focus {
        height: 3.6rem;
        width: 6.5rem;
        min-width: 6.5rem;
        max-width: 6.5rem;
      }
    `;
  }}
`;

export const StyledActions = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${theme.colors.dividers.t1};
      padding-top: 6rem;
      text-align: center;
    `;
  }}
`;
