import { UserActivity } from '../../../../../api/users/routes/get-user-activities/get-user-activities.types';
import routePaths from '../../../../../config/route-paths';
import { jobsDetailsPanelIdQueryKey } from '../../../../../components/jobs-details-panel-wrapper';
import { convertQueriesToString } from '../../../../../helpers/checks';

export const getLinkDetails = (
  item: UserActivity,
): {
  label: string;
  linkText: string;
  linkUrl: string;
} => {
  const { extra, id, type: label } = item;
  const linkText = `#${id}`;
  const baseLinkDetails = { label, linkText };

  switch (label) {
    case 'Auth':
      return {
        ...baseLinkDetails,
        label: extra?.method,
        linkUrl: '',
      };

    case 'Design':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.designs}/${id}`,
      };

    case 'Gift':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.giftCodes}/${id}`,
      };

    case 'Job': {
      const { machineId, repair, stockId } = extra || {};

      if (stockId) {
        return {
          label: 'Stock',
          linkText: `#${stockId}`,
          linkUrl: `${routePaths.stocks}/${stockId}`,
        };
      }

      if (repair && machineId) {
        const queries = convertQueriesToString({
          [jobsDetailsPanelIdQueryKey]: id,
          tab: 'repairs',
        });

        return {
          ...baseLinkDetails,
          label: 'Repair',
          linkUrl: `${routePaths.machines}/${machineId}${queries}`,
        };
      }

      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.jobs}/${id}`,
      };
    }

    case 'Machine':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.machines}/${id}`,
      };

    case 'Order':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.orders}/${id}`,
      };

    case 'Product':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.products}/${id}`,
      };

    case 'Stock':
      return {
        ...baseLinkDetails,
        linkUrl: `${routePaths.stocks}/${id}`,
      };

    default:
      return {
        label: '',
        linkText: '',
        linkUrl: '',
      };
  }
};
