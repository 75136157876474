import { useEffect, useMemo, useState } from 'react';
import { useModal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { UseBulkUpdateProps } from './use-handle-bulk-update-machines.types';
import { MachinesBulkAction } from '../../../../api/machines/routes/bulk-update/bulk-update.types';
import { machinesAtoms } from '../../machines.atoms';
import { ResetBuildPlateModal } from '../../components/reset-build-plate-modal';
import { UpdateMachineTypeModal } from '../../components/update-machine-type-modal';
import { SetMachinesOnlineModal } from '../../components/set-machines-online-modal';
import { MachineRecord } from '../../machines.types';
import { NewMachineIssuesModal } from '../../components/new-machine-issues-modal';
import { SetMachinesOfflineModal } from '../../components/set-machines-offline-modal';

export const useHandleBulkUpdateMachines = (props: UseBulkUpdateProps) => {
  const { onSuccess } = props;

  const machinesSelected = useRecoilValue(machinesAtoms.machinesSelected);

  const [machinesToUpdate, setMachinesToUpdate] = useState<MachineRecord[]>([]);

  const machineIdsToUpdate = useMemo(() => {
    return machinesToUpdate.map(({ id }) => {
      return id;
    });
  }, [machinesToUpdate]);

  const [showOfflineMachinesModal, hideOfflineMachinesModal] = useModal(() => {
    return (
      <SetMachinesOfflineModal
        machines={machinesToUpdate}
        onClose={hideOfflineMachinesModal}
        onSuccess={onSuccess}
      />
    );
  }, [machinesToUpdate]);

  const [showOnlineMachinesModal, hideOnlineMachinesModal] = useModal(() => {
    return (
      <SetMachinesOnlineModal
        machines={machinesToUpdate}
        onClose={hideOnlineMachinesModal}
        onSuccess={onSuccess}
      />
    );
  }, [machinesToUpdate]);

  const [showNewMachineIssuesModal, hideNewMachineIssuesModal] =
    useModal(() => {
      return (
        <NewMachineIssuesModal
          machines={machinesToUpdate}
          showBulkOptionsOnly
          onClose={hideNewMachineIssuesModal}
          onSuccess={onSuccess}
        />
      );
    }, [machinesToUpdate]);

  const [showResetBuildPlateModal, hideResetBuildPlateModal] = useModal(() => {
    return (
      <ResetBuildPlateModal
        machineIds={machineIdsToUpdate}
        onClose={hideResetBuildPlateModal}
        onSuccess={onSuccess}
      />
    );
  }, [machineIdsToUpdate]);

  const [showUpdateMachineTypeModal, hideUpdateMachineTypeModal] =
    useModal(() => {
      return (
        <UpdateMachineTypeModal
          machineIds={machineIdsToUpdate}
          onClose={hideUpdateMachineTypeModal}
          onSuccess={onSuccess}
        />
      );
    }, [machineIdsToUpdate]);

  const handleBulkUpdate = async (args: {
    action: MachinesBulkAction;
    singleMachine?: MachineRecord;
  }) => {
    const { action, singleMachine } = args;

    if (singleMachine) {
      setMachinesToUpdate([singleMachine]);
    }

    switch (action) {
      case 'Set offline':
        // see about getting a new modal added for this to match the "set online" modal
        return showOfflineMachinesModal();

      case 'Set online':
        // add new modal
        return showOnlineMachinesModal();

      case 'New issue':
        // add new modal
        return showNewMachineIssuesModal();

      case 'Reset build plate':
        return showResetBuildPlateModal();

      case 'Update type':
        return showUpdateMachineTypeModal();

      default:
        return undefined;
    }
  };

  useEffect(() => {
    setMachinesToUpdate(machinesSelected);
  }, [machinesSelected]);

  return { handleBulkUpdate };
};
