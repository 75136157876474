import { getFileUrl } from 'gantri-components';
import { FC } from 'react';
import { Image } from '../image';
import { Location } from '../location';
import { BackCardProps } from './back-card.types';

export const BackCard: FC<BackCardProps> = ({ designerInfo }) => {
  const { handout, logoUrl, name, userId } = designerInfo;
  const { backImage = '', nameIsVisible } = handout || {};

  const backImgSrc = getFileUrl<'designers'>({
    directory: 'designers',
    fileName: backImage,
    fileType: 'handouts',
    identifiers: {
      userId,
    },
  });

  const logoImgSrc = getFileUrl<'designers'>({
    directory: 'designers',
    fileName: logoUrl,
    fileType: 'logo',
    identifiers: {
      userId,
    },
  });

  return (
    <div className="handout--card-bleed-border handout--back-bleed-border">
      <div className="handout--card handout--card-back">
        <div className="handout--back-image-wrapper">
          <Image altText={name} src={backImgSrc} />
        </div>
        <table className="handout--back-bottom-content">
          <tbody>
            <tr>
              <td>
                <div className="handout--back-created-by">Created by</div>
                <div className="handout--back-logo-wrapper">
                  <div className="handout--logo-wrapper">
                    <Image altText={name} isLogo src={logoImgSrc} />
                  </div>
                </div>
                {nameIsVisible && (
                  <div className="handout--back-name-wrapper">
                    <p>
                      <strong>{name}</strong>
                    </p>
                  </div>
                )}
                <div className="handout--back-location-wrapper">
                  <Location designerInfo={designerInfo} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
