import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { UpdateDesignData } from './update-design-review.types';

export const updateDesignReview = (
  designId: number,
  data: UpdateDesignData,
) => {
  return axios.put(
    `${getCoreApiUrl('designs')}/${designId}/update-review`,
    data,
  );
};
