import { KeyboardEvent, useEffect, useState } from 'react';
import { Typography, TextField, Grid, Conditional } from 'gantri-components';
import { formatDate } from '../../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import {
  StyledActionsGrid,
  StyledEditContainer,
  StyledNoteStack,
} from './note.styles';
import { NoteProps } from './note.types';

export const Note = (props: NoteProps) => {
  const { data, onDelete, onEdit } = props;

  const [content, setContent] = useState('');
  const [editing, setEditing] = useState(false);

  const showEditing = () => {
    return setEditing(true);
  };

  const hideEditing = () => {
    return setEditing(false);
  };

  useEffect(() => {
    setContent(data.notes);
  }, [data.notes]);

  const saveNote = async () => {
    await onEdit(data.id, content);
    setEditing(false);
  };

  const deleteNote = async () => {
    await onDelete(data.id);
  };

  const onNoteKeyPress = async (event: KeyboardEvent<HTMLInputElement>) => {
    if (/enter/i.test(event.code)) {
      await saveNote();
    }
  };

  return (
    <StyledNoteStack gap="1rem" paddingBottom="1rem">
      <Grid columns="max-content 1fr" gap="1rem">
        <Typography text={data.name} textStyle="bold" />

        <Typography color="t2" text={formatDate(data.createdAt, LONG_FORMAT)} />
      </Grid>
      <Conditional
        condition={editing}
        Fallback={<Typography text={data.notes} />}
      >
        <StyledEditContainer>
          <TextField
            value={data.notes}
            onKeyDown={onNoteKeyPress}
            onTextChange={setContent}
          />
        </StyledEditContainer>
      </Conditional>

      <StyledActionsGrid
        columns="repeat(2, max-content)"
        gap="1rem"
        justifyContent="flex-end"
      >
        {editing && (
          <>
            <Typography color="link" text="Save" onClick={saveNote} />
            <Typography color="t1" text="Cancel" onClick={hideEditing} />
          </>
        )}

        {!editing && (
          <>
            <Typography color="link" text="Edit" onClick={showEditing} />
            <Typography color="alert" text="Delete" onClick={deleteNote} />
          </>
        )}
      </StyledActionsGrid>
    </StyledNoteStack>
  );
};
