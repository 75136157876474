import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { CompleteJobArgs, CompleteJobResponse } from './complete-job.types';
import { completeJob } from './complete-job';

export const useCompleteJob = <TransformedData = CompleteJobResponse>(
  props?: GenericMutateQueryProps<
    CompleteJobArgs,
    CompleteJobResponse,
    TransformedData
  >,
) => {
  const { onMutate: onCompleteJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to complete job.',
    mutationFn: completeJob,
    ...props,
  });

  return { ...rest, onCompleteJob };
};
