import {
  Conditional,
  DropdownChangeEvent,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import debounce from '../../../../../../../../../../helpers/debounce';
import { IssueCheckbox } from '../issue-checkbox';
import { RepairDetailsProps } from './repair-details.types';

export const RepairDetails = (props: RepairDetailsProps) => {
  const {
    fromCompleteStep,
    isDisabledIssue,
    notes,
    onClose,
    openIssues,
    repairType,
    selectedIssues,
    setNotes,
    setSelectedIssues,
  } = props;

  return (
    <>
      {!fromCompleteStep && repairType?.description && (
        <Stack gap="x">
          <Typography text="Description" variant="h5" />
          <Typography
            htmlText={repairType?.description.replace(/\n/g, '<br />')}
          />
        </Stack>
      )}
      <Stack gap="x">
        <Typography
          text={fromCompleteStep ? 'Issues addressed' : 'Issue(s) to Address'}
          variant="h5"
        />
        <Conditional
          condition={!!openIssues?.length}
          Fallback={
            <Typography
              color="alert"
              text="You must create a new machine issue before you can start a repair."
            />
          }
        >
          {openIssues.map((issue) => {
            return (
              <IssueCheckbox
                key={issue.id}
                isDisabledIssue={isDisabledIssue}
                issue={issue}
                selectedIssues={selectedIssues}
                setSelectedIssues={setSelectedIssues}
                onClose={onClose}
              />
            );
          })}
        </Conditional>
      </Stack>
      <Stack gap="x">
        <Typography text="Notes" variant="h5" />
        <TextArea
          placeholder="Describe how the repair will be completed"
          style={{ resize: 'vertical' }}
          value={notes}
          onChange={debounce(
            (event: DropdownChangeEvent<{ value: string }>) => {
              const notes = event.target.value;

              setNotes(notes);
            },
            300,
          )}
        />
      </Stack>
    </>
  );
};
