import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  a,
  a:visited,
  a:visited,
  a > p {
    color: ${(props) => {
      return props.theme.colors.typography.link;
    }};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
