export const placeholderImageSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646427278/static-assets/part-large_f1q2qr.png';

export const modelIconSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646685436/static-assets/3d-design_2x_nvfiwh.png';

export const bubbleIconSmallSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646670006/static-assets/icon-bubble_2x_egiqwa.png';

export const bubbleIconLargeSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646670095/static-assets/message-large_2x_izv7mw.png';

export const notesIconSmallSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646670029/static-assets/notes_2x_l9x75u.png';

export const notesIconLargeSrc =
  'https://res.cloudinary.com/gantri/image/upload/v1646670052/static-assets/icon-notes_2x_est8yl.png';
