import { Button, Grid, Table } from 'gantri-components';
import { CSVLink } from 'react-csv';
import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { InventoriesFilter } from '../../components/dropdowns';
import routePaths from '../../config/route-paths';
import {
  useInventoryCheckoutModal,
  useInventoryMigrationModal,
} from './modals';
import { PageWithTable } from '../../components/layout';
import { getDownloadData } from './helpers/get-download-data';
import { formatDate } from '../../helpers/formatter';
import { DOWNLOAD_MEDIUM_FORMAT } from '../../constants/dates';
import { downloadHeaders, getRowProps } from './inventories.constants';
import { useGetInventoriesColumns } from './hooks/use-get-inventories-columns';
import { StyledLink, StyledLinkContainer } from './inventories.styles';
import { useNewInventoryModal } from './components/new-inventory/use-new-inventory-modal';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import {
  FetchPaginatedInventoriesArgs,
  InventoriesSortingField,
  PaginatedInventory,
} from '../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { useNotification } from '../../hooks/useNotification';
import {
  inventoriesFiltersDefaults,
  inventoriesPageAtoms,
  pageName,
} from '../../components/dropdowns/inventories-filter/inventories-filters.atoms';
import { inventoriesApi } from '../../api';
import { useRouter, useSpinner } from '../../hooks';
import { useInvalidateInventoriesQueryCache } from '../../api/inventories/routes/queries-cache';
import { useSyncPropertyWithQueryString } from '../../hooks/useSyncPropertyWithQueryString';
import { useMoreMenu } from '../../hooks/use-more-menu';
import Protected from '../../components/common/protected/protected';
import { MoreMenuToggleOption } from '../../hooks/use-more-menu/components/more-menu-toggle/more-menu-toggle.types';

export const Inventories = () => {
  const { currentValue: locationIdQuery, updateQueryString } =
    useSyncPropertyWithQueryString(
      'location',
      inventoriesFiltersDefaults.filters.locationId,
    );

  const setLocationId = useSetRecoilState(
    inventoriesPageAtoms.filters.locationId,
  );
  const { navigate } = useRouter();
  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();
  const { invalidateAllInventoriesCache } =
    useInvalidateInventoriesQueryCache();

  const [showNewInventoryModal] = useNewInventoryModal({
    onSuccess: async () => {
      await invalidateAllInventoriesCache();
    },
  });

  const fetchPaginatedInventories = async (
    args: FiltersFetchRequestArgs<
      FetchPaginatedInventoriesArgs,
      InventoriesSortingField
    >,
  ) => {
    await onInterceptRequest(async () => {
      try {
        // added to prevent multiple fetch requests from the useTableFilters dependency changes
        if (!locationIdQuery) {
          const { data } = await inventoriesApi.fetchPaginatedInventories(args);

          setRecords(data.inventories);
          setTotalCount(data.resultsCount);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventories.',
        });
      }
    });
  };

  const {
    filtersProps,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedInventory,
    FetchPaginatedInventoriesArgs,
    InventoriesSortingField
  >(
    {
      fetchRequest: fetchPaginatedInventories,
      filtersContent: <InventoriesFilter />,
      modalFiltersDetails: [
        {
          atom: inventoriesPageAtoms.filters.active,
          defaultValue: inventoriesFiltersDefaults.filters.active,
          payloadKey: 'active',
        },
        {
          atom: inventoriesPageAtoms.filters.discarded,
          defaultValue: inventoriesFiltersDefaults.filters.discarded,
          payloadKey: 'discarded',
        },
        {
          atom: inventoriesPageAtoms.filters.product,
          defaultValue: inventoriesFiltersDefaults.filters.product,
          payloadKey: 'product',
        },
        {
          atom: inventoriesPageAtoms.filters.stockStatus,
          defaultValue: inventoriesFiltersDefaults.filters.stockStatus,
          payloadKey: 'stockStatus',
        },
        {
          atom: inventoriesPageAtoms.filters.types,
          defaultValue: inventoriesFiltersDefaults.filters.types,
          payloadKey: 'types',
        },
        {
          atom: inventoriesPageAtoms.filters.vendor,
          defaultValue: inventoriesFiltersDefaults.filters.vendor,
          payloadKey: 'vendor',
        },
        {
          atom: inventoriesPageAtoms.filters.locationId,
          defaultValue: inventoriesFiltersDefaults.filters.locationId,
          payloadKey: 'locationId',
        },
      ],
      pageName,
      searchFilterDetails: {
        atom: inventoriesPageAtoms.search,
      },
      sortByFilterDetails: {
        atom: inventoriesPageAtoms.sortBy,
        defaultValue: inventoriesFiltersDefaults.sortBy,
      },
      sortOptions: [
        { bidirectional: true, label: 'Name', sortingField: 'name' },
        { bidirectional: true, label: 'Type', sortingField: 'type' },
        {
          bidirectional: true,
          label: '% Discarded',
          sortingField: 'discarded',
        },
      ],
    },
    [locationIdQuery],
  );

  const columns = useGetInventoriesColumns({ records, setRecords });

  const { showModal: showInventoryMigration } = useInventoryMigrationModal({
    onSuccess: async () => {
      await invalidateAllInventoriesCache();
      await filtersProps.onReset();
    },
  });

  const { onShowInventoryCheckout } = useInventoryCheckoutModal();

  const downloadData = getDownloadData(records);

  const headerMenuOptions: MoreMenuToggleOption[] = [
    {
      enabled: true,
      name: 'Inventory Purchases',
      onOptionClick: () => {
        return navigate(routePaths.inventoryPurchases);
      },
    },
    {
      allowedFor: ['Admin'],
      enabled: true,
      name: 'Migrate Inventory',
      onOptionClick: showInventoryMigration,
    },
    {
      Component: () => {
        return (
          <StyledLinkContainer>
            <CSVLink
              data={downloadData}
              filename={`Inventories-${formatDate(
                new Date(),
                DOWNLOAD_MEDIUM_FORMAT,
              )}.csv`}
              headers={downloadHeaders}
            >
              <StyledLink
                horizontalPadding="0"
                text="Download Inventory Data"
                verticalPadding="0.7rem"
              />
            </CSVLink>
          </StyledLinkContainer>
        );
      },
      allowedFor: ['Admin'],
      enabled: true,
      onOptionClick: () => {
        // intentionally empty. Behavior handled in Component
      },
    },
  ];

  const { MoreMenu } = useMoreMenu({
    options: headerMenuOptions,
  });

  useEffect(() => {
    if (locationIdQuery) {
      setLocationId(locationIdQuery);
      updateQueryString(undefined);
    }
  }, []);

  return (
    <PageWithTable pageTitle="Inventories">
      <PageWithTable.Header title="Inventories">
        <Protected allowedFor={['Admin', 'Lead']}>
          <Grid alignItems="center" columns="repeat(3, max-content)" gap="1rem">
            <Button
              text="Inventory Check-out"
              variant="secondary"
              onClick={onShowInventoryCheckout}
            />

            <Button text="New Inventory" onClick={showNewInventoryModal} />

            <MoreMenu />
          </Grid>
        </Protected>
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={getRowProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
