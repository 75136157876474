import { useInvalidateFetchPaginatedJobsCache } from '../../get-paginated-jobs/get-paginated-jobs.query';
import { useInvalidateFetchPaginatedJobsCountCache } from '../../get-paginated-jobs-count/get-paginated-jobs-count.query';
import { useInvalidateFetchJobDetailsCache } from '../../get-job-details/get-job-details.query';

export const useInvalidateJobsFetchCaches = () => {
  const { invalidateFetchJobDetailsCache } =
    useInvalidateFetchJobDetailsCache();
  const { invalidateFetchPaginatedJobsCache } =
    useInvalidateFetchPaginatedJobsCache();
  const { invalidateFetchPaginatedJobsCountCache } =
    useInvalidateFetchPaginatedJobsCountCache();

  return {
    /** Invalidates the job details and paginated jobs caches. */
    invalidateJobCaches: () => {
      return Promise.all([
        invalidateFetchJobDetailsCache(),
        invalidateFetchPaginatedJobsCache(),
        invalidateFetchPaginatedJobsCountCache(),
      ]);
    },
  };
};
