import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { UpdateInventoryArgs } from './update-inventory.types';

export const updateInventory = (
  props: UpdateInventoryArgs,
): Promise<{ inventory: any }> => {
  const { id, locations = [], ...data } = props;

  return axios({
    data: {
      ...data,
      locations: locations.filter(Boolean),
    },
    method: 'put',
    url: `${coreApiUrl}/inventory/${id}`,
  }).then((response) => {
    return response.data;
  });
};
