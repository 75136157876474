import { CSSProperties, FC, PropsWithChildren } from 'react';
import { flexContainerDefaultProps } from './flex-container.presets';
import { FlexContainerProps } from './flex-container.props';

/**
 * @deprecated use equivalent from gantri-components (https://components.gantri.com/?path=/story/containers-flex--flex)
 */
export const FlexContainer: FC<PropsWithChildren<FlexContainerProps>> = ({
  alignItems,
  children,
  className,
  direction,
  fullWidth,
  justifyContent,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  onClick,
  padding,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  style,
  title,
  wrap,
}) => {
  const styleProps: CSSProperties = {
    alignItems,
    display: 'flex',
    flexDirection: direction,
    justifyContent,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    ...style,
  };

  if (fullWidth) {
    styleProps.width = '100%';
  }

  if (wrap) {
    styleProps.flexWrap = 'wrap';
  }

  return (
    <div
      className={className}
      style={styleProps}
      title={title}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

FlexContainer.defaultProps = flexContainerDefaultProps;
