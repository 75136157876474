import {
  Cell,
  Conditional,
  Flex,
  Grid,
  Icon,
  useModal,
} from 'gantri-components';
import { PropsWithChildren } from 'react';
import { LeavePageConfirmation } from '../../../../../../components/routes';
import { ExitWarningModal } from '../../../../../../components/common/exit-warning-modal';
import { FullScreenModalHeaderProps } from './full-screen-modal-header.types';
import { ExitWarningModalProps } from '../../../../../../components/common/exit-warning-modal/exit-warning-modal.types';
import { OverflowEllipsisTypography } from '../../../../../../components/common/overflow-ellipsis-typography';

export const FullScreenModalHeader = (
  props: PropsWithChildren<FullScreenModalHeaderProps>,
) => {
  const {
    ModalContent,
    children,
    disableLeavePageConfirmation,
    exitModalHeaderText,
    handleCloseModal,
    job,
    text,
  } = props;

  const headingText = text || [job?.step, job?.type].filter(Boolean).join(' ');
  const exitModalHeader = exitModalHeaderText || `Exit ${headingText}`;
  const message = `Are you sure you want to exit ${
    exitModalHeaderText || headingText
  }?`;

  const [showExitWarningModal] = useFullScreenExitWarningModal({
    children: ModalContent,
    header: exitModalHeader,
    message,
    onConfirm: handleCloseModal,
  });

  const onExitClick = async () => {
    if (disableLeavePageConfirmation) {
      await handleCloseModal();
    } else {
      showExitWarningModal();
    }
  };

  return (
    <>
      <LeavePageConfirmation
        disabled={disableLeavePageConfirmation}
        header={exitModalHeader}
        message={message}
        onConfirm={handleCloseModal}
      >
        {ModalContent}
      </LeavePageConfirmation>
      <Grid
        alignItems="center"
        columns={children && { lg: '2fr 3fr 2fr', md: '1fr max-content' }}
        gap="2rem"
      >
        <Cell row={1}>
          <OverflowEllipsisTypography text={headingText} variant="h4" />
        </Cell>
        <Conditional condition={!!children}>
          <Cell row={{ lg: 1, md: 2 }} width={{ lg: 1, md: 2 }}>
            {children}
          </Cell>
        </Conditional>
        <Cell row={1}>
          <Flex justifyContent="flex-end">
            <Icon
              color="t1"
              cursor="pointer"
              name="ui-control:x"
              size="1.6rem"
              onClick={onExitClick}
            />
          </Flex>
        </Cell>
      </Grid>
    </>
  );
};

export const useFullScreenExitWarningModal = (
  props: Omit<ExitWarningModalProps, 'onClose'>,
) => {
  const { children, header, message, onConfirm } = props;

  const [showExitWarningModal, hideExitWarningModal] = useModal(() => {
    return (
      <ExitWarningModal
        header={header}
        message={message}
        onClose={hideExitWarningModal}
        onConfirm={onConfirm}
      >
        {children}
      </ExitWarningModal>
    );
  }, [onConfirm]);

  return [showExitWarningModal, hideExitWarningModal] as const;
};
