import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getActiveQueries } from '../helpers/checks';

interface OnReplaceSearchParams {
  /** Query names to preserve in URL when value of `property` is updated. If `true`, all properties are updated. Defaults to true. */
  preserve?: boolean | string[];
  property: string;
  value: string | number;
}

export const useRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const getSearchValueByProperty = (property: string): string => {
    const params = new URLSearchParams(location.search);

    return params.get(property);
  };

  const onReplaceSearch = (params: OnReplaceSearchParams) => {
    const { preserve = true, property, value } = params;
    const preserveIsArray = Array.isArray(preserve);
    const queriesToPreserve = preserveIsArray
      ? preserve
      : Object.keys(getActiveQueries()).filter((query) => {
          return preserve && query !== property;
        });
    const searchParams = { [property]: String(value ?? '') };

    if (queriesToPreserve?.length) {
      const params = new URLSearchParams(location.search);

      queriesToPreserve.forEach((variable) => {
        if (!params.get(variable)) {
          return;
        }

        searchParams[variable] = params.get(variable);
      });
    }

    const hasSearchParams =
      !!Object.values(searchParams).filter(Boolean).length;

    navigate(
      {
        search: hasSearchParams
          ? `?${createSearchParams(searchParams).toString()}`
          : '',
      },
      { replace: true },
    );
  };

  return {
    getSearchValueByProperty,
    location,
    navigate,
    onReplaceSearch,
    params,
  };
};
