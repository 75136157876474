import {
  generateSkus,
  getSkuDetails,
  ProductColorCode,
  getColorsByProduct,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { NoneOptionCode } from '../../../../../../../../../../../../constants/options';
import {
  convertWhitespace,
  useGetBulkUploaderPatterns,
} from '../use-get-bulk-uploader-patterns';
import { useGetFileType } from '../use-get-file-type';
import { ProductSkuLevelFileType } from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const useProcessFileNameAndReturnMatchingSkus = () => {
  const product = useRecoilValue(productAtoms.product);
  const getFileType = useGetFileType();
  const { paintedColorsRegExp, productIdOrNameRegExp, variantPatternStrings } =
    useGetBulkUploaderPatterns();

  const processFileNameAndReturnMatchingSkus = (props: {
    fileExtension: string;
    fileName: string;
  }): { fileType: ProductSkuLevelFileType; skus: string[] } => {
    const { fileExtension, fileName } = props;

    // VALIDATE FILE TYPE (defaults to general product photo)
    const fileType = getFileType({ fileExtension, fileName });

    const invalidReturnValue = { fileType, skus: [] };

    // VALIDATE PRODUCT ID/NAME
    const isProductValid = productIdOrNameRegExp.test(fileName);

    if (!isProductValid) {
      return invalidReturnValue;
    }

    // VALIDATE COLOR CODE
    const colorCodes: (ProductColorCode | NoneOptionCode)[] = [];

    if (product.isPainted) {
      const colorMatches = fileName.match(paintedColorsRegExp);

      if (!colorMatches) {
        return invalidReturnValue;
      }

      const [_completeColorMatch, colorMatch] = colorMatches;

      const validColors = getColorsByProduct({
        allowTradeColors: true,
        isPainted: product.isPainted,
        productId: product.id,
      });

      const colorCode = validColors.find(({ code, shortColorName }) => {
        const codePattern = new RegExp(`^${code}$`, 'i');
        const shortColorNamePattern = new RegExp(
          `^${convertWhitespace(shortColorName)}$`,
          'i',
        );

        return (
          codePattern.test(colorMatch) || shortColorNamePattern.test(colorMatch)
        );
      })?.code;

      if (colorCode) {
        colorCodes.push(colorCode);
      } else {
        return invalidReturnValue;
      }
    } else {
      colorCodes.push('0');
    }

    const variantCodes: string[][] = [];

    // VALIDATE VARIANTS
    if (product?.selectors?.length) {
      variantPatternStrings.forEach((variantPatternString, index) => {
        const externalVariantPattern = new RegExp(variantPatternString, 'gi');

        const variantPartialCodeMatches = Array.from(
          fileName.matchAll(externalVariantPattern),
          (matchData) => {
            const filteredMatches = matchData.filter((match, index) => {
              // remove complete match and empty group matches
              return index > 0 && !!match;
            });

            return filteredMatches[0];
          },
        ).map((matchValue) => {
          let codeMatch = matchValue;

          product.selectors[index].forEach((selector) => {
            const matchingOption = selector.options.find(({ code, name }) => {
              const matchPattern = new RegExp(matchValue, 'i');

              return matchPattern.test(code) || matchPattern.test(name);
            });

            if (matchingOption) {
              codeMatch = matchingOption.code;
            }
          });

          return codeMatch;
        });

        const matchingFullVariantCodes = product.skuPartialCodes[index]
          .filter(({ partialCodes }) => {
            return variantPartialCodeMatches.every((code) => {
              return partialCodes.some((partial) => {
                return partial === code;
              });
            });
          })
          .map(({ code }) => {
            return code;
          });

        variantCodes.push(matchingFullVariantCodes);
      });
    }

    const { productId, size: sizeCode } = getSkuDetails({
      sku: product.skus[0],
      skuPartialCodes: product.skuPartialCodes,
    });

    const skus = generateSkus({
      colors: colorCodes,
      productId,
      size: sizeCode,
      variants: variantCodes,
    });

    return { fileType, skus };
  };

  return processFileNameAndReturnMatchingSkus;
};
