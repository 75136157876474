import { useInvalidateFetchAllInventoriesCache } from './fetch-all';
import { useInvalidateFetchAllInventoriesForJobsCache } from './fetch-all-for-jobs';
import { useInvalidateFetchAllInventoriesForStockCache } from './fetch-all-for-stock';

export const useInvalidateInventoriesQueryCache = () => {
  const { invalidateFetchAllInventoriesCache } =
    useInvalidateFetchAllInventoriesCache();

  const { invalidateFetchAllInventoriesJobsCache } =
    useInvalidateFetchAllInventoriesForJobsCache();

  const { invalidateFetchAllInventoriesForStockCache } =
    useInvalidateFetchAllInventoriesForStockCache();

  const invalidateAllInventoriesCache = async () => {
    await Promise.all([
      invalidateFetchAllInventoriesCache(),
      invalidateFetchAllInventoriesJobsCache(),
      invalidateFetchAllInventoriesForStockCache(),
    ]);
  };

  return {
    invalidateAllInventoriesCache,
    invalidateFetchAllInventoriesCache,
    invalidateFetchAllInventoriesForStockCache,
    invalidateFetchAllInventoriesJobsCache,
  };
};
