import { FC, useState } from 'react';
import {
  Button,
  Cell,
  CheckboxList,
  Flex,
  TextArea,
  Typography,
} from 'gantri-components';
import { CreationSection, UploadResaleCertificate } from './components';
import { StyledPageSection } from '../../../components/layout/page-layout-styles';
import {
  StyledNotesWrapper,
  StyledSummaryFieldLabelWrapper,
  StyledSummaryFieldValueWrapper,
  StyledSummarySpacer,
  StyledUserSummaryBorder,
  StyledUserSummarySectionContainer,
} from '../shared.styles';
import SubsectionTitle from '../../../components/layout/subsection-title';
import { Creation } from '../../../api/users/users.types';
import { useInvalidateFetchPaginatedUsersCache } from '../../../api/users/routes';
import { UserDetails } from '../user.types';
import { usersApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

const emailSettings = [
  {
    id: '1',
    label: 'News and updates',
  },
  {
    id: '2',
    label: 'Weekly sales report',
  },
  {
    id: '3',
    label: 'Quarterly sales report',
  },
];

const Account: FC<{ currentUser: any; updateUser: (data) => void }> = ({
  currentUser,
  updateUser,
}) => {
  const { notify, notifyAxiosError } = useNotification();

  const [updating, setUpdating] = useState(false);
  const [updatingMain, setUpdatingMain] = useState(false);
  const invalidateUsersCache = useInvalidateFetchPaginatedUsersCache();

  const {
    accountCreationDetails,
    accountType,
    email,
    facebookConnected,
    firstName,
    googleConnected,
    howHeardAboutUs,
    isActive,
    lastName,
    notes,
    resaleCertificate,
    subscriber,
    userId,
  } = currentUser;

  const onUpdateCurrentUser = async (userData: UserDetails) => {
    updateUser(userData);
    await invalidateUsersCache();
  };

  const updateEmailPreferences = async () => {
    setUpdating(true);

    try {
      const { data } = await usersApi.updateSubscription({
        subscriber,
        userId,
      });

      notify('Email preferences successfully updated.');

      await onUpdateCurrentUser({
        ...currentUser,
        subscriber: data.account.subscriber,
      });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update user email preferences.',
      });
    } finally {
      setUpdating(false);
    }
  };

  const handleUpdateClick = async () => {
    setUpdatingMain(true);

    try {
      const { data } = await usersApi.updateUser({
        notes,
        userId,
      });

      notify(data.notice);

      const { user } = data;

      await onUpdateCurrentUser({
        ...currentUser,
        notes: user.account.notes,
      });
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to update user.' });
    } finally {
      setUpdatingMain(false);
    }
  };

  const updateUserNotes = async (e) => {
    await onUpdateCurrentUser({
      ...currentUser,
      notes: e.target.value,
    });
  };

  const brandRecognition = howHeardAboutUs.map((name) => {
    return <Typography key={name} color="t2" text={name} />;
  });

  const onEmailPreferencesChange = async (values: string[]): Promise<void> => {
    const updatedSubscriber = emailSettings.reduce<Record<string, boolean>>(
      (accumulator, { id }) => {
        const isChecked = values.some((item) => {
          return item === id;
        });

        return { ...accumulator, [id]: isChecked };
      },
      {},
    );

    await onUpdateCurrentUser({
      ...currentUser,
      subscriber: updatedSubscriber,
    });
  };

  const generalSection = (
    <StyledUserSummaryBorder>
      <StyledUserSummarySectionContainer>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="First Name" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={firstName} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Last Name" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={lastName} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Email" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={email} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Type" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={accountType} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Active" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={isActive ? 'Yes' : 'No'} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <StyledSummarySpacer />
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Google" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={googleConnected ? 'Yes' : 'No'} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Facebook" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Typography color="t1" text={facebookConnected ? 'Yes' : 'No'} />
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <StyledSummarySpacer />
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Resale Certificate" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <Cell justifyItems="start">
              <UploadResaleCertificate
                currentUser={currentUser}
                resaleCertificate={resaleCertificate}
                updateUser={updateUser}
                userId={userId}
              />
            </Cell>
          </StyledSummaryFieldValueWrapper>
        </Flex>
      </StyledUserSummarySectionContainer>
      <StyledUserSummarySectionContainer>
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="How you heard about us" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            {brandRecognition}
          </StyledSummaryFieldValueWrapper>
        </Flex>
        <StyledSummarySpacer />
        <Flex width="100%" wrap="wrap">
          <StyledSummaryFieldLabelWrapper>
            <Typography text="Email preferences" />
          </StyledSummaryFieldLabelWrapper>
          <StyledSummaryFieldValueWrapper>
            <CheckboxList
              idProperty="id"
              items={emailSettings}
              values={Object.keys(subscriber).filter((key) => {
                return subscriber[key];
              })}
              onValueChange={onEmailPreferencesChange}
            />
            <StyledSummarySpacer />
            <Button
              processing={updating}
              text="Update Preferences"
              variant="ghost"
              onClick={updateEmailPreferences}
            />
          </StyledSummaryFieldValueWrapper>
        </Flex>
      </StyledUserSummarySectionContainer>
    </StyledUserSummaryBorder>
  );

  return !userId ? null : (
    <>
      <StyledPageSection>
        <SubsectionTitle>
          <Typography text="General" textStyle="bold" variant="h4" />
        </SubsectionTitle>
        {generalSection}
      </StyledPageSection>
      <StyledPageSection>
        <SubsectionTitle>
          <Typography text="Creation" textStyle="bold" variant="h4" />
        </SubsectionTitle>

        <CreationSection
          accountCreationDetails={accountCreationDetails as Creation}
        />
      </StyledPageSection>
      <StyledPageSection>
        <SubsectionTitle>
          <Typography text="Notes" textStyle="bold" variant="h4" />
        </SubsectionTitle>
        <StyledNotesWrapper>
          <TextArea
            ariaLabel="account-notes"
            minRows={3}
            name="account-notes"
            placeholder="Some random note about the user"
            value={notes || ''}
            onChange={updateUserNotes}
          />
        </StyledNotesWrapper>
        <Button
          data-update-notes-button
          processing={updatingMain}
          text="Update"
          onClick={handleUpdateClick}
        />
      </StyledPageSection>
    </>
  );
};

export default Account;
