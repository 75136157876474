import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  ToggleActiveAccountArgs,
  ToggleActiveAccountResponse,
} from './toggle-active-account.types';

export const toggleActiveAccount = ({
  active,
  userId,
}: ToggleActiveAccountArgs) => {
  return axios.put<ToggleActiveAccountResponse>(
    `${baseApiUrl}/activate-user/${userId}`,
    { active },
  );
};
