import { FC, useRef, useState } from 'react';
import { Icon } from 'gantri-components';
import DropdownWrapper from '../../dropdowns/dropdown-wrapper';
import { zIndexes } from '../../../constants/styles';
import Protected from '../protected/protected';
import { StyledMoreMenuOption, StyledMoreMenuOverlay } from './menu.styles';
import { MenuOption, MenuProps } from './menu.props';

export const Menu: FC<MenuProps> = ({ data, menuDropPadding = 0, options }) => {
  const [dropDownPosition, setDropDownPosition] = useState({
    left: 'unset',
    position: 'fixed',
    right: '0',
    top: '0',
    zIndex: zIndexes.moreMenu,
  });

  const [collapsed, setCollapsed] = useState(true);
  const menuRef = useRef(null);

  const calculateOptionCount = (choices: MenuOption<any>[]) => {
    return choices.filter((choice) => {
      return choice;
    }).length;
  };

  const toggleMenu = (e) => {
    e.stopPropagation();

    const { current } = menuRef;
    const optionsCount = calculateOptionCount(options);
    const rect = current.getBoundingClientRect();
    const documentRect = document.body.getBoundingClientRect();

    let bottomPosition = 0;
    let rightPosition = documentRect.width - rect.right;
    let top = `${rect.top + 23 + menuDropPadding}px`;

    const dropDownHeight = optionsCount * 38;
    const rectBottom = optionsCount * 43;

    if (rect.top + rectBottom + 10 > documentRect.height) {
      rightPosition = documentRect.width - rect.right + 25;
      bottomPosition = -dropDownHeight + 1 + (documentRect.height - rect.top);
      top = `${rect.top + menuDropPadding + bottomPosition}px`;
    }

    setDropDownPosition({
      ...dropDownPosition,
      right: `${rightPosition}px`,
      top,
    });
    setCollapsed(!collapsed);
  };

  const hideMenu = () => {
    setCollapsed(true);
  };

  const renderOptions = () => {
    return options.filter(Boolean).map((option: MenuOption) => {
      if (option) {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        const { allowedFor, enabled = true, name, onOptionClick } = option;
        const nameValue = typeof name === 'function' ? name(data) : name;
        const innerEnabled =
          typeof enabled === 'function' ? enabled(data) : enabled;

        const handleOptionClick = (e) => {
          e.stopPropagation();

          if (onOptionClick && innerEnabled) {
            onOptionClick(data);
          }

          hideMenu();
        };

        return (
          <Protected key={nameValue} allowedFor={allowedFor}>
            <li>
              <StyledMoreMenuOption
                data-cy-disabled={!innerEnabled}
                enabled={innerEnabled}
                role="menu"
                onClick={handleOptionClick}
              >
                {nameValue}
              </StyledMoreMenuOption>
            </li>
          </Protected>
        );
      }

      return null;
    });
  };

  return (
    <>
      <span
        ref={menuRef}
        data-3-dot-menu-toggle=""
        data-testid="data-3-dot-menu-toggle"
        onClick={toggleMenu}
      >
        <Icon
          color="t2"
          cursor="pointer"
          name="ui-control:three_dots_horizontal"
          width="3rem"
        />
      </span>

      <DropdownWrapper collapsed={collapsed} moreMenu style={dropDownPosition}>
        <ul style={{ width: '100%' }}>{renderOptions()}</ul>
      </DropdownWrapper>
      {!collapsed && <StyledMoreMenuOverlay onClick={toggleMenu} />}
    </>
  );
};
