import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { EditColorArgs } from './edit-color.types';

export const editColor = (args: EditColorArgs) => {
  const { stockData, stockInfoId } = args;

  return axios.put<TransactionResponse>(
    `${baseApiUrl}/item/${stockInfoId}/color`,
    { stockData },
  );
};
