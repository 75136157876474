import React, { FC, useEffect, useState } from 'react';
import firebase from '@firebase/app';
import '@firebase/auth';
import {
  Conditional,
  darkTheme,
  Grid,
  lightTheme,
  ModalProvider,
  ThemeProvider,
} from 'gantri-components';
import Div100vh from 'react-div-100vh';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import Cookies from 'universal-cookie';
import { useAsync } from 'react-use';
import { ErrorFallback, errorHandler } from '../components';
import NetworkErrorMessage from './network-error';
import {
  getTokenCookie,
  isValidAdminRoleType,
  REDIRECTED_FOR_AUTHENTICATION,
  redirectToMantle,
} from '../helpers/auth';
import debounce from '../helpers/debounce';
import firebaseConfig from '../config/firebase';
import { environment } from '../environment';
import { withRouter } from '../components/routes';
import { Notification } from '../components/notification';
import { FullPageLoadingSpinner } from '../components/common/full-page-loading-spinner';
import { DesktopSidebar } from './sidebar/desktop-sidebar';
import { MobileHeader } from './sidebar/mobile-header';
import { clearAllCache } from '../helpers/versioning';
import { dashboardApi, usersApi } from '../api';
import { GlobalStyle } from '../styles';
import { StyledAppContainer } from './app.styles';
import { useTemporalThemeSetup } from '../hooks/useTemporalThemeSetup';
import { ReactQueryProvider } from './react-query';
import { useNotification } from '../hooks/useNotification';
import {
  sidebarCollapseThreshold,
  tabletThreshold,
  uiAtoms,
} from '../global-atoms/ui';
import { userAtoms } from '../global-atoms/users';
import { ADMIN_ROLE_TYPE, STD_COOKIE_OPTIONS } from '../constants/environment';

// Buffer added to support pdf-merger-js
// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
window.Buffer = window.Buffer || require('buffer').Buffer;

const cookies = new Cookies();

const App: FC<any> = ({ location, navigate }) => {
  const theme = useRecoilValue(userAtoms.theme);

  const [loadContent, setLoadContent] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(true);
  const [version, setVersion] = useState('');

  const [viewportWidth, setViewportWidth] = useRecoilState(
    uiAtoms.viewportWidth,
  );
  const setUser = useSetRecoilState(userAtoms.user);
  const setAddresses = useSetRecoilState(userAtoms.addresses);
  const setFirebaseToken = useSetRecoilState(userAtoms.firebaseToken);
  const [isSidebarOpen, setIsSidebarOpen] = useRecoilState(
    uiAtoms.isSidebarOpen,
  );

  const { notify, notifyAxiosError } = useNotification();

  const updateStore = async () => {
    try {
      const { data } = await usersApi.getAdmin();

      if (data && data.success && isValidAdminRoleType(data.data.type)) {
        setUser(data.data);
        setAddresses(data.data.addresses);
        setFirebaseToken(data.firebaseToken);
        cookies.set(ADMIN_ROLE_TYPE, data.data.type, STD_COOKIE_OPTIONS);
      } else {
        redirectToMantle();
      }

      if (data) {
        try {
          firebase.initializeApp(firebaseConfig[environment.STAGE]);
          await firebase.auth().signInWithCustomToken(data.firebaseToken);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('firebase authentication error: ', error);
        }
      }

      return data;
    } catch (error) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch active user.',
      });
    } finally {
      setLoadContent(true);
      setRequestInProgress(false);
    }
  };

  const handleWindowResize = () => {
    const debouncedResize = debounce(() => {
      setViewportWidth(window.innerWidth);
    }, 50);

    debouncedResize();
  };

  const handleCacheForVersion = async () => {
    const response = await dashboardApi.getVersion();

    if (response.data.lastTag) {
      const currentVersion = localStorage.getItem('VERSION');

      // eslint-disable-next-line no-console
      console.log(
        `Core is running at ${currentVersion || response.data.lastTag}`,
      );

      setVersion(currentVersion || response.data.lastTag);

      if (response.data.lastTag !== currentVersion) {
        notify(`${response.data.lastTag} now available. Clearing cache.`);

        clearAllCache();
        localStorage.setItem('VERSION', response.data.lastTag);
      }
    }
  };

  useAsync(async () => {
    const tokenCookie = getTokenCookie();
    const { pathname } = location;

    if (!tokenCookie && pathname !== '/rfid-scanner') {
      redirectToMantle();
    } else if (pathname !== '/rfid-scanner') {
      // @ts-ignore
      await handleCacheForVersion();
      // @ts-ignore
      await updateStore().then((userData) => {
        if (
          localStorage.getItem(REDIRECTED_FOR_AUTHENTICATION) &&
          userData?.data
        ) {
          localStorage.removeItem(REDIRECTED_FOR_AUTHENTICATION);
          navigate(userData.data.type === 'Worker' ? '/orders' : '/');
        }
      });
    } else {
      setLoadContent(true);
    }

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();

    return () => {
      return window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    document.body.scrollTo({ top: 0 });
  }, [location]);

  useEffect(() => {
    const sidebarShouldBeOpen = viewportWidth > sidebarCollapseThreshold;

    setIsSidebarOpen(sidebarShouldBeOpen);
  }, [viewportWidth]);

  const appDataAttrs = {
    'data-app-container': '',
  };

  const isDesktop = viewportWidth > tabletThreshold;

  useTemporalThemeSetup(theme === 'dark' ? darkTheme : lightTheme);

  return (
    <ReactQueryProvider>
      <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
        <GlobalStyle />

        <ModalProvider rootComponent={React.Fragment}>
          <Notification />
          <FullPageLoadingSpinner />
          <Div100vh>
            <Grid
              columns={{ lg: 'max-content 1fr', md: '1fr' }}
              gap="unset"
              height="100%"
            >
              <Conditional
                condition={isDesktop}
                Fallback={<MobileHeader version={version} />}
              >
                <DesktopSidebar version={version} />
              </Conditional>

              <StyledAppContainer
                isDesktop={isDesktop}
                isSidebarOpen={isSidebarOpen}
                {...appDataAttrs}
              >
                {loadContent ? (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onError={errorHandler}
                    onReset={() => {
                      return setLoadContent(false);
                    }}
                  >
                    <Outlet />
                  </ErrorBoundary>
                ) : (
                  <NetworkErrorMessage displayMessage={!requestInProgress} />
                )}
              </StyledAppContainer>
            </Grid>
          </Div100vh>
        </ModalProvider>
      </ThemeProvider>
    </ReactQueryProvider>
  );
};

export default withRouter(App) as any;
