import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  GetJobDetailsArgs,
  GetJobDetailsResponse,
} from './get-job-details.types';
import { fetchJobDetails } from './get-job-details';

export const useFetchJobDetailsQuery = <
  TransformedData = GetJobDetailsResponse,
>(
  props: GenericFetchProps<
    GetJobDetailsArgs,
    GetJobDetailsResponse,
    TransformedData
  >,
) => {
  const { data, isLoading } = useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch job details.',
    queryObj: fetchJobDetails,
    ...props,
  });

  return {
    data,
    isLoading,
  };
};

export const useInvalidateFetchJobDetailsCache = () => {
  const invalidateFetchJobDetailsCache =
    useGetInvalidateQueryCache(fetchJobDetails);

  return {
    invalidateFetchJobDetailsCache,
  };
};
