import { TypographyColor } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledOrderedList = styled.ol<{ $color: TypographyColor }>`
  ${({ $color, theme }) => {
    return css`
      padding-left: 2rem;
      color: ${theme.colors.typography[$color]};
    `;
  }}
`;

export const StyledUnorderedList = styled.ul<{ $color: TypographyColor }>`
  ${({ $color, theme }) => {
    return css`
      list-style: unset;
      padding-left: 2rem;
      color: ${theme.colors.typography[$color]};
    `;
  }}
`;
