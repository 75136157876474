import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';

export const deliverManually = (shipmentId: number) => {
  return axios.put<TransactionResponse>(
    `${baseApiUrl}/shipment/${shipmentId}/delivered`,
    { manual: true },
  );
};
