import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Flex, Icon, Typography } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { StatusInfo } from '../../../../../../../../../../api/jobs/jobs.types';
import { OpenQueue } from '../../../../../../../../../../api/queue/print-prepare/get-print-prepare-queues/get-print-prepare-queues.types';
import { DateWithUserDataCell } from '../../../../../../../../../../components/common/custom-data-cells/date-user-data-cell';
import { StyledAnchor } from '../../../../../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../../../../../config/route-paths';
import { formatDate } from '../../../../../../../../../../helpers/formatter';
import { manageQueuesModalAtoms } from '../../../../../manage-queues-modal.atoms';
import { manageQueuesModalSteps } from '../../../../../manage-queues-modal.constants';

export const useQueuesListContentColumns = () => {
  const setStep = useSetRecoilState(manageQueuesModalAtoms.step);
  const setQueueToDelete = useSetRecoilState(
    manageQueuesModalAtoms.queueToDelete,
  );

  const columns: ColumnDef<OpenQueue>[] = [
    {
      accessorKey: 'id',
      cell: ({ getValue }: CellContext<OpenQueue, OpenQueue['id']>) => {
        return <Typography text={`#${getValue()}`} />;
      },
      header: 'Queue',
      size: 100,
    },
    {
      accessorKey: 'createdAt',
      cell: ({
        getValue,
        row,
      }: CellContext<OpenQueue, OpenQueue['createdAt']>) => {
        const date = formatDate<number>(getValue(), 'T');

        const statusInfo: StatusInfo = {
          created: {
            date,
            description: null,
            status: null,
            user: { ...row.original.user, email: '' },
          },
        };

        return (
          <DateWithUserDataCell
            data={statusInfo}
            date={date}
            jobId={null}
            type="Created"
          />
        );
      },
      header: 'Created',
    },
    {
      accessorKey: 'user',
      cell: ({ getValue }: CellContext<OpenQueue, OpenQueue['user']>) => {
        const user = getValue();

        return (
          <StyledAnchor
            href={`${routePaths.users}/${user.id}`}
            target="_blank"
            text={[user.firstName, user.lastName].filter(Boolean).join(' ')}
          />
        );
      },
      header: 'Created by',
    },
    {
      cell: ({ row }: CellContext<OpenQueue, never>) => {
        return (
          <Flex justifyContent="flex-end">
            <Icon
              color="alert"
              cursor="pointer"
              name="actions:trash_can"
              onClick={() => {
                setQueueToDelete(row.original);
                setStep(manageQueuesModalSteps.confirmDelete);
              }}
            />
          </Flex>
        );
      },
      id: 'delete',
      size: 20,
    },
  ];

  return columns;
};
