import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  AddResaleCertificateArgs,
  AddResaleCertificateResponse,
} from './add-resale-certificate.types';

export const addResaleCertificate = ({
  url,
  userId,
}: AddResaleCertificateArgs) => {
  return axios.put<AddResaleCertificateResponse>(
    `${coreApiUrl}/users/${userId}/resale-certificate`,
    {
      resaleCertificate: url,
    },
  );
};
