import { useRecoilValue } from 'recoil';
import { newMachineIssuesModalSteps } from '../../new-machine-issues-modal.constants';
import { newMachineIssuesModalAtoms } from '../../new-machine-issues-modal.atoms';
import { IssuesChecklistFooter } from '../step-issues-checklist/issues-checklist-footer';
import { ReviewCreatedIssuesFooter } from '../step-review-created-issues/review-created-issues-footer';
import { NewMachineIssuesModalFooterProps } from './new-machine-issues-modal-footer.types';

export const NewMachineIssuesModalFooter = (
  props: NewMachineIssuesModalFooterProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(newMachineIssuesModalAtoms.step);

  switch (step) {
    case newMachineIssuesModalSteps.issuesChecklist:
      return <IssuesChecklistFooter handleCloseModal={handleCloseModal} />;

    case newMachineIssuesModalSteps.reviewCreatedIssues:
      return <ReviewCreatedIssuesFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
