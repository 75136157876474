import React from 'react';
import { Grid, FormikInput, TextArea } from 'gantri-components';

export const NewInventoryForm = () => {
  return (
    <Grid gap="3rem">
      <FormikInput
        labelText="Inventory Name"
        name="name"
        placeholder="Enter name"
        required
      />
      <FormikInput
        labelText="Unit Type"
        name="unit"
        placeholder="Enter type"
        required
      />
      <FormikInput
        Field={<TextArea minRows={3} placeholder="Enter description" />}
        labelText="Description"
        name="description"
        required
      />
    </Grid>
  );
};
