import { Button, Cell, Conditional, Grid, Tooltip } from 'gantri-components';
import { EditButtonsProps } from './edit-buttons.types';

export const EditButtons = (props: EditButtonsProps) => {
  const {
    disabledTooltip,
    handleSave,
    isEditing,
    isEditingDisabled,
    setIsEditing,
    toggleEditMode,
  } = props;

  const handleToggleEditing = () => {
    return toggleEditMode({ isEditing, setIsEditing });
  };

  const EditButton = () => {
    return (
      <Button
        disabled={isEditingDisabled}
        text="Edit"
        variant="secondary"
        onClick={handleToggleEditing}
      />
    );
  };

  return (
    <Conditional
      condition={isEditing}
      Fallback={
        <Conditional
          condition={isEditingDisabled && !!disabledTooltip}
          Fallback={<EditButton />}
        >
          <Tooltip description={disabledTooltip} position="top-end">
            <EditButton />
          </Tooltip>
        </Conditional>
      }
    >
      <Grid columns="repeat(2, max-content)" gap="0.8rem">
        <Cell>
          <Button
            text="Save"
            onClick={async () => {
              await handleSave({ setIsEditing });
            }}
          />
        </Cell>
        <Cell>
          <Button
            text="Cancel"
            variant="secondary"
            onClick={handleToggleEditing}
          />
        </Cell>
      </Grid>
    </Conditional>
  );
};
