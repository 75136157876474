import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CancelNonProductJobArgs,
  CancelNonProductJobResponse,
} from './cancel-non-product-job.types';

export const cancelNonProductJob = ({ jobId }: CancelNonProductJobArgs) => {
  return axios
    .put<CancelNonProductJobResponse>(
      `${coreApiUrl}/jobs/non-product/${jobId}/cancel`,
    )
    .then(({ data }) => {
      return data;
    });
};
