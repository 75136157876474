import {
  Button,
  Flex,
  Icon,
  Modal,
  Stack,
  Typography,
} from 'gantri-components';
import { batchApi } from '../../../../../../api';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';
import { useNotification } from '../../../../../../hooks/useNotification';
import { StartOrCompleteBatchJobsModalProps } from './start-or-complete-batch-jobs-modal.types';

export const StartOrCompleteBatchJobsModal = (
  props: StartOrCompleteBatchJobsModalProps,
) => {
  const { actionType, batch, onClose, setBatch } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const handleBatchJobs = async () => {
    try {
      showLoading();

      const { data } =
        actionType === 'start'
          ? await batchApi.bulkStartBatch({
              batchId: batch?.id,
              returnBatch: true,
            })
          : await batchApi.bulkCompleteBatch({
              batchId: batch?.id,
              returnBatch: true,
            });

      notify(data.notice);

      setBatch(data.batch);

      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: `Unable to ${actionType} batch jobs.`,
      });
    } finally {
      hideLoading();
    }
  };

  const currentJob = batch?.parts?.[0]?.currentJob;
  const numJobs = batch?.parts?.length;

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            size="large"
            text={`Confirm ${actionType}`}
            onClick={handleBatchJobs}
          />
        </>
      }
      header={`${actionType === 'start' ? 'Start' : 'Complete'} ${numJobs} job${
        numJobs > 1 ? 's' : ''
      }`}
      maxWidth={{ lg: '62rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack
        alignContent="center"
        gap="2x"
        height={{ lg: '17.5rem', md: '100%' }}
      >
        <Typography
          align="center"
          text={`Are you sure you want to ${actionType} the following?`}
        />
        <Flex gap=".5x" justifyContent="center">
          <Typography
            align="center"
            icon={
              <Icon
                color="t2"
                name={jobTypeToIconTypeMap[currentJob?.type]}
                top="3px"
              />
            }
            text={`${currentJob?.step} ${currentJob?.type}`}
            textStyle="bold"
          />
          <Typography align="center" text={`jobs (${numJobs})`} />
        </Flex>
      </Stack>
    </Modal>
  );
};
