import { Button } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)`
  ${({ theme, variant }) => {
    return (
      variant === 'tertiary' &&
      css`
        border-color: ${theme.colors.dividers.t2};
      `
    );
  }}
`;
