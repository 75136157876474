import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  MigrateInventoryRequest,
  MigrationInventoryResponse,
} from './migrate-inventory,types';

export const migrateInventory = (
  request: MigrateInventoryRequest,
): Promise<MigrationInventoryResponse> => {
  return axios({
    data: request,
    method: 'post',
    url: `${coreApiUrl}/inventory/migration`,
  }).then((response) => {
    return response.data;
  });
};
