import React, { useEffect, useState } from 'react';
import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { DateUserDataCellProps } from './date-user-data-cell.types';
import { StyledTooltipTypography } from '../custom-data-cells-styles';
import { formatDate, timeAgo } from '../../../../helpers/formatter';
import { jobsApi, partsApi } from '../../../../api';
import routePaths from '../../../../config/route-paths';
import { StyledTooltipContent } from './date-user-data-cell.styles';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../../constants/dates';
import { StyledAnchor } from '../../styled-anchor';
import { StatusInfo, StatusInfoData } from '../../../../api/jobs/jobs.types';

export const DateWithUserDataCell = (props: DateUserDataCellProps) => {
  const { data, date, jobId, partId, type, variant } = props;

  const fallbackData: StatusInfoData = {
    date: formatDate(date, 'T') || null,
    description: null,
    status: null,
    user: null,
  };

  const [tooltipData, setTooltipData] = useState<StatusInfoData>(fallbackData);

  const fetchTooltipInfo = async () => {
    if (!tooltipData?.user) {
      const response = jobId
        ? await jobsApi.getTooltipInfo(jobId)
        : await partsApi.getTooltipInfo(partId);

      updateToolTipData(response?.data?.statusInfo || {});
    }
  };

  const updateToolTipData = (statusInfo: StatusInfo) => {
    const tooltipData =
      type === 'Created'
        ? statusInfo?.created
        : type === 'Start'
        ? statusInfo?.started
        : statusInfo?.completed;

    setTooltipData(tooltipData || fallbackData);
  };

  const TooltipContent = () => {
    const formattedDate = formatDate(tooltipData?.date, LONG_FORMAT);

    return (
      <StyledTooltipContent>
        {tooltipData?.user?.id ? (
          <>
            <StyledTooltipTypography text={formattedDate} />
            <StyledTooltipTypography text=" by " variant="p2" />
            <StyledAnchor
              target="_blank"
              text={[tooltipData.user.firstName, tooltipData.user.lastName]
                .filter(Boolean)
                .join(' ')}
              to={`${routePaths.users}/${tooltipData.user.id}`}
            />

            <Conditional condition={!!tooltipData?.reason}>
              <StyledTooltipTypography
                text={` due to ${tooltipData.reason}`.toLowerCase()}
              />
            </Conditional>
          </>
        ) : (
          <StyledTooltipTypography text={formattedDate} />
        )}
      </StyledTooltipContent>
    );
  };

  useEffect(() => {
    if (tooltipData) {
      // Purges memo cache around columns data for the tooltip if the job ID changes. Ensures tooltipData is accurate.
      setTooltipData(null);
    }
  }, [jobId]);

  useEffect(() => {
    updateToolTipData(data || {});
  }, [data]);

  return tooltipData?.date ? (
    <Flex alignItems="center" gap="0.8rem">
      <Typography
        text={
          variant === 'days-since'
            ? timeAgo(+tooltipData?.date)
            : formatDate(tooltipData?.date, MEDIUM_FORMAT)
        }
      />

      <Tooltip
        Component={<TooltipContent />}
        overlayContainerStyles={{ display: 'flex' }}
        position="top"
      >
        <Icon name="alert:i_circle" top="2px" onMouseEnter={fetchTooltipInfo} />
      </Tooltip>
    </Flex>
  ) : null;
};
