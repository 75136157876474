import {
  Conditional,
  Flex,
  Stack,
  Typography,
  getFileUrl,
} from 'gantri-components';
import { PartDiagramProps } from './part-diagram.types';
import {
  StyledImgWrapper,
  StyledPlaceholderStack,
  StyledSwatchFlexWrapper,
} from './part-diagram.styles';
import { PrimarySurfaceSwatch } from '../../../primary-surface-swatch';
import { SecondarySurfaceSwatch } from '../../../secondary-surface-swatch';
import Thumbnail from '../../../../../../../../components/common/thumbnail/thumbnail';
import { placeholderImgSrc } from '../../../../../../../search-part/components/search-part-summary/components/search-part-summary-thumbnail/search-part-summary-thumbnail.constants';

export const PartDiagram = (props: PartDiagramProps) => {
  const { imageType, job, showSwatches } = props;
  const material = job?.part?.material;
  const partImgSrc = job?.part?.thumbnail;
  const productImgSrc = getFileUrl<'products'>({
    directory: 'products',
    fileName: job?.product?.thumbnail,
    fileType: 'product-photos',
    identifiers: {
      productId: job?.product?.id,
      sku: job?.product?.sku,
    },
  });

  const showProductImg = imageType === 'product';

  return (
    <Stack gap="1.2rem">
      <Conditional
        condition={showProductImg ? !!productImgSrc : !!partImgSrc}
        Fallback={
          <StyledPlaceholderStack
            gap="1.6rem"
            justifyItems="center"
            padding={{ lg: '7rem', md: '5rem' }}
          >
            <Thumbnail size="13.4rem" src={placeholderImgSrc} />
            <Typography color="t2" text="No diagram available currently." />
          </StyledPlaceholderStack>
        }
      >
        <StyledPlaceholderStack justifyItems="center" padding="1.6rem">
          <StyledImgWrapper>
            <img
              alt={job?.part?.name}
              src={showProductImg ? productImgSrc : partImgSrc}
              width="100%"
            />
          </StyledImgWrapper>
        </StyledPlaceholderStack>
        <Conditional condition={showSwatches}>
          <StyledSwatchFlexWrapper wrap="wrap">
            <Flex alignItems="center" gap="1.2rem" width="unset">
              <PrimarySurfaceSwatch material={material} />
              <Typography text="Primary Surface" />
            </Flex>
            <Flex alignItems="center" gap="1.2rem" width="unset">
              <SecondarySurfaceSwatch material={material} />
              <Typography text="Secondary Surface" />
            </Flex>
          </StyledSwatchFlexWrapper>
        </Conditional>
      </Conditional>
    </Stack>
  );
};
