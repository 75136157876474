import styled, { css } from 'styled-components';
import { generateStylesForResolutionAwareProps } from 'gantri-components';
import { ExtractStyleFromResolutionAwarePropEntry } from 'gantri-components/dist/types/layout';
import { DividerProps } from './divider.types';

export const StyledDividerWrapper = styled.div<DividerProps>`
  ${({
    borderColor,
    borderLocation,
    height,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    theme,
  }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'height',
        resolutionAwareProp: height,
      },
      {
        cssProperty: 'padding',
        lookIn: theme.dimensions.spacing,
        resolutionAwareProp: padding,
      },
      {
        cssProperty: 'padding-left',
        lookIn: theme.dimensions.spacing,
        resolutionAwareProp: paddingLeft,
      },
      {
        cssProperty: 'padding-right',
        lookIn: theme.dimensions.spacing,
        resolutionAwareProp: paddingRight,
      },
      {
        cssProperty: 'padding-top',
        lookIn: theme.dimensions.spacing,
        resolutionAwareProp: paddingTop,
      },
      {
        cssProperty: 'padding-bottom',
        lookIn: theme.dimensions.spacing,
        resolutionAwareProp: paddingBottom,
      },
    ];

    return css`
      ${borderLocation &&
      css`border-${borderLocation}: 1px solid ${theme.colors.dividers[borderColor]};`}

      ${generateStylesForResolutionAwareProps(entries)};
    `;
  }}
`;

export const StyledHorizontalLine = styled.div<
  Pick<DividerProps, 'borderColor'>
>`
  ${({ borderColor, theme }) => {
    return css`
      border-bottom: 1px solid ${theme.colors.dividers[borderColor]};
    `;
  }}
`;
