import * as yup from 'yup';
import { JobBlockStock } from '../../../../../../../../api/products/products.types';
import { defaultJobBlockDurations } from '../../../parts-section/components/part-job-blocks/part-job-blocks.constants';
import { jobBlockInstructionsSchema } from '../../../parts-section/components/part-job-blocks/part-job-blocks.schema';

export interface StockJobsDurationFormData {
  assembleDuration: number;
  isAccessory: boolean;
  packDuration: number;
  qcDuration: number;
  stageDuration: number;
}

export const getStockJobsDurationInitialValues = ({
  isAccessory,
  stockJobsBlock,
}: {
  isAccessory: boolean;
  stockJobsBlock: JobBlockStock;
}): StockJobsDurationFormData => {
  const getFallbackIfAccessory = (value: number) => {
    return isAccessory ? undefined : value;
  };

  return {
    assembleDuration:
      getFallbackIfAccessory(stockJobsBlock?.assembleDuration) || undefined,
    isAccessory,
    packDuration:
      stockJobsBlock?.packDuration || defaultJobBlockDurations.packPack,
    qcDuration:
      getFallbackIfAccessory(
        stockJobsBlock?.qcDuration || defaultJobBlockDurations.qaQc,
      ) || undefined,
    stageDuration:
      getFallbackIfAccessory(
        stockJobsBlock?.stageDuration || defaultJobBlockDurations.assembleStage,
      ) || undefined,
  };
};

const getRequiredText = (label: string) => {
  return `${label} is required` as const;
};

const conditionalDurationSchema = yup.number().when('isAccessory', {
  is: (value: boolean) => {
    return !!value;
  },
  otherwise: yup
    .number()
    .min(1, ({ min }) => {
      return `Duration must be at least ${min}.`;
    })
    .required(getRequiredText('Duration')),
  then: yup
    .number()
    .min(1, ({ min }) => {
      return `Duration must be at least ${min}.`;
    })
    .nullable(),
});

export const stockJobsDurationFormikSchema: yup.SchemaOf<StockJobsDurationFormData> =
  yup.object().shape({
    assembleDuration: conditionalDurationSchema,
    // id: yup.number().required(),
    instructions: jobBlockInstructionsSchema,
    isAccessory: yup.boolean().required(),
    packDefaultDuration: yup.number(),
    packDuration: yup
      .number()
      .min(1, ({ min }) => {
        return `Duration must be at least ${min}.`;
      })
      .required(getRequiredText('Duration')),
    qcDefaultDuration: yup.number(),
    qcDuration: conditionalDurationSchema,
    stageDefaultDuration: yup.number(),
    stageDuration: conditionalDurationSchema,
  });
