import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { createNonProductJob } from './create-non-product-job';
import {
  CreateNonProductJobArgs,
  CreateNonProductJobResponse,
} from './create-non-product-job.types';

export const useCreateNonProductJob = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onCreateNonProductJob, ...rest } = useQueryMutation<
    CreateNonProductJobArgs,
    CreateNonProductJobResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create non-product job.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(notice);
      },
    },
    mutationFn: async (args) => {
      return createNonProductJob(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: false,
  });

  return { ...rest, onCreateNonProductJob };
};
