import { useAsync, useToggle } from 'react-use';
import { useHandleGetPrintJobs } from '../../components/step-confirm-begin/confirm-begin-footer/hooks/use-handle-get-print-jobs';
import { jobStatuses } from '../../../../../../../../constants/options';
import { JobDetails } from '../../../../../../../../api/jobs/routes/get-job-details/get-job-details.types';

export const useInitializeInProgressJob = (props: { job: JobDetails }) => {
  const { job } = props;

  const isInProgress = job.status === jobStatuses.inProgress;

  const [isFetchingAvailableJobs, toggleIsFetchingAvailableJobs] =
    useToggle(isInProgress);

  const handleGetPrintJobs = useHandleGetPrintJobs();

  useAsync(async () => {
    if (isInProgress) {
      try {
        await handleGetPrintJobs({
          partId: job.part.id,
        });
      } finally {
        toggleIsFetchingAvailableJobs();
      }
    }
  }, []);

  return { isFetchingAvailableJobs };
};
