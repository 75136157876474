import React, { FC } from 'react';
import {
  Conditional,
  Grid,
  SearchField,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { userStatusOptions } from '../../../../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { UserFilterProps } from './user-filter.types';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { FilterRow } from '../../../../common/filter-grid';
import { AtomsCheckboxList } from '../../../../common/atoms-checkbox-list';

export const UserFilter: FC<UserFilterProps> = (props) => {
  const {
    useSingleSelection = false,
    userIdsAtom,
    userIdsDefault,
    userNames,
    userStatusesAtom,
    userStatusesDefault,
  } = props;

  const [userIds, setUserIds] = useRecoilState(userIdsAtom);
  const resetUserIds = useResetRecoilState(userIdsAtom);
  const resetUserStatuses = useResetRecoilState(userStatusesAtom);

  return (
    <FilterRow>
      <FilterResetLabel
        atomsList={[userIdsAtom, userStatusesAtom]}
        defaultsList={[userIdsDefault, userStatusesDefault]}
        text="Users"
      />

      <Stack
        gap="x"
        maxWidth={{ lg: '60rem', md: 'unset' }}
        style={{ position: 'relative' }}
      >
        <SearchField
          autoSelectFirst={false}
          enableSelectedItemsList={!useSingleSelection}
          filterFn={(searchBy, option) => {
            return (
              option.name.toLowerCase().includes(searchBy.toLowerCase()) ||
              option.type?.toLowerCase()?.includes(searchBy.toLowerCase())
            );
          }}
          idProperty="id"
          items={userNames}
          labelProperty="name"
          placeholder="Search users"
          renderItem={({ name, type }) => {
            return (
              <Grid
                alignItems="center"
                columns="1fr max-content"
                gap="0.8rem"
                horizontalPadding="1.6rem"
                verticalPadding="0.8rem"
              >
                <Typography color="t1" text={name} variant="p2" />
                <Typography color="t2" text={type} variant="p3" />
              </Grid>
            );
          }}
          selectedItemsList={userIds ?? []}
          onChange={({ target }) => {
            if (useSingleSelection) {
              if (!target.value) {
                resetUserIds();

                if (userStatusesAtom) {
                  resetUserStatuses();
                }

                return;
              }

              // @ts-expect-error
              setUserIds([target.value]);

              return;
            }

            // @ts-expect-error
            if (!target.value || target.value?.length === 0) {
              resetUserIds();

              if (userStatusesAtom) {
                resetUserStatuses();
              }
            } else {
              // @ts-expect-error
              setUserIds(target.value ?? []);
            }
          }}
        />

        <Conditional condition={!!userStatusesAtom}>
          <AtomsCheckboxList
            atom={userStatusesAtom}
            getIsDisabled={() => {
              return !userIds || userIds?.length === 0;
            }}
            gridProps={{ columns: 2 }}
            items={[...userStatusOptions]}
          />
        </Conditional>
      </Stack>
    </FilterRow>
  );
};
