import { Box, Stack, Typography } from 'gantri-components';
import { getPrintRfidUrl } from '../../../../../../../../components/common/custom-data-cells/rfid-data-cell';
import { CompletePrintPrepareContentProps } from './complete-print-prepare-content.types';
import { downloadToastText } from '../../../../../../../../hooks/use-download-with-small-toast/use-download-with-small-toast.constants';
import { ModalContentHeading } from '../../../../common/modal-content-heading';
import { getFileNameWithCopyQty } from './helpers/get-file-name-with-copy-qty';
import { useDownloadButton } from '../../../../../../../../hooks/use-download-button';
import { FileToDownload } from '../../../../../../../../hooks/use-download/hooks/use-handle-download-request/use-handle-download-request.types';

export const CompletePrintPrepareContent = (
  props: CompletePrintPrepareContentProps,
) => {
  const { job } = props;

  const gcodeFile: FileToDownload = {
    fileName: getFileNameWithCopyQty(job.gcode?.fileName, 1),
    url: job.gcode?.url,
  };

  const {
    DownloadButton: DownloadGcodeButton,
    DownloadStatusToast: DownloadGcodeToast,
  } = useDownloadButton({
    ...downloadToastText.gcode,
    download: gcodeFile,
  });

  const rfidFile: FileToDownload = {
    url: getPrintRfidUrl({
      partId: job.part.id,
      stockId: job.stockId,
    }),
  };
  const {
    DownloadButton: DownloadTagButton,
    DownloadStatusToast: DownloadTagToast,
  } = useDownloadButton({
    ...downloadToastText.rfidTag,
    download: rfidFile,
  });

  return (
    <Stack gap="4x">
      <Box>
        {/* Toasts must be above section title to achieve desired positioning. */}
        <DownloadGcodeToast position="bottom-end" />
        <DownloadTagToast position="bottom-end" />
        <ModalContentHeading titleText="Prepare packet" />
      </Box>
      <Stack gap="7x">
        <Stack gap="x">
          <Typography text="Download G-code" variant="h5" />
          <Typography htmlText="Please download g-code and connect <strong>1</strong> SD card to reader." />
          <DownloadGcodeButton
            text={`Download: ${job.gcode?.fileName || 'gcode'}`}
          />
        </Stack>
        <Stack gap="x">
          <Typography text="Print RFID tag" variant="h5" />
          <DownloadTagButton text="Print tag" />
        </Stack>
      </Stack>
    </Stack>
  );
};
