import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateInventoryPurchaseShipmentArgs,
  CreateInventoryPurchaseShipmentResponse,
} from './create-inventory-purchase-shipment.types';

export const createInventoryPurchaseShipment = (
  body: CreateInventoryPurchaseShipmentArgs,
) => {
  return axios.post<CreateInventoryPurchaseShipmentResponse>(
    `${coreApiUrl}/inventory-purchase-shipments/create`,
    body,
  );
};
