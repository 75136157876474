import axios from 'axios';
import { environment } from '../../../../environment';
import { SendInviteArgs, SendInviteResponse } from './send-invite.types';

export const sendInvite = (body: SendInviteArgs) => {
  return axios.post<SendInviteResponse>(
    `${environment.API_URL}/invite/designer`,
    body,
  );
};
