import axios from 'axios';
import { libApiUrl } from '../../../../helpers/auth';
import { GetTaxesArgs, GetTaxesResponse } from './get-taxes.types';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';

/** Number in cents. */
const getTaxes = (body: GetTaxesArgs) => {
  return axios
    .post<GetTaxesResponse>(`${libApiUrl}/tax`, body)
    .then(({ data }) => {
      return data;
    });
};

export const getTaxesObj = getGenericQueryObj({
  groupName: 'get-taxes',
  query: getTaxes,
});
