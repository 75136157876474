import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { ResetJobsArgs, ResetJobsResponse } from './reset-jobs.types';

export const resetJobs = ({ stockId, ...body }: ResetJobsArgs) => {
  return axios.post<ResetJobsResponse>(
    `${getCoreApiUrl('stocks')}/${stockId}/reset-jobs`,
    body,
  );
};
