import { FC } from 'react';
import { useToggle } from 'react-use';
import { Button, Typography } from 'gantri-components';
import { environment } from '../../../../../../environment';
import { Handout } from '../handout';
import { StyledPrintPreviewSectionWrapper } from './print-preview-section.styles';
import { PrintPreviewSectionProps } from './print-preview-section.types';

export const PrintPreviewSection: FC<PrintPreviewSectionProps> = ({
  designerInfo,
}) => {
  const [enablePrintPreviewSection, toggleEnablePrintPreviewSection] =
    useToggle(false);

  const { STAGE: stage } = environment;

  return stage === 'local' ? (
    <>
      <Typography
        marginBottom="1rem"
        marginTop="4rem"
        text={`${stage.toUpperCase()} ENV PRINT PREVIEW`}
        textStyle="bold"
        variant="h4"
      />
      <Typography
        color="alert"
        marginBottom="2rem"
        text={`NOTE: This preview is only visible in the ${stage} environment.`}
      />
      <Button
        text={`${enablePrintPreviewSection ? 'Hide' : 'Show'} print preview`}
        variant="secondary"
        onClick={toggleEnablePrintPreviewSection}
      />
      {enablePrintPreviewSection && designerInfo.handout && (
        <StyledPrintPreviewSectionWrapper>
          <Handout designerInfo={designerInfo} />
        </StyledPrintPreviewSectionWrapper>
      )}
    </>
  ) : null;
};
