import styled from 'styled-components';

export const StyledPaymentTitle = styled.div`
  margin-bottom: 1.6rem;
`;

export const StyledAffirmSpan = styled.span`
  &:after {
    content: '';
    position: absolute;
    width: 54px;
    height: 22px;
    background-image: url('../../../assets/images/logos/affirm-logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    top: -6px;
    right: -58px;
    border: 1px solid red;
  }
`;

export const StyledPaymentInfoField = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
