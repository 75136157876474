import { useRecoilValue } from 'recoil';
import { PrintQaFooterProps } from './print-qa-footer.types';
import { ChecklistFooter } from '../step-checklist/checklist-footer';
import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { SelectJobFooter } from '../step-select-job/select-job-footer';
import { JobFailedFooter } from '../step-job-failed/job-failed-footer';
import { printQcModalAtoms } from '../../complete-print-qa-modal.atoms';
import { MachineOfflineFooter } from '../step-machine-offline/machine-offline-footer';

export const PrintQaFooter = (props: PrintQaFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(printQcModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return (
        <ConfirmBeginFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'SELECT_JOB':
      return <SelectJobFooter />;

    case 'CHECKLIST':
      return <ChecklistFooter handleCloseModal={handleCloseModal} job={job} />;

    case 'JOB_FAILED':
      return <JobFailedFooter handleCloseModal={handleCloseModal} />;

    case 'MACHINE_OFFLINE':
      return <MachineOfflineFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
