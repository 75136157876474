import { v4 as uuidv4 } from 'uuid';
import { Cell, Conditional, Icon, Tooltip } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { getVersionDetails } from '../../helpers/get-version-details';
import { StyledAddItemTypography } from './add-job-template-button.styles';
import { productJobsTabAtoms } from '../../../../../pages/product/components/product-jobs/product-jobs.atoms';
import { AddJobTemplateButtonProps } from './add-job-template-button.types';
import { JobTemplateWithId } from '../../jobs-template-table.types';
import { getTemplateEditDisabledReason } from '../../helpers/get-template-edit-disabled-reason';

export const AddJobTemplateButton = (props: AddJobTemplateButtonProps) => {
  const {
    disableEditing,
    isReorderActive,
    jobStep,
    jobTemplates,
    productId,
    selectedSKU,
    setEditingTemplateId,
    setJobTemplates,
    version,
  } = props;

  const [isEditingJobs, setIsEditingJobs] = useRecoilState(
    productJobsTabAtoms.isEditingJobs,
  );

  const { isDraft, isMinorVersion } = getVersionDetails(version);
  const disableAddJob = isMinorVersion || isReorderActive || isEditingJobs;

  const addJob = () => {
    const emptyJobTemplateId = uuidv4();
    const emptyJobTemplate: Partial<JobTemplateWithId> = {
      description: undefined,
      duration: 1,
      id: emptyJobTemplateId,
      instruction: {},
      inventoryRequests: [],
      machineType: undefined,
      productId,
      sku: selectedSKU,
      sortOrder: jobTemplates.length + 1,
      step: jobStep,
      type: undefined,
    };

    const updatedJobTemplates = [
      ...jobTemplates,
      emptyJobTemplate as JobTemplateWithId,
    ];

    setJobTemplates(updatedJobTemplates);
    setIsEditingJobs(true);
    setEditingTemplateId(emptyJobTemplateId);
  };

  return (
    <Conditional condition={isDraft && !disableEditing}>
      <Cell justifySelf="flex-start">
        <Tooltip
          description={getTemplateEditDisabledReason({
            isEditing: isEditingJobs,
            isMinorVersion,
            isReorderActive,
          })}
          position="top"
        >
          <StyledAddItemTypography
            $disabled={disableAddJob}
            color={disableAddJob ? 't3' : 'link'}
            icon={
              <Icon
                color={disableAddJob ? 't3' : 'link'}
                name="ui-control:plus_circle"
              />
            }
            marginTop="1rem"
            text="Add Job"
            onClick={disableAddJob ? undefined : addJob}
          />
        </Tooltip>
      </Cell>
    </Conditional>
  );
};
