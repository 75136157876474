import { Typography } from 'gantri-components';
import MetaData from '../meta-data';

const Unauthorized = () => {
  return (
    <>
      <MetaData title="Unauthorized" />
      <Typography
        color="alert"
        text="You do not have access to view this page."
      />
    </>
  );
};

export default Unauthorized;
