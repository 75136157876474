import { formatAsLocaleNumber } from '../../../../../helpers/formatter';
import { DashboardChartDefaultProps } from './dashboard-chart.types';

export const dashboardChartDefaultProps: Required<DashboardChartDefaultProps> =
  {
    minHeight: '40rem',
    transformTooltipValue: (value) => {
      return typeof value === 'number' ? formatAsLocaleNumber(value) : value;
    },
  };
