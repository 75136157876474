import { useRecoilValue } from 'recoil';
import { UserRole, userRoles } from '../../constants/users';
import { userAtoms } from '../../global-atoms/users';
import { getRolesForAccessLevelAndAbove } from '../../helpers/get-roles-for-access-level-and-above';

export const useUserHasAccessLevel = () => {
  const { id, type: role } = useRecoilValue(userAtoms.user);

  const userHasAccessLevel = (userRole: UserRole) => {
    return getRolesForAccessLevelAndAbove(userRole).some((userRole) => {
      return userRole === role;
    });
  };

  const getUserHasAccessTo = (
    userRolesOrIds: (UserRole | number)[],
    options?: {
      /** If `userRolesOrIds` array is empty, return `true`. */
      trueIfEmpty?: boolean;
    },
  ) => {
    const { trueIfEmpty } = options || {};

    return !userRolesOrIds?.length && trueIfEmpty
      ? true
      : !!userRolesOrIds?.some((allowed) => {
          if (typeof allowed === 'number') {
            return allowed === id;
          }

          return allowed === role;
        });
  };

  const hasAdminAccess = userHasAccessLevel(userRoles.admin);
  const hasLeadAccess = userHasAccessLevel(userRoles.lead);
  const hasWorkerAccess = userHasAccessLevel(userRoles.worker);

  return {
    getUserHasAccessTo,
    hasAdminAccess,
    hasLeadAccess,
    hasWorkerAccess,
    role,
  };
};
