import { Typography } from 'gantri-components';
import { PropsWithChildren } from 'react';
import {
  ErrorBoundary,
  ErrorBoundaryPropsWithFallback,
} from 'react-error-boundary';
import { StyledAnchor } from '../common/styled-anchor';
import { StyledBox } from './simple-error-boundary.styles';

export const SimpleErrorBoundary = ({
  children,
  ...rest
}: PropsWithChildren<Partial<ErrorBoundaryPropsWithFallback>>) => {
  return (
    <ErrorBoundary
      fallback={
        <StyledBox padding="x">
          <Typography text="There was an error rendering this content." />
          <StyledAnchor
            target="_blank"
            text="Please report this bug to the software team."
            to="https://form.asana.com/?k=uxvFdJrnWvaxwV-Khwk9XA&d=1186582822873190"
          />
        </StyledBox>
      }
      {...rest}
    >
      {children}
    </ErrorBoundary>
  );
};
