import styled, { css } from 'styled-components';

export const StyledPdfAnchor = styled.a`
  padding-top: 75%;
`;

export const StyledPdf = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid ${theme.colors.dividers.t1};
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-top: 1rem;
      }
    `;
  }}
`;

export const StyledFullImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: cover;
`;

export const StyledReviewItem = styled.div`
  ${({ theme }) => {
    return css`
      padding: 10px 0;
      border-top: 1px solid ${theme.colors.dividers.t1};

      button,
      button:hover,
      button:focus {
        height: 36px;
        width: 65px;
        min-width: 65px;
        max-width: 65px;
      }
    `;
  }}
`;

export const StyledActions = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${theme.colors.dividers.t1};
      padding-top: 60px;
      text-align: center;
    `;
  }}
`;
