import axios from 'axios';
import { FetchUserNpsReviewsResponse } from './fetch-user-nps-reviews.types';
import { coreApiUrl } from '../../../../helpers/auth';

const query = async (userId: number): Promise<FetchUserNpsReviewsResponse> => {
  const response = await axios.get<FetchUserNpsReviewsResponse>(
    `${coreApiUrl}/nps-reviews/all/${userId}`,
  );

  return response.data;
};

const groupKey = () => {
  return 'user-nps-reviews';
};

const key = (userId: number) => {
  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}/${userId}`,
    },
  ] as const;
};

export const fetchUserNpsReviews = {
  groupKey,
  key,
  query,
};
