import { useQueryClient } from '@tanstack/react-query';
import { useNotification } from '../../../../hooks/useNotification';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { UpdateProductReviewArgs } from './update-product-review.types';
import { updateProductReview } from './update-product-review';
import {
  fetchUserProductReviews,
  UserProductReview,
} from '../fetch-user-product-reviews';
import { useInvalidateFetchPaginatedProductReviewsCache } from '../fetch-paginated-product-reviews';

export const useUpdateProductReviewMutation = () => {
  const { notify, notifyAxiosError } = useNotification();
  const queryClient = useQueryClient();
  const invalidateReviewsCache =
    useInvalidateFetchPaginatedProductReviewsCache();

  const { isLoading, onMutate } = useQueryMutation<
    UpdateProductReviewArgs,
    { inventory: any }
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred while updating the review.',
        });
      },
      onSuccess: async (data, variables) => {
        notify(data.notice);
        await invalidateReviewsCache();
        // Replace optimistic inventory cache and avoid the loading indicator
        queryClient.setQueryData(
          fetchUserProductReviews.key(variables.userId),
          (old: any) => {
            return {
              ...(old ?? {}),
              reviews:
                (old as { reviews: Array<UserProductReview> })?.reviews?.map(
                  (review) => {
                    if (review.id === variables.id) {
                      return { ...review, status: variables.status };
                    }

                    return review;
                  },
                ) ?? [],
            };
          },
        );
      },
    },
    mutationFn: (request) => {
      return updateProductReview(request);
    },
    showLoading: true,
  });

  return { isLoading, onUpdateProductReview: onMutate };
};
