import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  AddPartToBatchArgs,
  AddPartToBatchResponse,
} from './add-part-to-batch.types';

export const addPartToBatch = (args: AddPartToBatchArgs) => {
  const { batchId, partId, returnBatch } = args;

  return axios.put<AddPartToBatchResponse>(
    `${coreApiUrl}/batch/${batchId}/${partId}/add`,
    {
      returnBatch,
    },
  );
};
