import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'gantri-components';
import Grid from '../../common/grid';
import Cell from '../../common/cell';
import ReasonStock from '../reason-stock';
import styles from '../styles.module.scss';
import routePaths from '../../../config/route-paths';
import { transactionTypesMap } from '../../../constants/options';
import { PageHeading } from '../../layout/page-heading';
import { useRouter } from '../../../hooks';
import { transactionsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

const ReplacementReason = ({ currentOrderData, id }) => {
  const { navigate } = useRouter();
  const { notifyAxiosError, onInterceptRequest } = useNotification();

  const { address, shipments, stocks } = currentOrderData;

  const [stockInfoIds, setStockInfoIds] = useState([]);
  const [newStocks, setNewStocks] = useState(stocks);
  const [valid, setValid] = useState(false);

  const validate = (checkedInfoIds) => {
    const hasInvalid = newStocks.find((stock) => {
      return (
        !stock.valid &&
        stock.valid !== undefined &&
        checkedInfoIds.includes(stock.stockInfoId)
      );
    });

    setValid(!hasInvalid && checkedInfoIds.length > 0);
  };

  const onCancel = () => {
    navigate(`${routePaths.orders}/${id}`);
  };

  const handleCheck = (modified) => {
    return (checked) => {
      const stockInfoIndex = stockInfoIds.findIndex((stockInfoId) => {
        return stockInfoId === modified.stockInfoId;
      });
      const recordedStockInfoId = stockInfoIndex >= 0;

      if (checked && !recordedStockInfoId) {
        const checkedInfoIds = [...stockInfoIds, modified.stockInfoId];

        setStockInfoIds(checkedInfoIds);
        validate(checkedInfoIds);
      } else if (!checked && recordedStockInfoId) {
        stockInfoIds.splice(stockInfoIndex, 1);
        setStockInfoIds(stockInfoIds);
        validate(stockInfoIds);
      }
    };
  };

  const onConfirm = async () => {
    if (valid) {
      await onInterceptRequest(async () => {
        try {
          const { data } = await transactionsApi.createTransaction({
            address,
            notes: `Replacement for order #${id}`,
            originalTransactionId: id,
            stockInfoIds,
            stocks: newStocks.filter((newStock) => {
              return stockInfoIds.includes(newStock.stockInfoId);
            }),
            type: transactionTypesMap.replacement,
          });

          if (data && data.success) {
            const updatedOrderId = data.order.id;

            navigate(`${routePaths.orders}/${updatedOrderId}`);
          }
        } catch (error) {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to create order.',
          });
        }
      });
    }
  };

  const handleUpdate = (stock) => {
    const spliceIndex = newStocks.findIndex((newStock) => {
      return newStock.id === stock.id;
    });
    const updatedStock = Object.assign(newStocks[spliceIndex], stock);

    newStocks.splice(spliceIndex, 1, updatedStock);
    setNewStocks(newStocks);
    validate(stockInfoIds);
  };

  return (
    <Grid columnGap="0" rowGap="60px" rows={3}>
      <Cell>
        <PageHeading
          subTitle="Select items below to create a replacement"
          title="Create Replacement"
        />
        {newStocks.map((stock) => {
          return (
            <ReasonStock
              key={stock.id}
              shipments={shipments}
              stock={stock}
              type="replacement"
              onCheck={handleCheck(stock)}
              onUpdate={handleUpdate}
            />
          );
        })}
      </Cell>
      <Grid columns="repeat(2, max-content)">
        <Button text="Cancel" variant="secondary" onClick={onCancel} />
        <Button
          classnames={styles['button-container']}
          disabled={!valid}
          text="Confirm"
          variant="primary"
          onClick={onConfirm}
        />
      </Grid>
    </Grid>
  );
};

ReplacementReason.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ReplacementReason;
