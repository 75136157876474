import { useEffect } from 'react';
import { Conditional } from 'gantri-components';
import { useBlocker } from 'react-router-dom';
import { ExitWarningModal } from '../../common/exit-warning-modal';
import { LeavePageConfirmationProps } from './leave-page-confirmation.types';

/** Add component to provide a confirmation modal under the following circumstances:
 *
 * - Window/tab close or refresh: provides a browser alert asking if you're sure. This alert cannot execute the `onConfirm` function. You must provide an alternate means of executing that logic.
 *
 * - Browser history navigation: provides a custom modal using the passed props with the browser alert fallback.
 */
export const LeavePageConfirmation = (props: LeavePageConfirmationProps) => {
  const { children, disabled, header, message, onConfirm } = props;

  const showBrowserAlert = (e: BeforeUnloadEvent) => {
    // The message in the browser alert cannot be modified, and the user's choice cannot be captured
    e.preventDefault();
  };

  const { proceed, reset, state } = useBlocker(!disabled);

  const handleOnConfirm = () => {
    proceed();

    onConfirm?.();
  };

  // Block non-react routes
  useEffect(() => {
    window.addEventListener('beforeunload', showBrowserAlert);

    return () => {
      window.removeEventListener('beforeunload', showBrowserAlert);
    };
  }, []);

  return (
    <Conditional condition={state === 'blocked'}>
      <ExitWarningModal
        header={header}
        message={message}
        onClose={reset}
        onConfirm={handleOnConfirm}
      >
        {children}
      </ExitWarningModal>
    </Conditional>
  );
};
