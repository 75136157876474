import { Stack, Typography } from 'gantri-components';
import { ContentWithLabelProps } from './content-with-label.types';

export const ContentWithLabel = (props: ContentWithLabelProps) => {
  const { Content, label, text } = props;

  return (
    <Stack gap="0.6rem">
      <Typography text={label} textStyle="bold" />
      {Content ? (
        <Content />
      ) : (
        <Typography color={!!text ? 't1' : 't2'} text={text || 'None'} />
      )}
    </Stack>
  );
};
