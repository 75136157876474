import { memo, Fragment } from 'react';
import { Typography, Flex, Grid, SearchField } from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { ProductIncludedProps } from './product-included.types';
import {
  ProductIncludedType,
  ProductNames,
} from '../../desginer-feature.types';

export const Section = (props: ProductIncludedProps) => {
  const { productIncluded, products, updateFeatureInfo } = props;

  const addNewProduct = () => {
    updateFeatureInfo({
      key: 'productIncluded',
      type: 'key',
      value: [...productIncluded, { id: '' }],
    });
  };

  const onProductSelect = (position: number) => {
    return (selectedProduct: ProductNames) => {
      updateFeatureInfo({
        key: 'productIncluded',
        type: 'key',
        value: productIncluded.map((product, index) => {
          return index === position ? { id: selectedProduct.id } : product;
        }),
      });
    };
  };

  const removeProduct = (position: number) => {
    updateFeatureInfo({
      key: 'productIncluded',
      type: 'key',
      value: productIncluded.filter((product, index: number) => {
        return index !== position;
      }),
    });
  };

  return (
    <StyledSectionContainer section="product-included">
      <Flex>
        <Typography text="Products included" textStyle="bold" variant="h4" />
        <Typography
          align="right"
          color="link"
          decoration="underline"
          marginLeft="0.8rem"
          marginTop="8px"
          style={{
            cursor: 'pointer',
          }}
          text="Add products"
          onClick={addNewProduct}
        />
      </Flex>
      <Grid
        columnGap="2rem"
        columns={{ lg: '1fr 10rem' }}
        justifyContent="space-between"
        marginTop="2rem"
        rowGap="1.5rem"
      >
        {productIncluded?.map((product: ProductIncludedType, index: number) => {
          return (
            <Fragment key={index}>
              <div>
                <Typography
                  marginBottom="0.5rem"
                  text="Product"
                  textStyle="bold"
                />
                <SearchField
                  idProperty="id"
                  items={products || []}
                  labelProperty="name"
                  placeholder="Select Location..."
                  value={product.id}
                  onSelect={onProductSelect(index)}
                />
              </div>
              <Typography
                align="right"
                color="alert"
                decoration="underline"
                marginLeft="1.5rem"
                marginTop="2rem"
                style={{
                  cursor: 'pointer',
                }}
                text="Remove"
                onClick={() => {
                  return removeProduct(index);
                }}
              />
            </Fragment>
          );
        })}
      </Grid>
    </StyledSectionContainer>
  );
};

export const ProductIncluded = memo(Section);
