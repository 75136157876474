import styled from 'styled-components';
import { Typography } from 'gantri-components';
import FlexContainer from '../../layout/flex-container';

export const StyledTextAndIconContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledTooltipTypography = styled(Typography)`
  display: inline;
`;

export const StyledFieldIconWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
`;

export const StyledTableIconContainer = styled.div`
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const StyledFileName = styled(Typography)`
  max-width: 100px;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const StyledButtonFlexContainer = styled(FlexContainer)`
  cursor: pointer;
  min-height: 24px;
  user-select: none;
`;
