import { array, mixed, number, object, SchemaOf, string } from 'yup';
import { InventoryType } from '../../constants/options';

const requiredText = 'Required.';

export type InventoryFormData = {
  locations: number[];
  name: string;
  type: InventoryType;
  unit: string;
};

export const inventorySchema: SchemaOf<InventoryFormData> = object().shape({
  locations: array().of(number()).required(requiredText),
  name: string().required(requiredText),
  type: mixed<InventoryType>().required(requiredText),
  unit: string().required(requiredText),
});
