import { Stack } from 'gantri-components';
import { MachineIssue } from '../../../../../../api/jobs/jobs.types';
import { IssueType } from '../../../../../../pages/machines/components/machine-issues/components/issue-type';

interface MachineIssuesProps {
  issues: MachineIssue[];
}

export const MachineIssues = ({ issues = [] }: MachineIssuesProps) => {
  return !!issues?.length ? (
    <Stack gap=".5x">
      {issues.map((issue) => {
        return <IssueType key={issue.id} issues={[issue]} showMoreInfo />;
      })}
    </Stack>
  ) : null;
};
