import { FC, useEffect, useState } from 'react';
import { useToggle } from 'react-use';
import { Button, Cell, Grid } from 'gantri-components';
import { HandoutsProps } from './handouts.types';
import { StyledPageSection } from './handouts.styles';
import { ContentSection } from './components/content-section';
import { ImagerySection } from './components/imagery-section';
import { ColorsSection } from './components/colors-section';
import { PreviewSection } from './components/preview-section';
import { PrintPreviewSection } from './components/print-preview-section';
import { useNotification } from '../../../../hooks/useNotification';

export const Handouts: FC<HandoutsProps> = (props) => {
  const { designerInfo, onProfileSave, updateDesignerInfo } = props;

  const { notifyError } = useNotification();

  const [backBackgroundColor, setBackBackgroundColor] = useState<string>(
    designerInfo?.handout?.backBackgroundColor ?? '#f4f4f4',
  );
  const [backTextColor, setBackTextColor] = useState<string>(
    designerInfo?.handout?.backTextColor ?? '#1c1c1c',
  );
  const [bio, setBio] = useState<string>(designerInfo?.handout?.bio ?? ' ');
  const [frontBackgroundColor, setFrontBackgroundColor] = useState<string>(
    designerInfo?.handout?.frontBackgroundColor ?? '#ffffff',
  );
  const [frontTextColor, setFrontTextColor] = useState<string>(
    designerInfo?.handout?.frontTextColor ?? '#1c1c1c',
  );
  const [nameIsVisible, setNameIsVisible] = useToggle(
    designerInfo?.handout?.nameIsVisible ?? true,
  );
  const [preparingPdf, setPreparingPdf] = useToggle(false);

  const getDesignerData = () => {
    return {
      ...designerInfo,
      handout: {
        ...designerInfo.handout,
        backBackgroundColor,
        backTextColor,
        bio,
        frontBackgroundColor,
        frontTextColor,
        nameIsVisible,
      },
    };
  };

  const handleDownload = async () => {
    try {
      setPreparingPdf(true);

      const designerData = getDesignerData();

      await onProfileSave(designerData);
    } catch (error) {
      notifyError('An error occurred downloading pdf handout.');
    } finally {
      setPreparingPdf(false);
    }
  };

  useEffect(() => {
    const designerData = getDesignerData();

    updateDesignerInfo({ type: 'state', value: designerData });
  }, [
    backBackgroundColor,
    backTextColor,
    bio,
    frontBackgroundColor,
    frontTextColor,
    nameIsVisible,
  ]);

  return (
    <StyledPageSection>
      <Grid
        alignItems="start"
        columns={{ lg: 2, md: 1, sm: 1 }}
        gap="2rem"
        marginBottom="4rem"
        width="100%"
      >
        <Cell>
          <ColorsSection
            backBackgroundColor={backBackgroundColor}
            backTextColor={backTextColor}
            frontBackgroundColor={frontBackgroundColor}
            frontTextColor={frontTextColor}
            setBackBackgroundColor={setBackBackgroundColor}
            setBackTextColor={setBackTextColor}
            setFrontBackgroundColor={setFrontBackgroundColor}
            setFrontTextColor={setFrontTextColor}
          />
          <ImagerySection
            designerInfo={designerInfo}
            updateDesignerInfo={updateDesignerInfo}
          />
          <ContentSection
            bio={bio}
            nameIsVisible={nameIsVisible}
            setBio={setBio}
            setNameIsVisible={setNameIsVisible}
          />
        </Cell>
        <Cell>
          <PreviewSection designerInfo={designerInfo} />
        </Cell>
      </Grid>
      <Button
        processing={preparingPdf}
        text="Download PDF"
        onClick={handleDownload}
      />
      <PrintPreviewSection designerInfo={designerInfo} />
    </StyledPageSection>
  );
};
