import {
  Box,
  Conditional,
  Flex,
  Icon,
  Stack,
  TextArea,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { StyledStack } from './job-failed-recommended-next-step-content.styles';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { useRecommendedNextSteps } from './hooks/use-recommended-next-steps';
import { RecommendedStepOption } from './components/recommended-step-option';

export const JobFailedRecommendedNextStepContent = () => {
  const [failedNextStep, setFailedNextStep] = useRecoilState(
    finishingQcModalAtoms.failedNextStep,
  );
  const setFailedNextStepDetails = useSetRecoilState(
    finishingQcModalAtoms.failedNextStepDetails,
  );
  const systemRecommendedStep = useRecoilValue(
    finishingQcModalAtoms.systemRecommendedStep,
  );

  const { nextStepOptions } = useRecommendedNextSteps();

  const recommendedAction =
    systemRecommendedStep === 'JOB_FAILED_RESTART' ? 'Re-start' : 'Re-finish';

  return (
    <Stack gap="x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.finish} ${jobTypeOptions.qc} job failed`}
      />
      <Stack gap="4x">
        <Conditional condition={!!systemRecommendedStep}>
          <Stack gap="4x">
            <Typography
              htmlText={
                !!systemRecommendedStep
                  ? `Based on the failure mode(s) selected, we recommend that you <strong style="white-space: nowrap">${recommendedAction}</strong> the part.`
                  : undefined
              }
            />
            <Stack gap="2x">
              {nextStepOptions.map(({ icon, items, label }) => {
                return (
                  <Stack key={icon} gap="x">
                    <Flex alignItems="center" gap="x">
                      <Icon color="t2" name={icon} />
                      <Typography text={label} />
                    </Flex>
                    <Box paddingLeft="2x">
                      <StyledStack padding="2x">
                        {items.map(({ label, step, subText }) => {
                          return (
                            <RecommendedStepOption
                              key={step}
                              label={label}
                              selectedNextStep={failedNextStep}
                              setSelectedNextStep={setFailedNextStep}
                              step={step}
                              subText={subText}
                              systemRecommendedStep={systemRecommendedStep}
                            />
                          );
                        })}
                      </StyledStack>
                    </Box>
                  </Stack>
                );
              })}
              <Conditional condition={failedNextStep === 'JOB_FAILED_RESTART'}>
                <TextArea
                  labelText="Add additional information about the failure:"
                  placeholder="Enter details about the failed part"
                  required
                  onTextChange={setFailedNextStepDetails}
                />
              </Conditional>
            </Stack>
          </Stack>
        </Conditional>
      </Stack>
    </Stack>
  );
};
