import { useState } from 'react';
import { Grid, Typography } from 'gantri-components';
import { useAsync } from 'react-use';
import { DetailsPanel } from '../common/details-panel';
import Timeline from '../common/timeline';
import { useNotification } from '../../hooks/useNotification';
import { versionApi } from '../../api';
import { History as HistoryType } from '../../api/version/version.types';
import { HistoryProps } from './history.types';
import { HistoryItem } from './components';
import { GetVersionType } from '../../pages/product/components/product-jobs/components/product-jobs-header/product-jobs-header.types';

export const History = (props: HistoryProps) => {
  const { getVersion, onClose, open, productId } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();
  const [history, setHistory] = useState<HistoryType[]>([]);

  const fetchHistory = async () => {
    try {
      showLoading();

      const response = await versionApi.getVersionHistory({ productId });

      if (response?.data?.success) {
        setHistory(response.data?.versionHistory || []);
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Error fetching history',
      });
    } finally {
      hideLoading();
    }
  };

  const handleVersionFetch = (value: GetVersionType) => {
    getVersion(value);
    onClose();
  };

  useAsync(async () => {
    await fetchHistory();
  }, []);

  return (
    <DetailsPanel
      header={
        <Grid columns="1fr repeat(2, max-content)" gap="1rem">
          <Typography
            style={{ fontSize: '2.2rem', lineHeight: '2.8rem' }}
            text="History"
            textStyle="bold"
            variant="h4"
          />
        </Grid>
      }
      open={open}
      onClose={onClose}
    >
      {history && (
        <Timeline
          getVersion={handleVersionFetch}
          isTitleVisible={false}
          items={history}
          renderItem={HistoryItem}
        />
      )}
    </DetailsPanel>
  );
};
