import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { EditLocationResponse } from './edit-location.types';

export const editLocation = async ({ location }) => {
  const res = await axios.put<EditLocationResponse>(
    `${coreApiUrl}/locations`,
    location,
  );

  return res.data;
};
