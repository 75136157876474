import pick from 'lodash/pick';
import { ConfirmDeliveryFormState } from './confirm-delivery-modal.types';

export const convertFormDataToShipmentData = (
  values: ConfirmDeliveryFormState,
) => {
  return {
    photos:
      values.photos?.map(({ url }) => {
        return url;
      }) ?? [],
    ...pick(values, [
      'notes',
      'deliveredAt',
      'discardedQuantity',
      'expectedQuantity',
      'quantity',
    ]),
  };
};
