import PropTypes from 'prop-types';
import { Button } from 'gantri-components';
import {
  StyledCloseButton,
  StyledModalButtonsContainer,
  StyledModalContent,
  StyledModalWrapper,
  StyledOuterContainer,
} from '../modals-styles.module';

const SelectionModal = ({
  cancelButtonArchetype,
  cancelButtonText,
  cancelButtonVisible,
  children,
  classnames,
  confirmButtonArchetype,
  confirmButtonText,
  confirmButtonVisible,
  confirmDisabled,
  footer,
  loading,
  onCancel,
  onClose,
  onConfirm,
  showaCloseButton,
  width,
}) => {
  const handleConfirmClick = (e) => {
    onConfirm(e);
  };

  const handleCancelClick = (e) => {
    onCancel(e);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose(e);
  };

  const handleClick = (e) => {
    e.stopPropagation();
  };

  return (
    <StyledOuterContainer width={width} onClick={handleClick}>
      {!showaCloseButton || loading ? null : (
        <StyledCloseButton
          className="x-icon-white"
          role="button"
          tabIndex="0"
          onClick={handleClose}
        />
      )}
      <StyledModalWrapper className={classnames}>
        <StyledModalContent>{children}</StyledModalContent>

        <StyledModalButtonsContainer>
          {cancelButtonVisible && (
            <Button
              text={cancelButtonText}
              variant={cancelButtonArchetype}
              onClick={handleCancelClick}
            />
          )}
          {confirmButtonVisible && (
            <Button
              disabled={confirmDisabled}
              processing={loading}
              text={confirmButtonText}
              variant={confirmButtonArchetype}
              onClick={handleConfirmClick}
            />
          )}
        </StyledModalButtonsContainer>

        {footer}
      </StyledModalWrapper>
    </StyledOuterContainer>
  );
};

SelectionModal.defaultProps = {
  cancelButtonArchetype: 'secondary',
  cancelButtonText: 'Cancel',
  cancelButtonVisible: true,
  confirmButtonArchetype: 'primary',
  confirmButtonText: 'Confirm',
  confirmButtonVisible: true,
  confirmDisabled: false,
  footer: null,
  loading: false,
  showaCloseButton: true,
};

SelectionModal.propTypes = {
  cancelButtonArchetype: PropTypes.string,
  cancelButtonText: PropTypes.string,
  cancelButtonVisible: PropTypes.bool,
  children: PropTypes.node,
  classnames: PropTypes.string,
  confirmButtonArchetype: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmButtonVisible: PropTypes.bool,
  confirmDisabled: PropTypes.bool,
  footer: PropTypes.element,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  showaCloseButton: PropTypes.bool,
};

export default SelectionModal;
