import { Button, Modal, Typography } from 'gantri-components';
import { inventoryPurchasesApi } from '../../../../../../../../../../../../api/inventory-purchases';
import { useNotification } from '../../../../../../../../../../../../hooks/useNotification';
import { DeleteShipmentModalProps } from './delete-shipment-modal.types';

export const DeleteShipmentModal = (props: DeleteShipmentModalProps) => {
  const { onClose, onUpdate, shipmentId, shipmentNumber } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handleDelete = async () => {
    try {
      showLoading();

      const { data } =
        await inventoryPurchasesApi.deleteInventoryPurchaseShipment(shipmentId);

      onUpdate(data.purchase);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to delete shipment.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            size="large"
            text="Delete"
            variant="primaryAlert"
            onClick={handleDelete}
          />
        </>
      }
      header="Delete Shipment"
      width={{ lg: '42rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        text={`Are you sure you want to delete "Shipment #${shipmentNumber}" from this purchase?`}
      />
    </Modal>
  );
};
