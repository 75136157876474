import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { Vendor } from '../../vendors.types';

interface UpdateVendorArgs extends Partial<Vendor> {}

interface UpdateVendorResponse {
  notice: string;
  success: boolean;
  vendor: Vendor;
}

export const updateVendor = (vendorId: number, body: UpdateVendorArgs) => {
  return axios.put<UpdateVendorResponse>(
    `${coreApiUrl}/vendors/${vendorId}/update`,
    body,
  );
};
