import PropTypes from 'prop-types';
import { Typography } from 'gantri-components';
import {
  StyledProgress,
  StyledProgressBar,
  StyledProgressBarWrapper,
} from './progress-bar.styles';

// @deprecated
// This component is deprecated and will be removed in the future.
// Please use LinearProgress from gantri-components instead.
const ProgressBar = ({
  current = 0,
  max = 100,
  proportionVisible = true,
  width = '100px',
}) => {
  const percentCompletion = Math.floor((current / max) * 100) || 0;

  return (
    <StyledProgressBarWrapper>
      <StyledProgressBar width={width}>
        <StyledProgress progress={percentCompletion} />
      </StyledProgressBar>
      <Typography
        color={percentCompletion >= 100 ? 'link' : 't1'}
        text={`${percentCompletion}%`}
      />
      {proportionVisible && (
        <Typography color="t1" text={`(${current} / ${max})`} />
      )}
    </StyledProgressBarWrapper>
  );
};

ProgressBar.propTypes = {
  current: PropTypes.number,
  max: PropTypes.number,
  proportionVisible: PropTypes.bool,
};

export default ProgressBar;
