import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  ReassignRecord,
  ReassignStocksResponse,
} from './reassign-stocks.types';

export const reassignStocks = (
  orderId: number,
  reassignRecords: ReassignRecord[],
) => {
  return axios.post<ReassignStocksResponse>(`${coreApiUrl}/stocks/re-assign`, {
    orderId,
    reassignData: reassignRecords,
  });
};
