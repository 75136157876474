import {
  Conditional,
  FormikInput,
  TextField,
  Typography,
} from 'gantri-components';
import { formatAsLocaleNumber } from '../../../../../../../../../../../../helpers/formatter';
import { useIsEditingPartRowJobsDataForm } from '../../../../../../hooks/use-is-editing-part-row';
import { PartJobBlocksPrintDurationProps } from './part-job-blocks-print-duration.types';

export const PartJobBlocksPrintDuration = (
  props: PartJobBlocksPrintDurationProps,
) => {
  const { index, printBlock, printDuration, replace } = props;

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  return (
    <Conditional
      condition={isEditingPartRow}
      Fallback={
        <Typography
          text={printDuration ? `${formatAsLocaleNumber(printDuration)}m` : '-'}
        />
      }
    >
      <FormikInput
        Field={
          <TextField
            placeholder="Input duration"
            rightIcon={<Typography color="t2" text="m" />}
            type="number"
            onTextChange={(printDuration) => {
              return replace(index, {
                ...printBlock,
                printDuration: printDuration
                  ? Number(printDuration)
                  : undefined,
              });
            }}
          />
        }
        fieldVariant="standard"
        name={`printBlock[${index}].printDuration`}
        required
      />
    </Conditional>
  );
};
