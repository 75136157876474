import { useState } from 'react';
import { Button, Modal, Stack, TextField, Typography } from 'gantri-components';
import { useToggle } from 'react-use';
import { useNotification } from '../../../../hooks/useNotification';
import { designersApi } from '../../../../api';

export const InviteDesignerModal = ({ onClose }: { onClose: () => void }) => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [processing, toggleProcessing] = useToggle(false);

  const { notify, notifyAxiosError } = useNotification();

  const disabled = !fullName || !email;

  const { notifyError } = useNotification();

  const validateName = () => {
    const nameArray = fullName.split(' ');

    if (nameArray.length <= 1) {
      notifyError('Please provide a Full Name');

      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    try {
      toggleProcessing();

      if (validateName()) {
        const [firstName, lastName] = fullName.split(' ');

        const { data } = await designersApi.sendInvite({
          email,
          firstName,
          lastName,
        });

        notify(data.notice);

        onClose();
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to send designer invite.',
      });
    } finally {
      toggleProcessing();
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={disabled}
            processing={processing}
            size="large"
            text="Send Invite"
            variant="primary"
            onClick={onSubmit}
          />
        </>
      }
      header={
        <Typography
          align="center"
          text="Invite Designer"
          textStyle="bold"
          variant="h4"
        />
      }
      width={{ lg: '42rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack gap="s1">
        <TextField
          placeholder="Full Name"
          required
          value={fullName}
          onTextChange={setFullName}
        />
        <TextField
          placeholder="Email"
          required
          value={email}
          onTextChange={setEmail}
        />
      </Stack>
    </Modal>
  );
};
