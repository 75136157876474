import { atom } from 'recoil';
import { RejectReason, RejectJobModalStep } from './reject-job-modal.types';
import { NextStep } from '../../../pages/jobs/components/modals/common/job-checklist/job-checklist.types';

export const atomKeyPrefix = 'REJECT_JOB_MODAL';

export const rejectJobModalAtoms = {
  nextStep: atom<NextStep>({
    default: undefined,
    key: `${atomKeyPrefix}-next-step`,
  }),
  rejectDetails: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-reject-details`,
  }),
  rejectReason: atom<RejectReason>({
    default: undefined,
    key: `${atomKeyPrefix}-reject-reason`,
  }),
  step: atom<RejectJobModalStep>({
    default: 'REJECT_REASON',
    key: `${atomKeyPrefix}-step`,
  }),
};
