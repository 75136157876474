import { ActiveBatch } from '../../../../../../../../../../../api/batch/routes/get-all-active-batches/get-all-active-batches.types';

export const batchSearchFilterFn = (
  searchString: string,
  item: ActiveBatch,
) => {
  const searchPattern = new RegExp(searchString, 'i');

  const idMatches = searchPattern.test(String(item.id));
  const colorsMatch = item.colors.some((colorName) => {
    return searchPattern.test(colorName);
  });

  return idMatches || colorsMatch;
};
