import { Flex, Icon, Overlay, TextField, Typography } from 'gantri-components';
import React from 'react';
import { useDatePicker } from './hooks/use-date-picker';
import { DatePickerProps } from './date-picker.types';
import { datePickerDefaultProps } from './date-picker.presets';
import { DatePickerDialog } from './components/date-picker-dialog';
import {
  StyledContent,
  StyledDateRange,
  StyledToggleDate,
} from './date-picker.styles';

export const DatePicker = (props: DatePickerProps) => {
  const {
    Trigger,
    allowFutureDays,
    allowFutureDaysOnly,
    autoWidth,
    calendarIconVisible,
    disabled,
    fromPlaceholder,
    initialValue,
    maximumDatesInRange,
    onDateChanged,
    placeholder,
    range,
    required,
    resetDates,
    style,
    toPlaceholder,
    value,
  } = props;

  const from =
    initialValue && (initialValue.from || initialValue.value)
      ? new Date(initialValue.from || initialValue.value)
      : null;
  const to = initialValue && initialValue.to ? new Date(initialValue.to) : null;

  const {
    daysInMonth,
    getDayStatus,
    goToNextMonth,
    goToPreviousMonth,
    isNextMonthActive,
    isPreviousMonthActive,
    onResetDates,
    opened,
    selection: { formattedFrom, formattedMonth, formattedTo },
    setDay,
    toggleOpened,
  } = useDatePicker({
    allowFutureDays,
    allowFutureDaysOnly,
    from,
    initialValue,
    maximumDatesInRange,
    onDateChanged,
    range,
    resetDates,
    to,
  });

  if (disabled) {
    return (
      <TextField
        disabled
        rightIcon={<Icon name="time:calendar" />}
        value={value ?? String(initialValue?.value) ?? ''}
      />
    );
  }

  return (
    <Overlay
      content={
        <DatePickerDialog
          daysInMonth={daysInMonth}
          formattedMonth={formattedMonth}
          getDayStatus={getDayStatus}
          goToNextMonth={goToNextMonth}
          goToPreviousMonth={goToPreviousMonth}
          isNextMonthActive={isNextMonthActive}
          isPreviousMonthActive={isPreviousMonthActive}
          resetDates={onResetDates}
          setDay={setDay}
        />
      }
      open={opened}
      position="bottom"
      onClose={toggleOpened}
      onOpen={toggleOpened}
    >
      {Trigger ? (
        // @ts-ignore
        <Trigger open={opened} />
      ) : (
        <Flex direction="column">
          <StyledDateRange $autoWidth={autoWidth} $range={range} style={style}>
            <StyledContent $hasValue={!!formattedFrom}>
              {range && <div data-flex-spacer="" />}
              <StyledToggleDate $isFilledIn={!!formattedFrom}>
                {formattedFrom || (range ? fromPlaceholder : placeholder)}
              </StyledToggleDate>
              {range && (
                <>
                  <Icon name="arrows:arrow_right" />
                  <StyledToggleDate $isFilledIn={!!formattedTo}>
                    {formattedTo || toPlaceholder}
                  </StyledToggleDate>
                </>
              )}
              {calendarIconVisible && <Icon name="time:calendar" />}
            </StyledContent>
          </StyledDateRange>

          {required && (
            <Typography
              color="t2"
              marginTop="4px"
              text="Required"
              variant="p3"
            />
          )}
        </Flex>
      )}
    </Overlay>
  );
};

DatePicker.defaultProps = datePickerDefaultProps;
