import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { resetTimeUntilMaintenance } from './reset-time-until-maintenance';
import { ResetTimeUntilMaintenanceResponse } from './reset-time-until-maintenance.types';

export const useResetTimeUntilMaintenance = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onResetTimeUntilMaintenance, ...rest } = useQueryMutation<
    number,
    ResetTimeUntilMaintenanceResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to reset hours until maintenance.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(notice);
      },
    },
    mutationFn: async (machineId) => {
      return resetTimeUntilMaintenance(machineId).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onResetTimeUntilMaintenance };
};
