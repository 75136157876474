import { Stack, TextArea, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { InventoryChecklistStep } from '../../../../../../common/inventory-checklist-step';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startNonProductJobModalAtoms } from '../../../start-non-product-job-modal.atoms';
import { UpdateInventoryContentProps } from './update-inventory-content.types';

export const UpdateInventoryContent = (props: UpdateInventoryContentProps) => {
  const { job } = props;

  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    startNonProductJobModalAtoms.inventoryRequests,
  );
  const [notes, setNotes] = useRecoilState(startNonProductJobModalAtoms.notes);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        subTitleText="Input inventory, add any additional notes, and complete job."
        titleText="Complete job"
      />
      <Stack gap="x">
        <Typography text="Inventory" variant="h5" />
        <InventoryChecklistStep
          inventoryRequests={inventoryRequests}
          jobStep={
            job.type // intentionally using job type to match design
          }
          setInventoryRequests={setInventoryRequests}
        />
      </Stack>
      <Stack gap="x">
        <Typography text="Notes" variant="h5" />
        <TextArea
          debounce={300}
          placeholder="Add a note..."
          value={notes}
          onTextChange={setNotes}
        />
      </Stack>
    </Stack>
  );
};
