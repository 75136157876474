import { Flex, Icon, Typography } from 'gantri-components';
import { FooterAlertTextProps } from './footer-alert-text.types';

export const FooterAlertText = ({
  color,
  iconName,
  text,
}: FooterAlertTextProps) => {
  return (
    <Flex alignItems="center" gap="0.6rem" justifyContent="center">
      <Icon color={color} name={iconName} />
      <Typography
        align="center"
        color={color}
        data-testid="footer-alert-message"
        text={text}
      />
    </Flex>
  );
};
