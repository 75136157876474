import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  GetJobTemplatesArgs,
  GetJobTemplatesResponse,
} from './fetch-product-part-templates.types';

export const fetchProductPartTemplates = (args: GetJobTemplatesArgs) => {
  const { color, partName, versionId } = args;

  return axios
    .get<GetJobTemplatesResponse>(
      `${getCoreApiUrl(
        'product-part-templates',
      )}/${color}/${versionId}/${encodeURIComponent(partName)}`,
    )
    .then(({ data }) => {
      return data;
    });
};
