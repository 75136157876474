import { atom } from 'recoil';
import { JobTemplateWithId } from './jobs-template-table.types';

const atomKeyPrefix = 'JOBS_TEMPLATE_TABLE';

export const jobsTemplateTableAtoms = {
  editingTemplateId: atom<number | string>({
    default: null,
    key: `${atomKeyPrefix}-editing-template-id`,
  }),
  fieldErrors: atom<(keyof JobTemplateWithId)[]>({
    default: [],
    key: `${atomKeyPrefix}-field-errors`,
  }),
  isSavingChanges: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-saving-changes`,
  }),
};
