import {
  Conditional,
  Flex,
  Icon,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { startCase } from 'lodash';
import { machineStatuses } from '../../../../../../../constants/machines';
import { ModalContentHeading } from '../../../../../../jobs/components/modals/common/modal-content-heading';
import { setMachinesOnlineModalAtoms } from '../../../set-machines-online-modal.atoms';
import { useGetBulkUpdateMachinesColumns } from '../../../../../hooks/use-get-bulk-update-machines-columns';

export const ReviewOnlinedMachinesContent = () => {
  const hasMachinesWithInvalidStatuses = useRecoilValue(
    setMachinesOnlineModalAtoms.hasMachinesWithInvalidStatuses,
  );
  const detailsPanelMachines = useRecoilValue(
    setMachinesOnlineModalAtoms.detailsPanelMachines,
  );
  const machinesOnlined = useRecoilValue(
    setMachinesOnlineModalAtoms.machinesOnlined,
  );
  const machinesNotOnlined = useRecoilValue(
    setMachinesOnlineModalAtoms.machinesNotOnlined,
  );
  const numMachinesOnlined = machinesOnlined.length;
  const numMachinesNotOnlined = machinesNotOnlined.length;

  const machinesOnlineColumns = useGetBulkUpdateMachinesColumns();
  const machinesOfflineColumns = useGetBulkUpdateMachinesColumns({
    machines: detailsPanelMachines,
    showIssues: true,
  });

  return (
    <Stack gap="6x">
      <Stack gap="3x">
        <Conditional
          condition={!!numMachinesOnlined}
          Fallback={
            <ModalContentHeading
              color="alert"
              titleText={`${machinesNotOnlined.length} machine(s) not onlined due to open issues`}
            />
          }
        >
          <ModalContentHeading
            color="success"
            titleText={`${machinesOnlined.length} machine(s) onlined successfully`}
          />
        </Conditional>

        <Conditional condition={hasMachinesWithInvalidStatuses}>
          <Typography
            text={`Some machines were in ${
              machineStatuses.ready
            } or ${startCase(
              machineStatuses.inProgress,
            )} status and were not eligible to online.`}
          />
        </Conditional>
      </Stack>

      <Conditional condition={!!numMachinesOnlined}>
        <Stack gap="x">
          <Typography text="Machines onlined" variant="h5" />
          <Table columns={machinesOnlineColumns} data={machinesOnlined} />
        </Stack>
      </Conditional>

      <Conditional condition={!!numMachinesNotOnlined}>
        <Stack gap="x">
          <Flex alignItems="baseline" gap="x">
            <Conditional condition={!!numMachinesOnlined}>
              <Icon
                color="alert"
                name="alert:warning_triangle_24"
                size="2.4rem"
                top="3px"
              />
            </Conditional>
            <Conditional
              condition={!!numMachinesOnlined}
              Fallback={<Typography text="Machines not onlined" variant="h5" />}
            >
              <Typography
                text={`${numMachinesNotOnlined} machine(s) not onlined due to open issues`}
                variant="h5"
              />
            </Conditional>
          </Flex>
          <Table columns={machinesOfflineColumns} data={machinesNotOnlined} />
        </Stack>
      </Conditional>
    </Stack>
  );
};
