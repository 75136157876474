import React, { FC } from 'react';
import { Button } from 'gantri-components';

export const DownloadFooterFilesStep: FC<{
  isValid: boolean;
  onBack: () => void;
  processing: boolean;
}> = (props) => {
  const { isValid, onBack, processing = false } = props;

  return (
    <>
      <Button size="large" text="Back" variant="secondary" onClick={onBack} />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text="Save"
        type="submit"
      />
    </>
  );
};
