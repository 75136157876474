import { Conditional, GetRowProps } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { TableSortField } from '../../components/common/table/table.props';
import { DesignsSortingField } from '../../components/dropdowns/designs-filter/designs-filter.types';
import { StyledThumbnailCell } from '../../assets/styles/common';
import Thumbnail from '../../components/common/thumbnail/thumbnail';
import { formatDate } from '../../helpers/formatter';
import { DesignRecord } from '../../api/designs/routes/fetch-paginated-designs/fetch-paginated-designs.types';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';

export const sortOptions: TableSortField<DesignsSortingField>[] = [
  { bidirectional: true, label: 'Name', sortingField: 'name' },
  {
    bidirectional: true,
    label: 'Category',
    sortingField: 'category',
  },
  { bidirectional: true, label: 'Step', sortingField: 'step' },
  { bidirectional: true, label: 'Status', sortingField: 'status' },
  {
    bidirectional: true,
    label: 'Created Date',
    sortingField: 'createdAtSec',
  },
  {
    bidirectional: true,
    label: 'Last Updated Date',
    sortingField: 'updatedAtSec',
  },
];

export const columns: ColumnDef<DesignRecord>[] = [
  {
    accessorKey: 'id',
    cell: ({ getValue }: CellContext<DesignRecord, DesignRecord['id']>) => {
      const id = getValue();

      return (
        <StyledAnchor href={`${routePaths.designs}/${id}`} text={`#${id}`} />
      );
    },
    header: 'Design #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'name',
    cell: ({
      getValue,
      row,
    }: CellContext<DesignRecord, DesignRecord['name']>) => {
      return (
        <StyledThumbnailCell>
          <Thumbnail size="6rem" src={row.original.thumbnailImage} />
          {getValue()}
        </StyledThumbnailCell>
      );
    },
    header: 'Name',
  },
  {
    accessorKey: 'designer',
    cell: ({
      getValue,
    }: CellContext<DesignRecord, DesignRecord['designer']>) => {
      const designer = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.designers}/${designer.id}`}
          target="_blank"
          text={designer.name}
        />
      );
    },
    header: 'Designer',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
  {
    accessorKey: 'step',
    header: 'Step',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  {
    accessorKey: 'createdAtSec',
    cell: ({
      getValue,
    }: CellContext<DesignRecord, DesignRecord['createdAtSec']>) => {
      return formatDate(getValue());
    },
    header: 'Created',
    size: 80,
  },
  {
    accessorKey: 'updatedAtSec',
    cell: ({
      getValue,
    }: CellContext<DesignRecord, DesignRecord['updatedAtSec']>) => {
      return formatDate(getValue());
    },
    header: 'Last Updated',
    minSize: 100,
  },
  {
    accessorKey: 'action',
    cell: ({
      getValue,
      row,
    }: CellContext<DesignRecord, DesignRecord['action']>) => {
      const action = getValue();

      const variant = action?.color === 'primary' ? 'link' : 'alert';

      return (
        <Conditional condition={!!action}>
          <StyledAnchor
            color={variant}
            text={`${action?.label}`}
            to={`${routePaths.designs}/${row.original.id}`}
          />
        </Conditional>
      );
    },
    header: 'Action',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
];

export const getRowProps: GetRowProps<DesignRecord> = ({ original }) => {
  return {
    'data-design-id': original.id,
  };
};
