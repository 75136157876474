import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { ReviewOfflineMachinesFooterProps } from './review-offline-machines-footer.types';

export const ReviewOfflineMachinesFooter = (
  props: ReviewOfflineMachinesFooterProps,
) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={handleCloseModal}
    />
  );
};
