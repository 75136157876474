import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { Conditional, Flex, Tooltip, Typography } from 'gantri-components';
import { useUpdateDesignerActiveStatus } from '../../../../api/designers/routes/activate/activate.query';
import { DesignerData } from '../../../../api/designers/routes/fetch-paginated-designers/fetch-paginated-designers.types';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenu } from '../../../../components/dropdowns';
import routePaths from '../../../../config/route-paths';
import { environment } from '../../../../environment';
import { formatDate } from '../../../../helpers/formatter';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetDesignersColumns = ({
  fetchDesigners,
}: {
  fetchDesigners: () => Promise<void>;
}) => {
  const { onUpdateDesignerActiveStatus } = useUpdateDesignerActiveStatus();

  const toggleDesignerStatus = async (userId: number, isActive: boolean) => {
    await onUpdateDesignerActiveStatus(
      { isActive, userId },
      { onSuccess: fetchDesigners },
    );
  };

  const columns: ColumnDef<DesignerData>[] = [
    {
      accessorKey: 'name',
      cell: ({
        getValue,
        row,
      }: CellContext<DesignerData, DesignerData['name']>) => {
        const name = getValue();

        return (
          <Conditional
            condition={!!row.original.id}
            Fallback={<Typography color="t1" text={name} />}
          >
            <StyledAnchor
              href={`${routePaths.designers}/${row.original.id}`}
              text={name}
            />
          </Conditional>
        );
      },
      header: 'Name',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
    },
    {
      accessorKey: 'profileLink',
      cell: ({
        getValue,
        row,
      }: CellContext<DesignerData, DesignerData['profileLink']>) => {
        const profileLink = getValue()?.trim();
        const isActive = row.original.status === 'Active';
        const href =
          isActive && profileLink
            ? `${environment.SHOP_URL}/${profileLink}`
            : undefined;

        return (
          <Conditional
            condition={!!profileLink}
            Fallback={<Typography text="-" />}
          >
            <Conditional
              condition={row.original.status === 'Active'}
              Fallback={
                <Tooltip
                  description="You must activate the designer before you can navigate to their URL."
                  position="top"
                >
                  <Typography color="t2" text={profileLink} />
                </Tooltip>
              }
            >
              <StyledAnchor href={href} target="_blank" text={profileLink} />
            </Conditional>
          </Conditional>
        );
      },
      header: 'URL',
      meta: {
        getIsClickable: ({
          getValue,
          row,
        }: Cell<DesignerData, DesignerData['profileLink']>) => {
          const profileLink = getValue();
          const isActive = row.original.status === 'Active';
          const isClickable = isActive && !!profileLink?.trim();

          return isClickable;
        },
      },
    },
    {
      accessorKey: 'designCount',
      header: 'Designs',
    },
    {
      accessorKey: 'productCount',
      header: 'Products',
    },
    {
      accessorKey: 'lastActive',
      cell: ({
        getValue,
      }: CellContext<DesignerData, DesignerData['lastActive']>) => {
        const lastActive = getValue();

        return (
          <Conditional
            condition={!!lastActive}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(lastActive)} />
          </Conditional>
        );
      },
      header: 'Last Active',
    },
    {
      accessorKey: 'status',
      cell: ({
        getValue,
        row,
      }: CellContext<DesignerData, DesignerData['status']>) => {
        return (
          <Typography
            color={row.original.active ? 'link' : 'alert'}
            text={getValue()}
          />
        );
      },
      header: 'Status',
    },
    {
      cell: ({ row }: CellContext<DesignerData, never>) => {
        return (
          <Flex justifyContent="flex-end">
            <MoreMenu
              data={row.original}
              options={[
                {
                  enabled: true,
                  name: row.original.active ? 'Deactivate' : 'Activate',
                  onOptionClick: ({ active, id }) => {
                    return toggleDesignerStatus(id, !active);
                  },
                },
              ]}
            />
          </Flex>
        );
      },
      id: 'menu',
      meta: { label: 'Actions' },
      size: 40,
    },
  ];

  return columns;
};
