import { useRecoilValue } from 'recoil';
import { setMachinesOnlineModalSteps } from '../../set-machines-online-modal.constants';
import { setMachinesOnlineModalAtoms } from '../../set-machines-online-modal.atoms';
import { ReviewOnlinedMachinesContent } from '../step-review-online-machines/review-online-machines-content';
import { ConfirmSetMachinesOnlineContent } from '../step-confirm-set-machines-online/confirm-set-machines-online-content';

export const SetMachinesOnlineModalContent = () => {
  const step = useRecoilValue(setMachinesOnlineModalAtoms.step);

  switch (step) {
    case setMachinesOnlineModalSteps.confirmSetMachinesOnline:
      return <ConfirmSetMachinesOnlineContent />;

    case setMachinesOnlineModalSteps.reviewOnlinedMachines:
      return <ReviewOnlinedMachinesContent />;

    default:
      return null;
  }
};
