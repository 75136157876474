import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';

export const ConfirmBeginContent = () => {
  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to start ${jobSteps.finish} ${jobTypeOptions.qc}?`}
      titleText="Start job"
    />
  );
};
