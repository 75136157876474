import React, { FC, PropsWithChildren } from 'react';
import { ContentProps } from './content.types';
import { ContentPresets } from './content.presets';
import { StyledFloatingContent } from './content.styles';

export const Content: FC<PropsWithChildren<ContentProps>> = (props) => {
  const { children } = props;

  return (
    <div>
      <StyledFloatingContent>{children}</StyledFloatingContent>
    </div>
  );
};

Content.defaultProps = ContentPresets;
