import { useState } from 'react';
import Timeline from '../../../../components/common/timeline';
import TimelineItem from '../../../../components/common/timeline/base-timeline-item';
import { SectionHeading } from '../section-heading';
import { AttachmentsSectionProps } from './timeline-section.types';

export const maxPerPage = 10;

export const TimelineSection = (props: AttachmentsSectionProps) => {
  const { editedInventoryPurchase } = props;
  const { timelines } = editedInventoryPurchase;

  const [currentPage, setCurrentPage] = useState<number>(1);

  const total = timelines.length;
  const pageCount = Math.ceil(total / maxPerPage);
  const onPageChange = setCurrentPage;

  const items = timelines.slice(
    (currentPage - 1) * maxPerPage,
    currentPage * maxPerPage,
  );

  return (
    <>
      <SectionHeading text="Timeline" />
      <Timeline
        isTitleVisible={false}
        items={items}
        pageCount={pageCount}
        paginated
        renderItem={TimelineItem}
        timelineCurrentPage={currentPage}
        total={total}
        onPageChange={onPageChange}
      />
    </>
  );
};
