import { memo, ChangeEvent, KeyboardEvent } from 'react';
import { Grid, Cell, Typography, TextField, Toggle } from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { GlobalSettingProps } from './global-settings.types';
import { onlyNumberLetterDash } from '../../../../helpers/regex';

const Section = (props: GlobalSettingProps) => {
  const { notify, pageUrl, status, updateFeatureInfo } = props;

  const togglePublish = (value: boolean) => {
    if (value) {
      notify('Make sure to save changes to view page.');
    }

    updateFeatureInfo({
      key: 'status',
      type: 'key',
      value: value ? 'published' : 'unpublished',
    });
  };

  const onPageUrlChange = (event: ChangeEvent<HTMLInputElement>) => {
    updateFeatureInfo({
      key: 'pageUrl',
      type: 'key',
      value: event.target.value,
    });
  };

  const pageUrlValidation = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!onlyNumberLetterDash.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <StyledSectionContainer section="global-settings">
      <Typography
        marginBottom="1.2rem"
        marginTop="4.5rem"
        text="Global  settings"
        textStyle="bold"
        variant="h4"
      />
      <Grid columns={{ lg: 2, sm: 1 }}>
        <Cell>
          <Typography
            className="publish-toggle"
            marginBottom="-1.5rem"
            text="Published?"
            textStyle="bold"
          />
          <Toggle value={status === 'published'} onSelected={togglePublish} />
        </Cell>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Page url"
            placeholder="Page url..."
            value={pageUrl}
            onChange={onPageUrlChange}
            // @ts-ignore
            onKeyDown={pageUrlValidation}
          />
          <Typography
            text="URL can contain only letters, numbers and dash symbol ('-')"
            variant="p3"
          />
        </Cell>
      </Grid>
    </StyledSectionContainer>
  );
};

export const GlobalSettings = memo(Section);
