import { Grid, Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledNoteStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border-bottom: 1px solid ${theme.colors.dividers.t1};

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    `;
  }}
`;

export const StyledEditContainer = styled.div`
  padding: 1rem 1rem 0.3rem;

  input {
    border-bottom: unset;
  }
`;

export const StyledActionsGrid = styled(Grid)`
  & > p {
    cursor: pointer;
    user-select: none;
  }
`;
