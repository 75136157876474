import { GetPackingSlipDocProps } from '../../get-packing-slip-doc.types';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getStocksNotYetShipped = (props: GetPackingSlipDocProps) => {
  const { currentOrderData, stocks: stocksInThisShipment } = props;
  const { shipments, stocks: allStocks = [] } = currentOrderData;

  const stockIdsAlreadyShipped = shipments
    .filter(({ trackingNumber }) => {
      return !!trackingNumber;
    })
    .reduce<number[]>((accumulator, shipment) => {
      const { stocks: stocksAlreadyShipped } = shipment;
      const stockIds = stocksAlreadyShipped.map(({ id }) => {
        return id;
      });

      return [...accumulator, ...stockIds];
    }, []);

  const stockIdsInThisShipment = stocksInThisShipment.map(({ id }) => {
    return id;
  });

  const stocksNotYetShipped = allStocks.filter(({ id }) => {
    return (
      id && ![...stockIdsAlreadyShipped, ...stockIdsInThisShipment].includes(id)
    );
  });

  return stocksNotYetShipped;
};
