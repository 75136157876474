import { useState } from 'react';
import { DEFAULT_PAGING_SIZE } from '../constants/common';

export const usePagingState = <T>(
  itemsPerPage = DEFAULT_PAGING_SIZE,
  startingPage = 1,
) => {
  const [records, setRecords] = useState<T[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(startingPage);
  const [totalCount, setTotalCount] = useState<number>(0);

  return {
    /** Active page number. */
    currentPage,
    /** Max items that can appear on a page. */
    itemsPerPage,
    /** Total number of pages based on the number of items. */
    pages: Math.ceil(totalCount / itemsPerPage) || 1,
    /** The fetched data. */
    records,
    /** Sets the active page number. */
    setCurrentPage,
    /** Sets the fetched data. */
    setRecords,
    /** Sets the total number of matching records found. */
    setTotalCount,
    /** Total number of matching records found. */
    totalCount,
  };
};
