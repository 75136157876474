import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useDeletePrintPrepareQueueMutation } from '../../../../../../../../api/queue/print-prepare';
import { manageQueuesModalAtoms } from '../../../manage-queues-modal.atoms';
import { manageQueuesModalSteps } from '../../../manage-queues-modal.constants';
import { ConfirmDeleteFooterProps } from './confirm-delete-footer.types';

export const ConfirmDeleteFooter = (props: ConfirmDeleteFooterProps) => {
  const { handleCloseModal } = props;

  const setStep = useSetRecoilState(manageQueuesModalAtoms.step);
  const queueToDelete = useRecoilValue(manageQueuesModalAtoms.queueToDelete);

  const { isLoading, onDeletePrintPrepareQueue } =
    useDeletePrintPrepareQueueMutation({ onSuccess: handleCloseModal });

  return (
    <>
      <Button
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep(manageQueuesModalSteps.queuesList);
        }}
      />
      <Button
        processing={isLoading}
        text="Confirm"
        variant="primaryAlert"
        onClick={async () => {
          await onDeletePrintPrepareQueue(queueToDelete.id);
        }}
      />
    </>
  );
};
