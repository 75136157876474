import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  BulkStartPrintPrepareQueueSetJobsArgs,
  BulkStartPrintPrepareQueueSetJobsResponse,
} from './bulk-start-print-prepare-queue-set-jobs.types';

export const bulkStartPrintPrepareQueueSetJobs = (
  args: BulkStartPrintPrepareQueueSetJobsArgs,
) => {
  const { queueId, setIndex, ...body } = args;

  return axios
    .put<BulkStartPrintPrepareQueueSetJobsResponse>(
      `${coreApiUrl}/queue/print-prepare/${queueId}/${setIndex}/bulk/start`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
