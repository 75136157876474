import styled, { css } from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledUnitInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--colors-modal-pickers-background);
  border: ${({ hasError }) => {
    return hasError
      ? css`1px solid var(--colors-red)`
      : css`1px solid var(--colors-cream)`;
  }};

  & > input {
    min-width: 54px;
    background: none;
    border: none;
  }

  &:focus-within,
  &:hover {
    cursor: text;
    border: 1px solid var(--colors-black);
  }
`;

export const StyledUnitText = styled(Typography)`
  right: 8px;
  position: absolute;
  user-select: none;
`;
