import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { DeletePrintPrepareQueueResponse } from './delete-print-prepare-queue.types';

export const deletePrintPrepareQueue = (id: number) => {
  return axios
    .delete<DeletePrintPrepareQueueResponse>(
      `${coreApiUrl}/queue/print-prepare/${id}`,
    )
    .then(({ data }) => {
      return data;
    });
};
