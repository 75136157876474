import { useRecoilValue } from 'recoil';
import { newMachineIssuesModalSteps } from '../../new-machine-issues-modal.constants';
import { newMachineIssuesModalAtoms } from '../../new-machine-issues-modal.atoms';
import { ReviewCreatedIssuesContent } from '../step-review-created-issues/review-created-issues-content';
import { IssuesChecklistContent } from '../step-issues-checklist/issues-checklist-content';
import { NewMachineIssuesModalContentProps } from './new-machine-issues-modal-content.types';

export const NewMachineIssuesModalContent = (
  props: NewMachineIssuesModalContentProps,
) => {
  const { showBulkOptionsOnly } = props;

  const step = useRecoilValue(newMachineIssuesModalAtoms.step);

  switch (step) {
    case newMachineIssuesModalSteps.issuesChecklist:
      return (
        <IssuesChecklistContent showBulkOptionsOnly={showBulkOptionsOnly} />
      );

    case newMachineIssuesModalSteps.reviewCreatedIssues:
      return <ReviewCreatedIssuesContent />;

    default:
      return null;
  }
};
