import { getTypeColumnHeading } from '../get-type-column-heading';
import {
  ReportOrderQueries,
  ReportOrderRefundTableData,
  ReportOrdersTableData,
} from '../../report-orders.types';
import {
  ordersBaseTableColumns,
  ordersBaseSortingOptions,
  reportOrdersRefundsTableColumns,
  reportOrdersRefundsSortingOptions,
  defaultOrdersGroupBy,
} from '../../report-orders.constants';
import { ReportDetails } from './get-report-details.types';
import { getActiveQueries } from '../../../../../../helpers/checks';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const { groupBy = defaultOrdersGroupBy, orderType } =
    getActiveQueries<ReportOrderQueries>();
  const heading = getTypeColumnHeading();

  const isRefundPage = !!groupBy && !!orderType;

  const restTableColumns = isRefundPage
    ? reportOrdersRefundsTableColumns
    : ordersBaseTableColumns;

  const tableColumns: (
    | DownloadableTableColumn<ReportOrdersTableData>
    | DownloadableTableColumn<ReportOrderRefundTableData>
  )[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...restTableColumns,
  ];

  const restSortingOptions = isRefundPage
    ? reportOrdersRefundsSortingOptions
    : ordersBaseSortingOptions;

  const sortingOptions: (
    | SortOptionFromTable<ReportOrdersTableData>
    | SortOptionFromTable<ReportOrderRefundTableData>
  )[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...restSortingOptions,
  ];

  return {
    sortingOptions,
    tableColumns,
  };
};
