import { v4 as uuidv4 } from 'uuid';
import { useMemo } from 'react';
import { UseEditableInventoriesTableParams } from './inventory.props';
import { InventoryRequest } from '../../../api/jobs/jobs.types';
import {
  InventoryForJobs,
  useFetchAllInventoriesForJobsQuery,
} from '../../../api/inventories/routes/fetch-all-for-jobs';

export const useEditableInventoriesTable = ({
  inventoryRequests,
  limitToInventoryTypes,
  onEdit,
}: UseEditableInventoriesTableParams) => {
  const { inventories: allInventories, isLoading } =
    useFetchAllInventoriesForJobsQuery<InventoryForJobs[]>({
      transform: (response) => {
        return (
          response.inventories?.filter((inventory) => {
            return inventory.status === 'Active';
          }) ?? []
        );
      },
    });

  const inventoriesOptions = useMemo(() => {
    if (isLoading || !allInventories?.length) return [];

    return allInventories
      .filter((inventory) => {
        return limitToInventoryTypes?.length > 0
          ? limitToInventoryTypes.includes(inventory.type)
          : true;
      })
      .map((inventory) => {
        return {
          label: inventory.name,
          value: inventory.id,
        };
      });
  }, [isLoading, limitToInventoryTypes]);

  const onSelectInventory = (id, inventorySelectedId) => {
    const selectedInventory = allInventories.find((inventory) => {
      return inventory.id === parseInt(inventorySelectedId);
    });

    if (!selectedInventory) {
      return;
    }

    const newInventory = {
      id: selectedInventory.id,
      name: selectedInventory.name,
      stock: parseFloat(String(selectedInventory.stock)) || 0,
      unit: selectedInventory.unit || 'Each',
    };

    const inventoryId = newInventory.id;

    onChangeInventoryData(id, 'inventory', newInventory, { inventoryId });
  };

  const onChangeInventoryData = (
    id: string | number,
    field: keyof InventoryRequest,
    value: object | number = 0,
    extra: object = {},
  ) => {
    onEdit(
      inventoryRequests.map((inventoryRequest) => {
        return inventoryRequest.id === id
          ? {
              ...inventoryRequest,
              [field]: value,
              ...extra,
            }
          : inventoryRequest;
      }),
    );
  };

  const addNewInventory = () => {
    onEdit([
      ...inventoryRequests,
      {
        id: uuidv4(),
        inventory: {
          name: '',
          unit: '',
        },
        isNew: true,
        need: 0,
      },
    ]);
  };

  const removeInventoryRequest = (id) => {
    onEdit(
      inventoryRequests.filter((inventoryRequest) => {
        return inventoryRequest.id !== id;
      }),
    );
  };

  return {
    addNewInventory,
    inventoriesOptions,
    onChangeInventoryData,
    onSelectInventory,
    removeInventoryRequest,
  };
};
