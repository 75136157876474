import { Typography } from 'gantri-components';
import { useEffect, useState } from 'react';
import { buttonToggleGroupDefaultProps } from './button-toggle-group.presets';
import {
  StyledToggleButton,
  StyledToggleButtonGroup,
} from './button-toggle-group.styles';
import { ButtonToggleGroupProps } from './button-toggle-group.types';

export const ButtonToggleGroup = (props: ButtonToggleGroupProps) => {
  const {
    defaultItem,
    getActiveBackgroundColor,
    items,
    onChange,
    transformLabel,
    ...other
  } = props;

  const firstItem = items[0];
  const defaultItemFallback =
    typeof firstItem === 'string' ? firstItem : firstItem?.value;
  const [activeItem, setActiveItem] = useState<string>(
    defaultItem || defaultItemFallback,
  );

  useEffect(() => {
    if (defaultItem !== activeItem) {
      setActiveItem(defaultItem);
    }
  }, [defaultItem]);

  return (
    <StyledToggleButtonGroup items={items} {...other}>
      {items.map((item, index) => {
        const isItemString = typeof item === 'string';
        const value = isItemString ? item : item.value;
        const key = `${value}-${index}`;
        const isActive = value === activeItem;
        const activeBackgroundColor = getActiveBackgroundColor(value);

        const handleButtonClick = () => {
          if (!isActive) {
            setActiveItem(value);
            onChange(value);
          }
        };

        return (
          <StyledToggleButton
            key={key}
            activeBackgroundColor={activeBackgroundColor}
            aria-checked={isActive}
            aria-label={transformLabel(value)}
            isActive={isActive}
            role="radio"
            onClick={handleButtonClick}
          >
            {isItemString ? (
              <Typography
                align="center"
                color={isActive ? 'alt' : 't2'}
                text={value}
              />
            ) : (
              // @ts-ignore
              <item.Component isActive={isActive} value={value} />
            )}
          </StyledToggleButton>
        );
      })}
    </StyledToggleButtonGroup>
  );
};

ButtonToggleGroup.defaultProps = buttonToggleGroupDefaultProps;
