import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { FetchBatchProductsAndPartsResponse } from './fetch-batch-products-and-parts.types';

export const fetchBatchProductsAndParts = () => {
  return axios
    .get<FetchBatchProductsAndPartsResponse>(
      `${getCoreApiUrl('products')}/product-part-template-list`,
    )
    .then(({ data }) => {
      return data;
    });
};
