import { getTypeColumnHeading } from '../get-type-column-heading';
import { ReportSalesTableData } from '../../report-sales.types';
import {
  salesBaseTableColumns,
  salesBaseSortingOptions,
} from '../../report-sales.constants';
import { ReportDetails } from './get-report-details.types';
import { getGroupByIsVisible } from '../get-group-by-is-visible';
import { convertQueriesToString } from '../../../../../../helpers/checks';
import { ReportSalesGroupBy } from '../../../../../../api/dashboard/routes/get-report-sales/get-report-sales.types';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const heading = getTypeColumnHeading();

  const tableColumns: DownloadableTableColumn<ReportSalesTableData>[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...salesBaseTableColumns,
  ];

  const sortingOptions: SortOptionFromTable<ReportSalesTableData>[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...salesBaseSortingOptions,
  ];

  const groupByItems = [
    {
      label: 'Product Name',
      value: 'productId',
    },
    {
      label: 'Color',
      value: 'color',
    },
    {
      label: 'Creator',
      value: 'creatorId',
    },
    {
      label: 'Customer Type',
      value: 'customerType',
    },
    {
      label: 'Country',
      value: 'country',
    },
  ];

  const groupByOnChange = (groupBy: ReportSalesGroupBy): void => {
    const windowLocation = window.location.origin + window.location.pathname;
    const queriesString = convertQueriesToString({ groupBy });

    window.location.href = windowLocation + queriesString;
  };

  const groupByIsVisible = getGroupByIsVisible();

  return {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  };
};
