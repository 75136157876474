import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllInventoriesResponse } from './fetch-all.types';

export const fetchAllInventories = (): Promise<FetchAllInventoriesResponse> => {
  return axios({
    method: 'get',
    url: `${coreApiUrl}/inventory/all`,
  }).then((response) => {
    return {
      ...response.data,
      inventories:
        response.data.inventories?.sort((a, b) => {
          return a.name.localeCompare(b.name);
        }) ?? [],
    };
  });
};

fetchAllInventories.queryKey = () => {
  return ['/inventory/all'];
};
