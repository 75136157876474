import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { DuplicateJobArgs, DuplicateJobResponse } from './duplicate-job.types';

export const duplicateJob = ({ jobId, ...body }: DuplicateJobArgs) => {
  return axios.post<DuplicateJobResponse>(
    `${coreApiUrl}/jobs/${jobId}/duplicate`,
    body,
  );
};
