import { number, object, SchemaOf } from 'yup';
import { PreparePrintsModalFormData } from './create-print-prepare-queue-modal.types';

export const getCreatePrintPrepareQueueSchema = ({
  availableJobs,
}: {
  availableJobs: number;
}): SchemaOf<PreparePrintsModalFormData> => {
  return object().shape({
    amount: number()
      .min(1, 'Number cannot be lower than 1')
      .max(availableJobs, `Number cannot be higher than ${availableJobs}`)
      .required('Required'),
  });
};
