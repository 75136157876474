import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CompleteJobArgs, CompleteJobResponse } from './complete-job.types';

export const completeJob = ({ jobId, printJobId, reason }: CompleteJobArgs) => {
  return axios
    .post<CompleteJobResponse>(`${coreApiUrl}/jobs/${jobId}/complete`, {
      printJobId,
      reason,
    })
    .then(({ data }) => {
      return data;
    });
};
