import { DependencyList, useEffect } from 'react';
import { useWindowSize } from '../use-window-size';

/** Executes the `callback` once, then on every window resize or dependencies change. */
export const useOnWindowResize = (
  callback: () => void,
  dependencies: DependencyList = [],
) => {
  const windowSize = useWindowSize();

  useEffect(() => {
    callback();
  }, [windowSize, ...dependencies]);
};
