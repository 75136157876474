import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { AddJobFormValues } from '../../../add-job-modal.types';
import { AddJobFormFooterProps } from './add-job-form-footer.types';

export const AddJobFormFooter = (props: AddJobFormFooterProps) => {
  const { handleCloseModal } = props;

  const { isValid } = useFormikContext<AddJobFormValues>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button disabled={!isValid} size="large" text="Add job" type="submit" />
    </>
  );
};
