import React, { FC } from 'react';
import { Button } from 'gantri-components';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { jobsApi, machinesApi } from '../../../../../../../../api';
import { ConfirmCompleteRepairFooterProps } from './confirm-complete-repair-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../../../jobs/components/modals/modals.constants';

export const ConfirmCompleteRepairFooter: FC<
  ConfirmCompleteRepairFooterProps
> = (props) => {
  const {
    inventoryRequests,
    jobId,
    notes,
    selectedIssues,
    setStep,
    setUpdateOnClose,
  } = props;
  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handleCompleteRepairJob = async () => {
    try {
      showLoading();

      const issueIds = selectedIssues.map(({ id }) => {
        return id;
      });

      await jobsApi.updateJob({
        inventoryRequests,
        issues: issueIds,
        jobId,
        notes,
      });

      await machinesApi.completeRepair(jobId);

      setUpdateOnClose(false);
      setStep('REPAIR_SUMMARY');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete repair job.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('CONFIRM_USED_INVENTORY');
        }}
      />
      <Button
        disabled={!selectedIssues?.length}
        size={fullScreenModalFooterButtonSize}
        text="Complete Repair"
        onClick={handleCompleteRepairJob}
      />
    </>
  );
};
