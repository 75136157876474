import { Cell, TextField, Typography } from 'gantri-components';
import { FocusEvent, useState } from 'react';
import { Label } from '../../../../../../../../components/label';
import { TextInputCellProps } from './text-input-cell.types';

export const TextInputCell = (props: TextInputCellProps) => {
  const {
    editedShipment,
    handleErrorMessage,
    isEditing,
    keyName,
    label,
    placeholder,
    setEditedShipment,
    ...rest
  } = props;

  const [errorMessage, setErrorMessage] = useState<string>('');

  const shipmentValue = editedShipment[keyName];
  const value = shipmentValue ? String(shipmentValue) : '';

  return (
    <Cell {...rest}>
      <Label paddingBottom=".5x" text={label} />
      {isEditing ? (
        <TextField
          errorMessage={errorMessage}
          placeholder={placeholder}
          value={value ? String(value) : ''}
          onBlur={(event: FocusEvent<HTMLInputElement>) => {
            if (!!handleErrorMessage) {
              const errorMessage = handleErrorMessage(event.target.value);

              setErrorMessage(errorMessage);
            }
          }}
          onChange={(event) => {
            setEditedShipment((oldProps) => {
              return {
                ...oldProps,
                [keyName]: event.target.value,
              };
            });
          }}
        />
      ) : (
        <Typography text={value || 'TBD'} />
      )}
    </Cell>
  );
};
