import { FC, Fragment, useMemo } from 'react';
import { Conditional, Typography } from 'gantri-components';
import { Stock } from '../../../../../../types/store';
import { OrderTransaction } from '../order-transaction';
import { getStocksGroupedByStatus } from './shipment-stocks.adapter';
import { StyledGroupHeader } from './shipment-stocks.styles';

export const ShipmentStocks: FC<{ hasExpandAll?: boolean; stocks: Stock[] }> = (
  props,
) => {
  const { hasExpandAll, stocks } = props;
  const shouldGroupStocksByStatus = stocks.length > 3;

  const groups = useMemo(() => {
    if (!shouldGroupStocksByStatus) return [];

    return getStocksGroupedByStatus(stocks);
  }, [shouldGroupStocksByStatus, stocks]);

  if (!shouldGroupStocksByStatus) {
    return (
      <>
        {stocks.map((stock) => {
          return <OrderTransaction key={stock.id} stock={stock} />;
        })}
      </>
    );
  }

  return (
    <>
      {groups.map((group) => {
        return (
          <Fragment key={group.status}>
            <StyledGroupHeader
              alignItems="center"
              gap="x"
              horizontalPadding="3x"
              verticalPadding="x"
            >
              <Typography text={group.status} textStyle="bold" variant="p3" />
              <Typography text={`(${group.stocks.length})`} variant="p3" />
            </StyledGroupHeader>

            <Conditional condition={hasExpandAll}>
              {group.stocks.map((stock) => {
                return <OrderTransaction key={stock.id} stock={stock} />;
              })}
            </Conditional>
          </Fragment>
        );
      })}
    </>
  );
};
