import { Stack, Typography } from 'gantri-components';
import { useAsync } from 'react-use';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Divider } from '../../../../../../components/divider';
import { jobSteps, jobTypeOptions } from '../../../../../../constants/options';
import { BatchPartCreationFormik } from '../../../../batch-part-creation.types';
import { getJobTemplatesForStep } from '../../helpers/get-job-templates-for-step';
import { PartInformation } from './components/part-information';
import { JobsTemplateTable } from '../../../../../../components/common/jobs-template-table';
import { Product } from '../../../../../../api/products/products.types';
import { JobTemplateWithId } from '../../../../../../components/common/jobs-template-table/jobs-template-table.types';
import { batchPartCreationAtoms } from '../../../../batch-part-creation.atoms';

export const EditBatchPage = () => {
  const partUuid = useRecoilValue(batchPartCreationAtoms.partUuid);
  const selectedProductDetails = useRecoilValue(
    batchPartCreationAtoms.selectedProductDetails,
  );
  const [jobs, setJobs] = useRecoilState(batchPartCreationAtoms.jobs);

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  const { partName, productId } = formikValues;

  const [printJobTemplates, setPrintJobTemplates] = useState<
    JobTemplateWithId[]
  >([]);
  const [finishingJobTemplates, setFinishingJobTemplates] = useState<
    JobTemplateWithId[]
  >([]);

  const jobsTemplateTableVersion = {
    id: selectedProductDetails?.versionId,
    status: 'Draft',
    type: 'Major',
  } as Product['version'];

  const handleSetExistingJobs = () => {
    const startingPrintJobs = getJobTemplatesForStep({
      jobTemplates: jobs,
      step: jobSteps.print,
    });

    setPrintJobTemplates(startingPrintJobs);

    const startingFinishingJobs = getJobTemplatesForStep({
      jobTemplates: jobs,
      step: jobSteps.finish,
    });

    setFinishingJobTemplates(startingFinishingJobs);
  };

  const handlePrintJobsSave = (jobTemplates: JobTemplateWithId[]): void => {
    const updatedTemplates = jobTemplates.map((jobTemplate) => {
      const { step, type } = jobTemplate;
      const isPrintPrintJob =
        step === jobSteps.print && type === jobTypeOptions.print;

      if (isPrintPrintJob) {
        // Replace machine type and weight values with those from the previous print prepare job.
        const matchingPrintPrepareJob = jobTemplates.find(
          ({ sortOrder, step, type }) => {
            return (
              step === jobSteps.print &&
              type === jobTypeOptions.prepare &&
              sortOrder === jobTemplate.sortOrder - 1
            );
          },
        );

        return {
          ...jobTemplate,
          machineType:
            matchingPrintPrepareJob?.machineType || jobTemplate.machineType,
          weight: matchingPrintPrepareJob?.weight || jobTemplate.weight,
        };
      }

      return jobTemplate;
    });

    setPrintJobTemplates(updatedTemplates);
  };

  useEffect(() => {
    setJobs([...printJobTemplates, ...finishingJobTemplates]);
  }, [printJobTemplates, finishingJobTemplates]);

  useAsync(async () => {
    if (jobs.length) {
      handleSetExistingJobs();
    }
  }, []);

  return (
    <Stack gap="2rem">
      <Stack gap="0.4rem">
        <Typography text="Edit" variant="h3" />
        <Typography text="Edit information and jobs for this part. Please make sure to include who you want this part to be delivered to." />
      </Stack>
      <PartInformation />
      <Divider paddingTop="2rem" />
      <Typography text="Jobs" variant="h4" />
      <Stack gap="1.6rem">
        <Typography text={`1. ${jobSteps.print}`} variant="h4" />
        <JobsTemplateTable
          data={printJobTemplates}
          partId={partUuid}
          partName={partName}
          productId={productId}
          productName={selectedProductDetails?.fullName}
          restrictJobStepTo={jobSteps.print}
          version={jobsTemplateTableVersion}
          onSaveOverride={handlePrintJobsSave}
        />
      </Stack>
      <Stack gap="1.6rem">
        <Typography text={`2. ${jobSteps.finish}`} variant="h4" />
        <JobsTemplateTable
          data={finishingJobTemplates}
          partId={partUuid}
          partName={partName}
          productId={productId}
          productName={selectedProductDetails?.fullName}
          restrictJobStepTo={jobSteps.finish}
          version={jobsTemplateTableVersion}
          onSaveOverride={setFinishingJobTemplates}
        />
      </Stack>
    </Stack>
  );
};
