import { Conditional, Flex, Icon, Typography } from 'gantri-components';
import { CellJobAttemptProps } from './cell-job-attempt.types';

export const CellJobAttempt = (props: CellJobAttemptProps) => {
  const { attempt } = props;

  return (
    <Flex alignItems="center" gap="0.6rem">
      <Conditional condition={attempt > 1}>
        <Icon color="t2" name="arrows:arrow_rotate_two" />
      </Conditional>
      <Typography text={attempt} />
    </Flex>
  );
};
