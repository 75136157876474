import { Row } from '@tanstack/react-table';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import {
  ReportJobsQueries,
  ReportJobsTableData,
} from '../../report-jobs.types';
import { defaultJobsGroupBy } from '../../report-jobs.constants';

export const getRowHref = (row: Row<ReportJobsTableData>): string => {
  const windowLocation = window.location.origin + window.location.pathname;
  const queries = getActiveQueries<ReportJobsQueries>();
  const { page: _page, ...queriesWithoutPage } = queries;
  const info: ReportJobsTableData['info'] = row?.original?.info;
  const type: string = row?.original?.type;

  const {
    groupBy = defaultJobsGroupBy,
    productId,
    version,
    machineType,
    machineId,
    jobType,
    partName,
    workerId,
  } = queries;

  const rowData = row?.original;
  const isFailurePage = groupBy && jobType;

  if (isFailurePage || /^total$/i.test(rowData?.type) || !groupBy) {
    return;
  }

  if (/^productName$/i.test(groupBy)) {
    /**
     * productId = undefined, means lvl 1
     * Generate a href to the product detail showing all product versions
     */
    if (!productId) {
      const queryString = convertQueriesToString({
        groupBy,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
      });

      return windowLocation + queryString;
    }

    /**
     * productId != undefined and version = undefined, means lvl 2
     * Generate a href to the version detail showing all SKUs
     */
    if (!version) {
      const queryString = convertQueriesToString({
        groupBy,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
        version: rowData?.info?.version,
      });

      return windowLocation + queryString;
    }

    if (!partName) {
      const queryString = convertQueriesToString({
        groupBy,
        partName: rowData?.info?.partName,
        productId: rowData?.info?.id,
        productName: rowData?.info?.name,
        version: rowData?.info?.version,
      });

      return windowLocation + queryString;
    }

    if (!jobType) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        jobType: type,
      });

      return windowLocation + queryString;
    }
  }

  if (/^jobType$/i.test(groupBy)) {
    if (!jobType) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        jobType: type,
      });

      return windowLocation + queryString;
    }
  }

  if (/^workerName$/i.test(groupBy)) {
    if (!workerId) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        workerId: info?.id,
        workerName: type,
      });

      return windowLocation + queryString;
    }

    if (!jobType) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        jobType: type,
      });

      return windowLocation + queryString;
    }
  }

  if (/^machineType$/i.test(groupBy)) {
    if (!machineType) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        machineType: type,
      });

      return windowLocation + queryString;
    }

    if (!machineId) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        machineId: info?.id,
        machineName: type,
      });

      return windowLocation + queryString;
    }

    if (!jobType) {
      const queryString = convertQueriesToString({
        ...queriesWithoutPage,
        groupBy,
        jobType: type,
      });

      return windowLocation + queryString;
    }
  }
};
