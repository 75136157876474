import {
  InventoriesSortingField,
  InventoryStockStatus,
  InventoryType,
} from '../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const inventoriesFiltersDefaults: {
  filters: {
    active: boolean;
    discarded: {
      max: number;
      min: number;
    };
    locationId: number;
    product: number;
    stockStatus: InventoryStockStatus[];
    types: InventoryType[];
    vendor: number;
  };
  sortBy: SortBy<InventoriesSortingField>;
} = {
  filters: {
    active: true,
    discarded: {
      max: 100,
      min: 0,
    },
    locationId: null,
    product: null,
    stockStatus: [],
    types: [],
    vendor: null,
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'INVENTORIES_FILTERS';

export const pageName: AtomPageName = 'inventories';

export const inventoriesPageAtoms = {
  defaults: inventoriesFiltersDefaults,
  filters: {
    active: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.active,
      key: `${atomKeyPrefix}-active`,
    })(pageName),
    discarded: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.discarded,
      key: `${atomKeyPrefix}-discarded`,
    })(pageName),
    locationId: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.locationId,
      key: `${atomKeyPrefix}-location-id`,
    })(pageName),
    product: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.product,
      key: `${atomKeyPrefix}-product`,
    })(pageName),
    stockStatus: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.stockStatus,
      key: `${atomKeyPrefix}-stock-status`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
    vendor: getGenericAtomFamily({
      defaultValue: inventoriesFiltersDefaults.filters.vendor,
      key: `${atomKeyPrefix}-vendor`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<InventoriesSortingField>>({
    defaultValue: inventoriesFiltersDefaults.sortBy,
  })(pageName),
};
