import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { jobsApi } from '../../../../../../../../../api';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';
import { jobStatuses } from '../../../../../../../../../constants/options';
import { useCompleteJob } from '../../../../../../../../../api/jobs/routes';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const { onCompleteJob } = useCompleteJob();

  const handleCompleteJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        if (job.status === jobStatuses.ready) {
          await jobsApi.startJob({ jobId: job.id });
        }

        await onCompleteJob({ jobId: job.id });

        await handleCloseModal({ updateOnClose: true });
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to complete job.' });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleCompleteJob}
      />
    </>
  );
};
