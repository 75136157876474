import { useRecoilState } from 'recoil';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { UpdatePartChecklist } from './part-checklist-content.types';

export const PartChecklistContent = () => {
  const [partChecklist, setPartChecklist] = useRecoilState(
    completeAssemblyModalAtoms.partChecklist,
  );
  const [isPartChecklistReviewed, setIsPartChecklistReviewed] = useRecoilState(
    completeAssemblyModalAtoms.isPartChecklistReviewed,
  );

  const updatePartChecklist: UpdatePartChecklist = (partId, data) => {
    setPartChecklist((oldChecklist) => {
      return {
        ...oldChecklist,
        [partId]: {
          ...data,
          requireDetailsOnFail: data.status === 'Re-start',
        },
      };
    });
  };

  return (
    <AdvancedChecklist
      buttonToggleWidth="10rem"
      checklist={partChecklist}
      getSubTitle={(numItems) => {
        return `Indicate which of the following (${numItems}) parts failed and choose what action to take.`;
      }}
      isChecklistReviewed={isPartChecklistReviewed}
      setIsChecklistReviewed={setIsPartChecklistReviewed}
      statuses={['Pass', 'Re-finish', 'Re-start']}
      title="Evaluate parts"
      onItemChange={updatePartChecklist}
    />
  );
};
