import { Conditional, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { VLMLocationGrid } from '../../../../common/vlm-location-grid';
import { startFinishStageModalAtoms } from '../../../start-finishing-stage-job-modal.atoms';
import { StyledFlexContainer } from './put-in-vlm-content.styles';
import { PutInVlmContentProps } from './put-in-vlm-content.types';

export const PutInVlmContent = (props: PutInVlmContentProps) => {
  const { job } = props;
  const { part } = job;

  const stockLocation = useRecoilValue(
    startFinishStageModalAtoms.stockLocation,
  );
  const vlmNotified = useRecoilValue(startFinishStageModalAtoms.vlmNotified);

  return (
    <Stack alignContent="center" gap="4x">
      <Conditional
        condition={vlmNotified}
        Fallback={
          <ModalContentHeading
            color="warning"
            titleText="Request not delivered to VLM"
          />
        }
      >
        <ModalContentHeading color="success" titleText="Request sent to VLM" />
      </Conditional>
      <Stack gap="x">
        <Conditional
          condition={vlmNotified}
          Fallback={
            <>
              <Typography text="Manual VLM Storage" textStyle="bold" />
              <Typography
                text={`Please complete the VLM request manually. Stock #${job.stockId} has been assigned to:`}
              />
            </>
          }
        >
          <Typography text="VLM Storage" textStyle="bold" />
          <Typography text={`Stock #${job.stockId} has been assigned to:`} />
        </Conditional>

        <StyledFlexContainer
          horizontalPadding="3x"
          justifyContent="center"
          verticalPadding="4x"
        >
          <VLMLocationGrid stockLocation={stockLocation} />
        </StyledFlexContainer>
        <Typography text={`Please store ${part.name} in the bin.`} />
      </Stack>
    </Stack>
  );
};
