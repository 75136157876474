import { Typography, Cell, Dropdown, TextField } from 'gantri-components';
import { useState } from 'react';
import { Label } from '../../../../components/label';
import {
  currencyOptions,
  inventoryPurchaseStatuses,
} from '../../../../constants/inventory-purchases';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { ResponsiveGrid } from '../responsive-grid';
import { SectionHeading } from '../section-heading';
import { PurchaseDetailsSectionProps } from './purchase-details-section.types';

export const PurchaseDetailsSection = (props: PurchaseDetailsSectionProps) => {
  const {
    editedInventoryPurchase,
    handleSave,
    isEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const canEditPurchaseDetails =
    editedInventoryPurchase?.status === inventoryPurchaseStatuses.waiting;
  const currencySymbol =
    currencyOptions[editedInventoryPurchase.currency].symbol;
  const disabledTooltip = `Inventory purchase must have status "${inventoryPurchaseStatuses.waiting}" to edit this section.`;

  return (
    <>
      <SectionHeading
        disabledTooltip={disabledTooltip}
        handleSave={handleSave}
        isEditing={isEditing}
        isEditingDisabled={isEditingDisabled || !canEditPurchaseDetails}
        setIsEditing={setIsEditing}
        text="Purchase Details"
        toggleEditMode={toggleEditMode}
      />
      <ResponsiveGrid>
        <Cell>
          <Label paddingBottom=".5x" text="Units" />
          {isEditing && canEditPurchaseDetails ? (
            <TextField
              minValue={1}
              placeholder="Units"
              type="number"
              value={String(editedInventoryPurchase.units)}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  units: Number(event.target.value),
                });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.units} />
          )}
        </Cell>
        <Cell>
          <Label paddingBottom=".5x" text={`Total Cost ${currencySymbol}`} />
          {isEditing && canEditPurchaseDetails ? (
            <TextField
              minValue={1}
              placeholder="Total Cost"
              type="number"
              value={String(editedInventoryPurchase.totalCost / 100)}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  totalCost: Number(event.target.value) * 100,
                });
              }}
            />
          ) : (
            <Typography
              text={formatAsCurrency(editedInventoryPurchase.totalCost || 0, {
                currency: editedInventoryPurchase.currency,
                isCents: true,
              })}
            />
          )}
        </Cell>
        <Cell>
          <Label paddingBottom=".5x" text="Currency" />
          {isEditing && canEditPurchaseDetails ? (
            <Dropdown
              items={Object.keys(currencyOptions).map((value) => {
                return {
                  label: value,
                  value,
                };
              })}
              placeholder="Currency"
              value={editedInventoryPurchase.currency}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  currency: event.target.value,
                });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.currency} />
          )}
        </Cell>
        <Cell>
          <Label paddingBottom=".5x" text="Invoice #" />
          {isEditing && canEditPurchaseDetails ? (
            <TextField
              minValue={1}
              placeholder="Invoice #"
              value={editedInventoryPurchase.invoice}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  invoice: event.target.value,
                });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.invoice} />
          )}
        </Cell>
      </ResponsiveGrid>
    </>
  );
};
