import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'gantri-components';
import { countries } from '../../../../constants/locales';

const CountryInput = ({
  errorMessage,
  expectedValue,
  labelText,
  onChange,
  value,
}) => {
  const usa = countries.find(({ name }) => {
    return name === 'United States';
  });
  const defaultValue = expectedValue === 'name' ? usa.name : usa.code;

  useEffect(() => {
    if (!value) {
      onChange(defaultValue);
    }
  }, []);

  return (
    <Dropdown
      errorMessage={errorMessage}
      idProperty="code"
      items={countries}
      labelProperty="name"
      labelText={labelText}
      value={value || countries[0].code}
      onSelect={(record) => {
        return onChange(record.code);
      }}
    />
  );
};

CountryInput.defaultProps = {
  expectedValue: 'name',
  onChange: () => {
    return null;
  },
  // 'name' or 'code'
  value: '',
};

CountryInput.propTypes = {
  errorMessage: PropTypes.string,
  expectedValue: PropTypes.string,
  labelText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default CountryInput;
