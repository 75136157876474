import { useNotification } from '../../../../hooks/useNotification';
import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { getDesignerStats } from '..';

interface UseGetDesignerStatsProps {
  id: number;
}

export const useGetDesignerStats = (props: UseGetDesignerStatsProps) => {
  const { id } = props;
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!id,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch designer stats.',
        });
      },
      queryFn: () => {
        return getDesignerStats.query(id);
      },
      queryKey: getDesignerStats.key(id),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
