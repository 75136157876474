import { Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import {
  ClickableIcon,
  StyledOverflowEllipsisCell,
  StyledGrid,
  StyledNotificationsStack,
} from './notification.styles';
import { notificationAtoms } from './notification.atoms';
import { notificationTypes } from '../../hooks/useNotification/use-notification.constants';
import { NotificationDetails } from '../../hooks/useNotification/use-notification.types';

export const Notification = () => {
  const [notifications, setNotifications] = useRecoilState(
    notificationAtoms.notifications,
  );

  const clearNotification = ({ id }: NotificationDetails) => {
    setNotifications((prevNotifications) => {
      return prevNotifications.filter((notification) => {
        return notification.id !== id;
      });
    });
  };

  useEffect(() => {
    notifications.forEach((notification) => {
      const { keepOpen, message, time } = notification;

      if (!!message && !keepOpen) {
        setTimeout(() => {
          clearNotification(notification);
        }, time);
      }
    });
  }, [notifications]);

  return (
    <StyledNotificationsStack gap="0" height="unset">
      {notifications.map((notification) => {
        const { id, keepOpen, message, type } = notification;

        return (
          <StyledGrid
            key={id}
            alignItems="center"
            columns={keepOpen ? '1fr max-content' : '1'}
            horizontalPadding="1.6rem"
            justifyContent="center"
            type={type}
          >
            <StyledOverflowEllipsisCell>
              {typeof message === 'string' ? (
                <Typography
                  align="center"
                  color={type === notificationTypes.warning ? 't1' : 'alt'}
                  text={message}
                  variant="p2"
                />
              ) : (
                message
              )}
            </StyledOverflowEllipsisCell>
            {keepOpen && (
              <ClickableIcon
                color={type === notificationTypes.warning ? 't2' : 'white'}
                name="ui-control:x_24"
                onClick={() => {
                  return clearNotification(notification);
                }}
              />
            )}
          </StyledGrid>
        );
      })}
    </StyledNotificationsStack>
  );
};
