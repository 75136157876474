import { Typography } from 'gantri-components';
import { StyledGrid, StyledImgWrapper } from './stat-column.styles';
import { StatColumnProps } from './stat-column.types';

export const StatColumn = ({ data, title }: StatColumnProps) => {
  return (
    <div>
      <Typography text={title} variant="h4" />
      {data.map((item) => {
        const {
          key,
          showThumbnailPlaceholder,
          subTitle,
          thumbnailUrl,
          title,
          value,
        } = item;

        const hasThumbnail = !!thumbnailUrl || showThumbnailPlaceholder;

        return (
          <StyledGrid
            key={key}
            alignItems="center"
            columns={
              hasThumbnail ? 'min-content 1fr min-content' : '1fr min-content'
            }
            gap="0.6rem"
            height="6.4rem"
            minWidth="25rem"
          >
            {hasThumbnail && (
              <StyledImgWrapper>
                {thumbnailUrl && <img alt="" src={thumbnailUrl} />}
              </StyledImgWrapper>
            )}
            <div>
              <Typography text={title} whiteSpace="nowrap" />
              <Typography color="t2" text={subTitle} whiteSpace="nowrap" />
            </div>
            <Typography text={value} whiteSpace="nowrap" />
          </StyledGrid>
        );
      })}
    </div>
  );
};
