import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useInvalidateJobsFetchCaches } from '../hooks/use-invalidate-jobs-fetch-caches';
import { CancelJobArgs, CancelJobResponse } from './cancel-job.types';
import { cancelJob } from './cancel-job';

export const useCancelJob = <TransformedData = CancelJobResponse>(
  props?: GenericMutateQueryProps<
    CancelJobArgs,
    CancelJobResponse,
    TransformedData
  >,
) => {
  const { invalidateJobCaches } = useInvalidateJobsFetchCaches();

  const { onMutate: onCancelJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to cancel job.',
    mutationFn: cancelJob,
    onSuccess: async (...args) => {
      await invalidateJobCaches();
      await props?.onSuccess?.(...args);
    },
    ...props,
  });

  return { ...rest, onCancelJob };
};
