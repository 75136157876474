import styled from 'styled-components';

export const StyledThumbnailWrapper = styled.div`
  position: relative;
  display: inline-flex;
  height: 80px;
  width: 80px;
  background-color: ${({ theme }) => {
    return theme.colors.surfaces.monochrome.t1Alt;
  }};
`;
