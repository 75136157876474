import { useCopyToClipboard } from 'react-use';
import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { coreApiUrl } from '../../../../helpers/auth';
import { useNotification } from '../../../../hooks/useNotification';
import { StyledAnchor } from '../../styled-anchor';
import { RFIDCellProps } from './rfid-data-cell.types';

export const getPrintRfidUrl = ({
  partId,
  stockId,
}: {
  partId: number;
  stockId: number;
}) => {
  return `${coreApiUrl}/parts/print-label?partId=${partId}&stockId=${stockId}`;
};

export const RFIDCell = (props: RFIDCellProps) => {
  const { name, partId, stockId } = props;

  const [, copyToClipboard] = useCopyToClipboard();
  const { notify } = useNotification();

  const onCopy = () => {
    copyToClipboard(`F${stockId}F${partId}`);
    notify('Copied to clipboard');
  };

  const rfidUrl = getPrintRfidUrl({ partId, stockId });

  const RfidIcon = () => {
    return <Icon color="t2" name="work:rfid_signal_24" size="2.4rem" />;
  };

  return (
    <Flex alignItems="center" width="auto">
      <Tooltip
        Component={
          <Flex gap="x" horizontalPadding="x" verticalPadding="x">
            <Typography text={`Part ID: #${partId}`} whiteSpace="nowrap" />
            <StyledAnchor text="Copy" onClick={onCopy} />
            <Typography text="|" />
            <StyledAnchor href={rfidUrl} target="_blank" text="Print" />
          </Flex>
        }
        overlayContainerStyles={{
          position: 'relative',
          zIndex: 1,
        }}
        position="top"
      >
        <StyledAnchor
          display="flex"
          href={rfidUrl}
          onClick={(event) => {
            return event.stopPropagation();
          }}
        >
          <Conditional condition={!!name} Fallback={<RfidIcon />}>
            <Flex alignItems="center" gap="x">
              <RfidIcon />
              <StyledAnchor text={name} />
            </Flex>
          </Conditional>
        </StyledAnchor>
      </Tooltip>
    </Flex>
  );
};
