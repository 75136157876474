import { ButtonToggleGroupProps } from './button-toggle-group.types';

export const buttonToggleGroupDefaultProps: Required<
  Pick<
    ButtonToggleGroupProps,
    'itemsWidth' | 'getActiveBackgroundColor' | 'transformLabel'
  >
> = {
  getActiveBackgroundColor: () => {
    return 'green_400';
  },
  itemsWidth: '12rem',
  transformLabel: (value) => {
    return value;
  },
};
