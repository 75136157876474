import { useEffect, useMemo } from 'react';
import {
  Button,
  Conditional,
  Dropdown,
  Flex,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  jobTemplateTypeOptionsForJobStep,
  jobTypeToIconTypeMap,
} from '../../../../../../../constants/jobs';
import { jobsTemplateTableAtoms } from '../../../../jobs-template-table.atoms';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { getVersionDetails } from '../../../../helpers/get-version-details';
import { useGetOnSaveAndOnCancel, useValidateFields } from './hooks';
import { JobTemplateTypeCellProps } from './job-template-type-cell.types';
import { JobTemplateWithId } from '../../../../jobs-template-table.types';

const JobTypeAndIcon = ({
  jobTemplate,
}: {
  jobTemplate: JobTemplateWithId;
}) => {
  const { type } = jobTemplate;

  return (
    <Flex alignItems="center" gap="x">
      <Conditional condition={!!jobTypeToIconTypeMap[type]}>
        <Icon name={jobTypeToIconTypeMap[type]} />
      </Conditional>
      <Typography text={type} />
    </Flex>
  );
};

export const JobTemplateTypeCell = (props: JobTemplateTypeCellProps) => {
  const {
    cachedJobTemplates,
    getValue,
    onEdit,
    onSaveOverride,
    partId,
    refetchProduct,
    row,
    setJobTemplates,
    version,
  } = props;

  const { isMinorVersion } = getVersionDetails(version);

  const { getHasError } = useValidateFields();
  const hasError = getHasError('type');

  const isEditingRow = useGetIsEditingRow(row);

  const [processing, setProcessing] = useRecoilState(
    jobsTemplateTableAtoms.isSavingChanges,
  );

  const { onCancel, onSave } = useGetOnSaveAndOnCancel({
    cachedJobTemplates,
    jobTemplate: row.original,
    onSaveOverride,
    partId,
    refetchProduct,
    setJobTemplates,
    setProcessing,
    version,
  });

  const { step } = row.original;

  const type = getValue();

  const availableJobTypes = useMemo(() => {
    return jobTemplateTypeOptionsForJobStep[step] || [];
  }, [step]);

  const items = useMemo(() => {
    return availableJobTypes?.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  }, [availableJobTypes]);

  useEffect(() => {
    const selectedTypeIsNotAvailable = !availableJobTypes?.some(
      (availableType) => {
        return availableType === type;
      },
    );

    if (isEditingRow && !!type && selectedTypeIsNotAvailable) {
      onEdit({
        ...row.original,
        type: null,
      });
    }
  }, [availableJobTypes]);

  return (
    <Stack gap="x">
      <Conditional
        condition={isEditingRow}
        Fallback={<JobTypeAndIcon jobTemplate={row.original} />}
      >
        <Conditional
          condition={isMinorVersion}
          Fallback={
            <Tooltip
              description={!step && 'You must select a job step first.'}
              position="top"
            >
              <Dropdown
                disabled={!step}
                errorMessage={hasError ? 'Required' : null}
                items={items}
                placeholder="Type"
                value={type}
                onSelect={({ value }) => {
                  onEdit({
                    ...row.original,
                    type: value,
                  });
                }}
              />
            </Tooltip>
          }
        >
          <JobTypeAndIcon jobTemplate={row.original} />
        </Conditional>
        <Flex alignItems="center" gap="0.4rem">
          <Button
            processing={processing}
            text="Save"
            variant="primary"
            onClick={onSave}
          />
          <Button text="Cancel" variant="secondary" onClick={onCancel} />
        </Flex>
      </Conditional>
    </Stack>
  );
};
