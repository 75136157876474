import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { UpdateDesignData } from '../update-design-review/update-design-review.types';

export const completeDesignReview = (
  designId: number,
  data: UpdateDesignData,
) => {
  return axios.put(`${getCoreApiUrl('designs')}/${designId}/review`, data);
};
