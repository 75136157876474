import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CancelJobArgs, CancelJobResponse } from './cancel-job.types';

export const cancelJob = ({ jobId }: CancelJobArgs) => {
  return axios
    .post<CancelJobResponse>(`${coreApiUrl}/jobs/${jobId}/cancel`, null)
    .then(({ data }) => {
      return data;
    });
};
