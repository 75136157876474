import { Conditional, Grid, Icon, Tooltip } from 'gantri-components';
import { JobStockIdCellProps } from './job-stock-id-cell.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';

export const JobStockIdCell = ({ getValue, row }: JobStockIdCellProps) => {
  const { highPriority } = row.original;
  const stockId = getValue();

  return (
    <Conditional condition={!!stockId}>
      <Grid alignItems="center" columns="repeat(2, max-content)" gap=".5x">
        <StyledAnchor
          href={`${routePaths.stocks}/${stockId}`}
          target="_blank"
          text={`#${stockId}`}
        />

        {highPriority && (
          <Tooltip
            description="High-priority stock"
            overlayContainerStyles={{
              position: 'relative',
              zIndex: 1,
            }}
            position="top"
          >
            <Icon name="arrows:arrow_triangle_up" />
          </Tooltip>
        )}
      </Grid>
    </Conditional>
  );
};
