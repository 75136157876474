import styled, { css } from 'styled-components';

export const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 8.5rem 1fr;
  gap: 0.8rem;
  grid-column: 1 / -1;
  padding-bottom: 0.8rem;

  ${({ theme }) => {
    return css`
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;
