import React, { FC } from 'react';
import { FastField, Field, useField } from 'formik';
import { Cell, Checkbox, FormikInput, Grid } from 'gantri-components';
import AutocompleteStreet from '../../../../../components/common/inputs/autocomplete';
import {
  CountryInput,
  StateInput,
} from '../../../../../components/common/inputs';

export const EditAddress: FC<{ selectAddress: (address) => void }> = ({
  selectAddress,
}) => {
  const [fieldCountry] = useField('country');

  return (
    <Grid columns={6} gap="1rem">
      <Cell width={3}>
        <FormikInput
          ariaLabel="firstName"
          name="firstName"
          placeholder="First Name"
        />
      </Cell>
      <Cell width={3}>
        <FormikInput
          ariaLabel="lastName"
          name="lastName"
          placeholder="Last Name"
        />
      </Cell>

      <Cell width={4}>
        <FormikInput
          Field={
            <AutocompleteStreet
              placeholder="Street"
              onSelectAddress={selectAddress}
            />
          }
          name="street"
        />
      </Cell>

      <Cell width={2}>
        <FormikInput ariaLabel="unit" name="unit" placeholder="Unit" />
      </Cell>

      <Cell width={2}>
        <FormikInput ariaLabel="city" name="city" placeholder="City" />
      </Cell>

      <Cell width={2}>
        <Field name="state">
          {({ field, meta }) => {
            return (
              <StateInput
                country={fieldCountry.value}
                hasError={meta.touched && meta.error}
                onInputChange={field.onChange}
                onSelectChange={(value) => {
                  return field.onChange({ target: { name: 'state', value } });
                }}
                {...field}
              />
            );
          }}
        </Field>
      </Cell>

      <Cell width={2}>
        <FormikInput name="zip" placeholder="Zip" />
      </Cell>

      <Cell width={6}>
        <FastField name="country">
          {({ field }) => {
            return (
              <CountryInput
                {...field}
                expectedValue="code"
                onChange={(country) => {
                  return field.onChange({
                    target: { name: 'country', value: country },
                  });
                }}
              />
            );
          }}
        </FastField>
      </Cell>

      <Cell width={6}>
        <FormikInput name="phone" placeholder="Phone" type="number" />
      </Cell>

      <Cell width={6}>
        <FastField name="doNotShip">
          {({ field }) => {
            return (
              <Checkbox
                checked={field.value}
                labelText="Do not ship"
                {...field}
              />
            );
          }}
        </FastField>
      </Cell>
    </Grid>
  );
};
