import { Conditional, Flex, Typography } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import StatusDataCell from '../../../../components/common/custom-data-cells/status-data-cell';
import { LocationState } from '../../locations.types';
import { DateWithUserDataCell } from '../../components';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenu } from '../../../../components/dropdowns';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import routePaths from '../../../../config/route-paths';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

interface UseGetLocationColumnsProps {
  menuOptions: MoreMenuOption<LocationState>[];
}

export const getInventoriesInLocationUrl = (locationId: number) => {
  return `${routePaths.inventories}?location=${locationId}` as const;
};

export const useGetLocationColumns = (props: UseGetLocationColumnsProps) => {
  const { menuOptions } = props;

  const columns: ColumnDef<LocationState>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        cell: ({
          getValue,
        }: CellContext<LocationState, LocationState['name']>) => {
          return <Typography text={getValue()} transform="capitalize" />;
        },
        header: 'Name',
        meta: {
          hideable: false,
          label: 'Actions',
          reorderable: false,
        },
        minSize: 400,
        size: 350,
      },
      {
        accessorKey: 'type',
        cell: ({
          getValue,
        }: CellContext<LocationState, LocationState['type']>) => {
          return <Typography text={getValue()} transform="capitalize" />;
        },
        header: 'Type',
        size: 130,
      },
      {
        accessorKey: 'workshop',
        cell: ({
          getValue,
        }: CellContext<LocationState, LocationState['workshop']>) => {
          return <Typography text={getValue()} />;
        },
        header: 'Workshop',
        size: 130,
      },
      {
        accessorKey: 'stock',
        cell: ({
          getValue,
        }: CellContext<LocationState, LocationState['stock']>) => {
          const stockId = getValue();

          return (
            <Conditional
              condition={typeof stockId === 'number'}
              Fallback={<Typography text={stockId} />}
            >
              <StyledAnchor
                href={`${routePaths.stocks}/${stockId}`}
                target="_blank"
                text={`#${stockId}`}
              />
            </Conditional>
          );
        },
        header: 'Stock #',
        meta: {
          getIsClickable: ({
            getValue,
          }: Cell<LocationState, LocationState['stock']>) => {
            return typeof getValue() === 'number';
          },
        },
        size: 186.6,
      },
      {
        accessorKey: 'parts',
        cell: ({
          getValue,
          row,
        }: CellContext<LocationState, LocationState['parts']>) => {
          const parts = getValue();

          return (
            <Conditional condition={!!parts} Fallback={<Typography text={0} />}>
              <StyledAnchor
                href={`${routePaths.parts}?location=${row.original.id}`}
                target="_blank"
                text={parts}
              />
            </Conditional>
          );
        },
        header: 'Parts in location',
        meta: {
          getIsClickable: ({
            getValue,
          }: Cell<LocationState, LocationState['parts']>) => {
            return getValue() > 0;
          },
        },
        size: 186.6,
      },
      {
        accessorKey: 'totalInventories',
        cell: ({
          getValue,
          row,
        }: CellContext<LocationState, LocationState['totalInventories']>) => {
          const inventories = getValue();

          return (
            <Conditional
              condition={!!inventories}
              Fallback={<Typography text={0} />}
            >
              <StyledAnchor
                href={getInventoriesInLocationUrl(row.original.id)}
                target="_blank"
                text={inventories}
              />
            </Conditional>
          );
        },
        header: 'Inventories in location',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 186.6,
      },
      {
        accessorKey: 'statusInfo',
        cell: ({
          getValue,
        }: CellContext<LocationState, LocationState['statusInfo']>) => {
          const statusInfo = getValue();

          return (
            <Conditional condition={!!statusInfo}>
              <DateWithUserDataCell
                data={statusInfo?.created}
                date={statusInfo?.createdAt}
                type="Created"
              />
            </Conditional>
          );
        },
        header: 'Created',
        size: 187,
      },
      {
        accessorKey: 'status',
        cell: ({
          row,
        }: CellContext<LocationState, LocationState['status']>) => {
          return row.original.status ? (
            <StatusDataCell {...row.original} />
          ) : null;
        },
        header: 'Status',
        size: 124,
      },
      {
        cell: ({ row }: CellContext<LocationState, never>) => {
          return (
            <Flex justifyContent="flex-end">
              <MoreMenu data={row.original} options={menuOptions} />
            </Flex>
          );
        },
        header: '',
        id: 'menu',
        meta: {
          hideable: false,
          label: 'Actions',
          reorderable: false,
        },
        size: 30,
      },
    ];
  }, []);

  return columns;
};
