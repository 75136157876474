import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../api';
import { useSpinner } from '../../../../../../../../../../hooks';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startAssembleStageModalAtoms } from '../../../start-assemble-stage-job-modal.atoms';
import { ConfirmStartFooterProps } from './confirm-start-footer.types';

export const ConfirmStartFooter = (props: ConfirmStartFooterProps) => {
  const { handleCloseModal, job } = props;

  const { onInterceptProcessingRequest, processing } = useSpinner();
  const { notifyAxiosError } = useNotification();

  const setVlmNotified = useSetRecoilState(
    startAssembleStageModalAtoms.vlmNotified,
  );
  const setStockLocation = useSetRecoilState(
    startAssembleStageModalAtoms.stockLocation,
  );
  const setStep = useSetRecoilState(startAssembleStageModalAtoms.step);

  const handleConfirmStart = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await jobsApi.startJob({
          callVlm: true,
          jobId: job.id,
        });
        const { location, vlmNotified } = data;

        setVlmNotified(vlmNotified);

        if (location) {
          setStockLocation(location);
          setStep('GET_FROM_VLM');
        } else {
          setStep('NO_ASSIGNED_LOCATION');
        }
      } catch (error) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to start job.' });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleConfirmStart}
      />
    </>
  );
};
