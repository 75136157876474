import { css } from 'styled-components';
import isObject from 'lodash/isObject';
import { media } from 'gantri-components';
import {
  AppSize,
  ResolutionAwarePropBase,
} from '../types/resolution-aware-prop.type';
import { ExtractStyleFromResolutionAwarePropEntry } from '../types/layout';

const extractSizeValueFromProperty = (prop: any, size: AppSize) => {
  if (isObject(prop)) {
    switch (size) {
      case 'lg':
        return prop['lg'];

      case 'md':
        return prop['md'] ?? prop['lg'];

      case 'sm':
        return prop['sm'] ?? prop['md'] ?? prop['lg'];
    }
  }

  return prop;
};

const extractValuesFromResolutionAwareProp = (
  prop: any,
): ResolutionAwarePropBase<any> => {
  if (Array.isArray(prop)) {
    return prop.reduce(
      (accumulator, currentProp) => {
        return {
          ...accumulator,
          lg: [
            ...accumulator.lg,
            extractSizeValueFromProperty(currentProp, 'lg'),
          ],
          md: [
            ...accumulator.md,
            extractSizeValueFromProperty(currentProp, 'md'),
          ],
          sm: [
            ...accumulator.sm,
            extractSizeValueFromProperty(currentProp, 'sm'),
          ],
        };
      },
      { lg: [], md: [], sm: [] },
    );
  }

  return isObject(prop)
    ? {
        lg: prop['lg'],
        md: prop['md'] ?? prop['lg'],
        sm: prop['sm'] ?? prop['md'] ?? prop['lg'],
      }
    : { lg: prop, md: prop, sm: prop };
};

export const generateStylesForResolutionAwareProps = (
  entries: ExtractStyleFromResolutionAwarePropEntry[],
) => {
  let lgCss = '';
  let mdCss = '';
  let smCss = '';

  entries.forEach((entry: ExtractStyleFromResolutionAwarePropEntry) => {
    if (entry.lookIn && Array.isArray(entry.resolutionAwareProp)) {
      throw new Error(
        'Specifying lookIn property when resolutionAwareProp is an array is not allowed',
      );
    }

    const { lg, md, sm } = extractValuesFromResolutionAwareProp(
      entry.resolutionAwareProp,
    );

    if (lg !== undefined) {
      const value = entry.lookIn ? entry.lookIn[lg] || lg : lg;

      lgCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'lg') : value
      }  ${entry.isImportant ? '!important' : ''};;`;
    }

    if (md !== undefined) {
      const value = entry.lookIn ? entry.lookIn[md] || md : md;

      mdCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'md') : value
      }  ${entry.isImportant ? '!important' : ''};;`;
    }

    if (sm !== undefined) {
      const value = entry.lookIn ? entry.lookIn[sm] || sm : sm;

      smCss += `${entry.cssProperty}: ${
        entry.valueFormatter ? entry.valueFormatter(value, 'sm') : value
      } ${entry.isImportant ? '!important' : ''};`;
    }
  });

  return css`
    ${lgCss}

    ${mdCss &&
    media.lessThan('md')`
       ${mdCss}
    `}

    ${smCss &&
    media.lessThan('sm')`
       ${smCss}
    `}
  `;
};

export const isSmallScreen = () => {
  return window.innerWidth <= 641;
};
