import { PartStatus, partStatuses } from '../../../../../../constants/options';

export const inProgressPartStatuses: PartStatus[] = [
  partStatuses.waiting,
  partStatuses.printing,
  partStatuses.finishing,
];

export const getIsInProgressPart = (status: string) => {
  return inProgressPartStatuses.some((s) => {
    return s === status;
  });
};
