import { useQueryFetch } from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { GetDashboardReportSummaryQueryArgs } from '../../dashboard.types';
import { getDashboardTechSummary } from './get-dashboard-tech-summary';

export const useFetchDashboardTechSummary = (
  props: GetDashboardReportSummaryQueryArgs,
) => {
  const { args, configs, enabled } = props;

  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!args && enabled,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch dashboard tech summary.',
        });
      },
      queryFn: () => {
        return getDashboardTechSummary.query(args, configs);
      },
      queryKey: getDashboardTechSummary.key(args, configs),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};
