import { atom } from 'recoil';
import { SetMachinesOnlineModalStep } from './set-machines-online-modal.types';
import { setMachinesOnlineModalSteps } from './set-machines-online-modal.constants';
import { BulkUpdateMachineRecord } from '../../../../api/machines/routes/bulk-update/bulk-update.types';

const atomKeyPrefix = 'SET_MACHINES_ONLINE_MODAL';

export const setMachinesOnlineModalAtoms = {
  detailsPanelMachines: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-details-panel-machines`,
  }),
  hasMachinesWithInvalidStatuses: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-has-ready-or-in-progress-machines`,
  }),
  machinesNotOnlined: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-not-onlined`,
  }),
  machinesOnlined: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-onlined`,
  }),
  step: atom<SetMachinesOnlineModalStep>({
    default: setMachinesOnlineModalSteps.confirmSetMachinesOnline,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
