import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedArgs,
  FetchPaginatedResponse,
} from './fetch-paginated.types';

export const fetchPaginatedDownloadsQuery = (
  requestBody: FetchPaginatedArgs,
) => {
  return axios
    .post<FetchPaginatedResponse>(
      `${coreApiUrl}/paginatedDownloads`,
      requestBody,
    )
    .then(({ data }) => {
      return data;
    });
};
