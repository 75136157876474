import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateInventoryPurchaseShipmentArgs,
  UpdateInventoryPurchaseShipmentResponse,
} from './update-inventory-purchase-shipment.types';

export const updateInventoryPurchaseShipment = ({
  id,
  ...body
}: UpdateInventoryPurchaseShipmentArgs) => {
  return axios.put<UpdateInventoryPurchaseShipmentResponse>(
    `${coreApiUrl}/inventory-purchase-shipments/${id}/update`,
    body,
  );
};
