import { Conditional } from 'gantri-components';
import { PropsWithChildren } from 'react';
import { ListProps } from './list.types';
import { listDefaultProps } from './list.presets';
import { StyledOrderedList, StyledUnorderedList } from './list.styles';

/**
 * Overrides default list element styling.
 *
 * @example
 * ```
 * <List type="ordered">
 *   {list.map((item, index) => (
 *     <li key={index}>
 *       // ...
 *     </li>
 *   ))}
 * </List>
 * ```
 */
export const List = (props: PropsWithChildren<ListProps>) => {
  const { children, color, type } = props;

  return (
    <Conditional
      condition={type === 'unordered'}
      Fallback={
        <StyledOrderedList $color={color}>{children}</StyledOrderedList>
      }
    >
      <StyledUnorderedList $color={color}>{children}</StyledUnorderedList>
    </Conditional>
  );
};

List.defaultProps = listDefaultProps;
