import { SearchField } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { FilterRow } from '../../../../common/filter-grid';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { ProductFilterProps } from './product-filter.types';

export const ProductFilter = (props: ProductFilterProps) => {
  const { atom, default: defaultValue, productNames } = props;

  const [productId, setProductId] = useRecoilState(atom);

  return (
    <FilterRow>
      <FilterResetLabel atom={atom} default={defaultValue} text="Product" />
      <SearchField
        idProperty="id"
        items={productNames}
        labelProperty="name"
        placeholder="Show all"
        searchable
        value={productId}
        onSelect={(product) => {
          setProductId(product?.id);
        }}
      />
    </FilterRow>
  );
};
