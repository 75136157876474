import { Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { createBatchModalAtoms } from '../../create-batch-modal.atoms';

export const CreateBatchModalContent = () => {
  const step = useRecoilValue(createBatchModalAtoms.step);
  const batchId = useRecoilValue(createBatchModalAtoms.batchId);

  switch (step) {
    case 'CREATE_BATCH':
      return (
        <Typography
          align="center"
          text="Are you sure you want to create a new batch?"
        />
      );

    case 'BATCH_CREATED':
      return (
        <>
          <Typography
            align="center"
            text={`Batch #${batchId} has been created.`}
          />
          <Typography
            align="center"
            text="Place a label on the rack or container."
          />
        </>
      );

    case 'ERROR_CREATING_BATCH':
      return (
        <>
          <Typography
            align="center"
            text="There was en error and batch could not be created."
          />
          <Typography align="center" text="Please try again." />
        </>
      );

    default:
      return null;
  }
};
