import { atom } from 'recoil';
import { OpenQueue } from '../../../../../api/queue/print-prepare/get-print-prepare-queues/get-print-prepare-queues.types';
import { ManageQueuesModalStep } from './manage-queues-modal.types';

const atomKeyPrefix = 'MANAGE_QUEUES_MODAL';

export const manageQueuesModalAtoms = {
  queueToDelete: atom<OpenQueue>({
    default: null,
    key: `${atomKeyPrefix}-queue-to-delete`,
  }),
  queues: atom<OpenQueue[]>({
    default: [],
    key: `${atomKeyPrefix}-queues`,
  }),
  step: atom<ManageQueuesModalStep>({
    default: 'QUEUES_LIST',
    key: `${atomKeyPrefix}-step`,
  }),
};
