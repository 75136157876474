import { useCallback, useEffect } from 'react';
import {
  AfterRowComponentDef,
  GetAfterRowComponentDef,
  Box,
  useRowSelection,
  RowSelectionProviders,
  dataAttrIsRowSelected,
  Conditional,
} from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { sortBy } from 'lodash';
import { PartTemplate } from '../../../../../../api/products/products.types';
import { PartJobBlocks } from './components/part-job-blocks';
import { useGetPartsSectionColumns } from './hooks/use-get-part-sections-columns';
import { PartsSectionHeader } from './components/parts-section-header';
import { productJobsTabAtoms } from '../../product-jobs.atoms';
import { StyledTable } from './parts-section.styles';
import { productAtoms } from '../../../../product.atoms';
import { getVersionDetails } from '../../../../../../components/common/jobs-template-table/helpers';

const PartsSectionComponent = () => {
  const product = useRecoilValue(productAtoms.product);
  const setIsEditingJobs = useSetRecoilState(productJobsTabAtoms.isEditingJobs);
  const isEditingPartId = useRecoilValue(productJobsTabAtoms.isEditingPartId);
  const isEditingStockJobsSection = useRecoilValue(
    productJobsTabAtoms.isEditingStockJobsSection,
  );
  const parts = sortBy(product?.parts || [], ['id']);

  const { isDraft } = getVersionDetails(product.version);

  const columns = useGetPartsSectionColumns();

  const { getIsRowSelected: getIsRowExpanded, selectRows: expandRows } =
    useRowSelection();

  const AfterRowComponent: AfterRowComponentDef<PartTemplate> = useCallback(
    ({ row }) => {
      const isExpanded = row.getIsSelected();

      return (
        <Conditional
          condition={isExpanded}
          Fallback={
            <tr
              style={{
                //! Rendered to DOM, but hidden. This allows the form validation to be run on the part job block.
                display: !isExpanded && 'none',
              }}
            >
              <td colSpan={columns.length}>
                <PartJobBlocks part={row.original} />
              </td>
            </tr>
          }
        >
          <PartJobBlocks part={row.original} />
        </Conditional>
      );
    },
    [],
  );

  const getAfterRowComponent: GetAfterRowComponentDef<PartTemplate> =
    useCallback(({ row }) => {
      const isExpanded = row.getIsSelected();

      return {
        Component: AfterRowComponent,
        // disabling the table formatting when collapsed allows the job blocks form to render and validate
        disableTableFormatting: !isExpanded,
      };
    }, []);

  useEffect(() => {
    expandRows([isEditingPartId]);
    setIsEditingJobs(!!isEditingPartId || !!isEditingStockJobsSection);
  }, [isEditingPartId, isEditingStockJobsSection]);

  return (
    <Box>
      <PartsSectionHeader parts={parts} />
      <StyledTable
        columns={columns}
        data={parts}
        getAfterRowComponent={getAfterRowComponent}
        getRowProps={({ original }) => {
          return {
            'data-part-template-id': original.id,
            [dataAttrIsRowSelected]: getIsRowExpanded(original.id),
          };
        }}
        rowSelection={{
          type: 'custom',
        }}
        stickyLastColumn={isDraft}
      />
    </Box>
  );
};

export const PartsSection = () => {
  return (
    <RowSelectionProviders>
      <PartsSectionComponent />
    </RowSelectionProviders>
  );
};
