import { ModalType } from './assign-machine-modal.types';

export const assignMachineModalText: Record<
  ModalType,
  { description: string; title: string }
> = {
  ASSIGN: {
    description: 'Select a ready machine for this job.',
    title: 'Assign machine to job',
  },
  REASSIGN: {
    description: 'Select another machine for this job.',
    title: 'Re-assign machine',
  },
  UNASSIGN: {
    description: 'Remove job from this machine?',
    title: 'Un-assign machine',
  },
};
