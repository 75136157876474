import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import {
  Checklist,
  ChecklistItem,
} from '../../common/job-checklist/job-checklist.types';
import { getFailingReasonsFromChecklist } from '../get-failing-reasons-from-checklist';

/** Checks against items where `status !== 'Pass'`. */
export const filterAgainstFailingChecklistItems = <
  KeyType extends FailedReason,
>(
  checklist: Partial<Checklist<KeyType>>,
  callback: (checklistItem: ChecklistItem) => boolean,
): KeyType[] => {
  const failingReasons = getFailingReasonsFromChecklist(checklist);

  return failingReasons.filter((reason) => {
    const checklistItem = checklist[reason];

    return callback(checklistItem);
  });
};
