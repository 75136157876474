import { atom, RecoilState } from 'recoil';
import { InventoryRequest } from '../../../../../../../api/jobs/jobs.types';
import { NonProductJobModalStep } from './start-non-product-job-modal.types';

export const atomKeyPrefix = 'START_NON_PRODUCT_JOB_MODAL';

export const startNonProductJobModalAtoms = {
  inventoryRequests: atom<InventoryRequest[]>({
    default: [],
    key: `${atomKeyPrefix}-inventory-requests`,
  }),
  notes: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-notes`,
  }),
  step: atom<NonProductJobModalStep>({
    default: 'CONFIRM_BEGIN',
    key: `${atomKeyPrefix}-assign-machine`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
} satisfies Record<string, RecoilState<any>>;
