import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../../../../../components/common/atoms-checkbox-list';
import DatePicker from '../../../../../../components/common/date-picker';
import { OnDateChanged } from '../../../../../../components/common/date-picker/date-picker.types';
import {
  FilterGrid,
  FilterRow,
} from '../../../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../../../components/common/filter-reset-label';
import { inventoryTransactionsFiltersAtoms } from './transactions-filter.atoms';
import {
  inventoryTransactionTypeOptions,
  inventoryTypeOptions,
} from './transactions-filter.constants';

export const TransactionsFilter = () => {
  const [dateRange, setDateRange] = useRecoilState(
    inventoryTransactionsFiltersAtoms.filters.dateRange,
  );

  const handleDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={inventoryTransactionsFiltersAtoms.filters.dateRange}
          default={inventoryTransactionsFiltersAtoms.defaults.filters.dateRange}
          text="Date"
        />
        <DatePicker
          autoWidth
          initialValue={{
            from: dateRange.from ? new Date(dateRange.from) : null,
            to: dateRange.to ? new Date(dateRange.to) : null,
          }}
          range
          onDateChanged={handleDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryTransactionsFiltersAtoms.filters.types}
          default={inventoryTransactionsFiltersAtoms.defaults.filters.types}
          text="Type"
        />
        <AtomsCheckboxList
          atom={inventoryTransactionsFiltersAtoms.filters.types}
          gridProps={{ columns: 2 }}
          items={[...inventoryTypeOptions]}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryTransactionsFiltersAtoms.filters.transactionTypes}
          default={
            inventoryTransactionsFiltersAtoms.defaults.filters.transactionTypes
          }
          text="Transaction Type"
        />
        <AtomsCheckboxList
          atom={inventoryTransactionsFiltersAtoms.filters.transactionTypes}
          gridProps={{ columns: 2 }}
          items={[...inventoryTransactionTypeOptions]}
        />
      </FilterRow>
    </FilterGrid>
  );
};
