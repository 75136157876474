import { UseDownloadProps } from './use-download.types';
import { useDownloadMultipleFiles } from './hooks/use-download-multiple-files';
import { useDownloadSingleFile } from './hooks/use-download-single-file';

export const useDownload = (props?: UseDownloadProps) => {
  const { downloadSingleFile, processing: processingSingleDownload } =
    useDownloadSingleFile(props);

  const { downloadMultipleFiles, processing: processingMultipleDownloads } =
    useDownloadMultipleFiles(props);

  const processing = processingSingleDownload || processingMultipleDownloads;

  return {
    downloadMultipleFiles,
    downloadSingleFile,
    /** Tracks the processing status of both `downloadSingleFile` and `downloadMultipleFiles`. If you need the processing status for each individually, import the hooks directly. */
    processing,
  };
};
