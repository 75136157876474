import { Modal } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { CompleteSandJobContent } from './components/complete-sand-job-content';
import { CompleteSandJobFooter } from './components/complete-sand-job-footer';
import { CompleteSandJobModalProps } from './complete-sand-job-modal.types';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { completeSandJobModalAtoms } from './complete-sand-job-modal.atoms';
import { jobStatuses } from '../../../../../../constants/options';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

export const CompleteSandJobModal = (props: CompleteSandJobModalProps) => {
  const { job, onClose, onJobUpdated } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    completeSandJobModalAtoms,
  );

  const updateOnClose = useRecoilValue(completeSandJobModalAtoms.updateOnClose);
  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  useEffect(() => {
    if (job.status === jobStatuses.inProgress) {
      setStep('COMPLETE_CHECKLIST');
    }
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            sectionsToShow={[
              'part',
              'color & material',
              'product',
              'instructions',
              'part diagram',
              'part diagram swatches',
            ]}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <CompleteSandJobFooter
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <CompleteSandJobContent job={job} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
