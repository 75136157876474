import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CancelStockArgs, CancelStockResponse } from './cancel-stock.types';

export const cancelStock = ({ stockId }: CancelStockArgs) => {
  return axios.put<CancelStockResponse>(
    `${coreApiUrl}/stocks/${stockId}/cancel`,
    {
      status: 'cancel',
    },
  );
};
