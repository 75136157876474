import { atom } from 'recoil';
import { CreateBatchModalStep } from './create-batch-modal.types';

export const atomKeyPrefix = 'CREATE_BATCH_MODAL';

export const createBatchModalAtoms = {
  batchId: atom<number>({
    default: undefined,
    key: `${atomKeyPrefix}-batch-id`,
  }),
  step: atom<CreateBatchModalStep>({
    default: 'CREATE_BATCH',
    key: `${atomKeyPrefix}-step`,
  }),
};
