import { media } from 'gantri-components';
import styled, { css, CSSProperties } from 'styled-components';
import { BorderTopDivider } from '../../../../../styles';

export const dataAttrRecalculatingHeight = 'data-recalculating-height';

export const StyledReportWrapper = styled.div<{
  minHeight?: CSSProperties['minHeight'];
  /** `ref` from `useInView` hook. */
  ref: (node?: Element) => void;
}>`
  padding-top: 1.8rem;
  padding-bottom: 3.2rem;
  min-height: 35rem;
  ${BorderTopDivider}

  ${({ minHeight = '20rem' }) => {
    return css`
      ${media.greaterThan('md')`
      min-height: ${minHeight};
    `}
    `;
  }}
`;

export const StyledTabsWrapper = styled.div`
  padding-bottom: 0.8rem;
`;

export const StyledSpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
`;

export const StyledScrollParent = styled.div`
  overflow-x: auto;
`;

export const StyledScrollChild = styled.div<{
  $minWidth?: CSSProperties['minWidth'];
}>`
  overflow: visible;
  height: 100%;
  ${({ $minWidth = '50rem' }) => {
    return css`
      min-width: ${$minWidth};
    `;
  }}
`;

export const StyledChartContainer = styled.div`
  height: 100%;

  &[${dataAttrRecalculatingHeight}] > * {
    display: none;
  }
`;
