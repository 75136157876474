import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { CloseModalButtonFooterProps } from './close-modal-button-footer.types';

export const CloseModalButtonFooter = (props: CloseModalButtonFooterProps) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={async () => {
        await handleCloseModal();
      }}
    />
  );
};
