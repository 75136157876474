export const awardOptions = [
  {
    award: 'CES Innovations',
    code: 'ces-innovations',
  },
  {
    award: 'Red Dot',
    code: 'red-dot',
  },
  {
    award: 'Communication Arts',
    code: 'communication-arts',
  },
  {
    award: 'D&AD',
    code: 'dad',
  },
  {
    award: 'The Cleverest Award by AD',
    code: 'cleverest',
  },
  {
    award: 'IDEA',
    code: 'idea',
  },
  {
    award: 'IDSA',
    code: 'idsa',
  },
  {
    award: 'Core 77 Design Award',
    code: 'core77',
  },
  {
    award: 'iF',
    code: 'if',
  },
  {
    award: 'International Design Awards',
    code: 'ida',
  },
  {
    award: 'Cooper Hewitt National Design Award',
    code: 'cooper-hewitt',
  },
  {
    award: 'Good Design',
    code: 'good-design',
  },
  {
    award: 'Spark',
    code: 'spark',
  },
  {
    award: 'Fast Company Most Innovative Companies',
    code: 'fast-innovative',
  },
  {
    award: 'A Design',
    code: 'adesign',
  },
  {
    award: 'Time Best Inventions',
    code: 'time-best',
  },
];
