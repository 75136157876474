import * as yup from 'yup';
import { optional } from '../../../../helpers/validators';

const requireIfHaveShipping = yup.lazy((_, context) => {
  return !context.parent.doNotShip
    ? yup.string().required('Required')
    : yup.string().nullable();
});

export const EditAddressSchema = yup.object().shape({
  city: requireIfHaveShipping,
  country: requireIfHaveShipping,
  firstName: requireIfHaveShipping,
  lastName: requireIfHaveShipping,
  phone: optional(),
  state: requireIfHaveShipping,
  street: requireIfHaveShipping,
  zip: requireIfHaveShipping,
});
