import { FormatAsLocaleNumberOptions } from './format-as-locale-number.types';

/**
 * Set `locale` in `options` to override default `'en-US'`.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options */
export const formatAsLocaleNumber = (
  value: number,
  options: FormatAsLocaleNumberOptions = {},
) => {
  const { locale, ...numberOptions } = options;
  const number = new Intl.NumberFormat(locale || 'en-US', {
    ...numberOptions,
  });

  return number.format(value);
};
