import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultStocksGroupBy } from '../../report-stocks.constants';
import { ReportStocksQueries } from '../../report-stocks.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportStocksQueries>();
  const { groupBy = defaultStocksGroupBy, productId, version } = queries;

  if (!/^productId$/i.test(groupBy)) {
    return 'Order Type';
  }

  if (version) {
    return 'SKU';
  }

  if (productId) {
    return 'Version';
  }

  return 'Product Name';
};
