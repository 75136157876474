import React, { FC, useMemo, useState } from 'react';
import {
  Button,
  Flex,
  Modal,
  SearchField,
  Typography,
} from 'gantri-components';
import {
  canadianShippingMethods,
  ShippingMethod,
  usShippingMethods,
} from '../../../../../../constants/options';
import { EditShippingProps } from './edit-shipping.types';

export const EditShippingModal: FC<EditShippingProps> = (props) => {
  const {
    address,
    handleConfirm,
    onClose,
    processing = false,
    value = null,
  } = props;

  const options = useMemo(() => {
    if (address.country === 'United States' || address.country === 'US') {
      return usShippingMethods;
    }

    if (address.country === 'Canada' || address.country === 'CA') {
      return canadianShippingMethods;
    }

    return [];
  }, [address.country]);

  const [shippingMethodSelected, setShippingMethodSelected] =
    useState<ShippingMethod>(value);

  return (
    <Modal
      footer={
        <>
          <Button
            processing={processing}
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />

          <Button
            size="large"
            text="Confirm"
            onClick={() => {
              return handleConfirm(shippingMethodSelected);
            }}
          />
        </>
      }
      header="Shipping Method"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Flex
        direction="column"
        gap="2rem"
        justifyContent="center"
        verticalMargin="x"
      >
        <Typography
          align="center"
          text="Select shipping option for this order."
        />

        <SearchField
          idProperty="shippingType"
          items={options}
          labelProperty="label"
          placeholder="Select shipping method..."
          value={shippingMethodSelected?.shippingType}
          onSelect={(op) => {
            return setShippingMethodSelected(op);
          }}
        />
      </Flex>
    </Modal>
  );
};
