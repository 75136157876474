import { Conditional, Dropdown, Typography } from 'gantri-components';
import { useEffect } from 'react';
import { JobStep, jobSteps } from '../../../../../../../constants/options';
import { getVersionDetails } from '../../../../helpers';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks/use-validate-fields';
import { JobTemplateStepCellProps } from './job-template-step-cell.types';

export const JobTemplateStepCell = (props: JobTemplateStepCellProps) => {
  const { getValue, onEdit, partId, restrictJobStepTo, row, version } = props;

  const { isMinorVersion } = getVersionDetails(version);
  const { getHasError } = useValidateFields();
  const hasError = getHasError('step');

  const isEditingRow = useGetIsEditingRow(row);

  const availableSteps: JobStep[] = partId
    ? [jobSteps.finish, jobSteps.print]
    : [jobSteps.assemble, jobSteps.pack, jobSteps.qa];
  const items = availableSteps.map((step) => {
    return { label: step, value: step };
  });

  useEffect(() => {
    if (restrictJobStepTo && getValue() !== restrictJobStepTo) {
      onEdit({
        ...row.original,
        step: restrictJobStepTo,
      });
    }
  }, []);

  return (
    <Conditional
      condition={isEditingRow && !restrictJobStepTo && !isMinorVersion}
      Fallback={<Typography text={getValue()} />}
    >
      <Dropdown
        errorMessage={hasError ? 'Required' : null}
        items={items}
        placeholder="Step"
        value={getValue()}
        onSelect={({ value }) => {
          onEdit({
            ...row.original,
            step: value,
          });
        }}
      />
    </Conditional>
  );
};
