import { Typography, Button, Stack } from 'gantri-components';
import { StyledErrorFallback } from './error.fallback.styles';
import { ErrorFallbackTypes } from './error-fallback.types';
import { StyledAnchor } from '../common/styled-anchor';

export const ErrorFallback = (props: ErrorFallbackTypes) => {
  const { error, resetErrorBoundary } = props;

  return (
    <StyledErrorFallback>
      <Stack alignContent="center">
        <Typography
          align="center"
          color="t1"
          text="Something went wrong :("
          textStyle="bold"
          variant="p1"
        />
        <Typography color="alert" text={error.message} variant="p1" />
        <StyledAnchor
          target="_blank"
          text="Please report this bug to the software team."
          to="https://form.asana.com/?k=uxvFdJrnWvaxwV-Khwk9XA&d=1186582822873190"
        />
        <Button text="Try again" onClick={resetErrorBoundary} />
      </Stack>
    </StyledErrorFallback>
  );
};
