import { GetStatus } from './get-table-row-status-from-attentions.types';

export const getTableRowStatusFromAttentions: GetStatus = (row) => {
  const { attentions } = row.original;

  if (attentions?.length) {
    return 'error';
  }

  return undefined;
};
