import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { UpdateNotesArgs, UpdateNotesResponse } from './update-notes.types';

export const updateNotes = ({ note, stockId }: UpdateNotesArgs) => {
  return axios.put<UpdateNotesResponse>(
    `${coreApiUrl}/stocks/${stockId}/notes`,
    {
      note,
    },
  );
};
