import { useEffect, useState } from 'react';
import { Button, TextField } from 'gantri-components';
import {
  StyledPageSection,
  StyledPageSubsection,
} from '../../../../components/layout/page-layout-styles';
import { StyledFieldLabel, StyledFieldWrapper } from '../../designer-styles';
import { PaypalProps } from './paypal.types';
import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { getDesigner } from '../../../../api/designers/routes';
import { useUpdateDesigner } from '../../../../api/designers/routes/update-designer/update-designer.query';
import { DesignerData } from '../../designer.types';

export const DesignerPayPal = (props: PaypalProps) => {
  const { designerInfo, updateDesignerInfo } = props;

  const paypal = designerInfo.paypalInfo || { email: '', name: '' };
  const [name, setName] = useState<string>(paypal.name || '');
  const [email, setEmail] = useState<string>(paypal.email || '');

  const { isLoading: processing, onUpdateDesigner } = useUpdateDesigner();

  const invalidateDesignerQueryCache = useGetInvalidateQueryCache(getDesigner);

  const handlePaypalUpdate = async () => {
    const updatedDesignerInfo: DesignerData = {
      ...designerInfo,
      paypalInfo: {
        email,
        name,
      },
    };

    onUpdateDesigner(
      { designerInfo: updatedDesignerInfo },
      {
        onSuccess: async () => {
          updateDesignerInfo({ type: 'state', value: updatedDesignerInfo });

          await invalidateDesignerQueryCache();
        },
      },
    );
  };

  const onNameChange = (value: string) => {
    return setName(value);
  };

  const onEmailChange = (value: string) => {
    return setEmail(value);
  };

  useEffect(() => {
    const { paypalInfo } = designerInfo;

    if (paypalInfo) {
      setName(paypalInfo.name);
      setEmail(paypalInfo.email);
    }
  }, [designerInfo]);

  return (
    <StyledPageSection>
      <StyledPageSubsection>
        <StyledFieldWrapper>
          <StyledFieldLabel text="Name" textStyle="bold" variant="p2" />
          <TextField
            placeholder="Name"
            value={name}
            onTextChange={onNameChange}
          />
        </StyledFieldWrapper>
        <StyledFieldWrapper>
          <StyledFieldLabel text="Email" textStyle="bold" variant="p2" />
          <TextField
            placeholder="Email"
            value={email}
            onTextChange={onEmailChange}
          />
        </StyledFieldWrapper>
      </StyledPageSubsection>
      <Button
        processing={processing}
        text="Update"
        onClick={handlePaypalUpdate}
      />
    </StyledPageSection>
  );
};
