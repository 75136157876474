import { Typography } from 'gantri-components';
import { StyledToolWrapper } from '../../../../advanced-checklist.styles';
import { ThumbnailWithZoomModal } from '../../../thumbnail-with-zoom-modal';
import { BinaryImageProps } from './binary-image.types';

export const BinaryImage = (props: BinaryImageProps) => {
  const { checklistItem } = props;

  return (
    <StyledToolWrapper
      columns={1}
      horizontalPadding="1rem"
      justifyItems="center"
      paddingBottom="0.8rem"
      paddingTop="1.2rem"
    >
      <Typography
        align="center"
        text="Use the following image to determine presence of issue:"
        variant="p3"
      />
      <ThumbnailWithZoomModal
        alt={checklistItem.description}
        heading={checklistItem.label}
        src={checklistItem.exampleImgSrc}
      />
    </StyledToolWrapper>
  );
};
