import { useRecoilValue } from 'recoil';
import { SelectTemplatePage } from './components/select-template-page';
import { EditBatchPage } from './components/edit-batch-page';
import { ConfirmBatchPage } from './components/confirm-batch-page';
import { batchPartCreationAtoms } from '../../batch-part-creation.atoms';

export const BatchPartCreationContent = () => {
  const step = useRecoilValue(batchPartCreationAtoms.step);

  switch (step) {
    case 'SELECT_TEMPLATE':
      return <SelectTemplatePage />;

    case 'CREATE_FROM_SCRATCH':
    case 'CREATE_FROM_PRODUCT':
      return <EditBatchPage />;

    case 'CONFIRM_BATCH_CREATION':
      return <ConfirmBatchPage />;

    default:
      return null;
  }
};
