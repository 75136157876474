import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  OverrideDelayedPartsArgs,
  OverrideDelayedPartsResponse,
} from './override-delayed-parts.types';

export const overrideDelayedParts = ({ stockId }: OverrideDelayedPartsArgs) => {
  return axios
    .put<OverrideDelayedPartsResponse>(
      `${coreApiUrl}/stocks/${stockId}/override-delayed-parts`,
    )
    .then(({ data }) => {
      return data;
    });
};
