import { v4 as uuidv4 } from 'uuid';
import {
  getBase64FromBlob,
  defaultImageExtensions,
  SelectedFileDetails,
} from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { bulkUploadModalAtoms } from '../../../../bulk-upload-modal.atoms';
import {
  invalidFileReasons,
  productSkuLevelAssetsFileTypes,
  productFileTypesMaxQty,
} from '../../../../bulk-upload-modal.constants';
import { useProcessFileNameAndReturnMatchingSkus } from '../use-process-file-name-and-return-matching-skus';
import { FileValidationRecord } from '../../../../bulk-upload-modal.types';
import { delayFor } from '../../../../../../../../../../../../helpers/delay-for';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import {
  SkuAssetUploadFileData,
  FormattedSkuAssets,
} from '../../../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';

export const useOnValidationCompleted = () => {
  const skus = useRecoilValue(productAtoms.skus);
  const setNumFilesAdded = useSetRecoilState(
    bulkUploadModalAtoms.numFilesAdded,
  );
  const setFileValidationByTimestamp = useSetRecoilState(
    bulkUploadModalAtoms.fileValidationByTimestamp,
  );
  const setProcessingFiles = useSetRecoilState(
    bulkUploadModalAtoms.processingFiles,
  );
  const setAssetsBySku = useSetRecoilState(bulkUploadModalAtoms.assetsBySku);
  const setIsHistoryExpanded = useSetRecoilState(
    bulkUploadModalAtoms.isHistoryExpanded,
  );

  const processFileNameAndReturnMatchingSkus =
    useProcessFileNameAndReturnMatchingSkus();

  return async ({
    invalidFiles,
    validFiles,
  }: {
    invalidFiles: SelectedFileDetails[];
    validFiles: SelectedFileDetails[];
  }) => {
    try {
      const updatedInvalidFiles = [...invalidFiles];

      const handleSingleValidFile = async (
        validFile: SelectedFileDetails,
      ): Promise<SkuAssetUploadFileData> => {
        const { fileBlob, fileExtension, fileName, metadata } = validFile;

        const { fileType, skus } = processFileNameAndReturnMatchingSkus({
          fileExtension,
          fileName,
        });

        const isImage = defaultImageExtensions.some((ext) => {
          return ext === fileExtension;
        });

        const fileUrl = isImage ? await getBase64FromBlob(fileBlob) : '';

        const bulkUploadFileData: SkuAssetUploadFileData = {
          fileBlob,
          fileName,
          fileType,
          fileUrl,
          id: uuidv4(),
          metadata,
          skus,
        };

        return bulkUploadFileData;
      };

      const formattedValidFiles: SkuAssetUploadFileData[] = await Promise.all(
        validFiles.map(handleSingleValidFile),
      );

      setAssetsBySku((prevAssetsBySku) => {
        const updatedAssetsBySku =
          formattedValidFiles.reduce<FormattedSkuAssets>(
            (
              updatedAssetsBySkuAccumulator,
              { fileType, skus, ...newFileData },
            ) => {
              const updatedData = skus.reduce<FormattedSkuAssets>(
                (updatedDataAccumulator, sku) => {
                  const isProductPhotos =
                    fileType ===
                    productSkuLevelAssetsFileTypes.whiteBackgroundPhotos;
                  const existingProductPhotos =
                    updatedAssetsBySkuAccumulator?.[sku]?.[
                      productSkuLevelAssetsFileTypes.whiteBackgroundPhotos
                    ] || [];

                  if (isProductPhotos) {
                    const isInvalidQty =
                      existingProductPhotos.length >=
                      productFileTypesMaxQty[fileType];

                    if (isInvalidQty) {
                      updatedInvalidFiles.push({
                        errorMessage: invalidFileReasons.isInvalidQty,
                        fileBlob: undefined,
                        fileExtension: undefined,
                        fileName: newFileData?.fileName,
                        fileSize: undefined,
                        metadata: undefined,
                      });

                      return updatedDataAccumulator;
                    }
                  }

                  const dataForSku = updatedAssetsBySkuAccumulator[sku];

                  const newDataForFileType = isProductPhotos
                    ? [...existingProductPhotos, newFileData]
                    : newFileData;

                  const selectedWhiteBackgroundPhoto =
                    existingProductPhotos.length
                      ? dataForSku.selectedWhiteBackgroundPhoto
                      : newFileData.fileName;

                  return {
                    ...updatedDataAccumulator,
                    [sku]: {
                      ...dataForSku,
                      [fileType]: newDataForFileType,
                      selectedWhiteBackgroundPhoto,
                    },
                  };
                },
                {},
              );

              return {
                ...updatedAssetsBySkuAccumulator,
                ...updatedData,
              };
            },
            prevAssetsBySku,
          );

        return updatedAssetsBySku;
      });

      setFileValidationByTimestamp((prevRecords) => {
        const timestamp = Date.now();

        const processingRecord: Record<string, FileValidationRecord> = {
          [timestamp]: {
            invalidFiles: updatedInvalidFiles.map(
              ({ errorMessage, fileName }) => {
                return { errorMessage, fileName };
              },
            ),
            timestamp,
            validFiles: formattedValidFiles,
          },
        };

        return {
          ...prevRecords,
          ...processingRecord,
        };
      });

      setNumFilesAdded((value) => {
        return value + validFiles.length;
      });

      // Delay is not necessary, but helps keep the timing of the updated table rows in sync with both the dropzone processing status change and history panel opening
      await delayFor(skus.length * 10);

      setIsHistoryExpanded(true);
    } finally {
      setProcessingFiles(false);
    }
  };
};
