import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Conditional,
  FormikInput,
  Grid,
  SearchField,
  Typography,
} from 'gantri-components';
import { debounce } from 'lodash';
import { SectionProps } from './section.types';
import { Vendor } from '../../../../../api/inventories/routes/get-inventory/get-inventory.types';
import routePaths from '../../../../../config/route-paths';
import { useFetchAllVendorNamesQuery } from '../../../../../api/vendors/routes';
import { FormItem } from '../../../components/form-item';

export const OverviewVendorSection: FC<SectionProps> = ({
  inventory,
  isEditModeActive,
}) => {
  const vendor = inventory.vendor || ({} as Vendor);
  const [vendorSearch, setVendorSearch] = useState<string>('');

  const { isLoading, vendors } = useFetchAllVendorNamesQuery({
    query: vendorSearch,
    showLoading: false,
  });

  return (
    <Grid maxWidth={isEditModeActive ? '60rem' : 'unset'} rowGap="s1">
      <FormItem
        isEditMode={isEditModeActive}
        label="Vendor"
        orientation="row"
        viewElement={
          !!vendor?.id ? (
            <Link to={`${routePaths.vendors}/${vendor?.id}`}>
              <Typography
                color="link"
                decoration="underline"
                text={vendor?.name}
              />
            </Link>
          ) : (
            '-'
          )
        }
      >
        <FormikInput
          Field={
            <SearchField
              autoSelectFirst={false}
              idProperty="id"
              items={isLoading ? [] : vendors ?? []}
              labelProperty="name"
              maxHeight="33rem"
              processing={isLoading}
              searchMode="external"
              onSelect={() => {
                setVendorSearch('');
              }}
              onTextChange={debounce(setVendorSearch, 300)}
            />
          }
          fieldVariant="standard"
          name="vendorId"
        />
      </FormItem>

      <FormItem
        isEditMode={isEditModeActive}
        label="Vendor Item Number"
        orientation="row"
        viewElement={
          <Conditional condition={!!vendor?.vendorNumber}>
            <Typography text={vendor?.vendorNumber} />
          </Conditional>
        }
      >
        <FormikInput name="vendorNumber" placeholder="Enter a vendor number" />
      </FormItem>
    </Grid>
  );
};
