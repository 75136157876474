import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { NoteResponse } from '../../designs.types';

export const sendNote = (designId: number, content: string) => {
  return axios.post<NoteResponse>(getCoreApiUrl('notes'), {
    designId,
    notes: content,
  });
};
