import { Cell, Typography } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import DatePicker from '../../../../common/date-picker';
import { DatesFilterProps } from './dates-filter.types';
import { OnDateChanged } from '../../../../common/date-picker/date-picker.types';
import { jobsPageAtoms } from '../../job-filter.atoms';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { FilterRow } from '../../../../common/filter-grid';

export const DatesFilter = (props: DatesFilterProps) => {
  const { endDate, startDate } = props;

  const setCreatedDateRange = useSetRecoilState(
    jobsPageAtoms.filters.createdDateRange,
  );
  const setCompletedDateRange = useSetRecoilState(
    jobsPageAtoms.filters.completedDateRange,
  );

  const startDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setCreatedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const endDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setCompletedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  return (
    <FilterRow>
      <FilterResetLabel
        atomsList={[
          jobsPageAtoms.filters.createdDateRange,
          jobsPageAtoms.filters.completedDateRange,
        ]}
        defaultsList={[
          jobsPageAtoms.defaults.filters.createdDateRange,
          jobsPageAtoms.defaults.filters.completedDateRange,
        ]}
        text="Dates"
      />
      <Cell>
        <Typography marginBottom="5px" text="Start" />
        <DatePicker
          initialValue={startDate}
          range
          onDateChanged={startDateChange}
        />

        <Typography marginBottom="5px" marginTop="1.5rem" text="End" />
        <DatePicker
          initialValue={endDate}
          range
          onDateChanged={endDateChange}
        />
      </Cell>
    </FilterRow>
  );
};
