import PropTypes from 'prop-types';
import { Dropdown, TextField } from 'gantri-components';
import { useEffect } from 'react';
import {
  canadaStates,
  countries,
  usaStates,
} from '../../../../constants/locales';

const StateInput = ({
  country,
  hasError,
  inputClassnames,
  labelText,
  name,
  onBlur,
  onInputChange,
  onSelectChange,
  value,
}) => {
  const { code: usaCode, name: usaName } =
    countries.find(({ name: countryName }) => {
      return countryName === 'United States';
    }) || '';
  const countryValue = !country ? usaName : country;

  const isUsa = countryValue === usaCode || countryValue === usaName;
  const isCanada = countryValue === 'CA' || countryValue === 'Canada';

  useEffect(() => {
    if (!value) {
      if (isUsa) {
        onSelectChange(usaStates[0].abbreviation);
      } else if (isCanada) {
        onSelectChange(canadaStates[0].abbreviation);
      }
    }
  }, []);

  if (isUsa) {
    return (
      <Dropdown
        errorMessage={hasError ? 'Required' : null}
        idProperty="abbreviation"
        items={usaStates}
        labelProperty="name"
        labelText={labelText}
        value={value || usaStates[0].abbreviation}
        onSelect={(record) => {
          return onSelectChange(record.abbreviation);
        }}
      />
    );
  }

  if (isCanada) {
    return (
      <Dropdown
        errorMessage={hasError ? 'Required' : null}
        idProperty="abbreviation"
        items={canadaStates}
        labelProperty="name"
        labelText={labelText}
        value={value || canadaStates[0].abbreviation}
        onSelect={(record) => {
          return onSelectChange(record.abbreviation);
        }}
      />
    );
  }

  return (
    <TextField
      className={inputClassnames}
      errorMessage={hasError ? 'Required' : null}
      labelText={labelText}
      name={name}
      placeholder=""
      value={value || ''}
      onBlur={onBlur}
      onChange={onInputChange}
    />
  );
};

StateInput.defaultProps = {
  country: '',
  hasError: false,
  inputClassnames: '',
  onInputChange: () => {
    return null;
  },
  onSelectChange: () => {
    return null;
  },
  value: '',
};

StateInput.propTypes = {
  country: PropTypes.string,
  hasError: PropTypes.bool,
  inputClassnames: PropTypes.string,
  labelText: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onInputChange: PropTypes.func,
  onSelectChange: PropTypes.func,
  value: PropTypes.string,
};

export default StateInput;
