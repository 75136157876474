import { atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { AtomPageName } from './get-generic-atom-family.types';

const { persistAtom } = recoilPersist();

export const getGenericAtomFamily = <Type = any>({
  defaultValue,
  key,
}: {
  defaultValue: Type;
  /** Should be unique to the system. */
  key: string;
}) => {
  return atomFamily<Type, AtomPageName>({
    default: defaultValue,
    effects_UNSTABLE: [persistAtom],
    key,
  });
};
