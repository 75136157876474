import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { TableSyncState } from '../../../../hooks/use-table-columns-sync/use-table-columns-sync.types';

export const getTableColumns = (
  tableId: string,
  configs?: AxiosRequestConfig,
) => {
  return axios.get<TableSyncState>(
    `${coreApiUrl}/users/table-config?tableId=${tableId}`,
    configs,
  );
};
