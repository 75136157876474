import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { useInvalidateJobsFetchCaches } from '../hooks/use-invalidate-jobs-fetch-caches';
import {
  CancelNonProductJobArgs,
  CancelNonProductJobResponse,
} from './cancel-non-product-job.types';
import { cancelNonProductJob } from './cancel-non-product-job';

export const useCancelNonProductJob = <
  TransformedData = CancelNonProductJobResponse,
>(
  props?: GenericMutateQueryProps<
    CancelNonProductJobArgs,
    CancelNonProductJobResponse,
    TransformedData
  >,
) => {
  const { invalidateJobCaches } = useInvalidateJobsFetchCaches();

  const { onMutate: onCancelNonProductJob, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to cancel job.',
    mutationFn: cancelNonProductJob,
    onSuccess: async (...args) => {
      await invalidateJobCaches();
      await props?.onSuccess?.(...args);
    },
    ...props,
  });

  return { ...rest, onCancelNonProductJob };
};
