import { Grid, Typography } from 'gantri-components';
import { VLMLocationGridProps } from './vlm-location-grid.types';

export const VLMLocationGrid = ({ stockLocation }: VLMLocationGridProps) => {
  return (
    <Grid
      columnGap="1.6rem"
      columns={2}
      data-testid="VLM-location-grid"
      maxWidth="12.8rem"
      rowGap="0.8rem"
      style={{ whiteSpace: 'nowrap' }}
    >
      <Typography fontWeight="bold" text="Rack" />
      <Typography text={stockLocation?.rack} />
      <Typography fontWeight="bold" text="Shelf/Tray" />
      <Typography text={stockLocation?.shelf} />
      <Typography fontWeight="bold" text="Bin" />
      <Typography text={stockLocation?.bin} />
    </Grid>
  );
};
