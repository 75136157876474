import { Grid, Icon, Stack, Tooltip, Typography } from 'gantri-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import routePaths from '../../../../../../config/route-paths';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { formatDate } from '../../../../../../helpers/formatter';
import { Issue } from '../../../../machines.types';
import { StyledTooltipWrapper } from './issue-type.styles';
import { IssueTypeProps } from './issue-type.types';

export const IssueType = ({
  groupMultipleIssues,
  issues,
  showMoreInfo,
}: IssueTypeProps) => {
  const isMultipleIssues = issues.length > 1 && groupMultipleIssues;
  const [firstIssue] = issues;
  const difficultyValue = firstIssue.difficulty?.toLowerCase() as Lowercase<
    Issue['difficulty']
  >;
  const iconName: IconProps['name'] = isMultipleIssues
    ? 'alert:i_circle'
    : difficultyValue
    ? `machine:difficulty_${difficultyValue}`
    : undefined;
  const issueType = isMultipleIssues ? 'Multiple issues' : firstIssue.type;
  const showTooltip = showMoreInfo && !isMultipleIssues;
  const name =
    firstIssue.user?.firstName || firstIssue.user?.lastName
      ? `${firstIssue.user?.firstName} ${firstIssue.user?.lastName}`
      : '';

  return (
    <Grid
      alignItems="center"
      columns={
        showTooltip
          ? 'repeat(auto-fit, minmax(0, max-content))'
          : 'max-content 1fr'
      }
      gap="x"
    >
      {iconName ? (
        <Icon
          color={isMultipleIssues ? 't1' : 'link'}
          name={iconName}
          size="1.6rem"
        />
      ) : (
        <span />
      )}
      <Typography
        color="t1"
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
        text={issueType}
      />
      {showTooltip && (
        <StyledTooltipWrapper>
          <Tooltip
            Component={
              <Stack gap=".5x" horizontalPadding="2x" verticalPadding="x">
                <div>
                  <Typography
                    text={`${formatDate(firstIssue.createdAt, LONG_FORMAT)}${
                      name ? ' by ' : ''
                    }`}
                  />
                  {name && (
                    <StyledAnchor
                      href={`${routePaths.users}/${firstIssue.user?.id}`}
                      target="_blank"
                      text={name}
                    />
                  )}
                </div>
                <Grid
                  alignItems="center"
                  columns="max-content 1fr"
                  gap="0.6rem"
                >
                  <Icon color="link" name={iconName} />
                  <Typography color="link" text={firstIssue.difficulty} />
                </Grid>
              </Stack>
            }
            position="top"
          >
            <Icon name="alert:i_circle" top="2px" />
          </Tooltip>
        </StyledTooltipWrapper>
      )}
    </Grid>
  );
};
