import { UserRole, userRoles } from '../../constants/users';

/** Returns all roles greater than and equal to the provided access level. */
export const getRolesForAccessLevelAndAbove = (accessLevel: UserRole) => {
  if (accessLevel === userRoles.lead) {
    return [userRoles.admin, userRoles.lead];
  }

  if (accessLevel === userRoles.worker) {
    return [userRoles.admin, userRoles.lead, userRoles.worker];
  }

  return [userRoles.admin];
};
