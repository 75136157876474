import { UseDownloadProps } from '../../use-download.types';
import { useHandleDownloadRequest } from '../use-handle-download-request';
import { pause } from '../../../../helpers/pause';
import { useNotification } from '../../../useNotification';
import { DownloadMultipleFiles } from './use-download-multiple-files.types';

export const useDownloadMultipleFiles = (props?: UseDownloadProps) => {
  const { onComplete, onError, onStart } = props || {};

  // intentionally not using state
  let allDownloadsSuccessful = true;

  const { onInterceptProcessingRequest, processing } = useNotification();

  const handleDownloadFile = useHandleDownloadRequest({
    onError,
  });

  const loopOverMultipleDownloads: DownloadMultipleFiles = async (
    files,
    options,
  ) => {
    const { chunkDelayMs = 1500, chunkSize = 10 } = options || {};

    const chunkToDownload = files.splice(0, chunkSize);

    try {
      await Promise.all(
        chunkToDownload.map(async (file) => {
          await handleDownloadFile(file);
        }),
      );
    } catch (error) {
      allDownloadsSuccessful = false;
    } finally {
      // placed in finally to ensure a single download failure does not prevent the remaining downloads from starting
      if (files.length) {
        await pause(chunkDelayMs);
        await loopOverMultipleDownloads(files, {
          ...options,
        });
      }
    }
  };

  const downloadMultipleFiles: DownloadMultipleFiles = async (
    files,
    options,
  ) => {
    const hasValidUrls = files.some(({ url }) => {
      return url;
    });

    if (hasValidUrls) {
      await onInterceptProcessingRequest(async () => {
        onStart?.();

        await loopOverMultipleDownloads([...files], options);

        if (allDownloadsSuccessful) {
          onComplete?.();
        }
      });
    }
  };

  return {
    downloadMultipleFiles,
    processing,
  };
};
