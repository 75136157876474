import { Cell, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { Label } from '../../../../components/label';
import routePaths from '../../../../config/route-paths';
import { ResponsiveGrid } from '../responsive-grid';
import { SectionHeading } from '../section-heading';
import { InventorySectionProps } from './inventory-section.types';

export const InventorySection = (props: InventorySectionProps) => {
  const { editedInventoryPurchase } = props;

  return (
    <>
      <SectionHeading text="Inventory" />
      {editedInventoryPurchase.inventory && (
        <ResponsiveGrid>
          <Cell>
            <Label paddingBottom=".5x" text="Name" />
            <Typography text={editedInventoryPurchase.inventory.name} />
          </Cell>
          <Cell>
            <Label paddingBottom=".5x" text="Type" />
            <Typography text={editedInventoryPurchase.inventory.type} />
          </Cell>
          <Cell>
            <Label paddingBottom=".5x" text="Inventory ID" />
            <Link
              to={`${routePaths.inventories}/${editedInventoryPurchase.inventoryId}`}
            >
              <Typography
                color="link"
                text={`#${editedInventoryPurchase.inventoryId}`}
              />
            </Link>
          </Cell>
          <Cell>
            <Label paddingBottom=".5x" text="Vendor" />
            <Typography text={editedInventoryPurchase.inventory.vendor} />
          </Cell>
          <Cell>
            <Label paddingBottom=".5x" text="Email" />
            <Typography
              text={
                editedInventoryPurchase.inventory.email ||
                'No email associated with this vendor.'
              }
            />
          </Cell>
        </ResponsiveGrid>
      )}
    </>
  );
};
