import { Button, Modal, Typography } from 'gantri-components';
import { useNotification } from '../../../hooks/useNotification';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../jobs-details-panel-wrapper/components/jobs-details-panel';
import { CancelJobModalProps } from './cancel-job-modal.types';
import { getJobInfo } from '../../../pages/jobs/helpers/get-job-info';
import { useCancelJob, useCancelNonProductJob } from '../../../api/jobs/routes';

export const CancelJobModal = (props: CancelJobModalProps) => {
  const { job, onClose, onUpdate } = props;

  const { onInterceptProcessingRequest, processing } = useNotification();

  const { onCancelJob } = useCancelJob({ onSuccess: onUpdate });
  const { onCancelNonProductJob } = useCancelNonProductJob({
    onSuccess: onUpdate,
  });

  const handleConfirmCancel = async () => {
    await onInterceptProcessingRequest(async () => {
      const { isProductJob, isRepairJob } = getJobInfo(job);

      if (isProductJob || isRepairJob) {
        await onCancelJob({ jobId: job.id });
      } else {
        await onCancelNonProductJob({ jobId: job.id });
      }
    });
  };

  return (
    <Modal
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      footer={
        <>
          <Button
            size="large"
            text="No"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={processing}
            size="large"
            text="Yes"
            onClick={async () => {
              await handleConfirmCancel();
              onClose();
            }}
          />
        </>
      }
      header={`Cancel ${job.description}`}
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        text="Are you sure you want to cancel this job?"
      />
    </Modal>
  );
};
