import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { fetchAllInventoriesForStock } from './fetch-all-for-stock';
import { FetchAllInventoriesForStockResponse } from './fetch-all-for-stock.types';

interface Props<TData>
  extends UseQueryProps<TData, FetchAllInventoriesForStockResponse> {
  inventoryIds?: number[];
}

export const useFetchAllInventoriesForStockQuery = <TData>(
  props?: Props<TData>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<
    FetchAllInventoriesForStockResponse | TData
  >({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventories.',
        });
      },
      queryFn: () => {
        return fetchAllInventoriesForStock.query(props?.inventoryIds);
      },
      queryKey: fetchAllInventoriesForStock.key(props?.inventoryIds),
      select: (response: FetchAllInventoriesForStockResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    inventories: data as TData,
    isLoading,
  };
};

export const useInvalidateFetchAllInventoriesForStockCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateFetchAllInventoriesForStockCache = async (
    inventoryIds?: number[],
  ) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!inventoryIds
        ? key === fetchAllInventoriesForStock.key(inventoryIds)[0].key
        : groupKey === fetchAllInventoriesForStock.groupKey();
    });
  };

  return { invalidateFetchAllInventoriesForStockCache };
};
