import { Button } from 'gantri-components';
import { QueuesListFooterProps } from './queues-list-footer.types';

export const QueuesListFooter = (props: QueuesListFooterProps) => {
  const { handleCloseModal } = props;

  return (
    <Button text="Cancel" variant="secondary" onClick={handleCloseModal} />
  );
};
