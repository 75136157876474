import { Typography } from 'gantri-components';
import { StyledPageSection } from '../layout/page-layout-styles';
import { PageHeading } from '../layout/page-heading';

const Page404 = () => {
  return (
    <StyledPageSection>
      <PageHeading title="Woah there friend." />
      <Typography text="Unfortunately, this page doesn't exist. If you're seeing this please let someone from the software team know." />
    </StyledPageSection>
  );
};

export default Page404;
