import {
  CircularProgress,
  Grid,
  Icon,
  Tab,
  Tabs,
  TabsList,
  Typography,
} from 'gantri-components';
import { memo } from 'react';
import { ReportSummaryProps } from './report-summary.types';
import { reportSummaryDefaultProps } from './report-summary.presets';
import {
  StyledSpinnerWrapper,
  StyledReportWrapper,
  StyledTabsWrapper,
} from './report-summary.styles';
import { useGetReportData } from './hooks/use-get-report-data';
import { ReportChartColumn } from './components/report-chart-column';
import { ReportTableColumn } from './components/report-table-column';

const ReportSummaryBase = (props: ReportSummaryProps) => {
  const {
    activeChart,
    activeChartDetails,
    activeTab,
    axisBottom,
    axisLeft,
    chartData,
    fullReportHref,
    leftMargin,
    minHeight,
    setActiveChart,
    setActiveTab,
    setReport,
    tableColumns,
    tableData,
    tableMinWidth,
    tabs,
    title,
    transformTooltipValue,
    useGetDashboardReportSummary,
  } = props;

  const { isFetching, isInView, observerRef } = useGetReportData({
    activeTab,
    setReport,
    useGetDashboardReportSummary,
  });

  return (
    <StyledReportWrapper ref={observerRef} minHeight={minHeight}>
      <Grid columns="1fr min-content" gap="3.2rem" paddingBottom="1.2rem">
        <Typography text={title} variant="h4" />
        <a href={fullReportHref}>
          <Typography
            color="link"
            icon={<Icon color="link" name="arrows:arrow_right" />}
            iconPosition="right"
            text="See more"
            whiteSpace="nowrap"
          />
        </a>
      </Grid>
      {!!tabs?.length && (
        <StyledTabsWrapper>
          <Tabs value={activeTab}>
            <TabsList
              onValueChange={(value: typeof tabs[number]['label']) => {
                return setActiveTab(value);
              }}
            >
              {tabs.map(({ label, value }, index) => {
                return (
                  <Tab key={`${value}-${index}`} label={label} value={value} />
                );
              })}
            </TabsList>
          </Tabs>
        </StyledTabsWrapper>
      )}
      {isFetching || !isInView ? (
        <StyledSpinnerWrapper>
          <CircularProgress size="10rem" />
        </StyledSpinnerWrapper>
      ) : (
        <Grid columns={{ lg: 2, md: 1 }}>
          {chartData && (
            <ReportChartColumn
              activeChart={activeChart}
              activeChartDetails={activeChartDetails}
              axisBottom={axisBottom}
              axisLeft={axisLeft}
              chartData={chartData}
              leftMargin={leftMargin}
              setActiveChart={setActiveChart}
              transformTooltipValue={transformTooltipValue}
            />
          )}
          {tableData && (
            <ReportTableColumn
              activeChart={activeChart}
              minWidth={tableMinWidth}
              tableColumns={tableColumns}
              tableData={tableData}
            />
          )}
        </Grid>
      )}
    </StyledReportWrapper>
  );
};

ReportSummaryBase.defaultProps = reportSummaryDefaultProps;

export const ReportSummary = memo(ReportSummaryBase);
