import { FC } from 'react';
import { FilterProps } from '..';
import { giftCardsPageAtoms } from './gift-cards-filter.atoms';
import { giftCardStatusOptions } from './gift-cards-filter.constants';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

const GiftCardsFilter: FC<FilterProps> = () => {
  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={giftCardsPageAtoms.filters.statuses}
          default={giftCardsPageAtoms.defaults.filters.statuses}
          hideReset
          text="Status"
        />
        <AtomsCheckboxList
          atom={giftCardsPageAtoms.filters.statuses}
          items={[...giftCardStatusOptions]}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default GiftCardsFilter;
