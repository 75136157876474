import { CellContext } from '@tanstack/react-table';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { CurrencyCell } from '../../../dashboard/components/common/currency-cell';
import { NumberCell } from '../../../dashboard/components/common/number-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { convertToDollars } from '../common/report-page/components/report-table/helpers/convert-to-dollars';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import { ReportSalesTableData } from './report-sales.types';

export const defaultSalesReportGroupBy = 'productId';

export const salesBaseTableColumns: DownloadableTableColumn<ReportSalesTableData>[] =
  [
    {
      accessorKey: 'orders',
      cell: ({
        getValue,
      }: CellContext<ReportSalesTableData, ReportSalesTableData['orders']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Orders',
    },
    {
      accessorKey: 'sales',
      cell: ({
        getValue,
      }: CellContext<ReportSalesTableData, ReportSalesTableData['sales']>) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Sales $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'items',
      cell: ({
        getValue,
      }: CellContext<ReportSalesTableData, ReportSalesTableData['items']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Items',
    },
    {
      accessorKey: 'refunds',
      cell: ({
        getValue,
      }: CellContext<
        ReportSalesTableData,
        ReportSalesTableData['refunds']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Refunds',
    },
    {
      accessorKey: 'refund-sales',
      cell: ({
        getValue,
      }: CellContext<
        ReportSalesTableData,
        ReportSalesTableData['refund-sales']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Refund $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'refund-items',
      cell: ({
        getValue,
      }: CellContext<
        ReportSalesTableData,
        ReportSalesTableData['refund-items']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Refunded Items',
    },
  ];

export const salesBaseSortingOptions = getSortingOptionsFromColumns(
  salesBaseTableColumns,
);

export const pageName: AtomPageName = 'report-sales';

export const reportSalesPageAtoms = {
  sortBy: getSortByFilterAtomFamily<SortBy<keyof ReportSalesTableData>>({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
