import axios from 'axios';
import { environment } from '../../../../environment';
import { SendMessageResponse } from './send-message.types';

export const sendMessage = (
  designId: number,
  data:
    | { content: string }
    | {
        file: {
          fileExtension: string;
          fileName: string;
          fileSize: number;
          fileUrl: string;
        };
      },
) => {
  return axios.post<SendMessageResponse>(
    `${environment.API_URL}/designs/${designId}/message`,
    data,
  );
};
