import { FC } from 'react';
import { Box, Icon, Tooltip, Typography } from 'gantri-components';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { formatDate } from '../../../../../../helpers/formatter';
import { UserTooltipProps } from './user-tooltip.types';
import routePaths from '../../../../../../config/route-paths';
import { StyledAnchor } from '../../../../styled-anchor';

export const UserTooltip: FC<UserTooltipProps> = ({ createdAt, userInfo }) => {
  return (
    <Tooltip
      Component={
        <Box horizontalPadding="2x" verticalPadding="x">
          <Typography
            display="inline"
            text={`${formatDate(createdAt, LONG_FORMAT)} by`}
          />
          &nbsp;
          <StyledAnchor
            text={userInfo.name}
            to={`${routePaths.users}/${userInfo.id}`}
          />
        </Box>
      }
      overlayContainerStyles={{
        position: 'relative',
        zIndex: 2,
      }}
      position="top"
    >
      <Icon color="t2" name="alert:i_circle" />
    </Tooltip>
  );
};
