import { convertStringArrayToValueLabelArray } from '../../../helpers/formatter';
import { UserRole } from '../../../constants/users';

const userTypes = ['Admin', 'Worker', 'User', 'Lead'];

export const getTypesAvailable = (currentTye: UserRole) => {
  return convertStringArrayToValueLabelArray(
    userTypes.filter((type) => {
      return type !== currentTye;
    }),
  );
};
