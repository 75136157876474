import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  DeleteLocationArgs,
  DeleteLocationResponse,
} from './delete-location.types';

export const deleteLocation = async ({ locationId }: DeleteLocationArgs) => {
  const res = await axios.delete<DeleteLocationResponse>(
    `${coreApiUrl}/locations/${locationId}`,
  );

  return res.data;
};
