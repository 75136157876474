import { Conditional } from 'gantri-components';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { RecoilState } from 'recoil';
import { useResetRecoilAtoms } from '../use-reset-recoil-atoms/use-reset-recoil-atoms';

export const useResetRecoilAtomsOnMount = (
  atoms: RecoilState<any>[] | Record<string, RecoilState<any>>,
) => {
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const resetAtoms = useResetRecoilAtoms(atoms);

  const ResetAtomsWrapper = useCallback(
    ({ children }: PropsWithChildren) => {
      return <Conditional condition={showChildren}>{children}</Conditional>;
    },
    [showChildren],
  );

  useEffect(() => {
    resetAtoms();
    setShowChildren(true);
  }, []);

  return {
    /** Wrap the content controlled by recoil atoms with this component. Once the atoms have been reset, the children will be rendered. */
    ResetAtomsWrapper,
    resetAtoms,
  };
};
