import { Typography } from 'gantri-components';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { stockStatuses } from '../../../../constants/options';
import { StockNumberDataCellProps } from './stock-number.types';

export const StockNumberDataCell = (props: StockNumberDataCellProps) => {
  const { id, status } = props;

  return (
    <>
      <StyledAnchor href={`${routePaths.stocks}/${id}`} text={`#${id}`} />
      <Typography
        color={status === stockStatuses.completed ? 'success' : 't2'}
        text={status}
      />
    </>
  );
};
