import {
  Icon,
  defaultImageExtensions,
  FileUploader,
  Cell,
} from 'gantri-components';
import { useFirebaseFileUploader } from '../../../../../../../../../hooks/use-firebase-file-uploader/use-firebase-file-uploader';
import { InstructionUploaderProps } from './instruction-file-uploader.types';

export const InstructionUploader = (props: InstructionUploaderProps) => {
  const {
    file,
    handleUploadsComplete,
    hasError,
    onFileDelete,
    uploadPath,
    variant = 'plain',
  } = props;

  const { fileName, url } = file || {};

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: url,
    handleUploadsComplete,
    onFileDelete,
    path: uploadPath,
  });

  return (
    <Cell justifyItems="start">
      <FileUploader
        {...fileUploaderProps}
        buttonProps={{
          icon: (
            <Icon
              color={hasError ? 'white' : 'link'}
              name="arrows:arrow_upload"
            />
          ),
          minWidth: '8rem',
          text: 'Upload Instruction',
          variant: hasError ? 'primaryAlert' : 'ghost',
        }}
        expectedExtensions={[...defaultImageExtensions, 'pdf']}
        fileName={fileName}
        variant={variant}
      />
    </Cell>
  );
};
