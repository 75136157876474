import { Button, useModal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { batchAtoms } from '../../batch.atoms';
import { UpdateBatchLocationModal } from './components/update-batch-location-modal';
import { UpdateBatchLocationsProps } from './update-batch-locations.types';

export const UpdateBatchLocations = (props: UpdateBatchLocationsProps) => {
  const { batch, setBatch } = props;

  const isReorderActive = useRecoilValue(batchAtoms.isReorderActive);

  const [showModal, hideModal] = useModal(() => {
    return (
      <UpdateBatchLocationModal
        batch={batch}
        setBatch={setBatch}
        onClose={hideModal}
      />
    );
  }, [batch]);

  return (
    <Button
      disabled={!batch?.parts?.length || isReorderActive}
      text="Update locations"
      variant="secondary"
      onClick={showModal}
    />
  );
};
