import { useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import { convertValueFromString } from '../helpers/checks';
import { useRouter } from './use-router';

export const useSyncPropertyWithQueryString = <
  Type = string | number | boolean,
>(
  property: string,
  initialValue: Type,
  /** Query names to preserve in URL when value of `property` is updated. If `true`, all properties are preserved. Defaults to true. */
  preserve?: boolean | string[],
) => {
  const { getSearchValueByProperty, location, onReplaceSearch } = useRouter();

  const currentSearchValue = getSearchValueByProperty(property);

  const [currentValue, setCurrentValue] = useState<Type>(
    currentSearchValue
      ? convertValueFromString<Type>(currentSearchValue)
      : initialValue,
  );

  const prevCurrentValue = usePrevious(currentValue);

  useEffect(() => {
    if (currentValue !== prevCurrentValue) {
      onReplaceSearch({
        preserve,
        property,
        value: String(currentValue ?? ''),
      });
    }
  }, [currentValue]);

  useEffect(() => {
    if (!currentSearchValue && initialValue) {
      setCurrentValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!location.search) {
      return;
    }

    const updatedValue = convertValueFromString<Type>(currentSearchValue);

    if (updatedValue !== prevCurrentValue) {
      setCurrentValue(updatedValue);
    }
  }, [location.search]);

  return {
    currentValue,
    updateQueryString: setCurrentValue,
  };
};
