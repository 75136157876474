import { useState } from 'react';
import { MoreMenuProps } from '../../components/dropdowns/more-menu/more-menu.types';
import { MoreMenuToggle } from './components/more-menu-toggle';

export const useMoreMenu = <Data extends unknown>(
  props: MoreMenuProps<Data>,
) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = (value?: boolean) => {
    setIsMenuOpen(value || !isMenuOpen);
  };

  return {
    MoreMenu: () => {
      return (
        <MoreMenuToggle
          {...props}
          isMenuOpen={isMenuOpen}
          toggleMenu={toggleMenu}
        />
      );
    },
    isMenuOpen,
    setIsMenuOpen,
    toggleMenu,
  };
};
