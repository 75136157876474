import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateHandoutArgs,
  CreateHandoutResponse,
} from './create-handout.types';

export const createHandout = ({
  designerId,
  htmlHandout,
}: CreateHandoutArgs) => {
  return axios.put<CreateHandoutResponse>(
    `${coreApiUrl}/designers/${designerId}/handout`,
    { htmlHandout },
  );
};
