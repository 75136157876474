import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetLocationsType, GetLocationsResponse } from './get-locations.types';

/** Returns all available locations of the specified type */
const query = (type?: GetLocationsType) => {
  return axios
    .get<GetLocationsResponse>(`${coreApiUrl}/locations/${type}`)
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'get-locations';
};

const key = (query?: string) => {
  return !!query
    ? [{ groupKey: groupKey(), key: `/locations/get-locations/${query}` }]
    : [
        {
          groupKey: groupKey(),
          key: '/locations/get-locations',
        },
      ];
};

export const getLocations = {
  groupKey,
  key,
  query,
};
