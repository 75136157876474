import { atom } from 'recoil';
import { NewMachineIssuesModalStep } from './new-machine-issues-modal.types';
import { newMachineIssuesModalSteps } from './new-machine-issues-modal.constants';
import { BulkUpdateMachineRecord } from '../../../../api/machines/routes/bulk-update/bulk-update.types';
import { MachineIssueChecklistItem } from '../../../../constants/options';
import { BulkUpdateMachineDetailsRecord } from '../bulk-update-machines-modal-details-panel/bulk-update-machines-modal-details-panel.types';

const atomKeyPrefix = 'NEW_MACHINE_ISSUES_MODAL';

export const newMachineIssuesModalAtoms = {
  detailsPanelMachines: atom<BulkUpdateMachineDetailsRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-details-panel-machines`,
  }),
  machinesOfflined: atom<BulkUpdateMachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-offlined`,
  }),
  selectedIssues: atom<MachineIssueChecklistItem[]>({
    default: [],
    key: `${atomKeyPrefix}-selected-issues`,
  }),
  step: atom<NewMachineIssuesModalStep>({
    default: newMachineIssuesModalSteps.issuesChecklist,
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
