import { Link } from 'react-router-dom';
import {
  Box,
  Conditional,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { useSetRecoilState } from 'recoil';
import {
  formatAsCurrency,
  formatAsLocaleNumber,
  formatDate,
} from '../../../../../../helpers/formatter';
import { StyledInlineTypography } from '../../../../../../components/layout/page-layout-styles';
import routePaths from '../../../../../../config/route-paths';
import { InventoryTransaction } from '../../../../../../api/inventories/routes/get-inventory/get-inventory.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { MoreMenuCell } from '../../components/more-menu-cell';
import { jobsDetailsPanelAtoms } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel/jobs-details-panel.atoms';

export const useGetTransactionsColumns = ({
  handleOnDelete,
}: {
  handleOnDelete: (record: InventoryTransaction) => void;
}) => {
  const setJobIdInDetailsPanel = useSetRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );

  const columns: ColumnDef<InventoryTransaction>[] = [
    {
      accessorKey: 'transactionDateSec',
      cell: ({
        getValue,
      }: CellContext<
        InventoryTransaction,
        InventoryTransaction['transactionDateSec']
      >) => {
        const transactionDateSec = getValue();

        return (
          <Conditional
            condition={!!transactionDateSec}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(transactionDateSec)} />
          </Conditional>
        );
      },
      header: 'Date',
      size: 80,
    },
    {
      accessorKey: 'cost',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<InventoryTransaction, InventoryTransaction['cost']>) => {
        const cost = getValue();

        return (
          <Conditional condition={!!cost} Fallback={<Typography text="-" />}>
            <Typography
              text={formatAsCurrency(cost, {
                currency: original.currency || 'USD',
                isCents: true,
              })}
            />
          </Conditional>
        );
      },
      header: 'Cost',
      size: 100,
    },
    {
      accessorKey: 'completionDateSec',
      cell: ({
        getValue,
      }: CellContext<
        InventoryTransaction,
        InventoryTransaction['completionDateSec']
      >) => {
        const completionDateSec = getValue();

        return (
          <Conditional
            condition={!!completionDateSec}
            Fallback={<Typography text="-" />}
          >
            <Typography text={formatDate(completionDateSec)} />
          </Conditional>
        );
      },
      header: 'Completion',
      size: 140,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 100,
    },
    {
      accessorKey: 'inventoryPurchaseId',
      cell: ({
        getValue,
      }: CellContext<
        InventoryTransaction,
        InventoryTransaction['inventoryPurchaseId']
      >) => {
        const inventoryPurchaseId = getValue();

        return (
          <Conditional condition={!!inventoryPurchaseId}>
            <Link
              to={`${routePaths.inventoryPurchases}/${inventoryPurchaseId}`}
            >
              <StyledInlineTypography
                color="link"
                decoration="underline"
                text={`#${inventoryPurchaseId}`}
              />
            </Link>
          </Conditional>
        );
      },
      header: 'Purchase ID',
      size: 100,
    },
    {
      accessorKey: 'jobId',
      cell: ({
        getValue,
      }: CellContext<InventoryTransaction, InventoryTransaction['jobId']>) => {
        const jobId = getValue();

        return (
          <Conditional condition={!!jobId}>
            <StyledAnchor text={`#${jobId}`} />
          </Conditional>
        );
      },
      header: 'Job ID',
      meta: {
        getOnClick: ({
          getValue,
        }: Cell<InventoryTransaction, InventoryTransaction['jobId']>) => {
          const jobId = getValue();
          const isClickable = !!jobId;

          return isClickable
            ? () => {
                return setJobIdInDetailsPanel(jobId);
              }
            : undefined;
        },
      },
      size: 100,
    },
    {
      accessorKey: 'userInfo',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<
        InventoryTransaction,
        InventoryTransaction['userInfo']
      >) => {
        const userInfo = getValue();

        return (
          <Grid alignItems="center" columns="1fr max-content" gap="1rem">
            <Link to={`${routePaths.users}/${userInfo?.id}`}>
              <StyledAnchor text={userInfo?.fullName} />
            </Link>

            <Conditional
              condition={
                original.type === 'Job' &&
                original.transactionType === 'Automatic' &&
                !!original.jobId
              }
            >
              <Tooltip
                Component={
                  <Box maxWidth="18rem" padding="1rem">
                    <Typography
                      display="inline"
                      text="Automatically created from job"
                    />
                    &nbsp;
                    <StyledAnchor
                      display="inline"
                      text={`#${original.jobId} `}
                      onClick={() => {
                        return setJobIdInDetailsPanel(original.jobId);
                      }}
                    />
                  </Box>
                }
                overlayContainerStyles={{ position: 'relative', zIndex: 2 }}
              >
                <Icon name="arrows:arrow_rotate_two" top=".4rem" />
              </Tooltip>
            </Conditional>
          </Grid>
        );
      },
      header: 'Created by',
      meta: {
        getIsClickable: ({
          getValue,
        }: Cell<InventoryTransaction, InventoryTransaction['userInfo']>) => {
          return !!getValue()?.fullName;
        },
      },
      size: 140,
    },
    {
      accessorKey: 'machine',
      cell: ({
        getValue,
      }: CellContext<
        InventoryTransaction,
        InventoryTransaction['machineInfo']
      >) => {
        return <Typography text={getValue()?.name} />;
      },
      header: 'Machine',
      size: 140,
    },
    {
      accessorKey: 'notes',
      cell: ({
        row: { original },
      }: CellContext<InventoryTransaction, InventoryTransaction['notes']>) => {
        return (
          <Conditional
            condition={original.type === 'Discarded'}
            Fallback={original.notes}
          >
            <Typography
              text={`${original.discardReason ?? 'N/A'}: ${original.notes}`}
            />
          </Conditional>
        );
      },
      header: 'Notes',
      size: 220,
    },
    {
      accessorKey: 'stockChange',
      header: 'Change',
      size: 60,
    },
    {
      accessorKey: 'stock',
      cell: ({
        row: { original },
      }: CellContext<InventoryTransaction, InventoryTransaction['stock']>) => {
        return (
          <Typography
            text={formatAsLocaleNumber(Number(original.stock), {
              maximumFractionDigits: 3,
            })}
          />
        );
      },
      header: 'Stock',
      size: 60,
    },
    {
      cell: (props: CellContext<InventoryTransaction, never>) => {
        return <MoreMenuCell {...props} handleOnDelete={handleOnDelete} />;
      },
      id: 'menu',
      size: 30,
    },
  ];

  return columns;
};
