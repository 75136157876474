import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import {
  DesignsFilterStatus,
  DesignsFilterStep,
  DesignsSortingField,
} from './designs-filter.types';
import { ProductLightCategory } from '../../../api/products/products.types';

export const designsFiltersDefaults: {
  filters: {
    action: boolean;
    categories: ProductLightCategory[];
    statuses: DesignsFilterStatus[];
    steps: DesignsFilterStep[];
  };
  sortBy: SortBy<DesignsSortingField>;
} = {
  filters: {
    action: true,
    categories: [],
    statuses: [],
    steps: [],
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'DESIGNS_FILTERS';

export const pageName: AtomPageName = 'designs';

export const designsPageAtoms = {
  defaults: designsFiltersDefaults,
  filters: {
    action: getGenericAtomFamily<boolean>({
      defaultValue: designsFiltersDefaults.filters.action,
      key: `${atomKeyPrefix}-action`,
    })(pageName),
    categories: getGenericAtomFamily<ProductLightCategory[]>({
      defaultValue: designsFiltersDefaults.filters.categories,
      key: `${atomKeyPrefix}-categories`,
    })(pageName),
    statuses: getGenericAtomFamily<DesignsFilterStatus[]>({
      defaultValue: designsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
    steps: getGenericAtomFamily<DesignsFilterStep[]>({
      defaultValue: designsFiltersDefaults.filters.steps,
      key: `${atomKeyPrefix}-steps`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<DesignsSortingField>>({
    defaultValue: designsFiltersDefaults.sortBy,
  })(pageName),
};
