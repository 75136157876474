import styled, { css } from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledAddItemTypography = styled(Typography)<{
  $disabled?: boolean;
}>`
  ${({ $disabled }) => {
    return css`
      cursor: ${$disabled ? 'not-allowed' : 'pointer'};
      min-height: 24px;
      user-select: none;
      max-width: fit-content;
    `;
  }}
`;
