import { Form, Formik } from 'formik';
import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';
import { locationModalSchema } from './location-modal.schema';
import { LocationModalProps } from './location-modal.types';
import { locationModalAtoms } from './location-modal.atoms';
import { LocationModalFooter } from './components/location-modal-footer';
import { LocationModalContent } from './components/location-modal-content';
import { useGetOnSubmit } from './hooks/use-get-on-submit';

export const LocationModal = (props: LocationModalProps) => {
  const {
    handleFetchRequest,
    locationBuildings,
    locationWorkshops,
    onClose,
    records,
    setRecords,
  } = props;

  const isEditModal = useRecoilValue(locationModalAtoms.isEditModal);
  const location = useRecoilValue(locationModalAtoms.location);
  const resetAtoms = useResetRecoilAtoms(locationModalAtoms);

  const handleCloseModal = () => {
    onClose();
  };

  const { onSubmit, processing } = useGetOnSubmit({
    handleCloseModal,
    handleFetchRequest,
    records,
    setRecords,
  });

  useEffect(() => {
    return resetAtoms;
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={locationModalSchema.cast(location || {})}
      validateOnChange
      validateOnMount
      validationSchema={locationModalSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <Form>
            <Modal
              footer={
                <LocationModalFooter
                  handleCloseModal={handleCloseModal}
                  processing={processing}
                />
              }
              header={isEditModal ? 'Edit Location' : 'New Location'}
              height={{ lg: '66rem', md: '100%' }}
              maxWidth={{ lg: '44rem', md: '100%' }}
              onClose={handleCloseModal}
            >
              <LocationModalContent
                locationBuildings={locationBuildings}
                locationWorkshops={locationWorkshops}
              />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
