import { Conditional, Typography, TypographyProps } from 'gantri-components';
import { Link } from 'react-router-dom';
import { StyledAnchorProps } from './styled-anchor.types';

export const StyledAnchor = (props: StyledAnchorProps) => {
  const { children, href, rel, state, target, text, to, ...rest } = props;

  const linkTo = href || to;

  const typographyProps = {
    color: 'link',
    decoration: 'underline',
    text,
    ...rest,
  } satisfies TypographyProps;

  return (
    <Conditional
      condition={!!linkTo}
      Fallback={children || <Typography {...typographyProps} />}
    >
      <Link rel={rel} state={state} target={target} to={linkTo}>
        {children || <Typography {...typographyProps} />}
      </Link>
    </Conditional>
  );
};
