import { Conditional, Icon, Tooltip, Typography } from 'gantri-components';
import { StyledAttentionsList } from './attention-grid-cell.styles';

export const AttentionGridCell = ({ attentions }) => {
  return (
    <Conditional condition={!!attentions?.length}>
      <Tooltip
        Component={
          <StyledAttentionsList>
            {attentions.map((attention, index) => {
              return (
                <Typography
                  key={index}
                  text={attention.description || attention.name}
                />
              );
            })}
          </StyledAttentionsList>
        }
        position="right"
      >
        <Typography
          color="alert"
          icon={<Icon color="alert" name="view:eye_open" />}
          text={attentions.length}
        />
      </Tooltip>
    </Conditional>
  );
};
