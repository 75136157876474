import styled, { css, CSSProperties } from 'styled-components';

export const StyledSpinnerWrapper = styled.div<{
  minHeight: CSSProperties['minHeight'];
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  ${({ minHeight }) => {
    return css`
      min-height: ${minHeight};
    `;
  }}
`;
