import { useMemo } from 'react';
import range from 'lodash/range';
import { Icon, Typography } from 'gantri-components';
import { PagingProps } from './paging.props';
import { PagingPresets } from './paging.presets';
import {
  StyledPagingButton,
  StyledPagingContainer,
  StyledPagingContentRight,
} from './paging.styles';
import SelectInput from '../inputs/select-input';
import { formatAsLocaleNumber } from '../../../helpers/formatter';

export const Paging = (props: PagingProps) => {
  const { currentPage, onPageChange, pageCount, total } = {
    ...PagingPresets,
    ...props,
  };

  const pages = useMemo(() => {
    return range(1, pageCount + 1).map((page) => {
      return formatAsLocaleNumber(page);
    });
  }, [pageCount]);

  const changePage = (page: string | number): void => {
    if (onPageChange && currentPage !== page) {
      onPageChange(+page);
    }
  };

  return (
    <StyledPagingContainer data-table-pagination>
      <Typography color="t2" text={`${formatAsLocaleNumber(total)} results`} />

      <StyledPagingContentRight>
        <StyledPagingButton
          disabled={currentPage === 1}
          onClick={() => {
            return changePage(currentPage - 1);
          }}
        >
          <Icon color="t2" height="1.6rem" name="arrows:arrow_chevron_left" />
        </StyledPagingButton>

        <SelectInput
          options={pages}
          placeholder=""
          value={currentPage}
          onChange={changePage}
        />

        <Typography color="t2" text={`of ${formatAsLocaleNumber(pageCount)}`} />

        <StyledPagingButton
          disabled={currentPage >= pageCount}
          onClick={() => {
            return changePage(currentPage + 1);
          }}
        >
          <Icon color="t2" height="1.6rem" name="arrows:arrow_chevron_right" />
        </StyledPagingButton>
      </StyledPagingContentRight>
    </StyledPagingContainer>
  );
};
