import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsApi, partsApi } from '../../../../../../../../../../../api';
import { maxFinishAttemptsBeforeRestart } from '../../../../../../../../../../../constants/jobs';
import {
  jobStatuses,
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { getFailJobReasonArg } from '../../../../../../../helpers/get-fail-job-reason-arg';
import { finishingQcModalAtoms } from '../../../../../complete-finishing-qa-modal.atoms';
import { getRecommendedStep } from './helpers/get-recommended-step';
import { UsePassOrFailJobProps } from './use-pass-or-fail-job.types';

export const usePassOrFailJob = (props: UsePassOrFailJobProps) => {
  const { handleCloseModal, job } = props;

  const setStep = useSetRecoilState(finishingQcModalAtoms.step);
  const checklist = useRecoilValue(finishingQcModalAtoms.checklist);
  const setNextJob = useSetRecoilState(finishingQcModalAtoms.nextJob);
  const setSystemRecommendedStep = useSetRecoilState(
    finishingQcModalAtoms.systemRecommendedStep,
  );
  const setIsAtMaxFinishAttempts = useSetRecoilState(
    finishingQcModalAtoms.isAtMaxFinishAttempts,
  );
  const setNumFinishingAttempts = useSetRecoilState(
    finishingQcModalAtoms.numFinishingAttempts,
  );
  const setFailedNextStep = useSetRecoilState(
    finishingQcModalAtoms.failedNextStep,
  );

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const onFail = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await partsApi.getPart(job.part.id);

        const numFinishAttempts = data.part[0].jobs.filter(
          ({ status, step, type }) => {
            return (
              step === jobSteps.finish &&
              type === jobTypeOptions.qc &&
              status !== jobStatuses.cancelled
            );
          },
        ).length;

        const isAtMaxFinishAttempts =
          numFinishAttempts >= maxFinishAttemptsBeforeRestart;

        setNumFinishingAttempts(numFinishAttempts);
        setIsAtMaxFinishAttempts(isAtMaxFinishAttempts);

        const recommendedStep = getRecommendedStep({
          checklist,
          isAtMaxFinishAttempts,
        });

        setFailedNextStep(recommendedStep);
        setSystemRecommendedStep(recommendedStep);

        setStep('JOB_FAILED_NEXT_STEP');
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch part details.',
        });
      }
    });
  };

  const onPass = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const reason = getFailJobReasonArg(checklist, {
          material: job.part.material,
        });

        const data = await jobsApi.completeJob({
          jobId: job.id,
          reason,
        });

        notify(data.notice);

        if (!!data.nextJob) {
          setNextJob(data.nextJob);
        }

        await handleCloseModal({ updateOnClose: true });
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to complete job.' });
      }
    });
  };

  return { onFail, onPass, processing };
};
