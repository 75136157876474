import { Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;
