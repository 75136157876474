import { Cell, TextArea, Typography } from 'gantri-components';
import { useState } from 'react';
import { SectionHeading } from '../section-heading';
import { AttachmentsSectionProps } from './notes-section.types';

export const NotesSection = (props: AttachmentsSectionProps) => {
  const {
    editedInventoryPurchase,
    handleSave,
    isEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <>
      <SectionHeading
        handleSave={handleSave}
        isEditing={isEditing}
        isEditingDisabled={isEditingDisabled}
        setIsEditing={setIsEditing}
        text="Notes"
        toggleEditMode={toggleEditMode}
      />
      {isEditing ? (
        <Cell maxWidth="50rem">
          <TextArea
            minRows={6}
            placeholder="Inventory Purchase Notes"
            value={editedInventoryPurchase.notes || ''}
            onChange={(event) => {
              updateEditedInventoryPurchaseWith({
                notes: event.target.value,
              });
            }}
          />
        </Cell>
      ) : (
        <Typography text={editedInventoryPurchase.notes} />
      )}
    </>
  );
};
