import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledSectionItem = styled(Grid)`
  ${({ theme }) => {
    return css`
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.colors.dividers.t1};
      }

      &:first-child {
        padding-top: 0;
      }
    `;
  }}
`;
