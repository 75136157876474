import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GetAllProductsResponse } from './get-all.types';

const getAllProductsQuery = () => {
  return axios
    .get<GetAllProductsResponse>(`${getCoreApiUrl('products')}/list`)
    .then(({ data }) => {
      return data;
    });
};

export const getAllProductsQueriesObj = getGenericQueryObj({
  groupName: 'get-all-products',
  query: getAllProductsQuery,
});
