import { orderActionModalTypes } from '../../../../../../constants/options';
import { EditColor, CancelOrRefundItems } from './components';
import { OrderActionModalType } from './order-actions.types';

export const OrderActionModal = (props: OrderActionModalType) => {
  const { type, ...rest } = props;

  if (type === orderActionModalTypes.cancel) {
    return <CancelOrRefundItems {...rest} />;
  }

  return <EditColor {...rest} />;
};
