import { Conditional, Typography } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { formatAsCurrency, formatDate } from '../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../constants/dates';
import routePaths from '../../config/route-paths';
import { StatusBadge } from '../inventory-purchase/components/status-badge';
import { StyledAnchor } from '../../components/common/styled-anchor';
import { PaginatedInventoryPurchase } from '../../api/inventory-purchases/inventory-purchases.types';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';

export const columns: ColumnDef<PaginatedInventoryPurchase>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['id']
    >) => {
      const id = getValue();

      return (
        <StyledAnchor
          href={`${routePaths.inventoryPurchases}/${id}`}
          target="_blank"
          text={`#${id}`}
        />
      );
    },
    header: 'Purchase #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'createdAt',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['createdAt']
    >) => {
      return formatDate(getValue(), MEDIUM_FORMAT);
    },
    header: 'Created',
    size: 60,
  },
  {
    accessorKey: 'completedAt',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['completedAt']
    >) => {
      return formatDate(getValue(), MEDIUM_FORMAT);
    },
    header: 'Completed',
    size: 60,
  },
  {
    accessorKey: 'invoice',
    header: 'Invoice # (PO)',
  },
  {
    accessorKey: 'userInfo',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['userInfo']
    >) => {
      const userInfo = getValue();

      return (
        <Conditional condition={!!userInfo?.id}>
          <StyledAnchor
            href={`${routePaths.users}/${userInfo?.id}`}
            target="_blank"
            text={userInfo?.fullName}
          />
        </Conditional>
      );
    },
    header: 'Created by',
    meta: {
      getIsClickable: ({
        getValue,
      }: Cell<
        PaginatedInventoryPurchase,
        PaginatedInventoryPurchase['userInfo']
      >) => {
        return !!getValue()?.id;
      },
    },
  },
  {
    accessorKey: 'inventory',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['inventory']
    >) => {
      const inventory = getValue();

      return (
        <Conditional condition={!!inventory}>
          <StyledAnchor
            href={`${routePaths.inventories}/${inventory.id}`}
            target="_blank"
            text={inventory.name}
          />
        </Conditional>
      );
    },
    header: 'Inventory',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    minSize: 200,
  },
  {
    accessorKey: 'vendor',
    header: 'Vendor',
    size: 250,
  },
  {
    accessorKey: 'units',
    cell: ({
      getValue,
      row,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['units']
    >) => {
      return (
        <Conditional condition={!!row.original?.inventory}>
          <Typography
            color="t1"
            text={`${getValue()} ${row.original?.inventory.unit}`}
          />
        </Conditional>
      );
    },
    header: 'Units',
    size: 100,
  },
  {
    accessorKey: 'totalCost',
    cell: ({
      getValue,
      row,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['totalCost']
    >) => {
      return (
        <Typography
          text={formatAsCurrency(getValue(), {
            currency: row.original.currency,
            isCents: true,
          })}
        />
      );
    },
    header: 'Total',
    size: 100,
  },
  {
    accessorKey: 'status',
    cell: ({
      getValue,
    }: CellContext<
      PaginatedInventoryPurchase,
      PaginatedInventoryPurchase['status']
    >) => {
      return <StatusBadge status={getValue()} />;
    },
    header: 'Status',
    size: 100,
  },
];
