import { memo, useEffect, useState } from 'react';
import { DashboardReportSummary } from '../../../../api/dashboard/dashboard.types';
import { useFetchDashboardOrdersSummary } from '../../../../api/dashboard/routes/get-dashboard-orders-summary/get-dashboard-orders-summary.query';
import routePaths from '../../../../config/route-paths';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { DashboardChartProps } from '../common/dashboard-chart/dashboard-chart.types';
import { getChartLeftMargin } from '../common/dashboard-chart/helpers';
import { ReportSummary } from '../common/reports-summary';
import { ordersTableColumns } from './dashboard-orders-summary.constants';

const DashboardOrdersSummaryBase = () => {
  const [report, setReport] = useState<DashboardReportSummary>();
  const [activeChart, setActiveChart] = useState<string>();

  const activeChartDetails = report?.chartData?.[activeChart];
  const activeChartIsCurrency = ['sales'].includes(activeChart);
  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] =
    activeChartIsCurrency
      ? (value) => {
          return formatAsCurrency(+value, {
            isCents: true,
          });
        }
      : undefined;
  const axisLeft = activeChartIsCurrency
    ? {
        format: (y: number) => {
          return formatAsCurrency(y, {
            isCents: true,
            maximumFractionDigits: 0,
          });
        },
      }
    : undefined;
  const leftMargin = getChartLeftMargin({
    activeChartDetails,
    isCents: activeChartIsCurrency,
  });

  useEffect(() => {
    if (!activeChart) {
      const chartDataKeys = Object.keys(report?.chartData || {});
      const firstCartDataKey = chartDataKeys[0];

      setActiveChart(firstCartDataKey);
    }
  }, [report?.chartData]);

  return (
    <ReportSummary
      activeChart={activeChart}
      activeChartDetails={activeChartDetails}
      axisLeft={axisLeft}
      chartData={report?.chartData}
      fullReportHref={`${routePaths.dashboard}/sales`}
      leftMargin={leftMargin}
      minHeight="24rem"
      setActiveChart={setActiveChart}
      setReport={setReport}
      tableColumns={ordersTableColumns}
      tableData={report?.tableData}
      title="Orders"
      transformTooltipValue={transformTooltipValue}
      useGetDashboardReportSummary={useFetchDashboardOrdersSummary}
    />
  );
};

export const DashboardOrdersSummary = memo(DashboardOrdersSummaryBase);
