import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { getYieldCellDetails } from '../../helpers/get-yield-cell-details';
import { FailReasonsCell } from '../common/fail-reasons-cell';
import { NumberCell } from '../common/number-cell';
import { FinishingJobsTableData } from './dashboard-finishing-jobs-summary.types';

export const finishingJobsTableColumns: ColumnDef<FinishingJobsTableData>[] = [
  {
    accessorKey: 'type',
    cell: (
      props: CellContext<
        FinishingJobsTableData,
        FinishingJobsTableData['type']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <Typography text={getValue()} />;
    },
    header: 'Jobs',
    size: 80,
  },
  {
    accessorKey: 'sand',
    cell: (
      props: CellContext<
        FinishingJobsTableData,
        FinishingJobsTableData['sand']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Sand',
    size: 80,
  },
  {
    accessorKey: 'paint',
    cell: (
      props: CellContext<
        FinishingJobsTableData,
        FinishingJobsTableData['paint']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Paint',
    size: 80,
  },
  {
    accessorKey: 'qa',
    cell: (
      props: CellContext<FinishingJobsTableData, FinishingJobsTableData['qa']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue, row } = props;
      const { 'qa-fail-reasons': qAFailReasons } = row.original;

      return <FailReasonsCell failReasons={qAFailReasons} value={getValue()} />;
    },
    header: 'QA',
    size: 80,
  },
  {
    accessorKey: 'sand-worker',
    cell: (
      props: CellContext<
        FinishingJobsTableData,
        FinishingJobsTableData['sand-worker']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Sand / Worker',
    size: 80,
  },
  {
    accessorKey: 'paint-worker',
    cell: (
      props: CellContext<
        FinishingJobsTableData,
        FinishingJobsTableData['paint-worker']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Paint / Worker',
    size: 80,
  },
];
