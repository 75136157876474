import { Button } from 'gantri-components';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { batchApi } from '../../../../../../api';
import routePaths from '../../../../../../config/route-paths';
import { useNotification } from '../../../../../../hooks/useNotification';
import { createBatchModalAtoms } from '../../create-batch-modal.atoms';
import { CreateBatchModalFooterProps } from './create-batch-modal-footer.types';
import { useRouter } from '../../../../../../hooks';

export const CreateBatchModalFooter = (props: CreateBatchModalFooterProps) => {
  const { onClose } = props;

  const [step, setStep] = useRecoilState(createBatchModalAtoms.step);
  const [batchId, setBatchId] = useRecoilState(createBatchModalAtoms.batchId);

  const [processing, setProcessing] = useState<boolean>(false);

  const { notifyAxiosError } = useNotification();

  const { navigate } = useRouter();

  switch (step) {
    case 'CREATE_BATCH':
      return (
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={processing}
            size="large"
            text="Confirm"
            onClick={async () => {
              try {
                setProcessing(true);

                const { data } = await batchApi.createBatch();

                setBatchId(data.batchId);
                setStep('BATCH_CREATED');
              } catch (error: unknown) {
                notifyAxiosError({
                  error,
                  fallbackMessage: 'Unable to create batch.',
                });
                setStep('ERROR_CREATING_BATCH');
              } finally {
                setProcessing(false);
              }
            }}
          />
        </>
      );

    case 'BATCH_CREATED':
      return (
        <Button
          size="large"
          text="Done"
          onClick={() => {
            onClose();
            navigate(`${routePaths.batches}/${batchId}`);
          }}
        />
      );

    case 'ERROR_CREATING_BATCH':
      return <Button size="large" text="Done" onClick={onClose} />;

    default:
      return null;
  }
};
