import { useRecoilValue } from 'recoil';
import { startNonProductJobModalAtoms } from '../../start-non-product-job-modal.atoms';
import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { UpdateInventoryFooter } from '../step-update-inventory/update-inventory-footer';
import { StartNonProductJobFooterProps } from './start-non-product-job-footer.types';

export const StartNonProductJobFooter = (
  props: StartNonProductJobFooterProps,
) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(startNonProductJobModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return (
        <ConfirmBeginFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'UPDATE_INVENTORY':
      return (
        <UpdateInventoryFooter handleCloseModal={handleCloseModal} job={job} />
      );

    default:
      return null;
  }
};
