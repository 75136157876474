import styled, { css } from 'styled-components';

export const StyledDivider = styled.hr`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.dividers.t1};
      height: 1px;
      border: none;
      margin-bottom: 0.8rem;
    `;
  }}
`;
