import { Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { productAtoms } from '../../../../product.atoms';
import { ProductPhotosList } from '../product-photos-list';
import { videoExtensions } from '../../../../../../helpers/images/cloudinary/generate-transformations-string/generate-transformations-string.types';
import { Divider } from '../../../../../../components/divider';
import { LifestylePhotosSection } from './components/lifestyle-photos-section';

export const ProductLevelAssets = () => {
  const selectedSku = useRecoilValue(productAtoms.selectedSku);
  const product = useRecoilValue(productAtoms.product);

  const videos = product?.assets?.product?.videos || [];

  return (
    <Stack gap="3x">
      <Divider />

      <Stack gap="7x">
        <LifestylePhotosSection />

        <ProductPhotosList
          expectedExtensions={[...videoExtensions]}
          fileType="videos"
          max={1}
          photos={videos}
          selectedSku={selectedSku}
          title="Video"
        />
      </Stack>
    </Stack>
  );
};
