import { atom } from 'recoil';
import {
  InventoryRequest,
  Job,
  Part,
} from '../../../../../../api/jobs/jobs.types';
import { InitialEvaluation } from '../../common/evaluate-part-content/evaluate-part-content.types';
import { completeAssemblyChecklistDefaults } from './complete-assembly-modal.constants';
import {
  CompleteAssemblyModalStep,
  CompleteAssemblyModalPartChecklist,
  CompleteAssemblyModalJobChecklist,
} from './complete-assembly-modal.types';
import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { ExceededCycleTimeReason } from './components/step-exceeded-cycle-time/exceeded-cycle-time-content/exceeded-cycle-time-content.types';
import { NextJob } from '../../../../../../api/jobs/routes/complete-job/complete-job.types';
import { NewPart } from '../../../../../../api/jobs/routes/fail-job/fail-job.types';

const atomKeyPrefix = 'ASSEMBLE_ASSEMBLE_MODAL';

export const completeAssemblyModalAtoms = {
  duplicatedJobs: atom<Job[]>({
    default: [],
    key: `${atomKeyPrefix}-duplicated-jobs`,
  }),
  exceededCycleTime: atom<number>({
    default: 0,
    key: `${atomKeyPrefix}-exceeded-cycle-time`,
  }),
  exceededCycleTimeReason: atom<ExceededCycleTimeReason>({
    default: null,
    key: `${atomKeyPrefix}-exceeded-cycle-time-reason`,
  }),
  initialEvaluation: atom<InitialEvaluation>({
    default: null,
    key: `${atomKeyPrefix}-initial-evaluation`,
  }),
  inventoryRequests: atom<InventoryRequest[]>({
    default: [],
    key: `${atomKeyPrefix}-inventory-requests`,
  }),
  isJobChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-job-checklist-reviewed`,
  }),
  isPartChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-part-checklist-reviewed`,
  }),
  job: atom<JobDetails>({
    default: null,
    key: `${atomKeyPrefix}-job`,
  }),
  jobChecklist: atom<CompleteAssemblyModalJobChecklist>({
    default: completeAssemblyChecklistDefaults,
    key: `${atomKeyPrefix}-job-checklist`,
  }),
  newParts: atom<NewPart[]>({
    default: [],
    key: `${atomKeyPrefix}-new-parts`,
  }),
  nextJob: atom<NextJob>({
    default: null,
    key: `${atomKeyPrefix}-next-job`,
  }),
  oldParts: atom<Part[]>({
    default: [],
    key: `${atomKeyPrefix}-old-parts`,
  }),
  partChecklist: atom<CompleteAssemblyModalPartChecklist>({
    default: {},
    key: `${atomKeyPrefix}-part-checklist`,
  }),
  step: atom<CompleteAssemblyModalStep>({
    default: 'CONFIRM_BEGIN',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
