import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { RejectJobArgs, RejectJobResponse } from './reject-job.types';

export const rejectJob = ({ jobId, ...body }: RejectJobArgs) => {
  return axios.put<RejectJobResponse>(
    `${coreApiUrl}/jobs/${jobId}/reject`,
    body,
  );
};
