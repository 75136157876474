import { retry } from '../../../../helpers/retry';
import { dataAttrActiveRow, dataAttrJobId } from '../../jobs.constants';
import { UpdateActiveJobRowsArgs } from './update-active-job-rows.types';

/**
 * Handles marking and un-marking the active job row when the user opens/closes the jobs details panel.
 *
 * @example
 * useEffect(() => {
 *   const { cleanupUseEffect } = updateActiveJobRows({
 *     jobIdInDetailsPanel,
 *     setViewInEditMode,
 *     viewInEditMode,
 *   });
 *
 *   return cleanupUseEffect;
 * }, [showDetailPanel, jobIdInDetailsPanel]);
 */
export const updateActiveJobRows = (args: UpdateActiveJobRowsArgs) => {
  const { jobIdInDetailsPanel, setViewInEditMode, viewInEditMode } = args;

  purgeActiveRows();

  if (jobIdInDetailsPanel) {
    const markActiveJobIdRow = () => {
      const activeJobRow = document.querySelector<HTMLTableRowElement>(
        `tr[${dataAttrJobId}="${jobIdInDetailsPanel}"]`,
      );

      if (activeJobRow) {
        activeJobRow.setAttribute(dataAttrActiveRow, '');
      }

      return !!activeJobRow;
    };

    retry({ callback: markActiveJobIdRow });
  } else if (viewInEditMode) {
    setViewInEditMode(false);
  }

  return { cleanupUseEffect: purgeActiveRows };
};

const purgeActiveRows = () => {
  return document.querySelectorAll(`[${dataAttrActiveRow}]`).forEach((row) => {
    return row.removeAttribute(dataAttrActiveRow);
  });
};
