import { Icon, media } from 'gantri-components';
import styled, { css } from 'styled-components';
import { BorderTopDivider } from '../../../../../styles';

export const StyledPopup = styled.div`
  min-width: 32rem;
  user-select: none;
  z-index: 1013;
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.overlayLevel1};
      box-shadow: ${theme.colors.shadow.high};
    `;
  }}

  ${media.lessThan('sm')`
    min-width: 30rem
  `};
`;

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.4rem 1.6rem;
  ${({ theme }) => {
    return css`
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;

export const StyledNavIcon = styled(Icon)<{ $isEnabled: boolean }>`
  ${({ $isEnabled }) => {
    return (
      !$isEnabled &&
      css`
        visibility: hidden;
      `
    );
  }}
`;

export const StyledWeekDayHeader = styled.ul`
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
`;

export const StyledWeekDay = styled.li`
  ${({ theme }) => {
    return css`
      color: ${theme.name === 'light'
        ? theme.colors.typography.t2
        : theme.colors.typography.t1};
    `;
  }}

  flex: 1;
  text-align: center;
`;

export const StyledDaysWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

export const StyledDayValue = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;

  &:hover {
    cursor: pointer;
  }
`;

export const StyledDay = styled.li<{
  $inRange: boolean;
  $selected: boolean;
  disabled: boolean;
}>`
  padding-top: calc(100% / 7);
  position: relative;
  text-align: center;
  width: calc(100% / 7);
  ${({ theme }) => {
    return css`
      color: ${theme.name === 'light'
        ? theme.colors.typography.t2
        : theme.colors.typography.t1};
    `;
  }}

  ${({ $selected, disabled, theme }) => {
    return (
      !disabled &&
      !$selected &&
      css`
        &:hover {
          color: ${theme.colors.typography.link};
        }
      `
    );
  }}

  ${({ disabled, theme }) => {
    return (
      disabled &&
      css`
        pointer-events: none;
        color: ${theme.colors.typography.t3};
      `
    );
  }}

  ${({ $inRange, theme }) => {
    return (
      $inRange &&
      css`
        background-color: ${theme.colors.surfaces.green.t1};
      `
    );
  }}

  ${({ $selected, theme }) => {
    return (
      $selected &&
      css`
        color: ${theme.name === 'light'
          ? theme.colors.typography.t3
          : theme.colors.typography.t1};
        background-color: ${theme.colors.surfaces.green.t3};
      `
    );
  }}
`;

export const StyledResetWrapper = styled.div`
  padding-top: 1rem;
  padding-bottom: 1.4rem;
  ${BorderTopDivider}
`;
