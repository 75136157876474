import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdatedStocksArg,
  GetUpdatedStocksResponse,
} from '../../version.types';

export const getUpdatedStocks = ({
  productId,
  type,
  versionId,
}: UpdatedStocksArg) => {
  return axios.get<GetUpdatedStocksResponse>(
    `${coreApiUrl}/versions/stocks/${productId}/${type}/${versionId}`,
  );
};
