import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { UpdateTransactionNoteArgs } from './update-transaction-note.types';

export const updateTransactionNote = (args: UpdateTransactionNoteArgs) => {
  const { notes, transactionId } = args;

  return axios.put<TransactionResponse>(
    `${baseApiUrl}/transactions/${transactionId}/notes`,
    { notes },
  );
};
