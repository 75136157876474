import { Box } from 'gantri-components';
import styled, { css } from 'styled-components';

export const LargeRadioWrapper = styled(Box)<{
  $checked: boolean;
}>`
  ${({ $checked, theme }) => {
    return css`
      border: 1px solid
        ${$checked ? theme.colors.typography.success : 'transparent'};
      border-radius: 0.4rem;
      background-color: ${theme.colors.surfaces.blue.t1};
      box-shadow: ${$checked && theme.colors.shadow.low};
      cursor: pointer;
    `;
  }}
`;
