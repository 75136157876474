import React, { PropsWithChildren } from 'react';
import { PageWithTableFC, PageWithTableProps } from './page-with-table.types';
import { PageWithTablePresets } from './page-with-table.presets';
import { Content, Header, Footer } from './components';
import MetaData from '../../meta-data';
import { StyledPageWithTable } from './page-with-table.styles';

export const PageWithTable: PageWithTableFC<
  PropsWithChildren<PageWithTableProps>
> = (props) => {
  const { children, pageTitle } = props;

  return (
    <>
      <MetaData title={pageTitle} />
      <StyledPageWithTable>{children}</StyledPageWithTable>
    </>
  );
};

PageWithTable.Header = Header;
PageWithTable.Content = Content;
PageWithTable.Footer = Footer;

PageWithTable.defaultProps = PageWithTablePresets;
