import { RetryArgs } from './retry.types';

/** Attempts callback immediately, then retries every `delay` until `maxAttempts` is reached. */
export const retry = async (args: RetryArgs) => {
  const { attempt = 1, callback, delay = 500, maxAttempts = 5 } = args;

  const successful = callback();

  if (successful) {
    return true;
  }

  if (attempt <= maxAttempts) {
    return setTimeout(() => {
      return retry({ attempt: attempt + 1, callback, delay, maxAttempts });
    }, delay);
  }

  return false;
};
