import { getFileUrl } from 'gantri-components';
import { Content } from 'pdfmake/interfaces';
import { cloudinaryUrl } from '../../../../../../../../../../helpers/images';
import { PackingSlipsButtonProps } from '../../../../packing-slip-button.types';
import { getPalette } from '../get-style-args';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/headers-footers/ */
export const getHeader = async (props: PackingSlipsButtonProps) => {
  const { currentOrderData } = props;
  const isThirdParty = currentOrderData.type === 'Third Party';
  const designerLogoFile = currentOrderData?.designerInfo?.logoUrl || '';
  const userId = currentOrderData?.designerInfo?.userId;

  if (isThirdParty && !designerLogoFile) {
    // eslint-disable-next-line no-restricted-globals, no-alert
    const goToDesignerPage = confirm(
      "A valid designer logo was not found. Please upload (or re-upload) the designer's logo. Would you like to go to the designer's page?",
    );

    if (goToDesignerPage) {
      window.location.href = `/designers/${userId}`;
    }
  }

  const { darkGray } = getPalette();
  const designerLogoSrc = getFileUrl<'designers'>({
    directory: 'designers',
    fileName: designerLogoFile,
    fileType: 'logo',
    identifiers: {
      userId,
    },
  });

  const designerLogoTransformed = cloudinaryUrl(designerLogoSrc, {
    aspectRatio: 1,
    crop: 'pad',
    dpr: 2,
    gravity: 'west',
    height: 24,
    width: 97,
  });

  const getBase64ImageFromURL = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.setAttribute('crossOrigin', 'anonymous');

      img.onload = () => {
        const canvas = document.createElement('canvas');

        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');

        ctx.drawImage(img, 0, 0);

        const dataURL = canvas.toDataURL('image/png');

        resolve(dataURL);
      };

      img.onerror = (error) => {
        reject(error);
      };

      img.src = url;
    });
  };

  const base64DesignerLogo =
    designerLogoFile && (await getBase64ImageFromURL(designerLogoTransformed));

  const designerLogo: Content = designerLogoFile
    ? {
        height: 24,
        image: base64DesignerLogo,
        width: 97,
      }
    : null;

  const gantriLogo: Content = {
    height: 24,
    /** Gantri logo */
    svg: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025.43 251.73" fill="${darkGray}" width="1em" height="1em" cursor="pointer" opacity="1"><path d="M189.99 116.32v18.41c0 30.7-3.08 50.49-10.92 68.22-14.67 32.4-40.59 48.78-80.84 48.78-43.32 0-72.66-19.79-87.67-53.56C3.07 181.46 0 162.71 0 125.86s3.07-55.59 10.56-72.3C25.56 19.79 54.91 0 98.56 0c26.26 0 48.09 7.85 63.78 22.17 16.37 15 24.9 35.82 25.93 64.47h-55.93c-1-15.35-4.43-23.87-10.58-29.68-5.79-5.11-14-7.84-24.55-7.84-16 0-26.95 6.48-33.08 20.46-4.45 10.24-5.81 20.81-5.81 56.28s1.36 46.05 5.81 56.28c6.48 14.33 18.42 20.47 35.46 20.47 16 0 26.62-6.14 32.06-18.08 2.4-5.8 3.41-10.91 3.76-26.94H97.55v-41.27ZM329.43 203.63h-66.68l-9.9 42.64h-56.58L253.83 5.46h84.94l57.17 240.81h-56.62Zm-9.9-43.66-23.1-112.22h-.69l-23.1 112.22ZM412.96 5.46h53.21l75.38 141.21V5.46h53.55v240.81h-52.83l-75.76-142.58v142.58h-53.55ZM658.39 52.87H617.8V5.46h136.47v47.41h-40.62v193.4h-55.26ZM839.63 144.62h-7.5v101.65h-55.26V5.46h88.69c33.42 0 53.89 10.23 63.78 31.38 4.08 9.21 5.79 19.78 5.79 38.2 0 18.08-1.71 29-5.79 38.2q-9.21 22.51-33.77 28.65l54.57 104.38h-59.69Zm-7.5-38.54h17.06c13.63 0 22.5-3.41 26.26-11.26 2-4.09 2.72-8.18 2.72-18.42 0-9.89-.67-13.63-2.72-17.73-4.1-8.19-13-10.57-26.26-10.57h-17.06ZM970.17 5.46h55.26v240.81h-55.26Z"></path></svg>`,
    width: 97,
  };

  const header: Content = {
    margin: [46, 46, 46, 36],
    stack: [isThirdParty ? designerLogo : gantriLogo],
  };

  return header;
};
