import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import {
  Checklist,
  ChecklistItem,
} from '../../common/job-checklist/job-checklist.types';
import { getFailingReasonsFromChecklist } from '../get-failing-reasons-from-checklist';

/** Checks against every `checklistItem` where `status !== 'Pass'` using `Array.some()`. */
export const checkAgainstFailingChecklistItems = <KeyType extends FailedReason>(
  checklist: Partial<Checklist<KeyType>>,
  callback: (checklistItem: ChecklistItem) => boolean,
): boolean => {
  const failingReasons = getFailingReasonsFromChecklist(checklist);

  return failingReasons.some((reason) => {
    const checklistItem = checklist[reason];

    return callback(checklistItem);
  });
};
