import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  GetPaginatedJobsArgs,
  GetPaginatedJobsResponse,
} from './get-paginated-jobs.types';
import { fetchPaginatedJobs } from './get-paginated-jobs';

export const useFetchPaginatedJobsQuery = <
  TransformedData = GetPaginatedJobsResponse,
>(
  props: GenericFetchProps<
    GetPaginatedJobsArgs,
    GetPaginatedJobsResponse,
    TransformedData
  >,
) => {
  const { data, isLoading } = useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated jobs.',
    queryObj: fetchPaginatedJobs,
    ...props,
  });

  return {
    data,
    isLoading,
  };
};

export const useInvalidateFetchPaginatedJobsCache = () => {
  const invalidateFetchPaginatedJobsCache =
    useGetInvalidateQueryCache(fetchPaginatedJobs);

  return { invalidateFetchPaginatedJobsCache };
};
