import { FC } from 'react';
import { Typography } from 'gantri-components';
import { StyleLabelContainer } from './required-input.styles';
import { RequiredInputProps } from './required-input.types';

export const RequiredInput: FC<RequiredInputProps> = (props) => {
  const { children, isRequired, label } = props;

  return (
    <>
      <StyleLabelContainer>
        <Typography align="left" text={label} textStyle="bold" variant="p2" />
        {isRequired && (
          <Typography align="left" color="alert" text="*" variant="p3" />
        )}
      </StyleLabelContainer>
      {children}
    </>
  );
};
