import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { PreparePrintsModalFormData } from '../../create-print-prepare-queue-modal.types';
import { CreatePrintPrepareQueueModalFooterProps } from './create-print-prepare-queue-modal-footer.types';

export const CreatePrintPrepareQueueModalFooter = (
  props: CreatePrintPrepareQueueModalFooterProps,
) => {
  const { handleCloseModal, processingConfirm } = props;

  const { isValid } = useFormikContext<PreparePrintsModalFormData>();

  return (
    <>
      <Button
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!isValid}
        processing={processingConfirm}
        text="Confirm"
        type="submit"
      />
    </>
  );
};
