import { Row } from '@tanstack/react-table';
import { useRecoilValue } from 'recoil';
import { jobsTemplateTableAtoms } from '../../jobs-template-table.atoms';
import { JobTemplateWithId } from '../../jobs-template-table.types';

export const useGetIsEditingRow = (row: Row<JobTemplateWithId>) => {
  const editingTemplateId = useRecoilValue(
    jobsTemplateTableAtoms.editingTemplateId,
  );

  return row.original.id === editingTemplateId;
};
