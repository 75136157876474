import { Grid, Icon, media } from 'gantri-components';
import styled, { css } from 'styled-components';
import { BorderTopDivider } from '../../../../styles';

export const StyledPreferencesPopUp = styled.div`
  width: 21.2rem;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => {
    return theme.colors.surfaces.monochrome.overlayLevel1;
  }};
  box-shadow: ${({ theme }) => {
    return theme.colors.shadow.medium;
  }};
  overflow: hidden;

  ${media.greaterThan('lg')`
    margin-top: 0.8rem;
  `};

  ${media.lessThan('md')`
    position: fixed;
    padding-top: 4.8rem;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
   `};
`;

export const StyledHeading = styled(Grid)`
  padding: 1.2rem 2.4rem;
`;

export const StyledClickableIcon = styled(Icon)`
  cursor: pointer;
  ${media.greaterThan('md')`
    display: none;
  `}
`;

export const StyledContent = styled.div`
  padding: 1.6rem 2.4rem;
  overflow: auto;

  ${({ theme }) => {
    return css`
      ${BorderTopDivider}
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}

  ${media.greaterThan('lg')`
    max-height: 50vh;
  `}

  ${media.lessThan('md')`
    flex: 1 0 0vh;
  `}
`;

export const StyledFooter = styled.div`
  padding: 0.4rem;
`;
