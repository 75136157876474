import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { LeavePageConfirmation } from '../../../../components/routes';
import { useResetRecoilAtoms } from '../../../../hooks/use-reset-recoil-atoms';
import { batchPartCreationAtoms } from '../../batch-part-creation.atoms';
import { useRouter } from '../../../../hooks';
import { BatchPartCreationFormik } from '../../batch-part-creation.types';

export const HandleBatchPartCreationLeave = () => {
  const { navigate } = useRouter();

  const navigateTo = useRecoilValue(batchPartCreationAtoms.navigateTo);

  const resetBatchPartCreationAtoms = useResetRecoilAtoms(
    batchPartCreationAtoms,
  );

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  const { template } = formikValues;

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo);
      resetBatchPartCreationAtoms();
    }
  }, [navigateTo]);

  return (
    <LeavePageConfirmation
      disabled={!template || !!navigateTo}
      header="Edit Part Creation"
      message="You are about to exit the part creation page. By doing so, all progress will be discarded. Are you sure you want to exit?"
      onConfirm={resetBatchPartCreationAtoms}
    />
  );
};
