import PropTypes from 'prop-types';
import { palette } from 'gantri-components';
import { StyledSpinnerWrapper } from './spinner-styles';

const LoadingSpinner = ({ color, lineBG, lineSize, size, timing }) => {
  return (
    <StyledSpinnerWrapper
      lineBG={lineBG || 'lightGrey'}
      lineSize={lineSize || '4px'}
      size={size || '30px'}
      spinnerColor={color || 'black'}
      timing={timing || '500ms'}
    />
  );
};

LoadingSpinner.propTypes = {
  color: PropTypes.oneOf(Object.keys(palette)),
  lineBG: PropTypes.oneOf(Object.keys(palette)),
  lineSize: PropTypes.string,
  size: PropTypes.string,
  timing: PropTypes.string,
};

export default LoadingSpinner;
