import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetIdleStocksArgs,
  GetIdleStocksResponse,
} from './get-idle-stocks.types';

export const getIdleStocks = ({ skus }: GetIdleStocksArgs) => {
  return axios.get<GetIdleStocksResponse>(
    `${coreApiUrl}/stocks/idle?skus=${skus.join(',')}`,
  );
};
