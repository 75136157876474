import {
  InventoryTransactionSortingField,
  InventoryTransactionType,
  InventoryType,
} from '../../../../../../api/inventories/routes/get-inventory/get-inventory.types';
import { DateRangeFilter } from '../../../../../../components/common/table/components/table-filters/table-filters.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../../../../helpers/get-generic-atom-family';
import { SortBy } from '../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const inventoryTransactionsFiltersDefaults: {
  filters: {
    dateRange: DateRangeFilter<string>;
    transactionTypes: InventoryTransactionType[];
    types: InventoryType[];
  };
  sortBy: SortBy<InventoryTransactionSortingField>;
} = {
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
    transactionTypes: [],
    types: [],
  },
  sortBy: {
    sortingField: 'default',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'INVENTORY_TRANSACTIONS_FILTERS';

export const pageName: AtomPageName = 'inventory-transactions';

export const inventoryTransactionsFiltersAtoms = {
  defaults: inventoryTransactionsFiltersDefaults,
  filters: {
    dateRange: getGenericAtomFamily({
      defaultValue: inventoryTransactionsFiltersDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range-sec`,
    })(pageName),
    transactionTypes: getGenericAtomFamily({
      defaultValue:
        inventoryTransactionsFiltersDefaults.filters.transactionTypes,
      key: `${atomKeyPrefix}-transaction-type`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: inventoryTransactionsFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<InventoryTransactionSortingField>>({
    defaultValue: inventoryTransactionsFiltersDefaults.sortBy,
  })(pageName),
};
