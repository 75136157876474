import styled, { CSSProperties } from 'styled-components';

export const StyledIconWithTextContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 5px;
  align-content: center;
  align-items: center;
`;

export const StyledDivider = styled.div<{
  marginBottom?: CSSProperties['marginBottom'];
  marginTop?: CSSProperties['marginTop'];
}>`
  height: 1px;
  width: 100%;
  background-color: ${(props) => {
    return props.color || props.theme.colors.dividers.t1;
  }};
  margin-top: ${(props) => {
    return props.marginTop || 'unset';
  }};
  margin-bottom: ${(props) => {
    return props.marginBottom || 'unset';
  }};
`;

export const StyledThumbnailCell = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 1rem;
  align-items: center;
`;

export const StyledInlineContainer = styled.div`
  display: inline-block;

  & > * {
    display: inline;
  }
`;

export const StyledFlexContainer = styled.div`
  display: flex;
`;
