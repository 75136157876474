import { renderToString } from 'react-dom/server';
import { DesignerData } from '../../../../../../designer.types';
import { Handout } from '../..';

export const createHandoutHtml = (
  designerInfo: DesignerData,
  wrapInPageTags = true,
) => {
  const removeWhitespace = (string: string) => {
    return string
      .replace(/\n/g, '')
      .replace(/\s\s+/g, '')
      .replace(/&quot;/g, '"');
  };

  const htmlString = renderToString(<Handout designerInfo={designerInfo} />);

  return wrapInPageTags
    ? removeWhitespace(`
      <!DOCTYPE html>
      <html>
        <head>
          <meta charset="utf-8"/>
        </head>
        <body>
          ${htmlString}
        </body>
      </html>
    `)
    : removeWhitespace(htmlString);
};
