import { useRecoilState } from 'recoil';
import { FinishingQaFailedReason } from '../../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { ChecklistItem } from '../../../../../common/job-checklist/job-checklist.types';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import { statusOptions } from '../../finishing-qa-content/finishing-qa-content.constants';
import { ChecklistContentProps } from './checklist-content.types';

export const ChecklistContent = (props: ChecklistContentProps) => {
  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    finishingQcModalAtoms.isChecklistReviewed,
  );
  const [checklist, setChecklist] = useRecoilState(
    finishingQcModalAtoms.checklist,
  );

  const updateChecklist = (
    reason: FinishingQaFailedReason,
    data: ChecklistItem,
  ) => {
    setChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      {...props}
      checklist={checklist}
      isChecklistReviewed={isChecklistReviewed}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={statusOptions}
      onItemChange={updateChecklist}
    />
  );
};
