import { Stack, Tooltip } from 'gantri-components';
import { memo } from 'react';
import { IssueType } from './components/issue-type';
import { MachineIssuesProps } from './machine-issues.types';

export const MachineIssues = memo(({ issues = [] }: MachineIssuesProps) => {
  if (!issues?.length) {
    return null;
  }

  const isMultipleIssues = issues.length > 1;
  const firstFiveIssues = issues.slice(0, 5);

  return isMultipleIssues ? (
    <Tooltip
      Component={
        <Stack gap="1rem" horizontalPadding="1.6rem" verticalPadding="0.8rem">
          {firstFiveIssues.map((issue, index) => {
            return (
              <IssueType key={`${issue.type}-${index}`} issues={[issue]} />
            );
          })}
        </Stack>
      }
      position="top"
    >
      <IssueType groupMultipleIssues issues={issues} />
    </Tooltip>
  ) : (
    <IssueType issues={issues} />
  );
});
