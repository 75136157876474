import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FailPrintJobArgs, FailPrintJobResponse } from './fail-print-job.types';

export const failPrintJob = ({ id, ...body }: FailPrintJobArgs) => {
  return axios
    .post<FailPrintJobResponse>(`${coreApiUrl}/jobs/${id}/fail`, body)
    .then(({ data }) => {
      return data;
    });
};
