import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  AddExceededCycleTimeReasonsArgs,
  AddExceededCycleTimeReasonsResponse,
} from './add-exceeded-cycle-time-reasons.types';
import { addExceededCycleTimeReasons } from './add-exceeded-cycle-time-reasons';
import { useInvalidateJobsFetchCaches } from '../hooks/use-invalidate-jobs-fetch-caches';

export const useAddExceededCycleTimeReasons = <
  TransformedData = AddExceededCycleTimeReasonsResponse,
>(
  props?: GenericMutateQueryProps<
    AddExceededCycleTimeReasonsArgs,
    AddExceededCycleTimeReasonsResponse,
    TransformedData
  >,
) => {
  const { invalidateJobCaches } = useInvalidateJobsFetchCaches();

  const { onMutate: onAddExceededCycleTimeReasons, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to log exceeded time reason.',
      mutationFn: addExceededCycleTimeReasons,
      onSuccess: async (...args) => {
        await invalidateJobCaches();
        await props?.onSuccess?.(...args);
      },
      ...props,
    });

  return { ...rest, onAddExceededCycleTimeReasons };
};
