import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { GetStockArgs, GetStockResponse } from './get-stock.types';

export const getStock = ({ stockId }: GetStockArgs) => {
  return axios
    .get<GetStockResponse>(`${getCoreApiUrl('stocks')}/${stockId}`)
    .then(({ data }) => {
      return data;
    });
};
