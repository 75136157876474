import { Cell, Flex, Icon, Tooltip, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { formatDate } from '../../helpers/formatter';
import routePaths from '../../config/route-paths';
import { StyledToolTipContent } from './minor-version.styles';
import { MinorVersionInfoType } from './minor-version-info.types';
import { LONG_FORMAT } from '../../constants/dates';

export const MinorVersionInfo = (props: MinorVersionInfoType) => {
  const { color, minorUpdate, statusInfo, version } = props;

  const TooltipContent = () => {
    const { created, published } = statusInfo;

    const formattedDate = formatDate(
      +published?.date || +created?.date,
      LONG_FORMAT,
    );

    const username = published
      ? `${published?.user?.firstName} ${published?.user?.lastName}`
      : `${created?.user?.firstName} ${created?.user?.lastName}`;

    const userId = published?.user?.id || created?.user?.id;

    if (username) {
      return (
        <StyledToolTipContent>
          <Typography
            display="inline"
            text={`Version updated on ${formattedDate}  by `}
            variant="p2"
          />
          <Link to={`${routePaths.users}/${userId}`}>
            <Typography
              color="link"
              decoration="underline"
              display="inline"
              text={username}
            />
          </Link>
        </StyledToolTipContent>
      );
    }

    return <Typography color="t2" text={`${formattedDate}`} variant="p2" />;
  };

  if (minorUpdate) {
    return (
      <Flex alignItems="center" gap="0.9rem">
        <Cell>
          <Typography color={color} text={version} />
        </Cell>
        <Cell marginTop="0.5rem">
          <Flex alignItems="baseline" gap="0.6rem">
            <Icon color="warning" name="alert:warning_triangle" />
            <Tooltip Component={<TooltipContent />}>
              <Icon name="alert:i_circle" size="1.6rem" />
            </Tooltip>
          </Flex>
        </Cell>
      </Flex>
    );
  }

  return <Typography color={color} text={version} />;
};
