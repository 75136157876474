import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import {
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import { useFirebaseFileUploader } from '../../../../../../../../../../hooks/use-firebase-file-uploader';
import { imageFloatingPartBatchPath } from '../../../../../../../../../../helpers/gcodes';
import { batchPartCreationAtoms } from '../../../../../../../../batch-part-creation.atoms';
import { getActiveEnv } from '../../../../../../../../../../helpers/get-active-env';
import { BatchPartCreationFormik } from '../../../../../../../../batch-part-creation.types';

export const PartThumbnail = () => {
  const [partThumbnail, setPartThumbnail] = useRecoilState(
    batchPartCreationAtoms.partThumbnail,
  );
  const resetPartThumbnail = useResetRecoilState(
    batchPartCreationAtoms.partThumbnail,
  );
  const partUuid = useRecoilValue(batchPartCreationAtoms.partUuid);

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  const { partName } = formikValues;

  const onFileDelete: HandleDeleteFile = async ({ isReplacing }) => {
    if (!isReplacing) {
      resetPartThumbnail();
    }
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileUrl, relativePath },
  ]) => {
    setPartThumbnail({ relativePath, url: fileUrl });
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: partThumbnail?.url,
    handleUploadsComplete,
    onFileDelete,
    path: imageFloatingPartBatchPath(partUuid),
  });

  const { isLocalEnv } = getActiveEnv();

  return (
    <FileUploader
      {...fileUploaderProps}
      enableCopyUrl={isLocalEnv}
      fileName={partName}
      variant="thumbnail"
    />
  );
};
