import { useRecoilState } from 'recoil';
import { Radio, Stack, TextArea, Typography } from 'gantri-components';
import { debounce } from 'lodash';
import { rejectJobModalAtoms } from '../../../reject-job-modal.atoms';
import { rejectReasonOptions } from './reject-reason-content.constants';
import { ModalContentHeading } from '../../../../../../pages/jobs/components/modals/common/modal-content-heading';

export const RejectReasonContent = () => {
  const [rejectReason, setRejectReason] = useRecoilState(
    rejectJobModalAtoms.rejectReason,
  );
  const [rejectDetails, setRejectDetails] = useRecoilState(
    rejectJobModalAtoms.rejectDetails,
  );

  return (
    <Stack gap="2x" verticalPadding="2x">
      <ModalContentHeading
        subTitleText="Please select a reason for rejecting this job."
        titleText="Reject reason"
      />
      {rejectReasonOptions.map((option) => {
        return (
          <Radio
            key={option}
            groupValue={option}
            labelText={option}
            value={rejectReason}
            onSelected={setRejectReason}
          />
        );
      })}
      <Stack gap="x">
        <Typography
          text="Add additional information about your selection:"
          textStyle="bold"
        />
        <TextArea
          placeholder="Enter details about the rejected part"
          required
          value={rejectDetails}
          onTextChange={debounce(setRejectDetails, 300)}
        />
      </Stack>
    </Stack>
  );
};
