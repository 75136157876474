import { updateMachine } from './update-machine';
import {
  UpdateMachineArgs,
  UpdateMachineResponse,
} from './update-machine.types';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';

export const useUpdateMachine = <TransformedData = UpdateMachineResponse>(
  props?: GenericMutateQueryProps<
    UpdateMachineArgs,
    UpdateMachineResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateMachine, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update machine.',
    mutationFn: updateMachine,
    ...props,
  });

  return { ...rest, onUpdateMachine };
};
