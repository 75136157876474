import { useFormikContext } from 'formik';
import {
  getColorsByProduct,
  ProductColorCode,
  productColorsMap,
} from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useAsync } from 'react-use';
import { BatchPartCreationFormik } from '../../../../../../../../batch-part-creation.types';
import {
  UseGetTemplateDetailsProps,
  DropdownOptions,
} from './use-get-batch-template-details.types';
import { getShouldClearDropdown } from './helpers/get-should-clear-dropdown';
import { Material } from '../../../../../../../../../../constants/options/options.types';
import { materials } from '../../../../../../../../../../constants/parts';
import {
  batchPartCreationAtoms,
  batchPartCreationDefaults,
} from '../../../../../../../../batch-part-creation.atoms';
import {
  unpaintedColor,
  UnpaintedColorCode,
} from '../../../../../../../../../../constants/colors';
import { ProductStatus } from '../../../../../../../../../../api/products/routes/get-all/get-all.types';
import { convertStringArrayToValueLabelArray } from '../../../../../../../../../../helpers/formatter';

export const useGetBatchTemplateDetails = (
  props: UseGetTemplateDetailsProps = { allProducts: [] },
) => {
  const { allProducts = [] } = props;

  const step = useRecoilValue(batchPartCreationAtoms.step);

  const {
    setFieldValue,
    setValues,
    values: formikValues,
  } = useFormikContext<BatchPartCreationFormik>();
  const { material, productId, template } = formikValues;

  const isFromProduct = template === 'CREATE_FROM_PRODUCT';

  const selectedProductDetails = useMemo(() => {
    return allProducts.find(({ id }) => {
      return id === formikValues.productId;
    });
  }, [formikValues.productId, allProducts]);

  const disableProductPartAndColor = !productId;

  const productNameOptions: DropdownOptions<number, { status: ProductStatus }> =
    useMemo(() => {
      return allProducts.map(({ fullName, id, status }) => {
        return {
          label: fullName,
          status,
          value: id,
        };
      });
    }, [allProducts]);

  /** Only validates against "from scratch" templates. */
  const isTranslucentMaterial =
    !isFromProduct && formikValues?.material === 'Translucent GPP';

  const productColorOptions = useMemo(() => {
    if (template === 'CREATE_FROM_SCRATCH' && !material) {
      return [];
    }

    const unpaintedColorOption = {
      label: unpaintedColor.name,
      value: unpaintedColor.code,
    };

    if (isTranslucentMaterial) {
      const translucentMaterialColors: DropdownOptions<UnpaintedColorCode> = [
        unpaintedColorOption,
      ];

      return translucentMaterialColors;
    }

    const options: DropdownOptions<ProductColorCode | UnpaintedColorCode> = (
      selectedProductDetails
        ? getColorsByProduct({
            allowTradeColors: true,
            isPainted: selectedProductDetails?.isPainted,
            productId: selectedProductDetails?.id,
          })
        : Object.values(productColorsMap)
    ).map(({ code, shortColorName }) => {
      return { label: shortColorName, value: code };
    });

    options.unshift(unpaintedColorOption);

    return options;
  }, [selectedProductDetails, formikValues.material]);

  const productPartOptions: DropdownOptions<string> = useMemo(() => {
    return convertStringArrayToValueLabelArray(
      selectedProductDetails?.parts || [],
    );
  }, [disableProductPartAndColor, selectedProductDetails?.id]);

  const partMaterialOptions: DropdownOptions<Material> = useMemo(() => {
    return Object.values(materials).map((value) => {
      return { label: value, value };
    });
  }, []);

  const selectingBatchTemplate = step === 'SELECT_TEMPLATE';

  const setValueIfOnlyOneOption = async (
    key: keyof BatchPartCreationFormik,
    options: { value: string }[],
  ) => {
    const firstOptionCode = options[0]?.value;

    if (
      selectingBatchTemplate &&
      options.length === 1 &&
      firstOptionCode !== formikValues[key]
    ) {
      await setFieldValue(key, firstOptionCode);
    }
  };

  const clearFieldsBasedOnAvailableOptions = async () => {
    if (selectingBatchTemplate) {
      const clearColorCode = getShouldClearDropdown({
        options: productColorOptions,
        value: formikValues.colorCode,
      });
      const clearPartName = getShouldClearDropdown({
        options: productPartOptions,
        value: formikValues.partName,
      });

      const newValues = {
        ...formikValues,
        colorCode: clearColorCode
          ? batchPartCreationDefaults.colorCode
          : formikValues.colorCode,
        partName: clearPartName
          ? batchPartCreationDefaults.partName
          : formikValues.partName,
      };

      if (clearPartName || clearColorCode) {
        await setValues(newValues);
      }
    }
  };

  useAsync(async () => {
    if (isTranslucentMaterial && formikValues.colorCode) {
      await setFieldValue('colorCode', unpaintedColor.code);
    } else if (!selectedProductDetails.isPainted) {
      await setFieldValue('colorCode', unpaintedColor.code);
    }
  }, [formikValues]);

  useAsync(async () => {
    await clearFieldsBasedOnAvailableOptions();
  }, [productPartOptions, productColorOptions]);

  useAsync(async () => {
    await setValueIfOnlyOneOption('colorCode', productColorOptions);
  }, [formikValues.colorCode]);

  useAsync(async () => {
    await setValueIfOnlyOneOption('partName', productPartOptions);
  }, [productPartOptions, formikValues.partName]);

  return {
    disableProductPartAndColor,
    isTranslucentMaterial,
    partMaterialOptions,
    productColorOptions,
    productNameOptions,
    productPartOptions,
  };
};
