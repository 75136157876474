import {
  DirectoryName,
  FileUploaderProps,
  HandleDeleteFile,
  HandleUploadFile,
  TransformFileName,
  transformFileNameDefault,
  TransformThumbnailSrc,
} from 'gantri-components';
import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { getFileDetails } from './helpers/get-file-details';
import { UseFileUploaderArgs as UseCloudinaryFileUploaderArgs } from './use-cloudinary-file-uploader.types';
import {
  useDeleteCloudinaryFile,
  useUploadCloudinaryFile,
} from '../../api/cloudinary/routes';
import { useNotification } from '../useNotification';
import { cloudinaryUrl } from '../../helpers/images';
import {
  fileUploaderAtoms,
  isUploadingDisabledMessage,
} from './use-cloudinary-file-uploader.atoms';

/**
 * https://www.notion.so/gantri/Asset-Directory-Structure-674ff9974018475c89a78249950b4216
 *
 * While not strictly necessary, it's highly recommended that you copy the `directory` value to the type definition to ensure highly accurate types:
 *
 * @example
 * const { fileUploaderProps } = useCloudinaryFileUploader<'products'>({
 *   directory: 'products',
 *   fileName: valueProp.fileName,
 *   fileType: 'value-props',
 *   identifiers: { productId: product.id },
 *   onFileDelete,
 *   onFileSelected,
 * });
 *
 * return <FileUploader {...fileUploaderProps} />;
 */
export const useCloudinaryFileUploader = <Directory extends DirectoryName>(
  props: UseCloudinaryFileUploaderArgs<Directory>,
) => {
  const {
    base64Url,
    fileName,
    handleUploadsComplete,
    isRawFile,
    onFileDelete: updateDbOnFileDelete,
    onFileSelected,
    showLoading,
    transformFileName = transformFileNameDefault,
    validateFile,
  } = props;

  const [isUploading, setIsUploading] = useRecoilState(
    fileUploaderAtoms.isUploading,
  );

  const {
    notifyAxiosError,
    onInterceptProcessingRequest,
    processing: isDeletingFile,
  } = useNotification();

  const { isLoading: isUploadingFile, onUploadCloudinaryFile } =
    useUploadCloudinaryFile({
      showLoading,
    });

  const { onDeleteFile } = useDeleteCloudinaryFile({
    showLoading,
  });

  const applyUuid = (fileName: string) => {
    /** Group `$1` === file extension or end of string. */
    const extensionPattern = /(\.[^.]+)?$/i;
    const uid = `_${Date.now()}`;

    return fileName.replace(extensionPattern, isRawFile ? `${uid}$1` : uid);
  };

  const { fileUrl: generatedUrl, folder } = getFileDetails<Directory>(props);

  const getThumbnailUrl = (fileUrl: string) => {
    if (base64Url) {
      return base64Url;
    }

    const isVideo = /\/video\//.test(fileUrl);

    return isVideo ? fileUrl.replace(/\.\w+$/, '.jpg') : fileUrl;
  };

  const handleTransformFileName: TransformFileName = (props) => {
    return transformFileName({ ...props, applyUuid });
  };

  const handleUploadFile: HandleUploadFile = ({ fileBlob, fileName }) => {
    setIsUploading(true);

    return onUploadCloudinaryFile({
      file: fileBlob,
      folder,
      publicId: fileName,
      resourceType: isRawFile ? 'raw' : 'auto',
    });
  };

  const handleDeleteFile: HandleDeleteFile = async (props) => {
    await onInterceptProcessingRequest(async () => {
      const { isReplacing } = props;

      if (!isReplacing) {
        await updateDbOnFileDelete?.(props);
      }

      if (!base64Url) {
        await onDeleteFile({ fileUrl: generatedUrl });
      }
    });
  };

  const handleDeleteFileOnly = async () => {
    if (!base64Url) {
      await onDeleteFile({ fileUrl: generatedUrl });
    }
  };

  const processing = isUploadingFile || isDeletingFile;

  const transformThumbnailSrc: TransformThumbnailSrc = (props) => {
    const { size, src } = props;

    if (base64Url) {
      return base64Url;
    }

    return cloudinaryUrl(src, {
      crop: 'limit',
      height: size,
      width: size,
    });
  };

  const fileUploaderProps = {
    disabledDescription: isUploading ? isUploadingDisabledMessage : undefined,
    fileName,
    fileUrl: getThumbnailUrl(generatedUrl),
    handleDeleteFile,
    handleFileSelected: onFileSelected as never,
    handleUploadFile: onFileSelected ? undefined : handleUploadFile,
    handleUploadsComplete: async (args) => {
      setIsUploading(false);
      await handleUploadsComplete?.(args);
    },
    isDisabled: isUploading,
    maxFileSizeMB: 10,
    onError: (args) => {
      return notifyAxiosError(args, { keepOpen: true });
    },
    processing,
    purifyFileName: true,
    transformFileName: handleTransformFileName,
    transformThumbnailSrc,
    validateFile,
  } satisfies Partial<FileUploaderProps>;

  useEffect(() => {
    return () => {
      setIsUploading(false);
    };
  }, []);

  return {
    fileUploaderProps,
    /** Use this to delete the file, but not update the database. */
    handleDeleteFileOnly,
    /** Use this if the file is not immediately uploaded on selection. */
    handleUploadFile,
    processing,
  };
};
