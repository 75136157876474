import styled, { css } from 'styled-components';

export const StyledImg = styled.img`
  ${() => {
    return css`
      max-width: 100%;
    `;
  }}
`;

export const StyledVideo = styled.video`
  ${() => {
    return css`
      max-width: 100%;
    `;
  }}
`;
