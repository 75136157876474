import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  GetFeatureSettingsArgs,
  GetFeatureSettingsResponse,
} from './get-designer-feature-settings.types';

export const query = (args: GetFeatureSettingsArgs) => {
  const { featureId, userId } = args;

  return axios
    .get<GetFeatureSettingsResponse>(
      `${baseApiUrl}/designers/${userId}/feature-page/${featureId}`,
    )
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'designer-feature-setting';
};

const key = (args?: GetFeatureSettingsArgs) => {
  const queries = [`userId=${args?.userId}`, `featureId=${args?.featureId}`];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getDesignerFeatureSettings = {
  groupKey,
  key,
  query,
};
