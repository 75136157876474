import { Conditional, Dropdown, Flex, Typography } from 'gantri-components';
import { useEffect, useState } from 'react';
import { useUpdateDesignCategories } from '../../../../api/designs/routes';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';
import { DesignCategoryProps } from './design-category.types';

export const DesignCategory = (props: DesignCategoryProps) => {
  const { designId, initialValue, options, title, type } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialValue);

  const { onUpdateDesignCategories } = useUpdateDesignCategories({
    onSuccess: async () => {
      setIsEditing(false);
    },
    showLoading: true,
  });

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <>
      <Typography text={title} />
      <Conditional
        condition={isEditing}
        Fallback={
          <Flex alignItems="center" gap="x">
            <Typography color="t2" text={value} />
            <StyledAnchor
              text="Edit"
              onClick={() => {
                setIsEditing(true);
              }}
            />
          </Flex>
        }
      >
        <Flex alignItems="center" gap="x">
          <Dropdown
            items={convertStringArrayToValueLabelArray(Object.values(options))}
            value={value}
            onSelect={(item) => {
              setValue(item?.value);
            }}
          />
          <StyledAnchor
            text="Update"
            onClick={async () => {
              await onUpdateDesignCategories({ id: designId, [type]: value });
            }}
          />
        </Flex>
      </Conditional>
    </>
  );
};
