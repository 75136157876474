import { memo } from 'react';
import { TextField, Cell, Flex } from 'gantri-components';
import {
  StyledFieldLabel,
  StyledURLBase,
} from '../../../../../../designer-styles';
import { environment } from '../../../../../../../../environment';
import { GeneralInputProps } from './general-inputs.types';

const Section = (props: GeneralInputProps) => {
  const { city, handleInputChange, name, profileLink, title } = props;

  return (
    <>
      <Cell>
        <StyledFieldLabel text="Designer name" textStyle="bold" />
        <TextField
          placeholder="Designer’s name"
          value={name || ''}
          onTextChange={handleInputChange('name')}
        />
      </Cell>
      <Cell>
        <StyledFieldLabel text="Url" textStyle="bold" variant="p2" />
        <Flex alignItems="center" justifyContent="flex-start">
          <StyledURLBase color="t2" text={`${environment.SHOP_URL}/`} />
          <TextField
            placeholder="url name"
            value={profileLink || ''}
            onTextChange={handleInputChange('profileLink')}
          />
        </Flex>
      </Cell>

      <Cell>
        <StyledFieldLabel text="Tagline" textStyle="bold" variant="p2" />
        <TextField
          placeholder="Tagline example"
          value={title || ''}
          onTextChange={handleInputChange('title')}
        />
      </Cell>
      <Cell>
        <StyledFieldLabel text="City" textStyle="bold" variant="p2" />
        <TextField
          placeholder="City name"
          value={city || ''}
          onTextChange={handleInputChange('city')}
        />
      </Cell>
    </>
  );
};

export const GeneralInputs = memo(Section);
