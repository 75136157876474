import { Conditional, Grid } from 'gantri-components';
import { PrimarySurfaceSwatch } from '../../../../../../../../../primary-surface-swatch';
import { SecondarySurfaceSwatch } from '../../../../../../../../../secondary-surface-swatch';
import { SeverityImagesRowProps } from './severity-images-row.types';
import { SeverityImageItem } from './components/severity-image-item';

export const SeverityImagesRow = (props: SeverityImagesRowProps) => {
  const {
    checklistItem,
    justPassImgSrc,
    material,
    surface,
    updateChecklistItem,
  } = props;

  return (
    <Grid columns="max-content 1fr" gap="1.6rem">
      <Conditional
        condition={surface === 'Primary'}
        Fallback={<SecondarySurfaceSwatch material={material} />}
      >
        <PrimarySurfaceSwatch material={material} />
      </Conditional>
      <Grid
        columnGap="1.2rem"
        columns={3}
        flow="column"
        rowGap="0.6rem"
        rows={2}
      >
        <SeverityImageItem
          checklistItem={checklistItem}
          src={checklistItem.severityImages.pass}
          status="Pass"
          surface={surface}
          text="Pass"
          updateChecklistItem={updateChecklistItem}
        />
        <SeverityImageItem
          checklistItem={checklistItem}
          src={justPassImgSrc}
          status="Pass"
          surface={surface}
          text="Just Pass"
          updateChecklistItem={updateChecklistItem}
        />
        <SeverityImageItem
          checklistItem={checklistItem}
          src={checklistItem.severityImages.fail}
          status="Fail"
          surface={surface}
          text="Fail"
          updateChecklistItem={updateChecklistItem}
        />
      </Grid>
    </Grid>
  );
};
