import styled, { css } from 'styled-components';
import { InitialEvaluation } from './evaluate-part-content.types';

export const StyledRadioWrapper = styled.div<{ status: InitialEvaluation }>`
  ${({ status, theme }) => {
    return css`
      padding: 1.2rem;
      background-color: ${status === 'PASS'
        ? theme.colors.surfaces.green.t1
        : theme.colors.surfaces.warning.t1};
    `;
  }}
`;
