import { mixed, object, SchemaOf, string } from 'yup';
import {
  LocationStatus,
  locationStatuses,
} from '../../../../constants/options';
import {
  LocationBuildings,
  LocationType,
  LocationWorkshop,
} from '../../locations.types';

export interface LocationModalSchema {
  area?: string;
  bin?: string;
  building: LocationBuildings;
  rack?: string;
  shelf?: string;
  status: LocationStatus;
  type: Lowercase<LocationType>;
  workshop: LocationWorkshop;
}
const requiredText = 'Required';

export const locationModalSchema: SchemaOf<LocationModalSchema> =
  object().shape({
    area: string().default(''),
    bin: string().default(''),
    building: mixed()
      .oneOf(['Polvorosa', 'Gate510'], requiredText)
      .required(requiredText),
    rack: string().default(''),
    shelf: string().default(''),
    status: mixed()
      .oneOf(Object.values(locationStatuses), requiredText)
      .required(requiredText)
      .default('Active'),
    type: mixed()
      .oneOf(
        ['area', 'bin', 'building', 'rack', 'shelf', 'workshop'],
        requiredText,
      )
      .required(requiredText),
    workshop: mixed()
      .oneOf(
        ['Assembly', 'Finishing', 'Print', 'Shipping', 'Staging', 'Storage'],
        requiredText,
      )
      .required(requiredText),
  });
