import { environment } from '../../environment';

export const getMantleUrl = () => {
  switch (environment.STAGE) {
    case 'local':
      return 'http://localhost:3002';
    case 'develop':
      return 'https://dev.gantri.com';
    case 'staging':
      return 'https://stage.gantri.com';
    default:
      return 'https://gantri.com';
  }
};
