import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from '../../hooks';
import { userAtoms } from '../../global-atoms/users';
import { UserRole } from '../../constants/users';

interface ProtectedRouteProps {
  Component: React.ComponentType | null;
  allowedFor?: UserRole[];
}

export const ProtectedRoute: FC<ProtectedRouteProps> = (props) => {
  const { Component, allowedFor } = props;
  const { location } = useRouter();

  const { type } = useRecoilValue(userAtoms.user);

  if (!allowedFor || (type && allowedFor.includes(type))) {
    return <Component />;
  }

  return <Navigate state={{ from: location.pathname }} to="/unauthorized" />;
};
