import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid, Modal, Typography } from 'gantri-components';
import { NewInventoryForm } from '../new-inventory/new-inventory-form';
import { EditInventoryModalProps } from './edit-inventory.types';
import { NewInventoryFormSchema } from '../new-inventory/new-inventory.schema';

export const EditInventoryModal: FC<EditInventoryModalProps> = ({
  initialValues = {
    description: '',
    name: '',
    unit: '',
  },
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={NewInventoryFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <Grid columns={2} gap="1.6rem">
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onCancel}
                  />

                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Confirm"
                    type="submit"
                  />
                </Grid>
              }
              header={
                <Typography
                  align="center"
                  text="Edit Inventory"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '42rem', md: '100%' }}
              onClose={onCancel}
            >
              <NewInventoryForm />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
