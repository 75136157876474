import { productColorsMap } from 'gantri-components';
import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportSalesQueries } from '../../report-sales.types';

export const getTitle = (rootTitle: string): string => {
  const queries = getActiveQueries<ReportSalesQueries>();
  const {
    color,
    country,
    creatorId,
    creatorName,
    customerType,
    groupBy,
    productId,
    productName,
    state,
  } = queries;

  if (/^productId$/i.test(groupBy) && productId && productName) {
    return productName;
  }

  if (/^color$/i.test(groupBy) && color) {
    return productColorsMap[color]?.shortColorName;
  }

  if (/^creatorId$/i.test(groupBy) && creatorId && creatorName) {
    if (productId && productName) {
      return productName;
    }

    return creatorName;
  }

  if (/^customerType$/i.test(groupBy) && customerType) {
    if (productId && productName) {
      return productName;
    }

    return `${customerType} Customer`;
  }

  if (/^country$/i.test(groupBy) && country) {
    if (!/^united states$/i.test(country)) {
      return country;
    }

    if (state) {
      return state;
    }

    return country;
  }

  return rootTitle;
};
