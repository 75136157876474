import { useRecoilValue } from 'recoil';
import { rejectJobModalAtoms } from '../../reject-job-modal.atoms';
import { RejectReasonFooter } from '../step-reject-reason/reject-reason-footer';
import { NextStepFooter } from '../step-next-step/next-step-footer';
import { RejectJobModalFooterProps } from './reject-job-modal-footer.types';

export const RejectJobModalFooter = (props: RejectJobModalFooterProps) => {
  const { jobId, onClose, onUpdate } = props;

  const step = useRecoilValue(rejectJobModalAtoms.step);

  switch (step) {
    case 'REJECT_REASON':
      return <RejectReasonFooter onClose={onClose} />;

    case 'NEXT_STEP':
      return (
        <NextStepFooter jobId={jobId} onClose={onClose} onUpdate={onUpdate} />
      );

    default:
      return null;
  }
};
