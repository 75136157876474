import { Conditional, Flex } from 'gantri-components';
import { Label } from '../../../../../../../../components/label';
import { ZoomableThumbnail } from '../../../../../../../../components/zoomable-thumbnail';
import { ShipmentThumbnailsProps } from './shipment-thumbnails.types';

export const ShipmentThumbnails = (props: ShipmentThumbnailsProps) => {
  const { editedShipment, shipmentNumber } = props;

  return (
    <Conditional condition={!!editedShipment.photos.length}>
      <Label paddingBottom=".5x" text="Photos" />
      <Flex gap="1.6rem">
        {editedShipment.photos.map((url, index) => {
          const altText = `Shipment #${shipmentNumber}. Attachment #${
            index + 1
          }`;

          return (
            <ZoomableThumbnail
              key={url}
              alt={altText}
              heading={altText}
              size={40}
              source="absolute"
              src={url}
            />
          );
        })}
      </Flex>
    </Conditional>
  );
};
