import { ChecklistItem } from '../../../../job-checklist.types';

export const getSurfacesStatus = (checklistItem: ChecklistItem) => {
  const {
    failStatusShouldTakeMachineOffline,
    ratingPrimarySurface,
    ratingSecondarySurface,
    severityImages,
    slider,
    status,
  } = checklistItem;

  const severityImagesFailValue = 5;

  const isSlider = !!slider;
  const isSeverityImages = !!severityImages;

  // PASS/FAIL
  const isPrimarySurfaceFailing =
    (isSlider && ratingPrimarySurface >= slider.failPrimaryAt) ||
    (isSeverityImages && ratingPrimarySurface >= severityImagesFailValue);

  const isSecondarySurfaceFailing =
    (isSlider && ratingSecondarySurface >= slider.failSecondaryAt) ||
    (isSeverityImages && ratingSecondarySurface >= severityImagesFailValue);

  // TAKE MACHINE OFFLINE
  const isPrimarySurfaceSliderTakingMachineOffline =
    isSlider && ratingPrimarySurface >= slider.takeMachineOfflineAt;

  const isSecondarySurfaceSliderTakingMachineOffline =
    isSlider && ratingSecondarySurface >= slider.takeMachineOfflineAt;

  const statusShouldTakeMachineOffline =
    !isSlider &&
    (status === 'Fail' ||
      isPrimarySurfaceFailing ||
      isSecondarySurfaceFailing) &&
    failStatusShouldTakeMachineOffline;

  const shouldTakeMachineOffline =
    isPrimarySurfaceSliderTakingMachineOffline ||
    isSecondarySurfaceSliderTakingMachineOffline ||
    statusShouldTakeMachineOffline;

  return {
    isPrimarySurfaceFailing,
    isPrimarySurfacePassing: !isPrimarySurfaceFailing,
    isSecondarySurfaceFailing,
    isSecondarySurfacePassing: !isSecondarySurfaceFailing,
    shouldTakeMachineOffline,
  };
};
