import React from 'react';
import { Box, Icon } from 'gantri-components';

export const MigrateIcon = () => {
  return (
    <Box
      backgroundColor="monochrome_300"
      borderRadius="50%"
      padding=".6rem"
      style={{
        alignSelf: 'center',
        display: 'inline-grid',
        justifyContent: 'center',
        justifyItems: 'center',
      }}
    >
      <Icon
        color="white"
        height="1.5rem"
        name="arrows:arrow_right"
        width="1.5rem"
      />
    </Box>
  );
};
