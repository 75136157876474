import { useRecoilState } from 'recoil';
import { Stack, Typography } from 'gantri-components';
import { InventoryChecklistStep } from '../../../../../common/inventory-checklist-step';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';

export const InventoryChecklistContent = () => {
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    completeAssemblyModalAtoms.inventoryRequests,
  );

  return (
    <Stack gap="0.8rem">
      <Typography text="Inventory" variant="h3" />
      <InventoryChecklistStep
        inventoryRequests={inventoryRequests}
        jobStep="assembly"
        setInventoryRequests={setInventoryRequests}
      />
    </Stack>
  );
};
