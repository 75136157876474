import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetPrintPrepareQueuesResponse } from './get-print-prepare-queues.types';

const query = (configs?: AxiosRequestConfig) => {
  return axios
    .get<GetPrintPrepareQueuesResponse>(
      `${coreApiUrl}/queue/print-prepare/available`,
      configs,
    )
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'get-print-prepare-queues';
};

const key = (configs?: AxiosRequestConfig) => {
  const queries = [configs];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getPrintPrepareQueues = {
  groupKey,
  key,
  query,
};
