import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  BulkCompleteBatchArgs,
  BulkCompleteBatchResponse,
} from './bulk-complete-batch.types';

export const bulkCompleteBatch = (args: BulkCompleteBatchArgs) => {
  const { batchId, returnBatch } = args;

  return axios.put<BulkCompleteBatchResponse>(
    `${coreApiUrl}/batch/${batchId}/bulk/complete`,
    {
      returnBatch,
    },
  );
};
