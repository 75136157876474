import { CollectionPage } from '../../../../../../designer.types';
import { useMoreMenu } from '../../../../../../../../hooks/use-more-menu';
import { MoreMenuCellProps } from './more-menu-cell.types';

export const MoreMenuCell = (props: MoreMenuCellProps) => {
  const { handleOnDelete, row } = props;

  const { MoreMenu } = useMoreMenu<CollectionPage>({
    data: row.original,
    options: [
      {
        enabled: true,
        name: 'Delete',
        onOptionClick: (record) => {
          handleOnDelete(record.name, record.id);
        },
      },
    ],
  });

  return <MoreMenu />;
};
