import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreatePrintPrepareQueueArgs,
  CreatePrintPrepareQueueResponse,
} from './create-print-prepare-queue.types';

export const createPrintPrepareQueue = (body: CreatePrintPrepareQueueArgs) => {
  return axios
    .post<CreatePrintPrepareQueueResponse>(
      `${coreApiUrl}/queue/print-prepare/create`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
