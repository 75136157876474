import PDFMerger from 'pdf-merger-js/browser';
import { Icon } from 'gantri-components';
import pdfMake from 'pdfmake/build/pdfmake';
import { getPackingSlipDoc } from './helpers/get-packing-slip-doc';
import { StyledIconButton } from './packing-slips-button.styles';
import { PackingSlipsButtonProps } from './packing-slip-button.types';
import { useNotification } from '../../../../../../hooks/useNotification';
import { getFileName } from './helpers/get-packing-slip-doc/helpers/get-file-name';
import { shipmentStatuses } from '../../../../../../constants/options';
import { shouldShipmentStatusShowLabelBtn } from '../order-shipment-action-button';

export const PackingSlipsButton = (props: PackingSlipsButtonProps) => {
  const { currentOrderData, labelUrls, status, stocks } = props;

  const { notifyError } = useNotification();

  const handleOnClick = async () => {
    try {
      pdfMake.fonts = {
        Sohne: {
          bold: 'https://res.cloudinary.com/gantri/raw/upload/fonts/SohneDreiviertelfett.ttf',
          normal:
            'https://res.cloudinary.com/gantri/raw/upload/fonts/SohneBuch.ttf',
        },
      };

      const isTradeOrder = currentOrderData?.type === 'Trade';

      if (isTradeOrder) {
        const docDefinition = await getPackingSlipDoc(props);

        pdfMake.createPdf(docDefinition).open();
      } else {
        const merger = new PDFMerger();

        const getBlobFromPdfData = (pdfData: pdfMake.TCreatedPdf) => {
          return new Promise<Blob>((resolve, reject) => {
            try {
              pdfData.getBlob((blob) => {
                resolve(blob);
              });
            } catch (error) {
              reject(error);
            }
          });
        };

        await Promise.all(
          stocks.map(async (stock) => {
            const docDefinition = await getPackingSlipDoc({
              ...props,
              inThisPackage: stock,
            });
            const pdfData = pdfMake.createPdf(docDefinition);
            const blob = await getBlobFromPdfData(pdfData);

            await merger.add(blob);
          }),
        );

        const mergedPdf = await merger.saveAsBlob();
        const fileName = getFileName(props);
        const url = URL.createObjectURL(mergedPdf);
        const pdfWindow = window.open(url, '_blank');

        // // add a load listener to the window so that the title gets changed on page load
        pdfWindow.addEventListener('load', () => {
          // Loading a blob overwrites the title. You have to use a setTimeout to set the title after the blob has loaded
          setTimeout(() => {
            pdfWindow.document.title = fileName;
          }, 100);
        });
      }
    } catch (error: unknown) {
      // eslint-disable-next-line no-console
      console.error(error);
      notifyError('Unable to generate packing slip.');
    }
  };

  const showLabelButton = labelUrls && shouldShipmentStatusShowLabelBtn(status);
  const isShipped = status === shipmentStatuses.shipped;
  const showPackingShipButton = showLabelButton || isShipped;

  return showPackingShipButton ? (
    <StyledIconButton
      icon={<Icon name="arrows:arrow_download" size="1.6rem" />}
      text="Packing Slip"
      variant="secondary"
      onClick={handleOnClick}
    />
  ) : null;
};
