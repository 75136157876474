import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportMachinesArgs,
  GetReportMachinesResponse,
} from './get-report-machines.types';

export const getReportMachines = (
  { rangeType, ...queries }: GetReportMachinesArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportMachinesResponse>(
    `${coreApiUrl}/reports/machines${queriesString}`,
    configs,
  );
};
