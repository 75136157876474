import PropTypes from 'prop-types';
import { Icon } from 'gantri-components';
import {
  StyledDownloadContainer,
  StyledDownloadIconContainer,
  StyledFileName,
} from './download-link-styles';

const DownloadLink = ({ fileName, url }) => {
  const openLinkURL = (newUrl) => {
    return () => {
      window.open(newUrl);
    };
  };

  return (
    <StyledDownloadContainer onClick={openLinkURL(url)}>
      <StyledDownloadIconContainer>
        <Icon color="link" name="arrows:arrow_download" />
      </StyledDownloadIconContainer>
      <StyledFileName color="link" text={fileName} variant="p2" />
    </StyledDownloadContainer>
  );
};

DownloadLink.propTypes = {
  fileName: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default DownloadLink;
