import { getTypeColumnHeading } from '../get-type-column-heading';
import { ReportProfitTableData } from '../../report-profit.types';
import {
  profitBaseTableColumns,
  profitBaseSortingOptions,
} from '../../report-profit.constants';
import { ReportDetails } from './get-report-details.types';
import { getGroupByIsVisible } from '../get-group-by-is-visible';
import { convertQueriesToString } from '../../../../../../helpers/checks';
import { ReportProfitGroupBy } from '../../../../../../api/dashboard/routes/get-report-profit/get-report-profit.types';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const heading = getTypeColumnHeading();

  const tableColumns: DownloadableTableColumn<ReportProfitTableData>[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...profitBaseTableColumns,
  ];

  const sortingOptions: SortOptionFromTable<ReportProfitTableData>[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...profitBaseSortingOptions,
  ];

  const groupByItems = [
    {
      label: 'Order Type',
      value: 'orderType',
    },
    {
      label: 'Product Name',
      value: 'productId',
    },
  ];

  const groupByOnChange = (groupBy: ReportProfitGroupBy): void => {
    const windowLocation = window.location.origin + window.location.pathname;
    const queriesString = convertQueriesToString({ groupBy });

    window.location.href = windowLocation + queriesString;
  };

  const groupByIsVisible = getGroupByIsVisible();

  return {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  };
};
