import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { DeleteIssueResponse } from './delete-issue.type';

export const deleteIssue = (id: number) => {
  return axios
    .delete<DeleteIssueResponse>(`${coreApiUrl}/issue/${id}/remove`)
    .then((response) => {
      return response.data;
    });
};
