import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { Batch } from '../../batch.types';
import { UpdateBatchLocationArgs } from './bulk-update-batch-location.types';

export const updateBatchLocation = (args: UpdateBatchLocationArgs) => {
  const { batchId, locationId, returnBatch } = args;

  return axios.put<{ batch: Batch; notice: string; success: boolean }>(
    `${coreApiUrl}/batch/${batchId}/location/${locationId}`,
    {
      returnBatch,
    },
  );
};
