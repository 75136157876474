import { Icon, Typography } from 'gantri-components';
import { environment } from '../../../../environment';
import { StyledFlex } from './dashboard-feed-downloads.styles';

const FeedItem = ({ href, text }: { href: string; text: string }) => {
  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      <Typography
        icon={<Icon color="link" name="arrows:arrow_download" />}
        text={text}
        whiteSpace="nowrap"
      />
    </a>
  );
};

export const DashboardFeedDownloads = () => {
  const googleFeedUrl = `${environment.API_URL}/feed/google.txt`;
  const facebookFeedUrl = `${environment.API_URL}/feed/facebook.csv`;

  return (
    <>
      <Typography text="Downloads" textStyle="bold" variant="h4" />
      <StyledFlex gap="2.5rem" paddingBottom="2.5rem">
        <FeedItem href={googleFeedUrl} text="Google Feed" />
        <FeedItem href={facebookFeedUrl} text="Facebook Feed" />
      </StyledFlex>
    </>
  );
};
