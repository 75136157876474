import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { MachineTasksFooter } from '../step-machine-tasks/machine-tasks-footer';
import { AssignMachineFooter } from '../step-assign-machine/assign-machine-footer';
import { StartPrintJobModalFooterProps } from './start-print-job-modal-footer.types';
import { startPrintPrintJobModalAtoms } from '../../start-print-job-modal.atoms';

export const StartPrintJobModalFooter: FC<StartPrintJobModalFooterProps> = (
  props,
) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(startPrintPrintJobModalAtoms.step);

  switch (step) {
    case 'ASSIGN_MACHINE':
      return (
        <AssignMachineFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'MACHINE_TASKS':
      return (
        <MachineTasksFooter handleCloseModal={handleCloseModal} job={job} />
      );

    default:
      return null;
  }
};
