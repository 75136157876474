import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useNotification } from '../../../../hooks/useNotification';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { updateInventory } from './update-inventory';
import { UpdateInventoryArgs } from './update-inventory.types';
import { getInventoryInfo } from '../get-inventory/get-inventory-info';

export const useUpdateInventoryMutation = (props?: {
  showLoading?: boolean;
}) => {
  const { notify, notifyAxiosError } = useNotification();
  const queryClient = useQueryClient();

  const { isLoading, onMutate } = useQueryMutation<
    UpdateInventoryArgs,
    { inventory: any }
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred while updating the inventory.',
        });
      },
      onSuccess: async (data, variables) => {
        notify(data.notice);

        // Replace optimistic inventory cache and avoid the loading indicator
        queryClient.setQueryData(getInventoryInfo.key(variables.id), (old) => {
          return omit(data, ['notice']);
        });
      },
    },
    mutationFn: (request) => {
      return updateInventory(request);
    },
    showLoading: props?.showLoading ?? true,
  });

  return { isLoading, onUpdateInventory: onMutate };
};
