import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SubmitRecommendedNextStepButton } from '../../../../../common/submit-recommended-next-step-button';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import { FinishQcNextSteps } from '../../../complete-finishing-qa-modal.types';
import { JobFailedRecommendedNextStepFooterProps } from './job-failed-recommended-next-step-footer.types';

export const JobFailedRecommendedNextStepFooter = (
  props: JobFailedRecommendedNextStepFooterProps,
) => {
  const { job } = props;

  const setStep = useSetRecoilState(finishingQcModalAtoms.step);
  const checklist = useRecoilValue(finishingQcModalAtoms.checklist);
  const setUpdateOnClose = useSetRecoilState(
    finishingQcModalAtoms.updateOnClose,
  );
  const setDuplicatedJobs = useSetRecoilState(
    finishingQcModalAtoms.duplicatedJobs,
  );
  const setNewParts = useSetRecoilState(finishingQcModalAtoms.newParts);
  const failedNextStep = useRecoilValue(finishingQcModalAtoms.failedNextStep);
  const nextStepDetails = useRecoilValue(
    finishingQcModalAtoms.failedNextStepDetails,
  );

  const handleSetStep = (selectedNextStep: FinishQcNextSteps) => {
    setStep(
      selectedNextStep === 'JOB_FAILED_RESTART'
        ? selectedNextStep
        : 'JOB_FAILED_UPDATE_BATCH',
    );
  };

  return (
    <SubmitRecommendedNextStepButton
      checklist={checklist}
      jobId={job.id}
      nextStepDetails={nextStepDetails}
      partId={job.part.id}
      selectedNextStep={failedNextStep}
      setDuplicatedJobs={setDuplicatedJobs}
      setNewParts={setNewParts}
      setStep={handleSetStep}
      setUpdateOnClose={setUpdateOnClose}
      size={fullScreenModalFooterButtonSize}
    />
  );
};
