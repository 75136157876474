import {
  Typography,
  Grid,
  Cell,
  Stack,
  Icon,
  Conditional,
} from 'gantri-components';
import { formatDate } from '../../../../../../helpers/formatter';
import { bytesToSize } from '../../../../../../helpers/file';
import Thumbnail from '../../../../../../components/common/thumbnail/thumbnail';
import { LONG_FORMAT } from '../../../../../../constants/dates';
import { StyledGridContainer, StyledMessage } from './comment.styles';
import { DesignMessage } from '../../../../../../api/designs/routes/fetch-design/fetch-design.types';

export const Comment = ({ message }: { message: DesignMessage }) => {
  const thumbnail =
    message.user.designerInfo?.photoUrl || message.user.photoUrl;

  return (
    <StyledGridContainer columns="3rem 1fr" gap="1rem">
      <Thumbnail size="3rem" src={thumbnail} />

      <Stack gap="1rem" height="unset">
        <Grid columns="max-content 1fr" gap="1rem">
          <Typography text={message.user.firstName} textStyle="bold" />

          <Typography
            color="t2"
            text={formatDate(message.createdAt, LONG_FORMAT)}
          />
        </Grid>

        <Conditional
          condition={!!message.file}
          Fallback={
            <StyledMessage isAdmin={message.isAdmin}>
              <Typography
                color={message.isAdmin ? 'alt' : 't2'}
                text={message.content}
              />
            </StyledMessage>
          }
        >
          <a href={message.file?.fileUrl} rel="noreferrer" target="_blank">
            <StyledMessage isAdmin={message.isAdmin}>
              <Grid columns="max-content 1fr" gap="12px">
                <Icon
                  color={message.isAdmin ? 'white' : 'link'}
                  name="arrows:arrow_download_24"
                  size="2.4rem"
                />
                <Cell>
                  <Typography
                    color={message.isAdmin ? 'alt' : 't2'}
                    decoration="underline"
                    text={`${message.file?.fileName} (${bytesToSize(
                      message.file?.fileSize,
                    )})`}
                  />
                </Cell>
              </Grid>
            </StyledMessage>
          </a>
        </Conditional>
      </Stack>
    </StyledGridContainer>
  );
};
