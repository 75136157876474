import PropTypes from 'prop-types';
import { Typography } from 'gantri-components';

const NetworkErrorMessage = ({ displayMessage }) => {
  return displayMessage ? (
    <>
      <Typography
        color="t1"
        text="Oops! Something went wrong :("
        textStyle="bold"
        variant="p1"
      />
      <Typography
        color="t2"
        text="Please refresh your browser to re-attempt connection to server."
        variant="p2"
      />
    </>
  ) : null;
};

NetworkErrorMessage.propTypes = {
  displayMessage: PropTypes.bool.isRequired,
};

export default NetworkErrorMessage;
