import styled from 'styled-components';
import { media } from 'gantri-components';
import { FlexContainer } from '../../../../../../components/layout';
import { createGlobalHandoutCss } from '../handout/helpers/create-global-handout-css';
import { getBackCardCss } from '../handout/components/back-card/helpers/get-back-card-css';
import { getFrontCardCss } from '../handout/components/front-card/helpers/get-front-card-css';
import { DesignerData } from '../../../../designer.types';

export const StyledFlexContainer = styled(FlexContainer)<{
  designerInfo: DesignerData;
}>`
  justify-content: center;
  ${media.greaterThan('sm')`
    justify-content: flex-start;
  `}
  overflow-x: auto;
  .handout--card-bleed-border {
    padding: 0;
  }
  ${(props) => {
    const { handout } = props.designerInfo;

    return (
      handout &&
      createGlobalHandoutCss(true) +
        getFrontCardCss(handout) +
        getBackCardCss(handout)
    );
  }}
`;

export const StyledCardWrapper = styled.div`
  padding: 15px;
  background-color: var(--colors-modal-details-background-color);
`;
