import groupBy from 'lodash/groupBy';
import { Stock } from '../../../../../../types/store';
import {
  StockStatus,
  stockStatuses,
} from '../../../../../../constants/options';

type GroupedByStatusResponse = { status: StockStatus; stocks: Stock[] }[];

export const getStocksGroupedByStatus = (
  stocks: Stock[],
): GroupedByStatusResponse => {
  const groups = groupBy(stocks, 'status');

  const sortOrder = [
    stockStatuses.inStock,
    stockStatuses.waiting,
    stockStatuses.printing,
    stockStatuses.finishing,
    stockStatuses.assembling,
    stockStatuses.qa,
    stockStatuses.packing,
    stockStatuses.completed,
  ];

  return sortOrder
    .filter((status) => {
      return !!groups[status];
    })
    .map((status) => {
      return { status, stocks: groups[status] };
    });
};
