import styled from 'styled-components';
import { palette } from 'gantri-components';

export const StyledReassignSelectorContainer = styled.div`
  color: ${palette.monochrome_600};

  select {
    min-width: 154px;
  }
`;
