import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetDashboardReportSummaryResponse,
  GetDashboardReportSummaryArgs,
} from '../../dashboard.types';

const query = (
  { endDate, rangeType, startDate }: GetDashboardReportSummaryArgs,
  configs?: AxiosRequestConfig,
) => {
  return axios
    .get<GetDashboardReportSummaryResponse>(
      `${coreApiUrl}/dashboard/orders?startDate=${startDate}&endDate=${endDate}&typeDate=${rangeType}`,
      configs,
    )
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'dashboard-orders-summary';
};

const key = (
  args: GetDashboardReportSummaryArgs,
  configs?: AxiosRequestConfig,
) => {
  const argsQueries = Object.keys(args).map((keyName) => {
    return `${keyName}=${args[keyName]}`;
  });
  const queries = [...argsQueries, configs];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getDashboardOrdersSummary = {
  groupKey,
  key,
  query,
};
