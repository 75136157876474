import { FormatAsCurrencyOptions } from './format-as-currency.types';

/**
 * Enable `isCents` in `options` to convert cents to dollars.
 *
 * Set `locale` and `currency` in `options` to render as currencies other than USD.
 *
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options */
export const formatAsCurrency = (
  value: number,
  options: FormatAsCurrencyOptions = {},
) => {
  const { isCents, locale, ...currencyOptions } = options;
  const currency = new Intl.NumberFormat(locale || 'en-US', {
    currency: 'USD',
    style: 'currency',
    ...currencyOptions,
  });

  return currency.format((value || 0) / (isCents ? 100 : 1));
};
