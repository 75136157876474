import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { bulkUpdate } from './bulk-update';
import { BulkUpdateArgs, BulkUpdateResponse } from './bulk-update.types';

export const useBulkUpdateMachines = <TransformedData = BulkUpdateResponse>(
  props?: GenericMutateQueryProps<
    BulkUpdateArgs,
    BulkUpdateResponse,
    TransformedData
  >,
) => {
  const { onMutate: onBulkUpdateMachines, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to bulk update machines.',
    mutationFn: bulkUpdate,
    ...props,
  });

  return { ...rest, onBulkUpdateMachines };
};
