import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  RemovePartFromBatchArgs,
  RemovePartFromBatchResponse,
} from './remove-part-from-batch.types';

export const removePartFromBatch = (args: RemovePartFromBatchArgs) => {
  const { batchId, partId, returnBatch } = args;

  return axios.put<RemovePartFromBatchResponse>(
    `${coreApiUrl}/batch/${batchId}/${partId}/remove`,
    { returnBatch },
  );
};
