import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledIconWrapper = styled(Grid)`
  ${({ theme }) => {
    return css`
      position: absolute;
      z-index: 1;
      top: 0.6rem;
      right: 0.6rem;
      border-radius: 2px;
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
    `;
  }}
`;

export const StyledThumbnailWrapper = styled.div`
  ${() => {
    return css`
      position: relative;
      cursor: pointer;
      border-radius: 2px;
      overflow: hidden;

      &:not(:hover) {
        ${StyledIconWrapper} {
          display: none;
        }
      }
    `;
  }}
`;

export const StyledImage = styled.img`
  object-fit: 'cover';
  float: left;
`;
