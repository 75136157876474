import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { JobReassignArgs, JobReassignResponse } from './job-reassign.types';

export const jobReassign = (args: JobReassignArgs) => {
  const { jobId, machineId } = args;
  const data = {
    jobId,
    machineId,
  };

  return axios.put<JobReassignResponse>(`${coreApiUrl}/jobs/re-assign`, data);
};
