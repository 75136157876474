import { StyledPageSection } from '../../../../components/layout/page-layout-styles';
import TimelineItem from '../../../../components/common/timeline/base-timeline-item';
import Timeline from '../../../../components/common/timeline';
import { ProfileProps } from './profile.types';
import {
  AwardsReceived,
  ProfileGeneralSection,
  SocialLink,
} from './components';

export const Profile = (props: ProfileProps) => {
  const { designerInfo, onUpdateProfile, updateDesignerInfo } = props;

  return !!designerInfo ? (
    <StyledPageSection>
      <ProfileGeneralSection
        bio={designerInfo.bio}
        city={designerInfo.city}
        country={designerInfo.country}
        headshotUrl={designerInfo.headshotUrl}
        logoUrl={designerInfo.logoUrl}
        name={designerInfo.name}
        profileLink={designerInfo.profileLink}
        quote={designerInfo.quote}
        state={designerInfo.state}
        title={designerInfo.title}
        updateDesignerInfo={updateDesignerInfo}
        userId={designerInfo.userId}
        onUpdateProfile={onUpdateProfile}
      />
      <AwardsReceived
        awards={designerInfo.awards}
        updateDesignerInfo={updateDesignerInfo}
      />
      <SocialLink
        links={designerInfo.links}
        updateDesignerInfo={updateDesignerInfo}
      />
      <Timeline items={designerInfo.timelines} renderItem={TimelineItem} />
    </StyledPageSection>
  ) : null;
};
