import { Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { DuplicatedJobsSection } from '../../../../../common/duplicated-jobs-section';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';

export const JobFailedContent = () => {
  const checklist = useRecoilValue(printQcModalAtoms.checklist);
  const duplicatedJobs = useRecoilValue(printQcModalAtoms.duplicatedJobs);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.print} ${jobTypeOptions.qc} job failed`}
      />

      <JobFailReasonsList checklist={checklist} />

      <DuplicatedJobsSection duplicatedJobs={duplicatedJobs} showTitle />
    </Stack>
  );
};
