import React, { FC, memo, useCallback, useRef } from 'react';
import { useField } from 'formik';
import {
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';
import { useSetRecoilState } from 'recoil';
import { NewOrderSection } from '../section';
import { Address } from '../../new-order.type';
import InfoWrapper from '../../../../components/info-wrapper';
import { NewOrderAddressForm } from './address-form';
import { defaultAddress } from './addresses.adapter';
import { userAtoms } from '../../../../global-atoms/users';

export const NewOrderAddressesSection: FC = memo(() => {
  const setAddresses = useSetRecoilState(userAtoms.addresses);

  const [{ value: addresses }, , addressesHelper] =
    useField<Address[]>('addresses');
  const [{ value: shippingType }] = useField<string>('shippingType');
  const [{ value: selectedAddress }, , helper] =
    useField<Address>('selectedAddress');
  const selectedAddressRef = useRef<Address | null>(null);
  const [toggleEditMode, setToggleEditMode] = useToggle(false);

  const onCancel = useCallback(() => {
    helper.setValue(selectedAddressRef.current);
    setToggleEditMode(false);
  }, [selectedAddressRef.current]);

  const onActivateEdit = (address: Address) => {
    helper.setValue(address);
    selectedAddressRef.current = { ...address };
    setToggleEditMode(true);
  };

  const onConfirm = (address: Address) => {
    selectedAddressRef.current = undefined;
    setToggleEditMode(false);

    const exist = addresses.find((item) => {
      return item.id === address.id;
    });

    const newAddresses = exist
      ? addresses.map((item) => {
          return item.id === address.id ? address : item;
        })
      : [...addresses, address];

    setAddresses(newAddresses);
    addressesHelper.setValue(newAddresses);
    helper.setValue(address);
  };

  if (shippingType === 'doNotShip') {
    return (
      <NewOrderSection title="Address">
        <Typography color="t2" text="N/A" />
      </NewOrderSection>
    );
  }

  return (
    <NewOrderSection
      action={
        <Button
          icon={<Icon color="link" name="ui-control:plus_circle" />}
          text="New Address"
          variant="secondary"
          onClick={() => {
            setToggleEditMode(true);
            helper.setValue(defaultAddress);
          }}
        />
      }
      title={
        <Flex alignItems="end" gap="x">
          <Typography text="Address" variant="h4" />
          <Typography
            color="t2"
            hidden={shippingType === 'other'}
            paddingBottom=".2rem"
            text="Required"
            variant="p3"
          />
        </Flex>
      }
    >
      <Conditional
        condition={toggleEditMode}
        Fallback={
          <Grid columns={2} gap="2x">
            {addresses.map((address) => {
              const {
                city,
                company,
                firstName,
                id: addressId,
                lastName,
                state,
                street,
                unit,
                zip,
              } = address;

              const showUnit = !unit ? null : `, ${unit}`;

              return (
                <InfoWrapper
                  key={addressId}
                  identifier={addressId.toString()}
                  selected={addressId === selectedAddress?.id}
                  onClick={() => {
                    return helper.setValue(address);
                  }}
                >
                  <Typography
                    text={[`${firstName} ${lastName}`, company]
                      .filter(Boolean)
                      .join(', ')}
                  />
                  <Typography
                    text={[street, showUnit].filter(Boolean).join(' ')}
                  />
                  <Typography text={`${city}, ${state} ${zip}`} />
                  <Button
                    text="Edit"
                    variant="ghost"
                    onClick={() => {
                      onActivateEdit(address);
                    }}
                  />
                </InfoWrapper>
              );
            })}
          </Grid>
        }
      >
        <NewOrderAddressForm onCancel={onCancel} onConfirm={onConfirm} />
      </Conditional>
    </NewOrderSection>
  );
});
