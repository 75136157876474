import { useModal } from 'gantri-components';
import { useCallback } from 'react';
import pick from 'lodash/pick';
import { EditInventoryModal } from './edit-inventory-modal';
import { EditInventoryFormState } from './edit-inventory.types';
import { PaginatedInventory } from '../../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { useUpdateInventoryMutation } from '../../../../api/inventories/routes/update-inventory';

export const useEditInventoryModal = (props: {
  inventory: PaginatedInventory;
  onSuccess: (inventory: PaginatedInventory) => void;
}) => {
  const { onUpdateInventory } = useUpdateInventoryMutation();

  const onSubmit = useCallback(
    (values: EditInventoryFormState) => {
      const data = {
        id: props.inventory.id,
        source: 'inventories-table',
        ...values,
      };

      onUpdateInventory(data, {
        onSuccess: (response) => {
          props?.onSuccess?.(response.inventory);
          hideModal();
        },
      });
    },
    [props?.inventory?.id],
  );

  const [showModal, hideModal] = useModal(() => {
    return (
      <EditInventoryModal
        initialValues={
          pick(props.inventory || {}, [
            'description',
            'unit',
            'name',
          ]) as EditInventoryFormState
        }
        onCancel={hideModal}
        onSubmit={onSubmit}
      />
    );
  }, [props.inventory]);

  return [showModal, hideModal];
};
