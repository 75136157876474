export const baseUserFilterData = {
  isAdmin: false,
  isDesigner: false,
  isUser: false,
  isWorker: false,
};

export const userRoles = {
  admin: 'Admin',
  lead: 'Lead',
  user: 'User',
  worker: 'Worker',
} as const;

export type UserRole = typeof userRoles[keyof typeof userRoles];

export const userAccessDeniedMessage =
  'You do not have permission for this action';
