import { Typography, Conditional } from 'gantri-components';
import { SectionItemProps } from './section-item.types';
import { StyledSectionItem } from './section-item.styles';

export const SectionItem = (props: SectionItemProps) => {
  const { children, condition = true, title } = props;

  return (
    <Conditional condition={condition}>
      <StyledSectionItem
        columns="15rem 1fr"
        gap="2x"
        horizontalPadding="0"
        verticalPadding="1rem"
      >
        <Typography text={title} textStyle="bold" />
        {children}
      </StyledSectionItem>
    </Conditional>
  );
};
