import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultOrdersGroupBy } from '../../report-orders.constants';
import { ReportOrderQueries } from '../../report-orders.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Orders';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportOrderQueries>();
  const { groupBy = defaultOrdersGroupBy, orderType } = queries;

  if (/^orderType$/i.test(groupBy) && orderType) {
    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy }),
    };

    return [rootBreadcrumb];
  }

  return [];
};
