import styled from 'styled-components';

export const StyledTileInfo = styled.ul`
  margin-bottom: 1.6rem;

  li {
    list-style: disc;
    margin-left: 2.5rem;

    * {
      display: inline;
    }
  }
`;
