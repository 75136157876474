import { FC } from 'react';
import { Cell, Grid, Icon, Typography } from 'gantri-components';
import {
  StyledOrderTransactionContainer,
  StyledShipmentHeaderContainer,
  StyledShipmentHeaderItemsWrapper,
} from '../order-shipment/order-shipment.styles';
import SubsectionTitle from '../../../../components/layout/subsection-title';
import {
  formatAsCurrency,
  formatDateWithoutTimezone,
} from '../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../constants/dates';
import {
  StyledCard,
  StyledGiftCardContentContainer,
  StyledGiftCardOrderImageContainer,
  StyledGiftCardRow,
  StyledMessageTypography,
  StyledRecipientTypography,
  StyledRightAlignedBlock,
  StyledRowContent,
  StyledSpecsContainer,
} from './order-gift-card.styles';
import { OrderGiftCardProps } from './order-gift-card.types';

export const OrderGiftCard: FC<OrderGiftCardProps> = ({ giftCard }) => {
  return (
    <StyledOrderTransactionContainer>
      <SubsectionTitle>
        <Grid gap="0.2rem" marginBottom="4x" width="100%">
          <Typography
            text={`Send on ${formatDateWithoutTimezone(
              giftCard.sendDateStr,
              MEDIUM_FORMAT,
            )}`}
            variant="h4"
          />

          <Typography
            color="link"
            icon={<Icon color="link" name="payment:gift_card" />}
            text="Digital Gift Card"
          />
        </Grid>
      </SubsectionTitle>
      <StyledShipmentHeaderContainer>
        <StyledShipmentHeaderItemsWrapper>
          <Grid columnGap="2x" columns=" 1fr max-content" rowGap="unset">
            <Typography text="Delivered on" />
            <Typography
              align="end"
              color="t2"
              text={
                giftCard.status === 'Redeemed' ? giftCard.sendDateStr : 'TBD'
              }
            />

            <Typography text="Delivering on" />
            <Typography align="end" color="t1" text={giftCard.sendDateStr} />

            <Typography text="Est. delivery by" />
            <Typography align="end" color="t1" text="N/A" />

            <Typography text="Ship by" />
            <Typography align="end" color="t1" text="N/A" />

            <Typography text="Shipped on" />
            <Typography align="end" color="t1" text="N/A" />
          </Grid>

          <Grid
            alignContent="start"
            columnGap="2x"
            columns="1fr max-content"
            rowGap="unset"
          >
            <Typography text="Status" />
            <Typography align="end" color="t1" text={giftCard.status} />

            <Typography text="Tracking" />
            <Typography align="end" color="t1" text="N/A" />

            <Typography text="Cost" />
            <Typography align="end" color="t1" text="N/A" />
          </Grid>
        </StyledShipmentHeaderItemsWrapper>
      </StyledShipmentHeaderContainer>

      <StyledGiftCardContentContainer>
        <Grid gap="unset" padding="unset">
          <StyledGiftCardRow>
            <StyledGiftCardOrderImageContainer>
              <StyledCard photo={giftCard.design.photo} />
            </StyledGiftCardOrderImageContainer>

            <StyledRowContent>
              <StyledSpecsContainer>
                <Typography text="Digital Gift Card" />
                <Typography color="t2" text={giftCard.design.designer.name} />
                <Typography
                  color="t2"
                  text={formatAsCurrency(giftCard.amount, { isCents: true })}
                />
              </StyledSpecsContainer>

              <StyledRightAlignedBlock>
                <Typography color="t1" text={giftCard.status} />
              </StyledRightAlignedBlock>

              <Cell width={2}>
                <Grid
                  columns="max-content minmax(0, 1fr)"
                  marginTop="3x"
                  padding="unset"
                  rowGap="unset"
                >
                  <Cell>
                    <Typography color="t1" text="Sender" />
                  </Cell>
                  <Cell>
                    <Typography text={giftCard.senderName} />
                  </Cell>

                  <Cell>
                    <Typography color="t1" text="Recipient" />
                  </Cell>

                  <StyledRecipientTypography
                    htmlText={`${giftCard.recipientName}, <span>${giftCard.recipientEmail}</span>`}
                  />

                  <Cell>
                    <Typography color="t1" text="Message" />
                  </Cell>
                  <Cell>
                    <StyledMessageTypography text={giftCard.message} />
                  </Cell>
                </Grid>
              </Cell>
            </StyledRowContent>
          </StyledGiftCardRow>
        </Grid>
      </StyledGiftCardContentContainer>
    </StyledOrderTransactionContainer>
  );
};
