import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { inventoryPurchasesFiltersDefaults } from './inventory-purchases-filter.constants';
import { InventoryPurchasesSortingField } from './inventory-purchases-filter.types';

const atomKeyPrefix = 'INVENTORY_PURCHASES_FILTERS';

export const pageName: AtomPageName = 'inventory-purchases';

export const inventoryPurchasesPageAtoms = {
  defaults: inventoryPurchasesFiltersDefaults,
  filters: {
    completedDateRange: getGenericAtomFamily({
      defaultValue:
        inventoryPurchasesFiltersDefaults.filters.completedDateRange,
      key: `${atomKeyPrefix}-completed-date-range`,
    })(pageName),
    country: getGenericAtomFamily({
      defaultValue: inventoryPurchasesFiltersDefaults.filters.country,
      key: `${atomKeyPrefix}-country`,
    })(pageName),
    createdDateRange: getGenericAtomFamily({
      defaultValue: inventoryPurchasesFiltersDefaults.filters.createdDateRange,
      key: `${atomKeyPrefix}-created-date-range`,
    })(pageName),
    hasDiscardedShipment: getGenericAtomFamily({
      defaultValue:
        inventoryPurchasesFiltersDefaults.filters.hasDiscardedShipment,
      key: `${atomKeyPrefix}-has-discarded-shipment`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: inventoryPurchasesFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<InventoryPurchasesSortingField>>({
    defaultValue: inventoryPurchasesFiltersDefaults.sortBy,
  })(pageName),
};
