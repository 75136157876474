import { StockInventoriesProps } from '../stock-inventories.types';
import { transformFetchAllInventories } from './stock-inventories.adapter';
import { FetchAllInventoriesResponse } from '../../../../../api/inventories/routes/fetch-all/fetch-all.types';
import { useFetchAllInventoriesForStockQuery } from '../../../../../api/inventories/routes/fetch-all-for-stock';
import { InventoryRowData } from './stock-inventories-table.types';

export const useStockInventoriesResume = (props: StockInventoriesProps) => {
  const { inventories: data, isLoading } = useFetchAllInventoriesForStockQuery<{
    rows: InventoryRowData[];
    totalCost: string;
  }>({
    enabled: props.inventories?.length > 0,
    inventoryIds: props.inventories?.map((inventory) => {
      return inventory.id;
    }),
    transform: (response: FetchAllInventoriesResponse) => {
      return transformFetchAllInventories(
        props.inventories,
        response.inventories,
      );
    },
  });

  return {
    inventories: data?.rows ?? [],
    isLoading,
    totalCost: data?.totalCost ?? '0',
  };
};
