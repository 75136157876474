import { Typography } from 'gantri-components';
import {
  formatAsCurrency,
  formatAsLocaleNumber,
} from '../../../../../../helpers/formatter';
import { StyledCell, StyledGrid } from './dashboard-summary.styles';
import {
  GridCellProps,
  DashboardSummaryProps,
} from './dashboard-summary.types';

const GridCell = ({ subTitle, title }: GridCellProps) => {
  return (
    <StyledCell>
      <Typography align="center" text={title} variant="h4" />
      <Typography align="center" text={subTitle} variant="p3" />
    </StyledCell>
  );
};

export const DashboardSummary = ({ dashboardInfo }: DashboardSummaryProps) => {
  return (
    <StyledGrid
      columns={{ lg: 4, md: 2 }}
      gap="0"
      paddingBottom="3.2rem"
      paddingTop="1.6rem"
    >
      {dashboardInfo && (
        <>
          <GridCell
            subTitle="Total Sales Excl. Tax $"
            title={formatAsCurrency(dashboardInfo.sales, { isCents: true })}
          />
          <GridCell
            subTitle="Total # Orders"
            title={formatAsLocaleNumber(dashboardInfo.orders)}
          />
          <GridCell
            subTitle="Total # Refunds"
            title={formatAsLocaleNumber(dashboardInfo.refunds)}
          />
          <GridCell
            subTitle="Total # Stocks Completed"
            title={formatAsLocaleNumber(dashboardInfo.stocks)}
          />
        </>
      )}
    </StyledGrid>
  );
};
