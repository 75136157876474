import { Fragment, memo } from 'react';
import { TextArea, Typography, Grid, Cell } from 'gantri-components';
import FlexContainer from '../../../../../../components/layout/flex-container';
import { Qa } from '../../../../../../types/store';
import { QaSectionProps } from './qa-section.types';
import { StyledQaSection } from './qa-section.styles';

const Section = (props: QaSectionProps) => {
  const { QAs, updateDesignerInfo } = props;

  const addQa = () => {
    updateDesignerInfo({
      key: 'QAs',
      type: 'key',
      value: [...QAs, { qaAnswer: '', qaQuestion: '' }],
    });
  };

  const updateQaProperty = (property: keyof Qa) => {
    return (value: string, index: number) => {
      if (QAs[index][property] !== value) {
        updateDesignerInfo({
          key: 'QAs',
          type: 'key',
          value: QAs.map((qa: Qa, i: number) => {
            return index === i ? { ...qa, [property]: value } : qa;
          }),
        });
      }
    };
  };

  const removeQa = (position: number) => {
    updateDesignerInfo({
      key: 'QAs',
      type: 'key',
      value: QAs.filter((_, index: number) => {
        return index !== position;
      }),
    });
  };

  return (
    <StyledQaSection>
      <FlexContainer marginBottom="2rem">
        <Typography text="Q&A" textStyle="bold" variant="h4" />
        <Typography
          align="right"
          color="link"
          decoration="underline"
          marginLeft="1.5rem"
          marginTop="8px"
          style={{
            cursor: 'pointer',
          }}
          text="Add a question"
          onClick={addQa}
        />
      </FlexContainer>

      <Grid columns={2} gap="2rem">
        {QAs?.map((qa: Qa, index: number) => {
          return (
            <Fragment key={index}>
              <Cell>
                <Typography
                  color="t2"
                  marginBottom="8px"
                  text={`Question #${index + 1}`}
                  variant="h5"
                />
                <Typography
                  marginBottom="8px"
                  text="Question"
                  textStyle="bold"
                />
                <TextArea
                  minRows={1}
                  placeholder="Information about the process...."
                  value={qa.qaQuestion}
                  onTextChange={(value) => {
                    return updateQaProperty('qaQuestion')(value, index);
                  }}
                />
              </Cell>
              <Cell>
                <Typography
                  align="right"
                  color="alert"
                  decoration="underline"
                  marginBottom="8px"
                  style={{ cursor: 'pointer' }}
                  text="Remove"
                  onClick={() => {
                    return removeQa(index);
                  }}
                />
                <Typography marginBottom="8px" text="Answer" textStyle="bold" />
                <TextArea
                  minRows={1}
                  placeholder="Information about the process...."
                  value={qa.qaAnswer}
                  onTextChange={(value) => {
                    return updateQaProperty('qaAnswer')(value, index);
                  }}
                />
              </Cell>
            </Fragment>
          );
        })}
      </Grid>
    </StyledQaSection>
  );
};

export const QaSection = memo(Section);
