import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledToolWrapper = styled(Grid)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1Alt};
    `;
  }}
`;
