import { Grid, Icon, Typography } from 'gantri-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import { Issue } from '../../../machines/machines.types';
import { IssueTypeProps } from './issue-difficulty.types';

export const IssueDifficulty = (props: IssueTypeProps) => {
  const { iconOnly, isMultipleIssues, issue } = props;

  const { difficulty } = issue;
  const difficultyValue = difficulty?.toLowerCase() as Lowercase<
    Issue['difficulty']
  >;
  const iconName: IconProps['name'] = isMultipleIssues
    ? 'alert:i_circle'
    : difficultyValue
    ? `machine:difficulty_${difficultyValue}`
    : undefined;

  return (
    <Grid
      alignItems="center"
      columns={iconOnly ? 'max-content' : 'max-content 1fr'}
      gap="0.6rem"
    >
      {iconName ? (
        <Icon
          color={isMultipleIssues ? 't1' : 'link'}
          name={iconName}
          size="1.6rem"
        />
      ) : (
        <span />
      )}
      {!iconOnly && <Typography color="link" text={difficulty} />}
    </Grid>
  );
};
