import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchPaginatedArgs,
  FetchPaginatedResponse,
} from './fetch-paginated.types';
import { fetchPaginatedDownloadsQuery } from './fetch-paginated';

const fetchPaginatedDownloadsQueriesObj = getGenericQueryObj({
  groupName: 'fetch-paginated-downloads',
  query: fetchPaginatedDownloadsQuery,
});

export const useFetchPaginatedDownloads = <
  TransformedData = FetchPaginatedResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedArgs,
    FetchPaginatedResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch downloads.',
    queryObj: fetchPaginatedDownloadsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedDownloadsCache = () => {
  const invalidateFetchPaginatedDownloadsCache = useGetInvalidateQueryCache(
    fetchPaginatedDownloadsQueriesObj,
  );

  return { invalidateFetchPaginatedDownloadsCache };
};
