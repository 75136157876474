import PropTypes from 'prop-types';
import { Button, palette, Typography } from 'gantri-components';
import StackedContainer from '../../common/stacked-container';
import {
  StyledModalButtonsContainer,
  StyledModalWrapper,
  StyledOuterContainer,
} from '../modals-styles.module';
import SubsectionTitle from '../../layout/subsection-title';
import { StyledDivider } from '../../../assets/styles/common';

const ConfirmationModal = ({
  buttonHorizontalPadding,
  cancelButtonArchetype,
  cancelButtonText,
  children,
  classnames,
  confirmButtonArchetype,
  confirmButtonText,
  confirmDisabled,
  description,
  divider,
  footer,
  horizontalPadding,
  loading,
  marginOverride,
  onCancel,
  onConfirm,
  text,
  verticalPadding,
  width,
}) => {
  const handleConfirmClick = (e) => {
    return onConfirm(e);
  };

  const handleCancelClick = (e) => {
    return onCancel(e);
  };

  return (
    <StyledOuterContainer>
      <StyledModalWrapper
        className={classnames}
        horizontalPadding={horizontalPadding}
        verticalPadding={verticalPadding}
        width={width}
      >
        <SubsectionTitle>
          <StackedContainer gap="10px">
            <Typography
              align="center"
              text={text}
              textStyle="bold"
              variant="h4"
            />
            {description && (
              <Typography align="center" color="t2" text={description} />
            )}
          </StackedContainer>
        </SubsectionTitle>
        <div>{children}</div>
        {divider && <StyledDivider color={palette.monochrome_200} />}
        <StyledModalButtonsContainer
          buttonHorizontalPadding={buttonHorizontalPadding}
          marginOverride={marginOverride}
        >
          <Button
            text={cancelButtonText}
            variant={cancelButtonArchetype}
            onClick={handleCancelClick}
          />
          <Button
            disabled={confirmDisabled}
            processing={loading}
            text={confirmButtonText}
            variant={confirmButtonArchetype}
            onClick={handleConfirmClick}
          />
        </StyledModalButtonsContainer>

        {footer}
      </StyledModalWrapper>
    </StyledOuterContainer>
  );
};

ConfirmationModal.defaultProps = {
  cancelButtonArchetype: 'secondary',
  cancelButtonText: 'Cancel',
  confirmButtonArchetype: 'primary',
  confirmButtonText: 'Confirm',
  footer: null,
  loading: false,
};

ConfirmationModal.propTypes = {
  cancelButtonArchetype: PropTypes.string,
  cancelButtonText: PropTypes.string,
  classnames: PropTypes.string,
  confirmButtonArchetype: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmDisabled: PropTypes.bool,
  description: PropTypes.string,
  footer: PropTypes.element,
  loading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.string,
};

export default ConfirmationModal;
