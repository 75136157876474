import { useTheme } from 'styled-components';
import { IconOverlayToolProps } from './icon-overlay-tool.types';

export const IconOverlayTool = (props: IconOverlayToolProps) => {
  const theme = useTheme();
  const defaultColor = theme.colors.iconography.t2;
  const { color = defaultColor } = props;

  return (
    <svg
      fill="none"
      height="33"
      viewBox="0 0 32 33"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M26.4036 1.83174L1.14329 6.28581L5.59736 31.5461L30.8577 27.0921L26.4036 1.83174ZM0.361328 5.73828L5.04983 32.3281L31.6396 27.6396L26.9511 1.04978L0.361328 5.73828Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M10.8966 24.6715C12.5301 24.3835 13.6464 22.774 13.341 21.0419C13.0355 19.3098 11.4361 18.1792 9.80262 18.4672C8.1691 18.7552 7.05284 20.3647 7.35825 22.0968C7.66367 23.8289 9.26309 24.9595 10.8966 24.6715ZM11.0138 25.3362C13.033 24.9802 14.3725 23.0051 14.0057 20.9247C13.6389 18.8443 11.7046 17.4464 9.6854 17.8025C7.6662 18.1585 6.32668 20.1336 6.69351 22.214C7.06034 24.2944 8.99461 25.6923 11.0138 25.3362Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        d="M6.77327 11.0049C6.81643 11.2496 6.653 11.483 6.40825 11.5262C6.1635 11.5693 5.9301 11.4059 5.88695 11.1612C5.84379 10.9164 6.00722 10.683 6.25197 10.6398C6.49672 10.5967 6.73012 10.7601 6.77327 11.0049Z"
        fill={color}
      />
      <path
        d="M9.87542 10.4579C9.91857 10.7026 9.75515 10.936 9.5104 10.9792C9.26564 11.0223 9.03225 10.8589 8.98909 10.6142C8.94593 10.3694 9.10936 10.136 9.35411 10.0929C9.59887 10.0497 9.83226 10.2131 9.87542 10.4579Z"
        fill={color}
      />
      <path
        d="M13.8639 9.7546C13.9286 10.1217 13.6835 10.4718 13.3164 10.5366C12.9492 10.6013 12.5991 10.3562 12.5344 9.98903C12.4697 9.6219 12.7148 9.2718 13.0819 9.20707C13.4491 9.14234 13.7992 9.38747 13.8639 9.7546Z"
        fill={color}
      />
      <path
        d="M18.2955 8.97319C18.3818 9.46269 18.055 9.92948 17.5655 10.0158C17.076 10.1021 16.6092 9.77526 16.5229 9.28575C16.4366 8.79625 16.7634 8.32946 17.2529 8.24314C17.7424 8.15683 18.2092 8.48368 18.2955 8.97319Z"
        fill={color}
      />
      <path
        d="M23.6135 8.03549C23.743 8.76974 23.2527 9.46993 22.5184 9.5994C21.7842 9.72887 21.084 9.23859 20.9545 8.50434C20.825 7.77008 21.3153 7.06989 22.0496 6.94042C22.7838 6.81095 23.484 7.30123 23.6135 8.03549Z"
        fill={color}
      />
      <path
        clipRule="evenodd"
        d="M25.0084 14.4152L16.1451 15.978L17.708 24.8413L26.5712 23.2785L25.0084 14.4152ZM15.3632 15.4305L17.1604 25.6232L27.3532 23.826L25.5559 13.6332L15.3632 15.4305Z"
        fill={color}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M26.4036 1.83174L1.14329 6.28581L5.59736 31.5461L30.8577 27.0921L26.4036 1.83174ZM0.361328 5.73828L5.04983 32.3281L31.6396 27.6396L26.9511 1.04978L0.361328 5.73828Z"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        clipRule="evenodd"
        d="M10.8966 24.6715C12.5301 24.3835 13.6464 22.774 13.341 21.0419C13.0355 19.3098 11.4361 18.1792 9.80262 18.4672C8.1691 18.7552 7.05284 20.3647 7.35825 22.0968C7.66367 23.8289 9.26309 24.9595 10.8966 24.6715ZM11.0138 25.3362C13.033 24.9802 14.3725 23.0051 14.0057 20.9247C13.6389 18.8443 11.7046 17.4464 9.6854 17.8025C7.6662 18.1585 6.32668 20.1336 6.69351 22.214C7.06034 24.2944 8.99461 25.6923 11.0138 25.3362Z"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M6.77327 11.0049C6.81643 11.2496 6.653 11.483 6.40825 11.5262C6.1635 11.5693 5.9301 11.4059 5.88695 11.1612C5.84379 10.9164 6.00722 10.683 6.25197 10.6398C6.49672 10.5967 6.73012 10.7601 6.77327 11.0049Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M9.87542 10.4579C9.91857 10.7026 9.75515 10.936 9.5104 10.9792C9.26564 11.0223 9.03225 10.8589 8.98909 10.6142C8.94593 10.3694 9.10936 10.136 9.35411 10.0929C9.59887 10.0497 9.83226 10.2131 9.87542 10.4579Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M13.8639 9.7546C13.9286 10.1217 13.6835 10.4718 13.3164 10.5366C12.9492 10.6013 12.5991 10.3562 12.5344 9.98903C12.4697 9.6219 12.7148 9.2718 13.0819 9.20707C13.4491 9.14234 13.7992 9.38747 13.8639 9.7546Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M18.2955 8.97319C18.3818 9.46269 18.055 9.92948 17.5655 10.0158C17.076 10.1021 16.6092 9.77526 16.5229 9.28575C16.4366 8.79625 16.7634 8.32946 17.2529 8.24314C17.7424 8.15683 18.2092 8.48368 18.2955 8.97319Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M23.6135 8.03549C23.743 8.76974 23.2527 9.46993 22.5184 9.5994C21.7842 9.72887 21.084 9.23859 20.9545 8.50434C20.825 7.77008 21.3153 7.06989 22.0496 6.94042C22.7838 6.81095 23.484 7.30123 23.6135 8.03549Z"
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        clipRule="evenodd"
        d="M25.0084 14.4152L16.1451 15.978L17.708 24.8413L26.5712 23.2785L25.0084 14.4152ZM15.3632 15.4305L17.1604 25.6232L27.3532 23.826L25.5559 13.6332L15.3632 15.4305Z"
        fillRule="evenodd"
        stroke={color}
        strokeWidth="0.2"
      />
    </svg>
  );
};
