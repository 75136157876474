import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { DesignerStatsDate } from '../../../../../../api/designers/routes/get-designer-stats/get-designer-stats.types';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import { UseGetDesignerStatsColumnsProps } from './use-get-designer-stats-columns.types';

export const useGetDesignerStatsColumns = (
  props: UseGetDesignerStatsColumnsProps,
): ColumnDef<DesignerStatsDate>[] => {
  const { lifetimeStats } = props;

  return [
    {
      accessorKey: 'date',
      footer: 'Lifetime',
      header: 'Period',
    },
    {
      accessorKey: 'designs',
      footer: String(lifetimeStats?.designs || '-'),
      header: 'Designs',
    },
    {
      accessorKey: 'products',
      footer: String(lifetimeStats?.products || '-'),
      header: 'Products',
    },
    {
      accessorKey: 'sales',
      cell: ({
        getValue,
      }: CellContext<DesignerStatsDate, DesignerStatsDate['sales']>) => {
        return (
          <Typography text={formatAsCurrency(getValue(), { isCents: true })} />
        );
      },
      footer: formatAsCurrency(lifetimeStats?.sales, { isCents: true }),
      header: 'Sales',
    },
    {
      accessorKey: 'totalUnits',
      footer: String(lifetimeStats?.totalUnits || '-'),
      header: '% of Total Sales',
    },
  ];
};
