import { Conditional, Stack, Table, Typography } from 'gantri-components';
import { duplicatedJobsColumns } from './duplicated-jobs-section.constants';
import { DuplicatedJobsSectionProps } from './duplicated-jobs-section.types';

export const DuplicatedJobsSection = (props: DuplicatedJobsSectionProps) => {
  const { duplicatedJobs, showTitle } = props;

  const DuplicatedJobsTable = () => {
    return <Table columns={duplicatedJobsColumns} data={duplicatedJobs} />;
  };

  return (
    <Conditional condition={!!duplicatedJobs.length}>
      <Conditional condition={showTitle} Fallback={<DuplicatedJobsTable />}>
        <Stack gap="x">
          <Typography text="Duplicated jobs" variant="h5" />
          <DuplicatedJobsTable />
        </Stack>
      </Conditional>
    </Conditional>
  );
};
