import {
  GetJobTemplatesArgs,
  GetJobTemplatesResponse,
} from './fetch-product-part-templates.types';
import { fetchProductPartTemplates } from './fetch-product-part-templates';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';

export const useFetchProductPartTemplates = <
  TransformedData = GetJobTemplatesResponse,
>(
  props?: GenericMutateQueryProps<
    GetJobTemplatesArgs,
    GetJobTemplatesResponse,
    TransformedData
  >,
) => {
  const { onMutate: onFetchProductPartTemplates, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to fetch product part template.',
      mutationFn: fetchProductPartTemplates,
      ...props,
    });

  return { ...rest, onFetchProductPartTemplates };
};
