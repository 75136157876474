export const productLightCategories = {
  clampLight: 'Clamp Light',
  floorLight: 'Floor Light',
  pendantLight: 'Pendant Light',
  tableLight: 'Table Light',
  wallLight: 'Wall Light',
  wallSconce: 'Wall Sconce',
} as const;

export const productCategories = {
  ...productLightCategories,
  accessory: 'Accessory',
  giftCard: 'Gift Card',
} as const;

export const productLightSubCategories = {
  accent: 'Accent',
  area: 'Area',
  task: 'Task',
} as const;

export const productAccessoriesSubCategories = {
  bulb: 'Bulb',
  hardware: 'Hardware',
  swag: 'Swag',
} as const;

export const productSubCategories = {
  ...productLightSubCategories,
  ...productAccessoriesSubCategories,
};

export const partPaintedStatuses = {
  painted: 'Painted',
  unpainted: 'Unpainted',
} as const;

export const productLevelCloudinaryFileTypes = {
  lifestylePhotos: 'lifestyle-photos',
  videos: 'videos',
} as const;

export const skuLevelCloudinaryFileTypes = {
  dimmingPhotoDark: 'dimming-photos',
  dimmingPhotoLight: 'dimming-photos',
  /** @deprecated */
  lifestylePhotosSku: 'lifestyle-photos-sku',
  scalePhoto: 'scale-photo',
  /** @deprecated */
  videosSku: 'videos-sku',
  whiteBackgroundPhotos: 'product-photos',
} as const;
