import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { CompleteSandJobFooterProps } from './complete-sand-job-footer.types';
import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { completeSandJobModalAtoms } from '../../complete-sand-job-modal.atoms';
import { CompleteChecklistFooter } from '../step-complete-checklist/complete-checklist-footer';
import { RecommendedNextStepFooter } from '../step-recommended-next-step/recommended-next-step-footer';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';

export const CompleteSandJobFooter = (props: CompleteSandJobFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(completeSandJobModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return (
        <ConfirmBeginFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'COMPLETE_CHECKLIST':
      return (
        <CompleteChecklistFooter
          handleCloseModal={handleCloseModal}
          job={job}
        />
      );

    case 'RECOMMENDED_NEXT_STEP':
      return <RecommendedNextStepFooter job={job} />;

    case 'JOB_FAILED_RESTART':
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={async () => {
            await handleCloseModal();
          }}
        />
      );

    default:
      return null;
  }
};
