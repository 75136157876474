import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  ActivateDesignerArgs,
  ActivateDesignerResponse,
} from './activate.types';

export const activate = ({ isActive, userId }: ActivateDesignerArgs) => {
  return axios.put<ActivateDesignerResponse>(`${coreApiUrl}/designers/active`, {
    active: isActive,
    userId,
  });
};
