import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { GetTransactionArgs } from './get-transaction.types';
import { transformLegacyShippingMethod } from '../../../../constants/options';

export const getTransaction = (args: GetTransactionArgs) => {
  const { id } = args;

  return axios
    .get<TransactionResponse>(`${baseApiUrl}/transactions/${id}`)
    .then((response) => {
      response.data.order.shipments = response.data.order.shipments.map(
        (shipment) => {
          if (
            !['cheapest', 'fastest', 'threedays'].includes(
              shipment.shippingOptions.type,
            )
          ) {
            return shipment;
          }

          const method = transformLegacyShippingMethod(
            shipment.shippingOptions.type as any,
            response.data.order.address?.country,
          );

          return {
            ...shipment,
            shippingOptions: {
              cost: shipment.shippingOptions.cost,
              option: method.label,
              provider: method.label,
              type: method.shippingType,
            },
          };
        },
      );

      return response;
    });
};
