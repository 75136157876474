import { CloudinaryTransformations } from '../generate-transformations-string/generate-transformations-string.types';
import { generateTransformationsString } from '../generate-transformations-string';
import { dynamicFolder } from '../../../../api/cloudinary/cloudinary.constants';

const firebaseUriBase = 'https://firebasestorage.googleapis.com/v0/b/';

export const cloudinaryUriBase = 'https://res.cloudinary.com/gantri/';

// DEVELOP
export const firebaseDevelopUriBase = `${firebaseUriBase}gantri-development.appspot.com/o/`;

export const cloudinaryDevelopFolder = `${dynamicFolder}/develop/`;

// STAGING
export const firebaseStagingUriBase = `${firebaseUriBase}gantri-staging.appspot.com/o/`;

export const cloudinaryStagingFolder = `${dynamicFolder}/staging/`;

// PROD
export const firebaseProdUriBase = `${firebaseUriBase}gantri-508ea.appspot.com/o/`;

export const cloudinaryProdFolder = `${dynamicFolder}/gantri/`;

const getFirebaseEnv = (src: string) => {
  const developPattern = new RegExp(`^${firebaseDevelopUriBase}`, 'i');
  const stagingPattern = new RegExp(`^${firebaseStagingUriBase}`, 'i');
  const prodPattern = new RegExp(`^${firebaseProdUriBase}`, 'i');

  return {
    isDevelop: developPattern.test(src),
    isProd: prodPattern.test(src),
    isStaging: stagingPattern.test(src),
  };
};

export const cloudinaryUrl = (
  source: string,
  transformations?: CloudinaryTransformations,
): string => {
  const src: string = source;
  // USED IN DEVELOPMENT TO MAP FIREBASE URLS TO CLOUDINARY
  // KEPT IN CASE WORKFLOW IS NEEDED IN THE FUTURE
  // const firebasePattern = new RegExp(`^${firebaseUriBase}`, "i");
  // const isFirebase = firebasePattern.test(src);
  // if (isFirebase) {
  //   // Convert to cloudinary
  //   const cloudinaryImageUriBase = `${cloudinaryUriBase}image/upload/`;
  //   // const env = getFirebaseEnv(src);
  //   // if (env.isDevelop) {
  //   // src = src.replace(
  //   // firebaseDevelopUriBase,
  //   // cloudinaryImageUriBase + cloudinaryDevelopFolder
  //   // );
  //   // } else if (env.isStaging) {
  //   // src = src.replace(
  //   // firebaseStagingUriBase,
  //   // cloudinaryImageUriBase + cloudinaryStagingFolder
  //   // );
  //   // } else {
  //   // src = src.replace(
  //   // firebaseProdUriBase,
  //   // cloudinaryImageUriBase + cloudinaryProdFolder
  //   // );
  //   // }

  //   // added to unblock dynamic images work
  //   src = src.replace(
  //     /.+\.appspot\.com\/o\//,
  //     cloudinaryImageUriBase + cloudinaryProdFolder
  //   );

  //   src = decodeURIComponent(src);
  //   src = encodeURI(src);
  // }
  const cloudinaryPattern = new RegExp(`^${cloudinaryUriBase}`, 'i');
  const isCloudinary = cloudinaryPattern.test(src);

  if (isCloudinary) {
    const uriBasePattern = /^.+upload\//i;
    const [uriBase] = src.match(uriBasePattern);
    const imagePath = src.split(uriBasePattern)[1];
    const transformationString = generateTransformationsString(transformations);

    return uriBase + transformationString + imagePath;
  }

  return src;
};
