import isEmpty from 'lodash/isEmpty';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { getMachineIssues } from './get-issues';
import { GetIssuesArgs, GetIssuesResponse } from './get-issues.types';

export const useGetMachineIssuesQuery = (props: GetIssuesArgs) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<GetIssuesResponse>({
    config: {
      enabled: !!props && !!props?.machineId,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred fetching machine issues.',
        });
      },
      queryFn: () => {
        return getMachineIssues.query(props);
      },
      queryKey: getMachineIssues.key(props),
    },
    showLoading: true,
  });

  return { isLoading, response: data };
};

export const useInvalidateGetMachineIssuesCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateCache = async (argsToInvalidate?: GetIssuesArgs) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      const { machineId, ...paginateParams } = argsToInvalidate ?? {};

      return !isEmpty(paginateParams)
        ? key === getMachineIssues.key(argsToInvalidate)[0].key
        : groupKey === getMachineIssues.groupKey(machineId);
    });
  };

  return invalidateCache;
};
