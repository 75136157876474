import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { ReviewCreatedIssuesFooterProps } from './review-created-issues-footer.types';

export const ReviewCreatedIssuesFooter = (
  props: ReviewCreatedIssuesFooterProps,
) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={handleCloseModal}
    />
  );
};
