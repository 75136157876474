import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllInventoriesForJobsResponse } from './fetch-all-for-jobs.types';

export const fetchAllInventoriesForJobs =
  (): Promise<FetchAllInventoriesForJobsResponse> => {
    return axios({
      method: 'get',
      url: `${coreApiUrl}/inventory/for-job`,
    }).then((response) => {
      return response.data;
    });
  };

fetchAllInventoriesForJobs.queryKey = () => {
  return ['/inventory/for-job'];
};
