import { NewRepairContent } from './components/new-repair-content';
import { ConfirmUsedInventoryContent } from './components/confirm-used-inventory-content';
import { ContentProps } from './content.types';
import { RepairSummaryContent } from './components/repair-summary-content';
import { ConfirmCompleteRepairContent } from './components/confirm-complete-repair-content';

export const Content = (props: ContentProps) => {
  const {
    inventoryRequests,
    isJobInProgress,
    notes,
    onClose,
    openIssues,
    repairType,
    selectedIssues,
    setInventoryRequests,
    setNotes,
    setRepairType,
    setSelectedIssues,
    setUpdateOnClose,
    step,
  } = props;

  switch (step) {
    case 'NEW_REPAIR':
      return (
        <NewRepairContent
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setRepairType={setRepairType}
          setSelectedIssues={setSelectedIssues}
          onClose={onClose}
        />
      );

    case 'CONFIRM_USED_INVENTORY':
      return (
        <ConfirmUsedInventoryContent
          inventoryRequests={inventoryRequests}
          setInventoryRequests={setInventoryRequests}
        />
      );

    case 'CONFIRM_COMPLETE_REPAIR':
      return (
        <ConfirmCompleteRepairContent
          isJobInProgress={isJobInProgress}
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setSelectedIssues={setSelectedIssues}
          setUpdateOnClose={setUpdateOnClose}
          onClose={onClose}
        />
      );

    case 'REPAIR_SUMMARY':
      return <RepairSummaryContent issues={selectedIssues} />;

    default:
      return null;
  }
};
