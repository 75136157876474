import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';

export const types = [
  'R&D',
  'Marketing',
  'Third Party',
  'Designer',
  'Trade',
  'Wholesale',
];

export const orderTypeOptions = convertStringArrayToValueLabelArray(types);
