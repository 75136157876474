import { useRecoilValue } from 'recoil';
import { startAssembleStageModalAtoms } from '../../start-assemble-stage-job-modal.atoms';
import { AssembleStageJobFooterProps } from './assemble-stage-job-footer.types';
import { ConfirmStartFooter } from '../step-confirm-start/confirm-start-footer';
import { GetFromVlmFooter } from '../step-get-from-vlm/get-from-vlm-footer';
import { CloseAssembleStageModalFooter } from './components/close-assemble-stage-modal-footer';

export const AssembleStageJobFooter = (props: AssembleStageJobFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(startAssembleStageModalAtoms.step);

  switch (step) {
    case 'CONFIRM_START':
      return (
        <ConfirmStartFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'GET_FROM_VLM':
      return <GetFromVlmFooter handleCloseModal={handleCloseModal} job={job} />;

    case 'NO_ASSIGNED_LOCATION':
      return (
        <CloseAssembleStageModalFooter handleCloseModal={handleCloseModal} />
      );

    default:
      return null;
  }
};
