import { memo } from 'react';
import { Typography, Grid, GetFolderStructureArgs } from 'gantri-components';
import { StyledSectionContainer } from '../../designer-feature.styles';
import { LifestyleImageryProps } from './lifestyle-imagery.types';
import { SortablePhotosList } from '../../../../components';

const Section = (props: LifestyleImageryProps) => {
  const { id, photos, type, updateFeatureInfo, userId } = props;

  const getOnImageUpload = (keyName: typeof type) => {
    return async (fileNames: string[]) => {
      updateFeatureInfo({
        key: keyName,
        type: 'key',
        value: [...photos, ...fileNames],
      });
    };
  };

  const getOnImageReplace = (keyName: typeof type) => {
    return async (replacementFileName: string, replaceIndex: number) => {
      updateFeatureInfo({
        key: keyName,
        type: 'key',
        value: photos.map((fileName, photoIndex) => {
          return photoIndex === replaceIndex ? replacementFileName : fileName;
        }),
      });
    };
  };

  const getOnImageRemove = (keyName: typeof type) => {
    return async (indexToRemove: number) => {
      updateFeatureInfo({
        key: keyName,
        type: 'key',
        value: photos.filter((_image, index) => {
          return index !== indexToRemove;
        }),
      });
    };
  };

  const getOnReorder = (keyName: typeof type) => {
    return async (updatedFileNames: string[]) => {
      updateFeatureInfo({
        key: keyName,
        type: 'key',
        value: updatedFileNames,
      });
    };
  };

  const getFolderStructureArgs: GetFolderStructureArgs<'designers'> = {
    directory: 'designers',
    fileType: 'collection-pages-lifestyle-imagery',
    identifiers: {
      collectionId: id,
      userId,
    },
  };

  return (
    <StyledSectionContainer>
      <Typography text="Lifestyle imagery" textStyle="bold" variant="h4" />
      <Grid columns={{ lg: 1 }} gap="0" justifyContent="space-between">
        <SortablePhotosList
          addPhotoPosition="start"
          getFolderStructureArgs={getFolderStructureArgs}
          max={Infinity}
          newFileName="image"
          photos={photos}
          title="Editorial imagery"
          onImageRemove={getOnImageRemove(type)}
          onImageReplace={getOnImageReplace(type)}
          onImageUpload={getOnImageUpload(type)}
          onReorder={getOnReorder(type)}
        />
      </Grid>
    </StyledSectionContainer>
  );
};

export const LifestyleImagery = memo(Section);
