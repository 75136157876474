import { Conditional } from 'gantri-components';
import { useMemo } from 'react';
import routePaths from '../../../../../../config/route-paths';
import { NotApplicableText } from '../../../../../common/not-applicable-text';
import { StyledAnchor } from '../../../../../common/styled-anchor';
import { AssignedToField } from '../../../../../common/assigned-to-field';
import { CellAssignedToProps } from './cell-assigned-to.types';

export const CellAssignedTo = (props: CellAssignedToProps) => {
  const { isEditing, job, setAssignedTo, userNames } = props;

  const workers = useMemo(() => {
    return userNames?.filter(({ type }) => {
      return type === 'Worker';
    });
  }, [userNames]);

  return (
    <Conditional
      condition={isEditing}
      Fallback={
        <Conditional
          condition={!!job?.assignedTo?.userName}
          Fallback={<NotApplicableText />}
        >
          <StyledAnchor
            text={job?.assignedTo?.userName}
            to={`${routePaths.users}/${job?.assignedTo?.userId}`}
          />
        </Conditional>
      }
    >
      <AssignedToField
        value={job.assignedTo?.userId}
        workers={workers}
        onSelect={(item) => {
          setAssignedTo(item?.id);
        }}
      />
    </Conditional>
  );
};
