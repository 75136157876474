import { Button, Conditional } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { checkAgainstFailingChecklistItems } from '../../../../../helpers/check-against-failing-checklist-items';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';
import { JobFailedFooterProps } from './job-failed-footer.types';

export const JobFailedFooter = (props: JobFailedFooterProps) => {
  const { handleCloseModal } = props;

  const setStep = useSetRecoilState(printQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(printQcModalAtoms.updateOnClose);
  const checklist = useRecoilValue(printQcModalAtoms.checklist);

  const shouldTakeMachineOffline = checkAgainstFailingChecklistItems(
    checklist,
    ({ failStatusShouldTakeMachineOffline }) => {
      return failStatusShouldTakeMachineOffline;
    },
  );

  useEffect(() => {
    if (shouldTakeMachineOffline) {
      setUpdateOnClose(true);
    }
  }, []);

  return (
    <Conditional
      condition={shouldTakeMachineOffline}
      Fallback={
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={async () => {
            await handleCloseModal();
          }}
        />
      }
    >
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Next"
        onClick={() => {
          setStep('MACHINE_OFFLINE');
        }}
      />
    </Conditional>
  );
};
