import isEmpty from 'lodash/isEmpty';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { GetInventoryTransactionsArgs } from './get-inventory.types';
import { getInventoryTransactions } from './get-inventory-transactions';

export const useGetInventoryTransactionsQuery = (
  props?: GetInventoryTransactionsArgs,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!props && !!props?.inventoryId,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred fetching inventory transactions.',
        });
      },
      queryFn: () => {
        return getInventoryTransactions.query(props);
      },
      queryKey: getInventoryTransactions.key(props),
    },
    showLoading: true,
  });

  return { isLoading, transactions: data };
};

export const useInvalidateGetInventoryTransactionsCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateCache = async (
    argsToInvalidate?: GetInventoryTransactionsArgs,
  ) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      const { inventoryId, ...paginateParams } = argsToInvalidate ?? {};

      return !isEmpty(paginateParams)
        ? key === getInventoryTransactions.key(argsToInvalidate)[0].key
        : groupKey === getInventoryTransactions.groupKey(inventoryId);
    });
  };

  return invalidateCache;
};
