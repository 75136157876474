import { useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-prepare-queue-modal.constants';
import { JobSetCompletedFooter } from '../step-job-set-completed/job-set-completed-footer';
import { JobSetPrepareFooter } from '../step-job-set-prepare/job-set-prepare-footer';
import { JobSetStartFooter } from '../step-job-set-start/job-set-start-footer';
import { QueueDeletedFooter } from '../step-queue-deleted/queue-deleted-footer';
import { PrintPrepareQueueModalFooterProps } from './print-prepare-queue-modal-footer.types';

export const PrintPrepareQueueModalFooter = (
  props: PrintPrepareQueueModalFooterProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(printPrepareQueueModalAtoms.step);

  switch (step) {
    case printPrepareQueueModalSteps.jobSetStart:
      return <JobSetStartFooter />;

    case printPrepareQueueModalSteps.jobSetPrepare:
      return <JobSetPrepareFooter handleCloseModal={handleCloseModal} />;

    case printPrepareQueueModalSteps.jobSetCompleted:
      return <JobSetCompletedFooter handleCloseModal={handleCloseModal} />;

    case printPrepareQueueModalSteps.queueDeleted:
      return <QueueDeletedFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
