import {
  OrderStatus,
  orderStatusesMap,
  TransactionType,
  transactionTypesMap,
} from '../../../constants/options';

export const typeOptions = Object.values(transactionTypesMap);

export const productionTypesFilterOptions = [
  transactionTypesMap.marketing,
  transactionTypesMap.order,
  transactionTypesMap.replacement,
  transactionTypesMap.thirdParty,
  transactionTypesMap.wholesale,
] satisfies TransactionType[];

export const statusOptions = Object.values(orderStatusesMap);

export const productionStatusFilterOptions = [
  orderStatusesMap.inProgress,
  orderStatusesMap.partiallyDelivered,
  orderStatusesMap.partiallyCancelled,
  orderStatusesMap.partiallyRefunded,
  orderStatusesMap.partiallyShipped,
  orderStatusesMap.processed,
  orderStatusesMap.refundInProgress,
  orderStatusesMap.readyToShip,
] satisfies OrderStatus[];
