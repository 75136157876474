import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import { Button } from 'gantri-components';
import Grid from '../../common/grid';
import Cell from '../../common/cell';
import styles from '../styles.module.scss';
import routePaths from '../../../config/route-paths';
import { shipmentStatuses } from '../../../constants/options';
import { ReasonStockRecord } from '../reassign-stock-record';
import { stocksApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';
import { PageHeading } from '../../layout/page-heading';
import { useRouter } from '../../../hooks';

const ReassignStock = ({ currentOrderData, id }) => {
  const { navigate } = useRouter();
  const { hideLoading, notify, showLoading } = useNotification();

  const { shipments } = currentOrderData;

  const [idleStocks, setIdleStocks] = useState({});
  const [selectedStocks, setSelectedStocks] = useState({});

  const statefulStocks = useMemo(() => {
    return flatten(
      shipments
        .filter((shipment) => {
          return shipment.status === shipmentStatuses.waiting;
        })
        .map((shipment) => {
          return shipment.stocks;
        }),
    );
  }, [shipments]);

  useEffect(() => {
    const skus = statefulStocks.map((stock) => {
      return stock.product.sku;
    });

    stocksApi.getIdleStocks({ skus }).then((response) => {
      if (response.data && response.data.success) {
        setIdleStocks(response.data.idleStocks);
      }
    });
  }, [statefulStocks]);

  const onConfirm = () => {
    showLoading();

    stocksApi
      .reassignStocks(id, Object.values(selectedStocks))
      .then((response) => {
        notify(response.data.notice);
        navigate(`/orders/${id}`);
      })
      .finally(() => {
        hideLoading();
      });
  };

  const onChange = (selected, newStock, oldStock) => {
    if (selected) {
      setSelectedStocks({
        ...selectedStocks,
        [oldStock.id]: {
          newStockData: {
            id: newStock.id,
            status: newStock.status,
          },
          oldStockData: {
            id: oldStock.id,
            stockInfoId: oldStock.stockInfoId,
          },
        },
      });
    } else {
      const temporal = { ...selectedStocks };

      delete temporal[oldStock.id];

      setSelectedStocks(temporal);
    }
  };

  return (
    <Grid rowGap="60px" rows={4}>
      <Cell>
        <PageHeading
          subTitle="Re-assign stock for the items below."
          title="Re-assign stock"
        />
        {statefulStocks.map((stock) => {
          return (
            <ReasonStockRecord
              key={stock.id}
              idleStocks={idleStocks[stock.product.sku]}
              selectedStocks={selectedStocks}
              stock={stock}
              onChange={onChange}
            />
          );
        })}
      </Cell>

      <Grid columns="repeat(2, 12rem)" gap="1rem">
        <Button
          text="Cancel"
          variant="secondary"
          onClick={() => {
            return navigate(`${routePaths.orders}/${id}`);
          }}
        />
        <Button
          classnames={styles['button-container']}
          disabled={!Object.keys(selectedStocks).length}
          text="Confirm"
          variant="primary"
          onClick={onConfirm}
        />
      </Grid>
    </Grid>
  );
};

ReassignStock.propTypes = {
  id: PropTypes.string.isRequired,
  isReturn: PropTypes.bool,
};

export default ReassignStock;
