import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultMachinesGroupBy } from '../../report-machines.constants';
import { ReportMachinesQueries } from '../../report-machines.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportMachinesQueries>();
  const { groupBy = defaultMachinesGroupBy, machineId, machineType } = queries;

  if (
    (/^machineName$/i.test(groupBy) && machineId) ||
    (/^machineType$/i.test(groupBy) && machineType)
  ) {
    return 'Issue Name';
  }

  if (/^machineName$/i.test(groupBy) && !machineId) {
    return 'Machine Name';
  }

  return 'Machines Type';
};
