import { FC, ReactNode } from 'react';
import {
  Grid,
  Typography,
  Flex,
  Conditional,
  Box,
  Table,
} from 'gantri-components';
import { InventoryMigrationFormState } from '../inventory-migration.types';
import { MigrateIcon } from '../components';

const InventoryItem: FC<{ description: string | ReactNode; title: string }> = ({
  description,
  title,
}) => {
  return (
    <Flex direction="column" gap=".6rem">
      <Typography text={title} textStyle="bold" />
      <Conditional
        condition={typeof description === 'string'}
        Fallback={description}
      >
        <Typography text={description as string} />
      </Conditional>
    </Flex>
  );
};

export const InventoryMigrationSummary: FC<{
  data: InventoryMigrationFormState;
}> = ({ data }) => {
  return (
    <Grid rowGap="2rem">
      <Typography text="The following inventory migration was performed." />
      <Grid alignItems="flex-start" columns="1fr max-content 1fr" gap="2rem">
        <InventoryItem
          description={data.existingSelected.name}
          title="Existing Inventory"
        />
        <MigrateIcon />
        <InventoryItem
          description={data.replacementSelected.name}
          title="Replacement Inventory"
        />

        <InventoryItem
          description={
            <Flex direction="column" gap=".6rem">
              <Typography
                lineHeight="1.8rem"
                text="Logged as a Minor version update for each product"
                variant="p3"
              />
              <Typography text={data.totalProductUpdated} />
            </Flex>
          }
          title="Products Updated"
        />
        <Box width="2.4rem" />
        <InventoryItem
          description={data.stockUpdated}
          title="In-Progress Stocks Updated"
        />
      </Grid>

      <Conditional condition={data?.productManualUpdate?.length > 0}>
        <InventoryItem
          description={
            <Typography
              lineHeight="1.8rem"
              text={`The following ${data?.productManualUpdate?.length} products currently have a draft and need to be published manually:`}
              variant="p3"
            />
          }
          title="Products Requiring Manual Updates"
        />

        <Table
          columns={[
            { accessorKey: 'id', header: 'ID' },
            { accessorKey: 'productName', header: 'Product Name' },
          ]}
          data={data.productManualUpdate}
        />
      </Conditional>
    </Grid>
  );
};
