import { Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledQueueStack = styled(Stack)`
  ${({ theme }) => {
    return css`
      border-radius: 0.4rem;
      background-color: ${theme.colors.surfaces.monochrome.t2};
    `;
  }}
`;
