/* eslint-disable sort-keys-fix/sort-keys-fix */
import {
  OpaqueHighTempFailedReason,
  TranslucentGplaFailedReason,
} from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Checklist } from '../../common/job-checklist/job-checklist.types';
import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';

export const opaqueHighTempChecklistDefaults: Checklist<OpaqueHighTempFailedReason> =
  {
    // keys sorted according to desired display order
    bumps_or_zseams: {
      ...checklistDefaults.bumps_or_zseams,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522503/static-assets/Bumps_and_z_seams_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522501/static-assets/Bumps_and_z_seams_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522498/static-assets/Bumps_and_z_seams_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522495/static-assets/Bumps_and_z_seams_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    layer_lines: {
      ...checklistDefaults.layer_lines,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522586/static-assets/Layerlines_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522590/static-assets/Layerlines_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522588/static-assets/Layerlines_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522583/static-assets/Layerlines_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    raft_texture: {
      ...checklistDefaults.raft_texture,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522651/static-assets/Raft_opaque_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522654/static-assets/Raft_opaque_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522657/static-assets/Raft_opaque_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522648/static-assets/Raft_opaque_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    warping: {
      ...checklistDefaults.warping,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
        takeMachineOfflineAt: 5,
      },
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      tool: 'Warping Gauges',
    },
    stringing: {
      ...checklistDefaults.stringing,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522749/static-assets/Stringing_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522752/static-assets/Stringing_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522747/static-assets/Stringing_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522756/static-assets/Stringing_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    delamination: {
      ...checklistDefaults.delamination,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      tool: 'binary-image',
    },
    under_extrusion: {
      ...checklistDefaults.under_extrusion,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      tool: 'binary-image',
    },
    bad_gcode: {
      ...checklistDefaults.bad_gcode,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
    wrong_part: {
      ...checklistDefaults.wrong_part,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
    other_failure_modes: {
      ...checklistDefaults.other_failure_modes,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
  };

export const translucentGplaChecklistDefaults: Checklist<TranslucentGplaFailedReason> =
  {
    layer_lines: {
      ...checklistDefaults.layer_lines,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522851/static-assets/layer_lines_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522840/static-assets/layer_lines_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522845/static-assets/layer_lines_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522854/static-assets/layer_lines_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    burn_or_debris: {
      ...checklistDefaults.burn_or_debris,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
        takeMachineOfflineAt: 5,
      },
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      tool: 'Overlay Tool',
    },
    raft_texture: {
      ...checklistDefaults.raft_texture,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679688925/static-assets/Raft_translucent_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679688926/static-assets/Raft_translucent_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679688428/static-assets/Raft_translucent_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679523561/static-assets/Raft_translucent_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    overhang_texture: {
      ...checklistDefaults.overhang_texture,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522915/static-assets/Overhang_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522920/static-assets/Overhang_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522929/static-assets/Overhang_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522925/static-assets/Overhang_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    warping: {
      ...checklistDefaults.warping,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
        takeMachineOfflineAt: 5,
      },
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      tool: 'Warping Gauges',
    },
    top_build_up: {
      ...checklistDefaults.top_build_up,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522988/static-assets/Top_Buildup_C_.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522975/static-assets/Top_Buildup_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522983/static-assets/Top_Buildup_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522979/static-assets/Top_Buildup_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    shadowing: {
      ...checklistDefaults.shadowing,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679523032/static-assets/Shadowing_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679523036/static-assets/Shadowing_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679523029/static-assets/Shadowing_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679523042/static-assets/Shadowing_F.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    thick_lower_line: {
      ...checklistDefaults.thick_lower_line,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679523364/static-assets/Thick_lower_line_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679523366/static-assets/Thick_lower_line_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679688599/static-assets/Thick_lower_line_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679523364/static-assets/Thick_lower_line_C.jpg',
      },
      tool: 'severity-images',
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
    },
    banding: {
      ...checklistDefaults.banding,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      tool: 'binary-image',
    },
    delamination: {
      ...checklistDefaults.delamination,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
      failStatusShouldTakeMachineOffline: true,
      tool: 'binary-image',
    },
    bad_gcode: {
      ...checklistDefaults.bad_gcode,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
    wrong_part: {
      ...checklistDefaults.wrong_part,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
    other_failure_modes: {
      ...checklistDefaults.other_failure_modes,
      failStatusRecommendation: 'Reprint',
      failStatusShouldFailPart: true,
    },
  };
