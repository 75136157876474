import React, { useState } from 'react';
import {
  Button,
  Cell,
  Conditional,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { ManualShippingModalProp } from './manual-shipping-modal.types';
import { ManualShippingModalPresets } from './manual-shipping-modal.presets';

export const ManualShippingModal = (props: ManualShippingModalProp) => {
  const { onCancel, onConfirm, onShippoClick, shippoButtonVisible, visible } =
    props;

  const [trackingNumber, setTrackingNumber] = useState<string>('');
  const [shippingCost, setShippingCost] = useState<number>(0);

  const onShippoSelected = () => {
    onShippoClick();
    setTrackingNumber('');
    setShippingCost(0);
  };

  const onShippoConfirm = () => {
    onConfirm(trackingNumber, shippingCost);
  };

  const onModalClose = () => {
    setTrackingNumber('');
    setShippingCost(0);
    onCancel();
  };

  return (
    visible && (
      <Modal
        footer={
          <Grid
            alignItems="center"
            columns={2}
            gap=".5x"
            justifyContent="center"
          >
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={onModalClose}
            />
            <Button
              disabled={!trackingNumber || !shippingCost}
              size="large"
              text="Confirm"
              onClick={onShippoConfirm}
            />
            <Conditional condition={shippoButtonVisible}>
              <Cell width={2}>
                <Button
                  text="Shippo"
                  variant="ghost"
                  onClick={onShippoSelected}
                />
              </Cell>
            </Conditional>
          </Grid>
        }
        height={{
          lg: 'max-content',
        }}
        width={{
          lg: '40rem',
          md: '100%',
        }}
        onClose={onModalClose}
      >
        <Stack>
          <div>
            <Typography
              align="center"
              text="Ship Manually"
              textStyle="bold"
              variant="p1"
            />
            <Typography
              align="center"
              color="t2"
              marginBottom="20px"
              marginTop="10px"
              text="Enter shipping details."
            />
          </div>

          <Cell>
            <Typography text="UPS tracking number #" textStyle="bold" />
            <TextField
              name="trackingNumber"
              value={trackingNumber}
              onTextChange={setTrackingNumber}
            />
          </Cell>

          <Cell>
            <Typography text="Shipping cost $" textStyle="bold" />
            <TextField
              name="shippingCost"
              type="number"
              value={shippingCost.toString()}
              onTextChange={(value) => {
                return setShippingCost(+value);
              }}
            />
          </Cell>
        </Stack>
      </Modal>
    )
  );
};

ManualShippingModal.defaultProps = ManualShippingModalPresets;
