import { Grid } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledGridContainer = styled(Grid)`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const StyledMessage = styled.div<{ isAdmin: boolean }>`
  ${({ isAdmin, theme }) => {
    return css`
      padding: 12px;
      border-radius: 1px 8px 8px 8px;
      max-width: 30rem;
      background-color: ${isAdmin
        ? theme.colors.surfaces.green.t3
        : theme.colors.surfaces.monochrome.t1};
    `;
  }}
`;
