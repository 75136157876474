import * as yup from 'yup';
import { required } from '../../../../helpers/validators';

export const DownloadModalSchema = yup.object().shape({
  description: required(),
  fileName: required(),
  link: required(),
  name: required(),
  thumbnail: required(),
  type: required(),
});
