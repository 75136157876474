import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardApi } from '../../../../api';
import { GetReportJobsArgs } from '../../../../api/dashboard/routes/get-report-jobs/get-report-jobs.types';
import { getActiveQueries } from '../../../../helpers/checks';
import { DashboardChartProps } from '../../../dashboard/components/common/dashboard-chart/dashboard-chart.types';
import { dashboardAndReportAtoms } from '../../../dashboard/dashboard.atoms';
import { ReportPage } from '../common/report-page';
import {
  GetReportData,
  ReportProps,
} from '../common/report-page/report-page.types';
import {
  getBreadcrumbs,
  getReportDetails,
  getRowProps,
  getTitle,
} from './helpers';
import {
  defaultJobsGroupBy,
  pageName,
  reportJobsPageAtoms,
} from './report-jobs.constants';
import { ReportJobsQueries } from './report-jobs.types';

export const ReportJobs = ({ rootTitle }: ReportProps) => {
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const {
    activeChart: activeChartQuery,
    groupBy = defaultJobsGroupBy,
    productId,
    version,
    jobType,
    machineId,
    machineType,
    partName,
    workerId,
  } = getActiveQueries<ReportJobsQueries>();

  const [activeChart, setActiveChart] = useState<string>(activeChartQuery);

  const {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  } = getReportDetails();

  const getReportData: GetReportData = ({ endDate, startDate }, configs) => {
    const args: GetReportJobsArgs = {
      endDate,
      groupBy,
      jobType,
      machineId: +machineId,
      machineType,
      partName,
      productId: +productId,
      rangeType,
      startDate,
      version,
      workerId: +workerId,
    };

    return dashboardApi.getReportJobs(args, configs);
  };

  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] = (
    value,
  ) => {
    return `${value}%`;
  };

  const axisLeft: DashboardChartProps['axisLeft'] = {
    format: (y: number) => {
      return `${y}%`;
    },
  };

  return (
    <ReportPage
      activeChart={activeChart}
      axisLeft={axisLeft}
      breadcrumbs={getBreadcrumbs()}
      extraChartLeftMargin={30}
      getReportData={getReportData}
      getRowProps={getRowProps}
      groupByIsVisible={groupByIsVisible}
      groupByItems={groupByItems}
      groupByOnChange={groupByOnChange}
      groupByValue={groupBy}
      pageName={pageName}
      setActiveChart={setActiveChart}
      sortByAtom={reportJobsPageAtoms.sortBy}
      sortingFields={sortingOptions}
      tableColumns={tableColumns}
      title={getTitle(rootTitle)}
      transformTooltipValue={transformTooltipValue}
    />
  );
};
