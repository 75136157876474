import { useRecoilState, useRecoilValue } from 'recoil';
import { StyledGrid } from './job-details-panel-footer.styles';
import { NavigationButton } from './components';
import { jobsDetailsPanelAtoms } from '../../jobs-details-panel.atoms';

export const JobDetailsPanelFooter = () => {
  const [jobIdInDetailsPanel, setJobIdInDetailsPanel] = useRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );
  const jobIds = useRecoilValue(jobsDetailsPanelAtoms.jobIds);
  const viewInEditMode = useRecoilValue(jobsDetailsPanelAtoms.viewInEditMode);

  const activeJobIndex = jobIds.indexOf(jobIdInDetailsPanel);
  const activeJobNumber = activeJobIndex + 1;
  const totalJobsOnPage = jobIds.length;
  const isFirstJob = activeJobNumber === 1;
  const isLastJob = activeJobNumber === totalJobsOnPage;

  const handleGetPrevJob = () => {
    const prevJobIndex = activeJobIndex - 1;
    const prevJobId = jobIds[prevJobIndex];

    setJobIdInDetailsPanel(prevJobId);
  };

  const handleGetNextJob = () => {
    const nextJobIndex = activeJobIndex + 1;
    const nextJobId = jobIds[nextJobIndex];

    setJobIdInDetailsPanel(nextJobId);
  };

  return activeJobIndex >= 0 && totalJobsOnPage && !viewInEditMode ? (
    <StyledGrid
      alignItems="center"
      columns="auto max-content auto"
      justifyContent="space-between"
    >
      <NavigationButton
        disabled={isFirstJob}
        name="arrows:arrow_chevron_left"
        onClick={handleGetPrevJob}
      />
      <div>{`Job ${activeJobNumber} of ${totalJobsOnPage}`}</div>
      <NavigationButton
        disabled={isLastJob}
        name="arrows:arrow_chevron_right"
        onClick={handleGetNextJob}
      />
    </StyledGrid>
  ) : null;
};
