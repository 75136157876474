import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllOrdersForFilterResponse } from './fetch-all-for-filter.types';

export const fetchAllOrdersForFilter = (
  query?: string,
): Promise<FetchAllOrdersForFilterResponse> => {
  return axios({
    method: 'get',
    params: { orderId: query },
    url: `${coreApiUrl}/orders`,
  }).then((response) => {
    return response.data;
  });
};

fetchAllOrdersForFilter.queryKey = (query?: string) => {
  return !!query ? ['/orders/for-filter', query] : ['/orders/for-filter'];
};
