import { getGenericAtomFamily } from '../../../../../helpers/get-generic-atom-family';
import { SortBy } from './use-table-filters.types';

const atomKeyPrefix = 'TABLE_FILTERS';

export const searchDefaultValue = '';

export const getSearchFilterAtomFamily = getGenericAtomFamily({
  defaultValue: searchDefaultValue,
  key: `${atomKeyPrefix}-search`,
});

export const sortByFallbackDefaultValue: SortBy<any> = {
  sortingField: null,
  sortingType: 'ASC',
};

export const getSortByFilterAtomFamily = <DefaultValue = SortBy<string>>({
  defaultValue,
}: {
  defaultValue: DefaultValue;
}) => {
  return getGenericAtomFamily({
    defaultValue,
    key: `${atomKeyPrefix}-sort-by`,
  });
};
