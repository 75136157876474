import { useState, useEffect } from 'react';
import { useModal } from 'gantri-components';
import { PartLocationInformationProps } from './part-location-information.types';
import { OnUpdateLocation } from '../../modals/update-location-modal/update-location-modal.types';
import { UpdateLocationModal } from '../../modals/update-location-modal';
import { partsApi, stocksApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

export const usePartLocations = (props: PartLocationInformationProps) => {
  const {
    id,
    location: originalLocation,
    locationTimelines,
    onLocationUpdate,
    onStatusUpdate,
    source,
    stockId,
  } = props;

  const { notify } = useNotification();
  const [location, setLocation] = useState({ id: null, name: '' });

  useEffect(() => {
    setLocation(originalLocation);
  }, [originalLocation]);

  const onUpdateLocation: OnUpdateLocation = async (locationId) => {
    const partResponse = await partsApi.updateLocation({
      id,
      locationId,
      source,
    });

    if (partResponse.data.success) {
      notify(partResponse.data.notice);

      if (onStatusUpdate && stockId) {
        const data = await stocksApi.getStock({ stockId });
        const { jobs, parts } = data.stock;
        const updatedJobs = jobs.map((job) => {
          const jobStatuses = job.statuses;

          if (jobStatuses?.length) {
            const currentJobStatus = job.status;
            const latestJobStatus = jobStatuses.find((stat) => {
              return stat.status === currentJobStatus;
            });
            const updatedJob = {
              ...job,
              user: latestJobStatus ? latestJobStatus.user : '',
            };

            return updatedJob;
          }

          return job;
        });
        const updatedData = { jobsForStock: updatedJobs, parts };

        onStatusUpdate?.(updatedData);
        onLocationUpdate?.(partResponse.data.part);
      } else {
        onLocationUpdate?.(partResponse.data.part);
      }
    }
  };

  const [showLocationModal, hideLocationModal] = useModal(() => {
    return (
      <UpdateLocationModal
        currentLocationId={location?.id}
        currentLocationName={location?.name}
        locationTimelines={locationTimelines}
        partId={id}
        stockId={stockId}
        onClose={hideLocationModal}
        onUpdateLocation={onUpdateLocation}
      />
    );
  }, [props, location]);

  return { location, showLocationModal };
};
