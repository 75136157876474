import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  PaginatedLocationsArgs,
  PaginatedLocationsResponse,
} from './paginated-location.types';

export const paginatedLocations = async (body: PaginatedLocationsArgs) => {
  return axios.post<PaginatedLocationsResponse>(
    `${coreApiUrl}/locations/pagination`,
    {
      data: body,
    },
  );
};
