import { Shipment } from '../../../../../../api/inventory-purchases/inventory-purchases.types';

export const shipmentStatuses = {
  arrived: 'Arrived',
  delivered: 'Delivered',
  readyToShip: 'Ready to ship',
  shipped: 'Shipped',
  waiting: 'Waiting',
} as const;

export const shipmentText: Partial<
  Record<keyof Shipment, { label: string; placeholder?: string }>
> = {
  arrivedAt: {
    label: 'Arrival (ATA)',
  },
  cargoReadyAt: {
    label: 'Cargo Ready',
  },
  deliveredAt: {
    label: 'Delivered',
  },
  dimension: {
    label: 'Dimensions (")',
    placeholder: 'Enter dimensions (in)',
  },
  etaAt: {
    label: 'ETA',
  },
  etdAt: {
    label: 'ETD',
  },
  expectedQuantity: {
    label: 'Expected Quantity',
    placeholder: 'Enter quantity',
  },
  grossWeight: {
    label: 'Gross Weight (kg)',
    placeholder: 'Enter weight (kg)',
  },
  notes: {
    label: 'Notes',
  },
  photos: {
    label: 'Photos',
  },
  shippedAt: {
    label: 'Shipped (ATD)',
  },
  trackingLink: {
    label: 'Tracking Link',
    placeholder: 'Enter tracking link',
  },
};
