import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportProfitQueries } from '../../report-profit.types';

export const getTitle = (rootTitle: string): string => {
  const queries = getActiveQueries<ReportProfitQueries>();
  const { groupBy, productId, productName, version } = queries;

  if (/^productId$/i.test(groupBy) && productId) {
    if (version) {
      return `${version}`;
    }

    return productName;
  }

  return rootTitle;
};
