import { useRecoilState, useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-prepare-queue-modal.constants';

export const useNavigateJobSets = () => {
  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const [step, setStep] = useRecoilState(printPrepareQueueModalAtoms.step);
  const [activeSetIndex, setActiveSetIndex] = useRecoilState(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  const sets = queueData?.sets;
  const numCompletedSets =
    sets?.filter?.(({ isComplete }) => {
      return isComplete;
    })?.length || 0;
  const totalSets = sets?.length || 0;
  const isOnQueueDeletedStep =
    step === printPrepareQueueModalSteps.queueDeleted;
  const isPrevDisabled = isOnQueueDeletedStep || activeSetIndex <= 0;
  const isNextDisabled =
    isOnQueueDeletedStep || activeSetIndex >= totalSets - 1;
  const activeSetNumber = activeSetIndex + 1;

  const navigateJobSets = (direction: 'prev' | 'next') => {
    const newIndex =
      direction === 'prev' ? activeSetIndex - 1 : activeSetIndex + 1;

    setActiveSetIndex(newIndex);

    const isComplete = sets?.[newIndex]?.isComplete;

    setStep(
      isComplete
        ? printPrepareQueueModalSteps.jobSetCompleted
        : printPrepareQueueModalSteps.jobSetStart,
    );
  };

  return {
    activeSetNumber,
    isNextDisabled,
    isPrevDisabled,
    navigateJobSets,
    numCompletedSets,
    totalSets,
  };
};
