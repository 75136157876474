import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { ReportProfitQueries } from '../../report-profit.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Profit';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportProfitQueries>();
  const { groupBy, productId, productName, version } = queries;

  if (!/^productId$/i.test(groupBy) || !productId) return [];

  const breadcrumb: Breadcrumb[] = [
    {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'productId' }),
    },
  ];

  if (version) {
    breadcrumb.push({
      name: productName,
      path:
        location +
        convertQueriesToString({
          groupBy: 'productId',
          productId,
          productName,
        }),
    });
  }

  return breadcrumb;
};
