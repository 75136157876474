import { Modal } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { PrintPrepareQueueModalContent } from './components/print-prepare-queue-modal-content';
import { PrintPrepareQueueModalFooter } from './components/print-prepare-queue-modal-footer';
import { PrintPrepareQueueModalHeader } from './components/print-prepare-queue-modal-header';
import { printPrepareQueueModalAtoms } from './print-prepare-queue-modal.atoms';
import { PrintPrepareQueueModalProps } from './print-prepare-queue-modal.types';
import { PrintPrepareQueueDetailsPanel } from './components/prepare-print-queue-details-panel';
import { useDeletePrintPrepareQueueMutation } from '../../../../../api/queue/print-prepare';
import { useResetRecoilAtomsOnMount } from '../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { useHandleCloseModal } from '../hooks/use-handle-close-modal';

export const PrintPrepareQueueModal = (props: PrintPrepareQueueModalProps) => {
  const { onClose, onUpdate } = props;

  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const deleteQueueOnClose = useRecoilValue(
    printPrepareQueueModalAtoms.deleteQueueOnClose,
  );

  const { queueData: _queueDataAtom, ...otherAtoms } =
    printPrepareQueueModalAtoms;
  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    // exclude queueData atom as it is set prior to modal open
    otherAtoms,
  );

  const { onDeletePrintPrepareQueue } = useDeletePrintPrepareQueueMutation();

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: async () => {
      await onDeletePrintPrepareQueue(queueData?.id);
      await onUpdate();
    },
    updateOnClose: deleteQueueOnClose,
  });

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        contentStackProps={{ alignContent: 'stretch' }}
        detailsPanel={<PrintPrepareQueueDetailsPanel />}
        detailsPanelWidth="1fr"
        footer={
          <PrintPrepareQueueModalFooter handleCloseModal={handleCloseModal} />
        }
        header={
          <PrintPrepareQueueModalHeader handleCloseModal={handleCloseModal} />
        }
        isFullScreen
      >
        <PrintPrepareQueueModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
