import { useNotification } from '../../../../hooks/useNotification';
import { inventoriesApi } from '../../../index';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import {
  useInvalidateQuery,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchAllInventoriesForJobsResponse } from './index';

interface Props<TData>
  extends UseQueryProps<TData, FetchAllInventoriesForJobsResponse> {}

export const useFetchAllInventoriesForJobsQuery = <TData>(
  props?: Props<TData>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<
    FetchAllInventoriesForJobsResponse | TData
  >({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventories.',
        });
      },
      queryFn: () => {
        return inventoriesApi.fetchAllInventoriesForJobs();
      },
      queryKey: inventoriesApi.fetchAllInventoriesForJobs.queryKey(),
      select: (response: FetchAllInventoriesForJobsResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    inventories: data as TData,
    isLoading,
  };
};

export const useInvalidateFetchAllInventoriesForJobsCache = () => {
  const invalidate = useInvalidateQuery();

  const invalidateFetchAllInventoriesJobsCache = () => {
    return invalidate(inventoriesApi.fetchAllInventoriesForJobs.queryKey());
  };

  return { invalidateFetchAllInventoriesJobsCache };
};
