import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardApi } from '../../../../api';
import { GetReportOrdersArgs } from '../../../../api/dashboard/routes/get-report-orders/get-report-orders.types';
import { getActiveQueries } from '../../../../helpers/checks';
import { DashboardChartProps } from '../../../dashboard/components/common/dashboard-chart/dashboard-chart.types';
import { dashboardAndReportAtoms } from '../../../dashboard/dashboard.atoms';
import { ReportPage } from '../common/report-page';
import {
  GetReportData,
  ReportProps,
} from '../common/report-page/report-page.types';
import {
  getReportDetails,
  getRowProps,
  getBreadcrumbs,
  getTitle,
} from './helpers';
import {
  defaultOrdersGroupBy,
  pageName,
  reportOrdersPageAtoms,
} from './report-orders.constants';
import { ReportOrderQueries } from './report-orders.types';

export const ReportOrders = ({ rootTitle }: ReportProps) => {
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const {
    activeChart: activeChartQuery,
    groupBy = defaultOrdersGroupBy,
    orderType,
  } = getActiveQueries<ReportOrderQueries>();
  const [activeChart, setActiveChart] = useState<string>(activeChartQuery);
  const activeChartIsPercent = ['onTimeCompletion'].includes(activeChart);

  const { sortingOptions, tableColumns } = getReportDetails();

  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] =
    activeChartIsPercent
      ? (value) => {
          return `${value}%`;
        }
      : undefined;
  const axisLeft: DashboardChartProps['axisLeft'] = activeChartIsPercent
    ? {
        format: (y: number) => {
          return `${y}%`;
        },
      }
    : undefined;

  const getReportData: GetReportData = ({ endDate, startDate }, configs) => {
    const args: GetReportOrdersArgs = {
      endDate,
      groupBy,
      orderType,
      rangeType,
      startDate,
    };

    return dashboardApi.getReportOrders(args, configs);
  };

  return (
    <ReportPage
      activeChart={activeChart}
      axisLeft={axisLeft}
      breadcrumbs={getBreadcrumbs()}
      extraChartLeftMargin={activeChartIsPercent ? 30 : undefined}
      getReportData={getReportData}
      getRowProps={getRowProps}
      pageName={pageName}
      setActiveChart={setActiveChart}
      sortByAtom={reportOrdersPageAtoms.sortBy}
      sortingFields={sortingOptions}
      tableColumns={tableColumns}
      title={getTitle(rootTitle)}
      transformTooltipValue={transformTooltipValue}
    />
  );
};
