import { useRecoilValue } from 'recoil';
import { setMachinesOnlineModalSteps } from '../../set-machines-online-modal.constants';
import { setMachinesOnlineModalAtoms } from '../../set-machines-online-modal.atoms';
import { ConfirmSetMachinesOnlineFooter } from '../step-confirm-set-machines-online/confirm-set-machines-online-footer';
import { ReviewOnlineMachinesFooter } from '../step-review-online-machines/review-online-machines-footer';
import { SetMachinesOnlineModalFooterProps } from './set-machines-online-modal-footer.types';

export const SetMachinesOnlineModalFooter = (
  props: SetMachinesOnlineModalFooterProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(setMachinesOnlineModalAtoms.step);

  switch (step) {
    case setMachinesOnlineModalSteps.confirmSetMachinesOnline:
      return (
        <ConfirmSetMachinesOnlineFooter handleCloseModal={handleCloseModal} />
      );

    case setMachinesOnlineModalSteps.reviewOnlinedMachines:
      return <ReviewOnlineMachinesFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
