import { Icon, Stack, Tooltip, Typography } from 'gantri-components';
import { StyledIconWithTextContainer } from '../../../../../../assets/styles/common';
import { jobTypeToIconTypeMap } from '../../../../../../constants/jobs';
import { StyledJobNameTypography } from '../../../../jobs.styles';
import { JobDescriptionCellProps } from './job-description-cell.types';

export const JobDescriptionCell = ({
  getValue,
  row,
}: JobDescriptionCellProps) => {
  const job = row.original;
  const iconName = jobTypeToIconTypeMap[job.type];
  const jobName = getValue();

  return !!jobName ? (
    <StyledIconWithTextContainer>
      <Tooltip position="top" title={job.type}>
        {iconName && <Icon color="t2" name={iconName} top="3px" />}
      </Tooltip>

      <StyledJobNameTypography
        icon={
          !!job.attentions?.length && (
            <Tooltip
              Component={
                <Stack gap="x" maxWidth="20rem" padding="x">
                  {job.attentions.map((attention) => {
                    return (
                      <Typography
                        key={attention.description}
                        text={attention.description}
                      />
                    );
                  })}
                </Stack>
              }
              position="top"
            >
              <Icon color="alert" name="view:eye_open" />
            </Tooltip>
          )
        }
        iconPosition="right"
        text={jobName?.replace('_', ' ')}
        textStyle="bold"
        variant="p2"
      />
    </StyledIconWithTextContainer>
  ) : null;
};
