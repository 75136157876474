import { Cell, Toggle } from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  machineStatusOptions,
  printerTypes,
} from '../../../constants/machines';
import { machinesPageAtoms } from './machines-filter.atoms';
import { MachinesFilterProps } from './machines-filter.types';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import {
  IssuesDifficulties,
  machineIssueOptions,
} from '../../../constants/options';
import { MachineIssueDropdown } from '../../machine-issue-dropdown';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

export const MachinesFilter = (props: MachinesFilterProps) => {
  const { machineLocations } = props;

  const [attentionOnly, setAttentionOnly] = useRecoilState(
    machinesPageAtoms.filters.attentionOnly,
  );

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.types}
          default={machinesPageAtoms.defaults.filters.types}
          text="Machine type"
        />
        <AtomsCheckboxList
          atom={machinesPageAtoms.filters.types}
          items={printerTypes}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.statuses}
          default={machinesPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={machinesPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={machineStatusOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.locations}
          default={machinesPageAtoms.defaults.filters.locations}
          text="Machine locations"
        />
        <AtomsCheckboxList
          atom={machinesPageAtoms.filters.locations}
          gridProps={{ columns: 2 }}
          items={machineLocations}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.difficulty}
          default={machinesPageAtoms.defaults.filters.difficulty}
          text="Issues"
        />
        <AtomsCheckboxList
          atom={machinesPageAtoms.filters.difficulty}
          gridProps={{ columns: 2 }}
          items={IssuesDifficulties}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.machineIssues}
          default={machinesPageAtoms.defaults.filters.machineIssues}
          text="Machine Issues"
        />
        <MachineIssueDropdown
          atom={machinesPageAtoms.filters.machineIssues}
          default={machinesPageAtoms.defaults.filters.machineIssues}
          items={machineIssueOptions.map(({ type }) => {
            return type;
          })}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={machinesPageAtoms.filters.attentionOnly}
          default={machinesPageAtoms.defaults.filters.attentionOnly}
          text="Attention only"
        />
        <Cell justifyContent="start">
          <Toggle value={attentionOnly} onSelected={setAttentionOnly} />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};
