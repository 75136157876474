import { FC, ReactNode } from 'react';
import { Conditional } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { userAtoms } from '../../global-atoms/users';
import { UserRole } from '../../constants/users';

interface ConditionalRouteByRolesProps {
  element: ReactNode | null;
  fallback: ReactNode | null;
  roles: UserRole[];
}

export const ConditionalRouteByRoles: FC<ConditionalRouteByRolesProps> = (
  props,
) => {
  const { element, fallback, roles = [] } = props;
  const { type } = useRecoilValue(userAtoms.user);

  return (
    <Conditional condition={roles.includes(type)} Fallback={fallback}>
      {element}
    </Conditional>
  );
};
