import { capitalize } from 'lodash';
import { getInventoriesInLocationUrl } from '../../hooks/use-get-location-columns';
import { LocationState } from '../../locations.types';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import routePaths from '../../../../config/route-paths';
import {
  formatAsLocaleNumber,
  formatDate,
} from '../../../../helpers/formatter';
import { DOWNLOAD_LONG_FORMAT } from '../../../../constants/dates';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';

export const downloadColumns: DownloadColumn<LocationState>[] = [
  {
    getValue: ({ name }) => {
      return name;
    },
    header: 'Name',
  },
  {
    getValue: ({ type }) => {
      return capitalize(type);
    },
    header: 'Type',
  },
  {
    getValue: ({ workshop }) => {
      return workshop;
    },
    header: 'Workshop',
  },
  {
    getValue: ({ stock }) => {
      return typeof stock === 'number'
        ? formatAsLinkForCSV({
            text: `#${stock}`,
            url: `${getAdminUrl() + routePaths.stocks}/${stock}`,
          })
        : stock;
    },
    header: 'Stock #',
  },
  {
    getValue: ({ parts }) => {
      return formatAsLocaleNumber(parts);
    },
    header: 'Parts in location',
  },
  {
    getValue: ({ id, totalInventories }) => {
      return totalInventories
        ? formatAsLinkForCSV({
            text: totalInventories,
            url: getAdminUrl() + getInventoriesInLocationUrl(id),
          })
        : totalInventories;
    },
    header: 'Inventories in location',
  },
  {
    getValue: ({ statusInfo }) => {
      return formatDate(Number(statusInfo?.createdAt), DOWNLOAD_LONG_FORMAT);
    },
    header: 'Created',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Status',
  },
];
