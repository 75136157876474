import { Badge } from 'gantri-components';
import { useTheme } from 'styled-components';
import { BatchStatusBadgeProps } from './batch-badge.types';

export const BatchStatusBadge = (props: BatchStatusBadgeProps) => {
  const { status } = props;

  const theme = useTheme();

  const badgeProps =
    status === 'Active'
      ? {
          borderColor: theme.colors.surfaces.green.t2,
          color: theme.colors.surfaces.green.t1,
          textColor: theme.colors.typography.t1,
        }
      : {
          borderColor: theme.colors.surfaces.monochrome.t3,
          color: theme.colors.surfaces.monochrome.overlayLevel2,
          textColor: theme.colors.typography.t1,
        };

  return <Badge {...badgeProps} text={status} />;
};
