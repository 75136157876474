import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  BulkCompletePrintPrepareQueueSetJobsArgs,
  BulkCompletePrintPrepareQueueSetJobsResponse,
} from './bulk-complete-print-prepare-queue-set-jobs.types';

export const bulkCompletePrintPrepareQueueSetJobs = (
  args: BulkCompletePrintPrepareQueueSetJobsArgs,
) => {
  const { queueId, setIndex, ...body } = args;

  return axios
    .put<BulkCompletePrintPrepareQueueSetJobsResponse>(
      `${coreApiUrl}/queue/print-prepare/${queueId}/${setIndex}/bulk/complete`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
