import { UsersSortingField } from '../../../api/users/routes/get-paginated-users/get-paginated-users.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { UsersFilterType } from './users-filters.types';

export const usersFiltersDefaults: {
  filters: {
    active: boolean;
    types: UsersFilterType[];
  };
  sortBy: SortBy<UsersSortingField>;
} = {
  filters: {
    active: false,
    types: [],
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'USERS_FILTERS';

export const pageName: AtomPageName = 'users';

export const usersPageAtoms = {
  defaults: usersFiltersDefaults,
  filters: {
    active: getGenericAtomFamily({
      defaultValue: usersFiltersDefaults.filters.active,
      key: `${atomKeyPrefix}-active`,
    })(pageName),
    types: getGenericAtomFamily({
      defaultValue: usersFiltersDefaults.filters.types,
      key: `${atomKeyPrefix}-types`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<UsersSortingField>>({
    defaultValue: usersFiltersDefaults.sortBy,
  })(pageName),
};
