import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedBatchesArgs,
  GetPaginatedBatchesResponse,
} from './get-paginated-batches.types';

export const getPaginatedBatches = (args: GetPaginatedBatchesArgs) => {
  const {
    colors,
    count,
    createdDateRange,
    page,
    search,
    sortingField,
    sortingType,
    status,
  } = args;
  const { from, to } = createdDateRange;
  const filter = { colors, createdEndDate: to, createdStartDate: from, status };

  return axios.post<GetPaginatedBatchesResponse>(
    `${coreApiUrl}/batch/paginated`,
    {
      data: {
        filter,
        limit: count,
        page,
        search,
        sortingField,
        sortingType,
      },
    },
  );
};
