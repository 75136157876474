import {
  Button,
  Checkbox,
  Modal,
  Stack,
  FileUploader,
  HandleFileSelected,
  SelectedFileDetails,
} from 'gantri-components';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { productsApi } from '../../../../../../../../api';
import { Handout } from '../../../../../../../../api/products/products.types';
import { useInvalidateFetchProductCache } from '../../../../../../../../api/products/routes';
import { useCloudinaryFileUploader } from '../../../../../../../../hooks/use-cloudinary-file-uploader';
import { GetFileDetailsArgs } from '../../../../../../../../hooks/use-cloudinary-file-uploader/helpers/get-file-details/get-file-details.types';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import { productAtoms } from '../../../../../../product.atoms';
import { UploadHandoutModalProps } from './upload-handout-modal.types';

export const UploadHandoutModal = (props: UploadHandoutModalProps) => {
  const { handout, onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const { invalidateFetchProductCache } = useInvalidateFetchProductCache();

  const productId = product?.id;

  const [handoutData, setHandoutData] = useState<Handout>({
    ...handout,
    productId,
    type: 'Handout',
  });
  const [disableUpload, setDisableUpload] = useState<boolean>(true);
  const defaultSelectedFileDetails: SelectedFileDetails = {
    fileBlob: undefined,
    fileExtension: undefined,
    fileName: undefined,
    fileSize: undefined,
    metadata: undefined,
  };
  const [selectedFileDetails, setSelectedFileDetails] =
    useState<SelectedFileDetails>(defaultSelectedFileDetails);

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const onFileSelected: HandleFileSelected = async (fileDetails) => {
    setDisableUpload(false);
    setSelectedFileDetails(fileDetails);
  };

  const fileDetailArgs: GetFileDetailsArgs<'products'> = handoutData.universal
    ? {
        directory: 'products',
        fileType: 'universal-handouts',
        identifiers: {},
      }
    : {
        directory: 'products',
        fileType: 'handouts',
        identifiers: { productId },
      };

  const { fileUploaderProps, handleUploadFile } =
    useCloudinaryFileUploader<'products'>({
      ...fileDetailArgs,
      fileName: selectedFileDetails.fileName,
      onFileDelete: async () => {
        setSelectedFileDetails(defaultSelectedFileDetails);
      },
      onFileSelected,
    });

  const uploadFile = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { fileName } = await handleUploadFile(selectedFileDetails);

        await productsApi.uploadHandoutOrInstructions({
          ...handoutData,
          link: fileName,
          name: fileName,
        });

        await invalidateFetchProductCache();
        onClose();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to upload file',
        });
      }
    });
  };

  const toggleUniversalStatus = () => {
    setHandoutData({
      ...handoutData,
      universal: !handoutData.universal,
    });
  };

  useEffect(() => {
    setHandoutData({
      ...handout,
      productId,
      type: 'Handout',
    });
  }, [handout]);

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={disableUpload}
            processing={processing}
            size="large"
            text="Upload"
            variant="primary"
            onClick={uploadFile}
          />
        </>
      }
      header="Upload Handout"
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack alignContent="center">
        <FileUploader
          {...fileUploaderProps}
          buttonProps={{
            width: '100%',
          }}
          expectedExtensions={['pdf']}
          purifyFileName
          variant={selectedFileDetails?.fileName ? 'plain' : 'button'}
        />
        <Checkbox
          checked={handoutData?.universal}
          labelText="This is a universal handout"
          onSelected={toggleUniversalStatus}
        />
      </Stack>
    </Modal>
  );
};
