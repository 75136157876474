import { Conditional, Stack, Typography } from 'gantri-components';
import { useFormikContext } from 'formik';
import { useRecoilState } from 'recoil';
import { useCallback } from 'react';
import { CreatePartFromRadio } from './components/create-part-from-radio';
import { BatchPartCreationFormik } from '../../../../batch-part-creation.types';
import { CreatePartFromProductForm } from './components/create-part-from-product-form';
import { batchPartCreationAtoms } from '../../../../batch-part-creation.atoms';
import { useGetBatchTemplateDetails } from './components/create-part-from-product-form/hooks/use-get-batch-template-details';
import { useFetchBatchProductsAndParts } from '../../../../../../api/products/routes';
import { CreatePartFromRadioContent } from './components/create-part-from-radio/create-part-from-radio.types';

export const SelectTemplatePage = () => {
  const [allProducts, setAllProducts] = useRecoilState(
    batchPartCreationAtoms.allProducts,
  );

  const {
    disableProductPartAndColor,
    productColorOptions,
    productNameOptions,
    productPartOptions,
  } = useGetBatchTemplateDetails({ allProducts });

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  useFetchBatchProductsAndParts({
    enabled:
      formikValues.template === 'CREATE_FROM_PRODUCT' && !allProducts.length,
    onSuccess: async (data) => {
      if (data?.products) {
        setAllProducts(data.products);
      }
    },
  });

  const RadioContentCreatePartFromProduct: CreatePartFromRadioContent =
    useCallback(
      ({ isChecked }) => {
        return (
          <Conditional condition={isChecked && !!allProducts.length}>
            <CreatePartFromProductForm
              disableProductPartAndColor={disableProductPartAndColor}
              productColorOptions={productColorOptions}
              productNameOptions={productNameOptions}
              productPartOptions={productPartOptions}
            />
          </Conditional>
        );
      },
      [
        disableProductPartAndColor,
        productColorOptions,
        productNameOptions,
        productPartOptions,
      ],
    );

  return (
    <Stack gap="1.6rem" height="unset">
      <Typography text="Template" variant="h3" />
      <Typography text="Select a product template or create a part from scratch." />
      <CreatePartFromRadio
        Content={RadioContentCreatePartFromProduct}
        groupValue="CREATE_FROM_PRODUCT"
        label="Create from Product"
        subLabel="Use an existing template from a product part"
        value={formikValues.template}
      />
      <CreatePartFromRadio
        groupValue="CREATE_FROM_SCRATCH"
        label="Create from Scratch"
        subLabel="Customize all jobs for this part"
        value={formikValues.template}
      />
    </Stack>
  );
};
