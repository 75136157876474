import {
  Typography,
  Flex,
  Button,
  Grid,
  getFolderStructure,
  Conditional,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import {
  useDeleteCloudinaryFile,
  useUploadCloudinaryFile,
} from '../../../../../../../../../../api/cloudinary/routes';
import { UploadFileResponse } from '../../../../../../../../../../api/cloudinary/routes/upload-file/upload-file.types';
import {
  deleteBlob,
  useUploadBlob,
} from '../../../../../../../../../../helpers/firebase';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { useFullScreenExitWarningModal } from '../../../../../../../../../jobs/components/modals/common/full-screen-modal-header';
import { HandleCloseModal } from '../../../../../../../../../jobs/components/modals/hooks/use-handle-close-modal/use-handle-close-modal.types';
import { productAtoms } from '../../../../../../../../product.atoms';
import { BulkUploadExitWarningModalContent } from '../bulk-upload-exit-warning-modal-content';
import { bulkUploadModalAtoms } from '../../bulk-upload-modal.atoms';
import { exitWarningModalHeader } from '../../bulk-upload-modal.constants';
import { SkuLevelCloudinaryFileType } from '../../../../../../../../../../api/products/products.types';
import {
  SkuAssetUploadFileData,
  AssetsBySku,
  SkuLevelAssetData,
} from '../../../../../../../../../../api/products/routes/fetch-sku-assets/fetch-sku-assets.types';
import { useUpdateSkuAssets } from '../../../../../../../../../../api/products/routes/update-sku-assets';
import { handleAsyncRequestsSequentially } from '../../../../../../../../../../helpers/handle-async-requests-sequentially';
import { getTransformProductAssetFileName } from '../../../../../../helpers/get-transform-product-asset-file-name';

interface BulkUploadModalFooterProps {
  handleCloseModal: HandleCloseModal;
}

export const BulkUploadModalFooter = (props: BulkUploadModalFooterProps) => {
  const { handleCloseModal } = props;

  const product = useRecoilValue(productAtoms.product);
  const numFilesAdded = useRecoilValue(bulkUploadModalAtoms.numFilesAdded);
  const numFilesRemoved = useRecoilValue(bulkUploadModalAtoms.numFilesRemoved);
  const assetsBySku = useRecoilValue(bulkUploadModalAtoms.assetsBySku);
  const filesToDeleteCloudinary = useRecoilValue(
    bulkUploadModalAtoms.filesToDeleteCloudinary,
  );
  const filesToDeleteFirebase = useRecoilValue(
    bulkUploadModalAtoms.filesToDeleteFirebase,
  );
  const disableSave = useRecoilValue(bulkUploadModalAtoms.disableSave);

  const {
    notifyError,
    onInterceptProcessingRequest,
    onInterceptRequest,
    processing,
  } = useNotification();

  const { onDeleteFile: onDeleteCloudinaryFile } = useDeleteCloudinaryFile();

  const handleUploadCloudinaryAsset = useHandleUploadCloudinaryAsset();

  const uploadBlob = useUploadBlob();

  const [showExitWarningModal] = useFullScreenExitWarningModal({
    children: <BulkUploadExitWarningModalContent />,
    header: exitWarningModalHeader,
    onConfirm: handleCloseModal,
  });

  const onCancel = async () => {
    if (disableSave) {
      await handleCloseModal();
    } else {
      showExitWarningModal();
    }
  };

  const { onUpdateSkuAssets } = useUpdateSkuAssets();

  const handleSave = async () => {
    // trigger full-page processing indicator
    await onInterceptRequest(async () => {
      // trigger processing state change to disable save button
      return onInterceptProcessingRequest(async () => {
        let numUploaded = 0;

        const assetsBySkuWithUploadData = structuredClone(assetsBySku);

        await Promise.all(
          Object.values(assetsBySku).map(
            async ({
              dimmingPhotoDark,
              dimmingPhotoLight,
              scalePhoto,
              sku,
              usdz,
              whiteBackgroundPhotos,
            }): Promise<UploadFileResponse[]> => {
              const uploadResponses: UploadFileResponse[] = [];

              if (dimmingPhotoDark?.fileBlob) {
                const response = await handleUploadCloudinaryAsset({
                  fileData: dimmingPhotoDark,
                  fileType: 'dimming-photos',
                  sku,
                });

                numUploaded += 1;

                assetsBySkuWithUploadData[sku].dimmingPhotoDark = response;

                uploadResponses.push(response);
              }

              if (dimmingPhotoLight?.fileBlob) {
                const response = await handleUploadCloudinaryAsset({
                  fileData: dimmingPhotoLight,
                  fileType: 'dimming-photos',
                  sku,
                });

                numUploaded += 1;

                assetsBySkuWithUploadData[sku].dimmingPhotoLight = response;

                uploadResponses.push(response);
              }

              if (
                whiteBackgroundPhotos.some(({ fileBlob }) => {
                  return !!fileBlob;
                })
              ) {
                const filesToUpload = whiteBackgroundPhotos.filter(
                  ({ fileBlob }) => {
                    return fileBlob;
                  },
                );

                const response = await handleAsyncRequestsSequentially({
                  callback: async (fileData) => {
                    return handleUploadCloudinaryAsset({
                      fileData,
                      fileType: 'product-photos',
                      sku,
                    });
                  },
                  items: filesToUpload,
                });

                numUploaded += filesToUpload.length;

                const existingAssets = assetsBySkuWithUploadData[
                  sku
                ].whiteBackgroundPhotos.filter(({ fileBlob }) => {
                  return !fileBlob;
                });

                assetsBySkuWithUploadData[sku].whiteBackgroundPhotos = [
                  ...existingAssets,
                  ...response,
                ];

                const hasExistingWhiteBackgroundPhotos =
                  whiteBackgroundPhotos.length &&
                  whiteBackgroundPhotos.length !== filesToUpload.length;

                if (!hasExistingWhiteBackgroundPhotos) {
                  assetsBySkuWithUploadData[sku].selectedWhiteBackgroundPhoto =
                    response[0].fileName;
                }

                uploadResponses.push(...response);
              }

              if (scalePhoto?.fileBlob) {
                const response = await handleUploadCloudinaryAsset({
                  fileData: scalePhoto,
                  fileType: 'scale-photo',
                  sku,
                });

                numUploaded += 1;

                assetsBySkuWithUploadData[sku].scalePhoto = response;

                uploadResponses.push(response);
              }

              if (usdz?.fileBlob) {
                const { fileBlob, fileName, metadata } = usdz;

                const { transformFileName } = getTransformProductAssetFileName({
                  fileType: 'model',
                  sku,
                });

                const formattedFileName = transformFileName({ fileName });

                const response: UploadFileResponse = {
                  fileName: formattedFileName,
                  fileUrl: undefined,
                };

                await uploadBlob({
                  blob: fileBlob,
                  displayLoader: false,
                  filename: formattedFileName,
                  hideUploadNotification: true,
                  metadata: { ...metadata, name: formattedFileName },
                  onComplete: async (url) => {
                    response.fileUrl = url;
                  },
                  path: `products/${product.id}/model`,
                });

                numUploaded += 1;

                assetsBySkuWithUploadData[sku].usdz = response;

                uploadResponses.push(response);
              }

              return uploadResponses;
            },
          ),
        );

        const skuAssets = Object.keys(
          assetsBySkuWithUploadData,
        ).reduce<AssetsBySku>((accumulator, sku) => {
          const data: SkuLevelAssetData = {
            dimmingPhotoDark:
              assetsBySkuWithUploadData[sku].dimmingPhotoDark?.fileName,
            dimmingPhotoLight:
              assetsBySkuWithUploadData[sku].dimmingPhotoLight?.fileName,
            scalePhoto: assetsBySkuWithUploadData[sku].scalePhoto?.fileName,
            selectedWhiteBackgroundPhoto:
              assetsBySkuWithUploadData[sku].selectedWhiteBackgroundPhoto,
            usdz: assetsBySkuWithUploadData[sku].usdz,
            whiteBackgroundPhotos: assetsBySkuWithUploadData[
              sku
            ].whiteBackgroundPhotos.map(({ fileName }) => {
              return fileName;
            }),
          };

          return { ...accumulator, [sku]: data };
        }, {});

        await onUpdateSkuAssets({
          numDeleted:
            filesToDeleteCloudinary.length + filesToDeleteFirebase.length,
          numUploaded,
          productId: product.id,
          skuAssets,
        });

        await Promise.all(
          filesToDeleteCloudinary.map((file) => {
            // TODO - consider adding limiter to this logic to prevent too many requests to cloudinary api
            return onDeleteCloudinaryFile(file);
          }),
        );

        await Promise.all(
          filesToDeleteFirebase.map(({ fileUrl }) => {
            return deleteBlob({ fileUrl, onError: notifyError });
          }),
        );
      });
    });

    await handleCloseModal({ updateOnClose: true });
  };

  return (
    <Grid
      alignItems="center"
      columns="1fr max-content"
      gap="x"
      horizontalPadding="2rem"
      verticalPadding="x"
    >
      <Conditional
        condition={!!numFilesAdded || !!numFilesRemoved}
        Fallback={<Typography color="t2" text="No Changes" />}
      >
        <Typography
          text={[
            numFilesAdded && `${numFilesAdded} added`,
            numFilesRemoved && `${numFilesRemoved} removed`,
          ]
            .filter(Boolean)
            .join(', ')}
        />
      </Conditional>

      <Flex gap="x">
        <Button
          size="large"
          text="Cancel"
          variant="secondary"
          onClick={onCancel}
        />
        <Button
          disabled={disableSave || processing}
          size="large"
          text="Save all Changes"
          onClick={handleSave}
        />
      </Flex>
    </Grid>
  );
};

const useHandleUploadCloudinaryAsset = () => {
  const product = useRecoilValue(productAtoms.product);

  const { onUploadCloudinaryFile } = useUploadCloudinaryFile();

  const handleUpload = async (props: {
    fileData: SkuAssetUploadFileData;
    fileType: SkuLevelCloudinaryFileType;
    sku: string;
  }) => {
    const { fileData, fileType, sku } = props;

    const folder = getFolderStructure<'products'>({
      directory: 'products',
      fileType,
      identifiers: {
        productId: product.id,
        sku,
      },
    });

    const { transformFileName } = getTransformProductAssetFileName({
      fileType,
      sku,
    });

    const publicId = transformFileName({ fileName: fileData.fileName });

    return onUploadCloudinaryFile({
      file: fileData?.fileBlob,
      folder,
      publicId,
      resourceType: 'auto',
    });
  };

  return handleUpload;
};
