import styled, { css } from 'styled-components';

export const StyledReviewItem = styled.div`
  ${({ theme }) => {
    return css`
      padding: 1rem 0;
      border-top: 1px solid ${theme.colors.dividers.t1};

      button,
      button:hover,
      button:focus {
        height: 3.6rem;
        width: 6.5rem;
        min-width: 6.5rem;
        max-width: 6.5rem;
      }
    `;
  }}
`;

export const StyledActions = styled.div`
  ${({ theme }) => {
    return css`
      border-top: 1px solid ${theme.colors.dividers.t1};
      padding-top: 6rem;
      text-align: center;
    `;
  }}
`;
