import { Conditional, Grid, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { Checklist } from '../../../../../common/job-checklist/job-checklist.types';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { filterAgainstFailingChecklistItems } from '../../../../../helpers/filter-against-failing-checklist-items';
import { CompletePrintQaFailReason } from '../../../complete-print-qa-modal.types';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';

export const MachineOfflineContent = () => {
  const checklist = useRecoilValue(printQcModalAtoms.checklist);
  const selectedPrintJob = useRecoilValue(printQcModalAtoms.selectedPrintJob);

  const machine = selectedPrintJob?.machine;
  const isMachineInProgress = useRecoilValue(
    printQcModalAtoms.isMachineInProgress,
  );

  const machineOfflineReasons = filterAgainstFailingChecklistItems(
    checklist,
    ({ failStatusShouldTakeMachineOffline }) => {
      return failStatusShouldTakeMachineOffline;
    },
  );

  const machineOfflineChecklist = machineOfflineReasons.reduce<
    Partial<Checklist<CompletePrintQaFailReason>>
  >((accumulator, reason) => {
    return { ...accumulator, [reason]: checklist[reason] };
  }, {});

  const machineName = machine?.name || 'Machine';

  return (
    <Stack gap="4x">
      <Conditional
        condition={isMachineInProgress}
        Fallback={
          <ModalContentHeading
            color="alert"
            titleText={`${machineName} offlined`}
          />
        }
      >
        <ModalContentHeading
          color="alert"
          titleText={`${machineName} will be offlined`}
        />
      </Conditional>

      <Conditional condition={!!machineOfflineReasons.length}>
        <Stack gap="x">
          <JobFailReasonsList
            checklist={machineOfflineChecklist}
            title="Failure reasons (machine)"
          />
        </Stack>
      </Conditional>

      <Stack gap="x">
        <Typography text="Machine details" variant="h5" />
        <Grid columns="max-content 1fr" gap="1rem">
          <Typography text="Location" textStyle="bold" />
          <Typography color="t1" text={machine?.location || '-'} />

          <Typography text="Machine Type" textStyle="bold" />
          <Typography color="t1" text={machine?.type || '-'} />
        </Grid>
      </Stack>
    </Stack>
  );
};
