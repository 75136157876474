import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startNonProductJobModalAtoms } from '../../../start-non-product-job-modal.atoms';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const setUpdateOnClose = useSetRecoilState(
    startNonProductJobModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(startNonProductJobModalAtoms.step);

  const handleStart = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        await jobsApi.startNonProductJob(job.id);

        setUpdateOnClose(true);
        setStep('UPDATE_INVENTORY');
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to start job.' });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleStart}
      />
    </>
  );
};
