import { Row } from '@tanstack/react-table';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultOrdersGroupBy } from '../../report-orders.constants';
import {
  OrderType,
  ReportOrderQueries,
  ReportOrdersTableData,
} from '../../report-orders.types';

export const getRowHref = (row: Row<ReportOrdersTableData>) => {
  const windowLocation = window.location.origin + window.location.pathname;
  const queries = getActiveQueries<ReportOrderQueries>();
  const { groupBy = defaultOrdersGroupBy, orderType } = queries;

  const type: OrderType = row?.original?.type;
  const isTotalRow = /^total$/i.test(type);
  const isRefundPage = groupBy && orderType;

  if (isTotalRow || isRefundPage) {
    return;
  }

  const queryString = convertQueriesToString({
    groupBy,
    orderType: type,
  });

  return windowLocation + queryString;
};
