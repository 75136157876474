import { ButtonToggleItem } from '../../../../../../../components/common/button-toggle-group/button-toggle-group.types';
import {
  DashboardReportDateRangeType,
  DashboardSummaryDateRangeType,
} from '../../../../../dashboard.types';
import { CustomRangeToggle } from './components/custom-range-toggle';

export const dashboardSummaryDateRangeTypeOptions: DashboardSummaryDateRangeType[] =
  ['Yesterday', 'Past week', 'Past month'];

const reportDateOptions: DashboardReportDateRangeType[] = [
  'Past week',
  'Past month',
  'Past 3 months',
  'Custom',
];

export const dashboardSummaryDateRangeToggleItems: ButtonToggleItem<DashboardSummaryDateRangeType>[] =
  dashboardSummaryDateRangeTypeOptions;

export const dashboardReportDateRangeToggleItems: ButtonToggleItem<DashboardReportDateRangeType>[] =
  reportDateOptions.map((value) => {
    const item: ButtonToggleItem<DashboardReportDateRangeType> =
      value === 'Custom'
        ? {
            Component: CustomRangeToggle,
            value,
          }
        : value;

    return item;
  });
