import { Modal } from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { jobStatuses } from '../../../../../../../constants/options';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { JobDetailsPanelLarge } from '../../../common/job-details-panel-large';
import { StartJobModalProps } from '../../start-job.types';
import { startNonProductJobModalAtoms } from './start-non-product-job-modal.atoms';
import { StartNonProductJobFooter } from './components/start-non-product-job-footer';
import { useResetRecoilAtomsOnMount } from '../../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { StartNonProductJobContent } from './components/start-non-product-job-content';
import { useHandleCloseModal } from '../../../hooks/use-handle-close-modal';

export const NonProductJobModal = (props: StartJobModalProps) => {
  const { job, onClose, onJobUpdated } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    startNonProductJobModalAtoms,
  );

  const updateOnClose = useRecoilValue(
    startNonProductJobModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(startNonProductJobModalAtoms.step);
  const setInventoryRequests = useSetRecoilState(
    startNonProductJobModalAtoms.inventoryRequests,
  );
  const setNotes = useSetRecoilState(startNonProductJobModalAtoms.notes);

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  useEffect(() => {
    setInventoryRequests(job.inventoryRequests || []);
    setNotes(job.notes);

    if (job.status === jobStatuses.inProgress) {
      setStep('UPDATE_INVENTORY');
    }
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            sectionsToShow={['assigned to', 'instructions']}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <StartNonProductJobFooter
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <StartNonProductJobContent job={job} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
