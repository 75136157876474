import { SearchField, Stack, Typography, Table } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { RequiredInput } from '../../../../common/inputs';
import { DateWithTooltip } from './components/date-with-tooltip';
import { locationModalContentDefaultProps } from './content.constants';
import { ContentProps } from './content.types';
import { LocationTimeline } from '../../../../../api/parts/routes/update-location/update-location.types';
import { VLMLocation } from '../../../../../api/locations/routes/get-available-locations/get-available-locations.types';

export const Content = (props: ContentProps) => {
  const {
    availableLocations,
    locationLabel,
    locationTimelines,
    partId,
    selectedLocation,
    setSearch,
    setSelectedLocation,
    stockId,
  } = props;

  const columns: ColumnDef<LocationTimeline>[] = [
    {
      accessorKey: 'location',
      cell: ({
        getValue,
      }: CellContext<LocationTimeline, LocationTimeline['location']>) => {
        return <Typography text={getValue()} />;
      },
      header: 'Name',
      minSize: 150,
    },
    {
      accessorKey: 'createdAtSec',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<LocationTimeline, LocationTimeline['createdAtSec']>) => {
        return (
          <DateWithTooltip
            date={new Date(getValue())}
            userData={original.user}
            userId={original.userId}
          />
        );
      },
      header: 'Date & time',
    },
  ];

  return (
    <Stack gap="2.1rem" height="38.5rem">
      <Stack gap="0.4rem" paddingBottom=".3rem">
        <RequiredInput isRequired label={locationLabel}>
          <SearchField
            idProperty="id"
            items={[
              { id: -1, name: 'Unassigned' } as VLMLocation,
              ...availableLocations,
            ]}
            labelProperty="name"
            placeholder="Select a location"
            searchMode="external"
            value={!!selectedLocation?.value ? selectedLocation.value : -1}
            onSelect={(item) => {
              setSelectedLocation(item?.id === -1 ? null : item);
            }}
            onTextChange={setSearch}
          />
        </RequiredInput>
      </Stack>
      <Typography text="Location History" variant="h4" />
      <Table columns={columns} data={locationTimelines || []} />
      {!locationTimelines?.length && (
        <Typography
          align="center"
          color="t2"
          text={getNoLocationHistoryMessage({ partId, stockId })}
        />
      )}
    </Stack>
  );
};

Content.defaultProps = locationModalContentDefaultProps;

const getNoLocationHistoryMessage = ({
  partId,
  stockId,
}: {
  partId?: number;
  stockId?: number;
}) => {
  const prefix = 'No location history';

  if (partId) {
    return `${prefix} for Part #${partId}.`;
  }

  if (stockId) {
    return `${prefix} for Stock #${stockId}.`;
  }

  return `${prefix}.`;
};
