import { useRecoilState, useSetRecoilState } from 'recoil';
import { useCompleteJob } from '../../../../../../../../api/jobs/routes';
import { jobTypeOptions } from '../../../../../../../../constants/options';
import { InventoryChecklistFooter } from '../../../../common/inventory-checklist-footer';
import { StartJobFooter } from '../../../../common/start-job-footer';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { packPackModalAtoms } from '../../complete-packing-modal.atoms';
import { PackPackJobFooterProps } from './pack-pack-job-footer.types';

export const PackPackJobFooter = (props: PackPackJobFooterProps) => {
  const { handleCloseModal } = props;

  const [step, setStep] = useRecoilState(packPackModalAtoms.step);
  const [inventoryRequests, setInventoryRequests] = useRecoilState(
    packPackModalAtoms.inventoryRequests,
  );
  const [job, setJob] = useRecoilState(packPackModalAtoms.job);
  const setUpdateOnClose = useSetRecoilState(packPackModalAtoms.updateOnClose);

  const { onCompleteJob } = useCompleteJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  switch (step) {
    case 'CONFIRM_START':
      return (
        <StartJobFooter
          job={job}
          nextStep="INVENTORY_CHECKLIST"
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
          size={fullScreenModalFooterButtonSize}
          onClose={handleCloseModal}
        />
      );

    case 'INVENTORY_CHECKLIST':
      return (
        <InventoryChecklistFooter
          inventoryRequests={inventoryRequests}
          job={job}
          setInventoryRequests={setInventoryRequests}
          setJob={setJob}
          size={fullScreenModalFooterButtonSize}
          text={`Confirm ${jobTypeOptions.pack} Completion`}
          onComplete={async () => {
            await onCompleteJob({ jobId: job.id });
          }}
        />
      );

    default:
      return null;
  }
};
