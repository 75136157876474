export const machineOfflineText = {
  completed120Jobs:
    'Prints on build plate have reached 120, and machine requires build plate maintenance.',
  completed60Jobs:
    'Prints on build plate have reached 60, and machine requires build plate maintenance.',
  lifespanExceeded:
    "Some of the machine's components have exceeded their lifespan.",
  printHoursExceeded:
    'Print-time has exceeded maintenance print hours, and machine requires maintenance.',
  title: 'Maintenance required',
};
