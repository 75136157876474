import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { ShipTransactionArgs } from './ship-transaction.types';

export const shipTransaction = (args: ShipTransactionArgs) => {
  const { id } = args;

  return axios.post<TransactionResponse>(`${baseApiUrl}/shippo/create`, { id });
};
