import { atom } from 'recoil';
import { NotificationDetails } from '../../hooks/useNotification/use-notification.types';

const atomKeyPrefix = 'NOTIFICATION';

export const notificationAtoms = {
  notifications: atom<NotificationDetails[]>({
    default: [],
    key: `${atomKeyPrefix}-notifications`,
  }),
};
