import styled from 'styled-components';

export const StyledTooltip = styled.div`
  position: fixed;
  white-space: break-spaces;
  padding: 8px 16px;
  display: none;
  background-color: var(--colors-modal-background-color);
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  font-weight: regular;
  color: ${({ theme }) => {
    return theme.colors.typography.t2;
  }}

  &:hover {
    display: block;
  }
`;

export const StyledTooltipContainer = styled.div`
  position: relative;
  display: inline-flex;
  cursor: pointer;

  &:hover ${StyledTooltip} {
    display: block;
  }
`;

export const StyledTooltipBase = styled.div`
  position: relative;
  display: flex;
`;
