import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  CreateTransactionArgs,
  CreateTransActionResponse,
} from './create-transaction.types';

export const createTransaction = (args: CreateTransactionArgs) => {
  const {
    address = '',
    discount,
    discountType,
    email = '',
    invoice,
    locationInstruction,
    notes = '',
    originalTransactionId = null,
    resaleCertificate,
    shipCost,
    shippingType = 'cheapest',
    stockInfoIds,
    stocks,
    type = '',
  } = args;

  return axios.post<CreateTransActionResponse>(`${baseApiUrl}/transactions`, {
    address,
    discount,
    discountType,
    email,
    invoice,
    locationInstruction,
    notes,
    originalTransactionId,
    resaleCertificate,
    shipCost,
    shippingType,
    stockInfoIds,
    stocks,
    type,
  });
};
