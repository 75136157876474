import {
  DirectoryName,
  getFileUrl,
  getFolderStructure,
} from 'gantri-components';
import { GetFileDetailsArgs } from './get-file-details.types';

/**
 * https://www.notion.so/gantri/Asset-Directory-Structure-674ff9974018475c89a78249950b4216
 *
 * While not strictly necessary, it's highly recommended that you copy the `directory` value to the type definition to ensure highly accurate types:
 *
 * @example
 * const { fileUrl, folder } = getFileDetails<'products'>({
 *   directory: 'products',
 *   fileName: valueProp.fileName,
 *   fileType: 'value-props',
 *   identifiers: {
 *     productId: product.id,
 *   },
 * });
 *
 * `fileName` is only used if rendering the uploaded image file.
 */
export const getFileDetails = <Directory extends DirectoryName>(
  args: GetFileDetailsArgs<Directory>,
) => {
  const fileUrl = getFileUrl<Directory>({ ...args, fileName: args.fileName });
  const folder = getFolderStructure<Directory>(args);

  return { fileUrl, folder };
};
