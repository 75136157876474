import {
  Conditional,
  Grid,
  productColorsMap,
  Radio,
  SearchField,
  Stack,
  Typography,
} from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { useEffect } from 'react';
import { batchApi } from '../../../../../../../../../api';
import { BatchSearchRenderItem } from './components/batch-search-render-item';
import { BatchSearchHeader } from './components/batch-search-header';
import { updateMethodOptions } from './update-batch-content.constants';
import { batchSearchFilterFn } from './helpers/batch-search-filter-fn';
import { UpdateBatchContentProps } from './update-batch-content.types';
import { BatchDropdownItem } from './components/batch-search-render-item/batch-search-render-item.types';
import { unpaintedColor } from '../../../../../../../../../constants/colors';

export const UpdateBatchContent = (props: UpdateBatchContentProps) => {
  const {
    atomActiveBatches,
    atomBatchUpdateMethod,
    atomNewBatchId,
    currentBatchId,
    minHeight,
    showRemoveOption,
  } = props;

  const [newBatchId, setNewBatchId] = useRecoilState(atomNewBatchId);
  const resetNewBatchId = useResetRecoilState(atomNewBatchId);
  const [batchUpdateMethod, setBatchUpdateMethod] = useRecoilState(
    atomBatchUpdateMethod,
  );
  const [activeBatches, setActiveBatches] = useRecoilState(atomActiveBatches);

  const isAddToExistingSelected = batchUpdateMethod === 'ADD_TO_EXISTING_BATCH';

  useEffect(() => {
    if (batchUpdateMethod && !isAddToExistingSelected) {
      resetNewBatchId();
    }
  }, [batchUpdateMethod]);

  useAsync(async () => {
    if (!activeBatches.length) {
      const { data } = await batchApi.getAllActiveBatches();

      const activeBatches = data.batches.map((batch) => {
        const numColors = batch?.colors?.length;
        const colorCode = batch?.colors?.[0];
        const colorsText =
          numColors > 1
            ? `${numColors} colors`
            : numColors === 1
            ? productColorsMap[colorCode]?.shortColorName || unpaintedColor.name
            : 'Empty';
        const searchBatchValue = [batch?.id, colorsText]
          .filter(Boolean)
          .join(', ');

        const batchDropdownItem: BatchDropdownItem =
          batch.id === currentBatchId
            ? { ...batch, disabled: true, label: searchBatchValue }
            : { ...batch, label: searchBatchValue };

        return batchDropdownItem;
      });

      setActiveBatches(activeBatches);
    }
  }, []);

  return (
    <Stack gap="x" minHeight={minHeight}>
      <Typography text="Select an option:" />
      <Grid alignItems="center" columns="max-content 1fr" gap="0.8rem">
        {updateMethodOptions.map((option) => {
          const isRemoveOption = option.value === 'REMOVE_FROM_BATCH';
          const isOptionRendered = !(isRemoveOption && !showRemoveOption);

          return (
            <Conditional key={option.value} condition={isOptionRendered}>
              <Radio
                groupValue={option.value}
                value={batchUpdateMethod}
                onSelected={(item) => {
                  setBatchUpdateMethod(item);
                }}
              />
              <Typography
                text={option.label}
                onClick={() => {
                  setBatchUpdateMethod(option.value);
                }}
              />
              <Conditional
                condition={
                  isAddToExistingSelected &&
                  option.value === 'ADD_TO_EXISTING_BATCH'
                }
              >
                <div data-radio-spacer="" />
                <Stack gap="x">
                  <SearchField
                    filterFn={batchSearchFilterFn}
                    header={<BatchSearchHeader />}
                    idProperty="id"
                    items={activeBatches}
                    placeholder="Search batch ID or color..."
                    renderItem={(batch) => {
                      return (
                        <BatchSearchRenderItem
                          batch={batch}
                          currentBatchId={currentBatchId}
                        />
                      );
                    }}
                    searchable
                    value={newBatchId}
                    onSelect={(item) => {
                      setNewBatchId(item?.id);
                    }}
                  />
                  <Typography text="Required" variant="p3" />
                </Stack>
              </Conditional>
            </Conditional>
          );
        })}
      </Grid>
    </Stack>
  );
};
