import { Button } from 'gantri-components';
import { MouseEvent } from 'react';
import { jobStatuses } from '../../../../../../constants/options';
import { JobActionCellProps } from './job-action-cell.types';

export const JobActionCell = (props: JobActionCellProps) => {
  const { onComplete, onStart, row } = props;

  const job = row?.original;
  const isReady = job.status === jobStatuses.ready;
  const isInProgress = job.status === jobStatuses.inProgress;

  const handleOnClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (isInProgress) {
      await onComplete(job.id);
    } else {
      await onStart(job.id);
    }
  };

  return isReady || isInProgress ? (
    <Button
      minWidth="10rem"
      text={isInProgress ? 'Complete' : 'Start'}
      variant="secondary"
      onClick={handleOnClick}
    />
  ) : null;
};
