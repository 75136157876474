import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import {
  Address,
  CoreUser,
} from '../../api/users/routes/get-admin/get-admin.types';

const atomKeyPrefix = 'USER';

const { persistAtom } = recoilPersist();

export const userAtoms = {
  addresses: atom<Address[]>({
    default: [],
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-addresses`,
  }),
  firebaseToken: atom<string>({
    default: '',
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-firebase-token`,
  }),
  theme: atom<'light' | 'dark'>({
    default: 'light',
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-theme`,
  }),
  user: atom<CoreUser>({
    default: {} as CoreUser,
    effects_UNSTABLE: [persistAtom],
    key: `${atomKeyPrefix}-user`,
  }),
};
