import { useMemo, useState } from 'react';
import {
  Cell,
  Dropdown,
  Grid,
  SearchField,
  TextField,
  Toggle,
  Typography,
} from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { inventoryTypes } from '../../../constants/options';
import { stockStatusOptions } from './inventories-filters.constants';
import { inventoriesPageAtoms } from './inventories-filters.atoms';
import { InventoryType } from '../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import { useFetchAllVendorNamesQuery } from '../../../api/vendors/routes';
import { useGetLocationsQuery } from '../../../api/locations/routes/get-locations/get-locations.query';
import { useGetAllProducts } from '../../../api/products/routes';
import { useGetProductsListItems } from '../../common/select-product-form/components/select-product-form-content/components/product-search/hooks/use-get-products-list-items';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';

const InventoriesFilter = () => {
  const [active, setActive] = useRecoilState(
    inventoriesPageAtoms.filters.active,
  );
  const [discarded, setDiscarded] = useRecoilState(
    inventoriesPageAtoms.filters.discarded,
  );
  const [product, setProduct] = useRecoilState(
    inventoriesPageAtoms.filters.product,
  );
  const resetProduct = useResetRecoilState(
    inventoriesPageAtoms.filters.product,
  );
  const [locationId, setLocationId] = useRecoilState(
    inventoriesPageAtoms.filters.locationId,
  );
  const resetLocationId = useResetRecoilState(
    inventoriesPageAtoms.filters.locationId,
  );
  const [stockStatus, setStockStatus] = useRecoilState(
    inventoriesPageAtoms.filters.stockStatus,
  );
  const [types, setTypes] = useRecoilState(inventoriesPageAtoms.filters.types);
  const [vendor, setVendor] = useRecoilState(
    inventoriesPageAtoms.filters.vendor,
  );
  const resetVendor = useResetRecoilState(inventoriesPageAtoms.filters.vendor);

  const [vendorSearch, setVendorSearch] = useState<string>('');

  const updateDiscard = (field: keyof typeof discarded, value: string) => {
    setDiscarded({ ...discarded, [field]: Number(value) });
  };

  const typesOptions = Object.values(inventoryTypes).map((name) => {
    return {
      label: name,
      value: name,
    };
  });

  const { max, min } = discarded;

  const { data, isLoading: isLoadingProducts } = useGetAllProducts();

  const productOptions = useGetProductsListItems(data?.products);

  const { isLoading: isLoadingVendors, vendors } = useFetchAllVendorNamesQuery({
    query: vendorSearch,
  });

  const { isLoading: isLoadingLocations, locations } = useGetLocationsQuery({
    type: 'inventory',
  });

  const locationItems = useMemo(() => {
    return !isLoadingLocations && locations
      ? locations.filter(({ totalInventories }) => {
          return !!totalInventories;
        })
      : [];
  }, [locations, isLoadingLocations]);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.types}
          default={inventoriesPageAtoms.defaults.filters.types}
          text="Type"
        />
        <Dropdown
          items={typesOptions}
          placeholder="Select Type"
          value={String(types[0])}
          onSelect={({ value }: { value: InventoryType }) => {
            setTypes([value]);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.vendor}
          default={inventoriesPageAtoms.defaults.filters.vendor}
          text="Vendor"
        />
        <SearchField
          autoSelectFirst={false}
          disabled={isLoadingVendors}
          idProperty="id"
          items={isLoadingVendors ? [] : vendors ?? []}
          labelProperty="name"
          maxHeight="33rem"
          placeholder="Select vendor"
          searchMode="external"
          value={vendor}
          onSelect={(vendor) => {
            setVendor(vendor?.id);
            setVendorSearch('');

            if (!vendor?.id) {
              resetVendor();
            }
          }}
          onTextChange={debounce(setVendorSearch, 300)}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.active}
          default={inventoriesPageAtoms.defaults.filters.active}
          text="Active Status only"
        />
        <Cell justifyContent="start">
          <Toggle name="active-status" value={active} onSelected={setActive} />
        </Cell>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.stockStatus}
          default={inventoriesPageAtoms.defaults.filters.stockStatus}
          text="Stock Status"
        />
        <AtomsCheckboxList
          atom={inventoriesPageAtoms.filters.stockStatus}
          gridProps={{ columns: 2 }}
          items={stockStatusOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.discarded}
          default={inventoriesPageAtoms.defaults.filters.discarded}
          text="% Discarded"
        />
        <Grid alignItems="center" columnGap="2x" columns="1fr max-content 1fr">
          <TextField
            ariaLabel="min-discarded"
            debounce={250}
            maxValue={100}
            minValue={0}
            rightIcon={<Typography text="%" />}
            type="number"
            value={String(min ?? 0)}
            onTextChange={(value) => {
              return updateDiscard('min', value);
            }}
          />
          <Typography text="to" />
          <TextField
            ariaLabel="max-discarded"
            debounce={250}
            maxValue={100}
            minValue={0}
            rightIcon={<Typography text="%" />}
            type="number"
            value={String(max ?? 100)}
            onTextChange={(value) => {
              return updateDiscard('max', value);
            }}
          />
        </Grid>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.product}
          default={inventoriesPageAtoms.defaults.filters.product}
          text="Product"
        />
        <SearchField
          disabled={isLoadingProducts}
          items={isLoadingProducts ? [] : productOptions}
          maxHeight="33rem"
          placeholder="Select product"
          value={product}
          onSelect={(item) => {
            setProduct(item?.value);

            if (!item?.value) {
              resetProduct();
            }
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoriesPageAtoms.filters.locationId}
          default={inventoriesPageAtoms.defaults.filters.locationId}
          text="Location"
        />
        <SearchField
          disabled={isLoadingLocations}
          idProperty="id"
          items={locationItems}
          labelProperty="name"
          maxHeight="33rem"
          placeholder="Select location"
          value={locationId}
          onSelect={(location) => {
            setLocationId(location?.id);

            if (!location?.id) {
              resetLocationId();
            }
          }}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default InventoriesFilter;
