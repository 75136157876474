import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { ConfirmStartContentProps } from './confirm-start-content.types';

export const ConfirmStartContent = (props: ConfirmStartContentProps) => {
  const { job } = props;

  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to start ${job.step} ${job.type}?`}
      titleText="Start job"
    />
  );
};
