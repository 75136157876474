import {
  Cell,
  Conditional,
  Flex,
  Grid,
  Slider,
  Stack,
  Typography,
} from 'gantri-components';
import { useCallback } from 'react';
import { PrimarySurfaceSwatch } from '../../../../../primary-surface-swatch';
import { SecondarySurfaceSwatch } from '../../../../../secondary-surface-swatch';
import {
  ChecklistItem,
  ChecklistSurfaceRating,
} from '../../../../job-checklist.types';
import { StyledToolWrapper } from '../../advanced-checklist.styles';
import { getSurfacesStatus } from '../../helpers/get-surfaces-status';
import { ToolIcon } from '../tool-icon';
import { SurfaceSlidersProps } from './surface-sliders.types';

export const SurfaceSliders = (props: SurfaceSlidersProps) => {
  const { checklistItem, material, updateChecklistItem } = props;
  const { ratingPrimarySurface, ratingSecondarySurface, slider } =
    checklistItem;
  const { failPrimaryAt, failSecondaryAt } = slider;

  const renderSecondarySlider =
    !!ratingPrimarySurface && ratingPrimarySurface < failPrimaryAt;

  const onSurfaceSlidersChange = useCallback(
    ({
      type,
      value,
    }: {
      type: 'primary' | 'secondary';
      value: ChecklistSurfaceRating;
    }) => {
      const isPrimarySlider = type === 'primary';
      const tempChecklistItem: ChecklistItem = isPrimarySlider
        ? { ...checklistItem, ratingPrimarySurface: value }
        : { ...checklistItem, ratingSecondarySurface: value };
      const { isPrimarySurfaceFailing, isSecondarySurfaceFailing } =
        getSurfacesStatus(tempChecklistItem);
      const isFailing = isPrimarySurfaceFailing || isSecondarySurfaceFailing;

      const updatedChecklistItem: ChecklistItem = {
        ...tempChecklistItem,
        ratingSecondarySurface: isPrimarySurfaceFailing
          ? null
          : tempChecklistItem.ratingSecondarySurface,
        status: isFailing ? 'Fail' : 'Pass',
      };

      updateChecklistItem(updatedChecklistItem);
    },
    [checklistItem],
  );

  return (
    <>
      <StyledToolWrapper
        alignItems="center"
        columns={{
          lg: 'repeat(2, max-content)',
          md: '1fr max-content',
        }}
        horizontalPadding="1rem"
        justifyContent="center"
        paddingBottom="0.8rem"
        paddingTop="1.2rem"
      >
        <Typography
          text="Use the following tool to determine the severity:"
          variant="p3"
        />
        <Flex alignItems="center" gap="0.8rem">
          <ToolIcon tool={checklistItem.tool} />
          <Typography text={checklistItem.tool} textStyle="bold" variant="p3" />
        </Flex>
      </StyledToolWrapper>
      <Stack gap="0.8rem">
        <Grid columns="max-content 1fr" gap="1.6rem">
          <Cell paddingTop="1rem">
            <PrimarySurfaceSwatch material={material} />
          </Cell>
          <Slider
            failStep={failPrimaryAt}
            max={5}
            min={1}
            value={ratingPrimarySurface}
            onValueChange={(value: ChecklistSurfaceRating) => {
              onSurfaceSlidersChange({ type: 'primary', value });
            }}
          />
        </Grid>
        <Conditional condition={renderSecondarySlider}>
          <Grid columns="max-content 1fr" gap="1.6rem">
            <Cell paddingTop="1rem">
              <SecondarySurfaceSwatch material={material} />
            </Cell>
            <Slider
              failStep={failSecondaryAt}
              max={5}
              min={1}
              value={ratingSecondarySurface}
              onValueChange={(value: ChecklistSurfaceRating) => {
                onSurfaceSlidersChange({ type: 'secondary', value });
              }}
            />
          </Grid>
        </Conditional>
      </Stack>
    </>
  );
};
