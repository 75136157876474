import axios from 'axios';
import { environment } from '../../../../environment';
import {
  UpdateDesignerArgs,
  UpdateDesignerResponse,
} from './update-designer.types';

export const updateDesigner = ({ designerInfo }: UpdateDesignerArgs) => {
  return axios.put<UpdateDesignerResponse>(`${environment.API_URL}/designer`, {
    ...designerInfo,
  });
};
