import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { SaveTableColumnsResponse } from './save-table-columns.types';
import { TableSyncState } from '../../../../hooks/use-table-columns-sync/use-table-columns-sync.types';

export const saveTableColumns = (
  data: TableSyncState,
  configs?: AxiosRequestConfig,
) => {
  return axios.post<SaveTableColumnsResponse>(
    `${coreApiUrl}/users/table-config`,
    data,
    configs,
  );
};
