import { media } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledChartWrapper = styled.div`
  padding: 2.4rem 0;
  ${({ theme }) => {
    return css`
      ${media.greaterThan('md')`
      padding: 0.8rem 0 2.4rem;
    `}
    `;
  }}
`;
