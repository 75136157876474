import { Dropdown } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { MachineIssueDropdownProps } from './machine-issue-dropdown.type';

export const MachineIssueDropdown = (props: MachineIssueDropdownProps) => {
  const { atom, default: defaultValue, items } = props;

  const [[selectionOption], setSelectedOptions] = useRecoilState(atom);

  const formattedItems = items.map((label) => {
    return { label, value: [label] };
  });

  const allItems = [
    { label: 'Please select...', value: defaultValue },
    ...formattedItems,
  ];

  const onSelect = ({ value }: { value: string[] }) => {
    setSelectedOptions(value);
  };

  return (
    <Dropdown
      items={allItems}
      placeholder="Please select..."
      value={selectionOption}
      onSelect={onSelect}
    />
  );
};
