import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { ReviewOnlineMachinesFooterProps } from './review-online-machines-footer.types';

export const ReviewOnlineMachinesFooter = (
  props: ReviewOnlineMachinesFooterProps,
) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={handleCloseModal}
    />
  );
};
