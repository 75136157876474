import { CellContext } from '@tanstack/react-table';
import { Conditional, Icon, Tooltip, Flex } from 'gantri-components';
import { UserActivity } from '../../../../api/users/routes/get-user-activities/get-user-activities.types';
import { StyledAnchor } from '../../../../components/common/styled-anchor';

export const URLDataCell = ({
  getValue,
}: CellContext<UserActivity, UserActivity['source']>) => {
  const source = getValue();

  return (
    <Flex alignItems="center" gap=".5rem">
      <Conditional condition={!!source}>
        <StyledAnchor href={source}>
          <Icon color="link" name="arrows:arrow_external" />
        </StyledAnchor>
        <Tooltip
          description={source}
          overlayContainerStyles={{
            position: 'relative',
            top: '2px',
            zIndex: 2,
          }}
          position="top"
        >
          <Icon name="alert:i_circle" top="2px" />
        </Tooltip>
      </Conditional>
    </Flex>
  );
};
