import { ProductColorCode } from 'gantri-components';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
  SortBy,
} from '../../common/table/hooks';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import {
  ProductReviewsSortingField,
  ProductReviewStatus,
} from '../../../api/users/routes';

export const productReviewsFiltersDefaults: {
  filters: {
    colors: ProductColorCode[];
    productId: number;
    statuses: ProductReviewStatus[];
  };
  sortBy: SortBy<ProductReviewsSortingField>;
} = {
  filters: {
    colors: [],
    productId: null,
    statuses: [],
  },
  sortBy: {
    sortingField: 'user',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'PRODUCT_REVIEWS_FILTERS';

export const pageName: AtomPageName = 'product-reviews';

export const productReviewsPageAtoms = {
  defaults: productReviewsFiltersDefaults,
  filters: {
    colors: getGenericAtomFamily({
      defaultValue: productReviewsFiltersDefaults.filters.colors,
      key: `${atomKeyPrefix}-colors`,
    })(pageName),
    productId: getGenericAtomFamily({
      defaultValue: productReviewsFiltersDefaults.filters.productId,
      key: `${atomKeyPrefix}-productId`,
    })(pageName),
    statuses: getGenericAtomFamily({
      defaultValue: productReviewsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<ProductReviewsSortingField>>({
    defaultValue: productReviewsFiltersDefaults.sortBy,
  })(pageName),
};
