import { FC, PropsWithChildren } from 'react';
import {
  StyledTooltipLink,
  StyledMenuTooltip,
  StyledPopupTypography,
} from './menu-tooltip.styles';
import { MenuTooltipProps, TooltipLinkProps } from './menu-tooltip.types';

const TooltipLink: FC<PropsWithChildren<TooltipLinkProps>> = (props) => {
  const { onLinkClicked, route, textStyle } = props;

  return (
    <StyledTooltipLink
      style={{ textDecoration: 'none' }}
      to={route.path}
      onClick={onLinkClicked}
    >
      <StyledPopupTypography
        color="t2"
        text={route.name}
        textStyle={textStyle}
      />
    </StyledTooltipLink>
  );
};

export const MenuTooltip: FC<MenuTooltipProps> = (props) => {
  const { route, ...rest } = props;

  return (
    <StyledMenuTooltip columns={1} gap="unset">
      <TooltipLink
        route={route}
        textStyle={route.subPaths?.length ? 'bold' : 'regular'}
        {...rest}
      />
      {route.subPaths?.map((subRoute, i) => {
        const key = `${subRoute.name}-${i}`;

        return <TooltipLink key={key} route={subRoute} {...rest} />;
      })}
    </StyledMenuTooltip>
  );
};
