import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { updateMarketplaceColors } from './update-marketplace-colors';
import {
  UpdateMarketplaceColorsArgs,
  UpdateMarketplaceColorsResponse,
} from './update-marketplace-colors.types';

export const useUpdateMarketplaceColors = <
  TransformedData = UpdateMarketplaceColorsResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateMarketplaceColorsArgs,
    UpdateMarketplaceColorsResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateMarketplaceColors, ...rest } =
    useGenericMutateQuery({
      fallbackErrorMessage: 'Unable to update marketplace colors.',
      mutationFn: updateMarketplaceColors,
      ...props,
    });

  return { ...rest, onUpdateMarketplaceColors };
};
