import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Conditional, Grid, Typography } from 'gantri-components';

interface FormItemProps {
  isEditMode?: boolean;
  label?: ReactNode | string;
  orientation?: 'column' | 'row';
  viewElement: ReactNode | string;
}

export const FormItem: FC<PropsWithChildren<FormItemProps>> = (props) => {
  const {
    children,
    isEditMode,
    label,
    orientation = 'column',
    viewElement,
  } = props;

  const gap = orientation === 'column' ? 'x' : '1.2rem';
  const columns = orientation === 'column' ? '1fr' : '18rem 1fr';

  return (
    <Grid columns={columns} gap={gap} width="100%">
      <Conditional condition={typeof label === 'string'} Fallback={label ?? ''}>
        <Typography text={String(label)} textStyle="bold" />
      </Conditional>

      <Conditional
        condition={isEditMode}
        Fallback={
          typeof viewElement === 'string' ? (
            <Typography text={viewElement} />
          ) : (
            viewElement
          )
        }
      >
        {children}
      </Conditional>
    </Grid>
  );
};
