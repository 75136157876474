import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { jobsDetailsPanelIdQueryKey } from '../../../../../../../../components/jobs-details-panel-wrapper';
import routePaths from '../../../../../../../../config/route-paths';
import { convertQueriesToString } from '../../../../../../../../helpers/checks';
import { useRouter } from '../../../../../../../../hooks';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { JobAddedFooterProps } from './job-added-footer.types';

export const JobAddedFooter = (props: JobAddedFooterProps) => {
  const { handleCloseModal } = props;

  const { navigate } = useRouter();

  const newJobId = useRecoilValue(addJobModalAtoms.newJobId);

  return (
    <>
      <Button
        size="large"
        text="Done"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        size="large"
        text="View job details"
        onClick={async () => {
          await handleCloseModal();

          const queries = convertQueriesToString({
            [jobsDetailsPanelIdQueryKey]: newJobId,
          });

          navigate(`${routePaths.jobs}${queries}`);
        }}
      />
    </>
  );
};
