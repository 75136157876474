import { useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-prepare-queue-modal.constants';
import { JobSetCompletedContent } from '../step-job-set-completed/job-set-completed-content';
import { JobSetPrepareContent } from '../step-job-set-prepare/job-set-prepare-content';
import { JobSetStartContent } from '../step-job-set-start/job-set-start-content';
import { QueueDeletedContent } from '../step-queue-deleted/queue-deleted-content';

export const PrintPrepareQueueModalContent = () => {
  const step = useRecoilValue(printPrepareQueueModalAtoms.step);

  switch (step) {
    case printPrepareQueueModalSteps.jobSetStart:
      return <JobSetStartContent />;

    case printPrepareQueueModalSteps.jobSetPrepare:
      return <JobSetPrepareContent />;

    case printPrepareQueueModalSteps.jobSetCompleted:
      return <JobSetCompletedContent />;

    case printPrepareQueueModalSteps.queueDeleted:
      return <QueueDeletedContent />;

    default:
      return null;
  }
};
