import { useEffect, useMemo, useState } from 'react';
import {
  addMonths,
  compareAsc,
  getDay,
  getDaysInMonth,
  isAfter,
  isBefore,
  isWithinInterval,
  setDate,
  startOfMonth,
} from 'date-fns';
import {
  addDays,
  formatDateWithoutTimezone,
  makeDateInstance,
} from '../../../../../helpers/formatter';
import { UseDatePickerProps } from './use-date-picker.types';

export const useDatePicker = (props: UseDatePickerProps) => {
  const {
    allowFutureDays,
    allowFutureDaysOnly,
    from: fromDate,
    initialValue,
    maximumDatesInRange,
    onDateChanged,
    range,
    resetDates,
    to: toDate,
  } = props;
  const [currentDate, setCurrentDate] = useState<Date>(
    toDate || fromDate || new Date(),
  );
  const [from, setFrom] = useState<Date>(fromDate);
  const [to, setTo] = useState<Date>(toDate);
  const [opened, setOpened] = useState(false);

  const toggleOpened = (value: boolean) => {
    return setOpened(value ?? !opened);
  };

  const goToPreviousMonth = () => {
    return setCurrentDate(addMonths(currentDate, -1));
  };

  const goToNextMonth = () => {
    return setCurrentDate(addMonths(currentDate, 1));
  };

  const getDaysInMonthArray = () => {
    const days = getDaysInMonth(currentDate);
    const temporal = setDate(currentDate, 1);
    const weekday = getDay(temporal);
    const emptyDays = Array.from({ length: weekday }, (_, index) => {
      return (index + 1) * -1;
    });

    return emptyDays.concat(
      Array.from({ length: days }, (_, index) => {
        return index + 1;
      }),
    );
  };

  const getDayStatus = (day: number) => {
    const temporal = setDate(makeDateInstance(currentDate), day);
    const fromDate = makeDateInstance(from);
    const toDate = makeDateInstance(to);

    const selected =
      (from && !compareAsc(fromDate, temporal)) ||
      (to && !compareAsc(toDate, temporal));

    let interval = {
      end: toDate,
      start: fromDate,
    };

    if (from && to && compareAsc(fromDate, toDate) === 1) {
      interval = {
        end: fromDate,
        start: toDate,
      };
    }

    const allowedInRange =
      !maximumDatesInRange ||
      !from ||
      to ||
      isWithinInterval(temporal, {
        end: addDays(fromDate, maximumDatesInRange),
        start: addDays(fromDate, maximumDatesInRange * -1),
      });

    return {
      disabled:
        (!allowFutureDays && isAfter(temporal, new Date())) ||
        (allowFutureDaysOnly && isBefore(temporal, new Date())) ||
        !allowedInRange,
      inRange: range && from && to && isWithinInterval(temporal, interval),
      selected,
    };
  };

  useEffect(() => {
    if (onDateChanged) {
      if (range) {
        if ((from && to) || (!from && !to)) {
          onDateChanged({
            formattedFrom:
              from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
            formattedTo: to && formatDateWithoutTimezone(to, 'MM/dd/yyyy'),
            formattedValue:
              from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
            from,
            to,
            value: from,
          });
        }
      } else {
        onDateChanged({
          formattedFrom: from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
          formattedTo: to && formatDateWithoutTimezone(to, 'MM/dd/yyyy'),
          formattedValue: from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
          from,
          to,
          value: from,
        });
      }
    }

    if (opened && (!range || (from && to))) {
      toggleOpened(false);
    }
  }, [from, to]);

  useEffect(() => {
    setFrom(fromDate || null);

    if (!fromDate) {
      setTo(null);
    }
  }, [formatDateWithoutTimezone(fromDate || '')]);

  useEffect(() => {
    if (initialValue) {
      if (range) {
        setFrom(initialValue.from ? new Date(initialValue.from) : null);
        setTo(initialValue.to ? new Date(initialValue.to) : null);
      }
    }
  }, [formatDateWithoutTimezone(initialValue ? initialValue.to : '')]);

  const isNextMonthActive = useMemo(() => {
    return (
      allowFutureDays ||
      !isAfter(startOfMonth(addMonths(currentDate, 1)), new Date())
    );
  }, [currentDate, allowFutureDays]);

  const isPreviousMonthActive = useMemo(() => {
    return !(
      allowFutureDaysOnly && isBefore(startOfMonth(currentDate), new Date())
    );
  }, [currentDate, allowFutureDaysOnly]);

  const setDay = (day: number) => {
    const temporal = setDate(currentDate, day);

    if (range) {
      if (!from) {
        setFrom(temporal);
      } else if (!to) {
        if (from <= temporal) {
          setTo(temporal);
        } else {
          setTo(from);
          setFrom(temporal);
        }
      } else {
        setFrom(temporal);
        setTo(null);
      }
    } else {
      setFrom(temporal);
    }
  };

  const onResetDates = () => {
    setFrom(resetDates.from ? new Date(resetDates.from) : null);
    setTo(resetDates.to ? new Date(resetDates.to) : null);
  };

  const selection = {
    formattedFrom: from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
    formattedMonth: formatDateWithoutTimezone(currentDate, 'MMM yyyy'),
    formattedTo: to && formatDateWithoutTimezone(to, 'MM/dd/yyyy'),
    formattedValue: from && formatDateWithoutTimezone(from, 'MM/dd/yyyy'),
    from,
    to,
    value: from,
  };

  return {
    daysInMonth: getDaysInMonthArray(),
    getDayStatus,
    goToNextMonth,
    goToPreviousMonth,
    isNextMonthActive,
    isPreviousMonthActive,
    onResetDates,
    opened,
    selection,
    setDay,
    toggleOpened,
  };
};
