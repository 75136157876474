import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  UpdateSubscriptionArgs,
  UpdateSubscriptionResponse,
} from './update-subscription.types';

export const updateSubscription = ({
  subscriber,
  userId,
}: UpdateSubscriptionArgs) => {
  return axios.put<UpdateSubscriptionResponse>(
    `${baseApiUrl}/subscribe/${userId}`,
    {
      subscriber,
    },
  );
};
