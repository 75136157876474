import { atomFamily } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { TableSyncState } from './use-table-columns-sync.types';

const { persistAtom } = recoilPersist();

export const tableColumnsSyncState = atomFamily<TableSyncState, string>({
  default: null,
  effects_UNSTABLE: [persistAtom],
  key: 'table-columns-sync/state',
});

export const tableOriginalColumnsSyncState = atomFamily<TableSyncState, string>(
  {
    default: null,
    effects_UNSTABLE: [persistAtom],
    key: 'table-original-columns-sync/state',
  },
);
