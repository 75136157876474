import { memo, ChangeEvent } from 'react';
import {
  Grid,
  Cell,
  Typography,
  TextField,
  TextArea,
  Toggle,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import {
  StyledSectionContainer,
  StyledSwitchWrapper,
} from '../../designer-feature.styles';
import {
  StorefrontSettingProps,
  TextFieldProp,
} from './storefront-settings.types';
import { useCloudinaryFileUploader } from '../../../../hooks/use-cloudinary-file-uploader';
import { getActiveEnv } from '../../../../helpers/get-active-env';

const Section = (props: StorefrontSettingProps) => {
  const { feature, id, updateFeatureInfo, userId } = props;

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    updateFeatureInfo({
      key: 'feature',
      type: 'key',
      value: {
        ...feature,
        photo: fileName,
      },
    });
  };

  const onFileDelete: HandleDeleteFile = async () => {
    updateFeatureInfo({
      key: 'feature',
      type: 'key',
      value: {
        ...feature,
        photo: '',
      },
    });
  };

  const { fileUploaderProps } = useCloudinaryFileUploader<'designers'>({
    directory: 'designers',
    fileName: feature.photo,
    fileType: 'collection-pages',
    handleUploadsComplete,
    identifiers: {
      collectionId: id,
      userId,
    },
    onFileDelete,
    transformFileName: ({ applyUuid, removeWhitespace }) => {
      return applyUuid(removeWhitespace('cover-image'));
    },
  });

  const onTextFieldChange = (property: TextFieldProp) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      updateFeatureInfo({
        key: 'feature',
        type: 'key',
        value: {
          ...feature,
          [property]: event.target.value,
        },
      });
    };
  };

  const toggleNewFeature = (value: boolean) => {
    updateFeatureInfo({
      key: 'feature',
      type: 'key',
      value: {
        ...feature,
        isNew: value,
      },
    });
  };

  const { isLocalEnv } = getActiveEnv();

  return (
    <StyledSectionContainer>
      <Typography
        marginBottom="1.2rem"
        text="Designer storefront page settings"
        textStyle="bold"
        variant="h4"
      />
      <Typography
        marginBottom="0.8rem"
        text="Note that content will not appear till collection page is published."
      />
      <Grid
        alignItems="baseline"
        columns={{ lg: 'max-content 1fr 1fr 40rem', md: '1fr' }}
      >
        <Cell>
          <Typography marginBottom="5px" text="Image" textStyle="bold" />
          <FileUploader
            {...fileUploaderProps}
            enableCopyUrl={isLocalEnv}
            variant="thumbnail"
          />
        </Cell>
        <Cell>
          <TextField
            debounce={500}
            labelPosition="top"
            labelText="Collection name"
            placeholder="Name.."
            value={feature.name}
            onChange={onTextFieldChange('name')}
          />
        </Cell>
        <Cell>
          <TextArea
            debounce={500}
            labelPosition="top"
            labelText="Collection details"
            minRows={2}
            placeholder="Details...."
            value={feature.details}
            onChange={onTextFieldChange('details')}
          />
        </Cell>
        <Cell>
          <StyledSwitchWrapper>
            <Typography text="New feature?" />
            <Toggle value={feature.isNew} onSelected={toggleNewFeature} />
          </StyledSwitchWrapper>
        </Cell>
      </Grid>
    </StyledSectionContainer>
  );
};

export const StorefrontSettings = memo(Section);
