import firebase from '@firebase/app';
import { getPurifiedFileName } from 'gantri-components';
import { useNotification } from '../hooks/useNotification';

export type UploadBlobParams = {
  blob?: Blob;
  displayLoader?: boolean;
  filename: string;
  height?: number;
  hideUploadNotification?: boolean;
  metadata?: unknown;
  onComplete?: (url: string, relativePath?: string) => Promise<void> | void;
  path: string;
  replacing?: boolean;
  type?: 'gcode';
  width?: number;
};

export const useUploadBlob = () => {
  const { hideLoading, notify, notifyError, showLoading } = useNotification();

  return async ({
    blob,
    displayLoader = true,
    filename,
    hideUploadNotification = false,
    metadata = null,
    onComplete,
    path,
    type,
  }: UploadBlobParams) => {
    const storageRef = firebase.storage().ref(path);
    const uploadTask =
      type === 'gcode'
        ? storageRef.child(getPurifiedFileName(filename)).put(blob, metadata)
        : storageRef.child(filename).put(blob, metadata);
    let runningNotificationShown = false;

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              notify('Upload is paused.');

              break;

            case firebase.storage.TaskState.RUNNING:
              if (!runningNotificationShown) {
                if (displayLoader) {
                  showLoading();
                }

                if (!hideUploadNotification) {
                  notify('Upload is running.', { keepOpen: true });
                }

                runningNotificationShown = true;
              }

              break;

            default: {
              break;
            }
          }
        },
        (error) => {
          if (displayLoader) {
            hideLoading();
          }

          notifyError(error.message);
          reject(error.message);
        },
        async () => {
          await uploadTask.snapshot.ref
            .getDownloadURL()
            .then(async (downloadURL) => {
              if (displayLoader) {
                hideLoading();
              }

              if (!hideUploadNotification) {
                notify('Upload complete.');
              }

              // Do not decode the download URL as it causes the URL to be invalid when downloading for some reason
              await onComplete?.(downloadURL, uploadTask.snapshot.ref.fullPath);
              resolve(downloadURL);
            });
        },
      );
    });
  };
};

type DeleteBlobParams = {
  fileUrl: string;
  onComplete?: () => Promise<void> | void;
  onError?: (error: any) => void;
  showErrors?: boolean;
};

export const deleteBlob = ({
  fileUrl,
  onComplete,
  onError,
  showErrors = true,
}: DeleteBlobParams) => {
  // todo: we are temporarily removing the ability to delete the files from firebase,
  // todo: makes sure update removeFileFromFirebase function when this is enabled.
  // until we finish our investigation of the issue with the 404 images, so if it happens again,
  // we would still have the previous images which we can
  // const fileRef = firebase.storage().refFromURL(fileUrl);
  // fileRef.delete().then(
  //   () => {
  //     notify('This file has been deleted.');
  //
  if (onComplete) {
    onComplete();
  }
  //   },
  //   error => {
  //     hideLoading();
  //
  //     if (onError) {
  //       onError(error);
  //     }
  //
  //     if (showErrors) {
  //       handleCatchError({
  //         dispatch,
  //         err: { message: error.message_ },
  //       });
  //     }
  //   },
  // );
};

export const getExtension = (fileName) => {
  return (fileName || '').split('.').pop();
};

export const getNameWithoutExtension = (fileName) => {
  return (fileName || '').split('.')[0];
};

export const deleteTemporalFile = (fileUrl: string): Promise<void> => {
  return firebase.storage().refFromURL(fileUrl).delete();
};
