import { ChecklistItem } from '../../../../common/job-checklist/job-checklist.types';

export const getAdvancedInfoValue = (checklistItem: ChecklistItem): number => {
  const {
    failStatusShouldTakeMachineOffline,
    ratingPrimarySurface,
    ratingSecondarySurface,
    slider,
    status,
  } = checklistItem;

  if (slider) {
    const largestValue = Math.max(
      ratingPrimarySurface,
      ratingSecondarySurface || 1,
    );

    return largestValue;
  }

  if (status === 'Pass') {
    return 1;
  }

  if (failStatusShouldTakeMachineOffline) {
    return 5;
  }

  return 3;
};
