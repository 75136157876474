import { Button } from 'gantri-components';
import { useToggle } from 'react-use';
import { jobsApi } from '../../../../../../api';
import { DuplicateType } from '../../../../../../api/jobs/routes/fail-qa-job/fail-qa-job.types';
import { useNotification } from '../../../../../../hooks/useNotification';
import { getFailJobReasonArg } from '../../helpers/get-fail-job-reason-arg';
import { NextStep } from '../job-checklist/job-checklist.types';
import { submitRecommendedNextStepButtonDefaultProps } from './submit-recommended-next-step-button.presets';
import { SubmitRecommendedNextStepButtonProps } from './submit-recommended-next-step-button.types';

export const SubmitRecommendedNextStepButton = (
  props: SubmitRecommendedNextStepButtonProps,
) => {
  const {
    checklist,
    jobId,
    nextStepDetails,
    onFailCallback,
    partId,
    selectedNextStep,
    setDuplicatedJobs,
    setNewParts,
    setStep,
    setUpdateOnClose,
    size,
  } = props;

  const [processing, toggleProcessing] = useToggle(false);
  const { notify, notifyAxiosError } = useNotification();

  const getDuplicateType = (selectedNextStep: NextStep): DuplicateType => {
    switch (selectedNextStep) {
      case 'JOB_FAILED_REFINISH_PAINT_ONLY':
        return 'Finish_Paint';

      case 'JOB_FAILED_REFINISH_TARGETED_REWORK':
        return 'Finish_Targeted';

      case 'JOB_FAILED_REFINISH_COMPLETE_REWORK':
        return 'Finish_Complete';

      case 'JOB_FAILED_RESTART':
      default:
        return 'Restart';
    }
  };

  const handleFailJob = async () => {
    try {
      toggleProcessing();

      const reason = getFailJobReasonArg(checklist);
      const duplicateType = getDuplicateType(selectedNextStep);
      const { duplicatedJobs, newParts, notice } = await jobsApi.failQaJob(
        jobId,
        {
          duplicateType,
          partsData:
            selectedNextStep === 'JOB_FAILED_RESTART'
              ? [
                  {
                    details: nextStepDetails,
                    id: partId,
                    type: 'Restart',
                  },
                ]
              : [],
          reason,
        },
      );

      notify(notice);

      await onFailCallback?.(selectedNextStep);

      setStep(selectedNextStep);
      setUpdateOnClose(true);
      setDuplicatedJobs(duplicatedJobs);
      setNewParts(newParts);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'An error occurred when failing the job.',
      });
    } finally {
      toggleProcessing();
    }
  };

  return (
    <Button
      disabled={selectedNextStep === 'JOB_FAILED_RESTART' && !nextStepDetails}
      processing={processing}
      size={size}
      text="Next"
      onClick={handleFailJob}
    />
  );
};

SubmitRecommendedNextStepButton.defaultProps =
  submitRecommendedNextStepButtonDefaultProps;
