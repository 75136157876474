import { memo } from 'react';
import { TextArea, Cell } from 'gantri-components';
import { StyledFieldLabel } from '../../../../../../designer-styles';
import { BioQuoteProps } from './bio-quote.types';

const Section = (props: BioQuoteProps) => {
  const { bio, handleInputChange, quote } = props;

  return (
    <>
      <Cell>
        <StyledFieldLabel text="Bio" textStyle="bold" variant="p2" />
        <TextArea
          minRows={4}
          placeholder="A few lines about the designer..."
          value={bio}
          onTextChange={handleInputChange('bio')}
        />
      </Cell>
      <Cell>
        <StyledFieldLabel text="Quote" textStyle="bold" variant="p2" />
        <TextArea
          minRows={4}
          placeholder="A quote to reference..."
          value={quote}
          onTextChange={handleInputChange('quote')}
        />
      </Cell>
    </>
  );
};

export const BioQuote = memo(Section);
