import { Button, Modal, Typography, useModal } from 'gantri-components';
import { useNotification } from '../../../useNotification';
import { jobsApi } from '../../../../api';
import { UseCompletePreviousJobsModalProps } from './use-complete-previous-jobs-modal.types';

export const useCompletePreviousJobsModal = (
  props: UseCompletePreviousJobsModalProps,
) => {
  const { jobId, onJobUpdate } = props;
  const { notify, notifyAxiosError, processing, setProcessing } =
    useNotification();

  const modalActions = useModal(() => {
    const [_showModal, hideModal] = modalActions;

    const handleCompletePrevJobs = async () => {
      try {
        setProcessing(true);

        const { data } = await jobsApi.completePreviousJobs(jobId);

        notify(data.notice);

        await onJobUpdate();

        hideModal();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to complete previous jobs.',
        });
      } finally {
        setProcessing(false);
      }
    };

    return (
      <Modal
        footer={
          <>
            <Button
              size="large"
              text="Cancel"
              variant="secondary"
              onClick={hideModal}
            />
            <Button
              processing={processing}
              size="large"
              text="Confirm"
              onClick={handleCompletePrevJobs}
            />
          </>
        }
        header="Complete previous jobs?"
        width={{ lg: '40rem', md: '100%' }}
        onClose={hideModal}
      >
        <Typography text="Are you sure you wish to complete all previous jobs?" />
      </Modal>
    );
  }, [jobId, processing]);

  return modalActions;
};
