import { Link, useParams } from 'react-router-dom';
import { Typography, Grid, Cell, Icon } from 'gantri-components';
import { Design } from '../../../../api/designs/routes/fetch-design/fetch-design.types';
import { MetaData } from '../../../../components/meta-data/metaData';

export const DesignHeading = ({ design }: { design: Design }) => {
  const { designId } = useParams<{ designId: string }>();

  return (
    <>
      <MetaData title={`Design ${design.name}`} />
      <Grid columns={1} gap="30px">
        <Cell>
          <Link to={`/designs/${designId}`}>
            <Typography
              color="link"
              icon={
                <Icon color="link" name="arrows:arrow_left_24" size="1.9rem" />
              }
              iconGap="0.8rem"
              iconPosition="left"
              text="Back to overview"
            />
          </Link>
        </Cell>
        <Cell>
          <Typography text={design.name} textStyle="bold" variant="h2" />
          <Typography color="link" text={design.designerName} variant="p2" />
        </Cell>
      </Grid>
    </>
  );
};
