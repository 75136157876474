import {
  Button,
  Cell,
  Conditional,
  Icon,
  Modal,
  Stack,
  TextField,
  Typography,
} from 'gantri-components';
import { inventoryPurchasesApi } from '../../../../api/inventory-purchases';
import DatePicker from '../../../../components/common/date-picker';
import { Label } from '../../../../components/label';
import { currencyOptions } from '../../../../constants/inventory-purchases';
import { formatDate } from '../../../../helpers/formatter';
import { useNotification } from '../../../../hooks/useNotification';
import { CompleteInventoryPurchaseModalProps } from './complete-inventory-purchase-modal.types';

export const CompleteInventoryPurchaseModal = (
  props: CompleteInventoryPurchaseModalProps,
) => {
  const {
    editedInventoryPurchase,
    onClose,
    updateEditedInventoryPurchaseWith,
  } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const completeInventoryPurchase = async () => {
    try {
      showLoading();

      const { data } = await inventoryPurchasesApi.completeInventoryPurchase({
        completedAt: editedInventoryPurchase.completedAt,
        id: editedInventoryPurchase.id,
        totalCost: editedInventoryPurchase.totalCost,
        units: editedInventoryPurchase.units,
      });

      onClose(data.purchase);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete inventory purchase.',
      });
    } finally {
      hideLoading();
    }
  };

  const totalQtyInShipments = editedInventoryPurchase.shipments.reduce<number>(
    (totalQty, { quantity }) => {
      return totalQty + quantity;
    },
    0,
  );

  const currencySymbol =
    currencyOptions[editedInventoryPurchase.currency].symbol;

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={() => {
              onClose();
            }}
          />
          <Button
            disabled={
              !editedInventoryPurchase.totalCost ||
              !editedInventoryPurchase.completedAt
            }
            size="large"
            text="Confirm"
            onClick={completeInventoryPurchase}
          />
        </>
      }
      header="Complete Purchase"
      width={{ lg: '42rem', md: '100%' }}
      onClose={() => {
        onClose();
      }}
    >
      <Stack gap="2rem">
        <Typography
          text="Complete all shipments and close out purchase."
          textStyle="bold"
        />
        <Cell gap="0.6rem">
          <Label paddingBottom=".5x" text="Inventory Name" />
          <Typography text={editedInventoryPurchase.inventory.name} />
        </Cell>
        <Cell gap="0.6rem">
          <Label paddingBottom=".5x" text="Number of Shipments" />
          <Typography text={editedInventoryPurchase.shipments.length} />
        </Cell>
        <Cell gap="0.6rem">
          <Label paddingBottom=".5x" text="Total Inventory Quantity" />
          <Typography text={totalQtyInShipments} />
          <Conditional
            condition={totalQtyInShipments < editedInventoryPurchase.units}
          >
            <Typography
              color="alert"
              icon={
                <Icon
                  color="alert"
                  name="alert:warning_triangle"
                  size="1.6rem"
                />
              }
              text="Total quantity delivered is less than purchase quantity."
              variant="p3"
            />
          </Conditional>
        </Cell>
        <Cell gap="0.6rem">
          <Label paddingBottom=".5x" text={`Total Cost ${currencySymbol}`} />
          <TextField
            minValue={1}
            required
            type="number"
            value={String(editedInventoryPurchase.totalCost / 100 || '')}
            onChange={(event) => {
              updateEditedInventoryPurchaseWith({
                totalCost: Number(event.target.value) * 100,
              });
            }}
          />
        </Cell>
        <Cell gap="0.6rem">
          <Label paddingBottom=".5x" text="Completion Date" />
          <DatePicker
            allowFutureDays={false}
            autoWidth
            initialValue={{ from: formatDate(Date.now()) }}
            onDateChanged={({ formattedFrom }) => {
              updateEditedInventoryPurchaseWith({
                completedAt: formattedFrom,
              });
            }}
          />
        </Cell>
      </Stack>
    </Modal>
  );
};
