import { CellContext } from '@tanstack/react-table';
import { ReportJobsGroupBy } from '../../../../api/dashboard/routes/get-report-jobs/get-report-jobs.types';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { NumberCell } from '../../../dashboard/components/common/number-cell';
import { PercentCell } from '../../../dashboard/components/common/percent-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import {
  ReportJobsFailuresTableData,
  ReportJobsTableData,
} from './report-jobs.types';

export const defaultJobsGroupBy: ReportJobsGroupBy = 'productName';

export const getJobsBaseTableColumns = ({
  groupBy,
}: {
  groupBy: ReportJobsGroupBy;
}): DownloadableTableColumn<ReportJobsTableData>[] => {
  const isGroupByProductName = groupBy === 'productName';
  const isGroupByWorkerName = groupBy === 'workerName';
  const isGroupByJobType = groupBy === 'jobType';

  return [
    !isGroupByWorkerName && {
      accessorKey: 'created',
      cell: ({
        getValue,
      }: CellContext<ReportJobsTableData, ReportJobsTableData['created']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Created',
      size: 100,
    },
    !isGroupByWorkerName && {
      accessorKey: 'cancelled',
      cell: ({
        getValue,
      }: CellContext<
        ReportJobsTableData,
        ReportJobsTableData['cancelled']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Cancelled',
      size: 100,
    },
    {
      accessorKey: 'started',
      cell: ({
        getValue,
      }: CellContext<ReportJobsTableData, ReportJobsTableData['started']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Started',
      size: 100,
    },
    {
      accessorKey: 'completed',
      cell: ({
        getValue,
      }: CellContext<
        ReportJobsTableData,
        ReportJobsTableData['completed']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Completed',
      size: 100,
    },
    {
      accessorKey: 'failed',
      cell: ({
        getValue,
      }: CellContext<ReportJobsTableData, ReportJobsTableData['failed']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Failed',
      size: 100,
    },
    {
      accessorKey: 'rejected',
      cell: ({
        getValue,
      }: CellContext<ReportJobsTableData, ReportJobsTableData['rejected']>) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Rejected',
      size: 100,
    },
    (isGroupByProductName || isGroupByJobType) && {
      accessorKey: 'exceededCycleTime',
      cell: ({
        getValue,
      }: CellContext<
        ReportJobsTableData,
        ReportJobsTableData['exceededCycleTime']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: '% Exceeded Cycle Time',
      size: 200,
    },
    {
      accessorKey: 'yield',
      cell: ({
        getValue,
      }: CellContext<ReportJobsTableData, ReportJobsTableData['yield']>) => {
        return <PercentCell value={getValue()} />;
      },
      header: 'Yield %',
      size: 100,
    },
  ].filter(Boolean);
};

export const getJobsBaseSortingOptions = ({
  groupBy,
}: {
  groupBy: ReportJobsGroupBy;
}) => {
  return getSortingOptionsFromColumns(getJobsBaseTableColumns({ groupBy }));
};

export const reportJobFailReasonsTableColumns: DownloadableTableColumn<ReportJobsFailuresTableData>[] =
  [
    {
      accessorKey: 'failed',
      cell: ({
        getValue,
      }: CellContext<
        ReportJobsFailuresTableData,
        ReportJobsFailuresTableData['failed']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Failed',
    },
    {
      accessorKey: 'ofFails',
      cell: ({
        getValue,
      }: CellContext<
        ReportJobsFailuresTableData,
        ReportJobsFailuresTableData['ofFails']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: '% of Fails',
    },
  ];

export const reportJobFailReasonsSortingOptions = getSortingOptionsFromColumns(
  reportJobFailReasonsTableColumns,
);

export const pageName: AtomPageName = 'report-jobs';

export const reportJobsPageAtoms = {
  sortBy: getSortByFilterAtomFamily<
    SortBy<keyof ReportJobsTableData | keyof ReportJobsFailuresTableData>
  >({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
