import { Flex, Table } from 'gantri-components';
import { useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Job } from '../../../../api/jobs/jobs.types';
import { getJobsRowProps } from '../../../../helpers/get-jobs-row-props';
import { useJobComplete } from '../../../jobs/hooks';
import { MachineRepairsTableProps } from './machine-repairs-table.types';
import { UseGetJobColumnsProps } from '../../../jobs/hooks/use-get-job-columns/use-get-job-columns.types';
import { JobDescriptionCell } from '../../../jobs/hooks/use-get-job-columns/components/job-description-cell';
import { JobStatusCell } from '../../../jobs/hooks/use-get-job-columns/components/job-status-cell';
import { JobStartDateCell } from '../../../jobs/hooks/use-get-job-columns/components/job-start-date-cell';
import { JobEndDateCell } from '../../../jobs/hooks/use-get-job-columns/components/job-end-date-cell';
import { JobInventoriesCell } from '../../../jobs/hooks/use-get-job-columns/components/job-inventories-cell';
import { JobMachineIssuesCell } from '../../../jobs/hooks/use-get-job-columns/components/job-machine-issues-cell';
import { JobActionCell } from '../../../jobs/components';
import { JobMenu } from '../../../../components/common/jobMenu/jobMenu';
import { useGetRepairsQuery } from '../../../../api/machines/routes/get-repairs/get-repairs.query';
import { useMachineQueriesCache } from '../../queries-cache';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';

export const getJobColumns = (args: UseGetJobColumnsProps) => {
  const { completeJob, onRefresh, startJob } = args;

  const columns: ColumnDef<Job>[] = [
    {
      accessorKey: 'description',
      cell: JobDescriptionCell,
      header: 'Job Name',
      minSize: 180,
    },
    {
      accessorKey: 'status',
      cell: JobStatusCell,
      header: 'Status',
      minSize: 140,
    },
    {
      accessorKey: 'startDate',
      cell: JobStartDateCell,
      header: 'Start',
      minSize: 80,
    },
    {
      accessorKey: 'endDate',
      cell: JobEndDateCell,
      header: 'End',
      minSize: 80,
    },
    {
      accessorKey: 'inventoryRequests',
      cell: JobInventoriesCell,
      header: 'Inventories',
      size: 180,
    },
    {
      accessorKey: 'machineIssues',
      cell: JobMachineIssuesCell,
      header: 'Machine Issues',
      minSize: 140,
    },
    {
      cell: (props: CellContext<Job, never>) => {
        return (
          <Flex alignItems="center" justifyContent="flex-end">
            <JobActionCell
              {...props}
              onComplete={completeJob}
              onStart={startJob}
            />

            <JobMenu job={props.row.original} onRefresh={onRefresh} />
          </Flex>
        );
      },
      id: 'menu',
      maxSize: 30,
      meta: { label: 'Actions' },
    },
  ];

  return columns;
};

export const MachineRepairsTable = ({
  machineId,
}: MachineRepairsTableProps) => {
  const [page, setPage] = useState<number>(1);
  const { response } = useGetRepairsQuery({ machineId, page });
  const invalidateCache = useMachineQueriesCache();

  const onRefresh = async () => {
    setPage(1);
    await invalidateCache(machineId);
  };

  const handleRepairJob = useJobComplete({
    onCompleteEnd: onRefresh,
  });

  const columns = getJobColumns({
    completeJob: handleRepairJob,
    onRefresh,
    startJob: handleRepairJob,
  });

  const paging = {
    currentPage: page,
    onPageChange: setPage,
    pageCount: response?.maxPages ?? 0,
    total: response?.totalCount ?? 0,
  };

  return (
    <JobsDetailsPanelWrapper records={response?.repairs} onRefresh={onRefresh}>
      <Table
        columns={columns}
        data={response?.repairs ?? []}
        getRowProps={getJobsRowProps}
        highlightHoveredRow
        paging={paging}
        stickyLastColumn
      />
    </JobsDetailsPanelWrapper>
  );
};
