import { useMemo } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { MachineStatusesDataCell } from '../../components/machine-status-data-cell/machine-status-data-cell';
import { MachineIssues } from '../../components/machine-issues';
import { BulkUpdateMachineRecord } from '../../../../api/machines/routes/bulk-update/bulk-update.types';
import { UseGetBulkUpdateMachinesColumnsProps } from './get-bulk-update-machines-columns.types';

export const useGetBulkUpdateMachinesColumns = (
  props?: UseGetBulkUpdateMachinesColumnsProps,
) => {
  const { machines, showIssues } = props || {};

  return useMemo(() => {
    const columns: ColumnDef<BulkUpdateMachineRecord>[] = [
      {
        accessorKey: 'name',
        header: 'Name',
        size: 80,
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<
          BulkUpdateMachineRecord,
          BulkUpdateMachineRecord['status']
        >) => {
          return <MachineStatusesDataCell status={getValue()} />;
        },
        header: 'Status',
        size: 80,
      },
      {
        accessorKey: 'location',
        header: 'Location',
        size: 80,
      },
      {
        cell: ({ row }: CellContext<BulkUpdateMachineRecord, never>) => {
          const machine = machines?.find(({ id }) => {
            return id === row.original.id;
          });

          return <MachineIssues issues={machine?.issues || []} />;
        },
        header: () => {
          return showIssues && 'Open Issues';
        },
        id: 'issues',
      },
    ];

    return columns;
  }, [showIssues, machines]);
};
