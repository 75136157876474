import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  BulkStartBatchArgs,
  BulkStartBatchResponse,
} from './bulk-start-batch.types';

export const bulkStartBatch = (args: BulkStartBatchArgs) => {
  const { batchId, returnBatch } = args;

  return axios.put<BulkStartBatchResponse>(
    `${coreApiUrl}/batch/${batchId}/bulk/start`,
    {
      returnBatch,
    },
  );
};
