import {
  Grid,
  Stack,
  TextArea,
  Typography,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { ChangeEvent } from 'react';
import { useRecoilState } from 'recoil';
import { Label } from '../../../../../../../../components/label';
import { getActiveEnv } from '../../../../../../../../helpers/get-active-env';
import { useCloudinaryFileUploader } from '../../../../../../../../hooks/use-cloudinary-file-uploader';
import { productAtoms } from '../../../../../../product.atoms';
import { BulbChangeInstructionsProps } from './bulb-change-instructions.types';

export const BulbChangeInstructions = (props: BulbChangeInstructionsProps) => {
  const {
    bulbInstructions,
    content,
    imageUrl,
    index,
    onBulbInstructionsSave,
    productId,
    setBulbInstructions,
  } = props;

  const [product, setProduct] = useRecoilState(productAtoms.product);

  const onFileDelete: HandleDeleteFile = async () => {
    const updatedInstructions = bulbInstructions.map((item, i) => {
      return index === i ? { ...item, imageUrl: '' } : item;
    });

    setBulbInstructions(updatedInstructions);
    onBulbInstructionsSave(updatedInstructions);
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileName },
  ]) => {
    const updatedInstructions = bulbInstructions.map((item, i) => {
      return index === i ? { ...item, imageUrl: fileName } : item;
    });

    setBulbInstructions(updatedInstructions);
    onBulbInstructionsSave(updatedInstructions);
  };

  const { fileUploaderProps, handleDeleteFileOnly } =
    useCloudinaryFileUploader<'products'>({
      directory: 'products',
      fileName: imageUrl,
      fileType: 'bulb-change-instructions',
      handleUploadsComplete,
      identifiers: {
        productId,
      },
      onFileDelete,
      showLoading: true,
    });

  const onUpdateBulbInstructionsContent = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const updatedInstructions = bulbInstructions.map((item, i) => {
      const content = event.target.value;

      return i === index ? { ...item, content } : item;
    });

    setBulbInstructions(updatedInstructions);

    setProduct({ ...product, bulbChangeInstructions: updatedInstructions });
  };

  const onBulbInstructionSectionDelete = async () => {
    const updatedInstructions = bulbInstructions.filter((_instructions, i) => {
      return index !== i;
    });

    setBulbInstructions(updatedInstructions);
    onBulbInstructionsSave(updatedInstructions);

    await handleDeleteFileOnly();
  };

  const { isLocalEnv } = getActiveEnv();

  return (
    <Grid
      columns={{ lg: 'max-content 1fr', sm: '1fr' }}
      gap={{ lg: '7x', md: '2x' }}
    >
      <FileUploader
        {...fileUploaderProps}
        enableCopyUrl={isLocalEnv}
        variant="thumbnail"
      />

      <Stack gap=".5x">
        <Label text={`Step ${index + 1}`} />

        <Stack gap="2x">
          <TextArea
            minRows={3}
            value={content || ''}
            onChange={onUpdateBulbInstructionsContent}
          />
          <Typography
            className="cursor-pointer"
            color="alert"
            justifySelf="start"
            text="Remove"
            onClick={onBulbInstructionSectionDelete}
          />
        </Stack>
      </Stack>
    </Grid>
  );
};
