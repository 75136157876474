import { useEffect, useState } from 'react';
import { useModal } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  jobStatuses,
  stockIsTreatedAsCompleted,
} from '../../constants/options';
import { useNotification } from '../useNotification';
import { Job } from '../../api/jobs/jobs.types';
import { useJobComplete, useJobStart } from '../../pages/jobs/hooks';
import { AssignMachineModal } from '../../components/modals/assign-machine-modal';
import { ModalType } from '../../components/modals/assign-machine-modal/assign-machine-modal.types';
import { jobsApi, machinesApi } from '../../api';
import { RejectJobModal } from '../../components/modals/reject-job-modal';
import { useCompletePreviousJobsModal } from './hooks/use-complete-previous-jobs-modal';
import { MoreMenuOption } from '../../components/dropdowns/more-menu/more-menu.types';
import { CancelJobModal } from '../../components/modals/cancel-job-modal';
import { UseJobMenuProps } from './use-job-menu.types';
import { jobsDetailsPanelAtoms } from '../../components/jobs-details-panel-wrapper/components/jobs-details-panel/jobs-details-panel.atoms';
import { getJobInfo } from '../../pages/jobs/helpers/get-job-info';
import { userAtoms } from '../../global-atoms/users';
import { getActiveEnv } from '../../helpers/get-active-env';

export const useJobMenu = (props: UseJobMenuProps) => {
  const { job, onRefresh } = props;

  const setJobIdInDetailsPanel = useSetRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );
  const [viewInEditMode, setViewInEditMode] = useRecoilState(
    jobsDetailsPanelAtoms.viewInEditMode,
  );

  const openDetailsPanel = () => {
    setJobIdInDetailsPanel(job.id);

    if (viewInEditMode) {
      setViewInEditMode(false);
    }
  };

  const openDetailsPanelInEditMode = () => {
    openDetailsPanel();
    setViewInEditMode(true);
  };

  const [assignedPrinter, setAssignedPrinter] = useState<number>();

  const [machineModalType, setMachineModalType] = useState<ModalType>('ASSIGN');

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const { isAdmin, type } = useRecoilValue(userAtoms.user);
  const isLead = type === 'Lead';

  const startJob = useJobStart({
    onStartEnd: onRefresh,
  });

  const completeJob = useJobComplete({
    onCompleteEnd: onRefresh,
  });

  const onAssignConfirm = async () => {
    try {
      const { data } = await machinesApi.jobReassign({
        jobId: job.id,
        machineId: assignedPrinter,
      });

      hideAssignModal();
      setAssignedPrinter(null);
      await onRefresh();

      notify(data.notice);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to assign job to machine.',
      });
    }
  };

  const onUnAssignConfirm = async () => {
    try {
      await machinesApi.jobUnassign({
        jobId: job.id,
      });

      hideAssignModal();
      setAssignedPrinter(null);
      await onRefresh();

      notify('Machine has been un-assigned.');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to unassign job to machine.',
      });
    }
  };

  const handleDuplicate = async () => {
    try {
      showLoading();

      await jobsApi.duplicateJob({
        jobId: job.id,
        machineId: job.machine?.id,
        partId: job.part?.id,
        stockId: job.stockId,
        workerId: job.workerId,
      });

      await onRefresh();
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to duplicate job.' });
    } finally {
      hideLoading();
    }
  };

  const {
    isInProgress,
    isNonProductJob,
    isPrintJob,
    isReady,
    isRejected,
    isRepairJob,
    isWaiting,
  } = getJobInfo(job);
  const machineId = job?.machine?.id;
  const { isProductionEnv } = getActiveEnv();

  const [showCompletePrevJobsModal] = useCompletePreviousJobsModal({
    jobId: job?.id,
    onJobUpdate: onRefresh,
  });

  const isStockJob = !(job?.part?.id || job?.partId);

  const menuOptions: MoreMenuOption<Job>[] = !job?.id
    ? []
    : [
        !isRepairJob && {
          enabled: job.status === jobStatuses.ready,
          name: 'Start',
          onOptionClick: () => {
            return startJob(job.id);
          },
        },
        isPrintJob
          ? {
              disabledTooltipProps: {
                description: job.machineType
                  ? ''
                  : 'Job is missing machine type.',
              },
              enabled:
                !!job.machineType &&
                !stockIsTreatedAsCompleted(job.stockStatus) &&
                (isWaiting || isReady),
              name: machineId ? 'Re-assign machine' : 'Assign machine',
              onOptionClick: () => {
                setMachineModalType(machineId ? 'REASSIGN' : 'ASSIGN');
                showAssignModal();
              },
            }
          : null,
        isPrintJob && machineId
          ? {
              enabled:
                !stockIsTreatedAsCompleted(job.stockStatus) &&
                (isWaiting || isReady),
              name: 'Un-assign machine',
              onOptionClick: () => {
                setMachineModalType('UNASSIGN');
                showAssignModal();
              },
            }
          : null,
        {
          enabled: !stockIsTreatedAsCompleted(job.stockStatus) && isInProgress,
          name: 'Complete',
          onOptionClick: () => {
            return completeJob(job.id);
          },
        },
        isWaiting &&
          !isProductionEnv && {
            allowedFor: ['Admin'],
            enabled: true,
            name: 'Complete previous jobs',
            onOptionClick: showCompletePrevJobsModal,
          },
        openDetailsPanel && {
          enabled: true,
          name: 'View details',
          onOptionClick: openDetailsPanel,
        },
        !isRepairJob && {
          enabled: isWaiting || isReady,
          name: 'Edit',
          onOptionClick: openDetailsPanelInEditMode,
        },
        {
          allowedFor: ['Admin', 'Lead'],
          enabled:
            ((isAdmin || isLead) &&
              !stockIsTreatedAsCompleted(job.stockStatus) &&
              (isWaiting || isReady)) ||
            (isRepairJob && isInProgress),
          name: 'Cancel',
          onOptionClick: () => {
            showCancelModal();
          },
        },
        !isStockJob && {
          allowedFor: ['Admin', 'Lead'],
          enabled: job.canBeRejected,
          name: 'Reject',
          onOptionClick: () => {
            showRejectModal();
          },
        },
        !isNonProductJob && {
          allowedFor: ['Admin', 'Lead'],
          enabled: !stockIsTreatedAsCompleted(job.stockStatus) && !isRejected,
          name: 'Duplicate',
          onOptionClick: handleDuplicate,
        },
      ];

  const [showAssignModal, hideAssignModal] = useModal(() => {
    return (
      <AssignMachineModal
        assignedPrinter={assignedPrinter}
        job={job}
        modalType={machineModalType}
        setAssignedPrinter={setAssignedPrinter}
        onAssignConfirm={onAssignConfirm}
        onClose={() => {
          hideAssignModal();
        }}
        onUnAssignConfirm={onUnAssignConfirm}
      />
    );
  }, [machineModalType, job, assignedPrinter]);

  const [showRejectModal, hideRejectModal] = useModal(() => {
    return (
      <RejectJobModal
        job={job}
        onClose={hideRejectModal}
        onUpdate={onRefresh}
      />
    );
  }, [job]);

  const [showCancelModal, hideCancelModal] = useModal(() => {
    return (
      <CancelJobModal
        job={job}
        onClose={hideCancelModal}
        onUpdate={onRefresh}
      />
    );
  }, [job]);

  useEffect(() => {
    setAssignedPrinter(machineId);
  }, [machineId]);

  return { menuOptions };
};
