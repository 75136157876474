import {
  BuildUriArgs,
  DownloadCsvArgs,
  ConvertToCsvArgs,
  ConvertToArraysArgs,
  JoinArraysArgs,
} from './download-csv.types';

// Copied and updated from https://github.com/react-csv/react-csv/blob/master/src/core.js
// Extracted to allow downloading without a rendered component as the open option hiding after making async request caused issues

export const newlineSeparator = '\n';

const convertToArrays = <DownloadRecord extends Record<string, any>>(
  args: ConvertToArraysArgs<DownloadRecord>,
) => {
  try {
    const { columns, records } = args;

    const headerLabels = columns.map(({ header }) => {
      return header;
    });

    const data = records.map((record) => {
      return columns.map(({ getValue }) => {
        try {
          return getValue(record);
        } catch (error) {
          throw new Error(error);
        }
      });
    });

    return [headerLabels, ...data];
  } catch (error) {
    throw new Error(error);
  }
};

const joinArrays = (args: JoinArraysArgs) => {
  try {
    const { data, enclosingCharacter, separator } = args;

    return data
      .filter(Boolean)
      .map((row) => {
        return row
          .map((value) => {
            const formattedValue = String(value ?? '').replace(/"/g, '""');

            return [
              enclosingCharacter,
              formattedValue,
              enclosingCharacter,
            ].join('');
          })
          .join(separator);
      })
      .join(newlineSeparator);
  } catch (error) {
    throw new Error(error);
  }
};

const convertToCSV = <DownloadRecord extends Record<string, any>>(
  args: ConvertToCsvArgs<DownloadRecord>,
) => {
  try {
    const { columns, records, ...rest } = args;

    const data = convertToArrays({ columns, records });

    return joinArrays({ data, ...rest });
  } catch (error) {
    throw new Error(error);
  }
};

const buildURI = <DownloadRecord extends Record<string, any>>(
  args: BuildUriArgs<DownloadRecord>,
) => {
  try {
    const { uFEFF, ...rest } = args;
    const csv = convertToCSV(rest);
    /** Simple safari detection based on user agent test */
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const type = isSafari ? 'application/csv' : 'text/csv';
    const blob = new Blob([uFEFF ? '\uFEFF' : '', csv], { type });
    const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

    const URL = window.URL || window.webkitURL;

    const csvContent =
      typeof URL.createObjectURL === 'undefined'
        ? dataURI
        : URL.createObjectURL(blob);

    return csvContent;
  } catch (error) {
    throw new Error(error);
  }
};

export const downloadCSV = <DownloadRecord extends Record<string, any>>({
  enclosingCharacter = '"',
  fileName,
  separator = ',',
  uFEFF = true,
  ...rest
}: DownloadCsvArgs<DownloadRecord>) => {
  try {
    const uri = buildURI({ ...rest, enclosingCharacter, separator, uFEFF });
    const encodedUri = encodeURI(uri);
    const link = document.createElement('a');

    link.setAttribute('href', encodedUri);
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // Required for FF

    link.click();
  } catch (error) {
    throw new Error(error);
  }
};
