import { Conditional } from 'gantri-components';
import { NotApplicableText } from '../../../../../../components/common/not-applicable-text';
import { CellLastActionProps } from './cell-last-action.types';
import { LastActionDataCell } from '../../../../../../components/common/custom-data-cells/last-action-data-cell';

export const CellLastAction = (props: CellLastActionProps) => {
  const { getValue, row } = props;

  const { lastActionData } = row.original;
  const lastActionAt = getValue();

  return (
    <Conditional condition={!!lastActionAt} Fallback={<NotApplicableText />}>
      <LastActionDataCell
        data={lastActionData}
        date={lastActionAt}
        jobId={lastActionData?.id}
      />
    </Conditional>
  );
};
