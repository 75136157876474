import {
  Button,
  Conditional,
  Flex,
  Icon,
  Modal,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { sortBy } from 'lodash';
import { CSVLink } from 'react-csv';
import { productAtoms } from '../../../../../../product.atoms';
import { useFetchSkuAssets } from '../../../../../../../../api/products/routes/fetch-sku-assets';
import { Divider } from '../../../../../../../../components/divider';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';
import { useHandleSetSelectedSku } from '../../../../../../hooks/use-handle-set-selected-sku';
import routePaths from '../../../../../../../../config/route-paths';
import { productFetchTypes } from '../../../../../../../../api/products/routes';
import { ExpandableItemsList } from '../../../../../../../../components/common/expandable-items-list';
import { getDownloadFileName } from '../../../../../../../../hooks/use-get-download-menu-option/helpers/get-download-file-name';
import { productFileTypesMaxQty } from '../../../sku-level-assets/components/bulk-upload-modal/bulk-upload-modal.constants';
import { productQueryKeys } from '../../../../../../product.constants';
import { convertQueriesToString } from '../../../../../../../../helpers/checks';
import { assetLevelViews } from '../../../../assets.constants';

interface SkuAssetDownloadData {
  dimmingPhotoDark: string;
  dimmingPhotoLight: string;
  scalePhoto: string;
  sku: string;
  usdz: string;
  whiteBackgroundPhotos: string;
}

interface ReviewAssetsModalProps {
  onClose: () => void;
}

export const ReviewAssetsModal = (props: ReviewAssetsModalProps) => {
  const { onClose } = props;

  const product = useRecoilValue(productAtoms.product);

  const [downloadData, setDownloadData] = useState<SkuAssetDownloadData[]>([]);
  const [report, setReport] = useState<{
    noDimmingImages: boolean;
    noScaleImage: boolean;
    noUsdzFile: boolean;
    skusMissingOptionalAssets: { sku: string }[];
    skusMissingProductPhotos: { sku: string }[];
  }>({
    noDimmingImages: false,
    noScaleImage: false,
    noUsdzFile: false,
    skusMissingOptionalAssets: [],
    skusMissingProductPhotos: [],
  });

  const theme = useTheme();

  const { isLoading } = useFetchSkuAssets({
    fetchArgs: {
      productId: product.id,
    },
    onSuccess: async ({ skuAssets: assetsBySku }) => {
      const assets = Object.values(assetsBySku);

      const noScaleImage = assets.every(({ scalePhoto }) => {
        return !scalePhoto;
      });
      const noDimmingImages = assets.every(
        ({ dimmingPhotoDark, dimmingPhotoLight }) => {
          return !dimmingPhotoDark && !dimmingPhotoLight;
        },
      );
      const noUsdzFile = assets.every(({ usdz }) => {
        return !usdz?.fileUrl;
      });
      const skusMissingProductPhotos = sortBy(
        assets
          .filter(({ whiteBackgroundPhotos }) => {
            return !whiteBackgroundPhotos?.length;
          })
          .map(({ sku }) => {
            return { sku };
          }),
        ['sku'],
      );
      const skusMissingOptionalAssets = sortBy(
        assets
          .filter(
            ({
              dimmingPhotoDark,
              dimmingPhotoLight,
              scalePhoto,
              usdz,
              whiteBackgroundPhotos,
            }) => {
              return (
                !dimmingPhotoDark ||
                !dimmingPhotoLight ||
                whiteBackgroundPhotos.length <
                  productFileTypesMaxQty.whiteBackgroundPhotos ||
                !scalePhoto ||
                !usdz?.fileUrl
              );
            },
          )
          .map(({ sku }) => {
            return { sku };
          }),
        ['sku'],
      );

      setReport({
        noDimmingImages,
        noScaleImage,
        noUsdzFile,
        skusMissingOptionalAssets,
        skusMissingProductPhotos,
      });

      const downloadData = assets.map(
        ({
          dimmingPhotoDark,
          dimmingPhotoLight,
          scalePhoto,
          sku,
          usdz,
          whiteBackgroundPhotos,
        }) => {
          const getStatusIcon = (isValid: boolean) => {
            return isValid ? '✅' : '❌';
          };

          const whiteBackgroundPhotosStatus = whiteBackgroundPhotos.map(
            (photo) => {
              return getStatusIcon(!!photo);
            },
          );

          for (
            let value = whiteBackgroundPhotosStatus.length;
            value < productFileTypesMaxQty.whiteBackgroundPhotos;
            value++
          ) {
            whiteBackgroundPhotosStatus.push(getStatusIcon(false));
          }

          const data: SkuAssetDownloadData = {
            dimmingPhotoDark: getStatusIcon(!!dimmingPhotoDark),
            dimmingPhotoLight: getStatusIcon(!!dimmingPhotoLight),
            scalePhoto: getStatusIcon(!!scalePhoto),
            sku,
            usdz: getStatusIcon(!!usdz),
            whiteBackgroundPhotos: whiteBackgroundPhotosStatus.join(''),
          };

          return data;
        },
      );

      setDownloadData(downloadData);
    },
  });

  const { handleSetSelectedSku } = useHandleSetSelectedSku();

  const noLifestyleImages = !product.assets?.product.lifestylePhotos?.length;
  const noVideo = !product.assets?.product?.videos?.length;

  const isMissingRequiredAssets =
    !!report.skusMissingProductPhotos.length ||
    report.noScaleImage ||
    report.noDimmingImages ||
    report.noUsdzFile ||
    noLifestyleImages;

  const numSkusMissingOptionalAssets = report.skusMissingOptionalAssets.length;
  const someSkusAreMissingOptionalAssets = !!numSkusMissingOptionalAssets;
  const isMissingOptionalAssets = someSkusAreMissingOptionalAssets || noVideo;

  return (
    <Modal
      contentStackProps={{
        horizontalPadding: '2x',
        verticalPadding: '2x',
      }}
      footer={<Button size="large" text="Done" onClick={onClose} />}
      header="Review Assets"
      maxWidth={{ lg: '48rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack
        alignContent={{ lg: 'center', md: 'start' }}
        gap="1.6rem"
        minHeight="15.3rem"
      >
        <Conditional
          key="Is loading"
          condition={isLoading}
          Fallback={
            <Conditional
              key="Required and optional assets complete."
              condition={isMissingRequiredAssets || isMissingOptionalAssets}
              Fallback={
                <Typography
                  align="center"
                  icon={
                    <Icon
                      color="link"
                      name="ui-control:check_mark_circle_filled"
                    />
                  }
                  text="Required and optional assets complete."
                />
              }
            >
              <Conditional
                key="Required assets complete."
                condition={isMissingRequiredAssets}
                Fallback={
                  <Typography
                    icon={
                      <Icon
                        color="link"
                        name="ui-control:check_mark_circle_filled"
                      />
                    }
                    text="Required assets complete."
                  />
                }
              >
                <StyledBackgroundStack
                  $backgroundColor={theme.colors.surfaces.warning.t1}
                  gap="0"
                  height="auto"
                  padding="x"
                >
                  <Typography
                    icon={
                      <Icon color="warning" name="alert:warning_triangle" />
                    }
                    paddingBottom="x"
                    text="Missing required assets."
                  />
                  <ExpandableItemsList
                    idProperty="sku"
                    Item={({ sku }) => {
                      const queriesString = convertQueriesToString({
                        [productQueryKeys.assetLevel]: assetLevelViews.sku,
                        [productQueryKeys.sku]: sku,
                        [productQueryKeys.tab]: productFetchTypes.assets,
                      });

                      return (
                        <StyledAnchor
                          href={`${routePaths.products}/${product.id}${queriesString}`}
                          text={sku}
                          onClick={() => {
                            // providing both onClick and href to support opening links in a new tab
                            handleSetSelectedSku(sku);
                            onClose();
                          }}
                        />
                      );
                    }}
                    Label={({ numItems }) => {
                      return (
                        <Flex alignItems="center" gap="x">
                          <Typography text={numItems} textStyle="bold" />
                          <Typography text="SKUs missing at least 1 White background shot" />
                        </Flex>
                      );
                    }}
                    list={report.skusMissingProductPhotos}
                  />
                  <Conditional
                    condition={
                      report.noScaleImage ||
                      report.noDimmingImages ||
                      report.noUsdzFile ||
                      noLifestyleImages
                    }
                  >
                    <Stack gap="x" paddingBottom="x">
                      <ConditionalLineItem
                        condition={report.noScaleImage}
                        text="No scale image present"
                        tooltipTitle="At least 1 SKU should have a scale image."
                      />

                      <ConditionalLineItem
                        condition={report.noDimmingImages}
                        text="No dimming images present"
                        tooltipTitle="At least 1 SKU should have dimming images (light and dark)."
                      />

                      <ConditionalLineItem
                        condition={report.noUsdzFile}
                        text="No USDZ files present"
                        tooltipTitle="At least 1 SKU should have a USDZ model."
                      />

                      <ConditionalLineItem
                        condition={noLifestyleImages}
                        text="No lifestyle images present"
                        tooltipTitle="Product should have at least 1 editorial image."
                      />
                    </Stack>
                  </Conditional>
                </StyledBackgroundStack>
              </Conditional>

              <Conditional
                key="Is missing optional assets"
                condition={isMissingOptionalAssets}
              >
                <StyledBackgroundStack
                  $backgroundColor={theme.colors.surfaces.monochrome.t1Alt}
                  gap="x"
                  height="auto"
                  horizontalPadding="x"
                  paddingBottom="2x"
                  paddingTop="x"
                >
                  <Typography
                    icon={<Icon name="alert:warning_triangle" />}
                    paddingBottom="x"
                    text="Missing optional assets."
                  />

                  <Conditional
                    key="SKUs missing optional assets"
                    condition={someSkusAreMissingOptionalAssets}
                  >
                    <Flex alignItems="center" gap="x" horizontalPadding="x">
                      <Typography
                        text={numSkusMissingOptionalAssets}
                        textStyle="bold"
                      />
                      <Typography text="SKUs missing optional assets" />
                    </Flex>
                  </Conditional>

                  <ConditionalLineItem
                    condition={noVideo}
                    text="Product video is missing"
                  />
                </StyledBackgroundStack>

                <Conditional
                  key="Download - SKU Assets CSV"
                  condition={someSkusAreMissingOptionalAssets}
                >
                  <CSVLink
                    data={downloadData}
                    filename={getDownloadFileName(
                      `${product.name} assets review`,
                    )}
                    headers={[
                      { key: 'sku', label: 'SKU' },
                      {
                        key: 'whiteBackgroundPhotos',
                        label: `White Background Shots 1-${productFileTypesMaxQty.whiteBackgroundPhotos}`,
                      },
                      { key: 'scalePhoto', label: 'Scale' },
                      { key: 'dimmingPhotoLight', label: 'Dimming L' },
                      { key: 'dimmingPhotoDark', label: 'Dimming D' },
                      { key: 'usdz', label: 'USDZ' },
                    ]}
                  >
                    <Button
                      icon={<Icon color="link" name="arrows:arrow_download" />}
                      text="Download - SKU Assets CSV"
                      variant="ghost"
                    />
                  </CSVLink>
                </Conditional>
              </Conditional>
            </Conditional>
          }
        >
          <Flex alignItems="center" justifyContent="center" minHeight="10rem">
            <Icon color="link" name="animated:loader" size="4rem" />
          </Flex>
        </Conditional>
      </Stack>
    </Modal>
  );
};

export const StyledBackgroundStack = styled(Stack)<{
  $backgroundColor: string;
}>`
  ${({ $backgroundColor }) => {
    return css`
      background-color: ${$backgroundColor};
    `;
  }}
`;

const ConditionalLineItem = (props: {
  condition: boolean;
  hideDivider?: boolean;
  text: string;
  tooltipTitle?: string;
}) => {
  const { condition, hideDivider, text, tooltipTitle } = props;

  return (
    <Conditional key={text} condition={condition}>
      <Conditional key="Hide divider" condition={!hideDivider}>
        <Divider />
      </Conditional>
      <Typography
        icon={
          <Conditional condition={!!tooltipTitle}>
            <Tooltip position="top" title={tooltipTitle}>
              <Icon name="alert:i_circle" />
            </Tooltip>
          </Conditional>
        }
        iconPosition="right"
        paddingLeft="x"
        paddingRight="x"
        text={text}
      />
    </Conditional>
  );
};
