import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startFinishStageModalAtoms } from '../../../start-finishing-stage-job-modal.atoms';
import { ConfirmStartFooterProps } from './confirm-start-footer.types';

export const ConfirmStartFooter = (props: ConfirmStartFooterProps) => {
  const { handleCloseModal, jobId } = props;

  const setStep = useSetRecoilState(startFinishStageModalAtoms.step);
  const setStockLocation = useSetRecoilState(
    startFinishStageModalAtoms.stockLocation,
  );
  const setVlmNotified = useSetRecoilState(
    startFinishStageModalAtoms.vlmNotified,
  );
  const setUpdateOnClose = useSetRecoilState(
    startFinishStageModalAtoms.updateOnClose,
  );

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const handleStartJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await jobsApi.startJob({ callVlm: true, jobId });
        const { location, vlmNotified } = data;

        if (vlmNotified) {
          setVlmNotified(vlmNotified);
        }

        setUpdateOnClose(true);
        setStockLocation(location);

        if (location) {
          setStep('PUT_IN_VLM');
        } else {
          setStep('VLM_IS_FULL');
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to start job.',
        });
      }
    });
  };

  const handleConfirmStart = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        await handleStartJob();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to start job.',
        });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleConfirmStart}
      />
    </>
  );
};
