import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { fetchGlobalVariantsQuery } from './fetch-global-variants';
import {
  FetchGlobalVariantsResponse,
  FetchGlobalVariantsArgs,
} from './fetch-global-variants.types';

const fetchGlobalVariantsQueriesObj = getGenericQueryObj({
  groupName: 'global-variants',
  query: fetchGlobalVariantsQuery,
});

export const useFetchGlobalVariants = <
  TransformedData = FetchGlobalVariantsResponse,
>(
  props?: GenericFetchProps<
    FetchGlobalVariantsArgs,
    FetchGlobalVariantsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch globalVariants.',
    queryObj: fetchGlobalVariantsQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchGlobalVariantsCache = () => {
  const invalidateFetchGlobalVariantsCache = useGetInvalidateQueryCache(
    fetchGlobalVariantsQueriesObj,
  );

  return { invalidateFetchGlobalVariantsCache };
};
