import { FC } from 'react';
import { Icon, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import {
  StyledGiftMessageContainer,
  StyledRefundReasonContainer,
  StyledShipmentStockInfoContainer,
  StyledShipmentTransactionContainer,
  StyledShipmentTransactionInfoContainer,
} from './order-transaction.styles';
import routePaths from '../../../../../../config/route-paths';
import { formatAsCurrency } from '../../../../../../helpers/formatter';
import { StyledInlineTypography } from '../../../../../../components/layout/page-layout-styles';
import { shipmentStatuses } from '../../../../../../constants/options';
import { OrderTransactionProps } from './order-transaction.types';
import { ShortProductSummary } from '../../../../../../components/common/short-product-summary';

export const OrderTransaction: FC<OrderTransactionProps> = (props) => {
  const { stock } = props;

  const {
    giftMessage,
    id,
    isGift,
    product,
    refundId,
    refundReason,
    replacementReason,
    status,
  } = stock;

  const {
    amount: { subtotal },
  } = product;

  const title = id ? `Stock #${id}` : '';
  const stockPageHref = id ? `${routePaths.stocks}/${id}` : '';

  const StockStatus = () => {
    switch (status) {
      case shipmentStatuses.returnInProgress:
      case shipmentStatuses.refunded:
        if (refundId) {
          return (
            <Link to={`${routePaths.orders}/${refundId}`}>
              <Typography align="right" color="link" text={status} />
            </Link>
          );
        }

        return <Typography align="right" color="alert" text={status} />;

      case shipmentStatuses.cancelled: {
        return <Typography align="right" color="alert" text={status} />;
      }

      case shipmentStatuses.waiting:
      case shipmentStatuses.completed:

      default: {
        return <Typography align="right" color="t2" text={status} />;
      }
    }
  };

  return (
    <StyledShipmentTransactionContainer title={title}>
      <StyledShipmentTransactionInfoContainer>
        <ShortProductSummary
          fetchSku={product.sku}
          href={stockPageHref}
          imageSize={{ lg: '6.6rem', md: '6.6rem', sm: '4.4rem' }}
        >
          <Typography
            color="t1"
            text={formatAsCurrency(subtotal, { isCents: true })}
          />

          {!!replacementReason && (
            <StyledRefundReasonContainer>
              <Typography text="Replacement reason" />
              <Typography color="t1" text={replacementReason} />
            </StyledRefundReasonContainer>
          )}

          {!!refundReason && (
            <StyledRefundReasonContainer>
              <Typography text="Refund reason" />
              <Typography color="t1" text={refundReason} />
            </StyledRefundReasonContainer>
          )}

          {isGift && (
            <StyledGiftMessageContainer>
              <Typography
                icon={<Icon color="link" name="payment:gift_card" />}
                text="This is a gift"
              />

              {!!giftMessage && (
                <div>
                  <Typography color="t1" text="Gift message" />
                  <Typography text={giftMessage} />
                </div>
              )}
            </StyledGiftMessageContainer>
          )}
        </ShortProductSummary>
      </StyledShipmentTransactionInfoContainer>
      <StyledShipmentStockInfoContainer>
        <span style={{ textAlign: 'right' }}>
          {id && (
            <>
              <StyledInlineTypography color="t2" text="Stock " />

              <Link
                state={{ locationStockId: id }}
                to={`${routePaths.stocks}/${id}`}
              >
                <StyledInlineTypography color="link" text={`#${id}`} />
              </Link>
            </>
          )}
        </span>
        <StockStatus />
      </StyledShipmentStockInfoContainer>
    </StyledShipmentTransactionContainer>
  );
};
