import { useRecoilValue } from 'recoil';
import { manageQueuesModalAtoms } from '../../manage-queues-modal.atoms';
import { manageQueuesModalSteps } from '../../manage-queues-modal.constants';
import { ConfirmDeleteContent } from '../step-confirm-delete/confirm-delete-content';
import { QueuesListContent } from '../step-queues-list/queues-list-content';

export const ManageQueuesModalContent = () => {
  const step = useRecoilValue(manageQueuesModalAtoms.step);

  switch (step) {
    case manageQueuesModalSteps.queuesList:
      return <QueuesListContent />;

    case manageQueuesModalSteps.confirmDelete:
      return <ConfirmDeleteContent />;

    default:
      return null;
  }
};
