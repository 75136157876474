import { useNotification } from '../../../../hooks/useNotification';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useInvalidateGetMachineIssuesCache } from '../get-issues/get-issues.query';
import { deleteIssue } from './delete-issue';
import { DeleteIssueResponse } from './delete-issue.type';
import { useInvalidateFetchPaginatedMachineTimelineCache } from '../fetch-paginated-machine-timelines';

export const useDeleteMachineIssueMutation = () => {
  const invalidateCache = useInvalidateGetMachineIssuesCache();
  const invalidateTimelineCache =
    useInvalidateFetchPaginatedMachineTimelineCache();
  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    { issueId: number; machineId: number },
    DeleteIssueResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred on deleting a machine issue.',
        });
      },
      onSuccess: async (data, variables) => {
        notify(data.notice);
        await invalidateCache({ machineId: variables.machineId });
        await invalidateTimelineCache({ machineId: variables.machineId });
      },
    },
    mutationFn: (request) => {
      return deleteIssue(request.issueId);
    },
    showLoading: true,
  });

  return { isLoading, onDeleteIssue: onMutate };
};
