import { useRecoilState } from 'recoil';
import { ChecklistItem } from '../../../../../common/job-checklist/job-checklist.types';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { printQcModalAtoms } from '../../../complete-print-qa-modal.atoms';
import { CompletePrintQaFailReason } from '../../../complete-print-qa-modal.types';
import { statusOptions } from '../../print-qa-content/print-qa-content.constants';
import { ChecklistContentProps } from './checklist-content.types';

export const ChecklistContent = (props: ChecklistContentProps) => {
  const { material } = props;

  const [checklist, setChecklist] = useRecoilState(printQcModalAtoms.checklist);

  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    printQcModalAtoms.isChecklistReviewed,
  );

  const updateChecklist = (
    reason: CompletePrintQaFailReason,
    data: ChecklistItem,
  ) => {
    setChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      checklist={checklist}
      isChecklistReviewed={isChecklistReviewed}
      material={material}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={statusOptions}
      onItemChange={updateChecklist}
    />
  );
};
