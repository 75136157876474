import { useTheme } from 'styled-components';
import { IconCylindricalToolProps } from './icon-cylindrical-tool.types';

export const IconCylindricalTool = (props: IconCylindricalToolProps) => {
  const theme = useTheme();
  const defaultColor = theme.colors.iconography.t2;
  const { color = defaultColor } = props;

  return (
    <svg
      fill="none"
      height="31"
      viewBox="0 0 27 31"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M8.99206 2.78477C8.99206 2.78477 9.18778 2.33623 9.28758 2.09009C9.52324 1.50885 9.86327 1.0144 10.4933 0.733911C11.1215 0.454247 11.9106 0.307561 12.7975 0.282282C14.5724 0.231697 16.7976 0.665603 19.0608 1.58323C21.3241 2.50086 23.2231 3.73917 24.4616 5.0115C25.0805 5.64734 25.5446 6.3021 25.8007 6.9403C25.9463 7.3032 26.0266 7.66657 26.0259 8.01915C26.007 8.50947 25.8007 8.85866 25.5478 9.34759C25.5478 9.34759 18.495 26.9458 18.0184 27.918C17.5419 28.8902 17.2529 29.3526 16.3863 29.8247C15.5609 30.2744 14.797 30.2491 13.91 30.2744C12.1352 30.3249 10.2191 29.891 7.95589 28.9734C5.69266 28.0558 3.79357 26.8175 2.5551 25.5451C1.69355 24.66 1.19829 24.001 1.03318 23.3407C0.868069 22.6803 1.03322 21.8549 1.69355 20.3691C2.35388 18.8833 8.99206 2.78477 8.99206 2.78477ZM24.9301 8.41977C24.8207 8.68955 24.5735 8.94925 24.1096 9.15578C23.6409 9.36442 22.994 9.49676 22.1913 9.51964C20.588 9.56533 18.5043 9.17042 16.3404 8.29303C14.1764 7.41564 12.406 6.24791 11.2873 5.09861C10.7272 4.52315 10.3551 3.97765 10.164 3.50152C9.97491 3.03023 9.97841 2.67174 10.0878 2.40195C10.1972 2.13217 10.4443 1.87247 10.9082 1.66594C11.3769 1.4573 12.0239 1.32496 12.8266 1.30208C14.4298 1.25639 16.5135 1.6513 18.6775 2.52869C20.8415 3.40608 22.6118 4.57381 23.7306 5.72311C24.2907 6.29857 24.6628 6.84408 24.8538 7.3202C25.0429 7.7915 25.0394 8.14998 24.9301 8.41977ZM15.957 9.23849C18.2202 10.1561 20.4455 10.59 22.2203 10.5394C23.0169 10.5167 23.7345 10.3961 24.3276 10.1692L17.707 26.4774C17.3564 27.2129 17.0467 27.918 17.0467 27.918C16.936 28.1909 16.5513 28.6863 16.0959 28.8902C15.6402 29.0942 15.0045 29.2296 14.1822 29.253C12.5819 29.2987 10.501 28.9044 8.33922 28.0279C6.17747 27.1515 5.16038 26.4774 4.00477 25.5451C2.84916 24.6129 2.8955 24.4963 2.3539 23.6709C1.81231 22.8454 2.00093 21.9087 2.5551 20.6993C3.10927 19.4899 9.28757 4.07113 9.28757 4.07113C9.61775 4.56639 9.98035 5.2186 10.5562 5.81022C11.7947 7.08256 13.6938 8.32086 15.957 9.23849Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
};
