import { getActiveQueries } from '../../../../../../helpers/checks';
import { ReportSalesQueries } from '../../report-sales.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportSalesQueries>();
  const { color, country, creatorId, customerType, groupBy, productId, state } =
    queries;

  if (/^color$/i.test(groupBy) && !color) {
    return 'Color';
  }

  if (/^creatorId$/i.test(groupBy) && !creatorId) {
    return 'Creator';
  }

  if (/^customerType$/i.test(groupBy) && !customerType) {
    return 'Customer Type';
  }

  if (/^country$/i.test(groupBy)) {
    if (country && !/noStates/i.test(state)) {
      return 'State';
    }

    if (state) {
      return 'City';
    }

    return 'Country';
  }

  if (groupBy && productId) {
    return 'SKU';
  }

  return 'Product Name';
};
