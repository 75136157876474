import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { UpdateDesignArgs, UpdateDesignResponse } from './update-design.types';

export const updateDesign = ({ id, ...body }: UpdateDesignArgs) => {
  return axios
    .put<UpdateDesignResponse>(`${getCoreApiUrl('designs')}/${id}/update`, body)
    .then(({ data }) => {
      return data;
    });
};
