import styled, { css } from 'styled-components';
import { Table } from 'gantri-components';

export const StyledReportTableWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  min-height: 40rem;
`;

export const dataAttrTotalRow = 'data-total-row';

export const StyledTable = styled(Table)<{
  $tableDimensions: {
    height: number;
    width: number;
  };
}>`
  [data-table-container] {
    ${({ $tableDimensions }) => {
      return css`
        height: ${$tableDimensions.height}px;
        max-width: ${$tableDimensions.width}px;
        width: 100%;
      `;
    }}
  }

  thead th > * {
    min-width: 10rem !important; // !important to override inline styling
  }

  thead tr,
  tbody tr {
    position: relative;
  }

  table thead {
    z-index: 3;
  }

  [${dataAttrTotalRow}] * {
    font-weight: bold;
  }
`;
