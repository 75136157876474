import { Conditional, Flex, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { DuplicatedJobsSection } from '../../../../../common/duplicated-jobs-section';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';
import { ViewBatchAnchor } from '../../../../../../../../batch/components/view-batch-anchor';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';

export const JobFailedRefinishPartContent = () => {
  const duplicatedJobs = useRecoilValue(finishingQcModalAtoms.duplicatedJobs);
  const newBatchId = useRecoilValue(finishingQcModalAtoms.newBatchId);
  const batchUpdateMethod = useRecoilValue(
    finishingQcModalAtoms.batchUpdateMethod,
  );

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.finish} ${jobTypeOptions.qc} job failed`}
      />
      <Stack gap="x">
        <Conditional
          condition={batchUpdateMethod === 'ADD_TO_EXISTING_BATCH'}
          Fallback={
            <>
              <Flex alignItems="baseline" gap="x">
                <Typography
                  text={`Part added to new Batch #${newBatchId}`}
                  variant="h5"
                />
                <ViewBatchAnchor batchId={newBatchId} />
              </Flex>
              <Typography text="Place a label on the rack or container and make sure part is placed correctly." />
            </>
          }
        >
          <Flex alignItems="baseline" gap="x">
            <Typography
              text={`Part added to Batch #${newBatchId}`}
              variant="h5"
            />
            <ViewBatchAnchor batchId={newBatchId} />
          </Flex>
          <Typography text="Make sure part is placed in the correct rack or container." />
        </Conditional>
      </Stack>
      <DuplicatedJobsSection duplicatedJobs={duplicatedJobs} showTitle />
    </Stack>
  );
};
