import { Flex, media } from 'gantri-components';
import styled, { css } from 'styled-components';
import { mediaQueries } from '../../../../constants/styles';

export const StyledQuickShipTitle = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: 5px;
  }
`;

export const StyledOrderTransactionContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledTransactionHeading = styled.div`
  margin-bottom: 5px;
`;

export const StyledShipmentHeaderContainer = styled.div`
  border: 1px solid
    ${(props) => {
      return props.theme.colors.dividers.t1;
    }};
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t2;
  }};
  padding: 2.4rem;

  ${media.lessThan('sm')`
     padding: 1.6rem;
  `};
`;

export const StyledShipmentHeaderItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4.8rem;

  ${mediaQueries.mobile(css`
    grid-template-columns: 1fr;
  `)}
`;

export const StyledShipmentHeaderColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(5, 1fr);

  ${media.lessThan('sm')`
     grid-template-rows: unset;
  `};
`;

export const StyledShipmentHeaderField = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: max-content;

  & > *:first-child {
    margin-right: 20px;
  }
`;

export const StyledShipmentActionButtonsWrapper = styled(Flex)`
  flex-flow: wrap;
  align-content: start;
`;

export const StyledTestShippoButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  margin-bottom: 6.4rem;

  & > button {
    margin-bottom: 10px;
  }
`;

export const StyledTrackingAnchor = styled.a`
  width: 18rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: right;
`;
