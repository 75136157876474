import { useState } from 'react';
import { GetRowProps, Table } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StocksFilter } from '../../components/dropdowns/stocks-filter';
import { StocksSortingField } from './stocks.types';
import { getTableRowStatusFromAttentions } from '../../helpers/get-table-row-status-from-attentions';
import { PageWithTable } from '../../components/layout';
import { realTimeChannels } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.constants';
import { useNotification } from '../../hooks/useNotification';
import { useSpinner } from '../../hooks';
import {
  FiltersFetchRequestArgs,
  SortBy,
  useTableFilters,
} from '../../components/common/table/hooks';
import { stocksApi } from '../../api';
import { FetchPaginatedStocksArgs } from '../../api/stocks/routes/fetch-paginated-stocks/fetch-paginated-stocks.types';
import { Stock } from '../../api/stocks/stocks.types';
import {
  pageName,
  stocksPageAtoms,
} from '../../components/dropdowns/stocks-filter/stocks-filter.atoms';
import { columns, modalFiltersDetails } from './stocks.constants';
import { StocksHeaderContent } from './components/stocks-header-content';
import { useRealTimeWebHook } from '../../hooks/use-real-time-web-hook';
import { RealTimeStockChangedData } from '../../hooks/use-real-time-web-hook/use-real-time-web-hook.types';

export const Stocks = () => {
  const { notifyAxiosError } = useNotification();
  const { onInterceptRequest } = useSpinner();

  const [downloadFilters, setDownloadFilters] =
    useState<
      FiltersFetchRequestArgs<FetchPaginatedStocksArgs, StocksSortingField>
    >();

  const fetchPaginatedStocks = async (
    args: FiltersFetchRequestArgs<FetchPaginatedStocksArgs, StocksSortingField>,
    options: { showLoadingIndicator?: boolean } = {
      showLoadingIndicator: true,
    },
  ) => {
    setDownloadFilters(args);

    const handleFetchPaginatedStocks = async () => {
      try {
        const { data } = await stocksApi.fetchPaginatedStocks(args);

        setRecords(data.stocks);
        setTotalCount(data.totalStocks);
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to fetch stocks.' });
      }
    };

    if (options?.showLoadingIndicator) {
      await onInterceptRequest(handleFetchPaginatedStocks);
    } else {
      await handleFetchPaginatedStocks();
    }
  };

  const {
    currentPage,
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<Stock, FetchPaginatedStocksArgs, StocksSortingField>({
    excludeFromActiveFiltersCount: ['sortBy'],
    fetchRequest: fetchPaginatedStocks,
    filtersContent: <StocksFilter />,
    modalFiltersDetails,
    pageName,
    searchFilterDetails: {
      atom: stocksPageAtoms.search,
    },
    sortOptions: [
      { label: 'Priority', sortingField: 'Priority' },
      { label: 'Create Date', sortingField: 'Created' },
    ],
  });

  const [sortingField, setSortingField] = useRecoilState(
    stocksPageAtoms.sortBy,
  );

  const onSort = async ({ sortingField }: SortBy<StocksSortingField>) => {
    // overriding default onSort due to using non-standard sorting args
    setSortingField(sortingField);
    await handleFetchRequest({ page: 1 });
  };

  const dataAttrStockId = 'data-stock-id';

  const getRowProps: GetRowProps<Stock> = (row) => {
    const {
      original: { id },
    } = row;

    const status = getTableRowStatusFromAttentions(row);

    return {
      [dataAttrStockId]: id,
      status,
    };
  };

  const activeStatusFilters = useRecoilValue(stocksPageAtoms.filters.statuses);

  useRealTimeWebHook<Stock, RealTimeStockChangedData>({
    activeStatusFilters,
    channel: realTimeChannels.stocks,
    dataAttrForRow: dataAttrStockId,
    handleFetchRequest: async () => {
      return handleFetchRequest(
        { page: currentPage },
        { showLoadingIndicator: false },
      );
    },
    records,
    setRecords,
  });

  return (
    <PageWithTable pageTitle="Stocks">
      <PageWithTable.Header title="Stocks">
        <StocksHeaderContent
          downloadFilters={downloadFilters}
          totalResults={pagingProps.total}
        />
      </PageWithTable.Header>

      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          getRowProps={getRowProps}
          highlightHoveredRow
          paging={pagingProps}
          search={searchProps}
          sorting={{ ...sortProps, current: sortingField, onSort }}
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
