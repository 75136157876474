import { useRecoilValue } from 'recoil';
import {
  Cell,
  Conditional,
  Flex,
  Grid,
  Stack,
  Typography,
} from 'gantri-components';
import { completeAssemblyModalAtoms } from '../../../../../complete-assembly-modal.atoms';
import Thumbnail from '../../../../../../../../../../../components/common/thumbnail/thumbnail';
import { DuplicatedJobsSection } from '../../../../../../../common/duplicated-jobs-section';
import { FailedPartDetailsProps } from './failed-part-details.types';
import { placeholderImageSrc } from '../../../../../../../../../../../constants/images';
import { RestartedPartTable } from '../../../../../../../common/restarted-part-table';
import { partStatuses } from '../../../../../../../../../../../constants/options';

export const FailedPartDetails = (props: FailedPartDetailsProps) => {
  const { partId } = props;

  const duplicatedJobs = useRecoilValue(
    completeAssemblyModalAtoms.duplicatedJobs,
  );

  const partChecklist = useRecoilValue(
    completeAssemblyModalAtoms.partChecklist,
  );
  const oldParts = useRecoilValue(completeAssemblyModalAtoms.oldParts);
  const newParts = useRecoilValue(completeAssemblyModalAtoms.newParts);

  const { status: checklistStatus } = partChecklist[partId];
  const oldPart = oldParts.find((part) => {
    return part.id === partId;
  });
  const duplicatedRefinishJobs = duplicatedJobs.filter((job) => {
    return job.partId === partId;
  });

  return (
    <Stack gap="x">
      <Grid alignItems="center" columns="max-content 1fr" gap="2x">
        <Thumbnail
          alt={oldPart.name}
          size="4rem"
          src={oldPart.thumbnail || placeholderImageSrc}
        />
        <Cell>
          <Flex alignItems="center" gap="x">
            <Typography text={oldPart.name} variant="h5" />
            <Typography color="alert" text={checklistStatus} />
          </Flex>
        </Cell>
      </Grid>
      <Conditional
        condition={checklistStatus === 'Re-finish'}
        Fallback={
          <>
            <Typography text="This part has been failed and can be discarded. A new part has been created using the latest version." />
            <RestartedPartTable
              newParts={newParts}
              oldParts={oldParts.map((part) => {
                return {
                  createdAt: part.createdAt,
                  id: part.id,
                  name: part.name,
                  status: partStatuses.failed,
                  version:
                    typeof part.version === 'string'
                      ? part.version
                      : part.version?.version,
                };
              })}
              partId={partId}
            />
          </>
        }
      >
        <Typography text="This part will be re-finished. The following jobs have been duplicated using the latest minor version." />
        <DuplicatedJobsSection duplicatedJobs={duplicatedRefinishJobs} />
      </Conditional>
    </Stack>
  );
};
