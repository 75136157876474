import {
  OrderActionConfiguration,
  orderStatusMapByValue,
  shipmentStatuses,
} from '../../../../constants/options';
import { Shipment } from '../../../../types/store';

interface StateByShipments {
  hasDeliveredShipment: boolean;
  hasNotShipped: boolean;
  hasWaitingShipment: boolean;
}

export interface IsOrderOptionEnabledParams {
  data: {
    orderStatus?: string;
    orderType?: string;
    shipments: Shipment[];
  };
  optionConfiguration?: OrderActionConfiguration;
}

export const getStateByShipments = (
  shipments: Shipment[],
): StateByShipments => {
  let hasDeliveredShipment = false;
  let hasNotShipped = false;
  let hasWaitingShipment = false;

  shipments?.forEach((shipment) => {
    const preShipmentStatuses = [
      shipmentStatuses.waiting,
      shipmentStatuses.inProgress,
      shipmentStatuses.readyToShip,
    ];

    if (!hasWaitingShipment && shipment.status === shipmentStatuses.waiting) {
      hasWaitingShipment = true;
    }

    if (shipmentStatuses.delivered === shipment.status) {
      hasDeliveredShipment = true;
    }

    if (preShipmentStatuses.includes(shipment.status)) {
      hasNotShipped = true;
    }
  });

  return { hasDeliveredShipment, hasNotShipped, hasWaitingShipment };
};

export const isOrderOptionEnabled = (params: IsOrderOptionEnabledParams) => {
  const { data, optionConfiguration } = params;
  const { enabledOrderStatuses, enabledOrderTypes, enabledShipmentBooleans } =
    optionConfiguration ?? {};
  const { orderStatus, orderType, shipments } = data;
  const shipmentState = getStateByShipments(shipments);

  if (!orderStatusMapByValue[orderStatus]) {
    return false;
  }

  if (!shipments || !shipments.length) {
    return false;
  }

  if (enabledOrderTypes && !enabledOrderTypes.includes(orderType)) {
    return false;
  }

  if (enabledOrderStatuses && !enabledOrderStatuses.includes(orderStatus)) {
    return false;
  }

  if (enabledShipmentBooleans) {
    for (const booleanKey of enabledShipmentBooleans) {
      if (!shipmentState[booleanKey]) {
        return false;
      }
    }
  }

  return true;
};
