import { Flex, Typography } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import {
  formatAsCurrency,
  formatPercentage,
} from '../../../../helpers/formatter';
import { GiftCode } from '../../../../api/gift-codes/gift-codes.types';
import { MoreMenu } from '../../../../components/dropdowns';
import { giftCodesApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { UseGetGiftCodesColumnsProps } from './use-get-gift-codes-columns.types';
import routePaths from '../../../../config/route-paths';
import { useQueryMutation } from '../../../../hooks/use-mutation';
import { UpdateGiftCodeResponse } from '../../../../api/gift-codes/routes/update-giftcode/update-giftcode.types';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetGiftCodesColumns = (props: UseGetGiftCodesColumnsProps) => {
  const { notify, notifyAxiosError } = useNotification();

  const { onRefresh } = props;

  const { onMutate: onToggleStatusFn } = useQueryMutation<
    { giftCode: string; newStatus: string },
    UpdateGiftCodeResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update gift code.',
        });
      },
      onSuccess: (data) => {
        const { gift, notice } = data;

        notify(notice);

        if (gift) {
          onRefresh();
        }
      },
    },
    mutationFn: (request) => {
      return giftCodesApi.updateGiftCode(request);
    },
    showLoading: true,
  });

  const columns: ColumnDef<GiftCode>[] = [
    {
      accessorKey: 'id',
      cell: ({ getValue }: CellContext<GiftCode, GiftCode['id']>) => {
        const id = getValue();

        return (
          <StyledAnchor
            href={`${routePaths.giftCodes}/${id}`}
            text={`#${id}`}
          />
        );
      },
      header: 'Gift Code #',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 100,
    },
    {
      accessorKey: 'code',
      header: 'Code',
    },
    {
      accessorKey: 'details',
      header: 'Details',
      size: 300,
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'amount',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<GiftCode, GiftCode['amount']>) => {
        const text =
          original.type === 'Discount %'
            ? formatPercentage(getValue())
            : formatAsCurrency(getValue(), { isCents: true });

        return <Typography text={text} />;
      },
      header: 'Amount',
    },
    {
      accessorKey: 'accountLimit',
      header: 'Account Limit',
    },
    {
      accessorKey: 'totalLimit',
      header: 'Total Limit',
    },
    {
      accessorKey: 'used',
      header: 'Usage',
    },
    {
      accessorKey: 'status',
      header: 'Status',
    },
    {
      cell: ({ row }: CellContext<GiftCode, never>) => {
        const options: MoreMenuOption<GiftCode>[] = [
          {
            enabled: true,
            name: row.original.status === 'Active' ? 'Deactivate' : 'Activate',
            onOptionClick: async ({ code, status }) => {
              onToggleStatusFn({
                giftCode: code,
                newStatus: status === 'Active' ? 'Inactive' : 'Active',
              });
            },
          },
        ];

        return (
          <Flex justifyContent="flex-end">
            <MoreMenu data={row.original} options={options} />
          </Flex>
        );
      },
      id: 'menu',
      meta: { label: 'Actions' },
      size: 40,
    },
  ];

  return columns;
};
