import { Conditional, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { RestartedPartTable } from '../../../../../common/restarted-part-table';
import {
  jobSteps,
  partStatuses,
} from '../../../../../../../../../constants/options';
import { JobFailedRestartPartContentProps } from './job-failed-restart-part-content.types';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';

export const JobFailedRestartPartContent = (
  props: JobFailedRestartPartContentProps,
) => {
  const { job } = props;
  const { part } = job;
  const { batchId } = part;

  const checklist = useRecoilValue(completeSandJobModalAtoms.checklist);
  const newParts = useRecoilValue(completeSandJobModalAtoms.newParts);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.finish} ${job.type} job failed`}
      />

      <JobFailReasonsList checklist={checklist} />

      <Conditional condition={!!batchId}>
        <Stack gap="x">
          <Typography
            text={`Part removed from Batch #${batchId}`}
            variant="h5"
          />
          <Typography text="Failed part has been removed from batch and can be discarded." />
        </Stack>
      </Conditional>

      <Stack gap="x">
        <Typography text="New Part Created" variant="h5" />
        <RestartedPartTable
          newParts={newParts}
          oldParts={[
            {
              createdAt: part.createdAt,
              id: part.id,
              name: part.name,
              status: partStatuses.failed,
              version:
                typeof part.version === 'string'
                  ? part.version
                  : part.version?.version,
            },
          ]}
          partId={part.id}
        />
      </Stack>
    </Stack>
  );
};
