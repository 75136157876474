import { FC, useMemo, useState } from 'react';
import { SectionContainer } from '../../../../../components/common/section-container';
import Timeline from '../../../../../components/common/timeline';
import TimelineItem from '../../../../../components/common/timeline/base-timeline-item';
import { useFetchPaginatedMachineTimelinesQuery } from '../../../../../api/machines/routes/fetch-paginated-machine-timelines/fetch-paginated-machine-timelines.query';

export const OverviewTimelineSection: FC<{ machineId: number }> = (props) => {
  const { machineId } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const queryArgs = useMemo(() => {
    return {
      machineId,
      page: currentPage,
    };
  }, [machineId, currentPage]);

  const { response } = useFetchPaginatedMachineTimelinesQuery(queryArgs);

  return (
    <SectionContainer title="Timeline">
      <Timeline
        isTitleVisible={false}
        items={response?.machine?.timelines ?? []}
        pageCount={response?.maxPages ?? 0}
        paginated
        renderItem={TimelineItem}
        timelineCurrentPage={currentPage}
        total={response?.totalTimelines ?? 0}
        onPageChange={setCurrentPage}
      />
    </SectionContainer>
  );
};
