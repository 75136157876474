import { IconType } from 'gantri-components/dist/components/icon/icon.type';
import { JobStep, jobSteps, JobType, jobTypeOptions } from './options';

export const supportedQAJobStepToTypeMap = {
  Assemble: {
    Assemble: 'Assemble',
  },
  Finish: {
    Paint: 'Finish',
    QA: 'Finish',
  },
  Print: {
    Print: 'Print',
    QA: 'Print',
  },
  QA: {
    QA: 'QA',
  },
  Technician: {
    Repair: 'Repair',
  },
};

export const jobTemplateTypeOptionsForJobStep = {
  [jobSteps.print]: [
    jobTypeOptions.prepare,
    jobTypeOptions.print,
    jobTypeOptions.qc,
  ],
  [jobSteps.finish]: [
    jobTypeOptions.glue,
    jobTypeOptions.sandRaw,
    jobTypeOptions.bondo,
    jobTypeOptions.mask,
    jobTypeOptions.tumbleRaw,
    jobTypeOptions.sandPrimed,
    jobTypeOptions.sandPainted,
    jobTypeOptions.prime,
    jobTypeOptions.paint,
    jobTypeOptions.qc,
    jobTypeOptions.stage,
  ],
  [`${jobSteps.finish} - Bondo`]: [
    jobTypeOptions.glue,
    jobTypeOptions.sandRaw,
    jobTypeOptions.bondo,
    jobTypeOptions.mask,
    jobTypeOptions.tumbleRaw,
    jobTypeOptions.sandPrimed,
    jobTypeOptions.sandPainted,
    jobTypeOptions.prime,
    jobTypeOptions.paint,
    jobTypeOptions.qc,
    jobTypeOptions.stage,
  ],
  [`${jobSteps.finish} - Non-Bondo`]: [
    jobTypeOptions.glue,
    jobTypeOptions.sandRaw,
    jobTypeOptions.bondo,
    jobTypeOptions.mask,
    jobTypeOptions.tumbleRaw,
    jobTypeOptions.sandPrimed,
    jobTypeOptions.sandPainted,
    jobTypeOptions.prime,
    jobTypeOptions.paint,
    jobTypeOptions.qc,
    jobTypeOptions.stage,
  ],
  [jobSteps.assemble]: [jobTypeOptions.stage, jobTypeOptions.assemble],
  [jobSteps.qa]: [jobTypeOptions.qc],
  [jobSteps.pack]: [jobTypeOptions.pack],
  [jobSteps.technician]: [jobTypeOptions.repair],
} satisfies Record<JobStep, JobType[]>;

/** Used specifically when adding a non-product job. Does not include the `Repair` type. */
export const addJobTypeOptionsForJobStep = {
  [jobSteps.assemble]: [
    jobTypeOptions.handoutsPrinting,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.training,
  ],
  [jobSteps.finish]: [
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.paintBoothCleaning,
    jobTypeOptions.paintGunCleaning,
    jobTypeOptions.sandRoomCleaning,
    jobTypeOptions.training,
  ],
  [jobSteps.pack]: [
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.training,
  ],
  [jobSteps.print]: [
    jobTypeOptions.bedTaping,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.rAndDSupport,
    jobTypeOptions.shredding,
    jobTypeOptions.spoolChangingBulk,
    jobTypeOptions.training,
  ],
  [jobSteps.technician]: [
    jobTypeOptions.heavyMachineryRepair,
    jobTypeOptions.inventoryManagement,
    jobTypeOptions.rAndDSupport,
    jobTypeOptions.training,
  ],
} satisfies Partial<Record<JobStep, JobType[]>>;

export const primaryStepsForJobs = [
  'Print',
  'Finish',
  'Assemble',
  'QA',
  'Pack',
] satisfies JobStep[];

export const primaryStepsJobsForDrafts = [
  'Assemble',
  'QA',
  'Pack',
] satisfies JobStep[];

export const machineTypes = [
  'Bambu Opaque',
  'Bambu Translucent',
  'Creality High-Temp PLA',
  'Creality Translucent PLA',
  'Tumbler',
  'Ultimaker High-Temp PLA',
  'Ultimaker Translucent PLA',
];

type DeprecatedJobType = 'Sand' | 'QA';

export const jobTypeToIconTypeMap: Record<
  JobType | DeprecatedJobType,
  IconType
> = {
  Assemble: 'work:assemble',
  'Bed Taping': 'work:tape',
  Bondo: 'actions:link',
  Glue: 'work:glue',
  'Handouts Printing': 'docs:document_lines',
  'Heavy Machinery Repair': 'work:wrench',
  'Inventory Management': 'work:triangle_ruler',
  Mask: 'work:mask',
  Pack: 'work:box',
  Paint: 'work:water_drop',
  'Paint Booth Cleaning': 'work:broom',
  'Paint Gun Cleaning': 'work:broom',
  Prepare: 'work:sd_card_outline',
  Prime: 'work:water_drop_dotted',
  Print: 'ui-control:lines_three',
  /** @deprecated legacy value */
  QA: 'actions:thumbs_up',
  QC: 'actions:thumbs_up',
  'R&D Support': 'work:light_bulb',
  Repair: 'work:print_nozzle',
  /** @deprecated legacy value */
  Sand: 'work:sand_paper',
  'Sand Room Cleaning': 'work:broom',
  'Sand-Painted': 'work:sand_painted',
  'Sand-Primed': 'work:sand_primed',
  'Sand-Raw': 'work:sand_paper',
  Shredding: 'ui-control:lines_three_vertical',
  'Spool Changing': 'work:spool',
  'Spool Changing (Bulk)': 'work:spool',
  Stage: 'work:assemble',
  Training: 'people:people',
  'Tumble-Raw': 'work:tumbler',
};

export const sortPartJobsToTop = (jobA, jobB) => {
  if (!!jobA.partId && !jobB.partId) {
    return -1;
  }

  if (!!jobB.partId && !jobA.partId) {
    return 1;
  }

  return 0;
};

export const maxFinishAttemptsBeforeRestart = 5;
