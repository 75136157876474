import { Badge } from 'gantri-components';
import { useTheme } from 'styled-components';
import { InventoryPurchaseStatus } from '../../../../api/inventory-purchases/inventory-purchases.types';
import { shipmentStatuses } from '../purchase-status-section/components/shipment/shipment.constants';
import { StatusBadgeProps } from './status-badge.types';

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  const theme = useTheme();

  const getBadgeColors = (
    status: InventoryPurchaseStatus,
  ): {
    borderColor: string;
    color: string;
    textColor: string;
  } => {
    switch (status) {
      case 'Partial':
      case 'Arrived':
        return {
          borderColor: theme.colors.surfaces.blue.t2,
          color: theme.colors.surfaces.blue.t1,
          textColor: theme.colors.typography.t1,
        };

      case 'Completed':
      case 'Delivered':
        return {
          borderColor: theme.colors.surfaces.green.t2,
          color: theme.colors.surfaces.green.t1,
          textColor: theme.colors.typography.t1,
        };

      case 'Waiting':
      case 'Ready to ship':
      case 'Shipped':
      default:
        return {
          borderColor: theme.colors.surfaces.monochrome.t3,
          color: theme.colors.surfaces.monochrome.overlayLevel2,
          textColor: theme.colors.typography.t1,
        };
    }
  };

  const badgeColors = getBadgeColors(status);

  return <Badge {...badgeColors} text={status || shipmentStatuses.waiting} />;
};
