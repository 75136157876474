import { Modal } from 'gantri-components';
import { useResetRecoilAtomsOnMount } from '../../../hooks/use-reset-recoil-atoms-on-mount';
import { rejectJobModalAtoms } from './reject-job-modal.atoms';
import { RejectJobModalFooter } from './components/reject-job-modal-footer';
import { RejectJobModalContent } from './components/reject-job-modal-content';
import { RejectJobModalProps } from './reject-job-modal.types';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../jobs-details-panel-wrapper/components/jobs-details-panel';

export const RejectJobModal = (props: RejectJobModalProps) => {
  const { job, onClose, onUpdate } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(rejectJobModalAtoms);

  return (
    <ResetAtomsWrapper>
      <Modal
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        footer={
          <RejectJobModalFooter
            jobId={job.id}
            onClose={onClose}
            onUpdate={onUpdate}
          />
        }
        header={`Reject Job #${job.id}: ${job.description}`}
        maxWidth={{ lg: '64rem', md: '100%' }}
        onClose={onClose}
      >
        <RejectJobModalContent job={job} />
      </Modal>
    </ResetAtomsWrapper>
  );
};
