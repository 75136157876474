import { formatAsLocaleNumber } from '../../helpers/formatter';
import { downloadToastText } from '../use-download-with-small-toast/use-download-with-small-toast.constants';
import { MoreMenuToggleOption } from '../use-more-menu/components/more-menu-toggle/more-menu-toggle.types';
import { useSmallToast } from '../use-small-toast';
import { useNotification } from '../useNotification';
import { downloadCSV } from './helpers/download-csv';
import { getDownloadFileName } from './helpers/get-download-file-name';
import { UseGetDownloadMenuOptionProps } from './use-get-download-menu-option.types';

export const useGetDownloadMenuOption = <Data extends Record<string, any>>(
  props: UseGetDownloadMenuOptionProps<Data>,
) => {
  const {
    columns,
    downloadCap = 10000,
    fetchRequestWithFilters,
    fileNamePrefix,
    totalResults,
  } = props;

  const { notifyError } = useNotification();

  const { Toast, setToastStatus, setToastText } = useSmallToast();

  const fileName = getDownloadFileName(fileNamePrefix);

  const onOptionClick = async () => {
    try {
      setToastText(downloadToastText.csv.startedText);

      const data = await fetchRequestWithFilters({
        count: downloadCap,
        page: 1,
      });

      downloadCSV({
        columns,
        fileName,
        records: data,
      });

      setToastStatus('success');
      setToastText(downloadToastText.csv.completedText);
    } catch (error: unknown) {
      console.error(error);
      notifyError(
        'CSV Download was unable to be completed. Please try again.',
        { keepOpen: true },
      );
    }
  };

  const downloadOption = {
    disabledTooltipProps: {
      description: `Download only available for ${formatAsLocaleNumber(
        downloadCap,
      )} results or less.`,
    },
    enabled: totalResults <= downloadCap,
    name: 'Download CSV',
    onOptionClick,
  } satisfies Partial<MoreMenuToggleOption<Data>>;

  return { Toast, downloadOption };
};
