import React, { FC, PropsWithChildren } from 'react';
import { HeadingProps } from './header.types';
import { HeaderPresets } from './header.presets';
import { PageHeading } from '../../../page-heading';

export const Header: FC<PropsWithChildren<HeadingProps>> = (props) => {
  const { children, ...rest } = props;

  return <PageHeading {...rest}>{children}</PageHeading>;
};

Header.defaultProps = HeaderPresets;
