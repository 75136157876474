import { Table } from 'gantri-components';
import styled, { css } from 'styled-components';

export const dataAttrIsEditing = 'data-is-editing';

export const dataAttrGroupTitle = 'data-template-group-title';

export const dataAttrGroupJob = 'data-template-group-job';

export const StyledTable = styled(Table)`
  ${({ theme }) => {
    return css`
      .tr {
        &[${dataAttrIsEditing}] {
          > .td:first-child {
            box-shadow: inset 2px 0 ${theme.colors.palette.green_300};
          }
        }

        &[${dataAttrGroupTitle}] {
          > .td {
            padding-top: 1.6rem;
            border-top: 1.6rem solid ${theme.colors.surfaces.monochrome.t1};
          }
        }

        &[${dataAttrGroupTitle}],
      &[${dataAttrGroupJob}] {
          > .td {
            background-color: ${theme.colors.surfaces.monochrome
              .t1Alt} !important;
          }
        }

        .th,
        .td {
          &[data-cell-menu] {
            vertical-align: middle;
          }
        }
      }
    `;
  }}
`;
