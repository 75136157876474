import { Cell, Conditional, Grid, Typography } from 'gantri-components';
import { useEffect, useState } from 'react';
import { InventoryPurchaseShipmentDateKeys } from '../../../../../../api/inventory-purchases/routes/update-inventory-purchase-status/update-inventory-purchase-status.types';
import { OnDateChanged } from '../../../../../../components/common/date-picker/date-picker.types';
import { ResponsiveGrid } from '../../../responsive-grid';
import { SectionSubheading } from '../../../section-subheading';
import { DateCell } from './components/date-cell';
import { TextInputCell } from './components/text-input-cell';
import { ShipmentButtons } from './components/shipment-buttons';
import { ShipmentStatusCell } from './components/shipment-status-cell';
import { StyledShipmentStack } from './shipment.styles';
import { ShipmentProps } from './shipment.types';
import { NumberInputCell } from './components/number-input-cell';
import { Shipment as ShipmentType } from '../../../../../../api/inventory-purchases/inventory-purchases.types';
import { shipmentText } from './shipment.constants';
import { ShipmentThumbnails } from './components/shipment-thumbnails';
import { Label } from '../../../../../../components/label';
import {
  getIsInvalidTrackingUrl,
  trackingUrlErrorMessage,
} from './helpers/get-is-invalid-tracking-url';

export const Shipment = (props: ShipmentProps) => {
  const {
    editedInventoryPurchase,
    index,
    isEditingDisabled,
    onUpdate,
    setIsEditingDisabled,
    totalShipments,
    unalteredShipment,
  } = props;

  const isNewShipment = !unalteredShipment.id;
  const shipmentNumber = index + 1;
  const wasDelivered = unalteredShipment.status === 'Delivered';

  const [editedShipment, setEditedShipment] =
    useState<ShipmentType>(unalteredShipment);

  const [isEditing, setIsEditing] = useState<boolean>(isNewShipment);

  const getOnDateChanged = (
    dateName: InventoryPurchaseShipmentDateKeys,
  ): OnDateChanged => {
    return ({ formattedFrom }) => {
      setEditedShipment((oldProps) => {
        return {
          ...oldProps,
          [dateName]: formattedFrom,
        };
      });
    };
  };

  useEffect(() => {
    if (!isEditingDisabled) {
      setEditedShipment(unalteredShipment);
    }
  }, [unalteredShipment, isEditingDisabled]);

  const totalQtyInShipments = editedInventoryPurchase.shipments.reduce<number>(
    (accumulator, { expectedQuantity, quantity, status }) => {
      return (
        accumulator + (status === 'Delivered' ? quantity : expectedQuantity)
      );
    },
    0,
  );

  const remainingQty = editedInventoryPurchase.units - totalQtyInShipments;
  const qtyPrefillValue = remainingQty <= 0 ? 0 : remainingQty;

  return (
    <StyledShipmentStack isEditing={isEditing}>
      <Grid
        alignItems="center"
        columns={{
          lg: 'max-content 1fr max-content',
          sm: 'max-content 1fr',
        }}
        gap="0.8rem"
      >
        <SectionSubheading
          text={`${
            isNewShipment ? 'Add' : isEditing ? 'Edit' : ''
          } Shipment #${shipmentNumber}`}
        />
        <Conditional
          condition={editedShipment.trackingLink && !isEditing}
          Fallback={<span />}
        >
          <Cell>
            <a
              href={editedShipment.trackingLink}
              rel="noreferrer"
              target="_blank"
            >
              <Typography color="link" text="Tracking Link" />
            </a>
          </Cell>
        </Conditional>
        <ShipmentButtons
          editedInventoryPurchase={editedInventoryPurchase}
          editedShipment={editedShipment}
          isEditing={isEditing}
          isEditingDisabled={isEditingDisabled}
          isNewShipment={isNewShipment}
          setEditedShipment={setEditedShipment}
          setIsEditing={setIsEditing}
          setIsEditingDisabled={setIsEditingDisabled}
          shipmentNumber={shipmentNumber}
          totalShipments={totalShipments}
          onUpdate={onUpdate}
        />
      </Grid>
      <ResponsiveGrid>
        <Conditional condition={isEditing}>
          <TextInputCell
            editedShipment={editedShipment}
            handleErrorMessage={(value) => {
              const isInvalid = getIsInvalidTrackingUrl(value);

              return isInvalid ? trackingUrlErrorMessage : undefined;
            }}
            isEditing={isEditing}
            keyName="trackingLink"
            label={shipmentText.trackingLink.label}
            placeholder={shipmentText.trackingLink.placeholder}
            setEditedShipment={setEditedShipment}
            width={{ lg: 5, md: 3, sm: 2 }}
          />
        </Conditional>
        <ShipmentStatusCell status={unalteredShipment.status} />
        <TextInputCell
          editedShipment={editedShipment}
          isEditing={isEditing}
          keyName="dimension"
          label={shipmentText.dimension.label}
          placeholder={shipmentText.dimension.placeholder}
          setEditedShipment={setEditedShipment}
        />
        <DateCell
          editedShipment={editedShipment}
          getOnDateChanged={getOnDateChanged}
          isEditing={isEditing}
          keyName="cargoReadyAt"
          label={shipmentText.cargoReadyAt.label}
        />
        <DateCell
          editedShipment={editedShipment}
          getOnDateChanged={getOnDateChanged}
          isEditing={isEditing}
          keyName="etaAt"
          label={shipmentText.etaAt.label}
        />
        <DateCell
          editedShipment={editedShipment}
          getOnDateChanged={getOnDateChanged}
          isEditing={isEditing}
          keyName="etdAt"
          label={shipmentText.etdAt.label}
        />

        <NumberInputCell
          editedShipment={editedShipment}
          isEditing={isEditing}
          keyName="expectedQuantity"
          label={shipmentText.expectedQuantity.label}
          placeholder={shipmentText.expectedQuantity.placeholder}
          prefillValue={qtyPrefillValue}
          required
          setEditedShipment={setEditedShipment}
        />

        <NumberInputCell
          editedShipment={editedShipment}
          isEditing={isEditing}
          keyName="grossWeight"
          label={shipmentText.grossWeight.label}
          placeholder={shipmentText.grossWeight.placeholder}
          setEditedShipment={setEditedShipment}
        />
        <DateCell
          allowFutureDays={false}
          editedShipment={editedShipment}
          getOnDateChanged={getOnDateChanged}
          isEditing={isEditing}
          keyName="shippedAt"
          label={shipmentText.shippedAt.label}
        />
        <DateCell
          allowFutureDays={false}
          editedShipment={editedShipment}
          getOnDateChanged={getOnDateChanged}
          isEditing={isEditing}
          keyName="arrivedAt"
          label={shipmentText.arrivedAt.label}
        />
        <DateCell
          allowFutureDays={false}
          editedShipment={editedShipment}
          keyName="deliveredAt"
          label={shipmentText.deliveredAt.label}
        />

        <Conditional condition={wasDelivered && !!editedShipment.quantity}>
          <Cell>
            <Label paddingBottom=".5x" text="Quantity" />
            <Typography text={editedShipment.quantity} />
          </Cell>
        </Conditional>

        <Conditional
          condition={wasDelivered && !!editedShipment.discardedQuantity}
        >
          <Cell>
            <Label paddingBottom=".5x" text="Discarded" />
            <Typography text={editedShipment.discardedQuantity} />
          </Cell>
        </Conditional>

        <Conditional condition={!!editedShipment.notes}>
          <Cell width={2}>
            <Conditional condition={!!editedShipment.notes}>
              <Label paddingBottom=".5x" text="Notes" />
              <Typography text={editedShipment.notes} />
            </Conditional>
          </Cell>
        </Conditional>

        <Conditional condition={!!editedShipment.photos.length}>
          <Cell>
            <ShipmentThumbnails
              editedShipment={editedShipment}
              shipmentNumber={shipmentNumber}
            />
          </Cell>
        </Conditional>
      </ResponsiveGrid>
    </StyledShipmentStack>
  );
};
