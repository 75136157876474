import {
  Conditional,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { DependencyList, useEffect, useMemo, useState } from 'react';
import { StockNumberDataCell } from '../../custom-data-cells/stock-number';
import { AttentionGridCell } from '../../../../components/layout/attention-grid-cell';
import { formatDate } from '../../../../helpers/formatter';
import { PartLocationInformation } from '../../../../components/common/part-location-information';
import { MEDIUM_FORMAT } from '../../../../constants/dates';
import { PaginatedPart } from '../../../../api/parts/routes/get-parts/get-parts.types';
import { PartStatusBadge } from '../../../../components/common/part-status-badge';
import { partStatuses } from '../../../../constants/options';
import { CellLastAction } from './components/cell-last-action';
import { getIsInProgressPart } from '../../../part/components/part-jobs/helpers/get-is-in-progress-part';
import { NotApplicableText } from '../../../../components/common/not-applicable-text';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import routePaths from '../../../../config/route-paths';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';
import { PartLocationInformationProps } from '../../../../components/common/part-location-information/part-location-information.types';
import { usePartLocations } from '../../../../components/common/part-location-information/use-part-locations';
import { ShortProductSummary } from '../../../../components/common/short-product-summary';

export const useGetTableColumns = (
  {
    handleLocationUpdate,
  }: {
    handleLocationUpdate: (responseData: any) => void;
  },
  dependencies: DependencyList,
) => {
  const columns: ColumnDef<PaginatedPart>[] = useMemo(() => {
    return [
      {
        accessorKey: 'id',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['id']>) => {
          const id = getValue();

          return (
            <StyledAnchor href={`${routePaths.parts}/${id}`} text={`#${id}`} />
          );
        },
        header: 'Part #',
        maxSize: 80,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'product',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['product']>) => {
          const product = getValue();

          return <ShortProductSummary {...product} />;
        },
        header: 'Product',
        size: 200,
      },
      {
        accessorKey: 'stock',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['stock']>) => {
          const stock = getValue();

          return !!stock ? (
            <StockNumberDataCell {...stock} />
          ) : (
            <NotApplicableText />
          );
        },
        header: 'Stock #',
        maxSize: 80,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'order',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['order']>) => {
          const order = getValue();

          return (
            <Conditional condition={!!order}>
              <StyledAnchor
                href={`${routePaths.orders}/${order?.id}`}
                text={`${order?.type} #${order?.id}`}
              />
            </Conditional>
          );
        },
        header: 'Order #',
        maxSize: 120,
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
      },
      {
        accessorKey: 'name',
        header: 'Part Name',
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['status']>) => {
          const status = getValue();

          return (
            <Conditional
              condition={status === partStatuses.discarded}
              Fallback={<PartStatusBadge status={status} />}
            >
              <Flex alignItems="center" gap="0.8rem">
                <PartStatusBadge status={status} />
                <Tooltip
                  description="Due to the stock being cancelled, this part now has a Discarded status. Please ensure that the part is discarded."
                  overlayContainerStyles={{ display: 'flex' }}
                  position="top"
                >
                  <Icon name="alert:i_circle" />
                </Tooltip>
              </Flex>
            </Conditional>
          );
        },
        header: 'Status',
      },
      {
        accessorKey: 'printYield',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['status']>) => {
          const printYield = getValue();

          return /n\/a/i.test(printYield) ? (
            <NotApplicableText />
          ) : (
            <Typography text={printYield} />
          );
        },
        header: 'Print yield %',
        maxSize: 100,
      },
      {
        accessorKey: 'attempt',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['attempt']>) => {
          const attempt = getValue();

          return (
            <Flex alignItems="center" gap="0.6rem">
              <Conditional condition={attempt > 1}>
                <Icon color="t2" name="arrows:arrow_rotate_two" />
              </Conditional>
              <Typography text={attempt} />
            </Flex>
          );
        },
        header: 'Atmpt',
        size: 60,
      },
      {
        accessorKey: 'version',
        cell: ({
          getValue,
        }: CellContext<PaginatedPart, PaginatedPart['version']>) => {
          return <Typography text={getValue()} />;
        },
        header: 'Version',
        size: 80,
      },
      {
        accessorKey: 'lastActionAt',
        cell: CellLastAction,
        header: 'Last Action',
      },
      {
        accessorKey: 'completedAt',
        cell: ({ getValue }) => {
          return formatDate(getValue(), MEDIUM_FORMAT);
        },
        header: 'Ended',
        size: 80,
      },
      {
        accessorKey: 'location',
        cell: ({
          getValue,
          row: { original },
        }: CellContext<PaginatedPart, PaginatedPart['location']>) => {
          const location = getValue();

          const props = {
            id: original.id,
            location,
            locationTimelines: original.locationTimelines,
            onLocationUpdate: handleLocationUpdate,
            source: 'stock',
          };

          return <PartLocationInformation {...props} />;
        },
        header: 'Location',
        maxSize: 340,
        meta: {
          getOnClick: ({
            getValue,
            row,
          }: Cell<PaginatedPart, PaginatedPart['location']>) => {
            const { id, locationTimelines } = row.original;
            const location = getValue();

            const partLocationProps: PartLocationInformationProps = {
              id,
              location,
              locationTimelines,
              onLocationUpdate: handleLocationUpdate,
              source: 'stock',
            };

            return () => {
              setPartLocationProps(partLocationProps);
            };
          },
        },
        size: 250,
      },
      {
        accessorKey: 'attentions',
        cell: ({
          getValue,
          row,
        }: CellContext<PaginatedPart, PaginatedPart['attentions']>) => {
          const { status } = row.original;
          const isInProgressPart = getIsInProgressPart(status);

          return (
            <Conditional condition={isInProgressPart}>
              <AttentionGridCell attentions={getValue()} />
            </Conditional>
          );
        },
        header: 'Attention',
        maxSize: 100,
      },
    ];
  }, dependencies);

  const [partLocationProps, setPartLocationProps] =
    useState<PartLocationInformationProps>({
      id: undefined,
      location: undefined,
      locationTimelines: [],
      onLocationUpdate: handleLocationUpdate,
      source: 'stock',
    });

  const { showLocationModal } = usePartLocations(partLocationProps);

  useEffect(() => {
    if (partLocationProps.id) {
      showLocationModal();
    }
  }, [partLocationProps]);

  return columns;
};
