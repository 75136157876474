import { useMemo } from 'react';

export const useParseDataAttributes = (
  dataAttributes: Record<string, unknown>,
) => {
  return useMemo(() => {
    return Object.keys(dataAttributes || {}).reduce(
      (acc: Record<string, unknown>, key: string) => {
        acc[`data-${key}`] = dataAttributes[key];

        return acc;
      },
      {},
    );
  }, [dataAttributes]);
};
