import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsApi, machinesApi } from '../../../../../../../../../../api';
import { MachineTasksFooterProps } from './machine-tasks-footer.types';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { startPrintPrintJobModalAtoms } from '../../../start-print-job-modal.atoms';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { useCreateNonProductJob } from '../../../../../../../../../../api/jobs/routes/create-non-product-job/create-non-product-job.query';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../../constants/options';
import { userAtoms } from '../../../../../../../../../../global-atoms/users';

export const MachineTasksFooter = (props: MachineTasksFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const assignedMachine = useRecoilValue(
    startPrintPrintJobModalAtoms.assignedMachine,
  );
  const buildPlateNeedsChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateNeedsChanged,
  );
  const buildPlateChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.buildPlateChanged,
  );
  const spoolChanged = useRecoilValue(
    startPrintPrintJobModalAtoms.spoolChanged,
  );
  const inventoryRequests = useRecoilValue(
    startPrintPrintJobModalAtoms.inventoryRequests,
  );

  const setStep = useSetRecoilState(startPrintPrintJobModalAtoms.step);

  const { onCreateNonProductJob } = useCreateNonProductJob();

  const { id: userId } = useRecoilValue(userAtoms.user);

  const handleUpdateAndStartJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        if (buildPlateChanged) {
          await machinesApi.resetJobCountdown(assignedMachine);
        }

        if (spoolChanged) {
          await onCreateNonProductJob({
            autoComplete: true,
            jobData: {
              assignedTo: userId,
              description: jobTypeOptions.spoolChanging,
              duration: null,
              instruction: { text: '' },
              inventoryRequests,
              step: jobSteps.print,
              type: jobTypeOptions.spoolChanging,
            },
          });
        }

        const { data } = await jobsApi.startJob({
          jobId: job.id,
          machineId: assignedMachine,
        });

        notify(data.notice);

        await handleCloseModal({ updateOnClose: true });
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to start job.',
        });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('ASSIGN_MACHINE');
        }}
      />
      <Button
        disabled={buildPlateNeedsChanged && !buildPlateChanged}
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text={`Confirm ${jobTypeOptions.print} Start`}
        onClick={handleUpdateAndStartJob}
      />
    </>
  );
};
