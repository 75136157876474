import { atom } from 'recoil';

const atomKeyPrefix = 'JOBS_DETAILS_PANEL';

export const jobsDetailsPanelAtoms = {
  activeJobId: atom<number>({
    default: undefined,
    key: `${atomKeyPrefix}-active-job-id`,
  }),
  jobIds: atom<number[]>({
    default: [],
    key: `${atomKeyPrefix}-job-ids`,
  }),
  viewInEditMode: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-view-in-edit-mode`,
  }),
};
