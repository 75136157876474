import { pluralize } from '../../../../../../../../helpers/pluralize';
import { ModalContentHeading } from '../../../../common/modal-content-heading';
import { useGetActiveJobSet } from '../../../hooks/use-get-active-job-set';

export const JobSetStartContent = () => {
  const set = useGetActiveJobSet();
  const numJobs = set?.jobDetails?.jobIds?.length || 0;

  return (
    <ModalContentHeading
      subTitleHtmlText={`Are you sure you want to start <strong>${numJobs} ${pluralize(
        'job',
        numJobs,
      )}</strong>?`}
      titleText="Start job set"
    />
  );
};
