/** Returns the number of ticks to render. If `[number of data points] % [return value] === 0`,  */
export const getTicksEveryXDays = (numDaysInRange: number) => {
  if (numDaysInRange <= 7) {
    return 1;
  }

  if (numDaysInRange <= 30) {
    return 7;
  }

  return 30;
};
