import { Button, Conditional, Stack } from 'gantri-components';
import { FooterAlertText } from '../../../../../../../jobs/components/modals/common/footer-alert-text';
import { fullScreenModalFooterButtonSize } from '../../../../../../../jobs/components/modals/modals.constants';
import { ConfirmUsedInventoryFooterProps } from './confirm-used-inventory-footer.types';

export const ConfirmUsedInventoryFooter = (
  props: ConfirmUsedInventoryFooterProps,
) => {
  const { inventoryRequests, setStep } = props;

  const maxStockExceeded = inventoryRequests.some(
    ({ discarded, inventory, need }) => {
      return Number(need) + (discarded || 0) > inventory.stock;
    },
  );

  return (
    <Stack gap="1.6rem">
      <Conditional condition={maxStockExceeded}>
        <FooterAlertText
          color="alert"
          iconName="alert:warning_triangle"
          text="Inventory selections exceed available stock."
        />
      </Conditional>

      <Button
        disabled={maxStockExceeded}
        size={fullScreenModalFooterButtonSize}
        text="Confirm Used Inventory"
        onClick={() => {
          setStep('CONFIRM_COMPLETE_REPAIR');
        }}
      />
    </Stack>
  );
};
