import { atom } from 'recoil';

const atomKeyPrefix = 'BATCH_PAGE';

export const batchAtoms = {
  isAddingPart: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-adding-part`,
  }),
  isReorderActive: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-reorder-active`,
  }),
};
