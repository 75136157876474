import { FC } from 'react';
import { Typography } from 'gantri-components';
import LoadingSpinner from '../../../../../../components/common/spinner';
import { BackCard } from '../handout/components/back-card';
import { FrontCard } from '../handout/components/front-card';
import {
  StyledCardWrapper,
  StyledFlexContainer,
} from './preview-section.styles';
import { PreviewSectionProps } from './preview-section.types';

export const PreviewSection: FC<PreviewSectionProps> = ({ designerInfo }) => {
  return (
    <>
      <Typography
        marginBottom="1rem"
        text="Preview"
        textStyle="bold"
        variant="h4"
      />
      {designerInfo.handout ? (
        <StyledFlexContainer designerInfo={designerInfo} wrap>
          <StyledCardWrapper>
            <FrontCard designerInfo={designerInfo} />
          </StyledCardWrapper>
          <StyledCardWrapper>
            <BackCard designerInfo={designerInfo} />
          </StyledCardWrapper>
        </StyledFlexContainer>
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};
