import { InventoryRequest } from '../../../../../../api/jobs/jobs.types';
import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';

export const getJobInventoryRequests = (args: {
  startingJobData: JobDetails;
}) => {
  const { startingJobData } = args;

  const jobInventoryRequests: InventoryRequest[] = !!startingJobData?.id
    ? startingJobData?.inventoryRequests?.map((inventoryRequestItem) => {
        return {
          id: inventoryRequestItem.inventory.id,
          inventory: inventoryRequestItem.inventory,
          need: inventoryRequestItem.need,
        };
      }) || []
    : [];

  return jobInventoryRequests;
};
