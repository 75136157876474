import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { SaveDesignData, SaveDesignResponse } from './save-design.types';

export const saveDesign = (designId: number, data: SaveDesignData) => {
  return axios.put<SaveDesignResponse>(
    `${getCoreApiUrl('designs')}/${designId}/save`,
    data,
  );
};
