import { JobType, jobTypeOptions } from '../../../../../../constants/options';
import { GetIsHandleBatchJobsDisabledArgs } from './get-is-handle-batch-jobs-disabled.types';

export const getIsHandleBatchJobsDisabled = (
  args: GetIsHandleBatchJobsDisabledArgs,
) => {
  const { isReorderActive, parts, requiredStatus } = args;
  const firstPart = parts?.[0];

  if (firstPart?.currentJob) {
    const { step, type } = firstPart.currentJob;

    const acceptableJobTypes: JobType[] = [
      jobTypeOptions.prime,
      jobTypeOptions.paint,
    ];

    const isDisabled =
      isReorderActive ||
      parts.some(({ currentJob }) => {
        return (
          !acceptableJobTypes.some((type) => {
            return type === currentJob?.type;
          }) ||
          requiredStatus !== currentJob?.status ||
          step !== currentJob?.step ||
          type !== currentJob?.type
        );
      });

    return isDisabled;
  }

  return true;
};
