import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { getYieldCellDetails } from '../../helpers/get-yield-cell-details';
import { FailReasonsCell } from '../common/fail-reasons-cell';
import { NumberCell } from '../common/number-cell';
import { AssemblyJobsTableData } from './dashboard-assembly-jobs-summary.types';

export const assemblyJobsTableColumns: ColumnDef<AssemblyJobsTableData>[] = [
  {
    accessorKey: 'type',
    cell: (
      props: CellContext<AssemblyJobsTableData, AssemblyJobsTableData['type']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <Typography text={getValue()} />;
    },
    header: 'Jobs',
    size: 80,
  },
  {
    accessorKey: 'stage',
    cell: (
      props: CellContext<AssemblyJobsTableData, AssemblyJobsTableData['stage']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Stage',
    size: 80,
  },
  {
    accessorKey: 'assemble',
    cell: (
      props: CellContext<
        AssemblyJobsTableData,
        AssemblyJobsTableData['assemble']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue, row } = props;
      const { 'assemble-fail-reasons': printQAFailReasons } = row.original;

      return (
        <FailReasonsCell failReasons={printQAFailReasons} value={getValue()} />
      );
    },
    header: 'Assemble',
    size: 80,
  },
  {
    accessorKey: 'qa',
    cell: (
      props: CellContext<AssemblyJobsTableData, AssemblyJobsTableData['qa']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue, row } = props;
      const { 'qa-fail-reasons': printQAFailReasons } = row.original;

      return (
        <FailReasonsCell failReasons={printQAFailReasons} value={getValue()} />
      );
    },
    header: 'QA',
    size: 80,
  },
  {
    accessorKey: 'pack',
    cell: (
      props: CellContext<AssemblyJobsTableData, AssemblyJobsTableData['pack']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Pack',
    size: 80,
  },
  {
    accessorKey: 'assemble-worker',
    cell: (
      props: CellContext<
        AssemblyJobsTableData,
        AssemblyJobsTableData['assemble-worker']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Assemble / Worker',
    size: 80,
  },
];
