import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';

export const ConfirmBeginContent = () => {
  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to complete ${jobSteps.assemble} ${jobTypeOptions.assemble}?`}
      titleText="Start job"
    />
  );
};
