import { useDownload } from '../use-download';
import { UseDownloadWithSmallToastProps } from './use-download-with-small-toast.types';
import { useSmallToast } from '../use-small-toast';

export const useDownloadWithSmallToast = (
  props: UseDownloadWithSmallToastProps,
) => {
  const {
    completedText,
    errorText,
    onComplete,
    onError,
    onStart,
    startedText,
  } = props;

  const { Toast, setToastStatus, setToastText } = useSmallToast();

  const useDownloadValues = useDownload({
    onComplete: () => {
      setToastText(completedText);
      setToastStatus('success');
      onComplete?.();
    },
    onError: (file) => {
      setToastText(errorText);
      setToastStatus('error');
      onError?.(file);
    },
    onStart: () => {
      setToastText(startedText);
      onStart?.();
    },
  });

  return { DownloadStatusToast: Toast, ...useDownloadValues };
};
