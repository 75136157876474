import { Cell } from 'gantri-components';
import styled, { css, CSSProperties } from 'styled-components';

export const StyledProgressCell = styled(Cell)`
  width: 100%;
`;

export const StyledDot = styled.div<{
  $borderColor: CSSProperties['color'];
  isActive: boolean;
}>`
  ${({ $borderColor, isActive, theme }) => {
    return css`
      border-radius: 100%;
      height: 0.8rem;
      width: 0.8rem;
      background-color: ${isActive
        ? theme.colors.iconography.link
        : theme.colors.iconography.t3};
      position: relative;
      z-index: 2;
      box-shadow: 0 0 0 0.4rem
        ${$borderColor || theme.colors.surfaces.monochrome.t1};
    `;
  }}
`;

export const StyledLine = styled.div<{
  $left?: CSSProperties['left'];
  $right?: CSSProperties['right'];
  $width: CSSProperties['width'];
  isActive: boolean;
}>`
  ${({ $left = 'auto', $right = 'auto', $width, isActive, theme }) => {
    return css`
      position: absolute;
      z-index: 1;
      top: 50%;
      height: 1px;
      left: ${$left};
      right: ${$right};
      width: ${$width};
      background-color: ${isActive
        ? theme.colors.iconography.link
        : theme.colors.iconography.t3};
    `;
  }}
`;
