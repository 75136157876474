import {
  Button,
  Conditional,
  Flex,
  Grid,
  Icon,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { batchAtoms } from '../../batch.atoms';
import { StyledFlex } from './add-part-to-batch.styles';
import { AddPartToBatchProps } from './add-part-to-batch.types';
import { AddPartToBatchForm } from './components/add-part-to-batch-form';

export const AddPartToBatch = (props: AddPartToBatchProps) => {
  const { batch, setBatch } = props;

  const [isAddingPart, setIsAddingPart] = useRecoilState(
    batchAtoms.isAddingPart,
  );
  const isReorderActive = useRecoilValue(batchAtoms.isReorderActive);

  return (
    <Conditional
      condition={isAddingPart}
      Fallback={
        <Flex paddingTop="x">
          <Button
            disabled={isReorderActive}
            icon={
              <Icon
                color={isReorderActive ? 't1' : 'link'}
                name="ui-control:plus"
              />
            }
            text="Add Part"
            variant="ghost"
            onClick={() => {
              setIsAddingPart(true);
            }}
          />
        </Flex>
      }
    >
      <StyledFlex alignItems="center" gap=".5x" padding="x">
        <Grid alignItems="center" columns="2.4rem max-content" gap="x">
          <Typography align="center" text={(batch?.parts?.length || 0) + 1} />
          <AddPartToBatchForm batch={batch} setBatch={setBatch} />
        </Grid>
      </StyledFlex>
    </Conditional>
  );
};
