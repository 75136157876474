import { Conditional, Typography } from 'gantri-components';
import { SeverityImagesRow } from './components/severity-images-row';
import { SeverityImagesProps } from './severity-images.types';

export const SeverityImages = (props: SeverityImagesProps) => {
  const { checklistItem, material, updateChecklistItem } = props;

  const showSecondaryOptions =
    checklistItem.ratingPrimarySurface &&
    checklistItem.ratingPrimarySurface < 5;

  return (
    <>
      <Typography
        text="Use the following images to determine the severity."
        variant="p3"
      />
      <SeverityImagesRow
        checklistItem={checklistItem}
        justPassImgSrc={checklistItem.severityImages.justPassPrimary}
        material={material}
        surface="Primary"
        updateChecklistItem={updateChecklistItem}
      />
      <Conditional condition={showSecondaryOptions}>
        <SeverityImagesRow
          checklistItem={checklistItem}
          justPassImgSrc={checklistItem.severityImages.justPassSecondary}
          material={material}
          surface="Secondary"
          updateChecklistItem={updateChecklistItem}
        />
      </Conditional>
    </>
  );
};
