import { useRecoilState } from 'recoil';
import { inventoryPurchasesPageAtoms } from './inventory-purchases-filter.atoms';
import { UseInventoryPurchaseFilter } from './inventory-purchases-filter.types';

export const useInventoryPurchasesFilter = (): UseInventoryPurchaseFilter => {
  const [createdDateRange, setCreatedDateRange] = useRecoilState(
    inventoryPurchasesPageAtoms.filters.createdDateRange,
  );

  const [completedDateRange, setCompletedDateRange] = useRecoilState(
    inventoryPurchasesPageAtoms.filters.completedDateRange,
  );

  const [country, setCountry] = useRecoilState(
    inventoryPurchasesPageAtoms.filters.country,
  );

  const [statuses, setStatuses] = useRecoilState(
    inventoryPurchasesPageAtoms.filters.statuses,
  );

  const [hasDiscardedShipment, setHasDiscardedShipment] = useRecoilState(
    inventoryPurchasesPageAtoms.filters.hasDiscardedShipment,
  );

  return {
    completedDateRange,
    country,
    createdDateRange,
    hasDiscardedShipment,
    setCompletedDateRange,
    setCountry,
    setCreatedDateRange,
    setHasDiscardedShipment,
    setStatuses,
    statuses,
  };
};
