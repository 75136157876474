import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { CreateStocksArgs, CreateStocksResponse } from './create-stocks.types';

export const createStocks = (stocks: CreateStocksArgs) => {
  return axios
    .post<CreateStocksResponse>(getCoreApiUrl('stocks'), stocks)
    .then(({ data }) => {
      return data;
    });
};
