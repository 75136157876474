import {
  Cell,
  Checkbox,
  Conditional,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { FailedReason } from '../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { AdvancedChecklistProps } from './advanced-checklist.types';
import { checkAgainstChecklistItems } from '../../../../helpers/check-against-checklist-items';
import { AdvancedChecklistReason } from './components/advanced-checklist-reason';
import { advancedChecklistDefaultProps } from './advanced-checklist.presets';
import { ModalContentHeading } from '../../../modal-content-heading';
import { getMissingEvaluationsMessage } from '../../helpers/get-missing-evaluations-message';

export const AdvancedChecklist = (props: AdvancedChecklistProps) => {
  const {
    buttonToggleWidth,
    checklist,
    getSubTitle,
    isChecklistReviewed,
    material,
    onItemChange,
    setIsChecklistReviewed,
    showConfirmCheckbox,
    statuses,
    title,
  } = props;

  const checklistReasons = Object.keys(checklist);

  const isChecklistFailing = checkAgainstChecklistItems(
    checklist,
    ({ status }) => {
      return status !== 'Pass';
    },
  );

  const prevIsChecklistFailing = usePrevious(isChecklistFailing);

  const hideConfirmCheckbox = !showConfirmCheckbox || isChecklistFailing;

  const missingEvaluationsMessage = getMissingEvaluationsMessage(checklist);

  const isChecklistComplete = !missingEvaluationsMessage;

  useEffect(() => {
    if (isChecklistComplete) {
      if (isChecklistFailing) {
        setIsChecklistReviewed(true);
      } else if (prevIsChecklistFailing) {
        setIsChecklistReviewed(false);
      }
    } else {
      setIsChecklistReviewed(false);
    }
  }, [isChecklistComplete, isChecklistFailing, prevIsChecklistFailing]);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        subTitleText={getSubTitle(checklistReasons.length)}
        titleText={title}
      />

      {checklistReasons.map((reason: FailedReason, index) => {
        const reasonNum = index + 1;
        const checklistItem = checklist[reason];

        return (
          <AdvancedChecklistReason
            key={`${reason}-${index}`}
            buttonToggleWidth={buttonToggleWidth}
            checklistItem={checklistItem}
            material={material}
            reason={reason}
            reasonNum={reasonNum}
            statuses={statuses}
            onItemChange={onItemChange}
          />
        );
      })}

      <Conditional condition={!hideConfirmCheckbox}>
        <Stack gap="x">
          <Typography text="Confirmation" variant="h5" />

          <Typography
            text="By checking the box, you are confirming that you have reviewed all issues."
            onClick={
              isChecklistComplete
                ? () => {
                    setIsChecklistReviewed(!isChecklistReviewed);
                  }
                : undefined
            }
          />

          <Cell justifyContent="flex-start" paddingTop="0.2rem">
            <Tooltip
              Component={
                !isChecklistComplete && (
                  <Typography
                    color="alert"
                    padding="1.2rem"
                    text={missingEvaluationsMessage}
                  />
                )
              }
              overlayContainerStyles={{ display: 'block', width: '100%' }}
              position="right"
            >
              <Checkbox
                checked={isChecklistReviewed}
                disabled={!isChecklistComplete}
                onSelected={setIsChecklistReviewed}
              />
            </Tooltip>
          </Cell>
        </Stack>
      </Conditional>
    </Stack>
  );
};

AdvancedChecklist.defaultProps = advancedChecklistDefaultProps;
