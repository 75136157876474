import {
  Badge,
  Conditional,
  Flex,
  Grid,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import { PaginatedInventory } from '../../../../api/inventories/routes/fetch-paginated-inventories/fetch-paginated-inventories.types';
import routePaths from '../../../../config/route-paths';
import Thumbnail from '../../../../components/common/thumbnail/thumbnail';
import { CurrencyCell } from '../../../dashboard/components/common/currency-cell';
import { TableHeaderWithInfoToolTip } from '../../../../components/common/custom-data-cells/headers';
import { StyledAnchor } from '../../../../components/common/styled-anchor';
import { useEditInventoryModal } from '../../components/edit-inventory/use-edit-inventory-modal';
import { useToggleStatus } from '../../use-toggle-status';
import { MoreMenu } from '../../../../components/dropdowns';
import { MoreMenuOption } from '../../../../components/dropdowns/more-menu/more-menu.types';
import { UseGetInventoriesColumnsProps } from './use-get-inventories-columns.types';
import { useInvalidateInventoriesQueryCache } from '../../../../api/inventories/routes/queries-cache';
import { getIsClickableIfValue } from '../../../../helpers/get-is-clickable-if-value';

export const useGetInventoriesColumns = (
  props: UseGetInventoriesColumnsProps,
) => {
  const { records, setRecords } = props;
  const theme = useTheme();
  const { invalidateAllInventoriesCache } =
    useInvalidateInventoriesQueryCache();

  const [inventoryToEdit, setInventoryToEdit] =
    useState<PaginatedInventory>(null);

  const [showEditInventoryModal] = useEditInventoryModal({
    inventory: inventoryToEdit,
    onSuccess: async (updateInventory: PaginatedInventory) => {
      const updatedInventories = records.map((inventory) => {
        return inventory.id === updateInventory.id
          ? updateInventory
          : inventory;
      });

      setRecords(updatedInventories);
      await invalidateAllInventoriesCache();
    },
  });

  const { onToggleActivate } = useToggleStatus({
    onSuccess: async (updatedInventory) => {
      const updatedInventories = records.map((inventory) => {
        return inventory.id === updatedInventory.id
          ? updatedInventory
          : inventory;
      });

      setRecords(updatedInventories);
      await invalidateAllInventoriesCache();
    },
  });

  const columns: ColumnDef<PaginatedInventory>[] = [
    {
      accessorKey: 'id',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<PaginatedInventory, PaginatedInventory['id']>) => {
        return (
          <StyledAnchor
            href={`${routePaths.inventories}/${original.id}`}
            text={`#${getValue()}`}
          />
        );
      },
      header: 'Inventory #',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      size: 100,
    },
    {
      accessorKey: 'name',
      cell: ({
        getValue,
        row: { original },
      }: CellContext<PaginatedInventory, PaginatedInventory['name']>) => {
        return (
          <Grid alignItems="center" columns="4rem 1fr" gap="0.6rem">
            <Thumbnail size="4rem" src={original.thumbnailUrl} />
            <Typography text={getValue()} />
          </Grid>
        );
      },
      header: 'Name',
      minSize: 250,
    },
    {
      accessorKey: 'vendor',
      cell: ({
        getValue,
      }: CellContext<PaginatedInventory, PaginatedInventory['vendor']>) => {
        const vendor = getValue();

        return (
          <Conditional condition={!!vendor?.name}>
            <StyledAnchor
              href={`${routePaths.vendors}/${vendor?.id}`}
              text={vendor?.name}
            />
          </Conditional>
        );
      },
      header: 'Vendor',
      meta: {
        getIsClickable: getIsClickableIfValue,
      },
      minSize: 250,
    },
    {
      accessorKey: 'type',
      header: 'Type',
    },
    {
      accessorKey: 'unit',
      header: 'Unit',
      size: 60,
    },
    {
      accessorKey: 'unitCost',
      cell: ({
        getValue,
        row,
      }: CellContext<PaginatedInventory, PaginatedInventory['unitCost']>) => {
        return (
          <CurrencyCell
            currency={row.original.currency || 'USD'}
            value={getValue()}
          />
        );
      },
      header: 'Cost',
      size: 60,
    },
    {
      accessorKey: 'locations',
      cell: ({
        getValue,
      }: CellContext<PaginatedInventory, PaginatedInventory['locations']>) => {
        const locations = getValue();
        const [firstLocation, ...restLocations] = locations;
        const numLocations = locations.length || 0;

        return (
          <Conditional
            condition={!!numLocations}
            Fallback={<Typography text="-" />}
          >
            <Grid alignItems="baseline" columns="max-content 1fr" gap=".5x">
              <Icon color="t2" name="location:location_pin" top="2px" />
              <Stack gap="x">
                <Typography text={firstLocation?.name} />
                <Conditional condition={numLocations > 1}>
                  <Typography
                    icon={
                      <Tooltip
                        Component={
                          <Stack horizontalPadding="2x" verticalPadding="x">
                            {restLocations.map(({ id, name }) => {
                              return <Typography key={id} text={name} />;
                            })}
                          </Stack>
                        }
                        maxWidth="18.6rem"
                        position="bottom-start"
                      >
                        <Icon name="alert:i_circle" />
                      </Tooltip>
                    }
                    iconPosition="right"
                    text={`+ ${numLocations - 1} other`}
                  />
                </Conditional>
              </Stack>
            </Grid>
          </Conditional>
        );
      },
      header: 'Location',
      size: 160,
    },
    {
      accessorKey: 'usage',
      header: () => {
        return (
          <TableHeaderWithInfoToolTip
            headerText="Usage"
            tooltipText="Average weekly stock usage in the last 8 weeks."
          />
        );
      },
      size: 80,
    },
    {
      accessorKey: 'discarded',
      cell: ({
        getValue,
      }: CellContext<PaginatedInventory, PaginatedInventory['discarded']>) => {
        const discarded = getValue();

        return <Typography text={discarded ? `${discarded}%` : '-'} />;
      },
      header: () => {
        return (
          <TableHeaderWithInfoToolTip
            headerText="% Discarded"
            tooltipText="Percent of last 100 inventory transactions with transactions type : Discarded."
          />
        );
      },
      size: 140,
    },
    {
      accessorKey: 'stock',
      cell: ({
        getValue,
      }: CellContext<PaginatedInventory, PaginatedInventory['stock']>) => {
        return <Typography color="t1" text={getValue()} />;
      },
      header: 'Stock',
      size: 60,
    },
    {
      accessorKey: 'status',
      cell: ({
        getValue,
      }: CellContext<PaginatedInventory, PaginatedInventory['status']>) => {
        const status = getValue();

        return (
          <Badge
            borderColor={
              status === 'Active'
                ? theme.colors.surfaces.green.t2
                : theme.colors.surfaces.monochrome.t3
            }
            color={
              status === 'Active'
                ? theme.colors.surfaces.green.t1
                : theme.colors.surfaces.monochrome.overlayLevel2
            }
            text={status}
            textColor={theme.colors.typography.t1}
          />
        );
      },
      header: 'Status',
      size: 80,
    },
    {
      cell: ({
        row,
      }: CellContext<PaginatedInventory, PaginatedInventory['stock']>) => {
        const menuOptions: MoreMenuOption<PaginatedInventory>[] = [
          {
            enabled: true,
            name: 'Edit',
            onOptionClick: (record) => {
              setInventoryToEdit(record);
              showEditInventoryModal();
            },
          },
          {
            enabled: true,
            name: row.original.status === 'Active' ? 'Deactivate' : 'Activate',
            onOptionClick: onToggleActivate,
          },
        ];

        return (
          <Flex justifyContent="flex-end">
            <MoreMenu data={row.original} options={menuOptions} />
          </Flex>
        );
      },
      id: 'menu',
      size: 30,
    },
  ];

  return columns;
};
