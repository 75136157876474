import * as yup from 'yup';
import {
  numberRule,
  required,
  validateRequiredNumber,
} from '../../../helpers/validators';

export const NewGiftCodeSchema = yup.object().shape({
  accountLimit: validateRequiredNumber(),
  amount: yup.lazy((_, context) => {
    return context.parent.type !== 'Gift'
      ? validateRequiredNumber()
      : numberRule(yup.string().nullable(true));
  }),
  code: required(),
  details: required(),
  totalLimit: validateRequiredNumber(),
});
