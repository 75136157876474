import axios from 'axios';
import {
  getRoleTypeCookie,
  getTokenCookie,
  isValidAdminRoleType,
  redirectToMantle,
  SESSION_IDENTIFIER,
} from '../helpers/auth';
import { clearAllCache } from '../helpers/versioning';

axios.interceptors.request.use(
  async (config) => {
    return {
      ...config,
      headers: {
        ...config.headers,
        Accept: 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
        'Session-Identifier': SESSION_IDENTIFIER,
      },
    };
  },
  (error) => {
    Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 401 &&
      !isValidAdminRoleType(getRoleTypeCookie())
    ) {
      clearAllCache();
      redirectToMantle();
    }

    return Promise.reject(error);
  },
);
