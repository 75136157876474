import { Cell, Dropdown, Toggle } from 'gantri-components';
import DatePicker from '../../common/date-picker';
import {
  countryOptions,
  inventoryPurchaseStatusOptions,
} from './inventory-purchases-filter.constants';
import { OnDateChanged } from '../../common/date-picker/date-picker.types';
import { useInventoryPurchasesFilter } from './use-inventory-purchases-filter';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { inventoryPurchasesPageAtoms } from './inventory-purchases-filter.atoms';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

export const InventoryPurchasesFilter = () => {
  const {
    completedDateRange,
    country,
    createdDateRange,
    hasDiscardedShipment,
    setCompletedDateRange,
    setCountry,
    setCreatedDateRange,
    setHasDiscardedShipment,
  } = useInventoryPurchasesFilter();

  const onCreatedDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCreatedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const onCompletedDateChanged: OnDateChanged = ({
    formattedFrom,
    formattedTo,
  }) => {
    setCompletedDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={inventoryPurchasesPageAtoms.filters.createdDateRange}
          default={
            inventoryPurchasesPageAtoms.defaults.filters.createdDateRange
          }
          text="Created"
        />
        <DatePicker
          autoWidth
          initialValue={createdDateRange}
          range
          onDateChanged={onCreatedDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryPurchasesPageAtoms.filters.completedDateRange}
          default={
            inventoryPurchasesPageAtoms.defaults.filters.completedDateRange
          }
          text="Completed"
        />
        <DatePicker
          autoWidth
          initialValue={completedDateRange}
          range
          onDateChanged={onCompletedDateChanged}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryPurchasesPageAtoms.filters.country}
          default={inventoryPurchasesPageAtoms.defaults.filters.country}
          text="Origin"
        />
        <Dropdown
          items={countryOptions}
          placeholder="Select country..."
          value={country}
          onSelect={(item) => {
            setCountry(item?.value);
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryPurchasesPageAtoms.filters.statuses}
          default={inventoryPurchasesPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={inventoryPurchasesPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={inventoryPurchaseStatusOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={inventoryPurchasesPageAtoms.filters.hasDiscardedShipment}
          default={
            inventoryPurchasesPageAtoms.defaults.filters.hasDiscardedShipment
          }
          text="Discarded units only"
        />
        <Cell justifyContent="start">
          <Toggle
            name="has-discarded"
            value={hasDiscardedShipment}
            onSelected={setHasDiscardedShipment}
          />
        </Cell>
      </FilterRow>
    </FilterGrid>
  );
};
