import { useFormikContext } from 'formik';
import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { LocationModalSchema } from '../../location-modal.schema';
import { locationModalAtoms } from '../../location-modal.atoms';
import { LocationModalFooterProps } from './location-modal-footer.types';

export const LocationModalFooter = (props: LocationModalFooterProps) => {
  const { handleCloseModal, processing } = props;

  const isEditModal = useRecoilValue(locationModalAtoms.isEditModal);

  const { isValid } = useFormikContext<LocationModalSchema>();

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        disabled={!isValid}
        processing={processing}
        size="large"
        text={isEditModal ? 'Update' : 'Create'}
        type="submit"
      />
    </>
  );
};
