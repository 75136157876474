import { FC, PropsWithChildren } from 'react';
import { PageProps } from './page.types';

export const Page: FC<PropsWithChildren<PageProps>> = ({ children }) => {
  return (
    <div className="handout--page">
      <div className="handout--page-header">
        <table className="handout--header">
          <tbody>
            <tr>
              <td>
                <span>AeroCut nano Arch-B 4x6 4-up Duplex</span>
              </td>
              <td>
                <div className="handout--printer-mark" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <table className="handout--table-grid">
        <tbody>
          <tr>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
          </tr>
          <tr>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
            <td className="handout--grid-card-wrapper">{children}</td>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
            <td className="handout--grid-card-wrapper">{children}</td>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
          </tr>
          <tr>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
            <td className="handout--grid-card-wrapper">{children}</td>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
            <td className="handout--grid-card-wrapper">{children}</td>
            <td className="">
              <div className="printer-mark printer-mark-horizontal" />
              <div className="printer-mark printer-mark-horizontal" />
            </td>
          </tr>
          <tr>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
            <td>
              <div className="printer-mark printer-mark-vertical" />
              <div className="printer-mark printer-mark-vertical" />
            </td>
            <td />
          </tr>
        </tbody>
      </table>
    </div>
  );
};
