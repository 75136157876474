import { FC, Fragment } from 'react';
import {
  StyledBreadcrumbWrapper,
  StyledBreadcrumbLink,
  StyledBreadcrumbSeparator,
} from './breadcrumbs.styles';
import { BreadcrumbsProps } from './breadcrumbs.types';

export const BreadCrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, ...rest }) => {
  return (
    <StyledBreadcrumbWrapper {...rest}>
      {breadcrumbs.map((item, index) => {
        return (
          <Fragment key={`${item.name}-${index}`}>
            <StyledBreadcrumbLink href={item.path}>
              {item.name}
            </StyledBreadcrumbLink>
            <StyledBreadcrumbSeparator>/</StyledBreadcrumbSeparator>
          </Fragment>
        );
      })}
    </StyledBreadcrumbWrapper>
  );
};
