import { getTypeColumnHeading } from '../get-type-column-heading';
import { ReportStocksTableData } from '../../report-stocks.types';
import { ReportDetails } from './get-report-details.types';
import { convertQueriesToString } from '../../../../../../helpers/checks';
import { ReportStocksGroupBy } from '../../../../../../api/dashboard/routes/get-report-stocks/get-report-stocks.types';
import {
  stocksBaseSortingOptions,
  stocksBaseTableColumns,
} from '../../report-stocks.constants';
import {
  DownloadableTableColumn,
  SortOptionFromTable,
} from '../../../common/report-page/components/report-table/report-table.types';

export const getReportDetails = (): ReportDetails => {
  const heading = getTypeColumnHeading();

  const tableColumns: DownloadableTableColumn<ReportStocksTableData>[] = [
    {
      accessorKey: 'type',
      header: heading,
    },
    ...stocksBaseTableColumns,
  ];

  const sortingOptions: SortOptionFromTable<ReportStocksTableData>[] = [
    {
      bidirectional: true,
      label: heading,
      sortingField: 'type',
    },
    ...stocksBaseSortingOptions,
  ];

  const groupByItems = [];

  const groupByOnChange = (groupBy: ReportStocksGroupBy): void => {
    const windowLocation = window.location.origin + window.location.pathname;
    const queriesString = convertQueriesToString({ groupBy });

    window.location.href = windowLocation + queriesString;
  };

  return {
    groupByIsVisible: false,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  };
};
