import { Button, Modal } from 'gantri-components';
import React, { useState } from 'react';
import { assignMachineModalText } from './assign-machine-modal.constants';
import { AssignMachineModalProps } from './assign-machine-modal.types';
import { AssignMachineModalContent } from './components/assign-machine-modal-content';

export const AssignMachineModal = (props: AssignMachineModalProps) => {
  const {
    assignedPrinter,
    job,
    modalType,
    onAssignConfirm,
    onClose,
    onUnAssignConfirm,
    processing,
    setAssignedPrinter,
  } = props;

  const [machineSelected, setMachineSelected] = useState<number>();

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={
              modalType !== 'UNASSIGN' && !assignedPrinter && !machineSelected
            }
            processing={processing}
            size="large"
            text="Confirm"
            onClick={() => {
              if (modalType === 'UNASSIGN') {
                return onUnAssignConfirm();
              }

              onAssignConfirm(machineSelected);
            }}
          />
        </>
      }
      header={assignMachineModalText[modalType].title}
      width={{ lg: '40rem', md: '100%' }}
      onClose={onClose}
    >
      <AssignMachineModalContent
        assignedPrinter={assignedPrinter}
        job={job}
        machineSelected={machineSelected}
        modalType={modalType}
        setAssignedPrinter={setAssignedPrinter}
        setMachineSelected={setMachineSelected}
      />
    </Modal>
  );
};
