import { productLightCategories } from '../../../api/products/products.constants';

export const productsFiltersStatusLabels = [
  'In preparation',
  'Ready',
  'Active',
  'Off Market',
] as const;

export const productsFilterCategoriesOptions = Object.values(
  productLightCategories,
);
