import { Button, Conditional, Tooltip, useModal } from 'gantri-components';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { jobStatuses } from '../../../../constants/options';
import { getIsHandleBatchJobsDisabled } from './helpers/get-is-handle-batch-jobs-disabled';
import { StartOrCompleteBatchJobsModal } from './components/start-or-complete-batch-jobs-modal';
import { StartOrCompleteBatchJobsProps } from './start-or-complete-batch-jobs.types';
import { batchAtoms } from '../../batch.atoms';

export const StartOrCompleteBatchJobs = (
  props: StartOrCompleteBatchJobsProps,
) => {
  const { batch, setBatch } = props;

  const isReorderActive = useRecoilValue(batchAtoms.isReorderActive);

  const isStartBatchJobsDisabled = useMemo(() => {
    return getIsHandleBatchJobsDisabled({
      isReorderActive,
      parts: batch?.parts,
      requiredStatus: jobStatuses.ready,
    });
  }, [batch?.parts, isReorderActive]);

  const isCompleteBatchJobsDisabled = useMemo(() => {
    return getIsHandleBatchJobsDisabled({
      isReorderActive,
      parts: batch?.parts,
      requiredStatus: jobStatuses.inProgress,
    });
  }, [batch?.parts, isReorderActive]);

  const showStartBatchBtn = useMemo(() => {
    return (
      batch?.parts?.length ===
      batch?.parts?.filter(({ currentJob }) => {
        return currentJob?.status === jobStatuses.ready;
      })?.length
    );
  }, [batch?.parts]);

  const [showModal, hideModal] = useModal(() => {
    return (
      <StartOrCompleteBatchJobsModal
        actionType={showStartBatchBtn ? 'start' : 'complete'}
        batch={batch}
        setBatch={setBatch}
        onClose={hideModal}
      />
    );
  }, [batch]);

  const showTooltip = showStartBatchBtn
    ? isStartBatchJobsDisabled
    : isCompleteBatchJobsDisabled;
  const tooltipText = showTooltip
    ? 'Batch action is only available for prime and paint jobs. Additionally, all jobs must have the same current job and job status.'
    : undefined;

  return (
    <Tooltip title={tooltipText}>
      <Conditional
        condition={showStartBatchBtn}
        Fallback={
          <Button
            disabled={isCompleteBatchJobsDisabled}
            text="Complete batch jobs"
            variant="secondary"
            onClick={showModal}
          />
        }
      >
        <Button
          disabled={isStartBatchJobsDisabled}
          text="Start batch jobs"
          variant="secondary"
          onClick={showModal}
        />
      </Conditional>
    </Tooltip>
  );
};
