import { useCallback } from 'react';
import { Grid, Typography } from 'gantri-components';
import { GeneralProps, InputTypes, SelectTypes } from './general.types';
import {
  BioQuote,
  CountrySelect,
  GeneralInputs,
  HeadShotLogo,
  StateInput,
} from './components';
import { DesignerReducerAction } from '../../../../designer.types';

export const ProfileGeneralSection = (props: GeneralProps) => {
  const {
    bio,
    city,
    country,
    headshotUrl,
    logoUrl,
    name,
    onUpdateProfile,
    profileLink,
    quote,
    state,
    title,
    updateDesignerInfo,
    userId,
  } = props;

  const handleInputChange = useCallback(
    (property: InputTypes) => {
      return (value: string) => {
        updateDesignerInfo({ key: property, type: 'key', value });
      };
    },
    [name, profileLink, title, city, bio, quote, state],
  );

  const handleSelectChange = useCallback(
    (property: SelectTypes) => {
      return (value: string) => {
        updateDesignerInfo({
          key: property,
          type: 'key',
          value,
        });
      };
    },
    [state, country],
  );

  const handleUpdateProfileAndSave = (data: DesignerReducerAction) => {
    updateDesignerInfo(data);
    onUpdateProfile({ [data.key]: data.value });
  };

  return (
    <>
      <Typography
        marginBottom="1rem"
        text="General"
        textStyle="bold"
        variant="h4"
      />

      <Grid columns={{ lg: 2, sm: 1 }} gap="2rem" width="100%">
        <HeadShotLogo
          headshotUrl={headshotUrl}
          logoUrl={logoUrl}
          updateDesignerInfo={handleUpdateProfileAndSave}
          userId={userId}
        />
        <GeneralInputs
          city={city}
          handleInputChange={handleInputChange}
          name={name}
          profileLink={profileLink}
          title={title}
        />
        <StateInput
          country={country}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          state={state}
        />
        <CountrySelect
          country={country}
          handleSelectChange={handleSelectChange}
        />
        <BioQuote
          bio={bio}
          handleInputChange={handleInputChange}
          quote={quote}
        />
      </Grid>
    </>
  );
};
