import { StackedContainerProps } from './stacked-container.props';

export const StackedContainerPresets: Partial<StackedContainerProps> = {
  alignContent: 'start',
  flow: 'row',
  gap: 's1',
  height: '100%',
  horizontalPadding: 'unset',
  justifyContent: 'unset',
  padding: 'unset',
};
