import { useRecoilValue } from 'recoil';
import { CompleteSandJobContentProps } from './complete-sand-job-content.types';
import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { completeSandJobModalAtoms } from '../../complete-sand-job-modal.atoms';
import { RecommendedNextStepContent } from '../step-recommended-next-step/recommended-next-step-content';
import { CompleteChecklistContent } from '../step-complete-checklist/complete-checklist-content';
import { JobFailedRestartPartContent } from '../step-job-failed-restart/job-failed-restart-part-content';

export const CompleteSandJobContent = (props: CompleteSandJobContentProps) => {
  const { job } = props;

  const step = useRecoilValue(completeSandJobModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return <ConfirmBeginContent job={job} />;

    case 'COMPLETE_CHECKLIST':
      return <CompleteChecklistContent />;

    case 'RECOMMENDED_NEXT_STEP':
      return <RecommendedNextStepContent job={job} />;

    case 'JOB_FAILED_RESTART':
      return <JobFailedRestartPartContent job={job} />;

    default:
      return null;
  }
};
