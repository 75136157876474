import { useState } from 'react';
import {
  DragEndEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { RecordWithId, UseDragAndDropProps } from './use-drag-and-drop.types';

/**
 * @example
 * const { handleDragEnd, sensors } = useDragAndDrop({
 *   data: UUID_ARRAY.map(id => ({ id })),
 *   onReorder: YOUR_ON_REORDER_HANDLER,
 * });
 *
 * return (
 *   <DndContext
 *     collisionDetection={closestCenter}
 *     sensors={sensors}
 *     onDragEnd={handleDragEnd}
 *   >
 *     <SortableContext items={UUID_ARRAY}>
 *      {UUID_ARRAY.map(id => {
 *        // CREATE NEW COMPONENT TO HOUSE THIS LOGIC
 *        const { SortableItemWrapper } = useSortableItem({id});
 *
 *        return (
 *          <SortableItemWrapper>
 *            {YOUR_SORTABLE_ELEMENT}
 *          </SortableItemWrapper>
 *        );
 *      })}
 *     </SortableContext>
 *   </DndContext>
 * );
 *
 */
export const useDragAndDrop = <Data extends RecordWithId>(
  props: UseDragAndDropProps<Data>,
) => {
  const { data, onReorder } = props;

  const sensors = useSensors(useSensor(PointerSensor));

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>(-1);

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);

    const activeIndex = data.findIndex((element) => {
      return String(element?.id) === String(event?.active?.id);
    });

    setActiveIndex(activeIndex);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    setIsDragging(false);

    const { active, over } = event;

    if (!over || !active || over?.disabled || active?.id === over?.id) {
      return;
    }

    const oldIndex = data.findIndex((element) => {
      return String(element?.id) === String(active?.id);
    });

    const newIndex = data.findIndex((element) => {
      return String(element?.id) === String(over?.id);
    });

    const reorderedData = arrayMove(data, oldIndex, newIndex);

    await onReorder(reorderedData);
  };

  return {
    activeIndex,
    handleDragEnd,
    handleDragStart,
    isDragging,
    sensors,
  };
};
