import { dynamicFolder } from '../../cloudinary.constants';
import { renameFile } from '../rename-file';
import { DeleteFileArgs } from './delete-file.types';

const getPublicIdsFromUrl = (fileUrl: string) => {
  const baseUriPattern = new RegExp(`^.+/${dynamicFolder}/`);
  const extensionPattern = /\.[^.]+$/;
  const fromPublicId = fileUrl
    .replace(baseUriPattern, `${dynamicFolder}/`)
    .replace(extensionPattern, '');

  const envFolderPattern = new RegExp(`^(${dynamicFolder}/[^/]+)`);
  const toPublicId = fromPublicId.replace(envFolderPattern, '$1/deleted-files');

  return { fromPublicId, toPublicId };
};

export const deleteFile = async ({ fileUrl }: DeleteFileArgs) => {
  const { fromPublicId, toPublicId } = getPublicIdsFromUrl(fileUrl);

  return renameFile({ fromPublicId, toPublicId }).then(({ data }) => {
    return data;
  });
};
