import { Box, Conditional, Flex, Icon, Typography } from 'gantri-components';
import { StyledAnchor } from '../styled-anchor';
import routePaths from '../../../config/route-paths';
import { LONG_FORMAT, MEDIUM_FORMAT } from '../../../constants/dates';
import { formatDate } from '../../../helpers/formatter';
import { DateWithUserTooltipProps } from './date-with-user-tooltip.types';
import { StyledTooltip } from './date-with-user-tooltip.styles';

export const DateWithUserTooltip = (props: DateWithUserTooltipProps) => {
  const { date, statusInfo, type } = props;

  const user = statusInfo?.[type]?.user;
  const email = user?.email;
  const name = [user?.firstName, user?.lastName]
    .filter(Boolean)
    .join(' ')
    .trim();

  return (
    <Flex alignItems="center" gap="x">
      <Typography text={formatDate(date, MEDIUM_FORMAT)} />
      <StyledTooltip
        Component={
          <Box horizontalPadding="2x" verticalPadding="x">
            <Typography display="inline" text={formatDate(date, LONG_FORMAT)} />
            &nbsp;
            <Typography display="inline" text="by" />
            &nbsp;
            <Conditional
              condition={!!name || !!email}
              Fallback={<Typography display="inline" text="the system" />}
            >
              <StyledAnchor
                display="inline"
                text={name || email}
                to={`${routePaths.users}/${user?.id}`}
              />
            </Conditional>
          </Box>
        }
        position="top"
      >
        <Icon name="alert:i_circle" />
      </StyledTooltip>
    </Flex>
  );
};
