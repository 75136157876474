/**
 * This will not format correctly in Apple's Numbers app, only Excel and Google Sheets.
 * Must use "convert text to formulas" option in Google Sheets.
 */
export const formatAsLinkForCSV = ({
  text,
  url,
}: {
  text: string | number;
  url: string;
}) => {
  return url ? `=HYPERLINK("${url}";"${text}")` : text;
};
