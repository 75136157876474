import * as yup from 'yup';
import { StringSchema } from 'yup';
import { isEmpty } from 'lodash';

// composed rules
export const requiredRule = (
  yupInstance: StringSchema = yup.string(),
): StringSchema => {
  return yupInstance.required('Required');
};

export const optional = () => {
  return yup.string().nullable();
};

export const required = (): StringSchema => {
  return requiredRule(optional());
};

export const isPositiveNumberRule = (
  yupInstance: StringSchema = yup.string(),
) => {
  return yupInstance.test(
    'isPositiveNumber',
    'Invalid number',
    (value, { path, schema }) => {
      if (schema.spec.nullable && value === null) {
        return true;
      }

      return +value >= 0;
    },
  );
};

export const numberRule = (yupInstance: StringSchema = yup.string()) => {
  return yupInstance.test(
    'isIntegerNumber',
    'Invalid number',
    (value, { schema }) => {
      const valid = String(value || '')?.match(/^\+?(0|[1-9]\d*)$/)?.length > 0;

      return schema.spec.nullable ? isEmpty(value) || valid : valid;
    },
  );
};

export const decimalRule = (yupInstance: StringSchema = yup.string()) => {
  return yupInstance.test(
    'isDecimalNumber',
    'Invalid number',
    (value, { schema }) => {
      const valid = String(value || '')?.match(/^\d*\.{0,1}\d*$/)?.length > 0;

      return schema.spec.nullable ? isEmpty(value) || valid : valid;
    },
  );
};

export const validateRequiredNumber = () => {
  return isPositiveNumberRule(
    numberRule(requiredRule(yup.string().nullable())),
  );
};

export const validateRequiredDecimalNumber = () => {
  return isPositiveNumberRule(
    decimalRule(requiredRule(yup.string().nullable())),
  );
};
