import { FC, PropsWithChildren, useMemo } from 'react';
import { Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import { SubTitleProps } from './sub-title.types';

export const SubTitle: FC<PropsWithChildren<SubTitleProps>> = ({
  children,
  ...rest
}) => {
  const Element = useMemo(() => {
    return (
      <Typography
        color="t2"
        text={children.toString()}
        textStyle="regular"
        variant="p2"
        {...rest}
      />
    );
  }, [rest]);

  if (rest.to) {
    return <Link to={rest.to}>{Element}</Link>;
  }

  return Element;
};
