import { Modal } from 'gantri-components';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { CompletePrintPrepareModalProps } from './complete-print-prepare-modal.types';
import { CompletePrintPrepareFooter } from './components/complete-print-prepare-footer';
import { CompletePrintPrepareContent } from './components/complete-print-prepare-content';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';

export const CompletePrintPrepareModal = (
  props: CompletePrintPrepareModalProps,
) => {
  const { job, onClose } = props;

  const handleCloseModal = async () => {
    onClose();
  };

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={job}
          machineName={job?.machine?.name}
          sectionsToShow={[
            'part',
            'color & material',
            'product',
            'weight',
            'machine',
            'instructions',
            'part diagram',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={<CompletePrintPrepareFooter {...props} />}
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <CompletePrintPrepareContent job={job} />
    </Modal>
  );
};
