import React from 'react';
import { Form, Formik } from 'formik';
import { Modal, Typography, Conditional } from 'gantri-components';
import { useInventoryMigrationState } from './inventory-migration-context';
import { Footer } from './footer/footer';
import { InventoryMigrationForm } from './content/form';
import { InventoryMigrationFormSchema } from './inventory-migration.schema';
import { InventoryMigrationSummary } from './content/summary';

export const InventoryMigrationModal = () => {
  const { initialValues, onClose, onMigrate, onSuccess, step } =
    useInventoryMigrationState();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={InventoryMigrationFormSchema}
      onSubmit={onMigrate}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Modal
              footer={
                <Footer
                  isValid={isValid}
                  step={step}
                  onCancel={onClose}
                  onDone={() => {
                    return onSuccess(values);
                  }}
                />
              }
              header={
                <Typography
                  align="center"
                  text="Inventory Migration"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '62rem', md: '100%' }}
              onClose={onClose}
            >
              <Conditional
                condition={step === 'FILLED_FORM_STEP'}
                Fallback={<InventoryMigrationSummary data={values} />}
              >
                <InventoryMigrationForm />
              </Conditional>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
