import { Button } from 'gantri-components';
import { ConfirmUsedInventoryFooter } from './components/confirm-used-inventory-footer';
import { NewRepairFooter } from './components/new-repair-footer';
import { FooterProps } from './footer.types';
import { ConfirmCompleteRepairFooter } from './components/confirm-complete-repair-footer';
import { fullScreenModalFooterButtonSize } from '../../../../../jobs/components/modals/modals.constants';

export const Footer = (props: FooterProps) => {
  const {
    handleCloseModal,
    inventoryRequests,
    isMachineWithComponentLifespans,
    job,
    notes,
    repairType,
    selectedIssues,
    setJob,
    setStep,
    setUpdateOnClose,
    step,
  } = props;

  switch (step) {
    case 'NEW_REPAIR':
      return (
        <NewRepairFooter
          isMachineWithComponentLifespans={isMachineWithComponentLifespans}
          machine={job?.machine}
          notes={notes}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setJob={setJob}
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
        />
      );

    case 'CONFIRM_USED_INVENTORY':
      return (
        <ConfirmUsedInventoryFooter
          inventoryRequests={inventoryRequests}
          setStep={setStep}
        />
      );

    case 'CONFIRM_COMPLETE_REPAIR':
      return (
        <ConfirmCompleteRepairFooter
          inventoryRequests={inventoryRequests}
          jobId={job.id}
          notes={notes}
          selectedIssues={selectedIssues}
          setStep={setStep}
          setUpdateOnClose={setUpdateOnClose}
        />
      );

    case 'REPAIR_SUMMARY':
      return (
        <Button
          size={fullScreenModalFooterButtonSize}
          text="Done"
          onClick={handleCloseModal}
        />
      );

    default:
      return null;
  }
};
