import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Typography } from 'gantri-components';
import { capitalize } from 'lodash';
import { LONG_FORMAT } from '../../constants/dates';
import { formatDate } from '../../helpers/formatter';
import Timeline from '../common/timeline';
import { StyledInlineTypography } from '../layout/page-layout-styles';
import SubsectionTitle from '../layout/subsection-title';
import {
  StyledLinkInlineTypography,
  StyledTimelineEmailContainer,
} from './order-timeline-styles';
import TimelineUserInfo from '../common/timeline/user-info';
import { transactionsApi } from '../../api';
import { useNotification } from '../../hooks/useNotification';

const OrderTimeline = ({ currentOrderData, onResend, orderId }) => {
  const { notifyAxiosError } = useNotification();
  const emails = currentOrderData.transactionEmailLog;
  const [statusTimeline, setStatusTimeline] = useState([]);

  const fetchTimeline = async () => {
    try {
      const { data } = await transactionsApi.getOrderTimeline({ orderId });

      if (data && data.success && data.timeline.length > 0) {
        setStatusTimeline(data.timeline);
      }
    } catch (error) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch order timeline.',
      });
    }
  };

  useEffect(() => {
    fetchTimeline();
  }, [orderId]);

  if (!statusTimeline.length) {
    return null;
  }

  return (
    <Flex
      direction={{ sm: 'column' }}
      gap={{ lg: 'unset', sm: '8x' }}
      marginTop="8x"
    >
      <StyledTimelineEmailContainer>
        <Timeline
          items={statusTimeline}
          renderItem={(item) => {
            return (
              <div>
                <Typography
                  text={formatDate(parseInt(item.createdAtSec), LONG_FORMAT)}
                />
                <Flex>
                  <Typography color="t1" text={item.description} />
                  &nbsp;
                  {item.user && (
                    <TimelineUserInfo userId={item.userId} {...item.user} />
                  )}
                </Flex>
              </div>
            );
          }}
        />
      </StyledTimelineEmailContainer>

      <StyledTimelineEmailContainer>
        <SubsectionTitle>
          <Typography text="Emails" textStyle="bold" variant="h4" />
        </SubsectionTitle>
        {emails.map((email) => {
          return (
            <Flex key={email.id} gap="1rem" marginBottom="20px">
              <Icon name="ui-control:email" top="0.4rem" />
              <div>
                <Typography text={formatDate(email.emailSentAt, LONG_FORMAT)} />
                <StyledInlineTypography
                  color="t2"
                  text={`${capitalize(email.emailType)} (`}
                />
                <StyledLinkInlineTypography
                  color="link"
                  data-testid="re-send"
                  text="re-send"
                  onClick={() => {
                    return onResend(email);
                  }}
                />
                <StyledInlineTypography color="t2" text=")" />
              </div>
            </Flex>
          );
        })}
      </StyledTimelineEmailContainer>
    </Flex>
  );
};

OrderTimeline.propTypes = {
  onResend: PropTypes.func.isRequired,
};

export default OrderTimeline;
