import { Dropdown, Flex, Stack } from 'gantri-components';
import { useOnWindowResize } from '../../../../../../../hooks/use-on-window-resize';
import { DashboardChart } from '../../../dashboard-chart';
import { useGetChartData } from './hooks/use-get-chart-data';
import {
  StyledChartContainer,
  StyledScrollChild,
  StyledScrollParent,
} from '../../report-summary.styles';
import { ReportChartColumnProps } from './report-chart-column.types';

export const ReportChartColumn = (props: ReportChartColumnProps) => {
  const {
    activeChart,
    activeChartDetails,
    axisBottom,
    axisLeft,
    chartData,
    leftMargin,
    setActiveChart,
    transformTooltipValue,
  } = props;

  const {
    chartContainerRef,
    chartDesktopHeight,
    formatBottomTicks,
    handleChartHeight,
  } = useGetChartData({ activeChartDetails });

  useOnWindowResize(handleChartHeight, [
    chartContainerRef?.current?.scrollHeight,
  ]);

  const chartDataKeys = Object.keys(chartData || {});
  const dropdownItems: { label: string; value: string }[] = chartDataKeys
    .map((key) => {
      return chartData[key][0];
    })
    .map(({ id, name }) => {
      return { label: name, value: `${id}` };
    });

  const hasDropdown = dropdownItems?.length > 1;

  return (
    <StyledScrollParent>
      <StyledScrollChild>
        <Stack gap="1.6rem" rows={hasDropdown ? 'min-content 1fr' : '1fr'}>
          {hasDropdown && (
            <Flex>
              <Dropdown
                items={dropdownItems}
                value={activeChart}
                width="auto"
                onChange={({ target }) => {
                  return setActiveChart(target.value);
                }}
              />
            </Flex>
          )}
          {activeChartDetails && (
            <StyledChartContainer ref={chartContainerRef}>
              <DashboardChart
                axisBottom={{
                  format: formatBottomTicks,
                  ...axisBottom,
                }}
                axisLeft={{
                  tickValues: 3,
                  ...axisLeft,
                }}
                data={activeChartDetails}
                margin={{
                  bottom: 35,
                  left: leftMargin,
                  right: 20,
                  top: 5,
                }}
                minHeight={{
                  lg: chartDesktopHeight,
                  md: '18rem',
                  sm: '12rem',
                }}
                transformTooltipValue={transformTooltipValue}
              />
            </StyledChartContainer>
          )}
        </Stack>
      </StyledScrollChild>
    </StyledScrollParent>
  );
};
