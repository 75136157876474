import { Conditional, Dropdown } from 'gantri-components';
import { ColorSwatchAndLabel } from '../color-swatch-and-label';
import {
  ColorDropdownItemProps,
  ColorSwatchDropdownProps,
} from './color-swatch-dropdown.types';

export const ColorSwatchDropdown = <Data extends Record<any, any>>(
  props: ColorSwatchDropdownProps<Data>,
) => {
  const { renderItemProps, ...dropdownProps } = props;

  const { disabled, idProperty = 'value' } = dropdownProps;

  const dropdownItemSize = '2.4rem';

  return (
    <Dropdown
      placeholder={dropdownProps.value ? undefined : 'Select color'}
      renderItem={(item) => {
        return (
          <ColorDropdownItem
            disabled={item?.disabled}
            size={dropdownItemSize}
            value={item?.[idProperty]}
            {...item}
            {...renderItemProps}
          />
        );
      }}
      renderPlaceholder={
        dropdownProps.value
          ? (item) => {
              return (
                <ColorDropdownItem
                  disabled={disabled}
                  size={dropdownItemSize}
                  value={dropdownProps.value || item?.[idProperty]}
                  {...renderItemProps}
                />
              );
            }
          : undefined
      }
      {...dropdownProps}
    />
  );
};

export const ColorDropdownItem = (props: ColorDropdownItemProps) => {
  const { disabled, size, textColor, value } = props || {};

  return (
    <Conditional condition={!!value}>
      <ColorSwatchAndLabel
        alignItems="center"
        colorCode={value}
        disabled={disabled}
        gap="x"
        horizontalPadding="x"
        iconColor={disabled ? 't1' : 't2'}
        labelColor={disabled ? 't2' : textColor}
        size={size}
        verticalPadding=".5x"
      />
    </Conditional>
  );
};
