import { FieldArray } from 'formik';
import {
  Conditional,
  FormikInput,
  HandleUploadsComplete,
  Stack,
  TextArea,
} from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { getCanAddInstruction } from './helpers/get-can-add-instruction';
import { InstructionDetailsProps } from './instruction-details.types';
import { InstructionUploader } from './components/instruction-uploader';
import { getIsDuplicate } from './helpers/get-is-duplicate';
import { useGetProductIdFromParams } from '../../../../../../../../../../hooks/use-get-product-id-from-param';
import { jobTemplateFilePath } from '../../../../../../../../../../../../helpers/gcodes';
import { productAtoms } from '../../../../../../../../../../product.atoms';

export const InstructionDetails = (props: InstructionDetailsProps) => {
  const { index: instructionIndex, instruction, schemaId } = props;

  const { data, step, type } = instruction;
  const { files } = data;

  const disabled = !step || !type;

  const instructionSchemaId = `${schemaId}[${instructionIndex}]` as const;
  const textSchemaId = `${instructionSchemaId}.data.text` as const;
  const filesSchemaId = `${instructionSchemaId}.data.files` as const;
  const newFileSchemaId = `${filesSchemaId}[${files.length}]`;

  const canAddInstruction = getCanAddInstruction({
    files,
    label: `${step} ${type}`,
  });

  const productId = useGetProductIdFromParams();
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const uploadPath = jobTemplateFilePath({
    productId,
    sku: selectedSku,
    type: 'instructions',
    version: 'draft',
  });

  return (
    <Stack gap=".5x">
      <FormikInput
        disabled={disabled}
        Field={<TextArea placeholder="Input instruction" />}
        fieldVariant="standard"
        name={textSchemaId}
      />

      <FieldArray name={filesSchemaId}>
        {({ push, remove }) => {
          const handleUploadsComplete: HandleUploadsComplete = async ([
            { fileName, fileUrl },
          ]) => {
            push({ fileName, url: fileUrl });
          };

          return (
            <>
              {files.map((file, index) => {
                const isDuplicate = getIsDuplicate({
                  allFiles: files,
                  file,
                });
                const existingFileSchemaId = `${filesSchemaId}[${index}]`;

                const onFileDelete = () => {
                  return remove?.(index);
                };

                return (
                  <FormikInput
                    key={file.url}
                    Field={
                      <InstructionUploader
                        file={file}
                        handleUploadsComplete={handleUploadsComplete}
                        inputName={existingFileSchemaId}
                        isDuplicate={isDuplicate}
                        uploadPath={uploadPath}
                        onFileDelete={onFileDelete}
                      />
                    }
                    fieldVariant="standard"
                    name={existingFileSchemaId}
                  />
                );
              })}

              <Conditional condition={canAddInstruction}>
                <FormikInput
                  Field={
                    <InstructionUploader
                      disabled={disabled}
                      file={null}
                      handleUploadsComplete={handleUploadsComplete}
                      inputName={newFileSchemaId}
                      uploadPath={uploadPath}
                      variant="button"
                    />
                  }
                  fieldVariant="standard"
                  name={`${filesSchemaId}[${files.length}]`}
                />
              </Conditional>
            </>
          );
        }}
      </FieldArray>
    </Stack>
  );
};
