import { DatePickerDefaultProps } from './date-picker.types';

export const datePickerDefaultProps: Required<DatePickerDefaultProps> = {
  calendarIconVisible: true,
  fromPlaceholder: 'From',
  placeholder: 'Choose a date',
  resetDates: {
    from: null,
    to: null,
  },
  toPlaceholder: 'To',
};
