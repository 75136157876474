import { memo, useEffect, useState } from 'react';
import { DashboardReportSummary } from '../../../../api/dashboard/dashboard.types';
import { useFetchDashboardPrintJobsSummary } from '../../../../api/dashboard/routes/get-dashboard-print-jobs-summary/get-dashboard-print-jobs-summary.query';
import { PrintJobType } from '../../../../api/dashboard/routes/get-dashboard-print-jobs-summary/get-dashboard-print-jobs-summary.types';
import routePaths from '../../../../config/route-paths';
import { getChartLeftMargin } from '../common/dashboard-chart/helpers';
import { ReportSummary } from '../common/reports-summary';
import {
  printJobsTabs,
  printJobsTableColumns,
} from './dashboard-print-jobs-summary.constants';

const DashboardPrintJobsSummaryBase = () => {
  const [activeTab, setActiveTab] = useState<PrintJobType>('total');
  const [report, setReport] = useState<DashboardReportSummary>();
  const [activeChart, setActiveChart] = useState<string>();

  const activeChartDetails = report?.chartData?.[activeChart];
  const leftMargin = getChartLeftMargin({
    activeChartDetails,
  });

  useEffect(() => {
    if (!activeChart) {
      const chartDataKeys = Object.keys(report?.chartData || {});
      const firstCartDataKey = chartDataKeys[0];

      setActiveChart(firstCartDataKey);
    }
  }, [report?.chartData]);

  return (
    <ReportSummary
      activeChart={activeChart}
      activeChartDetails={activeChartDetails}
      activeTab={activeTab}
      chartData={report?.chartData}
      fullReportHref={`${routePaths.dashboard}/jobs`}
      leftMargin={leftMargin}
      minHeight="42rem"
      setActiveChart={setActiveChart}
      setActiveTab={setActiveTab}
      setReport={setReport}
      tableColumns={printJobsTableColumns}
      tableData={report?.tableData}
      tabs={printJobsTabs}
      title="Print Jobs"
      useGetDashboardReportSummary={useFetchDashboardPrintJobsSummary}
    />
  );
};

export const DashboardPrintJobsSummary = memo(DashboardPrintJobsSummaryBase);
