import styled from 'styled-components';

export const StyledPrintPreviewSectionWrapper = styled.div`
  margin-top: 4rem;
  width: 100%;
  overflow-x: auto;
  .handout--page {
    margin-top: 2rem;
    border: 1px solid black;
  }
`;
