import { Flex, Icon, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { createBatchModalAtoms } from '../../create-batch-modal.atoms';

export const CreateBatchModalHeader = () => {
  const step = useRecoilValue(createBatchModalAtoms.step);

  switch (step) {
    case 'CREATE_BATCH':
      return <Typography align="center" text="Create Batch" variant="h4" />;

    case 'BATCH_CREATED':
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon
            color="success"
            name="ui-control:check_mark_circle_filled_24"
            size="2.4rem"
          />
          <Typography align="center" text="Batch created" variant="h4" />
        </Flex>
      );

    case 'ERROR_CREATING_BATCH':
      return (
        <Flex alignItems="center" gap="x" justifyContent="center">
          <Icon color="alert" name="alert:warning_triangle_24" size="2.4rem" />
          <Typography align="center" text="Error" variant="h4" />
        </Flex>
      );

    default:
      return null;
  }
};
