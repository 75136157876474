import { media } from 'gantri-components';
import styled from 'styled-components';

export const StyledTopStatsWrapper = styled.div`
  padding-bottom: 3.2rem;
`;

export const StyledScrollWrapper = styled.div`
  min-height: 13rem;
  width: 100%;
  overflow-x: auto;
  ${media.greaterThan('lg')`
    min-height: 6.5rem;
  `}
`;
