import {
  Button,
  Modal,
  SearchField,
  Stack,
  Typography,
} from 'gantri-components';
import { useState } from 'react';
import { batchApi } from '../../../../../../api';
import { useGetLocationsQuery } from '../../../../../../api/locations/routes/get-locations/get-locations.query';
import { useNotification } from '../../../../../../hooks/useNotification';
import { UseUpdateBatchLocationModalProps } from './update-batch-location-modal.types';

export const UpdateBatchLocationModal = (
  props: UseUpdateBatchLocationModalProps,
) => {
  const { batch, onClose, setBatch } = props;

  const [selectedLocationId, setSelectedLocationId] = useState<number>(null);

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const updateBatchLocation = async (locationId: number) => {
    try {
      showLoading();

      const { data } = await batchApi.updateBatchLocation({
        batchId: batch.id,
        locationId,
        returnBatch: true,
      });

      notify(data.notice);

      setBatch(data.batch);

      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update location for batch.',
      });
    } finally {
      hideLoading();
    }
  };

  const { locations } = useGetLocationsQuery({
    type: 'non-vlm',
  });

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={!selectedLocationId}
            size="large"
            text="Apply location"
            onClick={async () => {
              await updateBatchLocation(selectedLocationId);
            }}
          />
        </>
      }
      header="Update Location"
      width={{ lg: '62rem', md: '100%' }}
      onClose={onClose}
    >
      <Stack gap="x">
        <Typography
          marginBottom="x"
          text={`Select location to apply to ${batch?.parts?.length} parts:`}
        />
        <Typography text="Select location" textStyle="bold" />
        <SearchField
          idProperty="id"
          items={locations}
          labelProperty="name"
          placeholder="Select Location..."
          value={selectedLocationId}
          onSelect={(item) => {
            const id = item?.id;

            setSelectedLocationId(id);
          }}
        />
      </Stack>
    </Modal>
  );
};
