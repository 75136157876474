import { Button, Stack } from 'gantri-components';
import { jobsApi } from '../../../../../../api';
import { getInventoryWarnings } from '../../../../../../components/common/editable-inventories-table/helpers/get-inventory-warnings';
import { useNotification } from '../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../modals.constants';
import { FooterAlertText } from '../footer-alert-text';
import { InventoryChecklistFooterProps } from './inventory-checklist-footer.types';

export const InventoryChecklistFooter = (
  props: InventoryChecklistFooterProps<string>,
) => {
  const {
    afterInventoryStep,
    inventoryRequests,
    job,
    onComplete,
    setInventoryRequests,
    setJob,
    setStep,
    setUpdateOnClose,
    size = fullScreenModalFooterButtonSize,
    text = 'Confirm Used Inventory',
  } = props;

  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const isInactive = inventoryRequests.some((inventoryRequest) => {
    const { isInactive } = getInventoryWarnings(inventoryRequest);

    return isInactive;
  });

  const isStockExceeded = inventoryRequests.some((inventoryRequest) => {
    const { isStockExceeded } = getInventoryWarnings(inventoryRequest);

    return isStockExceeded;
  });

  const showWarningIcon = isInactive || isStockExceeded;

  const handleConfirmInventory = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await jobsApi.updateJob({
          ...job,
          inventoryRequests,
          jobId: job.id,
        });

        if (data.success) {
          notify(data.notice);
          /* This catches the edge case where a user adjusts the inventory on the job, 
        proceeds to the next step, and then navigates back, causing the old inventory 
        to be shown in the job details side panel. */
          setJob(data.job);
          /* This filters out any invalid inventories (missing name or zero qty) that may have been added to state. */
          setInventoryRequests(data.job.inventoryRequests);
        }

        if (onComplete) {
          await onComplete();
        } else {
          setUpdateOnClose(true);
          setStep(afterInventoryStep);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred updating job.',
        });
      }
    });
  };

  return (
    <Stack data-testid="complete-inventory-checklist-footer" gap="x">
      {isStockExceeded && (
        <FooterAlertText
          color="alert"
          iconName="alert:warning_triangle"
          text="Inventory selections exceed available stock."
        />
      )}
      <Button
        disabled={showWarningIcon}
        processing={processing}
        size={size}
        text={text}
        onClick={handleConfirmInventory}
      />
    </Stack>
  );
};
