import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  UpdateTransactionAddressArgs,
  UpdateTransactionAddressResponse,
} from './update-transaction-address.types';

export const updateTransactionAddress = (
  args: UpdateTransactionAddressArgs,
) => {
  const { address, transactionId } = args;

  return axios.put<UpdateTransactionAddressResponse>(
    `${baseApiUrl}/transactions/${transactionId}/address`,
    { address },
  );
};
