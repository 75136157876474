import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { UpdateUserArgs, UpdateUserResponse } from './update-user.types';

export const updateUser = ({
  newReferralHash,
  notes,
  userId,
}: UpdateUserArgs) => {
  return axios.put<UpdateUserResponse>(`${baseApiUrl}/users/${userId}`, {
    newReferralHash,
    notes,
  });
};
