import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedDesignersArgs,
  FetchPaginatedDesignersResponse,
} from './fetch-paginated-designers.types';

export const query = (body: FetchPaginatedDesignersArgs) => {
  return axios
    .post<FetchPaginatedDesignersResponse>(
      `${baseApiUrl}/paginatedDesigners`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'paginated-designers';
};

const key = (args: FetchPaginatedDesignersArgs) => {
  const queries = [
    `active=${args?.active}`,
    `count=${args?.count}`,
    `page=${args?.page}`,
    `search=${args?.search}`,
    `sortingField=${args?.sortingField}`,
    `sortingType=${args?.sortingType}`,
  ];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const fetchPaginatedDesigners = {
  groupKey,
  key,
  query,
};
