import { Conditional, Radio, Stack, Typography } from 'gantri-components';
import {
  EvaluatePartContentProps,
  InitialEvaluation,
} from './evaluate-part-content.types';
import { StyledRadioWrapper } from './evaluate-part-content.styles';
import { Checklist } from '../job-checklist/job-checklist.types';
import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';

export const EvaluatePartContent = <
  ChecklistType extends Partial<Checklist<FailedReason>>,
>(
  props: EvaluatePartContentProps<ChecklistType>,
) => {
  const {
    getDefaultChecklist,
    initialEvaluation,
    labelFail,
    labelPass,
    onSelected,
    setChecklist,
    setInitialEvaluation,
    subTitleHtml,
    title,
  } = props;

  return (
    <Stack gap="0.8rem">
      <Conditional condition={!!title}>
        <Typography text={title} variant="h3" />
      </Conditional>
      <Conditional condition={!!subTitleHtml}>
        <Typography htmlText={subTitleHtml} paddingBottom="1.6rem" />
      </Conditional>
      <StyledRadioWrapper status="PASS">
        <Radio
          groupValue="PASS"
          labelText={labelPass}
          value={initialEvaluation}
          onSelected={(value: InitialEvaluation) => {
            setInitialEvaluation(value);
            onSelected?.(value);

            const checklist = getDefaultChecklist(value);

            setChecklist(checklist);
          }}
        />
      </StyledRadioWrapper>
      <StyledRadioWrapper status="FAIL">
        <Radio
          groupValue="FAIL"
          labelText={labelFail}
          value={initialEvaluation}
          onSelected={(value: InitialEvaluation) => {
            setInitialEvaluation(value);
            onSelected?.(value);

            const checklist = getDefaultChecklist(value);

            setChecklist(checklist);
          }}
        />
      </StyledRadioWrapper>
    </Stack>
  );
};
