import PropTypes from 'prop-types';
import { TextField } from 'gantri-components';
import { StyledUnitInputContainer, StyledUnitText } from './unit-input-styles';

const unitAbbreviationByName = {
  bottle: 'bot',
  gallon: 'gal',
};

const UnitInput = ({
  maxValue,
  minValue,
  onBlurTextChange,
  onChange,
  onValueChange,
  placeholder,
  unit,
  value,
}) => {
  return (
    <StyledUnitInputContainer>
      <TextField
        maxValue={maxValue}
        minValue={minValue}
        placeholder={placeholder}
        step="any"
        type="number"
        value={value}
        onBlurTextChange={(value) => {
          onChange?.(value);
          onBlurTextChange?.(value);
        }}
        onTextChange={onValueChange}
      />
      <StyledUnitText
        color="t2"
        text={
          unit ? (unitAbbreviationByName[unit] ?? unit).toLowerCase() : 'each'
        }
        variant="p2"
      />
    </StyledUnitInputContainer>
  );
};

UnitInput.defaultProps = {
  maxValue: null,
  minValue: 0,
};

UnitInput.propTypes = {
  hasError: PropTypes.bool,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onBlurTextChange: PropTypes.func,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  placeholder: PropTypes.string,
  unit: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default UnitInput;
