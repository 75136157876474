import axios from 'axios';
import { FetchUserReviewsResponse } from './fetch-user-product-reviews.types';
import { coreApiUrl } from '../../../../helpers/auth';

const query = async (userId: number): Promise<FetchUserReviewsResponse> => {
  const response = await axios.get<FetchUserReviewsResponse>(
    `${coreApiUrl}/product-reviews/all/${userId}`,
  );

  return response.data;
};

const groupKey = () => {
  return 'user-product-reviews';
};

const key = (userId: number) => {
  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}/${userId}`,
    },
  ] as const;
};

export const fetchUserProductReviews = {
  groupKey,
  key,
  query,
};
