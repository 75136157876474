import { Flex, Toggle, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  designsFilterCategoriesOptions,
  designsFilterStatusesOptions,
  designsFilterStepsOptions,
} from './designs-filter.constants';
import { designsPageAtoms } from './designs-filter.atoms';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

export const DesignsFilter = () => {
  const [action, setAction] = useRecoilState(designsPageAtoms.filters.action);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={designsPageAtoms.filters.categories}
          default={designsPageAtoms.defaults.filters.categories}
          text="Category"
        />
        <AtomsCheckboxList
          atom={designsPageAtoms.filters.categories}
          gridProps={{ columns: 2 }}
          items={designsFilterCategoriesOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={designsPageAtoms.filters.steps}
          default={designsPageAtoms.defaults.filters.steps}
          text="Step"
        />
        <AtomsCheckboxList
          atom={designsPageAtoms.filters.steps}
          items={designsFilterStepsOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={designsPageAtoms.filters.action}
          default={designsPageAtoms.defaults.filters.action}
          text="Action"
        />
        <Flex alignItems="center" gap="x">
          <Typography text="Everything" />
          <Toggle value={action} onSelected={setAction} />
          <Typography text="On" />
        </Flex>
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={designsPageAtoms.filters.statuses}
          default={designsPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={designsPageAtoms.filters.statuses}
          items={designsFilterStatusesOptions}
        />
      </FilterRow>
    </FilterGrid>
  );
};
