import { useRecoilValue } from 'recoil';
import { startNonProductJobModalAtoms } from '../../start-non-product-job-modal.atoms';
import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { UpdateInventoryContent } from '../step-update-inventory/update-inventory-content';
import { StartNonProductJobContentProps } from './start-non-product-job-content.types';

export const StartNonProductJobContent = (
  props: StartNonProductJobContentProps,
) => {
  const { job } = props;

  const step = useRecoilValue(startNonProductJobModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return <ConfirmBeginContent job={job} />;

    case 'UPDATE_INVENTORY':
      return <UpdateInventoryContent job={job} />;

    default:
      return null;
  }
};
