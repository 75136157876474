import { Button } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledButton = styled(Button)<{
  'data-is-active': boolean;
}>`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1};

      &:hover,
      &[data-is-active='true'] {
        background-color: ${theme.colors.surfaces.monochrome.t1};
        border-color: ${theme.colors.dividers.t3};

        * {
          background-color: unset;
          color: ${theme.colors.typography.t1};
          fill: ${theme.colors.iconography.t2};
        }
      }
    `;
  }}
`;
