import { useNotification } from '../../../../hooks/useNotification';
import {
  useGetInvalidateQueryCache,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { GetPaginatedUsersArgs } from './get-paginated-users.types';
import { getPaginatedUsers } from './get-paginated-users';

interface UseFetchPaginatedUsersProps {
  filters: GetPaginatedUsersArgs;
}

export const useFetchPaginatedUsersQuery = (
  props?: UseFetchPaginatedUsersProps,
) => {
  const { filters } = props ?? {};
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!filters,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch users.',
        });
      },
      queryFn: () => {
        return getPaginatedUsers.query(filters);
      },
      queryKey: getPaginatedUsers.key(filters),
      // because we don't have a way to clear the cache for new referrals
      staleTime: 0,
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};

export const useInvalidateFetchPaginatedUsersCache = () => {
  return useGetInvalidateQueryCache(getPaginatedUsers);
};
