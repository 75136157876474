import axios from 'axios';
import { DEFAULT_PAGING_SIZE } from '../../../../constants/common';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetPaginatedVendorsArgs,
  GetPaginatedVendorsResponse,
} from './get-paginated-vendors.types';

export const getPaginatedVendors = ({
  count = DEFAULT_PAGING_SIZE,
  ...args
}: GetPaginatedVendorsArgs) => {
  return axios.post<GetPaginatedVendorsResponse>(
    `${coreApiUrl}/vendors/pagination`,
    {
      count,
      ...args,
    },
  );
};
