import { Checkbox, Flex, Grid, Icon, Typography } from 'gantri-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import {
  MachineIssueChecklistItem,
  machineIssueOptions,
} from '../../../../../../../../../constants/options';
import { IssueThumbnail } from '../../../../../../../../machine/components/issue-thumbnail/issue-thumbnail';
import { IssueFailureDetails } from '../../../../../../../../machine/components/issue-failure-detail/issue-failure-detail';
import { MachineIssueDifficulty } from '../../../../../../../../../api/jobs/jobs.types';
import { IssueChecklistProps } from './issue-checklist.types';

export const IssueChecklist = (props: IssueChecklistProps) => {
  const { selectedIssues, setSelectedIssues, showBulkOptionsOnly } = props;

  const getOnIssueSelected = (issue: MachineIssueChecklistItem) => {
    return (checked: boolean) => {
      const updatedIssues = checked
        ? [...selectedIssues, issue]
        : selectedIssues.filter(({ type }) => {
            return type !== issue.type;
          });

      setSelectedIssues(updatedIssues);
    };
  };

  const getOnTextChange = ({ type }: MachineIssueChecklistItem) => {
    return (notes: string) => {
      const updatedIssues = selectedIssues.map((issue) => {
        return issue.type === type ? { ...issue, notes } : issue;
      });

      setSelectedIssues(updatedIssues);
    };
  };

  const getDifficultyIconName = (
    difficulty: MachineIssueDifficulty,
  ): IconProps['name'] => {
    const difficultyName =
      difficulty.toLowerCase() as Lowercase<MachineIssueDifficulty>;

    return `machine:difficulty_${difficultyName}`;
  };

  const availableIssues = showBulkOptionsOnly
    ? machineIssueOptions.filter(({ allowBulkCreation }) => {
        return allowBulkCreation;
      })
    : machineIssueOptions;

  return (
    <>
      {availableIssues.map((issue) => {
        const { difficulty, thumbnailSrc, type } = issue;

        const checked = selectedIssues.some((selected) => {
          return selected.type === type;
        });
        const iconName = getDifficultyIconName(difficulty);

        return (
          <div key={type}>
            <Flex key={type} alignItems="center" justifyContent="space-between">
              <Grid
                alignItems="center"
                columnGap="0.8rem"
                columns="1fr max-content"
                rowGap="0.8rem"
              >
                <IssueThumbnail name={type} thumbnailSrc={thumbnailSrc} />
                <Flex gap="0.8rem">
                  <Typography text={type} />
                  <Icon
                    color="link"
                    name={iconName}
                    size="1.6rem"
                    top="0.2rem"
                  />
                  <Typography color="link" text={difficulty} />
                </Flex>
              </Grid>

              <Checkbox
                checked={checked}
                onSelected={getOnIssueSelected(issue)}
              />
            </Flex>
            {checked && (
              <IssueFailureDetails
                type={issue}
                onTextChange={getOnTextChange(issue)}
              />
            )}
          </div>
        );
      })}
    </>
  );
};
