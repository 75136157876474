import { Conditional, Stack, Table, Typography } from 'gantri-components';
import { FC } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../../config/route-paths';
import { ShopProps } from './index.types';
import { StyledAnchor } from '../../../components/common/styled-anchor';
import {
  UserShopCartItem,
  UserShopOrdersItem,
  UserShopWishlistItem,
} from '../../../api/users/users.types';
import { formatDate } from '../../../helpers/formatter';
import { getIsClickableIfValue } from '../../../helpers/get-is-clickable-if-value';

const ordersColumns: ColumnDef<UserShopOrdersItem>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
    }: CellContext<UserShopOrdersItem, UserShopOrdersItem['id']>) => {
      const id = getValue();

      return (
        <StyledAnchor
          state={{ locationOrderId: id }}
          text={`#${id}`}
          to={`${routePaths.orders}/${id}`}
        />
      );
    },
    header: '#',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
  {
    accessorKey: 'type',
    header: 'Type',
  },
  {
    accessorKey: 'createdAtSec',
    cell: ({
      getValue,
    }: CellContext<UserShopOrdersItem, UserShopOrdersItem['createdAtSec']>) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Created',
  },
  {
    accessorKey: 'completedAt',
    cell: ({
      getValue,
    }: CellContext<UserShopOrdersItem, UserShopOrdersItem['completedAt']>) => {
      const completedAt = getValue();

      return (
        <Conditional
          condition={!!completedAt}
          Fallback={<Typography text="TBD" />}
        >
          <Typography text={formatDate(completedAt)} />
        </Conditional>
      );
    },
    header: 'Completed',
  },
  {
    accessorKey: 'sendTo',
    header: 'Send To',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
];

const cartColumns: ColumnDef<UserShopCartItem>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'designer',
    header: 'Designer',
  },
  {
    accessorKey: 'color',
    header: 'Color',
  },
  {
    accessorKey: 'size',
    header: 'Size',
  },
  {
    accessorKey: 'added',
    cell: ({
      getValue,
    }: CellContext<UserShopCartItem, UserShopCartItem['added']>) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Added',
  },
];

const wishlistColumns: ColumnDef<UserShopWishlistItem>[] = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'designer',
    header: 'Designer',
  },
  {
    accessorKey: 'color',
    header: 'Color',
  },
  {
    accessorKey: 'size',
    header: 'Size',
  },
  {
    accessorKey: 'addedAtSec',
    cell: ({
      getValue,
    }: CellContext<
      UserShopWishlistItem,
      UserShopWishlistItem['addedAtSec']
    >) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Added',
  },
  {
    accessorKey: 'addedToCartSec',
    cell: ({
      getValue,
    }: CellContext<
      UserShopWishlistItem,
      UserShopWishlistItem['addedToCartSec']
    >) => {
      return <Typography text={formatDate(getValue())} />;
    },
    header: 'Added to Cart',
  },
];

export const Shop: FC<ShopProps> = ({ data }) => {
  if (!data || !data.orders) {
    return null;
  }

  const { cart, orders, wishlist } = data;

  return (
    <Stack gap="5x">
      <Stack data-orders-section="" gap="2x">
        <Typography text="Orders" textStyle="bold" variant="h4" />
        <Table columns={ordersColumns} data={orders} />
      </Stack>
      <Stack data-cart-section="" gap="2x">
        <Typography text="Cart" textStyle="bold" variant="h4" />
        <Table columns={cartColumns} data={cart} />
      </Stack>
      <Stack data-wishlist-section="" gap="2x">
        <Typography text="Wishlist" textStyle="bold" variant="h4" />
        <Table columns={wishlistColumns} data={wishlist} />
      </Stack>
    </Stack>
  );
};

export default Shop;
