import { Row } from '@tanstack/react-table';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import {
  ReportProfitQueries,
  ReportProfitTableData,
} from '../../report-profit.types';

export const getRowHref = (row: Row<ReportProfitTableData>) => {
  const windowLocation = window.location.origin + window.location.pathname;
  const queries = getActiveQueries<ReportProfitQueries>();
  const { groupBy, productId, productName, version } = queries;
  const rowData = row?.original;

  if (
    /^total$/i.test(rowData?.type) ||
    !groupBy ||
    !/^productId$/i.test(groupBy)
  ) {
    return;
  }

  /**
   * productId = undefined, means lvl 1
   * Generate a href to the product detail showing all product versions
   */
  if (!productId || !productName) {
    const queryString = convertQueriesToString({
      groupBy,
      productId: rowData?.info?.id,
      productName: rowData?.info?.name,
    });

    return windowLocation + queryString;
  }

  /**
   * productId != undefined and version = undefined, means lvl 2
   * Generate a href to the version detail showing all SKUs
   * TODO: backend should include the missing values ( id, name, version )
   */
  if (!version) {
    const queryString = convertQueriesToString({
      groupBy,
      productId: rowData?.info?.id,
      productName: rowData?.info?.name,
      version: rowData?.info?.version,
    });

    return windowLocation + queryString;
  }
};
