import { CellContext } from '@tanstack/react-table';
import { ReportOrdersGroupBy } from '../../../../api/dashboard/routes/get-report-orders/get-report-orders.types';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { NumberCell } from '../../../dashboard/components/common/number-cell';
import { PercentCell } from '../../../dashboard/components/common/percent-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import {
  ReportOrderRefundTableData,
  ReportOrdersTableData,
} from './report-orders.types';

export const defaultOrdersGroupBy: ReportOrdersGroupBy = 'orderType';

export const ordersBaseTableColumns: DownloadableTableColumn<ReportOrdersTableData>[] =
  [
    {
      accessorKey: 'created',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['created']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Created',
    },
    {
      accessorKey: 'completed',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['completed']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Completed',
    },
    {
      accessorKey: 'refunded',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['refunded']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Refunded',
    },
    {
      accessorKey: 'cancelled',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['cancelled']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Cancelled',
    },
    {
      accessorKey: 'itemsCreated',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['itemsCreated']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Items Created',
    },
    {
      accessorKey: 'giftCardsCreated',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['giftCardsCreated']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Gift Cards Created',
    },
    {
      accessorKey: 'onTimeCompletion',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['onTimeCompletion']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: 'On-time Completion %',
    },
    {
      accessorKey: 'leadTime',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrdersTableData,
        ReportOrdersTableData['leadTime']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Lead Time (hr)',
    },
  ];

export const ordersBaseSortingOptions = getSortingOptionsFromColumns(
  ordersBaseTableColumns,
);

export const reportOrdersRefundsTableColumns: DownloadableTableColumn<ReportOrderRefundTableData>[] =
  [
    {
      accessorKey: 'refunds',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrderRefundTableData,
        ReportOrderRefundTableData['refunds']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: '# Refunds',
    },
    {
      accessorKey: 'percent',
      cell: ({
        getValue,
      }: CellContext<
        ReportOrderRefundTableData,
        ReportOrderRefundTableData['percent']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: '% of Refunds',
    },
  ];

export const reportOrdersRefundsSortingOptions = getSortingOptionsFromColumns(
  reportOrdersRefundsTableColumns,
);

export const pageName: AtomPageName = 'report-orders';

export const reportOrdersPageAtoms = {
  sortBy: getSortByFilterAtomFamily<
    SortBy<keyof ReportOrdersTableData | keyof ReportOrderRefundTableData>
  >({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
