import React, { FC } from 'react';
import { ZoomableThumbnailProps } from './zoomable-thumbnail.types';
import { Thumbnail } from '../thumbnail';
import { useThumbnailZoomModal } from '../../pages/jobs/components/modals/common/job-checklist/components/advanced-checklist/components/advanced-checklist-reason/use-thumbnail-zoom-modal';

export const ZoomableThumbnail: FC<ZoomableThumbnailProps> = (props) => {
  const { alt, heading, src } = props;

  const altText = alt || heading;

  const [showZoomModal] = useThumbnailZoomModal(
    {
      alt: altText,
      heading,
      src,
    },
    [heading, src, altText],
  );

  return (
    <Thumbnail
      src={src}
      {...props}
      alt={altText}
      immediateAvailable
      onClick={src ? showZoomModal : null}
    />
  );
};
