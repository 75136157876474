import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateStockLocationArgs,
  UpdateStockLocationResponse,
} from './update-stock-location.types';

export const updateStockLocation = ({
  locationId,
  stockId,
}: UpdateStockLocationArgs) => {
  return axios.put<UpdateStockLocationResponse>(
    `${coreApiUrl}/stocks/${stockId}/location`,
    { locationId },
  );
};
