import { useNotification } from '../../../../hooks/useNotification';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import {
  useInvalidateQuery,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchAllOrdersForFilterResponse } from './fetch-all-for-filter.types';
import { fetchAllOrdersForFilter } from './fetch-all-for-filter';

interface Props<TData>
  extends UseQueryProps<TData, FetchAllOrdersForFilterResponse> {
  query?: string;
}

export const useFetchAllOrdersForFilterQuery = <
  TData = Record<string, unknown>,
>(
  props?: Props<TData>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<
    FetchAllOrdersForFilterResponse | TData
  >({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch orders.',
        });
      },
      queryFn: () => {
        return fetchAllOrdersForFilter(props?.query);
      },
      queryKey: fetchAllOrdersForFilter.queryKey(props?.query),
      select: (response: FetchAllOrdersForFilterResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    isLoading,
    orders: data as TData,
  };
};

export const useInvalidateFetchAllOrdersForFilterCache = () => {
  const invalidate = useInvalidateQuery();

  const invalidateFetchAllOrdersForJobsCache = (query?: string) => {
    return invalidate(fetchAllOrdersForFilter.queryKey(query));
  };

  return { invalidateFetchAllOrdersForJobsCache };
};
