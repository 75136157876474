import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportSalesArgs,
  GetReportSalesResponse,
} from './get-report-sales.types';

export const getReportSales = (
  { rangeType, ...queries }: GetReportSalesArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportSalesResponse>(
    `${coreApiUrl}/reports/sales${queriesString}`,
    configs,
  );
};
