import { FC } from 'react';
import { Icon, Typography, Grid, Cell, Tooltip, Box } from 'gantri-components';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../../../../helpers/formatter';
import routePaths from '../../../../../../../config/route-paths';
import {
  StyledFieldIconWrapper,
  StyledTooltipTypography,
} from './date-with-tooltip.style';
import {
  HOUR_FORMAT,
  LONG_FORMAT,
  MEDIUM_FORMAT,
} from '../../../../../../../constants/dates';

interface DateWithTooltipProps {
  date: Date;
  userData: {
    email: string;
    firstName: string;
    lastName: string;
  };
  userId: number;
}

export const DateWithTooltip: FC<DateWithTooltipProps> = ({
  date,
  userData,
  userId,
}) => {
  const TooltipContent = () => {
    const formattedDate = formatDate(+date, LONG_FORMAT);

    if (userData) {
      return (
        <Box horizontalPadding="x" verticalPadding=".5x">
          <StyledTooltipTypography
            color="t2"
            text={`${formattedDate}`}
            variant="p2"
          />
          <StyledTooltipTypography color="t2" text=" by " variant="p2" />
          <Link to={`${routePaths.users}/${userId}`}>
            <StyledTooltipTypography
              color="link"
              text={`${userData.firstName} ${userData.lastName}`}
            />
          </Link>
        </Box>
      );
    }

    return (
      <StyledTooltipTypography
        color="t2"
        text={`${formattedDate}`}
        variant="p2"
      />
    );
  };

  return (
    <Grid alignItems="center" columns={2} gap="0.5rem">
      <Cell>
        <Typography color="t1" text={formatDate(date, MEDIUM_FORMAT)} />
        <Typography color="t1" text={formatDate(date, HOUR_FORMAT)} />
      </Cell>
      <Cell>
        <Tooltip Component={<TooltipContent />} position="right">
          <StyledFieldIconWrapper>
            <Icon color="t1" name="alert:i_circle" size="1.6rem" />
          </StyledFieldIconWrapper>
        </Tooltip>
      </Cell>
    </Grid>
  );
};
