import styled, { css } from 'styled-components';
import { media, palette } from 'gantri-components';
import { Property } from 'csstype';
import { transitions, zIndexes } from '../../constants/styles';

export const StyledFilterWrapper = styled.div`
  outline: none;
  background-color: ${(props) => {
    return props.theme.colors.surfaces.monochrome.t2;
  }};
  margin-right: 10px;
  padding: 5px 10px;
  width: 18rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  ${transitions.base}
  user-select: none;

  ${media.lessThan('md')`
    width: 5.6rem;
    height: 3.2rem;
  `}
`;

export const StyledAnimatedDropdownIconWrapper = styled.div<{
  collapsed?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(
    ${({ collapsed }) => {
      return collapsed ? '0' : '180deg';
    }}
  );
`;

export const StyledDropdownCloseButton = styled.div`
  position: absolute;
  right: 17px;
  top: 15px;
  outline: none;
  cursor: pointer;
`;

export const StyledFilterSection = styled.div`
  margin-bottom: 30px;
  min-width: 30rem;
`;

export const StyledInlineFilterSection = styled(StyledFilterSection)`
  display: flex;
  max-width: max-content;

  ${media.lessThan('md')`
    flex-direction: column;
  `};
`;

export const StyledInlineFilterChild = styled.div<{
  noBorder?: boolean;
}>`
  width: 100%;

  &:not(:last-child) {
    margin-right: 15px;
    padding-right: 15px;
    border-right: ${({ noBorder }) => {
      return noBorder ? '' : `1px solid ${palette.monochrome_200}`;
    }};

    ${media.lessThan('md')`
      border-right: unset;
    `}
  }

  &:not(:first-child) {
    ${media.lessThan('md')`
      margin-top: 3rem;
    `}
  }
`;

export const StyledFilterSectionTitle = styled.p`
  margin-bottom: 1rem;
  min-width: 20rem;
  font-family: ${(props) => {
    return `${props.theme.fonts.boldFontFamily}, "Helvetica", sans-serif;`;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.p2.size;
  }};
  line-height: ${(props) => {
    return props.theme.fonts.p2.lineHeight;
  }};
`;

export const StyledFilterSectionTitleWrapper = styled.div`
  margin-bottom: 20px;
  width: fit-content;
`;

export const StyledSwitchWrapper = styled.div`
  margin: 0 10px;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;

  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledFilterColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const StyledFilterInputWrapper = styled.div<{
  long?: boolean;
  topMargin?: Property.MarginTop;
}>`
  width: ${({ long }) => {
    return long ? '380px' : '240px';
  }};
  margin-right: 10px;
  ${({ topMargin }) => {
    return (
      topMargin &&
      css`
        margin-top: ${topMargin};
      `
    );
  }}

  ${media.lessThan('sm')`
    width: auto;
  `}
`;

export const StyledFilterCheckboxWrapper = styled.div`
  margin-bottom: 7px;
  margin-right: 10px;
`;

export const StyledCheckboxSectionWrapper = styled.div`
  display: flex;
`;

export const StyledCheckboxSection = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMoreMenuOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexes.dropdown};
`;

export const StyledMoreMenuItem = styled.li<{ disabled: boolean }>`
  ${({ disabled }) => {
    return css`
      ${disabled && 'cursor: not-allowed;'}
    `;
  }}
`;

export const StyledMoreMenuOption = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  padding: 6px 20px;
  line-height: 35px;
  outline: none;
  width: 100%;
  text-align: left;
  ${transitions.base};

  ${({ disabled, theme }) => {
    return disabled
      ? css`
          color: ${theme.colors.typography.t2};
          cursor: not-allowed;
          opacity: 0.5;
          pointer-events: none;
        `
      : css`
          color: ${theme.colors.typography.t1};

          &:hover {
            background-color: ${theme.colors.surfaces.monochrome.overlayLevel2};
          }
        `;
  }}

  a {
    &:hover,
    > p:hover {
      text-decoration: none;
    }
  }
`;

export const StyledFilterFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const StyledFilterSectionTitleV2 = styled.p`
  min-width: 15rem;
`;

export const StyledSwitchWrapperV2 = styled.div`
  margin: 0;
`;
