import { GetTemplateEditDisabledReasonArgs } from './get-template-edit-disabled-reason.types';

export const getTemplateEditDisabledReason = (
  args: GetTemplateEditDisabledReasonArgs,
  ...customChecks: { reason: string; show: boolean }[]
) => {
  const { hasInvalidJobBlocks, isEditing, isMinorVersion, isReorderActive } =
    args;

  if (isReorderActive) {
    return 'Action is not available when table row reorder is active.';
  }

  if (isMinorVersion) {
    return 'Action is not available on a minor version.';
  }

  if (isEditing) {
    return 'Action is not available when editing.';
  }

  if (!!hasInvalidJobBlocks) {
    return 'Action is not available as the product contains invalid job blocks.';
  }

  if (customChecks) {
    const reason = getDisabledReason(...customChecks);

    if (reason) {
      return reason;
    }
  }

  return undefined;
};

export const getDisabledReason = (
  ...customChecks: { reason: string; show: boolean }[]
) => {
  for (let i = 0; i < customChecks.length; i++) {
    const { reason, show } = customChecks[i];

    if (show) {
      return reason;
    }
  }

  return undefined;
};
