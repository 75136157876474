import { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { formatDate } from '../../../../../../../../../helpers/formatter';
import { getTickValuesToRender } from './helpers/get-tick-values-to-render';
import { dataAttrRecalculatingHeight } from '../../../../report-summary.styles';
import { UseGetChartDataProps } from './use-get-chart-data.types';
import { MEDIUM_FORMAT } from '../../../../../../../../../constants/dates';
import { dashboardAndReportAtoms } from '../../../../../../../dashboard.atoms';

export const useGetChartData = (props: UseGetChartDataProps) => {
  const { activeChartDetails } = props;

  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const chartContainerRef = useRef<HTMLDivElement>(null);

  const [chartDesktopHeight, setChartDesktopHeight] = useState<string>('12rem');

  const xTicksToRender = getTickValuesToRender({
    activeChartDetails,
    rangeType,
  });

  const formatBottomTicks = (tick: string | number): string => {
    const isInTicksToRender = !!xTicksToRender.find((x) => {
      return x === tick;
    });

    return isInTicksToRender ? formatDate(tick, MEDIUM_FORMAT) : '';
  };

  const handleChartHeight = () => {
    // Charts cannot fill a section created by grid/flex. They need to have a static height set.
    const getChartHeight = () => {
      // hides content to accurately calculate height from CSS grid
      chartContainerRef?.current?.setAttribute(dataAttrRecalculatingHeight, '');

      const containerHeight = chartContainerRef?.current?.scrollHeight || 0;
      const minHeight = 120;

      // reveal content again
      chartContainerRef?.current?.removeAttribute(dataAttrRecalculatingHeight);

      return containerHeight > minHeight
        ? `${containerHeight / 10}rem`
        : '12rem';
    };

    const height = getChartHeight();

    if (height !== chartDesktopHeight) {
      setChartDesktopHeight(height);
    }
  };

  return {
    chartContainerRef,
    chartDesktopHeight,
    formatBottomTicks,
    handleChartHeight,
  };
};
