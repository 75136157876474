import * as yup from 'yup';
import {
  validateRequiredNumber,
  required,
  optional,
  isPositiveNumberRule,
  numberRule,
} from '../../../../../../../../../../../../helpers/validators';

export const ConfirmDeliveryFormSchema = yup.object().shape({
  deliveredAt: required(),
  discardedQuantity: isPositiveNumberRule(numberRule(yup.string().nullable())),
  expectedQuantity: validateRequiredNumber(),
  notes: yup.lazy((_, context) => {
    return !!context.parent.discardedQuantity &&
      Number(context.parent.discardedQuantity) > 0
      ? required()
      : optional();
  }),
  photos: yup.array(
    yup.object().shape({
      fileName: required(),
      url: required(),
    }),
  ),
  quantity: validateRequiredNumber(),
});
