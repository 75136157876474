import { v4 as uuidv4 } from 'uuid';
import { useFormikContext } from 'formik';
import {
  Button,
  Cell,
  Grid,
  Icon,
  SearchField,
  Tooltip,
} from 'gantri-components';
import { Fragment, useMemo } from 'react';
import { useGetLocationsQuery } from '../../../../../../../api/locations/routes/get-locations/get-locations.query';
import { InventoryFormData } from '../../../../../inventory.schema';
import { InventoryLocationsProps } from './inventory-location.types';

export const InventoryLocations = (props: InventoryLocationsProps) => {
  const { assignedLocations, isEditModeActive } = props;

  const { isLoading: isLoadingLocations, locations: availableLocations } =
    useGetLocationsQuery({
      enabled: isEditModeActive,
      type: 'inventory',
    });

  const { setFieldValue, values } = useFormikContext<InventoryFormData>();

  const locationItems = useMemo(() => {
    if (!isLoadingLocations && availableLocations) {
      const locationOptions =
        availableLocations.map((location) => {
          const disabled = values.locations.some((id) => {
            return id === location.id;
          });

          return { ...location, disabled };
        }) || [];

      // ensure that any assigned locations without the 'inventory' type are added so the input is not blank
      assignedLocations.forEach((location) => {
        if (
          !locationOptions.some(({ id }) => {
            return id === location.id;
          })
        ) {
          // @ts-expect-error
          locationOptions.push(location);
        }
      });

      return locationOptions;
    }

    return [];
  }, [availableLocations, values.locations, isLoadingLocations]);

  const hasEmptyLocations = values.locations.some((id) => {
    return !Boolean(id);
  });

  return (
    <Grid alignItems="center" columns="1fr max-content" gap="x">
      {values.locations.map((locationId, index) => {
        return (
          <Fragment key={locationId || uuidv4()}>
            <SearchField
              autoSelectFirst={false}
              disabled={isLoadingLocations}
              idProperty="id"
              items={locationItems}
              labelProperty="name"
              maxHeight="33rem"
              value={locationId}
              onSelect={(item) => {
                const newValue = values.locations.map(
                  (existingId, valueIndex) => {
                    return valueIndex === index ? item?.id : existingId;
                  },
                );

                setFieldValue('locations', newValue);
              }}
            />
            <Icon
              color="alert"
              cursor="pointer"
              name="actions:trash_can"
              onClick={() => {
                const newValue = values.locations.filter(
                  (_location, locationIndex) => {
                    return locationIndex !== index;
                  },
                );

                setFieldValue('locations', newValue);
              }}
            />
          </Fragment>
        );
      })}
      <Cell justifySelf="flex-start">
        <Tooltip
          description={
            hasEmptyLocations &&
            'You must fill out all location inputs before you can add a new one.'
          }
          position="right"
        >
          <Button
            disabled={hasEmptyLocations}
            icon={
              <Icon
                color={hasEmptyLocations ? 't1' : 'link'}
                name="ui-control:plus"
              />
            }
            size="small"
            text="Add location"
            variant="ghost"
            onClick={() => {
              setFieldValue('locations', [...values.locations, undefined]);
            }}
          />
        </Tooltip>
      </Cell>
    </Grid>
  );
};
