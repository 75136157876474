import styled, { css } from 'styled-components';
import { media } from 'gantri-components';
import { BorderTopDivider } from '../../styles';

export const StyledDesignerFeatureContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledHeaderButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
`;

export const StyledSectionContainer = styled.section<{
  section?: 'global-settings' | 'product-included' | 'page-colors';
}>`
  ${({ section, theme }) => {
    return css`
      display: inherit;
      flex-direction: column;
      padding: 2rem 0;
      border-bottom: 1px solid ${theme.colors.dividers.t1};
      ${section === 'global-settings' &&
      css`
        ${BorderTopDivider}
        ${media.lessThan('md')`
        .publish-toggle {
          margin-bottom: 1rem;
        }
      `}
      `}

      ${section === 'product-included' &&
      css`
        min-height: 30rem;
        height: auto;
      `}
    `;
  }}
`;

export const StyledSwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  justify-content: left;
  align-items: center;
  margin-bottom: 5rem;
`;

export const StyledColorInputContainer = styled.div<{ color: string }>`
  position: relative;
  padding-left: 3rem;

  p {
    margin-left: -3rem;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2.5rem;
    bottom: 0;
    margin: auto;
    width: 2rem;
    height: 2rem;
    background-color: ${(props) => {
      return props.color;
    }};
    border-radius: 50%;
    border: 1px solid
      ${(props) => {
        return props.theme.colors.palette.monochrome_200;
      }};
  }
`;
