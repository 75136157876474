import { FC } from 'react';
import { Button, Icon } from 'gantri-components';
import { StyledNavigationButtonWrapper } from './navigation-button.styles';
import { NavigationButtonProps } from './navigation-button.types';

export const NavigationButton: FC<NavigationButtonProps> = (props) => {
  const { disabled, name, onClick } = props;

  return (
    <StyledNavigationButtonWrapper>
      <Button
        disabled={disabled}
        icon={<Icon color={disabled ? 't1' : 't2'} name={name} />}
        variant="ghost"
        onClick={onClick}
      />
    </StyledNavigationButtonWrapper>
  );
};
