import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { TransactionResponse } from '../../transactions.types';
import { UpdateShippingMethodArgs } from './update-shipping-method.types';

export const updateShippingMethod = (args: UpdateShippingMethodArgs) => {
  const { provider, shipmentId, shippingType } = args;

  return axios.put<TransactionResponse>(
    `${baseApiUrl}/shipment/${shipmentId}/update-shipping`,
    { provider, shippingType },
  );
};
