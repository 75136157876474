import { memo, useMemo } from 'react';
import { useField } from 'formik';
import {
  Cell,
  Conditional,
  FormikInput,
  Grid,
  Line,
  Typography,
} from 'gantri-components';
import { Address, DiscountType, OrderStock } from '../../new-order.type';
import {
  canEnableDiscount,
  getHasTaxes,
  getSubtotal,
  getTaxableStocks,
  getTotal,
  getTotalDiscount,
} from './summary-section.adapter';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { NewOrderDiscountSection } from './discount-section';
import { useGetTaxesQuery } from '../../../../api/orders/routes';

export const NewOrderSummarySection = memo(() => {
  const [{ value: email }] = useField<string>('email');
  const [{ value: orderType }] = useField<string>('orderType');
  const [{ value: selectedAddress }] = useField<Address>('selectedAddress');
  const [{ value: stocks }] = useField<OrderStock[]>('stocks');
  const [{ value: discount }] = useField<number>('discount');
  const [{ value: shippingFee }] = useField<number>('shippingFee');
  const [{ value: discountType }] = useField<DiscountType>('discountType');

  const enableDiscount = useMemo(() => {
    return canEnableDiscount(orderType);
  }, [orderType]);

  const hasTaxes = useMemo(() => {
    return getHasTaxes(orderType);
  }, [orderType]);

  const taxableStocks = useMemo(() => {
    return getTaxableStocks(stocks);
  }, [stocks]);

  const { taxes = 0 } = useGetTaxesQuery({
    args: {
      address: selectedAddress,
      email,
      items: taxableStocks,
      orderType,
    },
    enabled: !!selectedAddress?.id && hasTaxes && !!taxableStocks?.length,
    transform: (response) => {
      return response.data.tax || 0;
    },
  });

  const subtotal = useMemo(() => {
    return getSubtotal(orderType, stocks);
  }, [orderType, stocks]);

  const totalDiscount = useMemo(() => {
    return getTotalDiscount(discountType, discount, subtotal);
  }, [discount, subtotal, discountType]);

  const total = useMemo(() => {
    return getTotal(subtotal, totalDiscount, shippingFee, taxes);
  }, [subtotal, taxes, shippingFee, totalDiscount]);

  return (
    <Grid columns={2} gap="unset">
      <Cell rowGap="x">
        <Grid columnGap="3x" columns="1fr 20rem">
          <Typography text="Subtotal" variant="p2" />
          <Typography
            text={formatAsCurrency(subtotal, { isCents: true })}
            variant="p2"
          />
        </Grid>

        <Conditional condition={enableDiscount}>
          <NewOrderDiscountSection totalDiscount={totalDiscount} />
        </Conditional>

        <Grid alignItems="center" columnGap="3x" columns="1fr 20rem">
          <Typography text="Shipping fee" variant="p2" />

          <Conditional
            condition={enableDiscount}
            Fallback={
              <Typography
                text={formatAsCurrency(0, { isCents: true })}
                variant="p2"
              />
            }
          >
            <FormikInput
              leftIcon={<Typography color="t2" text="$" variant="p2" />}
              name="shippingFee"
              placeholder="Input amount"
            />
          </Conditional>
        </Grid>

        <Grid columnGap="3x" columns="1fr 20rem">
          <Typography text="Tax" variant="p2" />
          <Typography
            text={formatAsCurrency(taxes || 0, { isCents: true })}
            variant="p2"
          />
        </Grid>

        <Line />

        <Grid columnGap="3x" columns="1fr 20rem">
          <Typography text="Total" variant="h6" />
          <Typography
            text={formatAsCurrency(total, { isCents: true })}
            variant="h6"
          />
        </Grid>
      </Cell>
    </Grid>
  );
});
