import { useResetRecoilState } from 'recoil';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { productJobsTabAtoms } from '../../../../../../../../../pages/product/components/product-jobs/product-jobs.atoms';
import { jobsTemplateTableAtoms } from '../../../../../../jobs-template-table.atoms';
import { useValidateFields } from '../use-validate-fields';
import { JobTemplateWithId } from '../../../../../../jobs-template-table.types';
import { UseGetOnSaveAndOnCancelProps } from './use-get-on-save-and-on-cancel.types';
import { filterInvalidInventoryRequests } from '../../../../../../../../../helpers/filter-invalid-inventory-requests';
import { formatGcodeFileName } from '../../../../../../../../../helpers/gcodes';

export const useGetOnSaveAndOnCancel = (
  props: UseGetOnSaveAndOnCancelProps,
) => {
  const {
    cachedJobTemplates,
    jobTemplate,
    onSaveOverride,
    partId,
    refetchProduct,
    setJobTemplates,
    setProcessing,
    version,
  } = props;

  const { resetValidateFields, validateFields } = useValidateFields();

  const resetIsEditingPage = useResetRecoilState(
    productJobsTabAtoms.isEditingJobs,
  );
  const resetEditingTemplateId = useResetRecoilState(
    jobsTemplateTableAtoms.editingTemplateId,
  );

  const { notify, notifyAxiosError } = useNotification();

  const resetEditingStates = () => {
    resetEditingTemplateId();
    resetIsEditingPage();
  };

  const onCancel = () => {
    setJobTemplates(cachedJobTemplates);
    resetEditingStates();
    resetValidateFields();
  };

  const handleUpdateGcode = (jobTemplate: JobTemplateWithId) => {
    const originalFileName =
      jobTemplate.gcode?.originalFileName || jobTemplate.gcode?.fileName;

    const formattedFileName = formatGcodeFileName({
      // to simplify what would be extremely complex logic on batch part editing, editing the job template row will create the gcode name out of only the original file name and weight
      partName: originalFileName,
      productName: '',
      productVersion: '',
      weight: jobTemplate?.weight,
    });

    return originalFileName
      ? {
          ...jobTemplate.gcode,
          fileName: formattedFileName,
          originalFileName,
        }
      : undefined;
  };

  const onSave = async () => {
    try {
      const canBeSaved = validateFields(jobTemplate);

      if (canBeSaved) {
        setProcessing(true);

        const jobTemplateId = jobTemplate.id;
        const isNewJobTemplate = !cachedJobTemplates.some(({ id }) => {
          return id === jobTemplateId;
        });

        const gcode = handleUpdateGcode(jobTemplate);
        const inventoryRequests = filterInvalidInventoryRequests(
          jobTemplate.inventoryRequests,
        );
        const updatedJobTemplate = {
          ...jobTemplate,
          gcode,
          inventoryRequests,
        };

        const handleInvalidInventories = (
          jobTemplates: JobTemplateWithId[],
        ) => {
          return jobTemplates.map((template) => {
            if (template.inventoryRequests.length) {
              const inventoryRequests = filterInvalidInventoryRequests(
                template.inventoryRequests,
              );

              return {
                ...template,
                inventoryRequests,
              };
            }

            return template;
          });
        };

        if (isNewJobTemplate) {
          const updatedJobTemplates = handleInvalidInventories([
            ...cachedJobTemplates,
            updatedJobTemplate,
          ]);

          onSaveOverride(updatedJobTemplates);
        } else {
          const updatedJobTemplates = handleInvalidInventories(
            cachedJobTemplates.map((cachedTemplate) => {
              return jobTemplateId === cachedTemplate.id
                ? updatedJobTemplate
                : cachedTemplate;
            }),
          );

          onSaveOverride(updatedJobTemplates);
        }

        resetEditingStates();
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to save changes.',
      });
    } finally {
      setProcessing(false);
    }
  };

  return { onCancel, onSave };
};
