import { useFormikContext } from 'formik';
import {
  Cell,
  Dropdown,
  FormikInput,
  Grid,
  SearchField,
} from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';
import { ProductNameAndStatus } from '../../../../../../../../components/common/select-product-form/components/select-product-form-content/components/product-search/components/product-name-and-status';
import { unpaintedColor } from '../../../../../../../../constants/colors';
import { batchPartCreationAtoms } from '../../../../../../batch-part-creation.atoms';
import { BatchPartCreationFormik } from '../../../../../../batch-part-creation.types';
import { CreatePartFromProductFormProps } from './create-part-from-product-form.types';
import { ColorSwatchDropdown } from '../../../../../../../../components/common/color-swatch-dropdown';

export const CreatePartFromProductForm = (
  props: CreatePartFromProductFormProps,
) => {
  const {
    disableProductPartAndColor,
    productColorOptions,
    productNameOptions,
    productPartOptions,
  } = props;

  const allProducts = useRecoilValue(batchPartCreationAtoms.allProducts);
  const [selectedProductDetails, setSelectedProductDetails] = useRecoilState(
    batchPartCreationAtoms.selectedProductDetails,
  );

  const { setFieldValue, values: formikValues } =
    useFormikContext<BatchPartCreationFormik>();

  const { productId } = formikValues;

  useAsync(async () => {
    if (productId) {
      const selectedProductDetails = allProducts.find(({ id }) => {
        return id === productId;
      });

      if (selectedProductDetails) {
        setSelectedProductDetails(selectedProductDetails);

        if (!selectedProductDetails.isPainted) {
          await setFieldValue('colorCode', unpaintedColor.code);
        }
      }
    }
  }, [productId]);

  return (
    <Grid columns={{ lg: 2, sm: 1 }} gap="2x" paddingTop="2rem">
      <Cell column={1}>
        <FormikInput
          Field={
            <SearchField
              disabled={productNameOptions.length <= 1}
              items={productNameOptions}
              labelText="Product"
              placeholder="Select a product"
              renderItem={(item: typeof productNameOptions[number]) => {
                return (
                  <ProductNameAndStatus
                    name={item?.label}
                    status={item?.status}
                  />
                );
              }}
              renderPlaceholder={(item: typeof productNameOptions[number]) => {
                return (
                  <ProductNameAndStatus
                    name={item?.label}
                    status={item?.status}
                    whitespace="nowrap"
                  />
                );
              }}
              required
              value={productId}
            />
          }
          name="productId"
        />
      </Cell>

      <Cell column={1}>
        <FormikInput
          Field={
            <Dropdown
              disabled={
                disableProductPartAndColor || productPartOptions.length <= 1
              }
              items={productPartOptions}
              labelText="Part"
              placeholder="Select a part"
              required
            />
          }
          fieldVariant="standard"
          name="partName"
        />
      </Cell>

      <Cell column={1}>
        <FormikInput
          Field={
            <ColorSwatchDropdown
              disabled={
                disableProductPartAndColor || productColorOptions.length <= 1
              }
              items={productColorOptions}
              labelText="Color"
              placeholder="Select a color"
              required
            />
          }
          fieldVariant="standard"
          name="colorCode"
        />
      </Cell>
    </Grid>
  );
};
