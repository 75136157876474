const yotpoConfig = {
  development: {
    apiKey: '2Bava0U7nDMvJuB2Q0w6BVi4RtZATM1ThK4ZqNtP',
  },
  production: {
    apiKey: '1nVM1PpQjrgwtB0bkLQ1d3BIXgh4nUyQC7g9rENH',
  },
  staging: {
    apiKey: '2Bava0U7nDMvJuB2Q0w6BVi4RtZATM1ThK4ZqNtP',
  },
};

export default yotpoConfig;
