import { Conditional, Tooltip, Typography } from 'gantri-components';
import { CompleteChecklistButtonWrapperProps } from './complete-checklist-button-wrapper.types';
import { getMissingEvaluationsMessage } from '../../helpers/get-missing-evaluations-message';

/** If the checklist is missing info from any items on the list, renders a descriptive tooltip on hover. */
export const CompleteChecklistButtonWrapper = (
  props: CompleteChecklistButtonWrapperProps,
) => {
  const { checklist, children } = props;

  const missingEvaluationsMessage = getMissingEvaluationsMessage(checklist);

  return (
    <Conditional condition={!!missingEvaluationsMessage} Fallback={children}>
      <Tooltip
        Component={
          <Typography
            color="alert"
            padding="1.2rem"
            text={missingEvaluationsMessage}
          />
        }
        overlayContainerStyles={{ display: 'block', width: '100%' }}
      >
        {children}
      </Tooltip>
    </Conditional>
  );
};
