import { atom } from 'recoil';
import { VLMLocation } from '../../../../../../../api/locations/routes/get-available-locations/get-available-locations.types';
import { StartFinishingStageJobModalSteps } from './start-finishing-stage-job-modal.types';

const atomKeyPrefix = 'FINISH_STAGE_MODAL';

export const startFinishStageModalAtoms = {
  step: atom<StartFinishingStageJobModalSteps>({
    default: 'CONFIRM_START',
    key: `${atomKeyPrefix}-step`,
  }),
  stockLocation: atom<VLMLocation>({
    default: null,
    key: `${atomKeyPrefix}-stock-location`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
  vlmNotified: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-vlm-notified`,
  }),
};
