import { StorefrontProps } from './storefront.types';
import { StyledStoreFrontContainer } from './storefront.styles';
import {
  ColorSection,
  CollectionPages,
  HeroSection,
  ImagerySection,
  QaSection,
} from './components';

export const Storefront = (props: StorefrontProps) => {
  const { currentDesigner, updateDesignerInfo } = props;

  return (
    <StyledStoreFrontContainer>
      <ColorSection
        theme={currentDesigner.theme}
        updateDesignerInfo={updateDesignerInfo}
      />
      <HeroSection
        heroImages={currentDesigner.heroImages}
        id={currentDesigner.userId}
        updateDesignerInfo={updateDesignerInfo}
        videoUrl={currentDesigner.videoUrl}
      />
      <ImagerySection
        editorialImages={currentDesigner.editorialImages}
        id={currentDesigner.userId}
        processImages={currentDesigner.processImages}
        quoteImages={currentDesigner.quoteImage}
        updateDesignerInfo={updateDesignerInfo}
      />
      <CollectionPages
        collectionPages={currentDesigner.collectionPages}
        id={currentDesigner.userId}
        profileLink={currentDesigner.profileLink}
        updateDesignerInfo={updateDesignerInfo}
      />
      <QaSection
        QAs={currentDesigner.QAs}
        updateDesignerInfo={updateDesignerInfo}
      />
    </StyledStoreFrontContainer>
  );
};
