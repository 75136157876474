import { ResponsiveLine } from '@nivo/line';
import { memo } from 'react';
import { useTheme } from 'styled-components';
import {
  formatAsLocaleNumber,
  formatDate,
} from '../../../../../helpers/formatter';
import { dashboardChartDefaultProps } from './dashboard-chart.presets';
import { StyledChartWrapper } from './dashboard-chart.styles';
import { DashboardChartProps } from './dashboard-chart.types';
import { getYScale } from './helpers/get-y-scale';
import { SliceTooltip } from './components/slice-tooltip';
import { splitPositivesAndNegatives } from './helpers/split-positives-and-negatives';
import { MEDIUM_FORMAT } from '../../../../../constants/dates';

const DashboardChartBase = (props: DashboardChartProps) => {
  const {
    axisBottom = {},
    axisLeft = {},
    data,
    minHeight,
    transformTooltipValue,
    ...overrides
  } = props;

  const theme = useTheme();

  const { t1: negativeFillColor, t3: negativeLineColor } =
    theme.colors.surfaces.alert;
  const { t1: positiveFillColor, t3: positiveLineColor } =
    theme.colors.surfaces.green;
  const chartData = splitPositivesAndNegatives(data);
  const colors = chartData.map(({ id }) => {
    return /negative/i.test(id?.toString())
      ? negativeLineColor
      : positiveLineColor;
  });
  const negativeValuesOnY =
    data[0].data
      .filter(({ y }) => {
        return y < 0;
      })
      .map(({ y }) => {
        return y as number;
      }) || [];
  const largestNegativeValueOnY = negativeValuesOnY.length
    ? Math.min(...negativeValuesOnY) || 0
    : 0;

  const formatLeftTicks = (value: number | string) => {
    return typeof value === 'number' ? formatAsLocaleNumber(value) : value;
  };

  return (
    <StyledChartWrapper $minHeight={minHeight}>
      {/* @ts-ignore */}
      <ResponsiveLine
        areaBaselineValue={largestNegativeValueOnY}
        areaOpacity={1}
        axisBottom={{
          format: (x) => {
            return formatDate(x, MEDIUM_FORMAT);
          },
          tickPadding: 15,
          tickSize: 0,
          ...axisBottom,
        }}
        axisLeft={{
          format: formatLeftTicks,
          tickPadding: 15,
          tickSize: 0,
          ...axisLeft,
        }}
        colors={colors}
        data={chartData}
        defs={[
          {
            colors: [
              { color: positiveFillColor, offset: 0 },
              { color: positiveFillColor, offset: 100 },
            ],
            // Due to an issue with Safari, this essentially only exists to give us a target-able fill attr. The actual fill color is assigned in this component's styles file.
            id: 'positive-fill-color',
            type: 'linearGradient',
          },
          {
            colors: [
              { color: negativeFillColor, offset: 0 },
              { color: negativeFillColor, offset: 100 },
            ],
            // Due to an issue with Safari, this essentially only exists to give us a target-able fill attr. The actual fill color is assigned in this component's styles file.
            id: 'negative-fill-color',
            type: 'linearGradient',
          },
        ]}
        enableArea
        enableGridX={false}
        enableGridY={false}
        enablePoints={false}
        enableSlices="x"
        fill={[
          {
            id: 'positive-fill-color',
            match: ({ id }) => {
              return /-positive$/i.test(id);
            },
          },
          {
            id: 'negative-fill-color',
            match: ({ id }) => {
              return /-negative$/i.test(id);
            },
          },
        ]}
        layers={[
          'grid',
          'axes',
          'areas',
          'crosshair',
          'lines',
          'points',
          'slices',
          'mesh',
          'markers',
          'legends',
        ]}
        lineWidth={2}
        margin={{ bottom: 35, left: 40, right: 20, top: 5 }}
        markers={
          negativeValuesOnY.length
            ? [
                {
                  axis: 'y',
                  lineStyle: {
                    stroke: theme.colors.dividers.t2,
                    strokeWidth: 1,
                  },
                  value: 0,
                },
              ]
            : []
        }
        pointLabelYOffset={
          0
          /* should not be required, but logs console error if not provided. */
        }
        sliceTooltip={(props) => {
          return (
            <SliceTooltip
              chartData={chartData}
              props={props}
              transformTooltipValue={transformTooltipValue}
            />
          );
        }}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.colors.dividers.t1,
                strokeWidth: 1,
              },
            },
          },
          crosshair: {
            line: {
              stroke: positiveLineColor,
              strokeDasharray: 'none',
              strokeOpacity: 1,
              strokeWidth: 1,
            },
          },
          fontSize: 15,
          textColor: theme.colors.typography.t1,
        }}
        yFormat={(value: string) => {
          return value;
        }}
        yScale={getYScale(data)}
        {...overrides}
      />
    </StyledChartWrapper>
  );
};

DashboardChartBase.defaultProps = dashboardChartDefaultProps;

export const DashboardChart = memo(DashboardChartBase);
