import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardApi } from '../../../../api';
import { GetReportSalesArgs } from '../../../../api/dashboard/routes/get-report-sales/get-report-sales.types';
import { getActiveQueries } from '../../../../helpers/checks';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { DashboardChartProps } from '../../../dashboard/components/common/dashboard-chart/dashboard-chart.types';
import { dashboardAndReportAtoms } from '../../../dashboard/dashboard.atoms';
import { ReportPage } from '../common/report-page';
import {
  GetReportData,
  ReportProps,
} from '../common/report-page/report-page.types';
import {
  getBreadcrumbs,
  getReportDetails,
  getRowProps,
  getTitle,
} from './helpers';
import {
  defaultSalesReportGroupBy,
  pageName,
  reportSalesPageAtoms,
} from './report-sales.constants';
import { ReportSalesQueries } from './report-sales.types';

export const ReportSales = ({ rootTitle }: ReportProps) => {
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const {
    activeChart: activeChartQuery,
    color,
    country,
    creatorId,
    customerType,
    groupBy = defaultSalesReportGroupBy,
    productId,
    state,
  } = getActiveQueries<ReportSalesQueries>();
  const [activeChart, setActiveChart] = useState<string>(activeChartQuery);

  const activeChartIsCurrency = ['sales'].includes(activeChart);

  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] =
    activeChartIsCurrency
      ? (value) => {
          return formatAsCurrency(+value, {
            isCents: true,
          });
        }
      : undefined;
  const axisLeft: DashboardChartProps['axisLeft'] = activeChartIsCurrency
    ? {
        format: (y: number) => {
          return formatAsCurrency(y, {
            isCents: true,
            maximumFractionDigits: 0,
          });
        },
      }
    : undefined;

  const {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  } = getReportDetails();

  const getReportData: GetReportData = ({ endDate, startDate }, configs) => {
    const args: GetReportSalesArgs = {
      color,
      country,
      creatorId: creatorId ? +creatorId : undefined,
      customerType,
      endDate,
      groupBy,
      productId: productId ? +productId : undefined,
      rangeType,
      startDate,
      state,
    };

    return dashboardApi.getReportSales(args, configs);
  };

  return (
    <ReportPage
      activeChart={activeChart}
      activeChartIsCurrency={activeChartIsCurrency}
      axisLeft={axisLeft}
      breadcrumbs={getBreadcrumbs()}
      defaultChart="sales"
      getReportData={getReportData}
      getRowProps={getRowProps}
      groupByIsVisible={groupByIsVisible}
      groupByItems={groupByItems}
      groupByOnChange={groupByOnChange}
      groupByValue={groupBy}
      pageName={pageName}
      setActiveChart={setActiveChart}
      sortByAtom={reportSalesPageAtoms.sortBy}
      sortingFields={sortingOptions}
      tableColumns={tableColumns}
      title={getTitle(rootTitle)}
      transformTooltipValue={transformTooltipValue}
    />
  );
};
