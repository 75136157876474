import styled from 'styled-components';
import { Typography } from 'gantri-components';

export const StyledTooltipTypography = styled(Typography)`
  display: inline;
`;

export const StyledFieldIconWrapper = styled.div`
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  cursor: pointer;
`;
