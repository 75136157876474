import { environment } from '../environment';

export const STD_COOKIE_OPTIONS = {
  domain: environment.COOKIES_DOMAIN,
  path: '/',
};

const generateStorageKey = (key: string) => {
  return `${key}_${environment.STAGE}`;
};

export const AUTH_TOKEN = generateStorageKey('AUTH_TOKEN');

export const ADMIN_ROLE_TYPE = generateStorageKey('ROLE_TYPE');

export const FIREBASE_TOKEN = generateStorageKey('FIREBASE_TOKEN');

export const CORE_VERSION = 'CORE_VERSION';
