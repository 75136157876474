import { memo, ChangeEvent, useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useClickAway, useToggle } from 'react-use';
import { Grid, Cell, Typography, TextField } from 'gantri-components';
import {
  StyledSectionContainer,
  StyledColorInputContainer,
} from '../../designer-feature.styles';
import { StyledColorPopup } from '../../../designer/designer-styles';
import { FeatureColors } from '../../desginer-feature.types';
import { PageColorProps } from './page-colors.types';

export const Section = (props: PageColorProps) => {
  const {
    primaryBackground,
    primaryText,
    secondaryBackground,
    secondaryText,
    updateFeatureInfo,
  } = props;

  const [primaryBackgroundVisible, togglePrimaryBackgroundPicker] =
    useToggle(false);
  const [primaryTextVisible, togglePrimaryTextPicker] = useToggle(false);

  const primaryBackgroundRef = useRef();

  useClickAway(primaryBackgroundRef, () => {
    togglePrimaryBackgroundPicker();
  });

  const primaryTextRef = useRef();

  useClickAway(primaryTextRef, () => {
    togglePrimaryTextPicker();
  });

  const [secondaryBackgroundVisible, toggleSecondaryBackgroundPicker] =
    useToggle(false);
  const [secondaryTextVisible, toggleSecondaryTextPicker] = useToggle(false);

  const secondaryBackgroundRef = useRef();

  useClickAway(secondaryBackgroundRef, () => {
    toggleSecondaryBackgroundPicker();
  });

  const secondaryTextRef = useRef();

  useClickAway(secondaryTextRef, () => {
    toggleSecondaryTextPicker();
  });

  const updateTheme = (property: FeatureColors, value: string) => {
    updateFeatureInfo({
      key: property,
      type: 'key',
      value,
    });
  };

  const onColorChange = (property: FeatureColors) => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      updateTheme(property, event.currentTarget.value);
    };
  };

  return (
    <StyledSectionContainer section="page-colors">
      <Typography text="Page colors" textStyle="bold" variant="h4" />
      <Grid columns={{ lg: 4, sm: 1 }} marginTop="1.2rem">
        <Cell>
          <StyledColorInputContainer color={primaryBackground}>
            <TextField
              labelPosition="top"
              labelText="Primary background color"
              placeholder="#FFFFFF"
              value={primaryBackground}
              onChange={onColorChange('primaryBackground')}
              onClick={togglePrimaryBackgroundPicker}
            />
            {primaryBackgroundVisible && (
              <StyledColorPopup ref={primaryBackgroundRef}>
                <ChromePicker
                  color={primaryBackground}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('primaryBackground', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
        <Cell>
          <StyledColorInputContainer color={secondaryBackground}>
            <TextField
              labelPosition="top"
              labelText="Secondary background color"
              placeholder="#FAFAFA"
              value={secondaryBackground}
              onChange={onColorChange('secondaryBackground')}
              onFocus={toggleSecondaryBackgroundPicker}
            />
            {secondaryBackgroundVisible && (
              <StyledColorPopup ref={secondaryBackgroundRef}>
                <ChromePicker
                  color={secondaryBackground}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('secondaryBackground', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
        <Cell>
          <StyledColorInputContainer color={primaryText}>
            <TextField
              labelPosition="top"
              labelText="Primary text color"
              placeholder="#1c1c1c1c"
              value={primaryText}
              onChange={onColorChange('primaryText')}
              onClick={togglePrimaryTextPicker}
            />
            {primaryTextVisible && (
              <StyledColorPopup ref={primaryTextRef}>
                <ChromePicker
                  color={primaryText}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('primaryText', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
        <Cell>
          <StyledColorInputContainer color={secondaryText}>
            <TextField
              className="color-picker"
              labelPosition="top"
              labelText="Secondary text color"
              placeholder="#1c1c1c1c"
              value={secondaryText}
              onChange={onColorChange('secondaryText')}
              onFocus={toggleSecondaryTextPicker}
            />
            {secondaryTextVisible && (
              <StyledColorPopup ref={secondaryTextRef}>
                <ChromePicker
                  color={secondaryText}
                  disableAlpha
                  onChangeComplete={({ hex }) => {
                    return updateTheme('secondaryText', hex);
                  }}
                />
              </StyledColorPopup>
            )}
          </StyledColorInputContainer>
        </Cell>
      </Grid>
    </StyledSectionContainer>
  );
};

export const PageColors = memo(Section);
