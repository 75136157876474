import styled, { css } from 'styled-components';
import { palette } from 'gantri-components';
import { mediaQueries, zIndexes } from '../../constants/styles';

export const StyledOuterContainer = styled.div`
  ${({ width }) => {
    return css`
      display: flex;
      flex-direction: column;
      z-index: 11;
      height: max-content;
      width: ${width || 'max-content'};
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      ${mediaQueries.mobile(css`
        padding: 0;
      `)}
    `;
  }}
`;

export const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: ${zIndexes.modalOverlay};
  background-color: ${palette.translucent_black_t1};
  pointer-events: visible;
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-container {
    width: 100%;
    position: initial;
  }

  div[class*='StyledInputContainer'] {
    background-color: var(--colors-modal-pickers-background);
  }
`;

export const StyledModalWrapper = styled.div`
  ${({ horizontalPadding, verticalPadding, width }) => {
    return css`
    background-color: var(--colors-modal-background-color);
    display: flex;
    flex-direction: column;
    padding-top: ${verticalPadding};
    padding-bottom: ${verticalPadding};
    padding-left: ${horizontalPadding};
    padding-right: ${horizontalPadding};
    margin: 0 30px;
    min-width: 400px;
    max-width: 500px;
    border-radius: 5px;
    align-self: center;
    width: ${width};

    h4 {
      text-align: center;
    }
  }

  ${mediaQueries.mobile(css`
    height: 100%;
    width: 100vw;
    padding: 60px 30px 60px;
    max-width: 100vw;
    min-width: 280px;
    margin: 0;
  `)}
  `;
  }}
`;

StyledModalWrapper.defaultProps = {
  horizontalPadding: '60px',
  verticalPadding: '60px',
};

export const StyledModalContent = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 1.5em;
    height: 0;
  }

  ::-webkit-scrollbar-thumb {
    height: 2em;
    border: 0.5em solid rgba(0, 0, 0, 0); /* Transparent border together with \`background-clip: padding-box\` does the trick */
    background-clip: padding-box;
    border-radius: 1em;
    background-color: rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.025);
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;

export const StyledModalButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  column-gap: 10px;
  margin-top: ${({ marginOverride }) => {
    return marginOverride || '40px';
  }};
  padding-left: ${({ buttonHorizontalPadding }) => {
    return buttonHorizontalPadding || 0;
  }};
  padding-right: ${({ buttonHorizontalPadding }) => {
    return buttonHorizontalPadding || 0;
  }};
`;

export const StyledButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCloseButton = styled.div`
  cursor: pointer;
  outline: none;
  width: 32px;
  height: 32px;
  align-self: flex-end;
  margin-bottom: 10px;
  border-radius: 50%;

  ${mediaQueries.mobile(css`
    position: absolute;
    top: 10px;
    right: 10px;
  `)}
`;

export const StyledModalTitleWrapper = styled.div`
  margin-bottom: 20px;
`;

export const StyledAlertIconWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    height: 50px;
    width: 50px;
  }

  ${({ hasTitle }) => {
    return (
      hasTitle &&
      css`
        padding-bottom: 20px;
      `
    );
  }}
`;

export const StyledAlertModalStaticContainer = styled.div`
  ${({ hasInfo }) => {
    return (
      hasInfo &&
      css`
        padding-bottom: 30px;
        border-bottom: 1px solid ${palette.monochrome_200};
      `
    );
  }}
`;

export const StyledAlertModalInfoContainer = styled.div`
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 10px;
`;
