import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../../jobs/components/modals/modals.constants';
import { newMachineIssuesModalAtoms } from '../../../new-machine-issues-modal.atoms';
import { useBulkUpdateMachines } from '../../../../../../../api/machines/routes';
import { IssuesChecklistFooterProps } from './issues-checklist-footer.types';
import { BulkUpdateResponse } from '../../../../../../../api/machines/routes/bulk-update/bulk-update.types';
import { newMachineIssuesModalSteps } from '../../../new-machine-issues-modal.constants';

export const IssuesChecklistFooter = (props: IssuesChecklistFooterProps) => {
  const { handleCloseModal } = props;

  const selectedIssues = useRecoilValue(
    newMachineIssuesModalAtoms.selectedIssues,
  );
  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    newMachineIssuesModalAtoms.detailsPanelMachines,
  );

  const setUpdateOnClose = useSetRecoilState(
    newMachineIssuesModalAtoms.updateOnClose,
  );
  const setMachinesOfflined = useSetRecoilState(
    newMachineIssuesModalAtoms.machinesOfflined,
  );
  const setStep = useSetRecoilState(newMachineIssuesModalAtoms.step);

  const onSuccess = async ({ offlined }: BulkUpdateResponse) => {
    setMachinesOfflined(offlined);

    const updatedDetailsPanelMachines = detailsPanelMachines.map(
      (oldMachine) => {
        const updatedMachine = offlined.find(({ id }) => {
          return id === oldMachine.id;
        });

        return updatedMachine || oldMachine;
      },
    );

    setDetailsPanelMachines(updatedDetailsPanelMachines);
    setUpdateOnClose(true);
    setStep(newMachineIssuesModalSteps.reviewCreatedIssues);
  };

  const { isLoading, onBulkUpdateMachines } = useBulkUpdateMachines({
    onSuccess,
  });

  const onConfirm = async () => {
    const machineIds = detailsPanelMachines.map(({ id }) => {
      return id;
    });

    await onBulkUpdateMachines({
      action: 'New issue',
      ids: machineIds,
      issues: selectedIssues,
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={handleCloseModal}
      />
      <Button
        disabled={!selectedIssues.length}
        processing={isLoading}
        size={fullScreenModalFooterButtonSize}
        text="Confirm"
        onClick={onConfirm}
      />
    </>
  );
};
