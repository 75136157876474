import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultJobsGroupBy } from '../../report-jobs.constants';
import { ReportJobsQueries } from '../../report-jobs.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportJobsQueries>();
  const {
    groupBy = defaultJobsGroupBy,
    partName,
    jobType,
    workerId,
    productId,
    machineType,
    machineName,
    version,
  } = queries;

  const isIssuesPage = !!groupBy && !!jobType;

  if (isIssuesPage) return 'Failure Name';

  if (/^productName$/i.test(groupBy)) {
    if (jobType) {
      return 'Failure Name';
    }

    if (partName) {
      return 'Job Type';
    }

    if (version) {
      return 'Part';
    }

    if (productId) {
      return 'Version';
    }

    return 'Product Name';
  }

  if (/^jobType$/i.test(groupBy)) {
    return 'Job Type';
  }

  if (/^workerName$/i.test(groupBy)) {
    if (!workerId) {
      return 'Worker Name';
    }

    if (!jobType) {
      return 'Job Type';
    }
  }

  if (/^machineType$/i.test(groupBy)) {
    if (!machineType) {
      return 'Machine Type';
    }

    if (!machineName) {
      return 'Machine Name';
    }

    if (!jobType) {
      return 'Job Type';
    }
  }

  return '';
};
