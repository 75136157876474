import { FC, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Conditional,
  Flex,
  Icon,
  Line,
  Typography,
} from 'gantri-components';
import { useToggle } from 'react-use';
import {
  StyledOrderTransactionContainer,
  StyledShipmentActionButtonsWrapper,
  StyledShipmentHeaderColumn,
  StyledShipmentHeaderContainer,
  StyledShipmentHeaderField,
  StyledShipmentHeaderItemsWrapper,
  StyledTrackingAnchor,
} from './order-shipment.styles';
import {
  shipmentDateLabelsMap,
  shipmentStatuses,
  transactionTypesMap,
  UPSTrackingUrl,
} from '../../../../constants/options';
import { formatAsCurrency, formatDate } from '../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../constants/dates';
import {
  OrderShipmentActionButton,
  PackingSlipsButton,
  shouldShipmentStatusShowLabelBtn,
  TestShippoWebhookButtons,
} from './components';
import { OrderShipmentProps } from './order-shipment.types';
import { transactionsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';
import { ShipmentStocks } from './components/shipment-stocks';

export const OrderShipment: FC<OrderShipmentProps> = (props) => {
  const {
    cancelShipment,
    currentOrderData,
    estimateDate,
    id,
    isSignatureRequired,
    rateCost,
    setCurrentOrderData,
    shipmentHistory,
    status,
    stocks,
    trackingNumber,
  } = props;

  const [isExpandStocks, setIsExpandStocks] = useToggle(true);
  const { notify, notifyAxiosError } = useNotification();
  const { shipping, type } = currentOrderData;

  const canUpdateSignature =
    status === 'Waiting' ||
    status === shipmentStatuses.waiting ||
    status === shipmentStatuses.readyToShip;

  const onSignatureRequiredChanged = (shipmentId: number) => {
    return async (value: boolean) => {
      try {
        const { data } =
          await transactionsApi.updateSignatureRequiredForDelivery(
            shipmentId,
            value,
          );

        notify(data.notice);

        if (data && data.success) {
          setCurrentOrderData(data.order);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage:
            'Unable to update if signature is required for delivery.',
        });
      }
    };
  };

  const DatesMap = () => {
    return (
      <>
        {Object.keys(shipmentDateLabelsMap).map((name) => {
          const { key, label: labelName } = shipmentDateLabelsMap[name];

          const getShipmentDateValue = () => {
            const value = estimateDate[key];

            return value ? formatDate(new Date(value), MEDIUM_FORMAT) : '';
          };

          return (
            <StyledShipmentHeaderField key={key}>
              <Typography text={labelName} />
              <Typography color="t1" text={getShipmentDateValue() || 'TBD'} />
            </StyledShipmentHeaderField>
          );
        })}
      </>
    );
  };

  const Tracking = () => {
    const trackingUrl =
      shipping.provider === 'UPS' && UPSTrackingUrl(trackingNumber);

    return trackingNumber ? (
      <StyledTrackingAnchor href={trackingUrl}>
        {trackingNumber}
      </StyledTrackingAnchor>
    ) : (
      <Typography color="t1" text="N/A" />
    );
  };

  const onCancel = () => {
    cancelShipment(id);
  };

  const shipmentTitle = useMemo(() => {
    if (status === shipmentStatuses.delivered) {
      return `Delivered on ${formatDate(
        estimateDate.deliveredOnSec,
        MEDIUM_FORMAT,
      )}`;
    }

    return `${'Est. delivery on'} ${formatDate(
      estimateDate.deliveryBySec || estimateDate.estDeliveryBySec,
      MEDIUM_FORMAT,
    )}`;
  }, [status, estimateDate]);

  const shouldGroupStocksByStatus = stocks.length > 3;

  return (
    <StyledOrderTransactionContainer data-shipment-id={id}>
      <TestShippoWebhookButtons
        currentOrderData={currentOrderData}
        shipmentHistory={shipmentHistory}
        shipmentId={id}
        shipmentStatus={status}
        trackingNumber={trackingNumber}
      />

      <Flex gap="0.8rem" justifyContent="space-between" marginBottom="4x">
        <div>
          <Typography text={shipmentTitle} variant="h4" />

          {estimateDate &&
            (estimateDate.leadTime === 'immediately' ||
              estimateDate.leadTime === '1-2 weeks') && (
              <Typography
                color="link"
                icon={<Icon color="link" name="alert:lightning_bolt_filled" />}
                marginTop="0.5rem"
                text="Quick Ship"
                variant="p2"
              />
            )}

          <Box marginTop="2x">
            <Checkbox
              checked={isSignatureRequired}
              disabled={!canUpdateSignature}
              labelText="Signature required for delivery"
              onSelected={onSignatureRequiredChanged(id)}
            />
          </Box>
        </div>
        <StyledShipmentActionButtonsWrapper
          gap="0.8rem"
          justifyContent="flex-end"
          width="auto"
        >
          {shouldShipmentStatusShowLabelBtn(status) &&
            type !== transactionTypesMap.refund && (
              <Button text="Cancel" variant="primaryAlert" onClick={onCancel} />
            )}
          <OrderShipmentActionButton {...props} />
          <PackingSlipsButton {...props} />
        </StyledShipmentActionButtonsWrapper>
      </Flex>

      <StyledShipmentHeaderContainer>
        <StyledShipmentHeaderItemsWrapper>
          <StyledShipmentHeaderColumn>
            <DatesMap />
          </StyledShipmentHeaderColumn>
          <StyledShipmentHeaderColumn>
            <StyledShipmentHeaderField>
              <Typography text="Status" />
              <Typography color="t1" text={status} />
            </StyledShipmentHeaderField>
            <StyledShipmentHeaderField>
              <Typography text="Tracking" />
              <Tracking />
            </StyledShipmentHeaderField>
            <StyledShipmentHeaderField>
              <Typography text="Cost" />
              <Typography
                color="t2"
                text={
                  rateCost
                    ? formatAsCurrency(rateCost, { isCents: true })
                    : 'N/A'
                }
              />
            </StyledShipmentHeaderField>
          </StyledShipmentHeaderColumn>
        </StyledShipmentHeaderItemsWrapper>

        <Conditional condition={shouldGroupStocksByStatus}>
          <Line marginBottom="1.2rem" marginTop="x" />

          <Flex alignItems="center" justifyContent="space-between">
            <Typography text={`${stocks.length} stocks in shipment`} />
            <Typography
              color="t2"
              icon={
                <Icon
                  name={
                    isExpandStocks
                      ? 'arrows:arrow_chevron_double_up'
                      : 'arrows:arrow_chevron_double_down'
                  }
                />
              }
              iconPosition="right"
              text={isExpandStocks ? 'Collapse All' : 'Expand All'}
              onClick={setIsExpandStocks}
            />
          </Flex>
        </Conditional>
      </StyledShipmentHeaderContainer>

      <ShipmentStocks hasExpandAll={isExpandStocks} stocks={stocks} />
    </StyledOrderTransactionContainer>
  );
};
