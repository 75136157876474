import { ColumnToHide } from '../../../../../../../../../types/store';
import { CheckboxOption } from '../../../../../../../../../components/user-preferences-toggle/components/user-preferences-modal/user-preferences-modal.types';
import { availableReports } from '../../settings-button.constants';

export const getChecklistFromReports = (
  reportsToHide: ColumnToHide<'dashboard'>[],
): CheckboxOption<'dashboard'>[] => {
  const defaultChecklist: CheckboxOption<'dashboard'>[] = availableReports.map(
    (label) => {
      const option: CheckboxOption<'dashboard'> = {
        checked: true,
        label,
      };

      return option;
    },
  );

  const updatedChecklist = defaultChecklist.map((option) => {
    const reportIsDisabled = reportsToHide.includes(option.label);

    return reportIsDisabled ? { ...option, checked: false } : option;
  });

  return updatedChecklist;
};
