import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { updateDesigner } from './update-designer';
import {
  UpdateDesignerArgs,
  UpdateDesignerResponse,
} from './update-designer.types';

export const useUpdateDesigner = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onUpdateDesigner, ...rest } = useQueryMutation<
    UpdateDesignerArgs,
    UpdateDesignerResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update designer.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(notice);
      },
    },
    mutationFn: async (args) => {
      return updateDesigner(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onUpdateDesigner };
};
