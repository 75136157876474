import React, { FC } from 'react';
import { Cell, Grid, Typography, Table, Conditional } from 'gantri-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import routePaths from '../../../../../config/route-paths';
import { SectionProps } from './section.types';
import { InventoryProductData } from '../../../../../api/inventories/routes/get-inventory/get-inventory.types';
import { StyledAnchor } from '../../../../../components/common/styled-anchor';
import { getIsClickableIfValue } from '../../../../../helpers/get-is-clickable-if-value';

const columns: ColumnDef<InventoryProductData>[] = [
  {
    accessorKey: 'id',
    cell: ({
      getValue,
    }: CellContext<InventoryProductData, InventoryProductData['id']>) => {
      const id = getValue();

      return (
        <Conditional condition={!!id}>
          <StyledAnchor
            maxWidth="4rem"
            text={`#${id}`}
            to={`${routePaths.products}/${id}`}
          />
        </Conditional>
      );
    },
    header: 'ID',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 100,
  },
  {
    accessorKey: 'name',
    header: 'Name',
  },
];

export const ProductsSection: FC<SectionProps> = ({ inventory }) => {
  const offMarketProducts = inventory?.productsData?.['Off Market'] || [];
  const activeProducts = inventory?.productsData?.Active || [];

  return (
    <Grid alignItems="flex-start" columns={{ lg: 2, sm: 1 }} gap="2.4rem">
      <Cell>
        <Typography
          text={`Active Products (${activeProducts.length || 0})`}
          textStyle="bold"
          variant="h4"
        />
        <Table columns={columns} data={activeProducts} />
      </Cell>
      <Cell>
        <Typography
          text={`Off Market Products (${offMarketProducts.length})`}
          textStyle="bold"
          variant="h4"
        />

        <Table columns={columns} data={offMarketProducts} />
      </Cell>
    </Grid>
  );
};
