import { CellContext } from '@tanstack/react-table';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { CurrencyCell } from '../../../dashboard/components/common/currency-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { convertToDollars } from '../common/report-page/components/report-table/helpers/convert-to-dollars';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import { ReportProfitTableData } from './report-profit.types';

export const defaultProfitReportGroupBy = 'orderType';

export const profitBaseTableColumns: DownloadableTableColumn<ReportProfitTableData>[] =
  [
    {
      accessorKey: 'subtotal',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['subtotal']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Subtotal $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'shipping',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['shipping']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Shipping $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'discount',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['discount']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Discount $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'tax',
      cell: ({
        getValue,
      }: CellContext<ReportProfitTableData, ReportProfitTableData['tax']>) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Tax $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'sales',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['sales']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Sales Exc. Tax $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'cogsBom',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['cogsBom']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'COGS - BOM $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'cogsLabor',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['cogsLabor']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'COGS - Labor $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'cogsShipping',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['cogsShipping']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'COGS - Shipping $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'cogsRoyalty',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['cogsRoyalty']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'COGS - Royalty $',
      transformDownload: convertToDollars,
    },
    {
      accessorKey: 'grossProfit',
      cell: ({
        getValue,
      }: CellContext<
        ReportProfitTableData,
        ReportProfitTableData['grossProfit']
      >) => {
        return <CurrencyCell value={getValue()} />;
      },
      header: 'Gross Profit $',
      transformDownload: convertToDollars,
    },
  ];

export const profitBaseSortingOptions = getSortingOptionsFromColumns(
  profitBaseTableColumns,
);

export const pageName: AtomPageName = 'report-profit';

export const reportProfitPageAtoms = {
  sortBy: getSortByFilterAtomFamily<SortBy<keyof ReportProfitTableData>>({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
