import { useInvalidateGetMachineIssuesCache } from '../get-issues/get-issues.query';
import { editIssue } from './edit-issue';
import { EditIssueArgs, EditIssueResponse } from './edit-issue.types';
import { useInvalidateFetchPaginatedMachineTimelineCache } from '../fetch-paginated-machine-timelines';
import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';

export const useEditMachineIssue = <TransformedData = EditIssueResponse>(
  props?: GenericMutateQueryProps<
    EditIssueArgs,
    EditIssueResponse,
    TransformedData
  >,
) => {
  const invalidateCache = useInvalidateGetMachineIssuesCache();
  const invalidateTimelineCache =
    useInvalidateFetchPaginatedMachineTimelineCache();

  const { onMutate: onEditIssue, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update the machine issue.',
    mutationFn: editIssue,
    ...props,
    onSuccess: async (data, variables) => {
      await invalidateCache({ machineId: variables.machineId });
      await invalidateTimelineCache({ machineId: variables.machineId });

      await props.onSuccess?.(data, variables);
    },
  });

  return { ...rest, onEditIssue };
};
