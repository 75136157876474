import { FC } from 'react';
import { Typography, TextArea, Grid, Cell, Toggle } from 'gantri-components';
import { ContentSectionProps } from './content-section-types';

export const ContentSection: FC<ContentSectionProps> = (props) => {
  const { bio, nameIsVisible, setBio, setNameIsVisible } = props;
  const maxLength = 360;

  return (
    <>
      <Typography
        marginBottom="1rem"
        marginTop="4rem"
        text="Content"
        textStyle="bold"
        variant="h4"
      />
      <Grid
        alignItems="start"
        columns={{ lg: 2, md: 1, sm: 1 }}
        gap="2rem"
        marginBottom="2rem"
      >
        <Cell>
          <Typography
            marginBottom="1rem"
            text="Bio"
            textStyle="bold"
            variant="p2"
          />
          <TextArea
            maxLength={maxLength}
            minRows={4}
            placeholder="A few lines about the designer..."
            value={bio}
            onTextChange={setBio}
          />
          <Typography text={`${bio.length}/${maxLength} characters`} />
        </Cell>
        <Cell>
          <Typography
            marginBottom="1rem"
            text="Designer name visible"
            textStyle="bold"
            variant="p2"
          />
          <Toggle value={nameIsVisible} onSelected={setNameIsVisible} />
        </Cell>
      </Grid>
    </>
  );
};
