import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetAllProductsResponse } from './get-all.types';
import { getAllProductsQueriesObj } from './get-all';

export const useGetAllProducts = <TransformedData = GetAllProductsResponse>(
  props?: GenericFetchProps<never, GetAllProductsResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch all products.',
    queryObj: getAllProductsQueriesObj,
    ...props,
  });
};

export const useInvalidateGetAllProductsCache = () => {
  const invalidateGetAllProductsCache = useGetInvalidateQueryCache(
    getAllProductsQueriesObj,
  );

  return { invalidateGetAllProductsCache };
};
