import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetAvailableLocationsArgs,
  GetAvailableLocationsResponse,
} from './get-available-locations.types';

export const getAvailableLocations = (body: GetAvailableLocationsArgs) => {
  return axios.post<GetAvailableLocationsResponse>(
    `${coreApiUrl}/locations/search`,
    body,
  );
};
