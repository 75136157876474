import { GetRowProps } from 'gantri-components';
import { Job } from '../../api/jobs/jobs.types';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { dataAttrJobId } from '../../pages/jobs/jobs.constants';
import { getTableRowStatusFromAttentions } from '../get-table-row-status-from-attentions';

export const getJobsRowProps: GetRowProps<Job> = (row) => {
  const status = getTableRowStatusFromAttentions(row);

  return {
    'data-job-name': row.original.description,
    'data-job-step': row.original.step,
    'data-job-type': row.original.type,
    'data-linked-to': JOB_DETAILS_PANEL_LINKED_TO,
    [dataAttrJobId]: row.original.id,
    status,
  } as const;
};
