import { Grid, TextArea } from 'gantri-components';

export const IssueFailureDetails = (props) => {
  const { onTextChange, option } = props;

  return (
    <Grid style={{ marginLeft: '8rem', paddingLeft: '0.8rem' }}>
      <TextArea
        placeholder="Add details about the issue"
        value={option}
        onTextChange={onTextChange}
      />
    </Grid>
  );
};
