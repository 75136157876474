import { DatumValue } from '@nivo/core';
import { getTicksEveryXDays } from '../get-ticks-every-x-days';
import { GetTickValuesToShowArgs } from './get-tick-values-to-render.types';

/** Logic is for `x` axis only. `x` values must be dates. */
export const getTickValuesToRender = (args: GetTickValuesToShowArgs) => {
  const { activeChartDetails, rangeType } = args;
  const dataPoints = activeChartDetails?.[0]?.data;

  if (!dataPoints) {
    return [];
  }

  const numDataPoints = dataPoints.length;
  const isCustom = rangeType === 'Custom';
  const isPast3Months = rangeType === 'Past 3 months';

  if ((isCustom && numDataPoints > 30) || isPast3Months) {
    const tickValuesToRender: DatumValue[] = [];

    dataPoints.forEach(({ x: value }, index) => {
      const dayOfMonth = new Date(value).getDate();
      const lastIndex = numDataPoints - 1;

      if (dayOfMonth === 1 || (isPast3Months && index === lastIndex)) {
        tickValuesToRender.push(value);
      }
    });

    return tickValuesToRender;
  }

  const tickValuesToRender: DatumValue[] = [];
  const ticksEveryXDays = getTicksEveryXDays(numDataPoints);

  for (let i = 0; i <= numDataPoints; i += ticksEveryXDays) {
    if (i === numDataPoints) {
      const value = dataPoints[numDataPoints - 1]?.x;

      tickValuesToRender.push(value);
    } else {
      const value = dataPoints[i]?.x;

      tickValuesToRender.push(value);
    }
  }

  return tickValuesToRender;
};
