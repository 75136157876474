import { capitalize } from 'lodash';
import { downloadTypes } from '../../../constants/options';
import { downloadsPageAtoms } from './downloads-filter.atoms';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

export const DownloadsFilter = () => {
  const downloadTypeItems = downloadTypes.map((value) => {
    return { label: capitalize(value), value };
  });

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={downloadsPageAtoms.filters.type}
          default={downloadsPageAtoms.defaults.filters.type}
          hideReset
          text="Type"
        />
        <AtomsCheckboxList
          atom={downloadsPageAtoms.filters.type}
          items={downloadTypeItems}
        />
      </FilterRow>
    </FilterGrid>
  );
};
