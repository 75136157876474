import { Conditional, Stack } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../print-prepare-queue-modal.constants';
import { DetailsPanelSetSection } from './components/details-panel-set-section';
import { DetailsPanelQueueSection } from './components/details-panel-queue-section';
import { DetailsPanelPartsSection } from './components/details-panel-parts-section';

export const PrintPrepareQueueDetailsPanel = () => {
  const step = useRecoilValue(printPrepareQueueModalAtoms.step);

  const isQueueFinished = [printPrepareQueueModalSteps.queueDeleted].some(
    (hiddenStep) => {
      return hiddenStep === step;
    },
  );

  return (
    <Stack gap="4x" height="unset">
      <DetailsPanelQueueSection />
      <Conditional condition={!isQueueFinished}>
        <DetailsPanelSetSection />
        <DetailsPanelPartsSection />
      </Conditional>
    </Stack>
  );
};
