import { Typography } from 'gantri-components';
import {
  StyledDay,
  StyledDaysWrapper,
  StyledDayValue,
  StyledHeader,
  StyledNavIcon,
  StyledPopup,
  StyledResetWrapper,
  StyledWeekDay,
  StyledWeekDayHeader,
} from './date-picker-dialog.styles';
import { DatePickerDialogProps } from './date-picker-dialog.types';

export const DatePickerDialog = (props: DatePickerDialogProps) => {
  const {
    daysInMonth,
    formattedMonth,
    getDayStatus,
    goToNextMonth,
    goToPreviousMonth,
    isNextMonthActive,
    isPreviousMonthActive,
    resetDates,
    setDay,
  } = props;

  return (
    <StyledPopup>
      <StyledHeader>
        <StyledNavIcon
          $isEnabled={isPreviousMonthActive}
          color="t2"
          cursor="pointer"
          name="arrows:arrow_chevron_left"
          size="1.6rem"
          onClick={goToPreviousMonth}
        />
        <Typography text={formattedMonth} variant="p2" />
        <StyledNavIcon
          $isEnabled={isNextMonthActive}
          color="t2"
          cursor="pointer"
          name="arrows:arrow_chevron_right"
          size="1.6rem"
          onClick={goToNextMonth}
        />
      </StyledHeader>

      <div>
        <StyledWeekDayHeader>
          <StyledWeekDay>Su</StyledWeekDay>
          <StyledWeekDay>Mo</StyledWeekDay>
          <StyledWeekDay>Tu</StyledWeekDay>
          <StyledWeekDay>We</StyledWeekDay>
          <StyledWeekDay>Th</StyledWeekDay>
          <StyledWeekDay>Fr</StyledWeekDay>
          <StyledWeekDay>Sa</StyledWeekDay>
        </StyledWeekDayHeader>
        <StyledDaysWrapper>
          {daysInMonth.map((day) => {
            const { disabled, inRange, selected } = getDayStatus(day);

            return (
              <StyledDay
                key={day}
                $inRange={inRange}
                $selected={selected}
                data-date-picker-day={day}
                data-in-range={inRange || undefined}
                data-selected={selected || undefined}
                disabled={disabled}
                onClick={() => {
                  return day > 0 && setDay(day);
                }}
              >
                {day > 0 && <StyledDayValue>{day}</StyledDayValue>}
              </StyledDay>
            );
          })}
        </StyledDaysWrapper>
        <StyledResetWrapper>
          <Typography
            align="center"
            color="link"
            text="Reset"
            onClick={resetDates}
          />
        </StyledResetWrapper>
      </div>
    </StyledPopup>
  );
};
