import {
  Cell,
  Conditional,
  Dropdown,
  generateSku,
  getColorsByProduct,
  getSkuDetails,
  Grid,
  ProductColorCode,
  ProductColorDetail,
  Stack,
  Typography,
  TypographyColor,
} from 'gantri-components';
import { flatten, isEqual } from 'lodash';
import { Fragment, PropsWithChildren } from 'react';
import { useRecoilValue } from 'recoil';
import { useHandleSetSelectedSku } from '../../hooks/use-handle-set-selected-sku';
import { productAtoms } from '../../product.atoms';
import { ColorSwatchDropdown } from '../../../../components/common/color-swatch-dropdown';

interface ProductSkuDropdownProps {
  showSelectedSku?: boolean;
}

export const ProductSkuDropdown = (props: ProductSkuDropdownProps) => {
  const { showSelectedSku } = props;
  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);
  const { handleSetSelectedSku } = useHandleSetSelectedSku();

  const skuDetails = getSkuDetails({
    sku: selectedSku || '',
    skuPartialCodes: product.skuPartialCodes,
  });

  const { color, variantPartials, variants } = skuDetails;

  const getTextColorForProductColor = (colorCode: ProductColorCode) => {
    const isColorOnMarketplace = product.colors.some(({ code }) => {
      return code === colorCode;
    });

    const textColor: TypographyColor = isColorOnMarketplace ? 'link' : 't1';

    return textColor;
  };

  const colorItems: (ProductColorDetail & { textColor: string })[] =
    getColorsByProduct({
      allowTradeColors: true,
      isPainted: product.isPainted,
      productId: product.id,
    }).map((colorItem) => {
      const textColor = getTextColorForProductColor(colorItem.code);

      return { ...colorItem, textColor };
    });

  const isColorDisabled = !colorItems.length;

  const getOnVariantSelect = (props: {
    partialIndex: number;
    variantIndex: number;
  }) => {
    const { partialIndex, variantIndex } = props;

    return (item: { code: string }) => {
      const newPartialCodes = variantPartials[variantIndex].map(
        (partial, index) => {
          if (partialIndex === index) {
            return item.code;
          }

          return partial;
        },
      );

      const newVariants = variants.map((fullVariantCode, index) => {
        if (variantIndex === index) {
          const { code: newFullVariantCode } = product.skuPartialCodes[
            variantIndex
          ].find(({ partialCodes }) => {
            return isEqual(partialCodes, newPartialCodes);
          });

          return newFullVariantCode;
        }

        return fullVariantCode;
      });

      const selectedSku = generateSku({
        ...skuDetails,
        variants: newVariants,
      });

      handleSetSelectedSku(selectedSku);
    };
  };

  const hasVariants = !!product.selectors?.length;

  const onSelect = (item: ProductColorDetail) => {
    const selectedSku = generateSku({
      ...skuDetails,
      color: item?.code,
    });

    handleSetSelectedSku(selectedSku);
  };

  return (
    <Grid
      alignItems={hasVariants ? 'end' : 'center'}
      columns={`max-content repeat(${
        (product.colors ? 1 : 0) + flatten(product.selectors || []).length
      }, ${hasVariants ? '20rem' : '25rem'}) ${
        showSelectedSku ? 'max-content' : ''
      }`}
      gap="x"
    >
      <Foo hasVariants={hasVariants}>
        <Typography text="SKU" />
      </Foo>

      <Cell>
        <ColorSwatchDropdown
          disabled={isColorDisabled}
          idProperty="code"
          items={colorItems}
          labelPosition={hasVariants ? 'top' : 'left'}
          labelProperty="shortColorName"
          labelText="Color"
          value={color}
          onSelect={onSelect}
        />
      </Cell>

      {product.selectors?.map((variantSelectors, variantIndex) => {
        return variantSelectors.map(({ label, options }, partialIndex) => {
          const onVariantSelect = getOnVariantSelect({
            partialIndex,
            variantIndex,
          });

          return (
            <Fragment key={label}>
              <Cell>
                <Dropdown
                  disabled={!options.length}
                  idProperty="code"
                  items={options}
                  labelProperty="name"
                  labelText={label}
                  value={variantPartials[variantIndex][partialIndex]}
                  onSelect={onVariantSelect}
                />
              </Cell>
            </Fragment>
          );
        });
      })}

      <Conditional condition={showSelectedSku}>
        <Foo hasVariants={hasVariants}>
          <Typography color="t2" text={`SKU: ${selectedSku || '-'}`} />
        </Foo>
      </Conditional>
    </Grid>
  );
};

const Foo = (props: PropsWithChildren<{ hasVariants: boolean }>) => {
  const { children, hasVariants } = props;

  return (
    <Conditional condition={hasVariants} Fallback={children}>
      <Stack flow="column" rows="1fr 3.6rem">
        <Cell />
        {children}
      </Stack>
    </Conditional>
  );
};
