import { GetShouldClearDropdownArgs } from './get-should-clear-dropdown.types';

/** Checks if the current value matches the available options. */
export const getShouldClearDropdown = (args: GetShouldClearDropdownArgs) => {
  const { options, value: selectedValue } = args;

  const valueMatchesOptions = options.some(({ value }) => {
    return value === selectedValue;
  });

  const clearField = !!selectedValue && !valueMatchesOptions;

  return clearField;
};
