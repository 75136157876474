import { Cell, Typography } from 'gantri-components';
import DatePicker from '../../../../../../../../components/common/date-picker';
import { Label } from '../../../../../../../../components/label';
import { formatDate } from '../../../../../../../../helpers/formatter';
import { DateCellProps } from './date-cell.types';

export const DateCell = (props: DateCellProps) => {
  const {
    editedShipment,
    getOnDateChanged,
    initialValue,
    isEditing,
    keyName,
    label,
    ...rest
  } = props;

  const value = editedShipment[keyName];

  const isFormattedDate = /^\d+\/\d+\/\d+$/.test(value);

  const formattedDate: string =
    value && (isFormattedDate ? value : formatDate(value));

  return (
    <Cell>
      <Label paddingBottom=".5x" text={label} />
      {isEditing ? (
        <DatePicker
          allowFutureDays
          autoWidth
          initialValue={initialValue || { from: value }}
          placeholder="00/00/0000"
          onDateChanged={getOnDateChanged(keyName)}
          {...rest}
        />
      ) : (
        <Typography text={value ? formattedDate : 'TBD'} />
      )}
    </Cell>
  );
};
