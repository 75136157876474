import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import {
  FetchPaginatedDesignsArgs,
  FetchPaginatedDesignsResponse,
} from './fetch-paginated-designs.types';
import { fetchPaginatedDesigns } from './fetch-paginated-designs';

const fetchPaginatedDesignsQueryObj = getGenericQueryObj({
  groupName: 'paginated-designs',
  query: fetchPaginatedDesigns,
});

export const useFetchPaginatedDesigns = <
  TransformedData = FetchPaginatedDesignsResponse,
>(
  props?: GenericFetchProps<
    FetchPaginatedDesignsArgs,
    FetchPaginatedDesignsResponse,
    TransformedData
  >,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch designs.',
    queryObj: fetchPaginatedDesignsQueryObj,
    ...props,
  });
};

export const useInvalidateFetchPaginatedDesignsCache = () => {
  const invalidateFetchPaginatedDesignsCache = useGetInvalidateQueryCache(
    fetchPaginatedDesignsQueryObj,
  );

  return { invalidateFetchPaginatedDesignsCache };
};
