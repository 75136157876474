import {
  Conditional,
  Flex,
  FormikInput,
  Icon,
  TextField,
  Tooltip,
  Typography,
} from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useGetAvailableJobsForPrintPrepareQueue } from '../../../../../../../api/queue/print-prepare';
import { createPrintPrepareQueueModalAtoms } from '../../create-print-prepare-queue-modal.atoms';

export const CreatePrintPrepareQueueModalContent = () => {
  const [availableJobs, setAvailableJobs] = useRecoilState(
    createPrintPrepareQueueModalAtoms.availableJobs,
  );

  const { available, isLoading: processingGetAvailableJobs } =
    useGetAvailableJobsForPrintPrepareQueue();

  useEffect(() => {
    setAvailableJobs(available || 0);
  }, [available]);

  return (
    <>
      <Typography text="Enter number of jobs to add to print prepare queue." />
      <Flex alignItems="flex-start" gap="x">
        <FormikInput
          Field={
            <TextField
              autoFocus={!processingGetAvailableJobs}
              disabled={processingGetAvailableJobs}
              placeholder="Number of jobs"
              type="number"
            />
          }
          fieldVariant="standard"
          name="amount"
        />
        <Typography paddingTop=".5x" text="/" />
        <Flex alignItems="center" gap=".5x" paddingTop=".5x" width="auto">
          <Conditional
            condition={processingGetAvailableJobs}
            Fallback={
              <Typography
                display="inline"
                iconPosition="left"
                text={availableJobs}
              />
            }
          >
            <Icon name="animated:loader" />
          </Conditional>
          <Typography
            icon={
              <Tooltip
                maxWidth="22.5rem"
                position="top"
                title="'Ready' or 'In-progress' Print Prepare jobs that do not belong to any other queues."
              >
                <Icon name="alert:i_circle" />
              </Tooltip>
            }
            iconPosition="right"
            text="available jobs"
            whiteSpace="nowrap"
          />
        </Flex>
      </Flex>
    </>
  );
};
