import { useMemo, useState } from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Checkbox, Flex, Typography } from 'gantri-components';
import styles from '../styles.module.scss';
import FlexContainer from '../../layout/flex-container';
import StackedContainer from '../../common/stacked-container';
import Select from '../../common/inputs/select';
import { formatCurrency } from '../../../helpers/formatter';
import { StyledReassignSelectorContainer } from './reassign-stock-record.styles';
import { ShortProductSummary } from '../../common/short-product-summary';

export const ReasonStockRecord = ({
  idleStocks,
  onChange,
  selectedStocks,
  stock,
}) => {
  const [checked, setChecked] = useState(false);
  const [selectedStock, setSelectedStock] = useState(-1);

  const usedStocks = useMemo(() => {
    return Object.keys(selectedStocks).reduce((acc, current) => {
      return {
        ...acc,
        [selectedStocks[+current]?.newStockData?.id]: {
          newStock: selectedStocks[+current]?.newStockData.id,
          oldStock: selectedStocks[+current]?.oldStockData.id,
        },
      };
    }, {});
  }, [selectedStocks]);

  const handleSelectedReason = (idleStock) => {
    setChecked(true);
    setSelectedStock(idleStock);

    onChange(
      true,
      stocksList.find((stock) => {
        return stock.id === +idleStock;
      }),
      stock,
    );
  };

  const stocksList = useMemo(() => {
    return (idleStocks || []).filter((record) => {
      return (
        !usedStocks[record.id] || usedStocks[record.id].oldStock === stock.id
      );
    });
  }, [usedStocks, stock, idleStocks]);

  const handleCheck = (value) => {
    setChecked(value);

    if (value) {
      onChange(
        true,
        stocksList.find((stock) => {
          return stock.id === +selectedStock;
        }),
        stock,
      );
    } else {
      setSelectedStock(-1);
      onChange(false, null, stock);
    }
  };

  return (
    <FlexContainer
      alignItems="flex-start"
      className={styles.stock}
      justifyContent="space-between"
      marginTop="60px"
    >
      <Flex gap="2rem">
        <Checkbox
          checked={checked}
          disabled={!stocksList || !stocksList.length || selectedStock === -1}
          onSelected={handleCheck}
        />

        <ShortProductSummary fetchSku={stock.product.sku} imageSize="8rem">
          <StackedContainer gap="unset">
            <Typography color="t2" text={stock.product.designer} />
            <StackedContainer
              alignItems="center"
              flow="column"
              gap="10px"
              marginTop="10px"
            >
              <StyledReassignSelectorContainer>
                Re-assign stock from{' '}
                <Link to={`/stocks/${stock.id}`}>#{stock.id}</Link> to
              </StyledReassignSelectorContainer>

              <Select
                classnames={cx(styles.reassignSelect, {
                  [styles['reason-select--placeholder']]: selectedStock === -1,
                })}
                value={selectedStock}
                onChange={handleSelectedReason}
              >
                <option disabled value={-1}>
                  Select stock
                </option>
                {(stocksList || []).map((idleStock, i) => {
                  return (
                    <option key={i} value={idleStock.id}>
                      #{idleStock.id} ({idleStock.status})
                    </option>
                  );
                })}
              </Select>
            </StackedContainer>
          </StackedContainer>
        </ShortProductSummary>
      </Flex>

      <FlexContainer alignItems="flex-end" direction="column">
        <Typography
          color="t2"
          text={formatCurrency(stock.product.amount.subtotal)}
          textStyle="regular"
          variant="p2"
        />
        <Typography
          color="t2"
          text={stock.status}
          textStyle="regular"
          variant="p2"
        />
      </FlexContainer>
    </FlexContainer>
  );
};
