import { ChecklistItem } from '../../../../job-checklist.types';
import { getSurfacesStatus } from '../get-surfaces-status';

export const getIsChecklistItemIncomplete = (
  checklistItem: ChecklistItem,
): boolean => {
  const {
    details,
    ratingSecondarySurface,
    requireDetailsOnFail,
    severityImages,
    slider,
    status,
  } = checklistItem;

  if (!status) {
    // handles toggles and "other" failure reason
    return true;
  }

  if (!!slider || !!severityImages) {
    const { isPrimarySurfacePassing } = getSurfacesStatus(checklistItem);

    if (isPrimarySurfacePassing && !ratingSecondarySurface) {
      return true;
    }
  }

  if (status !== 'Pass' && requireDetailsOnFail && !details) {
    return true;
  }

  return false;
};
