import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CompleteInventoryPurchaseArgs,
  CompleteInventoryPurchaseResponse,
} from './complete-inventory-purchase.types';

export const completeInventoryPurchase = ({
  id,
  ...body
}: CompleteInventoryPurchaseArgs) => {
  return axios.put<CompleteInventoryPurchaseResponse>(
    `${coreApiUrl}/inventory-purchases/${id}/complete`,
    body,
  );
};
