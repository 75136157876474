import { Form, Formik } from 'formik';
import { Button, Modal } from 'gantri-components';
import { machineStatuses } from '../../../../constants/machines';
import {
  useCreateMachine,
  useUpdateMachine,
} from '../../../../api/machines/routes';
import {
  addOrEditMachineSchema,
  machineInitialValues,
} from './add-or-edit-machine-modal.schema';
import {
  AddOrEditMachineFormData,
  AddOrEditMachineModalProps,
} from './add-or-edit-machine-modal.types';
import { AddOrEditMachineModalContent } from './components/add-or-edit-machine-modal-content';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';

export const AddOrEditMachineModal = (props: AddOrEditMachineModalProps) => {
  const { loadMachinesPage, machineToEdit, onClose } = props;

  const { onUpdateMachine } = useUpdateMachine({
    onSuccess: async () => {
      await loadMachinesPage();
    },
    showLoading: true,
  });

  const { onCreateMachine } = useCreateMachine({
    onSuccess: async () => {
      await loadMachinesPage({
        onlyClearRowsIfFilters: true,
        page: 1,
      });
    },
    showLoading: true,
  });

  const onSubmit = async (values: AddOrEditMachineFormData) => {
    const { id, status } = values;

    const active = status !== machineStatuses.offline;

    if (id) {
      await onUpdateMachine({
        ...values,
        active,
        editMode: true,
        machineId: id,
      });
    } else {
      await onCreateMachine({
        active,
        ...values,
      });
    }

    onClose();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={machineToEdit || machineInitialValues}
      validateOnChange
      validateOnMount
      validationSchema={addOrEditMachineSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, isValid, values }) => {
        const isEditing = !!values.id;

        return (
          <Form>
            <Modal
              footer={
                <SimpleErrorBoundary>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    processing={isSubmitting}
                    size="large"
                    text="Save"
                    type="submit"
                  />
                </SimpleErrorBoundary>
              }
              header={isEditing ? 'Edit Machine' : 'New Machine'}
              maxWidth={{ lg: '44rem', md: '100%' }}
              onClose={onClose}
            >
              <SimpleErrorBoundary>
                <AddOrEditMachineModalContent />
              </SimpleErrorBoundary>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
