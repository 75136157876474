import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import {
  GetJobDetailsArgs,
  GetJobDetailsResponse,
} from './get-job-details.types';

export const getJobDetails = (jobId: number) => {
  return axios
    .get<GetJobDetailsResponse>(`${coreApiUrl}/jobs/${jobId}/details`)
    .then((response) => {
      return response.data;
    });
};

const query = ({ jobId }: GetJobDetailsArgs) => {
  return getJobDetails(jobId);
};

export const fetchJobDetails = getGenericQueryObj({
  groupName: 'job-details',
  query,
});
