import { ChangeEvent, memo, useMemo, useState } from 'react';
import {
  Badge,
  Conditional,
  Flex,
  Grid,
  Table,
  TextField,
  Typography,
  useConfirmationModal,
} from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { StyledCollectionPageSection } from './collection-pages.styles';
import { CollectionPageProps } from './collection-pages.types';
import routePaths from '../../../../../../config/route-paths';
import { formatDate } from '../../../../../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../../../../../constants/dates';
import { useRouter } from '../../../../../../hooks';
import { useGetInvalidateQueryCache } from '../../../../../../hooks/use-fetch/use-query-fetch';
import { getDesigner } from '../../../../../../api/designers/routes';
import { useCreateDesignerFeature } from '../../../../../../api/designers/routes/create-feature-settings/create-feature-settings.query';
import { useDeleteDesignerFeature } from '../../../../../../api/designers/routes/delete-feature-settings/delete-feature-settings.query';
import { CollectionPage } from '../../../../designer.types';
import { StyledAnchor } from '../../../../../../components/common/styled-anchor';
import { MoreMenuCell } from './components/more-menu-cell';
import { getMantleUrl } from '../../../../../../helpers/get-mantle-url';
import { getIsClickableIfValue } from '../../../../../../helpers/get-is-clickable-if-value';

const Section = (props: CollectionPageProps) => {
  const { collectionPages, id, profileLink, updateDesignerInfo } = props;

  const { navigate } = useRouter();

  const [title, setTitle] = useState<string>('');
  const [collectionName, setCollectionName] = useState<string>('');
  const [collectionId, setCollectionId] = useState<number>(null);

  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const invalidateDesignerQueryCache = useGetInvalidateQueryCache(getDesigner);

  const { onCreateDesignerFeature } = useCreateDesignerFeature();

  const onCreateNewCollectionPage = async () => {
    await onCreateDesignerFeature(
      {
        designerId: id.toString(),
        name: title,
      },
      {
        onSuccess: async ({ featureId }) => {
          navigate(`${routePaths.designers}/${id}/feature-page/${featureId}`);

          await invalidateDesignerQueryCache();
        },
      },
    );
  };

  const onDeleteOptionClick = (name: string, id: number) => {
    setCollectionName(name);
    setCollectionId(id);
    showDeleteModal();
  };

  const { onDeleteDesignerFeature } = useDeleteDesignerFeature();

  const onDeleteCollectionPage = async () => {
    await onDeleteDesignerFeature(
      {
        designerId: id,
        featureId: collectionId,
      },
      {
        onSuccess: async () => {
          updateDesignerInfo({
            key: 'collectionPages',
            type: 'key',
            value: collectionPages.filter((feature) => {
              return feature.id !== collectionId;
            }),
          });
          await invalidateDesignerQueryCache();

          setCollectionName('');
          setCollectionId(null);
          hideDeletePageModal();
        },
      },
    );
  };

  const [showCreateCollectionPageModal, hideCreateCollectionPageModal] =
    useConfirmationModal({
      confirmButtonIsDisabled: !title,
      content: (
        <TextField
          labelPosition="top"
          labelText="Name"
          placeholder="Title..."
          value={title}
          onChange={onTitleChange}
        />
      ),
      headerText: 'Create page',
      onClose: () => {
        hideCreateCollectionPageModal();
      },
      onConfirm: async () => {
        await onCreateNewCollectionPage();
      },
      width: { lg: '40rem', md: 'auto' },
    });

  const [showDeleteModal, hideDeletePageModal] = useConfirmationModal({
    confirmButtonText: 'Delete',
    confirmButtonVariant: 'primaryAlert',
    content: (
      <Typography
        align="center"
        htmlText={`Are you sure you wish to delete page "${collectionName}".<br>
        This action can't be undone.`}
      />
    ),
    headerText: 'Delete page',
    onClose: () => {
      hideDeletePageModal();
      setCollectionName('');
    },
    onConfirm: async () => {
      await onDeleteCollectionPage();
    },
    width: { lg: '40rem', md: 'auto' },
  });

  const columns = useMemo(() => {
    const mantleUrl = getMantleUrl();

    const columns: ColumnDef<CollectionPage>[] = [
      {
        accessorKey: 'name',
        cell: ({
          getValue,
          row,
        }: CellContext<CollectionPage, CollectionPage['name']>) => {
          return (
            <StyledAnchor
              text={getValue()}
              to={`${routePaths.designers}/${id}/feature-page/${row.original.id}`}
            />
          );
        },
        header: 'Title',
        meta: {
          getIsClickable: getIsClickableIfValue,
        },
        size: 330,
      },
      {
        accessorKey: 'pageUrl',
        cell: ({
          getValue,
          row,
        }: CellContext<CollectionPage, CollectionPage['pageUrl']>) => {
          const pageUrl = getValue();

          return (
            <Conditional
              condition={!!pageUrl && row.original.status === 'published'}
              Fallback={<Typography color="t2" text={pageUrl} />}
            >
              <StyledAnchor
                target="_blank"
                text={pageUrl}
                to={`${mantleUrl}/${profileLink}/${pageUrl}`}
              />
            </Conditional>
          );
        },
        header: 'Url',
        meta: {
          getIsClickable: (
            props: Cell<CollectionPage, CollectionPage['pageUrl']>,
          ) => {
            const { row } = props;

            return (
              getIsClickableIfValue(props) &&
              row.original.status === 'published'
            );
          },
        },
        size: 330,
      },
      {
        accessorKey: 'createdAt',
        cell: ({
          getValue,
        }: CellContext<CollectionPage, CollectionPage['createdAt']>) => {
          return <Typography text={formatDate(getValue(), MEDIUM_FORMAT)} />;
        },
        header: 'Date Created',
      },
      {
        accessorKey: 'status',
        cell: ({
          getValue,
        }: CellContext<CollectionPage, CollectionPage['status']>) => {
          const status = getValue();

          return (
            <Badge
              color={status === 'published' ? 'green_400' : 'monochrome_400'}
              text={status}
              variant="secondary"
            />
          );
        },
        header: 'Status',
      },
      {
        cell: (props: CellContext<CollectionPage, never>) => {
          return (
            <MoreMenuCell {...props} handleOnDelete={onDeleteOptionClick} />
          );
        },
        id: 'menu',
        size: 30,
      },
    ];

    return columns;
  }, []);

  return (
    <StyledCollectionPageSection>
      <Flex marginBottom="2x">
        <Typography text="Collection pages" textStyle="bold" variant="h4" />
        <StyledAnchor
          marginLeft="1.5rem"
          marginTop="8px"
          text="Add a page"
          onClick={showCreateCollectionPageModal}
        />
      </Flex>
      <Grid columns={1} gap="2x">
        {!!collectionPages?.length && (
          <Table columns={columns} data={collectionPages} />
        )}
      </Grid>
    </StyledCollectionPageSection>
  );
};

export const CollectionPages = memo(Section);
