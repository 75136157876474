import styled, { css } from 'styled-components';

export const StyledThumbnailWrapper = styled.div`
  ${({ theme }) => {
    return css`
      > div {
        border: 1px solid ${theme.colors.dividers.t2};
      }
    `;
  }}
`;
