import { CellContext } from '@tanstack/react-table';
import { ReportStocksGroupBy } from '../../../../api/dashboard/routes/get-report-stocks/get-report-stocks.types';
import { getSortByFilterAtomFamily } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { NumberCell } from '../../../dashboard/components/common/number-cell';
import { PercentCell } from '../../../dashboard/components/common/percent-cell';
import { reportPageSortByDefaults } from '../common/report-page/components/report-table/report-table.constants';
import { DownloadableTableColumn } from '../common/report-page/components/report-table/report-table.types';
import { getSortingOptionsFromColumns } from '../common/report-page/helpers/get-sorting-options-from-columns';
import { ReportStocksTableData } from './report-stocks.types';

export const defaultStocksGroupBy: ReportStocksGroupBy = 'productId';

export const stocksBaseTableColumns: DownloadableTableColumn<ReportStocksTableData>[] =
  [
    {
      accessorKey: 'created',
      cell: ({
        getValue,
      }: CellContext<
        ReportStocksTableData,
        ReportStocksTableData['created']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Created',
    },
    {
      accessorKey: 'completed',
      cell: ({
        getValue,
      }: CellContext<
        ReportStocksTableData,
        ReportStocksTableData['completed']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Completed',
    },
    {
      accessorKey: 'cancelled',
      cell: ({
        getValue,
      }: CellContext<
        ReportStocksTableData,
        ReportStocksTableData['cancelled']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Cancelled',
    },
    {
      accessorKey: 'onTimeCompletion',
      cell: ({
        getValue,
      }: CellContext<
        ReportStocksTableData,
        ReportStocksTableData['onTimeCompletion']
      >) => {
        return <PercentCell value={getValue()} />;
      },
      header: 'On-time Completion %',
    },
    {
      accessorKey: 'leadTime',
      cell: ({
        getValue,
      }: CellContext<
        ReportStocksTableData,
        ReportStocksTableData['leadTime']
      >) => {
        return <NumberCell value={getValue()} />;
      },
      header: 'Lead Time (hr)',
    },
  ];

export const stocksBaseSortingOptions = getSortingOptionsFromColumns(
  stocksBaseTableColumns,
);

export const pageName: AtomPageName = 'report-stocks';

export const reportStocksPageAtoms = {
  sortBy: getSortByFilterAtomFamily<SortBy<keyof ReportStocksTableData>>({
    defaultValue: reportPageSortByDefaults,
  })(pageName),
};
