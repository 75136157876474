import { FC } from 'react';
import { Flex, Toggle, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { FilterProps } from '..';
import { referralsPageAtoms } from './referrals-filter.atoms';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

const ReferralsFilter: FC<FilterProps> = () => {
  const [purchased, setPurchased] = useRecoilState(
    referralsPageAtoms.filters.purchased,
  );

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={referralsPageAtoms.filters.purchased}
          default={referralsPageAtoms.defaults.filters.purchased}
          hideReset
          text="Status"
        />
        <Flex alignItems="center" gap="x">
          <Typography text="Show All" />
          <Toggle name="show-all" value={purchased} onSelected={setPurchased} />
          <Typography text="Purchased" />
        </Flex>
      </FilterRow>
    </FilterGrid>
  );
};

export default ReferralsFilter;
