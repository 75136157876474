import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from 'gantri-components';
import { rejectJobModalAtoms } from '../../../reject-job-modal.atoms';
import { RejectReasonFooterProps } from './reject-reason-footer.types';

export const RejectReasonFooter = (props: RejectReasonFooterProps) => {
  const { onClose } = props;

  const setStep = useSetRecoilState(rejectJobModalAtoms.step);
  const rejectReason = useRecoilValue(rejectJobModalAtoms.rejectReason);
  const rejectDetails = useRecoilValue(rejectJobModalAtoms.rejectDetails);

  return (
    <>
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onClose}
      />
      <Button
        disabled={!rejectReason || !rejectDetails}
        size="large"
        text="Next"
        variant="primaryAlert"
        onClick={() => {
          setStep('NEXT_STEP');
        }}
      />
    </>
  );
};
