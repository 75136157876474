import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { ConfirmBeginFooterProps } from './confirm-begin-footer.types';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { jobsApi } from '../../../../../../../../../api';

export const ConfirmBeginFooter = (props: ConfirmBeginFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const setStep = useSetRecoilState(completeSandJobModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeSandJobModalAtoms.updateOnClose,
  );

  const handleConfirmBegin = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        await jobsApi.startJob({ jobId: job.id });

        setUpdateOnClose(true);
        setStep('COMPLETE_CHECKLIST');
      } catch (error: unknown) {
        notifyAxiosError({ error, fallbackMessage: 'Unable to start job.' });
      }
    });
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleConfirmBegin}
      />
    </>
  );
};
