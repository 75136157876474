import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardApi } from '../../../../api';
import { GetReportMachinesArgs } from '../../../../api/dashboard/routes/get-report-machines/get-report-machines.types';
import { getActiveQueries } from '../../../../helpers/checks';
import { DashboardChartProps } from '../../../dashboard/components/common/dashboard-chart/dashboard-chart.types';
import { dashboardAndReportAtoms } from '../../../dashboard/dashboard.atoms';
import { ReportPage } from '../common/report-page';
import {
  GetReportData,
  ReportProps,
} from '../common/report-page/report-page.types';
import {
  getReportDetails,
  getRowProps,
  getBreadcrumbs,
  getTitle,
} from './helpers';
import {
  defaultMachinesGroupBy,
  pageName,
  reportMachinesPageAtoms,
} from './report-machines.constants';
import { ReportMachinesQueries } from './report-machines.types';

export const ReportMachines = ({ rootTitle }: ReportProps) => {
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const {
    activeChart: activeChartQuery,
    groupBy = defaultMachinesGroupBy,
    machineId,
    machineType,
  } = getActiveQueries<ReportMachinesQueries>();
  const [activeChart, setActiveChart] = useState<string>(activeChartQuery);
  const activeChartIsPercent = ['offline', 'utilization'].includes(activeChart);

  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] =
    activeChartIsPercent
      ? (value) => {
          return `${value}%`;
        }
      : undefined;
  const axisLeft: DashboardChartProps['axisLeft'] = activeChartIsPercent
    ? {
        format: (y: number) => {
          return `${y}%`;
        },
      }
    : undefined;
  const {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  } = getReportDetails();

  const getReportData: GetReportData = ({ endDate, startDate }, configs) => {
    const args: GetReportMachinesArgs = {
      endDate,
      groupBy,
      machineId: machineId ? +machineId : undefined,
      machineType,
      rangeType,
      startDate,
    };

    return dashboardApi.getReportMachines(args, configs);
  };

  return (
    <ReportPage
      activeChart={activeChart}
      axisLeft={axisLeft}
      breadcrumbs={getBreadcrumbs()}
      extraChartLeftMargin={activeChartIsPercent ? 30 : undefined}
      getReportData={getReportData}
      getRowProps={getRowProps}
      groupByIsVisible={groupByIsVisible}
      groupByItems={groupByItems}
      groupByOnChange={groupByOnChange}
      groupByValue={groupBy}
      pageName={pageName}
      setActiveChart={setActiveChart}
      sortByAtom={reportMachinesPageAtoms.sortBy}
      sortingFields={sortingOptions}
      tableColumns={tableColumns}
      title={getTitle(rootTitle)}
      transformTooltipValue={transformTooltipValue}
    />
  );
};
