import { useEffect } from 'react';
import { Modal } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { CompletePrintModalProps } from './complete-print-modal.types';
import { CompletePrintFooter } from './components/complete-print-footer';
import { CompletePrintContent } from './components/complete-print-content';
import { completePrintModalAtoms } from './complete-print-modal.atoms';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

export const CompletePrintModal = (props: CompletePrintModalProps) => {
  const { job, onClose, onJobUpdated } = props;

  const setMachine = useSetRecoilState(completePrintModalAtoms.machine);
  const updateOnClose = useRecoilValue(completePrintModalAtoms.updateOnClose);

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    completePrintModalAtoms,
  );

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  useEffect(() => {
    setMachine(job.machine);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={job}
            machineName={job?.machine?.name}
            sectionsToShow={[
              'part',
              'color & material',
              'product',
              'machine',
              'instructions',
              'part diagram',
            ]}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <CompletePrintFooter handleCloseModal={handleCloseModal} job={job} />
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <CompletePrintContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
