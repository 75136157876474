import { Table } from 'gantri-components';
import styled, { css, CSSProperties } from 'styled-components';

export const dataAttrTotalRow = 'data-total-row';

export const dataAttrActiveChart = 'data-active-chart';

export const StyledReportTableWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  min-height: 20rem;
`;

export const StyledTable = styled(Table)<{
  $minWidth: CSSProperties['minWidth'];
  $tableDimensions: {
    height: number;
    width: number;
  };
}>`
  [data-table-container] {
    ${({ $tableDimensions }) => {
      return css`
        max-width: ${$tableDimensions.width}px;
        width: 100%;
      `;
    }}
  }

  table {
    ${({ $minWidth }) => {
      return css`
        min-width: ${$minWidth};
      `;
    }}
  }

  th {
    * {
      min-width: 10rem !important; // !important to override inline styling
    }
  }

  td[${dataAttrActiveChart}] {
    ${({ theme }) => {
      return css`
        background-color: ${theme.colors.surfaces.green.t1};
      `;
    }}
  }

  [${dataAttrTotalRow}] * {
    font-weight: bold;
  }

  thead tr,
  tbody tr {
    position: relative;
  }
`;
