import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { FailReasonsCell } from '../common/fail-reasons-cell';
import { NumberCell } from '../common/number-cell';
import { ReportTabs } from '../common/reports-summary/report-summary.types';
import { PrintJobsTableData } from './dashboard-print-jobs-summary.types';
import { getYieldCellDetails } from '../../helpers/get-yield-cell-details';

export const printJobsTabs: ReportTabs = [
  { label: 'Total', value: 'total' },
  { label: 'GPLA', value: 'gpla' },
  { label: 'High-T', value: 'high-t' },
];

export const printJobsTableColumns: ColumnDef<PrintJobsTableData>[] = [
  {
    accessorKey: 'type',
    cell: (
      props: CellContext<PrintJobsTableData, PrintJobsTableData['type']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <Typography text={getValue()} />;
    },
    header: 'Jobs',
    size: 80,
  },
  {
    accessorKey: 'print',
    cell: (
      props: CellContext<PrintJobsTableData, PrintJobsTableData['print']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue, row } = props;
      const { 'print-fail-reasons': printFailReasons } = row.original;

      return (
        <FailReasonsCell failReasons={printFailReasons} value={getValue()} />
      );
    },
    header: 'Print',
    size: 80,
  },
  {
    accessorKey: 'print-qa',
    cell: (
      props: CellContext<PrintJobsTableData, PrintJobsTableData['print-qa']>,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue, row } = props;
      const { 'print-qa-fail-reasons': printQAFailReasons } = row.original;

      return (
        <FailReasonsCell failReasons={printQAFailReasons} value={getValue()} />
      );
    },
    header: 'Print QC',
    size: 80,
  },
  {
    accessorKey: 'print-worker',
    cell: (
      props: CellContext<
        PrintJobsTableData,
        PrintJobsTableData['print-worker']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Print / Worker',
    size: 80,
  },
  {
    accessorKey: 'print-qa-worker',
    cell: (
      props: CellContext<
        PrintJobsTableData,
        PrintJobsTableData['print-qa-worker']
      >,
    ) => {
      const { YieldCell, isYield } = getYieldCellDetails(props);

      if (isYield) {
        return <YieldCell />;
      }

      const { getValue } = props;

      return <NumberCell value={getValue()} />;
    },
    header: 'Print QC / Worker',
    size: 80,
  },
];
