import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import {
  UpdateProductJobBlocksArgs,
  UpdateProductJobBlocksResponse,
} from './update-product-job-blocks.types';
import { updateProductJobBlocks } from './update-product-job-blocks';

export const useUpdateProductJobBlocks = <
  TransformedData = UpdateProductJobBlocksResponse,
>(
  props?: GenericMutateQueryProps<
    UpdateProductJobBlocksArgs,
    UpdateProductJobBlocksResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateProductJobBlocks, ...rest } = useGenericMutateQuery(
    {
      fallbackErrorMessage: 'Unable to update product job blocks.',
      mutationFn: updateProductJobBlocks,
      ...props,
    },
  );

  return { ...rest, onUpdateProductJobBlocks };
};
