import {
  JobPassOrFailReasonDetails,
  ReasonDetails,
} from '../../../../../../api/jobs/routes/fail-job/fail-job.types';
import { FailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Material } from '../../../../../../constants/options/options.types';
import { Checklist } from '../../common/job-checklist/job-checklist.types';
import { getSurfacesStatus } from '../../common/job-checklist/components/advanced-checklist/helpers/get-surfaces-status';
import { getAdvancedInfoValue } from './helpers/get-advanced-info-value';

export const getFailJobReasonArg = <KeyType extends FailedReason>(
  checklist: Partial<Checklist<KeyType>>,
  /** If any of this information is available on the job, you should go ahead and include it. */
  advancedDetails?: { material: Material },
) => {
  const checklistReasons = Object.keys(checklist) as KeyType[];

  const reason = checklistReasons.reduce<JobPassOrFailReasonDetails<KeyType>>(
    (accumulator, reason) => {
      const checklistItem = checklist[reason];
      const {
        details,
        ratingPrimarySurface,
        ratingSecondarySurface,
        slider,
        status,
      } = checklistItem;

      const { isPrimarySurfaceFailing, isSecondarySurfaceFailing } =
        getSurfacesStatus(checklistItem);

      const value = getAdvancedInfoValue(checklistItem);

      const reasonDetails: ReasonDetails = {
        advancedInfo: {
          material: advancedDetails?.material,
          surface: !!slider
            ? {
                primary: {
                  status: isPrimarySurfaceFailing ? 'Fail' : 'Pass',
                  value: ratingPrimarySurface,
                },
                secondary: isPrimarySurfaceFailing
                  ? undefined
                  : {
                      status: isSecondarySurfaceFailing ? 'Fail' : 'Pass',
                      value: ratingSecondarySurface,
                    },
              }
            : undefined,
          value,
        },
        details,
        status,
      };

      const typedReasonSchema: JobPassOrFailReasonDetails<KeyType> = {
        ...accumulator,
        [reason]: reasonDetails,
      };

      return typedReasonSchema;
    },
    {} as JobPassOrFailReasonDetails<KeyType>,
  );

  return reason;
};
