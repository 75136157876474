import { InventoryDataCell } from '../../../../../custom-data-cells/inventory-data-cell';
import { useGetIsEditingRow } from '../../../use-get-is-editing-row';
import { useValidateFields } from '../job-template-type-cell/hooks';
import { JobTemplateInventoryCellProps } from './job-template-inventory-cell.types';

export const JobTemplateInventoryCell = (
  props: JobTemplateInventoryCellProps,
) => {
  const { getValue, onEdit, row } = props;

  const isEditingRow = useGetIsEditingRow(row);

  const { getHasError } = useValidateFields();
  const hasError = getHasError('inventoryRequests');

  return (
    <InventoryDataCell
      editing={isEditingRow}
      hasError={hasError}
      inventoryRequests={getValue()}
      onEdit={(inventoryRequests) => {
        onEdit({
          ...row.original,
          inventoryRequests,
        });
      }}
    />
  );
};
