export const isActivePath = (routePath: string) => {
  return window.location.pathname === routePath;
};

export const getFilenameFromURL = (url: string): string => {
  const decodedURL = decodeURIComponent(url);
  const cleanURL = decodedURL.split('?')[0].split('#')[0];

  const parts = cleanURL.split('/');
  let filename = parts[parts.length - 1];

  if (!filename && parts.length > 1) {
    filename = parts[parts.length - 2];
  }

  return filename || '';
};
