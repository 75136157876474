import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Button, Icon, Typography } from 'gantri-components';
import {
  StyledAlertIconWrapper,
  StyledAlertModalInfoContainer,
  StyledAlertModalStaticContainer,
  StyledCloseButton,
  StyledModalButtonsContainer,
  StyledModalTitleWrapper,
  StyledModalWrapper,
  StyledOuterContainer,
} from '../modals-styles.module';
import { useRouter } from '../../../hooks';

/**
 * @deprecated use useModal hook from gantri-components
 */
const AlertModal = ({
  children,
  confirmLink,
  confirmText,
  description,
  loading,
  onClose,
  onConfirm,
  title,
}) => {
  const { navigate } = useRouter();
  const location = useLocation();

  const visitLink = (link) => {
    navigate(link);

    if (location.pathname === link) {
      onClose();
    }
  };

  return (
    <StyledOuterContainer>
      {onClose && (
        <StyledCloseButton
          className="x-icon-white"
          role="button"
          tabIndex="0"
          onClick={onClose}
        />
      )}
      <StyledModalWrapper>
        <StyledAlertModalStaticContainer hasInfo={!!children}>
          <StyledAlertIconWrapper hasTitle={!!title}>
            <Icon color="alert" name="alert:warning_triangle" />
          </StyledAlertIconWrapper>
          {!!title && (
            <StyledModalTitleWrapper>
              <Typography
                align="center"
                color="alert"
                text={title}
                textStyle="bold"
                variant="h4"
              />
            </StyledModalTitleWrapper>
          )}
          {!!description &&
            (typeof description === 'string' ? (
              <Typography color="t1" text={description} />
            ) : (
              description.map((text) => {
                return <Typography key={text} color="t2" text={text} />;
              })
            ))}
        </StyledAlertModalStaticContainer>
        {!!children && (
          <StyledAlertModalInfoContainer>
            {children}
          </StyledAlertModalInfoContainer>
        )}
        <StyledModalButtonsContainer marginOverride={children ? '0' : '30px'}>
          {!!onClose && (
            <Button
              size="large"
              text="Done"
              variant="secondary"
              onClick={onClose}
            />
          )}
          {!!confirmLink ||
            (!!onConfirm && (
              <Button
                loading={loading}
                size="large"
                text={confirmText || 'View'}
                variant="primary"
                onClick={
                  confirmLink
                    ? () => {
                        return visitLink(confirmLink);
                      }
                    : onConfirm
                }
              />
            ))}
        </StyledModalButtonsContainer>
      </StyledModalWrapper>
    </StyledOuterContainer>
  );
};

AlertModal.propTypes = {
  children: PropTypes.node,
  confirmLink: PropTypes.string,
  confirmText: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
};

export default AlertModal;
