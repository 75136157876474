import { Stack, Table } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ModalContentHeading } from '../../../../../../jobs/components/modals/common/modal-content-heading';
import { newMachineIssuesModalAtoms } from '../../../new-machine-issues-modal.atoms';
import { useGetBulkUpdateIssuesColumns } from './hooks/get-bulk-update-issues-columns';

export const ReviewCreatedIssuesContent = () => {
  const detailsPanelMachines = useRecoilValue(
    newMachineIssuesModalAtoms.detailsPanelMachines,
  );
  const selectedIssues = useRecoilValue(
    newMachineIssuesModalAtoms.selectedIssues,
  );

  const bulkUpdateIssuesColumns = useGetBulkUpdateIssuesColumns();

  return (
    <Stack gap="3x">
      <ModalContentHeading
        color="success"
        subTitleText={`The following issue(s) have been created for ${detailsPanelMachines.length} machines:`}
        titleText="Issues created successfully"
      />

      <Table columns={bulkUpdateIssuesColumns} data={selectedIssues} />
    </Stack>
  );
};
