import { Button } from 'gantri-components';
import { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { batchApi } from '../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { finishingQcModalAtoms } from '../../../complete-finishing-qa-modal.atoms';

export const JobFailedUpdateBatchFooter = ({ partId }: { partId: number }) => {
  const { notifyAxiosError } = useNotification();
  const [processing, setProcessing] = useState<boolean>(false);

  const setStep = useSetRecoilState(finishingQcModalAtoms.step);
  const failedNextStep = useRecoilValue(finishingQcModalAtoms.failedNextStep);
  const batchUpdateMethod = useRecoilValue(
    finishingQcModalAtoms.batchUpdateMethod,
  );
  const [newBatchId, setNewBatchId] = useRecoilState(
    finishingQcModalAtoms.newBatchId,
  );

  const isAddingToExisting = batchUpdateMethod === 'ADD_TO_EXISTING_BATCH';
  const disableConfirm =
    !batchUpdateMethod || (isAddingToExisting && !newBatchId);

  const handleAddToBatch = async () => {
    try {
      setProcessing(true);

      if (isAddingToExisting) {
        await batchApi.addPartToBatch({
          batchId: newBatchId,
          partId,
        });
      } else {
        const { data } = await batchApi.createBatch(partId);

        setNewBatchId(data.batchId);
      }

      setStep(failedNextStep);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update batch.',
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Button
      disabled={disableConfirm}
      processing={processing}
      size={fullScreenModalFooterButtonSize}
      text="Confirm"
      onClick={handleAddToBatch}
    />
  );
};
