import { useRecoilValue } from 'recoil';
import { manageQueuesModalAtoms } from '../../manage-queues-modal.atoms';
import { manageQueuesModalSteps } from '../../manage-queues-modal.constants';
import { ConfirmDeleteFooter } from '../step-confirm-delete/confirm-delete-footer';
import { QueuesListFooter } from '../step-queues-list/queues-list-footer';
import { ManageQueuesModalFooterProps } from './manage-queues-modal-footer.types';

export const ManageQueuesModalFooter = (
  props: ManageQueuesModalFooterProps,
) => {
  const { handleCloseModal } = props;

  const step = useRecoilValue(manageQueuesModalAtoms.step);

  switch (step) {
    case manageQueuesModalSteps.queuesList:
      return <QueuesListFooter handleCloseModal={handleCloseModal} />;

    case manageQueuesModalSteps.confirmDelete:
      return <ConfirmDeleteFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
