import {
  Conditional,
  Dropdown,
  DropdownChangeEvent,
  Stack,
  Typography,
} from 'gantri-components';
import { machineRepairTypes } from '../../../../../../../../constants/options';
import { MachineRepairIssueType } from '../../../../../../../../constants/options/options.types';
import { ModalContentHeading } from '../../../../../../../jobs/components/modals/common/modal-content-heading';
import { RepairDetails } from './components/repair-details';
import { NewRepairContentProps } from './new-repair-content.types';

export const NewRepairContent = (props: NewRepairContentProps) => {
  const {
    notes,
    onClose,
    openIssues,
    repairType,
    selectedIssues,
    setNotes,
    setRepairType,
    setSelectedIssues,
  } = props;

  const handleRepairTypeSelect = (
    event: DropdownChangeEvent<{ value: MachineRepairIssueType }>,
  ) => {
    const repairTypeValue = event.target.value;
    const repairType = machineRepairTypes.find(({ type }) => {
      return type === repairTypeValue;
    });

    setRepairType(repairType);
    setNotes(repairType.description);
  };

  const allRepairTypes = machineRepairTypes.map((repairType) => {
    return {
      ...repairType,
      label: repairType.type,
      value: repairType.type,
    };
  });

  return (
    <Stack gap="3x">
      <ModalContentHeading
        subTitleText="Select repair type and confirm you want to start a new repair."
        titleText="Create New Repair"
      />
      <Stack gap="x">
        <Typography text="Repair Type" variant="h5" />
        <Dropdown
          items={allRepairTypes}
          placeholder="Select a repair type"
          value={repairType?.type}
          onChange={handleRepairTypeSelect}
        />
      </Stack>
      <Conditional condition={!!repairType?.type}>
        <RepairDetails
          notes={notes}
          openIssues={openIssues}
          repairType={repairType}
          selectedIssues={selectedIssues}
          setNotes={setNotes}
          setSelectedIssues={setSelectedIssues}
          onClose={onClose}
        />
      </Conditional>
    </Stack>
  );
};
