import { Stack, TextArea, Typography } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { jobSteps } from '../../../../../../../../../constants/options';
import { RecommendedNextStepContentProps } from './recommended-next-step-content.types';

export const RecommendedNextStepContent = (
  props: RecommendedNextStepContentProps,
) => {
  const { job } = props;

  const setNextStepDetails = useSetRecoilState(
    completeSandJobModalAtoms.nextStepDetails,
  );

  return (
    <Stack gap="x">
      <ModalContentHeading
        color="alert"
        subTitleHtmlText={
          'Part will be <strong style="white-space: nowrap">Re-started</strong>.'
        }
        titleText={`${jobSteps.finish} ${job.type} job failed`}
      />
      <Typography
        paddingTop="0.8rem"
        text="Add additional information about the failure:"
        textStyle="bold"
      />
      <TextArea
        placeholder="Enter details about the failed part"
        required
        onTextChange={debounce(setNextStepDetails, 300)}
      />
    </Stack>
  );
};
