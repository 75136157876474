import { FC } from 'react';
import {
  Conditional,
  Grid,
  Icon,
  Stack,
  Tooltip,
  Typography,
} from 'gantri-components';
import { Attention } from '../../../../../api/machines/machines.types';
import { SectionContainer } from '../../../../../components/common/section-container';
import { formatDate } from '../../../../../helpers/formatter';
import { LONG_FORMAT } from '../../../../../constants/dates';

export const RequiresAttentionSection: FC<{ attentions?: Attention[] }> = ({
  attentions = [],
}) => {
  return (
    <SectionContainer title={`Requires attention (${attentions?.length ?? 0})`}>
      <Conditional condition={!!attentions?.length}>
        <Stack gap="2rem">
          {attentions?.map((attention, index) => {
            return (
              <Grid key={index} columnGap="1rem" columns="max-content 1fr">
                <Icon name="alert:warning_triangle" top="3px" />

                <div>
                  <Typography
                    text={formatDate(attention.createdAt, LONG_FORMAT)}
                  />

                  <Typography
                    color="t2"
                    icon={
                      <Tooltip position="top" title={attention.description}>
                        <Icon name="alert:i_circle" />
                      </Tooltip>
                    }
                    iconPosition="right"
                    text={attention.name}
                  />
                </div>
              </Grid>
            );
          })}
        </Stack>
      </Conditional>
    </SectionContainer>
  );
};
