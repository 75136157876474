import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateDownloadArgs,
  UpdateDownloadResponse,
} from './update-download.types';

export const updateDownload = (requestBody: UpdateDownloadArgs) => {
  return axios.put<UpdateDownloadResponse>(
    `${coreApiUrl}/downloads/${requestBody.id}`,
    requestBody,
  );
};
