import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateGiftCodeArgs,
  UpdateGiftCodeResponse,
} from './update-giftcode.types';

export const updateGiftCode = ({ giftCode, newStatus }: UpdateGiftCodeArgs) => {
  return axios
    .put<UpdateGiftCodeResponse>(`${coreApiUrl}/gift/${giftCode}`, {
      newStatus,
    })
    .then((response) => {
      return response.data;
    });
};
