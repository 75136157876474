import { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from 'gantri-components';
import { StyledDivider } from './section-container.styles';

interface SectionContainerProps {
  title: string;
}

export const SectionContainer: FC<PropsWithChildren<SectionContainerProps>> = (
  props,
) => {
  const { children, title } = props;

  return (
    <Stack gap="1.2rem" width="100%">
      <Typography text={title} textStyle="bold" variant="h4" />
      <StyledDivider />
      {children}
    </Stack>
  );
};
