import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedProductReviewsArgs,
  FetchPaginatedProductReviewsResponse,
} from './fetch-paginated-product-reviews.types';

const query = async (
  body: FetchPaginatedProductReviewsArgs,
): Promise<FetchPaginatedProductReviewsResponse> => {
  const response = await axios.post<FetchPaginatedProductReviewsResponse>(
    `${coreApiUrl}/product-reviews/pagination`,
    body,
  );

  return response.data;
};

const groupKey = () => {
  return 'product-reviews';
};

const key = (args?: FetchPaginatedProductReviewsArgs) => {
  const queries = [
    groupKey(),
    `productId=${String(args?.productId)}`,
    `count=${args?.count}`,
    `page=${args?.page}`,
    `search=${args?.search}`,
    `sortingField=${args?.sortingField}`,
    `sortingType=${args?.sortingType}`,
    `colors=${args?.colors?.join(',') ?? ''}`,
    `status=${args?.statuses?.join(',') ?? ''}`,
  ];

  return [
    {
      groupKey: groupKey(),
      key: queries.join('|'),
    },
  ] as const;
};

export const fetchPaginatedProductReviews = {
  groupKey,
  key,
  query,
};
