import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { MetaDataProps } from './meta.type';

export const MetaData: FC<MetaDataProps> = (props) => {
  const { title } = props;

  return (
    <Helmet>
      <title>{`${title} • Gantri Admin`}</title>
    </Helmet>
  );
};
