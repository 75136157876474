import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQuery,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { GetOverviewArgs, OverviewMachine } from './get-overview.types';
import { getMachineOverview } from './get-overview';

export const useGetMachineOverviewQuery = (props: GetOverviewArgs) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<{ machine: OverviewMachine }>({
    config: {
      enabled: !!props && !!props?.machineId,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'An error occurred fetching machine details.',
        });
      },
      queryFn: () => {
        return getMachineOverview.query(props);
      },
      queryKey: getMachineOverview.key(props),
    },
    showLoading: true,
  });

  return { isLoading, response: data };
};

export const useInvalidateGetMachineOverviewCache = () => {
  const invalidate = useInvalidateQuery();

  const invalidateCache = async (argsToInvalidate?: GetOverviewArgs) => {
    await invalidate(getMachineOverview.key(argsToInvalidate));
  };

  return invalidateCache;
};
