import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { SortBy } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { InvitesSortingField } from './designer-invites.types';

export const invitesFiltersDefaults: {
  sortBy: SortBy<InvitesSortingField>;
} = {
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'DESIGNER_INVITES_FILTERS';

export const pageName: AtomPageName = 'invites';

export const designerInvitesAtoms = {
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<InvitesSortingField>>({
    defaultValue: invitesFiltersDefaults.sortBy,
  })(pageName),
};
