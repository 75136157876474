import { BatchPartCreationStep } from '../../../../types/store';
import { ProgressTrackerItem } from '../../../../components/common/progress-tracker/progress-tracker.types';

export const progressTrackerItems: ProgressTrackerItem<BatchPartCreationStep>[] =
  [
    {
      activeOnSteps: ['SELECT_TEMPLATE'],
      label: 'Template',
    },
    {
      activeOnSteps: ['CREATE_FROM_SCRATCH', 'CREATE_FROM_PRODUCT'],
      label: 'Edit',
    },
    {
      activeOnSteps: ['CONFIRM_BATCH_CREATION'],
      label: 'Confirm',
    },
  ];
