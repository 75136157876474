import { Shipment } from '../../../../../../../../../../api/inventory-purchases/inventory-purchases.types';

export const requiredKeysForConfirmDelivery: (keyof Shipment)[] = [
  'arrivedAt',
  'cargoReadyAt',
  'dimension',
  'etaAt',
  'etdAt',
  'grossWeight',
  'expectedQuantity',
  'shippedAt',
];
