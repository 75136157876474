import { Button, Modal, Stack, Typography } from 'gantri-components';
import { useState } from 'react';
import { batchApi } from '../../../../../../api';
import { useNotification } from '../../../../../../hooks/useNotification';
import { ArchiveBatchModalProps } from './archive-batch-modal.types';

export const ArchiveBatchModal = (props: ArchiveBatchModalProps) => {
  const { batchId, onClose, onUpdate } = props;

  const { notify, notifyAxiosError } = useNotification();
  const [processing, setProcessing] = useState<boolean>(false);

  const handleArchiveBatch = async () => {
    try {
      setProcessing(true);

      const { data } = await batchApi.archiveBatch(batchId);

      notify(data.notice);

      await onUpdate();
      onClose();
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to archive batch.',
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            processing={processing}
            size="large"
            text="Confirm"
            onClick={handleArchiveBatch}
          />
        </>
      }
      header="Archive batch"
      maxWidth="46rem"
      onClose={onClose}
    >
      <Stack alignContent="center" minHeight="12.2rem">
        <Typography
          align="center"
          text={`Are you sure you want to archive Batch #${batchId}?`}
        />
      </Stack>
    </Modal>
  );
};
