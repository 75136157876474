import { Cell, Stack } from 'gantri-components';
import { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { useRecoilValue } from 'recoil';
import {
  DashboardChartData,
  DashboardTableData,
  GetReportDataResponse,
} from '../../../../../api/dashboard/dashboard.types';
import { getActiveQueries } from '../../../../../helpers/checks';
import { useNotification } from '../../../../../hooks/useNotification';
import { DashboardHeading } from '../../../../dashboard/components/common/dashboard-heading';
import { dashboardReportDateRangeToggleItems } from '../../../../dashboard/components/common/dashboard-heading/components/dashboard-date-toggles/dashboard-date-toggles.constants';
import { DownloadHeader } from '../../../../dashboard/components/common/dashboard-heading/dashboard-heading.types';
import { dashboardAndReportAtoms } from '../../../../dashboard/dashboard.atoms';
import { ReportChart } from './components/report-chart';
import { ReportTable } from './components/report-table';
import { ReportPageProps } from './report-page.types';

export const ReportPage = (props: ReportPageProps) => {
  const {
    activeChart,
    activeChartIsCurrency,
    extraChartLeftMargin,
    axisLeft,
    breadcrumbs,
    defaultChart,
    getReportData,
    getRowProps,
    groupByIsVisible,
    groupByItems = [],
    groupByOnChange,
    groupByValue,
    setActiveChart,
    sortingFields,
    tableColumns,
    title,
    transformTooltipValue,
    pageName,
    sortByAtom,
  } = props;
  const { groupBy } = getActiveQueries();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [downloadData, setDownloadData] = useState<DashboardTableData>([]);
  const [tableData, setTableData] = useState<DashboardTableData>([]);
  const [totalRow, setTotalRow] = useState<GetReportDataResponse['total']>();
  const [chartData, setChartData] = useState<DashboardChartData>();

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const formattedStartDate = useRecoilValue(dashboardAndReportAtoms.startDate);
  const formattedEndDate = useRecoilValue(dashboardAndReportAtoms.endDate);

  const groupByName = groupByItems.find(({ value }) => {
    return value === groupByValue;
  })?.label;
  const downloadFileName = `${title} - ${
    groupByName ? `${groupByName} - ` : ''
  }${formattedStartDate}-${formattedEndDate}`;
  const downloadHeaders: DownloadHeader[] = tableColumns.map(
    ({ accessorKey, header, id, label }) => {
      const downloadHeader: DownloadHeader = {
        key: (accessorKey || id).toString(),
        label: (label || header).toString(),
      };

      return downloadHeader;
    },
  );

  const mountedRef = useRef<boolean>(true);
  const isMounted = mountedRef.current;
  const abortControllerRef = useRef<AbortController>();

  useAsync(async () => {
    if (formattedStartDate && formattedEndDate) {
      if (!abortControllerRef.current) {
        abortControllerRef.current = new AbortController();
      }

      const { signal } = abortControllerRef.current;

      try {
        if (isMounted) {
          setIsFetching(true);
        }

        showLoading();

        const { data } = await getReportData(
          {
            endDate: formattedEndDate,
            startDate: formattedStartDate,
          },
          { signal },
        );

        if (isMounted) {
          setTableData(data.tableData);
          setChartData(data.chartData);
          setTotalRow(data.total);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch report data.',
        });
      } finally {
        if (isMounted) {
          setIsFetching(false);
        }

        hideLoading();
      }
    }
  }, [formattedStartDate, formattedEndDate, groupBy]);

  useEffect(() => {
    return () => {
      abortControllerRef?.current?.abort();
      mountedRef.current = false;
    };
  }, []);

  return (
    <Stack
      gap="0"
      height="100%"
      overflow="hidden"
      rows={chartData ? 'max-content max-content 1fr' : 'max-content 1fr'}
    >
      <Cell>
        <DashboardHeading
          breadcrumbs={breadcrumbs}
          dateToggleItems={dashboardReportDateRangeToggleItems}
          download={{
            data: downloadData,
            fileName: downloadFileName,
            headers: downloadHeaders,
          }}
          title={title}
        />
      </Cell>
      {!isFetching && (
        <>
          <ReportChart
            activeChart={activeChart}
            activeChartIsCurrency={activeChartIsCurrency}
            axisLeft={axisLeft}
            chartData={chartData}
            defaultChart={defaultChart}
            extraLeftMargin={extraChartLeftMargin}
            setActiveChart={setActiveChart}
            transformTooltipValue={transformTooltipValue}
          />
          <ReportTable
            formattedEndDate={formattedEndDate}
            formattedStartDate={formattedStartDate}
            getRowProps={getRowProps}
            groupByIsVisible={groupByIsVisible}
            groupByItems={groupByItems}
            groupByOnChange={groupByOnChange}
            groupByValue={groupByValue}
            isFetching={isFetching}
            pageName={pageName}
            setDownloadData={setDownloadData}
            sortByAtom={sortByAtom}
            sortingFields={sortingFields}
            tableColumns={tableColumns}
            tableData={tableData}
            totalRow={totalRow}
          />
        </>
      )}
    </Stack>
  );
};
