export const handleAsyncRequestsSequentially = async <Data, Response>(props: {
  callback: (item: Data) => Promise<Response>;
  items: Data[];
}) => {
  const { callback, items } = props;

  const handleCallbackRecursively = async (props: {
    items: Data[];
    previousResponses: Response[];
  }): Promise<Response[]> => {
    const { items, previousResponses } = props;
    const [itemToProcess, ...remainingItems] = items;

    const response = await callback(itemToProcess);

    const updatedResponses = [...previousResponses, response];

    if (remainingItems.length) {
      return handleCallbackRecursively({
        items: remainingItems,
        previousResponses: updatedResponses,
      });
    }

    return updatedResponses;
  };

  return handleCallbackRecursively({ items, previousResponses: [] });
};
