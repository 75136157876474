import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { EditIssueArgs } from './edit-issue.types';

export const editIssue = (args: EditIssueArgs) => {
  const { id, ...data } = args;

  return axios.put(`${coreApiUrl}/issue/${id}/edit`, data).then((response) => {
    return response.data;
  });
};
