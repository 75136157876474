import { useInvalidateGetMachineRepairsCache } from '../../api/machines/routes/get-repairs/get-repairs.query';
import { useInvalidateGetMachineIssuesCache } from '../../api/machines/routes/get-issues/get-issues.query';
import {
  useInvalidateFetchPaginatedMachinesCache,
  useInvalidateFetchPaginatedMachineTimelineCache,
} from '../../api/machines/routes';
import { useInvalidateGetMachineOverviewCache } from '../../api/machines/routes/get-overview';
import { useInvalidateGetMachinePrintJobsCache } from '../../api/machines/routes/get-print-jobs';

export const useMachineQueriesCache = () => {
  const { invalidateFetchPaginatedMachinesCache } =
    useInvalidateFetchPaginatedMachinesCache();
  const invalidateRepairsCache = useInvalidateGetMachineRepairsCache();
  const invalidateIssuesCache = useInvalidateGetMachineIssuesCache();
  const invalidateTimelineCache =
    useInvalidateFetchPaginatedMachineTimelineCache();
  const invalidateMachineOverviewCache = useInvalidateGetMachineOverviewCache();
  const { invalidateGetMachinePrintJobsCache } =
    useInvalidateGetMachinePrintJobsCache();

  return async (machineId: number) => {
    const cacheId = { machineId };

    await invalidateFetchPaginatedMachinesCache();
    await invalidateRepairsCache(cacheId);
    await invalidateIssuesCache(cacheId);
    await invalidateTimelineCache(cacheId);
    await invalidateMachineOverviewCache(cacheId);
    await invalidateGetMachinePrintJobsCache(cacheId);
  };
};
