import styled from 'styled-components';

export const StyledBreadcrumbWrapper = styled.div`
  display: flex;
  width: 100%;
  color: var(--colors-grey);
`;

export const StyledBreadcrumbLink = styled.a`
  text-decoration: underline;
`;

export const StyledBreadcrumbSeparator = styled.span`
  margin: 0 0.8rem;
`;
