import { OnReorder } from 'gantri-components';
import { useNotification } from '../../../../../hooks/useNotification';
import { JobTemplateWithId } from '../../jobs-template-table.types';
import { UseHandleReorderProps } from './use-handle-reorder.types';

export const useHandleReorder = (props: UseHandleReorderProps) => {
  const { onSaveOverride } = props;

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const handleReorder: OnReorder<JobTemplateWithId> = async (jobTemplates) => {
    try {
      showLoading();

      const flattenedJobTemplates = jobTemplates.reduce(
        (accumulator, jobData) => {
          return jobData.subRows?.length
            ? [...accumulator, ...jobData.subRows]
            : [...accumulator, jobData];
        },
        [],
      );

      const updatedJobTemplates: JobTemplateWithId[] =
        flattenedJobTemplates.map((jobTemplate, index) => {
          return {
            ...jobTemplate,
            sortOrder: index + 1,
          };
        });

      onSaveOverride(updatedJobTemplates);
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to reorder jobs.' });
    } finally {
      hideLoading();
    }
  };

  return { handleReorder };
};
