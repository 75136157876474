import { Button } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completePrintModalAtoms } from '../../../complete-print-modal.atoms';
import { EvaluatePartFooterProps } from './evaluate-part-footer.types';

export const EvaluatePartFooter = (props: EvaluatePartFooterProps) => {
  const { handleCloseModal } = props;

  const checklist = useRecoilValue(completePrintModalAtoms.checklist);
  const setStep = useSetRecoilState(completePrintModalAtoms.step);

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        disabled={!checklist}
        size={fullScreenModalFooterButtonSize}
        text="Continue"
        onClick={() => {
          setStep('COMPLETE_CHECKLIST');
        }}
      />
    </>
  );
};
