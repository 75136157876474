import { FC } from 'react';
import { Button, Modal } from 'gantri-components';
import { useNotification } from '../../../../../../../hooks/useNotification';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { StartJobGenericModalProps } from './start-job-generic-modal.types';
import { JobDetailsPanelLarge } from '../../../common/job-details-panel-large';
import { fullScreenModalFooterButtonSize } from '../../../modals.constants';
import { ModalContentHeading } from '../../../common/modal-content-heading';
import { FullScreenModalHeader } from '../../../common/full-screen-modal-header';
import { jobsApi } from '../../../../../../../api';
import { useSpinner } from '../../../../../../../hooks';

export const StartJobGenericModal: FC<StartJobGenericModalProps> = (props) => {
  const { job, onClose, onJobUpdated } = props;

  const { onInterceptProcessingRequest, processing } = useSpinner();
  const { notify, notifyAxiosError } = useNotification();

  const startJob = async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await jobsApi.startJob({
          jobId: job.id,
          machineId: job.machine?.id,
        });

        notify(data.notice);

        await onJobUpdated();
        onClose();
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: `Unable to start the ${job.step} ${job.type} job.`,
        });
      }
    });
  };

  const handleCloseModal = async () => {
    onClose();
  };

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={job}
          sectionsToShow={[
            'part',
            'color & material',
            'product',
            'instructions',
            'part diagram',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={
        <>
          <Button
            size={fullScreenModalFooterButtonSize}
            text="Cancel"
            variant="secondary"
            onClick={handleCloseModal}
          />
          <Button
            processing={processing}
            size={fullScreenModalFooterButtonSize}
            text="Yes"
            onClick={startJob}
          />
        </>
      }
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <ModalContentHeading
        subTitleText={`Are you sure you want to start ${job?.step} ${job.type}?`}
        titleText="Start job"
      />
    </Modal>
  );
};
