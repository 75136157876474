import { Dispatch, SetStateAction } from 'react';
import { JobTemplateWithId } from '../../jobs-template-table.types';
import { OnEdit } from './get-on-edit.types';

export const getOnEdit = (props: {
  setJobTemplates: Dispatch<SetStateAction<JobTemplateWithId[]>>;
}) => {
  const { setJobTemplates } = props;

  const onEdit: OnEdit = (updatedJobTemplate) => {
    setJobTemplates((prevJobTemplates) => {
      const newJobTemplates = [...prevJobTemplates];

      const spliceIndex = newJobTemplates.findIndex(({ id }) => {
        return id === updatedJobTemplate.id;
      });

      newJobTemplates.splice(spliceIndex, 1, updatedJobTemplate);

      return newJobTemplates;
    });
  };

  return onEdit;
};
