import { Flex, Toggle, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { giftCodesPageAtoms } from './gift-codes-filter.atoms';
import { giftCodesFiltersTypeOptions } from './gift-codes-filter.constants';

export const GiftCodesFilter = () => {
  const [active, setActive] = useRecoilState(giftCodesPageAtoms.filters.active);

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={giftCodesPageAtoms.filters.type}
          default={giftCodesPageAtoms.defaults.filters.type}
          text="Type"
        />
        <AtomsCheckboxList
          atom={giftCodesPageAtoms.filters.type}
          items={[...giftCodesFiltersTypeOptions]}
        />
      </FilterRow>

      <FilterRow columns={1}>
        <FilterResetLabel
          atom={giftCodesPageAtoms.filters.active}
          default={giftCodesPageAtoms.defaults.filters.active}
          text="Status"
        />

        <Flex alignItems="center" gap="x">
          <Typography text="Show All" />
          <Toggle name="show-all" value={active} onSelected={setActive} />
        </Flex>
      </FilterRow>
    </FilterGrid>
  );
};
