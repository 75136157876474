import { Cell, TextField } from 'gantri-components';
import { debounce } from 'lodash';
import { ChecklistItem } from '../../job-checklist.types';
import { FailureDetailsProps } from './failure-details.types';

export const FailureDetails = (props: FailureDetailsProps) => {
  const { isOtherReason, onItemChange, option, reason } = props;

  return (
    <Cell width={2}>
      <TextField
        placeholder={
          isOtherReason
            ? option.description
            : 'Add details about the failure...'
        }
        required={option.requireDetailsOnFail}
        value={option.details}
        onTextChange={debounce((value: string) => {
          const trimmedValue = value.trim();

          const optionData: ChecklistItem = isOtherReason
            ? {
                ...option,
                details: trimmedValue,
                status: trimmedValue ? 'Fail' : 'Pass',
              }
            : {
                ...option,
                details: trimmedValue,
              };

          onItemChange(reason, optionData);
        }, 300)}
      />
    </Cell>
  );
};
