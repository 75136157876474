import { Button, Modal, Typography } from 'gantri-components';
import { inventoryPurchasesApi } from '../../../../api/inventory-purchases';
import routePaths from '../../../../config/route-paths';
import { deleteBlob } from '../../../../helpers/firebase';
import { useNotification } from '../../../../hooks/useNotification';
import { DeleteInventoryPurchaseModalProps } from './delete-inventory-purchase-modal.types';
import { useRouter } from '../../../../hooks';

export const DeleteInventoryPurchaseModal = (
  props: DeleteInventoryPurchaseModalProps,
) => {
  const { editedInventoryPurchase, onClose } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const { navigate } = useRouter();

  const handleDelete = async () => {
    if (editedInventoryPurchase) {
      try {
        showLoading();

        await inventoryPurchasesApi.deleteInventoryPurchase(
          editedInventoryPurchase.id,
        );

        const { attachments } = editedInventoryPurchase;

        attachments.forEach((attachment) => {
          notify(`Deleting ${attachment.fileName}`);

          deleteBlob({
            fileUrl: attachment.url,
            showErrors: false,
          });
        });
        navigate(routePaths.inventoryPurchases);
      } catch (error) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to delete inventory purchase.',
        });
      } finally {
        hideLoading();
      }
    }
  };

  return (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            size="large"
            text="Delete"
            variant="primaryAlert"
            onClick={handleDelete}
          />
        </>
      }
      header="Delete Inventory Purchase"
      width={{ lg: '42rem', md: '100%' }}
      onClose={onClose}
    >
      <Typography
        align="center"
        text={`Are you sure you wan to delete inventory purchase #${editedInventoryPurchase?.id}?`}
      />
    </Modal>
  );
};
