import { Grid, Typography } from 'gantri-components';
import { InventoryChecklistStepProps } from './inventory-checklist-step.types';
import { EditableInventoriesTable } from '../../../../../../components/common/editable-inventories-table';
import { List } from '../../../../../../components/common/list';

export const InventoryChecklistStep = (props: InventoryChecklistStepProps) => {
  const {
    inventoryRequests,
    jobStep,
    limitToInventoryTypes,
    setInventoryRequests,
  } = props;

  return (
    <>
      <Grid gap="unset" marginBottom="2x">
        <Typography
          text={`Enter the quantity of all inventory used during ${jobStep}:`}
        />
        <List>
          <li>
            <Typography
              display="inline"
              htmlTag="strong"
              text="Used:"
              textStyle="bold"
            />
            &nbsp;
            <Typography
              display="inline"
              text={`used in stock or damaged during ${jobStep}.`}
            />
          </li>
          <li>
            <Typography
              display="inline"
              htmlTag="strong"
              text="Discarded:"
              textStyle="bold"
            />
            &nbsp;
            <Typography
              display="inline"
              text="issue from manufacturer (low quality, doesn't work)"
            />
          </li>
        </List>
      </Grid>

      <EditableInventoriesTable
        inventoryRequests={inventoryRequests}
        limitToInventoryTypes={limitToInventoryTypes}
        trackDiscarded
        onEdit={setInventoryRequests}
      />
    </>
  );
};
