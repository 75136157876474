import { ProductReviewStatus } from '../../../api/users/routes';

export const reviewStatusOptions: {
  label: string;
  value: ProductReviewStatus;
}[] = [
  { label: 'Published', value: 'Published' },
  { label: 'Pending', value: 'Pending' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Incomplete', value: 'Incomplete' },
];
