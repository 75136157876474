import { FC } from 'react';
import {
  Button,
  Cell,
  Conditional,
  Dropdown,
  FormikInput,
  Grid,
  Icon,
  Modal,
  TextArea,
  TextField,
  Typography,
} from 'gantri-components';
import { Form, Formik } from 'formik';
import pick from 'lodash/pick';
import DatePicker from '../../../../components/common/date-picker';
import {
  discardReasonOptions,
  transactionInit,
  typeOptions,
} from './new-inventory-transaction.constants';
import {
  NewInventoryTransactionProps,
  TransactionFormState,
} from './new-inventory-transaction.types';
import { NewTransactionSchema } from './new-transaction.schema';
import { getInventoryUnit } from '../../../../components/common/editable-inventories-table/adapter';
import { formatCost, getRawCost } from './new-inventory-transaction.adapter';
import { useCreateInventoryTransactionMutation } from '../../../../api/inventories/routes/create-inventory-transaction';

export const NewInventoryTransaction: FC<NewInventoryTransactionProps> = (
  props,
) => {
  const { currentInventory, onClose } = props;
  const { id: inventoryId } = currentInventory;

  const { isLoading: syncing, onCreateTransaction } =
    useCreateInventoryTransactionMutation();

  const onSubmit = async (transaction: TransactionFormState) => {
    const data = {
      ...pick(transaction, [
        'discardReason',
        'notes',
        'stock',
        'stockChange',
        'transactionDate',
        'type',
      ]),
      cost:
        transaction.type === 'Purchase' ? getRawCost(transaction.cost) : null,
      inventoryId,
    };

    onCreateTransaction(data, {
      onSuccess: () => {
        onClose();
      },
    });
  };

  return (
    <Formik
      initialValues={transactionInit}
      validateOnChange
      validateOnMount
      validationSchema={NewTransactionSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, setFieldValue, values }: any) => {
        const isPurchaseType = values.type === 'Purchase';
        const isDiscardedType = values.type === 'Discarded';

        return (
          <Form>
            <Modal
              footer={
                <>
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />
                  <Button
                    disabled={!isValid}
                    processing={syncing}
                    size="large"
                    text="Save"
                    type="submit"
                  />
                </>
              }
              header="New Transaction"
              onClose={onClose}
            >
              <Grid columns={2} gap="s1">
                <Cell width={2}>
                  <FormikInput
                    Field={
                      <Dropdown items={typeOptions} labelText="Type" required />
                    }
                    name="type"
                  />
                </Cell>

                <Conditional condition={isDiscardedType}>
                  <Cell width={2}>
                    <FormikInput
                      Field={
                        <Dropdown
                          items={discardReasonOptions}
                          labelText="Discard reason"
                          placeholder="Select a reason"
                          required
                        />
                      }
                      name="discardReason"
                    />
                  </Cell>
                </Conditional>

                <Cell width={!isPurchaseType ? 2 : 1}>
                  <Typography marginBottom="4px" text="Date" textStyle="bold" />

                  <FormikInput
                    Field={
                      <DatePicker
                        autoWidth
                        initialValue={{
                          value: transactionInit.transactionDate,
                        }}
                        placeholder="00/00/0000"
                        required
                        onDateChanged={({ formattedValue }) => {
                          setFieldValue('transactionDate', formattedValue);
                        }}
                      />
                    }
                    name="transactionDate"
                  />
                </Cell>

                <Conditional condition={isPurchaseType}>
                  <FormikInput
                    ariaLabel="cost"
                    Field={
                      <TextField
                        onBlurTextChange={(cost) => {
                          if (!cost) return;

                          setFieldValue('cost', formatCost(cost));
                        }}
                      />
                    }
                    labelText="Cost $"
                    name="cost"
                    placeholder="Enter cost"
                    required
                    type="number"
                  />
                </Conditional>

                <Cell width={2}>
                  <FormikInput
                    ariaLabel="stockChange"
                    debounce={150}
                    fieldVariant="standard"
                    labelText="Change"
                    leftIcon={
                      !!values.type && (
                        <Icon
                          name={
                            isPurchaseType
                              ? 'ui-control:plus'
                              : 'ui-control:minus'
                          }
                          size="1rem"
                        />
                      )
                    }
                    name="stockChange"
                    placeholder={
                      !values.type
                        ? 'Quantity Change'
                        : isPurchaseType
                        ? 'Quantity added'
                        : 'Quantity subtracted'
                    }
                    required
                    rightIcon={
                      <Typography
                        color="t2"
                        text={getInventoryUnit(currentInventory.unit)}
                      />
                    }
                    type="number"
                  />
                </Cell>

                <Cell width={2}>
                  <FormikInput
                    Field={<TextArea ariaLabel="notes" />}
                    labelText="Notes"
                    name="notes"
                    placeholder="Enter a note"
                    required
                  />
                </Cell>
              </Grid>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
