import { atom } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { BatchProduct } from '../../api/products/routes/fetch-batch-products-and-parts/fetch-batch-products-and-parts.types';
import { getGenericAtomFamily } from '../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import {
  BatchPartCreationAtoms,
  BatchPartCreationFormik,
} from './batch-part-creation.types';

/** Atoms and formik default values. */
export const batchPartCreationDefaults: BatchPartCreationFormik &
  BatchPartCreationAtoms = {
  batchPartName: '',
  batchPartNotes: '',
  batchQty: undefined,
  colorCode: undefined,
  jobs: [],
  material: undefined,
  partName: '',
  partTemplateId: undefined,
  partThumbnail: {
    relativePath: undefined,
    url: undefined,
  },
  partUuid: uuidv4(),
  productId: undefined,
  selectedProductDetails: undefined,
  step: 'SELECT_TEMPLATE',
  template: undefined,
};

const atomKeyPrefix = 'BATCHES_PART_CREATION';
const pageName: AtomPageName = 'batch-part-creation';

export const batchPartCreationAtoms = {
  allProducts: atom<BatchProduct[]>({
    default: [],
    key: `${atomKeyPrefix}-batch-products`,
  }),
  jobs: getGenericAtomFamily({
    defaultValue: batchPartCreationDefaults.jobs,
    key: `${atomKeyPrefix}-jobs`,
  })(pageName),
  navigateTo: atom<string>({
    default: null,
    key: `${atomKeyPrefix}-navigate-to`,
  }),
  partTemplateId: getGenericAtomFamily({
    defaultValue: batchPartCreationDefaults.partTemplateId,
    key: `${atomKeyPrefix}-part-template-id`,
  })(pageName),
  partThumbnail: getGenericAtomFamily({
    defaultValue: batchPartCreationDefaults.partThumbnail,
    key: `${atomKeyPrefix}-part-thumbnail`,
  })(pageName),
  partUuid: getGenericAtomFamily({
    defaultValue: batchPartCreationDefaults.partUuid,
    key: `${atomKeyPrefix}-part-uuid`,
  })(pageName),
  selectedProductDetails: atom<BatchProduct>({
    default: null,
    key: `${atomKeyPrefix}-selected-product-details`,
  }),
  step: getGenericAtomFamily({
    defaultValue: batchPartCreationDefaults.step,
    key: `${atomKeyPrefix}-step`,
  })(pageName),
};
