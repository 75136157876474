import { Typography, Flex, Conditional } from 'gantri-components';
import { useTheme } from 'styled-components';
import { ZoomableThumbnail } from '../../../../../../../../../../../../../../components';
import { BulkUploadTableWhiteBackgroundPhotosCellProps } from './bulk-upload-table-white-background-photos-cell.types';

export const BulkUploadTableWhiteBackgroundPhotosCell = (
  props: BulkUploadTableWhiteBackgroundPhotosCellProps,
) => {
  const { selectedWhiteBackgroundPhoto, whiteBackgroundPhotos } = props;
  const theme = useTheme();

  return (
    <Flex alignItems="center" gap=".5x">
      <Conditional
        condition={!!whiteBackgroundPhotos.length}
        Fallback={<Typography text="-" />}
      >
        {whiteBackgroundPhotos.map(({ fileBlob, fileName, fileUrl, id }) => {
          const isActivePhoto = fileName === selectedWhiteBackgroundPhoto;
          const borderColor = isActivePhoto
            ? theme.colors.typography.link
            : undefined;

          return (
            <ZoomableThumbnail
              key={id || fileName}
              borderColor={borderColor}
              heading={fileName}
              size={36}
              source={fileBlob ? 'absolute' : 'dynamic'}
              src={fileUrl}
            />
          );
        })}
      </Conditional>
    </Flex>
  );
};
