import { Style, StyleDictionary } from 'pdfmake/interfaces';
import { palette } from 'gantri-components';

export const getPalette = () => {
  return {
    borderColor: palette.monochrome_200,
    darkGray: palette.monochrome_900,
    lightGray: palette.monochrome_600,
    tableHeaderBackgroundColor: palette.monochrome_100,
  };
};

const { darkGray, lightGray, tableHeaderBackgroundColor } = getPalette();

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/styling/ */
export const getStyleArgs = (): {
  defaultStyle: Style;
  styles: StyleDictionary;
} => {
  return {
    defaultStyle: {
      bold: false,
      color: darkGray,
      font: 'Sohne',
      fontSize: 9,
    },
    styles: {
      alignRight: {
        alignment: 'right',
      },
      bold: {
        bold: true,
      },
      fontSize11: {
        fontSize: 11,
      },
      fontSize8: {
        fontSize: 8,
      },
      fontSize9: {
        fontSize: 9,
      },
      lightGray: {
        color: lightGray,
      },
      summaryHeaderTopMargin: {
        margin: [0, 8, 0, 2],
      },
      tableCellAllMargins: {
        margin: [6, 8, 6, 2],
      },
      tableCellHorizontalMargins: {
        margin: [6, 0],
      },
      tableCellThirdPartyFooter: {
        margin: [3, 2],
      },
      tableCellVerticalMargins: {
        margin: [0, 2],
      },
      tableHeader: {
        fillColor: tableHeaderBackgroundColor,
        margin: [6, 3],
      },
      tableMargins: {
        margin: [0, 0, 0, 20],
      },
      underline: {
        decoration: 'underline',
      },
    },
  };
};
