import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import { GetRepairsArgs, GetRepairsResponse } from './get-repairs.types';

const query = (args: GetRepairsArgs) => {
  const { count = 10, machineId, page = 1 } = args;
  const queryString = convertQueriesToString({ count, page });

  return axios
    .get<GetRepairsResponse>(
      `${coreApiUrl}/machines/${machineId}/repairs${queryString}`,
    )
    .then((response) => {
      return response.data;
    });
};

const groupKey = (machineId: number) => {
  return `machine-${machineId}-issues`;
};

const key = (args: GetRepairsArgs) => {
  const queries = [
    'machine',
    `machineId=${args?.machineId}`,
    'repairs',
    `page=${args?.page ?? 1}`,
    `count=${args?.count ?? 10}`,
  ];

  return [
    {
      groupKey: groupKey(args?.machineId),
      key: queries.join('|'),
    },
  ];
};

export const getRepairs = {
  groupKey,
  key,
  query,
};
