import * as yup from 'yup';
import { Material } from '../../constants/options/options.types';

const requiredMaterial: Material = 'Opaque GPP';

export const schema = yup.object().shape({
  batchPartName: yup.string().required('Part name is required.').nullable(),
  batchPartNotes: yup.string().nullable(),
  batchQty: yup.number().required('Please enter a valid number.').nullable(),
  colorCode: yup
    .string()
    .when('material', {
      is: (value) => {
        return value === requiredMaterial;
      },
      then: yup.string().required('Color is required.').nullable(),
    })
    .nullable(),
  material: yup.string().nullable(),
  partName: yup.string().required('Part is required.').nullable(),
  productId: yup.number().required('Product is required.').nullable(),
  template: yup.string().required().nullable(),
});
