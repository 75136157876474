import { forwardRef } from 'react';
import { Box, Grid, Icon, Stack, Tooltip, Typography } from 'gantri-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import { NumberCell } from '../number-cell';
import { FailReasonsCellProps } from './fail-reasons-cell.types';
import { StyledIconWrapper } from './fail-reasons-cell.styles';
import { failReasonsCellDefaultProps } from './fail-reasons-cell.presets';

const TriggerIcon = forwardRef<HTMLDivElement, Partial<IconProps>>(
  (props, ref) => {
    return (
      <StyledIconWrapper ref={ref}>
        <Icon color="t1" name="alert:i_circle_24" size="1.6rem" {...props} />
      </StyledIconWrapper>
    );
  },
);

export const FailReasonsCell = (props: FailReasonsCellProps) => {
  const { failReasons, title, value } = props;

  return !!failReasons?.length ? (
    <Grid alignItems="center" columns="repeat(3, min-content)" gap="0.7rem">
      <NumberCell value={value} />
      <Tooltip
        Component={
          <Box horizontalPadding="3x" verticalPadding="2x">
            <Typography fontWeight="bold" paddingBottom="1.6rem" text={title} />
            <Stack gap="1rem">
              {failReasons.map(({ reason }) => {
                const text = reason.replace(/_+/g, ' ');

                return <Typography key={reason} text={text} />;
              })}
            </Stack>
          </Box>
        }
      >
        <TriggerIcon />
      </Tooltip>
    </Grid>
  ) : (
    <NumberCell value={value} />
  );
};

FailReasonsCell.defaultProps = failReasonsCellDefaultProps;
