import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../../constants/options';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';

export const ConfirmStartContent = () => {
  return (
    <ModalContentHeading
      subTitleText={`Are you sure you want to start ${jobSteps.assemble} ${jobTypeOptions.stage}?`}
      titleText="Start job"
    />
  );
};
