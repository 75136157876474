import { atom } from 'recoil';
import { InventoryRequest } from '../../../../../../api/jobs/jobs.types';
import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { PackingModalStep } from './complete-packing-modal.types';

const atomKeyPrefix = 'PACK_PACK_MODAL';

export const packPackModalAtoms = {
  inventoryRequests: atom<InventoryRequest[]>({
    default: [],
    key: `${atomKeyPrefix}-inventory-requests`,
  }),
  job: atom<JobDetails>({
    default: null,
    key: `${atomKeyPrefix}-job`,
  }),
  step: atom<PackingModalStep>({
    default: 'CONFIRM_START',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
