import { css } from 'styled-components';

export const mediaQueries = {
  desktop: (rules) => {
    return css`
      @media (min-width: 1024px) {
        ${rules}
      }
    `;
  },
  mobile: (rules) => {
    return css`
      @media (max-width: 640px) {
        ${rules}
      }
    `;
  },
  tablet: (rules) => {
    return css`
      @media (max-width: 1024px) {
        ${rules}
      }
    `;
  },
  tabletHorizontal: (rules) => {
    return css`
      @media (max-width: 1024px) and (orientation: landscape) {
        ${rules}
      }
    `;
  },
  tabletVertical: (rules) => {
    return css`
      @media (max-width: 1024px) and (orientation: portrait) {
        ${rules}
      }
    `;
  },
};

export const transitions = {
  base: css`
    -webkit-transition: 150ms ease-in-out;
    -moz-transition: 150ms ease-in-out;
    -ms-transition: 150ms ease-in-out;
    -o-transition: 150ms ease-in-out;
    transition: 150ms ease-in-out;
  `,
  slow: css`
    -webkit-transition: 300ms ease-in-out;
    -moz-transition: 300ms ease-in-out;
    -ms-transition: 300ms ease-in-out;
    -o-transition: 300ms ease-in-out;
    transition: 300ms ease-in-out;
  `,
};

export const shadows = {
  overhang: css`
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
  `,
};

export const zIndexes = {
  dataTableOverlap: 3,
  dropdown: 4,
  loadingOverlay: 1019,
  mobileSidebar: 1000,
  modalOverlay: 1011,
  modalPopup: 1012,
  moreMenu: 5,
  navigation: 10002,
  sidebar: 10,
} as const;

export const layout = {
  borderRadius: '2px',
  maxOverflowX: css`
    max-width: 100%;
    overflow-x: auto;
  `,
  overflowX: css`
    width: 100%;
    overflow-x: auto;
  `,
};
