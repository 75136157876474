import styled from 'styled-components';
import { palette } from 'gantri-components';

export const StyledProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;

  & > p:not(:last-child) {
    margin-right: 5px;
  }
`;

export const StyledProgressBar = styled.div<{ width: string }>`
  background-color: ${palette.monochrome_200};
  width: ${(props) => {
    return props.width;
  }};
  height: 4px;
  display: flex;
  margin-right: 5px;
`;

export const StyledProgress = styled.div<{ progress: number }>`
  background-color: ${({ progress }) => {
    return progress >= 100 ? palette.green_400 : palette.monochrome_black;
  }};
  height: 4px;
  flex-basis: ${({ progress }) => {
    return progress && `${progress}%`;
  }};
`;
