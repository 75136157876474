import { useState } from 'react';
import cx from 'classnames';
import { Checkbox, Flex, TextField, Typography } from 'gantri-components';
import styles from '../styles.module.scss';
import FlexContainer from '../../layout/flex-container';
import Select from '../../common/inputs/select';
import { formatCurrency } from '../../../helpers/formatter';
import { shipmentStatuses, stockStatuses } from '../../../constants/options';
import { ShortProductSummary } from '../../common/short-product-summary';

const ReasonStock = ({ onCheck, onUpdate, shipments, stock, type }) => {
  const [checked, setChecked] = useState(false);
  const [selectedReason, setSelectedReason] = useState(-1);
  const [specifiedReason, setSpecifiedReason] = useState('');

  const preShipmentStatuses = [
    shipmentStatuses.waiting,
    shipmentStatuses.inProgress,
    shipmentStatuses.readyToShip,
    shipmentStatuses.refunded,
  ];
  const disabledStatuses = [stockStatuses.cancelled];
  const shipmentFromStock = shipments.find((shipment) => {
    return shipment.id === stock.shipmentId;
  });
  const hasShipped =
    shipmentFromStock &&
    !preShipmentStatuses.includes(shipmentFromStock.status);
  const disabled =
    disabledStatuses.includes(stock.status) ||
    (type === 'refund' && hasShipped) ||
    (type === 'return' && stock.status === stockStatuses.returnInProgress);

  const reasonOptions =
    type === 'replacement'
      ? ['Breakage', 'Quality issue', 'Other']
      : [
          "Size doesn't fit",
          "Style doesn't fit",
          'Shipping takes too long',
          'Quality issue',
          'Other',
        ];

  const requiredSpecificationThreshold = reasonOptions.length - 3;

  const handleSelectedReason = (reason) => {
    const updatedStock = stock;

    updatedStock.valid = false;

    if (type === 'replacement') {
      updatedStock.replacementReason = reasonOptions[reason];
    }

    if (type === 'refund' || type === 'return') {
      updatedStock.refundReason = reasonOptions[reason];
    }

    if (parseInt(reason) <= requiredSpecificationThreshold) {
      updatedStock.valid = true;
      setSpecifiedReason('');
    }

    onUpdate(updatedStock);
    setSelectedReason(reason);
  };

  const handleSpecifiedReason = (reason) => {
    const updatedStock = stock;

    updatedStock.valid = reason !== '';

    if (type === 'replacement') {
      updatedStock.replacementReason = `${reasonOptions[selectedReason]}: ${reason}`;
    }

    if (type === 'refund' || type === 'return') {
      updatedStock.refundReason = `${reasonOptions[selectedReason]}: ${reason}`;
    }

    onUpdate(updatedStock);
    setSpecifiedReason(reason);
  };

  const selector = () => {
    return (
      checked && (
        <Select
          classnames={cx(styles['reason-select'], {
            [styles['reason-select--placeholder']]: selectedReason === -1,
          })}
          value={selectedReason}
          onChange={handleSelectedReason}
        >
          <option disabled value={-1}>
            Select your option
          </option>
          {reasonOptions.map((reason, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <option key={i} value={i}>
                {reason}
              </option>
            );
          })}
        </Select>
      )
    );
  };

  const reasonField = () => {
    return (
      checked &&
      selectedReason > requiredSpecificationThreshold && (
        <TextField
          marginTop="1rem"
          placeholder="Specify reason"
          value={specifiedReason}
          onTextChange={handleSpecifiedReason}
        />
      )
    );
  };

  const handleCheck = (value) => {
    // eslint-disable-next-line no-param-reassign
    stock.valid = !value;

    if (!value) {
      // eslint-disable-next-line no-param-reassign
      delete stock.valid;
      handleSelectedReason(-1);
      handleSpecifiedReason('');
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onCheck && onCheck(value);
    setChecked(value);
  };

  return (
    <FlexContainer
      alignItems="flex-start"
      className={styles.stock}
      justifyContent="space-between"
      marginTop="30px"
    >
      <Flex gap="1rem">
        <Checkbox
          checked={checked || disabled}
          disabled={disabled}
          onSelected={handleCheck}
        />
        <ShortProductSummary
          imageSize="8rem"
          {...stock.product}
          containerProps={{ alignItems: 'flex-start' }}
          name={stock.product.fullName}
          thumbnail={stock.product.imageUrl}
          variant={{ code: null, name: stock.product.variant }}
        >
          <Typography
            color="t2"
            text={stock.product.designer}
            textStyle="regular"
          />
          {selector()}
          {reasonField()}
        </ShortProductSummary>
      </Flex>

      <FlexContainer alignItems="flex-end" direction="column">
        <Typography
          color="t1"
          text={formatCurrency(stock.product.amount.subtotal)}
          textStyle="regular"
          variant="p2"
        />
        <Typography
          color="t1"
          text={stock.status}
          textStyle="regular"
          variant="p2"
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default ReasonStock;
