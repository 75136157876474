import React, { FC } from 'react';
import { Typography } from 'gantri-components';
import { AddressProps } from '../address.types';
import { Shipping } from '../../shipping';
import { formatPhoneNumber } from '../../../../../helpers/formatter';
import { countries } from '../../../../../constants/locales';

export const ViewAddress: FC<AddressProps> = (props) => {
  const { currentOrderData, setCurrentOrderData } = props;
  const { address, shipments } = currentOrderData;

  const {
    city,
    country,
    doNotShip,
    firstName,
    lastName,
    name,
    phone,
    state,
    street,
    unit,
    zip,
  } = address;

  // support legacy saved values
  const countryInstance = countries.find(({ code, name }) => {
    return name === country || code === country;
  }) ?? { name: country };
  const formattedPhone = phone ? formatPhoneNumber(phone.toString()) : '';

  if (doNotShip) {
    return <Typography color="t2" text="Do not ship" />;
  }

  return (
    <div style={{ width: '100%' }}>
      <Typography text={name || `${firstName} ${lastName}`} />
      <Typography color="t1" text={`${street}${unit ? `, ${unit}` : ''}`} />
      <Typography color="t1" text={`${city}, ${state} ${zip}`} />
      <Typography color="t1" text={countryInstance.name} />
      <Typography color="t1" marginBottom="1rem" text={formattedPhone} />

      {!!(shipments && shipments.length) && (
        <Shipping
          currentOrderData={currentOrderData}
          setCurrentOrderData={setCurrentOrderData}
        />
      )}
    </div>
  );
};
