import { formatDate } from '../../../../helpers/formatter';

/** Formats as `${prefix} - ${date}-T${time}.csv` where date and time are based on time of download. */
export const getDownloadFileName = (prefix: string) => {
  const now = Date.now();

  const date = formatDate(now, 'MM-dd-yyyy');
  const time = formatDate(now, 'HHmmss');

  return `${prefix} - ${date}-T${time}.csv`;
};
