import React, { FC, Fragment, useRef } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import {
  Button,
  Dropdown,
  FormikInput,
  Grid,
  Icon,
  Modal,
  SearchField,
  Typography,
} from 'gantri-components';
import { FormikProps } from 'formik/dist/types';
import {
  CheckoutFormState,
  InventoryCheckoutProps,
} from './inventory-checkout.types';
import { InventoryCheckoutSchema } from './inventory-checkout.schema';
import { typeOptions } from './inventory-checkout.constants';
import { StyledHeader } from './inventory-checkout.styles';
import UnitInput from '../../../../components/common/inputs/unit-input';
import {
  SelectorHeader,
  SelectorItem,
} from '../inventory-migration/components';

export const InventoryCheckoutForm: FC<InventoryCheckoutProps> = (props) => {
  const formRef = useRef<FormikProps<CheckoutFormState>>();
  const {
    initialValues = { inventories: [], type: undefined },
    inventories,
    onClose,
    onSubmit,
    processing,
  } = props;

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formRef}
      validateOnChange
      validateOnMount
      validationSchema={InventoryCheckoutSchema}
      onSubmit={onSubmit}
    >
      {({ isValid, values }) => {
        return (
          <Form>
            <Modal
              footer={
                <Grid columns={2} gap=".5rem">
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onClose}
                  />

                  <Button
                    disabled={!isValid}
                    processing={processing}
                    size="large"
                    text="Create"
                    type="submit"
                  />
                </Grid>
              }
              header={
                <Typography
                  align="center"
                  text="Inventory Check-out"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '74rem', md: '100%' }}
              onClose={onClose}
            >
              <Grid gap="s2">
                <FormikInput
                  Field={
                    <Dropdown
                      items={typeOptions}
                      labelText="Type"
                      placeholder="Select a transaction type"
                      required
                    />
                  }
                  name="type"
                />

                <FieldArray
                  name="inventories"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        <Grid
                          alignItems="flex-start"
                          columns="1fr 8.5rem 1fr"
                          gap=".8rem"
                        >
                          <StyledHeader>
                            <Typography text="Name" textStyle="bold" />
                            <Typography text="Quantity" textStyle="bold" />
                            <Typography text="Comments" textStyle="bold" />
                          </StyledHeader>

                          {values?.inventories?.map((_, index: number) => {
                            return (
                              <Fragment key={index}>
                                <FormikInput
                                  Field={
                                    <SearchField
                                      filterFn={(searchBy, option) => {
                                        return (
                                          option.name
                                            .toLowerCase()
                                            .includes(searchBy.toLowerCase()) ||
                                          option.id
                                            .toString()
                                            .includes(searchBy.toLowerCase())
                                        );
                                      }}
                                      header={<SelectorHeader />}
                                      idProperty="id"
                                      items={inventories}
                                      labelProperty="name"
                                      maxHeight="33rem"
                                      placeholder="Select inventory"
                                      renderItem={(option) => {
                                        return <SelectorItem option={option} />;
                                      }}
                                      onSelect={(item) => {
                                        formRef.current.setFieldValue(
                                          `inventories.${index}.inventorySelected`,
                                          item,
                                          false,
                                        );
                                      }}
                                    />
                                  }
                                  name={`inventories.${index}.inventory`}
                                />

                                <FormikInput
                                  Field={
                                    <UnitInput
                                      unit={
                                        values.inventories?.[
                                          index
                                        ]?.inventorySelected?.unit?.toLowerCase() ??
                                        ''
                                      }
                                      onBlurTextChange={(value: string) => {
                                        formRef.current.setFieldValue(
                                          `inventories.${index}.quantity`,
                                          parseInt(value),
                                          false,
                                        );
                                      }}
                                    />
                                  }
                                  fieldVariant="standard"
                                  name={`inventories.${index}.quantity`}
                                />

                                <Grid
                                  alignItems="center"
                                  columns="1fr max-content"
                                  gap=".8rem"
                                >
                                  <FormikInput
                                    name={`inventories.${index}.comments`}
                                    placeholder="Add comments"
                                  />

                                  <Icon
                                    color="t2"
                                    cursor="pointer"
                                    name="ui-control:x"
                                    size="1.6rem"
                                    onClick={() => {
                                      return arrayHelpers.remove(index);
                                    }}
                                  />
                                </Grid>
                              </Fragment>
                            );
                          })}
                        </Grid>

                        <Button
                          icon={<Icon color="link" name="ui-control:plus_24" />}
                          iconLocation="left"
                          text="Add Inventory"
                          variant="ghost"
                          width="fit-content"
                          onClick={() => {
                            arrayHelpers.push({
                              comments: '',
                              inventory: undefined,
                              inventorySelected: undefined,
                              quantity: 0,
                            });
                          }}
                        />
                      </>
                    );
                  }}
                />
              </Grid>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
