import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedDesignsArgs,
  FetchPaginatedDesignsResponse,
} from './fetch-paginated-designs.types';

export const fetchPaginatedDesigns = (args: FetchPaginatedDesignsArgs) => {
  const {
    action,
    categories,
    count,
    page,
    search,
    sortingField,
    sortingType,
    statuses,
    steps,
  } = args;

  return axios
    .post<FetchPaginatedDesignsResponse>(
      `${getCoreApiUrl('designs')}/paginated-designs`,
      {
        /** Submit `undefined` instead of `false` to ignore the filter if disabled and get "everything". */
        action: action || undefined,
        categories,
        count,
        page,
        search,
        sortingField,
        sortingType,
        statuses,
        steps,
      },
    )
    .then(({ data }) => {
      return data;
    });
};
