import { countries } from '../../../constants/locales';
import { inventoryPurchaseStatuses } from '../../../constants/inventory-purchases';
import {
  InventoryPurchasesSortingField,
  InventoryPurchaseStatus,
} from './inventory-purchases-filter.types';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { DateRangeFilter } from '../../common/table/components/table-filters/table-filters.types';

export const inventoryPurchaseStatusOptions = Object.values(
  inventoryPurchaseStatuses,
);

export const inventoryPurchasesFiltersDefaults: {
  filters: {
    completedDateRange: DateRangeFilter;
    country: string;
    createdDateRange: DateRangeFilter;
    hasDiscardedShipment?: boolean;
    statuses: InventoryPurchaseStatus[];
  };
  sortBy: SortBy<InventoryPurchasesSortingField>;
} = {
  filters: {
    completedDateRange: {
      from: null,
      to: null,
    },
    country: null,
    createdDateRange: {
      from: null,
      to: null,
    },
    hasDiscardedShipment: false,
    statuses: [],
  },
  sortBy: {
    sortingField: 'createdAt',
    sortingType: 'ASC',
  },
};

export const countryOptions = [
  {
    label: 'Select country...',
    value: inventoryPurchasesFiltersDefaults.filters.country,
  },
  ...countries.map(({ name }) => {
    return {
      label: name,
      value: name,
    };
  }),
];
