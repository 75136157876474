import { GetChartLeftMarginArgs } from './get-chart-left-margin.types';

export const getChartLeftMargin = (args: GetChartLeftMarginArgs) => {
  const { activeChartDetails = [], extraMargin = 25, isCents } = args;
  const pxPerCharacter = 8;
  const longestLength = activeChartDetails[0]?.data?.reduce(
    (currentLongest, { y }) => {
      let value = Number(y);

      if (isCents) {
        value /= 100;
      }

      const numCharacters = `${Math.ceil(value)}`.length;

      return numCharacters > currentLongest ? numCharacters : currentLongest;
    },
    0,
  );

  let leftMargin = longestLength * pxPerCharacter + extraMargin;

  if (isCents) {
    leftMargin += pxPerCharacter;
  }

  return leftMargin;
};
