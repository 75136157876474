import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportProfitArgs,
  GetReportProfitResponse,
} from './get-report-profit.types';

export const getReportProfit = (
  { rangeType, ...queries }: GetReportProfitArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportProfitResponse>(
    `${coreApiUrl}/reports/profit${queriesString}`,
    configs,
  );
};
