import React, { FC } from 'react';
import { Grid, Typography } from 'gantri-components';

export const SelectorItem: FC<{
  option: { disabled?: boolean; id: number; name: string };
}> = ({ option }) => {
  const textColor = option.disabled ? 't2' : 't1';

  return (
    <Grid columnGap="1rem" columns="5rem 1fr" padding=".8rem">
      <Typography color={textColor} text={option.id} />
      <Typography color={textColor} text={option.name} />
    </Grid>
  );
};
