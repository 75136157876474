import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultMachinesGroupBy } from '../../report-machines.constants';
import { ReportMachinesQueries } from '../../report-machines.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Machines';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportMachinesQueries>();
  const { groupBy = defaultMachinesGroupBy, machineId, machineType } = queries;

  if (/^machineName$/i.test(groupBy) && machineId) {
    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy }),
    };

    return [rootBreadcrumb];
  }

  if (/^machineType$/i.test(groupBy) && machineType) {
    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy }),
    };

    return [rootBreadcrumb];
  }

  return [];
};
