import { memo, useEffect, useState } from 'react';
import { DashboardReportSummary } from '../../../../api/dashboard/dashboard.types';
import { useFetchDashboardFinishJobsSummary } from '../../../../api/dashboard/routes/get-dashboard-finishing-jobs-summary/get-dashboard-finishing-jobs-summary.query';
import routePaths from '../../../../config/route-paths';
import { getChartLeftMargin } from '../common/dashboard-chart/helpers';
import { ReportSummary } from '../common/reports-summary';
import { finishingJobsTableColumns } from './dashboard-finishing-jobs-summary.constants';

const DashboardFinishingJobsSummaryBase = () => {
  const [report, setReport] = useState<DashboardReportSummary>();
  const [activeChart, setActiveChart] = useState<string>();

  const activeChartDetails = report?.chartData?.[activeChart];
  const leftMargin = getChartLeftMargin({
    activeChartDetails,
  });

  useEffect(() => {
    if (!activeChart) {
      const chartDataKeys = Object.keys(report?.chartData || {});
      const firstCartDataKey = chartDataKeys[0];

      setActiveChart(firstCartDataKey);
    }
  }, [report?.chartData]);

  return (
    <ReportSummary
      activeChart={activeChart}
      activeChartDetails={activeChartDetails}
      chartData={report?.chartData}
      fullReportHref={`${routePaths.dashboard}/jobs`}
      leftMargin={leftMargin}
      minHeight="42rem"
      setActiveChart={setActiveChart}
      setReport={setReport}
      tableColumns={finishingJobsTableColumns}
      tableData={report?.tableData}
      title="Finishing Jobs"
      useGetDashboardReportSummary={useFetchDashboardFinishJobsSummary}
    />
  );
};

export const DashboardFinishingJobsSummary = memo(
  DashboardFinishingJobsSummaryBase,
);
