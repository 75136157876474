import PropTypes from 'prop-types';
import { Conditional } from 'gantri-components';
import {
  StyledDropdownContainer,
  StyledDropdownWrapper,
} from './dropdown-wrapper-styles';

const DropdownWrapper = ({
  children,
  collapsed,
  customDrop,
  customWidth,
  dropdownStyles,
  moreMenu,
  style,
}) => {
  return (
    <StyledDropdownWrapper
      customDrop={customDrop}
      customWidth={customWidth}
      style={style}
    >
      <Conditional condition={!collapsed}>
        <StyledDropdownContainer moreMenu={moreMenu} style={dropdownStyles}>
          {children}
        </StyledDropdownContainer>
      </Conditional>
    </StyledDropdownWrapper>
  );
};

DropdownWrapper.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  customDrop: PropTypes.string,
  customWidth: PropTypes.string,
  dropdownStyles: PropTypes.object,
  moreMenu: PropTypes.bool,
  style: PropTypes.object,
};

export default DropdownWrapper;
