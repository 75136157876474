import { memo, Fragment } from 'react';
import { GetFolderStructureArgs, Typography } from 'gantri-components';
import { StyledImagerySection } from './imagery-section.styles';
import {
  ImagerySectionProps,
  FileType,
  FileKeyName,
} from './imagery-section.types';
import { imageryPhotoConfig } from './imagery-section.constants';
import { SortablePhotosList } from '../../../../../../components';

export const Section = (props: ImagerySectionProps) => {
  const {
    editorialImages,
    id,
    processImages,
    quoteImages,
    updateDesignerInfo,
  } = props;

  const getImageDetails = (
    fileType: FileType,
  ): { images: string[]; keyName: FileKeyName } => {
    const { keyName } = imageryPhotoConfig.find((item) => {
      return item.fileType === fileType;
    });

    switch (fileType) {
      case 'editorial-imagery': {
        return { images: editorialImages, keyName };
      }

      case 'process-imagery': {
        return { images: processImages, keyName };
      }

      case 'quote-imagery': {
        return { images: quoteImages, keyName };
      }

      default:
        return { images: [], keyName };
    }
  };

  const getOnImageUpload = (fileType: FileType) => {
    return async (fileNames: string[]) => {
      const { images, keyName } = getImageDetails(fileType);

      updateDesignerInfo({
        key: keyName,
        type: 'key',
        value: [...images, ...fileNames],
      });
    };
  };

  const getOnImageReplace = (fileType: FileType) => {
    return async (replacementFileName: string, replaceIndex) => {
      const { images, keyName } = getImageDetails(fileType);

      updateDesignerInfo({
        key: keyName,
        type: 'key',
        value: images.map((fileName, index) => {
          return replaceIndex === index ? replacementFileName : fileName;
        }),
      });
    };
  };

  const getOnImageRemove = (fileType: FileType) => {
    return async (removedIndex: number) => {
      const { images, keyName } = getImageDetails(fileType);

      updateDesignerInfo({
        key: keyName,
        type: 'key',
        value: images.filter((_fileName, index) => {
          return index !== removedIndex;
        }),
      });
    };
  };

  const getOnReorder = (fileType: FileType) => {
    return async (fileNames: string[]) => {
      const { keyName } = getImageDetails(fileType);

      updateDesignerInfo({
        key: keyName,
        type: 'key',
        value: fileNames,
      });
    };
  };

  return (
    <StyledImagerySection>
      <Typography
        marginBottom="1rem"
        text="Imagery"
        textStyle="bold"
        variant="h4"
      />
      {imageryPhotoConfig.map(({ fileType, max, title }) => {
        const { images } = getImageDetails(fileType);

        const getFolderStructureArgs: GetFolderStructureArgs<'designers'> = {
          directory: 'designers',
          fileType,
          identifiers: {
            userId: id,
          },
        };

        return (
          <Fragment key={fileType}>
            <SortablePhotosList
              getFolderStructureArgs={getFolderStructureArgs}
              max={max}
              newFileName="image"
              photos={images}
              title={title}
              onImageRemove={getOnImageRemove(fileType)}
              onImageReplace={getOnImageReplace(fileType)}
              onImageUpload={getOnImageUpload(fileType)}
              onReorder={getOnReorder(fileType)}
            />
          </Fragment>
        );
      })}
    </StyledImagerySection>
  );
};

export const ImagerySection = memo(Section);
