import { Stack } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledShipmentStack = styled(Stack)<{ isEditing: boolean }>`
  padding: 2rem 1.6rem 1.6rem;
  ${({ isEditing, theme }) => {
    return isEditing
      ? css`
          box-shadow: ${theme.colors.shadow.low};
          border: 1px solid ${theme.colors.dividers.t1};
        `
      : css`
          background-color: ${theme.colors.surfaces.monochrome.t1Alt};
        `;
  }}

  textarea {
    padding: unset;
  }
`;
