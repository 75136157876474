import { Icon, Conditional, Box, Grid, Typography } from 'gantri-components';
import { useTheme } from 'styled-components';
import { useMemo } from 'react';
import { ValidatedFileDetails } from '../../../../../../bulk-upload-modal.types';
import {
  StyledOverflowTooltip,
  StyledOverflowTypography,
} from './validated-files-list.styles';
import { ExpandableItemsList } from '../../../../../../../../../../../../../../components/common/expandable-items-list';
import { ValidatedFilesListProps } from './validated-files-list.types';
import { bulkUploadsRestrictions } from '../../../../../../bulk-upload-modal.constants';

export const ValidatedFilesList = (props: ValidatedFilesListProps) => {
  const { defaultIsExpanded, filesList, isValid } = props;

  const theme = useTheme();

  // convert to strings and back to JSON in order to remove duplicates.
  const uniqueFilesList: ValidatedFileDetails[] = useMemo(() => {
    return Array.from(
      new Set(
        filesList.map((value) => {
          return JSON.stringify(value);
        }),
      ),
    ).map((value) => {
      return JSON.parse(value);
    });
  }, []);

  const numItems = uniqueFilesList.length;

  return (
    <Conditional condition={!!numItems}>
      <ExpandableItemsList
        backgroundColor={
          isValid
            ? theme.colors.surfaces.blue.t1
            : theme.colors.surfaces.alert.t1
        }
        defaultIsExpanded={defaultIsExpanded}
        idProperty="fileName"
        Item={({ errorMessage, fileName }) => {
          return (
            <Grid
              alignItems="center"
              columns={!isValid ? '1fr 9.6rem' : '1fr'}
              gap="2x"
              width="100%"
            >
              <Box overflow="hidden">
                <StyledOverflowTooltip
                  overlayContainerStyles={{ width: '100%' }}
                  position="top-end"
                  title={fileName}
                >
                  <StyledOverflowTypography text={fileName} variant="p3" />
                </StyledOverflowTooltip>
              </Box>

              <Conditional condition={!isValid}>
                <Typography
                  align="right"
                  color="alert"
                  text={
                    errorMessage ||
                    `Upload qty (${bulkUploadsRestrictions.maxUploadsAllowed}) reached`
                  }
                  variant="p3"
                />
              </Conditional>
            </Grid>
          );
        }}
        Label={({ numItems }) => {
          return (
            <Box verticalPadding=".5x">
              <Conditional
                condition={isValid}
                Fallback={
                  <Typography
                    icon={<Icon color="alert" name="alert:i_circle" />}
                    text={`${numItems} asset(s) invalid`}
                  />
                }
              >
                <Typography
                  icon={
                    <Icon
                      color="success"
                      name="ui-control:check_mark_circle_filled"
                    />
                  }
                  text={`${numItems} asset(s) validated`}
                />
              </Conditional>
            </Box>
          );
        }}
        list={uniqueFilesList}
      />
    </Conditional>
  );
};
