import {
  Button,
  Cell,
  Dropdown,
  Flex,
  Grid,
  Icon,
  Overlay,
} from 'gantri-components';
import { FC } from 'react';
import { CSVLink } from 'react-csv';
import { useRecoilState, useRecoilValue } from 'recoil';
import { DatePickerDialog } from '../../../../../components/common/date-picker/components/date-picker-dialog';
import { OnDateChanged } from '../../../../../components/common/date-picker/date-picker.types';
import { useDatePicker } from '../../../../../components/common/date-picker/hooks/use-date-picker';
import { PageHeading } from '../../../../../components/layout/page-heading';
import { dashboardAndReportAtoms } from '../../../dashboard.atoms';
import { DashboardDateToggles } from './components/dashboard-date-toggles';
import { SettingsButton } from './components/settings-button';
import { DashboardHeadingProps } from './dashboard-heading.types';
import { useOnDateRangeTypeChange } from './hooks/use-on-date-range-type-change';

export const DashboardHeading: FC<DashboardHeadingProps> = (props) => {
  const { dateToggleItems, download, enableSettingsToggle, ...rest } = props;

  const [startDate, setStartDate] = useRecoilState(
    dashboardAndReportAtoms.startDate,
  );
  const [endDate, setEndDate] = useRecoilState(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const { onDateRangeTypeChange } = useOnDateRangeTypeChange();

  const optionalCellTriggers = [download, enableSettingsToggle];
  const numRepeatColumns = optionalCellTriggers.filter((cell) => {
    return !!cell;
  }).length;
  const dropdownItems = dateToggleItems.map((item) => {
    const value = typeof item === 'string' ? item : item.value;

    return {
      label: value,
      value,
    };
  });

  const initTo = new Date();
  const initFrom = new Date();

  initFrom.setDate(initTo.getDate() - 7);

  const initialValue = {
    from: new Date(startDate || initFrom),
    to: new Date(endDate || initTo),
  };

  const onDateChanged: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    if (/^custom$/i.test(rangeType)) {
      toggleDatePicker(false);
      setStartDate(formattedFrom);
      setEndDate(formattedTo);
    }
  };

  const {
    daysInMonth,
    getDayStatus,
    goToNextMonth,
    goToPreviousMonth,
    isNextMonthActive,
    isPreviousMonthActive,
    onResetDates: resetDates,
    opened: datePickerIsVisible,
    selection: { formattedMonth },
    setDay,
    toggleOpened: toggleDatePicker,
  } = useDatePicker({
    from: initialValue.from,
    initialValue,
    onDateChanged,
    range: true,
    to: initialValue.to,
  });

  return (
    <>
      <PageHeading {...rest}>
        <Grid
          alignItems="center"
          columns={{
            lg:
              numRepeatColumns >= 1
                ? `1fr repeat(${numRepeatColumns}, max-content)`
                : '1',
            md: '1',
          }}
          gap="0.8rem"
        >
          <Cell hidden={{ lg: false, md: true }}>
            <DashboardDateToggles dateToggleItems={dateToggleItems} />
          </Cell>
          {download && (
            <CSVLink
              data={download.data}
              filename={download.fileName}
              headers={download.headers}
            >
              <Button text="Download CSV" variant="secondary" />
            </CSVLink>
          )}
          {enableSettingsToggle && <SettingsButton />}
        </Grid>
      </PageHeading>
      <Cell hidden={{ lg: true, md: false }}>
        <Flex>
          <Dropdown
            icon={<Icon name="time:calendar" />}
            items={dropdownItems}
            value={rangeType}
            onChange={({ target }) => {
              const { value } = target;

              if (/^custom$/i.test(value)) {
                toggleDatePicker(true);
              }

              onDateRangeTypeChange(value);
            }}
          />
        </Flex>
        {datePickerIsVisible && (
          <Flex paddingTop="0.4rem">
            <Overlay
              content={
                <DatePickerDialog
                  daysInMonth={daysInMonth}
                  formattedMonth={formattedMonth}
                  getDayStatus={getDayStatus}
                  goToNextMonth={goToNextMonth}
                  goToPreviousMonth={goToPreviousMonth}
                  isNextMonthActive={isNextMonthActive}
                  isPreviousMonthActive={isPreviousMonthActive}
                  resetDates={resetDates}
                  setDay={setDay}
                />
              }
              open={datePickerIsVisible}
              position="bottom-start"
              onClose={toggleDatePicker}
            />
          </Flex>
        )}
      </Cell>
    </>
  );
};
