import { environment } from '../../environment';

export const getAdminUrl = () => {
  switch (environment.STAGE) {
    case 'local':
      return 'http://localhost:3001';
    case 'develop':
      return 'https://dev.admin.gantri.com';
    case 'staging':
      return 'https://stage.admin.gantri.com';
    default:
      return 'https://admin.gantri.com';
  }
};
