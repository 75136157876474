import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateMachineArgs,
  UpdateMachineResponse,
} from './update-machine.types';

export const updateMachine = (args: UpdateMachineArgs) => {
  const {
    active,
    editMode = false,
    location,
    machineId,
    name,
    status,
    type,
  } = args;

  return axios
    .put<UpdateMachineResponse>(`${coreApiUrl}/machines/${machineId}`, {
      active,
      editMode,
      location,
      name,
      status,
      type,
    })
    .then(({ data }) => {
      return data;
    });
};
