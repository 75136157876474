const debounce = (callback, delay = 0) => {
  let timeoutId;

  const clearExistingTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  return (args) => {
    const exec = () => {
      timeoutId = null;
      callback(args);
    };

    clearExistingTimeout();
    timeoutId = setTimeout(exec, delay);
  };
};

export default debounce;
