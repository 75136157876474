import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, TextField, Typography } from 'gantri-components';
import {
  StyledCloseButton,
  StyledModalButtonsContainer,
  StyledModalTitleWrapper,
  StyledModalWrapper,
  StyledOuterContainer,
} from '../modals-styles.module';

const FailModal = ({ jobName, loading, onClose, onFail }) => {
  const [failureReason, setFailureReason] = useState('');
  const [duplicateJob, setDuplicateJob] = useState(true);
  const [needPreviousJobs, setNeedPreviousJobs] = useState(false);

  return (
    <StyledOuterContainer>
      {onClose && (
        <StyledCloseButton
          className="x-icon-white"
          role="button"
          tabIndex="0"
          onClick={onClose}
        />
      )}
      <StyledModalWrapper>
        <StyledModalTitleWrapper>
          <Typography
            align="center"
            text={`Fail ${jobName}`}
            textStyle="bold"
            variant="p1"
          />
          <Typography
            align="center"
            color="t2"
            text="Please provide a reason."
            variant="p2"
          />
        </StyledModalTitleWrapper>
        <TextField
          placeholder="Failure reason"
          value={failureReason}
          onTextChange={setFailureReason}
        />
        <br />
        <Checkbox
          checked={duplicateJob}
          labelText="Duplicate this job"
          onSelected={setDuplicateJob}
        />
        <br />
        <Checkbox
          checked={needPreviousJobs}
          labelText="Duplicate all previous jobs in part"
          onSelected={setNeedPreviousJobs}
        />
        <StyledModalButtonsContainer>
          {onClose && (
            <Button text="Cancel" variant="secondary" onClick={onClose} />
          )}
          <Button
            disabled={!failureReason}
            processing={loading}
            text="Fail"
            variant="alert"
            onClick={onFail(failureReason, duplicateJob, needPreviousJobs)}
          />
        </StyledModalButtonsContainer>
      </StyledModalWrapper>
    </StyledOuterContainer>
  );
};

FailModal.propTypes = {
  jobName: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onFail: PropTypes.func.isRequired,
};

export default FailModal;
