import { Content } from 'pdfmake/interfaces';
import { GetPackingSlipDocProps } from '../../get-packing-slip-doc.types';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getItemInPackage = ({
  currentOrderData,
  inThisPackage,
  stocks,
}: GetPackingSlipDocProps): Content[] => {
  const product = inThisPackage?.product;
  const isTradeOrder = currentOrderData?.type === 'Trade';

  return stocks.length > 1 && !isTradeOrder
    ? [
        {
          layout: 'noBorders',
          style: ['tableMargins'],
          table: {
            body: [
              [
                {
                  style: ['tableCellAllMargins', 'bold', 'fontSize8'],
                  text: 'In this package',
                },
                '',
                '',
                '',
                '',
              ],
              [
                {
                  style: ['tableCellHorizontalMargins'],
                  text: product?.fullName,
                },
                {
                  style: [
                    'tableCellHorizontalMargins',
                    'lightGray',
                    'fontSize8',
                  ],
                  text: product?.sku,
                },
                {
                  style: ['tableCellHorizontalMargins'],
                  text: product?.color?.name,
                },
                {
                  style: ['tableCellHorizontalMargins'],
                  text: product?.size?.name,
                },
                { style: ['tableCellHorizontalMargins'], text: 1 },
              ],
            ],
            headerRows: 1,
            widths: ['35%', '20%', '15%', '15%', '15%'],
          },
        },
      ]
    : [];
};
