import { LineProps } from '@nivo/line';
import { ChartSerie } from '../../../../../../../api/dashboard/dashboard.types';

export const getYScale = (chartData: ChartSerie[]) => {
  let min = 0;

  for (let i = 0; i < chartData.length; i++) {
    const { data } = chartData[i];

    for (let i = 0; i < data.length; i++) {
      const { y } = data[i];
      const yAsNumber = Number(y);
      const isNumber = !isNaN(yAsNumber);

      if (isNumber && yAsNumber < min) {
        min = yAsNumber;
      }
    }
  }

  if (min < 0) {
    const scaleSpec: LineProps['yScale'] = {
      max: 'auto',
      min,
      stacked: false,
      type: 'linear',
    };

    return scaleSpec;
  }
};
