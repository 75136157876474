import { Typography } from 'gantri-components';
import { StyledItemGrid } from '../../../../../../../../../../../components/common/inventory-form/inventory-form.styles';

export const BatchSearchHeader = () => {
  return (
    <StyledItemGrid
      $disabled
      alignItems="baseline"
      columns="6rem 10.7rem 12.9rem 1fr"
      gap="2x"
      horizontalPadding="2x"
      verticalPadding="x"
    >
      <Typography color="t2" text="Batch" />
      <Typography color="t2" text="Colors" />
      <Typography color="t2" text="Current Step" />
      <Typography color="t2" text="Created" />
    </StyledItemGrid>
  );
};
