import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { getStock } from './get-stock';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetStockArgs, GetStockResponse } from './get-stock.types';

const getStockQueriesObj = getGenericQueryObj({
  groupName: 'get-stock',
  query: getStock,
});

export const useGetStock = <TransformedData = GetStockResponse>(
  props?: GenericFetchProps<GetStockArgs, GetStockResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch stock.',
    queryObj: getStockQueriesObj,
    ...props,
  });
};

export const useInvalidateGetStockCache = () => {
  const invalidateGetStockCache =
    useGetInvalidateQueryCache(getStockQueriesObj);

  return { invalidateGetStockCache };
};
