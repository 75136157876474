import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { JobUnassignArgs, JobUnassignResponse } from './job-unassign.types';

export const jobUnassign = (args: JobUnassignArgs) => {
  const { jobId } = args;
  const data = {
    jobId,
  };

  return axios.put<JobUnassignResponse>(`${coreApiUrl}/jobs/un-assign`, data);
};
