import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { GetDesignerResponse } from './get-designer.types';

export const query = (id: number) => {
  return axios
    .get<GetDesignerResponse>(`${baseApiUrl}/users/${id}`)
    .then(({ data }) => {
      return data;
    });
};

const groupKey = () => {
  return 'designer';
};

const key = (id: number) => {
  const queries = [`id=${id}`];

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}?${queries.join('&')}`,
    },
  ] as const;
};

export const getDesigner = {
  groupKey,
  key,
  query,
};
