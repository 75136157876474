import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardApi } from '../../../../api';
import { GetReportStocksArgs } from '../../../../api/dashboard/routes/get-report-stocks/get-report-stocks.types';
import { getActiveQueries } from '../../../../helpers/checks';
import { DashboardChartProps } from '../../../dashboard/components/common/dashboard-chart/dashboard-chart.types';
import { dashboardAndReportAtoms } from '../../../dashboard/dashboard.atoms';
import { ReportPage } from '../common/report-page';
import {
  GetReportData,
  ReportProps,
} from '../common/report-page/report-page.types';
import {
  getReportDetails,
  getRowProps,
  getBreadcrumbs,
  getTitle,
} from './helpers';
import {
  defaultStocksGroupBy,
  pageName,
  reportStocksPageAtoms,
} from './report-stocks.constants';
import { ReportStocksQueries } from './report-stocks.types';

export const ReportStocks = ({ rootTitle }: ReportProps) => {
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const {
    activeChart: activeChartQuery,
    groupBy = defaultStocksGroupBy,
    productId,
    version,
  } = getActiveQueries<ReportStocksQueries>();

  const [activeChart, setActiveChart] = useState<string>(activeChartQuery);
  const activeChartIsPercent = ['onTimeCompletion'].includes(activeChart);
  const transformTooltipValue: DashboardChartProps['transformTooltipValue'] =
    activeChartIsPercent
      ? (value) => {
          return `${value}%`;
        }
      : undefined;
  const axisLeft: DashboardChartProps['axisLeft'] = activeChartIsPercent
    ? {
        format: (y: number) => {
          return `${y}%`;
        },
      }
    : undefined;

  const {
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    sortingOptions,
    tableColumns,
  } = getReportDetails();

  const getReportData: GetReportData = ({ endDate, startDate }, configs) => {
    const args: GetReportStocksArgs = {
      endDate,
      groupBy,
      productId: productId ? +productId : undefined,
      rangeType,
      startDate,
      version,
    };

    return dashboardApi.getReportStocks(args, configs);
  };

  return (
    <ReportPage
      activeChart={activeChart}
      axisLeft={axisLeft}
      breadcrumbs={getBreadcrumbs()}
      extraChartLeftMargin={activeChartIsPercent ? 30 : undefined}
      getReportData={getReportData}
      getRowProps={getRowProps}
      groupByIsVisible={groupByIsVisible}
      groupByItems={groupByItems}
      groupByOnChange={groupByOnChange}
      groupByValue={groupBy}
      pageName={pageName}
      setActiveChart={setActiveChart}
      sortByAtom={reportStocksPageAtoms.sortBy}
      sortingFields={sortingOptions}
      tableColumns={tableColumns}
      title={getTitle(rootTitle)}
      transformTooltipValue={transformTooltipValue}
    />
  );
};
