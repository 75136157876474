import { useEffect, useRef } from 'react';

export const useIsNotFirstRender = (cb: () => void, dependencies = []) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;

      return;
    }

    cb();
  }, dependencies);
};
