import styled, { css } from 'styled-components';
import { ButtonToggleGroupProps } from './button-toggle-group.types';

export const StyledToggleButtonGroup = styled.div<
  Pick<ButtonToggleGroupProps, 'items' | 'itemsWidth'>
>`
  ${({ items, itemsWidth }) => {
    return css`
      display: grid;
      user-select: none;
      grid-template-columns: repeat(${items.length}, ${itemsWidth});
      width: max-content;
      overflow: hidden;
    `;
  }}
`;

export const StyledToggleButton = styled.div<{
  activeBackgroundColor: string;
  isActive: boolean;
}>`
  ${({ activeBackgroundColor, isActive, theme }) => {
    const backgroundColor = isActive
      ? theme.colors.palette[activeBackgroundColor || 'green_400']
      : 'unset';

    return css`
      position: relative;
      height: 4.2rem;
      text-align: center;
      display: grid;
      align-items: center;
      align-content: center;
      cursor: pointer;
      background-color: ${backgroundColor};
      border: 1px solid ${
        isActive ? backgroundColor : theme.colors.dividers.t1
      };

      p {
        text-align: center;
      }

      &:not(:last-child) {
        border-right: unset;
      }
      &:first-child {
        border-top-left-radius .4rem; 
        border-bottom-left-radius .4rem; 
      }
      &:last-child {
        border-top-right-radius .4rem; 
        border-bottom-right-radius .4rem; 
      }
    `;
  }}
`;
