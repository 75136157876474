/* eslint-disable sort-keys-fix/sort-keys-fix */

import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { CompleteSandJobChecklist } from './complete-sand-job-modal.types';

export const completeSandJobModalChecklistDefaults: CompleteSandJobChecklist = {
  // keys sorted according to desired display order
  part_breakage: {
    ...checklistDefaults.part_breakage,
    failStatusRecommendation: 'Restart',
    showThumbnail: true,
  },
  feature_damage: {
    ...checklistDefaults.feature_damage,
    failStatusRecommendation: 'Restart',
    showThumbnail: true,
  },
  over_sanding: {
    ...checklistDefaults.over_sanding,
    failStatusRecommendation: 'Restart',
    showThumbnail: true,
  },
  delamination: {
    ...checklistDefaults.delamination,
    failStatusRecommendation: 'Restart',
    showThumbnail: true,
  },
  other_failure_modes: {
    ...checklistDefaults.other_failure_modes,
    failStatusRecommendation: 'Restart',
    showThumbnail: true,
  },
};
