import { Button } from 'gantri-components';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { printPrepareQueueModalAtoms } from '../../../print-prepare-queue-modal.atoms';
import { QueueDeletedFooterProps } from './queue-deleted-footer.types';

export const QueueDeletedFooter = (props: QueueDeletedFooterProps) => {
  const { handleCloseModal } = props;

  const setDeleteQueueOnClose = useSetRecoilState(
    printPrepareQueueModalAtoms.deleteQueueOnClose,
  );

  useEffect(() => {
    setDeleteQueueOnClose(false);
  }, []);

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={() => {
        handleCloseModal();
      }}
    />
  );
};
