import {
  Conditional,
  DynamicListItem,
  DynamicListItemDefaultData,
  Stack,
  Typography,
} from 'gantri-components';
import { GridProps } from 'gantri-components/dist/components/grid/grid.types';
import {
  productCategories,
  productSubCategories,
} from '../../../../api/products/products.constants';
import { Product } from '../../../../api/products/products.types';
import { LONG_FORMAT } from '../../../../constants/dates';
import { formatDate } from '../../../../helpers/formatter';

export const SIDEBAR_WIDTH = '21rem';

export const SIDEBAR_WIDTH_COMPACT = '4rem';

export const productInfoPageMaxWidth: GridProps['maxWidth'] = '120rem';

export const noDesignsDisabledTooltipDescription =
  'Action is not available when the product is missing a Design.';

export const noVisibleColorDisabledTooltipDescription =
  'Action is not available when a painted product does not have at least one Visible Color.';

export const statusOptions = (product: Product) => {
  const items: DynamicListItem<DynamicListItemDefaultData>[] = [
    {
      label: 'In preparation',
      value: 'In preparation',
    },
    {
      label: 'Ready',
      value: 'Ready',
    },
    {
      disabled:
        (product.category !== productCategories.accessory &&
          !product.designs?.length) ||
        !product.colors?.length,
      disabledTooltipProps: {
        Component: (
          <Stack gap="x" horizontalPadding="2x" verticalPadding="x">
            <Conditional condition={!product.designs?.length}>
              <Typography
                color="t2"
                text={noDesignsDisabledTooltipDescription}
              />
            </Conditional>
            <Conditional condition={!product.colors?.length}>
              <Typography
                color="t2"
                text={noVisibleColorDisabledTooltipDescription}
              />
            </Conditional>
          </Stack>
        ),
      },
      label: product.launchedAt
        ? `Active. Launched ${formatDate(product.launchedAt, LONG_FORMAT)}`
        : 'Active',
      value: 'Active',
    },
    {
      label: 'Off Market',
      value: 'Off Market',
    },
  ];

  return items;
};

export const productCategoryOptions = Object.values(productCategories);

export const productSubCategoryOptions = Object.values(productSubCategories);

export const outlets = [
  'Architectural Digest',
  'Apartment Therapy',
  'Business Insider',
  'Cleverest Awards',
  'Cnet',
  'Cool Hunting',
  'Core77',
  'Designboom',
  'Design Milk',
  'Dezeen',
  'Dwell',
  'Fast Company',
  'Gear Patrol',
  'House Beautiful',
  'HYPEBEAST',
  'MyDomaine',
  'Refinery29',
  'SanFrancisco Magazine',
  'The New York Times',
  'Time',
  'Uncrate',
  'Wired',
  'Yanko Design',
] as const;

export const bulbOptions = [
  'E12, T6, 65mm',
  '2x E12, T6, 65mm',
  'E12, T8, 94mm',
  'E12, T13, 42mm',
  'E26, A19, 109mm, 10W',
  'E26, A19, 112mm, 13W',
  'E26, BR20',
  'E26, BR30',
  'E26, G25',
  'E26, T8, 94mm',
  'E26, T8, 112mm',
] as const;

export const allCompatibleWithBulbs = [
  'Philips Hue White and Color, E12, Candle, 470lm',
  'Philips hue White Ambiance, E12, Candle, 470lm',
  'Philips Hue White and Color, E26, A19, 800lm',
  'Philips Hue White Ambiance, E26, A19 800lm',
  'Philips Hue White and Color, E26, A19, 1100lm',
  'Philips Hue White Ambiance, E26, A19, 1100lm',
  'Philips Hue White And Color, E26, A21, 1600lm',
  'Philips Hue White Ambiance, E26, A21, 1600lm',
  'Philips Hue White And Color, E26, BR30, 1200lm',
  'Philips Hue White Ambiance, E26, BR30, 1200lm',
  'Philips Hue, White Ambiance Filament, E26, G25, 550lm, 7W',
  'E12, T6, 65mm',
  'E12, T8, 94mm',
  'E12, T13, 42mm',
  'E26, A15, 500lm',
  'E26, A19, 800lm',
  'E26, A19, 1100lm',
  'E26, A21, 1600lm',
  'E26, R20, 525lm',
  'E26, BR30, 650lm',
  'E26, G25, 800lm',
  'E26, T8, 94mm',
  'E26, T8, 112mm',
] as const;

export const tags = [
  'mid-century',
  'minimal',
  'playful',
  'scandinavian',
  'classical',
] as const;

export const rooms = [
  'Bathroom',
  'Bedroom',
  'Entryway',
  'Garage',
  'Kids',
  'Kitchen & Dining',
  'Living',
  'Office',
  'Patio & Outdoor',
] as const;

export const shapes = [
  'Cone',
  'Cylinder',
  'Globe',
  'Organic',
  'Square',
] as const;

export const selectedRoomInit = rooms.reduce((acc, curr) => {
  return (acc[curr] = false), acc;
}, {}) as Record<string, boolean>;

export const sizes = ['cm', 'sm', 'md', 'lg'] as const;

export const userTypes = ['Admin', 'Worker', 'Lead'] as const;

export const ALL_USER_TYPES = [
  'User',
  'Admin',
  'Designer',
  'Worker',
  'Lead',
] as const;
