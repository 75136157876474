import {
  FeaturePageSettings,
  FeatureReducerAction,
} from './desginer-feature.types';

export const currentFeatureInit: FeaturePageSettings = {
  ctaLink: '',
  ctaTitle: '',
  desktopImage: '',
  editorialImages: [],
  feature: {
    details: '',
    isNew: false,
    name: '',
    photo: '',
  },
  id: null,
  introParagraph: '',
  introPhoto: '',
  introTitle: '',
  meetCollection: [],
  mobileImage: '',
  name: 'Aim Table Collection',
  pageUrl: '',
  primaryBackground: '',
  primaryText: '',
  productIncluded: [],
  products: [],
  profileLink: '',
  secondaryBackground: '',
  secondaryText: '',
  showLogo: false,
  showName: false,
  status: 'unpublished',
  subtitle: '',
  textColor: '',
  title: '',
  valueProps: [],
  videoUrl: '',
};

export const FeatureReducer = (
  state: FeaturePageSettings,
  action: FeatureReducerAction,
): FeaturePageSettings => {
  switch (action.type) {
    case 'state': {
      const { value } = action;
      const featureData = typeof value === 'object' ? value : {};

      return {
        ...state,
        ...featureData,
      };
    }

    case 'key': {
      const { key, value } = action;

      return {
        ...state,
        [key]: value,
      };
    }

    default:
      return state;
  }
};

export const breakCrumbData = [
  {
    name: 'Designer storefront settings',
    path: '',
  },
];

export const validationFields = {
  ctaLink: {
    error: false,
  },
  ctaTitle: {
    error: false,
  },
  valueProps: [],
};
