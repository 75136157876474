import { Cell } from 'gantri-components';
import { ButtonVariant } from 'gantri-components/dist/components/button/button.types';
import { useCallback } from 'react';
import { JobStatus } from '../../../../../../../../../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { ChecklistItem } from '../../../../../../../../../../job-checklist.types';
import { ThumbnailWithZoomModal } from '../../../../../../../thumbnail-with-zoom-modal';
import { StyledButton } from './severity-image-item.styles';
import { SeverityImageItemProps } from './severity-image-item.types';

export const SeverityImageItem = (props: SeverityImageItemProps) => {
  const { checklistItem, src, status, surface, text, updateChecklistItem } =
    props;

  const isPrimary = surface === 'Primary';
  const currentRating = isPrimary
    ? checklistItem.ratingPrimarySurface
    : checklistItem.ratingSecondarySurface;

  const rating = getRatingForText(text);

  const isActive = currentRating === rating;

  const setRatingForSurface = useCallback(() => {
    const ratingKeyName = (
      isPrimary ? 'ratingPrimarySurface' : 'ratingSecondarySurface'
    ) satisfies keyof ChecklistItem;

    if (isPrimary && status === 'Fail') {
      updateChecklistItem({
        ...checklistItem,
        ratingPrimarySurface: rating,
        ratingSecondarySurface: null,
        status,
      });
    } else {
      updateChecklistItem({
        ...checklistItem,
        [ratingKeyName]: rating,
        status,
      });
    }
  }, [isPrimary, rating, status, checklistItem]);

  return (
    <>
      <Cell justifySelf="center">
        <ThumbnailWithZoomModal
          alt={checklistItem.description}
          heading={`${checklistItem.label}: ${text} (${surface})`}
          src={src}
        />
      </Cell>
      <StyledButton
        text={text}
        textVariant="h6"
        variant={getButtonVariant({ isActive, status })}
        onClick={setRatingForSurface}
      />
    </>
  );
};

const getButtonVariant = ({
  isActive,
  status,
}: {
  isActive: boolean;
  status: JobStatus;
}): ButtonVariant => {
  if (isActive) {
    if (status === 'Fail') {
      return 'primaryAlert';
    }

    return 'primary';
  }

  return 'tertiary';
};

const getRatingForText = (
  text: Extract<JobStatus, 'Pass' | 'Fail'> | 'Just Pass',
) => {
  switch (text) {
    case 'Pass':
      return 1 as const;
    case 'Just Pass':
      return 3 as const;
    case 'Fail':
      return 5 as const;
    default:
      return null;
  }
};
