import React, { FC } from 'react';
import { ColorDetails, ColorPicker, SearchField } from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { productReviewsPageAtoms } from './product-reviews-filter.atoms';
import { reviewStatusOptions } from './product-reviews-filter.constacts';
import { useGetAllProducts } from '../../../api/products/routes';
import { useGetProductsListItems } from '../../common/select-product-form/components/select-product-form-content/components/product-search/hooks/use-get-products-list-items';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { FilterGrid, FilterRow } from '../../common/filter-grid';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';

const ProductReviewsFilter: FC = () => {
  const [product, setProduct] = useRecoilState(
    productReviewsPageAtoms.filters.productId,
  );
  const resetProduct = useResetRecoilState(
    productReviewsPageAtoms.filters.productId,
  );
  const [selectedColorCodes, setSelectedColorCodes] = useRecoilState(
    productReviewsPageAtoms.filters.colors,
  );

  const handleColorSelect = (selectedColorsDetails: ColorDetails[]) => {
    const newSelectedColorCodes = selectedColorsDetails
      ? selectedColorsDetails.map(({ code }) => {
          return code;
        })
      : [];

    setSelectedColorCodes(newSelectedColorCodes);
  };

  const { data, isLoading: isLoadingProducts } = useGetAllProducts();

  const productOptions = useGetProductsListItems(data?.products);

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={productReviewsPageAtoms.filters.productId}
          default={productReviewsPageAtoms.defaults.filters.productId}
          text="Product"
        />
        <SearchField
          disabled={isLoadingProducts}
          items={isLoadingProducts ? [] : productOptions}
          maxHeight="33rem"
          placeholder="Select product"
          value={product}
          onSelect={(item) => {
            setProduct(item?.value);

            if (!item?.value) {
              resetProduct();
            }
          }}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={productReviewsPageAtoms.filters.colors}
          default={productReviewsPageAtoms.defaults.filters.colors}
          text="Colors"
        />
        <ColorPicker
          value={selectedColorCodes}
          onValueChange={handleColorSelect}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={productReviewsPageAtoms.filters.statuses}
          default={productReviewsPageAtoms.defaults.filters.statuses}
          text="Review Status"
        />
        <AtomsCheckboxList
          atom={productReviewsPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={reviewStatusOptions}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default ProductReviewsFilter;
