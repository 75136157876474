import { capitalize } from 'lodash';
import { FailedReason } from '../api/jobs/jobs.types';
import { FailedReason as FailedReasonCode } from '../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../pages/jobs/components/modals/common/job-checklist/job-checklist.constants';

export const prepareFailureReasons = (failedReason: FailedReason) => {
  if (typeof failedReason.reason === 'string') {
    return failedReason.reason;
  }

  const failReasons = Object.keys(failedReason.reason || {})
    .filter((key) => {
      return failedReason.reason[key] !== 'Pass' && key !== 'freeText';
    })
    .map((code: FailedReasonCode) => {
      const { details } = failedReason.reason[code];
      const label =
        checklistDefaults[code]?.label ||
        /* fallback label */ capitalize(code.replace(/_/g, ' '));

      return { code, details, label };
    });

  return failReasons;
};
