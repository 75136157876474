import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedPayoutsArgs,
  FetchPaginatedPayoutsResponse,
} from './fetch-paginated-payouts.types';

export const fetchPaginatedPayouts = (args: FetchPaginatedPayoutsArgs) => {
  const {
    dateRange,
    itemsPerPage = 5,
    page = null,
    sortingField = null,
    sortingType = null,
    statuses = null,
  } = args;

  return axios.post<FetchPaginatedPayoutsResponse>(
    `${coreApiUrl}/paginatedPayouts`,
    {
      count: itemsPerPage,
      endDate: dateRange.to,
      page,
      sortingField,
      sortingType,
      startDate: dateRange.from,
      statuses,
    },
  );
};
