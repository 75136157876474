import { Dropdown, FormikInput, Stack, TextField } from 'gantri-components';
import { machineStatuses } from '../../../../../../constants/machines';
import { StatusItem } from '../../../../../machine/sections/general-section/status-item';
import { machineTypeOptions } from '../../../../machine.constants';
import { convertStringArrayToValueLabelArray } from '../../../../../../helpers/formatter';

export const AddOrEditMachineModalContent = () => {
  const machineStatusOptions = convertStringArrayToValueLabelArray([
    machineStatuses.ready,
    machineStatuses.manual,
    machineStatuses.offline,
  ]);

  return (
    <Stack gap="2x">
      <FormikInput
        Field={
          <TextField labelText="Name" placeholder="Machine Name" required />
        }
        name="name"
      />

      <FormikInput
        Field={
          <Dropdown
            items={machineTypeOptions}
            labelText="Type"
            placeholder="Machine Type"
            required
          />
        }
        name="type"
      />

      <FormikInput
        Field={
          <TextField labelText="Location" placeholder="Location" required />
        }
        name="location"
      />

      <FormikInput
        Field={
          <Dropdown
            items={machineStatusOptions}
            labelText="Status"
            maxHeight={400}
            placeholder="Please select"
            renderItem={(option) => {
              return <StatusItem showDescription status={option?.label} />;
            }}
            renderPlaceholder={(option) => {
              return (
                <StatusItem
                  status={option?.label}
                  style={{ paddingLeft: '.8rem' }}
                />
              );
            }}
            required
          />
        }
        name="status"
      />
    </Stack>
  );
};
