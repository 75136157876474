import { UserActivityType } from '../../../api/users/routes/get-user-activities/get-user-activities.types';
import { DateRangeFilter } from '../../../components/common/table/components/table-filters/table-filters.types';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const userActivityFiltersDefaults: {
  filters: {
    dateRange: DateRangeFilter;
    type: UserActivityType[];
  };
} = {
  filters: {
    dateRange: {
      from: null,
      to: null,
    },
    type: [],
  },
};

const atomKeyPrefix = 'USER_ACTIVITY';

export const pageName: AtomPageName = 'user-activity';

export const userActivityPageAtoms = {
  defaults: userActivityFiltersDefaults,
  filters: {
    dateRange: getGenericAtomFamily({
      defaultValue: userActivityFiltersDefaults.filters.dateRange,
      key: `${atomKeyPrefix}-date-range`,
    })(pageName),
    type: getGenericAtomFamily({
      defaultValue: userActivityFiltersDefaults.filters.type,
      key: `${atomKeyPrefix}-type`,
    })(pageName),
  },
};
