import PropTypes from 'prop-types';
import {
  StyledOuterContainer,
  StyledModalWrapper,
  StyledCloseButton,
} from '../modals-styles.module';

/**
 * @deprecated use useModal hook from gantri-components
 */
const BaseModal = ({ children, classnames, onClose }) => {
  return (
    <StyledOuterContainer>
      {!onClose ? null : (
        <StyledCloseButton
          className="x-icon-white"
          role="button"
          tabIndex="0"
          onClick={onClose}
        />
      )}
      <StyledModalWrapper className={classnames}>{children}</StyledModalWrapper>
    </StyledOuterContainer>
  );
};

BaseModal.defaultProps = {
  onClose: null,
};

BaseModal.propTypes = {
  children: PropTypes.node,
  classnames: PropTypes.string,
  onClose: PropTypes.func,
};

export default BaseModal;
