import { Button } from 'gantri-components';
import { fullScreenModalFooterButtonSize } from '../../../../../../../modals.constants';
import { CloseAssembleStageModalFooterProps } from './close-assemble-stage-modal-footer.types';

export const CloseAssembleStageModalFooter = (
  props: CloseAssembleStageModalFooterProps,
) => {
  const { handleCloseModal } = props;

  return (
    <Button
      size={fullScreenModalFooterButtonSize}
      text="Done"
      onClick={async () => {
        await handleCloseModal();
      }}
    />
  );
};
