import { useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { printQcModalAtoms } from '../../../../../complete-print-qa-modal.atoms';
import { HandleGetPrintJobsArgs } from './use-handle-get-print-jobs.types';

/** On success, sets the `step` to `'SELECT_JOB'`. */
export const useHandleGetPrintJobs = () => {
  const setAvailablePrintJobs = useSetRecoilState(
    printQcModalAtoms.availablePrintJobs,
  );
  const setStep = useSetRecoilState(printQcModalAtoms.step);
  const setSelectedPrintJob = useSetRecoilState(
    printQcModalAtoms.selectedPrintJob,
  );

  const { notifyAxiosError } = useNotification();

  return async (props: HandleGetPrintJobsArgs) => {
    try {
      const { partId } = props;

      const printJobsResponse = await jobsApi.getPartPrintJobs(partId);

      if (printJobsResponse.data.success) {
        const availablePrintJobs = printJobsResponse.data.jobs.filter(
          (item) => {
            return !item.alreadyReview;
          },
        );

        setAvailablePrintJobs(availablePrintJobs);

        if (availablePrintJobs.length === 1) {
          setSelectedPrintJob(availablePrintJobs[0]);
          setStep('CHECKLIST');
        } else {
          setStep('SELECT_JOB');
        }
      }
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fetch print jobs.',
      });
    }
  };
};
