import { GetRowProps, dataAttrRowIsClickable } from 'gantri-components';
import { getRowHref } from '../get-row-href';
import { ReportStocksTableData } from '../../report-stocks.types';

export const getRowProps: GetRowProps<ReportStocksTableData> = (row) => {
  const href = getRowHref(row);

  const onClick = () => {
    window.location.href = href;
  };

  return href
    ? {
        [dataAttrRowIsClickable]: '',
        onClick,
        text: href,
      }
    : {};
};
