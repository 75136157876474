import { FC, useState } from 'react';
import {
  Button,
  Conditional,
  Stack,
  Table,
  Typography,
} from 'gantri-components';
import { MachineJobsTableProps } from './machine-jobs-table-type';
import { getJobsRowProps } from '../../../../helpers/get-jobs-row-props';
import { useGetMachinePrintJobs } from '../../../../api/machines/routes/get-print-jobs';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';
import { useGetMachineJobsColumns } from './hooks/use-get-machine-jobs-columns';
import { Job } from '../../../../api/jobs/jobs.types';

export const MachineJobsTable: FC<MachineJobsTableProps> = (props) => {
  const { currentMachine, onRefreshData } = props;

  const [jobsToShow, setJobsToShow] = useState<Job[]>([]);

  const limitJobsTo = 20;

  const { data, isLoading } = useGetMachinePrintJobs({
    fetchArgs: {
      machineId: currentMachine.id,
    },
    onSuccess: async (data) => {
      const printJobs = data?.machine?.printJobs || [];

      // Reduce shown jobs to prevent long react response time
      const limitedJobsList = printJobs.slice(0, limitJobsTo);

      setJobsToShow(limitedJobsList);
    },
  });

  const printJobs = data?.machine?.printJobs || [];
  const numPrintJobs = printJobs.length;

  const { columns, handleRefresh } = useGetMachineJobsColumns({
    currentMachine,
    onRefresh: onRefreshData,
  });

  const showLoadMoreButton = numPrintJobs > jobsToShow.length;

  if (isLoading) {
    return <Typography text="Loading print jobs ..." />;
  }

  if (numPrintJobs === 0) {
    return <Typography text="No print jobs" />;
  }

  return (
    <Stack>
      <JobsDetailsPanelWrapper records={jobsToShow} onRefresh={handleRefresh}>
        <Table
          columns={columns}
          data={jobsToShow}
          getRowProps={getJobsRowProps}
          highlightHoveredRow
          stickyLastColumn
        />
      </JobsDetailsPanelWrapper>

      <Conditional condition={showLoadMoreButton}>
        <Button
          justifySelf="start"
          text="Load More"
          onClick={() => {
            setJobsToShow(printJobs);
          }}
        />
      </Conditional>
    </Stack>
  );
};
