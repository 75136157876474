import { FC } from 'react';
import PropTypes from 'prop-types';
import BaseModal from './base-modal';
import ConfirmationModal from './confirmation-modal';
import SelectionModal from './selection-modal';
import Overlay from './overlay';
import AlertModal from './alert-modal';
import FailModal from './fail-modal';
import { useParseDataAttributes } from '../../hooks/useParseDataAttributes';

/**
 * @deprecated use equivalent from gantri-components
 */
const Modal: FC<any> = ({ children, type, visible, ...rest }) => {
  // todo: do this temporarily until this component is replaced with ui library's modal
  const parsedDataAttributes = useParseDataAttributes(rest.dataAttributes);

  switch (type) {
    case 'confirmation': {
      return visible ? (
        <Overlay>
          <div
            className="modal-container"
            role="dialog"
            {...parsedDataAttributes}
          >
            <ConfirmationModal {...rest}>{children}</ConfirmationModal>
          </div>
        </Overlay>
      ) : null;
    }

    case 'selection': {
      return visible ? (
        <Overlay>
          <div
            className="modal-container"
            role="dialog"
            {...parsedDataAttributes}
          >
            <SelectionModal {...rest}>{children}</SelectionModal>
          </div>
        </Overlay>
      ) : null;
    }

    case 'alert': {
      return visible ? (
        <Overlay>
          <div
            className="modal-container"
            role="dialog"
            {...parsedDataAttributes}
          >
            <AlertModal {...rest}>{children}</AlertModal>
          </div>
        </Overlay>
      ) : null;
    }

    case 'fail': {
      return visible ? (
        <Overlay>
          <div
            className="modal-container"
            role="dialog"
            {...parsedDataAttributes}
          >
            <FailModal {...rest} />
          </div>
        </Overlay>
      ) : null;
    }

    default: {
      return visible ? (
        <Overlay>
          <div
            className="modal-container"
            role="dialog"
            {...parsedDataAttributes}
          >
            <BaseModal {...rest}>{children}</BaseModal>
          </div>
        </Overlay>
      ) : null;
    }
  }
};

Modal.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  visible: PropTypes.bool,
};

export default Modal;
