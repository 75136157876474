import axios from 'axios';
import omit from 'lodash/omit';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedGiftCodesArgs,
  FetchPaginatedGiftCodesResponse,
} from './fetch-paginated-giftcodes.types';

const getTypes = (args?: FetchPaginatedGiftCodesArgs) => {
  return (
    args?.types?.reduce(
      (acc, type) => {
        return {
          ...acc,
          [type]: true,
        };
      },
      { discount: false, gift: false },
    ) ?? { discount: false, gift: false }
  );
};

const query = async (
  args: FetchPaginatedGiftCodesArgs,
): Promise<FetchPaginatedGiftCodesResponse> => {
  const types = getTypes(args);

  const response = await axios.post<FetchPaginatedGiftCodesResponse>(
    `${coreApiUrl}/paginatedGiftCodes`,
    {
      ...omit(args, ['types']),
      types,
    },
  );

  return response.data;
};

const groupKey = () => {
  return 'paginated-gift-codes';
};

const key = (args?: FetchPaginatedGiftCodesArgs) => {
  const types = getTypes(args);

  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}/active/${args?.active ?? false}/page/${
        args?.page ?? 1
      }/search/${args?.search}/types/${JSON.stringify(types)}`,
    },
  ] as const;
};

export const fetchPaginatedGiftCodes = {
  groupKey,
  key,
  query,
};
