import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  UpdateFeatureSettingsArgs,
  UpdateFeatureSettingsResponse,
} from './update-feature-settings.types';

export const updateFeatureSettings = ({
  settings,
}: UpdateFeatureSettingsArgs) => {
  return axios.put<UpdateFeatureSettingsResponse>(
    `${baseApiUrl}/designer/feature-page`,
    { settings },
  );
};
