import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  GetInventoryResponse,
  GetInventoryTransactionsArgs,
} from './get-inventory.types';

const query = (args: GetInventoryTransactionsArgs) => {
  const { dateRange, inventoryId, ...body } = args;

  return axios
    .post<GetInventoryResponse>(
      `${coreApiUrl}/inventory/${inventoryId}/Transactions`,
      {
        endDateSec: dateRange?.to,
        startDateSec: dateRange?.from,
        ...body,
      },
    )
    .then((response) => {
      return response.data;
    });
};

const groupKey = (inventoryId: number) => {
  return `inventory-${inventoryId}-transactions`;
};

const key = (args: GetInventoryTransactionsArgs) => {
  const queries = [
    'inventory',
    `inventoryId=${args?.inventoryId}`,
    'transactions',
    `page=${args?.page}`,
    `search=${args?.search}`,
    `sortingField=${args?.sortingField}`,
    `sortingType=${args?.sortingType}`,
    `startDateSec=${args?.dateRange.from}`,
    `endDateSec=${args?.dateRange.to}`,
    `transactionType=${args?.transactionType?.join(',')}`,
    `types=${args?.types?.join(',')}`,
  ];

  return [
    {
      groupKey: groupKey(args?.inventoryId),
      key: queries.join('|'),
    },
  ];
};

export const getInventoryTransactions = {
  groupKey,
  key,
  query,
};
