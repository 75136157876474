import { Cell, Conditional, Typography } from 'gantri-components';
import { StyledToggleIcon } from './mobile-toggle-header.styles';
import { MobileToggleHeaderProps } from './mobile-toggle-header.types';

export const MobileToggleHeader = (props: MobileToggleHeaderProps) => {
  const { isExpanded, title, toggleIsExpanded } = props;

  return (
    <Conditional condition={!!title}>
      <Cell
        hidden={{ lg: true, sm: false }}
        paddingBottom={isExpanded ? '1.6rem' : undefined}
      >
        <Typography
          icon={
            <StyledToggleIcon
              color="t2"
              name={`arrows:arrow_chevron_${isExpanded ? 'up' : 'down'}`}
              size="1.6rem"
              onClick={toggleIsExpanded}
            />
          }
          iconPosition="right"
          text={title}
          textStyle="bold"
          variant="h4"
        />
      </Cell>
    </Conditional>
  );
};
