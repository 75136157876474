/** If `qty` is not `1`, returns the plural. By default, adds an `s` to the end, but `plural` can be provided for alternative spelling. */
export const pluralize = (
  word: string,
  qty: number,
  options?: {
    /** If provided, returns `plural` instead of just adding `s`. */
    plural?: string;
  },
) => {
  if (qty === 1) {
    return word;
  }

  return options?.plural || `${word}s`;
};
