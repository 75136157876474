import { Button } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useBulkStartPrintPrepareQueueSetJobs } from '../../../../../../../../api/queue/print-prepare';
import { pluralize } from '../../../../../../../../helpers/pluralize';
import { fullScreenModalFooterButtonSize } from '../../../../modals.constants';
import { useGetNumJobsInSet } from '../../../hooks/get-num-jobs-in-set';
import { printPrepareQueueModalAtoms } from '../../../print-prepare-queue-modal.atoms';
import { printPrepareQueueModalSteps } from '../../../print-prepare-queue-modal.constants';

export const JobSetStartFooter = () => {
  const [queueData, setQueueData] = useRecoilState(
    printPrepareQueueModalAtoms.queueData,
  );
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );
  const setStep = useSetRecoilState(printPrepareQueueModalAtoms.step);

  const numJobsInSet = useGetNumJobsInSet();

  const { isLoading, onBulkStartPrintPrepareQueueSetJobs } =
    useBulkStartPrintPrepareQueueSetJobs({
      onDeleted: () => {
        setStep(printPrepareQueueModalSteps.queueDeleted);
      },
    });

  return (
    <Button
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text={`Start ${pluralize('job', numJobsInSet)}`}
      onClick={async () => {
        const { printPrepareQueue } = await onBulkStartPrintPrepareQueueSetJobs(
          {
            queueId: queueData?.id,
            returnQueue: true,
            setIndex: activeSetIndex,
          },
        );

        setQueueData(printPrepareQueue);
        setStep(printPrepareQueueModalSteps.jobSetPrepare);
      }}
    />
  );
};
