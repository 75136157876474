import { Grid } from 'gantri-components';
import { ResponsiveGridProps } from './responsive-grid.types';

export const ResponsiveGrid = (props: ResponsiveGridProps) => {
  return (
    <Grid
      alignItems="start"
      columnGap="3rem"
      columns={{
        lg: 'repeat(5, 1fr)',
        md: 'repeat(3, 1fr)',
        sm: 'repeat(2, 1fr)',
      }}
      rowGap="2rem"
      {...props}
    />
  );
};
