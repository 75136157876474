import { Dropdown, GetRowProps } from 'gantri-components';
import { useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { DashboardTableData } from '../../../../../../../api/dashboard/dashboard.types';
import { getSortMapping } from '../../../../../../../components/common/table/components/table-actions/helpers/get-sort-mapping';
import { useTableFilters } from '../../../../../../../components/common/table/hooks/use-table-filters';
import { FiltersFetchRequestArgs } from '../../../../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../../helpers/checks';
import { useOnWindowResize } from '../../../../../../../hooks/use-on-window-resize';
import { useSorting } from '../../../../../../../hooks/use-sorting';
import { reportPageSortByDefaults } from './report-table.constants';
import {
  dataAttrTotalRow,
  StyledReportTableWrapper,
  StyledTable,
} from './report-table.styles';
import { ReportTableProps, TransformDownload } from './report-table.types';

export const ReportTable = (props: ReportTableProps) => {
  const {
    formattedEndDate,
    formattedStartDate,
    getRowProps,
    groupByIsVisible,
    groupByItems,
    groupByOnChange,
    groupByValue,
    isFetching,
    pageName,
    setDownloadData,
    sortByAtom,
    sortingFields,
    tableColumns,
    tableData,
    totalRow,
  } = props;

  const queries = getActiveQueries();
  const { page } = queries;
  const [tableDimensions, setTableDimensions] = useState<{
    height: number;
    width: number;
  }>({ height: 0, width: 0 });

  const getFinalRowProps: GetRowProps<DashboardTableData[number]> = (row) => {
    const rowProps = getRowProps?.(row) || {};
    const { original } = row;
    const isTotalRow = /^total$/i.test(original.type);

    return isTotalRow ? { ...rowProps, [dataAttrTotalRow]: '' } : rowProps;
  };

  const { setSortingField, setSortingType, sortedData } = useSorting({
    data: tableData,
    // @ts-expect-error
    fieldsMapping: getSortMapping(sortingFields),
    initialSortingField: reportPageSortByDefaults.sortingField,
    initialSortingType: reportPageSortByDefaults.sortingType,
  });

  const pageSize = 50;

  const fetchReportData = async (
    args: FiltersFetchRequestArgs<Record<string, any>, 'type' | string>,
  ) => {
    const { sortingField, sortingType } = args;

    setSortingField(sortingField);
    setSortingType(sortingType);
  };

  const {
    currentPage,
    handleFetchRequest,
    pagingProps,
    records,
    setCurrentPage,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    Record<any, any>,
    FiltersFetchRequestArgs<Record<string, any>, 'type' | string>,
    any
  >({
    fetchRequest: fetchReportData,
    pageName,
    pageSize,
    sortByFilterDetails: {
      atom: sortByAtom,
      defaultValue: reportPageSortByDefaults,
    },
    sortOptions: sortingFields,
    startingPage: page ? +page : 1,
  });

  const allTableDataWithTotal =
    !!sortedData.length && totalRow ? [...sortedData, totalRow] : sortedData;

  const GroupByDropdown = groupByIsVisible
    ? () => {
        return (
          <Dropdown
            items={groupByItems}
            value={groupByValue}
            onChange={({ target }) => {
              return groupByOnChange(target.value);
            }}
          />
        );
      }
    : undefined;

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const pagingVisible = tableData.length > pageSize;

  useOnWindowResize(() => {
    const tableActionsHeight = 50;
    const paginationHeight = pagingVisible ? 50 : 0;
    const height = tableWrapperRef.current?.offsetHeight;
    const width = tableWrapperRef.current?.offsetWidth;
    const tableHeight = height - tableActionsHeight - paginationHeight;

    setTableDimensions({ height: tableHeight, width });
  });

  const resetSortByAtom = useResetRecoilState(sortByAtom);
  const { sortingField: currentSortingField } = useRecoilValue(sortByAtom);

  useEffect(() => {
    setCurrentPage(1);
  }, [pagingProps.total]);

  useEffect(() => {
    const sortingFieldMatches = sortingFields.some(({ sortingField }) => {
      return sortingField === currentSortingField;
    });

    if (!sortingFieldMatches) {
      resetSortByAtom();
    }
  }, [sortingFields]);

  useEffect(() => {
    const queryString = convertQueriesToString({
      ...queries,
      page: currentPage,
    });
    const { origin, pathname } = window.location;

    window.history.replaceState(
      { page: currentPage },
      '',
      origin + pathname + queryString,
    );
  }, [currentPage, window.location.search]);

  useEffect(() => {
    const endIndex = currentPage * pageSize;
    const startIndex = endIndex - pageSize;
    let records = sortedData.slice(startIndex, endIndex);

    if (currentPage === pagingProps.pageCount && totalRow) {
      records = [...records, totalRow];
    }

    setRecords(records);
  }, [currentPage, sortedData]);

  useEffect(() => {
    setTotalCount(tableData.length);
  }, [tableData.length]);

  useAsync(async () => {
    if (!isFetching) {
      await handleFetchRequest({ page: currentPage });

      const hasDownloadTransforms = tableColumns.some(
        ({ transformDownload }) => {
          return !!transformDownload;
        },
      );

      if (hasDownloadTransforms) {
        type TransformDownloadMap = Record<string, TransformDownload>;
        const transformDownloadMap = tableColumns.reduce<TransformDownloadMap>(
          (accumulator, { accessorKey, id, transformDownload }) => {
            const dataKeyName = id || String(accessorKey);

            return transformDownload
              ? { ...accumulator, [dataKeyName]: transformDownload }
              : accumulator;
          },
          {} as TransformDownloadMap,
        );

        setDownloadData(
          allTableDataWithTotal.map((rowData: Record<string, any>) => {
            const transformedRow = Object.keys(rowData).reduce(
              (accumulator, keyName) => {
                const transformDownload = transformDownloadMap[keyName];
                const oldValue = rowData[keyName];
                const newValue = transformDownload
                  ? transformDownload(oldValue)
                  : oldValue;

                return {
                  ...accumulator,
                  [keyName]: newValue,
                };
              },
              {} as typeof rowData,
            );

            return transformedRow;
          }),
        );
      } else {
        setDownloadData(allTableDataWithTotal);
      }
    }
  }, [isFetching, formattedEndDate, formattedStartDate, sortedData]);

  return tableData ? (
    <StyledReportTableWrapper ref={tableWrapperRef}>
      <StyledTable
        $tableDimensions={tableDimensions}
        columns={tableColumns}
        customAction={
          GroupByDropdown
            ? { Component: GroupByDropdown, position: 'left' }
            : undefined
        }
        data={records}
        getRowProps={getFinalRowProps}
        paging={pagingProps}
        sorting={sortProps}
      />
    </StyledReportTableWrapper>
  ) : null;
};
