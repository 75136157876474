import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import { useNotification } from '../../../../hooks/useNotification';
import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { GetLocationsResponse, GetLocationsType } from './get-locations.types';
import { getLocations } from './get-locations';

interface Props<TData> extends UseQueryProps<TData, GetLocationsResponse> {
  type: GetLocationsType;
}

export const useGetLocationsQuery = (
  props?: Props<GetLocationsResponse['locations']>,
) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<GetLocationsResponse>({
    config: {
      enabled: props?.enabled || true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch locations.',
        });

        props?.onError?.(error);
      },
      queryFn: () => {
        return getLocations.query(props.type);
      },
      queryKey: getLocations.key(),
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    isLoading,
    ...data,
    locations: data?.locations || [],
  };
};

export const useInvalidateGetLocationsCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateGetLocationsCache = async (search?: string) => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey, key } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return !!search
        ? key === getLocations.key(search)[0].key
        : groupKey === getLocations.groupKey();
    });
  };

  return { invalidateGetLocationsCache };
};
