import { useRecoilValue } from 'recoil';
import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { completeAssemblyModalAtoms } from '../../complete-assembly-modal.atoms';
import { InventoryChecklistContent } from '../step-inventory-checklist/inventory-checklist-content';
import { ExceededCycleTimeContent } from '../step-exceeded-cycle-time/exceeded-cycle-time-content';
import { AssemblyCompletedFailedContent } from '../step-assembly-completed-failed/assembly-completed-failed-content';
import { PartChecklistContent } from '../step-part-checklist/part-checklist-content';
import { JobChecklistContent } from '../step-job-checklist/job-checklist-content';

export const CompleteAssemblyModalContent = () => {
  const step = useRecoilValue(completeAssemblyModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN': {
      return <ConfirmBeginContent />;
    }

    case 'INVENTORY_CHECKLIST':
      return <InventoryChecklistContent />;

    case 'JOB_CHECKLIST':
      return <JobChecklistContent />;

    case 'PART_CHECKLIST':
      return <PartChecklistContent />;

    case 'ASSEMBLY_COMPLETED_FAILED':
      return <AssemblyCompletedFailedContent />;

    case 'JOB_FAILED_EXCEEDED_CYCLE_TIME':
    case 'JOB_PASSED_EXCEEDED_CYCLE_TIME':
      return <ExceededCycleTimeContent />;

    default:
      return null;
  }
};
