import { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Typography } from 'gantri-components';
import { Link } from 'react-router-dom';
import {
  StyledFieldIconWrapper,
  StyledTextAndIconContainer,
  StyledTooltipTypography,
} from '../custom-data-cell-styles';
import Tooltip from '../../../common/tooltip';
import { formatDate } from '../../../../helpers/formatter';
import routePaths from '../../../../config/route-paths';
import { LONG_FORMAT } from '../../../../constants/dates';

const NameCell = ({ assignedInfo, description = '', name = '' }) => {
  return (
    <StyledTextAndIconContainer>
      <Typography color="t1" text={name || description} variant="p2" />
      {assignedInfo && assignedInfo.userId && (
        <Tooltip
          content={
            <>
              <StyledTooltipTypography
                color="t2"
                text={assignedInfo.description}
              />
              <Link to={`${routePaths.users}/${assignedInfo.userId}`}>
                <StyledTooltipTypography
                  color="link"
                  text={` ${assignedInfo.userName} `}
                />
              </Link>
              <StyledTooltipTypography
                color="t2"
                text={formatDate(assignedInfo.assignedAt, LONG_FORMAT)}
              />
            </>
          }
        >
          <StyledFieldIconWrapper>
            <Icon color="t2" name="alert:i_circle" />
          </StyledFieldIconWrapper>
        </Tooltip>
      )}
    </StyledTextAndIconContainer>
  );
};

NameCell.propTypes = {
  assignedInfo: PropTypes.shape({
    assignedAt: PropTypes.number,
    description: PropTypes.string,
    userId: PropTypes.number,
    userName: PropTypes.string,
  }),
  description: PropTypes.string,
  name: PropTypes.string,
  partIdToPartMap: PropTypes.object,
};

export default memo(NameCell);
