import { getFileUrl } from 'gantri-components';
import { FC } from 'react';
import { Image } from '../image';
import { Location } from '../location';
import { IconInstagram } from './components/icon-instagram';
import { FrontCardProps } from './front-card.types';

export const FrontCard: FC<FrontCardProps> = ({ designerInfo }) => {
  const { handout, headshotUrl, links, logoUrl, name, profileLink, userId } =
    designerInfo;

  const headshotImgSrc = getFileUrl<'designers'>({
    directory: 'designers',
    fileName: headshotUrl,
    fileType: 'headshot',
    identifiers: {
      userId,
    },
  });

  const logoImgSrc = getFileUrl<'designers'>({
    directory: 'designers',
    fileName: logoUrl,
    fileType: 'logo',
    identifiers: {
      userId,
    },
  });

  return (
    <div className="handout--card-bleed-border handout--front-bleed-border">
      <div className="handout--card handout--card-front">
        <table className="handout--front-top-section">
          <tbody>
            <tr>
              <td>
                <div className="handout--front-logo-wrapper">
                  <div className="handout--logo-wrapper">
                    <Image altText={name} isLogo src={logoImgSrc} />
                  </div>
                </div>
                {handout?.nameIsVisible && (
                  <div className="handout--front-name-wrapper">
                    <p>
                      <strong>{name}</strong>
                    </p>
                  </div>
                )}
                <div className="handout--front-location-wrapper">
                  <Location designerInfo={designerInfo} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="handout--front-designer-image-wrapper">
          <Image altText={name} src={headshotImgSrc} />
        </div>
        <div className="handout--front-bio-wrapper">
          <p>{handout?.bio}</p>
        </div>
        <div className="handout--front-bottom-links-wrapper">
          <div className="handout--store-path">
            <table>
              <tbody>
                <tr>
                  <td>
                    <p>{`gantri.com/${profileLink}`}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="handout--instagram">
            {links?.instagram && (
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="handout--instagram-icon">
                        <IconInstagram color={handout.frontTextColor} />
                      </div>
                    </td>
                    <td>
                      <div className="handout--instagram-username">
                        {`@${links?.instagram}`}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
