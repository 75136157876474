import React, { FC, useState } from 'react';
import {
  Badge,
  Box,
  Dropdown,
  Flex,
  FormikInput,
  Grid,
  Image,
  FileUploader,
  HandleDeleteFile,
  HandleUploadsComplete,
} from 'gantri-components';
import { useTheme } from 'styled-components';
import { useField } from 'formik';
import { GeneralSectionProps } from './general-section.types';
import { convertObjectToDropdownMenuItems } from '../../../../helpers/transformers';
import {
  inventoryStatuses,
  inventoryTypes,
} from '../../../../constants/options';
import { formatAsCurrency } from '../../../../helpers/formatter';
import { Divider } from '../../../../components/divider';
import { currencyOptions } from '../../../../constants/inventory-purchases';
import { TableHeaderWithInfoToolTip } from '../../../../components/common/custom-data-cells/headers';
import { FormItem } from '../../components/form-item';
import { useFirebaseFileUploader } from '../../../../hooks/use-firebase-file-uploader';
import { getActiveEnv } from '../../../../helpers/get-active-env';

export const GeneralSection: FC<GeneralSectionProps> = (props) => {
  const { currentInventory: inventory, isEditModeActive } = props;
  const theme = useTheme();
  const [currentThumbnail, setCurrentThumbnail] = useState(
    inventory?.thumbnailUrl,
  );
  const [, , thumbnailHelper] = useField('thumbnailUrl');

  const onFileDelete: HandleDeleteFile = async () => {
    setCurrentThumbnail('');
    await thumbnailHelper.setValue('');
  };

  const handleUploadsComplete: HandleUploadsComplete = async ([
    { fileUrl },
  ]) => {
    setCurrentThumbnail(fileUrl);
    await thumbnailHelper.setValue(fileUrl);
  };

  const { fileUploaderProps } = useFirebaseFileUploader({
    fileUrl: currentThumbnail,
    handleUploadsComplete,
    onFileDelete,
    path: `inventories/${inventory.id}`,
  });

  const { isLocalEnv } = getActiveEnv();

  return (
    <Grid alignItems="center" columnGap="s4" columns="max-content 1fr">
      <FormItem
        isEditMode={isEditModeActive}
        viewElement={
          <Box height="10rem" width="10rem">
            <Image
              alt=""
              height={100}
              source={currentThumbnail ? 'absolute' : 'static'}
              src={currentThumbnail || 'core/common/default-inventory_o0ldhl'}
              style={{ minHeight: '10rem', minWidth: '10rem' }}
              width={100}
            />
          </Box>
        }
      >
        <FileUploader
          {...fileUploaderProps}
          enableCopyUrl={isLocalEnv}
          fileName={null}
          maxFileSizeMB={5}
          minImageHeight={100}
          minImageWidth={100}
          thumbnailSize="10rem"
          variant="thumbnail"
        />
      </FormItem>

      <Grid
        alignItems="flex-start"
        columnGap="3x"
        columns="repeat(auto-fill, minmax(15rem, max-content))"
        rowGap="3x"
        rows={isEditModeActive ? '6.3rem' : 'unset'}
      >
        <Flex alignItems="flex-start" gap="3x" height="100%">
          <FormItem
            isEditMode={isEditModeActive}
            label="Type"
            viewElement={inventory?.type}
          >
            <FormikInput
              Field={
                <Dropdown
                  items={convertObjectToDropdownMenuItems(inventoryTypes)}
                  placeholder="Select a type"
                />
              }
              name="type"
            />
          </FormItem>

          <Divider borderLocation="left" height="100%" />
        </Flex>

        <Flex alignItems="flex-start" gap="3x" height="100%">
          <FormItem
            isEditMode={isEditModeActive}
            label="Unit"
            viewElement={inventory.unit}
          >
            <FormikInput name="unit" placeholder="Enter a unit" required />
          </FormItem>

          <Divider borderLocation="left" height="100%" />
        </Flex>

        <Flex alignItems="flex-start" gap="3x" height="100%">
          <FormItem
            isEditMode={isEditModeActive}
            label="Status"
            viewElement={
              <Badge
                borderColor={
                  inventory?.status === 'Active'
                    ? theme.colors.surfaces.green.t2
                    : theme.colors.surfaces.monochrome.t3
                }
                color={
                  inventory?.status === 'Active'
                    ? theme.colors.surfaces.green.t1
                    : theme.colors.surfaces.monochrome.overlayLevel2
                }
                text={inventory?.status}
                textColor={theme.colors.typography.t1}
                width="min-content"
              />
            }
          >
            <FormikInput
              Field={
                <Dropdown
                  items={convertObjectToDropdownMenuItems(inventoryStatuses)}
                  placeholder="Select a status"
                />
              }
              name="status"
            />
          </FormItem>

          <Divider borderLocation="left" height="100%" />
        </Flex>

        <Flex alignItems="flex-start" gap="3x" height="100%">
          <FormItem
            label={
              <TableHeaderWithInfoToolTip
                headerText={`Cost ${
                  currencyOptions[inventory?.currency]?.symbol || '$'
                }`}
                position="bottom"
                tooltipText="Unit cost from last purchase transaction."
              />
            }
            viewElement={formatAsCurrency(Number(inventory?.unitCost), {
              currency: inventory?.currency || 'USD',
              isCents: true,
            })}
          />

          <Divider borderLocation="left" height="100%" />
        </Flex>

        <FormItem label="Stock" viewElement={String(inventory?.stock)} />
      </Grid>
    </Grid>
  );
};
