import { formatDate } from '../../../../helpers/formatter';
import {
  getPastMonthsDates,
  getPastWeekDates,
  getYesterdayDates,
  useOnDateRangeTypeChange,
} from '../../components/common/dashboard-heading/hooks/use-on-date-range-type-change';
import { AllDashboardDateRangeTypes } from '../../dashboard.types';

/** If `rangeType` !== `'Custom'`, checks that `endDate` is actually set to yesterday (can happen when at least one day has passed without the local storage being cleared). If it is not, resets the date ranges based off of the `rangeType`. */
export const useValidateEndDate = ({
  endDate,
  rangeType,
}: {
  endDate: string;
  rangeType: AllDashboardDateRangeTypes;
}) => {
  const { onDateRangeTypeChange } = useOnDateRangeTypeChange();

  if (rangeType !== 'Custom') {
    const formattedEndDate = formatDate(endDate);
    const formattedExpectedEndDate = getExpectedEndDate(rangeType);

    if (formattedEndDate !== formattedExpectedEndDate) {
      onDateRangeTypeChange(rangeType);

      return false;
    }
  }

  return true;
};

const getExpectedEndDate = (
  rangeType: Exclude<AllDashboardDateRangeTypes, 'Custom'>,
): string => {
  switch (rangeType) {
    case 'Past week': {
      const { to } = getPastWeekDates();

      return formatDate(to);
    }

    case 'Past month': {
      const { to } = getPastMonthsDates(1);

      return formatDate(to);
    }

    case 'Past 3 months': {
      const { to } = getPastMonthsDates(3);

      return formatDate(to);
    }

    case 'Yesterday':

    default: {
      const { to } = getYesterdayDates();

      return formatDate(to);
    }
  }
};
