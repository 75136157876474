import { Row } from '@tanstack/react-table';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultMachinesGroupBy } from '../../report-machines.constants';
import {
  ReportMachinesQueries,
  ReportMachinesTableData,
} from '../../report-machines.types';
import { ReportMachineType } from '../../../../../../api/dashboard/routes/get-report-machines/get-report-machines.types';

export const getRowHref = (row: Row<ReportMachinesTableData>) => {
  const windowLocation = window.location.origin + window.location.pathname;
  const queries = getActiveQueries<ReportMachinesQueries>();
  const { groupBy = defaultMachinesGroupBy, machineId, machineType } = queries;

  const rowData = row?.original;
  const isTotalRow = /^total$/i.test(rowData?.type);
  const isIssuesPage = groupBy && (machineId || machineType);

  if (isTotalRow || isIssuesPage) {
    return;
  }

  if (/^machineName$/i.test(groupBy) && !machineId) {
    const queryString = convertQueriesToString<ReportMachinesQueries>({
      groupBy,
      machineId: String(rowData?.info?.id),
      machineName: rowData?.info?.name,
    });

    return windowLocation + queryString;
  }

  if (/^machineType$/i.test(groupBy) && !machineType) {
    const queryString = convertQueriesToString<ReportMachinesQueries>({
      groupBy,
      machineType: rowData?.type as ReportMachineType,
    });

    return windowLocation + queryString;
  }
};
