import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import { AddCreditArgs, AddCreditResponse } from './add-credit.types';

export const addCredit = ({ amount, reason, userId }: AddCreditArgs) => {
  return axios.post<AddCreditResponse>(`${baseApiUrl}/credit`, {
    amount,
    reason,
    userId,
  });
};
