import styled, { css } from 'styled-components';
import { media, palette, Typography } from 'gantri-components';
import { mediaQueries } from '../../constants/styles';

export const StyledPage = styled.div`
  margin-bottom: 120px;

  ${mediaQueries.tablet(css`
    margin-bottom: 60px;
  `)}
`;

export const StyledPageSection = styled.div<{
  tablePage?: boolean;
  transitionName?: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1.6rem;
  gap: 2.4rem;

  ${({ tablePage }) => {
    return (
      !tablePage &&
      css`
        ${media.greaterThan('lg')`
        padding-bottom: 3rem;
      `}

        ${media.lessThan('lg')`
        margin-bottom: 3rem}
      `}
      `
    );
  }};
`;

export const StyledPageSubsection = styled.div<{
  collapseMargin?: boolean;
  collapsed?: boolean;
  collapsible?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${({ collapseMargin, collapsed, collapsible }) => {
    return (
      collapsible &&
      css`
        padding: 3rem 0;
        border-top: 1px solid ${palette.monochrome_200};
        border-bottom: 1px solid ${palette.monochrome_200};
        max-height: ${collapsed ? '9.5rem' : 'auto'};
        ${collapseMargin &&
        css`
          &:not(:last-child) {
            margin-bottom: 0;
          }

          border-bottom: none;
        `}
      `
    );
  }}
`;

export const PaddedStyledPageSubsection = styled(StyledPageSubsection)`
  padding-bottom: 6.4rem;
`;

export const StyledDoubleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledDoubleChild = styled.div<{
  minWidth?: string;
}>`
  flex: 1;
  ${(props) => {
    return props.minWidth && `min-width: ${props.minWidth};`;
  }}
  margin-bottom: 3rem;

  &:not(:last-child) {
    margin-right: 3rem;
  }
`;

export const StyledInlineTypography = styled(Typography)`
  display: inline;
`;
