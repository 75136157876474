import {
  useInvalidateQueryByPredicate,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { useNotification } from '../../../../hooks/useNotification';
import { getAvailableJobsForPrintPrepareQueue } from './get-available-print-prepare-queue-jobs';

export const useGetAvailableJobsForPrintPrepareQueue = (args?: {
  showLoading: boolean;
}) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage:
            'Unable to fetch available jobs for print prepare queues.',
        });
      },
      queryFn: () => {
        return getAvailableJobsForPrintPrepareQueue.query();
      },
      queryKey: getAvailableJobsForPrintPrepareQueue.key(),
    },
    showLoading: args?.showLoading,
  });

  return {
    ...data,
    isLoading,
  };
};

export const useInvalidateGetAvailableJobsForPrintPrepareQueueCache = () => {
  const invalidate = useInvalidateQueryByPredicate();

  const invalidateGetAvailableJobsForPrintPrepareQueueCache = async () => {
    await invalidate((query) => {
      if (typeof query.queryKey[0] === 'string') return false;

      const { groupKey } = query.queryKey[0] as {
        groupKey: string;
        key: string;
      };

      return groupKey === getAvailableJobsForPrintPrepareQueue.groupKey();
    });
  };

  return { invalidateGetAvailableJobsForPrintPrepareQueueCache };
};
