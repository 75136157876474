import { Radio } from 'gantri-components';
import { LargeRadioWrapper } from './radio-button.styles';
import { RadioButtonProps } from './radio-button.types';

export const RadioButton = <T extends string>(props: RadioButtonProps<T>) => {
  const { checked, groupValue, onSelected, value, ...rest } = props;

  const onClick = () => {
    onSelected(groupValue);
  };

  return (
    <LargeRadioWrapper $checked={checked} padding="2x" onClick={onClick}>
      <Radio
        {...rest}
        groupValue={groupValue}
        value={value}
        onSelected={onClick}
      />
    </LargeRadioWrapper>
  );
};
