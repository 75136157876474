import { useState } from 'react';
import { Conditional, TextArea, Typography } from 'gantri-components';
import { EditableTextRow } from '../../../../components/common/editable-text-row';
import { NoteProps } from './notes.types';
import { transactionsApi } from '../../../../api';
import { useNotification } from '../../../../hooks/useNotification';

export const Notes = (props: NoteProps) => {
  const { currentOrderData, onUpdate } = props;
  const { notify, notifyAxiosError } = useNotification();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editNotes, setEditNotes] = useState<string>('');
  const { id: orderId, notes } = currentOrderData;

  const handleEdit = () => {
    setEditNotes(notes || '');
    setEditMode(true);
  };

  const handleSave = async () => {
    try {
      const { data } = await transactionsApi.updateTransactionNote({
        notes: editNotes,
        transactionId: orderId,
      });

      notify(data.notice);

      if (data && data.success) {
        onUpdate?.(editNotes);
      }

      setEditMode(false);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update order notes.',
      });
    }
  };

  const handleCancel = () => {
    setEditNotes('');
    setEditMode(false);
  };

  const handleChange = (e) => {
    setEditNotes(e.target.value);
  };

  return (
    <>
      <EditableTextRow
        editing={editMode}
        marginBottom="10px"
        text="Notes"
        onCancel={handleCancel}
        onEdit={handleEdit}
        onSave={handleSave}
      />

      <Conditional
        condition={editMode}
        Fallback={<Typography color="t1" text={notes} />}
      >
        <TextArea
          minRows={3}
          name="notes"
          placeholder="Notes"
          style={{ width: '100%' }}
          value={editNotes}
          onChange={handleChange}
        />
      </Conditional>
    </>
  );
};
