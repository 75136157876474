import {
  Checklist,
  ChecklistItem,
  ChecklistKeyType,
} from '../../common/job-checklist/job-checklist.types';

/** Returns items where `status !== 'Pass'`. */
export const getFailingReasonsFromChecklist = <
  KeyType extends ChecklistKeyType,
>(
  checklist: Partial<Checklist<KeyType>>,
): KeyType[] => {
  // While this could be written cleaner, doing so causes TS issues due to how TS intentionally handles `Object.keys()`.
  const failingChecklistKeys = Object.values(checklist || {})
    .map((item: ChecklistItem, index) => {
      const checklistKeys = Object.keys(checklist).map((key) => {
        const isNaN = Number.isNaN(Number(key));

        return isNaN ? key : +key;
      }) as KeyType[];

      return item.status !== 'Pass' ? checklistKeys[index] : null;
    })
    .filter((key) => {
      return !!key;
    });

  return failingChecklistKeys;
};
