import { Button, Conditional } from 'gantri-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../api';
import { jobTypeOptions } from '../../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { checkAgainstChecklistItems } from '../../../../../helpers/check-against-checklist-items';
import { getFailJobReasonArg } from '../../../../../helpers/get-fail-job-reason-arg';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { CompleteChecklistFooterProps } from './complete-checklist-footer.types';

export const CompleteChecklistFooter = (
  props: CompleteChecklistFooterProps,
) => {
  const { handleCloseModal, job } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const checklist = useRecoilValue(completeQaQcModalAtoms.checklist);
  const setDuplicatedJobs = useSetRecoilState(
    completeQaQcModalAtoms.duplicatedJobs,
  );
  const isChecklistReviewed = useRecoilValue(
    completeQaQcModalAtoms.isChecklistReviewed,
  );
  const setStep = useSetRecoilState(completeQaQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeQaQcModalAtoms.updateOnClose,
  );

  const isChecklistFailing = checkAgainstChecklistItems(
    checklist,
    ({ status }) => {
      return status === 'Fail';
    },
  );

  const onComplete = async () => {
    try {
      showLoading();

      const data = await jobsApi.completeJob({
        jobId: job.id,
      });

      notify(data.notice);

      await handleCloseModal({ updateOnClose: true });
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to complete job.',
      });
    } finally {
      hideLoading();
    }
  };

  const onFail = async () => {
    try {
      showLoading();

      const reason = getFailJobReasonArg(checklist);
      const response = await jobsApi.failJob({
        duplicateType: 'Reassemble',
        jobId: job.id,
        reason,
      });

      if (!!response.data.duplicatedJobs?.length) {
        setDuplicatedJobs(response.data.duplicatedJobs);
        setUpdateOnClose(true);
      }

      setStep('JOB_FAILED');
      notify(response.data.notice);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to fail job.',
      });
    } finally {
      hideLoading();
    }
  };

  return (
    <Conditional
      condition={isChecklistFailing}
      Fallback={
        <Button
          disabled={!isChecklistReviewed}
          size={fullScreenModalFooterButtonSize}
          text={`Confirm ${jobTypeOptions.qc} Completion`}
          variant="primary"
          onClick={onComplete}
        />
      }
    >
      <Button
        disabled={!isChecklistReviewed}
        size={fullScreenModalFooterButtonSize}
        text="Fail QC"
        variant="primaryAlert"
        onClick={onFail}
      />
    </Conditional>
  );
};
