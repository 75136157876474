import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { ReportSalesQueries } from '../../report-sales.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Sales';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportSalesQueries>();
  const {
    color,
    country,
    creatorId,
    creatorName,
    customerType,
    groupBy,
    productId,
    state,
  } = queries;

  if (/^productId$/i.test(groupBy) && productId) {
    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'productId' }),
    };

    return [rootBreadcrumb];
  }

  if (/^color$/i.test(groupBy) && color) {
    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'color' }),
    };

    return [rootBreadcrumb];
  }

  if (/^creatorId$/i.test(groupBy) && creatorId) {
    if (productId) {
      const rootBreadcrumb: Breadcrumb = {
        name: rootPageName,
        path: location + convertQueriesToString({ groupBy: 'creatorId' }),
      };
      const subPageBreadcrumb: Breadcrumb = {
        name: creatorName,
        path:
          location +
          convertQueriesToString({
            creatorId,
            creatorName,
            groupBy: 'creatorId',
          }),
      };

      return [rootBreadcrumb, subPageBreadcrumb];
    }

    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'creatorId' }),
    };

    return [rootBreadcrumb];
  }

  if (/^customerType$/i.test(groupBy) && customerType) {
    if (productId) {
      const rootBreadcrumb: Breadcrumb = {
        name: rootPageName,
        path: location + convertQueriesToString({ groupBy: 'customerType' }),
      };
      const subPageBreadcrumb: Breadcrumb = {
        name: `${customerType} Customer`,
        path:
          location +
          convertQueriesToString({ customerType, groupBy: 'customerType' }),
      };

      return [rootBreadcrumb, subPageBreadcrumb];
    }

    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'customerType' }),
    };

    return [rootBreadcrumb];
  }

  if (/^country$/i.test(groupBy) && country) {
    if (!/^united states$/i.test(country)) {
      const rootBreadcrumb: Breadcrumb = {
        name: rootPageName,
        path: location + convertQueriesToString({ groupBy: 'country' }),
      };

      return [rootBreadcrumb];
    }

    if (state) {
      const rootBreadcrumb: Breadcrumb = {
        name: rootPageName,
        path: location + convertQueriesToString({ groupBy: 'country' }),
      };
      const subPageBreadcrumb: Breadcrumb = {
        name: country,
        path:
          location + convertQueriesToString({ country, groupBy: 'country' }),
      };

      return [rootBreadcrumb, subPageBreadcrumb];
    }

    const rootBreadcrumb: Breadcrumb = {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy: 'country' }),
    };

    return [rootBreadcrumb];
  }

  return [];
};
