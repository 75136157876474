import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import {
  ProductsFilterInventory,
  ProductsFilterStatus,
  ProductsSortingField,
} from './products-filter.types';
import { ProductLightCategory } from '../../../api/products/products.types';

export const productsFiltersDefaults: {
  filters: {
    categories: ProductLightCategory[];
    inventory: ProductsFilterInventory;
    statuses: ProductsFilterStatus[];
  };
  sortBy: SortBy<ProductsSortingField>;
} = {
  filters: {
    categories: [],
    inventory: null,
    statuses: ['Active'],
  },
  sortBy: {
    sortingField: 'id',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'PRODUCTS_FILTERS';

export const pageName: AtomPageName = 'products';

export const productsPageAtoms = {
  defaults: productsFiltersDefaults,
  filters: {
    categories: getGenericAtomFamily<ProductLightCategory[]>({
      defaultValue: productsFiltersDefaults.filters.categories,
      key: `${atomKeyPrefix}-categories`,
    })(pageName),
    inventory: getGenericAtomFamily<ProductsFilterInventory>({
      defaultValue: productsFiltersDefaults.filters.inventory,
      key: `${atomKeyPrefix}-inventory`,
    })(pageName),
    statuses: getGenericAtomFamily<ProductsFilterStatus[]>({
      defaultValue: productsFiltersDefaults.filters.statuses,
      key: `${atomKeyPrefix}-statuses`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<ProductsSortingField>>({
    defaultValue: productsFiltersDefaults.sortBy,
  })(pageName),
};
