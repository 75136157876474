import { useTheme } from 'styled-components';
import { IconWarpingGaugeProps } from './icon-warping-gauge.types';

export const IconWarpingGauge = (props: IconWarpingGaugeProps) => {
  const theme = useTheme();
  const defaultColor = theme.colors.iconography.t2;
  const { color = defaultColor } = props;

  return (
    <svg
      fill="none"
      height="25"
      viewBox="0 0 33 25"
      width="33"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M23.6401 22.9273C23.6837 22.9608 23.7265 22.9937 23.7626 23.0072L24.208 23.3077C26.4222 24.8011 29.4278 24.2169 30.9212 22.0028C32.3555 19.8764 31.8734 17.02 29.8719 15.4741C29.8118 15.4296 29.7734 15.3972 29.7402 15.3693C29.6731 15.3128 29.6273 15.2743 29.4678 15.1894C23.8201 12.181 15.3563 7.78398 9.55253 4.83718C5.96493 3.01564 3.4129 1.71989 3.21345 1.58536C2.47545 1.08757 1.45111 1.31552 0.925633 2.09457C0.400156 2.87362 0.57253 3.90877 1.31053 4.40655C1.55122 4.5689 4.09659 6.69565 7.63508 9.65218L7.64352 9.65924C12.2281 13.4898 18.4765 18.7105 23.5399 22.857C23.5718 22.8748 23.6062 22.9012 23.6401 22.9273ZM27.3198 17.4131C26.4997 16.86 25.3865 17.0764 24.8334 17.8964C24.2803 18.7165 24.4966 19.8297 25.3167 20.3828C26.1368 20.936 27.2499 20.7196 27.8031 19.8995C28.3562 19.0795 28.1398 17.9663 27.3198 17.4131Z"
        fillRule="evenodd"
        stroke={color}
      />
    </svg>
  );
};
