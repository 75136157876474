import { useFormikContext } from 'formik';
import {
  Cell,
  FormikInput,
  Grid,
  Radio,
  Stack,
  Typography,
} from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import {
  batchPartCreationAtoms,
  batchPartCreationDefaults,
} from '../../../../../../batch-part-creation.atoms';
import { BatchPartCreationFormik } from '../../../../../../batch-part-creation.types';
import { CreatePartFromRadioProps } from './create-part-from-radio.types';

export const CreatePartFromRadio = (props: CreatePartFromRadioProps) => {
  const { Content, groupValue, label, subLabel, value } = props;

  const { setValues } = useFormikContext<BatchPartCreationFormik>();

  const setJobs = useSetRecoilState(batchPartCreationAtoms.jobs);
  const setPartThumbnail = useSetRecoilState(
    batchPartCreationAtoms.partThumbnail,
  );
  const isChecked = groupValue === value;

  const handleSelect = async () => {
    await setValues({
      batchPartName: batchPartCreationDefaults.batchPartName,
      batchPartNotes: batchPartCreationDefaults.batchPartNotes,
      batchQty: batchPartCreationDefaults.batchQty,
      colorCode: batchPartCreationDefaults.colorCode,
      material: batchPartCreationDefaults.material,
      partName: batchPartCreationDefaults.partName,
      productId: batchPartCreationDefaults.productId,
      template: groupValue,
    });

    setJobs([]);
    setPartThumbnail(batchPartCreationDefaults.partThumbnail);
  };

  return (
    <Grid columns="max-content 1fr" gap="0.8rem">
      <FormikInput
        Field={<Radio groupValue={groupValue} value={value} />}
        name="template"
      />
      <Cell>
        <Stack
          gap="0.8rem"
          height="unset"
          justifySelf="start"
          style={{ cursor: 'pointer' }}
          onClick={handleSelect}
        >
          <Typography text={label} />
          <Typography color="t2" text={subLabel} variant="p3" />
        </Stack>
        {/* Do not use Conditional */}
        {!!Content && <Content isChecked={isChecked} />}
      </Cell>
    </Grid>
  );
};
