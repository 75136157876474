import { GetJobTemplatesForStepArgs } from './get-job-templates-for-step.types';

export const getJobTemplatesForStep = (args: GetJobTemplatesForStepArgs) => {
  const { jobTemplates, step: stepToMatch } = args;

  const matchingJobs = jobTemplates.filter(({ step }) => {
    return step === stepToMatch;
  });

  return matchingJobs;
};
