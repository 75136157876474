import { Grid, media } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledGrid = styled(Grid)`
  min-height: 20rem;

  ${media.greaterThan('md')`
    min-height: 11rem;
  `}
`;

export const StyledCell = styled.div`
  padding: 1.1rem;
  ${({ theme }) => {
    return css`
      border: 1px solid ${theme.colors.dividers.t1};
    `;
  }}

  ${media.greaterThan('md')`
    &:not(:last-child) {
      border-right: none;
    }
  `}
  ${media.lessThan('md')`
    &:nth-child(odd) {
      border-right: none;
    }
    &:nth-child(1n+3) {
      border-top: none;
    }
  `}
`;
