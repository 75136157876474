import { Conditional, Icon, Tooltip } from 'gantri-components';
import { useMemo } from 'react';
import {
  StyledIconWrapper,
  StyledThumbnailWrapper,
} from './advanced-checklist-thumbnail.styles';
import { useThumbnailZoomModal } from '../../use-thumbnail-zoom-modal';
import Thumbnail from '../../../../../../../../../../../../components/common/thumbnail/thumbnail';
import { AdvancedChecklistThumbnailProps } from './advanced-checklist-thumbnail.types';

export const AdvancedChecklistThumbnail = (
  props: AdvancedChecklistThumbnailProps,
) => {
  const { checklistItem } = props;

  const [showZoomModal] = useThumbnailZoomModal({
    heading: checklistItem.label,
    src: checklistItem.exampleImgSrc,
  });

  return useMemo(() => {
    return (
      <Conditional condition={!!checklistItem.showThumbnail}>
        <Conditional
          condition={checklistItem.thumbnailSrc === checklistItem.exampleImgSrc}
          Fallback={
            <Conditional
              condition={!!checklistItem.exampleImgSrc}
              Fallback={
                <Thumbnail size="10rem" src={checklistItem.thumbnailSrc} />
              }
            >
              <StyledThumbnailWrapper size="10rem" onClick={showZoomModal}>
                <Thumbnail size="10rem" src={checklistItem.thumbnailSrc} />
                <StyledIconWrapper
                  aria-describedby={checklistItem.label}
                  aria-label="View example"
                >
                  <Icon color="t2" name="media:photo" size="1.6rem" />
                </StyledIconWrapper>
              </StyledThumbnailWrapper>
            </Conditional>
          }
        >
          <Tooltip description="View larger image" position="top-end">
            <Thumbnail
              size="10rem"
              src={checklistItem.thumbnailSrc}
              onClick={showZoomModal}
            />
          </Tooltip>
        </Conditional>
      </Conditional>
    );
  }, [checklistItem]);
};
