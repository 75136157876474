import { Button, Cell, Conditional, Stack } from 'gantri-components';
import { useState } from 'react';
import {
  InventoryPurchase,
  Shipment as ShipmentType,
} from '../../../../api/inventory-purchases/inventory-purchases.types';
import { inventoryPurchaseStatuses } from '../../../../constants/inventory-purchases';
import { SectionHeading } from '../section-heading';
import { Shipment } from './components/shipment';
import { PurchaseStatusSectionProps } from './purchase-status-section.types';

export const PurchaseStatusSection = (props: PurchaseStatusSectionProps) => {
  const {
    editedInventoryPurchase,
    isEditingDisabled,
    isPurchaseComplete,
    onUpdate,
    setIsEditingDisabled,
  } = props;

  const [isAddingShipment, setIsAddingShipment] = useState<boolean>(false);

  const emptyShipment: ShipmentType = {
    arrivedAt: null,
    cargoReadyAt: null,
    createdAt: null,
    deliveredAt: null,
    dimension: null,
    etaAt: null,
    etdAt: null,
    grossWeight: 0,
    id: null,
    inventoryPurchaseId: editedInventoryPurchase.id,
    notes: null,
    photos: [],
    quantity: 0,
    shippedAt: null,
    status: inventoryPurchaseStatuses.waiting,
    trackingLink: null,
    updatedAt: null,
    vendorId: null,
  };

  const shipments = isAddingShipment
    ? [...editedInventoryPurchase.shipments, emptyShipment]
    : editedInventoryPurchase.shipments;

  return (
    <Stack gap="2rem" height="unset">
      <SectionHeading
        status={editedInventoryPurchase.status}
        text="Purchase Status"
      />
      {shipments.map((shipment, index) => {
        return (
          <Shipment
            key={`${shipment.id}-${index}`}
            editedInventoryPurchase={editedInventoryPurchase}
            index={index}
            isEditingDisabled={isEditingDisabled}
            setIsEditingDisabled={setIsEditingDisabled}
            totalShipments={shipments.length}
            unalteredShipment={shipment}
            onUpdate={(newInventoryPurchaseData?: InventoryPurchase) => {
              onUpdate(newInventoryPurchaseData);
              setIsAddingShipment(false);
            }}
          />
        );
      })}
      <Conditional condition={!isPurchaseComplete}>
        <Cell justifyContent="flex-start">
          <Button
            disabled={isEditingDisabled}
            text="Add Shipment"
            variant="secondary"
            onClick={() => {
              setIsEditingDisabled(true);
              setIsAddingShipment(true);
            }}
          />
        </Cell>
      </Conditional>
    </Stack>
  );
};
