import { useMemo } from 'react';
import { orderSecondaryActions } from '../../../../constants/options';
import { MoreMenu } from '../../../../components/dropdowns';
import Protected from '../../../../components/common/protected/protected';
import { OrderActionProps } from './order-actions.types';
import { isOrderOptionEnabled } from './helper';

export const OrderActionMenu = (props: OrderActionProps) => {
  const { optionActions, order } = props;
  const { shipments, status, type } = order || {};

  const menuOptions = useMemo(() => {
    return Object.keys(orderSecondaryActions).map((action) => {
      return {
        allowedFor: orderSecondaryActions[action].allowedFor,
        enabled: isOrderOptionEnabled({
          data: {
            orderStatus: status,
            orderType: type,
            shipments,
          },
          optionConfiguration: orderSecondaryActions[action],
        }),
        name: orderSecondaryActions[action].label,
        onOptionClick: () => {
          optionActions[action]();
        },
      };
    });
  }, [shipments, status, type]);

  return (
    <Protected allowedFor={['Admin']}>
      <MoreMenu options={menuOptions} />
    </Protected>
  );
};
