import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks';
import { ReferralsSortingField } from '../../../pages/referrals/referrals.types';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const referralsFiltersDefaults: {
  filters: {
    purchased: boolean;
  };
  sortBy: SortBy<ReferralsSortingField>;
} = {
  filters: {
    purchased: false,
  },
  sortBy: {
    sortingField: 'joinDate',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'REFERRALS_FILTERS';

export const pageName: AtomPageName = 'referrals';

export const referralsPageAtoms = {
  defaults: referralsFiltersDefaults,
  filters: {
    purchased: getGenericAtomFamily({
      defaultValue: referralsFiltersDefaults.filters.purchased,
      key: `${atomKeyPrefix}-purchased`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<ReferralsSortingField>>({
    defaultValue: referralsFiltersDefaults.sortBy,
  })(pageName),
};
