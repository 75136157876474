import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { UpdateProductReviewArgs } from './update-product-review.types';

export const updateProductReview = async (
  props: UpdateProductReviewArgs,
): Promise<any> => {
  const { id, status } = props;

  return axios({
    data: {
      status,
    },
    method: 'put',
    url: `${coreApiUrl}/product-reviews/update/${id}`,
  }).then((response) => {
    return response.data;
  });
};
