import { ConfirmBeginContent } from '../step-confirm-begin/confirm-begin-content';
import { CompleteJobImmediatelyContentProps } from './complete-job-immediately-content.types';

export const CompleteJobImmediatelyContent = (
  props: CompleteJobImmediatelyContentProps,
) => {
  const { job } = props;

  return <ConfirmBeginContent job={job} />;
};
