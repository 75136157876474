import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  FetchShortProductSummaryArgs,
  FetchShortProductSummaryResponse,
} from './fetch-short-product-summary.types';

export const fetchShortProductSummary = ({
  sku,
}: FetchShortProductSummaryArgs) => {
  return axios
    .get<FetchShortProductSummaryResponse>(
      `${getCoreApiUrl('products')}/short-product-summary?sku=${sku}`,
    )
    .then(({ data }) => {
      return data;
    });
};
