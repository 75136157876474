import {
  DownloadsFilterType,
  DownloadsSortingField,
} from '../../../api/downloads/routes/fetch-paginated/fetch-paginated.types';
import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { SortBy } from '../../common/table/hooks';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';

export const downloadsFiltersDefaults: {
  filters: {
    type: DownloadsFilterType[];
  };
  sortBy: SortBy<DownloadsSortingField>;
} = {
  filters: {
    type: [],
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'DOWNLOADS_FILTERS';

export const pageName: AtomPageName = 'downloads';

export const downloadsPageAtoms = {
  defaults: downloadsFiltersDefaults,
  filters: {
    type: getGenericAtomFamily({
      defaultValue: downloadsFiltersDefaults.filters.type,
      key: `${atomKeyPrefix}-type`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<DownloadsSortingField>>({
    defaultValue: downloadsFiltersDefaults.sortBy,
  })(pageName),
};
