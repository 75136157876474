import routePaths from '../../../../config/route-paths';
import { PaginatedPart } from '../../../../api/parts/routes/get-parts/get-parts.types';
import { formatAsLinkForCSV } from '../../../../hooks/use-get-download-menu-option/helpers/format-as-link-for-csv';
import { getAdminUrl } from '../../../../helpers/get-admin-url';
import { formatDate } from '../../../../helpers/formatter';
import { DOWNLOAD_LONG_FORMAT } from '../../../../constants/dates';
import { newlineSeparator } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv';
import { DownloadColumn } from '../../../../hooks/use-get-download-menu-option/helpers/download-csv/download-csv.types';
import { getIsInProgressPart } from '../../../part/components/part-jobs/helpers/get-is-in-progress-part';

export const downloadColumns: DownloadColumn<PaginatedPart>[] = [
  {
    getValue: ({ id }) => {
      return id
        ? formatAsLinkForCSV({
            text: `#${id}`,
            url: `${getAdminUrl() + routePaths.parts}/${id}`,
          })
        : '';
    },
    header: 'Part #',
  },
  {
    getValue: ({ product }) => {
      return product?.name;
    },
    header: 'Product',
  },
  {
    getValue: ({ stock }) => {
      return stock?.id
        ? formatAsLinkForCSV({
            text: `#${stock.id}`,
            url: `${getAdminUrl() + routePaths.stocks}/${stock.id}`,
          })
        : '';
    },
    header: 'Stock #',
  },
  {
    getValue: ({ stock }) => {
      return stock?.status;
    },
    header: 'Stock Status',
  },
  {
    getValue: ({ order }) => {
      return order?.id
        ? formatAsLinkForCSV({
            text: `${order.type} #${order.id}`,
            url: `${getAdminUrl() + routePaths.orders}/${order.id}`,
          })
        : '';
    },
    header: 'Order #',
  },
  {
    getValue: ({ name }) => {
      return name;
    },
    header: 'Part Name',
  },
  {
    getValue: ({ status }) => {
      return status;
    },
    header: 'Status',
  },
  {
    getValue: ({ printYield }) => {
      return printYield;
    },
    header: 'Print yield %',
  },
  {
    getValue: ({ attempt }) => {
      return attempt;
    },
    header: 'Attempt',
  },
  {
    getValue: ({ version }) => {
      return version ? `v${version}` : '';
    },
    header: 'Version',
  },
  {
    getValue: ({ lastActionAt }) => {
      return formatDate(lastActionAt, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Last Action at',
  },
  {
    getValue: ({ lastActionData }) => {
      return lastActionData?.job
        ? `${lastActionData.job.name} (${lastActionData.job.status})`.trim()
        : '';
    },
    header: 'Last Action details',
  },
  {
    getValue: ({ completedAt }) => {
      return formatDate(completedAt, DOWNLOAD_LONG_FORMAT);
    },
    header: 'Ended',
  },
  {
    getValue: ({ location }) => {
      return location?.name;
    },
    header: 'Location',
  },
  {
    getValue: ({ attentions, status }) => {
      const isInProgressPart = getIsInProgressPart(status);

      return isInProgressPart
        ? attentions
            ?.map(({ description, name }) => {
              return description || name;
            })
            ?.join(newlineSeparator)
        : '';
    },
    header: 'Attention',
  },
];
