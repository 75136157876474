import { getSearchFilterAtomFamily } from '../../common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../helpers/get-generic-atom-family';
import { AtomPageName } from '../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { GiftCodesFilterType } from './gift-codes-filter.types';

export const giftCodesFiltersDefaults: {
  filters: {
    active: boolean;
    type: GiftCodesFilterType[];
  };
} = {
  filters: {
    active: false,
    type: [],
  },
};

const atomKeyPrefix = 'GIFT_CODES_FILTERS';

export const pageName: AtomPageName = 'gift-codes';

export const giftCodesPageAtoms = {
  defaults: giftCodesFiltersDefaults,
  filters: {
    active: getGenericAtomFamily({
      defaultValue: giftCodesFiltersDefaults.filters.active,
      key: `${atomKeyPrefix}-active`,
    })(pageName),
    type: getGenericAtomFamily({
      defaultValue: giftCodesFiltersDefaults.filters.type,
      key: `${atomKeyPrefix}-type`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
};
