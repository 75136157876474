import { Button, Table, useModal } from 'gantri-components';
import {
  GetPaginatedBatchesArgs,
  PaginatedBatch,
} from '../../api/batch/routes/get-paginated-batches/get-paginated-batches.types';
import { PageWithTable } from '../../components/layout';
import {
  FiltersFetchRequestArgs,
  useTableFilters,
} from '../../components/common/table/hooks';
import { BatchesSortingField } from './batches.types';
import {
  batchesFilterAtoms,
  batchesFiltersDefaults,
  batchesPageSearchAtom,
  batchesPageSortByAtom,
} from './batches.atoms';
import { useGetBatchesColumns } from './hooks/use-get-batches-columns';
import { BatchesFilters } from './components/batches-filters';
import { batchApi } from '../../api';
import { CreateBatchModal } from './components/create-batch-modal';
import { useNotification } from '../../hooks/useNotification';

export const Batches = () => {
  const pageTitle = 'Batches';

  const { notifyAxiosError, onInterceptRequest } = useNotification();

  const fetchBatches = async (
    filters: FiltersFetchRequestArgs<
      GetPaginatedBatchesArgs,
      BatchesSortingField
    >,
  ) => {
    await onInterceptRequest(async () => {
      try {
        const { data } = await batchApi.getPaginatedBatches(filters);

        setRecords(data.batches);
        setTotalCount(data.totalBatches);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch batches.',
        });
      }
    });
  };

  const {
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    PaginatedBatch,
    GetPaginatedBatchesArgs,
    BatchesSortingField
  >({
    fetchRequest: fetchBatches,
    filtersContent: <BatchesFilters />,
    modalFiltersDetails: [
      {
        atom: batchesFilterAtoms.colors,
        defaultValue: batchesFiltersDefaults.filters.colors,
        payloadKey: 'colors',
      },
      {
        atom: batchesFilterAtoms.createdDateRange,
        defaultValue: batchesFiltersDefaults.filters.createdDateRange,
        payloadKey: 'createdDateRange',
      },
      {
        atom: batchesFilterAtoms.status,
        defaultValue: batchesFiltersDefaults.filters.status,
        payloadKey: 'status',
      },
    ],
    pageName: 'batches',
    searchFilterDetails: {
      atom: batchesPageSearchAtom,
    },
    sortByFilterDetails: {
      atom: batchesPageSortByAtom,
      defaultValue: batchesFiltersDefaults.sortBy,
    },
    sortOptions: [
      {
        bidirectional: true,
        label: 'Created date',
        sortingField: 'createdAt',
      },
    ],
  });

  const columns = useGetBatchesColumns({
    onUpdate: () => {
      return handleFetchRequest({ page: 1 });
    },
  });

  const [showCreateBatchModal, hideCreateBatchModal] = useModal(() => {
    return <CreateBatchModal onClose={hideCreateBatchModal} />;
  }, []);

  return (
    <PageWithTable pageTitle={pageTitle}>
      <PageWithTable.Header title={pageTitle}>
        <Button text="Create Batch" onClick={showCreateBatchModal} />
      </PageWithTable.Header>
      <PageWithTable.Content>
        <Table
          columns={columns}
          data={records}
          filters={filtersProps}
          highlightHoveredRow
          paging={pagingProps}
          search={{
            ...searchProps,
            maxWidth: '24rem',
            placeholder: 'Search by Batch ID or Color',
          }}
          sorting={sortProps}
          stickyLastColumn
        />
      </PageWithTable.Content>
    </PageWithTable>
  );
};
