import { FC } from 'react';
import { CSSProperties } from 'styled-components';
import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import { StyledThumbnail } from './thumbnail.styles';
import { ThumbnailProps } from './thumbnail.props';
import { ThumbnailPresets } from './thumbnail.presets';
import { cloudinaryUrl } from '../../../helpers/images';

const Thumbnail: FC<ThumbnailProps> = (props) => {
  const { height, size, src, width } = props;

  const imgSrc = /cloudinary/i.test(src)
    ? cloudinaryUrl(src, {
        crop: 'fill',
        height: getCloudinarySize(height || size),
        width: getCloudinarySize(width || size),
      })
    : src;

  return <StyledThumbnail role="img" {...props} src={imgSrc} />;
};

Thumbnail.defaultProps = ThumbnailPresets;

export default Thumbnail;

const getCloudinarySize = (
  size:
    | ResolutionAwareProp<CSSProperties['height']>
    | ResolutionAwareProp<CSSProperties['width']>,
): number => {
  const sizeString =
    typeof size === 'number' || typeof size === 'string'
      ? String(size)
      : String(size.lg || size.md || size.sm);
  const sizeNum = Number(sizeString.replace(/[a-zA-Z]+/, ''));

  return /rem/.test(sizeString) ? sizeNum * 10 : sizeNum;
};
