import { Button } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../api';
import { jobStatuses } from '../../../../../../../../../constants/options';
import { useNotification } from '../../../../../../../../../hooks/useNotification';
import { fullScreenModalFooterButtonSize } from '../../../../../modals.constants';
import { completeQaQcModalAtoms } from '../../../complete-final-qa-modal.atoms';
import { ConfirmStartFooterProps } from './confirm-start-footer.types';

export const ConfirmStartFooter = (props: ConfirmStartFooterProps) => {
  const { handleCloseModal, job } = props;

  const { notifyAxiosError, processing, setProcessing } = useNotification();

  const setStep = useSetRecoilState(completeQaQcModalAtoms.step);
  const setUpdateOnClose = useSetRecoilState(
    completeQaQcModalAtoms.updateOnClose,
  );

  const handleStartJob = async () => {
    try {
      const isReady = job.status === jobStatuses.ready;

      if (isReady) {
        setProcessing(true);
        await jobsApi.startJob({ jobId: job.id });
        setUpdateOnClose(true);
      }

      setStep('COMPLETE_CHECKLIST');
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to start job.',
      });
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Button
        size={fullScreenModalFooterButtonSize}
        text="Cancel"
        variant="secondary"
        onClick={async () => {
          await handleCloseModal();
        }}
      />
      <Button
        processing={processing}
        size={fullScreenModalFooterButtonSize}
        text="Yes"
        onClick={handleStartJob}
      />
    </>
  );
};
