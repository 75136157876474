import { Box, Conditional, Typography } from 'gantri-components';
import { productStatuses } from '../../../../../../../../../constants/options';
import { ProductNameAndStatusProps } from './product-name-and-status.types';

export const ProductNameAndStatus = (props: ProductNameAndStatusProps) => {
  const { name, status, whitespace } = props;

  return (
    <Conditional condition={!!name}>
      <Box horizontalPadding="x" verticalPadding=".5x">
        <Typography display="inline" text={name} whiteSpace={whitespace} />
        <Conditional condition={status !== productStatuses.active}>
          <Conditional condition={whitespace === 'nowrap'} Fallback={' '}>
            &nbsp;
          </Conditional>

          <Typography
            color="alert"
            display="inline"
            text={`(${status})`}
            whiteSpace={whitespace}
          />
        </Conditional>
      </Box>
    </Conditional>
  );
};
