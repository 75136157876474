import styled, { css } from 'styled-components';
import { media } from 'gantri-components';
import { BaseTransition } from '../../styles';

export const StyledPhotosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > * {
    // using "margin" as "gap" has a delay after re-arranging images
    margin-right: 2.4rem;
    margin-bottom: 2.4rem;

    ${media.lessThan('sm')`
      margin-right: 1.6rem;
      margin-bottom: 1.6rem;
    `}
  }
`;

export const StyledPhoto = styled.div<{ $isActive: boolean }>`
  ${({ $isActive }) => {
    return (
      $isActive &&
      css`
        ${BaseTransition}

        & > div {
          transform: scale(0.95) translateX(-1px);
        }
      `
    );
  }}
`;
