import { Dropdown } from 'gantri-components';
import { useRecoilState } from 'recoil';
import { AtomsCheckboxList } from '../../../../components/common/atoms-checkbox-list';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { locationsPageAtoms } from '../../locations.atoms';
import { locationTypes, locationWorkshops } from '../../locations.constants';
import { LocationType } from '../../locations.types';

export const LocationFilters = () => {
  const [workshop, setWorkshop] = useRecoilState(
    locationsPageAtoms.filters.workshop,
  );

  const locationTypeOptions = [
    locationTypes.workshop,
    locationTypes.area,
    locationTypes.rack,
    locationTypes.shelf,
    locationTypes.bin,
  ].map((label) => {
    return {
      label,
      value: label.toLowerCase() as Lowercase<LocationType>,
    };
  });

  const workshopOptions = [
    {
      label: 'All',
      value: null,
    },
    ...Object.values(locationWorkshops).map((label) => {
      return {
        label,
        value: label,
      };
    }),
  ];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={locationsPageAtoms.filters.type}
          default={locationsPageAtoms.defaults.filters.type}
          text="Type"
        />
        <AtomsCheckboxList
          atom={locationsPageAtoms.filters.type}
          items={locationTypeOptions}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={locationsPageAtoms.filters.workshop}
          default={locationsPageAtoms.defaults.filters.workshop}
          text="Workshop"
        />
        <Dropdown
          items={workshopOptions}
          placeholder="Select workshop..."
          value={workshop}
          onSelect={(item) => {
            setWorkshop(item?.value);
          }}
        />
      </FilterRow>
    </FilterGrid>
  );
};
