import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  FetchPaginatedTransactionsArgs,
  FetchPaginatedTransactionsResponse,
} from './fetch-paginated-transactions.types';

export const fetchPaginatedTransactions = (
  args: FetchPaginatedTransactionsArgs,
) => {
  const { completedDateRange, createdDateRange, ...rest } = args;

  return axios.post<FetchPaginatedTransactionsResponse>(
    `${baseApiUrl}/paginated-transactions`,
    {
      ...rest,
      completedEndDate: completedDateRange.to.formatted,
      completedEndDateSec: completedDateRange.to.sec,
      completedStartDate: completedDateRange.from.formatted,
      completedStartDateSec: completedDateRange.from.sec,
      endDate: createdDateRange.to.formatted,
      endDateSec: createdDateRange.to.sec,
      startDate: createdDateRange.from.formatted,
      startDateSec: createdDateRange.from.sec,
    },
  );
};
