import { Box, Conditional, Flex, Icon } from 'gantri-components';
import { GcodeDataCellProps } from './gcode-data-cell.types';
import { StyledFileName } from './gcode-data-cell.styles';
import { StyledTableIconContainer } from '../custom-data-cells-styles';
import { UserTooltip } from './components/user-tooltip';
import { useDownload } from '../../../../hooks/use-download';

export const GcodeDataCell = (props: GcodeDataCellProps) => {
  const { gcode } = props;

  const { downloadSingleFile } = useDownload();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <Box
      style={{ display: 'inline-block' }}
      onClick={(event) => {
        return event.stopPropagation();
      }}
    >
      <Conditional condition={!!gcode?.fileName && !!gcode?.url}>
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            onClick={() => {
              return downloadSingleFile({
                fileName: gcode.fileName,
                url: gcode.url,
              });
            }}
          >
            <StyledFileName color="link" text={gcode?.fileName} variant="p2" />
            <StyledTableIconContainer>
              <Icon color="link" name="arrows:arrow_external" />
            </StyledTableIconContainer>
          </Flex>

          {gcode?.userInfo && (
            <UserTooltip
              createdAt={gcode.createdAt}
              userInfo={gcode.userInfo}
            />
          )}
        </Flex>
      </Conditional>
    </Box>
  );
};
