import {
  Conditional,
  Grid,
  Icon,
  Tooltip,
  Typography,
} from 'gantri-components';
import { PropsWithChildren, useEffect, useState } from 'react';
import {
  SmallToastProps,
  IconColor,
  UseSmallToastProps,
  ToastStatus,
} from './use-small-toast.types';

export const smallToastWarningIconColors: IconColor[] = ['alert', 'warning'];

export const useSmallToast = (props?: UseSmallToastProps) => {
  const { delay = 2000 } = props || {};

  const [text, setText] = useState<string>('');
  const [status, setStatus] = useState<ToastStatus>(undefined);
  const [iconColor, setIconColor] = useState<IconColor>(undefined);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>(null);

  const statusToColorMap: Record<ToastStatus, IconColor> = {
    error: 'alert',
    success: 'link',
    warning: 'warning',
  };

  useEffect(() => {
    setIconColor(status ? statusToColorMap[status] : undefined);
  }, [status]);

  useEffect(() => {
    if (text) {
      // reset countdown each time a new message appears
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(() => {
        setText('');
        setIconColor(undefined);
      }, delay);

      setTimeoutId(id);
    } else {
      setStatus(undefined);
    }
  }, [text]);

  const Toast = (props: PropsWithChildren<SmallToastProps>) => {
    return (
      <Tooltip
        Component={
          <Grid
            alignItems="center"
            columns={!!status ? '1.6rem 1fr' : 1}
            gap="2x"
            horizontalPadding="3x"
            verticalPadding="2x"
          >
            <Conditional condition={!!status}>
              <Icon
                color={iconColor}
                name={
                  status === 'success'
                    ? 'ui-control:check_mark_circle_filled'
                    : 'alert:warning_triangle'
                }
              />
            </Conditional>
            <Typography text={text} />
          </Grid>
        }
        maxWidth="21.6rem"
        open={!!text}
        triggerEvent="manual"
        {...props}
      />
    );
  };

  return { Toast, setToastStatus: setStatus, setToastText: setText };
};
