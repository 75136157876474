import { Typography } from 'gantri-components';
import { CellContext } from '@tanstack/react-table';
import { GetYieldCellDetailsReturn } from './get-yield-cell-details.types';

export const getYieldCellDetails = (
  cellProps: CellContext<any, any>,
): GetYieldCellDetailsReturn => {
  const { getValue, row } = cellProps;
  const isYield = /^yield$/i.test(row.original.type);
  const value = getValue();

  if (isYield) {
    const isNumber = typeof value === 'number';

    const YieldCell = () => {
      return <Typography text={isNumber ? `${value}%` : `${value} %`} />;
    };

    return { YieldCell, isYield };
  }

  return { isYield: false };
};
