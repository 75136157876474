import { capitalize } from 'lodash';
import { DownloadFormState } from './download-modal.types';
import { convertStringArrayToValueLabelArray } from '../../../helpers/formatter';
import { downloadTypes } from '../../../constants/options';

export const initialFormState: Partial<DownloadFormState> = {
  description: '',
  fileName: undefined,
  id: undefined,
  name: '',
  thumbnail: undefined,
  type: '',
};

export const downloadTypeOptions = convertStringArrayToValueLabelArray([
  ...downloadTypes,
]).map((item) => {
  return { ...item, label: capitalize(item.label) };
});
