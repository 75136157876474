import { StocksStatus } from '../../components/dropdowns/stocks-filter/stocks-filter.constants';
import {
  orderStatusesMap,
  shipmentStatuses,
  stockStatuses,
} from '../../constants/options';
import { Stock } from '../../types/store';

export const isAbleToShipShipment = (
  shipment: { status: string; stocks: Stock[] },
  orderStatus: string,
): boolean => {
  return !(
    hasIncompleteStock(shipment.stocks) ||
    shipment.status !== shipmentStatuses.readyToShip ||
    [
      orderStatusesMap.delivered,
      orderStatusesMap.refunded,
      orderStatusesMap.cancelled,
    ].some((status) => {
      return status === orderStatus;
    }) ||
    [
      shipmentStatuses.cancelled,
      shipmentStatuses.refunded,
      shipmentStatuses.delivered,
    ].includes(shipment.status)
  );
};

export const hasIncompleteStock = (
  stocks: { status: StocksStatus }[],
): boolean => {
  return stocks.some(({ status: stockStatus }) => {
    const completeStockStatuses: StocksStatus[] = [
      stockStatuses.inStock,
      stockStatuses.completed,
      stockStatuses.restocked,
      stockStatuses.refunded,
      stockStatuses.cancelled,
    ];

    return !completeStockStatuses.includes(stockStatus);
  });
};

export const canNotDeliver = (orderStatus: string): boolean => {
  return [
    orderStatusesMap.refunded,
    orderStatusesMap.cancelled,
    orderStatusesMap.delivered,
  ].some((status) => {
    return status === orderStatus;
  });
};
