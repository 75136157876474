import { Conditional, Grid, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { Checklist } from '../../../../../common/job-checklist/job-checklist.types';
import { JobFailReasonsList } from '../../../../../common/job-fail-reasons-list';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import { filterAgainstFailingChecklistItems } from '../../../../../helpers/filter-against-failing-checklist-items';
import { completePrintModalAtoms } from '../../../complete-print-modal.atoms';
import { CompletePrintFailReason } from '../../../complete-print-modal.types';
import { machineOfflineText } from './machine-offline-content.constants';

export const MachineOfflineContent = () => {
  const checklist = useRecoilValue(completePrintModalAtoms.checklist);
  const machine = useRecoilValue(completePrintModalAtoms.machine);
  const machinePrintHoursExceeded = useRecoilValue(
    completePrintModalAtoms.machinePrintHoursExceeded,
  );

  const buildPlateIssueType = useRecoilValue(
    completePrintModalAtoms.buildPlateIssueType,
  );

  const machineOfflineReasons = filterAgainstFailingChecklistItems(
    checklist,
    ({ failStatusShouldTakeMachineOffline }) => {
      return failStatusShouldTakeMachineOffline;
    },
  );

  const machineOfflineChecklist = machineOfflineReasons.reduce<
    Partial<Checklist<CompletePrintFailReason>>
  >((accumulator, reason) => {
    return { ...accumulator, [reason]: checklist[reason] };
  }, {});

  const maintenanceMessage = useMemo(() => {
    if (machinePrintHoursExceeded) {
      return machineOfflineText.printHoursExceeded;
    }

    if (buildPlateIssueType === '60_jobs') {
      return machineOfflineText.completed60Jobs;
    }

    return machineOfflineText.completed120Jobs;
  }, [machinePrintHoursExceeded, buildPlateIssueType]);

  return (
    <Stack gap="5x">
      <ModalContentHeading
        color="alert"
        titleText={`${machine?.name || 'Machine'} offlined`}
      />

      <Conditional condition={!!machineOfflineReasons.length}>
        <Stack gap="x">
          <JobFailReasonsList
            checklist={machineOfflineChecklist}
            title="Failure reasons (machine)"
          />
        </Stack>
      </Conditional>

      <Conditional
        condition={machinePrintHoursExceeded || !!buildPlateIssueType}
      >
        <Stack gap="x">
          <Typography text={machineOfflineText.title} variant="h5" />
          <Typography text={maintenanceMessage} />
        </Stack>
      </Conditional>

      <Stack gap="x">
        <Typography text="Machine details" variant="h5" />
        <Grid columns="max-content 1fr" gap="1rem">
          <Typography text="Location" textStyle="bold" />
          <Typography color="t1" text={machine?.location || '-'} />

          <Typography text="Machine Type" textStyle="bold" />
          <Typography color="t1" text={machine?.type || '-'} />
        </Grid>
      </Stack>
    </Stack>
  );
};
