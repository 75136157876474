import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { useInvalidateGetAvailableJobsForPrintPrepareQueueCache } from '../get-available-print-prepare-queue-jobs';
import { useInvalidateGetPrintPrepareQueuesCache } from '../get-print-prepare-queues';
import { deletePrintPrepareQueue } from './delete-print-prepare-queue';
import { DeletePrintPrepareQueueResponse } from './delete-print-prepare-queue.types';

export const useDeletePrintPrepareQueueMutation = (args?: {
  onSuccess?: (data: DeletePrintPrepareQueueResponse) => void;
  showLoading?: boolean;
}) => {
  const { invalidateGetPrintPrepareQueuesCache } =
    useInvalidateGetPrintPrepareQueuesCache();

  const { invalidateGetAvailableJobsForPrintPrepareQueueCache } =
    useInvalidateGetAvailableJobsForPrintPrepareQueueCache();

  const { notify, notifyAxiosError } = useNotification();

  const { isLoading, onMutate } = useQueryMutation<
    number,
    DeletePrintPrepareQueueResponse
  >({
    config: {
      onError: (error) => {
        // @ts-expect-error
        if (error.response.status === 404) {
          notify('Queue deleted successfully.');
        } else {
          notifyAxiosError({
            error,
            fallbackMessage: 'Unable to delete print prepare queue.',
          });
        }
      },
      onSuccess: async (data) => {
        notify(data.notice);
        args?.onSuccess?.(data);
        await invalidateGetPrintPrepareQueuesCache();
        await invalidateGetAvailableJobsForPrintPrepareQueueCache();
      },
    },
    mutationFn: deletePrintPrepareQueue,
    showLoading: args?.showLoading,
  });

  return { isLoading, onDeletePrintPrepareQueue: onMutate };
};
