import { useRecoilValue } from 'recoil';
import { PrintPrepareQueueSet } from '../../../../../../../api/queue/print-prepare/print-prepare.types';
import { printPrepareQueueModalAtoms } from '../../print-prepare-queue-modal.atoms';

export const useGetActiveJobSet = (): PrintPrepareQueueSet => {
  const queueData = useRecoilValue(printPrepareQueueModalAtoms.queueData);
  const activeSetIndex = useRecoilValue(
    printPrepareQueueModalAtoms.activeSetIndex,
  );

  return queueData?.sets?.[activeSetIndex];
};
