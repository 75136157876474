import { memo } from 'react';
import { TextField } from 'gantri-components';
import {
  countries,
  usaStates,
} from '../../../../../../../../constants/locales';
import { StyledFieldLabel } from '../../../../../../designer-styles';
import { Select } from '../../../../../../../../components/common/inputs';
import Cell from '../../../../../../../../components/common/cell';
import { StateInputProps } from './state-input.types';

const Section = (props: StateInputProps) => {
  const { country, handleInputChange, handleSelectChange, state } = props;

  const { code: usa } =
    countries.find(({ name }) => {
      return name === 'United States';
    }) || {};

  const options = usaStates.map(({ name: stateName }) => {
    return (
      <option key={stateName} value={stateName}>
        {stateName}
      </option>
    );
  });

  return (
    <Cell>
      {country === usa ? (
        <>
          <StyledFieldLabel
            text="Province / State"
            textStyle="bold"
            variant="p2"
          />
          <Select value={state} onChange={handleSelectChange('state')}>
            {options}
          </Select>
        </>
      ) : (
        <>
          <StyledFieldLabel
            text="Province / State"
            textStyle="bold"
            variant="p2"
          />
          <TextField
            placeholder="Province or state name"
            value={state}
            onTextChange={handleInputChange('state')}
          />
        </>
      )}
    </Cell>
  );
};

export const StateInput = memo(Section);
