import { useNotification } from '../../../../hooks/useNotification';
import {
  useGetInvalidateQueryCache,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';
import { FetchPaginatedProductReviewsArgs } from './fetch-paginated-product-reviews.types';
import { fetchPaginatedProductReviews } from './fetch-paginated-product-reviews';

interface FetchPaginatedProductReviewsProps {
  filters: FetchPaginatedProductReviewsArgs;
}

export const useFetchPaginatedProductReviewsQuery = (
  props?: FetchPaginatedProductReviewsProps,
) => {
  const { filters } = props ?? {};
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch({
    config: {
      enabled: !!filters,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch paginated product reviews.',
        });
      },
      queryFn: () => {
        return fetchPaginatedProductReviews.query(filters);
      },
      queryKey: fetchPaginatedProductReviews.key(filters),
    },
    showLoading: true,
  });

  return { ...data, isLoading };
};

export const useInvalidateFetchPaginatedProductReviewsCache = () => {
  return useGetInvalidateQueryCache(fetchPaginatedProductReviews);
};
