import { Cell, Conditional, Grid, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { Divider } from '../../../../../../components/divider';
import { jobSteps } from '../../../../../../constants/options';
import { getJobTemplatesForStep } from '../../helpers/get-job-templates-for-step';
import { placeholderImageSrc } from '../../../../../../constants/images';
import Thumbnail from '../../../../../../components/common/thumbnail/thumbnail';
import { ColorSwatchAndLabel } from '../../../../../../components/common/color-swatch-and-label';
import { ContentWithLabel } from '../common/content-with-label';
import { JobsTemplateTable } from '../../../../../../components/common/jobs-template-table';
import { batchPartCreationAtoms } from '../../../../batch-part-creation.atoms';
import { BatchPartCreationFormik } from '../../../../batch-part-creation.types';

export const ConfirmBatchPage = () => {
  const jobs = useRecoilValue(batchPartCreationAtoms.jobs);
  const partThumbnail = useRecoilValue(batchPartCreationAtoms.partThumbnail);
  const partUuid = useRecoilValue(batchPartCreationAtoms.partUuid);
  const selectedProductDetails = useRecoilValue(
    batchPartCreationAtoms.selectedProductDetails,
  );

  const { values: formikValues } = useFormikContext<BatchPartCreationFormik>();

  const {
    batchPartName,
    batchPartNotes,
    batchQty,
    colorCode,
    material,
    partName,
    productId,
  } = formikValues;

  const printJobTemplates = getJobTemplatesForStep({
    jobTemplates: jobs,
    step: jobSteps.print,
  });

  const finishingJobTemplates = getJobTemplatesForStep({
    jobTemplates: jobs,
    step: jobSteps.finish,
  });

  return (
    <Stack gap="2rem">
      <Stack gap="0.4rem">
        <Typography text="Confirm" variant="h3" />
        <Typography text="Are you sure you want to create the following part batch?" />
      </Stack>
      <Cell paddingTop="1.2rem">
        <Typography text="Part Information" variant="h4" />
      </Cell>
      <Grid columns={{ lg: 'max-content 1fr', sm: '1fr' }} gap="1.6rem">
        <Cell>
          <Thumbnail
            alt={partName}
            size="10rem"
            src={partThumbnail?.url || placeholderImageSrc}
          />
        </Cell>
        <Grid alignItems="baseline" columns={{ lg: 5, sm: 2 }}>
          <ContentWithLabel label="Part Name" text={batchPartName} />
          <ContentWithLabel label="Quantity" text={batchQty} />
          <Cell width={{ lg: 3, sm: 2 }}>
            <ContentWithLabel label="Notes" text={batchPartNotes} />
          </Cell>
          <Conditional
            condition={!!productId}
            Fallback={
              <>
                <ContentWithLabel label="Material" text={material} />
                <ContentWithLabel
                  Content={
                    colorCode
                      ? () => {
                          return <ColorSwatchAndLabel colorCode={colorCode} />;
                        }
                      : undefined
                  }
                  label="Color"
                />
              </>
            }
          >
            <ContentWithLabel
              label="Product"
              text={selectedProductDetails?.fullName}
            />
            <ContentWithLabel
              Content={() => {
                return <ColorSwatchAndLabel colorCode={colorCode} />;
              }}
              label="Color"
            />
            <ContentWithLabel label="Part" text={partName} />
          </Conditional>
        </Grid>
      </Grid>
      <Divider paddingTop="2rem" />
      <Typography text="Jobs" variant="h4" />
      <Stack gap="1.6rem">
        <Typography text={`1. ${jobSteps.print}`} variant="h4" />
        <JobsTemplateTable
          data={printJobTemplates}
          disableEditing
          partId={partUuid}
          partName={partName}
          restrictJobStepTo={jobSteps.print}
        />
      </Stack>
      <Conditional condition={!!finishingJobTemplates.length}>
        <Stack gap="1.6rem">
          <Typography text={`2. ${jobSteps.finish}`} variant="h4" />
          <JobsTemplateTable
            data={finishingJobTemplates}
            disableEditing
            partId={partUuid}
            partName={partName}
            restrictJobStepTo={jobSteps.finish}
          />
        </Stack>
      </Conditional>
    </Stack>
  );
};
