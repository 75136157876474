import { useRecoilState, useSetRecoilState } from 'recoil';
import { EvaluatePartContent as BaseEvaluatePartContent } from '../../../../../common/evaluate-part-content';
import { completePrintModalAtoms } from '../../../complete-print-modal.atoms';
import {
  completedPrintChecklistDefaults,
  partialPrintChecklistDefaults,
} from '../../../complete-print-modal.constants';

export const EvaluatePartContent = () => {
  const [printCompletionStatus, setPrintCompletionStatus] = useRecoilState(
    completePrintModalAtoms.printCompletionStatus,
  );
  const setChecklist = useSetRecoilState(completePrintModalAtoms.checklist);

  return (
    <BaseEvaluatePartContent
      getDefaultChecklist={(value) => {
        return value === 'PASS'
          ? completedPrintChecklistDefaults
          : partialPrintChecklistDefaults;
      }}
      initialEvaluation={printCompletionStatus}
      labelFail="Partial or incomplete print"
      labelPass="Complete print"
      setChecklist={setChecklist}
      setInitialEvaluation={setPrintCompletionStatus}
      subTitleHtml="Is the print complete?"
      title="Evaluate part"
    />
  );
};
