import { Button } from 'gantri-components';
import { useCompleteJob } from '../../../../../../../../../../api/jobs/routes';
import { jobTypeOptions } from '../../../../../../../../../../constants/options';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { GetFromVlmFooterProps } from './get-from-vlm-footer.types';

export const GetFromVlmFooter = (props: GetFromVlmFooterProps) => {
  const { handleCloseModal, job } = props;

  const { isLoading, onCompleteJob } = useCompleteJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const handleConfirmRetrieved = async () => {
    await onCompleteJob({ jobId: job.id });
  };

  return (
    <Button
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text={`Confirm ${jobTypeOptions.stage} Completion`}
      onClick={handleConfirmRetrieved}
    />
  );
};
