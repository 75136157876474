import { atom } from 'recoil';
import { LocationState } from '../../locations.types';

const atomKeyPrefix = 'LOCATION_MODAL';

export const locationModalAtoms = {
  isEditModal: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-edit-modal`,
  }),
  location: atom<LocationState>({
    default: null,
    key: `${atomKeyPrefix}-location`,
  }),
};
