import { Badge, Conditional, Flex, Icon, Tooltip } from 'gantri-components';
import { useTheme } from 'styled-components';
import {
  jobSteps,
  jobTypeOptions,
  partStatuses,
} from '../../../constants/options';
import { getPartStatusBadgeColors } from './helpers/get-part-status-badge-colors';
import { PartStatusBadgeProps } from './part-status-badge.types';

export const PartStatusBadge = ({ status }: PartStatusBadgeProps) => {
  const theme = useTheme();

  const badgeColors = getPartStatusBadgeColors({ status, theme });

  return (
    <Flex alignItems="center" gap="x">
      <Badge width="auto" {...badgeColors} text={status} />

      <Conditional condition={status === partStatuses.delayedStart}>
        <Tooltip
          maxWidth="22rem"
          overlayContainerStyles={{ top: '2px' }}
          position="top-start"
          title={`This part is delayed until all other painted parts in the stock have completed ${jobSteps.finish} ${jobTypeOptions.sandRaw}.`}
        >
          <Icon name="alert:i_circle" />
        </Tooltip>
      </Conditional>
    </Flex>
  );
};
