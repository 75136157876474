import { useFormikContext } from 'formik';
import { Conditional, Icon, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { jobTypeToIconTypeMap } from '../../../../../../../../constants/jobs';
import { addJobModalAtoms } from '../../../add-job-modal.atoms';
import { AddJobFormValues } from '../../../add-job-modal.types';

export const JobAddedContent = () => {
  const workers = useRecoilValue(addJobModalAtoms.workers);

  const { values } = useFormikContext<AddJobFormValues>();

  const { assignedTo, step, type } = values;
  const jobCreatedBaseText = `${step} ${type} has been created`;

  const workerName = workers.find(({ id }) => {
    return id === assignedTo;
  })?.name;

  const JobTypeIcon = () => {
    return <Icon color="t2" name={jobTypeToIconTypeMap[type]} top="4px" />;
  };

  return (
    <Stack alignContent="center" justifyContent="center" minHeight="18.8rem">
      <Conditional
        condition={!!workerName}
        Fallback={
          <Typography
            align="center"
            icon={<JobTypeIcon />}
            text={`${jobCreatedBaseText}.`}
          />
        }
      >
        <Typography
          align="center"
          icon={<JobTypeIcon />}
          text={`${jobCreatedBaseText} and has been assigned to ${workerName}.`}
        />
      </Conditional>
    </Stack>
  );
};
