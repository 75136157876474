import { GetCellProps } from 'gantri-components';
import { Issue } from '../../../../../../api/machines/routes/get-issues/get-issues.types';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';

export const useGetGetMachineIssuesCellProps = () => {
  const getCellProps: GetCellProps<Issue> = ({ column, getValue }) => {
    switch (column.id) {
      case 'repair': {
        const repair = getValue<Issue['repair']>();
        const isClickable = !!repair?.id;

        return isClickable
          ? { 'data-linked-to': JOB_DETAILS_PANEL_LINKED_TO }
          : {};
      }

      default:
        return {};
    }
  };

  return getCellProps;
};
