const unitAbbreviationByName: Record<string, string> = {
  bottle: 'bot',
  gallon: 'gal',
};

export const getInventoryUnit = (inventoryUnit?: string) => {
  if (!inventoryUnit) {
    return 'each';
  }

  if (
    !unitAbbreviationByName[inventoryUnit] &&
    !unitAbbreviationByName[inventoryUnit.toLowerCase()]
  ) {
    return inventoryUnit.toLowerCase();
  }

  return unitAbbreviationByName[inventoryUnit];
};
