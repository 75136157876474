import { SearchField, Stack } from 'gantri-components';
import { useAsync } from 'react-use';
import { useRecoilState } from 'recoil';
import { machinesApi } from '../../../../../../../../../../api';
import {
  idleMachinesAtoms,
  IdleMachineDetails,
} from '../../../../../../../../../../global-atoms/idle-machines';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { startPrintPrintJobModalAtoms } from '../../../start-print-job-modal.atoms';
import { AssignMachineContentProps } from './assign-machine-content.types';

export const AssignMachineContent = (props: AssignMachineContentProps) => {
  const { job } = props;
  const { machineType } = job;
  const preAssignedMachineId = job.machine?.id;

  const [assignedMachine, setAssignedMachine] = useRecoilState(
    startPrintPrintJobModalAtoms.assignedMachine,
  );
  const [idleMachinesOfType, setIdleMachinesOfType] = useRecoilState(
    idleMachinesAtoms[machineType],
  );

  const { notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  useAsync(async () => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await machinesApi.fetchIdleMachines({
          type: machineType,
        });

        const idleMachinesOfType = data.machines;

        setIdleMachinesOfType(idleMachinesOfType);

        // Handles jobs that had machines assigned before starting the Print Print job
        const isAssignedMachineInIdleList =
          !!preAssignedMachineId &&
          idleMachinesOfType.some(({ id }) => {
            return id === preAssignedMachineId;
          });

        if (isAssignedMachineInIdleList) {
          setAssignedMachine(preAssignedMachineId);
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch idle machines.',
        });
      }
    });
  }, []);

  return (
    <Stack gap="3x">
      <ModalContentHeading
        subTitleText={
          !!preAssignedMachineId && preAssignedMachineId === assignedMachine
            ? 'Review the selected ready machine and then confirm start job.'
            : 'Select a ready machine and then confirm start job.'
        }
        titleText="Machine assignment"
      />
      <SearchField
        disabled={processing}
        idProperty="id"
        items={idleMachinesOfType}
        labelProperty="name"
        placeholder="Search name..."
        value={assignedMachine}
        onSelect={(item: IdleMachineDetails) => {
          setAssignedMachine(item?.id);
        }}
      />
    </Stack>
  );
};
