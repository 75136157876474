import Cookies from 'universal-cookie';
import {
  ADMIN_ROLE_TYPE,
  AUTH_TOKEN,
  CORE_VERSION,
  FIREBASE_TOKEN,
  STD_COOKIE_OPTIONS,
} from '../constants/environment';

const cookies = new Cookies();

export const clearAllCache = () => {
  localStorage.clear(); // clear atoms

  // clear out all cookies, but keep user logged in
  const allCookies = Object.keys(cookies.getAll()).filter((cookie) => {
    return ![
      CORE_VERSION,
      AUTH_TOKEN,
      FIREBASE_TOKEN,
      ADMIN_ROLE_TYPE,
    ].includes(cookie);
  });

  allCookies.forEach((cookie) => {
    return cookies.remove(cookie, STD_COOKIE_OPTIONS);
  });
};
