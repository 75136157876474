import { useCallback, useEffect, useRef, useState } from 'react';
import { useConfirmationModal, Table, GetCellProps } from 'gantri-components';
import { useSetRecoilState } from 'recoil';
import { useGetTransactionsColumns } from './hooks/use-get-transactions-columns';
import { TransactionsFilter } from './components';
import { TransactionsProps } from './transactions-section.types';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { useTableFilters } from '../../../../components/common/table/hooks/use-table-filters';
import {
  GetInventoryTransactionsArgs,
  InventoryTransaction,
  InventoryTransactionSortingField,
} from '../../../../api/inventories/routes/get-inventory/get-inventory.types';
import {
  inventoryTransactionsFiltersAtoms,
  inventoryTransactionsFiltersDefaults,
  pageName,
} from './components/transactions-filter/transactions-filter.atoms';
import { useGetInventoryTransactionsQuery } from '../../../../api/inventories/routes/get-inventory';
import { useDeleteInventoryTransactionMutation } from '../../../../api/inventories/routes/delete-inventory-transaction';
import { jobsDetailsPanelAtoms } from '../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel/jobs-details-panel.atoms';
import { JobsDetailsPanelWrapper } from '../../../../components/jobs-details-panel-wrapper';
import { dataAttrJobId } from '../../../jobs/jobs.constants';

export const TransactionsSection = ({
  currentInventoryId,
}: TransactionsProps) => {
  const transactionSelected = useRef<number>(null);

  const setJobIdInDetailsPanel = useSetRecoilState(
    jobsDetailsPanelAtoms.activeJobId,
  );

  const handleOnDelete = (record: InventoryTransaction) => {
    transactionSelected.current = record.id;
    showConfirmationModal();
  };

  const [filters, setFilters] = useState<GetInventoryTransactionsArgs>();

  const { isLoading, transactions } = useGetInventoryTransactionsQuery(
    !!filters
      ? {
          ...filters,
          inventoryId: Number(currentInventoryId),
          sortingField:
            filters.sortingField === 'default' ? '' : filters.sortingField,
        }
      : null,
  );

  const { onDeleteTransaction } = useDeleteInventoryTransactionMutation();

  const [showConfirmationModal, hideConfirmationModal] = useConfirmationModal({
    confirmButtonText: 'Confirm',
    confirmButtonVariant: 'primary',
    content: 'Are you sure?',
    headerText: 'Delete Transaction',
    onClose: () => {
      transactionSelected.current = null;
      hideConfirmationModal();
    },
    onConfirm: async () => {
      const request = {
        inventoryId: Number(currentInventoryId),
        transactionId: transactionSelected.current,
      };

      await onDeleteTransaction(request, {
        onSuccess: () => {
          hideConfirmationModal();
        },
      });
    },
    width: { lg: '44rem', md: '100%' },
  });

  useEffect(() => {
    if (isLoading) return;

    setRecords(transactions?.inventoryTransactions ?? []);
    setTotalCount(transactions?.resultsCount ?? 0);
  }, [isLoading, transactions]);

  const {
    currentPage,
    filtersProps,
    handleFetchRequest,
    pagingProps,
    records,
    searchProps,
    setRecords,
    setTotalCount,
    sortProps,
  } = useTableFilters<
    InventoryTransaction,
    GetInventoryTransactionsArgs,
    InventoryTransactionSortingField
  >({
    fetchRequest: setFilters,
    filtersContent: <TransactionsFilter />,
    modalFiltersDetails: [
      {
        atom: inventoryTransactionsFiltersAtoms.filters.dateRange,
        defaultValue: inventoryTransactionsFiltersDefaults.filters.dateRange,
        payloadKey: 'dateRange',
      },
      {
        atom: inventoryTransactionsFiltersAtoms.filters.transactionTypes,
        defaultValue:
          inventoryTransactionsFiltersDefaults.filters.transactionTypes,
        payloadKey: 'transactionType',
      },
      {
        atom: inventoryTransactionsFiltersAtoms.filters.types,
        defaultValue: inventoryTransactionsFiltersDefaults.filters.types,
        payloadKey: 'types',
      },
    ],
    pageName,
    searchFilterDetails: {
      atom: inventoryTransactionsFiltersAtoms.search,
    },
    sortByFilterDetails: {
      atom: inventoryTransactionsFiltersAtoms.sortBy,
      defaultValue: inventoryTransactionsFiltersDefaults.sortBy,
    },
    sortOptions: [
      { label: 'Default', sortingField: 'default' },
      {
        bidirectional: true,
        label: 'Date',
        sortingField: 'transactionDate',
      },
      {
        bidirectional: true,
        label: 'Completion',
        sortingField: 'completionDate',
      },
    ],
  });

  const columns = useGetTransactionsColumns({
    handleOnDelete,
  });

  const getCellProps: GetCellProps<InventoryTransaction> = useCallback(
    ({ column, getValue }) => {
      switch (column.id) {
        case 'jobId': {
          const jobId = getValue<InventoryTransaction['jobId']>();
          const isClickable = !!jobId;

          return isClickable
            ? { 'data-linked-to': JOB_DETAILS_PANEL_LINKED_TO }
            : {};
        }

        default:
          return {};
      }
    },
    [],
  );

  return (
    <JobsDetailsPanelWrapper
      records={records}
      onRefresh={async () => {
        return handleFetchRequest({ page: currentPage });
      }}
    >
      <Table
        columns={columns}
        data={records}
        filters={filtersProps}
        getCellProps={getCellProps}
        getRowProps={({ original }) => {
          return { [dataAttrJobId]: original.jobId };
        }}
        highlightHoveredRow
        paging={pagingProps}
        search={searchProps}
        sorting={sortProps}
        stickyLastColumn
      />
    </JobsDetailsPanelWrapper>
  );
};
