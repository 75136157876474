import {
  Cell,
  Grid,
  Typography,
  Radio,
  Conditional,
  Icon,
} from 'gantri-components';
import { StyledCell } from './recommended-step-option.styles';
import { RecommendedStepOptionProps } from './recommended-step-option.types';

export const RecommendedStepOption = (props: RecommendedStepOptionProps) => {
  const {
    hideRadio,
    icon,
    label,
    selectedNextStep,
    setSelectedNextStep,
    step,
    subText,
    systemRecommendedStep,
  } = props;

  const isRecommendedStep = systemRecommendedStep === step;

  const handleSetNextStep = () => {
    setSelectedNextStep(step);
  };

  return (
    <Grid
      alignItems="center"
      columnGap="x"
      columns={hideRadio ? '1fr' : 'repeat(2, max-content)'}
      rowGap=".5x"
    >
      <Conditional condition={!hideRadio}>
        <Cell column={1}>
          <Radio
            groupValue={selectedNextStep}
            name={label}
            value={step}
            onSelected={handleSetNextStep}
          />
        </Cell>
      </Conditional>
      <StyledCell
        column={hideRadio ? undefined : 2}
        onClick={handleSetNextStep}
      >
        <Grid alignItems="center" columns="repeat(3, max-content)" gap="x">
          <Icon color="t2" name={icon} />
          <Typography text={label} />
          {isRecommendedStep && (
            <Typography color="link" text="Recommended" variant="p3" />
          )}
        </Grid>
      </StyledCell>
      <StyledCell
        column={hideRadio ? undefined : 2}
        onClick={handleSetNextStep}
      >
        <Typography color="t2" text={subText} variant="p3" />
      </StyledCell>
    </Grid>
  );
};
