import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateProductDesignArgs,
  UpdateProductDesignResponse,
} from './update-product-design.types';

export const updateProductDesign = ({
  id,
  ...body
}: UpdateProductDesignArgs) => {
  return axios
    .put<UpdateProductDesignResponse>(
      `${getCoreApiUrl('products')}/${id}/update-design`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
