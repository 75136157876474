import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  UpdateInventoryPurchaseArgs,
  UpdateInventoryPurchaseResponse,
} from './update-inventory-purchase.types';

export const updateInventoryPurchase = ({
  id,
  ...body
}: UpdateInventoryPurchaseArgs) => {
  return axios.put<UpdateInventoryPurchaseResponse>(
    `${coreApiUrl}/inventory-purchases/${id}/update`,
    body,
  );
};
