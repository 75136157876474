import { DesignerData, DesignerReducerAction } from './designer.types';

export const currentDesignerInit: DesignerData = {
  QAs: [],
  awards: [],
  bio: '',
  city: '',
  collectionPages: [],
  country: '',
  editorialImages: [],
  email: '',
  features: [],
  firstName: '',
  handout: {
    backBackgroundColor: '',
    backImage: '',
    backTextColor: '',
    bio: '',
    frontBackgroundColor: '',
    frontTextColor: '',
    nameIsVisible: false,
  },
  headshotUrl: '',
  heroImages: [],
  id: null,
  lastName: '',
  links: {
    behance: '',
    instagram: '',
    twitter: '',
    website: '',
  },
  logoUrl: '',
  name: '',
  paypalInfo: {
    email: '',
    name: '',
  },
  processImages: [],
  profileLink: '',
  quote: '',
  quoteImage: [],
  shopifyApiKey: '',
  shopifyPassword: '',
  shopifyStoreName: '',
  state: '',
  theme: {
    background: '',
    highlight: '',
  },
  timelines: [],
  title: '',
  userId: null,
  videoUrl: '',
};

export const DesignerReducer = (
  state: DesignerData,
  action: DesignerReducerAction,
): DesignerData => {
  switch (action.type) {
    case 'state': {
      const { value } = action;
      const designerData = typeof value === 'object' ? value : {};

      return {
        ...state,
        ...designerData,
      };
    }

    case 'key': {
      const { key, value } = action;

      return {
        ...state,
        [key]: value,
      };
    }

    default:
      return state;
  }
};
