import React, { FC, memo } from 'react';
import { Button, Conditional } from 'gantri-components';
import { FooterProps } from './footer.types';

export const Footer: FC<FooterProps> = memo((props) => {
  const { isValid, onCancel, onDone, onMigrate, step } = props;

  return (
    <Conditional
      condition={step !== 'SUMMARY_STEP'}
      Fallback={<Button size="large" text="Done" onClick={onDone} />}
    >
      <Button
        size="large"
        text="Cancel"
        variant="secondary"
        onClick={onCancel}
      />

      <Button
        key={step}
        disabled={!isValid}
        size="large"
        text="Migrate"
        type="submit"
        onClick={onMigrate}
      />
    </Conditional>
  );
});
