import { UseDownloadProps } from '../../use-download.types';
import { useHandleDownloadRequest } from '../use-handle-download-request';
import { useNotification } from '../../../useNotification';
import { DownloadSingleFile } from './use-download-single-file.types';

export const useDownloadSingleFile = (props?: UseDownloadProps) => {
  const { onComplete, onError, onStart } = props || {};

  const { notifyWarning, onInterceptProcessingRequest, processing } =
    useNotification();

  const handleDownloadFile = useHandleDownloadRequest({
    onError,
  });

  const downloadSingleFile: DownloadSingleFile = async (file) => {
    if (file.url) {
      await onInterceptProcessingRequest(async () => {
        try {
          onStart?.();

          await handleDownloadFile(file);

          onComplete?.();
        } catch (error) {
          // Do nothing. Catch handled in handleDownloadFile
        }
      });
    } else {
      notifyWarning('Only uploaded files can be downloaded.');
    }
  };

  return {
    downloadSingleFile,
    processing,
  };
};
