import { useRecoilState } from 'recoil';
import { AdvancedChecklist } from '../../../../../common/job-checklist/components/advanced-checklist';
import { completeSandJobModalAtoms } from '../../../complete-sand-job-modal.atoms';
import { ChecklistItem } from '../../../../../common/job-checklist/job-checklist.types';
import { FinishingSandingFailedReason } from '../../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';

export const CompleteChecklistContent = () => {
  const [checklist, setChecklist] = useRecoilState(
    completeSandJobModalAtoms.checklist,
  );
  const [isChecklistReviewed, setIsChecklistReviewed] = useRecoilState(
    completeSandJobModalAtoms.isChecklistReviewed,
  );

  const updateChecklist = (
    reason: FinishingSandingFailedReason,
    data: ChecklistItem,
  ) => {
    setChecklist((previous) => {
      return {
        ...previous,
        [reason]: data,
      };
    });
  };

  return (
    <AdvancedChecklist
      buttonToggleWidth="8rem"
      checklist={checklist}
      getSubTitle={(numItems) => {
        return `Choose what you see for the following (${numItems}) beyond repair failures.`;
      }}
      isChecklistReviewed={isChecklistReviewed}
      setIsChecklistReviewed={setIsChecklistReviewed}
      showConfirmCheckbox
      statuses={['Pass', 'Fail']}
      title="Evaluate part"
      onItemChange={updateChecklist}
    />
  );
};
