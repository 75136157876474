import { FC } from 'react';
import { useRecoilState } from 'recoil';
import { FilterProps } from '../index';
import DatePicker from '../../common/date-picker';
import { payoutsPageAtoms } from '../../../pages/payouts/payouts.atoms';
import { PayoutsFilterStatus } from '../../../api/transactions/routes/fetch-paginated-payouts/fetch-paginated-payouts.types';
import { OnDateChanged } from '../../common/date-picker/date-picker.types';
import { FilterResetLabel } from '../../common/filter-reset-label';
import { AtomsCheckboxList } from '../../common/atoms-checkbox-list';
import { FilterGrid, FilterRow } from '../../common/filter-grid';

const PayoutFilters: FC<FilterProps> = () => {
  const [dateRange, setDateRange] = useRecoilState(
    payoutsPageAtoms.filters.dateRange,
  );

  const onDateChange: OnDateChanged = ({ formattedFrom, formattedTo }) => {
    setDateRange({
      from: formattedFrom || null,
      to: formattedTo || null,
    });
  };

  const payoutsStatusFilterOptions: PayoutsFilterStatus[] = ['Unpaid', 'Paid'];

  return (
    <FilterGrid>
      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.dateRange}
          default={payoutsPageAtoms.defaults.filters.dateRange}
          text="Date range"
        />
        <DatePicker
          allowFutureDays
          initialValue={dateRange}
          range
          resetDates={payoutsPageAtoms.defaults.filters.dateRange}
          onDateChanged={onDateChange}
        />
      </FilterRow>

      <FilterRow>
        <FilterResetLabel
          atom={payoutsPageAtoms.filters.statuses}
          default={payoutsPageAtoms.defaults.filters.statuses}
          text="Status"
        />
        <AtomsCheckboxList
          atom={payoutsPageAtoms.filters.statuses}
          gridProps={{ columns: 2 }}
          items={payoutsStatusFilterOptions}
        />
      </FilterRow>
    </FilterGrid>
  );
};

export default PayoutFilters;
