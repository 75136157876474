import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetProductArgs, GetProductResponse } from './get-product.types';
import { fetchProduct } from './get-product';
import { getGenericQueryObj } from '../../../../hooks/use-generic-fetch-query/helpers/get-generic-query-obj';

const fetchProductQueriesObj = getGenericQueryObj({
  groupName: 'fetch-product',
  query: fetchProduct,
});

export const useFetchProduct = <TransformedData = GetProductResponse>(
  props: GenericFetchProps<GetProductArgs, GetProductResponse, TransformedData>,
) => {
  return useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch product.',
    queryObj: fetchProductQueriesObj,
    ...props,
  });
};

export const useInvalidateFetchProductCache = () => {
  const invalidateFetchProductCache = useGetInvalidateQueryCache(
    fetchProductQueriesObj,
  );

  return { invalidateFetchProductCache };
};
