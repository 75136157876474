import React, { FC } from 'react';
import { Button, Conditional, Flex, Tooltip } from 'gantri-components';
import { useUserHasAccessLevel } from '../../../hooks/use-user-has-access-level';
import { userAccessDeniedMessage } from '../../../constants/users';

interface FormHeaderProps {
  canEdit?: boolean;
  isEditModeActive?: boolean;
  isSyncing?: boolean;
  isValid?: boolean;
  onCancel: () => void;
  onEdit?: () => void;
  onSave?: () => void;
}

export const FormHeader: FC<FormHeaderProps> = (props) => {
  const {
    canEdit,
    isEditModeActive,
    isSyncing,
    isValid,
    onCancel,
    onEdit,
    onSave,
  } = props;

  const { hasLeadAccess } = useUserHasAccessLevel();

  const disableEdit = !canEdit || !hasLeadAccess;

  return (
    <Conditional
      condition={isEditModeActive}
      Fallback={
        <Tooltip
          description={!hasLeadAccess && userAccessDeniedMessage}
          position="left"
        >
          <Button
            disabled={disableEdit}
            text="Edit Machine"
            variant="secondary"
            onClick={onEdit}
          />
        </Tooltip>
      }
    >
      <Flex gap=".4rem">
        <Button
          disabled={!isValid}
          processing={isSyncing}
          text="Save"
          type="submit"
          onClick={onSave}
        />
        <Button
          disabled={isSyncing}
          text="Cancel"
          variant="secondary"
          onClick={onCancel}
        />
      </Flex>
    </Conditional>
  );
};
