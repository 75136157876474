import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledFlex = styled(Flex)`
  ${({ theme }) => {
    return css`
      box-shadow: inset 2px 0 ${theme.colors.palette.green_300};
      border-bottom: 1px solid ${theme.colors.dividers.t1};
    `;
  }}
`;
