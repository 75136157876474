import { FC, PropsWithChildren } from 'react';
import { Cell, Conditional } from 'gantri-components';
import { BreadCrumbs } from './components/breadcrumbs';
import { Title } from './components/title';
import { SubTitle } from './components/sub-title';
import { PageHeadingProps } from './page-heading.types';
import {
  StyledDesktopBreadcrumbs,
  StyledPageHeadingGrid,
  StyledPageHeadingWrapper,
} from './page-heading.styles';
import { pageHeadingDefaultProps } from './page-heading.presets';

/**
 * - `children` appears to the right of everything else.
 * - Any non-specified props apply to the wrapping div.
 *  */
export const PageHeading: FC<PropsWithChildren<PageHeadingProps>> = (props) => {
  const {
    ContentBelow,
    breadcrumbs,
    children,
    columns,
    subTitle,
    subTitleProps,
    title,
    ...rest
  } = props;

  return (
    <StyledPageHeadingWrapper {...rest}>
      <StyledPageHeadingGrid
        alignItems="center"
        columnGap="1rem"
        columns={columns}
        height="100%"
        justifyContent="space-between"
        rowGap="unset"
        width="100%"
      >
        {breadcrumbs && (
          <Cell column="1/3" hidden={{ lg: true, md: false }}>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
          </Cell>
        )}
        <Cell>
          {breadcrumbs && (
            <StyledDesktopBreadcrumbs breadcrumbs={breadcrumbs} />
          )}
          <Conditional condition={typeof title === 'string'} Fallback={title}>
            <Title>{title}</Title>
          </Conditional>

          <Conditional condition={!!subTitle}>
            <Cell justifySelf="start">
              <SubTitle {...subTitleProps}>{subTitle}</SubTitle>
            </Cell>
          </Conditional>
        </Cell>
        {children && <Cell>{children}</Cell>}
      </StyledPageHeadingGrid>
      {ContentBelow}
    </StyledPageHeadingWrapper>
  );
};

PageHeading.defaultProps = pageHeadingDefaultProps;
