import {
  Cell,
  Conditional,
  Grid,
  Radio,
  Stack,
  TextArea,
  TextField,
  Typography,
} from 'gantri-components';
import { Fragment, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  exceededCycleTimeOptions,
  exceededCycleTimeText,
} from './exceeded-cycle-time-content.constants';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';

export const ExceededCycleTimeContent = () => {
  const [exceededCycleTimeReason, setExceededCycleTimeReason] = useRecoilState(
    completeAssemblyModalAtoms.exceededCycleTimeReason,
  );
  const step = useRecoilValue(completeAssemblyModalAtoms.step);

  const otherIsActive = exceededCycleTimeReason?.label === 'Other';
  const [otherReason, setOtherReason] = useState<string>(
    otherIsActive ? exceededCycleTimeReason.value : '',
  );

  useEffect(() => {
    if (!otherIsActive) {
      setOtherReason('');
    }
  }, [otherIsActive]);

  return (
    <Stack gap="4x">
      <Conditional
        condition={step === 'JOB_FAILED_EXCEEDED_CYCLE_TIME'}
        Fallback={
          <ModalContentHeading
            color="success"
            titleText={`${jobSteps.assemble} ${jobTypeOptions.assemble} job completed`}
          />
        }
      >
        <ModalContentHeading
          color="alert"
          titleText={`${jobSteps.assemble} ${jobTypeOptions.assemble} job failed`}
        />
      </Conditional>
      <Stack gap=".5x">
        <Typography text="Exceeded Cycle Time" variant="h5" />
        <Typography text={exceededCycleTimeText.textJobTimeExceeded} />
      </Stack>
      <Stack gap="3x">
        <Stack gap=".5x">
          {exceededCycleTimeOptions.map(({ label, value }) => {
            const isOtherOption = label === 'Other';

            const handleRadioClick = () => {
              setExceededCycleTimeReason({
                ...exceededCycleTimeReason,
                label,
                value: isOtherOption ? otherReason : value,
              });
            };

            return (
              <Fragment key={label}>
                <Grid alignItems="center" columns="max-content 1fr" gap="x">
                  <Radio
                    groupValue={exceededCycleTimeReason?.label}
                    value={label}
                    onSelected={handleRadioClick}
                  />
                  <Typography text={label} onClick={handleRadioClick} />
                  <Conditional condition={isOtherOption && otherIsActive}>
                    <Cell />
                    <TextField
                      placeholder={exceededCycleTimeText.placeholderOther}
                      required
                      value={otherReason}
                      onChange={(event) => {
                        const { value } = event.target;

                        setOtherReason(value);
                        setExceededCycleTimeReason({
                          ...exceededCycleTimeReason,
                          value,
                        });
                      }}
                    />
                  </Conditional>
                </Grid>
              </Fragment>
            );
          })}
        </Stack>
        <Stack gap="x">
          <Typography text={exceededCycleTimeText.textNotes} textStyle="bold" />
          <TextArea
            placeholder={exceededCycleTimeText.placeholderNotes}
            value={exceededCycleTimeReason?.notes}
            onChange={(event) => {
              const { value } = event.target;

              setExceededCycleTimeReason({
                ...exceededCycleTimeReason,
                notes: value,
              });
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
