import {
  jobsPageAtoms,
  jobsFiltersDefaults,
} from '../../components/dropdowns/jobs-filter/job-filter.atoms';
import { TableSortField } from '../../components/common/table/table.props';
import { ModalFiltersDetail } from '../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { GetPaginatedJobsArgs } from '../../api/jobs/routes/get-paginated-jobs/get-paginated-jobs.types';
import { JobsSortingField } from '../../components/dropdowns/jobs-filter/job-filter.types';

export const partsColumnNameByStep = {
  assemble: 'Parts',
  finish: 'Part',
  qa: 'Parts',
};

export const pageTitleByStep = {
  all: 'All Jobs',
  assemble: '03 Assemble Jobs',
  finish: '02 Finish Jobs',
  pack: '05 Pack Jobs',
  print: '01 Print Jobs',
  qa: '04 Qa Jobs',
};

export const stepParamValueByStep = {
  all: '',
  qa: 'QA',
};

/** Applying this data attribute marks a row as active, applying a new background color. */
export const dataAttrActiveRow = 'data-active-row';

export const dataAttrJobId = 'data-job-id';

export const jobsSortOptions: TableSortField<JobsSortingField>[] = [
  { label: 'Priority', sortingField: 'Priority' },
  { label: 'Start Date', sortingField: 'Start Date' },
  { label: 'End Date', sortingField: 'End Date' },
  { label: 'Machines', sortingField: 'Machines' },
  { label: 'Create Date', sortingField: 'Create Date' },
];

export const jobsModalFiltersDetails: ModalFiltersDetail<GetPaginatedJobsArgs>[] =
  [
    {
      atom: jobsPageAtoms.filters.assignedTo,
      defaultValue: jobsFiltersDefaults.filters.assignedTo,
      payloadKey: 'assignedTo',
    },
    {
      atom: jobsPageAtoms.filters.attentionOnly,
      defaultValue: jobsFiltersDefaults.filters.attentionOnly,
      payloadKey: 'attentionOnly',
    },
    {
      atom: jobsPageAtoms.filters.colors,
      defaultValue: jobsFiltersDefaults.filters.colors,
      payloadKey: 'colors',
    },
    {
      atom: jobsPageAtoms.filters.completedDateRange,
      defaultValue: jobsFiltersDefaults.filters.completedDateRange,
      payloadKey: 'completedDateRange',
    },
    {
      atom: jobsPageAtoms.filters.createdDateRange,
      defaultValue: jobsFiltersDefaults.filters.createdDateRange,
      payloadKey: 'createdDateRange',
    },
    {
      atom: jobsPageAtoms.filters.late,
      defaultValue: jobsFiltersDefaults.filters.late,
      payloadKey: 'late',
    },
    {
      atom: jobsPageAtoms.filters.locations,
      defaultValue: jobsFiltersDefaults.filters.locations,
      payloadKey: 'locations',
    },
    {
      atom: jobsPageAtoms.filters.machineIssues,
      defaultValue: jobsFiltersDefaults.filters.machineIssues,
      payloadKey: 'machineIssues',
    },
    {
      atom: jobsPageAtoms.filters.machineTypes,
      defaultValue: jobsFiltersDefaults.filters.machineTypes,
      payloadKey: 'machineTypes',
    },
    {
      atom: jobsPageAtoms.filters.materials,
      defaultValue: jobsFiltersDefaults.filters.materials,
      payloadKey: 'materials',
    },
    {
      atom: jobsPageAtoms.filters.productId,
      defaultValue: jobsFiltersDefaults.filters.productId,
      payloadKey: 'productId',
    },
    {
      atom: jobsPageAtoms.filters.orderId,
      defaultValue: jobsFiltersDefaults.filters.orderId,
      payloadKey: 'orderId',
    },

    {
      atom: jobsPageAtoms.sortBy,
      defaultValue: jobsFiltersDefaults.filters.sortBy,
      payloadKey: 'sortBy',
    },
    {
      atom: jobsPageAtoms.filters.statuses,
      defaultValue: jobsFiltersDefaults.filters.statuses,
      payloadKey: 'statuses',
    },
    {
      atom: jobsPageAtoms.filters.steps,
      defaultValue: jobsFiltersDefaults.filters.steps,
      payloadKey: 'steps',
    },
    {
      atom: jobsPageAtoms.filters.types,
      defaultValue: jobsFiltersDefaults.filters.types,
      payloadKey: 'types',
    },
    {
      atom: jobsPageAtoms.filters.users,
      defaultValue: jobsFiltersDefaults.filters.users,
      payloadKey: 'userIds',
    },
    {
      atom: jobsPageAtoms.filters.userStatuses,
      defaultValue: jobsFiltersDefaults.filters.userStatuses,
      payloadKey: 'userStatuses',
    },
  ];
