import { useRecoilValue } from 'recoil';
import { Stack } from 'gantri-components';
import { completeAssemblyModalAtoms } from '../../../complete-assembly-modal.atoms';
import { getFailingReasonsFromChecklist } from '../../../../../helpers/get-failing-reasons-from-checklist';
import { FailedPartDetails } from './components/failed-part-details';
import { ModalContentHeading } from '../../../../../common/modal-content-heading';
import {
  jobSteps,
  jobTypeOptions,
} from '../../../../../../../../../constants/options';

export const AssemblyCompletedFailedContent = () => {
  const partChecklist = useRecoilValue(
    completeAssemblyModalAtoms.partChecklist,
  );

  const failingPartIds = getFailingReasonsFromChecklist(partChecklist);

  return (
    <Stack gap="4x">
      <ModalContentHeading
        color="alert"
        titleText={`${jobSteps.assemble} ${jobTypeOptions.assemble} job failed`}
      />
      <Stack gap="5x">
        {failingPartIds.map((partId) => {
          return <FailedPartDetails key={partId} partId={partId} />;
        })}
      </Stack>
    </Stack>
  );
};
