import { Button, Cell, Conditional, Grid, Tooltip } from 'gantri-components';
import { ButtonProps } from 'gantri-components/dist/components/button/button.types';
import { inventoryPurchasesApi } from '../../../../../../../../api/inventory-purchases';
import { useNotification } from '../../../../../../../../hooks/useNotification';
import {
  getIsInvalidTrackingUrl,
  trackingUrlErrorMessage,
} from '../../helpers/get-is-invalid-tracking-url';
import { shipmentText } from '../../shipment.constants';
import { NotEditingShipmentButtons } from './components/not-editing-shipment-buttons';
import { ShipmentButtonsProps } from './shipment-buttons.types';

export const ShipmentButtons = (props: ShipmentButtonsProps) => {
  const {
    editedInventoryPurchase,
    editedShipment,
    isEditing,
    isEditingDisabled,
    isNewShipment,
    onUpdate,
    setEditedShipment,
    setIsEditing,
    setIsEditingDisabled,
    shipmentNumber,
    totalShipments,
  } = props;

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const handleSave = async () => {
    if (isNewShipment) {
      try {
        showLoading();

        const { data } =
          await inventoryPurchasesApi.createInventoryPurchaseShipment(
            editedShipment,
          );

        notify('Shipment created.');
        setIsEditing(false);
        setIsEditingDisabled(false);

        onUpdate(data.purchase);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create new shipment.',
        });
      } finally {
        hideLoading();
      }
    } else {
      try {
        showLoading();

        const { data } =
          await inventoryPurchasesApi.updateInventoryPurchaseShipment(
            editedShipment,
          );

        notify('Shipment updated.');
        setIsEditing(false);
        setIsEditingDisabled(false);

        onUpdate(data.purchase);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update shipment.',
        });
      } finally {
        hideLoading();
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsEditingDisabled(false);
    onUpdate();
  };

  const SaveButton = (props: Partial<ButtonProps>) => {
    return <Button text="Save" onClick={handleSave} {...props} />;
  };

  const isInvalidTrackingUrl = getIsInvalidTrackingUrl(
    editedShipment.trackingLink,
  );
  const isInvalidQuantity = !editedShipment.expectedQuantity;

  const disableSaveTooltipDescription = [
    isInvalidTrackingUrl && trackingUrlErrorMessage,
    isInvalidQuantity && `${shipmentText.expectedQuantity.label} is required.`,
  ]
    .filter((item) => {
      return item;
    })
    .join(' ');

  return (
    <Conditional
      condition={isEditing}
      Fallback={
        <NotEditingShipmentButtons
          editedInventoryPurchase={editedInventoryPurchase}
          editedShipment={editedShipment}
          isEditingDisabled={isEditingDisabled}
          setEditedShipment={setEditedShipment}
          setIsEditing={setIsEditing}
          setIsEditingDisabled={setIsEditingDisabled}
          shipmentNumber={shipmentNumber}
          totalShipments={totalShipments}
          onUpdate={onUpdate}
        />
      }
    >
      <Cell width={{ lg: 1, sm: 2 }}>
        <Grid
          alignItems="center"
          columns="repeat(2, max-content)"
          gap="0.8rem"
          justifyContent={{ lg: 'flex-end', sm: 'flex-start' }}
        >
          <Conditional
            condition={isInvalidQuantity || isInvalidTrackingUrl}
            Fallback={<SaveButton />}
          >
            <Tooltip
              description={disableSaveTooltipDescription}
              position="top-end"
            >
              <SaveButton disabled />
            </Tooltip>
          </Conditional>
          <Button text="Cancel" variant="secondary" onClick={handleCancel} />
        </Grid>
      </Cell>
    </Conditional>
  );
};
