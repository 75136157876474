import { Stack, Typography } from 'gantri-components';

export const JobNotAddedContent = () => {
  return (
    <Stack alignContent="center" gap="0" minHeight="18.8rem">
      <Typography
        align="center"
        text="There was en error and job could not be added."
      />
      <Typography align="center" text="Please try again." />
    </Stack>
  );
};
