import { Conditional, Icon } from 'gantri-components';
import {
  StyledIconWrapper,
  StyledImage,
  StyledThumbnailWrapper,
} from './thumbnail-with-zoom-modal.styles';
import { ThumbnailWithZoomModalProps } from './thumbnail-with-zoom-modal.types';
import { placeholderImageSrc } from '../../../../../../../../../../constants/images';
import { cloudinaryUrl } from '../../../../../../../../../../helpers/images';
import { useThumbnailZoomModal } from '../advanced-checklist-reason/use-thumbnail-zoom-modal';

export const ThumbnailWithZoomModal = (props: ThumbnailWithZoomModalProps) => {
  const { alt, heading, src } = props;

  const imgSrc = src || placeholderImageSrc;

  const [showZoomModal] = useThumbnailZoomModal({
    alt: alt || heading,
    heading,
    src,
  });

  return (
    <StyledThumbnailWrapper onClick={!!src ? showZoomModal : undefined}>
      <StyledImage
        alt={alt}
        src={cloudinaryUrl(imgSrc, {
          aspectRatio: 1,
          crop: 'fill',
          width: 300,
        })}
      />
      <Conditional condition={!!src}>
        <StyledIconWrapper padding="0.2rem">
          <Icon name="view:eye_open_24" size="2.4rem" />
        </StyledIconWrapper>
      </Conditional>
    </StyledThumbnailWrapper>
  );
};
