import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CreateInventoryTransactionArgs } from './create-inventory-transaction.types';

export const createTransaction = (args: CreateInventoryTransactionArgs) => {
  const {
    cost = undefined,
    discardReason = undefined,
    inventoryId,
    notes,
    stock,
    stockChange,
    transactionDate,
    type,
  } = args;

  return axios({
    data: {
      cost,
      discardReason,
      inventoryId,
      notes,
      stock,
      stockChange,
      transactionDate,
      type,
    },
    method: 'post',
    url: `${coreApiUrl}/inventory-transaction`,
  }).then((response) => {
    return response.data;
  });
};
