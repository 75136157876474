import { capitalize } from 'lodash';
import { SortInfo, TableSortField } from '../../../../table.props';
import { SortMapping } from './get-sort-mapping.types';

export const getSortMapping = <SortingField = string>(
  sortingOptions: TableSortField<SortingField>[] = [],
) => {
  return sortingOptions.reduce<SortMapping<SortingField>>((acc, current) => {
    if (current.bidirectional) {
      const ascendingLabel = 'asc.';
      const ascendingSortInfo: SortInfo<SortingField> = {
        label: `${current.label} - ${ascendingLabel}`,
        sortingField: current.sortingField,
        sortingType: 'ASC',
      };
      const descendingLabel = 'desc.';
      const descendingSortInfo: SortInfo<SortingField> = {
        label: `${current.label} - ${descendingLabel}`,
        sortingField: current.sortingField,
        sortingType: 'DESC',
      };

      return {
        ...acc,
        [`${capitalize(current.label.toLowerCase())} ${ascendingLabel}`]:
          ascendingSortInfo,
        [`${capitalize(current.label.toLowerCase())} ${descendingLabel}`]:
          descendingSortInfo,
      };
    }

    return {
      ...acc,
      [`${current.label}`]: {
        ...current,
        sortingType: current.sortingType || 'DESC',
      },
    };
  }, {} as SortMapping<SortingField>);
};
