import axios from 'axios';
import { environment } from '../../../../environment';
import { ResendInviteResponse } from './resend-invite.types';

export const resendInvite = (id: number) => {
  return axios.post<ResendInviteResponse>(
    `${environment.API_URL}/invite/${id}/resend`,
    null,
  );
};
