import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultMachinesGroupBy } from '../../report-machines.constants';
import { ReportMachinesQueries } from '../../report-machines.types';

export const getGroupByIsVisible = (): boolean => {
  const queries = getActiveQueries<ReportMachinesQueries>();
  const { groupBy = defaultMachinesGroupBy, machineId, machineType } = queries;

  if (
    (/^machineType$/i.test(groupBy) && machineType) ||
    (/^machineName$/i.test(groupBy) && machineId)
  ) {
    return false;
  }

  return true;
};
