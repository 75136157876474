import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FetchAllVendorNamesResponse } from './fetch-all-vendor-names.types';

const query = (query?: string): Promise<FetchAllVendorNamesResponse> => {
  return axios({
    method: 'get',
    params: { query },
    url: `${coreApiUrl}/vendors/vendor-names`,
  }).then((response) => {
    return {
      ...response.data,
      vendors: response.data.vendorNames ?? [],
    };
  });
};

const groupKey = () => {
  return 'vendor-names';
};

const key = (query?: string) => {
  return !!query
    ? [{ groupKey: groupKey(), key: `/vendors/vendor-names/${query}` }]
    : [
        {
          groupKey: groupKey(),
          key: '/vendors/vendor-names',
        },
      ];
};

export const fetchAllVendorNames = {
  groupKey,
  key,
  query,
};
