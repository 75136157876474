import {
  getSearchFilterAtomFamily,
  getSortByFilterAtomFamily,
} from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.atoms';
import { getGenericAtomFamily } from '../../../../helpers/get-generic-atom-family';
import { SortBy } from '../../../../components/common/table/hooks/use-table-filters/use-table-filters.types';
import { AtomPageName } from '../../../../helpers/get-generic-atom-family/get-generic-atom-family.types';
import { DesignersSortingField } from '../../designers.types';

export const designersFiltersDefaults: {
  filters: {
    active: boolean;
  };
  sortBy: SortBy<DesignersSortingField>;
} = {
  filters: {
    active: false,
  },
  sortBy: {
    sortingField: 'name',
    sortingType: 'ASC',
  },
};

const atomKeyPrefix = 'DESIGNERS_FILTERS';

export const pageName: AtomPageName = 'designers';

export const designersPageAtoms = {
  defaults: designersFiltersDefaults,
  filters: {
    active: getGenericAtomFamily({
      defaultValue: designersFiltersDefaults.filters.active,
      key: `${atomKeyPrefix}-active`,
    })(pageName),
  },
  search: getSearchFilterAtomFamily(pageName),
  sortBy: getSortByFilterAtomFamily<SortBy<DesignersSortingField>>({
    defaultValue: designersFiltersDefaults.sortBy,
  })(pageName),
};
