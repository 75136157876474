import { Typography } from 'gantri-components';
import { formatAsLocaleNumber } from '../../../../../helpers/formatter';
import { NumberCellProps } from './number-cell.types';

export const NumberCell = (props: NumberCellProps) => {
  const { formatNumberOptions, typographyProps, value } = props;
  const isNaN = Number.isNaN(Number(value));

  return (
    <Typography
      {...typographyProps}
      text={isNaN ? '-' : formatAsLocaleNumber(value, formatNumberOptions)}
    />
  );
};
