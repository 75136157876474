import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useRecoilValue } from 'recoil';
import { UseGetReportDataProps } from './use-get-report-data.types';
import { dashboardAndReportAtoms } from '../../../../../dashboard.atoms';
import { DashboardSummaryDateRangeType } from '../../../../../dashboard.types';

export const useGetReportData = (props: UseGetReportDataProps) => {
  const { activeTab, setReport, useGetDashboardReportSummary } = props;

  const startDate = useRecoilValue(dashboardAndReportAtoms.startDate);
  const endDate = useRecoilValue(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const { inView: isInView, ref: observerRef } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const { chartData, isLoading, tableData } = useGetDashboardReportSummary({
    args: {
      activeTab,
      endDate,
      rangeType: rangeType as DashboardSummaryDateRangeType,
      startDate,
    },
    enabled: isInView,
  });

  useEffect(() => {
    setReport({
      chartData: chartData || {},
      tableData: tableData || [],
    });
  }, [chartData, tableData]);

  return {
    isFetching: isLoading,
    isInView,
    observerRef,
  };
};
