/* eslint-disable   sort-keys-fix/sort-keys-fix */

import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { Checklist } from '../../common/job-checklist/job-checklist.types';
import { CompleteAssemblyModalFailReason } from './complete-assembly-modal.types';

export const completeAssemblyChecklistDefaults: Checklist<CompleteAssemblyModalFailReason> =
  {
    // keys are sorted according to desired display order
    bad_finish: {
      ...checklistDefaults.bad_finish,
      requireDetailsOnFail: true,
    },
    bad_fit: {
      ...checklistDefaults.bad_fit,
      requireDetailsOnFail: true,
    },
    lost_part: {
      ...checklistDefaults.lost_part,
    },
    wrong_part: {
      ...checklistDefaults.wrong_part,
    },
    damaged_by_assembly: {
      ...checklistDefaults.damaged_by_assembly,
      requireDetailsOnFail: true,
    },
    other_failure_modes: {
      ...checklistDefaults.other_failure_modes,
    },
  };

export const maxExceededCycleTime = 20;
