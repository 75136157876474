import { Cell, Dropdown, TextField, Typography } from 'gantri-components';
import { useState } from 'react';
import { Label } from '../../../../components/label';
import {
  factoryDestinations,
  inventoryPurchasePriority,
  inventoryPurchaseStatuses,
} from '../../../../constants/inventory-purchases';
import {
  canadaStates,
  countries,
  usaStates,
} from '../../../../constants/locales';
import { ResponsiveGrid } from '../responsive-grid';
import { SectionHeading } from '../section-heading';
import { SectionSubheading } from '../section-subheading';
import { ShippingDetailsSectionProps } from './shipping-details-section.types';

export const ShippingDetailsSection = (props: ShippingDetailsSectionProps) => {
  const {
    editedInventoryPurchase,
    handleSave,
    isEditingDisabled,
    toggleEditMode,
    updateEditedInventoryPurchaseWith,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);

  const canEditShippingDetails = [
    inventoryPurchaseStatuses.waiting,
    inventoryPurchaseStatuses.readyToShip,
  ].some((status) => {
    return status === editedInventoryPurchase.status;
  });

  const disabledTooltip = canEditShippingDetails
    ? ''
    : `Inventory purchase must have status "${inventoryPurchaseStatuses.waiting}" or "${inventoryPurchaseStatuses.readyToShip}" to edit this section.`;

  const formattedDestination = factoryDestinations.find((destination) => {
    return destination.value === editedInventoryPurchase.address;
  })?.label;

  const countryOptions = countries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  return (
    <>
      <SectionHeading
        disabledTooltip={disabledTooltip}
        handleSave={handleSave}
        isEditing={isEditing}
        isEditingDisabled={isEditingDisabled || !canEditShippingDetails}
        setIsEditing={setIsEditing}
        text="Shipping Details"
        toggleEditMode={toggleEditMode}
      />
      <SectionSubheading text="Origin:" />
      <ResponsiveGrid>
        <Cell>
          <Label paddingBottom=".5x" text="Country" />
          {isEditing && canEditShippingDetails ? (
            <Dropdown
              items={countryOptions}
              placeholder="Country"
              value={editedInventoryPurchase.country}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  country: event.target.value,
                });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.country} />
          )}
        </Cell>

        <Cell>
          <Label paddingBottom=".5x" text="State/Province" />
          {isEditing && canEditShippingDetails ? (
            <>
              {editedInventoryPurchase.country === 'United States' && (
                <Dropdown
                  items={usaStates.map(({ name }) => {
                    return {
                      label: name,
                      value: name,
                    };
                  })}
                  placeholder="State"
                  value={editedInventoryPurchase.state}
                  onChange={(event) => {
                    updateEditedInventoryPurchaseWith({
                      state: event.target.value,
                    });
                  }}
                />
              )}
              {editedInventoryPurchase.country === 'Canada' && (
                <Dropdown
                  items={canadaStates.map(({ name }) => {
                    return {
                      label: name,
                      value: name,
                    };
                  })}
                  placeholder="State"
                  value={editedInventoryPurchase.state}
                  onChange={(event) => {
                    updateEditedInventoryPurchaseWith({
                      state: event.target.value,
                    });
                  }}
                />
              )}
              {!['United States', 'Canada'].includes(
                editedInventoryPurchase.country,
              ) && (
                <TextField
                  placeholder="State"
                  value={String(editedInventoryPurchase.state)}
                  onChange={(event) => {
                    updateEditedInventoryPurchaseWith({
                      state: event.target.value,
                    });
                  }}
                />
              )}
            </>
          ) : (
            <Typography text={editedInventoryPurchase.state} />
          )}
        </Cell>

        <Cell>
          <Label paddingBottom=".5x" text="City" />
          {isEditing && canEditShippingDetails ? (
            <TextField
              placeholder="City"
              value={editedInventoryPurchase.city}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({ city: event.target.value });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.city} />
          )}
        </Cell>
      </ResponsiveGrid>

      <SectionSubheading text="Destination:" />
      <ResponsiveGrid>
        <Cell>
          <Label paddingBottom=".5x" text="Address" />
          {isEditing && canEditShippingDetails ? (
            <Dropdown
              items={factoryDestinations}
              placeholder="Address"
              value={editedInventoryPurchase.address}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  address: event.target.value,
                });
              }}
            />
          ) : (
            <Typography text={formattedDestination} />
          )}
        </Cell>
        <Cell />
        <Cell>
          <Label paddingBottom=".5x" text="Priority" />
          {isEditing && canEditShippingDetails ? (
            <Dropdown
              items={inventoryPurchasePriority.map((value) => {
                return {
                  label: value,
                  value,
                };
              })}
              placeholder="Priority"
              value={editedInventoryPurchase.priority}
              onChange={(event) => {
                updateEditedInventoryPurchaseWith({
                  priority: event.target.value,
                });
              }}
            />
          ) : (
            <Typography text={editedInventoryPurchase.priority} />
          )}
        </Cell>
      </ResponsiveGrid>
    </>
  );
};
