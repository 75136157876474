import { Modal } from 'gantri-components';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { CompleteJobImmediatelyFooter } from './components/complete-job-immediately-footer';
import { CompleteJobImmediatelyContent } from './components/complete-job-immediately-content';
import { CompleteJobImmediatelyModalProps } from './complete-job-immediately-modal.types';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

/** This modal will immediately start and complete the job on initial confirmation. */
export const CompleteJobImmediatelyModal = (
  props: CompleteJobImmediatelyModalProps,
) => {
  const { job, onClose, onJobUpdated } = props;

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
  });

  return (
    <Modal
      closeable={false}
      dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
      detailsPanel={
        <JobDetailsPanelLarge
          job={job}
          sectionsToShow={[
            'part',
            'color & material',
            'product',
            'instructions',
            'part diagram',
            'part diagram swatches',
          ]}
        />
      }
      detailsPanelWidth="1fr"
      footer={
        <CompleteJobImmediatelyFooter
          handleCloseModal={handleCloseModal}
          job={job}
        />
      }
      header={
        <FullScreenModalHeader handleCloseModal={handleCloseModal} job={job} />
      }
      isFullScreen
    >
      <CompleteJobImmediatelyContent job={job} />
    </Modal>
  );
};
