import { useModal } from 'gantri-components';
import { useRef } from 'react';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { Download } from '../downloads.types';
import { DownloadModal } from './components/download-modal';
import { DownloadFormState } from './download-modal.types';
import { downloadsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';

export const useDownloadModal = (props: { onComplete?: () => void }) => {
  const { onComplete } = props;
  const downloadRef = useRef<Download>();
  const { notify, notifyAxiosError, onInterceptProcessingRequest, processing } =
    useNotification();

  const [showModal, hideModal] = useModal(() => {
    return (
      <DownloadModal
        download={downloadRef.current}
        processing={processing}
        onClose={hideModal}
        onSave={onSave}
      />
    );
  }, [downloadRef.current, processing]);

  const onOpenDownloadModal = (download?: Download) => {
    downloadRef.current = download;
    showModal();
  };

  const onSave = async (values: DownloadFormState) => {
    await onInterceptProcessingRequest(async () => {
      const data = pick(values, [
        'description',
        'fileName',
        'link',
        'name',
        'thumbnail',
        'type',
      ]);

      if (!!values.id) {
        await onUpdate(values.id, data);

        return;
      }

      await onCreate(data);
    });
  };

  const onUpdate = async (id: number, values: DownloadFormState) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await downloadsApi.updateDownload({
          ...values,
          id,
        });

        notify(data.notice || 'Item update successful.');

        if (data?.newDownload) {
          hideModal();
          onComplete?.();
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to update item.',
        });
      }
    });
  };

  const onCreate = async (values: DownloadFormState) => {
    await onInterceptProcessingRequest(async () => {
      try {
        const { data } = await downloadsApi.createDownload({
          ...omit(values, ['id']),
        });

        notify(data.notice || 'Item creation successful.');

        if (data?.newDownload) {
          hideModal();
          onComplete?.();
        }
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to create item.',
        });
      }
    });
  };

  return onOpenDownloadModal;
};
