import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetNpsReviewDetailResponse } from './get-nps-review-detail.types';

const query = async (reviewId: number): Promise<GetNpsReviewDetailResponse> => {
  const response = await axios.get<GetNpsReviewDetailResponse>(
    `${coreApiUrl}/nps-reviews/${reviewId}`,
  );

  return response.data;
};

const groupKey = () => {
  return 'user-nps-review-details';
};

const key = (reviewId: number) => {
  return [
    {
      groupKey: groupKey(),
      key: `${groupKey()}/${reviewId}`,
    },
  ] as const;
};

export const getNpsReviewDetail = {
  groupKey,
  key,
  query,
};
