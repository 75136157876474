export async function hashArray(array: any[]): Promise<string> {
  const msgUint8 = new TextEncoder().encode(JSON.stringify(array));
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray
    .map((buffer) => {
      return buffer.toString(16).padStart(2, '0');
    })
    .join('');
}
