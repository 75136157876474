import * as yup from 'yup';
import {
  decimalRule,
  optional,
  required,
  validateRequiredDecimalNumber,
} from '../../../../helpers/validators';

export const NewTransactionSchema = yup.object().shape({
  cost: yup.lazy((_, context) => {
    return context.parent.type === 'Purchase'
      ? validateRequiredDecimalNumber()
      : decimalRule(yup.string().nullable(true));
  }),
  discardReason: yup.lazy((_, context) => {
    return context.parent.type === 'Discarded' ? required() : optional();
  }),
  notes: required(),
  stockChange: required(),
  transactionDate: required(),
  type: required(),
});
