import { FC, useMemo, useState } from 'react';
import cx from 'classnames';
import {
  Box,
  Button,
  ColorDetails,
  ColorPicker,
  Grid,
  Modal,
  productColorsMap,
  Typography,
} from 'gantri-components';
import styles from '../../../styles.module.scss';
import { stockStatuses } from '../../../../../../../../constants/options';
import { EditColorProps } from './edit-colors.types';
import { CurrentOrderData } from '../../../../../../../../types/store';
import { StocksStatus } from '../../../../../../../../components/dropdowns/stocks-filter/stocks-filter.constants';
import { useSpinner } from '../../../../../../../../hooks';
import { transactionsApi } from '../../../../../../../../api';
import { unpaintedColor } from '../../../../../../../../constants/colors';
import { ShortProductSummary } from '../../../../../../../../components/common/short-product-summary';

export const EditColor: FC<EditColorProps> = (props) => {
  const { currentOrderData, onClose, setCurrentOrderData, visible } = props;

  const { colorChangedStocks = [], id: orderId, shipments } = currentOrderData;
  // todo: this is temporal solution, this file needs a complete refactor
  const [temporalStockColors, setTemporalStockColors] = useState<
    Record<string, string>
  >({});

  const { onInterceptRequest } = useSpinner();

  const handleConfirm = async () => {
    return onInterceptRequest(async () => {
      await transactionsApi
        .editColor({
          stockData: colorChangedStocks,
          stockInfoId: orderId,
        })
        .then(({ data }) => {
          return data;
        })
        .then((data: { order: CurrentOrderData; success: boolean }) => {
          if (data.success) {
            setCurrentOrderData(data.order);
          }

          onClose();
        });
    });
  };

  const handleCancel = async () => {
    onClose();

    shipmentWithEditableStocks.forEach((shipment) => {
      if (shipment.stocks) {
        shipment.stocks.forEach((stock) => {
          setTemporalStockColors((current) => {
            return {
              ...current,
              [stock.id]: stock.product.color.code,
            };
          });
        });
      }
    });
  };

  const totalShipments = shipments && shipments.length;

  const shipmentWithEditableStocks = useMemo(() => {
    if (shipments && shipments.length) {
      return shipments.filter((shipment) => {
        return shipment.stocks.some((stock) => {
          const editableStatuses: StocksStatus[] = [stockStatuses.waiting];

          return editableStatuses.includes(stock.status);
        });
      });
    }

    return [];
  }, [shipments]);

  const renderShipments = () => {
    return shipmentWithEditableStocks.map(
      ({ id: shipmentId, stocks: shipStocks }, i) => {
        const shipmentIdx = i + 1;

        const shipmentStocks = shipStocks
          .filter((stock) => {
            const editableStatuses: StocksStatus[] = [stockStatuses.waiting];

            return editableStatuses.includes(stock.status);
          })
          .map(
            ({
              id: stockId,
              product,
              productOptions: { colors: colorOptions },
              stockInfoId,
            }) => {
              const handleColorChange = (color: ColorDetails) => {
                if (!color) {
                  return;
                }

                const { code: cCode, name: cName } = color;

                const existingChangedStock = colorChangedStocks.find(
                  ({ id }) => {
                    return id === stockInfoId;
                  },
                );

                if (!existingChangedStock) {
                  colorChangedStocks.push({
                    id: stockInfoId,
                    newColor: {
                      code: cCode,
                      name: cName,
                    },
                  });
                } else {
                  const existingStockIdx = `${colorChangedStocks.indexOf(
                    existingChangedStock,
                  )}`;

                  colorChangedStocks[existingStockIdx] = {
                    id: stockInfoId,
                    newColor: {
                      code: cCode,
                      name: cName,
                    },
                  };
                }

                setCurrentOrderData({
                  ...currentOrderData,
                  colorChangedStocks,
                });

                setTemporalStockColors((current) => {
                  return {
                    ...current,
                    [stockId]: cCode,
                  };
                });
              };

              const stockStyles = cx(
                styles['border-default'],
                styles['stock-wrapper'],
                styles['edit-color'],
                'row-flex-start',
              );

              const colorOptionsData: ColorDetails[] = colorOptions.map(
                (item) => {
                  return {
                    code: item.code === unpaintedColor.code ? null : item.code,
                    name: productColorsMap[item.code]?.shortColorName,
                  };
                },
              );

              return (
                <div key={stockId} className={stockStyles}>
                  <Box padding="x">
                    <ShortProductSummary fetchSku={product.sku}>
                      <Box marginTop="x">
                        <ColorPicker
                          allowMultipleSelection={false}
                          colors={colorOptionsData}
                          value={
                            temporalStockColors[stockId] ?? product?.color?.code
                          }
                          onValueChange={handleColorChange}
                        />
                      </Box>
                    </ShortProductSummary>
                  </Box>
                </div>
              );
            },
          );

        return (
          <div key={shipmentId}>
            {!shipmentStocks ? null : (
              <div className={cx(styles['shipment-section'])}>
                <Typography
                  text={`Shipment ${shipmentIdx}/${totalShipments}`}
                />

                <Grid alignItems="start">{shipmentStocks}</Grid>
              </div>
            )}
          </div>
        );
      },
    );
  };

  return (
    visible && (
      <Modal
        footer={
          <>
            <Button
              size="large"
              text={!!shipmentWithEditableStocks.length ? 'Cancel' : 'Ok'}
              variant="secondary"
              onClick={handleCancel}
            />
            {!!shipmentWithEditableStocks.length && (
              <Button
                disabled={colorChangedStocks.length < 1}
                size="large"
                text="Confirm"
                onClick={handleConfirm}
              />
            )}
          </>
        }
        height={{
          lg: 'max-content',
        }}
        width={{
          lg: 'max-content',
          sm: '100%',
        }}
        onClose={handleCancel}
      >
        <Typography
          align="center"
          text="Change item"
          variant="h4"
          verticalPadding="3rem"
        />
        {shipmentWithEditableStocks.length ? (
          renderShipments()
        ) : (
          <Typography
            align="center"
            marginTop="1rem"
            text="There are no items that can be changed."
          />
        )}
      </Modal>
    )
  );
};
