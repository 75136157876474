import { useQueryMutation } from '../../../../hooks/use-mutation';
import { useNotification } from '../../../../hooks/useNotification';
import { createDesignerFeature } from './create-feature-settings';
import {
  CreateFeatureArgs,
  CreateFeatureResponse,
} from './create-feature-settings.types';

export const useCreateDesignerFeature = () => {
  const { notify, notifyAxiosError } = useNotification();

  const { onMutate: onCreateDesignerFeature, ...rest } = useQueryMutation<
    CreateFeatureArgs,
    CreateFeatureResponse
  >({
    config: {
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Error on create new collection page.',
        });
      },
      onSuccess: (data) => {
        const { notice } = data;

        notify(
          notice || "Designer's Feature Settings has been created successfully",
        );
      },
    },
    mutationFn: async (args) => {
      return createDesignerFeature(args).then(({ data }) => {
        return data;
      });
    },
    showLoading: true,
  });

  return { ...rest, onCreateDesignerFeature };
};
