import { Checkbox } from 'gantri-components';
import { CheckboxProps } from 'gantri-components/dist/components/checkbox/checkbox.types';
import { IssueDetails } from '../../../common/issue-details';
import { StyledStack } from './issue-checkbox.styles';
import { IssueCheckboxProps } from './issue-checkbox.types';

export const IssueCheckbox = (props: IssueCheckboxProps) => {
  const { isDisabledIssue, issue, onClose, selectedIssues, setSelectedIssues } =
    props;

  const checked = selectedIssues.some(({ id }) => {
    return id === issue.id;
  });
  const disabled = isDisabledIssue?.(issue) ?? false;

  const handleToggleCheckbox: CheckboxProps['onChange'] = (event) => {
    const checked: boolean = event.target.value;

    if (checked) {
      setSelectedIssues([...selectedIssues, issue]);
    } else {
      const updatedIssues = [...selectedIssues];
      const index = updatedIssues.indexOf(issue);

      updatedIssues.splice(index, 1);
      setSelectedIssues(updatedIssues);
    }
  };

  return (
    <StyledStack gap="0.8rem">
      <Checkbox
        checked={checked}
        Component={() => {
          return <IssueDetails issue={issue} onClose={onClose} />;
        }}
        disabled={disabled}
        labelText={issue.type}
        onChange={handleToggleCheckbox}
      />
    </StyledStack>
  );
};
