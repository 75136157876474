import { useRecoilValue } from 'recoil';
import { startAssembleStageModalAtoms } from '../../start-assemble-stage-job-modal.atoms';
import { AssembleStageJobContentProps } from './assemble-stage-job-content.types';
import { ConfirmStartContent } from '../step-confirm-start/confirm-start-content';
import { GetFromVlmContent } from '../step-get-from-vlm/get-from-vlm-content';
import { NoAssignedLocationContent } from '../step-no-assigned-location/no-assigned-location-content';

export const AssembleStageJobContent = (
  props: AssembleStageJobContentProps,
) => {
  const { job } = props;

  const step = useRecoilValue(startAssembleStageModalAtoms.step);

  switch (step) {
    case 'CONFIRM_START':
      return <ConfirmStartContent />;

    case 'GET_FROM_VLM':
      return <GetFromVlmContent job={job} />;

    case 'NO_ASSIGNED_LOCATION':
      return <NoAssignedLocationContent job={job} />;

    default:
      return null;
  }
};
