import { useDebounce } from 'react-use';
import { useState } from 'react';
import { useSpinner } from './use-spinner';

type UseSearchProps = {
  debounceTime?: number;
  enableLoadingBehavior?: boolean;
  onSearchCallback?: (search: string) => void;
};

export const useSearch = ({
  debounceTime = 300,
  enableLoadingBehavior = false,
  onSearchCallback,
}: UseSearchProps) => {
  const { onInterceptRequest } = useSpinner();
  const [search, setSearch] = useState<string>('');

  const onChangeSearch = async () => {
    if (enableLoadingBehavior) {
      return onInterceptRequest(async () => {
        return onSearchCallback?.(search.toLowerCase());
      });
    }

    onSearchCallback?.(search.toLowerCase());
  };

  useDebounce(onChangeSearch, debounceTime, [search]);

  return { onChangeSearch: setSearch };
};
