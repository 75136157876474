import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { UploadFileArgs, UploadFileResponse } from './upload-file.types';
import { uploadFile } from './upload-file';

export const useUploadCloudinaryFile = <TransformedData = UploadFileResponse>(
  props?: GenericMutateQueryProps<
    UploadFileArgs,
    UploadFileResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUploadCloudinaryFile, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to upload file.',
    mutationFn: uploadFile,
    ...props,
  });

  return { ...rest, onUploadCloudinaryFile };
};
