import { ResolutionAwareProp } from 'gantri-components/dist/types/resolution-aware-prop.type';
import styled, { css, CSSProperties } from 'styled-components';
import { generateStylesForResolutionAwareProps } from '../../../../../helpers/layout.helpers';
import { ExtractStyleFromResolutionAwarePropEntry } from '../../../../../types/layout';

export const StyledChartWrapper = styled.div<{
  $minHeight: ResolutionAwareProp<CSSProperties['minHeight']>;
}>`
  overflow: hidden; // prevents chart jump from tooltip being rendered on mouse enter causing overflow
  width: 99%; // https://github.com/plouc/nivo/issues/411#issuecomment-761939245
  height: 100%;

  ${({ $minHeight }) => {
    const entries: ExtractStyleFromResolutionAwarePropEntry[] = [
      {
        cssProperty: 'min-height',
        resolutionAwareProp: $minHeight,
        valueFormatter: (value) => {
          return value || '12rem';
        },
      },
    ];

    return generateStylesForResolutionAwareProps(entries);
  }}

  > div > div {
    // solves issue with div wrapping chart svg adding ~5px extra height
    display: grid;
  }

  // solves issue with linear gradient CSS defs in Safari sometimes failing to load, causing a black fill area
  path[fill='url(#positive-fill-color)'] {
    ${({ theme }) => {
      return css`
        fill: ${theme.colors.surfaces.green.t1};
      `;
    }}
  }
  // solves issue with linear gradient CSS defs in Safari sometimes failing to load, causing a black fill area
  path[fill='url(#negative-fill-color)'] {
    ${({ theme }) => {
      return css`
        fill: ${theme.colors.surfaces.alert.t1};
      `;
    }}
  }
`;

export const StyledTooltip = styled.div`
  padding: 0.8rem 1.6rem;
  border-radius: 3px;
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.overlayLevel1};
      border: 1px solid ${theme.colors.dividers.t1};
      box-shadow: ${theme.colors.shadow.low};
    `;
  }}
`;
