import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ButtonToggleGroup } from '../../../../../../../components/common/button-toggle-group';
import { useOnDateRangeTypeChange } from '../../hooks/use-on-date-range-type-change';
import { DashboardDateTogglesProps } from './dashboard-date-toggles.types';
import { getIsValidRangeType } from './helpers/get-is-valid-range-type';
import { dashboardAndReportAtoms } from '../../../../../dashboard.atoms';

export const DashboardDateToggles = (props: DashboardDateTogglesProps) => {
  const { dateToggleItems } = props;

  const { onDateRangeTypeChange } = useOnDateRangeTypeChange();

  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const validRangeTypeOptions = dateToggleItems.map((option) => {
    return typeof option === 'string' ? option : option.value;
  });
  const isValidRange = getIsValidRangeType({
    rangeType,
    validRangeTypes: validRangeTypeOptions,
  });

  const [defaultItem, setDefaultItem] = useState(rangeType);

  useEffect(() => {
    // if switching from a page where your rangeType is valid to one where it is not, updates the atoms to the first valid option.
    if (!isValidRange) {
      const [defaultRangeType] = validRangeTypeOptions;

      setDefaultItem(defaultRangeType);
      onDateRangeTypeChange(defaultRangeType);
    }
  }, [rangeType]);

  return (
    <ButtonToggleGroup
      defaultItem={defaultItem}
      items={isValidRange ? dateToggleItems : []}
      onChange={onDateRangeTypeChange}
    />
  );
};
