import { Conditional, Flex, Grid, Typography } from 'gantri-components';
import { progressTrackerDefaultProps } from './progress-tracker.presets';
import {
  StyledDot,
  StyledLine,
  StyledProgressCell,
} from './progress-tracker.styles';
import { ProgressTrackerProps } from './progress-tracker.types';

export const ProgressTracker = <StepName extends string>(
  props: ProgressTrackerProps<StepName>,
) => {
  const { activeStep, borderColor, items, maxWidth } = props;

  const getActiveSteps = ({
    endIndex,
    startIndex = 0,
  }: {
    endIndex: number;
    startIndex?: number;
  }) => {
    return items.reduce<StepName[]>((accumulator, item, index) => {
      const isWithinRange = index >= startIndex && index <= endIndex;

      return isWithinRange
        ? [...accumulator, ...item.activeOnSteps]
        : accumulator;
    }, []);
  };

  const lastStepIndex = items.length - 1;

  const middleSteps = items.filter((step, index) => {
    return index !== 0 && index !== lastStepIndex;
  });

  const returnOnSteps = <Value extends string | boolean>(
    value: Value,
    stepsToReturnOn: StepName[],
  ): Value => {
    return stepsToReturnOn.some((returnStep) => {
      return returnStep === activeStep;
    })
      ? value
      : undefined;
  };

  const lastItemIndex = items.length - 1;
  const firstIndexActiveDotSteps = getActiveSteps({
    endIndex: lastItemIndex,
  });
  const firstIndexActiveLineSteps = getActiveSteps({
    endIndex: lastItemIndex,
    startIndex: 1,
  });
  const lineWidth = 'calc(50% + 2.5rem)';

  return (
    <Flex justifyContent="center">
      <Grid
        alignItems="center"
        columnGap="2rem"
        columns={`repeat(${items.length}, 1fr)`}
        justifyContent="center"
        justifyItems="center"
        maxWidth={maxWidth}
        rowGap="0.4rem"
        width="unset"
      >
        {items.map(({ activeOnSteps, label }, index) => {
          return (
            <Typography
              key={`${label}-${index}`}
              align="center"
              text={label}
              textStyle={returnOnSteps('bold', activeOnSteps)}
            />
          );
        })}

        <Conditional condition={items.length > 1}>
          <StyledProgressCell justifyItems="center" position="relative">
            <StyledDot
              $borderColor={borderColor}
              isActive={returnOnSteps(true, firstIndexActiveDotSteps)}
            />
            <StyledLine
              $left="50%"
              $width={lineWidth}
              isActive={returnOnSteps(true, firstIndexActiveLineSteps)}
            />
          </StyledProgressCell>
        </Conditional>
        <Conditional condition={items.length > 2}>
          {middleSteps.map((item, middleStepIndex) => {
            /** +1 for skipped first index */
            const currentStepIndex = middleStepIndex + 1;
            const nextStepIndex = currentStepIndex + 1;
            const activeNextLineSteps = items[nextStepIndex].activeOnSteps;

            const activeDotSteps = getActiveSteps({
              endIndex: lastItemIndex,
              startIndex: currentStepIndex,
            });

            return (
              <StyledProgressCell
                key={`${activeDotSteps.join()}-${middleStepIndex}`}
                justifyItems="center"
                position="relative"
              >
                <StyledLine
                  $right="50%"
                  $width={lineWidth}
                  isActive={returnOnSteps(true, activeDotSteps)}
                />
                <StyledDot
                  $borderColor={borderColor}
                  isActive={returnOnSteps(true, activeDotSteps)}
                />
                <StyledLine
                  $left="50%"
                  $width={lineWidth}
                  isActive={returnOnSteps(true, activeNextLineSteps)}
                />
              </StyledProgressCell>
            );
          })}
        </Conditional>
        <StyledProgressCell justifyItems="center" position="relative">
          <StyledDot
            $borderColor={borderColor}
            isActive={returnOnSteps(true, items[lastStepIndex].activeOnSteps)}
          />
          <StyledLine
            $right="50%"
            $width={lineWidth}
            isActive={returnOnSteps(true, items[lastStepIndex].activeOnSteps)}
          />
        </StyledProgressCell>
      </Grid>
    </Flex>
  );
};

ProgressTracker.defaultProps = progressTrackerDefaultProps;
