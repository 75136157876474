import { PropsWithChildren } from 'react';
import { UserRole } from '../../../constants/users';
import { useUserHasAccessLevel } from '../../../hooks/use-user-has-access-level';

export interface ProtectedProps {
  /** User roles or user IDs. */
  allowedFor: (UserRole | number)[];
}

const Protected = (props: PropsWithChildren<ProtectedProps>) => {
  const { allowedFor, children } = props;

  const { getUserHasAccessTo } = useUserHasAccessLevel();

  const userHasAccess = getUserHasAccessTo(allowedFor, { trueIfEmpty: true });

  if (userHasAccess) {
    return <>{children}</>;
  }

  return null;
};

export default Protected;
