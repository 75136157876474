import { FailedReason } from '../../../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { Checklist } from '../../job-checklist.types';
import { getIsChecklistItemIncomplete } from '../../components/advanced-checklist/helpers/get-is-checklist-item-incomplete';

export const getMissingEvaluationsMessage = (
  checklist: Partial<Checklist<FailedReason>>,
) => {
  const checklistKeys = Object.keys(checklist) as (keyof typeof checklist)[];

  const incompleteItemLabels = checklistKeys
    .map((code, index) => {
      const isIncomplete = getIsChecklistItemIncomplete(checklist[code]);
      const reasonNum = index + 1;
      const prefixedReasonNum =
        String(reasonNum).length === 1 ? `0${reasonNum}` : reasonNum;
      const { label } = checklist[code];

      return isIncomplete ? `${prefixedReasonNum} ${label}` : undefined;
    })
    .filter((info) => {
      return !!info;
    });

  const text = incompleteItemLabels.length
    ? `Missing evaluations for ${incompleteItemLabels.join(', ')}.`
    : undefined;

  return text;
};
