import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { RestartPartArgs, RestartPartsResponse } from './restart-part.types';

export const restartPart = (args: RestartPartArgs) => {
  const { partId, ...body } = args;

  return axios.put<RestartPartsResponse>(
    `${coreApiUrl}/parts/${partId}/restart`,
    body,
  );
};
