import { Flex } from 'gantri-components';
import styled, { css } from 'styled-components';

export const StyledFlexCategorySquare = styled(Flex)`
  ${({ theme }) => {
    return css`
      width: 4rem;
      height: 4rem;
      background-color: ${theme.colors.surfaces.green.t3};
    `;
  }}
`;
