import { forwardRef } from 'react';
import { CellProps } from './cell.props';
import { StyledCell } from './cell.styles';
import { CellPresets } from './cell.presets';

/**
 * @deprecated use equivalent from gantri-components (https://components.gantri.com/?path=/story/containers-cell--cell)
 */
export const Cell = forwardRef((props: CellProps, ref: any) => {
  const { children, ...others } = { ...CellPresets, ...props };

  return (
    <StyledCell ref={ref} {...others}>
      {children}
    </StyledCell>
  );
});
