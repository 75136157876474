import { useEffect } from 'react';
import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { CompleteAssemblyModalProps } from './complete-assembly-modal.types';
import { CompleteAssemblyModalFooter } from './components/complete-assembly-modal-footer';
import { CompleteAssemblyModalContent } from './components/complete-assembly-modal-content';
import { jobStatuses } from '../../../../../../constants/options';
import { JobDetailsPanelLarge } from '../../common/job-details-panel-large';
import { completeAssemblyModalAtoms } from './complete-assembly-modal.atoms';
import { JobDetails } from '../../../../../../api/jobs/routes/get-job-details/get-job-details.types';
import { useResetRecoilAtomsOnMount } from '../../../../../../hooks/use-reset-recoil-atoms-on-mount';
import { FullScreenModalHeader } from '../../common/full-screen-modal-header';
import { useHandleCloseModal } from '../../hooks/use-handle-close-modal';

export const CompleteAssemblyModal = (props: CompleteAssemblyModalProps) => {
  const { job: originalJob, onClose, onJobUpdated } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    completeAssemblyModalAtoms,
  );

  const updateOnClose = useRecoilValue(
    completeAssemblyModalAtoms.updateOnClose,
  );
  const inventoryRequests = useRecoilValue(
    completeAssemblyModalAtoms.inventoryRequests,
  );
  const [step, setStep] = useRecoilState(completeAssemblyModalAtoms.step);
  const [job, setJob] = useRecoilState(completeAssemblyModalAtoms.job);
  const setInventoryRequests = useSetRecoilState(
    completeAssemblyModalAtoms.inventoryRequests,
  );

  const handleCloseModal = useHandleCloseModal({
    onClose,
    onUpdate: onJobUpdated,
    updateOnClose,
  });

  const jobDetails: JobDetails =
    step === 'INVENTORY_CHECKLIST' ? job : { ...job, inventoryRequests };

  useEffect(() => {
    setJob(originalJob);

    setInventoryRequests(originalJob?.inventoryRequests || []);

    if (originalJob?.status === jobStatuses.inProgress) {
      setStep('INVENTORY_CHECKLIST');
    }
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        detailsPanel={
          <JobDetailsPanelLarge
            job={jobDetails}
            sectionsToShow={[
              'stock',
              'product',
              'instructions',
              'inventories',
              'product photo',
            ]}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <CompleteAssemblyModalFooter handleCloseModal={handleCloseModal} />
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            job={job}
          />
        }
        isFullScreen
      >
        <CompleteAssemblyModalContent />
      </Modal>
    </ResetAtomsWrapper>
  );
};
