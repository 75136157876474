import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from 'gantri-components';
import { useToggle } from 'react-use';
import { rejectJobModalAtoms } from '../../../reject-job-modal.atoms';
import { useNotification } from '../../../../../../hooks/useNotification';
import { NextStepFooterProps } from './next-step-footer.types';
import { jobsApi } from '../../../../../../api';

export const NextStepFooter = (props: NextStepFooterProps) => {
  const { jobId, onClose, onUpdate } = props;

  const [processing, toggleProcessing] = useToggle(false);
  const setStep = useSetRecoilState(rejectJobModalAtoms.step);
  const nextStep = useRecoilValue(rejectJobModalAtoms.nextStep);
  const reason = useRecoilValue(rejectJobModalAtoms.rejectReason);
  const details = useRecoilValue(rejectJobModalAtoms.rejectDetails);
  const { notify, notifyAxiosError } = useNotification();

  return (
    <>
      <Button
        text="Back"
        variant="secondary"
        onClick={() => {
          setStep('REJECT_REASON');
        }}
      />
      <Button
        disabled={!nextStep}
        processing={processing}
        text="Reject Job"
        variant="primaryAlert"
        onClick={async () => {
          try {
            toggleProcessing();

            const action =
              nextStep === 'JOB_FAILED_RESTART' ? 'Restart' : 'Duplicate';

            const { data } = await jobsApi.rejectJob({
              action,
              details,
              jobId,
              reason,
            });

            notify(data.notice);

            await onUpdate();
            onClose();
          } catch (error: unknown) {
            notifyAxiosError({
              error,
              fallbackMessage: 'Unable to reject job.',
            });
          } finally {
            toggleProcessing();
          }
        }}
      />
    </>
  );
};
