import { Conditional, Flex, Typography } from 'gantri-components';
import { Cell, CellContext, ColumnDef } from '@tanstack/react-table';
import { URLDataCell } from './url-data-cell';
import { DateDataCell } from './date-data-cell';
import {
  UserActivity,
  UserActivityType,
} from '../../../api/users/routes/get-user-activities/get-user-activities.types';
import { StyledAnchor } from '../../../components/common/styled-anchor';
import { getLinkDetails } from './helpers/get-link-details';
import { getIsClickableIfValue } from '../../../helpers/get-is-clickable-if-value';

export const userActivityOptions: UserActivityType[] = [
  'Auth',
  'Design',
  'Gift',
  'Job',
  'Machine',
  'Order',
  'Payment Fail',
  'Product',
  'Stock',
];

export const columns: ColumnDef<UserActivity>[] = [
  {
    accessorKey: 'type',
    header: 'Type',
    size: 50,
  },
  {
    accessorKey: 'action',
    header: 'Action',
  },
  {
    accessorKey: 'type',
    cell: ({ row }: CellContext<UserActivity, UserActivity['type']>) => {
      const { label, linkText, linkUrl } = getLinkDetails(row.original);

      return (
        <Flex gap=".5x">
          <Typography color="t2" display="inline" text={label} />
          <Conditional condition={!!linkUrl && !!linkText}>
            <StyledAnchor display="inline" text={linkText} to={linkUrl} />
          </Conditional>
        </Flex>
      );
    },
    header: 'Link',
    id: 'link',
    meta: {
      getIsClickable: ({
        getValue,
      }: Cell<UserActivity, UserActivity['type']>) => {
        return getValue() !== 'Auth';
      },
    },
    size: 100,
  },
  {
    accessorKey: 'source',
    cell: URLDataCell,
    header: 'URL',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 30,
  },
  {
    accessorKey: 'dateSec',
    cell: DateDataCell,
    header: 'Date',
    size: 80,
  },
  {
    accessorKey: 'ip',
    header: 'IP Address',
    size: 80,
  },
  {
    accessorKey: 'userAgent',
    header: 'User Agent',
    size: 300,
  },
];
