import { RecoilState, atom } from 'recoil';
import {
  CompleteSandJobModalStep,
  CompleteSandJobChecklist,
} from './complete-sand-job-modal.types';
import { NextStep } from '../../common/job-checklist/job-checklist.types';
import { NewPart } from '../../../../../../api/jobs/routes/fail-job/fail-job.types';
import { completeSandJobModalChecklistDefaults } from './complete-sand-job-modal.constants';
import { NextJob } from '../../../../../../api/jobs/routes/complete-job/complete-job.types';

export const atomKeyPrefix = 'COMPLETE_SAND_JOB_MODAL';

export const completeSandJobModalAtoms = {
  checklist: atom<CompleteSandJobChecklist>({
    default: completeSandJobModalChecklistDefaults,
    key: `${atomKeyPrefix}-checklist`,
  }),
  isChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-checklist-reviewed`,
  }),
  newParts: atom<NewPart[]>({
    default: [],
    key: `${atomKeyPrefix}-new-parts`,
  }),
  nextJob: atom<NextJob>({
    default: null,
    key: `${atomKeyPrefix}-next-job`,
  }),
  nextStep: atom<NextStep>({
    default: 'JOB_FAILED_RESTART',
    key: `${atomKeyPrefix}-next-step`,
  }),
  nextStepDetails: atom<string>({
    default: '',
    key: `${atomKeyPrefix}-next-step-details`,
  }),
  step: atom<CompleteSandJobModalStep>({
    default: 'CONFIRM_BEGIN',
    key: `${atomKeyPrefix}-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
} satisfies Record<string, RecoilState<any>>;
