import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Tooltip, Typography } from 'gantri-components';
import { Job } from '../../../../../../../../api/jobs/jobs.types';
import { JobDescriptionCell } from '../../../../../../../jobs/hooks/use-get-job-columns/components/job-description-cell';
import { JobStatusCell } from '../../../../../../../jobs/hooks/use-get-job-columns/components/job-status-cell';
import { SearchPartSummaryJobActionCell } from '../../components/search-part-summary-job-action-cell';
import { GetSearchPartSummaryJobColumnsArgs } from './get-search-part-summary-job-columns.types';
import { jobStatuses } from '../../../../../../../../constants/options';
import { StyledAnchor } from '../../../../../../../../components/common/styled-anchor';

export const getSearchPartSummaryJobColumns = (
  args: GetSearchPartSummaryJobColumnsArgs,
) => {
  const {
    clearPartSearch,
    getPartDetails,
    onOpenAssignMachineModal,
    partStatus,
  } = args;

  const jobColumns: ColumnDef<Job>[] = [
    {
      accessorKey: 'description',
      cell: JobDescriptionCell,
      header: 'Job Name',
      size: 180,
    },
    {
      accessorKey: 'type',
      header: 'Type',
      size: 100,
    },
    {
      accessorKey: 'step',
      header: 'Step',
      size: 100,
    },
    {
      accessorKey: 'machine',
      cell: ({ row }) => {
        const machineType = row.original?.machineType;
        const isWaiting = row.original?.status === jobStatuses.waiting;
        const isReady = row.original?.status === jobStatuses.ready;
        const isPrintType = row.original.type === 'Print';
        const hasMachine = !!row.original?.machine;

        if (hasMachine) {
          return row.original?.machine?.name;
        }

        if (isPrintType && (isWaiting || isReady)) {
          return (
            <Tooltip
              description={machineType ? '' : 'Job is missing machine type.'}
              position="left"
            >
              <StyledAnchor
                color={machineType ? 'link' : 't2'}
                text="Assign"
                onClick={
                  machineType
                    ? (e: React.MouseEvent<any>) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onOpenAssignMachineModal(row.original);
                      }
                    : undefined
                }
              />
            </Tooltip>
          );
        }

        return null;
      },
      header: 'Machine',
      size: 140,
    },
    {
      accessorKey: 'machineType',
      header: 'Machine Type',
      size: 190,
    },
    partStatus && {
      accessorKey: 'weight',
      cell: ({ getValue }: CellContext<Job, Job['weight']>) => {
        const weight = getValue();

        return <Typography text={weight && `${weight} g`} />;
      },
      header: 'Weight',
      size: 60,
    },
    {
      accessorKey: 'status',
      cell: JobStatusCell,
      header: 'Status',
      size: 100,
    },
    {
      cell: ({ row }: CellContext<Job, never>) => {
        return (
          <SearchPartSummaryJobActionCell
            clearPartSearch={clearPartSearch}
            getPartDetails={getPartDetails}
            job={row.original}
            onRefresh={getPartDetails}
          />
        );
      },
      id: 'actions',
      size: 30,
    },
  ].filter(Boolean);

  return jobColumns;
};
