import { getActiveQueries } from '../../../../../../helpers/checks';
import { defaultOrdersGroupBy } from '../../report-orders.constants';
import { ReportOrderQueries } from '../../report-orders.types';

export const getTypeColumnHeading = () => {
  const queries = getActiveQueries<ReportOrderQueries>();
  const { groupBy = defaultOrdersGroupBy, orderType } = queries;

  if (/^orderType$/i.test(groupBy) && orderType) {
    return 'Refund Reason';
  }

  return 'Order Type';
};
