import { useGenericMutateQuery } from '../../../../hooks/use-generic-mutate-query';
import { GenericMutateQueryProps } from '../../../../hooks/use-generic-mutate-query/use-generic-mutate-query.types';
import { UpdateDesignArgs, UpdateDesignResponse } from './update-design.types';
import { updateDesign } from './update-design';

export const useUpdateDesign = <TransformedData = UpdateDesignResponse>(
  props?: GenericMutateQueryProps<
    UpdateDesignArgs,
    UpdateDesignResponse,
    TransformedData
  >,
) => {
  const { onMutate: onUpdateDesign, ...rest } = useGenericMutateQuery({
    fallbackErrorMessage: 'Unable to update design.',
    mutationFn: updateDesign,
    ...props,
  });

  return { ...rest, onUpdateDesign };
};
