import { convertStringArrayToValueLabelArray } from '../../../../helpers/formatter';

export const statusOptions = convertStringArrayToValueLabelArray([
  'Ready',
  'Manual',
  'Offline',
]);

export const statusMap = {
  Manual: 'Must be assigned a print',
  Offline: 'Needs Repair or Maintenance',
  Ready: 'Automatically accepts prints',
};
