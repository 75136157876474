import { useFormikContext } from 'formik';
import {
  Conditional,
  Dropdown,
  FormikInput,
  Stack,
  Typography,
} from 'gantri-components';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { Divider } from '../../../../../../components/divider';
import Timeline from '../../../../../../components/common/timeline';
import TimelineItem from '../../../../../../components/common/timeline/base-timeline-item';
import { LocationModalSchema } from '../../location-modal.schema';
import { locationModalAtoms } from '../../location-modal.atoms';
import { LocationModalContentProps } from './location-modal-content.types';
import { locationStatusOptions } from '../../../../../../constants/options';

export const LocationModalContent = (props: LocationModalContentProps) => {
  const { locationBuildings, locationWorkshops } = props;

  const isEditModal = useRecoilValue(locationModalAtoms.isEditModal);
  const location = useRecoilValue(locationModalAtoms.location);
  const { setFieldValue, values } = useFormikContext<LocationModalSchema>();

  useEffect(() => {
    if (!!values.bin) {
      setFieldValue('type', 'bin');
    } else if (!!values.shelf) {
      setFieldValue('type', 'shelf');
    } else if (!!values.rack) {
      setFieldValue('type', 'rack');
    } else if (!!values.area) {
      setFieldValue('type', 'area');
    } else {
      setFieldValue('type', 'workshop');
    }
  }, [values]);

  useEffect(() => {
    if (!values.area) {
      setFieldValue('rack', '');
    }
  }, [values.area]);

  useEffect(() => {
    if (!values.rack) {
      setFieldValue('shelf', '');
    }
  }, [values.rack]);

  useEffect(() => {
    if (!values.shelf) {
      setFieldValue('bin', '');
    }
  }, [values.shelf]);

  return (
    <Stack gap="3x">
      <Stack gap="2x">
        <Typography text="Name and association" variant="h5" />
        <Stack gap="3x">
          <Stack gap="x">
            <FormikInput
              Field={
                <Dropdown
                  items={locationBuildings.sort().map((label) => {
                    return {
                      label,
                      value: label,
                    };
                  })}
                  placeholder="Select building..."
                  required
                />
              }
              fieldVariant="standard"
              labelText="Building"
              name="building"
            />
          </Stack>

          <Stack gap="x">
            <FormikInput
              Field={
                <Dropdown
                  items={locationWorkshops.sort().map((label) => {
                    return {
                      label,
                      value: label,
                    };
                  })}
                  placeholder="Select workshop..."
                  required
                />
              }
              fieldVariant="standard"
              labelText="Workshop"
              name="workshop"
            />
          </Stack>

          <Stack gap="x">
            <FormikInput
              debounce={50}
              fieldVariant="standard"
              labelText="Area"
              name="area"
              placeholder="Place within workshop"
            />
          </Stack>

          <Conditional condition={!!values.area}>
            <Stack gap="x">
              <FormikInput
                debounce={50}
                fieldVariant="standard"
                labelText="Rack"
                name="rack"
                placeholder="Name rack..."
              />
            </Stack>
          </Conditional>

          <Conditional condition={!!values.rack}>
            <Stack gap="x">
              <FormikInput
                debounce={50}
                fieldVariant="standard"
                labelText="Shelf"
                name="shelf"
                placeholder="Name shelf..."
              />
            </Stack>
          </Conditional>

          <Conditional condition={!!values.shelf}>
            <Stack gap="x">
              <FormikInput
                debounce={50}
                fieldVariant="standard"
                labelText="Bin"
                name="bin"
                placeholder="Name bin..."
              />
            </Stack>
          </Conditional>

          <Divider />

          <Stack gap="2x">
            <Typography text="Status" variant="h5" />

            <FormikInput
              Field={<Dropdown items={locationStatusOptions} required />}
              fieldVariant="standard"
              name="status"
            />
          </Stack>
        </Stack>
      </Stack>
      <Conditional condition={isEditModal}>
        <Divider />
        <Timeline items={location?.timeline || []} renderItem={TimelineItem} />
      </Conditional>
    </Stack>
  );
};
