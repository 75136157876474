import { useGetInvalidateQueryCache } from '../../../../hooks/use-fetch/use-query-fetch';
import { useGenericFetchQuery } from '../../../../hooks/use-generic-fetch-query';
import { GenericFetchProps } from '../../../../hooks/use-generic-fetch-query/use-generic-fetch-query.types';
import { GetPaginatedJobsArgs } from '../get-paginated-jobs/get-paginated-jobs.types';
import { GetPaginatedJobsCountResponse } from './get-paginated-jobs-count.types';
import { fetchPaginatedJobsCount } from './get-paginated-jobs-count';

export const useFetchPaginatedJobsCountQuery = <
  TransformedData = GetPaginatedJobsCountResponse,
>(
  props: GenericFetchProps<
    GetPaginatedJobsArgs,
    GetPaginatedJobsCountResponse,
    TransformedData
  >,
) => {
  const { data, isLoading } = useGenericFetchQuery({
    fallbackErrorMessage: 'Unable to fetch paginated jobs count.',
    queryObj: fetchPaginatedJobsCount,
    ...props,
  });

  return {
    data,
    isLoading,
  };
};

export const useInvalidateFetchPaginatedJobsCountCache = () => {
  const invalidateFetchPaginatedJobsCountCache = useGetInvalidateQueryCache(
    fetchPaginatedJobsCount,
  );

  return { invalidateFetchPaginatedJobsCountCache };
};
