import React, { FC, useState } from 'react';
import { SearchField, Typography } from 'gantri-components';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { debounce } from 'lodash';
import { OrdersSearchInputProps } from './orders-search-input.types';
import { useFetchAllOrdersForFilterQuery } from '../../../../api/orders/routes';

export const OrdersSearchInput: FC<OrdersSearchInputProps> = (props) => {
  const { atom } = props;

  const [orderId, setOrderId] = useRecoilState(atom);
  const reset = useResetRecoilState(atom);
  const [query, setQuery] = useState<string>(String(orderId ?? '') || '');

  const { isLoading, orders } = useFetchAllOrdersForFilterQuery({
    enabled: !!query,
    query,
    transform: (response) => {
      return response.orders;
    },
  });

  return (
    <SearchField
      idProperty="id"
      items={isLoading ? [] : orders ?? []}
      labelProperty="id"
      maxHeight="33rem"
      minSearchLength={3}
      placeholder="Search orders"
      processing={isLoading}
      renderItem={(order) => {
        return (
          <Typography
            horizontalPadding="1.6rem"
            text={`${order.type} #${order.id}`}
            variant="p2"
            verticalPadding="0.8rem"
          />
        );
      }}
      searchable
      searchMode="external"
      value={orderId}
      onSelect={(order) => {
        setOrderId(order?.id);

        if (!order?.id) {
          reset();
        }
      }}
      onTextChange={debounce(setQuery, 300)}
    />
  );
};
