import { useRecoilValue, useSetRecoilState } from 'recoil';
import { jobsApi } from '../../../../../../../../../../../api';
import { useNotification } from '../../../../../../../../../../../hooks/useNotification';
import { getFailJobReasonArg } from '../../../../../../../helpers/get-fail-job-reason-arg';
import { getFailingReasonsFromChecklist } from '../../../../../../../helpers/get-failing-reasons-from-checklist';
import { PartsDataArg } from '../../part-checklist-footer.types';
import { completeAssemblyModalAtoms } from '../../../../../complete-assembly-modal.atoms';

export const useOnFail = () => {
  const setUpdateOnClose = useSetRecoilState(
    completeAssemblyModalAtoms.updateOnClose,
  );
  const setStep = useSetRecoilState(completeAssemblyModalAtoms.step);
  const setDuplicatedJobs = useSetRecoilState(
    completeAssemblyModalAtoms.duplicatedJobs,
  );
  const setExceededCycleTime = useSetRecoilState(
    completeAssemblyModalAtoms.exceededCycleTime,
  );
  const setNewParts = useSetRecoilState(completeAssemblyModalAtoms.newParts);
  const job = useRecoilValue(completeAssemblyModalAtoms.job);
  const jobChecklist = useRecoilValue(completeAssemblyModalAtoms.jobChecklist);
  const partChecklist = useRecoilValue(
    completeAssemblyModalAtoms.partChecklist,
  );

  const { hideLoading, notify, notifyAxiosError, showLoading } =
    useNotification();

  const failingPartIds = getFailingReasonsFromChecklist(partChecklist);

  const onFail = async () => {
    try {
      showLoading();

      const partsData: PartsDataArg = failingPartIds.map((id) => {
        const partData: PartsDataArg[number] = {
          details: partChecklist[id].details,
          id,
          type:
            partChecklist[id].status === 'Re-finish'
              ? 'Finish_Complete'
              : 'Restart',
        };

        return partData;
      });

      const reason = getFailJobReasonArg(jobChecklist);
      const { data } = await jobsApi.failJob({
        jobId: job.id,
        partsData,
        reason,
      });

      if (!!data.duplicatedJobs?.length) {
        setDuplicatedJobs(data.duplicatedJobs);
        setUpdateOnClose(true);
      }

      if (!!data.newParts?.length) {
        setNewParts(data.newParts);
      }

      setExceededCycleTime(data.exceededCycleTime);
      notify(data.notice);
      setStep('ASSEMBLY_COMPLETED_FAILED');
    } catch (error: unknown) {
      notifyAxiosError({ error, fallbackMessage: 'Unable to fail the job.' });
    } finally {
      hideLoading();
    }
  };

  return { failingPartIds, onFail };
};
