import { FC, Fragment, PropsWithChildren } from 'react';
import { Flex } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { StyledStyledSidebarLinkSpacer } from '../sidebar.styles';
import { menuItems } from './sidebar-links.constants';
import { SidebarLinksProps } from './sidebar-links.types';
import { MenuItem } from './components/menu-item';
import { uiAtoms } from '../../../global-atoms/ui';

export const SidebarLinks: FC<PropsWithChildren<SidebarLinksProps>> = ({
  onLinkClicked,
  userType,
}) => {
  const isSidebarOpen = useRecoilValue(uiAtoms.isSidebarOpen);
  const expandedMenuItems = useRecoilValue(uiAtoms.expandedMenuItems);

  const lowerCaseUserType = userType.toLowerCase() as Lowercase<
    typeof userType
  >;
  const items =
    lowerCaseUserType === 'user' ? [] : menuItems[lowerCaseUserType];

  return (
    <div>
      {items.map((route, i) => {
        if (route.type === 'spacer') {
          const key = `spacer-${i}`;

          return <StyledStyledSidebarLinkSpacer key={key} />;
        }

        const key = `${route.name}-${i}`;

        return (
          <Fragment key={key}>
            <MenuItem
              expandedMenuItems={expandedMenuItems}
              route={route}
              onLinkClicked={onLinkClicked}
            />
            {route.subPaths?.length && isSidebarOpen && (
              <Flex alignItems="stretch" direction="column">
                {expandedMenuItems[route.name] &&
                  route.subPaths.map((route, i) => {
                    const key = `${route.name}-${i}`;

                    return (
                      <MenuItem
                        key={key}
                        isSubPath
                        route={route}
                        onLinkClicked={onLinkClicked}
                      />
                    );
                  })}
              </Flex>
            )}
          </Fragment>
        );
      })}
      <StyledStyledSidebarLinkSpacer />
    </div>
  );
};
