import { InventoryTransaction } from '../../../../../../api/inventories/routes/get-inventory/get-inventory.types';
import { useMoreMenu } from '../../../../../../hooks/use-more-menu';
import { MoreMenuCellProps } from './more-menu-cell.types';

export const MoreMenuCell = (props: MoreMenuCellProps) => {
  const { handleOnDelete, row } = props;
  const { MoreMenu } = useMoreMenu<InventoryTransaction>({
    data: row.original,
    options: [
      {
        enabled: ({ revert }) => {
          return !revert;
        },
        name: 'Delete',
        onOptionClick: handleOnDelete,
      },
    ],
  });

  return <MoreMenu />;
};
