import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  CreateDownloadResponse,
  CreateDownloadArgs,
} from './create-download.types';

export const createDownload = (requestBody: CreateDownloadArgs) => {
  return axios.post<CreateDownloadResponse>(
    `${coreApiUrl}/downloads`,
    requestBody,
  );
};
