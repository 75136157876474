import { Conditional, Stack, Typography } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { ModalContentHeading } from '../../../../../../common/modal-content-heading';
import { VLMLocationGrid } from '../../../../common/vlm-location-grid';
import { startAssembleStageModalAtoms } from '../../../start-assemble-stage-job-modal.atoms';
import { StyledFlexContainer } from '../../assemble-stage-job-content/assemble-stage-job-content.styles';
import { GetFromVlmContentProps } from './get-from-vlm-content.types';

export const GetFromVlmContent = (props: GetFromVlmContentProps) => {
  const { job } = props;
  const { stockId } = job;

  const vlmNotified = useRecoilValue(startAssembleStageModalAtoms.vlmNotified);
  const stockLocation = useRecoilValue(
    startAssembleStageModalAtoms.stockLocation,
  );

  return (
    <Stack alignContent="center" gap="3x">
      <Stack gap="x">
        <Conditional
          condition={vlmNotified}
          Fallback={
            <ModalContentHeading
              color="warning"
              titleText="Request not delivered to VLM"
            />
          }
        >
          <ModalContentHeading
            color="success"
            titleText="Request sent to VLM"
          />
        </Conditional>
      </Stack>
      <Stack gap="x">
        <Conditional
          condition={vlmNotified}
          Fallback={
            <>
              <Typography text="Manual VLM Picking" textStyle="bold" />
              <Typography
                text={`Please complete the VLM request manually. Please pick Stock #${stockId} from the indicated bin:`}
              />
            </>
          }
        >
          <Typography text="VLM Picking" textStyle="bold" />
          <Typography
            text={`Please pick Stock #${stockId} from the indicated bin:`}
          />
        </Conditional>

        <StyledFlexContainer
          justifyContent="center"
          paddingBottom="3.2rem"
          paddingTop="3.2rem"
        >
          <VLMLocationGrid stockLocation={stockLocation} />
        </StyledFlexContainer>
      </Stack>
    </Stack>
  );
};
