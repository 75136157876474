import { Typography } from 'gantri-components';
import { InventoryRequest } from '../../../../../../api/jobs/jobs.types';
import { JobInventoriesCellProps } from './job-inventories-cell.types';

export const JobInventoriesCell = ({ getValue }: JobInventoriesCellProps) => {
  const inventoryRequests = getValue();

  const inventoriesList = getInventoriesListFromRequests(inventoryRequests);

  return (
    <>
      {inventoriesList.map((inventory, index) => {
        return <Typography key={`${inventory}-${index}`} text={inventory} />;
      })}
    </>
  );
};

export const getInventoriesListFromRequests = (
  inventoryRequests: InventoryRequest[],
) => {
  return inventoryRequests?.map(
    ({
      /** inventory is sometimes provided as its own key which contains the unit and name data */
      inventory,
      name,
      need,
      unit,
    }) => {
      const inventoryDetails = [
        need,
        unit || inventory?.unit,
        name || inventory?.name,
      ]
        .filter(Boolean)
        .join(' ');

      return inventoryDetails;
    },
  );
};
