import { Icon, Overlay, Tooltip, Typography } from 'gantri-components';
import {
  StyledMoreMenuItem,
  StyledMoreMenuOption,
} from '../../../../components/dropdowns/menu-styles';
import { StyledDropdownContainer } from '../../../../components/dropdowns/dropdown-wrapper/dropdown-wrapper-styles';
import Protected from '../../../../components/common/protected/protected';
import { MoreMenuToggleProps } from './more-menu-toggle.types';
import { moreMenuDefaultProps } from './more-menu-toggle.presets';

export const MoreMenuToggle = <Data extends unknown>(
  props: MoreMenuToggleProps<Data>,
) => {
  const { Toggle, data, isMenuOpen, options, position, toggleMenu, top } =
    props;
  const availableOptions = options.filter(Boolean);

  return (
    <Overlay
      content={
        <StyledDropdownContainer moreMenu>
          <ul style={{ width: '100%' }}>
            {availableOptions.map((option) => {
              const {
                Component,
                allowedFor,
                disabled = false,
                disabledTooltipProps,
                enabled = true,
                name,
                onOptionClick,
              } = option;

              const deprecatedIsEnabled =
                typeof enabled === 'function' ? enabled(data) : enabled;
              const isDisabled =
                typeof disabled === 'function'
                  ? disabled(data)
                  : disabled || !deprecatedIsEnabled;

              const handleOptionClick = () => {
                if (onOptionClick && !isDisabled) {
                  onOptionClick(data);
                  toggleMenu(false);
                }
              };

              const nameValue = typeof name === 'function' ? name(data) : name;

              return (
                <Protected key={nameValue} allowedFor={allowedFor}>
                  <StyledMoreMenuItem
                    aria-disabled={isDisabled}
                    disabled={isDisabled}
                  >
                    <Tooltip
                      {...(isDisabled
                        ? {
                            ...disabledTooltipProps,
                            position: disabledTooltipProps?.position || 'left',
                          }
                        : {})}
                      triggerEvent="hover"
                    >
                      <StyledMoreMenuOption
                        disabled={isDisabled}
                        role="menu"
                        onClick={handleOptionClick}
                      >
                        {!!Component ? (
                          <Component data={data} />
                        ) : (
                          <Typography text={nameValue} />
                        )}
                      </StyledMoreMenuOption>
                    </Tooltip>
                  </StyledMoreMenuItem>
                </Protected>
              );
            })}
          </ul>
        </StyledDropdownContainer>
      }
      open={isMenuOpen}
      position={position}
      triggerEvent="click"
      onClose={() => {
        toggleMenu(false);
      }}
    >
      <span data-3-dot-menu-toggle="" data-testid="data-3-dot-menu-toggle">
        {/* Do not use Conditional component */}
        {!!Toggle ? (
          <Toggle onClick={toggleMenu} />
        ) : (
          <Icon
            color="t2"
            cursor="pointer"
            name="ui-control:three_dots_horizontal"
            top={top}
            width="2.4rem"
            onClick={() => {
              toggleMenu();
            }}
          />
        )}
      </span>
    </Overlay>
  );
};

MoreMenuToggle.defaultProps = moreMenuDefaultProps;
