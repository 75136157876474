import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  AddProductImageArgs,
  AddProductImageResponse,
} from './add-product-image.types';

export const addProductAssets = (args: AddProductImageArgs) => {
  const { productId, ...data } = args;

  return axios.put<AddProductImageResponse>(
    `${baseApiUrl}/products/${productId}/add-assets`,
    data,
  );
};
