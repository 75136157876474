import { Property } from 'csstype';
import styled, { css } from 'styled-components';

export const StyledThumbnailWrapper = styled.div<{ size: Property.Width }>`
  ${({ size }) => {
    return css`
      position: relative;
      display: inline-flex;
      height: ${size};
      width: ${size};
      cursor: pointer;
      background-color: ${({ theme }) => {
        return theme.colors.surfaces.monochrome.t1Alt;
      }};
    `;
  }}
`;

export const StyledIconWrapper = styled.div`
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => {
    return theme.colors.surfaces.monochrome.t1Alt;
  }};
  cursor: pointer;
  display: inline-flex;
`;
