import { Button } from 'gantri-components';
import { useRecoilValue } from 'recoil';
import { useCompleteNonProductJob } from '../../../../../../../../../../api/jobs/routes';
import { filterInvalidInventoryRequests } from '../../../../../../../../../../helpers/filter-invalid-inventory-requests';
import { fullScreenModalFooterButtonSize } from '../../../../../../modals.constants';
import { startNonProductJobModalAtoms } from '../../../start-non-product-job-modal.atoms';
import { UpdateInventoryFooterProps } from './update-inventory-footer.types';

export const UpdateInventoryFooter = (props: UpdateInventoryFooterProps) => {
  const { handleCloseModal, job } = props;

  const inventoryRequests = useRecoilValue(
    startNonProductJobModalAtoms.inventoryRequests,
  );
  const notes = useRecoilValue(startNonProductJobModalAtoms.notes);

  const { isLoading, onCompleteNonProductJob } = useCompleteNonProductJob({
    onSuccess: async () => {
      await handleCloseModal({ updateOnClose: true });
    },
  });

  const handleComplete = async () => {
    await onCompleteNonProductJob({
      inventoryRequests: filterInvalidInventoryRequests(inventoryRequests),
      jobId: job.id,
      notes,
    });
  };

  return (
    <Button
      disabled={false}
      processing={isLoading}
      size={fullScreenModalFooterButtonSize}
      text={`Confirm ${job.type} Completion`}
      onClick={handleComplete}
    />
  );
};
