import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import {
  UpdateMarketplaceColorsArgs,
  UpdateMarketplaceColorsResponse,
} from './update-marketplace-colors.types';

export const updateMarketplaceColors = ({
  colors,
  id,
}: UpdateMarketplaceColorsArgs) => {
  return axios
    .put<UpdateMarketplaceColorsResponse>(
      `${getCoreApiUrl('products')}/${id}/marketplace-colors`,
      { colors },
    )
    .then(({ data }) => {
      return data;
    });
};
