export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
  if (bytes === 0) return 'N/A';
  // @ts-ignore
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;

  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const getFileExtension = (fileName: string) => {
  return fileName.match(/[^.]+.$/)[0];
};

export const downloadBlob = (blob: Blob, fileName: string) => {
  // Create a URL for the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary link element
  const link = document.createElement('a');

  // Set link attributes
  link.href = url;
  link.download = fileName;

  // Append link to body (not necessary, but can help in some browsers)
  document.body.appendChild(link);

  // Simulate click
  link.click();

  // Clean up
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};
