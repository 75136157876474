export const locationTypes = {
  area: 'Area',
  bin: 'Bin',
  building: 'Building',
  rack: 'Rack',
  shelf: 'Shelf',
  workshop: 'Workshop',
} as const;

export const locationWorkshops = {
  assembly: 'Assembly',
  finishing: 'Finishing',
  print: 'Print',
  shipping: 'Shipping',
  staging: 'Staging',
  storage: 'Storage',
} as const;
