import { partStatuses } from '../../../../../constants/options';
import {
  BadgeColors,
  GetPartStatusBadgeColorsArgs,
} from './get-part-status-badge-colors.types';

export const getPartStatusBadgeColors = (
  args: GetPartStatusBadgeColorsArgs,
): BadgeColors => {
  const { status, theme } = args;

  const isDarkMode = theme.name === 'dark';

  switch (status) {
    case partStatuses.waiting:
      return {
        borderColor: theme.colors.palette.blue_200,
        color: theme.colors.surfaces.blue.t1,
        textColor: theme.colors.typography.t1,
      };

    case partStatuses.completed:
      return {
        borderColor: theme.colors.palette.green_400,
        color: theme.colors.palette.green_400,
        textColor: theme.colors.typography.alt,
      };

    case partStatuses.cancelled:
    case partStatuses.delayedStart:
    case partStatuses.lost:
      return {
        borderColor: theme.colors.palette.monochrome_300,
        color: isDarkMode
          ? theme.colors.palette.monochrome_800
          : theme.colors.palette.monochrome_100,
        textColor: theme.colors.typography.t1,
      };

    case partStatuses.failed:
    case partStatuses.discarded:
      return {
        borderColor: theme.colors.palette.red_200,
        color: theme.colors.surfaces.alert.t1,
        textColor: theme.colors.typography.t1,
      };

    case partStatuses.printing:
    case partStatuses.finishing:
    default:
      return {
        borderColor: theme.colors.palette.gold_200,
        color: theme.colors.surfaces.warning.t1,
        textColor: theme.colors.typography.t1,
      };
  }
};
