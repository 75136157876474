import {
  Button,
  Grid,
  Icon,
  LinearProgress,
  Typography,
} from 'gantri-components';
import { useNavigateJobSets } from '../../../../hooks/use-navigate-job-sets';

export const JobSetNavigation = () => {
  const {
    activeSetNumber,
    isNextDisabled,
    isPrevDisabled,
    navigateJobSets,
    numCompletedSets,
    totalSets,
  } = useNavigateJobSets();

  return (
    <Grid
      alignItems="center"
      columns="max-content 1fr max-content max-content"
      gap="2x"
      justifyContent="center"
      justifySelf="center"
      maxWidth="52rem"
      width="100%"
    >
      <Button
        disabled={isPrevDisabled}
        icon={
          <Icon
            color={isPrevDisabled ? 't3' : 't2'}
            name="arrows:arrow_chevron_double_left"
          />
        }
        text="Prev"
        variant="tertiary"
        onClick={() => {
          return navigateJobSets('prev');
        }}
      />
      <LinearProgress current={numCompletedSets} total={totalSets} />
      <Typography
        htmlText={`<strong>Set ${activeSetNumber}</strong> of ${totalSets}`}
        variant="p3"
      />
      <Button
        disabled={isNextDisabled}
        icon={
          <Icon
            color={isNextDisabled ? 't3' : 't2'}
            name="arrows:arrow_chevron_double_right"
          />
        }
        iconLocation="right"
        text="Next"
        variant="tertiary"
        onClick={() => {
          return navigateJobSets('next');
        }}
      />
    </Grid>
  );
};
