import styled, { css } from 'styled-components';
import { Typography, media } from 'gantri-components';
import {
  bubbleIconLargeSrc,
  bubbleIconSmallSrc,
  notesIconLargeSrc,
  notesIconSmallSrc,
} from '../../../../constants/images';

export const StyledMessagesContainer = styled.div<{ collapsed: boolean }>`
  ${({ collapsed, theme }) => {
    return css`
      position: fixed;
      top: 0;
      right: 0;
      height: 100%;
      z-index: 5;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background: ${theme.colors.surfaces.monochrome.overlayLevel1};
      border-left: 1px solid ${theme.colors.dividers.t1};
      width: 400px;
      transition: 0.3s ease-in-out;

      ${media.lessThan('lg')`
      top: 48px;
      height: calc(100% - 48px);
    `}

      & > div {
        transition: 0.3s ease-in-out;
      }

      ${collapsed &&
      css`
        width: 80px;
        align-items: flex-end;

        & > div:not(:first-child) {
          display: none;
          opacity: 0;
        }

        // header
        & > div:first-child {
          grid-template-columns: 1fr;
          align-items: flex-start;
          align-content: flex-start;
          justify-content: center;
          justify-items: center;
          border-bottom: unset;

          & > span:first-child {
            transform: rotate(0);
          }
        }
      `}
    `;
  }}
`;

export const StyledHeader = styled.div`
  ${({ theme }) => {
    return css`
      height: 50px;
      border-bottom: 1px solid ${theme.colors.dividers.t1};
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      gap: 10px;
      padding: 10px 30px 10px 10px;

      & > * {
        cursor: pointer;
      }
    `;
  }}
`;

const StyledIcon = styled.span`
  height: 24px;
  width: 24px;
  background-position: center;
  background-size: 18px;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.3s ease-in-out;
`;

export const StyledMessagesIconSmall = styled(StyledIcon)`
  background-image: url(${bubbleIconSmallSrc});
`;

export const StyledMessagesIconLarge = styled(StyledIcon)`
  width: 70px;
  height: 60px;
  background-size: 70px 60px;
  background-image: url(${bubbleIconLargeSrc});
`;

export const StyledNotesIconSmall = styled(StyledIcon)`
  background-image: url(${notesIconSmallSrc});
`;

export const StyledNotesIconLarge = styled(StyledIcon)`
  width: 70px;
  height: 60px;
  background-size: 70px 60px;
  background-image: url(${notesIconLargeSrc});
`;

export const StyledContentContainer = styled.div`
  height: calc(100% - 160px);
  overflow-y: auto;
  padding: 30px 30px 0 30px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
`;

export const StyledContent = styled.div<{ active: boolean }>`
  ${({ active }) => {
    return css`
      transition: opacity 0.3s ease-in-out;
      display: grid;
      align-content: start;

      ${!active &&
      css`
        position: absolute;
        top: 30px;
        right: 30px;
        bottom: 30px;
        left: 30px;
        opacity: 0;
        transform: scale(0);
      `}
    `;
  }}
`;

export const StyledFooter = styled.div`
  padding: 10px 30px;
`;

export const StyledInputContainer = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.surfaces.monochrome.t1};
      height: 48px;
      padding: 12px;

      input {
        background-color: ${theme.colors.surfaces.monochrome.t1};
        border: unset;
        padding: unset;
        width: calc(100% - 35px);
        &:hover,
        &:focus {
          border: unset;
        }
      }
    `;
  }}
`;

export const StyledSendButton = styled.span`
  ${({ theme }) => {
    return css`
      display: inline-block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: ${theme.colors.surfaces.green.t3};
      position: absolute;
      top: 0;
      right: 12px;
      padding: 0.8rem;
      bottom: 0;
      margin: auto;
      cursor: pointer;
    `;
  }}
`;

export const StyledInstructions = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;
  justify-items: center;
  align-content: center;
  padding: 90px;
  width: min-content;
  min-width: 340px;
  margin: auto;
`;

export const StyledAttachmentTypography = styled(Typography)`
  cursor: pointer;
`;
