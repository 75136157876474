/* eslint-disable sort-keys-fix/sort-keys-fix */
import { FinishingQaFailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import { checklistDefaults } from '../../common/job-checklist/job-checklist.constants';
import { Checklist } from '../../common/job-checklist/job-checklist.types';
import { placeholderImageSrc } from '../../../../../../constants/images';

export const completeFinishingQaModalChecklistDefaults: Checklist<FinishingQaFailedReason> =
  {
    // keys sorted according to desired display order
    cracking: {
      ...checklistDefaults.cracking,
      failStatusRecommendation: 'Finish_Targeted',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679518472/static-assets/Cracking_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679518467/static-assets/Cracking_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679518469/static-assets/Cracking_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679518470/static-assets/Cracking_F.jpg',
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    gunk: {
      ...checklistDefaults.gunk,
      failStatusRecommendation: 'Finish_Targeted',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
      },
      thumbnailSrc: null,
      tool: 'Overlay Tool',
    },
    under_sanding: {
      ...checklistDefaults.under_sanding,
      failStatusRecommendation: 'Finish_Complete',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679521858/static-assets/Undersand_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679521853/static-assets/Undersand_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679521857/static-assets/Undersand_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679521855/static-assets/Undersand_F.jpg',
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    under_spray: {
      ...checklistDefaults.under_spray,
      failStatusRecommendation: 'Finish_Paint',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: placeholderImageSrc,
        justPassPrimary: placeholderImageSrc,
        justPassSecondary: placeholderImageSrc,
        fail: placeholderImageSrc,
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    color_bleed: {
      ...checklistDefaults.color_bleed,
      failStatusRecommendation: 'Finish_Paint',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679518325/static-assets/Color_Bleed___C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679518327/static-assets/Color_Bleed_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679518332/static-assets/Color_Bleed_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679518331/static-assets/Color_Bleed_F.jpg',
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    excess_paint: {
      ...checklistDefaults.excess_paint,
      failStatusRecommendation: 'Finish_Complete',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: 'https://res.cloudinary.com/gantri/image/upload/v1679522106/static-assets/Overspray_C.jpg',
        justPassPrimary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522122/static-assets/Overspray_P.jpg',
        justPassSecondary:
          'https://res.cloudinary.com/gantri/image/upload/v1679522100/static-assets/Overspray_S.jpg',
        fail: 'https://res.cloudinary.com/gantri/image/upload/v1679522103/static-assets/Overspray_F.jpg',
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    dents: {
      ...checklistDefaults.dents,
      failStatusRecommendation: 'Restart',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
      },
      thumbnailSrc: null,
      tool: 'Overlay Tool',
    },
    scuffing: {
      ...checklistDefaults.scuffing,
      failStatusRecommendation: 'Finish_Targeted',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      slider: {
        failPrimaryAt: 4,
        failSecondaryAt: 5,
      },
      thumbnailSrc: null,
      tool: 'Overlay Tool',
    },
    over_sanding: {
      ...checklistDefaults.over_sanding,
      failStatusRecommendation: 'Restart',
      failStatusShouldFailPart: true,
      ratingPrimarySurface: 1,
      ratingSecondarySurface: 1,
      severityImages: {
        pass: placeholderImageSrc,
        justPassPrimary: placeholderImageSrc,
        justPassSecondary: placeholderImageSrc,
        fail: placeholderImageSrc,
      },
      thumbnailSrc: null,
      tool: 'severity-images',
    },
    wrong_part: {
      ...checklistDefaults.wrong_part,
      failStatusRecommendation: 'Restart',
      failStatusShouldFailPart: true,
    },
    other_failure_modes: {
      ...checklistDefaults.other_failure_modes,
      failStatusRecommendation: 'Finish_Complete',
      failStatusShouldFailPart: true,
    },
  };
