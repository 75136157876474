import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  TestSendGridAlmostThereArgs,
  TestSendGridAlmostThereResponse,
} from './test-sendgrid-almot-there.types';

export const testSendgridAlmostThere = (args: TestSendGridAlmostThereArgs) => {
  const { shipmentId } = args;

  return axios.post<TestSendGridAlmostThereResponse>(
    `${coreApiUrl}/almost-there`,
    { shipmentId },
  );
};
