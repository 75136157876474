import styled, { css } from 'styled-components';
import { BorderTopDivider } from '../../../styles';

export const StyledPagingContainer = styled.div`
  padding: 0.7rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${BorderTopDivider}
  user-select: none;
`;

export const StyledPagingContentRight = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-gap: 5px;
  align-items: center;
  align-content: center;
`;

export const StyledPagingButton = styled.div<{ disabled: boolean }>`
  ${({ disabled }) => {
    return css`
      width: 2.8rem;
      height: 2.8rem;
      border: 1px solid
        ${(props) => {
          return props.theme.colors.dividers.t1;
        }};
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: ${disabled ? 'none' : 'all'};
      opacity: ${disabled ? 0.5 : 1};
      border-radius: 4px;
    `;
  }}
`;
