import axios from 'axios';
import { getCoreApiUrl } from '../../../../helpers/auth';
import { FetchGlobalVariantsResponse } from './fetch-global-variants.types';

export const fetchGlobalVariantsQuery = () => {
  return axios
    .get<FetchGlobalVariantsResponse>(getCoreApiUrl('global-variants'))
    .then(({ data }) => {
      return data;
    });
};
