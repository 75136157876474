import {
  Box,
  Button,
  Conditional,
  Dropdown,
  FileUploader,
  Flex,
  getColorsByProduct,
  Stack,
  Typography,
} from 'gantri-components';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { flatten } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useAsync } from 'react-use';
import { productAtoms } from '../../../../../../../../product.atoms';
import { Divider } from '../../../../../../../../../../components/divider';
import { useCloudinaryFileUploader } from '../../../../../../../../../../hooks/use-cloudinary-file-uploader';
import { useSortableItem } from '../../../../../../../../../../hooks/use-sortable-item';
import { getActiveEnv } from '../../../../../../../../../../helpers/get-active-env';
import {
  LifestylePhotoSectionItemProps,
  SortableLifestylePhotoData,
} from './lifestyle-photos-section-item.types';
import { lifestyleProductAssetsAtoms } from '../../lifestyle-photos-section.atoms';
import { ColorSwatchDropdown } from '../../../../../../../../../../components/common/color-swatch-dropdown';
import { ColorSwatchAndLabel } from '../../../../../../../../../../components/common/color-swatch-and-label';
import { useFetchProductData } from '../../../../../../../../hooks/use-fetch-product-data';
import { useUpdateProductLifestylePhotos } from '../../../../../../../../../../api/products/routes/update-product-lifestyle-photos';
import { Label } from '../../../../../../../../../../components/label';
import { getTransformProductAssetFileName } from '../../../../../../helpers/get-transform-product-asset-file-name';
import { useNotification } from '../../../../../../../../../../hooks/useNotification';

export const LifestylePhotosSectionItem = (
  props: LifestylePhotoSectionItemProps,
) => {
  const { asset, assets, handleUploadFile, index, isDraggable, resetAssets } =
    props;

  const { base64Url, fileName, filePendingUpload, id } = asset;

  const product = useRecoilValue(productAtoms.product);

  const [editingLifestylePhotoData, setEditingLifestylePhotoData] =
    useRecoilState(lifestyleProductAssetsAtoms.editingLifestylePhotoData);
  const resetEditingLifestylePhotoData = useResetRecoilState(
    lifestyleProductAssetsAtoms.editingLifestylePhotoData,
  );

  const { onInterceptProcessingRequest, processing } = useNotification();

  const { invalidateFetchProductCache } = useFetchProductData();

  const { onUpdateLifestylePhotos } = useUpdateProductLifestylePhotos({
    onSuccess: async () => {
      await invalidateFetchProductCache();
    },
  });

  const { isLocalEnv } = getActiveEnv();

  const { transformFileName } = getTransformProductAssetFileName({
    fileType: 'lifestyle-photos',
    productId: product.id,
  });

  const { fileUploaderProps } = useCloudinaryFileUploader<'products'>({
    base64Url,
    directory: 'products',
    fileName,
    fileType: 'lifestyle-photos',
    handleUploadsComplete: async ([{ fileName }]) => {
      const updatedLifestylePhotos = assets.map((asset, assetIndex) => {
        return assetIndex === index ? { ...asset, fileName } : asset;
      });

      await onUpdateLifestylePhotos({
        lifestylePhotos: updatedLifestylePhotos,
        productId: product.id,
      });
    },
    identifiers: {
      productId: product.id,
    },
    onFileDelete: async () => {
      const updatedLifestylePhotos = assets.filter((_data, assetIndex) => {
        return assetIndex !== index;
      });

      await onUpdateLifestylePhotos({
        lifestylePhotos: updatedLifestylePhotos,
        productId: product.id,
      });
    },
    transformFileName,
  });

  const { SortableDragHandleWrapper, SortableItemWrapper } = useSortableItem({
    id,
    useDragHandle: true,
  });

  const updateAssetsToRemoveEmptyVariants = (
    assets: SortableLifestylePhotoData[],
  ) => {
    return assets.map((asset) => {
      let { variants } = asset;

      const isVariantsEmpty = Object.values(variants || {}).every((value) => {
        const partialTypes = Object.keys(value);

        return !partialTypes.length;
      });

      if (isVariantsEmpty) {
        variants = null;
      }

      return { ...asset, variants };
    });
  };

  const onSave = async () => {
    await onInterceptProcessingRequest(async () => {
      if (filePendingUpload) {
        const { fileName } = await handleUploadFile(filePendingUpload);

        const updatedLifestylePhotos = assets.map((asset, assetIndex) => {
          return assetIndex === index
            ? {
                ...editingLifestylePhotoData,
                fileName,
                filePendingUpload: undefined,
              }
            : asset;
        });

        await onUpdateLifestylePhotos({
          lifestylePhotos: updateAssetsToRemoveEmptyVariants(
            updatedLifestylePhotos,
          ),
          productId: product.id,
        });
      } else {
        const updatedLifestylePhotos = assets.map((asset, assetIndex) => {
          return assetIndex === index ? editingLifestylePhotoData : asset;
        });

        await onUpdateLifestylePhotos({
          lifestylePhotos: updateAssetsToRemoveEmptyVariants(
            updatedLifestylePhotos,
          ),
          productId: product.id,
        });
      }

      resetEditingLifestylePhotoData();
    });
  };

  const onCancel = () => {
    resetAssets();
    resetEditingLifestylePhotoData();
  };

  const onEdit = () => {
    setEditingLifestylePhotoData(asset);
  };

  const isEditingItem = asset.id === editingLifestylePhotoData?.id;

  const colors = getColorsByProduct({
    allowTradeColors: true,
    isPainted: product.isPainted,
    productId: product.id,
  });

  const flattenedSelectors = useMemo(() => {
    return flatten(product.selectors);
  }, [product.selectors]);

  const disableSaveButton = product.isPainted
    ? !editingLifestylePhotoData?.color
    : false;

  const shouldAutoUpload =
    filePendingUpload && !product.isPainted && !flattenedSelectors.length;

  useAsync(async () => {
    if (shouldAutoUpload) {
      await onSave();
    }
  }, [shouldAutoUpload]);

  return (
    <Conditional condition={!shouldAutoUpload}>
      <SortableItemWrapper title={fileName}>
        <Stack gap="2x" paddingBottom="2x">
          <Flex alignItems="center" gap="3x">
            <FileUploader
              {...fileUploaderProps}
              enableCopyUrl={isLocalEnv}
              isDisabled={!!editingLifestylePhotoData}
              isDraggable={isDraggable}
              isReplaceable={!editingLifestylePhotoData}
              SortableDragHandleWrapper={SortableDragHandleWrapper}
              variant="thumbnail"
            />

            <Box>
              <Conditional
                condition={isEditingItem}
                Fallback={
                  <Conditional
                    condition={product.isPainted || !!flattenedSelectors.length}
                  >
                    <Stack>
                      <Flex alignItems="center" gap="3x" height="100%">
                        <Conditional condition={product.isPainted}>
                          <Flex alignItems="center" gap="x">
                            <Label text="Color:" whiteSpace="nowrap" />
                            <ColorSwatchAndLabel colorCode={asset.color} />
                          </Flex>
                        </Conditional>

                        {flattenedSelectors.map((selector) => {
                          const { id, label, options, type } = selector;

                          const code = asset?.variants?.[id]?.[type];
                          const name = options.find((option) => {
                            return option.code === code;
                          })?.name;

                          return (
                            <Fragment key={`${id}-${type}`}>
                              <Conditional condition={product.isPainted}>
                                <Box height="100%">
                                  <Divider
                                    borderLocation="left"
                                    height="100%"
                                  />
                                </Box>
                              </Conditional>

                              <Flex alignItems="center" gap=".5x">
                                <Label text={`${label}:`} whiteSpace="nowrap" />
                                <Typography
                                  text={name || '-'}
                                  whiteSpace="nowrap"
                                />
                              </Flex>
                            </Fragment>
                          );
                        })}

                        <Button
                          disabled={!!editingLifestylePhotoData}
                          size="medium"
                          text="Edit"
                          variant="ghost"
                          onClick={onEdit}
                        />
                      </Flex>
                    </Stack>
                  </Conditional>
                }
              >
                <Flex gap="x" justifyContent="flex-start">
                  <Conditional condition={product.isPainted}>
                    <Box minWidth="18rem">
                      <ColorSwatchDropdown
                        disabled={processing}
                        idProperty="code"
                        items={colors}
                        labelProperty="shortColorName"
                        labelText="Color"
                        required
                        value={editingLifestylePhotoData?.color}
                        onSelect={(item) => {
                          setEditingLifestylePhotoData((prevData) => {
                            const updatedData: SortableLifestylePhotoData = {
                              ...prevData,
                              color: item?.code,
                            };

                            return updatedData;
                          });
                        }}
                      />
                    </Box>
                  </Conditional>

                  {flattenedSelectors.map((selector) => {
                    const { id, label, options, type } = selector;

                    const placeholder = `Select ${label.toLowerCase()}`;
                    const optionsWithDeselect = [
                      { code: undefined, name: placeholder },
                      ...options,
                    ];

                    return (
                      <Box key={`${id}-${type}`} minWidth="18rem">
                        <Dropdown
                          disabled={processing}
                          idProperty="code"
                          items={optionsWithDeselect}
                          labelProperty="name"
                          labelText={label}
                          placeholder={placeholder}
                          value={
                            editingLifestylePhotoData?.variants?.[id]?.[type]
                          }
                          onSelect={(item) => {
                            setEditingLifestylePhotoData((prevData) => {
                              const updatedData: SortableLifestylePhotoData = {
                                ...prevData,
                                variants: {
                                  ...(prevData?.variants || {}),
                                  [id]: {
                                    ...(prevData?.variants?.[id] || {}),
                                    [type]: item?.code,
                                  },
                                },
                              };

                              return updatedData;
                            });
                          }}
                        />
                      </Box>
                    );
                  })}

                  <Stack gap=".5x">
                    <Box height="2.2rem" />
                    <Flex alignItems="center" gap=".5x">
                      <Button
                        disabled={processing}
                        size="medium"
                        text="Cancel"
                        variant="secondary"
                        onClick={onCancel}
                      />
                      <Button
                        disabled={disableSaveButton}
                        processing={processing}
                        size="medium"
                        text="Save"
                        onClick={onSave}
                      />
                    </Flex>
                  </Stack>
                </Flex>
              </Conditional>
            </Box>
          </Flex>

          <Divider />
        </Stack>
      </SortableItemWrapper>
    </Conditional>
  );
};
