import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  TestShippoWebhookArgs,
  TestShippoWebhookResponse,
} from './test-shippo-webhook.types';

export const testShippoWebhook = (args: TestShippoWebhookArgs) => {
  const {
    shipmentHistory,
    testType,
    trackingNumber, // TRANSIT || DELIVERED
    trackingStatus,
  } = args;

  const { location } = shipmentHistory;
  const objectCreated = shipmentHistory.object_created;
  const objectId = shipmentHistory.object_id;

  const statusDetails =
    testType === 'TRANSIT'
      ? 'Your shipment has departed from the origin.'
      : 'Your shipment has been delivered.';

  const testData = {
    tracking_history: shipmentHistory,
    tracking_number: trackingNumber,
    tracking_status:
      trackingStatus === null
        ? null
        : {
            location,
            object_created: objectCreated,
            object_id: objectId,
            status: testType,
            status_date: Date.now(),
            status_details: statusDetails,
          },
  };

  return axios.post<TestShippoWebhookResponse>(`${baseApiUrl}/shippo-webhook`, {
    data: testData,
  });
};
