import { Conditional } from 'gantri-components';
import { DividerProps } from './divider.types';
import { StyledDividerWrapper, StyledHorizontalLine } from './divider.styles';
import { dividerDefaultProps } from './divider.presets';

export const Divider = (props: DividerProps) => {
  const { borderColor, borderLocation, ...rest } = props;

  const isHorizontalBorder = ['top', 'bottom'].includes(borderLocation);

  return (
    <StyledDividerWrapper
      borderColor={borderColor}
      borderLocation={isHorizontalBorder ? undefined : borderLocation}
      {...rest}
    >
      <Conditional condition={isHorizontalBorder}>
        <StyledHorizontalLine borderColor={borderColor} />
      </Conditional>
    </StyledDividerWrapper>
  );
};

Divider.defaultProps = dividerDefaultProps;
