import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { CancelPartArgs, CancelPartsResponse } from './cancel-part.types';

export const cancelPart = (args: CancelPartArgs) => {
  const { partId, ...body } = args;

  return axios.put<CancelPartsResponse>(
    `${coreApiUrl}/parts/${partId}/cancel`,
    body,
  );
};
