import React, { FC } from 'react';
import { Form, Formik } from 'formik';
import { Button, Grid, Modal, Typography } from 'gantri-components';
import { NewInventoryFormSchema } from './new-inventory.schema';
import { NewInventoryModalProps } from './new-inventory.types';
import { NewInventoryForm } from './new-inventory-form';

export const NewInventoryModal: FC<NewInventoryModalProps> = ({
  initialValues = { description: '', name: '', unit: '' },
  onCancel,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validateOnChange
      validateOnMount
      validationSchema={NewInventoryFormSchema}
      onSubmit={onSubmit}
    >
      {({ isValid }: any) => {
        return (
          <Form>
            <Modal
              footer={
                <Grid columns={2} gap="1.6rem">
                  <Button
                    size="large"
                    text="Cancel"
                    variant="secondary"
                    onClick={onCancel}
                  />

                  <Button
                    disabled={!isValid}
                    size="large"
                    text="Confirm"
                    type="submit"
                  />
                </Grid>
              }
              header={
                <Typography
                  align="center"
                  text="New Inventory"
                  textStyle="bold"
                  variant="h4"
                />
              }
              width={{ lg: '42rem', md: '100%' }}
              onClose={onCancel}
            >
              <NewInventoryForm />
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};
