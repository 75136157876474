import { Datum } from '@nivo/line';
import { v4 as uuidv4 } from 'uuid';
import { ChartSerie } from '../../../../../../../api/dashboard/dashboard.types';

const isNegative = (datum: Datum) => {
  return datum?.y < 0;
};

const isPositive = (datum: Datum) => {
  return datum?.y >= 0;
};

const getDataPoints = (data: Datum[], index: number) => {
  const prevDataPoint = data[index - 1];
  const currentDataPoint = data[index];
  const nextDataPoint = data[index + 1];
  const nextNextDataPoint = data[index + 2];
  const emptyDataPoint: Datum = { ...currentDataPoint, y: null };

  const prevIsNegative = isNegative(prevDataPoint);
  const prevIsPositive = isPositive(prevDataPoint);
  const currentIsNegative = isNegative(currentDataPoint);
  const currentIsPositive = isPositive(currentDataPoint);
  const nextIsNegative = isNegative(nextDataPoint);
  const nextIsPositive = isPositive(nextDataPoint);
  const nextNextIsNegative = isNegative(nextNextDataPoint);
  const nextNextIsPositive = isPositive(nextNextDataPoint);

  return {
    currentIsNegative,
    currentIsPositive,
    emptyDataPoint,
    nextIsNegative,
    nextIsPositive,
    nextNextIsNegative,
    nextNextIsPositive,
    prevIsNegative,
    prevIsPositive,
  };
};

/** Split chart data and return unique indexes for positive and negative data point sets. IDs are updated with a `-positive` or `-negative` suffix. */
export const splitPositivesAndNegatives = (chartData: ChartSerie[]) => {
  const updatedData: ChartSerie[] = [];

  chartData.forEach(({ data, id, name }) => {
    const onlyNegativeData = data.map((dataPoint, index) => {
      const { currentIsNegative, emptyDataPoint } = getDataPoints(data, index);

      return currentIsNegative ? dataPoint : emptyDataPoint;
    });

    updatedData.push({
      data: onlyNegativeData,
      id: `${id}-${uuidv4()}-negative`,
      name,
    });

    const hillyNegativeDataA = data.map((dataPoint, index) => {
      const {
        currentIsNegative,
        emptyDataPoint,
        nextIsNegative,
        nextIsPositive,
        nextNextIsPositive,
        prevIsPositive,
      } = getDataPoints(data, index);

      return nextIsNegative ||
        (prevIsPositive &&
          currentIsNegative &&
          nextIsPositive &&
          nextNextIsPositive)
        ? dataPoint
        : emptyDataPoint;
    });

    updatedData.push({
      data: hillyNegativeDataA,
      id: `${id}-${uuidv4()}-negative`,
      name,
    });

    const hillyNegativeDataB = data.map((dataPoint, index) => {
      const {
        currentIsNegative,
        currentIsPositive,
        emptyDataPoint,
        nextIsNegative,
        nextIsPositive,
        prevIsPositive,
      } = getDataPoints(data, index);

      return (prevIsPositive && currentIsPositive && nextIsNegative) ||
        (prevIsPositive && currentIsNegative && nextIsPositive)
        ? dataPoint
        : emptyDataPoint;
    });

    updatedData.push({
      data: hillyNegativeDataB,
      id: `${id}-${uuidv4()}-negative`,
      name,
    });

    const onlyPositiveData = data.map((dataPoint, index) => {
      const { currentIsPositive, emptyDataPoint } = getDataPoints(data, index);

      return currentIsPositive ? dataPoint : emptyDataPoint;
    });

    updatedData.push({
      data: onlyPositiveData,
      id: `${id}-${uuidv4()}-positive`,
      name,
    });

    const hillyPositiveData = data.map((dataPoint, index) => {
      const {
        currentIsPositive,
        emptyDataPoint,
        nextIsNegative,
        nextIsPositive,
        prevIsNegative,
      } = getDataPoints(data, index);

      return nextIsPositive ||
        (prevIsNegative && currentIsPositive && nextIsNegative)
        ? dataPoint
        : emptyDataPoint;
    });

    updatedData.push({
      data: hillyPositiveData,
      id: `${id}-${uuidv4()}-positive`,
      name,
    });
  });

  return updatedData;
};
