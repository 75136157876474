import { atom } from 'recoil';
import { MachineRecord } from './machines.types';

const atomKeyPrefix = 'MACHINES_PAGE';

export const machinesAtoms = {
  disableRowSelection: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-enable-row-selection`,
  }),
  machinesSelected: atom<MachineRecord[]>({
    default: [],
    key: `${atomKeyPrefix}-machines-selected`,
  }),
};
