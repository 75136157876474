import PropTypes from 'prop-types';
import { StyledOverlay } from '../modals-styles.module';

const Overlay = ({ children }) => {
  const handleClick = (e) => {
    e.stopPropagation();
  };

  return <StyledOverlay onClick={handleClick}>{children}</StyledOverlay>;
};

Overlay.propTypes = {
  children: PropTypes.node,
};

export default Overlay;
