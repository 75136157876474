import React, { FC } from 'react';
import { Stack } from 'gantri-components';
import { OverviewProps } from './overview-section.types';
import {
  OverviewGeneralSection,
  OverviewVendorSection,
  ProductsSection,
} from './sections';
import { SectionContainer } from '../../../../components/common/section-container';

export const OverviewSection: FC<OverviewProps> = ({
  currentInventory: inventory,
  isEditModeActive,
}) => {
  return (
    <Stack rowGap="7x">
      <OverviewGeneralSection
        inventory={inventory}
        isEditModeActive={isEditModeActive}
      />

      <SectionContainer title="Vendor">
        <OverviewVendorSection
          inventory={inventory}
          isEditModeActive={isEditModeActive}
        />
      </SectionContainer>

      <ProductsSection inventory={inventory} />
    </Stack>
  );
};
