import { Dropdown, DropdownChangeEvent, Flex, Stack } from 'gantri-components';
import { useEffect, useRef } from 'react';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../../helpers/checks';
import { DashboardChart } from '../../../../../../dashboard/components/common/dashboard-chart';
import { getChartLeftMargin } from '../../../../../../dashboard/components/common/dashboard-chart/helpers';
import { useGetChartData } from '../../../../../../dashboard/components/common/reports-summary/components/report-chart-column/hooks/use-get-chart-data';
import {
  StyledScrollChild,
  StyledScrollParent,
} from '../../report-page.styles';
import { StyledChartWrapper } from './report-chart.styles';
import { ReportChartProps } from './report-chart.types';

export const ReportChart = (props: ReportChartProps) => {
  const {
    activeChart,
    activeChartIsCurrency,
    axisLeft,
    chartData,
    defaultChart,
    extraLeftMargin,
    setActiveChart,
    transformTooltipValue,
  } = props;
  const activeChartDetails = chartData?.[activeChart];
  const leftMargin = getChartLeftMargin({
    activeChartDetails,
    extraMargin: extraLeftMargin,
    isCents: activeChartIsCurrency,
  });
  const chartDataKeys = Object.keys(chartData || {});
  const dropdownItems: { label: string; value: string }[] = chartDataKeys
    .map((key) => {
      return chartData[key][0];
    })
    .map(({ id, name }) => {
      return { label: name, value: `${id}` };
    });
  const hasDropdown = dropdownItems?.length > 1;

  const { formatBottomTicks } = useGetChartData({
    activeChartDetails,
  });

  const handleDropdownChange = ({ target }: DropdownChangeEvent<any>) => {
    const activeChart: string = target.value;
    const queries = getActiveQueries();
    const queryString = convertQueriesToString({
      ...queries,
      activeChart,
    });
    const { origin, pathname } = window.location;

    window.history.replaceState(
      { activeChart },
      '',
      origin + pathname + queryString,
    );

    setActiveChart(activeChart);
  };

  const mountedRef = useRef<boolean>(true);
  const isMounted = mountedRef.current;

  useEffect(() => {
    if (!activeChart && isMounted) {
      const chartDataKeys = Object.keys(chartData || {});
      const firstCartDataKey = chartDataKeys[0];

      setActiveChart(defaultChart || firstCartDataKey);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [chartData]);

  return chartData ? (
    <StyledScrollParent>
      <StyledScrollChild>
        <StyledChartWrapper>
          <Stack gap="1.1rem" rows={hasDropdown ? 'min-content 1fr' : '1fr'}>
            {hasDropdown && (
              <Flex>
                <Dropdown
                  items={dropdownItems}
                  value={activeChart}
                  width="auto"
                  onChange={handleDropdownChange}
                />
              </Flex>
            )}
            {activeChartDetails && (
              <DashboardChart
                axisBottom={{
                  format: formatBottomTicks,
                }}
                axisLeft={{
                  tickValues: 3,
                  ...(axisLeft || {}),
                }}
                data={activeChartDetails}
                margin={{
                  bottom: 35,
                  left: leftMargin,
                  right: 20,
                  top: 5,
                }}
                minHeight="28rem"
                transformTooltipValue={transformTooltipValue}
              />
            )}
          </Stack>
        </StyledChartWrapper>
      </StyledScrollChild>
    </StyledScrollParent>
  ) : null;
};
