import { useNotification } from '../../../../hooks/useNotification';
import { inventoriesApi } from '../../../index';
import { FetchAllInventoriesResponse } from './fetch-all.types';
import { UseQueryProps } from '../../../../hooks/use-fetch/react-queries.types';
import {
  useInvalidateQuery,
  useQueryFetch,
} from '../../../../hooks/use-fetch/use-query-fetch';

interface Props<TData>
  extends UseQueryProps<TData, FetchAllInventoriesResponse> {}

export const useFetchAllInventoriesQuery = <TData>(props?: Props<TData>) => {
  const { notifyAxiosError } = useNotification();

  const { data, isLoading } = useQueryFetch<
    FetchAllInventoriesResponse | TData
  >({
    config: {
      enabled: props?.enabled ?? true,
      onError: (error) => {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to fetch inventories.',
        });

        props?.onError?.(error);
      },
      queryFn: () => {
        return inventoriesApi.getAll();
      },
      queryKey: inventoriesApi.getAll.queryKey(),
      select: (response: FetchAllInventoriesResponse) => {
        return props?.transform?.(response) ?? response;
      },
    },
    showLoading: props?.showLoading,
    transformDependencies: props?.transformDependencies,
  });

  return {
    inventories: data as TData,
    isLoading,
  };
};

export const useInvalidateFetchAllInventoriesCache = () => {
  const invalidate = useInvalidateQuery();

  const invalidateFetchAllInventoriesCache = () => {
    return invalidate(inventoriesApi.getAll.queryKey());
  };

  return { invalidateFetchAllInventoriesCache };
};

export const paintInventoryNamePattern = /Paint\s?[-(—]/i;
