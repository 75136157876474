import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';

export const deleteInventoryTransaction = (transactionId: number) => {
  return axios({
    method: 'delete',
    url: `${coreApiUrl}/inventory-transaction/${transactionId}`,
  }).then((response) => {
    return response.data;
  });
};
