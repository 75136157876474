import axios, { AxiosRequestConfig } from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { convertQueriesToString } from '../../../../helpers/checks';
import {
  GetReportStocksArgs,
  GetReportStocksResponse,
} from './get-report-stocks.types';

export const getReportStocks = (
  { rangeType, ...queries }: GetReportStocksArgs,
  configs?: AxiosRequestConfig,
) => {
  const queriesString = convertQueriesToString({
    ...queries,
    typeDate: rangeType,
  });

  return axios.get<GetReportStocksResponse>(
    `${coreApiUrl}/reports/stocks${queriesString}`,
    configs,
  );
};
