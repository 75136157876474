import { Breadcrumb } from '../../../../../../components/layout/page-heading/components/breadcrumbs/breadcrumbs.types';
import {
  convertQueriesToString,
  getActiveQueries,
} from '../../../../../../helpers/checks';
import { defaultJobsGroupBy } from '../../report-jobs.constants';
import { ReportJobsQueries } from '../../report-jobs.types';

export const getBreadcrumbs = (): Breadcrumb[] => {
  const rootPageName = 'Jobs';
  const { origin, pathname } = window.location;
  const location = origin + pathname;
  const queries = getActiveQueries<ReportJobsQueries>();
  const {
    groupBy = defaultJobsGroupBy,
    productId,
    jobType,
    machineType,
    machineName,
    productName,
    workerName,
    workerId,
    version,
    partName,
  } = queries;

  const breadcrumb: Breadcrumb[] = [
    {
      name: rootPageName,
      path: location + convertQueriesToString({ groupBy }),
    },
  ];

  if (/^productName$/i.test(groupBy) && productName) {
    if (version) {
      breadcrumb.push({
        name: productName,
        path:
          location +
          convertQueriesToString({
            groupBy: 'productName',
            productId,
            productName,
          }),
      });
    }

    if (partName) {
      breadcrumb.push({
        name: version,
        path:
          location +
          convertQueriesToString({
            groupBy: 'productName',
            productId,
            productName,
            version,
          }),
      });
    }

    if (jobType) {
      breadcrumb.push({
        name: partName,
        path:
          location +
          convertQueriesToString({
            groupBy: 'productName',
            partName,
            productId,
            productName,
            version,
          }),
      });
    }

    return breadcrumb;
  }

  if (/^jobType$/i.test(groupBy) && jobType) {
    return breadcrumb;
  }

  if (/^workerName$/i.test(groupBy) && workerName) {
    if (jobType) {
      const workerNameQueries = { groupBy, workerId, workerName };
      const workerNameBreadcrumb: Breadcrumb = {
        name: workerName,
        path: location + convertQueriesToString(workerNameQueries),
      };
      const workerNameBreadcrumbTrail = [...breadcrumb, workerNameBreadcrumb];

      return workerNameBreadcrumbTrail;
    }

    return breadcrumb;
  }

  if (/^machineType$/i.test(groupBy) && machineType) {
    if (machineName) {
      const machineTypeQueries = { groupBy, machineType };
      const machineTypeBreadcrumb: Breadcrumb = {
        name: machineType,
        path: location + convertQueriesToString(machineTypeQueries),
      };
      const machineTypeBreadcrumbTrail = [...breadcrumb, machineTypeBreadcrumb];

      if (jobType) {
        const machineNameQueries = { ...machineTypeQueries, machineName };
        const machineNameBreadcrumb: Breadcrumb = {
          name: machineName,
          path: location + convertQueriesToString(machineNameQueries),
        };
        const machineNameBreadcrumbTrail = [
          ...machineTypeBreadcrumbTrail,
          machineNameBreadcrumb,
        ];

        return machineNameBreadcrumbTrail;
      }

      return machineTypeBreadcrumbTrail;
    }

    return breadcrumb;
  }

  return [];
};
