import { MoreMenuProps } from './more-menu.types';
import { moreMenuDefaultProps } from './more-menu.presets';
import { useMoreMenu } from '../../../hooks/use-more-menu';

export const MoreMenu = <Data extends unknown>(props: MoreMenuProps<Data>) => {
  const { MoreMenu: Menu } = useMoreMenu(props);

  return <Menu />;
};

MoreMenu.defaultProps = moreMenuDefaultProps;
