import { atom } from 'recoil';
import { Job } from '../../../../../../api/jobs/jobs.types';
import { NextJob } from '../../../../../../api/jobs/routes/complete-job/complete-job.types';
import { FinishingQaFailedReason } from '../../../../../../api/jobs/routes/get-fail-reasons-list/get-fail-reasons-list.types';
import {
  Checklist,
  NextStep,
} from '../../common/job-checklist/job-checklist.types';
import { completeFinishingQaModalChecklistDefaults } from './complete-finishing-qa-modal.constants';
import {
  CompleteFinishingQaModalStep,
  FinishingQaFailedNextStep,
} from './complete-finishing-qa-modal.types';
import { NewPart } from '../../../../../../api/jobs/routes/fail-job/fail-job.types';
import { ActiveBatch } from '../../../../../../api/batch/routes/get-all-active-batches/get-all-active-batches.types';
import { BatchUpdateMethod } from '../../../../../part/components/part-summary/components/update-part-batch-modal/components/step-update-batch/update-batch-content/update-batch-content.types';

const atomKeyPrefix = 'FINISHING_QA_MODAL';

export const finishingQcModalAtoms = {
  activeBatches: atom<ActiveBatch[]>({
    default: [],
    key: `${atomKeyPrefix}-active-batches`,
  }),
  batchUpdateMethod: atom<BatchUpdateMethod>({
    default: null,
    key: `${atomKeyPrefix}-batch-update-method`,
  }),
  checklist: atom<Checklist<FinishingQaFailedReason>>({
    default: completeFinishingQaModalChecklistDefaults,
    key: `${atomKeyPrefix}-checklist`,
  }),
  duplicatedJobs: atom<Job[]>({
    default: [],
    key: `${atomKeyPrefix}-duplicated-jobs`,
  }),
  failedNextStep: atom<FinishingQaFailedNextStep>({
    default: null,
    key: `${atomKeyPrefix}-failed-next-step`,
  }),
  failedNextStepDetails: atom<string>({
    default: null,
    key: `${atomKeyPrefix}-failed-next-step-details`,
  }),
  isAtMaxFinishAttempts: atom<boolean>({
    default: null,
    key: `${atomKeyPrefix}-is-at-max-finish-attempts`,
  }),
  isChecklistReviewed: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-is-checklist-reviewed`,
  }),
  newBatchId: atom<number>({
    default: null,
    key: `${atomKeyPrefix}-new-batch-id`,
  }),
  newParts: atom<NewPart[]>({
    default: [],
    key: `${atomKeyPrefix}-new-parts`,
  }),
  nextJob: atom<NextJob>({
    default: null,
    key: `${atomKeyPrefix}-next-job`,
  }),
  numFinishingAttempts: atom<number>({
    default: 1,
    key: `${atomKeyPrefix}-num-finishing-attempts`,
  }),
  step: atom<CompleteFinishingQaModalStep>({
    default: 'CONFIRM_BEGIN',
    key: `${atomKeyPrefix}-step`,
  }),
  systemRecommendedStep: atom<NextStep>({
    default: null,
    key: `${atomKeyPrefix}-system-recommended-step`,
  }),
  updateOnClose: atom<boolean>({
    default: false,
    key: `${atomKeyPrefix}-update-on-close`,
  }),
};
