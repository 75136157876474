import axios from 'axios';
import { baseApiUrl } from '../../../../helpers/auth';
import {
  ToggleProductHandoutIsUniversalArgs,
  ToggleProductHandoutIsUniversalResponse,
} from './toggle-product-handout-is-universal.types';

export const toggleProductHandoutIsUniversal = ({
  handoutId,
  ...body
}: ToggleProductHandoutIsUniversalArgs) => {
  return axios
    .put<ToggleProductHandoutIsUniversalResponse>(
      `${baseApiUrl}/product-assets/${handoutId}`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
