import { FC } from 'react';
import { Typography } from 'gantri-components';
import Grid from '../../../../../../components/common/grid';
import { ColorInputCell } from './components/color-input-cell';
import { ColorsSectionProps } from './colors-section.types';

export const ColorsSection: FC<ColorsSectionProps> = (props) => {
  const {
    backBackgroundColor,
    backTextColor,
    frontBackgroundColor,
    frontTextColor,
    setBackBackgroundColor,
    setBackTextColor,
    setFrontBackgroundColor,
    setFrontTextColor,
  } = props;

  return (
    <>
      <Typography
        marginBottom="1rem"
        text="Colors"
        textStyle="bold"
        variant="h4"
      />
      <Grid columns={{ lg: 2, md: 1 }} gap="2rem" style={{ zIndex: 1 }}>
        <ColorInputCell
          color={frontTextColor}
          column={{
            lg: 1,
            md: 1,
          }}
          label="Front text color"
          row={{
            lg: 1,
            md: 2,
          }}
          setColor={setFrontTextColor}
        />
        <ColorInputCell
          color={backTextColor}
          column={{
            lg: 2,
            md: 1,
          }}
          label="Back text color"
          row={{
            lg: 1,
            md: 4,
          }}
          setColor={setBackTextColor}
        />
        <ColorInputCell
          color={frontBackgroundColor}
          column={{
            lg: 1,
            md: 1,
          }}
          label="Front background color"
          row={{
            lg: 2,
            md: 1,
          }}
          setColor={setFrontBackgroundColor}
        />
        <ColorInputCell
          color={backBackgroundColor}
          column={{
            lg: 2,
            md: 1,
          }}
          label="Back background color"
          row={{
            lg: 2,
            md: 3,
          }}
          setColor={setBackBackgroundColor}
        />
      </Grid>
    </>
  );
};
