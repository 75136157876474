import { Modal } from 'gantri-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect } from 'react';
import { FullScreenModalHeader } from '../../../jobs/components/modals/common/full-screen-modal-header';
import { NewMachineIssuesModalProps } from './new-machine-issues-modal.types';
import { newMachineIssuesModalAtoms } from './new-machine-issues-modal.atoms';
import { NewMachineIssuesModalFooter } from './components/new-machine-issues-modal-footer';
import { NewMachineIssuesModalContent } from './components/new-machine-issues-modal-content';
import { useResetRecoilAtomsOnMount } from '../../../../hooks/use-reset-recoil-atoms-on-mount';
import { BulkUpdateMachinesModalDetailsPanel } from '../bulk-update-machines-modal-details-panel';
import { SimpleErrorBoundary } from '../../../../components/simple-error-boundary';

export const NewMachineIssuesModal = (props: NewMachineIssuesModalProps) => {
  const { machines, onClose, onSuccess, showBulkOptionsOnly } = props;

  const { ResetAtomsWrapper } = useResetRecoilAtomsOnMount(
    newMachineIssuesModalAtoms,
  );
  const updateOnClose = useRecoilValue(
    newMachineIssuesModalAtoms.updateOnClose,
  );
  const [detailsPanelMachines, setDetailsPanelMachines] = useRecoilState(
    newMachineIssuesModalAtoms.detailsPanelMachines,
  );

  const handleCloseModal = async () => {
    if (updateOnClose) {
      await onSuccess();
    }

    onClose();
  };

  useEffect(() => {
    setDetailsPanelMachines(machines);
  }, []);

  return (
    <ResetAtomsWrapper>
      <Modal
        closeable={false}
        detailsPanel={
          <BulkUpdateMachinesModalDetailsPanel
            machines={detailsPanelMachines}
          />
        }
        detailsPanelWidth="1fr"
        footer={
          <SimpleErrorBoundary>
            <NewMachineIssuesModalFooter handleCloseModal={handleCloseModal} />
          </SimpleErrorBoundary>
        }
        header={
          <FullScreenModalHeader
            handleCloseModal={handleCloseModal}
            text="New Machine Issues"
          />
        }
        isFullScreen
      >
        <SimpleErrorBoundary>
          <NewMachineIssuesModalContent
            showBulkOptionsOnly={showBulkOptionsOnly}
          />
        </SimpleErrorBoundary>
      </Modal>
    </ResetAtomsWrapper>
  );
};
