import { Flex, Toggle, Typography } from 'gantri-components';
import { useRecoilState } from 'recoil';
import {
  FilterGrid,
  FilterRow,
} from '../../../../components/common/filter-grid';
import { FilterResetLabel } from '../../../../components/common/filter-reset-label';
import { StyledSwitchWrapper } from '../../../../components/dropdowns/menu-styles';
import { designersPageAtoms } from './designers-filter.atoms';

export const DesignersFilter = () => {
  const [active, setActive] = useRecoilState(designersPageAtoms.filters.active);

  return (
    <FilterGrid isSmallFormat>
      <FilterRow columns={1}>
        <FilterResetLabel
          atom={designersPageAtoms.filters.active}
          default={designersPageAtoms.defaults.filters.active}
          hideReset
          text="Status"
        />
        <Flex alignItems="center">
          <Typography text="Show All" />
          <StyledSwitchWrapper>
            <Toggle value={active} onSelected={setActive} />
          </StyledSwitchWrapper>
          <Typography text="Active" />
        </Flex>
      </FilterRow>
    </FilterGrid>
  );
};
