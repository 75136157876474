import { Content } from 'pdfmake/interfaces';
import { GetPackingSlipDocProps } from '../../get-packing-slip-doc.types';
import { getTableItemsFromStocks } from '../get-table-items-from-stocks';

/** Docs: https://pdfmake.github.io/docs/0.1/document-definition-object/ */
export const getItemsInShipment = ({
  stocks,
}: GetPackingSlipDocProps): Content[] => {
  const numStocks = stocks.length;

  const itemsInShipment = getTableItemsFromStocks(stocks);

  return [
    {
      layout: 'noBorders',
      style: ['tableMargins'],
      table: {
        body: [
          [
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: `Items in shipment ${
                numStocks > 1 ? `(${numStocks} total)` : ''
              }`,
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'SKU',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Color',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Size',
            },
            {
              style: ['tableHeader', 'bold', 'tableCellAllMargins'],
              text: 'Quantity',
            },
          ],
          ...itemsInShipment.map(({ color, name, qty, size, sku }) => {
            return [
              { style: ['tableCellAllMargins'], text: name },
              {
                style: ['tableCellAllMargins', 'lightGray', 'fontSize8'],
                text: sku,
              },
              { style: ['tableCellAllMargins'], text: color },
              { style: ['tableCellAllMargins'], text: size },
              { style: ['tableCellAllMargins'], text: qty },
            ];
          }),
        ],
        headerRows: 1,
        widths: ['35%', '20%', '15%', '15%', '15%'],
      },
    },
  ];
};
