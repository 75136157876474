import { Typography } from 'gantri-components';
import { formatDate } from '../../../../../../../helpers/formatter';
import { StyledTooltip } from '../../dashboard-chart.styles';
import { SliceTooltipProps } from './slice-tooltip.types';
import { MEDIUM_FORMAT } from '../../../../../../../constants/dates';

export const SliceTooltip = ({
  chartData,
  props,
  transformTooltipValue,
}: SliceTooltipProps) => {
  const [point] = props.slice.points;
  const {
    data: { x: date, y: value },
    serieId,
  } = point;
  const label = chartData.find(({ id }) => {
    return id === serieId;
  })?.name;
  const textValue = transformTooltipValue(value).toString();

  return (
    <StyledTooltip>
      <Typography
        color="t2"
        text={formatDate(date, MEDIUM_FORMAT)}
        variant="p3"
      />
      <Typography fontWeight="bold" text={label} variant="p3" />
      <Typography text={textValue} />
    </StyledTooltip>
  );
};
