import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  RemoveResaleCertificateArgs,
  RemoveResaleCertificateResponse,
} from './remove-resale-certificate.types';

export const removeResaleCertificate = ({
  userId,
}: RemoveResaleCertificateArgs) => {
  return axios.delete<RemoveResaleCertificateResponse>(
    `${coreApiUrl}/users/${userId}/resale-certificate`,
  );
};
