import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  AddExceededCycleTimeReasonsArgs,
  AddExceededCycleTimeReasonsResponse,
} from './add-exceeded-cycle-time-reasons.types';

export const addExceededCycleTimeReasons = ({
  jobId,
  ...body
}: AddExceededCycleTimeReasonsArgs) => {
  return axios
    .put<AddExceededCycleTimeReasonsResponse>(
      `${coreApiUrl}/jobs/${jobId}/exceeded-cycle-time-reasons`,
      body,
    )
    .then(({ data }) => {
      return data;
    });
};
