import React, { FC, memo, useMemo } from 'react';
import {
  Button,
  Cell,
  Flex,
  FormikInput,
  Grid,
  Typography,
} from 'gantri-components';
import { FastField, Field, useField } from 'formik';
import AutocompleteStreet from '../../../../components/common/inputs/autocomplete';
import { CountryInput, StateInput } from '../../../../components/common/inputs';
import { getSaveAddressPayload, isValidAddressForm } from './addresses.adapter';
import { Address } from '../../new-order.type';
import { useUpdateAddressMutation } from '../../../../api/addresses/routes/update-address';
import { useCreateAddressMutation } from '../../../../api/addresses/routes/create-address';

export const NewOrderAddressForm: FC<{
  onCancel: VoidFunction;
  onConfirm: (address: Address) => void;
}> = memo((props) => {
  const { onCancel, onConfirm } = props;
  const [fieldCountry] = useField('country');
  const [{ value: selectedAddress }] = useField('selectedAddress');

  const { isLoading: isUpdating, onUpdateAddress } = useUpdateAddressMutation({
    onSuccess: () => {
      onConfirm({ ...selectedAddress });
    },
  });

  const { isLoading: isCreating, onCreateAddress } = useCreateAddressMutation({
    onSuccess: (newAddress: Address) => {
      onConfirm(newAddress);
    },
  });

  const processing = isUpdating || isCreating;
  const isEditMode = !!selectedAddress?.id;

  const onSave = async () => {
    const isValid = isValidAddressForm(selectedAddress);
    if (!isValid) return;

    const payload = getSaveAddressPayload(selectedAddress);

    if (isEditMode) {
      await onUpdateAddress(payload);

      return;
    }

    await onCreateAddress(payload);
  };

  const isValidForm = useMemo(() => {
    return isValidAddressForm(selectedAddress);
  }, [selectedAddress]);

  return (
    <>
      <Typography
        paddingBottom="3x"
        text={isEditMode ? 'Edit Address' : 'New Shipping Address'}
        variant="h6"
      />
      <Grid alignItems="start" columns={6} gap="2x">
        <Cell gap="2x" width={3}>
          <FormikInput
            labelText="First Name"
            name="selectedAddress.firstName"
          />
          <FormikInput
            labelText="Company (Optional)"
            name="selectedAddress.company"
          />
        </Cell>

        <Cell gap="2x" width={3}>
          <FormikInput labelText="Last Name" name="selectedAddress.lastName" />
          <FormikInput
            labelText="Phone Number"
            name="selectedAddress.phone"
            type="tel"
          />
        </Cell>

        <Cell gap="2x" width={2}>
          <FormikInput
            Field={<AutocompleteStreet onSelectAddress={console.log} />}
            labelText="Street"
            name="selectedAddress.street"
          />

          <Field name="state">
            {({ field, meta }) => {
              return (
                <StateInput
                  country={fieldCountry.value}
                  hasError={meta.touched && meta.error}
                  labelText="State"
                  onInputChange={field.onChange}
                  onSelectChange={(value) => {
                    return field.onChange({
                      target: { name: 'selectedAddress.state', value },
                    });
                  }}
                  {...field}
                />
              );
            }}
          </Field>
        </Cell>

        <Cell gap="2x" width={2}>
          <FormikInput labelText="Unit" name="selectedAddress.unit" />
          <FormikInput labelText="Zip" name="selectedAddress.zip" />
        </Cell>

        <Cell gap="2x" width={2}>
          <FormikInput labelText="City" name="selectedAddress.city" />

          <FastField name="country">
            {({ field }) => {
              return (
                <CountryInput
                  {...field}
                  expectedValue="code"
                  labelText="Country"
                  onChange={(country) => {
                    return field.onChange({
                      target: { name: 'country', value: country },
                    });
                  }}
                />
              );
            }}
          </FastField>
        </Cell>

        <Cell width={6}>
          <Flex
            alignItems="center"
            gap="2x"
            justifyContent="end"
            paddingTop="2x"
          >
            <Button text="Cancel" variant="secondary" onClick={onCancel} />
            <Button
              disabled={!isValidForm}
              processing={processing}
              text={isEditMode ? 'Update Address' : 'Add Address'}
              onClick={onSave}
            />
          </Flex>
        </Cell>
      </Grid>
    </>
  );
});
