import {
  Cell,
  Conditional,
  ErrorMessage,
  FormikInput,
  Typography,
} from 'gantri-components';
import { useFormikContext } from 'formik';
import * as lodash from 'lodash';
import { useRecoilValue } from 'recoil';
import { capitalize } from 'lodash';
import { PartJobBlocksFormData } from '../../../../part-job-blocks.types';
import { GCodeDetails } from '../../../../../../../../../../../../components/common/jobs-template-table/hooks/use-get-jobs-template-table-columns/components/job-template-gcode-cell/components/gcode-details';
import {
  getGcodePrintQtyPartName,
  jobTemplateFilePath,
} from '../../../../../../../../../../../../helpers/gcodes';
import { PartJobBlocksPrintGcodeProps } from './part-job-blocks-print-gcode.types';
import { OnGCodeUpload } from '../../../../../../../../../../../../components/common/jobs-template-table/hooks/use-get-jobs-template-table-columns/components/job-template-gcode-cell/components/gcode-details/gcode-details.types';
import { productAtoms } from '../../../../../../../../../../product.atoms';
import { getProductName } from '../../../../../../../../../../../../helpers/product';
import { useIsEditingPartRowJobsDataForm } from '../../../../../../hooks/use-is-editing-part-row';

export const PartJobBlocksPrintGcode = (
  props: PartJobBlocksPrintGcodeProps,
) => {
  const {
    disable,
    gcode,
    index,
    numPrints,
    partName,
    printBlock,
    printName,
    replace,
    schemaId,
    weight,
  } = props;

  const product = useRecoilValue(productAtoms.product);
  const selectedSku = useRecoilValue(productAtoms.selectedSku);

  const isEditingPartRow = useIsEditingPartRowJobsDataForm();

  const productName = getProductName(product);

  const { errors, setFieldTouched, touched } =
    useFormikContext<PartJobBlocksFormData>();

  const urlSchemaId = `${schemaId}.url`;
  const fieldTouched = !!lodash.get(touched, urlSchemaId);
  const errorMessage: string = fieldTouched
    ? lodash.get(errors, urlSchemaId)
    : '';

  const uploadPath = jobTemplateFilePath({
    productId: product.id,
    sku: selectedSku,
    type: 'gcodes',
    version: 'draft',
  });

  const formattedPartName = getGcodePrintQtyPartName({
    numPrints,
    partName,
    printName,
  });

  const onBlur = async () => {
    await setFieldTouched(urlSchemaId, true);
  };

  const onRemove = () => {
    replace(index, {
      ...printBlock,
      gcode: {},
    });
  };

  const onUpload: OnGCodeUpload = (gcode) => {
    replace(index, {
      ...printBlock,
      gcode,
    });
  };

  const gcodeUrlSchemaId = `${schemaId}.url`;

  const missingFields = capitalize(
    [numPrints > 1 && !printName && 'name', !weight && 'weight']
      .filter(Boolean)
      .join(' & '),
  );
  const disabledDescription = `${missingFields} must be provided before you can upload a g-code.`;

  return (
    <Conditional
      condition={isEditingPartRow || !!gcode?.url}
      Fallback={<Typography text="-" />}
    >
      <FormikInput
        Field={
          <Cell>
            <GCodeDetails
              disabled={disable}
              disabledDescription={disabledDescription}
              gcode={gcode}
              inputName={gcodeUrlSchemaId}
              isEditing={isEditingPartRow}
              partName={formattedPartName}
              productName={productName}
              productVersion={product?.version?.version}
              showDownloadToast
              uploadPath={uploadPath}
              weight={weight}
              onBlur={onBlur}
              onRemove={onRemove}
              onUpload={onUpload}
            />
            <Conditional condition={isEditingPartRow}>
              <ErrorMessage errorMessage={errorMessage} required={!!weight} />
            </Conditional>
          </Cell>
        }
        fieldVariant="standard"
        name={gcodeUrlSchemaId}
      />
    </Conditional>
  );
};
