import { StartPrintJobModal } from './components/start-print-job-modal';
import { StartJobModalProps } from './start-job.types';
import { StartAssembleStageJobModal } from './components/start-assemble-stage-job-modal';
import { StartFinishingStageJobModal } from './components/start-finishing-stage-job-modal';
import { CompleteAssemblyModal } from '../complete-job/complete-assembly-modal';
import { CompletePrintQaModal } from '../complete-job/complete-print-qa-modal';
import { CompleteFinishingQaModal } from '../complete-job/complete-finishing-qa-modal';
import { CompleteFinalQAModal } from '../complete-job/complete-final-qa-modal';
import { StartJobGenericModal } from './components/start-job-generic-modal';
import { CompletePackingModal } from '../complete-job/complete-packing-modal';
import { CompleteSandJobModal } from '../complete-job/complete-sand-job-modal';
import { CompleteJobImmediatelyModal } from '../complete-job/complete-job-immediately-modal';
import { getJobInfo } from '../../../helpers/get-job-info';
import { CompletePrintPrepareModal } from '../complete-job/complete-print-prepare-modal';
import { NonProductJobModal } from './components/start-non-product-job-modal';
import { jobStatuses } from '../../../../../constants/options';
import { JobHasWrongStatusModal } from '../job-has-wrong-status-modal';

export const StartJobModal = (props: StartJobModalProps) => {
  const { job } = props;

  const {
    isAssembleJob,
    isAssembleStageJob,
    isFinalQaJob,
    isFinishBondoJob,
    isFinishGlueJob,
    isFinishMaskJob,
    isFinishSandJob,
    isFinishStageJob,
    isFinishingQaJob,
    isNonProductJob,
    isPackingJob,
    isPrintJob,
    isPrintPrepareJob,
    isPrintQaJob,
    isReady,
  } = getJobInfo(job);

  if (!isReady) {
    return (
      <JobHasWrongStatusModal {...props} expectedStatus={jobStatuses.ready} />
    );
  }

  if (isPrintPrepareJob) {
    return <CompletePrintPrepareModal {...props} />;
  }

  if (isPrintJob) {
    return <StartPrintJobModal {...props} />;
  }

  if (isPrintQaJob) {
    return <CompletePrintQaModal {...props} />;
  }

  if (isFinishMaskJob || isFinishBondoJob || isFinishGlueJob) {
    return <CompleteJobImmediatelyModal {...props} />;
  }

  if (isFinishSandJob) {
    return <CompleteSandJobModal {...props} />;
  }

  if (isFinishingQaJob) {
    return <CompleteFinishingQaModal {...props} />;
  }

  if (isFinishStageJob) {
    return <StartFinishingStageJobModal {...props} />;
  }

  if (isAssembleStageJob) {
    return <StartAssembleStageJobModal {...props} />;
  }

  if (isAssembleJob) {
    return <CompleteAssemblyModal {...props} />;
  }

  if (isFinalQaJob) {
    return <CompleteFinalQAModal {...props} />;
  }

  if (isPackingJob) {
    return <CompletePackingModal {...props} />;
  }

  if (isNonProductJob) {
    return <NonProductJobModal {...props} />;
  }

  // Paint and Prime jobs
  return <StartJobGenericModal {...props} />;
};
