import {
  Button,
  Conditional,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useModal,
} from 'gantri-components';
import { inventoryPurchasesApi } from '../../../../api/inventory-purchases';
import { InventoryPurchase } from '../../../../api/inventory-purchases/inventory-purchases.types';
import Menu from '../../../../components/common/menu';
import { MenuOption } from '../../../../components/common/menu/menu.props';
import { Label } from '../../../../components/label';
import { inventoryPurchaseStatuses } from '../../../../constants/inventory-purchases';
import { formatDate } from '../../../../helpers/formatter';
import { useNotification } from '../../../../hooks/useNotification';
import { CompleteInventoryPurchaseModal } from '../complete-inventory-purchase-modal';
import { DeleteInventoryPurchaseModal } from '../delete-inventory-purchase-modal';
import { PageHeadingContentProps } from './page-heading-content.types';

export const PageHeadingContent = (props: PageHeadingContentProps) => {
  const {
    editedInventoryPurchase,
    isEditingDisabled,
    onUpdate,
    updateEditedInventoryPurchaseWith,
  } = props;

  const { notify, notifyAxiosError } = useNotification();

  const [showCompleteModal, hideCompleteModal] = useModal(() => {
    return (
      <CompleteInventoryPurchaseModal
        editedInventoryPurchase={editedInventoryPurchase}
        updateEditedInventoryPurchaseWith={updateEditedInventoryPurchaseWith}
        onClose={async (newInventoryPurchaseData?: InventoryPurchase) => {
          onUpdate(newInventoryPurchaseData);
          hideCompleteModal();
        }}
      />
    );
  }, [editedInventoryPurchase]);

  const [showDeleteModal, hideDeleteModal] = useModal(() => {
    return (
      <DeleteInventoryPurchaseModal
        editedInventoryPurchase={editedInventoryPurchase}
        onClose={hideDeleteModal}
      />
    );
  }, [editedInventoryPurchase]);

  const sendVendorEmail = async () => {
    try {
      const response = await inventoryPurchasesApi.sendVendorEmail(
        editedInventoryPurchase.id,
      );

      notify(response.data.notice);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'An error occurred sending vendor email.',
      });
    }
  };

  const isPurchaseDelivered =
    editedInventoryPurchase.status === inventoryPurchaseStatuses.delivered;
  const isCompleteButtonDisabled = isEditingDisabled || !isPurchaseDelivered;
  const isPurchaseComplete =
    editedInventoryPurchase.status === inventoryPurchaseStatuses.completed;

  const pageOptions: MenuOption[] = [
    {
      enabled: true,
      name: 'Email vendor',
      onOptionClick: sendVendorEmail,
    },
    {
      allowedFor: ['Admin'],
      enabled: !isPurchaseComplete,
      name: 'Delete',
      onOptionClick: showDeleteModal,
    },
  ];

  const CompleteButton = () => {
    return (
      <Button
        disabled={isCompleteButtonDisabled}
        text="Complete"
        onClick={showCompleteModal}
      />
    );
  };

  return (
    <Grid alignItems="center" columns="repeat(2, max-content)" gap="2rem">
      <Conditional
        condition={isPurchaseComplete}
        Fallback={
          <Conditional
            condition={isCompleteButtonDisabled}
            Fallback={<CompleteButton />}
          >
            <Tooltip
              description="All shipments must be delivered before you can complete an inventory purchase."
              position="bottom-end"
            >
              <CompleteButton />
            </Tooltip>
          </Conditional>
        }
      >
        <Stack gap="unset" height="unset">
          <Label paddingBottom=".5x" text="Completed" />
          <Typography text={formatDate(editedInventoryPurchase.completedAt)} />
        </Stack>
      </Conditional>
      <Menu menuDropPadding={10} options={pageOptions} />
    </Grid>
  );
};
