import { Icon, Typography } from 'gantri-components';
import { useTheme } from 'styled-components';
import { IconProps } from 'gantri-components/dist/components/icon/icon.types';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AdvancedToggleItemComponent } from '../../../../../../../../../components/common/button-toggle-group/button-toggle-group.types';
import DatePicker from '../../../../../../../../../components/common/date-picker';
import { StyledTriggerWrapper } from './custom-range-toggle.styles';
import { dashboardAndReportAtoms } from '../../../../../../../dashboard.atoms';

export const CustomRangeToggle: AdvancedToggleItemComponent = ({
  isActive,
  value,
}) => {
  const theme = useTheme();

  const [startDate, setStartDate] = useRecoilState(
    dashboardAndReportAtoms.startDate,
  );
  const [endDate, setEndDate] = useRecoilState(dashboardAndReportAtoms.endDate);
  const rangeType = useRecoilValue(dashboardAndReportAtoms.rangeType);

  const initTo = new Date();
  const initFrom = new Date();

  initFrom.setDate(initTo.getDate() - 7);

  const initialValue = {
    from: startDate || initFrom,
    to: endDate || initTo,
  };

  return (
    <DatePicker
      initialValue={{
        from: initialValue.from,
        to: initialValue.to,
      }}
      range
      Trigger={() => {
        const activeIconColor: IconProps['color'] =
          theme.name === 'light' ? 'alt' : 't1';
        const inactiveIconColor: IconProps['color'] =
          theme.name === 'light' ? 't1' : 't3';

        return (
          <StyledTriggerWrapper>
            <Typography
              align="center"
              color={isActive ? 'alt' : 't2'}
              icon={
                <Icon
                  color={isActive ? activeIconColor : inactiveIconColor}
                  name="time:calendar"
                  size="1.6rem"
                  style={{ marginBottom: '0.1rem' }}
                />
              }
              text={value}
            />
          </StyledTriggerWrapper>
        );
      }}
      onDateChanged={({ formattedFrom, formattedTo }) => {
        if (/^custom$/i.test(rangeType)) {
          setStartDate(formattedFrom);
          setEndDate(formattedTo);
        }
      }}
    />
  );
};
