import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import {
  VersionPublishArgs,
  VersionPublishResponse,
} from '../../version.types';

export const publishVersion = ({
  description,
  productId,
  type,
}: VersionPublishArgs) => {
  return axios.put<VersionPublishResponse>(`${coreApiUrl}/versions`, {
    description,
    productId,
    type,
  });
};
