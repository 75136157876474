import { environment } from '../../environment';

export const getActiveEnv = () => {
  const isLocalEnv = environment.STAGE === 'local';
  const isDevelopEnv = environment.STAGE === 'develop';
  const isStagingEnv = environment.STAGE === 'staging';
  const isProductionEnv = environment.STAGE === 'production';

  return {
    isDevelopEnv,
    isLocalEnv,
    isProductionEnv,
    isStagingEnv,
  };
};
