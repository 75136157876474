import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Typography } from 'gantri-components';
import { TableSortField } from '../../components/common/table/table.props';
import { GiftCardsSortingField } from '../../api/gift-cards/routes/fetch-paginated-giftcards/fetch-paginated-giftcards.types';
import { formatDateWithoutTimezone } from '../../helpers/formatter';
import { MEDIUM_FORMAT } from '../../constants/dates';
import { GiftCardRecord } from './gift-cards.types';
import { StyledAnchor } from '../../components/common/styled-anchor';
import routePaths from '../../config/route-paths';
import { getIsClickableIfValue } from '../../helpers/get-is-clickable-if-value';

export const sortOptions: TableSortField<GiftCardsSortingField>[] = [
  { bidirectional: true, label: 'Date', sortingField: 'createdAt' },
  {
    bidirectional: true,
    label: 'Send Date',
    sortingField: 'sendDate',
  },
];

export const columns: ColumnDef<GiftCardRecord>[] = [
  {
    accessorKey: 'orderId',
    cell: ({
      getValue,
    }: CellContext<GiftCardRecord, GiftCardRecord['orderId']>) => {
      const id = getValue();

      return (
        <StyledAnchor href={`${routePaths.orders}/${id}`} text={`#${id}`} />
      );
    },
    header: 'Order #',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
    size: 60,
  },
  {
    accessorKey: 'createdAt',
    cell: ({
      getValue,
    }: CellContext<GiftCardRecord, GiftCardRecord['createdAt']>) => {
      return formatDateWithoutTimezone(getValue(), MEDIUM_FORMAT);
    },
    header: 'Date',
    size: 60,
  },
  {
    accessorKey: 'design',
    cell: ({
      getValue,
    }: CellContext<GiftCardRecord, GiftCardRecord['design']>) => {
      return <Typography text={getValue().title} />;
    },
    header: 'Gift Card Name',
    id: 'giftCardName',
  },
  {
    accessorKey: 'senderName',
    cell: ({
      getValue,
      row,
    }: CellContext<GiftCardRecord, GiftCardRecord['createdAt']>) => {
      return (
        <StyledAnchor
          href={`${routePaths.users}/${row.original.userId}`}
          target="_blank"
          text={getValue()}
        />
      );
    },
    header: 'Sender',
    meta: {
      getIsClickable: getIsClickableIfValue,
    },
  },
  {
    accessorKey: 'recipientEmail',
    header: 'Recipient Email',
  },
  {
    accessorKey: 'sendDate',
    cell: ({ getValue }) => {
      return formatDateWithoutTimezone(getValue(), MEDIUM_FORMAT);
    },
    header: 'Send Date',
    size: 60,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 60,
  },
];
