import { Button, Modal } from 'gantri-components';
import { useState } from 'react';
import { useAsync, useDebounce } from 'react-use';
import { locationsApi } from '../../../api';
import { useNotification } from '../../../hooks/useNotification';
import {
  SelectedLocation,
  UpdateLocationModalProps,
} from './update-location-modal.types';
import { Content } from './components/content';
import { VLMLocation } from '../../../api/locations/routes/get-available-locations/get-available-locations.types';
import { updateLocationDefaultProps } from './update-location-modal.constants';

export const UpdateLocationModal = (props: UpdateLocationModalProps) => {
  const {
    currentLocationId,
    currentLocationName,
    filterLocationsCallback,
    header,
    locationLabel,
    locationTimelines,
    onClose,
    onUpdateLocation,
    partId,
    stockId,
  } = props;

  const locationName = currentLocationName || 'Unassigned';

  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation>({
    label: locationName,
    value: currentLocationId,
  });
  // extracted to state as availableLocations could be an empty array if the VLM is full or there is an error
  const [locationsFetched, setLocationsFetched] = useState<boolean>(false);
  const [locations, setLocations] = useState<VLMLocation[]>([]);
  const [availableLocations, setAvailableLocations] = useState<VLMLocation[]>(
    [],
  );
  const [search, setSearch] = useState<string>('');

  const { hideLoading, notifyAxiosError, showLoading } = useNotification();

  const locationUpdated = currentLocationId !== selectedLocation?.value;

  const getAvailableLocations = async () => {
    try {
      showLoading();

      const { data } = await locationsApi.getAvailableLocations({
        partId,
        stockId,
      });

      let locations = data.locations || [];

      locations = filterLocationsCallback
        ? locations.filter(filterLocationsCallback)
        : locations;

      setLocations(locations);
      setAvailableLocations(locations);
      setLocationsFetched(true);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage:
          'There was an error getting the available VLM locations.',
      });
    } finally {
      hideLoading();
    }
  };

  const getFilteredLocations = () => {
    if (search.length > 0) {
      const filterLocations = locations.filter((location) => {
        return location.name.toLowerCase().includes(search.toLowerCase());
      });

      setAvailableLocations(
        filterLocations.length > 0 ? filterLocations : locations,
      );
    } else {
      setAvailableLocations(locations);
    }
  };

  useDebounce(getFilteredLocations, 300, [search]);

  const handleUpdateLocation = async () => {
    try {
      showLoading();
      await onUpdateLocation(selectedLocation?.value);
      onClose();
    } catch (error: unknown) {
      await getAvailableLocations();
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to update location.',
      });
    } finally {
      hideLoading();
    }
  };

  const handleChangeLocation = (item?: VLMLocation) => {
    setSelectedLocation(!!item ? { label: item.name, value: item.id } : null);
  };

  useAsync(async () => {
    await getAvailableLocations();
  }, []);

  return locationsFetched ? (
    <Modal
      footer={
        <>
          <Button
            size="large"
            text="Cancel"
            variant="secondary"
            onClick={onClose}
          />
          <Button
            disabled={!locationUpdated}
            size="large"
            text="Update"
            onClick={handleUpdateLocation}
          />
        </>
      }
      header={header}
      width={{ lg: '46rem', md: '100%' }}
      onClose={onClose}
    >
      <Content
        availableLocations={availableLocations}
        locationLabel={locationLabel}
        locationTimelines={locationTimelines}
        partId={partId}
        selectedLocation={selectedLocation}
        setSearch={setSearch}
        setSelectedLocation={handleChangeLocation}
        stockId={stockId}
      />
    </Modal>
  ) : null;
};

UpdateLocationModal.defaultProps = updateLocationDefaultProps;
