import { Cell, Grid, SearchField, Typography } from 'gantri-components';
import { FC } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { FilterRow } from '../../../../common/filter-grid';
import { FilterResetLabel } from '../../../../common/filter-reset-label';
import { AssignedToFilterProps } from './assigned-to-filter.types';

export const AssignedToFilter: FC<AssignedToFilterProps> = (props) => {
  const {
    assignedToAtom,
    default: defaultValue,
    useSingleSelection = false,
    userNames,
  } = props;

  const [assignedTo, setAssignedTo] = useRecoilState(assignedToAtom);
  const resetAssignedTo = useResetRecoilState(assignedToAtom);

  return (
    <FilterRow>
      <FilterResetLabel
        atom={assignedToAtom}
        default={defaultValue}
        text="Assigned to"
      />

      <Cell
        data-filter-by="assignedTo"
        maxWidth={{ lg: '60rem', md: 'unset' }}
        style={{ position: 'relative' }}
      >
        <SearchField
          autoSelectFirst={false}
          enableSelectedItemsList={!useSingleSelection}
          filterFn={(searchBy, option) => {
            return (
              option.name.toLowerCase().includes(searchBy.toLowerCase()) ||
              option.type?.toLowerCase()?.includes(searchBy.toLowerCase())
            );
          }}
          idProperty="id"
          items={userNames}
          labelProperty="name"
          placeholder="Search user"
          renderItem={({ name, type }) => {
            return (
              <Grid
                alignItems="center"
                columns="1fr max-content"
                gap="0.8rem"
                horizontalPadding="1.6rem"
                verticalPadding="0.8rem"
              >
                <Typography color="t1" text={name} variant="p2" />
                <Typography color="t2" text={type} variant="p3" />
              </Grid>
            );
          }}
          selectedItemsList={assignedTo ?? []}
          size="medium"
          onChange={({ target }) => {
            if (useSingleSelection) {
              if (!target.value) {
                resetAssignedTo();

                return;
              }

              // @ts-expect-error
              setAssignedTo([target.value]);

              return;
            }

            // @ts-expect-error
            if (!target.value || target.value?.length === 0) {
              resetAssignedTo();
            } else {
              // @ts-expect-error
              setAssignedTo(target.value ?? []);
            }
          }}
        />
      </Cell>
    </FilterRow>
  );
};
