import { useRecoilValue } from 'recoil';
import { finishingQcModalAtoms } from '../../complete-finishing-qa-modal.atoms';
import { CloseModalButtonFooter } from '../close-modal-button-footer';
import { ChecklistFooter } from '../step-checklist/checklist-footer';
import { ConfirmBeginFooter } from '../step-confirm-begin/confirm-begin-footer';
import { JobFailedRecommendedNextStepFooter } from '../step-job-failed-recommended-next-step/job-failed-recommended-next-step-footer';
import { JobFailedUpdateBatchFooter } from '../step-job-failed-update-batch/job-failed-update-batch-footer';
import { FinishingQaFooterProps } from './finishing-qa-footer.types';

export const FinishingQaFooter = (props: FinishingQaFooterProps) => {
  const { handleCloseModal, job } = props;

  const step = useRecoilValue(finishingQcModalAtoms.step);

  switch (step) {
    case 'CONFIRM_BEGIN':
      return (
        <ConfirmBeginFooter handleCloseModal={handleCloseModal} job={job} />
      );

    case 'CHECKLIST':
      return <ChecklistFooter handleCloseModal={handleCloseModal} job={job} />;

    case 'JOB_FAILED_NEXT_STEP':
      return <JobFailedRecommendedNextStepFooter job={job} />;

    case 'JOB_FAILED_UPDATE_BATCH':
      return <JobFailedUpdateBatchFooter partId={job.part.id} />;

    case 'JOB_FAILED_REFINISH_PAINT_ONLY':
    case 'JOB_FAILED_REFINISH_TARGETED_REWORK':
    case 'JOB_FAILED_REFINISH_COMPLETE_REWORK':
    case 'JOB_FAILED_RESTART':
      return <CloseModalButtonFooter handleCloseModal={handleCloseModal} />;

    default:
      return null;
  }
};
