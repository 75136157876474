import {
  Button,
  Cell,
  Conditional,
  Icon,
  Modal,
  useModal,
} from 'gantri-components';
import { DependencyList, useState } from 'react';
import { JOB_DETAILS_PANEL_LINKED_TO } from '../../../../../../../../../../../components/jobs-details-panel-wrapper/components/jobs-details-panel';
import { cloudinaryUrl } from '../../../../../../../../../../../helpers/images';
import { placeholderImageSrc } from '../../../../../../../../../../../constants/images';
import { StyledImg, StyledVideo } from './use-thumbnail-zoom-modal.styles';
import { UseThumbnailZoomModalProps } from './use-thumbnail-zoom-modal.types';
import { OverflowEllipsisTypography } from '../../../../../../../../../../../components/common/overflow-ellipsis-typography';

/** @deprecated Consider using `ZoomableThumbnail` instead. */
export const useThumbnailZoomModal = (
  props: UseThumbnailZoomModalProps,
  dependencies: DependencyList = [],
): ReturnType<typeof useModal> => {
  const { alt, heading, src, width } = props;
  const contentSrc = src || placeholderImageSrc;
  const altText = alt || typeof heading === 'string' ? String(heading) : '';

  const isVideo = /\/video\//i.test(src);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const [showModal, hideModal] = useModal(() => {
    return (
      <Modal
        dataAttributes={{ 'linked-to': JOB_DETAILS_PANEL_LINKED_TO }}
        footer={
          <Button
            size="large"
            text="Close"
            variant="secondary"
            onClick={hideModal}
          />
        }
        header={
          typeof heading === 'string' ? (
            <OverflowEllipsisTypography
              align="center"
              text={heading}
              variant="h4"
            />
          ) : (
            heading
          )
        }
        maxWidth={{ lg: '80vw', md: '100%' }}
        width={{ lg: width || 'max-content', md: '100%' }}
        onClose={hideModal}
      >
        <Cell justifyContent="center">
          <Conditional condition={!isLoaded}>
            <Icon color="link" name="animated:loader" size="4rem" />
          </Conditional>
          <Conditional
            condition={isVideo}
            Fallback={
              <StyledImg
                alt={altText}
                aria-label={altText}
                src={cloudinaryUrl(contentSrc, {
                  crop: 'limit',
                  height: 1000,
                  width: 1000,
                })}
                title={altText}
                onLoad={() => {
                  setIsLoaded(true);
                }}
              />
            }
          >
            <StyledVideo
              aria-label={altText}
              autoPlay
              loop
              muted
              src={contentSrc}
              title={altText}
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          </Conditional>
        </Cell>
      </Modal>
    );
  }, [...dependencies, contentSrc, isLoaded]);

  return [showModal, hideModal];
};
