import { FC, useState } from 'react';
import { Button } from 'gantri-components';
import {
  orderStatusesMap,
  shipmentStatuses,
  transactionTypesMap,
} from '../../../../../../constants/options';
import { StyledTestShippoButtonsContainer } from '../../order-shipment.styles';
import { environment } from '../../../../../../environment';
import { ShippoWebhookButtonProps } from './shippo-webhook-buttons.types';
import { useNotification } from '../../../../../../hooks/useNotification';
import { transactionsApi } from '../../../../../../api';

export const TestShippoWebhookButtons: FC<ShippoWebhookButtonProps> = (
  props,
) => {
  const {
    currentOrderData,
    shipmentHistory,
    shipmentId,
    shipmentStatus,
    trackingNumber,
  } = props;

  const { status: orderStatus, type: orderType } = currentOrderData;

  const { notify, notifyAxiosError } = useNotification();
  const [loading, setLoading] = useState<boolean | string>(false);

  const testShippoWebhook = (
    testType: string,
    fail: boolean,
    buttonId: string,
  ) => {
    return async () => {
      try {
        setLoading(testType === shipmentStatuses.failure ? buttonId : testType);
        await transactionsApi.testShippoWebhook({
          fail,
          shipmentHistory: shipmentHistory || {},
          testType,
          trackingNumber,
        });

        notify(`Test ${testType} successful.`);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to test shippo webhook.',
        });
      } finally {
        setLoading(null);
      }
    };
  };

  const testSentryError = (
    testType: string,
    fail: boolean,
    buttonId: string,
  ) => {
    return async () => {
      try {
        setLoading(testType === shipmentStatuses.failure ? buttonId : testType);
        await transactionsApi.testShippoWebhook({
          fail,
          shipmentHistory: shipmentHistory || {},
          testType,
          trackingNumber,
          trackingStatus: null,
        });

        notify(`Test ${testType} successful.`);
      } catch (error: unknown) {
        notifyAxiosError({
          error,
          fallbackMessage: 'Unable to test shippo webhook.',
        });
      } finally {
        setLoading(null);
      }
    };
  };

  const testSendgridAlmostThere = async () => {
    try {
      setLoading('almost there');

      const { data } = await transactionsApi.testSendgridAlmostThere({
        shipmentId,
      });

      notify(data.notice);
    } catch (error: unknown) {
      notifyAxiosError({
        error,
        fallbackMessage: 'Unable to send test "almost there" email.',
      });
    } finally {
      setLoading(null);
    }
  };

  const testButtonsConditions =
    ([
      shipmentStatuses.labelCreated,
      shipmentStatuses.inTransit,
      shipmentStatuses.shipped,
    ].includes(shipmentStatus) &&
      [
        orderStatusesMap.shipped,
        orderStatusesMap.partiallyDelivered,
        orderStatusesMap.partiallyRefunded,
        orderStatusesMap.partiallyShipped,
      ].some((status) => {
        return status === orderStatus;
      })) ||
    (orderType === transactionTypesMap.refund &&
      orderStatus === orderStatusesMap.refundInProgress);
  const showButtons =
    environment.STAGE !== 'production' && testButtonsConditions;

  return !showButtons ? null : (
    <StyledTestShippoButtonsContainer>
      <Button
        processing={loading === shipmentStatuses.transit}
        text="Shippo: Test Transit"
        onClick={testShippoWebhook(shipmentStatuses.transit, false, '1')}
      />
      <Button
        processing={loading === shipmentStatuses.delivered}
        text="Shippo: Test Deliver"
        onClick={testShippoWebhook(shipmentStatuses.delivered, false, '2')}
      />
      <Button
        processing={loading === 'almost there'}
        text="Sendgrid: Test Almost There"
        onClick={testSendgridAlmostThere}
      />
      <Button
        processing={loading === '1'}
        text="Shippo: Test Failed"
        onClick={testShippoWebhook(shipmentStatuses.failure, true, '1')}
      />
      <Button
        processing={loading === '2'}
        text="Shippo: Test Webhook Error"
        onClick={testSentryError(shipmentStatuses.failure, true, '2')}
      />
    </StyledTestShippoButtonsContainer>
  );
};
