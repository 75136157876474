import { FC } from 'react';
import { Icon, Typography } from 'gantri-components';
import { formatPhoneNumber } from '../../../../helpers/formatter';
import { OrderPaymentInfoProps } from './payment-info.types';

export const OrderPaymentInfo: FC<OrderPaymentInfoProps> = ({
  currentOrderData,
  payment,
}) => {
  const { billingAddress } = currentOrderData;

  const {
    city,
    country,
    firstName,
    lastName,
    name,
    phone,
    state,
    street,
    unit,
    zip,
  } = billingAddress || {};

  const paymentInfo = () => {
    if (!payment || payment.thirdParty) {
      return null;
    }

    if (payment.type === 'Affirm') {
      return (
        <Typography
          icon={<Icon name="logos:logo_affirm" top="-3px" />}
          iconPosition="right"
          text="Pay monthly with"
        />
      );
    }

    return <Typography text={`${payment.cardType} *${payment.number}`} />;
  };

  const formattedPhone = phone ? formatPhoneNumber(phone) : '';

  return (
    <>
      <Typography marginBottom="2x" text="Payment" variant="h4" />

      <div>
        {paymentInfo()}
        {billingAddress && (
          <>
            <Typography
              marginTop="2x"
              text={name || `${firstName} ${lastName}`}
            />
            <Typography text={`${street}${unit ? `, ${unit}` : ''}`} />
            <Typography color="t1" text={`${city}, ${state}, ${zip}`} />
            <Typography color="t1" text={country} />
            <Typography color="t1" text={formattedPhone} />
          </>
        )}
      </div>
    </>
  );
};
