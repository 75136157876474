import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { GetOverviewArgs, OverviewMachine } from './get-overview.types';

const query = (args: GetOverviewArgs) => {
  const { machineId } = args;

  return axios
    .get<{ machine: OverviewMachine }>(
      `${coreApiUrl}/machines/${machineId}/overview`,
    )
    .then((response) => {
      return response.data;
    });
};

const key = (args: GetOverviewArgs) => {
  const { machineId } = args;

  return [`machine-${machineId}-overview`];
};

export const getMachineOverview = {
  key,
  query,
};
