import axios from 'axios';
import { coreApiUrl } from '../../../../helpers/auth';
import { FailQaJobArgs } from './fail-qa-job.types';
import { FailJobResponse } from '../fail-job/fail-job.types';

export const failQaJob = (jobId: number, body: FailQaJobArgs) => {
  return axios
    .post<FailJobResponse>(`${coreApiUrl}/jobs/${jobId}/fail`, body)
    .then(({ data }) => {
      return data;
    });
};
