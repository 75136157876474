import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { fullPageLoadingSpinnerAtoms } from '../../components/common/full-page-loading-spinner/full-page-loading-spinner.atoms';
import { FunctionReturningPromise } from './use-spinner.types';

export const useSpinner = () => {
  const [processing, setProcessing] = useState<boolean>();
  const setShowSpinner = useSetRecoilState(
    fullPageLoadingSpinnerAtoms.showSpinner,
  );

  /**
   * Handles showing/hiding the full-page loading indicator.
   *
   * Usage example:
   * ```
   * const { onInterceptRequest } = useSpinner();
   *
   * const handleEndpoint = async () => {
   *   await onInterceptRequest(async () => {
   *     try {
   *        await yourRequestedAxiosEndpoint();
   *      } catch (error: unknown) {
   *        notifyAxiosError({
   *         error,
   *         fallbackMessage: 'Unable to complete task.',
   *        });
   *      }
   *   })
   * };
   * ```
   */
  const onInterceptRequest = async (
    requestCallback: FunctionReturningPromise,
  ) => {
    try {
      showLoading();

      return await requestCallback();
    } finally {
      hideLoading();
    }
  };

  /**
   * Handles toggling the processing state for use elsewhere.
   *
   * Usage example:
   * ```
   * const { onInterceptProcessingRequest, processing } = useSpinner();
   *
   * const handleEndpoint = async () => {
   *   await onInterceptProcessingRequest(async () => {
   *     try {
   *        await yourRequestedAxiosEndpoint();
   *      } catch (error: unknown) {
   *        notifyAxiosError({
   *         error,
   *         fallbackMessage: 'Unable to complete task.',
   *        });
   *      }
   *   })
   * };
   *
   * return <Button processing={processing} text="Confirm" />
   * ```
   */
  const onInterceptProcessingRequest = async (
    requestCallback: FunctionReturningPromise,
  ) => {
    try {
      setProcessing(true);

      return await requestCallback();
    } finally {
      setProcessing(false);
    }
  };

  const showLoading = () => {
    setShowSpinner(true);
  };

  const hideLoading = () => {
    setShowSpinner(false);
  };

  return {
    hideLoading,
    onInterceptProcessingRequest,
    onInterceptRequest,
    processing,
    setProcessing,
    showLoading,
  };
};
