import { Grid, SearchField, Typography } from 'gantri-components';
import { AssignedToFieldProps } from './assigned-to-field.types';

export const AssignedToField = (props: AssignedToFieldProps) => {
  const { labelText, onSelect, value, workers } = props;

  return (
    <SearchField
      filterFn={(searchBy, option) => {
        return (
          option.name.toLowerCase().includes(searchBy.toLowerCase()) ||
          option.type?.toLowerCase()?.includes(searchBy.toLowerCase())
        );
      }}
      idProperty="id"
      items={workers}
      labelProperty="name"
      labelText={labelText}
      maxHeight="40rem"
      placeholder="Search worker"
      renderItem={({ name, type }) => {
        return (
          <Grid
            alignItems="center"
            columns="1fr max-content"
            gap="0.8rem"
            horizontalPadding="1.6rem"
            verticalPadding="0.8rem"
          >
            <Typography color="t1" text={name} variant="p2" />
            <Typography color="t2" text={type} variant="p3" />
          </Grid>
        );
      }}
      value={value}
      onSelect={onSelect}
    />
  );
};
